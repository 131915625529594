import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { TimeZoneService } from '../../../services/timeZone.service';

@Component({
  templateUrl: 'optional-date-and-time.component.html',
  styleUrls: ['optional-date-and-time.component.scss'],
  selector: 'app-optional-date-time',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionalDateAndTimeComponent implements OnChanges, OnDestroy {
  @Input() firstDateLabel: string = 'Start';
  @Input() firstDateString: string;
  @Input() secondDateLabel: string = 'End';
  @Input() secondDateString: string;
  @Input() showPickers: boolean = true;
  @Input() editMode: boolean = false;
  @Input() showSecondDate: boolean = true;
  @Input() includeTime: boolean = true;
  @Input() isDetailsPage = false;
  @Input() showCheckbox = true;

  @Output() onFirstDateChanged = new EventEmitter<string>();
  @Output() onSecondDateChanged = new EventEmitter<string>();
  @Output() onShowPickersChanged = new EventEmitter<boolean>();

  minDate: Date;
  startDate: string;
  endDate: string;
  showDateError = false;
  subscriptions: Subscription[] = [];
  public readonly T = T;

  constructor(private readonly timezoneService: TimeZoneService) {}

  ngOnChanges(): void {
    if (!this.showCheckbox) {
      this.showPickers = true;
    }

    this.startDate = JSON.parse(
      JSON.stringify(this.timezoneService.localiseDateISOStringByCustomFormat(this.firstDateString, 'YYYY-MM-DDTHH:mm:ss'))
    );
    this.endDate = JSON.parse(
      JSON.stringify(this.timezoneService.localiseDateISOStringByCustomFormat(this.secondDateString, 'YYYY-MM-DDTHH:mm:ss'))
    );

    if (this.firstDateString) {
      this.minDate = new Date(this.firstDateString);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  onStartDateChanged(dateAsIsoString: string) {
    this.startDate = dateAsIsoString;
    this.firstDateString = dateAsIsoString;
    this.onFirstDateChanged.next(dateAsIsoString);

    const newMinDate = new Date(dateAsIsoString);

    newMinDate.setMinutes(newMinDate.getMinutes() + 1);

    this.minDate = newMinDate;

    if (!this.secondDateString || new Date(this.secondDateString) < new Date(dateAsIsoString)) {
      this.secondDateString = newMinDate.toISOString();
      this.onSecondDateChanged.next(newMinDate.toJSON());
    }

    this.showDateError = false;
  }

  onEndDateChanged(dateAsIsoString: string) {
    this.endDate = dateAsIsoString;
    this.secondDateString = dateAsIsoString;
    this.onSecondDateChanged.next(this.secondDateString);
  }

  onStartTimeChanged(dateAsIsoString: string) {
    this.startDate = dateAsIsoString;
    this.firstDateString = dateAsIsoString;
    this.onFirstDateChanged.next(dateAsIsoString);

    const newMinDate = new Date(this.startDate);

    newMinDate.setMinutes(newMinDate.getMinutes() + 1);

    this.minDate = newMinDate;

    if (!this.secondDateString || new Date(this.secondDateString) < new Date(dateAsIsoString)) {
      this.secondDateString = newMinDate.toISOString();
      this.onSecondDateChanged.next(newMinDate.toJSON());
    }
  }

  onEndTimeChanged(dateAsIsoString: string) {
    this.endDate = dateAsIsoString;
    this.secondDateString = dateAsIsoString;
    this.onSecondDateChanged.next(dateAsIsoString);
  }

  get localisedEndDate(): string {
    if (this.secondDateString && this.secondDateString.indexOf('0001-') < 0) {
      return this.timezoneService.localiseDateISOString(this.secondDateString, true, true);
    }

    return 'n/a';
  }

  get localisedStartDate(): string {
    if (this.firstDateString && this.firstDateString.indexOf('0001-') < 0) {
      return this.timezoneService.localiseDateISOString(this.firstDateString, true, true);
    }

    return 'n/a';
  }

  get localisedEndDateShort(): string {
    if (this.secondDateString) {
      return this.timezoneService.localiseDateISOString(this.secondDateString, false, false);
    }

    return '';
  }

  get localisedStartDateShort(): string {
    if (this.firstDateString) {
      return this.timezoneService.localiseDateISOString(this.firstDateString, false, false);
    }

    return '';
  }

  toggleShowPickers(state: boolean) {
    this.onShowPickersChanged.next(state);
  }
}
