import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { CommentEventsEmitter } from 'src/app/modules/shared/events/comments.events';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { UpdateTypes } from 'src/app/modules/shared/enums/updateTypes';
import { Comment } from 'src/app/modules/shared/models/comment';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { LocalisationService } from '../../../services/localisation.service';

@Component({
  selector: 'add-update-modal',
  templateUrl: 'add-update-modal.component.html',
  styleUrls: ['add-update-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddUpdateModal implements OnInit, OnDestroy, AfterViewInit {
  subscriptions: Subscription[] = [];
  @ViewChild('header', { static: true }) header: ElementRef<HTMLElement>;
  commentViewModel: Comment;
  objectId: number;
  objectType: ObjectTypes;
  objectSubType: any;
  commentType: UpdateTypes;
  relatedObjectId: number;
  placeholder: string;
  showEditorButtons: boolean = true;
  isModal: boolean = true;
  public headerText: string;
  public message: string;
  public isRagUpdate: boolean;
  public currentComment: string = '';
  public modalTitle: string;
  broadcastCommentsCountUpdated = false;
  public readonly T = T;
  public placeholderMessage: string = `${this.translateService.instant(T.common.add_description)}...`;
  public childhubAccountId = 0;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly authenticationService: AuthenticationService,
    private readonly commentEventsEmitter: CommentEventsEmitter,
    private readonly elemRef: ElementRef,
    private readonly translateService: TranslateService,
    private readonly localisationService: LocalisationService
  ) {}

  ngOnInit(): void {
    const accountId = this.authenticationService.getCurrentAccount().id;
    const employeeId = this.authenticationService.getCurrentEmployee().id;
    this.commentViewModel = new Comment();
    this.commentViewModel.accountId = accountId;
    this.commentViewModel.createdById = employeeId;
    this.commentViewModel.globalObjectId = this.objectId;
    this.commentViewModel.globalObjectType = this.objectType;
    this.commentViewModel.relatedGlobalObjectId = this.relatedObjectId;
    this.getModalTitle();
    this.initDefaultMessage();
    this.initPlaceholderMessage();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  initDefaultMessage() {
    if (!this.message) {
      if (this.isRagUpdate) {
        this.message = this.translateService.instant(T.common.your_change_may_affect_rag_rating);
      } else {
        this.message = this.translateService.instant(T.common.please_add_comment_or_update_bellow);
      }
    }
  }
  ngAfterViewInit(): void {
    this.setModalContainerDisplayFlex();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onUpdateTextChanged(comment: string) {
    this.currentComment = comment;
  }

  onAddUpdate() {
    if (this.currentComment.length) {
      this.commentEventsEmitter.broadcastCommentSaveChangesClicked();
      this.bsModalRef.hide();
    }
  }

  setModalContainerDisplayFlex() {
    const modalDialog = this.elemRef.nativeElement.closest('[role=document]');
    (modalDialog as HTMLElement).classList.add('overflow-visible');
  }

  getModalTitle() {
    if (this.headerText) {
      this.modalTitle = this.headerText;
    } else {
      switch (this.objectType) {
        case ObjectTypes.IncidentItem: {
          let sTypes: IncidentItemTypes = null;

          if (this.objectSubType) {
            sTypes = this.objectSubType as IncidentItemTypes;
          }

          if (sTypes && sTypes === IncidentItemTypes.Log) {
            const localisedLog = this.translateService.instant(T.defaultLocalizations.log.one);
            this.modalTitle = this.translateService.instant(T.common.add_item_update, { item: localisedLog });
          } else {
            const localisedIncident = this.localisationService.localiseObjectType(ObjectTypes.IncidentItem);
            this.modalTitle = this.translateService.instant(T.common.add_item_update, { item: localisedIncident });
          }
        }
      }
    }
    this.modalTitle = this.translateService.instant(T.common.add_comment);
  }

  initPlaceholderMessage(): void {
    if (this.placeholder) {
      this.placeholderMessage = this.placeholder;
    }
  }
}
