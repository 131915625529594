<div class="loading-wrapper">
  <div class="responsive-flex-wrapper">
    <div class="information-wrapper" #info>
      <div *ngIf="displayLogo" class="logo-wrapper">
        <img src="assets/images/wetrack-square.png" class="logo-image-wrapper" />
      </div>
      <div class="description-wrapper">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="responsive-flex-wrapper">
    <!-- <img src="assets/images/wetrack-loader.svg"> -->
    <div class="loading-bars-wrapper">
      <div class="loading-bar"></div>
      <div class="loading-bar"></div>
      <div class="loading-bar"></div>
      <div class="loading-bar"></div>
    </div>
  </div>
</div>
