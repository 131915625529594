import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { W3WAutocomplete } from 'src/app/modules/incidents/api/what3words/what3word_autocomplete';
import { What3WordInfo } from 'src/app/modules/incidents/models/what3WordInfo';
import { MapSearchType } from 'src/app/modules/shared/enums/maps/mapSearchType';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { GMapUtilities } from 'src/app/modules/shared/utilities/gMap.utilities';
import { ZoneViewModel } from 'src/app/modules/settings/viewModels/zoneViewModel';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { Constants } from 'src/app/modules/shared/models/constants';
import { MapsLoaderService } from 'src/app/modules/shared/services/mapsLoader.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss'],
})
export class SearchFieldComponent implements OnInit, OnDestroy {
  @ViewChild('address_search', { static: false }) addressSearchInput: ElementRef<HTMLInputElement>;
  @ViewChild('what_three_words_search', { static: false }) whatThreeWordsSearchInput: ElementRef<HTMLInputElement>;
  @ViewChild('zones_areas_search', { static: false }) zonesAreasSeachInput: ElementRef<HTMLInputElement>;

  @Input() map: google.maps.Map;
  @Input() what3PolygonColor: string;
  @Input() zones: ZoneViewModel[] = [];
  @Input() areas: AreaViewModel[] = [];
  @Input() enableAddressSearch = true;
  @Input() enableWhat3WordSearch = true;
  @Input() enableZonesAreasSearch = true;

  @Output() searchStateChanged = new EventEmitter<boolean>();
  @Output() autocompleteResult = new EventEmitter<google.maps.places.PlaceResult>();
  @Output() what3WordsResult = new EventEmitter<What3WordInfo>();

  private selectedSearchType: MapSearchType = MapSearchType.ADDRESS_PLACES;
  private what3wordsAutoCompleteInitialized = false;
  private autoCompletInitialized = false;

  private autocomplete: google.maps.places.Autocomplete = null;
  private placesAutocompleteListener: google.maps.MapsEventListener = null;

  public isMobile = false;
  public expanded = false;
  public dropdownOpen = false;
  public searchTypes = MapSearchType;

  public filteredZonesAreas: { id: number; title: string; type: number }[] = [];

  public readonly T = T;

  constructor(
    private geoLocationService: GeoLocationService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly mapsLoaderService: MapsLoaderService,
    private changeDetector: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < Constants.xs;
    this.registerEvents();
    if (this.selectedSearchType === MapSearchType.ADDRESS_PLACES) {
      if (!this.autoCompletInitialized) {
        this.initAutoComplete();
        this.autoCompletInitialized = true;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.placesAutocompleteListener) {
      google.maps.event.removeListener(this.placesAutocompleteListener);
    }

    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }

    const input = this.addressSearchInput.nativeElement;
    if (input) {
      google.maps.event.clearInstanceListeners(input);
    }

    const pacContainer = document.querySelector('.pac-container');
    if (pacContainer) {
      pacContainer.parentElement.removeChild(pacContainer);
    }
  }

  get isAddressSearchType(): boolean {
    return this.selectedSearchType === MapSearchType.ADDRESS_PLACES;
  }

  get isWhatThreeWordsSearchType(): boolean {
    return this.selectedSearchType === MapSearchType.WHAT3WORDS;
  }

  get isZonesAreasSeachType(): boolean {
    return this.selectedSearchType === MapSearchType.ZONES_AREAS;
  }

  public onSearchIconClick(_event) {
    this.expanded = true;
    this.changeDetector.detectChanges();
    this.focusInputField();

    this.searchStateChanged.emit(this.expanded);
  }

  public onDropdownIconClick(_event) {
    this.dropdownOpen = true;
    this.resetZonesAreasSearchField();
  }

  public onZoneAreaSearchInputChange(_event: KeyboardEvent, searchValue: string) {
    this.filteredZonesAreas = [];

    const trimmedValue = searchValue.trim();
    if (trimmedValue) {
      this.zones.forEach((zone) => {
        if (zone.title.includes(trimmedValue)) {
          this.filteredZonesAreas.push({
            id: zone.id,
            title: zone.title,
            type: 0,
          });
        }
      });

      this.areas.forEach((area) => {
        if (area.title.includes(trimmedValue)) {
          this.filteredZonesAreas.push({
            id: area.id,
            title: area.title,
            type: 1,
          });
        }
      });
    }
  }

  public onSearchTypeChange(_event, mapSearchType: MapSearchType) {
    this.dropdownOpen = false;
    switch (mapSearchType) {
      case MapSearchType.ADDRESS_PLACES:
        this.selectedSearchType = MapSearchType.ADDRESS_PLACES;
        if (!this.autoCompletInitialized) {
          this.initAutoComplete();
          this.autoCompletInitialized = true;
        }
        break;

      case MapSearchType.WHAT3WORDS:
        this.selectedSearchType = MapSearchType.WHAT3WORDS;
        if (!this.what3wordsAutoCompleteInitialized) {
          this.initWhat3WordAutoComplete();
          this.what3wordsAutoCompleteInitialized = true;
        }
        break;

      case MapSearchType.ZONES_AREAS:
        this.selectedSearchType = MapSearchType.ZONES_AREAS;
        break;
      case MapSearchType.ALL:
        break;
      default:
        break;
    }

    this.changeDetector.detectChanges();
    this.focusInputField();
  }

  public onZoneAreaSeachResultClicked(_event, item: { id: number; title: string; type: number }) {
    if (this.zonesAreasSeachInput) {
      if (this.zonesAreasSeachInput.nativeElement.value !== item.title) {
        this.zonesAreasSeachInput.nativeElement.value = item.title;
      }
    }

    let polygon = this.getPolygonFrom(item);
    if (polygon) {
      this.map.fitBounds(GMapUtilities.getPolygonLatLngBounds(polygon));
      polygon.setMap(null);
      polygon = null;
    }

    this.resetZonesAreasSearchField(false);
  }

  private focusInputField(): void {
    switch (this.selectedSearchType) {
      case MapSearchType.ADDRESS_PLACES:
        this.addressSearchInput.nativeElement.focus();
        break;

      case MapSearchType.WHAT3WORDS:
        this.whatThreeWordsSearchInput.nativeElement.focus();
        break;

      case MapSearchType.ALL:
        break;
    }
  }

  private registerEvents(): void {
    if (this.map !== null) {
      this.map.addListener('click', () => {
        if (this.expanded) {
          this.expanded = false;
          this.dropdownOpen = false;
          this.changeDetector.detectChanges();
          this.resetZonesAreasSearchField();
          this.searchStateChanged.emit(this.expanded);
        }
      });
    }
  }

  private initAutoComplete(): void {
    void this.mapsLoaderService.loadGoogleMapsAPIs().subscribe(() => {
      const input = this.addressSearchInput.nativeElement;
      this.autocomplete = new google.maps.places.Autocomplete(input, {});
      this.placesAutocompleteListener = this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = this.autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            this.autocompleteResult.emit(null);
          } else {
            this.autocompleteResult.emit(place);
          }
        });
      });
    });
  }

  private initWhat3WordAutoComplete() {
    const input = this.whatThreeWordsSearchInput.nativeElement;
    const autocomplete = new W3WAutocomplete(input, { apiKey: GeoLocationService.W3W_API_KEY });
    autocomplete.onResultClicked((result) => {
      this.ngZone.run(() => {
        const words = result.words;
        if (words !== input.value) {
          input.value = words;
        }

        this.getWhat3WordsFromAddress(words);
      });
    });
  }

  private getWhat3WordsFromAddress(what3words: string): void {
    this.geoLocationService.getWhat3WordsFromAddress(what3words).subscribe({
      next: (response) => {
        const what3WordInfo = this.getWhat3WordInfoFromResponse(response);
        this.what3WordsResult.emit(what3WordInfo);
      },
      error: (_error) => {},
    });
  }

  private getWhat3WordInfoFromResponse(response: any): What3WordInfo {
    const southEest = response.square.southwest;
    const northEast = response.square.northeast;
    const squareBounds = new google.maps.LatLngBounds();
    squareBounds.extend(new google.maps.LatLng(southEest.lat, southEest.lng));
    squareBounds.extend(new google.maps.LatLng(northEast.lat, northEast.lng));

    const what3SquarePolygon = new google.maps.Polygon({
      map: null,
      paths: GMapUtilities.convertLatLngBoundsToPolygonPath(squareBounds),
      fillColor: this.what3PolygonColor,
      fillOpacity: 0.3,
      strokeColor: this.what3PolygonColor,
      strokeOpacity: 1,
      strokeWeight: 1,
    });

    const what3WordInfo = {
      polygon: what3SquarePolygon,
      address: response.words,
      lat: response.coordinates.lat,
      lng: response.coordinates.lng,
      nearestPlace: response.nearestPlace,
    } as What3WordInfo;

    return what3WordInfo;
  }

  private resetZonesAreasSearchField(clearInput: boolean = true): void {
    this.filteredZonesAreas = [];

    if (this.zonesAreasSeachInput && clearInput) {
      this.zonesAreasSeachInput.nativeElement.value = '';
    }
  }

  private getPolygonFrom(item: { id: number; title: string; type: number }): google.maps.Polygon {
    let polygon: google.maps.Polygon = null;

    if (item.type === 0) {
      const zone = this.zones.find((zone) => zone.id === item.id);
      const zoneShapePointsArr = zone.zoneShapePoints;
      polygon = GMapUtilities.buildPolygonFromZoneModel(zoneShapePointsArr, null);
    } else {
      const area = this.areas.find((area) => area.id === item.id);
      const areaShapePointsArr = area.areaShapePoints;
      polygon = GMapUtilities.buildPolygonFromZoneModel(areaShapePointsArr, null);
    }

    return polygon;
  }
}
