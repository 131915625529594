import { FilterDateRangeOptions } from '../../enums/filter/filterDateRangeOptions';
import { FilterDateOptions } from '../../enums/filter/filterDateOptions';

export class FilterValueDateViewModel {
  public id: number;
  public dateProperty: FilterDateOptions;
  public dateRange: FilterDateRangeOptions;
  public fromDate: string;
  public toDate: string;
}
