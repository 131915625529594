import { ObjectTypes } from '../enums/objectTypes';

export class ObjectEventModel {
  public globalObjectType: ObjectTypes;
  public globalObjectId: number;
  public model: any;

  constructor(globalObjectId: number, globalObjectType: ObjectTypes, model: any) {
    this.globalObjectId = globalObjectId;
    this.globalObjectType = globalObjectType;
    this.model = model;
  }
}
