import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ElementRef,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CommentEventsEmitter } from 'src/app/modules/shared/events/comments.events';
import { IncidentStatuses } from 'src/app/modules/incidents/enums/incidentStatuses';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { DocumentService } from 'src/app/modules/shared/services/document.service';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { IncidentItemViewModel } from 'src/app/modules/incidents/viewModels/incidentItemViewModel';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-close-incident-modal',
  templateUrl: 'close-incident-modal.component.html',
  styleUrls: ['close-incident-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseIncidentModal implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  closeText: string = '';
  submitButtonText: string;
  incident: IncidentItemViewModel;
  description: string = '';
  showEditorButtons: boolean = false;
  attachments: UploadViewModel[] = [];
  objectType = ObjectTypes.IncidentItem;
  title: string = '';
  loading = false;
  commentAdded: boolean = false;
  newFilter: IncidentStatuses;

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly commentEventsEmitter: CommentEventsEmitter,
    private readonly documentService: DocumentService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly elemRef: ElementRef,
    private readonly translateService: TranslateService
  ) {}
  onModalClose: EventEmitter<string> = new EventEmitter<string>();
  ngOnInit(): void {
    switch(this.incident.status){
      case (IncidentStatuses.Closed):
        this.submitButtonText = this.translateService.instant(T.control.reopen);
        this.title = this.translateService.instant(T.control.reopen_comments);
        this.description =  this.translateService.instant(T.control.reopen_explanation);
        break;
      case (IncidentStatuses.Open):
        this.submitButtonText = this.translateService.instant(T.control.submit_close);
        this.title = this.translateService.instant(T.control.closing_comments);
        this.description =  this.translateService.instant(T.control.closing_explanation);
        break;
      case (IncidentStatuses.Draft):
        if(this.newFilter === IncidentStatuses.Open) {
          this.submitButtonText = this.translateService.instant(T.control.submit);
          this.title = this.translateService.instant(T.control.open_comments);
          this.description =  this.translateService.instant(T.control.draft_to_open_explanation);
          break;
        } else if(this.newFilter === IncidentStatuses.Closed) {
          this.submitButtonText =this.translateService.instant(T.control.submit_close);
          this.title = this.translateService.instant(T.control.closing_comments);
          this.description = this.translateService.instant(T.control.draft_to_closed_explanation);
          break;
        }
        break;
      default: return;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.onModalClose.next(this.closeText);
    this.bsModalRef.hide();
  }

  updateUploadViewModels(uploadViewModels: UploadViewModel[]) {
    this.attachments = [];
    this.attachments = uploadViewModels;
    this.changeDetectorRef.detectChanges();
  }
}
