<div class="errors-container">
  <div
    class="validation-error"
    *ngFor="let error of errors"
    [app-tooltip]="{ title: stripHtml(error), position: 'top' }"
  >
    <i class="material-symbols-outlined">error_outline</i>
    <span class="error-message" [innerHTML]="error"></span>
  </div>
</div>
