import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class KanbanEventsEmitter {
  private cardDragStarted = new Subject<string | number>();
  $cardDragStarted = this.cardDragStarted.asObservable();

  broadcastCardDragStarted(id: string | number) {
    this.cardDragStarted.next(id);
  }
}
