import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class PaginationManager {
  public readonly defaultItemsPerPage = 20;

  public generateID(): string {
    return uuidv4();
  }

  private readonly _paginationModels = new BehaviorSubject<PaginationModel[]>([]);
  private readonly _scrollToId = new Subject<ScrollToModel>();
  private readonly _scrollToIndex = new Subject<ScrollToIndexModel>();
  readonly paginationModels$ = this._paginationModels.asObservable();
  readonly scrolltoIndex$ = this._scrollToIndex.asObservable();
  readonly scrolltoId$ = this._scrollToId.asObservable();

  getPaginationModel(id: string) {
    const models = this._paginationModels.getValue();
    const model = models.find((m) => m.id === id);
    return model;
  }

  addModel(id: string, itemsPerPage: number = this.defaultItemsPerPage, itemsCount: number = 0, currentPage: number = 1) {
    const model = new PaginationModel(id, itemsPerPage, itemsCount, currentPage);
    let models = this._paginationModels.getValue();

    if (!models) {
      models = [];
    }

    models.push(model);

    this._paginationModels.next(models);
  }

  updateModel(model: PaginationModel) {
    let models = this._paginationModels.getValue();

    if (!models) {
      models = [];
      models.push(model);
    }

    const matching = models.find((m) => m.id === model.id);
    if (matching) {
      models[models.indexOf(matching)] = model;
    } else {
      models.push(model);
    }

    this._paginationModels.next(models);
  }

  deleteModel(id: string) {
    const models = this._paginationModels.getValue();

    if (models) {
      const matching = models.find((m) => m.id === id);
      if (matching) {
        models.splice(models.indexOf(matching), 1);
      }

      this._paginationModels.next(models);
    }
  }

  broadcastScrollToId(paginationId: string, id: number) {
    this._scrollToId.next(new ScrollToModel(paginationId, id));
  }
  broadcastScrollToIndex(paginationId: string, index: number) {
    this._scrollToIndex.next(new ScrollToIndexModel(paginationId, index));
  }
}

export class PaginationModel {
  id: string;
  itemsPerPage: number;
  itemsCount: number;
  currentPage: number;

  constructor(id: string, itemsPerPage: number, itemsCount: number, currentPage: number) {
    this.id = id;
    this.itemsPerPage = itemsPerPage;
    this.itemsCount = itemsCount;
    this.currentPage = currentPage;
  }
}

export class ScrollToModel {
  paginationId: string;
  itemId: number;

  constructor(paginationId: string, itemId: number) {
    this.paginationId = paginationId;
    this.itemId = itemId;
  }
}
export class ScrollToIndexModel {
  paginationId: string;
  index: number;

  constructor(paginationId: string, itemId: number) {
    this.paginationId = paginationId;
    this.index = itemId;
  }
}
