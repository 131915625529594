import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  OnChanges,
} from '@angular/core';
import { RAGBreakdown } from '../../../models/rag/ragBreakdown';
import { RAGStatus } from '../../../models/rag/ragStatus';

@Component({
  selector: 'app-rag-breakdown-bar',
  templateUrl: './rag-breakdown-bar.component.html',
  styleUrls: ['./rag-breakdown-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RAGBreakdownBarComponent implements OnChanges {
  @Input() ragBreakdowns: RAGBreakdown[] = [];
  @Input() rags: RAGStatus[] = [];
  @Input() displayZeroRAGs = true;
  @Input() displayRelativeValueWidth = false;
  @Input() maxRAGsSum = -1;
  @Input() displayCount: boolean = true;
  @Input() canClickRag: boolean = true;
  @Input() isWithinEventDetails: boolean = false;

  @Output() onRAGsFiltered = new EventEmitter<RAGStatus[]>();

  private relativeWidths: number[] = [];
  public isEmptyState = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ragBreakdowns?.currentValue?.length) {
      const ragBreakdown = changes.ragBreakdowns.currentValue;

      this.relativeWidths = [];
      ragBreakdown.forEach((rag: RAGBreakdown) => {
        this.relativeWidths.push(this.calculateRelativeWidth(rag.count));
      });

      this.isEmptyState = !ragBreakdown.some((rag: RAGBreakdown) => rag.count > 0);
      this.changeDetectorRef.markForCheck();
    }
  }

  calculateRelativeWidth(value: number): number {
    return (value / this.ragBreakdowns.reduce((prev, curr) => prev + curr.count, 0)) * 100;
  }

  calculateContainerRelativeWidth(): number {
    return (this.ragBreakdowns.reduce((prev, curr) => prev + curr.count, 0) / this.maxRAGsSum) * 100;
  }

  getRagOpacity(ragBreakdown: RAGBreakdown) {
    return this.rags.length === 0 || this.rags.indexOf(ragBreakdown.ragStatus) > -1 ? '100%' : '30%';
  }

  ragClicked(ragBreakdown: RAGBreakdown) {
    if (this.canClickRag) {
      if (this.rags.length === 0 || this.rags.length === this.ragBreakdowns.length) {
        this.rags = [ragBreakdown.ragStatus];
      } else if (this.rags.indexOf(ragBreakdown.ragStatus) > -1) {
        this.rags = this.rags.filter((f) => f !== ragBreakdown.ragStatus);
      } else {
        this.rags.push(ragBreakdown.ragStatus);
      }

      this.onRAGsFiltered.emit(this.rags);
    }
  }

  trackByMethod(index: number, el: RAGBreakdown): RAGStatus {
    return el.ragStatus;
  }
}
