<div class="row">
  <div
    class="col-12"
    *ngIf="!singleSelect && currentlySelectedFilterSettings && currentlySelectedFilterSettings.length"
  >
    <div class="inline-lozenges-wrapper border-bottom">
      <app-filters-inline-edit-lozenges
        [appliedFilters]="currentlySelectedFilterSettings"
        [filterType]="filterType"
        [label]="label"
        [disabled]="disabled"
        (makePrimary)="onMakePrimary($event)"
        (filterRemoved)="onFilterSettingRemoved($event)"
        [isActionsGroup]="isActionsGroup"
      >
      </app-filters-inline-edit-lozenges>
    </div>
  </div>

  <div *ngIf="useSearch" class="col-md-12">
    <app-search-dropdown-r
      [useFullBorder]="useFullBorder"
      [removeBorders]="removeBorders"
      [objects]="settings"
      [filterType]="filterType"
      [disabled]="disabled"
      [bindProperty]="'filterText'"
      [label]="label"
      [disableMargin]="disableMargin"
      [multiSelect]="!singleSelect"
      [currentlySelectedObject]="currentlySelectedSetting"
      [currentlySelectedObjects]="currentlySelectedFilterSettings"
      [compareBy]="'filterValue'"
      [typeahead]="typeahead$"
      (objectSelected)="onFilterSettingSelected($event)"
      [icon]="icon"
      [svg]="svg"
      [areAllSelected]="areAllSelected"
      (objectCleared)="onFilterSettingCleared()"
      (cancelChanges)="onCancelChanges()"
      (applyChanges)="onApplyChanges($event)"
      (selectAll)="onSelectAll()"
      (clearAll)="onClearAll()"
    >
    </app-search-dropdown-r>
  </div>
  <div *ngIf="!useSearch" class="col-md-12">
    <app-simple-dropdown *ngIf="!useRadioButtons"
      [useFullBorder]="useFullBorder"
      [removeBorders]="removeBorders"
      [objects]="settings"
      [filterType]="filterType"
      [disabled]="disabled"
      [bindProperty]="'filterText'"
      [label]="label"
      [disableMargin]="disableMargin"
      [multiSelect]="!singleSelect"
      [currentlySelectedObject]="currentlySelectedSetting"
      [currentlySelectedObjects]="currentlySelectedFilterSettings"
      [compareBy]="'filterValue'"
      [typeahead]="typeahead$"
      (objectSelected)="onFilterSettingSelected($event)"
      [icon]="icon"
      [svg]="svg"
      [areAllSelected]="areAllSelected"
      (objectCleared)="onFilterSettingCleared()"
      (cancelChanges)="onCancelChanges()"
      (applyChanges)="onApplyChanges($event)"
      (selectAll)="onSelectAll()"
      (clearAll)="onClearAll()"
      (deselectionChange)="onDeselectionChange($event)"
      [singleSelect]="singleSelect"
      [isActionsGroup]="isActionsGroup"
    >
    </app-simple-dropdown>

    <div *ngIf="useRadioButtons">
      <div class="radio-buttons-label">{{(T.common.privacy.one | translate) + '*'}}</div>
      <div class="d-flex mt-3 mb-1">
      <div *ngFor="let setting of settings">
        <app-radio-button
          class="me-4"
          [text]="setting.filterText"
          [checked]="currentlySelectedSettingValue === setting.filterValue"
          (checkedChanged)="onFilterSettingSelected(setting)"
          [disabled]="disabled"
        >
        </app-radio-button>
      </div>
      </div>
    </div>
  </div>
  <div class="helper-text d-flex text-danger" *ngIf="required && !valid">{{ validationMessage }}</div>
</div>
