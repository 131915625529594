import { Injectable } from '@angular/core';
import { StateService } from '../../planning/services/state.service';
import { RiskRAGMatrixModel } from '../viewModels/planning/riskRAGMatrix';
import { AuthenticationService } from './authentication.service';
import { RiskRAGStatuses } from '../enums/planning/riskRagStatuses';
import { PlanningSettingsService } from '../../settings/services/planning-settings.service';

@Injectable({
  providedIn: 'root',
})
export class RiskRAGMatrixService extends StateService<RiskRAGMatrixModel> {
  constructor(
    private readonly planningSettingsService: PlanningSettingsService,
    private readonly authenticationService: AuthenticationService
  ) {
    super();
  }

  public initRAGMatrix() {
    this.planningSettingsService.getRiskRagMatrix().subscribe((res) => {
      this.updateList(res);
    });
  }

  public getRAGFromImpactAndLikelihood(impact: number, likelihood: number): RiskRAGStatuses {
    let result = RiskRAGStatuses.Low;
    const ragmatrix = this._list.getValue();
    if (ragmatrix) {
      const matchingElement = ragmatrix.find((r) => r.impact == impact && r.likelihood == likelihood);
      if (matchingElement) {
        result = matchingElement.ragStatus;
      }
    }

    return result;
  }
}
