import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-card-loading',
  templateUrl: 'skeleton-card-loading.component.html',
  styleUrls: ['skeleton-card-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonCardLoadingComponent {
  @Input() count: number = 20;
  @Input() showLozenges: boolean = true;
  @Input() countLozenges: number = 3;
  @Input() showListHeader: boolean = true;
  @Input() showListHeaderRARs: boolean = true;
  @Input() largeCards: boolean = true;
  @Input() categoryCard: boolean = false;
  @Input() showMarginLeftRight: boolean = true;
}
