import { ChangeDetectionStrategy, Component, ContentChildren, QueryList, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccordionService } from '../../../services/accordion.service';
import { SettingsAccordionGroupComponent } from './settings-accordion-group/settings-accordion-group.component';

@Component({
  selector: 'app-settings-accordion',
  templateUrl: 'settings-accordion.component.html',
  styleUrls: ['settings-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsAccordionComponent implements AfterViewInit {
  private readonly subscriptions = new Subscription();
  @ContentChildren(SettingsAccordionGroupComponent) groups: QueryList<SettingsAccordionGroupComponent>;

  constructor(
    private readonly accordionService: AccordionService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.subscriptions.add(
      this.accordionService.toggleStageChanged.subscribe((res) => {
        const matchingElement = this.groups.find((e) => e.id == res.id);
        if (matchingElement) {
          this.toggleGroup(matchingElement);
        }
      })
    );

    this.subscriptions.add(
      this.accordionService.newGroupAdded.subscribe((newGroupId: any) => {


        this.accordionService.collapseAllExcept(newGroupId);
        this.cdr.detectChanges();

      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  toggleGroup(group: SettingsAccordionGroupComponent) {
    group.opened = !group.opened;
    this.cdr.markForCheck();
  }
}
