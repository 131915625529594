<ng-template #template>
  <div class="status-rows-wrapper">
    <div *ngFor="let status of statuses" class="status-row" (click)="onStatusSelected(status.key)">
      <div class="status-text" [class.selected]="status.key === currentlySelectedStatus">
        <span>{{ status.value }}</span>
        <ng-container *ngIf="status.key === currentlySelectedStatus">&nbsp;(selected)</ng-container>
      </div>
      <div class="status-icon" [style.background-color]="getColourByStatus(status.key)">
        <i class="material-symbols-outlined">{{ getIconByStatus(status.key) }}</i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messageTemplate>
  <div class="message-wrapper">
    <div class="text-danger mb-2"><i class="material-symbols-outlined mr-2">list</i>Checklist Incomplete!</div>
    <div class="mb-3">
      {{ readonlyMessage }}
    </div>
  </div>
</ng-template>

<ng-container *ngIf="isReadonly && !readonlyMessage">
  <div #wrapper class="wrapper">
    <ng-container *ngTemplateOutlet="bubbleTemplate"></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!isReadonly || readonlyMessage">
  <div
    #wrapper
    class="wrapper"
    (click)="onClick()"
    [app-popup]="
      !isReadonly
        ? {
            templateRef: template,
            title: 'Select status',
            position: popupPosition,
            arrowPosition: popupArrowPosition,
            useHeader: true,
            useFooter: true
          }
        : {
            templateRef: messageTemplate,
            title: 'Select status',
            position: popupPosition,
            arrowPosition: popupArrowPosition,
            useHeader: true,
            useFooter: false
          }
    "
  >
    <ng-container *ngTemplateOutlet="bubbleTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #bubbleTemplate>
  <div class="responsive-flex-wrapper">
    <app-bubble [large]="useLargeBubble" [color]="getColourByStatus(status)" [icon]="getIconByStatus(status)">
    </app-bubble>
  </div>
  <div class="dropdown-icon-wrapper" [style.opacity]="isReadonly ? 0.3 : 1">
    <div class="dropdown-icon"></div>
  </div>
</ng-template>
