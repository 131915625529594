<div #header class="modal-header">
  <div class="modal-header-content">
    <h4>Save Filter Settings</h4>
    <i
      class="material-symbols-outlined modal-close"
      (click)="bsModalRef.hide()"
      style="font-size: 20px; margin-right: 0; float: unset !important; right: 0; position: absolute; top: 14px"
      >close</i
    >
  </div>
</div>

<div class="modal-body modal-body-margin" #body (keydown)="onKeyDown($event)">
  <div class="row">
    <div class="col-12">
      <div class="cool-input-wrapper">
        <app-text-field [(ngModel)]="title" [placeholder]="'Title*'" [maxLength]="80" [required]="true">
        </app-text-field>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-6">
      <button class="btn btn-white btn-block filter-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="col-6">
      <button class="btn action-btn btn-block filter-button" (click)="onConfirm()">Confirm</button>
    </div>
  </div>
</div>
