import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

import * as moment from 'moment';

@Pipe({
  name: 'lastUpdatedDate',
  pure: false,
})
export class LastUpdatedDatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(dateAsString: string): string {
    const now = moment();
    const then = moment(dateAsString);
    const days = now.diff(then, 'days');
    const hours = now.diff(then, 'hours');
    const minutes = now.diff(then, 'minutes');
    const seconds = now.diff(then, 'seconds');

    if (days >= 1) {
      return this.translateService.instant(T.common.time_ago.days_ago[days > 1 ? 'many' : 'one'], { count: days });
    }
    if (hours >= 1) {
      return this.translateService.instant(T.common.time_ago.hour_ago[hours > 1 ? 'many' : 'one'], { count: hours });
    }
    if (minutes >= 1) {
      return this.translateService.instant(T.common.time_ago.minute_ago[minutes > 1 ? 'many' : 'one'], { count: minutes });
    }
    return this.translateService.instant(T.common.time_ago.seconds_ago[seconds > 1 ? 'many' : 'one'], { count: seconds });
  }
}
