<div class="bubble" [style.width]="size" [style.height]="size">
  <i
    class="material-symbols-outlined"
    [class.outlined]="outlinedIcon"
    [app-tooltip]="tooltip"
    [style.color]="color"
    [style.font-size]="size"
    >info</i
  >
</div>
