<ng-container *ngIf="filterTypeSelectorViewModel !== undefined">
  <ng-container *ngIf="canEdit">
    <app-floating-dropdown
    [singleSelect]="singleSelect"
    [filters]="allowedFilters"
    [showTitle]="showTitle"
    [appliedFilters]="localAppliedFilters"
    [filterType]="filterTypeSelectorViewModel.filterType"
    [mobile]="mobile"
    [showOnlyActive]="showOnlyActive"
    [lozengeInlineEdit]="lozengeInlineEdit"
    [isFilterRequired]="isFilterRequired"
    (filtersUpdated)="updateFilters($event)"
    ></app-floating-dropdown>
  </ng-container>
  <ng-container *ngIf="!canEdit">
    <app-filters-inline-edit-lozenges [appliedFilters]="appliedFilters"></app-filters-inline-edit-lozenges>
  </ng-container>
</ng-container>
