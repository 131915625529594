import { Injectable } from "@angular/core";
import { Configuration } from "src/app/app.constants";
import { Loader } from '@googlemaps/js-api-loader';
import { Observable, forkJoin } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class MapsLoaderService {
  private googleMapsApiLoader: Loader;
  private readonly mapConfig = this.configuration.googleMapsConfiguration;

  constructor(private readonly configuration: Configuration) {
    this.googleMapsApiLoader = new Loader({
      apiKey: this.mapConfig.key,
      version: this.mapConfig.version.toString(),
    });
  }

  public loadGoogleMapsAPIs(): Observable<any> {
    const fj = forkJoin([
      this.googleMapsApiLoader.importLibrary("maps"),
      this.googleMapsApiLoader.importLibrary("core"),
      this.googleMapsApiLoader.importLibrary("places"),
      this.googleMapsApiLoader.importLibrary("geocoding"),
      this.googleMapsApiLoader.importLibrary("marker"),
      this.googleMapsApiLoader.importLibrary("geometry"),
      this.googleMapsApiLoader.importLibrary("drawing"),
      this.googleMapsApiLoader.importLibrary("streetView"),
    ]);

    return fj;
  }

  public get mapConfiguration() {
    return this.mapConfig;
  }
}
