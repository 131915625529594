export class FilesUtilities {

  /**
   * Gets the file extention or returns undefined if no filename is provided.
   *
   * @param fileName
   * The name of the file.
   *
   * @returns
   * The extention of the file.
   * For example: "png", "txt", "exe" and so on.
   */
  public static getFileExtention(fileName: string): string | undefined {
    let ext: string;

    if(fileName) {
      const lastDot = fileName.lastIndexOf('.');
      ext = fileName.substring(lastDot + 1);
    }

    return ext;
  }

  /**
   * Gets the file extention and replaces it with proper version of it
   * which is suitable for the context of the app or returns undefined
   * if no filename is provided.
   *
   * For example if the file file extention is 'jpg' this method will return "jpeg".
   *
   * @param fileName
   * The name of the file.
   *
   * @returns
   * The extention of the file and eventually replaces it with more suitable version of it.
   * For example if the file file extention is 'jpg' this method will return "jpeg".
   */
  public static getProperFileExtention(fileName: string): string | undefined {
    let ext: string;

    if(fileName) {
      const lastDot = fileName.lastIndexOf('.');
      ext = fileName.substring(lastDot + 1);
      switch(ext) {
        case 'jpg': {
          ext = 'jpeg'
          break;
        };
        default: break;
      }
    }

    return ext;
  }
}
