import { RAGStatuses } from '../enums/ragStatuses';
import { Colors } from '../enums/colors';
import { RAGBreakdown } from '../models/rag/ragBreakdown';
import { RiskRAGStatuses } from '../enums/planning/riskRagStatuses';
import { IncidentSeverities } from '../enums/incidents/incidentSeverities';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { LocalisationService } from '../services/localisation.service';
import { FilterTypes } from '../enums/filterTypes';

export class RagHelper {
  public static setRagFilterText(
    ragStatus: number,
    filterText: string,
    bold: boolean = true,
    translateService: TranslateService
  ) {
    const colorName = RAGStatuses.translateItem(ragStatus, translateService);
    return bold ? `${colorName} - ` + `<strong>${filterText}</strong>` : `${colorName} - ${filterText}`;
  }

  public static getRagStatusObjects(
    accountID: number,
    useOnlyColor: boolean,
    translateService: TranslateService,
    localisationService: LocalisationService
  ): { value: RAGStatuses; color: string; description: string }[] {
    const ragStatuses: { value: RAGStatuses; color: string; description: string }[] = [];
    ragStatuses.push({
      value: RAGStatuses.Grey,
      description: this.getRAGDescriptionTextFromValue(
        RAGStatuses.Grey,
        accountID,
        useOnlyColor,
        translateService,
        localisationService
      ),
      color: this.getRagBoxFromValue(RAGStatuses.Grey),
    });
    ragStatuses.push({
      value: RAGStatuses.Red,
      description: this.getRAGDescriptionTextFromValue(
        RAGStatuses.Red,
        accountID,
        useOnlyColor,
        translateService,
        localisationService
      ),
      color: this.getRagBoxFromValue(RAGStatuses.Red),
    });
    ragStatuses.push({
      value: RAGStatuses.Amber,
      description: this.getRAGDescriptionTextFromValue(
        RAGStatuses.Amber,
        accountID,
        useOnlyColor,
        translateService,
        localisationService
      ),
      color: this.getRagBoxFromValue(RAGStatuses.Amber),
    });
    ragStatuses.push({
      value: RAGStatuses.Green,
      description: this.getRAGDescriptionTextFromValue(
        RAGStatuses.Green,
        accountID,
        useOnlyColor,
        translateService,
        localisationService
      ),
      color: this.getRagBoxFromValue(RAGStatuses.Green),
    });
    ragStatuses.push({
      value: RAGStatuses.Blue,
      description: this.getRAGDescriptionTextFromValue(
        RAGStatuses.Blue,
        accountID,
        useOnlyColor,
        translateService,
        localisationService
      ),
      color: this.getRagBoxFromValue(RAGStatuses.Blue),
    });
    return ragStatuses;
  }

  public static getRAGColourTextFromValue(ragStatus: RAGStatuses, translateService: TranslateService) {
    return RAGStatuses.translateItem(ragStatus, translateService);
  }

  public static getRAGColourHexFromValue(ragStatus: RAGStatuses) {
    switch (ragStatus) {
      case RAGStatuses.Grey:
        return Colors.Grey;
      case RAGStatuses.Red:
        return Colors.Red;
      case RAGStatuses.Amber:
        return Colors.Amber;
      case RAGStatuses.Green:
        return Colors.Green;
      case RAGStatuses.Blue:
        return Colors.Blue;
    }
  }

  public static getSeverityColourHexFromValue(ragStatus: IncidentSeverities) {
    switch (ragStatus) {
      case IncidentSeverities.Severity_1:
        return Colors.Green;
      case IncidentSeverities.Severity_2:
        return Colors.Yellow;
      case IncidentSeverities.Severity_3:
        return Colors.Amber;
      case IncidentSeverities.Severity_4:
        return Colors.Orange;
      case IncidentSeverities.Severity_5:
        return Colors.Red;
    }
  }

  public static getRagBoxFromValue(ragStatus: RAGStatuses | RiskRAGStatuses) {
    switch (ragStatus) {
      case RAGStatuses.Grey:
        return 'rag-box-0';
      case RAGStatuses.Red:
        return 'rag-box-1';
      case RAGStatuses.Amber:
        return 'rag-box-2';
      case RAGStatuses.Green:
        return 'rag-box-3';
      case RAGStatuses.Blue:
        return 'rag-box-4';
    }
  }

  public static getRAGDescriptionTextFromValue(
    ragStatus: RAGStatuses,
    accountID: number,
    useOnlyColor: boolean,
    translateService: TranslateService,
    localisationService: LocalisationService
  ) {
    const color = this.getRAGColourTextFromValue(ragStatus, translateService);

    if (useOnlyColor) {
      return color;
    }

    const description: string = localisationService.localiseFilterValueByFilterType(ragStatus, FilterTypes.RAG);
    return color + ' (' + description + ')';
  }

  public static getRAGDescriptionFromValue(
    ragStatus: RAGStatuses,
    accountID: number,
    translateService: TranslateService
  ) {
    let description: string = '';
    switch (ragStatus) {
      case RAGStatuses.Grey:
        description = translateService.instant(T.common.rag_description.not_started);
        break;
      case RAGStatuses.Red:
        description =
          accountID === 196
            ? translateService.instant(T.common.rag_description.escalated_late)
            : translateService.instant(T.common.rag_description.slipped);
        break;
      case RAGStatuses.Amber:
        description =
          accountID === 196
            ? translateService.instant(T.common.rag_description.issues_due)
            : translateService.instant(T.common.rag_description.slipping);
        break;
      case RAGStatuses.Green:
        description = translateService.instant(T.common.rag_description.on_track);
        break;
      case RAGStatuses.Blue:
        description = translateService.instant(T.common.rag_description.complete);
        break;
    }

    return description;
  }

  public static getRAGLocalisedDescriptionFromValue(
    ragStatus: RAGStatuses,
    localisationService: LocalisationService
  ) {
    return localisationService.localiseFilterValueByFilterType(ragStatus, FilterTypes.RAG);
  }

  public static getTotalRAGBreakdownBar(rags: RAGStatuses[]): RAGBreakdown[] {
    const list = [
      {
        ragStatus: RAGStatuses.Grey,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Grey),
        count: rags.filter((r) => r === RAGStatuses.Grey).length,
      },
      {
        ragStatus: RAGStatuses.Red,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Red),
        count: rags.filter((r) => r === RAGStatuses.Red).length,
      },
      {
        ragStatus: RAGStatuses.Amber,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Amber),
        count: rags.filter((r) => r === RAGStatuses.Amber).length,
      },
      {
        ragStatus: RAGStatuses.Green,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Green),
        count: rags.filter((r) => r === RAGStatuses.Green).length,
      },
      {
        ragStatus: RAGStatuses.Blue,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Blue),
        count: rags.filter((r) => r === RAGStatuses.Blue).length,
      },
    ];

    return list;
  }

  public static getTotalRAGBreakdownBarByRagBreakdown(rags: RAGBreakdown[]): RAGBreakdown[] {
    const list = [
      {
        ragStatus: RAGStatuses.Grey,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Grey),
        count: rags.find((r) => r.ragStatus === RAGStatuses.Grey).count,
      },
      {
        ragStatus: RAGStatuses.Red,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Red),
        count: rags.find((r) => r.ragStatus === RAGStatuses.Red).count,
      },
      {
        ragStatus: RAGStatuses.Amber,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Amber),
        count: rags.find((r) => r.ragStatus === RAGStatuses.Amber).count,
      },
      {
        ragStatus: RAGStatuses.Green,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Green),
        count: rags.find((r) => r.ragStatus === RAGStatuses.Green).count,
      },
      {
        ragStatus: RAGStatuses.Blue,
        colour: RagHelper.getRAGColourHexFromValue(RAGStatuses.Blue),
        count: rags.find((r) => r.ragStatus === RAGStatuses.Blue).count,
      },
    ];

    return list;
  }

  public static calculateAverageRag(rags: RAGStatuses[]): RAGStatuses {
    const totalRAGs = rags.length;

    const totalGreyRAGs = rags.filter((rag) => rag === RAGStatuses.Grey).length;
    const totalBlueRAGs = rags.filter((rag) => rag === RAGStatuses.Blue).length;
    const nonGreyRAGsArr = rags.filter((rag) => rag !== RAGStatuses.Grey);

    if (totalRAGs === 0) return RAGStatuses.Grey;
    if (totalBlueRAGs === totalRAGs) return RAGStatuses.Blue;
    if (totalGreyRAGs === totalRAGs) return RAGStatuses.Grey;
    if (totalGreyRAGs + totalBlueRAGs === totalRAGs) return RAGStatuses.Green;

    const total = nonGreyRAGsArr.reduce((prev, curr) => prev + curr);
    const count = nonGreyRAGsArr.length;

    if (total === 0 || count === 0) {
      return RAGStatuses.Grey;
    }

    const average = total / count;
    if (average >= 1 && average < 2) return RAGStatuses.Red;
    if (average >= 2 && average < 3) return RAGStatuses.Amber;
    if (average >= 3 && average < 4) return RAGStatuses.Green;
    if (average === 4) {
      return RAGStatuses.Blue;
    }

    return RAGStatuses.Grey;
  }
}
