<app-modal>
  <app-modal-header [title]="modalTitle" (closed)="onClose()"></app-modal-header>
  <app-modal-body>
    <div class="update-modal-body">
      <div class="comment-message-container">
        <div class="comment-message">{{ message }}</div>
      </div>
      <div class="update-comment-section">
        <div class="comment-container">
          <app-rich-text-editor
            [placeholder]="placeholderMessage"
            [commentType]="commentType"
            [objectType]="objectType"
            [objectId]="objectId"
            [relatedObjectId]="relatedObjectId"
            [showButtons]="showEditorButtons"
            [isModal]="true"
            [broadcastCommentsCountUpdated]="broadcastCommentsCountUpdated"
            (onTextChanged)="onUpdateTextChanged($event)"
            (onModalClose)="onClose()"
            [childHubAccountId]="childhubAccountId"
          >
          </app-rich-text-editor>
        </div>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer style="margin-top: 24px">
    <div class="update-modal-footer">
      <app-button-rectangle
        class="modal-close"
        [text]="T.common.cancel | translate"
        [size]="'large'"
        class="mr-8"
        [buttonTheme]="'white'"
        (clicked)="onClose()"
      ></app-button-rectangle>
      <app-button-rectangle
        class="modal-add"
        [text]="T.common.add_comment | translate"
        [size]="'large'"
        (click)="onAddUpdate()"
        [disabled]="currentComment.length === 0"
      ></app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>
