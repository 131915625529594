<div
  class="container"
  [ngStyle]="{'height': containerHeightPx > 0 ? containerHeightPx + 'px' : '100%'}">
    <div class="icon">
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="24px"
        height="24px"
        viewBox="0 0 45.354 45.354"
        style="enable-background: new 0 0 45.354 45.354"
        xml:space="preserve"
      >
        <g>
          <path
            fill="#939699"
            d="M22.677,0C12.509,0,4.266,8.243,4.266,18.411c0,9.224,11.471,21.36,16.305,26.065c1.184,1.15,3.056,1.174,4.263,0.047
            c4.863-4.533,16.254-16.211,16.254-26.113C41.087,8.243,32.845,0,22.677,0z M22.677,24.393c-4.204,0-7.61-3.406-7.61-7.609
            s3.406-7.61,7.61-7.61c4.203,0,7.608,3.406,7.608,7.61S26.88,24.393,22.677,24.393z"
          />
        </g>
      </svg>
    </div>
    <div *ngIf="emptyStateText"
      class="empty-state-text"
      [ngStyle]="{'font-size': emptyStateTextFontSize + 'px'}">
        {{ emptyStateText }}
    </div>
</div>
