import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AccountHubService } from 'src/app/modules/accountHub/services/account-hub.service';
import { T } from 'src/assets/i18n/translation-keys';
import { EditableFieldTypes } from '../../../enums/editableFieldTypes';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { LocalisationService } from '../../../services/localisation.service';
import { IconTypes } from '../../../types/iconTypes';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { IconUtilities } from '../../../utilities/icon.utilities';
import { RagHelper } from '../../../utilities/rag.utilities';
import { RouteHelper } from '../../../utilities/route.helper';
import { ModalHeaderIconType } from '../../common/modal-header/modal-header.component';
import { HubModalDataInterface } from '../interfaces/hub-modal-data.interface';
import { Account } from '../../../models/account';
import * as moment from 'moment';

@Component({
  selector: 'app-project-details-modal',
  templateUrl: './project-details-modal.component.html',
  styleUrls: ['./project-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailsModalComponent implements OnInit, HubModalDataInterface {
  @Input() object: any;
  @Input() account: Account;
  @Input() allowedFilters: FilterViewModel[] = [];
  @Input() readonly = false;
  @Input() objectType: ObjectTypes;
  @Output() objectUpdated: EventEmitter<any> = new EventEmitter();

  public readonly modalHeaderIconType = ModalHeaderIconType;
  public readonly filterTypes = FilterTypes;
  public readonly objectTypes = ObjectTypes;
  public readonly editableFieldTypes = EditableFieldTypes;
  public readonly T = T;

  // helpers
  public expectedDueDateFilterValue: string;

  public loading = false;
  public canEdit = true;
  public descriptionExpanded = false;
  public historyExpanded = false;
  public additionalDetailsExpanded = false;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly translateService: TranslateService,
    private readonly localisationService: LocalisationService,
    protected readonly authenticationService: AuthenticationService,
    private readonly accountHubService: AccountHubService
  ) {}
  protected mobileWidth: number;
  protected doubleWidthPixels: 800;
  protected wrapper: ElementRef<HTMLElement>;
  protected subscriptions: Subscription[];

  ngOnInit(): void {
    this.expectedDueDateFilterValue = this.getExpectedFilterValue();

    if (!this.account) {
      this.account.id = this.authenticationService.getCurrentAccount().id;
    }

    if (this.readonly) {
      this.canEdit = false;
    }

  }

  public close() {
    this.bsModalRef.hide();
  }

  public getExpectedFilterValue(): string {
    const filter = this.object.filters.filter(
      (r) => r.filterType == FilterTypes.Date && r.dateProperty == FilterDateOptions.Expected_Due_Date
    );

    if (filter) {
      return filter[0].filterValue;
    }
    return '';
  }

  public getFilterValue(filterType: FilterTypes): string {
    const filter = this.object.filters.find((f) => f.filterType === filterType);
    if (filter) {
      return filter.filterValue;
    }
    return '';
  }

  public get taskExpectedDue(): string {
    return ''; //this.task.filters.find(f => f.filterType === FilterTypes.)
  }

  public get ragColor(): string {
    return RagHelper.getRAGColourHexFromValue(this.object.rag);
  }

  get ragDescription() {
    return RagHelper.getRAGDescriptionFromValue(
      this.object.rag,
      this.authenticationService.getCurrentAccount().id,
      this.translateService
    );
  }

  public get projectIcon(): string {
    return IconUtilities.getSvgForIconType(+this.iconType);
  }

  public get iconType(): string {
    const iconType = IconTypes.Project;
    const iconTypeStr = EnumUtilities.items(IconTypes).find((t) => t.key === iconType).value;
    return iconTypeStr;
  }

  public get headerText() {
    return `${this.getLocalizedProject()} Quick View`;
    //return this.translateService.instant(T.common.item_details, { item: this.getLocalizedTask() });
  }

  public get dueDateIndicator(): { label: string; color: string } {
    const dueDateFilterValue = this.expectedDueDateFilterValue;
    if (!dueDateFilterValue) {
      return null;
    }

    const dueDate = moment(dueDateFilterValue);
    const today = moment();

    if (dueDate.isBefore(today)) {
      return {
        label: this.translateService.instant(T.common.overdue.one),
        color: 'var(--wt-red)'
      };
    }  else if (dueDate.isSame(today, 'day')) {
      return {
        label: this.translateService.instant(T.common.due_today),
        color: 'var(--wt-black)'
      };
    } else if (dueDate.isSame(today.add(1, 'day'), 'day')) {
      return {
        label: this.translateService.instant(T.calendar.due_tomorrow),
        color: 'var(--wt-black)'
      };
    } else if (dueDate.isAfter(today.add(1, 'day'), 'day')) {
      return {
        label: this.translateService.instant(T.calendar.due_in_count_days, { count: dueDate.diff(today, 'days') }),
        color: 'var(--wt-grey-dark)',
      };
    }
    return null;
  }

  public get ownersCount(): number {
    return this.object.filters.filter(f => f.filterType === FilterTypes.Owner).length;
  }

  public navigateToDetaislPage() {
    const route = `${this.account.url}${RouteHelper.getRoute(this.objectType)}${this.object.id}`;
    window.open(route, '_blank');
  }

  public onTitleUpdate(newTitle: string): void {
    this.object.title = newTitle;

    let titleFilte = this.object.filters.find(f => f.filterType === FilterTypes.Title);
    if(!titleFilte){

      titleFilte = new FilterViewModel();
      titleFilte.filterType = FilterTypes.Title;
      titleFilte.filterValue = newTitle;
      titleFilte.filterText = newTitle;
      titleFilte.id = `${titleFilte.filterType}:${titleFilte.filterValue}`;
      titleFilte.filterAction = FilterActionTypes.Add;

      this.object.filters.push(titleFilte);
    }
    else{
      titleFilte.filterValue = newTitle;
      titleFilte.filterText = newTitle;
      titleFilte.filterAction = FilterActionTypes.Update;
    }


      this.accountHubService
      .updateObject(this.object.id, this.object.accountId, this.objectType,[titleFilte])
      .subscribe((res) => {
        this.objectUpdated.emit(this.object);
      });


  }

  public onDescriptionUpdate(description: string): void {
    this.object.description = description;

    let filter = this.object.filters.find(f => f.filterType === FilterTypes.Description);
    if(!filter){

      filter = new FilterViewModel();
      filter.filterType = FilterTypes.Description;
      filter.filterValue = description;
      filter.filterText = description;
      filter.id = `${filter.filterType}:${filter.filterValue}`;
      filter.filterAction = FilterActionTypes.Add;

      this.object.filters.push(filter);
    }
    else{
      filter.filterValue = description;
      filter.filterText = description;
      filter.filterAction = FilterActionTypes.Update;
    }

    filter.displayForGlobalObjectType = ObjectTypes.Project;
      this.accountHubService
      .updateObject(this.object.id, this.object.accountId, this.objectType,[filter])
      .subscribe((res) => {
        this.objectUpdated.emit(this.object);
      });


  }

  public expandCollapseDescBtnClicked() {
    this.descriptionExpanded = !this.descriptionExpanded;
  }

  public updateFilters(filters: FilterViewModel[], filterType: FilterTypes) {

    const updatedFilter = filters.filter((f) => f.filterType === filterType && f.filterAction !== FilterActionTypes.None);
    const objectFilters =this.object.filters.filter(r=>r.filterType == filterType);
    const removedFilters = objectFilters.filter(r=>!filters.find(f=>f.id == r.id));

    if(removedFilters && removedFilters.length > 0){
      removedFilters.forEach(r=>r.filterAction = FilterActionTypes.Remove);
      updatedFilter.push(...removedFilters);
    }

    this.accountHubService
      .updateObject(this.object.id, this.object.accountId, this.objectType, updatedFilter)
      .subscribe((res) => {
        this.objectUpdated.emit(res);
      });
  }

  public onHistoryEntriesUpdate(count: number) {

  }

  public onDatesSaved(dates: any) {


    const startDate = dates.startDate;
    const endDate = dates.endDate;
    const dateFilters = this.object.filters.filter((f) => f.filterType === FilterTypes.Date);

    const dueDateFilter = dateFilters.find((f) => f.dateProperty == FilterDateOptions.Expected_Due_Date);
    dueDateFilter.filterValue = endDate;
    dueDateFilter.filterText = endDate;
    dueDateFilter.id = `${dueDateFilter.filterType}:${dueDateFilter.dateProperty}:${endDate}`;
    dueDateFilter.filterAction = FilterActionTypes.Add;

    this.accountHubService.updateObject(this.object.id, this.account.id, this.objectType, [dueDateFilter]).subscribe((e) => {
      dueDateFilter.filterAction = FilterActionTypes.None;
      this.objectUpdated.emit(this.object);

    });
  }

  public onItemIndexClicked(index: number) {
    if(index === 0) {
      this.additionalDetailsExpanded = !this.additionalDetailsExpanded;
    } else if (index === 1) {
      this.historyExpanded = !this.historyExpanded;
    }
  }

  private getLocalizedProject(): string {
    return this.localisationService.localiseObjectType(ObjectTypes.Project);
  }
}
