import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnInit,
} from '@angular/core';
import { WtButtonTypes } from 'src/app/modules/shared/enums/wtButtonTypes.enum';
import { ButtonSizes } from 'src/app/modules/shared/enums/styles/buttonSizes.enum';
import { ButtonTheme } from 'src/app/modules/shared/components/common/button/button.component';
import { ButtonThemes } from 'src/app/modules/shared/enums/styles/buttonThemes.enum';

@Component({
  selector: 'app-button-dropdown-simple',
  templateUrl: './button-dropdown-simple.component.html',
  styleUrls: ['./button-dropdown-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDropdownSimpleComponent implements OnInit, OnChanges {
  @Input() icon: string;
  @Input() size = ButtonSizes.Small;
  @Input() fitToContainer: boolean = false;
  @Input() buttonTheme = ButtonThemes.Primary;
  @Input() svg: string;
  @Input() dropdownWidth: number;
  @Input() dropdownOptions: object[];
  @Input() text: string;
  @Input() bindProperty: string;
  @Input() currentlySelectedOption: object;
  @Input() showSelectedOption: boolean = true;
  @Input() showBtnText: boolean = true;
  @Input() closeUponSelection: boolean = false;
  @Input() buttonType: WtButtonTypes = 'Rectangle';
  @Input() showDropdownArrow: boolean = false;

  @Output() optionSelected = new EventEmitter();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.currentlySelectedOption && this.dropdownOptions && this.dropdownOptions.length) {
      this.currentlySelectedOption = this.dropdownOptions[0];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dropdownOptions && !changes.dropdownOptions.firstChange) {
      this.changeDetectorRef.markForCheck();
    }
  }

  get getButtonText(): string {
    if (!this.showBtnText) {
      return '';
    }

    if (this.text) {
      return this.text;
    }

    if (this.showSelectedOption && this.currentlySelectedOption && this.bindProperty) {
      return this.currentlySelectedOption[this.bindProperty] as string ?? '';
    }

    return '';
  }

  get getButtonSvg() {
    if (this.showSelectedOption && this.currentlySelectedOption) {
      return this.currentlySelectedOption['svg'];
    }
    return undefined;
  }

  onDropdownItemSelected(option: object) {
    this.currentlySelectedOption = option;
    this.optionSelected.emit(option);
  }

  protected readonly ButtonSizes = ButtonSizes;
  protected readonly ButtonTheme = ButtonTheme;
  protected readonly ButtonThemes = ButtonThemes;
}
