import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../../app.constants';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { DashboardLayoutViewModel } from '../viewModels/dashboard/dashboardLayoutViewModel';

@Injectable({
  providedIn: 'root',
})
export class DashboardLayoutService {
  private actionUrl: string = '';
  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`DashboardLayout/`);
  }

  getDashboardLayoutList(moduleType: ModuleTypes): Observable<DashboardLayoutViewModel[]> {
    return this.http.get<DashboardLayoutViewModel[]>(`${this.actionUrl}List/${moduleType}`);
  }

  addDashboardLayout(moduleType: ModuleTypes, dashboardLayout: DashboardLayoutViewModel): Observable<DashboardLayoutViewModel> {
    return this.http.post<DashboardLayoutViewModel>(`${this.actionUrl}Add/${moduleType}`, dashboardLayout);
  }

  updateDashboardLayout(dashboardLayout: DashboardLayoutViewModel) {
    return this.http.put(`${this.actionUrl}Update`, dashboardLayout);
  }

  setLayoutAsDefault(moduleType: ModuleTypes, dashboardLayout: DashboardLayoutViewModel) {
    return this.http.put(`${this.actionUrl}SetAsDefault/${moduleType}/${dashboardLayout.id}`, dashboardLayout);
  }

  deleteDashboardLayout(dashboardLayoutId: number) {
    return this.http.delete(`${this.actionUrl}Delete/${dashboardLayoutId}`);
  }
}
