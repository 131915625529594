import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { EventTypeListItemViewModel } from '../viewModels/events/eventCategoryListItemViewModel';
import { EventTypeDetailsViewModel } from '../viewModels/events/eventCategoryDetailsViewModel';
import { EventViewModel } from '../viewModels/events/eventViewModel';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { LightFilterUpdateModel } from '../models/filter/LightFilterUpdateModel';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private baseUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.baseUrl = this.configuration.buildEndpoint('Events/');
  }

  addEvent(event: EventViewModel) {
    return this.http.post<EventViewModel>(`${this.baseUrl}AddEvent`, event);
  }

  updateEventLight(item: LightFilterUpdateModel) {
    return this.http.post<LightFilterUpdateModel>(`${this.baseUrl}UpdateLight`, item);
  }

  editEvent(event: EventViewModel) {
    return this.http.post<EventViewModel>(`${this.baseUrl}EditEvent`, event);
  }

  getEventDetails(eventId: number): Observable<EventViewModel> {
    return this.http.get<EventViewModel>(`${this.baseUrl}GetEventDetails/${eventId}`);
  }

  getEventList(filters: FilterViewModel[]): Observable<EventViewModel[]> {
    return this.http.post<EventViewModel[]>(this.baseUrl + 'GetEventsList', filters);
  }

  getLiveEvents(): Observable<EventViewModel[]> {
    return this.http.get<EventViewModel[]>(this.baseUrl + 'GetLiveEvents');
  }

  deleteEvents(Ids: number[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}DeleteEvents`, Ids);
  }

  getEventTypeList(): Observable<EventTypeListItemViewModel[]> {
    return this.http.get<EventTypeListItemViewModel[]>(this.baseUrl + 'GetEventTypesList');
  }

  addEventType(eventCategory: EventTypeDetailsViewModel) {
    return this.http.post<EventTypeDetailsViewModel>(`${this.baseUrl}AddEventType`, eventCategory);
  }

  editEventType(eventCategory: EventTypeDetailsViewModel) {
    return this.http.post<EventTypeDetailsViewModel>(`${this.baseUrl}EditEventType`, eventCategory);
  }

  deleteEventTypes(Ids: number[]): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}DeleteEventTypes`, Ids);
  }
}
