import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, filter, Subscription } from 'rxjs';
import { ComboBoxChip, ComboBoxDateRange, ComboBoxDropdownItem, ComboBoxLabelPosition, ComboBoxType } from '../../../types/comboBoxDropdown';
import { WindowEventsEmitter } from '../../../events/window.events';

@Component({
  selector: 'app-combo-box',
  templateUrl: './combo-box.component.html',
  styleUrls: ['./combo-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComboBoxComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('comboWrapper', { static: false }) comboWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('inputElement', { static: false }) inputElement: ElementRef<HTMLInputElement>;

  @Input() label = '';
  @Input() labelPosition: ComboBoxLabelPosition = 'TOP';
  @Input() labelWidth: number = 0;
  @Input() width: number = 0;

  @Input() placeholder = '';

  /** This property defines the select type of the combobox which: List, DatePicker, DateRangePicker... */
  @Input() comboType: ComboBoxType = 'List';

  /** ONLY ComboBoxType = List: Array with elements which will be populated in the dropdown list */
  @Input() dropdownList: ComboBoxDropdownItem[] = [];

  /** ONLY ComboBoxType = List: Html template for dropdown list items. If no template is specified the default one will be used. */
  @Input() dropdownItemTemplate: TemplateRef<HTMLElement>;

  /** ONLY ComboBoxType = List: Indicator weather the dropdown list will allow more than one item to be selected. */
  @Input() multiselect = true;

  /** ONLY ComboBoxType = List: Indicator if the user is allowed to input a string in order to filter the dropdown list */
  @Input() readonly = false;

  /** ONLY ComboBoxType = List: This property defines how the selected value(s) will be rendered on the combobox container in case of multiselection enabled */
  @Input() chipType: ComboBoxChip = 'Statistics';

  /**
   * ONLY ComboBoxType = List: This property defines the maximum width of the dropdown width container.
   * If no value is passed the width will be the same as the input element's width
   */
  @Input() dropDownMaxWidth: number = 0;

  /** ONLY ComboBoxType = DatePicker */
  @Input() date: string = null;

  /** ONLY ComboBoxType = DateRangePicker */
  @Input() startDate: string = null;
  @Input() endDate: string = null;

  /** ONLY ComboBoxType = InputText: The text which will be displayed in the input field */
  @Input() textValue = '';

  /** ONLY ComboBoxType = InputText: Indicator weather the cursor will be positioned on the input field and ready for accepting input text. */
  @Input() autofocus = false;

  @Output() listSelectionChange = new EventEmitter<ComboBoxDropdownItem>();
  @Output() inputTextChange = new EventEmitter<string>();
  @Output() dateRangeChange = new EventEmitter<ComboBoxDateRange>();
  @Output() filterCleared = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];
  private readonly debounceTime = 600;
  private animationOngoing = false;

  public dropdownListItems: ComboBoxDropdownItem[] = [];
  public dropDownListFilterValue = '';

  public dropdownVisible = false;
  public currentlyHoveredItem: ComboBoxDropdownItem;

  public selectedStartDate: string;
  public selectedEndDate: string;

  input: UntypedFormControl;
  form: UntypedFormGroup;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly windowEventsEmitter: WindowEventsEmitter
  ) {}

  ngOnInit(): void {
    if (this.comboType === 'List') {
      this.dropdownListItems = this.dropdownList.slice();
      this.validateDropdownListData();

      if (this.dropDownMaxWidth <= 0) {
        this.dropDownMaxWidth = this.width;
      }

      this.input = new UntypedFormControl(this.dropDownListFilterValue);
      this.form = new UntypedFormGroup({
        input: this.input,
      });

      this.subscriptions.push(
        this.input.valueChanges.subscribe((value: string) => {
          this.dropDownListFilterValue = value;
        })
      );
    } else if (this.comboType === 'DatePicker') {
      this.selectedStartDate = this.date ? this.date : this.startDate;
    } else if (this.comboType === 'DateRangePicker') {
      this.selectedStartDate = this.startDate;
      this.selectedEndDate = this.endDate;
    } else if (this.comboType === 'InputText') {
      this.input = new UntypedFormControl(this.textValue);
      this.form = new UntypedFormGroup({
        input: this.input,
      });

      this.subscriptions.push(
        this.input.valueChanges.pipe(debounceTime(350)).subscribe((value: string) => {
          this.inputTextChange.emit(value);
        })
      );
    }

    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.comboType === 'List') {
      if (changes.dropdownList && !changes.dropdownList.firstChange) {
        this.dropdownListItems = this.dropdownList.slice();
        this.validateDropdownListData();
      }
    } else if (this.comboType === 'InputText') {
      if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
        this.input?.setValue(changes.value.currentValue);
      }

      if (this.autofocus) {
        setTimeout(() => {
          this.inputElement.nativeElement.focus();
        });
      }
    }
  }

  get isFilterApplied(): boolean {
    if(this.isDropdownList) {
      return !!this.dropdownListItems.find(item => item.selected);
    }
    else if(this.isSingleDatePicker) {
      return !!this.selectedStartDate;
    }
    else if(this.isDateRangePicker) {
      return !!this.selectedStartDate && !!this.selectedEndDate;
    }
    else if(this.isInputText) {
      return !!this.input.value;
    }

    return false;
  }

  get selectedItemsCount(): number {
    return this.dropdownListItems.filter((item) => item.selected).length;
  }

  get selectedItem(): ComboBoxDropdownItem {
    return this.dropdownListItems.find((item) => item.selected);
  }

  get isLabelTopAligned() {
    return this.labelPosition === 'TOP';
  }

  get isDatePicker() {
    return this.comboType === 'DatePicker' || this.comboType === 'DateRangePicker';
  }

  get isSingleDatePicker() {
    return this.comboType === 'DatePicker';
  }

  get isDateRangePicker() {
    return this.comboType === 'DateRangePicker';
  }

  get isDropdownList() {
    return this.comboType === 'List';
  }

  get isInputText() {
    return this.comboType === 'InputText';
  }

  get isReadonly() {
    return this.readonly;
  }

  public initSubscriptions() {
    this.subscriptions.push(
      this.windowEventsEmitter.windowClickEventTriggered$
        .pipe(filter(() => !this.animationOngoing))
        .subscribe((e) => {
          if(this.dropdownVisible) {
            const target = e.target as HTMLElement;

            const flag =
              this.comboWrapper &&
              this.comboWrapper.nativeElement !== target &&
              !this.comboWrapper.nativeElement.contains(target);

            if(flag) {
              this.dropdownVisible = false;
              this.changeDetectorRef.detectChanges();
            }
          }
        })
    );
  }

  public onInputClick() {
    if (this.isDropdownList) {
      this.dropdownVisible = true;
    }

    this.changeDetectorRef.detectChanges();
  }

  public onDropdownClick() {
    this.dropdownVisible = !this.dropdownVisible;
    this.changeDetectorRef.detectChanges();
  }

  public onItemSelected(item: ComboBoxDropdownItem) {
    item.selected = !item.selected;
    this.dropDownListFilterValue = '';

    if (!this.multiselect) {
      const previouslySelectedItem = this.dropdownListItems.find((i) => i.selected === true && i.id !== item.id);
      if (previouslySelectedItem) {
        previouslySelectedItem.selected = false;
      }

      this.dropdownVisible = false;
      this.currentlyHoveredItem = null;
    }

    this.listSelectionChange.next(item);
    this.changeDetectorRef.detectChanges();
  }

  public onItemHover(item: ComboBoxDropdownItem) {
    this.currentlyHoveredItem = item;
  }

  public onItemUnhover() {
    this.currentlyHoveredItem = null;
  }

  public onStartDateChanged($event: string) {
    this.selectedStartDate = $event;
  }

  public onEndDateChanged($event: string) {
    this.selectedEndDate = $event;
  }

  public onDatesChanged($event: { startDate: string; endDate: string }) {
    this.selectedStartDate = $event.startDate;
    this.selectedEndDate = $event.endDate;
    this.dateRangeChange.emit($event);

  }

  public isMatchSearchText(value: string): boolean {
    if (value && this.dropDownListFilterValue) {
      const match = value.toLowerCase().includes(this.dropDownListFilterValue.toLowerCase());
      return match;
    }

    return true;
  }

  /**
   * Clears the selected item(s) and sets the data into
   * initial state.
   *
   * After this method is called the value of the combo is empty.
   */
  public clear(emitEvent = false) {
    if(this.comboType === 'List') {
      this.dropDownListFilterValue = '';

      if(this.input.value) {
        this.input.setValue('');
      }

      this.dropdownListItems.forEach(item => {
        if(item.selected) {
          item.selected = false;

          if(emitEvent) {
            this.listSelectionChange.next(item);
          }
        }
      });
    } else if(this.comboType === 'InputText') {
      this.textValue = '';

      if(this.input.value) {
        this.input.setValue('');

        if(emitEvent) {
          this.inputTextChange.emit('');
        }
      }
    } else if(this.comboType === 'DatePicker') {
      if(this.selectedStartDate) {
        this.selectedStartDate = null;

        if(emitEvent) {
          this.dateRangeChange.emit(null);
        }
      }
    } else if(this.comboType === 'DateRangePicker') {
      if(this.selectedStartDate || this.selectedEndDate) {
        this.selectedStartDate = null;
        this.selectedEndDate = null;

        if(emitEvent) {
          this.dateRangeChange.emit(null);
        }
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  /**
   * This method should be called by user's action upon clicking on
   * the clear filter button in the input field.
   *
   */
  public onFilterClearClick() {
    this.clear(false);

    this.filterCleared.emit();
  }

  /**
   * Validates the input data of the dropdown list items.
   * If we have a single select dropdown but multiple items are marked as selected
   * the function will unselect all but the first one based on the natural list order.
   */
  private validateDropdownListData() {
    if (!this.multiselect) {
      let flag = false;
      this.dropdownList.forEach((item) => {
        if (item.selected) {
          if (!flag) {
            flag = true;
          } else {
            item.selected = false;
          }
        }
      });
    }
  }

}
