import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DependentTasksService } from '../../../services/dependentTasks.service';
import { GanttEventsEmitter } from '../../../events/gantt.events';
import { CachingService } from '../../../services/caching.service';
import { DependentTask } from '../../../models/dependentTask';
import { GridSettings } from '../../../models/gridSettings';
import { DependentTasks } from '../../../models/dependentTasks';
import { Employee } from '../../../models/employee';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { FilterViewModel } from '../../../models/filter/filterViewModel';

@Component({
  selector: 'app-gantt-choose-dependency',
  templateUrl: './choose-dependency.component.html',
  styleUrls: ['./choose-dependency.component.scss'],
})
export class ChooseDependencyModalComponent implements OnInit {
  @ViewChild('tableWrapper', { static: true }) tableWrapper: ElementRef<HTMLDivElement>;

  typeahead: Subject<string> = new Subject();
  foundTasks: DependentTask[] = [];
  //TODO FILTERS
  projects: [];
  taskGroups: [];
  departments: [];
  currentlySelectedProject: any;
  currentlySelectedTaskGroup: any;
  currentlySelectedDepartment: any;

  filters: FilterViewModel[] = [];
  gridSettings: GridSettings = new GridSettings();
  sortedStateByColumn: { [key: string]: boolean } = {};

  dependentTasks: DependentTasks;
  dependentTask: DependentTask;
  context: string;
  currentEmployee: Employee;
  public localisedProject: string = 'Project';
  public localisedTaskGroup: string = 'Task Group';
  public localisedDepartment: string = 'Department';

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dependentTasksService: DependentTasksService,
    private readonly ganttEventsEmitter: GanttEventsEmitter,
    private readonly cacheService: CachingService,
    private readonly localisationService: LocalisationService,
    public ganttService: DependentTasksService
  ) {}

  ngOnInit() {
    const acc = this.cacheService.GetAccountConfiguration();
    this.currentEmployee = this.cacheService.GetEmployee(acc.accountId);
    this.localisedProject = this.localisationService.localise('Project');
    this.localisedDepartment = this.localisationService.localise('Department');
    this.localisedTaskGroup = this.localisationService.localise('Task Group');

    //TODO FILTERS
    // this.ganttService.GetDropDownValues(FilterTypes.Venue).subscribe(keyValuePairs => {
    //   this.projects = keyValuePairs.map(keyValuePair => {
    //     const fs = new FilterSetting(FilterTypes.Venue, keyValuePair.key);

    //     fs.summary = keyValuePair.value;

    //     return fs;
    //   });
    // });

    // this.ganttService.GetDropDownValues(FilterTypes.Task_System).subscribe(keyValuePairs => {
    //   this.taskGroups = keyValuePairs.map(keyValuePair => {
    //     const fs = new FilterSetting(FilterTypes.Task_System, keyValuePair.key);

    //     fs.summary =`${keyValuePair.value} (${keyValuePair.venue})`;

    //     return fs;
    //   });
    // });

    // this.ganttService.GetDropDownValues(FilterTypes.Department).subscribe(keyValuePairs => {
    //   this.departments = keyValuePairs.map(keyValuePair => {
    //     const fs = new FilterSetting(FilterTypes.Department, keyValuePair.key);

    //     fs.summary = keyValuePair.value;

    //     return fs;
    //   });
    // });

    this.typeahead.pipe(switchMap((keyword) => this.dependentTasksService.search(keyword))).subscribe((dependentTasks) => {
      this.foundTasks = dependentTasks.tasks;
      this.changeDetectorRef.markForCheck();
    });
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onProjectSelected(project: any) {
    //TODO FILTERS
    // this.currentlySelectedDepartment = null;
    // this.currentlySelectedTaskGroup = null;
    // this.onFilterSettingSelected(this.currentlySelectedProject = project);
  }

  onTaskGroupSelected(taskGroup: any) {
    //TODO FILTERS
    // this.currentlySelectedDepartment = null;
    // this.currentlySelectedProject = null;
    // this.onFilterSettingSelected(this.currentlySelectedTaskGroup = taskGroup);
  }

  onDepartmentSelected(department: any) {
    //TODO FILTERS
    // this.currentlySelectedProject = null;
    // this.currentlySelectedTaskGroup = null;
    // this.onFilterSettingSelected(this.currentlySelectedDepartment = department);
  }

  onItemCleared(fs: any): void {
    //TODO FILTERS
    // this.foundTasks = [];
    // if (this.currentlySelectedDepartment.filterType === fs.filterType) {
    //   return this.currentlySelectedDepartment = null;
    // }
    // if (this.currentlySelectedProject.filterType === fs.filterType) {
    //   return this.currentlySelectedProject = null;
    // }
    // if (this.currentlySelectedTaskGroup.filterType === fs.filterType) {
    //   return this.currentlySelectedTaskGroup = null;
    // }
  }

  scrollToTop() {
    this.tableWrapper.nativeElement.scrollTop = 0;
    this.tableWrapper.nativeElement.scrollLeft = 0;
  }

  getSortedTasks(property: string, ascending: boolean = true): DependentTask[] {
    return this.foundTasks.sort((a, b) => {
      if (ascending) {
        return a[property].localeCompare(b[property]);
      } else {
        return b[property].localeCompare(a[property]);
      }
    });
  }

  sortTasksByProperty(property: string): void {
    this.sortedStateByColumn[property] = !this.sortedStateByColumn[property];
    this.foundTasks = this.getSortedTasks(property, !this.sortedStateByColumn[property]);
  }

  onTaskSelected(task: DependentTask) {
    if (task === this.dependentTask) {
      this.dependentTask = null;
      this.context = null;

      return;
    }

    this.dependentTask = task;

    //TODO FILTERS
    // const filterSettings = new FilterSettings();
    // const fs = new FilterSetting(FilterTypes.Dependent_Task, this.dependentTask.ref);

    // filterSettings.settings.push(fs);

    // this.context = FilterSettingsHelper.toContextString(filterSettings);
  }

  onContinue() {
    this.ganttEventsEmitter.broadcastGanttTaskDependencyContextChanged(this.context);
    this.bsModalRef.hide();
  }

  localiseString(localiseText: string): string {
    return this.localisationService.localise(localiseText);
  }

  get infoNote(): string {
    return `To view ${this.getWordWithLeadingIndefiniteArticle(
      this.localiseString('objective')
    )}'s whole dependency tree, either search for the ${this.localiseString(
      'objective'
    )} by title or ref code, or use the filters (only ${this.localiseString('objectives')} with dependencies are shown).`;
  }

  get inputLabel(): string {
    return `Search for ${this.getWordWithLeadingIndefiniteArticle(this.localiseString('objective'))} or sub-${this.localiseString(
      'objective'
    )}`;
  }

  // we do not account for exceptions like "an honest" etc. (YAGNI)
  private getWordWithLeadingIndefiniteArticle(word: string) {
    const vowels = ['a', 'e', 'i', 'o', 'u'];

    if (vowels.indexOf(word[0]) !== -1) {
      return `an ${word}`;
    }

    return `a ${word}`;
  }
}
