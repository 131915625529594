import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DocumentViewModel } from 'src/app/modules/shared/viewModels/documents/documentViewModel';
import { Subscription } from 'rxjs';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { DocumentCategoryViewModel } from '../../../viewModels/documents/documentCategoryViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Configuration } from 'src/app/app.constants';
import { DocumentTypes } from 'src/app/modules/shared/enums/documentTypes';
import { ConfirmationService } from '../../../services/confirmation.service';
import { DocumentService } from '../../../services/document.service';
import { AlertService } from '../../../services/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { IncidentsManager } from '../../../managers/incidents.manager';
import { CommentEventsEmitter } from '../../../events/comments.events';
import { DocumentDetailsModalComponent } from '../../modals/document-details/document-details-modal.component';
import { VideoModalComponent } from '../../modals/video/video-modal.component';
import { SeverityViewModel } from '../../../models/severityViewModel';
import { IncidentItemSimpleModel } from 'src/app/modules/incidents/viewModels/incidentItemSimpleModel';
import { PrivacyStatuses } from '../../../enums/privacyStatuses';
import { FilterTypes } from '../../../enums/filterTypes';
import { UploadTypes } from '../../../enums/uploadTypes';
import { UrlService } from '../../../services/url.service';
import { BounceFadeInAnimation } from '../../../utilities/animations/bounceFadeIn.animation';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-document-card',
  templateUrl: 'document-card.component.html',
  styleUrls: ['document-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    // nice stagger effect when showing existing elements
    BounceFadeInAnimation.ListBounce,
    BounceFadeInAnimation.ItemBounce,
  ],
})
export class DocumentCardComponent implements OnInit, OnDestroy {
  private userId: number;
  private accountId: number;
  private bsModalRef: BsModalRef;
  private modalConfig = { backdrop: true, ignoreBackdropClick: true };
  private imageExtensions = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
  private videoExtensions = ['mp4', 'mkv', 'avi', 'wmv', 'flv', 'mpeg', 'mov'];
  private documentExtensions = ['doc', 'docx', 'pdf', 'csv', 'txt', 'xls', 'xlsx', 'xlsm'];
  private extension: string;
  private severities: SeverityViewModel[] = [];
  @Input() document: DocumentViewModel;
  @Input() uploadViewModel: UploadViewModel;
  @Input() documentCategories: DocumentCategoryViewModel[];
  @Input() isSmallScreen: boolean;
  @Input() incidentItems: IncidentItemSimpleModel[];
  @Input() isReadOnly: boolean = false;
  @Output() documentChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadDeleted: EventEmitter<UploadViewModel> = new EventEmitter<UploadViewModel>();
  @Output() uploadChanged: EventEmitter<UploadViewModel> = new EventEmitter<UploadViewModel>();
  largeSeverity: boolean = false;
  subscriptions: Subscription[] = [];
  privacyStatuses: { key: PrivacyStatuses; value: string }[] = EnumUtilities.items(PrivacyStatuses);
  privacyStatus: { key: PrivacyStatuses; value: string };
  documentType: DocumentTypes;
  uploadImage: string | ArrayBuffer;
  showLockIcon: boolean = false;
  filterTypes = FilterTypes;
  showFullScreenImage: boolean = false;
  documentTypes = DocumentTypes;

  public T = T;
  public noDescriptionTranslated: string;

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly documentService: DocumentService,
    private readonly alertService: AlertService,
    private readonly authenticationService: AuthenticationService,
    private readonly modalService: BsModalService,
    private readonly configuration: Configuration,
    private readonly incidentManager: IncidentsManager,
    private readonly commentsEventEmitter: CommentEventsEmitter,
    private readonly urlService: UrlService,
    private readonly translateService: TranslateService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  ngOnInit(): void {
    this.severities = this.incidentManager.severities;
    this.userId = this.authenticationService.getCurrentEmployee().id;
    this.accountId = this.authenticationService.getCurrentEmployee().accountId;
    this.getDocumentType();

   this.noDescriptionTranslated = this.translateService.instant(T.defaultLocalizations.description.none);

    if (this.uploadViewModel) {
      let originalTitle: string = '';
      if (this.uploadViewModel.uploadType === UploadTypes.File) {
        originalTitle = this.uploadViewModel.originalTitle;
      } else {
        originalTitle = this.uploadViewModel.link;
      }
      if (this.uploadViewModel.title == originalTitle && this.uploadViewModel.uploadType === UploadTypes.Hyperlink) {
        this.editUpload();
      }
    }

    // enable when fully functioning
    // if (this.document && this.document.privacyStatus != PrivacyStatuses.Open) {
    //     this.showLockIcon = true;
    // }
    // if (this.uploadViewModel && this.uploadViewModel.privacyStatus != PrivacyStatuses.Open) {
    //     this.showLockIcon = true;
    // }
  }

  getDocumentType() {
    if (this.document) {
      this.setExtension(this.document.uploadType, this.document.filename);
    } else {
      const fileName = this.uploadViewModel.fileName ? this.uploadViewModel.fileName : '';
      this.setExtension(this.uploadViewModel.uploadType, fileName);
    }

    if (this.imageExtensions.indexOf(this.extension) !== -1) {
      this.documentType = DocumentTypes.Image;
    } else if (this.videoExtensions.indexOf(this.extension) !== -1) {
      this.documentType = DocumentTypes.Video;
    } else if (this.documentExtensions.indexOf(this.extension) !== -1) {
      this.documentType = DocumentTypes.Document;
    }
  }

  private setExtension(uploadType: UploadTypes, fileName: string) {
    if (uploadType === UploadTypes.Hyperlink) {
      this.documentType = DocumentTypes.Link;
    } else if (fileName) {
      const splitted = fileName.split('.');
      this.extension = splitted[splitted.length - 1].toLowerCase();
    }
  }

  getDocumentIcon() {
    if (this.documentType === DocumentTypes.Link) {
      return 'link';
    } else if (this.documentType === DocumentTypes.Video) {
      return 'videocam';
    } else {
      if (this.extension === 'pdf') {
        return 'picture_as_pdf';
      } else if (this.extension === 'xls' || this.extension === 'xlsx' || this.extension === 'xlsm') {
        return 'grid_on';
      } else return 'format_align_justify';
    }
  }

  deleteDocument(document: DocumentViewModel) {
    this.confirmationService.confirmThis(
      'Are you sure you wish to delete this attachment?',
      () => {
        this.documentService.deleteDocument(document.id, document.globalObjectId, document.globalObjectType).subscribe(() => {
          this.documentChanged.next(true);
          void this.alertService.success('Document deleted successfully');
          this.commentsEventEmitter.broadcastCommentAdded(document.globalObjectId);
        });
      },
      () => {
        //handle cancel if needed
      }
    );
  }

  editDocument(document: DocumentViewModel) {
    const uploadViewModel: UploadViewModel = new UploadViewModel();
    uploadViewModel.accountId = document.accountId;
    uploadViewModel.documentID = document.id;
    uploadViewModel.description = document.description ? document.description : '';
    uploadViewModel.employeeId = document.createdById;
    uploadViewModel.privacyStatus = document.privacyStatus;
    uploadViewModel.title = document.title ? document.title : '';
    uploadViewModel.uploadType = document.uploadType;
    uploadViewModel.link = document.uploadType === UploadTypes.Hyperlink ? document.filename : '';
    uploadViewModel.documentCategoryIDs = [];
    uploadViewModel.fileName = document.filename;
    const documentCategories = this.documentCategories.filter(
      (c) => c.documentViewModels.filter((d) => d.id == document.id).length > 0
    );
    documentCategories.forEach((d) => uploadViewModel.documentCategoryIDs.push(d.id));
    const initialState = {
      title: 'Edit Attachment',
      uploadDocumentViewModel: uploadViewModel,
      documentCategories: this.documentCategories.filter(
        (c) => c.parentDocumentCategoryId === undefined || c.parentDocumentCategoryId === 0
      ),
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(DocumentDetailsModalComponent, modalParams);
    this.subscriptions.push(
      this.bsModalRef.content.onSubmit.subscribe((res: UploadViewModel) => {
        this.documentService.updateDocument(res).subscribe(() => {
          void this.alertService.success('Document updated successfully.');
          this.documentChanged.next(true);
        });
      })
    );
  }

  openVideoModal() {
    const initialState = {
      fileName: this.document.filename,
      title: this.document.title,
      id: this.document.id,
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(VideoModalComponent, modalParams);
  }

  deleteUpload() {
    this.documentService.deleteUnassignedFile(this.uploadViewModel.fileName).subscribe(() => {
      this.uploadDeleted.next(this.uploadViewModel);
    });
  }

  editUpload() {
    const initialState = {
      uploadDocumentViewModel: this.uploadViewModel,
      documentCategories: this.documentCategories.filter(
        (c) => c.parentDocumentCategoryId === undefined || c.parentDocumentCategoryId === 0
      ),
    };
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(DocumentDetailsModalComponent, modalParams);
    this.subscriptions.push(
      this.bsModalRef.content.onSubmit.subscribe((res: UploadViewModel) => {
        this.uploadChanged.next(res);
      })
    );

    this.subscriptions.push(
      this.bsModalRef.content.cancelled.subscribe((res: UploadViewModel) => {
        if (res.fileName.length) {
          this.documentService.deleteUnassignedFile(res.fileName).subscribe(() => {
            this.uploadDeleted.next(res);
          });
        } else {
          this.uploadDeleted.next(res);
        }
      })
    );
  }

  getPrivacyString(privacyStatus: PrivacyStatuses): string {
    const status = this.privacyStatuses.find((e) => e.key === privacyStatus);
    if (!status) {
      return 'n/a';
    }
    return status.value;
  }

  getItemSeverity(severity: number) {
    return this.severities.find((s) => s.severity == severity);
  }

  get showDocumentCategories() {
    if (
      this.document &&
      this.documentCategories
        .filter((c) => c.id != 0)
        .filter((c) => c.documentViewModels.filter((d) => d.id == this.document.id).length > 0).length > 0
    ) {
      return true;
    }
    if (this.uploadViewModel && this.uploadViewModel.documentCategoryIDs.length > 0) {
      return true;
    }
    return false;
  }

  showFullScreen() {
    this.showFullScreenImage = true;
  }

  hideFullScreen() {
    this.showFullScreenImage = false;
  }

  get documentUrl(): string {
    if (this.document) {
      let resourseUrl = this.document.filename;
      if (resourseUrl) {
        resourseUrl = resourseUrl.toLowerCase().startsWith(this.configuration.ResourceFolderName.toLowerCase())
          ? resourseUrl
          : `/${this.configuration.ResourceFolderName}/${this.accountId}/${resourseUrl}`;
      }
      return this.document.uploadType === UploadTypes.File
        ? this.urlService.buildResourceUrl(resourseUrl)
        : this.document.filename;
    }
    return '';
  }

  get uploadViewModelImage(): string {
    if (this.uploadViewModel) {
      let resourseUrl = this.uploadViewModel.fileName;
      if (resourseUrl) {
        resourseUrl = resourseUrl.toLowerCase().startsWith(this.configuration.ResourceFolderName.toLowerCase())
          ? resourseUrl
          : `/${this.configuration.ResourceFolderName}/${this.accountId}/${resourseUrl}`;
      }
      return this.uploadViewModel.uploadType === UploadTypes.File
        ? this.urlService.buildResourceUrl(resourseUrl)
        : this.uploadViewModel.link;
    }
    return '';
  }
}
