import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { ModuleService } from '../../../services/module.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CanCreatePipe } from '../../../pipes/can-create.pipe';
import { AuthenticationService } from '../../../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationService } from '../../../services/localisation.service';
import { ProjectAddModalComponent } from 'src/app/modules/planning/components/modals/project-add-modal/project-add-modal.component';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TaskAddModalComponent } from 'src/app/modules/planning/components/modals/task-add-modal/task-add-modal.component';
import { RiskAddModalComponent } from 'src/app/modules/planning/components/modals/risk-add-modal/risk-add-modal.component';
import { IncidentAddModalComponent } from 'src/app/modules/incidents/components/common/modals/incident-add/incident-add-modal.component';
import { IncidentLogAddModal } from 'src/app/modules/incidents/components/common/modals/incident-log-add/incident-log-add-modal.component';
import { JobAddModalComponent } from 'src/app/modules/incidents/components/common/modals/job-add/job-add-modal.component';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { HeaderFiltersService } from '../../../services/header-filters.service';
import { Subscription } from 'rxjs';
import { SideFiltersService } from '../../../services/side-filters.service';
import { ActionRiskAddModalComponent } from 'src/app/modules/planning/components/modals/action-risk-add-modal/action-risk-add-modal.component';
import { Constants } from '../../../models/constants';
import { UtilitiesService } from '../../../services/utilities.service';
import { NotificationsService } from '../../../services/notifications.service';
import { RiskAssessmentAddModalComponent } from 'src/app/modules/risk/components/assessments/risk-assessment-add-modal/risk-assessment-add-modal.component';
import { IndicatorTemplateAddModalComponent } from 'src/app/modules/accountHub/components/modals/indicator-template-add-modal/indicator-template-add-modal.component';
import { IndicatorAddModalNewComponent } from 'src/app/modules/sustainability/components/modals/indicator-add-modal-new/indicator-add-modal-new.component';
import {
  RiskProfileAddModalComponent
} from '../../../../risk/components/risk-profiles/risk-profile-add-modal/risk-profile-add-modal.component';

@Component({
  selector: 'app-top-list-header',
  templateUrl: './top-list-header.component.html',
  styleUrls: ['./top-list-header.component.scss']
})
export class TopListHeaderComponent implements OnInit, OnDestroy {
  @Input() useSearch: boolean = true;
  @Input() showAddForObjectTypes: ObjectTypes[] = [];
  @Input() showFixedFilters: boolean = false;
  @Input() showGlobalFilters: boolean = false;
  @Input() hideAddBtn: boolean = false;

  public readonly T = T;
  public readonly moduleTypes = ModuleTypes;
  public readonly objectTypes = ObjectTypes;
  public readonly filterTypes = FilterTypes;
  public hasAnyAddPermissions = false;
  public isSearchMode = false;
  public searchString = '';
  public appliedFilters: FilterViewModel[] = [];
  public isMobile = false;
  public isSidebarExpanded = false;
  public pendingNotifications = 0;

  private subscriptions = new Subscription();
  private readonly riskModalConfig: ModalOptions = { class: 'modal-medium-add', ignoreBackdropClick: true };
  private readonly planningModalConfig: ModalOptions = { class: 'modal-large-add', ignoreBackdropClick: true };
  private readonly incidentModalConfig: ModalOptions = { backdrop: 'static', keyboard: false};
  private readonly logModalConfig: ModalOptions = { backdrop: true, ignoreBackdropClick: true };
  private readonly jobModalConfig: ModalOptions = { class: 'modal-full-screen', ignoreBackdropClick: true };
  private readonly organisationModalConfig: ModalOptions = { ignoreBackdropClick: true, class: 'modal-double-width', initialState: {organisationId: 0} };
  public availableAddButtons: {localisedItem: string, handler: () => void, hasAddPermissions: boolean}[] = [];

  private addButtonConfig: Map<ObjectTypes, {localisedItem: string, handler: () => void, hasAddPermissions: boolean}[]> = new Map([
    [ObjectTypes.Project, [{
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.Project),
      handler: () => this.modalService.show(ProjectAddModalComponent, this.planningModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Planning, FilterTypes.Project)
      }]
    ],
    [ObjectTypes.Task, [{
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.Task),
      handler: () => this.modalService.show(TaskAddModalComponent, this.planningModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Planning, FilterTypes.Task)
      }]
    ],
    [ObjectTypes.Risk, [{
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.Risk),
      handler: () => this.modalService.show(RiskAddModalComponent, this.riskModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Risk, FilterTypes.Risk)
      }]
    ],
    [ObjectTypes.Risk_Assesment, [{
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.Risk_Assesment),
      handler: () => this.modalService.show(RiskAssessmentAddModalComponent, this.jobModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Risk, FilterTypes.Risk)
      }]
    ],
    [ObjectTypes.Risk_Profile, [{
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.Risk_Profile),
      handler: () => this.modalService.show(RiskProfileAddModalComponent, this.logModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Risk, FilterTypes.Risk)
      }]
    ],
    [ObjectTypes.Risk_Action_Item, [{
      localisedItem: this.translateService.instant(T.defaultLocalizations.action_item.one),
      handler: () => this.modalService.show(ActionRiskAddModalComponent, this.planningModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Risk, FilterTypes.Risk_Action_Item)
      }]
    ],
    [ObjectTypes.IncidentItem, [
      {
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.IncidentItem),
      handler: () =>  this.modalService.show(IncidentAddModalComponent, this.incidentModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Incidents, FilterTypes.Incident)
      },
      {
      localisedItem: this.translateService.instant(T.defaultLocalizations.log.one),
      handler: () =>  this.modalService.show(IncidentLogAddModal, this.logModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Incidents, FilterTypes.Incident)
      },
      ]
    ],
    [ObjectTypes.Job, [{
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.Job),
      handler: () => this.modalService.show(JobAddModalComponent, this.jobModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Incidents, FilterTypes.Job)
    }]],
    [ObjectTypes.Indicator, [{
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.Indicator),
      handler: () => this.modalService.show(IndicatorAddModalNewComponent, this.jobModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Sustainability, FilterTypes.Indicator)
    }]],
    [ObjectTypes.Indicator_Template, [{
      localisedItem: this.localisationService.localiseObjectType(ObjectTypes.Indicator_Template),
      handler: () => this.modalService.show(IndicatorTemplateAddModalComponent, this.jobModalConfig),
      hasAddPermissions: this.canCreatePipe.transform(ModuleTypes.Sustainability, FilterTypes.Indicator_Template)
    }]]
  ]);

  constructor(
    private readonly moduleService: ModuleService,
    private readonly router: Router,
    private readonly canCreatePipe: CanCreatePipe,
    private readonly cdr: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService,
    private readonly localisationService: LocalisationService,
    private readonly modalService: BsModalService,
    private readonly route: ActivatedRoute,
    private readonly headerFiltersService: HeaderFiltersService,
    private readonly sideFiltersService: SideFiltersService,
    private readonly utilitiesService: UtilitiesService,
    private readonly notificationsService: NotificationsService,
    ) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < Constants.sm;
    this.availableAddButtons = this.showAddForObjectTypes.reduce((acc, objectType) => {
      const addButtonConfig = this.addButtonConfig.get(objectType);
      if (addButtonConfig) {
        acc.push(...addButtonConfig.filter(({hasAddPermissions}) => hasAddPermissions));
      }
      return acc;
    }, [] as {localisedItem: string, handler: () => void, hasAddPermissions: boolean}[])

    this.appliedFilters = this.headerFiltersService.currentFilters;
    this.subscriptions.add(
      this.headerFiltersService.filtersChanged$.subscribe((filters) => {
        this.appliedFilters = filters;
        this.cdr.detectChanges();
      })
    );

    this.subscriptions.add(
      this.utilitiesService.sidebarToggled$.subscribe((isToggled) => {
        this.isSidebarExpanded = isToggled;
        this.cdr.markForCheck();
      })
    )

    this.subscriptions.add(
      this.notificationsService.unreadNotificationsCount.subscribe((res) => {
        this.pendingNotifications = res;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  showSearch() {
    this.searchString = this.route.snapshot.queryParamMap.get('search');
    this.isSearchMode = true;
  }

  hideSearch() {
    this.isSearchMode = false;
    this.searchString = undefined;
  }

  onSearch() {
    let searchInModule = '';
    const planningObjectTypes = [ObjectTypes.Project, ObjectTypes.Task, ObjectTypes.Risk];
    const incidentObjectTypes = [ObjectTypes.IncidentItem, ObjectTypes.Job];
    if (this.showAddForObjectTypes.some((objectType) => planningObjectTypes.includes(objectType))) {
      searchInModule = ModuleTypes.Planning.toString();
    } else if (this.showAddForObjectTypes.some((objectType) => incidentObjectTypes.includes(objectType))) {
      searchInModule = ModuleTypes.Incidents.toString();
    } else {
      searchInModule = ModuleTypes.Planning.toString();
    }
    void this.router.navigate(['/v2/search'], { queryParams: { search: this.searchString, searchIn: searchInModule } });
  }

  handleAddButtonClick(event: Event): void {
    event.stopPropagation();
    if (this.availableAddButtons.length === 1) {
      this.availableAddButtons[0].handler();
    }
  }

  public onResetFilters(): void {
    this.headerFiltersService.setCurrentFilters([]);
  }

  public openSideFilters(): void {
    this.sideFiltersService.toggleSideFilters('open');
  }

  public toggleSideNav(e?: Event) {
    e?.stopPropagation();
    this.utilitiesService.broadcastSidebarToggle(!this.isSidebarExpanded);
  }
}
