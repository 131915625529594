export const dateFormatOptions = [
  { key: 'dd/MM/yyyy' } as DateFormatOption,
  { key: 'MM/dd/yyyy' } as DateFormatOption,
  { key: 'yyyy/MM/dd' } as DateFormatOption,
];

export type DateFormatOption = {
  key: string;
};

export const parseEuropeanDateString = (euFormatDateString: string): Date => {
  const dateElements = euFormatDateString.split('/');
  const day = dateElements.shift();
  const month = dateElements.shift();
  const year = dateElements.shift();

  return new Date(`${month}/${day}/${year}`);
};

const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const days: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const formatDate: (date: Date, format: string) => string = (date, format) => {
  const secondFormats: string[] = ['s', 'ss'].sort((a, b) => b.length - a.length);
  const minuteFormats: string[] = ['m', 'mm'].sort((a, b) => b.length - a.length);
  const hourFormats: string[] = ['H', 'HH'].sort((a, b) => b.length - a.length);
  const dayFormats: string[] = ['dd', 'ddd', 'dddd'].sort((a, b) => b.length - a.length);
  const monthFormats: string[] = ['MM', 'MMM', 'MMMM'].sort((a, b) => b.length - a.length);
  const yearFormats: string[] = ['yyyy'].sort((a, b) => b.length - a.length);

  secondFormats.forEach((sf) => {
    const regex = new RegExp(sf, 'g');

    switch (sf) {
      case 'ss':
        let seconds = date.getSeconds().toString();
        let length = seconds.length;

        if (!--length) {
          seconds = `0${seconds}`;
        }

        format = format.replace(regex, seconds);
        break;
      case 's':
        format = format.replace(regex, date.getSeconds().toString());
        break;
      default:
        return;
    }
  });

  minuteFormats.forEach((mf) => {
    const regex = new RegExp(mf, 'g');

    switch (mf) {
      case 'mm':
        let minutes = date.getMinutes().toString();
        let length = minutes.length;

        if (!--length) {
          minutes = `0${minutes}`;
        }

        format = format.replace(regex, minutes);
        break;
      case 'm':
        format = format.replace(regex, date.getMinutes().toString());
        break;
      default:
        return;
    }
  });

  hourFormats.forEach((hf) => {
    const regex = new RegExp(hf, 'g');

    switch (hf) {
      case 'HH':
        let hours: string = date.getHours().toString();
        let length: number = hours.length;

        if (!--length) {
          hours = `0${hours}`;
        }

        format = format.replace(regex, hours);
        break;
      case 'H':
        format = format.replace(regex, date.getHours().toString());
        break;
      default:
        return;
    }
  });

  dayFormats.forEach((df) => {
    const regex = new RegExp(df, 'g');

    switch (df) {
      case 'dddd':
        format = format.replace(regex, days[date.getDay()]);
        break;
      case 'ddd':
        format = format.replace(regex, days[date.getDay()].substr(0, df.length));
        break;
      case 'dd':
        const day = date.getDate();
        let dayString = day.toString();
        let length: number = dayString.length;

        if (!--length) {
          dayString = `0${dayString}`;
        }

        format = format.replace(regex, dayString);
        break;
      default:
        return;
    }
  });

  monthFormats.forEach((mf) => {
    const regex = new RegExp(mf, 'g');

    switch (mf) {
      case 'MMMM':
        format = format.replace(regex, months[date.getMonth()]);
        break;
      case 'MMM':
        format = format.replace(regex, months[date.getMonth()].substr(0, mf.length));
        break;
      case 'MM':
        let month = date.getMonth();
        let monthString = (++month).toString();
        let length: number = monthString.length;

        if (!--length) {
          monthString = `0${monthString}`;
        }

        format = format.replace(regex, monthString);
        break;
      default:
        return;
    }
  });

  yearFormats.forEach((yf) => {
    const regex = new RegExp(yf, 'g');

    switch (yf) {
      case 'yyyy':
        format = format.replace(regex, date.getFullYear().toString());
        break;
      default:
        return;
    }
  });

  return format;
};

type TimeObject = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const SECOND_IN_MS = 1000;
export const MINUTE_IN_MS = SECOND_IN_MS * 60;
export const HOUR_IN_MS = MINUTE_IN_MS * 60;
export const DAY_IN_MS = HOUR_IN_MS * 24;

export const convertToTimeObject = (miliseconds: number): TimeObject => {
  return {
    days: miliseconds / DAY_IN_MS,
    hours: miliseconds / HOUR_IN_MS,
    minutes: miliseconds / MINUTE_IN_MS,
    seconds: miliseconds / SECOND_IN_MS,
  };
};

export const convertToRelativeTimeObject = (miliseconds: number): TimeObject => {
  const timeObject = convertToTimeObject(miliseconds);
  timeObject.days = Math.abs(timeObject.days);

  return {
    days: Math.floor(timeObject.days),
    hours: Math.abs(Math.floor(timeObject.hours - Math.floor(timeObject.days) * 24)),
    minutes: Math.abs(Math.floor(timeObject.minutes - Math.floor(timeObject.hours) * 60)),
    seconds: Math.abs(Math.floor(timeObject.seconds - Math.floor(timeObject.minutes) * 60)),
  };
};

export const getDatesDifferenceInDays = (a: Date, b: Date) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.abs(Math.floor((utc2 - utc1) / DAY_IN_MS));
};

export const getDatesDifferenceInYears = (a: Date, b: Date): number => {
  return a.getFullYear() - b.getFullYear();
};

export const isDateValid = (date: Date) => {
  return !isNaN(date.getTime());
};

export const getTimezoneOffset = (date: Date) => {
  return date.getTimezoneOffset() * 60000;
};

export const isDateAWeekend = (date: Date) => {
  if(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  return false;
}

declare global {
  interface Date {
    toTimelessDate(): Date;
  }
}

Date.prototype.toTimelessDate = function () {
  return new Date(Date.prototype.toDateString.call(this));
};
