import { GMapUtilities } from '../utilities/gMap.utilities';

export class ShapePointViewModel {
  private id: number;
  latitude: number;
  longitude: number;

  constructor(lat: number, lng: number) {
    this.latitude = lat;
    this.longitude = lng;
  }

  public getLat(): number {
    return this.latitude;
  }

  public getLng(): number {
    return this.longitude;
  }

  public distanceTo(anotherLatLng: ShapePointViewModel) {
    return GMapUtilities.distanceBetweenShapePoints(this, anotherLatLng);
  }
}
