<div class="check-card-container">
  <div class="card-inner big">
    <div class="card-top">
      <div class="card-top-left">
        <div class="draggable-wrapper" *ngIf="draggable">
          <div class="responsive-flex-wrapper">
            <div class="material-symbols-outlined">drag_handle</div>
          </div>
        </div>
        <div class="titles">
          <div class="primary-title" [app-tooltip]="{ message: item.title, position: ['top'] }">
            {{ item.title }}
            <ng-container *ngIf="item.starred">
              <i class="material-symbols-outlined star-icon">star</i> {{ T.common.required.one | translate }}
            </ng-container>
          </div>
          <div class="sub-title" *ngIf="item.description">
            <div>{{ item.description | htmlString }}</div>
          </div>
        </div>
      </div>
      <div class="card-top-right">
        <div
          class="more-button"
          [closeUponSelection]="true"
          ngDropdown
          [templateRef]="moreButtonTemplate"
          (click)="$event.stopPropagation()"
        >
          <i class="material-symbols-outlined check-icon">more_horiz</i>
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div *ngIf="item.responseType === responseTypes.Mark_as_Complete">
        <div class="checkbox-container" (click)="$event.stopPropagation()">
          <div class="checkbox">
            <app-checkbox-with-label
              (clicked)="onCheckComplete($event)"
              [intialState]="item.completed ? 'checked' : 'unchecked'"
              [disabled]="loading"
              [label]="T.control.mark_as_complete | translate"
            >
            </app-checkbox-with-label>
          </div>
        </div>
      </div>
      <div *ngIf="item.responseType === responseTypes.Text_Response">
        <app-rich-text-editor-v2
          [disabled]="loading"
          [inputText]="item.response"
          [showUndoAndRedo]="false"
          [showProfileImage]="false"
          [disabled]="!userHasWriteAccess"
          [placeholder]="'Write a response...'"
          (saved)="saveInputResponse($event)"
        >
        </app-rich-text-editor-v2>
      </div>
      <div *ngIf="item.responseType === responseTypes.Numeric_Response">
        <app-text-field
          [(ngModel)]="item.response"
          [placeholder]="'Numeric Response'"
          [restrictedCharacters]="['e', 'E', '+', '-']"
          [disabled]="loading"
          type="number"
          (blur)="saveNumericResponse()">
        </app-text-field>
      </div>
      <div class="d-flex" *ngIf="item.responseType === responseTypes.Options_Set_1">
        <ng-container *ngFor="let optionSet1Item of optionSet1Items">
          <app-button-rectangle
            class="option-btn"
            [ngClass]="optionSet1Item === optionSet1Items[2] ? 'mr-0' : ''"
            [text]="optionSet1Item.value"
            [disabled]="loading"
            [buttonTheme]="optionSet1Item.value.toLowerCase()"
            [selected]="item.response === optionSet1Item.key.toString()"
            (clicked)="selectType(optionSet1Item)"
            [fitToContainer]="true"
          >
          </app-button-rectangle>
        </ng-container>
      </div>
      <div class="d-flex" *ngIf="item.responseType === responseTypes.Options_Set_2">
        <ng-container *ngFor="let optionSet2Item of optionSet2Items; let i = index">
          <app-button-rectangle
            class="option-btn"
            [ngClass]="optionSet2Item === optionSet2Items[2] ? 'mr-0' : ''"
            [text]="optionSet2Item.value"
            [disabled]="loading"
            [buttonTheme]="optionSet1Items[i].value.toLowerCase()"
            [selected]="item.response === optionSet2Item.key.toString()"
            (clicked)="selectType(optionSet2Item)"
            [fitToContainer]="true"
          >
          </app-button-rectangle>
        </ng-container>
      </div>
      <div class="d-flex" *ngIf="showTextResponseField">
        <app-rich-text-editor-v2
          [disabled]="loading"
          [inputText]="item.textResponse"
          [showUndoAndRedo]="false"
          [showProfileImage]="false"
          [disabled]="!userHasWriteAccess"
          [placeholder]="'Write a response...'"
          (saved)="saveTextResponse($event)"
          class="w-100"
        >
        </app-rich-text-editor-v2>
      </div>
    </div>
    <div *ngIf="isMobile" class="mobile-owner-lozenge">
      <app-card-lozenge
        *ngIf="showOwnerLozenge && hasOwner"
        [class.mr-16]="item.responseType === responseTypes.Mark_as_Complete || item.starred"
        [canEdit]="userHasWriteAccess"
        [objectType]="objectType"
        [allAppliedFilters]="filterSettings"
        [filters]="filterSettings"
        [filterTypeSelector]="ownerFilterTypeSelector"
        (lozengeUpdates)="updateFilters($event)"
      >
      </app-card-lozenge>
    </div>
  </div>

  <ng-template #moreButtonTemplate>
    <div class="dropdown-options-container">
      <div class="dropdown-option" (click)="openChecklistModal(item)">{{ T.control.view_check | translate }}</div>
    </div>
  </ng-template>
</div>
