import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EmployeeSettingViewModel } from '../viewModels/employeeSettingViewModel';
import { DashboardWidgetComponent } from '../models/dashboardWidgetComponent';
import { DashboardWidgetFiltersViewModel } from '../viewModels/dashboard/dashboardWidgetFiltersViewModel';

@Injectable()
export class DashboardEventEmitters {
  private readonly countdownSettingsChanged = new Subject<void>();
  private readonly taskRAGBreakdownOptionsChanged = new Subject<void>();
  private readonly locationSettingsChanged = new Subject<void>();
  private readonly infoSettingsChanged = new Subject<void>();
  private readonly capabilityBreakdownOptionsChanged = new Subject<void>();
  private readonly temperatureSettingChanged = new Subject<EmployeeSettingViewModel>();
  private readonly dashboardWidgetComponentRemoved = new Subject<DashboardWidgetComponent>();
  private readonly dashboardWidgetComponentAdded = new Subject<DashboardWidgetComponent>();
  private readonly dashboardWidgetComponentsLoaded = new Subject<DashboardWidgetComponent[]>();
  private readonly gridLayoutResetHappened = new Subject<void>();
  private readonly widgetFilterChanged = new Subject<DashboardWidgetFiltersViewModel>();

  countdownSettingsChanged$ = this.countdownSettingsChanged.asObservable();
  taskRAGBreakdownOptionsChanged$ = this.taskRAGBreakdownOptionsChanged.asObservable();
  locationSettingsChanged$ = this.locationSettingsChanged.asObservable();
  infoSettingsChanged$ = this.infoSettingsChanged.asObservable();
  capabilityBreakdownOptionsChanged$ = this.capabilityBreakdownOptionsChanged.asObservable();
  temperatureSettingChanged$ = this.temperatureSettingChanged.asObservable();
  dashboardWidgetComponentRemoved$ = this.dashboardWidgetComponentRemoved.asObservable();
  dashboardWidgetComponentAdded$ = this.dashboardWidgetComponentAdded.asObservable();
  dashboardWidgetComponentsLoaded$ = this.dashboardWidgetComponentsLoaded.asObservable();
  gridLayoutResetHappened$ = this.gridLayoutResetHappened.asObservable();
  widgetFilterChanged$ = this.widgetFilterChanged.asObservable();

  broadcastCountdownSettingsChanged() {
    this.countdownSettingsChanged.next();
  }

  broadcastTaskRAGBreakdownOptionsChanged() {
    this.taskRAGBreakdownOptionsChanged.next();
  }

  broadcastLocationSettingsChanged() {
    this.locationSettingsChanged.next();
  }

  broadcastInfoSettingsChanged() {
    this.infoSettingsChanged.next();
  }

  broadcastCapabilityBreakdownOptionsChanged() {
    this.capabilityBreakdownOptionsChanged.next();
  }

  broadcastTemperatureSettingChanged(setting: EmployeeSettingViewModel) {
    this.temperatureSettingChanged.next(setting);
  }

  broadcastDashboardWidgetComponentRemoved(dashboardWidgetComponentRemoved: DashboardWidgetComponent) {
    this.dashboardWidgetComponentRemoved.next(dashboardWidgetComponentRemoved);
  }

  broadcastDashboardWidgetComponentAdded(dashboardWidgetComponentAdded: DashboardWidgetComponent) {
    this.dashboardWidgetComponentAdded.next(dashboardWidgetComponentAdded);
  }

  broadcastDashboardWidgetComponentsLoaded(dashboardComponents: DashboardWidgetComponent[]) {
    this.dashboardWidgetComponentsLoaded.next(dashboardComponents);
  }

  broadcastGridLayoutResetHappened() {
    this.gridLayoutResetHappened.next();
  }

  broadcastWidgetFilterChange(dashboardWidgetFilters: DashboardWidgetFiltersViewModel) {
    this.widgetFilterChanged.next(dashboardWidgetFilters);
  }
}
