import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ProjectDetailsViewModel } from '../../../viewModels/projects/projectDetailsViewModel';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { AddModalButtonOptions } from 'src/app/modules/shared/enums/addModalButtonOptions.enum';
import { AddModalKeyboardShortcuts } from 'src/app/modules/shared/enums/addModalKeyboardShortcuts.enum';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProjectService } from '../../../services/projects-service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { Router } from '@angular/router';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { PrivacyStatuses } from 'src/app/modules/shared/enums/privacyStatuses';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';
import { FilterDropdownNewComponent } from 'src/app/modules/shared/components/filters-rebuild/filter-dropdown-new/filter-dropdown-new.component';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';

@Component({
  selector: 'app-project-add-modal',
  templateUrl: './project-add-modal.component.html',
  styleUrls: ['./project-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectAddModalComponent implements OnInit, OnDestroy {
  @ViewChild('dropDown') dropDown: FilterDropdownNewComponent;

  /**
   * When the user clicks the close button this event is fired.
   *
   * NOTE!!!
   * This event MUST NOT be confused with closing the modal
   * so in order for all the callbacks subscribed to
   * this event to work correctly it shouldn't be called INSIDE any function
   * which closes the modal.
   */
  @Output() cancel = new EventEmitter<void>();

  project = new ProjectDetailsViewModel();
  filterTypes = FilterTypes;
  filters: FilterViewModel[] = [];
  addModalButtonOptions = AddModalButtonOptions;

  public employee: Employee;
  public form: UntypedFormGroup;
  public isHiddenOptionVisible: boolean = false;
  public editableFieldTypes = EditableFieldTypes;
  public keyboardShortcuts = AddModalKeyboardShortcuts.getKeyShortsAsObj(this.translateService);
  public buttonOptions = AddModalButtonOptions.getOptions(this.translateService);
  public dateReseter: boolean = false;
  public isLoading: boolean = false;
  public mobileMaxWidth: number = Constants.sm;
  public triggerErrors: boolean = false;
  public validationErrors: string[];
  public localisedProject: string = 'Project';
  public localisedDepartment: string = 'Department';
  public localisedTask: string = 'Task';
  public selectedButtonOption: number = 0;
  public referrer: string = '';

  projectSvg = IconUtilities.getSvgForFilterType(FilterTypes.Project, 24, true, '#939699');
  public readonly T = T;

  private allowedFilters: FilterViewModel[];
  private subscriptions = new Subscription();
  private isTouched: boolean = false;
  objectTypes = ObjectTypes;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        if (event.shiftKey) {
          this.handleAction(
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          );
          return;
        }
        this.handleAction(
          AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
        );
        return;
      }
      this.handleAction(AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService));
      return;
    }
  }
  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    if (event.target.innerWidth < this.mobileMaxWidth) {
      this.isHiddenOptionVisible = true;
    }
  }

  constructor(
    private bsModalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private projectService: ProjectService,
    private authenticationService: AuthenticationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private router: Router,
    private elemRef: ElementRef,
    private confirmationService: ConfirmationService,
    private localisationService: LocalisationService,
    private readonly alertService: AlertService,
    private readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.localisedProject = this.localisationService.localiseObjectType(ObjectTypes.Project);
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);
    this.localisedTask = this.localisationService.localiseObjectType(ObjectTypes.Task);
    if (window.innerWidth < this.mobileMaxWidth) {
      this.isHiddenOptionVisible = true;
    }

    this.form = this.fb.group({
      title: [
        '',
        {
          validators: [Validators.required, this.wtValidators.title(3, 250), this.wtValidators.restrictedChars([';', '!', '<'])],
          updateOn: 'blur'
        }
      ],
      refCode: ['', { validators: [Validators.maxLength(12), this.wtValidators.restrictedChars([';'])], updateOn: 'blur' }],
      expectedDueDate: [''],
      description: ['', Validators.maxLength(1000)]
    });

    this.subscriptions.add(
      this.form.valueChanges.subscribe((res) => {
        this.isTouched = true;
      })
    );

    this.initAllowedFilters();

    if (this.referrer) {
      if (this.referrer === 'gantt') {
        this.buttonOptions = this.buttonOptions.filter(
          (o) => o != AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
        );
        this.selectedButtonOption = 1;
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initAllowedFilters() {
    // Load them on ngOnInit (in the beggining) so when the client click on "Assign Me" the assign of the filter will be instant;
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();

    const privacyFilters = this.filters.find((a) => a.filterType == FilterTypes.Project_Privacy_Status);
    if (!privacyFilters) {
      const fs = new FilterViewModel();
      fs.filterType = FilterTypes.Project_Privacy_Status;
      fs.filterValue = PrivacyStatuses.Open;
      fs.displayForGlobalObjectType = ObjectTypes.Project;
      this.filters.push(fs);
    }

    this.filters = FilterUtilities.SetFilterValue(this.filters, FilterTypes.Project_Status, 1, ObjectTypes.Project);
  }

  get currentFilters() {
    return [...this.filters];
  }

  setAccountForCurrentOwner() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (s: any) => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter !== null && this.filters.indexOf(empFilter) < 0) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  setMyDepartment() {
    this.dropDown.onFilterSettingCleared();

    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (f) => f.filterType == FilterTypes.Department && f.filterValue.toString() == this.employee.departmentId.toString()
      );
      if (empFilter && this.filters.indexOf(empFilter) < 0) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  showHiddenOptions() {
    this.isHiddenOptionVisible = !this.isHiddenOptionVisible;
    this.changeDetectorRef.detectChanges();
  }

  handleFilterChange(filters: FilterViewModel[]) {
    this.filters = filters;

    // Use this aproach because afterViewInit is triggered before emmiting the initial filters.
    // 4 are the initial filters, so thats the hacky way instead of AfterViewInit
    if (filters.length > 1) {
      this.isTouched = true;
    }
  }

  get getDate() {
    if (this.form.controls.expectedDueDate.value) {
      return this.form.controls.expectedDueDate.value as string;
    }
    return '';
  }

  dateChangeHandler(dateAsIsoString: string) {
    this.form.controls.expectedDueDate.setValue(dateAsIsoString);
  }

  handleAction(action: string) {
    const isFiltersValid = this.validateRequiredFilters([FilterTypes.Project_Status]);
    this.validationErrors = [];
    if (!this.form.valid || !isFiltersValid) {
      this.triggerErrors = true;
      return;
    }

    this.isLoading = true;
    this.subscriptions.add(
      this.handleSubmit().subscribe((res) => {
        const newProject = res.returnModel as ProjectDetailsViewModel;
        const errors = res.errorList;
        if (errors?.length > 0) {
          this.validationErrors = errors;
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
        } else {
          void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedProject }));

          // Add filters for Added Project & General Task Group
          const addedFilters: FilterViewModel[] = this.generateNewFilters(newProject);
          this.allowedFiltersService.addFilterSettings(addedFilters);
          this.isLoading = false;

          if (action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService)) {
            this.bsModalEventsEmitter.broadcastBsModalActionHandleClicked(newProject);
            this.closeModal();
          } else if (
            action ===
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
          ) {
            void this.router.navigate([`v2/planning/list/projects/${newProject.id}`]);
            this.closeModal();
          } else if (
            action ===
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          ) {
            this.resetModal();
          }
        }
      })
    );
  }

  generateNewFilters(newProject: ProjectDetailsViewModel): FilterViewModel[] {
    const addedFilters: FilterViewModel[] = [];

    const projectFilter: FilterViewModel = new FilterViewModel();
    projectFilter.filterType = FilterTypes.Project;
    projectFilter.filterValue = newProject.id;
    projectFilter.filterText = newProject.title;
    projectFilter.relatedObjectId = 0;

    const taskGroupFilter: FilterViewModel = new FilterViewModel();
    taskGroupFilter.filterType = FilterTypes.Task_Group;
    taskGroupFilter.filterValue = newProject.defaultTaskGroupId;
    taskGroupFilter.filterText = 'General';
    taskGroupFilter.relatedObjectId = newProject.id;

    addedFilters.push(projectFilter);
    addedFilters.push(taskGroupFilter);
    return addedFilters;
  }

  confirmClose() {
    if (this.isTouched) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.bsModalEventsEmitter.broadcastBsModalCloseClicked({});
          this.closeModal();
          this.cancel.next();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    } else {
      this.closeModal();
      this.cancel.next();
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  resetModal() {
    this.form.reset();
    this.filters = [];
    this.resetDateComponent();
  }

  resetDateComponent() {
    this.dateReseter = true;
    this.changeDetectorRef.detectChanges();
    this.dateReseter = false;
    this.changeDetectorRef.detectChanges();
  }

  handleSubmit() {
    if (this.form.valid) {
      this.project.title = this.form.controls.title.value.trim();
      this.project.description = this.form.controls.description.value;
      this.project.refCode = this.form.controls.refCode.value;
      this.project.expectedDueDate = this.form.controls.expectedDueDate.value ? this.form.controls.expectedDueDate.value : '';
      this.project.filters = this.filters;

      // Populate primitive properties from Filter list
      this.project.privacyStatus = this.getFilterValue(FilterTypes.Project_Privacy_Status);
      this.project.status = this.getFilterValue(FilterTypes.Project_Status);
      this.project.departmentId = this.getFilterValue(FilterTypes.Department);

      return this.projectService.add(this.project);
    }
  }

  getFilterValue(filterType: FilterTypes): number {
    const filter: FilterViewModel = this.project.filters.find((f) => f.filterType === filterType);
    return filter ? filter.filterValue : 0;
  }

  updateDescription($event) {
    this.form.controls.description.setValue($event);
  }

  validateRequiredFilters(filtersForSearch: FilterTypes[]): boolean {
    let isValid = true;
    filtersForSearch.forEach((currentFilterType) => {
      if (!this.filters.find((f) => f.filterType === currentFilterType)) {
        isValid = false;
      }
    });

    return isValid;
  }
}
