import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Output,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnChanges,
} from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { DocumentCategoryViewModel } from 'src/app/modules/shared/viewModels/documents/documentCategoryViewModel';
import { DocumentViewModel } from 'src/app/modules/shared/viewModels/documents/documentViewModel';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { Configuration } from 'src/app/app.constants';
import { AuthenticationService } from '../../../../services/authentication.service';
import { DocumentCategoryService } from '../../../../services/documentCategory.service';
import { AlertService } from '../../../../services/alert.service';
import { Employee } from '../../../../models/employee';
import { ObjectTypes } from '../../../../enums/objectTypes';
import { UploadTypes } from '../../../../enums/uploadTypes';
import { DocumentService } from '../../../../services/document.service';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { CapacitorUtilities } from '../../../../utilities/capacitor.utilities';

@Component({
  selector: 'app-document-upload',
  templateUrl: 'document-upload.component.html',
  styleUrls: ['document-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentUploadComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions = new Subscription();
  private currentEmployee: Employee;
  private readonly smallDivWith: number = 671;
  private readonly largeDivWith: number = 1220;
  readonly isNativePlatform = CapacitorUtilities.isNative;

  @Input() globalObjectId: number;
  @Input() globalObjectType: ObjectTypes;
  @Input() documents: DocumentViewModel[] = [];
  @Input() uploadViewModels: UploadViewModel[] = [];
  @Input() isReadOnly: boolean = false;
  @Input() handleUpdatesInternally: boolean = true;

  @Output() uploadViewModelsChanged: EventEmitter<UploadViewModel[]> = new EventEmitter<UploadViewModel[]>();
  @Output() documentsChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLElement>;
  @ViewChild('file') fileElement: ElementRef<HTMLElement>;

  url: any;
  loading: boolean = true;
  public documentCategories: DocumentCategoryViewModel[] = [];
  wrapperIsSmall: boolean = false;
  wrapperIsLarge: boolean = false;
  public readonly T = T;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly documentCategoryService: DocumentCategoryService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly configuration: Configuration,
    private readonly alertService: AlertService,
    private readonly documentService: DocumentService,
    private readonly translateService: TranslateService
  ) {}

  public getWrapperWidth() {
    if (this.wrapper) {
      this.wrapper.nativeElement.offsetWidth <= this.smallDivWith ? (this.wrapperIsSmall = true) : (this.wrapperIsSmall = false);
      this.wrapper.nativeElement.offsetWidth >= this.largeDivWith ? (this.wrapperIsLarge = true) : (this.wrapperIsLarge = false);
    }
  }

  ngOnInit(): void {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    if (this.handleUpdatesInternally) {
      this.subscriptions.add(
        forkJoin(
          this.documentCategoryService.getDocumentCategoriesWithDocuments(),
          this.documentService.getByObjectId(this.globalObjectId, this.globalObjectType)
        ).subscribe(([categories, documents]) => {
          this.documentCategories = categories;
          this.documents = documents;
          this.documentsChanged.next(documents);
          this.changeDetectorRef.markForCheck();
          this.loading = false;
        })
      );
    } else {
      this.subscriptions.add(
        this.documentCategoryService.getDocumentCategoriesWithDocuments().subscribe((categories) => {
          this.documentCategories = categories;
          this.changeDetectorRef.markForCheck();
          this.loading = false;
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(): void {
    this.getWrapperWidth();
  }

  addFiles(files: File[]) {
    this.loading = true;
    for (let i = 0; i < files.length; i++) {
      this.addUploadViewModel(files[i], '', UploadTypes.File);
    }
    this.loading = false;
  }

  onSelectFile(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        if (files[i].size > this.configuration.MaxFileSize) {
          void this.alertService.error(this.translateService.instant(T.common.the_file_is_too_large_try_smaller));
        } else {
          const splitted = files[i].name.split('.');
          const extention = splitted[splitted.length - 1].toLowerCase();
          if (!this.configuration.AllowedUploadTypes.includes(extention)) {
            void this.alertService.error(this.translateService.instant(T.common.the_file_is_not_supported_try_different_type));
          } else {
            this.addUploadViewModel(files[i], '', UploadTypes.File);
          }
        }
      }
    }
  }

  addLink() {
    this.addUploadViewModel(null, '', UploadTypes.Hyperlink);
  }

  private addUploadViewModel(file: File, link: string, uploadType: UploadTypes) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    if (file && uploadType === UploadTypes.File) {
      let useCompression = false;

      if (acceptedImageTypes.indexOf(file.type) !== -1) {
        useCompression = true;
      }

      this.documentService.splitUploadFile(file, useCompression, true, false).subscribe((res) => {
        this.addViewModel(file.name, link, uploadType, res);
        this.changeDetectorRef.detectChanges();
      });
    } else {
      this.addViewModel('', link, uploadType, '');
      this.changeDetectorRef.detectChanges();
    }
  }

  private addViewModel(originalFileName: string, link: string, uploadType: UploadTypes, fileName: string) {
    const uploadViewModel: UploadViewModel = new UploadViewModel();
    uploadViewModel.accountId = this.currentEmployee.accountId;
    uploadViewModel.employeeId = this.currentEmployee.id;
    uploadViewModel.description = '';
    uploadViewModel.fileName = fileName;
    uploadViewModel.link = link;
    uploadViewModel.documentCategoryIDs = [];
    uploadViewModel.globalObjectId = this.globalObjectId;
    uploadViewModel.globalObjectType = this.globalObjectType;
    uploadViewModel.uploadType = uploadType;
    uploadViewModel.title = uploadType === UploadTypes.File ? this.getDefaultFileTitle(originalFileName) : link;
    uploadViewModel.originalTitle = uploadType === UploadTypes.File ? this.getDefaultFileTitle(originalFileName) : link;
    this.uploadViewModels.push(uploadViewModel);
  }

  private getDefaultFileTitle(fileName: string) {
    let title: string = '';
    const splitted = fileName.split('.');
    title = splitted[0].trim();
    title = title.replace(/-/g, ' ').replace(/_/g, ' ');
    return title;
  }

  onDropHandler(ev: DragEvent) {
    this.onSelectFile(ev.dataTransfer ? ev.dataTransfer.files : null);
  }

  openFileUpload() {
    if (this.fileElement) {
      const newEvent = new MouseEvent('click');
      this.fileElement.nativeElement.dispatchEvent(newEvent);
    }
  }

  deleteUploadViewModel(uploadViewModel: UploadViewModel) {
    this.uploadViewModels.splice(this.uploadViewModels.indexOf(uploadViewModel), 1);
    if (!this.handleUpdatesInternally) {
      this.uploadViewModelsChanged.next(this.uploadViewModels);
      this.changeDetectorRef.markForCheck();
    }
  }

  updateUploadViewModel(uploadViewModel: UploadViewModel) {
    if (this.handleUpdatesInternally) {
      this.subscriptions.add(
        this.documentService.addDocument(uploadViewModel).subscribe((res) => {
          this.documents.push(res);
          this.uploadViewModels = [];
          this.changeDetectorRef.markForCheck();
          this.documentsChanged.next(this.documents);
        })
      );
    } else {
      const uploads = this.uploadViewModels.filter((u) => u.fileName == uploadViewModel.fileName);
      if (uploads && uploads.length > 0) {
        uploads[0] = uploadViewModel;
        this.uploadViewModelsChanged.next(this.uploadViewModels);
        if (this.globalObjectId && this.globalObjectId !== 0) {
          this.uploadViewModels = [];
          this.changeDetectorRef.markForCheck();
        }
      }
    }
  }

  onDocumentChanged() {
    if (this.handleUpdatesInternally) {
      this.subscriptions.add(
        this.documentService.getByObjectId(this.globalObjectId, this.globalObjectType).subscribe((documents) => {
          this.documents = documents;
          this.documentsChanged.next(documents);
          this.changeDetectorRef.markForCheck();
          this.loading = false;
        })
      );
    } else {
      this.documentsChanged.next(null);
    }
  }

  get cardClass() {
    if (this.wrapperIsSmall) {
      return 'full-width';
    }
    if (this.wrapperIsLarge) {
      return 'third-width';
    }
    return 'half-width';
  }
}
