import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  ChangeDetectorRef,
  EventEmitter,
} from '@angular/core';
import { RAGBreakdown } from '../../../models/rag/ragBreakdown';
import { RAGStatus } from '../../../models/rag/ragStatus';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';

@Component({
  selector: 'app-list-header',
  templateUrl: 'list-header.component.html',
  styleUrls: ['list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListHeaderComponent {
  @Input() searchText: string;
  @Input() searchPlaceholder: string;
  @Input() showRAGBreakdown: boolean = true;
  @Input() showSearchBar: boolean = true;
  @Input() ragBreakdownTitle: string;
  @Input() ragBreakdownData: RAGBreakdown[] = [];
  @Input() rags: RAGStatus[] = [];
  @Input() showQuickFilters: boolean = false;
  @Input() quickFilterSelectorVMs: FilterTypeSelectorViewModel[];

  @Output() onSearch = new EventEmitter<string>();
  @Output() onRAGsFiltered = new EventEmitter<RAGStatus[]>();

  constructor(changeDetectorRef: ChangeDetectorRef) {}

  onListSearch(searchText: string) {
    this.onSearch.next(searchText);
  }

  onRagsFiltered(rags: RAGStatus[]) {
    this.onRAGsFiltered.next(rags);
  }
}
