import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, of, skip, switchMap } from 'rxjs';
import { IncidentChannelViewModel } from '../../settings/viewModels/incidentChannelsViewModel';
import { AccountSettingTypes } from '../enums/accountSettingTypes';
import { FilterTypes } from '../enums/filterTypes';
import { ObjectTypes } from '../enums/objectTypes';
import { ObjectEventEmitters } from '../events/object.events';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { FixedFilterType } from '../types/FixedFilterType';
import { FixedObjectType } from '../types/FixedObjectType';
import { FilterUtilities } from '../utilities/filter.utilities';
import { AccountSettingsService } from './account-settings.service';
import { AllowedFiltersService } from './allowed-filters.service';
import { AuthenticationService } from './authentication.service';
import { FixedFiltersServiceAbstract } from './fixed-filters-service.abstract';
import { IncidentChannelsService } from './incident-channels-service';
import { ModuleService } from './module.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Injectable({ providedIn: 'root' })
export class FixedIncidentChannelFiltersService
  extends FixedFiltersServiceAbstract<IncidentChannelViewModel>
  implements OnDestroy
{
  protected override readonly objectTypes: ObjectTypes[] = [ObjectTypes.IncidentItem, ObjectTypes.Job];
  override readonly filterType: FixedFilterType = FilterTypes.Incident_Channel;
  override readonly fixedObjectType: FixedObjectType = ObjectTypes.Incident_Channel;

  constructor(
    private readonly incidentChannelsService: IncidentChannelsService,
    private readonly accountSettingsService: AccountSettingsService,
    protected readonly authenticationService: AuthenticationService,
    protected readonly moduleService: ModuleService,
    protected readonly allowedFiltersService: AllowedFiltersService,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly translateService: TranslateService
  ) {
    super(authenticationService, moduleService, allowedFiltersService, objectEventEmitters, translateService);
  }

  protected override checkServiceOnInit(): Observable<undefined> {
    return this.accountSettingsService.getEnableIncidentChannelsSetting().pipe(
      switchMap((isServiceEnabled) => {
        this._isServiceEnabled.next(isServiceEnabled);
        const hasCommonObjTypes = this.objectTypes.some((item) => this.moduleService.currentObjectTypes.includes(item));
        this._isFilterActive.next(isServiceEnabled && hasCommonObjTypes);

        return of(undefined);
      })
    );
  }

  protected override generateAllFilter(): FilterViewModel {
    return FilterUtilities.GenerateFilter(
      this.filterType,
      '0',
      this.translateService.instant(T.common.all_channels),
      0,
      true,
      ObjectTypes.Global
    );
  }

  protected override generateEpmtyEntity(): IncidentChannelViewModel {
    const emptyEntity = new IncidentChannelViewModel();
    emptyEntity.title = this.translateService.instant(T.common.all_channels);
    emptyEntity.id = 0;

    return emptyEntity;
  }

  protected override initSubscriptions() {
    super.initSubscriptions();

    this.subscriptions.add(
      this.objectEventEmitters.accountSettingUpdated$.subscribe(({ settingType, setting }) => {
        if (settingType === AccountSettingTypes.Enable_Incident_Channels) {
          this._isServiceEnabled.next(setting.value === '1');
          this.populateData().subscribe();
        }
      })
    );

    this.subscriptions.add(
      this.fixedFilter$.pipe(skip(1)).subscribe((fixedFilter) => {
        const channelId = fixedFilter ? parseInt(fixedFilter.filterValue.toString()) : 0;
        this.employee.defaultIncidentChannel = channelId;

        if (this.isServiceEnabled) {
          this.incidentChannelsService.setIncidentChannel(channelId).subscribe();
        }
      })
    );
  }

  protected override setFixedFilterOnInit(): Observable<undefined> {
    const allowedChannels = this.allowedEntities;
    const allowedFilters = this.allowedFilters;

    if (allowedChannels.length === 0 || !this.isServiceEnabled) {
      return of(undefined);
    }

    if (this.employee.defaultIncidentChannel) {
      const activeChannelInAllowed = allowedChannels.find((channel) => channel.id === this.employee.defaultIncidentChannel);

      if (activeChannelInAllowed) {
        const newFilter = allowedFilters.find((f) => f.filterValue.toString() === activeChannelInAllowed.id.toString());
        this._fixedFilter.next(newFilter);
      }
    } else {
      this._fixedFilter.next(this.allFilter);
    }

    return of(undefined);
  }

  protected override populateData(): Observable<undefined> {
    if (!this.isServiceEnabled) {
      this._allowedFilters.next([]);
      this._allowedEntities.next([]);

      return of(undefined);
    }

    return forkJoin([
      this.incidentChannelsService.getActiveChannels(),
      this.incidentChannelsService.getActiveChannelFilters(),
    ]).pipe(
      switchMap(([channels, channelFilters]) => {
        channels.unshift(this.emptyEntity);

        channelFilters = channelFilters.map((f) => {
          return { ...f, filterType: this.filterType, displayForGlobalObjectType: ObjectTypes.Global } as FilterViewModel;
        });
        this._allFilter = channelFilters.find((f) => f.filterValue.toString() === '0');

        this._allowedFilters.next(channelFilters);
        this._allowedEntities.next(channels);

        return of(undefined);
      })
    );
  }
}
