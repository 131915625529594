import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { isDateValid } from 'ngx-bootstrap/chronos';
import { T } from 'src/assets/i18n/translation-keys';
import { TimeZoneService } from '../../../services/timeZone.service';
import { UserAgentService } from '../../../services/user-agent.service';
import { formatDate } from '../../../utilities/date.utilities';

@Component({
  selector: 'app-details-datepicker',
  templateUrl: './details-datepicker.component.html',
  styleUrls: ['./details-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsDatepickerComponent implements OnInit, OnChanges {
  @Input() date: string;
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() range: boolean = false;
  @Input() isEndDateField: boolean;
  @Input() required: boolean;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() label: string;
  @Input() placeholder: string = 'No Date';
  @Input() canEdit: boolean = true;
  @Input() editTime: boolean = false;
  @Input() disableEditButton: boolean = false;
  @Input() comparedTo: string;
  @Input() comparingLabel: string;
  @Input() includeTime: boolean = false;
  @Input() sameDateHelper: string = 'is Today';
  @Input() dayOfTheWeekName: boolean = false;
  @Input() timePicker: boolean = false;
  @Input() showComparer: boolean = false;
  @Input() accordionLozenge: boolean = false;
  @Output() dateChanged = new EventEmitter<string>();
  @Output() endDateChanged = new EventEmitter<string>();
  @Output() datesChanged = new EventEmitter();
  public currentDate: Date;
  public currentEndDate: Date;
  public comparedToDate: Date;
  public today: Date;
  public differenceInDays: number;
  public beforeOrAfter: string;
  public isCurrentDateBiggerThanCompared: boolean;
  public touchDevice: boolean;
  public timePickerDisabled: boolean = true;
  public timeStartString: string;
  public timeDueString: string;
  public marginOffset: number = 60;
  public showEditButton: boolean = false;
  public readonly T = T;
  private readonly browsersSupportedFormat: string = 'yyyy-MM-dd';

  constructor(
    private readonly timeZoneService: TimeZoneService,
    private readonly userAgentService: UserAgentService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userAgentService.isTouch$.subscribe((isTouch) => {
      this.touchDevice = isTouch;
      this.changeDetectorRef.markForCheck();
    });

    this.initDates();
    this.calculateDifferenceInDays();

    const offsetWidth = this.getWidthOfText(this.label);
    if (offsetWidth > this.marginOffset - 10) {
      this.marginOffset = offsetWidth + 10;
    }
  }

  ngOnChanges() {
    this.initDates();
    this.calculateDifferenceInDays();

    const offsetWidth = this.getWidthOfText(this.label);
    if (offsetWidth > this.marginOffset - 10) {
      this.marginOffset = offsetWidth + 10;
    }
  }

  isNumber(num: number) {
    return !isNaN(num);
  }

  initDates() {
    this.today = new Date();
    this.today = new Date(this.timeZoneService.localiseDateISOStringByCustomFormat(this.today.toISOString(), 'MM/DD/YYYY'));
    if (this.date) {
      this.currentDate = new Date(this.timeZoneService.localiseDateISOStringByCustomFormat(this.date, 'MM/DD/YYYY'));
    }
    if (this.endDate) {
      this.currentEndDate = new Date(this.timeZoneService.localiseDateISOStringByCustomFormat(this.endDate, 'MM/DD/YYYY'));
    }
    if (this.comparedTo) {
      this.comparedToDate = new Date(this.timeZoneService.localiseDateISOStringByCustomFormat(this.comparedTo, 'MM/DD/YYYY'));
    }
  }

  onDateChanged(date) {
    this.dateChanged.emit(date);
  }
  onEndDateChanged(date) {
    this.endDateChanged.emit(date);
  }
  onDatesChanged(date) {
    this.datesChanged.emit(date);
  }
  calculateDifferenceInDays() {
    if (!this.currentDate || !this.comparedToDate) {
      return;
    }
    const actualStartTimeDiff = this.currentDate.toTimelessDate().getTime() - this.comparedToDate.toTimelessDate().getTime();
    this.differenceInDays = Math.round(actualStartTimeDiff / (1000 * 60 * 60 * 24));

    if (this.differenceInDays > 0) {
      this.isCurrentDateBiggerThanCompared = true;
    }
  }

  // onChangeNative(e: Event) {
  //   const date: Date = (<HTMLInputElement>e.target).valueAsDate;

  //   if (date) {
  //     if(this.isEndDateField){
  //       this.endDateChanged.emit(date);
  //     } else if (!this.isEndDateField) {
  //       this.dateChanged.emit(date);
  //     }
  //   }
  // }

  get formattedValue(): string {
    if (!isDateValid(this.currentDate)) {
      return;
    }

    return formatDate(this.currentDate, this.browsersSupportedFormat);
  }
  get formattedEndValue(): string {
    if (!isDateValid(this.currentEndDate)) {
      return;
    }

    return formatDate(this.currentEndDate, this.browsersSupportedFormat);
  }

  getWidthOfText(txt) {
    const spanField = document.createElement('span');
    spanField.style.visibility = 'hidden';
    spanField.style.whiteSpace = 'nowrap';
    spanField.style.fontSize = '12px';
    document.body.appendChild(spanField);

    spanField.innerText = txt;
    return spanField.offsetWidth;
  }

  toggleShowEditButton() {
    if (this.canEdit) {
      this.showEditButton = !this.showEditButton;
    }
  }
}
