<ng-container [ngTemplateOutlet]="listCountResultTemplate"></ng-container>
@if (showArchive && !showOnlyArchiveButton) {
  <div class="d-flex justify-content-between action-buttons">
    <div class="bulk-archve-left d-flex justify-content-between">
      <div class="archive-button me-3" (click)="toggleSelectAll()">
        @if (selectAll) {
          {{ T.common.select_all | translate}}
        }
        @else {
          {{ T.common.deselect_all | translate }}
        }
      </div>
      <div class="archive-button" (click)="onToggleSelect(!showArchive)">{{ T.common.cancel_selection | translate}}</div>
    </div>
    <div class="bulk-archive-right" [class.disabled]="!hasSelectedItems">
      <div class="archive-button" (click)="onBulkArchive()">
        <div class="me-2 archive-text" [class.disabled]="!hasSelectedItems">
          @if (customButtonText) {
            {{ customButtonText }}
          }
          @else {
            {{ T.common.archive | translate }}
          }
        </div>
        <div>
          <i class="material-symbols-outlined delete-icon" [class.disabled]="!hasSelectedItems">delete</i>
        </div>
      </div>
    </div>
  </div>
}
@else if (!showArchive && !showOnlyArchiveButton) {
  <div class="d-flex justify-content-between action-buttons">
    @if (isAdmin) {
      <div class="bulk-archve-left d-flex justify-content-between">
        <div class="archive-button" (click)="onToggleSelect(true)">{{ T.common.select | translate }}</div>
      </div>
    }
    <div class="bulk-archive-right">
      <ng-container [ngTemplateOutlet]="sortListButtonTemplate"></ng-container>
    </div>
  </div>
}
@else if (showOnlyArchiveButton && hasSelectedItems) {
  <div class="archive-button" (click)="onBulkArchive()">
    <div class="me-2">
      @if (customButtonText) {
        {{ customButtonText }}
      }
      @else {
        {{ T.common.archive | translate }}
      }
    </div>
    <div>
      <i class="material-symbols-outlined delete-icon">delete</i>
    </div>
  </div>
}

<ng-template #sortListButtonTemplate>
  <ng-content select="[sort-list-button-template]"></ng-content>
</ng-template>

<ng-template #listCountResultTemplate>
  <ng-content select="[list-count-result-template]"></ng-content>
</ng-template>

