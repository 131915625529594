import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { Constants } from 'src/app/modules/shared/models/constants';
import { T } from 'src/assets/i18n/translation-keys';
import { ButtonTheme, ButtonType } from 'src/app/modules/shared/components/common/button/button.component';

@Component({
  selector: 'add-widget-location-modal',
  templateUrl: './add-widget-location-modal.component.html',
  styleUrls: ['./add-widget-location-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class AddWidgetLocationModal implements OnInit, OnDestroy {
  private readonly mobileWidth: number = Constants.xs;
  defaultLocation = new LocationViewModel(0, 0, '');
  accountId: number;
  isLoaded: boolean = false;

  subscriptions: Subscription[] = [];
  onSubmit = new EventEmitter<LocationViewModel>();
  public readonly T = T;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, public readonly bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.isLoaded = true;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  onIncidentLocationUpdated(locationViewModel: LocationViewModel) {
    this.defaultLocation.latitude = locationViewModel.latitude;
    this.defaultLocation.longitude = locationViewModel.longitude;
    this.defaultLocation.locationDetails = locationViewModel.locationDetails;
  }

  onConfirm() {
    this.onSubmit.next(this.defaultLocation);
    this.bsModalRef.hide();
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  protected readonly ButtonTheme = ButtonTheme;
  protected readonly ButtonType = ButtonType;
}
