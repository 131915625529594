import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentViewModel } from 'src/app/modules/shared/viewModels/documents/documentViewModel';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';

@Component({
  selector: 'app-attachments-board-view',
  templateUrl: 'attachments-board-view.component.html',
  styleUrls: ['attachments-board-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsBoardViewComponent {
  @Input() accountId: number;
  @Input() documents: DocumentViewModel[];
  @Input() uploadViewModel: UploadViewModel;

  @Output() onAttachmentDownload = new EventEmitter<DocumentViewModel>();
  @Output() onAttachmentDelete = new EventEmitter<DocumentViewModel>();
  @Output() onAttachmentLinkClicked = new EventEmitter<DocumentViewModel>();

  attachmentDownload(attachment: DocumentViewModel) {
    this.onAttachmentDownload.next(attachment);
  }

  attachmentDelete(attachment: DocumentViewModel) {
    this.onAttachmentDelete.next(attachment);
  }

  attachmentLinkClicked(attachment: DocumentViewModel) {
    this.onAttachmentLinkClicked.next(attachment);
  }
}
