<div #header class="modal-header">
  <div class="modal-header-content">
    <h4>{{ title }}</h4>
    <i
      class="material-symbols-outlined modal-close"
      (click)="bsModalRef.hide()"
      style="font-size: 20px; margin-right: 0; float: unset !important; right: 0; position: absolute; top: 14px"
      >close</i
    >
  </div>
</div>

<div class="modal-body">
  <video controls (click)="toggleVideo(videoPlayer)" #videoPlayer style="max-width: 100%; max-height: 100%">
    <source src="{{ streamUrl }}" type="video/mp4" />
    Browser not supported
  </video>
</div>
