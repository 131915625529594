import { Component, EventEmitter, Input,  Output } from "@angular/core";

@Component({
  selector: 'app-headline-status-card-icon',
  templateUrl: './headline-status-card-icon.component.html',
  styleUrls: ['./headline-status-card-icon.component.scss']
})

export class HeadlineStatusCardIconComponent {
  @Input() headlineStatus: string;
  @Input() headlineStatusMessage: string;
  @Output() headlineStatusUpdated = new EventEmitter<MouseEvent>();

  updateHeadlineStatus(event: MouseEvent) {
    this.headlineStatusUpdated.next(event);
  }
}
