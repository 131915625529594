export enum MarkerType {
  INCIDENT = 1,
  RUNSHEET_ITEM = 2,
  PROJECT = 3,
  TASK_GROUP = 4,
  TASK = 5,
  SUB_TASK = 6,
  RISK = 7,
  ISSUE = 8,
  OPPORTUNITY = 9,
  EVALUATION = 10,
  JOB = 11,
  LOG = 12
}
