import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum IndicatorTargetBadgeTypes {
  No_Badge = 0,
  Gold = 1,
  Silver = 2,
  Bronze = 3,
}

export namespace IndicatorTargetBadgeTypes {
  export function translatedItems(translateService: TranslateService): { key: IndicatorTargetBadgeTypes; value: string }[] {
    const a: { key: IndicatorTargetBadgeTypes; value: string }[] = [];
    for (const enumMember in IndicatorTargetBadgeTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translateItem(+enumMember, translateService) });
      }
    }
    return a;
  }
}

function translateItem(key: IndicatorTargetBadgeTypes, translateService: TranslateService): string {
  switch (key) {
    case IndicatorTargetBadgeTypes.No_Badge:
      return translateService.instant(T.sustainability.no_badge);
    case IndicatorTargetBadgeTypes.Gold:
      return translateService.instant(T.sustainability.gold);
    case IndicatorTargetBadgeTypes.Silver:
      return translateService.instant(T.sustainability.silver);
    case IndicatorTargetBadgeTypes.Bronze:
      return translateService.instant(T.sustainability.bronze);
    default:
      return 'n/a';
  }
}
