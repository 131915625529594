<div class="app-modal-header">
  <div class="app-modal-header-content">
    <app-button-transparent
      [text]="'Cancel'"
      [textColor]="'primary-text'"
      class="header-link mr-auto"
      (clicked)="onClose($event)">
    </app-button-transparent>

    <div
      *ngIf="icon && iconType === modalHeaderIconType.Material"
      class="material-symbols-outlined"
      [style.transform]="iconTransform"
      [style.color]="iconColor">
      {{ icon }}
    </div>
    <app-details-icon *ngIf="iconType === modalHeaderIconType.Rich" [type]="richIconType" outlined="true" size="24">
    </app-details-icon>
    <img *ngIf="iconType === modalHeaderIconType.Custom" [src]="customIconSrc" width="24" height="24" />

    <div class="app-modal-header-content-title" *ngIf="!editableTitle">{{ title }}</div>
    <app-simple-editable-field
      [ngClass]="isSuggestedRiskDetailsModal ? 'is-suggested-risk-details' : ''"
      *ngIf="editableTitle"
      [input]="title"
      [canEdit]="true"
      [fontSize]="fontSize"
      [lineClamp]="lineClamp"
      (onUpdate)="onTitleUpdate($event)">
    </app-simple-editable-field>

    <div *ngIf="showFullScreenIcon" class="material-symbols-outlined" (click)="onFullScreen($event)">fullscreen</div>
    <div class="material-symbols-outlined close" (click)="onClose($event)">close</div>
    <app-button-transparent
      *ngIf="showDelete"
      [text]="'Delete'"
      [textColor]="'primary-text'"
      class="header-link ml-auto"
      (clicked)="onDelete($event)">
    </app-button-transparent>
    <span *ngIf="!showDelete" class="header-link ml-auto" style="color: #fff">Delete</span>
  </div>
</div>
