import { Pipe, PipeTransform } from '@angular/core';
import { ObjectTypes } from '../enums/objectTypes';
import { LocalisationService } from '../services/localisation.service';

@Pipe({
  name: 'localiseObjectType',
})
export class LocaliseObjectTypePipe implements PipeTransform {
  constructor(private readonly localisationService: LocalisationService) {}

  transform(objectType: ObjectTypes, plural: boolean = false): string {
    return this.localisationService.localiseObjectType(objectType, plural);
  }
}
