import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';

@Component({
  selector: 'app-dashboard-widget-modal',
  templateUrl: './dashboard-widget-modal.component.html',
  styleUrls: ['./dashboard-widget-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetModalComponent {
  @ViewChild('wrapper', { static: false }) protected readonly wrapper: ElementRef<HTMLElement>;

  @Input() headerTitle: string = '';
  @Input() widget: unknown;
  @Input() inputParams: unknown;
  constructor(
    public readonly bsModalRef: BsModalRef,
    protected readonly bsModalEventsEmitter: BsModalEventsEmitter
  ) {}

  close() {
    this.bsModalRef.hide();
  }
}
