import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { WtStorageService } from '../shared/services/wt-storage.service';
@Injectable()
export class SignoutOidcService {
  constructor(private authService: AuthService, private readonly wtStorage: WtStorageService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { iss } = route.queryParams;

    if (iss === this.authService.config.issuer) {
      this.wtStorage.removeItem('id_token');
    }

    return of(false);
  }
}
