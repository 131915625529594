import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constants';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { NotificationViewModel } from '../viewModels/notificationViewModel';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private actionUrl: string = '';
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint('Notification/');
  }

  send(notification: NotificationViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'Send', notification, this.authenticationService.getHttpOptions());
  }
}
