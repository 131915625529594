<div class="header-wrapper">
  <div class="top">
    <div class="d-flex">
      <app-details-icon [outlined]="outlinedIcon" [type]="iconType"></app-details-icon> &nbsp; {{ objectTitle }}
    </div>
  </div>
  <div class="button-wrapper">
    <app-back-button (buttonClicked)="goBack()"></app-back-button>
  </div>
</div>
