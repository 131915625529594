@if(isHubAccount) {
  <app-hub-side-navbar
    [currentAccount]="currentAccount"
    [currentEmployee]="currentEmployee">
  </app-hub-side-navbar>
} @else {
  <app-main-side-navbar
    [currentAccount]="currentAccount"
    [currentEmployee]="currentEmployee">
  </app-main-side-navbar>
}
