import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { SortingService } from '../../../services/sorting.service';
import { EqualityUtilities } from '../../../utilities/equality.utilities';

@Component({
  selector: 'app-filters-multiselect',
  templateUrl: './filters-multiselect.component.html',
  styleUrls: ['./filters-multiselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-multiselect-mobile]': 'mobile',
    '[style.height]': 'lozengeInlineEdit ? "unset" : ""',
  },
})
export class FiltersMultiselectComponent implements OnInit, OnChanges {
  @Input() mobile: boolean;
  @Input() lozengeInlineEdit: boolean;
  @Input() filterType: FilterTypes;
  @Input() filters: FilterViewModel[];
  @Input() appliedFilters: FilterViewModel[];
  @Input() showOnlyActive: boolean = false;
  @Input() showTitle: boolean = true;
  @Input() canClickOnStar = true;
  @Input() isFilterRequired: boolean = false;

  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();

  private hasBeenInitExecuted = false;
  filteredFilters: FilterViewModel[] = [];
  searchValue: string;

  public localAppliedFilters: FilterViewModel[] = [];
  public originalAppliedFilters: FilterViewModel[] = [];
  public excludeState: boolean = false;
  public primaryState: boolean = false;
  public isDepartmentFilter = false;
  public areAllSelected: boolean = false;
  public multiSelect: boolean = true;
  public readonly T = T;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private readonly sortingService: SortingService) {}

  ngOnInit() {
    this.filters = this.sortingService.sortFilters(this.filters, this.filterType);

    if (!this.appliedFilters) {
      this.appliedFilters = [];
    }

    this.filteredFilters = JSON.parse(JSON.stringify(this.filters)) as FilterViewModel[];
    this.filterSpecialCases();
    this.appliedFilters = this.appliedFilters.filter((f) => f.filterType === this.filterType);

    this.localAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters.slice())) as FilterViewModel[];
    this.originalAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];

    this.setInitialExcludeState();
    this.setInitialPrimaryState();
    this.isDepartmentFilter = this.filterType === FilterTypes.Department;
    this.hasBeenInitExecuted = true;

    // this.selected = this.appliedFilters.slice();
    // this.newlySelected = [];

    this.checkIfAllAreSelected();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appliedFilters && this.hasBeenInitExecuted) {
      this.appliedFilters = this.appliedFilters.filter((f) => f.filterType === this.filterType);
      this.localAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];
      if (this.filterType === FilterTypes.Department) {
        this.localAppliedFilters.sort((a, b) => +b.isPrimary - +a.isPrimary);
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  setInitialExcludeState() {
    this.excludeState = this.localAppliedFilters[0]?.exclude;
  }

  setInitialPrimaryState() {
    this.primaryState = this.localAppliedFilters[0]?.isPrimary;
  }

  get isFilterChanged(): boolean {
    if (this.isFilterRequired && !this.localAppliedFilters.length) {
      return true;
    }
    return EqualityUtilities.arraysEqual(this.appliedFilters, this.localAppliedFilters);
  }

  onEntrySelected(entry: FilterViewModel): void {
    entry.filterAction = FilterActionTypes.Add;
    const selectedIndex = this.getItemIndex(entry);

    if (selectedIndex > -1) {
      this.localAppliedFilters = this.localAppliedFilters.filter((a) => a.id !== entry.id);
    } else {
      this.localAppliedFilters.push(entry);
    }

    this.localAppliedFilters = this.localAppliedFilters.slice();

    this.checkIfAllAreSelected();
  }

  private getItemIndex(entry: FilterViewModel): number {
    return this.localAppliedFilters.findIndex((a) => a.filterType === entry.filterType && a.filterValue == entry.filterValue);
  }

  onSearch(value: string): void {
    this.searchValue = value?.toLowerCase().trim();

    if (value) {
      this.filteredFilters = this.filters.filter(
        (f) => f.filterText && f.filterText.toLowerCase().indexOf(this.searchValue) > -1
      );
    } else {
      this.filteredFilters = this.filters.slice();
    }

    if (this.showOnlyActive) {
      this.filteredFilters = this.filteredFilters.filter((f) => f.isActive);
    }
    this.filterSpecialCases();
    this.checkIfAllAreSelected();

    this.changeDetectorRef.markForCheck();
  }

  private filterSpecialCases() {
    if (this.filterType === FilterTypes.Owner) {
      this.filteredFilters = this.filteredFilters.filter((f) => !f.isExternal);
    }
  }

  /**
   * this is event which is called internali from app-filters-inline-edit-lozenges
   * when an lozenge is deleted or lozenges are cleared all. It retursn only the left lozenges
   */
  onFilterDeleted(deletedFilter: FilterViewModel): void {
    this.localAppliedFilters = this.localAppliedFilters.filter(
      (f) => !(f.filterType === deletedFilter.filterType && f.filterValue == deletedFilter.filterValue)
    );

    this.checkIfAllAreSelected();
  }

  onPrimaryFilterClicked(primaryFilter: FilterViewModel): void {
    if (primaryFilter.isPrimary) {
      this.localAppliedFilters.forEach((f) => (f.isPrimary = false));
      primaryFilter.isPrimary = true;
    }
  }

  onExclude(excluded: boolean): void {
    this.localAppliedFilters.forEach((f) => (f.exclude = excluded));
    this.excludeState = excluded;
  }

  onPrimarySelect(selected: boolean): void {
    this.localAppliedFilters.forEach((f) => (f.isPrimary = selected));
    this.primaryState = selected;
  }

  onCancel(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  onSelectAll(): void {
    if (this.multiSelect) {
      this.localAppliedFilters = this.filters.slice();
      this.localAppliedFilters.forEach((a) => (a.filterAction = FilterActionTypes.Add));

      this.checkIfAllAreSelected();

      this.changeDetectorRef.markForCheck();
    }
  }

  onClearAll(): void {
    this.localAppliedFilters = [];
    this.changeDetectorRef.markForCheck();
    this.checkIfAllAreSelected();
  }

  onApply(): void {
    this.localAppliedFilters.forEach((f) => (f.exclude = this.excludeState));
    this.filtersUpdated.next(this.localAppliedFilters);
    this.onCancel();
  }

  private checkIfAllAreSelected(): void {
    if (!this.filters.length || this.searchValue) {
      this.areAllSelected = true;
    } else {
      this.areAllSelected = this.localAppliedFilters.length === this.filters.length;
    }
  }
}
