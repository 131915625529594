import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { ConfirmationService } from '../../../services/confirmation.service';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeUtil } from '../../../utilities/employee.utilities';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-bulk-archive',
  templateUrl: './bulk-archive.component.html',
  styleUrls: ['./bulk-archive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkArchiveComponent implements OnInit {
  public readonly T = T;
  public showArchive: boolean = false;
  public selectAll: boolean = false;
  public isAdmin: boolean = false;
  @Input() confirmModalText: string;
  @Input() customButtonText: string;
  @Input() employee: Employee;
  @Input() showOnlyArchiveButton: boolean = false;
  @Input() hasSelectedItems: boolean = false;
  @Output() bulkArchiveClick = new EventEmitter();
  @Output() selectAllItems = new EventEmitter();
  @Output() toggleShowArchive = new EventEmitter();


  constructor(
    protected readonly confirmationService: ConfirmationService,
    protected readonly translateService: TranslateService,
    private readonly authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    if (!this.confirmModalText) {
      this.confirmModalText = this.translateService.instant(T.common.are_you_sure_archive_confirm_text);
    }
  }

  public onToggleSelect(showArchive: boolean): void {
    this.selectAll = showArchive;
    this.showArchive = showArchive;
    this.toggleShowArchive.next(showArchive);
  }

  public toggleSelectAll(): void {
    this.selectAll = !this.selectAll;
    this.selectAllItems.next(true);
  }

  public onBulkArchive(): void {
    this.confirmationService.confirmThis(
      this.confirmModalText,
      () => {
        this.bulkArchiveClick.next(true);
      },
      () => {
        //handle cancel
      }
    );
  }
}
