import { Pipe, PipeTransform } from '@angular/core';
import { LocalisationService } from '../services/localisation.service';

@Pipe({
  name: 'localiseText',
})
export class LocaliseTextPipe implements PipeTransform {
  constructor(private readonly localisationService: LocalisationService) {}

  transform(unlocalised: string): string {
    return this.localisationService.localise(unlocalised);
  }
}
