import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SeverityViewModel } from '../models/severityViewModel';
import { IncidentChannelViewModel } from '../../settings/viewModels/incidentChannelsViewModel';

@Injectable()
export class IncidentsEventsEmitter {
  private readonly incidentItemClicked: Subject<number> = new Subject();

  private readonly accountSeveritySettingsChanged: Subject<SeverityViewModel[]> = new Subject();
  private readonly dashboardWidgetOptionsToggled = new Subject<boolean>();

  readonly incidentItemClicked$ = this.incidentItemClicked.asObservable();
  readonly accountSeveritySettingsChanged$ = this.accountSeveritySettingsChanged.asObservable();
  readonly dashboardWidgetOptionsToggled$ = this.dashboardWidgetOptionsToggled.asObservable();

  private readonly _incidentChannels = new BehaviorSubject<IncidentChannelViewModel[]>([]);
  readonly incidentChannels$ = this._incidentChannels.asObservable();

  setIncidentChannels(channels: IncidentChannelViewModel[]): void {
    this._incidentChannels.next(channels);
  }

  boradcastIncidentItemClicked(incidentItemId: number) {
    this.incidentItemClicked.next(incidentItemId);
  }

  broadcastAccountSeveritySettingsChanged(severity: SeverityViewModel[]) {
    this.accountSeveritySettingsChanged.next(severity);
  }

  broadcastDashboardWidgetOptionsToggled(widgetOptionsToggled: boolean) {
    this.dashboardWidgetOptionsToggled.next(widgetOptionsToggled);
  }
}
