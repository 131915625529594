import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { ZoneViewModel } from 'src/app/modules/shared/viewModels/zoneViewModel';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { RunsheetViewModel } from '../../models/runsheet.model';
import { FilterToViewModelUtilityService } from 'src/app/modules/shared/services/utilities/filterToViewModelUtilityService';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { AddModalKeyboardShortcuts } from 'src/app/modules/shared/enums/addModalKeyboardShortcuts.enum';
import { AddModalButtonOptions } from 'src/app/modules/shared/enums/addModalButtonOptions.enum';
import { Observable, Subscription } from 'rxjs';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { RunsheetsService } from '../../services/runsheets.service';
import { OperationTypes } from 'src/app/modules/shared/enums/operationTypes';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { Router } from '@angular/router';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { SharedRunsheetService } from '../../services/shared-runsheet.service';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { Account } from 'src/app/modules/shared/models/account';
import { FixedFilters } from 'src/app/modules/shared/enums/fixedFilters.enum';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';

enum ModalStates {
  Create,
  Edit,
  Duplicate,
}

@Component({
  selector: 'app-runsheet-add-modal',
  templateUrl: './runsheet-add-modal.component.html',
  styleUrls: ['./runsheet-add-modal.component.scss'],
})
export class RunsheetAddModalComponent implements OnInit, OnDestroy {
  public runsheet = new RunsheetViewModel();
  public localisedItem: string = 'Run Sheet';
  public localisedZones: string;
  public localisedAreas: string;
  public localiseEvent: string = '';
  public isModalTouched = false;
  public form: UntypedFormGroup;
  public triggerErrors = false;
  public dateReseter = false;
  public readonly filterTypes = FilterTypes;
  public filters: FilterViewModel[] = [];
  public editableFieldTypes = EditableFieldTypes;
  public employee: Employee;
  public account: Account;
  public keyboardShortcuts = AddModalKeyboardShortcuts.getKeyShortsAsObj(this.translateService);
  public buttonOptions = AddModalButtonOptions.getOptions(this.translateService);
  public isLoading = false;
  public validationErrors: string[];

  public areaFilters: FilterViewModel[] = [];
  public applicableAreaFilters: FilterViewModel[] = [];
  public mapZones: ZoneViewModel[] = [];
  public mapAreas: AreaViewModel[] = [];
  public selectedZone: FilterViewModel;
  public title: string;
  public modalState: ModalStates;
  public initialSelectedZone: FilterViewModel;
  public localisedDepartment = 'Department';
  public readonly T = T;

  private subscriptions = new Subscription();

  @Output() onSubmit: EventEmitter<RunsheetViewModel> = new EventEmitter<RunsheetViewModel>();

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.modalState != ModalStates.Duplicate) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        if (event.shiftKey) {
          this.handleAction(AddModalButtonOptions.Save_Add_Another);
          return;
        }
        this.handleAction(AddModalButtonOptions.Save_View_Details);
        return;
      }
      this.handleAction(AddModalButtonOptions.Save_Close);
      return;
    }
  }

  constructor(
    private readonly localisationService: LocalisationService,
    private readonly confirmationService: ConfirmationService,
    private readonly bsModalRef: BsModalRef,
    private readonly elemRef: ElementRef,
    private readonly fb: UntypedFormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly authenticationService: AuthenticationService,
    private readonly filterToViewModelUtilityService: FilterToViewModelUtilityService,
    private readonly runsheetService: RunsheetsService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly timeZoneService: TimeZoneService,
    private readonly sharedRunsheetService: SharedRunsheetService,
    private readonly cachingService: CachingService,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();
    this.localisedItem = this.localisationService.localiseObjectType(ObjectTypes.Runsheet);
    this.localisedZones = this.localisationService.localiseObjectType(ObjectTypes.Zone, true);
    this.localisedAreas = this.localisationService.localiseObjectType(ObjectTypes.Area, true);
    this.localiseEvent = this.localisationService.localiseObjectType(ObjectTypes.Event);
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);

    if (!this.runsheet.id) {
      this.title = this.translateService.instant(T.common.add_new_item, { item: this.localisedItem });
      this.modalState = ModalStates.Create;
      this.runsheet.filters = [];
    } else {
      this.runsheet = JSON.parse(JSON.stringify(this.runsheet));
      this.title = this.translateService.instant(T.common.copy_of_item, {
        item: `${this.runsheet.title} - ${this.timeZoneService.localiseDateISOString(this.runsheet.date, false, false)}`,
      });
      this.modalState = ModalStates.Duplicate;

      this.runsheet.filters = this.runsheet.filters.filter((x) => x.filterType != FilterTypes.Date);
      this.runsheet.date = '';
    }

    this.filters = this.runsheet.filters;

    this.form = this.fb.group({
      title: [
        this.runsheet?.title || '',
        { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])], updateOn: 'blur' },
      ],
      notes: [this.runsheet?.notes || ''],
      date: ['', { validators: [Validators.required] }],
    });

    this.areaFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Area);
    this.setApplicableFilters();
    this.setLiveFilters();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setLiveFilters() {
    if (this.account.fixedFilters == FixedFilters.Event) {
      if (this.cachingService.selectedEventID) {
        this.runsheet.eventId = +this.cachingService.selectedEventID;
        this.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Event, String(this.runsheet.eventId), '', undefined));
      }
    } else if (this.account.fixedFilters == FixedFilters.VenueZone) {
      if (this.cachingService.selectedZoneID) {
        this.initialSelectedZone = FilterUtilities.GenerateFilter(
          FilterTypes.Zone,
          Number(this.cachingService.selectedZoneID),
          '',
          undefined
        );
        this.filters.push(this.initialSelectedZone);
      }
    }
  }

  handleFilterChange(filters: FilterViewModel[]) {
    this.filters = filters;
    this.selectedZone = filters.find((f) => f.filterType == FilterTypes.Zone);
    this.setZonesAndAreas();
    this.changeDetectorRef.detectChanges();
  }

  handleEventFilterChange(filters: FilterViewModel[]) {
    const eventFilter: FilterViewModel = filters.find((f) => f.filterType == FilterTypes.Event);
    this.runsheet.eventId = eventFilter != undefined ? +eventFilter.filterValue : null;
    this.changeDetectorRef.detectChanges();
  }

  setAccountForCurrentOwner() {
    const allowedOwnerFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Owner);
    if (allowedOwnerFilters) {
      const currentEmployeeFilter = allowedOwnerFilters.find(
        (f: any) => f.filterValue.toString() === this.employee.id.toString()
      );
      if (
        currentEmployeeFilter !== null &&
        !this.filters.some((f) => f.filterType == FilterTypes.Owner && f.filterValue == this.employee.id)
      ) {
        this.filters = this.filters.slice();
        this.filters.push(currentEmployeeFilter);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  get getDate() {
    if (this.form.controls.date.value) {
      return this.form.controls.date.value as string;
    }
    return '';
  }

  dateChangeHandler(dateAsIsoString: string) {
    const dateInTimezone = this.timeZoneService.getCurrentMomentInLocalisedTimezone(dateAsIsoString);
    dateInTimezone.set('hour', 0);
    dateInTimezone.set('minutes', 0);
    dateInTimezone.set('seconds', 0);
    dateInTimezone.set('milliseconds', 0);

    const newIso = dateInTimezone.toISOString();

    this.populateDateInFilter(newIso);

    this.form.controls.date.setValue(newIso);
  }

  populateDateInFilter(value: any) {
    const due = this.filters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty == FilterDateOptions.Date);
    if (!due) {
      this.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Date, value, value, FilterDateOptions.Date));
    } else {
      due.filterValue = value;
    }
  }

  resetDateComponent() {
    this.dateReseter = true;
    this.changeDetectorRef.detectChanges();
    this.dateReseter = false;
    this.changeDetectorRef.detectChanges();
  }

  localiseString(s: string) {
    return this.localisationService.localise(s);
  }

  setMyDepartment() {
    const allowedDepartmentFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Department);
    if (allowedDepartmentFilters) {
      const currentEmployeeDepFilter = allowedDepartmentFilters.find(
        (f) => f.filterValue.toString() == this.employee.departmentId.toString()
      );
      if (
        currentEmployeeDepFilter &&
        !this.filters.some((f) => f.filterType == FilterTypes.Department && f.filterValue == this.employee.departmentId)
      ) {
        this.filters = this.filters.slice();
        this.filters.push(currentEmployeeDepFilter);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  setZonesAndAreas() {
    this.mapZones = this.filterToViewModelUtilityService.getZoneViewModelsFromFilters(this.filters);
    this.mapAreas = this.filterToViewModelUtilityService.getAreaViewModelsFromFilters(this.filters);

    this.setApplicableFilters();
    this.changeDetectorRef.markForCheck();
  }

  private setApplicableFilters() {
    if (this.mapZones && this.mapZones.length) {
      const areas = [];
      this.mapZones.forEach((zone) => {
        const zoneAreas = this.areaFilters.filter((a) => +a.relatedObjectId == zone.id);

        if (zoneAreas) {
          areas.push(...zoneAreas);
        }
      });
      this.applicableAreaFilters = areas.slice();
    } else {
      this.mapAreas = [];
      this.applicableAreaFilters = [];
      this.filters = this.filters.filter((x) => x.filterType != FilterTypes.Area);
    }
  }

  updateNotes($event) {
    this.form.controls.notes.setValue($event);
  }

  confirmClose() {
    if (this.isModalTouched) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.closeModal();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  handleAction(action: string) {
    this.validationErrors = [];

    if (!this.form.valid) {
      this.triggerErrors = true;
      return;
    }
    if (action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService)) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res: ValidatedViewModel) => {
          const errors = res.errorList;
          const newRunsheet = res.returnModel as RunsheetViewModel;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.allowedFiltersService.refreshFilters(
              OperationTypes.Create,
              [{ id: newRunsheet.id, title: newRunsheet.title, relatedObjectId: 0 }],
              FilterTypes.Runsheet
            );
            this.isLoading = false;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedItem }));
            this.onSubmit.next(this.runsheet);
            this.closeModal();
          }
        })
      );
    } else if (
      action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
    ) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res: ValidatedViewModel) => {
          const errors = res.errorList;
          const newRunsheet = res.returnModel as RunsheetViewModel;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.sharedRunsheetService.loadRunsheet(newRunsheet);
            this.sharedRunsheetService.loadFilters(newRunsheet.filters);
            this.allowedFiltersService.refreshFilters(
              OperationTypes.Create,
              [{ id: newRunsheet.id, title: newRunsheet.title, relatedObjectId: 0 }],
              FilterTypes.Runsheet
            );
            this.isLoading = false;
            void this.router.navigate([`v2/runsheets/list/${newRunsheet.id}`]);
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedItem }));
            this.closeModal();
          }
        })
      );
    } else if (
      action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
    ) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res) => {
          const errors = res.errorList;
          const newRunsheet = res.returnModel as RunsheetViewModel;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.allowedFiltersService.refreshFilters(
              OperationTypes.Create,
              [{ id: newRunsheet.id, title: newRunsheet.title, relatedObjectId: 0 }],
              FilterTypes.Runsheet
            );
            this.isLoading = false;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedItem }));
            this.onSubmit.next(this.runsheet);
            this.resetModal();
          }
        })
      );
    }
  }

  handleSubmit(): Observable<ValidatedViewModel> {
    this.runsheet.filters = this.filters;
    this.runsheet = { ...this.runsheet, ...this.form.value };

    const eventFilter = this.runsheet.filters.find((f) => f.filterType == FilterTypes.Event);

    if (eventFilter) {
      this.runsheet.eventId = eventFilter.filterValue;
    }

    return this.runsheetService.create(this.runsheet);
  }

  resetModal() {
    this.form.reset();
    this.filters = [];
    this.resetDateComponent();
  }

  duplicateRunsheet() {
    this.isLoading = true;
    this.subscriptions.add(
      this.handleDuplicate().subscribe((res: RunsheetViewModel) => {
        const newRunsheet = res;
        this.allowedFiltersService.refreshFilters(
          OperationTypes.Create,
          [{ id: newRunsheet.id, title: newRunsheet.title, relatedObjectId: 0 }],
          FilterTypes.Runsheet
        );
        this.isLoading = false;
        this.sharedRunsheetService.loadRunsheet(newRunsheet);
        this.sharedRunsheetService.loadFilters(newRunsheet.filters);
        void this.router.navigate([`v2/runsheets/list/${newRunsheet.id}`]);
        void this.alertService.success(this.translateService.instant(T.common.item_duplicated, { item: this.localisedItem }));
        this.closeModal();
      })
    );
  }

  handleDuplicate(): Observable<RunsheetViewModel> {
    this.runsheet.filters = this.filters;
    this.runsheet = { ...this.runsheet, ...this.form.value };

    return this.runsheetService.duplicate(this.runsheet);
  }
}
