<div class="breadcrumb-wrapper">
  <div class="single-breadcrumb" *ngFor="let breadcrumb of breadcrumbConfig; index as i">
    <span *ngIf="i !== 0" class="separator material-symbols-outlined">chevron_right</span>
    <a
    (click)="navigateToBreadcrumbUrl(breadcrumb)"
    class="breadcrumb-link"
    [ngClass]="{'active': breadcrumb.active, 'clickable': !!breadcrumb.url}">
      <span class="material-symbols-outlined breadcrumb-icon" *ngIf="breadcrumb.icon">{{breadcrumb.icon}}</span>
      <span>{{breadcrumb.label}}</span>
    </a>
  </div>
</div>
