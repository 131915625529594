<div class="sidebar-button" [class.active]="active" (click)="toggleSidebar()">
  <span class="txt-rotate">
    {{ T.common.options | translate }}
    <i class="material-symbols-outlined" *ngIf="active"> keyboard_arrow_down </i>
    <i class="material-symbols-outlined" *ngIf="!active"> keyboard_arrow_up </i>
  </span>
</div>
<div #wrapper class="venue-dashboard-sidebar">
  <div class="container-fluid">
    <div class="header">
      <h3>{{ title }}</h3>
      <div class="dismiss" (click)="onCloseSidebar()">
        <div class="responsive-flex-wrapper">
          <i class="material-symbols-outlined">close</i>
        </div>
      </div>
    </div>

    <div class="body">
      <div class="template-wrapper">
        <ng-template [ngTemplateOutlet]="template"></ng-template>
      </div>
      <div class="footer-buttons-wrapper" *ngIf="showFooterButtons">
        <div class="row">
          <div class="col-6">
            <button type="button" class="btn btn-white btn-block filter-button" (click)="onExportToPdf()">
              <div class="responsive-flex-wrapper">
                <i class="material-symbols-outlined font-icon-size">picture_as_pdf</i>&nbsp;{{ T.common.save | translate }}
              </div>
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-white btn-block filter-button" (click)="onExportToPng()">
              <div class="responsive-flex-wrapper">
                <i class="material-symbols-outlined font-icon-size">insert_photo</i>&nbsp;{{ T.common.cancel | translate }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
