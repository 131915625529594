import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export interface BreadcrumbOption {
  label: string;
  icon?: string;
  url?: string;
  active?: boolean;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() breadcrumbConfig: BreadcrumbOption[] = [];


constructor(
  private readonly router: Router
) {}

  public navigateToBreadcrumbUrl(breadcrumb: BreadcrumbOption): void {
    if(breadcrumb.url) {
      this.router.navigate([breadcrumb.url]);
    }
  }
}
