import { Pipe, PipeTransform } from '@angular/core';

// For legacy reasons, all JSON response bodies currently have <, >, and & replaced with &lt;, &gt;, and &amp;, respectively.
// This pipe converts the HTML entities into the characters, so that they could be correctly displayed.

@Pipe({
  name: 'convertHtmlEntities'
})
export class ConvertHTMLEntitiesPipe implements PipeTransform {

  transform(value: string): unknown {
    const regexGreaterThan = /&gt;/g;
    const regexLessThan = /&lt;/g;
    const regexAmpersand = /&amp;/g;
    const regexQuote = /&quot;/g;

    const convertedValue = value?.replace(regexGreaterThan, '>').replace(regexLessThan, '<').replace(regexAmpersand, '&').replace(regexQuote, '"');

    return convertedValue;
  }

}
