import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, merge, Observable, of, skip, Subscription, switchMap } from 'rxjs';
import { ObjectTypes } from '../enums/objectTypes';
import { ObjectEventEmitters } from '../events/object.events';
import { Account } from '../models/account';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { FixedFilterType } from '../types/FixedFilterType';
import { FixedObjectType } from '../types/FixedObjectType';
import { AllowedFiltersService } from './allowed-filters.service';
import { AuthenticationService } from './authentication.service';
import { ModuleService } from './module.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export abstract class FixedFiltersServiceAbstract<FixedEntity> implements OnDestroy {
  protected employee: Employee;
  protected account: Account;
  protected readonly subscriptions = new Subscription();

  protected abstract readonly objectTypes: ObjectTypes[];
  abstract readonly filterType: FixedFilterType;
  abstract readonly fixedObjectType: FixedObjectType;

  protected _allFilter: FilterViewModel;
  get allFilter(): FilterViewModel {
    return this._allFilter;
  }
  protected emptyEntity: FixedEntity;

  protected readonly _isAllFilterApplied = new BehaviorSubject<boolean>(false);
  readonly isAllFilterApplied$: Observable<boolean> = this._isAllFilterApplied.asObservable();
  get isAllFilterApplied(): boolean {
    return this._isAllFilterApplied.value;
  }

  protected readonly _isFilterActive = new BehaviorSubject<boolean>(false);
  readonly isFilterActive$: Observable<boolean> = this._isFilterActive.asObservable();
  get isFilterActive(): boolean {
    return this._isFilterActive.value;
  }

  protected readonly _isServiceEnabled = new BehaviorSubject<boolean>(false);
  readonly isServiceEnabled$: Observable<boolean> = this._isServiceEnabled.asObservable();
  get isServiceEnabled(): boolean {
    return this._isServiceEnabled.value;
  }

  protected readonly _allowedFilters = new BehaviorSubject<FilterViewModel[]>([]);
  readonly allowedFilters$: Observable<FilterViewModel[]> = this._allowedFilters.asObservable();
  get allowedFilters(): FilterViewModel[] {
    return this._allowedFilters.value;
  }

  protected readonly _fixedFilter = new BehaviorSubject<FilterViewModel>(undefined);
  readonly fixedFilter$: Observable<FilterViewModel> = this._fixedFilter.asObservable();
  get fixedFilter(): FilterViewModel {
    return this._fixedFilter.value;
  }

  protected readonly _allowedEntities = new BehaviorSubject<FixedEntity[]>([]);
  readonly allowedEntities$: Observable<FixedEntity[]> = this._allowedEntities.asObservable();
  get allowedEntities(): FixedEntity[] {
    return this._allowedEntities.value;
  }

  constructor(
    protected readonly authenticationService: AuthenticationService,
    protected readonly moduleService: ModuleService,
    protected readonly allowedFiltersService: AllowedFiltersService,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly TranslateService: TranslateService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  init(): Observable<undefined> {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();

    this._allFilter = this.generateAllFilter();
    this.emptyEntity = this.generateEpmtyEntity();

    return this.checkServiceOnInit().pipe(
      switchMap(() => {
        return this.populateData().pipe(
          switchMap(() => {
            return this.setFixedFilterOnInit().pipe(
              switchMap(() => {
                this.initSubscriptions();

                return of(undefined);
              })
            );
          })
        );
      })
    );
  }

  protected abstract checkServiceOnInit(): Observable<undefined>;

  protected abstract generateAllFilter(): FilterViewModel;

  protected abstract generateEpmtyEntity(): FixedEntity;

  protected initSubscriptions() {
    this.subscriptions.add(
      this.fixedFilter$.subscribe((fixedFilter) => {
        this._isAllFilterApplied.next(fixedFilter?.filterValue.toString() === '0');
      })
    );

    this.subscriptions.add(
      combineLatest([this.isServiceEnabled$, this.moduleService.objectTypesChanged$]).subscribe(
        ([isServiceEnabled, objectTypes]) => {
          const hasCommonObjTypes = this.objectTypes.some((item) => objectTypes.includes(item));
          const newStatus = isServiceEnabled && hasCommonObjTypes;

          if (newStatus !== this.isFilterActive) {
            this._isFilterActive.next(newStatus);
          }
        }
      )
    );

    this.subscriptions.add(
      this.allowedFilters$.pipe(skip(1)).subscribe(() => {
        this.setFixedFilterFromAllowedFilters();
      })
    );

    this.subscriptions.add(
      this.allowedFiltersService.allowedFiltersByTypeChanged$.subscribe(() => {
        this.populateData().subscribe();
      })
    );

    this.subscriptions.add(
      merge(
        this.objectEventEmitters.objectAdded$,
        this.objectEventEmitters.objectUpdated$,
        this.objectEventEmitters.objectDeleted$
      )
        .pipe(filter(({ globalObjectType }) => globalObjectType === this.fixedObjectType))
        .subscribe(() => {
          this.populateData().subscribe();
        })
    );
  }

  setFixedFilter(newFilter: FilterViewModel): void {
    if (!this.isServiceEnabled) {
      this._fixedFilter.next(undefined);
      return;
    }

    const fixedFilterInAllowed = this.allowedFilters.find((f) => f.filterValue.toString() === newFilter?.filterValue.toString());

    if (fixedFilterInAllowed) {
      this._fixedFilter.next(newFilter);
    }
  }

  protected setFixedFilterFromAllowedFilters(): void {
    if (!this.isServiceEnabled) {
      this._fixedFilter.next(undefined);
      return;
    }

    const fixedFilter = this.allowedFilters.find((f) => f.filterValue.toString() === this.fixedFilter?.filterValue.toString());

    if (!fixedFilter) {
      this._fixedFilter.next(this._allFilter);
      return;
    }

    this._fixedFilter.next(fixedFilter);
  }

  protected abstract setFixedFilterOnInit(): Observable<undefined>;

  protected abstract populateData(): Observable<undefined>;
}
