import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { AlertController } from '@ionic/angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Configuration } from 'src/app/app.constants';
import { Plugins } from '@capacitor/core';
import { environment } from '../../../../environments/environment';

const { NativeMarket } = Plugins;

interface MobileStatusInfo {
  underMaintenance: boolean;
  maintenanceMsg: {
    Title: string;
    Message: string;
  };
  version: string;
  majorVersionMessage: {
    Title: string;
    Message: string;
    ButtonText: string;
  };
  minorVersionMessage: {
    Title: string;
    Message: string;
    ButtonText: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class MobileVersionUpdateService {
  private actionUrl: string;
  private bsModalRef: BsModalRef;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private bsModalService: BsModalService,
    private alertCtrl: AlertController
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`MobileApp/`);
  }

  async checkForUpdate() {
    if (Capacitor.getPlatform() == 'web') {
      return;
    }
    this.http.get(`${this.actionUrl}GetStatus`).subscribe(async (info: MobileStatusInfo) => {
      info.majorVersionMessage = JSON.parse(info.majorVersionMessage as any);
      info.minorVersionMessage = JSON.parse(info.minorVersionMessage as any);
      if (info.underMaintenance) {
        void this.presentAlert(info.majorVersionMessage.Title, info.majorVersionMessage.Message);
      } else {
        const localVersionNumber = await (await App.getInfo()).version; //1.2.0
        const localSplittedVersion = localVersionNumber.split('.');
        const serverVersionNumber = info.version;
        const serverSplittedVersion = serverVersionNumber.split('.');

        if (serverSplittedVersion[0] > localSplittedVersion[0]) {
          //Major Version Difference
          void this.presentAlert(
            info.majorVersionMessage.Title,
            info.majorVersionMessage.Message,
            info.majorVersionMessage.ButtonText
          );
        } else if (serverSplittedVersion[1] > localSplittedVersion[1]) {
          // Minor Version Difference
          // this.presentAlert(info.minorVersionMessage.Title, info.minorVersionMessage.Message, info.minorVersionMessage.ButtonText, true);
        }
      }
    });
  }

  async presentAlert(title: string, message: string, btnText = '', allowClose = false) {
    const buttons = [];

    if (btnText != '') {
      buttons.push({
        text: btnText,
        handler: () => {
          this.openAppStoreEntry();
        },
      });
    }

    if (allowClose) {
      buttons.push({
        text: 'Not now',
        role: 'cancel',
      });
    }

    const alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: buttons,
      backdropDismiss: allowClose,
      cssClass: 'alert-mobile-status',
    });

    await alert.present();
  }

  openAppStoreEntry() {
    NativeMarket.openStoreListing({
      appId: environment.MOBILE_APP_ID,
    });
  }
}
