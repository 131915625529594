<div class="choose-dependency">
  <div class="container-fluid">
    <div class="header">
      <div class="responsive-flex-wrapper">
        <h3>Choose Dependency</h3>
        <div class="dismiss" (click)="onClose()">
          <div class="responsive-flex-wrapper">
            <i class="material-symbols-outlined">close</i>
          </div>
        </div>
      </div>
    </div>
    <div class="info-note-container">
      <a class="info-note">{{ infoNote }}</a>
    </div>
    <div class="body">
      <div class="search">
        <app-search-input
          [label]="inputLabel"
          [typeahead]="typeahead"
          [foundItems]="foundTasks"
          [bindProperty]="'title'"
        >
        </app-search-input>
        <div class="separator">
          <span>Or</span>
        </div>
      </div>
      <div class="dropdowns">
        <div class="row" *ngIf="projects">
          <div class="col-12">
            <app-search-dropdown-r
              [objects]="projects"
              [bindProperty]="'summary'"
              [currentlySelectedObject]="currentlySelectedProject"
              [label]="'Filter by ' + localisedProject"
              (objectSelected)="onProjectSelected($event)"
              (objectCleared)="onItemCleared($event)"
            >
            </app-search-dropdown-r>
          </div>
        </div>
        <div class="row" *ngIf="taskGroups">
          <div class="col-12">
            <app-search-dropdown-r
              [objects]="taskGroups"
              [bindProperty]="'summary'"
              [currentlySelectedObject]="currentlySelectedTaskGroup"
              [label]="'Filter by ' + localisedTaskGroup"
              (objectSelected)="onTaskGroupSelected($event)"
              (objectCleared)="onItemCleared($event)"
            >
            </app-search-dropdown-r>
          </div>
        </div>
        <div class="row" *ngIf="departments">
          <div class="col-12">
            <app-search-dropdown-r
              [objects]="departments"
              [bindProperty]="'summary'"
              [currentlySelectedObject]="currentlySelectedDepartment"
              [label]="'Filter by ' + localisedDepartment"
              (objectSelected)="onDepartmentSelected($event)"
              (objectCleared)="onItemCleared($event)"
            >
            </app-search-dropdown-r>
          </div>
        </div>
      </div>
      <div #tableWrapper class="tasks-table" [class.loaded]="foundTasks.length">
        <table
          class="table table-striped"
          #dataTable="mfDataTable"
          [mfData]="foundTasks"
          [mfRowsOnPage]="gridSettings.pageSize"
        >
          <thead>
            <tr class="row">
              <th class="col-3" (click)="sortTasksByProperty('ref')">Ref</th>
              <th class="col-6" (click)="sortTasksByProperty('title')">Title</th>
              <th class="col-3">Selected</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let task of dataTable.data" class="row">
              <td class="col-3">{{ task.ref }}</td>
              <td class="col-6" [innerHTML]="task.title"></td>
              <td class="col-3">
                <app-checkbox
                  [isChecked]="task.ref === dependentTask?.ref"
                  (checked)="onTaskSelected(task)"
                ></app-checkbox>
              </td>
            </tr>
          </tbody>
          <tfoot *ngIf="foundTasks.length">
            <tr>
              <td>
                <mfBootstrapPaginator class="mfbootstrappaginator" [rowsOnPageSet]="[10, 25, 50, 100]" (click)="scrollToTop()">
                </mfBootstrapPaginator>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="footer">
      <div class="row">
        <div class="col-6">
          <button type="button" class="btn btn-block custom-btn large-btn idle-btn" (click)="onClose()">Cancel</button>
        </div>
        <div class="col-6">
          <button
            class="btn btn-block custom-btn large-btn action-btn"
            (click)="onContinue()"
            [disabled]="!dependentTask"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
