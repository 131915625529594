import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { UserAgentService } from '../../../services/user-agent.service';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-details-text-area',
  templateUrl: './details-text-area.component.html',
  styleUrls: ['./details-text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsTextAreaComponent implements OnInit, AfterViewInit {
  @ViewChild('textArea') textArea: ElementRef<HTMLElement>;

  @Input() set input(val: string) {
    this.origintalInput = val;
    this.modifiedInput = val;
  }
  @Input() label: string;
  @Input() canEdit: boolean;
  @Input() placeholder: string;
  @Input() minLength = 3;
  @Output() update = new EventEmitter<string>();

  public isEditMode = false;
  public origintalInput = '';
  public modifiedInput = '';
  public showEditButton = false;
  public editState = false;
  public isTouchDevice = false;
  public readonly T = T;
  public lineHeight: number = 26;
  public isCollapsed: boolean = false;
  public isCollapsable: boolean = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly userAgentService: UserAgentService,
  ) {}

  ngOnInit(): void {
    this.userAgentService.isTouch$.subscribe((isTouch) => {
      this.isTouchDevice = isTouch;
    });
  }

  ngAfterViewInit(): void {
    this.setCollapseState();
  }

  public save(input: string): void {
    if (this.modifiedInput !== this.origintalInput) {
      this.update.emit(input);
      this.toggleEditMode();
      this.changeDetectorRef.markForCheck();
    }
  }

  private setCollapseState(): void {
    setTimeout(() => {
      if (this.textArea) {
        const currentHeight = this.textArea.nativeElement.offsetHeight;
        if (currentHeight > this.lineHeight) {
          this.isCollapsed = true;
          this.isCollapsable = true;
        }
      }
      this.changeDetectorRef.markForCheck();
    }, 20);
  }

  /**
   * Toggles edit mode, which is used to show rich-text-editor
   */
  public toggleEditMode(e?: Event): void {
    e?.stopPropagation();
    if (this.canEdit) {
      this.isEditMode = !this.isEditMode;
      this.setCollapseState();
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Cancels edit mode and resets the input to the original value
   */
  public cancelEdit(event?: Event): void {
    if (this.origintalInput !== this.modifiedInput) {
      this.modifiedInput = this.origintalInput;
    }
    event?.stopPropagation();
    this.toggleEditMode(event);
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Value from rich text editor
   */
  public changeInput(newValue: string): void {
    this.modifiedInput = newValue;
    this.changeDetectorRef.markForCheck();
  }
}
