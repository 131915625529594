import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneService } from '../services/timeZone.service';

@Pipe({
  name: 'localisedWeekday',
})
export class LocalisedWeekdayPipe implements PipeTransform {
  constructor(private readonly timeZoneService: TimeZoneService) {}

  transform(dateISOString: string): string {
    return this.timeZoneService.localiseDateISOStringByCustomFormat(dateISOString, 'dddd');
  }
}
