<div
  class="attachment-card-container"
  [object]="attachment"
  [attachmentType]="attachmentType"
  [image]="documentUrl(attachment)"
  ngImagePreview
>
  <div
    class="attachment-preview"
    (click)="onAttachmentClick()"
    [innerHTML]="getDocumentIcon(attachment) | safeHtml"
  ></div>
  <div class="attachment-info">
    <div class="attachment-info-container text-truncate">
      <div class="attachment-name text-truncate">
        <ng-container *ngIf="attachment.title">{{ attachment.title }}</ng-container>
        <ng-container *ngIf="!attachment.title">{{ attachment.filename }}</ng-container>
      </div>
      <div class="attachment-date">{{ attachmentCreatedDate }}</div>
    </div>
    <div class="attachment-actions-container">
      <ng-container *ngIf="attachmentType !== attachmentTypes.Link">
        <div class="action-download" (click)="downloadAttachment($event)">
          <i class="material-symbols-outlined action-icon">download</i>
        </div>
      </ng-container>
      <!-- <div class="action-delete" (click)="deleteAttachment($event)">
        <i class="material-symbols-outlined action-icon">delete</i>
      </div> -->
    </div>
  </div>
</div>
