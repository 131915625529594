import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import * as moment from 'moment';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { FilterDateRangeOptions } from '../../../enums/filter/filterDateRangeOptions';
import { FilterValueDateViewModel } from '../../../models/filter/filterValueDateViewModel';
import { TimeZoneService } from '../../../services/timeZone.service';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { NgRangeDatesOutput } from '../../../models/rangeDatepicker/rangeDatepickerModels.model';
import { FilterUtilities } from '../../../utilities/filter.utilities';

@Component({
  selector: 'app-date-expandable-filter-selector',
  templateUrl: './date-expandable-filter-selector.component.html',
  styleUrls: ['./date-expandable-filter-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateExpandableFilterSelectorComponent {
  @ViewChild('openDatepickerIcon') datepickerIcon: ElementRef<HTMLElement>;

  @Input() appliedFilters: FilterViewModel[];
  @Input() mobile: boolean;
  @Input() dateRange: boolean = true;
  @Input() filterSelectorVM: FilterTypeSelectorViewModel;
  @Input() expanded: boolean = false;
  @Output() filterAdded: EventEmitter<FilterViewModel> = new EventEmitter();
  @Output() filterRemoved: EventEmitter<FilterViewModel> = new EventEmitter();
  @Output() toggleChange: EventEmitter<'open' | 'close'> = new EventEmitter<'open' | 'close'>();

  dateFilterViewModel: FilterViewModel = new FilterViewModel();
  date: Date = new Date();
  dateModel: string;
  fromDate: Date;
  fromDateModel: string;
  toDate: Date;
  toDateModel: string;
  separator: string = '/';
  relative: boolean = true;
  showTimePicker: boolean = false;
  timePickerDisabled: boolean = true;
  public timeStartString: string;
  public timeDueString: string;
  filterDateRangeOptions: { key: FilterDateRangeOptions; value: string }[] = EnumUtilities.items(FilterDateRangeOptions);

  constructor(private readonly timeZoneService: TimeZoneService, private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.filterSelectorVM.dateProperty == FilterDateOptions.Start_Time) {
      this.showTimePicker = true;
    }

    if (
      this.appliedFilters.length
    ) {
      this.dateFilterViewModel = this.appliedFilters[0];

      this.relative = this.dateFilterViewModel.dateRangeOption != FilterDateRangeOptions.Fixed_Date_Range;
      if (this.dateFilterViewModel.dateRangeOption == FilterDateRangeOptions.Fixed_Date_Range) {
        this.fromDate = new Date(this.dateFilterViewModel.filterValue.fromDate);
        this.toDate = new Date(this.dateFilterViewModel.filterValue.toDate);
      }
      if (this.dateFilterViewModel.dateRangeOption == FilterDateRangeOptions.Fixed_Time_Range) {
        this.fromDate = new Date(this.dateFilterViewModel.filterValue.fromDate);
        this.toDate = new Date(this.dateFilterViewModel.filterValue.toDate);
        this.timeStartString = moment(this.fromDate).format('HH:mm');
        this.timeDueString = moment(this.toDate).format('HH:mm');
        this.timePickerDisabled = false;
        this.relative = false;
      }
    } else if (!this.fromDate && !this.toDate) {
      this.InitDateModel();
    }
  }

  ngOnChanges() {
    if (
      this.appliedFilters.length
    ) {
      this.dateFilterViewModel = this.appliedFilters[0];
      this.relative = this.dateFilterViewModel.dateRangeOption != FilterDateRangeOptions.Fixed_Date_Range;
      if (this.dateFilterViewModel.dateRangeOption == FilterDateRangeOptions.Fixed_Date_Range) {
        this.fromDate = new Date(this.dateFilterViewModel.filterValue.fromDate);
        this.toDate = new Date(this.dateFilterViewModel.filterValue.toDate);
      }
      if (this.dateFilterViewModel.dateRangeOption == FilterDateRangeOptions.Fixed_Time_Range) {
        this.fromDate = new Date(this.dateFilterViewModel.filterValue.fromDate);
        this.toDate = new Date(this.dateFilterViewModel.filterValue.toDate);
        this.timeStartString = moment(this.fromDate).format('HH:mm');
        this.timeDueString = moment(this.toDate).format('HH:mm');
        this.timePickerDisabled = false;
        this.relative = false;
      }
    } else {
      this.InitDateModel();
    }
    this.changeDetectorRef.detectChanges();
  }

  get dateFormat(): string {
    return this.timeZoneService.dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
  }

  onRelativeDateSelected(dateRangeOption: FilterDateRangeOptions): void {
    if (this.dateFilterViewModel.dateRangeOption == dateRangeOption) {
      this.dateFilterViewModel.dateRangeOption = null;
    } else {
      this.dateFilterViewModel.dateRangeOption = dateRangeOption;
    }

    this.dateFilterViewModel.filterValue.dateRange = this.dateFilterViewModel.dateRangeOption;
    this.filterAdded.next(this.dateFilterViewModel);
  }

  public onDateRangeChanged(change: NgRangeDatesOutput): void {
    let fromDate = change.startDate;
    let endDate = change.endDate;

    if(moment(fromDate).isSame(endDate, 'day')){
      fromDate = moment(fromDate).startOf('day').toISOString();
      endDate = moment(endDate).endOf('day').toISOString();
    }

    this.dateFilterViewModel.dateRangeOption = FilterDateRangeOptions.Fixed_Date_Range;
    const filterValueDateVm = this.dateFilterViewModel.filterValue as FilterValueDateViewModel;
    filterValueDateVm.fromDate = fromDate as string;
    filterValueDateVm.toDate = endDate as string;
    filterValueDateVm.dateRange = FilterDateRangeOptions.Fixed_Date_Range;
    this.dateFilterViewModel.filterValue = filterValueDateVm;

    this.filterAdded.next(this.dateFilterViewModel);
  }

  sanitizeText(filterMember: FilterDateRangeOptions): string {
    return FilterDateRangeOptions[filterMember].toString().replace(/_/g, ' ');
  }

  isSelected(filterMember: FilterDateRangeOptions): boolean {
    return filterMember === this.dateFilterViewModel.dateRangeOption;
  }

  isRelativeChecked(isChecked: boolean): boolean {
    this.relative = isChecked;
    this.changeDetectorRef.detectChanges();
    return this.relative;
  }

  private InitDateModel() {
    this.dateFilterViewModel = FilterUtilities.GenerateDateFilter(this.filterSelectorVM.dateProperty, this.filterSelectorVM.filterSelectorType, undefined )
    this.dateFilterViewModel.displayForGlobalObjectType = this.filterSelectorVM.displayForObjectType;
    this.dateFilterViewModel.id = 0;

    this.dateFilterViewModel.filterValue.id = 0;
    this.dateFilterViewModel.filterValue.fromDate = '';
    this.dateFilterViewModel.filterValue.toDate = '';
  }

  public onExpand(): void {
    const newState = !this.expanded;
    this.toggleChange.emit(newState ? 'open' : 'close');
  }

  public isNoneFilterSelected(): boolean {
    return this.dateFilterViewModel.dateRangeOption == null;
  }

  public isFixedRangeSelected(): boolean {
    return !this.relative
  }

  public onClear(): void {
    if(this.appliedFilters.length) {
      this.filterRemoved.emit();
      this.changeDetectorRef.detectChanges();
    }
  }

  public getDateFilterText(): string {
    const filterValueDateVM = this.dateFilterViewModel;
    if(!filterValueDateVM?.dateRangeOption){
      return '';
    }
    if (filterValueDateVM.dateRangeOption === FilterDateRangeOptions.Fixed_Date_Range) {
      return `${this.timeZoneService.localiseDateISOString(
        filterValueDateVM.filterValue.fromDate,
        false,
        false
      )} to ${this.timeZoneService.localiseDateISOString(filterValueDateVM.filterValue.toDate, false, false)}`;
    } else {
      return this.filterDateRangeOptions.find(
        (filterDateRangeOption) => filterDateRangeOption.key === filterValueDateVM.dateRangeOption
      ).value;
    }
  }

  public openDatepicker(event: MouseEvent): void {
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.datepickerIcon.nativeElement.click();
  }
}
