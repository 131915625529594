import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/app.constants';
import { FilterTypeSelectorViewModel } from '../viewModels/filters/filterTypeSelectorViewModel';

@Injectable({
  providedIn: 'root',
})
export class GenerateAccountFiltersService {
  private url: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.url = this.configuration.buildEndpoint(`Filters/`);
  }

  generateFiltersForAccount(accountId: number) {
    return this.http.post<FilterTypeSelectorViewModel[]>(`${this.url}GenerateForAccount/${accountId}`, {});
  }
}
