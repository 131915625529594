import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  TemplateRef,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { DropdownEventsEmitter } from '../../../events/dropdown.events';

@Component({
  selector: 'app-dropdown-content',
  templateUrl: './dropdown-content.component.html',
  styleUrls: ['./dropdown-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownContentComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLElement>;

  dropdownItemsTemplate: TemplateRef<HTMLElement>;
  visible: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    private readonly dropdownEventsEmitter: DropdownEventsEmitter,
    public readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.dropdownEventsEmitter.dropdownComponentRef = this;

    this.subscriptions.push(
      this.dropdownEventsEmitter.dropdownDimensionsChanged$.subscribe(({ left, top, width }) => {
        const {
          nativeElement: { style },
        } = this.wrapper;

        style.left = `${left}px`;
        style.top = `${top}px`;
        style.width = `${width}px`;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
