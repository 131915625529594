import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum RAGDateTypes {
  Start_Date = 1,
  Due_Date = 2,
}

export namespace RAGDateTypes {
  export function translatedItems(translateService: TranslateService): { key: RAGDateTypes; value: string }[] {
    const a: { key: RAGDateTypes; value: string }[] = [];
    for (const enumMember in RAGDateTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translateItem(+enumMember, translateService) });
      }
    }
    return a;
  }
}

function translateItem(key: RAGDateTypes, translateService: TranslateService) {
  switch (key) {
    case RAGDateTypes.Start_Date:
      return translateService.instant(T.common.start_date.one);
    case RAGDateTypes.Due_Date:
      return translateService.instant(T.defaultLocalizations.due_date.one);
    default:
      return 'unknown';
  }
}
