
<div class="carousel-wrapper" (mouseover)="stopAutoplay()" (mouseleave)="startAutoplay()">
  <div class="carousel-container">
      <div class="carousel-content" [ngClass]="{'slide-in': animateForward, 'slide-out': animateBackward}">
        @for (item of currentItems; track item) {
          <app-suggested-card
            [objectType]="objectType"
            [suggestedItem]="item"
            [showSelectButton]="false"
            [isWithinAddRiskModal]="isWithinAddRiskModal"
            (prefillFormEvent)="prefillForm($event)"
            (click)="isWithinAddRiskModal ? null : selectCard(item)">
          </app-suggested-card>
        }
      </div>
  </div>

  <!-- Controls -->
  <div [ngClass]="{'carousel-arrow prev': true, 'arrow-btn-disabled': isItFirst }" (click)="previousPage()">
    <i class="material-symbols-outlined material-arrow-icon ms-1">arrow_back_ios</i>
  </div>
  <div [ngClass]="{'carousel-arrow next': true, 'arrow-btn-disabled': isItLast }" (click)="nextPage()">
    <i class="material-symbols-outlined material-arrow-icon">arrow_forward_ios</i>
  </div>

  <!-- Indicators -->
  @if (showIndicators) {
    <div class="carousel-indicators">
      @for (indicator of totalPagesArray; track $index;) {
        <div class="carousel-indicator-button" (click)="goToPage($index)" [class.active]="$index === currentPage"></div>
      }
    </div>
  }
</div>
