import { ObjectTypes } from '../enums/objectTypes';
import { UpdateTypes } from '../enums/updateTypes';

export class Comment {
  public id: number;
  public accountId: number;
  public globalObjectId: number;
  public globalObjectType: ObjectTypes;
  public commentType: UpdateTypes;
  public status: number;
  public title: string;
  public rating: number;
  public comment: string;
  public relatedGlobalObjectId: number;
  public createdById: number;
  public created: Date;
}
