import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { SuggestedRiskAction, SuggestedRiskViewModel } from 'src/app/modules/risk/models/SuggestedRiskViewModel';
import { SuggestedRisksService } from 'src/app/modules/risk/services/suggested-risks.service';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { RelevanceNames, RelevanceScores } from 'src/app/modules/shared/enums/relevanceEnums';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { RiskRAGMatrixService } from 'src/app/modules/shared/services/risk-rag-matrix.service';
import { IconTypes } from 'src/app/modules/shared/types/iconTypes';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { RiskRagHelper } from 'src/app/modules/shared/utilities/risk-rag.utilities copy';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-suggested-risk-details-modal',
  templateUrl: './suggested-risk-details-modal.component.html',
  styleUrl: './suggested-risk-details-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestedRiskDetailsModalComponent implements OnInit, OnDestroy {
  @Output() suggestedRiskUpdated = new EventEmitter<SuggestedRiskViewModel>();
  @Output() closed = new EventEmitter();

  public readonly T = T;
  public loading = true;

  public suggestedRisk: SuggestedRiskViewModel;
  protected subscriptions = new Subscription();

  public suggestedRiskActions: SuggestedRiskAction[];
  public filteredSuggestedRiskActions: SuggestedRiskAction[] = [];
  public selectedRiskActions: SuggestedRiskAction[] = [];
  public venueType = 0;

  public filterTypes = FilterTypes;

  public ragDescription: string;
  public markerColor: string;
  public searchText: string = '';

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly bsModalRef: BsModalRef,
    private readonly bsModalService: BsModalService,
    protected readonly translateService: TranslateService,
    protected readonly localisationService: LocalisationService,
    private readonly riskRAGMatrixService: RiskRAGMatrixService,
    protected readonly suggestedRisksService: SuggestedRisksService,
  ){}

  ngOnInit(): void {
    this.setRiskRatingRAGInformation();

    this.subscriptions.add(
      this.suggestedRisksService.getRiskActionsList({title: this.suggestedRisk.title, riskParameters: {venueType: [this.venueType]}}).subscribe(res => {
        this.suggestedRiskActions = res;
        this.filteredSuggestedRiskActions = this.suggestedRiskActions;
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      })
    )

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get riskRelevanceValue(): string {
    if(this.suggestedRisk.relevanceScore <= RelevanceScores.Low) {
      return RelevanceNames.Low;
    } else if(this.suggestedRisk.relevanceScore > RelevanceScores.Low && this.suggestedRisk.relevanceScore <= RelevanceScores.Medium) {
      return RelevanceNames.Medium;
    } else if (this.suggestedRisk.relevanceScore > RelevanceScores.Medium) {
      return RelevanceNames.High;
    }

  }

  public closeModal(): void {
    this.hideModalWithoutEmitting();
    this.closed.emit();
  }

  public hideModalWithoutEmitting(): void {
    this.bsModalRef.hide();
    this.bsModalService._hideBackdrop();
  }

  public onTitleUpdate(title: string): void {
    this.suggestedRisk.title = title;
  }

  public updateDescription(description: string): void {
    this.suggestedRisk.description = description.trim();
  }

  public svgIcon(color = '#FFFFFF'): string {
    return IconUtilities.getSvgForIconType(IconTypes.Risk, 16, false, color);
  }

  public setRiskRatingRAGInformation(): void {
    const ragValue = this.riskRAGMatrixService.getRAGFromImpactAndLikelihood(this.suggestedRisk.impact, this.suggestedRisk.likelihood);
    this.ragDescription = this.localisationService.localiseFilterValueByFilterType(
      ragValue,
      this.filterTypes.Risk_RAG
    ) as string;

    this.markerColor = RiskRagHelper.getRAGColourHexFromValue(ragValue);
  }

  public onListSearch(searchText: string): void {
    this.searchText = searchText;
    this.filteredSuggestedRiskActions = this.suggestedRiskActions.filter(r => r.title?.toLowerCase().includes(this.searchText.toLowerCase()));
    this.changeDetectorRef.detectChanges();
  }

  public selectRiskAction(selectedRiskAction: SuggestedRiskAction): void {
    const action = this.selectedRiskActions?.find(riskActions => riskActions.title === selectedRiskAction.title);
    const filteredAction = this.filteredSuggestedRiskActions.find(x => x.title === selectedRiskAction.title)

    if(!action) {
      filteredAction.isSelected = true;
      this.selectedRiskActions.push(selectedRiskAction);
    } else {
      filteredAction.isSelected = false;
      this.selectedRiskActions = this.selectedRiskActions.filter(riskActions => riskActions.title !== selectedRiskAction.title);
    }

    this.changeDetectorRef.detectChanges();
  }

  public isSelected(title: string): boolean {
    return !!this.suggestedRisk.riskActions?.find(risk => risk.title === title);
  }

  public handleFilterChange(filters: FilterViewModel[]) {
    this.suggestedRisk.filters = filters;
    this.suggestedRisk.impact = filters.find((f) => f.filterType === FilterTypes.Risk_Impact)?.filterValue;
    this.suggestedRisk.likelihood = filters.find((f) => f.filterType === FilterTypes.Risk_Likelihood)?.filterValue;

    this.setRiskRatingRAGInformation();

    this.changeDetectorRef.detectChanges();
  }

  public onAddRisk(): void {
    if(!this.suggestedRisk.riskActions || this.suggestedRisk.riskActions?.length === 0 ) {
      this.suggestedRisk.riskActions = JSON.parse(JSON.stringify(this.selectedRiskActions)) as SuggestedRiskAction[];
    }

    this.suggestedRiskUpdated.emit(this.suggestedRisk);
    this.hideModalWithoutEmitting();
  }
}
