import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  OnChanges,
  HostListener,
  ElementRef,
} from '@angular/core';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { RagHelper } from '../../../utilities/rag.utilities';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { Constants } from 'src/app/modules/shared/models/constants';
import { DetailIconTypes } from '../../../types/DetailsIcon.types';
import { ValidationUtilities } from '../../../utilities/validation.utilities';
import { RAGStatuses } from '../../../enums/ragStatuses';
import { RAGDateTypes } from 'src/app/modules/settings/enums/ragDateTypes';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import * as moment from 'moment';
import { LocalisationService } from '../../../services/localisation.service';
import { Account } from '../../../models/account';
import { RAGDateOrderTypes } from 'src/app/modules/settings/enums/ragDateOrderTypes';
import { PlanningSettingsService } from 'src/app/modules/settings/services/planning-settings.service';
import { ObjectTypes } from '../../../enums/objectTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { RagDropdownTypes } from '../../../types/ragDropdown.types';

@Component({
  selector: 'app-rag-dropdown',
  templateUrl: 'rag-dropdown.component.html',
  styleUrls: ['rag-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RAGDropdownComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions = new Subscription();
  private account: Account;

  localisedTask = 'Task';
  filterType: FilterTypes = FilterTypes.RAG;

  // Rag Comments
  commentRequiredForValues = [RAGStatuses.Red, RAGStatuses.Amber, RAGStatuses.Grey];
  commentPlaceholder: string = this.translateService.instant(T.common.add_comment_to_explain_rag_update);

  // AutRAG properties
  allRagFilters: FilterViewModel[] = [];
  blueAndRedRagFilters: FilterViewModel[] = [];
  warningMessage: string = '';
  public readonly T = T;

  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;

  @Input() ragDropdownType: RagDropdownTypes = 'Badge';
  @Input() showEditDropdownIcon = false;
  @Input() iconType: DetailIconTypes;
  @Input() iconSize: number = 40;
  @Input() appliedFilters: FilterViewModel[];
  @Input() disabled = false;
  @Input() taskCardType: string;
  @Input() showInfo: boolean = true;
  @Input() infoLabel: string;
  @Input() hideArrowIcon: boolean = false;

  /** HUb specific logic if you have filters for multiple accounts such as in hub context, please pass the required account for which you want to get the rag filters */
  @Input() filterAccount?: Account = undefined;

  @Output() onFilterUpdated = new EventEmitter<{ filters: FilterViewModel[]; comment: string }>();

  availableFilters: FilterViewModel[] = [];
  currentFilter: FilterViewModel;
  comment: string;
  includeComment: boolean = false;
  newlySelected: FilterViewModel[] = [];

  //The include comment checkbox state has changed from checked to unchecked
  //after a non comment required item is already selected!
  forceFooterDisplayState = false;

  @HostListener('document:keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.onCancel();
    } else if (event.key === 'Enter') {
      this.onApply();
    }
  }
  @HostListener('document:click', ['$event']) clickout(event) {
    if(!this.elementRef.nativeElement.contains(event.target)) {
      if(this.ngDropdownDirective && this.ngDropdownDirective.visible) {
        this.onCancel();
      }
    }
  }

  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly planningSettingsService: PlanningSettingsService,
    private readonly translateService: TranslateService,
    readonly elementRef: ElementRef<HTMLElement>
  ) {}

  ngOnChanges(): void {
    this.setCurrentFilter();
    this.setAvailableFiltersBasedOnAutoRagRules();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.infoLabel) {
      this.infoLabel = EnumUtilities.items(FilterTypes).find((i) => i.key === this.filterType).value;
    }

    this.allRagFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);

    if (this.filterAccount !== undefined) {
      this.account = this.filterAccount;
      this.allRagFilters = this.allRagFilters .filter(r=>r.accountId === this.account.id);
    } else {
      this.account = this.authenticationService.getCurrentAccount();
    }

    FilterUtilities.setFilterText(this.allRagFilters, this.localisationService, this.translateService);
    this.blueAndRedRagFilters = this.allRagFilters.filter((f) => f.filterValue === 1 || f.filterValue === 4);
    this.availableFilters = this.allRagFilters;
    this.localisedTask = this.localisationService.localiseObjectType(ObjectTypes.Task);
    this.setAvailableFiltersBasedOnAutoRagRules();
    this.setCurrentFilter();

    this.changeDetectorRef.markForCheck();
  }

  // If the account has the autoRAG rule that items change to Red x days before/after start/due date, if a task is more than
  // x days before/after after its start/due date, it should not be possible to change that task's RAG to anything except RED and BLUE.
  // So filter our availableFilters to only Red & Blue, and add warning message
  setAvailableFiltersBasedOnAutoRagRules() {
    if (this.account !== undefined && this.account.enforceAutoUpdateBlueRAGRule) {
      const taskAutoUpdates = this.planningSettingsService.taskAutoUpdates;
      if (taskAutoUpdates.length > 0) {
        const redRule = taskAutoUpdates.find((t) => t.value === 1);
        if (redRule !== undefined) {
          const numberOfDays: number = redRule.days;
          const taskDateFilter =
            redRule.dateToApply === RAGDateTypes.Start_Date
              ? this.appliedFilters.find(
                  (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Date
                )
              : this.appliedFilters.find(
                  (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Date
                );
          const warningMessageKey =
            redRule.dateToApply === RAGDateTypes.Start_Date
              ? T.common.rag_update_item_to_blue_start_date
              : T.common.rag_update_item_to_blue_due_date;

          if (taskDateFilter !== undefined) {
            const dateToCompare =
              redRule.dateOrder === RAGDateOrderTypes.Before
                ? moment(taskDateFilter.filterValue).add(-numberOfDays, 'days')
                : moment(taskDateFilter.filterValue).add(numberOfDays, 'days');

            const diff = moment().diff(dateToCompare, 'day');

            if (diff > 0) {
              this.availableFilters = this.blueAndRedRagFilters;
              this.warningMessage = this.translateService.instant(warningMessageKey, { item: this.localisedTask });
            } else {
              this.warningMessage = '';
              this.availableFilters = this.allRagFilters;
            }
          }
        }
      }
    }
  }

  changeState() {
    this.includeComment = !this.includeComment;
    this.forceFooterDisplayState = !this.includeComment && this.isNewlySelected && !this.isRagRequiringCommentSelected;

    this.changeDetectorRef.detectChanges();
    setTimeout(() => {
      this.ngDropdownDirective.updatePosition();
    }, 10);
  }

  setCurrentFilter() {
    this.currentFilter = this.appliedFilters.find((x) => x.filterType === this.filterType);
    if (this.currentFilter && this.account) {
      this.currentFilter.colour = RagHelper.getRAGColourHexFromValue(parseInt(this.currentFilter.filterValue as string));
      this.currentFilter.filterText = RagHelper.getRAGDescriptionTextFromValue(
        parseInt(this.currentFilter.filterValue as string),
        this.account.id,
        false,
        this.translateService,
        this.localisationService
      );
    }
  }

  get isNewlySelected() {
    return this.newlySelected && this.newlySelected.length;
  }

  get displayFooter(): boolean {
    return this.includeComment || this.forceFooterDisplayState;
  }

  get isRagRequiringCommentSelected(): boolean {
    return !!this.commentRequiredForValues.find(rag => rag === +this.selectedEntry.filterValue);
  }

  get selectedEntry(): FilterViewModel {
    let filter: FilterViewModel;

    if (!this.newlySelected || !this.newlySelected.length) {
      filter = this.appliedFilters.find((f) => f.filterType === this.filterType);
    } else {
      filter = this.newlySelected[0];
    }
    return filter;
  }

  get currentFilterColour(): string {
    let result = '';

    if (this.currentFilter) {
      if (this.currentFilter.colour) {
        result = this.currentFilter.colour;
      } else {
        const colour = RagHelper.getRAGColourHexFromValue(+this.currentFilter.filterValue);
        if (colour) {
          result = colour.toString();
        }
      }
    }

    return result;
  }

  get ragDescription() {
    return RagHelper.getRAGLocalisedDescriptionFromValue(
      +this.currentFilter.filterValue,
      this.localisationService
    );
  }

  get mobile(): boolean {
    return window.innerWidth <= Constants.xs;
  }

  onFiltersWithCommentAdded(ev: { filters: FilterViewModel[]; comment: string }) {
    const filters = this.appliedFilters.filter((s) => s.filterType !== this.filterType);
    filters.push(...ev.filters);
    this.appliedFilters = filters;
    this.comment = ev.comment;

    this.onFilterUpdated.next({ filters: this.appliedFilters, comment: this.comment });

    this.comment = undefined;

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }
  }

  onCancel() {
    this.comment = undefined;
    this.includeComment = false;
    this.forceFooterDisplayState = false;
    this.newlySelected = [];

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }

    this.changeDetectorRef.markForCheck();
  }

  onClear() {
    this.comment = undefined;
    this.includeComment = false;
    this.forceFooterDisplayState = false;
    this.newlySelected = [];

    this.changeDetectorRef.markForCheck();
  }

  onApply() {
    if (this.isInputValid) {
      const matching = this.appliedFilters.find((f) => f.filterType === this.newlySelected[0].filterType);
      if (!matching) {
        this.appliedFilters.push(this.newlySelected[0]);
      } else {
        this.appliedFilters[this.appliedFilters.indexOf(matching)] = this.newlySelected[0];
      }
      this.setCurrentFilter();
      this.onFilterUpdated.next({ filters: this.appliedFilters, comment: this.comment });
      this.onCancel();
    }
  }

  onEntrySelected(entry: FilterViewModel): void {
    this.newlySelected = [];
    this.newlySelected.push(entry);

    if (!this.includeComment) {
      this.setIncludeComment();
    }

    this.changeDetectorRef.detectChanges();
  }

  setIncludeComment() {
    if (this.commentRequiredForValues && this.newlySelected && this.newlySelected[0]) {
      const matching = this.commentRequiredForValues.find((x) => x.toString() === this.newlySelected[0].filterValue.toString());
      this.includeComment = matching !== undefined;

      if (!this.includeComment) {
        this.onApply();
      } else {
        setTimeout(() => {
          this.ngDropdownDirective.updatePosition();
        }, 10);
        this.changeDetectorRef.detectChanges();
      }
    }
  }

  get isInputValid(): boolean {
    let result = true;

    if (!this.newlySelected.length) {
      result = false;
    } else if (
      ((this.commentRequiredForValues &&
        this.commentRequiredForValues.find((v) => v.toString() === this.newlySelected[0].filterValue.toString()) !== undefined) ||
        this.includeComment) &&
      !ValidationUtilities.validateAgainstMinLength(this.comment, 1)
    ) {
      result = false;
    }

    return result;
  }
}
