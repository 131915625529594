import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { ValidatedViewModel } from '../../shared/viewModels/validatedViewModel';
import { ObjectTagViewModel } from '../viewModels/objectTagViewModel';
import { ObjectTagGroupViewModel } from '../viewModels/objectTagGroupViewModel';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { LocalisationService } from '../../shared/services/localisation.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Injectable()
export class ObjectTagService {
  private objectTagUrl: string;
  private objectTagGroupUrl: string;

  public ObjectTagObjectTypeItems: any[] = [
    {
      objectType: ObjectTypes.Project,
      tabsetItemId: ObjectTypes.Project.toString(),
      description: this.translateService.instant(T.settings.tag_manager.item_tags, {
        item: this.localisationService.localiseObjectType(ObjectTypes.Project),
      }),
    },
    {
      objectType: ObjectTypes.Task,
      tabsetItemId: ObjectTypes.Task.toString(),
      description: this.translateService.instant(T.settings.tag_manager.item_tags, {
        item: this.localisationService.localiseObjectType(ObjectTypes.Task),
      }),
    },
    {
      objectType: ObjectTypes.Risk,
      tabsetItemId: ObjectTypes.Risk.toString(),
      description: this.translateService.instant(T.settings.tag_manager.item_tags, {
        item: this.localisationService.localiseObjectType(ObjectTypes.Risk),
      }),
    },
    {
      objectType: ObjectTypes.IncidentItem,
      tabsetItemId: ObjectTypes.IncidentItem.toString(),
      description: this.translateService.instant(T.settings.tag_manager.item_tags, {
        item: this.localisationService.localiseObjectType(ObjectTypes.IncidentItem),
      }),
    },
    {
      objectType: ObjectTypes.Job,
      tabsetItemId: ObjectTypes.Job.toString(),
      description: this.translateService.instant(T.settings.tag_manager.item_tags, {
        item: this.localisationService.localiseObjectType(ObjectTypes.Job),
      }),
    },
    {
      objectType: ObjectTypes.Runsheet_Item,
      tabsetItemId: ObjectTypes.Runsheet_Item.toString(),
      description: this.translateService.instant(T.settings.tag_manager.item_tags, {
        item: this.localisationService.localiseObjectType(ObjectTypes.Runsheet_Item),
      }),
    },
    {
      objectType: ObjectTypes.Indicator,
      tabsetItemId: ObjectTypes.Indicator.toString(),
      description: this.translateService.instant(T.settings.tag_manager.item_tags, {
        item: this.localisationService.localiseObjectType(ObjectTypes.Indicator),
      }),
    },
  ];

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private authenticationService: AuthenticationService,
    private localisationService: LocalisationService,
    private translateService: TranslateService
  ) {
    this.objectTagUrl = this.configuration.buildEndpoint('ObjectTag/');
    this.objectTagGroupUrl = this.configuration.buildEndpoint('ObjectTagGroup/');
  }

  //Object Tags
  addTag(model: ObjectTagViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(`${this.objectTagUrl}Add`, model);
  }

  getTagList(filters: FilterViewModel[]): Observable<ObjectTagViewModel[]> {
    return this.http.post<ObjectTagViewModel[]>(`${this.objectTagUrl}List`, filters);
  }

  deleteTags(tagIds: number[]) {
    return this.http.post(`${this.objectTagUrl}Delete`, tagIds);
  }

  updateTag(model: ObjectTagViewModel): Observable<ObjectTagViewModel> {
    return this.http.post<ObjectTagViewModel>(`${this.objectTagUrl}Update`, model);
  }

  // Object Tag Groups
  addTagGroup(model: ObjectTagGroupViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(`${this.objectTagGroupUrl}Add`, model);
  }

  getTagGroupList(filters: FilterViewModel[]): Observable<ObjectTagGroupViewModel[]> {
    return this.http.post<ObjectTagGroupViewModel[]>(`${this.objectTagGroupUrl}List`, filters);
  }

  updateTagGroup(model: ObjectTagGroupViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(`${this.objectTagGroupUrl}Update`, model);
  }

  deleteTagGroup(tagGroupId: number) {
    return this.http.post(`${this.objectTagGroupUrl}Delete/${tagGroupId}`, null);
  }
}
