import { ContentChild, Directive, Input } from '@angular/core';
import { AccordionContentDirective } from './accordion-content.directive';
import { AccordionHeaderDirective } from './accordion-header.directive';
import { AccordionTitleDirective } from './accordion-title.directive';


@Directive({
  selector: 'app-accordion-item-details',
})
export class AccordionItemDetailsDirective {
  @Input() title = '';
  @Input() disabled = false;
  @Input() expanded = false;
  @Input() visible = true;
  @ContentChild(AccordionContentDirective) content: AccordionContentDirective;
  @ContentChild(AccordionTitleDirective) customTitle: AccordionTitleDirective;
  @ContentChild(AccordionHeaderDirective) customHeader: AccordionHeaderDirective;
}
