import { Colors } from '../../shared/enums/colors';
import { FilterDateOptions } from '../../shared/enums/filter/filterDateOptions';
import { FilterTypes } from '../../shared/enums/filterTypes';
import { IncidentSeverities } from '../../shared/enums/incidents/incidentSeverities';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { EnumUtilities } from '../../shared/utilities/enum.utilities';
import { FilterUtilities } from '../../shared/utilities/filter.utilities';
import { IncidentItemTypes } from '../enums/incidentItemTypes';
import { IncidentStatuses } from '../enums/incidentStatuses';
import { sortDropdownOptions } from '../enums/sortDropdownOptions';
import { IncidentListItemViewModel } from '../viewModels/incidentListItemViewModel';

export class IncidentsUtilities {
  static setIncidentsFilters(inputFilters: FilterViewModel[], incidentStatus: IncidentStatuses): FilterViewModel[] {
    const filters = JSON.parse(JSON.stringify(inputFilters)) as FilterViewModel[];

    if (incidentStatus !== undefined) {
      const incidentStatusFilterSetting = inputFilters.find((s) => s.filterType === FilterTypes.Incident_Status);

      if (!incidentStatusFilterSetting) {
        const statusFilter = FilterUtilities.GenerateFilter(FilterTypes.Incident_Status, incidentStatus.toString(), EnumUtilities.items(IncidentStatuses).find(i => i.key === incidentStatus).value);
        statusFilter.displayForGlobalObjectType = ObjectTypes.IncidentItem;
        filters.push(statusFilter);
      }
    }

    return filters;
  }

  static updateLogItemPropertiesToEscalateToIncident(logItem: IncidentListItemViewModel) {
    logItem.incidentItemType = IncidentItemTypes.Incident;
    const incidentTypeFilter = logItem.filters.find((f) => f.filterType === FilterTypes.Item_Type);
    if (incidentTypeFilter) {
      incidentTypeFilter.filterValue = IncidentItemTypes.Incident;
    } else {
      logItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Item_Type, IncidentItemTypes.Incident));
    }

    logItem.severity = IncidentSeverities.Severity_1;
    const severityFilter = logItem.filters.find((f) => f.filterType === FilterTypes.Incident_Severity);
    if (severityFilter) {
      severityFilter.filterValue = IncidentSeverities.Severity_1;
    } else {
      logItem.filters.push(
        FilterUtilities.GenerateFilter(FilterTypes.Incident_Severity, IncidentSeverities.Severity_1.toString())
      );
    }

    logItem.status = IncidentStatuses.Open;
    const statusFilter = logItem.filters.find((f) => f.filterType === FilterTypes.Incident_Status);
    if (statusFilter) {
      statusFilter.filterValue = IncidentStatuses.Open;
    } else {
      logItem.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Incident_Status, IncidentStatuses.Open.toString()));
    }

    // If there is no start time, set to the created date
    if (!logItem.startTime) {
      logItem.startTime = logItem.created;
      const startTimeFilter = logItem.filters.find(
        (f) => f.filterType == FilterTypes.Date && f.dateProperty == FilterDateOptions.Start_Date
      );
      if (startTimeFilter) {
        startTimeFilter.filterValue = logItem.created;
      } else {
        logItem.filters.push(
          FilterUtilities.GenerateFilter(FilterTypes.Start_Time, logItem.created, '', FilterDateOptions.Start_Date)
        );
      }
    }
  }

  static sortList(selectedSortOption: sortDropdownOptions, items: any[]): any[] {
    switch (selectedSortOption) {
      case sortDropdownOptions.Created_New_Old: {
        return this.sortByCreated(items, true);
      }
      case sortDropdownOptions.Created_Old_New: {
        return this.sortByCreated(items);
      }
      case sortDropdownOptions.Updated_New_Old: {
        return this.sortByDate(items, true);
      }
      case sortDropdownOptions.Updated_Old_New: {
        return this.sortByDate(items);
      }
      case sortDropdownOptions.A_Z: {
        return this.sortByTitle(items);
      }
      case sortDropdownOptions.Z_A: {
        return this.sortByTitle(items, true);
      }
      case sortDropdownOptions.Severity_Low_High: {
        return this.sortBySeverity(items);
      }
      case sortDropdownOptions.Severity_High_Low: {
        return this.sortBySeverity(items, true);
      }
      default: {
        return items;
      }
    }
  }

  static getIncidentStatusFromString(value: string): IncidentStatuses {
    let incidentStatus: IncidentStatuses;

    switch (value) {
      case 'Open': {
        incidentStatus = IncidentStatuses.Open;
        break;
      }
      case 'Draft': {
        incidentStatus = IncidentStatuses.Draft;
        break;
      }
      case 'Closed': {
        incidentStatus = IncidentStatuses.Closed;
        break;
      }
      default: {
        incidentStatus = undefined;
        break;
      }
    }

    return incidentStatus;
  }

  static getIncidentStatusColorHex(status: IncidentStatuses): string {
    switch (status) {
      case IncidentStatuses.Open: {
        return Colors.Black;
      }
      case IncidentStatuses.Draft: {
        return Colors.Grey;
      }
      case IncidentStatuses.Closed: {
        return Colors.Blue_Dark;
      }
      default: {
        return '#000000';
      }
    }
  }

  private static sortByTitle(items: any[], descending: boolean = false): any[] {
    return items
      .sort((a, b) => {
        if (descending) {
          return b.title.localeCompare(a.title);
        } else {
          return a.title.localeCompare(b.title);
        }
      })
      .slice();
  }

  private static sortByDate(items: any[], descending: boolean = false): any[] {
    return items
      .sort((a, b) => {
        const aTime = new Date(a.updated).getTime();
        const bTime = new Date(b.updated).getTime();

        if (descending) {
          return bTime - aTime;
        } else {
          return aTime - bTime;
        }
      })
      .slice();
  }

  private static sortByCreated(items: any[], descending: boolean = false): any[] {
    return items
      .sort((a, b) => {
        const aTime = new Date(a.created).getTime();
        const bTime = new Date(b.created).getTime();

        if (descending) {
          return bTime - aTime;
        } else {
          return aTime - bTime;
        }
      })
      .slice();
  }

  private static sortBySeverity(items: IncidentListItemViewModel[], descending: boolean = false): IncidentListItemViewModel[] {
    return items
      .sort((a, b) => {
        if (descending) {
          return b.severity - a.severity;
        } else {
          return a.severity - b.severity;
        }
      })
      .slice();
  }
}
