@if (isEditMode) {
  <div class="details-text-area-wrapper">
    <div class="first-row">
      <div class="label">{{ T.defaultLocalizations.headline_status.one | translate }}</div>
      <div class="action-buttons">
        <app-button-icon-square
          class="me-2"
          [icon]="'check'"
          [disabled]="modifiedInput === origintalInput"
          (click)="saveHeadlineStatus()">
        </app-button-icon-square>
        <app-button-icon-square
          [icon]="'close'"
          (click)="cancelEdit()">
        </app-button-icon-square>
      </div>
    </div>
    <div class="second-row" [class.new-input-edit-state]="editState">
        <input
          #headlineInput
          class="headline-status-input"
          [class.new-input-edit-state]="isEditMode"
          [type]="'text'"
          [(ngModel)]="modifiedInput"
          autocomplete="off"
          [placeholder]="T.common.click_here_to_update_headline_status | translate"
          [required]="false"
          [minlength]="3"
          [maxlength]="255"
          inputValidation
        />
    </div>
  </div>
}

@if (!isEditMode) {
  <div class="details-text-area-wrapper">
    <div class="first-row">
      <div class="label">{{ T.defaultLocalizations.headline_status.one | translate }}</div>
      @if (isCollapsable) {
        <div class="action-buttons">
          <app-button-transparent
            (clicked)="isCollapsed =! isCollapsed"
            [text]="!isCollapsed ? (T.common.show_less | translate) : (T.common.show_more | translate)"
            size="small"
            iconFontSize="16"
            icon="unfold_more"
            textColor="primary-text"
            class="ms-2">
          </app-button-transparent>
        </div>
      }
    </div>
    <div class="second-row d-flex" (click)="toggleEditMode($event)">
      @if (modifiedInput && modifiedInput.length > 0) {
        <div
          #textArea
          class="text-area"
          [innerHTML]="modifiedInput"
          [class.collapsed]="isCollapsed"
          [style.height]="isCollapsed ? lineHeight + 'px' : 'auto'">
        </div>
        @if (isCollapsed && isCollapsable) {
          <div class="dots">...</div>
        }
        @if (object.headlineStatusUpdated) {
          <ng-container *ngTemplateOutlet="lastUpdated"></ng-container>
        }
      }
      @if (!modifiedInput || modifiedInput.length === 0) {
        <div class="not-edit-state-text empty-state">
          {{ placeholder }}
          @if (object.headlineStatusUpdated) {
            <ng-container *ngTemplateOutlet="lastUpdated"></ng-container>
          }
        </div>
      }
    </div>
  </div>
}

<ng-template #lastUpdated>
  @if (isCollapsed) {
    <span class="last-updated-wrapper">
      {{
        T.common.headline_status_message_last_updated
        | translate: { lastUpdated: object.headlineStatusUpdated | lastUpdatedDate }
      }}
    </span>
  }
</ng-template>
