import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ButtonClasses } from 'src/app/modules/shared/enums/styles/buttonClasses.enum';
import { ButtonSizes } from 'src/app/modules/shared/enums/styles/buttonSizes.enum';
import { ButtonThemes } from 'src/app/modules/shared/enums/styles/buttonThemes.enum';

@Component({
  selector: 'app-svg-button',
  templateUrl: './svg-button.component.html',
  styleUrls: ['./svg-button.component.scss'],
})
export class SvgButtonComponent {
  @Input() svg: string;
  @Input() text: string;
  @Input() selected: boolean;
  @Input() size = ButtonSizes.Small;
  @Input() buttonTheme = ButtonThemes.Primary;
  @Input() isTextTruncate: boolean = false;

  @Output() clicked = new EventEmitter();

  getButtonClasses() {
    const classesArr = [];
    classesArr.push(ButtonClasses[this.size]);
    classesArr.push(ButtonClasses[this.buttonTheme]);
    if (this.isTextTruncate) {
      classesArr.push('button-width-68-px');
    }
    return classesArr;
  }

  onClick(e: Event) {
    this.clicked.emit(e);
  }
}
