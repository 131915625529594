<div class="linked-items-details-list-wrapper" *ngIf="!loading" [style.margin-bottom]="!loading && !canAddLinkedItems && !linkedItems ? '0px' : '24px'">
  <div class="type-container" *ngFor="let type of linkedItems">
    <div class="type-header">
      <app-details-icon
        [size]="18"
        backgroundFill="#939699"
        [type]="convertObjTypeToDetailIconType(type)"
      ></app-details-icon>
      <div class="type-title">{{ getLocalisedObjectType(type.objectType, type.objectSubType) }}:</div>
    </div>
    <div class="linked-item" *ngFor="let item of type.linkedItems">
      <div class="status-color" [style.background-color]="getRagStatusColor(item)"></div>
      <div class="title-container">
        <span class="item-title" (click)="navigateToDetailsPage(item)">{{ item.title }}</span>
        <i
          class="material-symbols-outlined delete-icon"
          [app-tooltip]="{ message: T.common.remove_link | translate, position: ['left'] }"
          (click)="removeLink(item)"
          >close</i
        >
      </div>
    </div>
  </div>
  <app-button-rectangle
    *ngIf="canAddLinkedItems"
    class="add-new-target-button"
    [fitToContainer]="true"
    [icon]="'add_link'"
    [borderColor]="'var(--wt-primary)'"
    [textColor]="'var(--wt-primary)'"
    size="large"
    [text]="T.common.add_item | translate: { item: T.common.linked_item.one | translate }"
    buttonTheme="white"
    [disabled]="false"
    (clicked)="addLinkedItem()"
  >
  </app-button-rectangle>
</div>
<app-loading *ngIf="loading"></app-loading>
