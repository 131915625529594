import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UrlService } from '../modules/shared/services/url.service';
import { AuthenticationService } from '../modules/shared/services/authentication.service';
import { AuthService } from '../modules/auth/auth.service';
import { authConfig } from '../modules/auth/auth.config';

@Component({
  selector: 'app-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss'],
})
export class unAuthorisedComponent implements OnInit {
  public logoUrl: string = '';
  public returnUrl: string;
  public loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private urlService: UrlService,
    private authenticationService: AuthenticationService,
    private isAuthenticationService: AuthService
  ) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] as string || '/v2/mytrack';
    this.logoUrl = this.urlService.buildResourceUrl(this.authenticationService.getCurrentAccount().localLogoURL);
  }

  reload() {
    document.location.href = `${window.location.protocol}//${window.location.host}/${this.returnUrl}`;
  }

  goToAccountList() {
    document.location.href = `${authConfig.issuer}/Account/Select`;
  }
}
