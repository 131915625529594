import { RiskListViewModel } from './RiskListViewModel';
import { RiskPrivacyStatusTypes } from '../enums/riskPrivacyStatusTypes';
import { RiskImpact } from '../enums/riskImpact';
import { RiskRAGStatuses } from 'src/app/modules/shared/enums/planning/riskRagStatuses';
import { RiskLikelihoods } from 'src/app/modules/shared/enums/risks/riskLikelihoods';

export class RiskDetailsViewModel extends RiskListViewModel {
  description: string;
  latitude: number;
  longitude: number;
  additionalInfo: string;
  privacyStatus: RiskPrivacyStatusTypes;
  locationDetails: string;
  baselineLikelihood: RiskLikelihoods;
  baselineImpact: RiskImpact;
  baselineRAG: RiskRAGStatuses;
  isSelected: boolean;
  completedJobs: number;
  totalJobs: number;
}
