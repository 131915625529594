import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  OnDestroy,
  ChangeDetectorRef,
  TemplateRef,
  Input,
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { WindowEventsEmitter } from 'src/app/modules/shared/events/window.events';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { FloatingSidebarEventEmitters } from '../../../events/floating-sidebar.events';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-floating-sidebar',
  templateUrl: './floating-sidebar.component.html',
  styleUrls: ['./floating-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingSidebarComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLDivElement>;
  @ViewChildren('dropdownButton') dropdownButtons: QueryList<ElementRef<HTMLButtonElement>>;
  @Input() title: string;
  @Input() template: TemplateRef<HTMLElement>;
  @Input() showFooterButtons: boolean = true;
  bsModalRef: BsModalRef;
  animationOngoing: boolean = false;
  subscriptions: Subscription[] = [];
  currentEmployee: Employee;
  currentAccount: Account;
  active = false;
  public readonly T = T;

  constructor(
    private readonly sideBarEventEmitters: FloatingSidebarEventEmitters,
    private readonly modalService: BsModalService,
    private readonly windowEventsEmitter: WindowEventsEmitter,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly cachingService: CachingService,
    private readonly localisationService: LocalisationService
  ) {}

  ngOnInit() {
    this.currentAccount = this.cachingService.GetAccountInfo();
    this.currentEmployee = this.cachingService.GetEmployee(this.currentAccount.id);

    this.subscriptions.push(
      this.sideBarEventEmitters.sidebarToggled$.subscribe((res) => {
        const { nativeElement } = this.wrapper;
        const header = document.querySelector<HTMLElement>('.header');

        this.animationOngoing = true;

        setTimeout(() => {
          this.animationOngoing = false;
        }, 300);

        nativeElement.style.top = `${header.offsetHeight}px`;
        nativeElement.classList.toggle('active');
        this.active = !this.active;
      })
    );

    //   this.subscriptions.push(
    //     this.windowEventsEmitter.windowClickEventTriggered$
    //       .pipe(filter(() => !this.animationOngoing))
    //       .subscribe(e => {
    //         const target = e.target as HTMLElement;

    //         if (
    //           target !== this.wrapper.nativeElement &&
    //           !isChildOf(this.wrapper.nativeElement, target)
    //         ) {
    //           this.wrapper.nativeElement.classList.remove('active');
    //         }

    //         const dropdownButtons = this.dropdownButtons
    //           .map(ref => ref.nativeElement as HTMLElement)
    //           .filter(button => button.classList.contains('toggled'));

    //         if (
    //           dropdownButtons.length &&
    //           dropdownButtons.indexOf(target) === -1 &&
    //           dropdownButtons.reduce((prev, curr) => {
    //             return prev && !isChildOf(curr, target);
    //           }, true)
    //         ) {
    //           dropdownButtons.forEach(button => {
    //             button.classList.remove('toggled')
    //             button.parentElement.lastElementChild.classList.remove('show');
    //           });
    //         }
    //       })
    //   );
    // }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onCloseSidebar() {
    this.sideBarEventEmitters.broadcastSidebarToggled(false);
  }

  toggleSelected(e: Event) {
    const target = e.target as HTMLElement;

    target.classList.toggle('toggled');

    const dropdown = target.parentElement.querySelector<HTMLElement>('.dropdown-menu');
    const borderWidth: number = (target.offsetWidth - target.clientWidth) / 2;

    dropdown.style.left = `${target.offsetLeft - borderWidth}px`;
    dropdown.style.top = `${target.offsetTop + target.offsetHeight}px`;

    dropdown.classList.toggle('show');
  }

  onExportToPdf() {}

  onExportToPng() {}

  toggleSidebar() {
    this.sideBarEventEmitters.broadcastSidebarToggled(!this.active);
  }
}
