import { ProgressAnimationType } from 'ngx-toastr';
import { ToastrConfig, ToastrPositions } from './toastr-config.utilities';

export class SignalRToastrConfig extends ToastrConfig {
  constructor(
    _position: ToastrPositions = ToastrPositions.Top_Right,
    _timeOut: number = 4000,
    _closeButton: boolean = true,
    _progressBar: boolean = false,
    _progressAnimation: ProgressAnimationType = 'increasing'
  ) {
    super(_position, _timeOut, _closeButton, _progressBar, _progressAnimation);
    this.enableHtml = true;
  }
}
