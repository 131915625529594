import { Component, Input, OnInit } from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';

@Component({
  selector: 'app-owner-avatar',
  templateUrl: './owner-avatar.component.html',
  styleUrls: ['./owner-avatar.component.scss'],
})
export class OwnerAvatarComponent implements OnInit {
  @Input() ownerFilter: FilterViewModel;
  @Input() remaining: number;
  name: string;

  ngOnInit(): void {
    this.name = this.ownerFilter?.filterText;
  }

  get initials(): string {
    const tokens = this.ownerFilter.filterText.split(' ');
    const firstInitial = tokens[0].substring(0, 1).toUpperCase();
    const secondInitial = tokens.length > 1 ? tokens[1].substring(0, 1).toUpperCase() : '';

    return `${firstInitial + secondInitial}`;
  }
}
