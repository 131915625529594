import { ObjectTypes } from '../enums/objectTypes';
import { UpdateTypes } from '../enums/updateTypes';

export class NotificationViewModel {
  objectId: number;
  objectType: ObjectTypes;
  commentType: UpdateTypes;
  employeeIds: number[];
  currentEmployeeId: number;
  itemUrl: string;
  commentText: string;
}
