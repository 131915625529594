@if (loading) {
  <app-loading>
    <p>{{ T.common.loading | translate }}...</p>
  </app-loading>
}

@else {
  <app-modal>
    <app-modal-header
      [iconType]="iconType"
      [richIconType]="richIconType"
      [title]="headerText"
      (closed)="close()"
    >
    </app-modal-header>
    <app-modal-body>
      <div class="content-wrapper">
        <main>
          <section class="header-details">
            <div class="left-section">
              <app-details-dropdown
                [accountId]="riskItem.accountId"
                [isStatus]="true"
                [displayLabel]="false"
                [label]="T.common.status.one | translate"
                [canEdit]="canEdit"
                [filterType]="filterTypes.Risk_Status"
                (onFiltersUpdated)="onFiltersUpdated($event)"
                [statusBars]="false"
                [appliedFilters]="riskItem.filters"
                [showDropdownArrow]="true"
                [arrowPosition]="'right'"
              ></app-details-dropdown>
              <div class="separator"></div>
              <app-details-lozenge
                [accountId]="riskItem.accountId"
                [filters]="riskItem.filters"
                [filterType]="filterTypes.Risk_Impact_Type"
                [canEdit]="canEdit"
                [accordionLozenge]="true"
                (onFiltersUpdated)="onFiltersUpdated($event)"
                [singleSelect]="false"
                [accordionOverflowDivider]="', '"
              >
              </app-details-lozenge>
              <div class="separator"></div>
              <div class="date-container">
                <app-icon name="event" size="tiny" class="me-1"></app-icon>
                <span *ngIf="riskItem.dueDate" class="date-value">{{ riskItem.dueDate | localisedDate: false}}</span>
                <span *ngIf="!riskItem.dueDate" class="date-value">{{ T.common.no_date | translate }}</span>
              </div>
            </div>
            <div class="right-section">
              <div class="employee-container">
                <app-employee-avatar *ngIf="ownerData" [employee]="ownerData"></app-employee-avatar>
                <span class="employee-name">{{ ownerNames }}</span>
              </div>
            </div>
          </section>
          <section class="title-wrapper">
            <app-simple-editable-field
              [input]="riskItem.title"
              [canEdit]="canEdit"
              [fontSize]="20"
              [lineClamp]="2"
              (onUpdate)="onTitleUpdate($event)"
            ></app-simple-editable-field>
            <div class="ref-code">ID: {{riskItem.id}}</div>
          </section>
          <section class="headline-status-wrapper">
            <app-headline-status
              [object]="riskItem"
              (onChanged)="onHeadlineStatusUpdated($event)"
            ></app-headline-status>
          </section>
          <section class="description-wrapper">
            <app-details-text-area
              [input]="riskDescriptionText"
              [label]="T.defaultLocalizations.description.one | translate"
              [placeholder]="T.common.add_description | translate"
              [canEdit]="canEdit"
              (update)="onDescriptionUpdate($event)"
            ></app-details-text-area>
          </section>
          <section class="risk-details">
            <div class="risk-raiting-container">
              <div class="lozenge-label mb-1">{{ T.common.risk_rating | translate }}</div>
              <app-badge
                class="d-flex"
                color="custom"
                variant="fat"
                [label]="ragDescription"
                [svgIcon]="svgIcon"
                [customColor]="ragColor"
              ></app-badge>
            </div>
            <div class="risk-likehood-container">
              <app-details-dropdown
                [filterType]="filterTypes.Risk_Likelihood"
                [canEdit]="false"
                [canEditMessage]="''"
                [statusBars]="true"
                [appliedFilters]="riskItem.filters"
                [excludeFilterValues]="[]"
                [handleClickExternally]="true"
              >
              </app-details-dropdown>
            </div>
            <div class="risk-impact-container">
              <app-details-dropdown
                [filterType]="filterTypes.Risk_Impact"
                [canEdit]="false"
                [canEditMessage]="''"
                [statusBars]="true"
                [appliedFilters]="riskItem.filters"
                [handleClickExternally]="true"
              ></app-details-dropdown>
            </div>
            <div class="risk-actions-container">
              <div class="lozenge-label mb-1">{{ T.common.action.many | translate }}</div>
              <div class="font-size-14 fw-bold">
                {{
                  T.common.count_of_total_actions_completed
                    | translate : { count: actionsBreakdown.completed, total: actionsBreakdown.total }
                }}
              </div>
            </div>
          </section>
          <hr>
          <section class="additional-data">
            <div class="row details-row">
              <div class="col-2 lozenge-label pe-0">{{ T.common.created | translate }}</div>
              <div class="col-10 details-value">{{ createdDate | localisedDate: true }}</div>
            </div>
            <div class="row details-row">
              <div class="col-2 lozenge-label pe-0">{{ T.defaultLocalizations.owner.many | translate }}</div>
              <div class="col-10 details-value">
                {{ownerNames}}
              </div>
            </div>
          </section>
          <hr>
        </main>
        <aside>
          <app-accordion-details [collapsing]="false" (onItemIndexClicked)="onItemIndexClicked($event)">
            <app-accordion-item-details [title]="T.common.details | translate" [expanded]="false">
              <ng-template accordionContent>
                <div class="details-container">
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">
                      {{ objectTypes.Department | localiseObjectType : true }}
                    </div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        class="w-100"
                        [accountId]="riskItem.accountId"
                        [filters]="riskItem.filters"
                        [filterType]="filterTypes.Department"
                        [canEdit]="canEdit"
                        [accordionLozenge]="true"
                        [useLightUpdate]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.owner.many | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                      [accountId]="riskItem.accountId"
                      [useLightUpdate]="true"
                      [accordionLozenge]="true"
                      [filters]="riskItem?.filters"
                      [filterType]="filterTypes.Owner"
                      [canEdit]="canEdit"
                      (onFiltersUpdated)="onFiltersUpdated($event)"
                    >
                    </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ objectTypes.Project | localiseObjectType }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        class="w-100"
                        [accountId]="riskItem.accountId"
                        [filters]="riskItem.filters"
                        [filterType]="filterTypes.Project"
                        [canEdit]="canEdit"
                        [accordionLozenge]="true"
                        [useLightUpdate]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">
                      {{ T.common.impact_type.one | translate : { impact: translatedImpact } }}
                    </div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        class="w-100"
                        [accountId]="riskItem.accountId"
                        [filters]="riskItem.filters"
                        [filterType]="filterTypes.Risk_Impact_Type"
                        [canEdit]="canEdit"
                        [accordionLozenge]="true"
                        [useLightUpdate]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.common.strategy.one | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        class="w-100"
                        [accountId]="riskItem.accountId"
                        [filters]="riskItem.filters"
                        [filterType]="filterTypes.Risk_Strategy"
                        [canEdit]="canEdit"
                        [accordionLozenge]="true"
                        [singleSelect]="true"
                        [useLightUpdate]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.privacy_status.one | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        class="w-100"
                        [accountId]="riskItem.accountId"
                        [filters]="riskItem.filters"
                        [filterType]="filterTypes.Risk_Privacy_Status"
                        [canEdit]="canEdit"
                        [accordionLozenge]="true"
                        [useLightUpdate]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)"
                        [singleSelect]="true"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.due_date.one | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-datepicker
                        [date]="riskItem.dueDate"
                        [canEdit]="canEdit"
                        [accordionLozenge]="true"
                        [label]="T.defaultLocalizations.due_date.one | translate"
                        (dateChanged)="onDueDateChange($event)"
                      ></app-details-datepicker>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.identified_by.one | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        class="w-100"
                        [accountId]="riskItem.accountId"
                        [filters]="riskItem.filters"
                        [filterType]="filterTypes.Identified_By"
                        [canEdit]="canEdit"
                        [accordionLozenge]="true"
                        [useLightUpdate]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)"
                        [singleSelect]="true"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.event_type.one | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        class="w-100"
                        [accountId]="riskItem.accountId"
                        [filters]="riskItem.filters"
                        [filterType]="filterTypes.Event_Type"
                        [canEdit]="canEdit"
                        [accordionLozenge]="true"
                        [useLightUpdate]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)"
                        [singleSelect]="true"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                </div>
              </ng-template>
            </app-accordion-item-details>
            <app-accordion-item-details [title]="'History'" [expanded]="false">
              <ng-template accordionContent>
                <div *ngIf="historyExpanded" class="history-feed-wrapper">
                  <app-history-feed-new
                    [isModal]="true"
                    [canEdit]="canEdit"
                    [showInput]="true"
                    [objectId]="riskItem.id"
                    [objectType]="objectType"
                    [showHeader]="false"
                    [singleItemHistory]="true"
                    [childHubAccountId]="riskItem.accountId"
                  >
                  </app-history-feed-new>
                </div>
              </ng-template>
            </app-accordion-item-details>
          </app-accordion-details>
        </aside>
      </div>
    </app-modal-body>
    <app-modal-footer>
      <div class="footer-wrapper">
        <app-button-rectangle
          [text]="T.common.view_full_details | translate"
          [size]="'medium'"
          [buttonTheme]="'primary'"
          (clicked)="navigateToDetaislPage()"
        ></app-button-rectangle>
      </div>
    </app-modal-footer>
  </app-modal>
}
