<section [ngClass]="{'suggested-risks-list-wrapper': true, 'open': !hideSuggestedRisksCarousel, 'open-in-add-risk-modal' : isWithinAddRiskModal && !hideSuggestedRisksCarousel}">
  <header class="suggested-risks-list-header">
    <div class="d-flex align-items-center">
      <h3 class="suggested-risks-list-title"> {{ T.common.suggested_risks | translate }} </h3>
      @if(!hideSuggestedRisksCarousel){
        <div class="suggested-risks-list-button" (click)="openSuggestedRisksModal()"> {{ T.common.see_all | translate }} </div>
      } @else {
        <div class="suggested-risks-list-button" (click)="toggleHideSuggestedRisksCarousel(false)">{{ T.common.show_more | translate }}</div>
      }
    </div>
    @if(!hideSuggestedRisksCarousel){
      <div *ngIf="!isWithinAddRiskModal" class="d-flex align-items-center suggested-risks-list-button" (click)="toggleHideSuggestedRisksCarousel(true)">
        <span>{{ T.common.hide | translate}}</span>
        <span class="material-symbols-outlined">
          close
        </span>
      </div>
      <span *ngIf="isWithinAddRiskModal" class="material-symbols-outlined close-icon" (click)="toggleHideSuggestedRisksCarousel(true)">close</span>
    }
  </header>
  @if(!hideSuggestedRisksCarousel && !loading){
    <app-carousel
      [objectType]="objectType"
      [items]="suggestedRisks"
      [itemsPerPage]="numberOfCardsToDisplay"
      (selectedItem)="openSuggestedRisksModal($event)"
      [isWithinAddRiskModal]="isWithinAddRiskModal"
      (prefillFormEvent)="handlePrefillForm($event)"
    ></app-carousel>
  } @else if(!hideSuggestedRisksCarousel && loading) {
    <app-loading></app-loading>
  }
</section>
