import { Injectable } from '@angular/core';
import { CachingService } from './caching.service';
import { Account } from '../models/account';
import { EmployeeRoleTypes } from '../enums/employees/EmployeeRoleTypes';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { environment } from 'src/environments/environment';
import { Employee } from '../models/employee';
declare let pendo: any;

@Injectable({
  providedIn: 'root'
})
export class PendoService {
  private account: Account;

  constructor(private cachingService: CachingService) {}

  public init(employeeDetails: Employee) {
    this.account = this.cachingService.GetAccountInfo();
    const clusterName = this.getClusterName();

    pendo.initialize({
      visitor: {
        id: `${clusterName}-${employeeDetails.id}`,
        email: employeeDetails.username,
        full_name: `${employeeDetails.firstName} ${employeeDetails.surname}`,
        role: employeeDetails.employeeRole.map(item => EmployeeRoleTypes[item.roleId]).join(","),
        module_access: employeeDetails.permissions.map(item => ModuleTypes[item.module]).join(","),
        language: employeeDetails.languageCode
      },
      account: {
        id: `${clusterName}-${this.account.id}`,
        account_name: this.account.title,
        salesforceid: this.account.saleForceID ? this.account.saleForceID : ''
      }
    });
  }

  private getClusterName(): string {
    const currentEnvironment = environment?.CLUSTER_NAME;
    return currentEnvironment;
  }
}

