import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FilterDateOptions } from '../enums/filter/filterDateOptions';
import { FilterDateRangeOptions } from '../enums/filter/filterDateRangeOptions';
import { FilterSelectorTypes } from '../enums/filter/filterSelectorTypes';
import { FilterTypes } from '../enums/filterTypes';
import { ObjectTypes } from '../enums/objectTypes';
import { EmployeeCustomFilterViewModel } from '../models/filter/employeeCustomFilterViewModel';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { v4 as uuidv4 } from 'uuid';
import { LocalisationService } from '../services/localisation.service';
import { AuthenticationService } from '../services/authentication.service';
import { Employee } from '../models/employee';
import { RAGStatuses } from '../enums/ragStatuses';
import { MilestoneTypes } from '../enums/milestoneTypes';
import { RiskRAGStatuses } from '../enums/planning/riskRagStatuses';
import { IncidentStatuses } from '../../incidents/enums/incidentStatuses';
import { IncidentSeverities } from '../enums/incidents/incidentSeverities';
import { RiskStatusTypes } from '../../risk/enums/riskStatusTypes';

@Injectable({ providedIn: 'root' })
export class MockedSuggestedFiltersService {
  private employee: Employee;

  constructor(private readonly localisationService: LocalisationService, private readonly authService: AuthenticationService) {
    this.employee = authService.getCurrentEmployee();
  }

  public getSuggestedFilters(accountId: number, employee: Employee): Observable<EmployeeCustomFilterViewModel[]> {
    const employeeId = employee.id;
    this.employee = employee;
    const suggestedFilters: EmployeeCustomFilterViewModel[] = [
      {
        title: `My ${this.localiseString('Tasks')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Owner,
            filterValue: employeeId,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `My ${this.localiseString('Tasks')} Due Next 30 Days`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Owner,
            filterValue: employeeId,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Date,
            dateProperty: FilterDateOptions.Due_Date,
            filterSelectorType: FilterSelectorTypes.Date,
            dateRangeOption: FilterDateRangeOptions.Next_30_days,
            filterValue: {
              dateProperty: FilterDateOptions.Due_Date,
              dateRange: FilterDateRangeOptions.Next_30_days,
            },
            displayForGlobalObjectType: ObjectTypes.Task,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `Open ${this.localiseString('Tasks')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Red,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Amber,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Grey,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Green,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `My Open ${this.localiseString('Tasks')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Owner,
            filterValue: employeeId,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Red,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Amber,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Grey,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Green,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `My Red ${this.localiseString('Tasks')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Owner,
            filterValue: employeeId,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Red,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `Milestones Due Next 3 Months`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Milestone_Type,
            filterValue: MilestoneTypes.Bronze,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Milestone_Type,
            filterValue: MilestoneTypes.Silver,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Milestone_Type,
            filterValue: MilestoneTypes.Gold,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Date,
            dateProperty: FilterDateOptions.Due_Date,
            filterSelectorType: FilterSelectorTypes.Date,
            dateRangeOption: FilterDateRangeOptions.Next_3_months,
            filterValue: {
              dateProperty: FilterDateOptions.Due_Date,
              dateRange: FilterDateRangeOptions.Next_3_months,
            },
            displayForGlobalObjectType: ObjectTypes.Task,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `My ${this.localiseString('Projects')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Owner,
            filterValue: employeeId,
            displayForGlobalObjectType: ObjectTypes.Project,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `Only Milestones`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Milestone_Type,
            filterValue: MilestoneTypes.Bronze,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Milestone_Type,
            filterValue: MilestoneTypes.Silver,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Milestone_Type,
            filterValue: MilestoneTypes.Gold,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `${this.localisationService.localiseFilterValueByFilterType(
          MilestoneTypes.Gold,
          FilterTypes.Milestone_Type
        )} Milestones`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Milestone_Type,
            filterValue: MilestoneTypes.Gold,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `My ${this.localiseString('Risks')}, etc.`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Owner,
            filterValue: employeeId,
            displayForGlobalObjectType: ObjectTypes.Risk,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `Overdue ${this.localiseString('Risks')}, etc.`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            exclude: false,
            filterSelectorType: FilterSelectorTypes.Dropdown,
            displayForGlobalObjectType: ObjectTypes.Risk,
            filterType: FilterTypes.Risk_Status,
            filterValue: RiskStatusTypes.Open,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Date,
            dateProperty: FilterDateOptions.Due_Date,
            filterSelectorType: FilterSelectorTypes.Date,
            dateRangeOption: FilterDateRangeOptions.Up_to_today,
            displayForGlobalObjectType: ObjectTypes.Risk,
            filterValue: {
              dateProperty: FilterDateOptions.Due_Date,
              dateRange: FilterDateRangeOptions.Up_to_today,
            },
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `Red ${this.localiseString('Risks')} and ${this.localiseString('Issues')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterSelectorType: FilterSelectorTypes.Dropdown,
            displayForGlobalObjectType: ObjectTypes.Risk,
            filterType: FilterTypes.Risk_RAG,
            filterValue: RiskRAGStatuses.Extreme,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `${this.localiseString('Risks')}, etc. Created Last 7 Days`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Date,
            dateProperty: FilterDateOptions.Created,
            filterSelectorType: FilterSelectorTypes.Date,
            dateRangeOption: FilterDateRangeOptions.Last_7_days,
            displayForGlobalObjectType: ObjectTypes.Risk,
            filterValue: {
              dateProperty: FilterDateOptions.Created,
              dateRange: FilterDateRangeOptions.Last_7_days,
            },
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `My Open ${this.localiseString('Incidents')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Owner,
            filterValue: employeeId,
            displayForGlobalObjectType: ObjectTypes.IncidentItem,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Incident_Status,
            filterValue: IncidentStatuses.Open,
            displayForGlobalObjectType: ObjectTypes.IncidentItem,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `Severity 5 ${this.localiseString('Incidents')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Incident_Severity,
            filterValue: IncidentSeverities.Severity_5,
            displayForGlobalObjectType: ObjectTypes.IncidentItem,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `${this.localiseString('Incidents')} Created Today`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Date,
            dateProperty: FilterDateOptions.Created,
            filterSelectorType: FilterSelectorTypes.Date,
            dateRangeOption: FilterDateRangeOptions.Today,
            displayForGlobalObjectType: ObjectTypes.IncidentItem,
            filterValue: {
              dateProperty: FilterDateOptions.Created,
              dateRange: FilterDateRangeOptions.Today,
            },
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `${this.localiseString('Incidents')} Updated Today`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Date,
            dateProperty: FilterDateOptions.Updated,
            filterSelectorType: FilterSelectorTypes.Date,
            dateRangeOption: FilterDateRangeOptions.Today,
            displayForGlobalObjectType: ObjectTypes.IncidentItem,
            filterValue: {
              dateProperty: FilterDateOptions.Updated,
              dateRange: FilterDateRangeOptions.Today,
            },
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
      {
        title: `${this.localiseString('Incidents')} Closed Today`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Date,
            dateProperty: FilterDateOptions.Closed,
            filterSelectorType: FilterSelectorTypes.Date,
            dateRangeOption: FilterDateRangeOptions.Today,
            displayForGlobalObjectType: ObjectTypes.IncidentItem,
            filterValue: {
              dateProperty: FilterDateOptions.Closed,
              dateRange: FilterDateRangeOptions.Today,
            },
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      },
    ];

    const departmentId = this.employee?.departmentId;
    if (departmentId) {
      // TASKS RELATED DEPARTMENTS -- starts here
      const MyDepartmentsTasks: EmployeeCustomFilterViewModel = {
        title: `My ${this.localiseString('Department')}'s ${this.localiseString('Tasks')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Department,
            filterValue: departmentId,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterText: '',
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      };

      const myDepartmentsDue30Days: EmployeeCustomFilterViewModel = {
        title: `My ${this.localiseString('Department')}'s ${this.localiseString('Tasks')} Due Next 30 Days`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Department,
            filterValue: departmentId,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterText: '',
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Date,
            dateProperty: FilterDateOptions.Due_Date,
            filterSelectorType: FilterSelectorTypes.Date,
            dateRangeOption: FilterDateRangeOptions.Next_30_days,
            filterValue: {
              dateProperty: FilterDateOptions.Due_Date,
              dateRange: FilterDateRangeOptions.Next_30_days,
            },
            displayForGlobalObjectType: ObjectTypes.Task,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      };

      const myDepartmentsRedTasks: EmployeeCustomFilterViewModel = {
        title: `My ${this.localiseString('Department')}'s Red ${this.localiseString('Tasks')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Department,
            filterValue: departmentId,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterText: '',
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.RAG,
            filterValue: RAGStatuses.Red,
            displayForGlobalObjectType: ObjectTypes.Task,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      };

      suggestedFilters.splice(5, 0, MyDepartmentsTasks, myDepartmentsDue30Days, myDepartmentsRedTasks);
      // ENDS HERE -- tasks related departments

      // RISK RELATED DEPARTMENTS -- starts here
      const myDepartmentsRisks: EmployeeCustomFilterViewModel = {
        title: `My ${this.localiseString('Department')}'s ${this.localiseString('Risks')}, etc.`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Department,
            filterValue: departmentId,
            displayForGlobalObjectType: ObjectTypes.Risk,
            filterText: '',
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      };

      const indexOfMyRisks = suggestedFilters.findIndex((employeeFilt) =>
        employeeFilt.filters.some((f) => f.filterType == FilterTypes.Owner && f.displayForGlobalObjectType == ObjectTypes.Risk)
      );

      suggestedFilters.splice(indexOfMyRisks + 1, 0, myDepartmentsRisks);
      // ENDS HERE -- risks related departments

      // INCIDENTS RELATED DEPARTMENTS -- starts here
      const myDepartmentsOpenIncidents: EmployeeCustomFilterViewModel = {
        title: `My Open ${this.localiseString('Incidents')}`,
        accountId: accountId,
        employeeId: employeeId,
        filters: [
          {
            filterType: FilterTypes.Department,
            filterValue: departmentId,
            displayForGlobalObjectType: ObjectTypes.IncidentItem,
            filterText: '',
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
          {
            filterType: FilterTypes.Incident_Status,
            filterValue: IncidentStatuses.Open,
            displayForGlobalObjectType: ObjectTypes.IncidentItem,
            filterSelectorType: FilterSelectorTypes.Dropdown,
          } as FilterViewModel,
        ],
        globalObjectType: ObjectTypes.Favorite_Filters,
        id: uuidv4() as unknown as number,
      };

      suggestedFilters.splice(-1, 0, myDepartmentsOpenIncidents);
      // ENDS HERE -- incidents related departments
    }

    return of(suggestedFilters);
  }

  private localiseString(str: string) {
    return this.localisationService.localise(str);
  }

  private localiseFilterType(ft: FilterTypes) {
    return this.localisationService.localiseFilterType(ft);
  }
}
