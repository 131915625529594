import {
  Component,
  OnInit,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ProjectDetailsViewModel } from '../../../viewModels/projects/projectDetailsViewModel';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { ZoneViewModel } from 'src/app/modules/settings/viewModels/zoneViewModel';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { IncidentsSettingsService } from 'src/app/modules/shared/services/indicents-settings.service';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { GMapUtilities } from 'src/app/modules/shared/utilities/gMap.utilities';
import { LocationAndFiltersViewModel } from 'src/app/modules/shared/viewModels/locationAndFiltersViewModel';

@Component({
  selector: 'app-location-details-edit-modal',
  templateUrl: './location-details-edit-modal.component.html',
  styleUrls: ['./location-details-edit-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDetailsEditModalComponent implements OnInit, OnDestroy {
  private employee: Employee;
  private availableAreaFilters: FilterViewModel[] = [];

  public loading = true;
  public zonesAndAreasLoading = true;

  private subscriptions = new Subscription();
  private isTouched = false;

  public isHiddenOptionVisible = false;
  public mobileMaxWidth = Constants.sm;

  project = new ProjectDetailsViewModel();
  filterTypes = FilterTypes;
  filters: FilterViewModel[] = [];
  locationDetails: string;
  latitude = 0.0;
  longitude = 0.0;
  locationViewModel: LocationViewModel;
  selectedZones: ZoneViewModel[] = [];
  selectedAreas: AreaViewModel[] = [];
  markerType: MarkerType;
  objectType: ObjectTypes;
  markerColor: string;
  onSave = new EventEmitter<LocationAndFiltersViewModel>();

  applicableAreaFilters: FilterViewModel[] = [];

  public localisedZones: string;
  public localisedAreas: string;

  //Zones and areas data
  public zones: ZoneViewModel[] = [];
  public areas: AreaViewModel[] = [];
  public readonly T = T;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmit();
      return;
    }
  }
  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    if (event.target.innerWidth < this.mobileMaxWidth) {
      this.isHiddenOptionVisible = true;
    }
  }

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly elemRef: ElementRef,
    private readonly confirmationService: ConfirmationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly localisationService: LocalisationService,
    private readonly incidentsSettingsService: IncidentsSettingsService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.localisedAreas = this.localisationService.localiseObjectType(ObjectTypes.Area, true);
    this.localisedZones = this.localisationService.localiseObjectType(ObjectTypes.Zone, true);
    if (window.innerWidth < this.mobileMaxWidth) {
      this.isHiddenOptionVisible = true;
    }

    if(!this.markerType) {
      this.markerType = GMapUtilities.getMarkerTypeFromObjectType(this.objectType);
    }

    this.filters = FilterUtilities.DistinctFilterValues(this.filters);
    this.getAreaFilters();
    this.getZonesAndAreas();

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  showHiddenOptions() {
    this.isHiddenOptionVisible = !this.isHiddenOptionVisible;
    this.changeDetectorRef.detectChanges();
  }

  handleFilterChange(filters: FilterViewModel[]) {
    const zonesOnlyFilters = filters.filter((f) => f.filterType === FilterTypes.Zone);
    this.filters = filters.filter((f) => {
      if (f.filterType === FilterTypes.Area) {
        return zonesOnlyFilters.some((f1) => +f1.filterValue === f.relatedObjectId);
      }

      return true;
    });

    this.populateZonesAndAreasDropdownFields();
    this.changeDetectorRef.detectChanges();
  }

  confirmClose() {
    if (this.isTouched) {
      const onCloseModal = () => {
        this.closeModal();
      };
      const onCancel = () => {};
      const areYouSureYouWantToLeaveText = this.translateService.instant(T.common.are_sure_you_want_to_leave_page);
      const confirmText = this.translateService.instant(T.common.confirm);
      const leaveText = this.translateService.instant(T.common.leave);
      const stayText = this.translateService.instant(T.common.stay);

      this.confirmationService.confirmThis(
        areYouSureYouWantToLeaveText,
        onCloseModal,
        onCancel,
        confirmText,
        false,
        stayText,
        leaveText,
        'danger'
      );
    } else {
      this.closeModal();
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  handleSubmit() {
    const returnModel: LocationAndFiltersViewModel = {
      filters: this.filters,
      latitude: this.latitude,
      longitude: this.longitude,
      locationDetails: this.locationDetails,
    };

    this.onSave.next(returnModel);
    this.closeModal();
  }

  onLocationUpdated(locationViewModel: LocationViewModel) {
    this.locationViewModel = locationViewModel;
    this.longitude = locationViewModel.longitude;
    this.latitude = locationViewModel.latitude;
    this.locationDetails = locationViewModel.locationDetails;
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Assigns to a global reference the available area filters (FilterTypes.AREA)
   * taken from the filters service.
   *
   * This method should be called ONLY from the component's init method and the reason
   * it exists is to group related array manipulation.
   */
  private getAreaFilters() {
    this.availableAreaFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Area);
    this.availableAreaFilters = this.availableAreaFilters.filter(UniqueItems);
  }

  private getZonesAndAreas() {
    this.subscriptions.add(
      forkJoin([this.incidentsSettingsService.getIncidentZonesViewModels(), this.incidentsSettingsService.getAreas()]).subscribe(
        ([zones, areas]) => {
          this.zones = zones.filter(UniqueItems);
          this.areas = areas.filter(UniqueItems);
          this.populateZonesAndAreasDropdownFields();

          this.loading = false;
          this.zonesAndAreasLoading = false;
          this.changeDetectorRef.detectChanges();
        }
      )
    );
  }

  private getZoneViewModelsFromFilters(): ZoneViewModel[] {
    const result: ZoneViewModel[] = [];

    this.filters
      .filter((f) => f.filterType === FilterTypes.Zone)
      .filter(UniqueItems)
      .forEach((filter) => {
        const zone = this.zones.find((z) => z.id === +filter.filterValue);
        if (zone) {
          result.push(zone);
        }
      });

    return result;
  }

  private getAreaViewModelsFromFilters(): AreaViewModel[] {
    const result: AreaViewModel[] = [];

    this.filters
      .filter((f) => f.filterType === FilterTypes.Area)
      .forEach((filter) => {
        const area = this.areas.find((a) => a.id === +filter.filterValue);
        if (area) {
          result.push(area);
        }
      });

    return result;
  }

  private setApplicableFilters() {
    if (this.selectedZones && this.selectedZones.length) {
      this.applicableAreaFilters = this.selectedZones.flatMap((zone) =>
        this.availableAreaFilters.filter((a) => +a.relatedObjectId === zone.id)
      );

      this.filters.forEach((f) => {
        if (f.filterType === FilterTypes.Area) {
          f.relatedObjectId = this.applicableAreaFilters.find((af) => af.filterValue === f.filterValue)?.relatedObjectId;
        }
      });
    } else {
      this.selectedAreas = [];
      this.applicableAreaFilters = [];
    }
  }

  private populateZonesAndAreasDropdownFields() {
    this.selectedZones = this.getZoneViewModelsFromFilters();
    this.selectedAreas = this.getAreaViewModelsFromFilters();

    if (!this.selectedZones.length) {
      this.selectedAreas = [];
    }

    this.setApplicableFilters();
    this.changeDetectorRef.markForCheck();
  }
}

function UniqueItems(): (value: FilterViewModel, index: number, array: FilterViewModel[]) => unknown {
  return (filter, index, self) => {
    return self.findIndex((f) => f.id === filter.id) === index;
  };
}
