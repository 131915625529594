<!-- Map container -->
<div #map class="map-wraper"></div>

<!-- Floating controls -->
<ng-container *ngIf="mapLoaded">
  <ng-container *ngIf="editMode">
    <div #topLeftControlsWrapper class="top-left-controls-wrapper">
      <div #searchControlBtnWrapper *ngIf="showSearchButton">
        <app-search-field
          #searchControl
          [map]="gmap"
          [zones]="zones"
          [areas]="areas"
          [enableZonesAreasSearch]="enableZonesAreasSearch"
          (searchStateChanged)="onSearchStateChanged($event)"
          (autocompleteResult)="onAutocompleteResult($event)"
          (what3WordsResult)="onWhat3WordsResult($event)"
        >
        </app-search-field>
      </div>
      <div *ngIf="showMyLocationButton && !isMobile" #myLocationControlBtnWrapper class="margin-left-fix">
        <app-my-location-button (locationAvailableEvent)="onMyLocationAvailable($event)"></app-my-location-button>
      </div>
      <div *ngIf="showNoLocationButton && lat && lng" #noLocationControlBtnWrapper class="margin-left-fix">
        <app-no-location-button (onClickedEvent)="setNoLocation()"></app-no-location-button>
      </div>
    </div>

    <div #topCenterControlsWrapper *ngIf="isMobile">
      <div *ngIf="showMyLocationButton" #myLocationControlBtnWrapper class="margin-left-fix">
        <app-my-location-button
          [showLabel]="true"
          (onLocationAvailableEvent)="onMyLocationAvailable($event)"
        ></app-my-location-button>
      </div>
    </div>
  </ng-container>

  <div #zoneAreaControlBtnWrapper
    class="zone-area-btn-wrapper"
    [ngClass]="{ 'desktop-wrapper': !isMobile, 'mobile-wrapper': isMobile }">
      <app-zone-area-toggle-button *ngIf="showZonesAndAreasButton && polygonsAvailable"
        [map]="gmap"
        [zonesPolygon]="zonesPolygon"
        [areasPolygon]="areasPolygon">
      </app-zone-area-toggle-button>
  </div>

  <div #w3wGridControlBtnWrapper *ngIf="showW3WGridButton"
    class="w3w-grid-btn-wrapper">
      <app-w3w-grid-button
        [map]="gmap"
        (clicked)="onW3WGridControlClicked($event)"
        (gridToggled)="onW3WGridToggled($event)">
      </app-w3w-grid-button>
  </div>

  <div #mapTypesBtnWrapper
    class="map-types-control-wrapper">
      <app-map-views-group-button #mapTypesGroupButton
        [map]="gmap"
        [drawAsMapOverlay]="true">
      </app-map-views-group-button>
  </div>

</ng-container>
