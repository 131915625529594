import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneService } from '../services/timeZone.service';

@Pipe({
  name: 'localisedDate',
})
export class LocalisedDatePipe implements PipeTransform {
  constructor(private readonly timeZoneService: TimeZoneService) {}

  transform(dateISOString: string, includeTime: boolean, setTimeFirst: boolean = false): string {
    return this.timeZoneService.localiseDateISOString(dateISOString, includeTime, setTimeFirst);
  }
}
