import { FilterTypes } from '../enums/filterTypes';
import { PrivacyStatuses } from '../enums/privacyStatuses';
import { RunsheetItemPriorities } from '../../runsheets/enums/runsheetEnums';
import { IndicatorPriorities } from '../../sustainability/enums/indicatorPriorities';
import { MilestoneTypes } from '../enums/milestoneTypes';
import { RAGStatuses } from '../enums/ragStatuses';

export class EnumBasedLocalisationUtility {
  private static readonly enumBasedLocalisationDefaultTexts = {
    [FilterTypes.Privacy_Status]: {
      [PrivacyStatuses.Open]: 'Open',
      [PrivacyStatuses.Confidential]: 'Confidential',
      [PrivacyStatuses.Medical]: 'Medical',
      [PrivacyStatuses.Police]: 'Police',
    },
    [FilterTypes.Runsheet_Item_Priority]: {
      [RunsheetItemPriorities.Level_1]: 'Level 1',
      [RunsheetItemPriorities.Level_2]: 'Level 2',
      [RunsheetItemPriorities.Level_3]: 'Level 3',
    },
    [FilterTypes.Indicator_Priority]: {
      [IndicatorPriorities.One_Star]: 'One Star',
      [IndicatorPriorities.Two_Stars]: 'Two Stars',
      [IndicatorPriorities.Three_Stars]: 'Three Stars',
    },
    [FilterTypes.Milestone_Type]: {
      [MilestoneTypes.No_Milestone]: 'No Milestone',
      [MilestoneTypes.Bronze]: 'Type 1 (Default: "Bronze")',
      [MilestoneTypes.Silver]: 'Type 2 (Default: "Silver")',
      [MilestoneTypes.Gold]: 'Type 3 (Default: "Gold")',
    },
    [FilterTypes.RAG]: {
      [RAGStatuses.Grey]: 'Not Started',
      [RAGStatuses.Red]: 'Slipped',
      [RAGStatuses.Amber]: 'Slipping',
      [RAGStatuses.Green]: 'On Track',
      [RAGStatuses.Blue]: 'Complete',
    },
  };

  /**
   * Returns the default text for the given filterType and enum value.
   */
  public static getEnumBasedLocalisationDefaultText(filterType: FilterTypes, enumValue: number): string {
    return EnumBasedLocalisationUtility.enumBasedLocalisationDefaultTexts[filterType]?.[enumValue];
  }
}
