import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class SigninOidcService {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(): Observable<boolean> {
    //WORKAROUND: https://github.com/angular/angular/issues/16710
    if (!location.hash.length) {
      return this.authService.handleLogin();
    }

    return this.authService.loadDiscoveryDocument().pipe(
      mergeMap(() => this.authService.handleLogin()
      )
    );
  }
}
