import { Directive, Input, OnInit, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { Constants } from '../models/constants';

@Directive({
  selector: '[forScreen]',
})
export class ResizeDirective implements OnInit {
  config = new IConfigElement();

  @Input('forScreen') configWidth: 'mobile' | 'tablet' | 'desktop';

  constructor(private r: Renderer2, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnInit() {
    this.displayElement(window.innerWidth);
    this.r.listen('window', 'resize', (event) => {
      this.displayElement(event.target.visualViewport.width);
    });
  }

  displayElement(visualViewportWidth: number) {
    if (this.isValidPredifinedSize(visualViewportWidth)) {
      if (!this.viewContainer.get(0)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      this.viewContainer.clear();
    }
  }

  isValidPredifinedSize(viewportWidth: number) {
    switch (this.config[this.configWidth]) {
      case this.config.mobile:
        return viewportWidth < this.config.mobile;
      case this.config.tablet:
        return this.config.mobile <= viewportWidth && viewportWidth < this.config.tablet;
      case this.config.desktop:
        return this.config.tablet <= viewportWidth;
      default:
        return false;
    }
  }
}

export class IConfigElement {
  mobile: number;
  tablet: number;
  desktop: number;

  constructor() {
    this.desktop = 900;
    this.mobile = Constants.xs;
    this.tablet = 600;
  }
}
