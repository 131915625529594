import { AfterViewInit, ElementRef, OnInit, OnDestroy, Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { BsModalEventsEmitter } from '../../../events/bsModal.events';
import { Constants } from 'src/app/modules/shared/models/constants';
import { Inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  template: '',
})
export class ModalBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly mobileWidth: number = Constants.xs;
  protected readonly doubleWidthPixels = 800;
  protected readonly wrapper: ElementRef<HTMLElement>;
  protected readonly subscriptions: Subscription[] = [];

  constructor(
    @Inject(String) protected readonly fullscreen: boolean,
    public readonly bsModalRef: BsModalRef,
    protected readonly bsModalEventsEmitter: BsModalEventsEmitter,
    @Inject(String) protected readonly doubleWidth: boolean = false
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.bsModalEventsEmitter.bsModalStateChanged$.subscribe(() => this.rendered()));
  }

  ngAfterViewInit() {
    if (this.fullscreen) {
      this.toFullscreen();
    } else {
      if (!this.doubleWidth && window.innerWidth <= this.mobileWidth) {
        this.toFullscreen();
      } else if (this.doubleWidth && window.innerWidth <= this.doubleWidthPixels) {
        this.toFullscreen();
      } else if (this.doubleWidth) {
        this.toDoubleWidth();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  protected rendered() {
    // this function is called when the modal content has been loaded
    // every child should implement it for themselves (virtual)
  }

  public get ref(): BsModalRef {
    return this.bsModalRef;
  }

  protected toFullscreen() {
    const modalDialog = this.wrapper.nativeElement.parentElement.parentElement.parentElement;
    const modalContent = this.wrapper.nativeElement.parentElement.parentElement;
    const modal = this.wrapper.nativeElement.parentElement;

    modalDialog.style.borderRadius = '0';
    modalDialog.style.display = 'flex';
    modalDialog.style.margin = '0';
    modalDialog.style.padding = '0';
    modalDialog.style.width = '100%';
    modalDialog.style.maxWidth = 'unset';
    modalDialog.style.minHeight = '100%';
    modalDialog.style.height = '100%';
    modalContent.style.borderRadius = '0';
    modal.style.display = 'block';
    modal.style.height = '100%';
  }

  protected toDoubleWidth() {
    const modalDialog = this.wrapper.nativeElement.parentElement.parentElement.parentElement;
    modalDialog.style.maxWidth = '800px';
  }
}
