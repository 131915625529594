import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from '../../../enums/objectTypes';
import { LocalisedDatePipe } from '../../../pipes/localised-date.pipe';
import { LocalisationService } from '../../../services/localisation.service';
import { TimeZoneService } from '../../../services/timeZone.service';
import { EventViewModel } from '../../../viewModels/events/eventViewModel';

@Component({
  selector: 'app-fixed-event-dropdown-select',
  templateUrl: './fixed-event-dropdown-select.component.html',
  styleUrls: ['./fixed-event-dropdown-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class FixedEventDropdownSelectModal implements OnInit, OnDestroy {
  @Output() fixedEventChanged = new EventEmitter<number>();
  events: EventViewModel[];
  selectedEvent: EventViewModel;
  private readonly subscriptions = new Subscription();
  modalTitle = 'Select Live Event';
  localisedEvent = 'Event';
  localisedEvents = 'Events';
  public readonly T = T;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly timezoneService: TimeZoneService,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.localisedEvent = this.localisationService.localiseObjectType(ObjectTypes.Event);
    this.localisedEvents = this.localisationService.localiseObjectType(ObjectTypes.Event, true);
    this.modalTitle = this.translateService.instant(T.common.select_live_localisedEvent, { localisedEvent: this.localisedEvent });
    this.events.forEach((e) => {
      this.setEventDisplayProperties(e);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private setEventDisplayProperties(event: EventViewModel) {
    event.startDate = this.localiseDate(event.startDate);
    event.endDate = this.localiseDate(event.endDate);
  }

  private localiseDate(date: string): string {
    return new LocalisedDatePipe(this.timezoneService).transform(date, false, false);
  }

  onNoFixedEventConfirmed() {
    this.setFixedEvent(0);
  }
  onClose() {
    this.bsModalRef.hide();
  }

  isSelected(event: EventViewModel) {
    return this.selectedEvent && this.selectedEvent.id === event.id;
  }

  setSelected(event: EventViewModel) {
    this.selectedEvent = event;
    this.changeDetectorRef.markForCheck();
  }

  onConfirm() {
    this.setFixedEvent(this.selectedEvent.id);
  }

  private setFixedEvent(id: number) {
    this.fixedEventChanged.emit(id);
    this.bsModalRef.hide();
  }
}
