import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/modules/shared/models/constants';

@Component({
  selector: 'app-tabset-on-demand-loading',
  templateUrl: './tabset-on-demand-loading.component.html',
  styleUrls: ['./tabset-on-demand-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsetOnDemandLoadingComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('tabset') tabset: ElementRef<HTMLDivElement>;
  @ContentChildren(TabComponent, { static: true } as unknown) tabComponents: QueryList<TabComponent>;

  @Input() tabIndex: number;
  @Input() tabId: string;

  @Output() tabChanged: EventEmitter<TabComponent> = new EventEmitter();
  @Output() tabIndexChanged: EventEmitter<number> = new EventEmitter();

  private subscriptions: Subscription[] = [];

  public mobileWidth = Constants.xs;
  public isMobile: boolean = false;
  public isSticky: boolean = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.isMobile = window.innerWidth <= this.mobileWidth;

    this.tabComponents.toArray().forEach((tabComponent) => {
      this.subscriptions.push(tabComponent.activated.subscribe(() => this.onTabClicked(tabComponent)));
    });

    if (this.tabId) {
      const tab = this.tabs.find((t) => t.id.toString() == this.tabId.toString());
      if (tab) {
        tab.activate();
      } else {
        this.tabComponents.first.activate();
      }
    } else {
      this.tabComponents.first.activate();
    }

    const activeTab = this.tabs.find((t) => t.active);
    if (activeTab) {
      this.onTabClicked(activeTab);
    }
  }
  ngAfterViewInit() {
    // this.tabComponents.find(x => x.isTabVisible === true).activate()
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges() {
    if (this.tabIndex) {
      this.tabs.filter((_, i) => i !== this.tabIndex).forEach((t) => (t.active = false));
      this.tabs[this.tabIndex].active = true;
    } else if (this.tabId) {
      this.tabs.forEach((tab) => {
        tab.active = tab.id == this.tabId;
      });
    } else {
      this.tabComponents.first.active = true;
      // this.tabComponents.find(x => x.isTabVisible === true).active = true;
    }

    const activeTab = this.tabs.find((t) => t.active);
    if (activeTab) {
      this.onTabClicked(activeTab);
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.tabComponents.forEach((s) => s.ngOnDestroy());
    this.tabComponents = null;

    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get offsetTop(): number {
    if (this.tabset) {
      return this.tabset.nativeElement.offsetTop;
    }

    return -1;
  }

  set sticky(state: boolean) {
    if (this.isSticky !== state) {
      this.isSticky = state;
      this.changeDetectorRef.detectChanges();
    }
  }

  get tabs(): TabComponent[] {
    return this.tabComponents.toArray();
  }

  get activeTab(): TabComponent {
    return this.tabs.find((t) => t.active);
  }

  onTabClicked(tab: TabComponent): void {
    this.tabs.filter((t) => t !== tab).forEach((t) => (t.active = false));

    tab.active = true;
    this.tabChanged.next(tab);
    this.tabIndexChanged.next(this.tabs.findIndex((tab) => tab.active));
    this.changeDetectorRef.detectChanges();
  }
}
