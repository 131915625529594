import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { isChildOf } from '../../../utilities/html.utilities';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-list-search-input',
  templateUrl: './list-search-input.component.html',
  styleUrls: ['./list-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSearchInputComponent implements OnInit {
  private readonly debounceTime = 600;

  @ViewChild('input') inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('listSearchInputWrapper') listSearchInputWrapper: ElementRef<HTMLElement>;
  @ViewChild('searchIconWrapper') searchIconWrapper: ElementRef<HTMLElement>;

  @Input() placeholder: string;
  @Input() background: string;

  @Output() inputChanged: EventEmitter<string> = new EventEmitter();

  inputVisible: boolean = false;
  inputSubject: Subject<string> = new Subject();
  subscriptions: Subscription[] = [];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscriptions.push(
      this.inputSubject.pipe(debounceTime(this.debounceTime)).subscribe((input) => {
        this.inputChanged.emit(input);
      })
    );
  }

  onInput() {
    this.inputSubject.next(this.inputElement.nativeElement.value);
  }

  onShowHideInput(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    if (this.inputElement && this.inputElement.nativeElement.value) {
      return;
    }

    this.inputVisible = !this.inputVisible;
    this.changeDetectorRef.detectChanges();

    if (this.inputElement) {
      this.inputElement.nativeElement.focus();
    }
  }

  onClearInput() {
    this.inputElement.nativeElement.value = null;
    this.inputChanged.emit(this.inputElement.nativeElement.value);
    this.changeDetectorRef.detectChanges();
    this.inputElement.nativeElement.focus();
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(e: Event) {
    if (!this.listSearchInputWrapper) {
      return;
    }

    if (
      e.target !== this.listSearchInputWrapper.nativeElement &&
      !isChildOf(this.listSearchInputWrapper.nativeElement, e.target as HTMLElement)
    ) {
      this.onShowHideInput(e);
    }
  }
}
