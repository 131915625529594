<!-- Input template for desktop version -->
<ng-template #inputTemplate>
  <ng-container *ngIf="useNgDateMask">
    <input
      #input
      [type]="type"
      [(ngModel)]="value"
      autocomplete="off"
      [class.cool-custom-input-invalid]="(!inputIsPristine && !inputIsValid) || (displayError && !inputIsValid)"
      [class.input-label-shown]="valueLength"
      [class.icon-input]="icon || svg"
      [required]="required"
      [minlength]="minLength"
      [maxlength]="maxLength"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      inputValidation
      [condition]="(!inputIsPristine && !inputIsValid) || (displayError && !inputIsValid)"
      [error]="errors[0]"
      [autoFocusDirective]="autofocus"
      ngDateMask
      [format]="format"
      [separator]="separator"
      [disabled]="disabled"
      step="any"
      (change)="onChange.emit($event)"
    />
  </ng-container>
  <ng-container *ngIf="!useNgDateMask">
    <input
      [id]="type === 'number' ? 'custom-input' : ''"
      #input
      [type]="type"
      [(ngModel)]="value"
      autocomplete="off"
      [class.cool-custom-input-invalid]="(!inputIsPristine && !inputIsValid) || (displayError && !inputIsValid)"
      [class.input-label-shown]="valueLength"
      [class.icon-input]="icon || svg"
      [required]="required"
      [minlength]="minLength"
      [maxlength]="maxLength"
      (keydown)="onKeyDown($event)"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      [disabled]="disabled"
      [pattern]="pattern"
      [autoFocusDirective]="autofocus"
      step="any"
    />
  </ng-container>
</ng-template>

<!-- Desktop version of the input field -->
<ng-container *ngIf="!isMobile">
  <div
    class="cool-input"
    [class.cool-input-margin-bottom]="(inputIsPristine || inputIsValid) && !disableMargin"
    [class.cool-input-countdown]="maxLength"
    [class.cool-input-with-icon]="icon"
    [class.no-borders]="removeBorders"
    [class.full-border]="useFullBorder"
  >
    <span *ngIf="icon" class="cool-input-icon" [class.disabled]="disabled">
      <i class="material-symbols-outlined">{{ icon }}</i>
    </span>
    <span *ngIf="svg && svg.length" class="cool-input-icon" [class.disabled]="disabled" [innerHtml]="svg | safeHtml">
    </span>
    <ng-template [ngTemplateOutlet]="inputTemplate"></ng-template>

    <label [class.label-icon]="icon || svg">{{ placeholder }}</label>

    <span *ngIf="maxLength && showInputCountdown" class="cool-input-countdown">{{
      valueLength + '/' + maxLength
    }}</span>
    <ng-template [ngTemplateOutlet]="errorsTemplate" [ngTemplateOutletContext]="{ errors: errors }"> </ng-template>
    <div class="helper-text d-flex" *ngIf="showHelperText">{{ helperText }}</div>
  </div>
</ng-container>

<!-- Mobile version of the input field -->
<ng-container *ngIf="isMobile">
  <div class="mobile-input-container" *ngIf="formGroup && formControlName" [formGroup]="formGroup">
    <ion-item mode="md">
      <span *ngIf="icon" class="mobile-icon" [class.disabled]="disabled">
        <i class="material-symbols-outlined">{{ icon }}</i>
      </span>
      <span
        slot="start"
        *ngIf="svg && svg.length"
        class="mobile-icon"
        [class.disabled]="disabled"
        [innerHtml]="svg | safeHtml"
      ></span>
      <ion-label mode="md" position="floating">{{ placeholder }}</ion-label>
      <ion-input
        #mobileInput
        mode="md"
        [type]="type"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        [required]="required"
        [minlength]="minLength"
        [maxlength]="maxLength"
        (ionBlur)="onBlur($event)"
        (ionFocus)="onFocus($event)"
        [formControlName]="formControlName"
        [disabled]="disabled"
        (change)="onChange.emit($event)"
        autofocus="true"
        step="any"
      ></ion-input>
      <div slot="error">
        <ng-template [ngTemplateOutlet]="errorsTemplate" [ngTemplateOutletContext]="{ errors: errors }"> </ng-template>
      </div>
    </ion-item>
  </div>
  <div class="mobile-input-container" *ngIf="!formGroup">
    <ion-item mode="md">
      <span *ngIf="icon" slot="start" class="mobile-icon" [class.disabled]="disabled">
        <i class="material-symbols-outlined">{{ icon }}</i>
      </span>
      <span
        *ngIf="svg && svg.length"
        slot="start"
        class="mobile-icon"
        [class.disabled]="disabled"
        [innerHtml]="svg | safeHtml"
      ></span>
      <ion-label mode="md" position="floating">{{ placeholder }}</ion-label>
      <ion-input
        mode="md"
        #mobileInput
        [type]="type"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        [required]="required"
        [minlength]="minLength"
        [maxlength]="maxLength"
        (ionBlur)="onBlur($event)"
        (ionFocus)="onFocus($event)"
        [disabled]="disabled"
        [autofocus]="autofocus"
        step="any"
      ></ion-input>
      <div slot="error">
        <ng-template [ngTemplateOutlet]="errorsTemplate" [ngTemplateOutletContext]="{ errors: errors }"> </ng-template>
      </div>
    </ion-item>
  </div>
</ng-container>

<ng-template #errorsTemplate let-errors="errors">
  <ng-container *ngFor="let error of errors">
    <app-validation-error style="margin-bottom: -8px" [error]="error"></app-validation-error>
  </ng-container>
</ng-template>
