<div class="modal-delete-container">
  <div class="modal-delete-header">
    <h6 class="modal-delete-title">
      Delete <span class="item-name">'{{ itemName }}'</span>
    </h6>
  </div>
  <div class="modal-delete-content" *ngIf="!withCode">
    <div class="modal-delete-message">Are you sure you want to delete this item?</div>
  </div>
  <div class="modal-delete-content" *ngIf="withCode">
    <div class="modal-delete-message">
      Are you sure you want to delete this item? Enter the following confirmation code to continue:
      <span class="confirmation-code">0000</span>
    </div>
    <form class="code-input" [formGroup]="form">
      <input formControlName="i1" type="number" id="1" #input1 class="single-digit" (keydown)="onKeyDown($event)" />
      <input formControlName="i2" type="number" id="2" #input2 class="single-digit" (keydown)="onKeyDown($event)" />
      <input formControlName="i3" type="number" id="3" #input3 class="single-digit" (keydown)="onKeyDown($event)" />
      <input formControlName="i4" type="number" id="4" #input4 class="single-digit" (keydown)="onKeyDown($event)" />
    </form>
  </div>
  <div class="modal-delete-footer">
    <div class="modal-delete-buttons">
      <app-button-rectangle
        class="cancel-button"
        text="Cancel"
        (clicked)="onCancel()"
        buttonTheme="white"
        size="large"
      ></app-button-rectangle>
      <app-button-rectangle
        class="delete-button"
        text="Delete"
        (clicked)="onDelete()"
        buttonTheme="danger"
        size="large"
      ></app-button-rectangle>
    </div>
  </div>
</div>
