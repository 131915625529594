import { Injectable, OnDestroy } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable, of, skip, switchMap } from 'rxjs';
import { EventsService } from './events-service';
import { FixedEventDropdownSelectModal } from '../components/modals/fixed-event-dropdown-select/fixed-event-dropdown-select.component';
import { BsModalConfig } from '../config/modal.config';
import { AccountSettingTypes } from '../enums/accountSettingTypes';
import { FilterTypes } from '../enums/filterTypes';
import { FixedFilters } from '../enums/fixedFilters.enum';
import { ObjectTypes } from '../enums/objectTypes';
import { ObjectEventEmitters } from '../events/object.events';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { FixedFilterType } from '../types/FixedFilterType';
import { FixedObjectType } from '../types/FixedObjectType';
import { FilterUtilities } from '../utilities/filter.utilities';
import { AllowedFiltersService } from './allowed-filters.service';
import { AuthenticationService } from './authentication.service';
import { CachingService } from './caching.service';
import { EmployeeSettingsService } from './employee-settings.service';
import { FixedFiltersServiceAbstract } from './fixed-filters-service.abstract';
import { LocalisationService } from './localisation.service';
import { ModuleService } from './module.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { EventViewModel } from '../viewModels/events/eventViewModel';

@Injectable({ providedIn: 'root' })
export class FixedEventFiltersService extends FixedFiltersServiceAbstract<EventViewModel> implements OnDestroy {
  protected override readonly objectTypes: ObjectTypes[] = [
    ObjectTypes.IncidentItem,
    ObjectTypes.Runsheet,
    ObjectTypes.Runsheet_Item,
    ObjectTypes.Job,
    ObjectTypes.Public_Incident_Report
  ];
  override readonly filterType: FixedFilterType = FilterTypes.Event;
  override readonly fixedObjectType: FixedObjectType = ObjectTypes.Event;

  constructor(
    private readonly cachingService: CachingService,
    private readonly localisationService: LocalisationService,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly eventsService: EventsService,
    private readonly bsModalService: BsModalService,
    private readonly bsModalConfig: BsModalConfig,
    protected readonly authenticationService: AuthenticationService,
    protected readonly moduleService: ModuleService,
    protected readonly allowedFiltersService: AllowedFiltersService,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly translateService: TranslateService
  ) {
    super(authenticationService, moduleService, allowedFiltersService, objectEventEmitters, translateService);
  }

  protected override checkServiceOnInit(): Observable<undefined> {
    this._isServiceEnabled.next(
      (this.account.useIMS || this.account.useRunSheets) && this.account.fixedFilters === FixedFilters.Event
    );
    const hasCommonObjTypes = this.objectTypes.some((item) => this.moduleService.currentObjectTypes.includes(item));
    this._isFilterActive.next(this.isServiceEnabled && hasCommonObjTypes);

    return of(undefined);
  }

  protected override generateAllFilter(): FilterViewModel {
    return FilterUtilities.GenerateFilter(
      this.filterType,
      '0',
      this.translateService.instant(T.common.all_items, {
        items: this.localisationService.localiseObjectType(ObjectTypes.Event, true),
      }),
      0,
      true,
      ObjectTypes.Global
    );
  }

  protected override generateEpmtyEntity(): EventViewModel {
    this.localisationService.localise('Events');

    const emptyEntity = new EventViewModel();
    emptyEntity.id = 0;
    emptyEntity.accountId = this.account.id;
    emptyEntity.title = this.translateService.instant(T.common.all_items, {
      items: this.localisationService.localiseObjectType(ObjectTypes.Event, true),
    });

    return emptyEntity;
  }

  protected override initSubscriptions() {
    super.initSubscriptions();

    this.subscriptions.add(
      this.objectEventEmitters.accountSettingUpdated$.subscribe(({ settingType, setting }) => {
        if (settingType === AccountSettingTypes.Fixed_Filters) {
          this._isServiceEnabled.next(setting.value === FixedFilters.Event.toString());
          this.populateData().subscribe();
        }
      })
    );

    this.subscriptions.add(
      this.fixedFilter$.pipe(skip(1)).subscribe((fixedFilter) => {
        const eventId = fixedFilter ? parseInt(fixedFilter.filterValue.toString()) : 0;
        this.cachingService.selectedEventID = eventId > 0 ? eventId.toString() : '';

        if (this.isServiceEnabled) {
          this.employeeSettingsService.setEmployeeLiveEvent(eventId).subscribe(() => {
            this.employeeSettingsService.getToken(this.account.id).subscribe();
          });
        }
      })
    );
  }

  protected override setFixedFilterOnInit(): Observable<undefined> {
    const allowedEvents = this.allowedEntities;
    const allowedFilters = this.allowedFilters;

    if (allowedEvents.length === 0 || !this.isServiceEnabled) {
      this.cachingService.removeFixedEventPromptCookie();
      return of(undefined);
    }

    const employeeEventId = +this.cachingService.selectedEventID;

    if (employeeEventId) {
      const eventVm = allowedEvents.find((event) => event.id === employeeEventId);
      const filter = FilterUtilities.GenerateFilter(FilterTypes.Event, employeeEventId.toString(), eventVm?.title);
      this.showFixedFilterModal(filter);
    } else {
      const eventInAllowed = allowedEvents.find((event) => event.id === employeeEventId);

      if (!employeeEventId || !eventInAllowed) {
        this.showFixedFilterModal();
      } else {
        const newFilter = allowedFilters.find((f) => f.filterValue.toString() === eventInAllowed.id.toString());
        this._fixedFilter.next(newFilter);
      }
    }

    return of(undefined);
  }

  private showFixedFilterModal(filter: FilterViewModel = this.allFilter): void {
    this._fixedFilter.next(filter);

    if (this.cachingService.fixedEventPromptCookie) {
      return;
    }

    const filteredAllowedEvents = this.allowedEntities.filter((e) => e.id !== 0);
    const allowedFilters = this.allowedFilters;

    if (filteredAllowedEvents.length) {
      const bsModalRef = this.bsModalService.show(FixedEventDropdownSelectModal, {
        ...this.bsModalConfig,
        initialState: {
          events: filteredAllowedEvents,
        },
      });

      bsModalRef.content.fixedEventChanged.subscribe((id) => {
        this.cachingService.fixedEventPromptCookie = 'True';

        if (id === 0) {
          return;
        }

        const newFilter = allowedFilters.find((f) => f.filterValue.toString() === id.toString());
        this._fixedFilter.next(newFilter);
      });
    }
  }

  protected override populateData(): Observable<undefined> {
    if (!this.isServiceEnabled) {
      this._allowedFilters.next([]);
      this._allowedEntities.next([]);

      return of(undefined);
    }

    return this.eventsService.getLiveEvents().pipe(
      switchMap((events) => {
        events.unshift(this.emptyEntity);

        const newAllowedFilters = events.map((ev) => {
          return FilterUtilities.GenerateFilter(this.filterType, ev.id.toString(), ev.title, 0, true, ObjectTypes.Global);
        });

        this._allowedFilters.next(newAllowedFilters);
        this._allowedEntities.next(events);

        return of(undefined);
      })
    );
  }
}
