import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPadding',
  pure: false,
})
export class NumberPaddingPipe implements PipeTransform {
  transform(number: number, length: number): string {
    if (!number) return '';
    if (isNaN(number) || isNaN(length)) {
      return number.toString();
    }
    let res = number.toString();
    while (res.length < length) {
      res = '0' + res;
    }
    return res;
  }
}
