import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UrlService } from '../modules/shared/services/url.service';
import { AuthenticationService } from '../modules/shared/services/authentication.service';
import { AuthService } from '../modules/auth/auth.service';
import { authConfig } from '../modules/auth/auth.config';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss'],
})
export class NoAccessComponent implements OnInit {
  public logoUrl: string = '';
  public returnUrl: string;
  public loading: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private urlService: UrlService,
    private authenticationService: AuthenticationService,
    private isAuthenticationService: AuthService
  ) {}

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] as string || '/dashboard';
    this.logoUrl = this.urlService.buildResourceUrl(this.authenticationService.getCurrentAccount().localLogoURL);
  }

  login() {
    this.isAuthenticationService.logOut();
  }

  goToAccountList() {
    document.location.href = `${authConfig.issuer}/Account/Select`;
  }

  onLogout() {
    this.isAuthenticationService.logOut();
  }
}
