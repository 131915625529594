import { ToastOptions } from '@ionic/angular';
import { IndividualConfig, ProgressAnimationType, ComponentType } from 'ngx-toastr';

export enum ToastrPositions {
  Top_Right = 1,
  Bottom_Right = 2,
  Bottom_Left = 3,
  Top_Left = 4,
  Top_Full_Width = 5,
  Bottom_Full_Width = 6,
  Top_Center = 7,
  Bottom_Center = 8,
}

export class ToastrConfig implements IndividualConfig {
  disableTimeOut: boolean;
  timeOut: number;
  closeButton: boolean;
  extendedTimeOut: number;
  progressBar: boolean;
  progressAnimation: ProgressAnimationType;
  enableHtml: boolean;
  toastClass: string;
  position: ToastrPositions;
  positionClass: string;
  titleClass: string;
  messageClass: string;
  easing: string;
  easeTime: string | number;
  tapToDismiss: boolean;
  toastComponent?: ComponentType<any>;
  onActivateTick: boolean;
  newestOnTop: boolean;
  icon: string;
  payload: any;

  constructor(
    _position: ToastrPositions = ToastrPositions.Top_Right,
    _timeOut: number = 4000,
    _closeButton: boolean = true,
    _progressBar: boolean = false,
    _progressAnimation: ProgressAnimationType = 'increasing'
  ) {
    this.position = _position;
    this.timeOut = _timeOut;
    this.closeButton = _closeButton;
    this.progressBar = _progressBar;
    this.progressAnimation = _progressAnimation;
    this.positionClass = this.getToastrPositionClass(_position);
  }

  getToastrPositionClass(position: ToastrPositions): string {
    switch (position) {
      case ToastrPositions.Top_Right: {
        return 'toast-top-right';
      }
      case ToastrPositions.Bottom_Right: {
        return 'toast-bottom-right ';
      }
      case ToastrPositions.Bottom_Left: {
        return 'toast-bottom-left';
      }
      case ToastrPositions.Top_Left: {
        return 'toast-top-left';
      }
      case ToastrPositions.Top_Full_Width: {
        return 'toast-top-full-width';
      }
      case ToastrPositions.Bottom_Full_Width: {
        return 'toast-bottom-full-width';
      }
      case ToastrPositions.Top_Center: {
        return 'toast-top-center';
      }
      case ToastrPositions.Bottom_Center: {
        return 'toast-bottom-center';
      }
    }
    return 'toast-bottom-right';
  }
}

export class ToastrSettings {
  public static defaultToastrConfig: ToastrConfig = new ToastrConfig(ToastrPositions.Top_Right, 4000, true, false, 'increasing');
  public static defaultMobileToastrConfig: ToastOptions = {
    position: 'top',
    duration: 4000,
    keyboardClose: true,
    animated: true,
  };
}
