import { Component, Input, OnInit } from '@angular/core';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { Configuration } from 'src/app/app.constants';


@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit {
  @Input() currentAccount: Account;
  @Input() currentEmployee: Employee;

  public isHubAccount: boolean = false;
  public isChildHubAccount: boolean = false;

  constructor(
    protected readonly urlService: UrlService,
    protected readonly configuration: Configuration,
  ) {}

  ngOnInit(): void {
    if (this.currentAccount) {
      this.isHubAccount = this.currentAccount.isHubAccount;
      this.isChildHubAccount = this.currentAccount.isChiildHubAccount;
    }
  }
}
