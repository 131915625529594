export enum UpdateTypes {
  None = 0,
  Created = 1,
  Modified = 2,
  Deleted = 3,
  RAGUpdate = 4,
  Task_Risk_Linked = 5,
  RAGRiskUpdate = 6,
  Attachment_Added = 7,
  Attachment_Added_To_Venue = 11,
  Venue_Comment_Added = 8,
  Venue_Created = 9,
  Task_Comment_Added = 10,
  Venue_Attachment_Added = 12,
  Task_Dependee_Modified = 13,
  Task_ActionSteps_Created = 130,
  Task_ActionSteps_Modified = 131,
  Task_ActionSteps_Deleted = 132,
  Trend_Threshold_Exceeded = 14,
  Venue_Risk_Modified = 15,
  Risk_Task_Linked = 16,
  Item_Archived = 17,
  Item_Unarchived = 30,
  Task_Upstream_Dependency_Created = 20,
  Task_Downstream_Dependency_Created = 21,
  Incident_Completed = 222,
  Incident_Comment_Added = 200,
  Incident_Closed = 201,
  Runsheet_Comment_Added = 61,

  Checklist_Item_Added = 301,
  Checklist_Item_Updated = 302,
  Checklist_Item_Removed = 303,
  Checklist_Item_Completed = 304,
  Checklist_Template_Added = 305,
  Check_Added = 310,
  Check_Resolution_Changed = 311,
  Check_Status_Changed = 312,
  Check_Comment_Added = 313,
  Risk_Comment_Added = 314,
  Risk_Action_Comment_Added = 315,
  Indicator_Update_Value_Updated = 400,
  Indicator_Update_Comment_Added = 401,
  Indicator_Comment_Added = 402,

  Public_Incident_Report_Comment_Added = 430,
}
