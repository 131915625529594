<div class="d-flex">
  <div class="pages-wrapper">
    <div class="page-wrapper" [class.selected]="currentPage === 1" (click)="navigate(currentPage - 1, $event)">
      <div class="responsive-flex-wrapper">
        <i class="material-symbols-outlined">keyboard_arrow_left</i>
      </div>
    </div>
    <ng-container *ngIf="pages[0] !== 1 && !isMobile">
      <div class="page-wrapper" (click)="navigate(1, $event)" [class.selected]="currentPage === 1">1</div>
      <div class="page-wrapper selected" [ngClass]="paginationExtraMarginBreakpoint() ? 'left-dots' : ''">...</div>
    </ng-container>
    <ng-container *ngFor="let page of pages; let i = index">
      <ng-container
        *ngIf="i === pages.length - 1 && totalPagesCount > shownPagesCount && totalPagesCount > page && !isMobile"
      >
        <div class="page-wrapper selected right-dots">...</div>
        <div class="page-wrapper" (click)="navigate(totalPagesCount, $event)" [class.selected]="currentPage === page">
          {{ totalPagesCount }}
        </div>
      </ng-container>
      <ng-container *ngIf="i < pages.length - 1 || page === totalPagesCount || isMobile">
        <div
          class="page-wrapper"
          [ngClass]="currentPage > 9 ? 'double-digit-page' : ''"
          (click)="navigate(page, $event)"
          [class.selected]="currentPage === page"
        >
          {{ page }}
        </div>
      </ng-container>
    </ng-container>
    <div
      class="page-wrapper"
      (click)="navigate(currentPage + 1, $event)"
      [class.selected]="currentPage === totalPagesCount"
    >
      <div class="responsive-flex-wrapper">
        <i class="material-symbols-outlined">keyboard_arrow_right</i>
      </div>
    </div>
  </div>
  <app-button-dropdown-simple
    [dropdownOptions]="sortDropdownItems"
    [currentlySelectedOption]="selectedDropdownOption"
    [bindProperty]="'label'"
    *ngIf="showSort"
    [closeUponSelection]="true"
    [buttonTheme]="'white'"
    [icon]="'arrow_drop_down'"
    (optionSelected)="onDropdownChange($event)"
  ></app-button-dropdown-simple>
</div>
