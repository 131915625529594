export enum ButtonThemes {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  White = 'white',
  Successful = 'successful',
  Fail = 'fail',
  Pass = 'pass',
  Unsure = 'unsure',
}
