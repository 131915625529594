import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ButtonClasses } from 'src/app/modules/shared/enums/styles/buttonClasses.enum';
import { ButtonThemes } from 'src/app/modules/shared/enums/styles/buttonThemes.enum';

@Component({
  selector: 'app-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./button-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonDropdownComponent {
  @Input() selectedOption: string;
  @Input() options = [];
  @Input() disabled = false;
  @Input() fitToContainer = false;
  @Input() loading = false;
  @Input() buttonTheme = ButtonThemes.Primary;

  @Output() selected = new EventEmitter();

  isDropdownOpen = false;

  getButtonClasses() {
    const classesArr = [];
    classesArr.push(ButtonClasses[this.buttonTheme]);
    return classesArr;
  }

  get restOfOptions() {
    return this.options.filter((e) => e !== this.selectedOption);
  }

  emitSelection(e: Event) {
    if (!this.isDropdownOpen) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (!this.loading && !this.isDropdownOpen) {
      this.selected.emit(this.selectedOption);
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  changeSelectedOption(option: string) {
    this.selectedOption = option;
  }
}
