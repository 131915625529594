<ng-container *ngIf="showListHeader && largeCards">
  <app-skeleton-list-header-loading [showRARs]="showListHeaderRARs"></app-skeleton-list-header-loading>
</ng-container>

<div
  [class.category-card-container]="categoryCard"
  [class.small-card-margin-left-right]="!largeCards && showMarginLeftRight"
  class="card-container"
  [ngClass]="!largeCards ? 'small-card-container' : ''"
  *ngFor="let card of [].constructor(count)"
>
  <ng-container *ngIf="largeCards && !categoryCard">
    <div class="card-inner big">
      <div class="card-top">
        <div class="card-top-left">
          <div class="skeleton-icon skeleton-icon-animation"></div>
          <div class="skeleton-title skeleton-animation"></div>
        </div>
      </div>
      <ng-container *ngIf="showLozenges">
        <div class="card-bottom custom-scrollbar">
          <div class="card-main-lozenges" *ngFor="let card of [].constructor(countLozenges)">
            <div class="skeleton-lozenge skeleton-lozenge-animation"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="!largeCards && !categoryCard">
      <div class="card-inner small">
        <div class="card-left">
        <div class="skeleton-icon skeleton-icon-animation"></div>
      </div>
      <div class="card-right">
        <div class="skeleton-title top skeleton-animation"></div>
        <div class="skeleton-title bottom skeleton-animation"></div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="largeCards && categoryCard">
    <div class="card-inner category-card-inner big">
      <div class="card-left">
        <div class="skeleton-title skeleton-animation"></div>
      </div>
      <div class="card-right">
        <div class="skeleton-title skeleton-animation"></div>
        <div class="skeleton-icon skeleton-icon-animation"></div>
      </div>
    </div>
  </ng-container>
</div>
