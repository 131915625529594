import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, forkJoin } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterSelectorTypes } from '../../enums/filter/filterSelectorTypes';
import { FilterTypes } from '../../enums/filterTypes';
import { DropdownEventsEmitter } from '../../events/dropdown.events';
import { ObjectEventEmitters } from '../../events/object.events';
import { Constants } from '../../models/constants';
import { Employee } from '../../models/employee';
import { FilterViewModel } from '../../models/filter/filterViewModel';
import { IDropdownState } from '../../models/interfaces/dropdown-state.interface';
import { ItemSubscriberUserGroupViewModel } from '../../models/itemSubscriberUserGroupViewModel';
import { ItemSubscriptionViewModel } from '../../models/itemSubscriptionViewModel';
import { SubscriberUserGroupViewModel } from '../../models/subscriberUserGroupViewModel copy';
import { SubscriberViewModel } from '../../models/subscriberViewModel';
import { AlertService } from '../../services/alert.service';
import { AllowedFiltersService } from '../../services/allowed-filters.service';
import { AuthenticationService } from '../../services/authentication.service';
import { CommentsService } from '../../services/comments.service';
import { SubscriptionService } from '../../services/subscription.service';
import { EmployeeViewModel } from '../../viewModels/employeeViewModel';
import { FilterTypeSelectorViewModel } from '../../viewModels/filters/filterTypeSelectorViewModel';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

@Component({
  selector: 'app-subscribers-accordion',
  templateUrl: './subscribers-accordion.component.html',
  styleUrls: ['./subscribers-accordion.component.scss'],
})
export class SubscribersAccordionComponent implements OnInit {
  private employee: Employee;
  subscriptions: Subscription[] = [];
  subscriberFilterType = FilterTypes.Subscriber;
  subscriberGroupFilterType = FilterTypes.Subscriber_Group;
  subscribers: SubscriberViewModel[] = [];
  groupSubscribers: SubscriberUserGroupViewModel[] = [];
  allSubscriberFilters: FilterViewModel[] = [];
  subscriberFilters: FilterViewModel[] = [];
  public availableEmployees: EmployeeViewModel[] = [];
  public subscriberFilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
  public subscriberGroupFilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
  public allowedGroupsFilters: FilterViewModel[] = [];
  public allowedSubscriberFilters: FilterViewModel[] = [];

  @Input() globalObjectID: number;
  @Input() globalObjectType: number;
  @Input() canEdit = false;
  @Output() onSubscribersChanged = new EventEmitter<SubscriberViewModel[]>();
  @Output() onSubscribersGroupChanged = new EventEmitter<SubscriberUserGroupViewModel[]>();
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLElement>;

  public readonly T = T;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly subscriptionService: SubscriptionService,
    private readonly dropdownEventsEmitter: DropdownEventsEmitter,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly alertService: AlertService,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly translateService: TranslateService,
    private readonly commentsService: CommentsService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.initAvailableEmployees();
    this.loadSubscribers();
    this.initSubscriptions();
    this.initFilters();
  }

  initSubscriptions() {
    this.subscriptions.push(
      this.objectEventEmitters.objectSubscribedTo$.subscribe((res) => {
        if (res.globalObjectId === this.globalObjectID && res.globalObjectType === this.globalObjectType) {
          this.loadSubscribers();
        }
      })
    );
    this.subscriptions.push(
      this.objectEventEmitters.objectUnbscribedFrom$.subscribe((res) => {
        if (res.globalObjectId === this.globalObjectID && res.globalObjectType === this.globalObjectType) {
          this.loadSubscribers();
        }
      })
    );
  }

  loadSubscribers() {
    this.allowedSubscriberFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Subscriber);
    this.allowedGroupsFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Subscriber_Group);
    forkJoin([
      this.subscriptionService.getSubscribers(this.globalObjectID, this.globalObjectType),
      this.subscriptionService.getSubscriberGroups(this.globalObjectID, this.globalObjectType),
    ]).subscribe(([subscribers, groups]) => {
      this.subscribers = subscribers.filter(onlyUnique);

      groups = groups.filter(onlyUnique);

      this.groupSubscribers = groups;
      const employeeIds = subscribers.map((s) => s.employeeId);
      const groupIds = groups.map((s) => s.userGroupId);

      const employeeFilters = this.allowedSubscriberFilters.filter((f) => employeeIds.indexOf(+f.filterValue) >= 0);
      this.subscriberFilters = JSON.parse(JSON.stringify(employeeFilters));
      this.allSubscriberFilters = JSON.parse(JSON.stringify(employeeFilters));

      const groupFilters = this.allowedGroupsFilters.filter((f) => groupIds.indexOf(+f.filterValue) >= 0);

      if (groupFilters && groupFilters.length) {
        this.subscriberFilters.push(...groupFilters);
        this.allSubscriberFilters.push(...groupFilters);
      }

      this.onSubscribersChanged.next(this.subscribers);
      this.onSubscribersGroupChanged.next(this.groupSubscribers);
      this.changeDetectorRef.markForCheck();
    });
  }

  handleSubscriberChange(filters: FilterViewModel[], isSubscribers: boolean) {
    if (isSubscribers) this.getSubscribersFromFilters(filters);
    else this.getSubscriberGroupsFromFilters(filters);
  }

  private getSubscribersFromFilters(filters: FilterViewModel[]) {
    const updatedSubscriberEmployees = filters.filter((f) => f.filterType == this.subscriberFilterType);
    const updatedSubscriberEmployeeIds = updatedSubscriberEmployees.map((f) => f.filterValue);
    const currentSubscriberEmployees = this.allSubscriberFilters.filter((f) => f.filterType == this.subscriberFilterType);
    const currentSubscriberEmployeeIds = currentSubscriberEmployees.map((f) => f.filterValue);

    // Added

    updatedSubscriberEmployees.forEach((f) => {
      if (currentSubscriberEmployeeIds.indexOf(f.filterValue) < 0) {
        this.addSubscriber(f);
      }
    });

    // // Removed
    this.allSubscriberFilters.forEach((f) => {
      if (updatedSubscriberEmployeeIds.indexOf(f.filterValue) < 0) {
        this.removeSubscriber(f);
      }
    });
  }

  private getSubscriberGroupsFromFilters(filters: FilterViewModel[]) {
    const updatedSubscriberEmployees = filters.filter((f) => f.filterType == this.subscriberGroupFilterType);
    const updatedSubscriberEmployeeIds = updatedSubscriberEmployees.map((f) => f.filterValue);
    const currentSubscriberEmployees = this.allSubscriberFilters.filter((f) => f.filterType == this.subscriberGroupFilterType);
    const currentSubscriberEmployeeIds = currentSubscriberEmployees.map((f) => f.filterValue);
    // Added
    updatedSubscriberEmployees.forEach((f) => {
      if (currentSubscriberEmployeeIds.indexOf(f.filterValue) < 0) {
        this.addSubscriberGroup(f);
      }
    });

    // // Removed
    this.allSubscriberFilters.forEach((f) => {
      if (updatedSubscriberEmployeeIds.indexOf(f.filterValue) < 0) {
        this.removeSubscriberGroup(f);
      }
    });
  }

  addSubscriber(filter: FilterViewModel) {
    const itemSubscriptionViewModel = new ItemSubscriptionViewModel();
    itemSubscriptionViewModel.accountId = this.employee.accountId;
    itemSubscriptionViewModel.employeeId = Number(filter.filterValue);
    itemSubscriptionViewModel.globalObjectId = this.globalObjectID;
    itemSubscriptionViewModel.globalObjectType = this.globalObjectType;
    itemSubscriptionViewModel.createdById = this.employee.id;
    itemSubscriptionViewModel.id = 0;
    itemSubscriptionViewModel.created = '';

    this.subscriptions.push(
      this.subscriptionService.addItemSubscription(itemSubscriptionViewModel).subscribe((res) => {
        const filterText = this.stripHtml(filter.filterText);
        this.subscribers.push(res);
        this.allSubscriberFilters.push(filter);
        this.subscriberFilters.push(filter);
        this.changeDetectorRef.markForCheck();
        void this.alertService.success(
          this.translateService.instant(T.common.employee_added_to_subscribers, { employee: filterText })
        );
        this.onSubscribersChanged.next(this.subscribers);
      })
    );
  }

  addSubscriberGroup(filter: FilterViewModel) {
    const itemSubscriptionViewModel = new ItemSubscriberUserGroupViewModel();
    itemSubscriptionViewModel.accountId = this.employee.accountId;
    itemSubscriptionViewModel.userGroupId = Number(filter.filterValue);
    itemSubscriptionViewModel.globalObjectId = this.globalObjectID;
    itemSubscriptionViewModel.globalObjectType = this.globalObjectType;
    itemSubscriptionViewModel.createdById = this.employee.id;
    itemSubscriptionViewModel.id = 0;
    itemSubscriptionViewModel.created = '';

    this.subscriptions.push(
      this.subscriptionService.addItemGroupSubscription(itemSubscriptionViewModel).subscribe((res) => {
        const filterText = this.stripHtml(filter.filterText);
        this.allSubscriberFilters.push(filter);
        this.subscriberFilters.push(filter);
        this.groupSubscribers.push(res);
        this.changeDetectorRef.markForCheck();
        void this.alertService.success(
          this.translateService.instant(T.common.group_added_to_subscriber_groups, { group: filterText })
        );
        this.onSubscribersGroupChanged.next([...this.groupSubscribers]);
      })
    );
  }

  removeSubscriber(filter: FilterViewModel) {
    const subscriber = this.subscribers.find((s) => s.employeeId === filter.filterValue);
    if (subscriber !== undefined) {
      this.removeSubscription(subscriber);
    }
  }

  removeSubscriberGroup(filter: FilterViewModel) {
    const subscriber = this.groupSubscribers.find((s) => s.userGroupId === filter.filterValue);
    if (subscriber !== undefined) {
      this.removeGroupSubscription(subscriber);
    }
  }

  removeSubscription(subscriber: SubscriberViewModel) {
    this.subscriptions.push(
      this.subscriptionService.removeItemSubscription(subscriber.itemSubscriberId).subscribe((res) => {
        this.subscribers.splice(this.subscribers.indexOf(subscriber), 1);
        this.allSubscriberFilters = this.allSubscriberFilters.filter(
          (s) =>
            s.filterType != this.subscriberFilterType ||
            (s.filterType == this.subscriberFilterType && s.filterValue != subscriber.employeeId)
        );
        this.changeDetectorRef.markForCheck();
        void this.alertService.success(
          this.translateService.instant(T.common.employee_removed_from_subscribers, {
            employee: `${subscriber.firstName} ${subscriber.surname}`,
          })
        );
        this.subscriberFilters = this.subscriberFilters.filter((f) => f.filterValue !== subscriber.employeeId);
        this.allSubscriberFilters = this.subscriberFilters.filter((f) => f.filterValue !== subscriber.employeeId);
        this.onSubscribersChanged.next(this.subscribers);
      })
    );
  }

  removeGroupSubscription(subscriber: SubscriberUserGroupViewModel) {
    this.subscriptions.push(
      this.subscriptionService.removeItemGroupSubscription(subscriber.itemSubscriberUserGroupId).subscribe((res) => {
        this.groupSubscribers.splice(this.groupSubscribers.indexOf(subscriber), 1);
        this.allSubscriberFilters = this.allSubscriberFilters.filter(
          (s) =>
            s.filterType != this.subscriberGroupFilterType ||
            (s.filterType == this.subscriberGroupFilterType && s.filterValue != subscriber.userGroupId)
        );
        this.changeDetectorRef.markForCheck();
        void this.alertService.success(
          this.translateService.instant(T.common.group_removed_from_subscriber_groups, { group: subscriber.title })
        );
        this.subscriberFilters = this.subscriberFilters.filter((f) => f.filterValue !== subscriber.userGroupId);
        this.allSubscriberFilters = this.subscriberFilters.filter((f) => f.filterValue !== subscriber.userGroupId);
        this.onSubscribersGroupChanged.next(this.groupSubscribers);
      })
    );
  }

  onDropdownStateChanged(dropdownState: IDropdownState, filterType: FilterTypes) {
    let element: HTMLElement = dropdownState.wrapper;
    while (element) {
      if (element.offsetTop) {
        break;
      }

      element = element.parentElement;
    }

    const wrapperTop = (dropdownState.wrapper.lastElementChild as HTMLElement).offsetTop;
    const wrapperHeight = (dropdownState.wrapper.lastElementChild as HTMLElement).offsetHeight;
    const padding: number = 10;

    if (element && element.offsetTop + wrapperTop + wrapperHeight > this.wrapper.nativeElement.offsetHeight - padding) {
      this.wrapper.nativeElement.scrollTop = this.wrapper.nativeElement.offsetHeight;
    }
    this.dropdownEventsEmitter.broadcastDropdownPositionChanged();
  }

  stripHtml(text: string): string {
    const regex = /(<([^>]*)>)/gi;

    return text.replace(regex, '').trim();
  }

  getGroupInitials(group: SubscriberUserGroupViewModel) {
    const splitted = group.title.trim().split(' ');
    if (splitted && splitted.length > 1) {
      return `${splitted[0][0].toUpperCase()}${splitted[1][0].toUpperCase()}`;
    }

    return `${group.title[0].toUpperCase()}${group.title[1].toUpperCase()}`;
  }

  private initAvailableEmployees() {
    this.subscriptions.push(
      this.commentsService.getAllEmployeesByAccountId().subscribe((res) => {
        this.availableEmployees = res;
      })
    );
  }

  public get employeeViewModelOfSubscribers(): EmployeeViewModel[] {
    if (this.availableEmployees && this.subscribers) {
      return this.availableEmployees.filter((employee) =>
        this.subscribers.find((subscriber) => subscriber.employeeId === employee.id)
      );
    }
    return null;
  }

  get isMobile(): boolean {
    return document.body.clientWidth <= Constants.xs;
  }

  private initFilters() {
    this.subscriberFilterTypeSelectorViewModel.filterType = FilterTypes.Subscriber;
    this.subscriberFilterTypeSelectorViewModel.filterSelectorType = FilterSelectorTypes.Dropdown;

    this.subscriberGroupFilterTypeSelectorViewModel.filterType = FilterTypes.Subscriber_Group;
    this.subscriberGroupFilterTypeSelectorViewModel.filterSelectorType = FilterSelectorTypes.Dropdown;
  }
}
