export enum ButtonClasses {
  small = 'button-small',
  medium = 'button-medium',
  large = 'button-large',
  primary = 'primary-button',
  secondary = 'secondary-button',
  danger = 'danger-button',
  white = 'white-button',
  successful = 'successful-button',
  'white-text' = 'white-text',
  'dark-text' = 'dark-text',
  'danger-text' = 'danger-text',
  'primary-text' = 'primary-text',
  'grey-text' = 'grey-text',
  truncated = 'button-width-68-px',
  selected = 'selected',
  fail = 'fail-button',
  pass = 'pass-button',
  unsure = 'unsure-button',
}
