import { Constants } from '../../models/constants';

export abstract class DashboardCardInterface {
  protected abstract readonly widgetName: string;
  abstract get headerTitle(): string;
  protected readonly mobileWidth = Constants.xs;
  protected abstract setSizes(): void;

  public isMobile(): boolean {
    return window.innerWidth <= this.mobileWidth;
  }

  public onResized(ev: Event) {
    this.setSizes();
  }

}
