import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum NotificationFrequencyTypes {
  Every_Time = 1,
  Daily = 2,
  Weekly = 3,
  Never = 4,
}

export namespace NotificationFrequencyTypes {
  export function translatedItems(translateService: TranslateService): { key: NotificationFrequencyTypes; value: string }[] {
    const a: { key: NotificationFrequencyTypes; value: string }[] = [];
    for (const enumMember in NotificationFrequencyTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translateItem(+enumMember, translateService) });
      }
    }
    return a;
  }
}

function translateItem(key: NotificationFrequencyTypes, translateService: TranslateService): string {
  switch (key) {
    case NotificationFrequencyTypes.Every_Time:
      return translateService.instant(T.calendar.every_time);
    case NotificationFrequencyTypes.Daily:
      return translateService.instant(T.calendar.daily);
    case NotificationFrequencyTypes.Weekly:
      return translateService.instant(T.calendar.weekly);
    case NotificationFrequencyTypes.Never:
      return translateService.instant(T.common.never);
    default:
      return 'unknown';
  }
}
