<div #header class="modal-header">
  <div class="modal-header-content">
    <h4>{{ title }}</h4>
    <i
      class="material-symbols-outlined modal-close"
      (click)="bsModalRef.hide()"
      style="font-size: 20px; margin-right: 0; float: unset !important; right: 0; position: absolute; top: 14px"
      >close</i
    >
  </div>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <p class="label" [innerHTML]="message"></p>
    </div>
  </div>
</div>
