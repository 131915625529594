import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { IncidentsSettingsService } from 'src/app/modules/shared/services/indicents-settings.service';
import { ZoneViewModel } from 'src/app/modules/settings/viewModels/zoneViewModel';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { GMapUtilities } from 'src/app/modules/shared/utilities/gMap.utilities';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { What3WordInfo } from 'src/app/modules/incidents/models/what3WordInfo';
import { ObjectMarker } from 'src/app/modules/shared/models/map/markers/objectMarker';
import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { SearchFieldComponent } from '../controls/search-field/search-field.component';
import { BaseMap } from 'src/app/modules/shared/interfaces/map/baseMapInterface';
import { W3wGridButtonComponent } from '../controls/w3w-grid-button/w3w-grid-button.component';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { MapsLoaderService } from 'src/app/modules/shared/services/mapsLoader.service';
import { IncidentsManager } from 'src/app/modules/shared/managers/incidents.manager';
import { PulsingIncidentMarker } from 'src/app/modules/shared/models/map/markers/pulsingIncidentMarker';
import { DarkOverlayMapType } from 'src/app/modules/shared/models/map/overlays/darkOverlayMapType';
import { ObjectEventEmitters } from 'src/app/modules/shared/events/object.events';
import { What3WordResponseModel } from 'src/app/modules/incidents/models/what3WordResponseModel';
import { JobsUtilities } from 'src/app/modules/incidents/utilities/job-utilities.util';

/**
 * The purpose of this component is to display/edit a single location of an object - incident, risk, runsheet item and so on.
 * It also takes care of displaying the available zones/areas on the map so the user can have a context when specifing
 * the object's location.
 *
 * However this component is pretty complex due to the fact that it allows editing/setting an object's location in more
 * than one way throught the available controls:
 *
 * 1. Search for an address - allows to search for an address using a remote service to get its coordinates.
 * 2. Search for W3W words - allows to search for w3w words using a remote service to get its coordinates.
 * 3. My location look up - allows to determine the user's location using the build in phone gps device or through a call
 *    to remote service to look up for approximate address based on IP.
 *
 * In adition this component is desgined to be used in two different modes:
 * 1. Readonly mode - simply displays an object's location marker on the map.
 * 2. Edit mode - allows the user to edit a location of an object.
 *
 * In case it is used in edit mode, the component needs to take care of the fact that it may be called as a separate instance
 * inside a modal window while at the same time it is loaded in a readonly mode on the main page. Having that in mind when
 * the object's location is edited the readonly version of the component needs to react to the changes made inside the modal one.
 * This can be handled in two different ways:
 *
 * 1. Throught he ngOnChanges lifecycle
 * 2. With subscripton to an event emitter.
 */


@Component({
  selector: 'app-incident-map',
  templateUrl: './incident-map.component.html',
  styleUrls: ['./incident-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentMapComponent extends BaseMap implements OnInit, OnDestroy, OnChanges {
  @ViewChild('map') mapElement: ElementRef<HTMLElement>;
  @ViewChild('topLeftControlsWrapper') topLeftControlsWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('topCenterControlsWrapper') topCenterControlsWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('zoneAreaControlBtnWrapper') zoneAreaControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('w3wGridControlBtnWrapper') w3wGridControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('searchControl') searchControl: SearchFieldComponent;
  @ViewChild(W3wGridButtonComponent) w3wGridControl: W3wGridButtonComponent;
  @ViewChild('myLocationControlBtnWrapper') myLocationControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('noLocationControlBtnWrapper') noLocationControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('mapTypesBtnWrapper') mapTypesBtnWrapper: ElementRef<HTMLDivElement>;

  /**
   * The position of the marker.
   * If the marker has no position set BOTH (lat and lng) to 0.
   */
  @Input() lat: number;
  @Input() lng: number;

  /**
   * Indicator whether the component is to be initialized in readonly or edit mode.
   * Based on this property the user can or can not edit the location of the object
   * and also in case it is "readonly" mode most of the controls will be omitted.
   */
  @Input() editMode: boolean;

  /**
   * The map zoom which the component should be initialized.
   * Please have in mind that depending on whether the object
   * has position or not this property may be omitted because
   * if no position is specified yet the zoom level of the map
   * will be adjusted so it fits all the available zones/areas.
   */
  @Input() defaultZoom = IncidentMapComponent.DEFAULT_ZOOM;

  /**
   * The color of the marker.
   * This color should be some of the RAGs or other predefined status of the object.
   */
  @Input() markerColor: string;

  /**
   * This input field is the same as the object types. However some object types
   * have subtipes and instead of passing two different fields the MarkerType enum
   * is introduced.
   */
  @Input() markerType: MarkerType = MarkerType.INCIDENT;

  /**
   * The ID of the object location is to be displayed.
   * This field is not mandatory as the component doesnt care about the update process
   * which is handled outside of it.
   *
   * However in case "reflectExternalUpdates" is set to true (by default) when an update
   * is done outside of the component, if the ID is specified the component will reflect
   * the location change.
   */
  @Input() objectId: number;

  /**
   * Indicator whether to render the "No location" control button.
   * The purpose of this button is to remove the position of the object.
   *
   * !NOTE!
   * If the component is initialized in readonly mode (editMode = false) this property
   * will be omitted and the button wont be rendered!
   */
  @Input() showNoLocationButton = true;

  /**
   * Indicator whether to rendered the "My location" control button.
   * The purpose of this button is to locate the user's position. Please have in mind that
   * based on the device the app is being used the accuracy may vary significantly!
   *
   * !NOTE!
   * If the component is initialized in readonly mode (editMode = false) this property
   * will be omitted and the button wont be rendered!
   */
  @Input() showMyLocationButton = true;

  /**
   * Indicator whether to rendered the "Search".
   * The purpose of this control is to reverse geocode some address and/or W3W word. In addition
   * this control lets u search for the user's zones/areas.
   *
   * !NOTE!
   * If the component is initialized in readonly mode (editMode = false) this control
   * will have limited functionality and will allow only zones/areas to be looked up.
   */
  @Input() showSearchButton = true;

  /**
   * Indicator whether to rendered the 3x3 grid overlay which represents a W3W "location".
   * For more information read the w3w documentation.
   *
   * !NOTE!
   * If this marker type is not Incident one, setting this to true will have no effect.
   */
  @Input() showW3WGridButton = true;

  /**
   * Indicator whether to render the zones and areas toggle button.
   *
   * !NOTE!
   * If no zones/areas are available for the account the botton won't be rendered
   * no matter if you set this parameter to true!
   */
  @Input() showZonesAndAreasButton = true;

  @Input() enableAddressSearch = true;
  @Input() enableWhat3WordSearch = true;
  @Input() enableZonesAreasSearch = true;

  /**
   * In case of editMode = true, the purpose of these fields is to keep a track if one or more
   * zones/areas has been assigned to the object. The component monitors the size of the
   * arrays and when the first zone/area is set the object's location automatically gets updated
   * to the center of zone/area location.
   *
   * The component itself doesn't give a control to assign one or more zones/areas to the object
   * so the assigning is taken care of outside the component.
   */
  @Input() selectedZones: ZoneViewModel[] = [];
  @Input() selectedAreas: AreaViewModel[] = [];

  /**
   * The user's zones/areas which will be rendered over the map.
   * If u dont provide an array withe them the component will make a request to the backend
   * and loads them innerly.
   *
   * !!!
   * It is recommended that you do not modify/filter the arrays you pass from outside of the
   * component as the polygons rendered on the map will be static and wont reflect the changes
   * of the arrays made outside of the component.
   *
   * So, if you plan to filter the arrays, you should either make a copy before passing to the
   * component or simply don't pass and let the component loads the zones/areas independently.
   * !!!
   */
  @Input() zones: ZoneViewModel[];
  @Input() areas: AreaViewModel[];

  /** DEPRECATED and will be removed soon. */
  @Input() showIncidentZonesUponLoad = false;

  /**
   * Indicator whether to reflect external changes of the object.
   * If the component is loaded in editMode = true and is placed inside a modal and underneath it
   * the same component is initialized in readonly mode this property should be set to true
   * so the readonly version of the component reflects the changes made inside the modal one.
   *
   * Said directly: The readonly instance of this component should have this set to true in order
   * to reflect the changes made by the non readonly version of it (in case it is loaded on the same
   * page inside a modal!)
   */
  @Input() reflectExternalUpdates = true;

  /**
   * The event is triggered when the user has changed the location
   * but any third party service request (reverse geocoding, w3w and so on)
   * hasn't been completed yet.
   */
  @Output() locationUpdateStarted = new EventEmitter<void>();

  /**
   * Outputs the event when the location is changed so the caller component
   * takes care of it.
   */
  @Output() onLocationUpdated = new EventEmitter<LocationViewModel>();


  private position: google.maps.LatLngLiteral;
  private zoom: number;
  private allowLocationUpdateBroadcast = true;

  public mapLoaded = false;
  public locationMarker: ObjectMarker = null;

  userDraggedMarker = false;
  subscriptions: Subscription[] = [];
  accountId: number;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    protected readonly geoLocationService: GeoLocationService,
    protected readonly localisationService: LocalisationService,
    protected readonly mapsLoaderService: MapsLoaderService,
    protected readonly incidentManager: IncidentsManager,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly incidentsSettingsService: IncidentsSettingsService,
  ) {
    super(
      mapsLoaderService,
      geoLocationService,
      localisationService,
      incidentsSettingsService
    );
  }

  ngOnInit() {
    this.accountId = this.authenticationService.getCurrentAccount().id;
    this.zoom = this.defaultZoom;

    if(this.lat && this.lng) {
      this.position = {
        lat: this.lat,
        lng: this.lng
      }
    }

    if(this.markerType !== MarkerType.INCIDENT) {
      this.showW3WGridButton = false;
    }

    if(!this.editMode) {
      this.showMyLocationButton = false;
      this.showNoLocationButton = false;
      this.showSearchButton = false;
    }

    this.initMap();
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.mapLoaded) {
      if (changes.selectedZones && this.editMode) {
        this.initZonesMappedWithItem();
      }
      if (changes.selectedAreas && this.editMode) {
        this.initAreasMappedToItem();
      }

      if(changes.lat || changes.lng) {
        if(this.reflectExternalUpdates) {
          this.allowLocationUpdateBroadcast = false;
          this.position = (this.lat === 0 && this.lng === 0)
            ? null
            : { lat: this.lat, lng: this.lng }

          this.setObjectLocation(this.position, 0, false);
          this.allowLocationUpdateBroadcast = true;
        }
      }

      if(changes.showW3WGridButton) {
        if(changes.showW3WGridButton.currentValue === true && this.markerType !== MarkerType.INCIDENT) {
          this.showW3WGridButton = false;
        }
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * This is a callback method which is triggered after the user's location is determined
   * when the "My location" control button is clicked.
   *
   * Basically it is a wrapper method which takes's care of using the user's location
   * in order to satisfy the purpose of the component's functionality.
   *
   * @param $latLng the coordinates of the location
   */
  public onMyLocationAvailable($latLng: google.maps.LatLng): void {
    this.setObjectLocation($latLng.toJSON(), BaseMap.MY_LOCATION_DEFAULT_ZOOM);
  }

  /**
   * Initialization of the map.
   * This method overrides the super class one AND also MUST call its super version.
   */
  protected initMap(): void {
    const mapOptions = {
      center: this.position || {lat: 0, lng: 0},
      mapTypeControl: false
    };

    this.subscriptions.push(
      this.googleMapsAPIs.subscribe(() => {
        super.initMap(this.mapElement, mapOptions);
        this.registerMapEvents();
      })
    );
  }

  /**
   * Registering map events.
   * This method MUST be called after the map is initialized.
   */
  private registerMapEvents(): void {
    this.map.addListener('maptypeid_changed', () => {
      const mapTypeId = this.map.getMapTypeId();
      if (mapTypeId === google.maps.MapTypeId.SATELLITE || mapTypeId === google.maps.MapTypeId.HYBRID) {
        if (this.map.overlayMapTypes.getAt(0) instanceof DarkOverlayMapType === false) {
          this.map.overlayMapTypes.insertAt(0, new DarkOverlayMapType(new google.maps.Size(256, 256)));
        }
      } else {
        if (this.map.overlayMapTypes.getAt(0) instanceof DarkOverlayMapType) {
          this.map.overlayMapTypes.removeAt(0);
        }
      }
    });
    this.map.addListener('center_changed', () => {
      if (this.locationMarker) {
        if (this.map.getBounds().contains(this.locationMarker.getPosition())) {
          const mapTypeId = this.map.getMapTypeId();
          if (mapTypeId === google.maps.MapTypeId.SATELLITE || mapTypeId === google.maps.MapTypeId.HYBRID) {
            if (this.map.overlayMapTypes.getAt(0) instanceof DarkOverlayMapType === false) {
              this.map.overlayMapTypes.insertAt(0, new DarkOverlayMapType(new google.maps.Size(256, 256)));
            }
          }
        } else {
          if (this.map.overlayMapTypes.getAt(0) instanceof DarkOverlayMapType) {
            this.map.overlayMapTypes.removeAt(0);
          }
        }
      }
    });

    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      if(this.position) {
        this.setObjectLocation(this.position, this.zoom);
      }

      this.processZonesAndAreas();

      this.initZonesMappedWithItem();
      this.initAreasMappedToItem();

      if (this.editMode) {
        super.registerTapAndHoldEven((event: google.maps.MapMouseEvent) => {
          this.setObjectLocation(event.latLng.toJSON());
        });
      }

      this.mapLoaded = true;
      this.changeDetectorRef.detectChanges();

      this.initControlsOverlay();
    });
  }


  /**
   * This method handles the creation of zones and areas polygons which are displayed on the map.
   *
   * This method has effect ONLY if the input zones/areas are present to this component as
   * it uses their array of shape points in order to buuld the corresponding polygon.
   *
   * The polygons are created using the google maps api and that's why they are being holded
   * outside the zones/areas model using their ids for mapping.
   *
   * For each zone/area which has array of shape poings is created a google maps polygon
   * which is mapped to the zone/area's ID using a standart Map.
   *
   * @param fitBounds
   * Whether to zoom the map as much as possible while keeping all the polygons in the map viewport.
   */
  private buildPolygons(fitBounds = false): void {
    super.buildPolygonsFromZonesAndAreas(this.zones, this.areas, true);

    if (this.editMode) {
      super.zonesPolygon.forEach((polygon) => {
        super.registerTapAndHoldEvenOnPolygon(polygon, (event: google.maps.MapMouseEvent) => {
          this.setObjectLocation(event.latLng.toJSON());
        });
      });

      super.areasPolygon.forEach((polygon) => {
        super.registerTapAndHoldEvenOnPolygon(polygon, (event: google.maps.MapMouseEvent) => {
          this.setObjectLocation(event.latLng.toJSON());
        });
      });
    }

    if(fitBounds) {
      super.fitZonesBounds();
    }
  }

  /**
   * This method takes care to position each control on a specific map location.
   * As most of the controls become functional ONLY after the map is fully initialized
   * this methid MUST be called after the creation of the map and when its tiles are
   * loaded.
   */
  private initControlsOverlay(): void {
    if (this.showW3WGridButton && this.markerType === MarkerType.INCIDENT) {
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.w3wGridControlBtnWrapper.nativeElement);
    }

    if (this.editMode) {
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.topLeftControlsWrapper.nativeElement);

      if (super.isMobile) {
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.topCenterControlsWrapper.nativeElement);
      }
    }

    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.zoneAreaControlBtnWrapper.nativeElement);
    this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.mapTypesBtnWrapper.nativeElement);
  }


  /**
   * This is a callback method which is triggered after the w3w control button is clicked.
   *
   * @param $state whether the button is active or not.
   */
  public onW3WGridControlClicked($state: boolean): void {
    if(this.locationMarker) {
      const pulsingIncidentMarker = this.locationMarker as PulsingIncidentMarker;
      pulsingIncidentMarker.toggleWhat3Polygon($state);
    }
  }

  /**
   * This is a callback method which is triggered after the w3w grid visibility
   * on the map has changed.
   *
   * @param $gridVisible whether the grid is visible on the map or not
   */
  public onW3WGridToggled($gridVisible: boolean): void {
    if(this.locationMarker && this.markerType === MarkerType.INCIDENT) {
      const pulsingIncidentMarker = this.locationMarker as PulsingIncidentMarker;
      pulsingIncidentMarker.toggleWhat3Polygon($gridVisible);
    }
  }

  public onSearchStateChanged(expanded: boolean): void {
    if (this.noLocationControlBtnWrapper) {
      this.noLocationControlBtnWrapper.nativeElement.style.display = expanded ? 'none' : 'block';
    }
    if (this.myLocationControlBtnWrapper) {
      this.myLocationControlBtnWrapper.nativeElement.style.display = expanded ? 'none' : 'block';
    }
  }

  public onAutocompleteResult(place: google.maps.places.PlaceResult): void {
    if (place) {
      this.position = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };

      if(this.locationMarker) {
        this.locationMarker.position = new google.maps.LatLng(this.position);

        //Removing the what3address infoBox
        if (this.markerType === MarkerType.INCIDENT) {
          const incidentMarker = this.locationMarker as PulsingIncidentMarker;
          incidentMarker.what3WordInfo.polygon?.setMap(null);
          incidentMarker.w3wPopupLabelVisible = false;
        }
      } else {
        this.initMarker();
      }

      this.map.panTo(this.position);
      this.map.setZoom(BaseMap.LOCATION_SEARCH_DEFAULT_ZOOM);

      this.onLocationUpdate(this.position, place.formatted_address);
    }
  }

  public onWhat3WordsResult(what3WordInfo: What3WordInfo): void {
    this.position = {
      lat: what3WordInfo.lat,
      lng: what3WordInfo.lng
    };

    if(this.locationMarker) {
      this.locationMarker.position = new google.maps.LatLng(this.position);

      //Removing the what3address infoBox
      if (this.markerType === MarkerType.INCIDENT) {
        const incidentMarker = this.locationMarker as PulsingIncidentMarker;
        incidentMarker.what3WordInfo.polygon?.setMap(null);
        incidentMarker.w3wPopupLabelVisible = false;
      }
    } else {
      this.initMarker();
    }

    this.map.panTo(this.position);
    this.map.setZoom(BaseMap.W3W_SERCH_DEFAULT_ZOOM);
    this.map.setMapTypeId('satellite');

    this.onLocationUpdate(this.position);
  }

  /**
   * Removes the object's location and broadcast the event.
   *
   * @param broadcastLocationUpdated
   * Whether to broadcast the event or not.
   * NOTE! If the global variable "enableLocationUpdateBroadcast" is set to false, this one will be omited!!!
   */
  public setNoLocation(broadcastLocationUpdated: boolean = true): void {
    this.position = null;

    if (this.locationMarker !== null) {
      this.locationMarker.setMap(null);
      this.locationMarker = null;
    }

    if(this.allowLocationUpdateBroadcast && broadcastLocationUpdated) {
      this.onLocationUpdate(this.position);
    }
  }


  /**
   * !!! IMPORTANT !!!
   *
   * If you call this method within ngOnChanges lifecycle
   * ALWAYS MAKE SURE the global variable "enableLocationUpdateBroadcast" or the third parameter "broadcastLocationUpdated" is set to FALSE
   *
   * Updates the object's marker position and broadcast the related event if specified by the caller.
   * The complexity of this method is due to the fact that it reduces code duplication as the component allows
   * the marker position to be set/created from few different controls.
   *
   * If the object's marker isn't created yet, it gets created with the specified position.
   *
   * @param position
   * The position at which the marker will be set.
   *
   * @param zoomLevel
   * The zoom level of the map after the position is updated. In case no zoomLevel is specified
   * the current one will be used.
   *
   * @param broadcastLocationUpdated
   * Indicator whether to broadcast the updated position.
   * NOTE! If the global variable "enableLocationUpdateBroadcast" is set to false, this one will be omited!!!
   */
  private setObjectLocation(position: google.maps.LatLngLiteral, zoomLevel: number = 0, broadcastLocationUpdated: boolean = true): void {
    if(position) {
      this.position = position;

      if(this.locationMarker) {
        this.locationMarker.position = new google.maps.LatLng(position);
      } else {
        this.initMarker();
      }

      this.map.panTo(position);
      this.map.setZoom(zoomLevel > 0 ? zoomLevel : this.map.getZoom());

      if(this.allowLocationUpdateBroadcast && broadcastLocationUpdated) {
        this.onLocationUpdate(position);
      }

      this.changeDetectorRef.markForCheck();
    } else {
      this.setNoLocation(broadcastLocationUpdated);
    }
  }

  /**
   * This method should be called only after the object's location is updated because in case no place address
   * is specified for the new location it makes a request for reverse geocoding the address of the new position.
   *
   * Once the place address is available the method broadcast the "onLocationUpdated" event.
   *
   * @param position
   * The new position of the object's marker. If the position is undefined or null the place address is omitted
   * and broacasted as an empty string.
   *
   * @param placeAddress
   * The location's administrative address.
   * If the address isn't provided, a request to third party service is being made to reverse geocode the location.
   */
  private onLocationUpdate(position: google.maps.LatLngLiteral, placeAddress: string = null): void {
    this.locationUpdateStarted.emit();

    let updatedLocation = new LocationViewModel(0, 0, '');

    if(position) {
      if(placeAddress) {
        updatedLocation = new LocationViewModel(position.lat, position.lng, placeAddress);
        this.onLocationUpdated.emit(updatedLocation);
      } else {
        this.geoLocationService.getAddressFromCoordinates(position.lat, position.lng)
          .subscribe((addressResults) => {
            const formattedAddress = addressResults && addressResults[0] ? addressResults[0].formatted_address : '';
            updatedLocation = new LocationViewModel(position.lat, position.lng, formattedAddress);
            this.onLocationUpdated.emit(updatedLocation);
          });
      }
    } else {
      this.onLocationUpdated.emit(updatedLocation);
    }
  }

  private initZonesMappedWithItem(): void {
    if(this.selectedZones?.length === 1) {
      const zone = this.selectedZones[0];
      if(zone?.zoneShapePoints) {
        const polygon = GMapUtilities.buildPolygonFromZoneModel(zone.zoneShapePoints, null);
        const polyLatLngBounds = GMapUtilities.getPolygonLatLngBounds(polygon);
        this.map.fitBounds(polyLatLngBounds);

        this.position = polyLatLngBounds.getCenter().toJSON();
        this.setObjectLocation(this.position, 0, true);
      }
    }
  }

  private initAreasMappedToItem(): void {
    if(this.selectedAreas?.length === 1) {
      const area = this.selectedAreas[0];
      if(area?.areaShapePoints) {
        const polygon = GMapUtilities.buildPolygonFromAreaModel(area.areaShapePoints, null);
        const polyLatLngBounds = GMapUtilities.getPolygonLatLngBounds(polygon);
        this.map.fitBounds(polyLatLngBounds);

        this.position = polyLatLngBounds.getCenter().toJSON();
        this.setObjectLocation(this.position, 0, true);
      }
    }
  }

  /**
   * Initialization of the object's marker using google maps marker api.
   * Depending of the global input parameter MarkerType the behaviour and/or look and feel of the marker will vary.
   *
   * @param what3WordInfo
   * This parameter is taken into consideration ONLY IF the object type is INCIDENT.
   * For more information check the W3W service documentation.
   */
  private initMarker(what3WordInfo: What3WordInfo = null): void {
    if(this.markerType === MarkerType.INCIDENT) {
      const severity = this.incidentManager.getSeverityByColor(this.markerColor) || 0;
      const incidentMarker = new PulsingIncidentMarker(
        new google.maps.LatLng(this.position),
        this.markerColor,
        severity,
        this.map
      );

      if (what3WordInfo === null) {
        this.geoLocationService.getWhat3WordsFromCoordinates(this.position.lat, this.position.lng).subscribe({
          next: (response: What3WordResponseModel) => {
            const what3Words = response.words;
            incidentMarker.w3wPopupLabel = what3Words;

            incidentMarker.what3WordInfo = this.getWhat3WordInfoFromResponse(response);
            incidentMarker.toggleWhat3Polygon(this.w3wGridControl.w3wGridVisible);
          },
          error: (_error) => {}
        });
      } else {
        incidentMarker.w3wPopupLabel = what3WordInfo.address;
        incidentMarker.what3WordInfo = what3WordInfo;
      }

      this.locationMarker = incidentMarker;
    }
    else {
      this.locationMarker = new ObjectMarker(
        this.markerType,
        new google.maps.LatLng(this.position),
        this.markerColor,
        this.map
      );
    }

    this.locationMarker.draggable = this.editMode;
    this.locationMarker.attachEvent('dragstart', () => {
      if (this.markerType === MarkerType.INCIDENT) {
        const incidentMarker = this.locationMarker as PulsingIncidentMarker;
        incidentMarker.what3WordInfo.polygon?.setMap(null);
        incidentMarker.w3wPopupLabelVisible = false;
      }
    });
    this.locationMarker.attachEvent('dragend', (_mouseEvent: google.maps.MapMouseEvent) => {
      this.userDraggedMarker = true;

      this.position = this.locationMarker.getPosition().toJSON();
      this.zoom = 20;

      if (this.markerType === MarkerType.INCIDENT) {
        const incidentMarker = this.locationMarker as PulsingIncidentMarker;
        this.geoLocationService.getWhat3WordsFromCoordinates(this.position.lat, this.position.lng)
          .subscribe((response: What3WordResponseModel) => {
            const what3Words = response.words;
            incidentMarker.w3wPopupLabel = what3Words;
            incidentMarker.w3wPopupLabelVisible = true;

            incidentMarker.what3WordInfo = this.getWhat3WordInfoFromResponse(response);
            incidentMarker.toggleWhat3Polygon(this.w3wGridControl.w3wGridVisible);
          });
      }
      this.onLocationUpdate(this.position);
    });
  }

  private getWhat3WordInfoFromResponse(response: What3WordResponseModel): What3WordInfo {
    const what3Words = response.words;

    const southEest = response.square.southwest;
    const northEast = response.square.northeast;
    const squareBounds = new google.maps.LatLngBounds();
    squareBounds.extend(new google.maps.LatLng(southEest.lat, southEest.lng));
    squareBounds.extend(new google.maps.LatLng(northEast.lat, northEast.lng));

    const what3SquarePolygon = new google.maps.Polygon({
      map: null,
      paths: GMapUtilities.convertLatLngBoundsToPolygonPath(squareBounds),
      fillColor: this.markerColor,
      fillOpacity: 0.3,
      strokeColor: this.markerColor,
      strokeOpacity: 1,
      strokeWeight: 1,
    });

    const what3WordInfo = {
      polygon: what3SquarePolygon,
      address: what3Words,
    } as What3WordInfo;

    return what3WordInfo;
  }

  private processZonesAndAreas(): void {
    if (!this.zones || !this.areas) {
      super.getZonesAndAreas((zones, areas) => {
          this.zones = zones.slice();
          this.areas = areas.slice();
          this.buildPolygons(false);

          this.changeDetectorRef.markForCheck();
      });
    } else {
      const fitPolysBounds = this.position === null;
      this.buildPolygons(fitPolysBounds);
    }
  }

  // private getZonesAndAreas() {
  //   this.subscriptions.push(
  //     forkJoin([this.incidentsSettingsService.getIncidentZonesViewModels(), this.incidentsSettingsService.getAreas()]).subscribe(
  //       ([zones, areas]) => {
  //         this.zones = zones.slice();
  //         this.areas = areas.slice();
  //         this.buildPolygons(false);

  //         this.changeDetectorRef.markForCheck();
  //       }
  //     )
  //   );
  // }

  private initSubscriptions(): void {
    if(this.objectId && !this.editMode && this.reflectExternalUpdates) {
      this.subscriptions.push(
        this.objectEventEmitters.objectUpdated$.subscribe(result => {
            const model = result.model;

            const existFlag = model && model.id && this.objectId && model.id === this.objectId;
            const changePositionFlag = model.latitude !== this.lat && model.longitude !== this.lng;
            if(existFlag && changePositionFlag) {
              this.allowLocationUpdateBroadcast = false;
              this.position = (this.lat === 0 && this.lng === 0)
              ? null
              : { lat: this.lat, lng: this.lng }

              this.setObjectLocation(this.position, 0, false);
              this.allowLocationUpdateBroadcast = true;
            }

            if(this.markerType === MarkerType.JOB && model.status) {
              const color = JobsUtilities.GetJobStatusColor(model.status);
              const changeColorFlag = color !== this.locationMarker.getColor();
              if(existFlag && changeColorFlag) {
                this.markerColor = color;
                this.locationMarker.setColor(color);
              }
            }
        })
      );
    }
  }

  // Override functions - Used in IncidentDetailsComponent to reset the location/zoom/add "No Location" overlay
  setComponentLocation(lat: number, lng: number): void {
    this.lat = lat;
    this.lng = lng;
  }

  setComponentLocationAtZoom(lat: number, lng: number, zoom: number): void {
    this.setComponentLocation(lat, lng);
    this.setZoom(zoom);
  }

  setZoom(zoom: number): void {
    this.zoom = zoom;
  }
}
