import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
@Component({
  selector: 'app-filters-mobile-header',
  templateUrl: './filters-mobile-header.component.html',
  styleUrls: ['./filters-mobile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersMobileHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() useClear: boolean;
  @Input() clearDisabled: boolean;

  @Output() cancel = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();
  cancelBtnText = T.common.cancel;
  clearBtnText = T.common.clear;
/**
 *
 */
constructor(private readonly translationService: TranslateService) {

}

  ngOnInit() {
    this.cancelBtnText = this.translationService.instant(T.common.cancel);
    this.clearBtnText = this.translationService.instant(T.common.clear);
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onClear(): void {
    this.clear.emit();
  }
}
