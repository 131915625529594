import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { ObjectTypes } from '../enums/objectTypes';
import { ResponseModel } from '../models/responseModel';
import { FilterTypes } from '../enums/filterTypes';
import { Account } from '../models/account';
import { AuthenticationService } from './authentication.service';
import { Employee } from '../models/employee';
import { OperationTypes } from '../enums/operationTypes';
import { EmployeeUtil } from '../utilities/employee.utilities';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { ObjectEventEmitters } from '../events/object.events';
import { EmployeeDetailsViewModel } from '../viewModels/employeeDetailsViewModel';
import { IncidentItemDetailsViewModel } from '../../incidents/viewModels/incidentItemDetailsViewModel';
import { PrivacyStatuses } from '../enums/privacyStatuses';
import { IncidentItemTypes } from '../../incidents/enums/incidentItemTypes';
import { NotificationsService } from './notifications.service';
import { AllowedFiltersService } from './allowed-filters.service';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { Configuration } from 'src/app/app.constants';
import { SignalREventEmitters } from '../events/signalr.events';
import { AlertService } from './alert.service';
import { SignalRToastrConfig } from '../utilities/signalr-toastr-config.utilities';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import { AuthService } from '../../auth/auth.service';
import { HistoryEntryViewModel } from '../viewModels/history-feed/historyEntryViewModel';
import { WtStorageService } from './wt-storage.service';
import { NotificationItemViewModel } from '../viewModels/notificationItemViewModel';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private account: Account;
  private employee: Employee;
  private connectionLost = false;
  private tastrConfig = new SignalRToastrConfig();

  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthService,
    private readonly objectEventEmitter: ObjectEventEmitters,
    private readonly notificationsService: NotificationsService,
    private readonly configuration: Configuration,
    private readonly signalREventEmitters: SignalREventEmitters,
    private readonly alertService: AlertService,
    private readonly wtStorage: WtStorageService
  ) {}

  public startConnection = () => {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();

    const options: IHttpConnectionOptions = {
      skipNegotiation: true,
      transport: 1, // WebSockets
    };

    this.startDataConnection(options);
  };

  public addListener = () => {
    this.hubConnection.on('data', (response: [ResponseModel] | ResponseModel) => {
      let data = response[0];
      if (!data) {
        data = response;
      }

      if (!this.account) {
        this.account = this.authenticationService.getCurrentAccount();
      }

      if (!this.employee) {
        this.employee = this.authenticationService.getCurrentEmployee();
      }

      if (this.account && data.accountId && data.accountId === this.account.id) {
        if (
          data.type === ObjectTypes.Employee &&
          (data.operationType === OperationTypes.Delete || data.operationType === OperationTypes.Update) &&
          data.response.employeeID === this.employee.id
        ) {
          let forceLogout = false;

          if (data.operationType === OperationTypes.Delete) {
            forceLogout = true;
          } else {
            const employeeData = data.response as EmployeeDetailsViewModel;
            if (!employeeData.isActive) {
              forceLogout = true;
            }
          }

          if (forceLogout) {
            this.authenticationService.logout();
          }
        } else if (data.type === ObjectTypes.Allowed_Filter) {
          const filterViewModels = data.response as FilterViewModel[];

          if (
            (this.employee,
            filterViewModels.length > 0 && EmployeeUtil.permissionByFilterViewModel(this.employee, filterViewModels[0]))
          ) {
            switch (data.operationType) {
              case OperationTypes.Create: {
                this.allowedFiltersService.addFilterSettings(filterViewModels);
                break;
              }
              case OperationTypes.Delete: {
                this.allowedFiltersService.deleteFilterSettings(filterViewModels);
                break;
              }
              case OperationTypes.Update: {
                this.allowedFiltersService.updateFilterSettings(filterViewModels, false);
                break;
              }
              case OperationTypes.Archive: {
                this.allowedFiltersService.deleteFilterSettings(filterViewModels);
                break;
              }
            }
          }
        } else if (this.employeeHasAccess(this.employee.id, data.type, data.response)) {
          switch (data.operationType) {
            case OperationTypes.Create: {
              this.objectEventEmitter.broadcastObjectAdded(data.id, data.type, data.response);
              break;
            }
            case OperationTypes.Delete: {
              this.objectEventEmitter.broadcastObjectDeleted(data.id, data.type, data.response);
              break;
            }
            case OperationTypes.Update: {
              this.objectEventEmitter.broadcastObjectUpdated(data.id, data.type, data.response);
              break;
            }
            case OperationTypes.Archive: {
              this.objectEventEmitter.broadcastObjectArchived(data.id, data.type, data.response);
              break;
            }
            case OperationTypes.RagBreakdownUpdate: {
              this.objectEventEmitter.broadcastRagBreakdownUpdated(data.id, data.type, data.response);
              break;
            }
            case OperationTypes.CreateList: {
              this.objectEventEmitter.broadcastListAdded(data.id, data.type, data.response);
              break;
            }
            case OperationTypes.DeleteList: {
              this.objectEventEmitter.broadcastListDeleted(data.id, data.type, data.response);
              break;
            }
            case OperationTypes.UpdateList: {
              this.objectEventEmitter.broadcastListUpdated(data.id, data.type, data.response);
              break;
            }
            case OperationTypes.FilterLightUpdate: {
              this.objectEventEmitter.broadcastListLightUpdated(data.id, data.type, data.response);
              break;
            }
          }
        }
      }
    });
  };

  public addNotificationsListener = () => {
    this.hubConnection.on('notifications', (data: NotificationItemViewModel[]) => {

      if (!this.account) {
        this.account = this.authenticationService.getCurrentAccount();
      }

      if (!this.employee) {
        this.employee = this.authenticationService.getCurrentEmployee();
      }
      if (data[0] && data[0].employeeId === this.employee.id) this.notificationsService.addNotifications(data);
    });
  };

  public addHistoryListener = () => {
    this.hubConnection.on('history', (response: [ResponseModel] | ResponseModel) => {
      let data = response[0];

      if(!data)
      {
        data = response;
      }

      if (!this.account) {
        this.account = this.authenticationService.getCurrentAccount();
      }

      if (!this.employee) {
        this.employee = this.authenticationService.getCurrentEmployee();
      }

      if (data.accountId == this.employee.accountId) {
        this.objectEventEmitter.broadcastHistoryAdded({objectId: data.id, objectType: data.type, history: data.response as HistoryEntryViewModel});
      }
    });
  };

  private startDataConnection(options: signalR.IHttpConnectionOptions) {

     const token = this.authService.getAccessToken();

    options.accessTokenFactory = () => token;


    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${this.configuration.BackEndAPI}/WeTrackHub?uId=${this.employee.id}&aid=${this.account.id}`, options)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Error)
      .configureLogging(signalR.LogLevel.Debug)
      .configureLogging(signalR.LogLevel.Trace)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        if (this.connectionLost) {
          this.connectionLost = false;
          void this.alertService.info(
            'Connection restored. Click here to reload the page and see any updates you missed.',
            '',
            this.tastrConfig,
            () => location.reload()
          );
        }
      })
      .then(() => {
        this.addListener();
        this.addNotificationsListener();
        this.addHistoryListener();
      })
      .catch((err) => {
        console.log('##ERROR: On start connection');
        console.log(err);

        if (!this.connectionLost) {
          this.connectionLost = true;
          //show alert
          void this.alertService.info(
            'You are no longer connected and may miss updates until connection is restored.',
            '',
            this.tastrConfig
          );
        }
        setTimeout(() => this.startConnection(), 5000);
      });

    this.hubConnection.onclose(() => {
      if (!this.connectionLost) {
        this.connectionLost = true;
        void this.alertService.info(
          'You are no longer connected and may miss updates until connection is restored.',
          '',
          this.tastrConfig
        );
      }

      setTimeout(() => this.startConnection(), 5000);
    });

    this.hubConnection.onreconnected((a) => {
      if (this.connectionLost) {
        this.connectionLost = false;
        void this.alertService.info(
          'Connection restored. Click here to reload the page and see any updates you missed.',
          '',
          this.tastrConfig,
          () => location.reload()
        );
      }
    });

    this.hubConnection.onreconnecting((err) => {
      console.log('##ERROR: reconnecting issues');
      console.log(err);
      if (!this.connectionLost) {
        this.connectionLost = true;
        //show alert
        void this.alertService.info(
          'You are no longer connected and may miss updates until connection is restored.',
          '',
          this.tastrConfig
        );
      }
    });
  }

  employeeHasAccess(employeeID: number, type: ObjectTypes, response: any): boolean {
    let hasAccess = false;
    if (type === ObjectTypes.IncidentItem) {
      const incident = response as IncidentItemDetailsViewModel;
      if (incident.incidentItemType === IncidentItemTypes.Log) {
        return true;
      } else if (incident.privacyStatus === PrivacyStatuses.Open) {
        if (EmployeeUtil.IsAdmin(this.employee)) {
          hasAccess = true;
        }

        let hasPerm = EmployeeUtil.hasReadPermission(this.employee, FilterTypes.Incident, [incident.id], false);

        if (!hasPerm) {
          hasPerm = EmployeeUtil.hasOwnerPermissions(this.employee.id, incident.filters);
        }

        if (hasPerm && incident.privacyStatus == PrivacyStatuses.Open) {
          hasAccess = true;
        } else if (incident.departments && incident.departments.length) {
          incident.departments.forEach((dep) => {
            const hasDepartmentPermission = EmployeeUtil.permissionByModuleTypeAndValue(
              this.employee,
              ModuleTypes.Incidents,
              FilterTypes.Department,
              dep.id
            );
            if (hasDepartmentPermission) {
              hasAccess = true;
            }
          });
        }
      } else {
        const isAdmin = EmployeeUtil.IsAdmin(this.employee);
        const perm = EmployeeUtil.permissionByModuleTypeAndValue(
          this.employee,
          ModuleTypes.Incidents,
          FilterTypes.Incident,
          incident.privacyStatus
        );
        if (perm || isAdmin) {
          hasAccess = true;
        }
      }
    } else {
      hasAccess = true;
    }

    return hasAccess;
  }
}
