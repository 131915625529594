import { Component, Input, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { CardTypes } from 'src/app/modules/shared/enums/cardTypes';
import { RAGStatuses } from 'src/app/modules/shared/enums/ragStatuses';
import { RagHelper } from 'src/app/modules/shared/utilities/rag.utilities';
import { RiskRagHelper } from 'src/app/modules/shared/utilities/risk-rag.utilities copy';
import { IndicatorTrendRAGs } from 'src/app/modules/sustainability/enums/indicatorTrendRAGs';
import { IndicatorTrendStatuses } from 'src/app/modules/sustainability/enums/indicatorTrendStatuses';
import { SustainabilityUtilities } from 'src/app/modules/sustainability/utilities/sustainability.utilities';

@Component({
  selector: 'app-card-icon',
  templateUrl: './card-icon.component.html',
  styleUrls: ['./card-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardIconComponent {
  //Views
  @ViewChild('icon') iconElementRef: ElementRef<HTMLElement>;

  //Input
  @Input() cardType: CardTypes;
  @Input() ragStatus: RAGStatuses;
  @Input() trendRAG: IndicatorTrendRAGs;
  @Input() trendStatus: IndicatorTrendStatuses;
  @Input() size: number = 24;
  @Input() color: string = '';
  //public
  cardTypes = CardTypes;

  trendStatuses = IndicatorTrendStatuses;

  //private
  defaultRagStatus: RAGStatuses = RAGStatuses.Grey;

  constructor(private readonly changeDetector: ChangeDetectorRef) {}

  get ragStatusColor(): string {
    if (this.cardType === CardTypes.Risk || this.cardType === CardTypes.Issue || this.cardType === CardTypes.Opportunity) {
      return RiskRagHelper.getRAGColourHexFromValue(this.ragStatus as number);
    }
    if (this.cardType === CardTypes.Indicator) return SustainabilityUtilities.getRAGColourHexFromValue(this.trendRAG);

    return RagHelper.getRAGColourHexFromValue(this.ragStatus);
  }


  get riskIssueOpportunityColor(): string {
    return '#ff0000';
  }
}
