import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { FilterViewModelWithComment } from '../../../models/filter/filterViewModelWithComment.Model';
import { ObjectTypes } from '../../../enums/objectTypes';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-filters-inline-edit-container',
  templateUrl: './filters-inline-edit-container.component.html',
  styleUrls: ['./filters-inline-edit-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.margin-top]': 'lozengeInlineEdit ? "8px" : ""',
    '[style.max-height]': 'lozengeInlineEdit ? "360px" : ""',
    '[style.display]': 'lozengeInlineEdit ? "flex" : ""',
  },
})
export class FiltersInlineEditContainerComponent implements OnInit, OnChanges {
  @Input() mobile: boolean;
  /** TODO to check where and why is needed? now used only for exclude? */
  @Input() lozengeInlineEdit: boolean;

  /** used in many places for refactoring 2.0 */
  @Input() filterTypeSelectorViewModel: FilterTypeSelectorViewModel;
  @Input() filters: FilterViewModel[];
  @Input() appliedFilters: FilterViewModel[];
  /** it is heavely used it is for decideing if we need single select or multy select drop down for refactoring 2.0 */
  @Input() singleSelect: boolean = false;
  @Input() showOnlyActive: boolean = false;
  @Input() showTitle: boolean = true;
  @Input() customTitle: string = '';
  @Input() isFilterRequired: boolean = false;
  @Input() isFixedFilter: boolean = false;
  @Input() useClearAllButton: boolean = true;
  // if true allows the component to be closed when used alone (for example in the details-lozenge component), if false - the component is nested in a modal
  @Input() isUsedAlone: boolean = false;
  @Input() isSearchActive = true;
  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]> ();

  localAppliedFilters: FilterViewModel[] = [];
  filterSelectorTypes = FilterSelectorTypes;
  filterTypes = FilterTypes;
  canEdit: boolean = true;
  hasBeenInitExecuded = false;
  required: boolean = false;
  canClickOnStar = true;

  constructor(
    readonly elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly bsModalRef: BsModalRef,
  ) {}

  ngOnInit() {
    if (!FilterUtilities.IncludeZeroValues(this.filterTypeSelectorViewModel.filterType)) {
      this.filters = this.filters.filter((d) => d.filterValue);
    }

    // if(this.showOnlyActive) {
    //   this.filters = this.filters.filter(f => f.isActive);
    // }

    if (
      (this.filterTypeSelectorViewModel.displayForObjectType === ObjectTypes.Risk &&
      this.filterTypeSelectorViewModel.filterType === FilterTypes.Owner)
    ) {
      this.required = true;
    }

    this.localAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];

    this.hasBeenInitExecuded = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appliedFilters && this.hasBeenInitExecuded) {
      this.localAppliedFilters = JSON.parse(JSON.stringify(this.appliedFilters)) as FilterViewModel[];

      this.changeDetectorRef.detectChanges();
    }
  }

  updateFilterColor(filterColorViewModel: FilterViewModelWithComment) {
    if (filterColorViewModel && filterColorViewModel.filters) {
      if (filterColorViewModel.filters.length > 0) {
        const filters = filterColorViewModel.filters;
        this.localAppliedFilters = this.localAppliedFilters.filter(
          (e) =>
            filters.findIndex(
              (r) => r.filterType === e.filterType && r.displayForGlobalObjectType === e.displayForGlobalObjectType
            ) < 0
        );
        this.localAppliedFilters = this.localAppliedFilters.concat(filters);
      } else {
        /** the case when we are removing last element froms pecific filter type /object type in this case we are creating artifical filter
         * with value -1 in order to tell the filters component to clear all filters with the same type/dsip[layforobject types
         */
        const fl = new FilterViewModel();
        fl.displayForGlobalObjectType = this.filterTypeSelectorViewModel.displayForObjectType;
        fl.filterType = this.localAppliedFilters[0].filterType;
        fl.filterValue = -1;

        this.localAppliedFilters = [fl];
      }
    }
    this.localAppliedFilters.forEach(
      (s) => (s.displayForGlobalObjectType = this.filterTypeSelectorViewModel.displayForObjectType)
    );
    this.BroadcastChanges(this.localAppliedFilters);
  }

  updateFilters(filters: FilterViewModel[]) {
    if (filters.length === 0) {
      /** the case when we are removing last element froms pecific filter type /object type in this case we are creating artifical filter
       * with value -1 in order to tell the filters component to clear all filters with the same type/dsip[layforobject types
       */
      const fl = new FilterViewModel();

      if (
        (this.filterTypeSelectorViewModel.displayForObjectType === ObjectTypes.Risk &&
        this.filterTypeSelectorViewModel.filterType === FilterTypes.Owner)
      ) {
        return;
      } else if (this.filterTypeSelectorViewModel.filterType !== FilterTypes.Tag) {
        fl.displayForGlobalObjectType = this.filterTypeSelectorViewModel.displayForObjectType;
      } else {
        fl.displayForGlobalObjectType = this.localAppliedFilters[0].displayForGlobalObjectType;
        fl.relatedObjectId = this.localAppliedFilters[0].relatedObjectId;
      }
      // fl.displayForGlobalObjectType = this.filterTypeSelectorViewModel.displayForObjectType;

      fl.filterType = this.filterTypeSelectorViewModel.filterType;
      fl.filterValue = -1;

      this.localAppliedFilters = [fl];
    } else {
      this.localAppliedFilters = filters;
      if (this.filterTypeSelectorViewModel.filterType !== FilterTypes.Tag) {
        this.localAppliedFilters.forEach(
          (s) => (s.displayForGlobalObjectType = this.filterTypeSelectorViewModel.displayForObjectType)
        );
      }
    }

    this.BroadcastChanges(this.localAppliedFilters);
  }

  getProperFilterType(): FilterTypes {
    return this.filterTypeSelectorViewModel.filterType;
  }

  onDateFilterAdded(filterViewModels: FilterViewModel[]) {
    this.localAppliedFilters = filterViewModels;
    this.localAppliedFilters.forEach(
      (s) => (s.displayForGlobalObjectType = this.filterTypeSelectorViewModel.displayForObjectType)
    );

    this.BroadcastChanges(this.localAppliedFilters);
  }

  private BroadcastChanges(filters: FilterViewModel[]) {
    this.localAppliedFilters = FilterUtilities.RemoveFiltersFromArray(this.localAppliedFilters, filters);
    this.localAppliedFilters = this.localAppliedFilters.concat(filters);

    const newLocalAppliedFilters = this.localAppliedFilters.filter((x) => x.filterValue !== -1);
    this.filtersUpdated.next(newLocalAppliedFilters);
  }

  get isFixedEvent(): boolean {
    return this.isFixedFilter && this.filterTypeSelectorViewModel?.filterType === this.filterTypes.Event;
  }

  get isFixedZone(): boolean {
    return this.isFixedFilter && this.filterTypeSelectorViewModel?.filterType === this.filterTypes.Zone;
  }

  get isFixedChannel(): boolean {
    return this.isFixedFilter && this.filterTypeSelectorViewModel?.filterType === this.filterTypes.Incident_Channel;
  }

  onCancel(): void {
    this.bsModalRef.hide();
  }
}
