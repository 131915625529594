<div class="create-modal-container">
  <div class="create-modal-header">
    <h6 class="create-modal-title">Create {{ itemName }}</h6>
  </div>
  <div class="create-modal-content">
    <div class="create-modal-message">
      Are you sure you want to create a dependency for
      <div class="modal-highlight-content">'{{ dependOn }}'?</div>
    </div>
    <div class="modal-depend-item">
      <span class="modal-depend-item-title">Will Depend On</span>
      <span class="modal-depend-item-name">{{ dependOn }}</span>
    </div>
  </div>
  <div class="create-modal-footer">
    <div class="create-modal-buttons">
      <app-button-rectangle
        class="cancel-button"
        text="Cancel"
        (clicked)="onCancel()"
        buttonTheme="white"
        size="large"
      ></app-button-rectangle>
      <app-button-rectangle
        class="create-button"
        text="Continue"
        (clicked)="onAdd()"
        buttonTheme="primary"
        size="large"
      ></app-button-rectangle>
    </div>
  </div>
</div>
