export enum IncidentEditableItemTypes {
  Category = 1,
  Tag = 2,
  Zone = 3,
  DocumentCategory = 4,
  Department = 5,
  DepartmentCategory = 6,
  VenueCategory = 7,
  VenueTag = 8,
  VenueType = 9,
  CapabilityType = 10,
  ActivityType = 11,
  Area = 12,
}
