<div class="scale-units-wrapper" [class.map-overlay]="drawAsMapOverlay === true">
  <div
    *ngFor="let scaleUnit of scaleUnits"
    class="scale-unit-button"
    [style.width]="scaleUnitWidth"
    [class.selected]="scaleUnit.key === currentlySelected"
    [class.map-overlay]="drawAsMapOverlay === true"
    (click)="onScaleUnitSelected(scaleUnit)"
  >
    <span class="scale-unit-text">{{ scaleUnit.value }}</span>
  </div>
</div>
