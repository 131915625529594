import { SustainabilityScopes } from 'src/app/modules/sustainability/enums/sustainability_scopesTypes';
import { ModifiableEntityViewModel } from '../../../../incidents/viewModels/modifiableEntityViewModel';
import { IndicatorPriorities } from '../../../../sustainability/enums/indicatorPriorities';
import { IndicatorTrackingDateTypes } from '../../../../sustainability/enums/indicatorTrackingDateTypes';
import { IndicatorUpdateExpectedDirections } from '../../../../sustainability/enums/indicatorUpdateExpectedDirections';
import { IndicatorUpdateTypes } from '../../../../sustainability/enums/indicatorUpdateTypes';

export class IndicatorTemplateViewModel extends ModifiableEntityViewModel {
  indicatorCategoryId: number;
  updateType: IndicatorUpdateTypes;
  description: string;
  trackingDateValue: number;
  trackingDateType: IndicatorTrackingDateTypes;
  priority: IndicatorPriorities;
  eventId: number;
  expectedDirection?: IndicatorUpdateExpectedDirections;
  hasChildIndicators: boolean;
  active: boolean;
  unitTypeId:number;
  conversionUnitId:number;
  defaultUnitId:number;
  conversionUnitTitle: string;
  conversionUnitAbbreviation: string;
  sustainabilityScope: SustainabilityScopes;
}
