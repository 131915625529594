<app-modal>
  <app-loading *ngIf="loading"></app-loading>
  <ng-container *ngIf="!loading">
    <app-modal-header
      [title]="T.common.add_item | translate: { item: localisedLog }"
      [showDelete]="false"
      (closed)="closeModal()"
    >
    </app-modal-header>
    <app-modal-body>
      <div class="row col-row-space custom-gutters" [formGroup]="logForm">
        <div class="col-12">
          <app-text-field
            [formGroup]="logForm"
            formControlName="title"
            [placeholder]="T.defaultLocalizations.title.one | translate"
            [useReactiveMessages]="true"
            [required]="true"
          ></app-text-field>
        </div>
        <div class="col-12">
          <app-details-editable-field
            [input]="incidentLog.description ? incidentLog.description : ''"
            [header]="T.defaultLocalizations.description.one | translate"
            [maxLength]="2000"
            [minLength]="0"
            [type]="editableFieldTypes.RichText"
            [canEdit]="true"
            (onUpdate)="updateDescription($event)"
            [placeholder]="T.common.add_description | translate"
          >
          </app-details-editable-field>
        </div>
        <div class="col-12">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Incident_Category"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.control.add_incident_category.many | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="col-12">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Department"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.add_item | translate: { item: localisedDepartments }"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
          <span class="subtext mt-1 mt-sm-0">{{
            T.common.which_items_are_involved | translate: { items: localisedDepartments }
          }}</span>
        </div>
        <div class="col-12">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Owner"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.add_owner.many | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
          <span class="subtext mt-1 mt-sm-0">{{
            T.common.which_individuals_are_involved_and_should_be_notified | translate
          }}</span>
        </div>
        <div class="col-12">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.User_Group"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.add_employee_group.many | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
          <span class="subtext mt-1 mt-sm-0">{{
            T.common.which_groups_of_localisedEmployees_should_be_notified
              | translate: { localisedEmployees: objectTypes.Employee | localiseObjectType: true }
          }}</span>
        </div>
        <div class="col-12 privacy-status">
          <app-simple-dropdown
            #searchDropdown
            [objects]="filteredStatuses"
            [bindProperty]="'value'"
            [label]="T.defaultLocalizations.privacy_status.one | translate"
            [currentlySelectedObject]="privacyStatus"
            (objectSelected)="onPrivacyStatusSelected($event)"
          >
          </app-simple-dropdown>
        </div>
        <div *ngIf="showDetails" class="col-12">
          <app-add-modal-tag-groups
            [showTitle]="false"
            [filters]="filters"
            [layoutClasses]="'col-12'"
            [tagGroupObjectType]="objectType"
            (onFiltersUpdated)="handleFilterChange($event)"
          ></app-add-modal-tag-groups>
        </div>
        <div *ngIf="showDetails" class="col-12">
          <app-optional-date-time
            [firstDateLabel]="T.calendar.start | translate"
            [secondDateLabel]="T.calendar.end | translate"
            [firstDateString]="incidentLog.startTime"
            [secondDateString]="incidentLog.endTime"
            [showPickers]="showPickers"
            [editMode]="true"
            (onFirstDateChanged)="onStartDateChanged($event)"
            (onSecondDateChanged)="onEndDateChanged($event)"
            (onShowPickersChanged)="onShowPickersChanged($event)"
          ></app-optional-date-time>
        </div>
        <div class="row" *ngIf="showDetails">
          <div class="col-12 mb-2">
            <app-filter-dropdown-new
              [label]="T.common.add_item | translate: { item: localisedZones }"
              [filterType]="filterTypes.Zone"
              [filterByModuleObjectType]="false"
              [appliedFilters]="filters"
              [typeahead]="true"
              (onFilterUpdate)="handleFilterChange($event)"
            >
            </app-filter-dropdown-new>
            <span class="subtext mt-1 mt-sm-0">{{
              T.common.which_items_are_affected | translate: { items: localisedZones }
            }}</span>
          </div>
          <div class="col-12 mb-2">
            <app-filter-dropdown-new
              [label]="T.common.add_item | translate: { item: localisedAreas }"
              [filterType]="filterTypes.Area"
              [filterByModuleObjectType]="false"
              [appliedFilters]="filters"
              [typeahead]="true"
              [usePredefinedFilters]="true"
              [predefinedFilters]="applicableAreaFilters"
              [relatedObjectId]="selectedZone?.filterValue"
              [disabled]="applicableAreaFilters.length === 0"
              (onFilterUpdate)="handleFilterChange($event)"
            >
            </app-filter-dropdown-new>
            <span class="subtext mt-1 mt-sm-0">{{
              T.common.which_items_are_affected | translate: { items: localisedAreas }
            }}</span>
          </div>
          <div class="col-12 mb-2">
            <app-incident-map
              [defaultZoom]="15"
              [lat]="incidentLog.latitude"
              [lng]="incidentLog.longitude"
              [selectedZones]="mapZones"
              [selectedAreas]="mapAreas"
              [showIncidentZonesUponLoad]="true"
              [markerColor]="severityColor"
              [markerType]="markerType"
              [editMode]="true"
              (onLocationUpdated)="onIncidentLocationUpdated($event)"
            >
            </app-incident-map>
          </div>
        </div>
        <app-button-bar-rounded
          class="show-more-btn details-column"
          [text]="T.common.show_more | translate"
          [secondText]="T.common.show_less | translate"
          fitToContainer="true"
          [isOpen]="showDetails"
          (clicked)="toggleDetailsState()"
        ></app-button-bar-rounded>
      </div>
    </app-modal-body>
    <app-modal-footer>
      <app-button-rectangle
        [buttonTheme]="'white'"
        [size]="'large'"
        [text]="T.common.cancel | translate"
        (clicked)="closeModal()"
      ></app-button-rectangle>
      <app-button-rectangle
        [buttonTheme]="'primary'"
        [size]="'large'"
        [text]="T.common.add | translate"
        [disabled]="!valid && !logForm.valid"
        (clicked)="onAddIncidentLog()"
      ></app-button-rectangle>
    </app-modal-footer>
  </ng-container>
</app-modal>
