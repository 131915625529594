export class WeatherDataCache {
  key: string;
  timestamp: number;
  data: any;

  constructor(key: string, timestamp: number, data: any) {
    this.key = key;
    this.timestamp = timestamp;
    this.data = data;
  }
}
