<button type="button" class="button-transparent" (click)="onClick($event)" [disabled]="disabled" [ngClass]="size">
  <div class="button-content-container" [ngClass]="buttonClasses" [class.reverse-icon-and-text]="reverseIconPlacement && !dropdown && icon">
    @if (dropdown) {
      <i
        class="material-symbols-outlined"
        [style.font-size.px]="iconFontSize"
        [ngClass]="{ rotate: isOpen }">{{ icon }}
      </i>

      <div
        class="button-text text-decoration-none px-1"
        [style.font-size.px]="iconFontSize">{{ text }}
      </div>

      <i
        class="material-symbols-outlined"
        [style.font-size.px]="iconFontSize"
        [ngClass]="{ rotate: isOpen }">arrow_drop_down
      </i>
    }

    @if (!dropdown && icon !== undefined) {
      <i
        class="material-symbols-outlined normal-icon"
        [style.font-size.px]="iconFontSize"
        [style.line-height.px]="iconFontSize"
        >{{ icon }}</i
      >
      <div class="button-text">{{ text }}</div>
    }

    @if (!dropdown && icon === undefined) {
      <div class="button-text">{{ text }}</div>
    }

  </div>
</button>
