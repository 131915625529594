import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FilterDateRangeOptions } from '../../../enums/filter/filterDateRangeOptions';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { TimeZoneService } from '../../../services/timeZone.service';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { ObjectTypes } from '../../../enums/objectTypes';
import { LocalisationService } from '../../../services/localisation.service';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
@Component({
  selector: 'app-filter-lozenge',
  templateUrl: './filters-lozenge.component.html',
  styleUrls: ['./filters-lozenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterLozengeComponent implements OnInit, OnChanges {
  @ViewChild('filterLozenge') filterLozengeElementRef: ElementRef<HTMLElement>;

  /**
   * applied filters by type which should be displayed by the lozenge
   */
  @Input() appliedFiltersByType: FilterViewModel[] = [];
  @Input() editMode: boolean;
  @Input() exclude: boolean;
  @Input() isFixedFilter = false;

  @Output() removed = new EventEmitter<FilterViewModel> ();

  private exludeFromLocalisation = [FilterTypes.Tag];

  public filterType: FilterTypes;
  public filterTypes = FilterTypes;
  public selectorType: FilterSelectorTypes;
  public lozengeLabel: string;
  public lozengeText: string;
  public filterValue: string;
  public readonly T = T;

  filterSelectorTypes = FilterSelectorTypes;
  filterDateProperties: { key: FilterDateOptions; value: string }[] = EnumUtilities.items(FilterDateOptions);
  filterDateRangeOptions: { key: FilterDateRangeOptions; value: string }[] = EnumUtilities.items(FilterDateRangeOptions);

  isChannelApplied: boolean = false;
  isEventApplied: boolean = false;
  isZoneApplied: boolean = false;

  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
    private readonly timeZoneService: TimeZoneService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.appliedFiltersByType.length > 0) {
      this.appliedFiltersByType = this.appliedFiltersByType.filter((d) => d != undefined);
      const firstFilterType = this.appliedFiltersByType[0];

      this.filterType = this.appliedFiltersByType[0].filterType;
      this.selectorType = this.appliedFiltersByType[0].filterSelectorType;
      const dateProperty = firstFilterType.filterValue;

      this.filterValue = this.appliedFiltersByType[0].filterValue;

      this.getLozengeLabel(firstFilterType, dateProperty);
      this.getLozengeText(firstFilterType);
      this.getCustomLozengeStatuses();
    }
  }

  private getCustomLozengeStatuses() {
    const channelFilter = this.appliedFiltersByType.find((f) => f.filterType === FilterTypes.Incident_Channel);
    this.isChannelApplied = channelFilter && channelFilter.filterValue.toString() !== '0';

    const eventFilter = this.appliedFiltersByType.find((f) => f.filterType === FilterTypes.Event);
    this.isEventApplied = eventFilter && eventFilter.filterValue.toString() !== '0';

    const zoneFilter = this.appliedFiltersByType.find((f) => f.filterType === FilterTypes.Zone);
    this.isZoneApplied = zoneFilter && zoneFilter.filterValue.toString() !== '0';
  }

  private getLozengeText(firstFilterType: FilterViewModel) {
    if (firstFilterType.filterType == FilterTypes.Incident_Channel) {
      if (firstFilterType.filterText && firstFilterType.filterText === "All Channels" && this.isFixedFilter) {
        this.lozengeText = this.translateService.instant(T.common.all_channels);
      } else {
        this.lozengeText = this.appliedFiltersByType.map((f) => f.filterText).join(', ');
      }
    } else if (firstFilterType.filterText) {
      this.lozengeText = this.appliedFiltersByType.map((f) => f.filterText).join(', ');
    } else if (firstFilterType.filterValue.dateRangeOption) {
      const filterValueDateVM = firstFilterType.filterValue;
      if (filterValueDateVM.dateRange === FilterDateRangeOptions.Fixed_Date_Range) {
        this.lozengeText = `${this.timeZoneService.localiseDateISOString(
          filterValueDateVM.fromDate,
          false,
          false
        )} to ${this.timeZoneService.localiseDateISOString(filterValueDateVM.toDate, false, false)}`;
      } else {
        this.lozengeText = this.filterDateRangeOptions.find(
          (filterDateRangeOption) => filterDateRangeOption.key === filterValueDateVM.dateRange
        ).value;
      }
    } else if (firstFilterType.dateRangeOption) {
      const filterValueDateVM = firstFilterType.filterValue;
      if (firstFilterType.dateRangeOption === FilterDateRangeOptions.Fixed_Date_Range) {
        this.lozengeText = `${this.timeZoneService.localiseDateISOString(
          filterValueDateVM.fromDate,
          false,
          false
        )} to ${this.timeZoneService.localiseDateISOString(filterValueDateVM.toDate, false, false)}`;
      } else {
        this.lozengeText = this.filterDateRangeOptions.find(
          (filterDateRangeOption) => filterDateRangeOption.key === firstFilterType.dateRangeOption
        ).value;
      }
    }

    this.changeDetectorRef.markForCheck();
  }

  private getLozengeLabel(firstFilterType: FilterViewModel, dateProperty: any) {
    let objectTypeText = ObjectTypes[firstFilterType.displayForGlobalObjectType].replace('_', ' ');

    if (firstFilterType.displayForGlobalObjectType !== ObjectTypes.Runsheet_Item) {
      objectTypeText = objectTypeText.replace('Item', '');
    } else if (firstFilterType.displayForGlobalObjectType === ObjectTypes.Runsheet_Item) {
      objectTypeText = objectTypeText.replace('Runsheet', '');
    }

    const objectType = this.localisationService.localise(objectTypeText);

    let dropdownLabel = this.appliedFiltersByType[0].filterDropdownTitle;

    if (this.exludeFromLocalisation.indexOf(this.filterType) < 0) {
      dropdownLabel = this.localisationService.localise(this.appliedFiltersByType[0].filterDropdownTitle);
    }

    if (firstFilterType.filterType == FilterTypes.Date) {
      dropdownLabel = this.filterDateProperties.find(
        (filterDateProperty) => filterDateProperty.key === dateProperty.dateProperty
      )?.value;
    }

    // Remove duplication (i.e "Incident Incident Severity")
    let objectTypeLabel =
      dropdownLabel !== null && dropdownLabel.toLowerCase().indexOf(objectType.toLowerCase()) > -1 ? '' : objectType;
      if(objectTypeLabel === ObjectTypes[ObjectTypes.Global]) {
        objectTypeLabel = this.translateService.instant(T.common.global_object_type);
    }

    this.lozengeLabel = `${objectTypeLabel} ${dropdownLabel}`;

    // Only for the Risk Impact Type label (used in AssignRisksToAssessmentModal)
    if(firstFilterType.filterType === FilterTypes.Risk_Impact_Type ||
      firstFilterType.filterType === FilterTypes.Event_Type) this.lozengeLabel = dropdownLabel;
  }

  ngOnChanges() {
    if (this.appliedFiltersByType.length > 0) {
      this.appliedFiltersByType = this.appliedFiltersByType.filter((d) => d != undefined);
      const firstFilterType = this.appliedFiltersByType[0];

      this.filterType = this.appliedFiltersByType[0].filterType;
      this.selectorType = this.appliedFiltersByType[0].filterSelectorType;
      const dateProperty = firstFilterType.filterValue;

      this.getLozengeLabel(firstFilterType, dateProperty);
      this.getLozengeText(firstFilterType);
      this.getCustomLozengeStatuses();
      this.filterValue = this.appliedFiltersByType[0].filterValue;
    }
  }

  onRemoved(): void {
    this.removed.emit(this.appliedFiltersByType[0]);
  }

  get overflowingCount(): number {
    return this.appliedFiltersByType.length - 1;
  }

  get hasIcon(): boolean {
    return this.exclude || this.isFixedFilter;
  }

  get isDate(): boolean {
    return this.selectorType === FilterSelectorTypes.Date;
  }
}
