export enum ModuleIconTypes {
  Colourway_1,
  Colourway_2,
  Grey_Colour,
  Blue_Colour,
  Custom_Colour,
  White,
  Yellow,
  Black
}
