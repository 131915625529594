import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { EmployeeRoleTypes } from '../../enums/employees/EmployeeRoleTypes';
import { FilterTypes } from '../../enums/filterTypes';
import { PermissionOperationTypes } from '../../enums/permissionOperationTypes.enum';
import { PrivacyStatuses } from '../../enums/privacyStatuses';
import { Employee } from '../../models/employee';
import { FilterViewModel } from '../../models/filter/filterViewModel';
import { EmployeeUtil } from '../../utilities/employee.utilities';
import { IPermissionManager } from '../IPermissionManager';
import { BasePermissionManager } from './basePermissionManager';

export class ControlPermissionManager extends BasePermissionManager implements IPermissionManager {
  public IsApplicable(filterType: FilterTypes): boolean {
    return filterType == FilterTypes.Incident;
  }

  public applyPermissions(
    obj: { id: number; filters: FilterViewModel[]; permissionFilters: FilterViewModel[] },
    moduleType: ModuleTypes,
    filterType: FilterTypes, //current object i.e Task list => Task | Write => Project
    employee: Employee,
    operationType?: PermissionOperationTypes
  ): boolean {
    const isConfid = obj.filters.find((s) => s.filterType == FilterTypes.Privacy_Status);

    if (
      isConfid &&
      isConfid.filterValue == PrivacyStatuses.Open &&
      EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Control_Admin)
    ) {
      return true;
    }

    const primaryFilterType = Object.keys(this.primaryFilters).find((r) => this.primaryFilters[r].some((r) => r == filterType));

    const haveObjectWritePermissions = EmployeeUtil.hasWritePermission(employee, 0 as ModuleTypes, filterType, [obj.id], obj);

    const isOwner = this.haveOwnerRights(obj, employee);

    const hasDepartmentPermissions = this.haveDepartmentPermissions(
      employee,
      +primaryFilterType,
      obj.filters,
      obj.permissionFilters
    );

    const availableIncidents = obj.filters.filter((r) => r.filterType == FilterTypes.Incident);
    const availableIncidentsIDs = availableIncidents.map((r) => +r.filterValue);
    const availableIncidentsLevel = obj.filters
      .filter((r) => r.filterType == FilterTypes.Privacy_Status)
      .map((r) => r.filterValue)[0];
    const haveIncidentsWritePermissions = EmployeeUtil.hasWritePermission(
      employee,
      ModuleTypes.Incidents,
      FilterTypes.Incident,
      availableIncidentsIDs,
      obj,
      availableIncidentsLevel
    );

    if (haveObjectWritePermissions || isOwner) {
      return true;
    }

    if (
      filterType == FilterTypes.Incident &&
      (EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Control_Admin) ||
        (haveIncidentsWritePermissions && hasDepartmentPermissions))
    ) {
      return true;
    }

    return false;
  }
}
