import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-filters-mobile-footer',
  templateUrl: './filters-mobile-footer.component.html',
  styleUrls: ['./filters-mobile-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersMobileFooterComponent {
  @Input() applyDisabled: boolean;

  @Output() apply: EventEmitter<void> = new EventEmitter();

  public readonly T = T;

  onApply(): void {
    this.apply.emit();
  }
}
