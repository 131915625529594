import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
  ChangeDetectionStrategy,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { AddModalButtonOptions } from 'src/app/modules/shared/enums/addModalButtonOptions.enum';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { Subscription, Observable } from 'rxjs';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { TaskGroupService } from '../../../services/task-group-service';
import { AddModalKeyboardShortcuts } from 'src/app/modules/shared/enums/addModalKeyboardShortcuts.enum';
import { TaskGroupListItemViewModel } from '../../../viewModels/taskGroupListItemViewModel';
import { ObjectEventEmitters } from 'src/app/modules/shared/events/object.events';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { OperationTypes } from 'src/app/modules/shared/enums/operationTypes';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { TaskAddModalComponent } from '../task-add-modal/task-add-modal.component';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { AllowedFiltersService } from '../../../../shared/services/allowed-filters.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-task-group-add-modal',
  templateUrl: './task-group-add-modal.component.html',
  styleUrls: ['./task-group-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskGroupAddModalComponent implements OnInit, OnDestroy {
  /**
   * When the user clicks the close button this event is fired.
   *
   * NOTE!!!
   * This event MUST NOT be confused with closing the modal
   * so in order for all the callbacks subscribed to
   * this event to work correctly it shouldn't be called INSIDE any function
   * which closes the modal.
   */
  @Output() cancel = new EventEmitter<void>();

  public selectedProject: FilterViewModel;
  public addModalButtonOptions = AddModalButtonOptions;
  public saveAndCloseHandleActionOnly = false;
  public filters: FilterViewModel[] = [];
  public isLoading = false;
  public form: UntypedFormGroup;
  public triggerErrors = false;
  public taskGroup = new TaskGroupListItemViewModel();
  public editMode = false;
  public headerText: string = 'Add New';
  public presselectedTaskGroup: TaskGroupListItemViewModel;
  public filterTypes = FilterTypes;
  public buttonOptions = [
    this.translateService.instant(T.common.save_amp_close),
    this.translateService.instant(T.common.save_amp_add_another),
    this.translateService.instant(T.common.save_amp_add_item, {
      item: this.localisationService.localiseObjectType(ObjectTypes.Task),
    }),
  ];
  public selectedButtonOption = 0;
  public keyboardShortcuts = AddModalKeyboardShortcuts.getKeyShortsAsObj(this.translateService);
  taskSvg = IconUtilities.getSvgForFilterType(FilterTypes.Task_Group);
  public taskAddBsModalRef: BsModalRef;
  public localisedTaskGroup = 'Task Group';
  public localisedProject = 'Project';
  public readonly T = T;
  public disableProjectDropdown: boolean = false;
  private employee: Employee;
  private subscriptions = new Subscription();
  private isTouched = false;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        if (event.shiftKey) {
          this.handleAction(
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          );
          return;
        }
        this.handleAction(
          AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
        );
        return;
      }
      this.handleAction(AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService));
      return;
    }
  }

  constructor(
    private readonly confirmationService: ConfirmationService,
    private readonly bsModalRef: BsModalRef,
    private readonly fb: UntypedFormBuilder,
    private readonly authenticationService: AuthenticationService,
    private readonly taskGroupService: TaskGroupService,
    private readonly elemRef: ElementRef,
    private readonly objectEventemitter: ObjectEventEmitters,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly bsModalService: BsModalService,
    private localisationService: LocalisationService,
    private readonly alertService: AlertService,
    private readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.localisedTaskGroup = this.localisationService.localiseObjectType(ObjectTypes.Task_Group);
    this.localisedProject = this.localisationService.localiseObjectType(ObjectTypes.Project);

    this.form = this.fb.group({
      taskGroupTitle: ['', { validators: [Validators.required, this.wtValidators.title(2, 60), this.wtValidators.restrictedChars([';'])] }],
    });
    if (this.presselectedTaskGroup) {
      this.taskGroup = this.presselectedTaskGroup;
      this.form.get('taskGroupTitle').setValue(this.presselectedTaskGroup.title);
    }

    if (this.presselectedTaskGroup) {
      this.filters = this.presselectedTaskGroup.filters;
    } else {
      // this.allowedFiltersService.getAllowedFilters(this.employee.id).subscribe(d=>{
      //   this.filters = d;
      // })
      this.filters = [];
    }


    if (this.selectedProject) {
      this.disableProjectDropdown = true;
      this.filters.push(this.selectedProject)
    }

    if (this.editMode) {
      this.headerText = this.translateService.instant(T.common.move);
    }

    if (this.saveAndCloseHandleActionOnly) {
      this.buttonOptions = [this.translateService.instant(T.common.save_amp_close)];
      this.selectedButtonOption = 0;
    }

    this.changeDetectorRef.markForCheck();
    this.subscriptions.add(
      this.form.valueChanges.subscribe((res) => {
        this.isTouched = true;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleAction(action: string) {
    const isFiltersValid = this.validateRequiredFilters([FilterTypes.Project]);
    if (!this.form.valid || !isFiltersValid) {
      this.triggerErrors = true;
      return;
    }
    this.isLoading = true;
    this.subscriptions.add(
      this.handleSubmit().subscribe((res: any) => {
        if (this.editMode) {
          res = res as TaskGroupListItemViewModel;
        } else {
          res = res as ValidatedViewModel;
        }

        if (res?.errorList?.length > 0) {
          // Handle errors
          void this.alertService.error(this.translateService.instant(T.common.something_happened));
          this.isLoading = false;
        } else {
          void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedTaskGroup }));
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();

          const addedTaskGroup = this.editMode
            ? (res as TaskGroupListItemViewModel)
            : (res.returnModel as TaskGroupListItemViewModel);

          this.objectEventemitter.broadcastObjectAdded(addedTaskGroup.id, ObjectTypes.Task_Group, addedTaskGroup);
          this.allowedFiltersService.refreshFilters(
            OperationTypes.Create,
            [{ id: addedTaskGroup.id, title: addedTaskGroup.title, relatedObjectId: +this.selectedProject.filterValue }],
            FilterTypes.Task_Group
          );

          this.bsModalEventsEmitter.broadcastBsModalActionHandleClicked(addedTaskGroup);
          if (action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService)) {
            this.closeModal();
          } else if (
            action ===
            this.translateService.instant(T.common.save_amp_add_item, {
              item: this.localisationService.localiseObjectType(ObjectTypes.Task),
            })
          ) {
            this.addTask(addedTaskGroup);
          } else if (
            action ===
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          ) {
            this.resetModal();
          }
        }
      })
    );
  }

  handleProjectChange(filters: FilterViewModel[]) {
    this.selectedProject = filters.find((f) => f.filterType === FilterTypes.Project);
    this.filters = filters;
    this.isTouched = true;
    // When Project selected if its not on editMode(updateMode), clear Task Groups
    if (!this.editMode) {
      this.form.reset();
    }
  }

  handleSubmit(): Observable<any> {
    this.taskGroup.filters = this.filters;
    this.taskGroup.title = this.form.controls.taskGroupTitle.value.trim();
    this.taskGroup.projectId = this.getFilterValue(FilterTypes.Project);

    // Populate primitive properties from Filter list
    if (this.editMode) {
      return this.taskGroupService.update(this.taskGroup);
    } else {
      return this.taskGroupService.add(this.taskGroup);
    }
  }

  getFilterValue(filterType: FilterTypes): number {
    const filter: FilterViewModel = this.taskGroup.filters.find((f) => f.filterType === filterType);
    return filter ? filter.filterValue : 0;
  }

  validateRequiredFilters(filtersForSearch: FilterTypes[]): boolean {
    let isValid = true;
    filtersForSearch.forEach((currentFilterType) => {
      if (!this.filters.find((f) => f.filterType === currentFilterType)) {
        isValid = false;
      }
    });

    return isValid;
  }

  get isValid(): boolean {
    const title = this.form.controls.taskGroupTitle.value ? this.form.controls.taskGroupTitle.value.trim() : '';
    return this.validateRequiredFilters([FilterTypes.Project]) && title.length > 3 && title !== 'General';
  }

  resetModal() {
    this.form.reset();
    this.filters = [];
  }

  confirmClose() {
    if (this.isTouched) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.bsModalEventsEmitter.broadcastBsModalCloseClicked({});
          this.closeModal();
          this.cancel.next();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    } else {
      this.closeModal();
      this.cancel.next();
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  addTask(returnedTaskGroup: TaskGroupListItemViewModel) {
    const modalConfig = { class: 'modal-large-add', ignoreBackdropClick: true };

    const initialState = {
      selectedProject: undefined,
      selectedTaskGroup: undefined,
    };

    if (this.filters) {
      const projectFitler = returnedTaskGroup.filters.find((f) => f.filterType === FilterTypes.Project);
      const taskGroupFilter = new FilterViewModel();
      taskGroupFilter.filterType = FilterTypes.Task_Group;
      taskGroupFilter.filterValue = returnedTaskGroup.id;
      taskGroupFilter.filterText = returnedTaskGroup.title;
      taskGroupFilter.relatedObjectId = projectFitler.filterValue;

      if (projectFitler) {
        initialState.selectedProject = projectFitler;
      }

      if (taskGroupFilter) {
        initialState.selectedTaskGroup = taskGroupFilter;
      }
    }
    const modalParams = Object.assign({}, modalConfig, { initialState });

    this.closeModal();
    this.taskAddBsModalRef = this.bsModalService.show(TaskAddModalComponent, modalParams);
  }
}
