import { DocumentViewModel } from './documentViewModel';
import { PrivacyStatuses } from '../../enums/privacyStatuses';

export class DocumentCategoryViewModel {
  public id: number;
  public accountId: number;
  public clientId: number;
  public title: string;
  public description: string;
  public parentDocumentCategoryId: number;
  public parentDocumentCategoryTitle: string;
  public viewOrder: number;
  public privacyStatus: PrivacyStatuses;
  public created: Date;
  public isSelected: boolean;
  public hubObjectId: number;
  public documentViewModels: DocumentViewModel[];
}
