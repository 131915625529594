import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { ValidationErrorComponent } from '../components/common/validation-error/validation-error.component';
import { DynamicComponentsService } from '../services/dynamicComponents.service';

@Directive({
  selector: '[inputValidation]',
})
export class InputValidationDirective implements OnChanges {
  @Input() condition: boolean;
  @Input() error: string;

  validationErrorElement: HTMLElement;

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly dynamicComponentsManager: DynamicComponentsService
  ) {}

  ngOnChanges() {
    this.toggleError(this.condition, this.error);
  }

  private toggleError(condition: boolean, error: string) {
    if (!condition) {
      this.destroyValidationError();
    } else {
      this.destroyValidationError();

      this.validationErrorElement = this.dynamicComponentsManager.createComponentElement(ValidationErrorComponent, {
        error,
      }).location.nativeElement;

      this.elementRef.nativeElement.parentElement.appendChild(this.validationErrorElement);
    }
  }

  private destroyValidationError() {
    if (this.validationErrorElement) {
      this.validationErrorElement.remove();
    }
  }
}
