import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-edit-time-modal',
  templateUrl: 'edit-time-modal.component.html',
  styleUrls: ['edit-time-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateTimeModalComponent implements OnInit, AfterViewInit {
  time: string;
  hours = "00";
  minutes = "00";
  public onUpdate = new EventEmitter<string>();
  public onCloseModal = new EventEmitter();
  isChanged = false;
  headingText = '';
  public readonly T = T;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly elemRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if(!this.headingText) {
      this.headingText = this.translateService.instant(T.common.update_time_modal_header);
    }

    if (this.time) {
      const [hours, minutes] = this.time.split(":").map(part => part.padStart(2, '0'));
      this.hours = hours;
      this.minutes = minutes;
    }
  }

  ngAfterViewInit(): void {
    this.setModalContainerDisplayFlex();
  }

  onClose() {
    this.bsModalRef.hide();
    this.onCloseModal.emit();
  }

  setModalContainerDisplayFlex() {
    const modalDialog = this.elemRef.nativeElement.closest('[role=document]') as HTMLElement;
    modalDialog.classList.add('overflow-visible');
  }

  onStartTimeChange(time: {hours: string, minutes: string}) {
    this.time = `${time.hours}:${time.minutes}`;
    this.isChanged = true;
    this.cdr.markForCheck();
  }

  onSave() {
    this.onUpdate.next(this.time);
    this.bsModalRef.hide();
  }
}
