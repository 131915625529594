import { Injectable } from "@angular/core";
import { WeatherDataCache } from "../../models/weatherDataCache";
import { WtStorageService } from "../wt-storage.service";

@Injectable()
export class WeatherDataCachingService {

    /**
     * This key is used to store the cached weather data in the local storage
     * The data is stored as an array of WeatherDataCache objects and is the same as the memory cache
     * The reason for storing the data in the local storage is to have the data available after the page is reloaded
     * during development and testing
     */
    public static readonly localStorageKey = 'WEATHER_DATA';

    // In-memory cache
    private readonly map: Map<string, WeatherDataCache> = new Map<string, WeatherDataCache>();

    constructor(
      private readonly wtStorage: WtStorageService
    ) {}

    /**
     * Get cached weather data for the given location from memory or local storage.
     *
     * @param location
     * @returns the cached weather data or undefined if not found
     */
    public getCachedWeatherData(location: {lat: number, lng: number}): WeatherDataCache | undefined {
      if(!location) {
        return undefined;
      }

      const cacheKey = this.buildKey(location.lat, location.lng);
      const memoryCache = this.getFromMemoryCache(cacheKey);
      if(memoryCache) {
        return memoryCache;
      }

      return this.getFromLocalStorageCache(cacheKey);
    }

    /**
     * Save weather data to memory and local storage cache.
     *
     * @param location
     * @param weatherData
     */
    public saveCachedWeatherData(location: {lat: number, lng: number}, weatherData: any): void {
      if(!location) {
        return;
      }

      const cacheKey = this.buildKey(location.lat, location.lng);
      const cachedData = new WeatherDataCache(cacheKey, new Date().getTime(), weatherData);

      this.saveToMemoryCache(cacheKey, cachedData);
      this.saveToLocalStorageCache(cacheKey, cachedData);
    }

    /**
     * Retrieve cached data from in-memory cache.
     *
     * @param cacheKey
     * @returns
     */
    private getFromMemoryCache(cacheKey: string): WeatherDataCache | undefined {
      return this.map.get(cacheKey);
    }

    /**
     * Retrieve cached data from local storage.
     * This method should be called only if the data is not available in memory cache.
     *
     * @param cacheKey
     * @returns
     */
    private getFromLocalStorageCache(cacheKey: string): WeatherDataCache | undefined {
      const localStorageCachedData = this.wtStorage.getItem(WeatherDataCachingService.localStorageKey);
      if (!localStorageCachedData) {
        return undefined;
      }

      try {
        const parsedData = JSON.parse(localStorageCachedData) as WeatherDataCache[];
        const cachedData = parsedData.find(d => d.key === cacheKey);

        if (cachedData) {
          // Cache the data in memory for future use
          this.saveToMemoryCache(cacheKey, cachedData);
          return cachedData;
        }
      } catch (_error) {}

      return undefined;
    }

    /**
     * Save data to memory cache.
     *
     * @param cacheKey
     * @param cachedData
     */
    private saveToMemoryCache(cacheKey: string, cachedData: WeatherDataCache): void {
      this.map.set(cacheKey, cachedData);
    }

    /**
     * Save data to local storage and update existing data if necessary.
     *
     * @param cacheKey
     * @param cachedData
     */
    private saveToLocalStorageCache(cacheKey: string, cachedData: WeatherDataCache): void {
      const localStorageCachedData = this.wtStorage.getItem(WeatherDataCachingService.localStorageKey);

      try {
        let parsedData: WeatherDataCache[] = [];

        if (localStorageCachedData) {
          parsedData = JSON.parse(localStorageCachedData) as WeatherDataCache[];
          const existingData = parsedData.find(d => d.key === cacheKey);

          if (existingData) {
            existingData.data = cachedData.data;
            existingData.timestamp = cachedData.timestamp;
          } else {
            parsedData.push(cachedData);
          }
        } else {
          parsedData = [cachedData];
        }

        this.wtStorage.setItem(WeatherDataCachingService.localStorageKey, JSON.stringify(parsedData));
      }
      catch (_error) {}
    }

    /**
     * Build a unique cache key from latitude and longitude.
     *
     * @param lat
     * @param lng
     * @returns
     */
    private buildKey(lat: number, lng: number): string {
        return `${lat},${lng}`;
    }
}
