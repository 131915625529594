<div class="create-modal-container">
  <div class="create-modal-header">
    <h6 class="create-modal-title">Save Changes</h6>
  </div>
  <div class="create-modal-content">
    <div class="create-modal-message">
      Are you sure you want to save the following changes to
      <div class="modal-highlight-content">'{{ itemName }}'?</div>
    </div>
    <div class="modal-content-dates">
      <div class="modal-start-date-container">
        <span class="modal-start-title">Start</span>
        <span class="modal-start-date">{{ startDate | localisedDate: true:true }}</span>
      </div>
      <div class="modal-end-date-container">
        <span class="modal-end-title">Start</span>
        <span class="modal-end-date">{{ endDate | localisedDate: true:true }}</span>
      </div>
    </div>
  </div>
  <div class="create-modal-footer">
    <div class="create-modal-buttons">
      <app-button-rectangle
        class="cancel-button"
        text="Cancel"
        (clicked)="onCancel()"
        buttonTheme="white"
        size="large"
      ></app-button-rectangle>
      <app-button-rectangle
        class="create-button"
        text="Save"
        (clicked)="onAdd()"
        buttonTheme="primary"
        size="large"
      ></app-button-rectangle>
    </div>
  </div>
</div>
