import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleSwitchComponent {
  @Input() toggled: boolean;
  @Input() left: string;
  @Input() right: string;
  @Input() active: string;

  @Output() onChange: EventEmitter<boolean> = new EventEmitter();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  onChangeActive(e: Event, side: string) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    if (side === this.active) {
      return;
    }

    this.active = side;
    this.changeDetectorRef.markForCheck();
    this.onChange.emit((this.toggled = !this.toggled));
  }
}
