@if (isEditMode) {
  <div class="details-text-area-wrapper">
    <div class="first-row">
      <div class="label">{{ label }}</div>
      <div class="action-buttons">
        <app-button-icon-square
          class="me-2"
          [icon]="'check'"
          [disabled]="modifiedInput === origintalInput"
          (click)="save(modifiedInput)">
        </app-button-icon-square>
        <app-button-icon-square
          [icon]="'close'"
          (click)="cancelEdit()">
        </app-button-icon-square>
      </div>
    </div>
    <div class="second-row" [class.new-input-edit-state]="editState">
        <app-rich-text-editor-v2
        [placeholder]="placeholder"
        [inputText]="modifiedInput"
        [showUndoAndRedo]="false"
        [showProfileImage]="false"
        [minLength]="minLength"
        [autoFocus]="true"
        (textChanged)="changeInput($event)"
        (canceled)="cancelEdit($event)"
        (saved)="save($event)"
        ></app-rich-text-editor-v2>
    </div>
  </div>
}

@if (!isEditMode) {
  <div class="details-text-area-wrapper">
    <div class="first-row">
      <div class="label">{{ label }}</div>
      @if (isCollapsable) {
        <div class="action-buttons">
          <app-button-transparent
            (clicked)="isCollapsed =! isCollapsed"
            [text]="!isCollapsed ? (T.common.show_less | translate) : (T.common.show_more | translate)"
            size="small"
            iconFontSize="16"
            icon="unfold_more"
            textColor="primary-text"
            class="ms-2">
          </app-button-transparent>
        </div>
      }
    </div>
    <div class="second-row"
    [class.d-flex]="isCollapsable"
    (click)="toggleEditMode($event)">
      @if (modifiedInput && modifiedInput.length > 0) {
        <div
          #textArea
          class="text-area"
          [innerHTML]="modifiedInput"
          [class.collapsed]="isCollapsed"
          [style.height]="isCollapsed ? lineHeight + 'px' : 'auto'">
        </div>
        @if (isCollapsed && isCollapsable) {
          <div class="dots">...</div>
        }
      }
      @if (!modifiedInput || modifiedInput.length === 0) {
        <div class="not-edit-state-text empty-state">
          {{ placeholder }}
        </div>
      }
    </div>
  </div>
}
