import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ZoneViewModel } from 'src/app/modules/settings/viewModels/zoneViewModel';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { MapSidebarEventsEmitter } from 'src/app/modules/shared/events/map-sidebar.events';
import { ZonesAreasButtonState } from 'src/app/modules/shared/enums/maps/zonesButtonState';
import { GMapUtilities } from 'src/app/modules/shared/utilities/gMap.utilities';
import { MapViewUnit } from 'src/app/modules/shared/enums/maps/mapViewUnit';
import { MapViewsGroupButtonComponent } from '../map-views-group-button/map-views-group-button.component';
import { LevelIndexLabelViewModel } from 'src/app/modules/settings/viewModels/levelIndexLabelViewModel';
import { CheckboxWithLabelComponent } from '../../../checkbox-with-label/checkbox-with-label.component';

@Component({
  selector: 'app-map-sidebar',
  templateUrl: './map-sidebar.component.html',
  styleUrls: ['./map-sidebar.component.scss'],
})
export class MapSidebarComponent implements OnInit {
  @ViewChild('controlsWrapper', { static: true }) controlsWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('fullscreenIcon', { static: true }) fullscreenIcon: ElementRef<HTMLElement>;
  @ViewChild('refreshIcon', { static: true }) refreshIcon: ElementRef<HTMLElement>;
  @ViewChild('sidebarWrapper', { static: true }) sidebarWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('mapTypesGroupButton', { static: true }) mapTypesGroupButton: MapViewsGroupButtonComponent;

  @Input() map: google.maps.Map;
  @Input() zones: ZoneViewModel[];
  @Input() areas: AreaViewModel[];
  @Input() zonesPolygons: Map<number, google.maps.Polygon> = new Map();
  @Input() areasPolygons: Map<number, google.maps.Polygon> = new Map();
  @Input() showZonesAreasBtn = false;
  @Input() showTrafficLayerBtn = false;
  @Input() showW3WGridBtn = false;
  @Input() showReloadBtn = false;
  @Input() showMyLocationBtn = true;
  @Input() showFullscreenBtn = true;

  public active = false;
  public mapLayers: LevelIndexLabelViewModel[] = [];
  public visibleMapLayers: LevelIndexLabelViewModel[] = [];

  public zonesVisible = true;
  public areasVisible = true;

  private zonesAreasButtonState: ZonesAreasButtonState = ZonesAreasButtonState.NONE_DISPLAYED;

  constructor(
    private readonly mapSidebarEventsEmitter: MapSidebarEventsEmitter,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.fullscreenIcon && this.map) {
      document.onwebkitfullscreenchange =
        document.onmsfullscreenchange =
        document.onmozfullscreenchange =
        document.onfullscreenchange =
          () => {
            const elementToSendFullscreen = this.map.getDiv().firstChild as HTMLElement;
            if (this.isFullscreen(elementToSendFullscreen)) {
              this.fullscreenIcon.nativeElement.innerText = 'fullscreen_exit';
            } else {
              this.fullscreenIcon.nativeElement.innerText = 'fullscreen';
            }
          };
    }

    this.mapLayers = GMapUtilities.getMapLayers();
  }

  get displayHybridMapCheckbox(): boolean {
    return this.map.getMapTypeId() === 'satellite' || this.map.getMapTypeId() === 'hybrid';
  }

  get isHybridMapTypeId(): boolean {
    return this.map.getMapTypeId() === 'hybrid';
  }

  public mapLevelCheckState(layer: LevelIndexLabelViewModel): boolean {
    if(this.visibleMapLayers.length) {
      return !!this.visibleMapLayers.find(l => l.id === layer.id);
    }

    return false;
  }

  public onToggleSidebar(): void {
    this.sidebarWrapper.nativeElement.classList.toggle('active');
    this.active = !this.active;
  }

  public onCloseSidebar(): void {
    this.onToggleSidebar();
  }

  public onMapLayerCheckStatusChanged(layer: LevelIndexLabelViewModel, $eventState: string) {
    const state = CheckboxWithLabelComponent.toBooleanState($eventState);
    if(layer) {
      if(state) {
        this.visibleMapLayers.push(layer);
      } else {
        this.visibleMapLayers.splice(this.visibleMapLayers.findIndex(l => l.id === layer.id), 1);
      }
    } else {
      this.visibleMapLayers = [];
    }

    this.filterZonesAndAreas();
  }

  /**
   * Shoes/hides all zone polygons on map
   * @param $eventState
   */
  public onZonesDisplayButtonStateChanged($eventState: string) {
    const checked = CheckboxWithLabelComponent.toBooleanState($eventState);

    this.zones.forEach(zone => {
      const polygon = this.zonesPolygons.get(zone.id);
      if(polygon) {
        let displayOnMap = checked;
        if(displayOnMap) {
          displayOnMap =
            this.visibleMapLayers.length === 0 ||
            this.visibleMapLayers.findIndex(l => +l.id === zone.levelIndex) > -1;
        }

        polygon.setMap(displayOnMap ? this.map : null);
      }
    });

    this.zonesVisible = !this.zonesVisible;
  }

  /**
   * Shows hide all area pologyns on map
   * @param $eventState
   */
  public onAreasDisplayButtonStateChanged($eventState: string) {
    const checked = CheckboxWithLabelComponent.toBooleanState($eventState);

    this.areas.forEach(area => {
      const polygon = this.areasPolygons.get(area.id);
      if(polygon) {
        let displayOnMap = checked;
        if(displayOnMap) {
          displayOnMap =
            this.visibleMapLayers.length === 0 ||
            this.visibleMapLayers.findIndex(l => +l.id === area.levelIndex) > -1;
        }

        polygon.setMap(displayOnMap ? this.map : null);
      }
    });

    this.areasVisible = !this.areasVisible;
  }

  public onZonesAreasButtonStateChanged($event: ZonesAreasButtonState) {
    if (this.refreshIcon) {
      const hidden = $event === ZonesAreasButtonState.NONE_DISPLAYED;
      if (hidden) {
        this.refreshIcon.nativeElement.parentElement.classList.add('disabled');
      } else {
        this.refreshIcon.nativeElement.parentElement.classList.remove('disabled');
      }
    }

    this.zonesAreasButtonState = $event;
  }

  public onMapViewUnitSelected(_$event: MapViewUnit): void {

  }

  public onHybridMapTypeClicked($event: string): void {
    if ($event === 'checked') {
      this.map.setMapTypeId(google.maps.MapTypeId.HYBRID);
    } else {
      this.map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
    }
  }

  public onMyLocationAvailable($latLng: google.maps.LatLng): void {
    if (this.map) {
      this.map.panTo($latLng);
      this.map.setZoom(19);
    }
    this.mapSidebarEventsEmitter.broadcastOnMyLocationAvailable($latLng);
  }

  public onRefreshClicked($event: MouseEvent) {
    if (this.zonesAreasButtonState) {
      switch (this.zonesAreasButtonState) {
        case ZonesAreasButtonState.ZONES_DISPLAYED: {
          this.fitZonesBounds();
          break;
        }
        case ZonesAreasButtonState.ZONES_AREAS_DISPLAYED: {
          this.fitZonesAndAreasBounds();
          break;
        }
        case ZonesAreasButtonState.NONE_DISPLAYED: {
          break;
        }
      }
    }
  }

  public onFullScreenClicked($event: MouseEvent) {
    if (this.map) {
      const elementToSendFullscreen = this.map.getDiv().firstChild as HTMLElement;
      if (this.isFullscreen(elementToSendFullscreen)) {
        this.exitFullscreen();
      } else {
        this.requestFullscreen(elementToSendFullscreen);
      }
    }
  }

  private isFullscreen(element: HTMLElement) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) == element
    );
  }

  private requestFullscreen(element: HTMLElement) {
    if (element.requestFullscreen) {
      void element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      void element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      void element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      void element.msRequestFullScreen();
    }
  }

  private exitFullscreen() {
    if (document.exitFullscreen) {
      void document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      void document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      void document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      void document.msExitFullscreen();
    }
  }

  private fitZonesBounds(): void {
    const latLngBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    if (this.zones) {
      this.zonesPolygons.forEach((zone) => {
        const zoneLatLngBounds = GMapUtilities.getPolygonLatLngBounds(zone);
        latLngBounds.union(zoneLatLngBounds);
      });
    }

    this.map.fitBounds(latLngBounds);
  }

  private fitZonesAndAreasBounds() {
    const latLngBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    if (this.zonesPolygons) {
      this.zonesPolygons.forEach((zone) => {
        const zoneLatLngBounds = GMapUtilities.getPolygonLatLngBounds(zone);
        latLngBounds.union(zoneLatLngBounds);
      });
    }

    if (this.areasPolygons) {
      this.areasPolygons.forEach((area) => {
        const areaLatLngBounds = GMapUtilities.getPolygonLatLngBounds(area);
        latLngBounds.union(areaLatLngBounds);
      });
    }

    this.map.fitBounds(latLngBounds);
  }

  private filterZonesAndAreas(): void {
    if(this.zonesVisible) {
      this.zones.forEach(zone => {
        const polygon = this.zonesPolygons.get(zone.id);
        if(polygon) {
          const displayOnMap =
            this.visibleMapLayers.length === 0 ||
            this.visibleMapLayers.findIndex(l => +l.id === zone.levelIndex) > -1;

          polygon.setMap(displayOnMap ? this.map : null);
        }
      });
    }

    if(this.areasVisible) {
      this.areas.forEach(area => {
        const polygon = this.areasPolygons.get(area.id);
        if(polygon) {
          const displayOnMap =
            this.visibleMapLayers.length === 0 ||
            this.visibleMapLayers.findIndex(l => +l.id === area.levelIndex) > -1;

          polygon.setMap(displayOnMap ? this.map : null);
        }
      });
    }
  }
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onwebkitfullscreenchange?: any;
    onmsfullscreenchange?: any;
    onmozfullscreenchange?: any;
  }

  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}
