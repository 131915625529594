<app-loading *ngIf="isLoading"></app-loading>
<div *ngIf="!isLoading" class="add-modal-container">
  <div class="modal-header-container d-flex justify-content-between align-items-center">
    <app-button-transparent size="large" class="d-block d-md-none" text="Cancel" (clicked)="confirmClose()">
    </app-button-transparent>
    <span class="modal-title">{{ T.common.add_item | translate: { item: localisedSubTask } }}</span>
    <span class="material-symbols-outlined close-icon d-none d-md-block" (click)="confirmClose()">close</span>
    <app-button-transparent
      size="large"
      class="d-block d-md-none"
      [text]="T.common.save | translate"
      (clicked)="handleAction(addModalButtonOptions.Save_Close)"
    ></app-button-transparent>
  </div>
  <div class="validations-erros-container" *ngIf="validationErrors?.length > 0">
    <div class="validation-error" *ngFor="let error of validationErrors">
      <i class="material-symbols-outlined">error_outline</i>
      {{ error }}
    </div>
  </div>
  <div class="modal-body-container row col-row-space custom-gutters" [formGroup]="form">
    <div class="col-12">
      <div class="visible-part row col-row-space custom-gutters">
        <div class="container-with-bottom-element col-md-6 col-12 order-md-1">
          <app-filter-dropdown-new
            class="modal-project"
            [displayError]="triggerErrors"
            [disabled]="isTriggeredFromDetails"
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Project"
            [disableMargin]="true"
            [required]="true"
            [singleSelect]="true"
            [appliedFilters]="filters"
            [typeahead]="true"
            (onFilterUpdate)="handleProjectChange($event)"
            [label]="localisedProject + '*'"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="d-flex container-with-bottom-element col-md-6 col-12 order-md-3">
          <div class="drop-arrow">
            <div class="vertical-line"></div>
            <div class="horizontal-line"></div>
          </div>
          <app-filter-dropdown-new
            class="flex-fill modal-taskGroup"
            [disabled]="isTriggeredFromDetails || !selectedProject"
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Task_Group"
            [disableMargin]="true"
            [required]="true"
            [singleSelect]="true"
            [appliedFilters]="filters"
            [typeahead]="true"
            [relatedObjectId]="selectedProject?.filterValue"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="d-flex container-with-bottom-element col-md-6 col-12 order-md-5">
          <div class="second-arrow drop-arrow">
            <div class="vertical-line"></div>
            <div class="horizontal-line"></div>
          </div>
          <app-filter-dropdown-new
            class="flex-fill modal-task"
            [filterByModuleObjectType]="false"
            [disabled]="!selectedTaskGroup || isParentTask"
            [displayError]="triggerErrors"
            [filterType]="filterTypes.Task"
            [disableMargin]="true"
            [required]="true"
            [singleSelect]="true"
            [appliedFilters]="filters"
            [typeahead]="true"
            [relatedObjectId]="selectedTaskGroup?.filterValue"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="d-flex container-with-bottom-element col-md-6 col-12 order-md-7">
          <div class="third-arrow drop-arrow">
            <div class="vertical-line"></div>
            <div class="horizontal-line"></div>
          </div>
          <app-text-field
            class="flex-fill modal-subTask"
            [displayError]="triggerErrors"
            [autofocus]="true"
            formControlName="subTaskTitle"
            [disableMargin]="true"
            [useReactiveMessages]="true"
            [icon]=""
            [svg]="subTaskSvgIcon"
            [placeholder]="(T.common.item_title | translate: { item: localisedSubTask }) + '*'"
            [formGroup]="form"
            [maxLength]="250"
          >
          </app-text-field>
        </div>
        <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12 order-md-8">
          <div class="selection">
            <app-filter-dropdown-new
              [filterByModuleObjectType]="false"
              [filterType]="filterTypes.Owner"
              [appliedFilters]="filters"
              [disableMargin]="true"
              [typeahead]="true"
              [label]="T.common.assign_owner.many | translate"
              (onFilterUpdate)="handleFilterChange($event)"
            >
            </app-filter-dropdown-new>
          </div>
          <div class="bottom-element d-flex">
            <app-button-transparent
              size="small"
              icon="add"
              [text]="T.common.assign_me | translate"
              (clicked)="setAccountForCurrentOwner()"
            ></app-button-transparent>
          </div>
        </div>
        <div class="d-flex flex-column col-md-6 col-12 order-md-6">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.User_Group"
            [appliedFilters]="filters"
            [disableMargin]="true"
            [typeahead]="true"
            [label]="T.common.assign_group_s | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="col-md-6 col-12 order-md-2">
          <app-filter-dropdown-new
            [useSearch]="false"
            [displayError]="triggerErrors"
            [required]="true"
            [filterByModuleObjectType]="false"
            [disableMargin]="true"
            [currentlySelectedSettingValue]="'0'"
            [filterType]="filterTypes.RAG"
            [singleSelect]="true"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.rag_status.one | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="col-md-6 col-12 order-md-4 d-flex" *ngIf="!dateReseter">
          <app-datepicker
            class="nested-col-left"
            [required]="mandatoryDates"
            [showError]="triggerErrors"
            [range]="true"
            [endDate]="getDueDate"
            [date]="getStartDate"
            (dateChanged)="setStartDate($event)"
            (endDateChanged)="setDueDate($event)"
            [placeholder]="T.common.start_date.one | translate"
            [showPlaceholderFirst]="true"
            [disableMargin]="true"
          >
          </app-datepicker>
          <app-datepicker
            class="nested-col-right"
            [required]="mandatoryDates"
            [showError]="triggerErrors"
            [range]="true"
            [endDate]="getDueDate"
            [date]="getStartDate"
            [isEndDateField]="true"
            (dateChanged)="setStartDate($event)"
            (endDateChanged)="setDueDate($event)"
            [placeholder]="T.defaultLocalizations.due_date.one | translate"
            [showPlaceholderFirst]="true"
            [disableMargin]="true"
          ></app-datepicker>
        </div>
        <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12 order-md-12">
          <div class="selection">
            <app-filter-dropdown-new
              [filterByModuleObjectType]="false"
              [filterType]="filterTypes.Department"
              [disableMargin]="true"
              [appliedFilters]="filters"
              [typeahead]="true"
              [label]="T.common.assign_item | translate: { item: localisedDepartment }"
              (onFilterUpdate)="handleFilterChange($event)"
            >
            </app-filter-dropdown-new>
          </div>
          <div class="bottom-element d-flex" *ngIf="employee?.departmentId > 0">
            <app-button-transparent
              size="small"
              icon="add"
              [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
              (clicked)="setMyDepartment()"
            ></app-button-transparent>
          </div>
        </div>
      </div>
    </div>
    <!-- Hidden Options -->
    <ng-container *ngIf="isHiddenOptionVisible">
      <div class="col-md-6 col-12">
        <app-filter-dropdown-new
          [useSearch]="false"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Priority"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="T.defaultLocalizations.priority.one | translate"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="col-md-6 col-12">
        <app-simple-dropdown
          (objectSelected)="handleComletion($event)"
          [objects]="completionArr"
          bindProperty="visibleValue"
          [label]="T.common.completion | translate"
        >
        </app-simple-dropdown>
      </div>
      <div class="col-md-3 col-12">
        <app-text-field [maxLength]="12" formControlName="refCode" [placeholder]="'Ref Code'" [formGroup]="form">
        </app-text-field>
      </div>
      <div class="description col-12">
        <app-details-editable-field
          [input]="form.controls.description.value ? form.controls.description.value : ''"
          [header]="T.defaultLocalizations.description.one | translate"
          [type]="editableFieldTypes.RichText"
          [canEdit]="true"
          (onUpdate)="updateDescription($event)"
          (onInputChange)="updateDescription($event)"
          [placeholder]="T.common.add_description | translate"
        ></app-details-editable-field>
      </div>
      <div class="col-12">
        <app-text-field name="headlineStatus" [ngModelOptions]="{ standalone: true }" [(ngModel)]="task.headlineStatus"
          [placeholder]="T.defaultLocalizations.headline_status.one | translate" [maxLength]="255" [minLength]="3"
          [required]="false">
        </app-text-field>
      </div>
      <app-add-modal-tag-groups
        class="col-12"
        [layoutClasses]="'col-md-6 col-12'"
        [filters]="filters"
        [tagGroupObjectType]="objectTypes.Task"
        (onFiltersUpdated)="handleFilterChange($event)"
      >
      </app-add-modal-tag-groups>
    </ng-container>
    <!-- End of Hidden Options -->
    <div class="show-more-btn d-none d-md-block col-12">
      <app-button-bar-rounded
        (clicked)="showHiddenOptions()"
        [isOpen]="isHiddenOptionVisible"
        [fitToContainer]="true"
        [text]="T.common.show_more | translate"
        [secondText]="T.common.show_less | translate"
      ></app-button-bar-rounded>
    </div>
    <div class="buttons-row col-12 d-flex justify-content-between align-items-center">
      <app-button-transparent
        class="d-none d-lg-block"
        [app-tooltip]="{
          title: T.common.keyboard_shortcuts | translate,
          position: ['top'],
          templateRef: tooltipTemplate
        }"
        [text]="T.common.keyboard_shortcuts | translate"
        icon="keyboard"
      ></app-button-transparent>
      <div class="action-buttons">
        <app-button-rectangle
          class="button d-none d-md-block"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.close | translate"
          buttonTheme="white"
          (clicked)="confirmClose()"
        ></app-button-rectangle>
        <app-button-dropdown
          class="button"
          [fitToContainer]="true"
          [selectedOption]="buttonOptions[selectedButtonOption]"
          [options]="buttonOptions"
          (selected)="handleAction($event)"
        ></app-button-dropdown>
      </div>
    </div>
  </div>
  <ng-template #tooltipTemplate>
    <table class="styled-table">
      <tr *ngFor="let keybind of keyboardShortcuts">
        <td>
          <span class="key-shortcut">{{ keybind.key }}</span> -
          <span class="key-description">{{ keybind.description }}</span>
        </td>
      </tr>
    </table>
  </ng-template>
</div>
