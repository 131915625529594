<div
  class="filters-list-entry"
  [class.filters-list-entry-selected]="selected"
  [class.filters-list-entry-mobile]="mobile"
  [class.filters-list-entry-focused]="focused"
  (mouseup)="onClick()"
>
  <div class="filters-list-entry-color" [style.background-color]="color" *ngIf="color"></div>
  <div style="margin-right: 12px; display: flex" *ngIf="filterType === filterTypes.Job_Priority">
    <span
      *ngFor="let item of [].constructor(3); let i = index"
      style="color: #cccccc"
      class="material-symbols-outlined star"
      [class.wetrack-black]="i < filterValue"
      >star
    </span>
  </div>
  <ng-container *ngIf="customIcon">
    <i class="material-symbols-outlined me-2">{{ customIcon }}</i>
  </ng-container>
  <div class="filters-list-entry-text" [innerHTML]="setText()"></div>
  <div class="filters-list-entry-icon">
    <i class="material-symbols-outlined material-symbols-outlined-add">add_circle_outline</i>
    <i [class.material-symbols-outlined-applied-required]="required" class="material-symbols-outlined material-symbols-outlined-applied">done</i>
    <i *ngIf="!required" class="material-symbols-outlined material-symbols-outlined-remove">highlight_off</i>
  </div>
</div>
<div class="filters-list-entry-border" *ngIf="useBorderBottom"></div>
