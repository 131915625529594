import { Provider } from '@angular/core';

import { BsModalConfig } from '../config/modal.config';

export const modalConfigProviders: Provider[] = [
  {
    provide: BsModalConfig,
    useClass: BsModalConfig,
  },
];
