<app-text-field
  [placeholder]="searchPlaceholder"
  [(ngModel)]="searchValue"
  (ngModelChange)="onSearch()"
  (click)="onClick()"
></app-text-field>
<i
  *ngIf="!searchValue"
  class="material-symbols-outlined material-symbols-outlined-search"
  [style.color]="inputIsFocused() ? 'var(--wt-primary)' : ''"
  >search</i
>
<i *ngIf="searchValue" class="material-symbols-outlined material-symbols-outlined-close" (click)="onClear($event)">close</i>
