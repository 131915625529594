<div class="attachments-list-header-wrapper">
  <div *ngIf="!isInAccordion" class="list-actions-button-container d-flex">
    <app-button-dropdown-simple
      class="me-2"
      [icon]="'arrow_drop_down'"
      [buttonTheme]="'white'"
      [bindProperty]="'translatedValue'"
      [text]="selectedDropdownOption.translatedValue"
      [dropdownOptions]="sortDropdownItems"
      [currentlySelectedOption]="selectedDropdownOption"
      [showSelectedOption]="true"
      [closeUponSelection]="true"
      (optionSelected)="selectedDropdownOptionChanged($event)"
    ></app-button-dropdown-simple>
    <app-button-dropdown-simple
      *ngIf="selectedCategory"
      [dropdownOptions]="documentCategories"
      icon="arrow_drop_down"
      [bindProperty]="'title'"
      [closeUponSelection]="true"
      [currentlySelectedOption]="selectedCategory"
      size="small"
      (optionSelected)="onCategoryOptionChange($event)"
      buttonTheme="white"
      class="mr-8"
    ></app-button-dropdown-simple>
    <ng-container *ngIf="showUploadButton">
      <app-button-rectangle
        ngDropdown
        [closeUponSelection]="true"
        [templateRef]="uploadButtonTemplate"
        [text]="T.common.upload_document | translate"
        size="small"
        icon="upload"
        buttonTheme="white"
        [disabled]="false"
        [ngClass]="{ disabled: false }"
        class="mr-8"
      ></app-button-rectangle>
    </ng-container>
  </div>
  <div *ngIf="isInAccordion" class="accordion-buttons">
    <ng-container *ngIf="showUploadButton">
      <app-button-rectangle
        ngDropdown
        [closeUponSelection]="true"
        [templateRef]="uploadButtonTemplate"
        [text]="T.common.upload_document | translate"
        size="small"
        icon="upload"
        [fitToContainer]="true"
        buttonTheme="white"
        [disabled]="false"
        [ngClass]="{ disabled: false }"
        class="mr-8"
      ></app-button-rectangle>
    </ng-container>
    <div class="type-and-category-container">
      <app-button-dropdown-simple
        class="me-2"
        [icon]="'arrow_drop_down'"
        [buttonTheme]="'white'"
        [bindProperty]="'translatedValue'"
        [text]="selectedDropdownOption.translatedValue"
        [dropdownOptions]="sortDropdownItems"
        [currentlySelectedOption]="selectedDropdownOption"
        [showSelectedOption]="true"
        [fitToContainer]="true"
        [closeUponSelection]="true"
        (optionSelected)="selectedDropdownOptionChanged($event)"
      ></app-button-dropdown-simple>
      <app-button-dropdown-simple
        *ngIf="selectedCategory"
        [dropdownOptions]="documentCategories"
        icon="arrow_drop_down"
        [bindProperty]="'title'"
        [closeUponSelection]="true"
        [fitToContainer]="true"
        [currentlySelectedOption]="selectedCategory"
        size="small"
        (optionSelected)="onCategoryOptionChange($event)"
        buttonTheme="white"
        class="mr-8"
      ></app-button-dropdown-simple>
    </div>
  </div>
  <ng-container *ngIf="isDetailsPage && showListBoardButtons">
    <div class="list-view-options">
      <div
        class="list-view pe-2"
        (click)="selectAttachmentsView(attachmentsListViewTypes.List)"
        [class.selected-view]="selectedListView === attachmentsListViewTypes.List"
      >
        <div class="list-view-icon me-1">
          <i class="material-symbols-outlined list-icon">list</i>
        </div>
        <div class="list-view-text">{{ T.common.list | translate }}</div>
      </div>
      |
      <div
        class="list-view ps-2"
        (click)="selectAttachmentsView(attachmentsListViewTypes.Board)"
        [class.selected-view]="selectedListView === attachmentsListViewTypes.Board"
      >
        <div class="list-view-icon me-1">
          <i class="material-symbols-outlined list-icon">dashboard</i>
        </div>
        <div class="list-view-text">{{ T.common.board | translate }}</div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #uploadButtonTemplate>
  <div class="dropdown-options-container">
    <div class="dropdown-option" *ngIf="isNativePlatform" (click)="takePhoto()">
      {{ T.common.take_a_photo | translate }}
    </div>
    <div class="dropdown-option" (click)="openFileUpload()">{{ T.common.choose_a_file | translate }}</div>
    <div class="dropdown-option" (click)="addLink()">{{ T.common.add_link | translate }}</div>
  </div>
</ng-template>
