import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { AccountSettingTypes } from 'src/app/modules/shared/enums/accountSettingTypes';
import { DashboardInfoWidgetViewModel } from 'src/app/modules/settings/viewModels/dashboardInfoWidgetViewModel';
import { UntypedFormGroup } from '@angular/forms';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-widget-dashboard-settings',
  templateUrl: 'info-widget-dashboard-settings.component.html',
  styleUrls: ['info-widget-dashboard-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoWidgetDashboardSettingsComponent implements OnInit, OnDestroy {
  @Input() module: ModuleTypes;
  @Input() modalActionHandlerClick: Observable<void>;

  private subscriptions: Subscription[] = [];

  isAdmin = false;
  employee: Employee;
  account: Account;

  venueDashboardInfoWidgetAccountSettingType = AccountSettingTypes.Venue_Dashboard_Info_Widget;
  incidentsDashboardInfoWidgetAccountSettingType = AccountSettingTypes.Incident_Dashboard_Info_Widget;
  currentAccountSettingType: AccountSettingTypes;
  dashboardInfoWidget: DashboardInfoWidgetViewModel;
  dashboardInfoWidgetForm: UntypedFormGroup;
  dashboardInfoWidgetEditMode: boolean = false;
  public readonly T = T;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly accountSettingsService: AccountSettingsService,
    private readonly alertService: AlertService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dashboardEventEmitters: DashboardEventEmitters,
    private readonly translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }
  ngOnInit(): void {
    if (this.module === ModuleTypes.Incidents) {
      this.currentAccountSettingType = this.incidentsDashboardInfoWidgetAccountSettingType;
    } else {
      this.currentAccountSettingType = this.venueDashboardInfoWidgetAccountSettingType;
    }
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    this.getDashboardInfoWidget();
  }

  //#region dashboard info widget
  getDashboardInfoWidget() {
    this.subscriptions.push(
      this.accountSettingsService.getDashboardInfoWidgetSettings(this.currentAccountSettingType).subscribe((widget) => {
        this.dashboardInfoWidget = widget;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  onWidgetInfoUpdated(widgetInfo: DashboardInfoWidgetViewModel) {
    this.dashboardInfoWidget = widgetInfo;
    this.updateDashboardInfoWidget();
    this.changeDetectorRef.detectChanges();
  }

  updateDashboardInfoWidget() {
    this.accountSettingsService
      .updateDashboardInfoWidgetSettings(this.account.id, this.currentAccountSettingType, this.dashboardInfoWidget)
      .subscribe((res) => {
        void this.alertService.success(this.translateService.instant(T.dashboard.dashboard_info_widget_updated));
        this.changeDetectorRef.detectChanges();
        this.dashboardEventEmitters.broadcastInfoSettingsChanged();
      });
  }

  toggleInfoWidgetEditMode() {
    this.dashboardInfoWidgetEditMode = !this.dashboardInfoWidgetEditMode;
    this.changeDetectorRef.detectChanges();
  }
}
