import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';

@Component({
  selector: 'app-owners-avatar-group',
  templateUrl: './owners-avatar-group.component.html',
  styleUrls: ['./owners-avatar-group.component.scss'],
})
export class OwnersAvatarGroupComponent implements OnInit, OnChanges {
  @Input() ownersFilter: FilterViewModel[] = [];
  @Input() maxVisible: number = 3;
  @Input() avatarSize: number = 24;

  private remainingOwners: number = 0;

  public dummyIterator: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ownersFilter && !changes.firstChange) {
      this.initDummyIterator();
      this.remainingOwners = this.ownersFilter.length - this.maxVisible;
    }
  }

  ngOnInit(): void {
    this.initDummyIterator();
    this.remainingOwners = this.ownersFilter.length - this.maxVisible;
  }

  get remaining(): number {
    return this.remainingOwners >= 0 ? this.remainingOwners : 0;
  }

  get isRemaining(): boolean {
    return this.remainingOwners > 0;
  }

  public getOwnerFilter(index: number): FilterViewModel {
    return this.ownersFilter[index];
  }

  private initDummyIterator() {
    const size = this.maxVisible <= this.ownersFilter.length ? this.maxVisible : this.ownersFilter.length;
    this.dummyIterator = Array(size)
      .fill(0)
      .map((x, i) => i);
  }
}
