import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-back-button',
  templateUrl: 'back-button.component.html',
  styleUrls: ['back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackButtonComponent {
  @Input() url: string;
  @Input() showIcon: boolean = true;
  @Input() label = this.translateService.instant(T.common.back);
  @Input() useBrowserBackNavigation: boolean = false;

  @Output() buttonClicked = new EventEmitter<void>();

  constructor(private readonly router: Router, private readonly translateService: TranslateService) {}

  onClick() {
    if (this.url) {
      void this.router.navigate([this.url]);
    } else if (this.useBrowserBackNavigation) {
      window.history.back();
    } else {
      this.buttonClicked.emit();
    }
  }
}
