<div class="toggle-button-container" [style.width]="fillWidth ? '100%' : 'fit-content'">
  <div
    class="single-toggle-button"
    [style.flex]="fillWidth ? '1' : 'initial'"
    *ngFor="let option of objects"
    [attr.active]="option?.[bindProperty] === currentlySelectedObject?.[bindProperty]"
    (click)="onClick(option)"
    [attr.disabled]="disabled"
  >
    <ng-container *ngIf="option && option['icon']; else noIconTemplate">
      <i
        class="material-symbols-outlined"
        [style.transform]="'rotate(' + (option && option['rotateIconDeg'] ? option['rotateIconDeg'] : 0) + 'deg)'"
        >{{ option['icon'] }}</i
      >
      <span *forScreen="'desktop'">{{ option[labelProperty] }}</span>
    </ng-container>
    <ng-template #noIconTemplate>
      <span>{{ option[labelProperty] }}</span>
    </ng-template>
  </div>
</div>
