import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { EmployeeSettingViewModel } from 'src/app/modules/shared/viewModels/employeeSettingViewModel';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { TaskRAGBreakdownTypes } from 'src/app/modules/planning/enums/taskRAGBreakdownTypes';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';

@Component({
  selector: 'app-venue-dashboard-settings',
  templateUrl: 'venue-dashboard-settings.component.html',
  styleUrls: ['venue-dashboard-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VenueDashboardSettingsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  isAdmin = false;
  employee: Employee;
  account: Account;
  taskRAGBreakdownOptions: { key: TaskRAGBreakdownTypes; value: string }[] = TaskRAGBreakdownTypes.items(
    this.localisationService
  );
  taskRAGBreakdownOptionsSetting: EmployeeSettingViewModel;
  taskRAGBreakdownSelectedOptions = [];
  showTaskRAGBreakdownOptions = true;
  public localisedTask: string = 'Task';

  constructor(
    private readonly alertService: AlertService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly employeeSettingService: EmployeeSettingsService,
    private readonly dashboardEventEmitters: DashboardEventEmitters,
    private readonly authenticationService: AuthenticationService,
    private readonly localisationService: LocalisationService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }
  ngOnInit(): void {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.localisedTask = this.localisationService.localiseObjectType(ObjectTypes.Task);
    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    this.getTaskRagOptionSetting();
  }

  //#region Task RAG Breakdown Widget

  getTaskRagOptionSetting() {
    this.showTaskRAGBreakdownOptions = false;
    this.subscriptions.push(
      this.employeeSettingService.getEmployeeSetting(EmployeeSettingTypes.Venue_Dashboard_Task_RAG_Widget).subscribe((res) => {
        this.taskRAGBreakdownOptionsSetting = res;
        if (this.taskRAGBreakdownOptionsSetting.value) {
          this.taskRAGBreakdownSelectedOptions = JSON.parse(this.taskRAGBreakdownOptionsSetting.value);
        }
        this.showTaskRAGBreakdownOptions = true;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  isTaskRAGOptionChecked(option: { key: TaskRAGBreakdownTypes; value: string }) {
    if (this.taskRAGBreakdownSelectedOptions.find((o) => o == option.key)) return true;
    return false;
  }

  onTaskRAGOptionChecked(state: boolean, option: { key: TaskRAGBreakdownTypes; value: string }) {
    if (!state) {
      const selectedOption = this.taskRAGBreakdownSelectedOptions.find((o) => o == option.key);
      if (selectedOption) {
        this.taskRAGBreakdownSelectedOptions.splice(this.taskRAGBreakdownSelectedOptions.indexOf(selectedOption), 1);
        this.changeDetectorRef.markForCheck();
        this.updateTaskRagOptionSetting(true);
      }
    } else {
      if (this.taskRAGBreakdownSelectedOptions.length < 3) {
        this.taskRAGBreakdownSelectedOptions.push(option.key);
        this.updateTaskRagOptionSetting(true);
      } else {
        void this.alertService.error('The maximum number of selected options is 3');
        this.getTaskRagOptionSetting();
      }
    }
  }

  updateTaskRagOptionSetting(showAlert: boolean = false) {
    this.taskRAGBreakdownOptionsSetting.value = JSON.stringify(this.taskRAGBreakdownSelectedOptions);
    this.subscriptions.push(
      this.employeeSettingService
        .updateEmployeeSetting(EmployeeSettingTypes.Venue_Dashboard_Task_RAG_Widget, this.taskRAGBreakdownOptionsSetting)
        .subscribe(() => {
          if (showAlert) {
            void this.alertService.success('Task RAG breakdown widget updated.');
          }
          this.getTaskRagOptionSetting();
          this.dashboardEventEmitters.broadcastTaskRAGBreakdownOptionsChanged();
        })
    );
  }

  //#endregion

  localiseString(str: string) {
    return this.localisationService.localise(str);
  }
}
