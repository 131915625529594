import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[appDragDrop]',
})
export class DragDropDirective {
  @Output() dropHandler: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();

  public dragging: boolean;
  public loaded: boolean;
  public imageLoaded: boolean;
  public imageSrc: string;
  public invalidFlag: boolean;

  @HostListener('dragover') onDragOver() {
    return false;
  }
  @HostListener('dragenter') handleDragEnter() {
    this.dragging = true;
  }
  @HostListener('dragleave') handleDragLeave() {
    this.dragging = false;
  }
  @HostListener('drop', ['$event']) handleDrop(ev: any) {
    ev.preventDefault();
    this.dragging = false;
    this.handleInputChange(ev);
  }

  OnInit() {}

  handleInputChange(ev: any) {
    this.dropHandler.emit(ev);
  }
}
