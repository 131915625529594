import { Component, Input, Output, EventEmitter, OnChanges, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ChecklistItemViewModel } from 'src/app/modules/shared/models/checklists/checklistItemViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { Constants } from 'src/app/modules/shared/models/constants';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { FilterTypeSelectorViewModel } from 'src/app/modules/shared/viewModels/filters/filterTypeSelectorViewModel';
import { FilterSelectorTypes } from 'src/app/modules/shared/enums/filter/filterSelectorTypes';
import { Employee } from 'src/app/modules/shared/models/employee';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import {
  ChecklistOptionSet1,
  ChecklistOptionSet2,
  ChecklistResponseTypes,
} from 'src/app/modules/shared/enums/checklists/checklistEnums';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-checklist-item-card',
  templateUrl: 'checklist-item-card.component.html',
  styleUrls: ['checklist-item-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistItemCardComponent implements OnInit, OnChanges {
  private mobileWidth = Constants.xs;

  @Input() item: ChecklistItemViewModel;
  @Input() userHasWriteAccess: boolean;
  @Input() showOwnerLozenge = true;
  @Input() draggable = true;
  @Input() loading: boolean = false;
  @Output() viewItem = new EventEmitter<ChecklistItemViewModel>();
  @Output() itemCompleted = new EventEmitter<ChecklistItemViewModel>();

  filterSettings: FilterViewModel[] = [];
  lozengesFilterTypes = [FilterTypes.Owner];
  hasNoLozenges = true;
  noBoxShadowStyle = '{"box-shadow":"none"}';
  ownerFilterTypeSelector: FilterTypeSelectorViewModel;
  employee: Employee;
  objectType = ObjectTypes.Checklist_Item;
  responseTypes = ChecklistResponseTypes;

  optionSet1Items = ChecklistOptionSet1.items();
  optionSet2Items = ChecklistOptionSet2.items();
  editableFieldTypes = EditableFieldTypes;
  hasOwner: boolean;
  public readonly T = T;
  showTextResponseField = false;

  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.hasOwner = !!this.item.employeeId;
    this.initVisibleFilterTypes();
    this.employee = this.authenticationService.getCurrentEmployee();

    this.setTextResponseFieldVisibility();
  }

  setTextResponseFieldVisibility() {
    this.showTextResponseField =
      this.item.responseType === this.responseTypes.Options_Set_2 || this.item.responseType === this.responseTypes.Options_Set_1;
  }

  ngOnChanges(): void {
    if (this.item) {
      this.getEmployeeSetting();
      this.setTextResponseFieldVisibility();
      this.changeDetectorRef.detectChanges();
    }
  }

  private getEmployeeSetting() {
    const settings = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Owner);
    let setting = settings.find(
      (s) => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.item.employeeId.toString()
    );
    if (!setting) {
      setting = FilterUtilities.GenerateFilter(FilterTypes.Owner, 0, 'n/a');
    }

    if (setting) {
      this.filterSettings = [setting];
      this.hasNoLozenges = false;
    }
  }

  onCheckComplete(state: string) {
    if (state === 'checked') {
      this.item.completed = true;
      this.item.response = this.item.completed.toString();
      this.itemCompleted.next(this.item);
    } else if (state === 'unchecked') {
      this.item.completed = false;
      this.item.response = this.item.completed.toString();
      this.itemCompleted.next(this.item);
    }
  }

  get isMobile(): boolean {
    return window.innerWidth <= this.mobileWidth;
  }

  initVisibleFilterTypes() {
    const ownerFilterType = new FilterTypeSelectorViewModel();
    ownerFilterType.filterSelectorType = FilterSelectorTypes.Dropdown;
    ownerFilterType.filterType = FilterTypes.Owner;
    ownerFilterType.filterTypeText = 'Owner';
    ownerFilterType.isSelected = true;
    ownerFilterType.viewOrder = 0;
    ownerFilterType.dateProperty = 0;

    this.ownerFilterTypeSelector = ownerFilterType;
  }

  selectType(response: { key: number; value: string }) {
    this.item.response = response.key.toString();
    this.itemCompleted.next(this.item);
  }

  saveInputResponse($event: string) {
    const richText = $event;
    this.item.response = richText;
    this.itemCompleted.next(this.item);
  }

  saveTextResponse($event: string) {
    const richText = $event;
    this.item.textResponse = richText;
    this.itemCompleted.next(this.item);
  }

  saveNumericResponse(){
    if(this.item.response){
      this.itemCompleted.next(this.item);
    }
  }

  updateFilters(filters: FilterViewModel[]) {
    const filter = filters.find((f) => f.filterType === FilterTypes.Owner && (f.filterValue as string) !== '0');
    this.item.employeeId = filter ? parseInt(filter.filterValue as string) : 0;

    if (filter) {
      this.filterSettings = [filter];
    }

    this.itemCompleted.next(this.item);
  }

  openChecklistModal(item: ChecklistItemViewModel) {
    this.viewItem.emit(item);
  }
}
