import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ReportHistory } from '../../../models/reports/reportHistory';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  @Input() reportTab: ReportTabs;
  @Input() searchTrought;
  @Output() emitTheResult = new EventEmitter<searchResult>();
  @ViewChild('search') searchField: ElementRef;
  userInputUpdate = new Subject<string>();
  outputResult: any[] = [];
  searchFieldOpen: boolean = false;
  userInput: string;

  constructor(public readonly changeDetectorRef: ChangeDetectorRef) {
    this.userInputUpdate.pipe(debounceTime(10), distinctUntilChanged()).subscribe((value) => {
      if (value) {
        this.outputResult = this.searchForPublic(value);
      } else {
        this.outputResult = [];
      }
    });
  }

  @HostListener('window:keydown.enter', ['$event'])
  keyEvent() {
    if (this.searchFieldOpen) {
      const searchResults = new searchResult();
      searchResults.reportTab = this.reportTab;
      searchResults.results = this.outputResult;
      this.emitTheResult.emit(searchResults);
    }
  }

  searchForPublic(word: string): string[] {
    const resultOuput = [];

    for (const item of this.searchTrought) {
      if (item.title.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
        resultOuput.push(item);
      }
    }

    return resultOuput;
  }

  emitItem(item: any) {
    this.emitTheResult.emit(item);
  }

  openSearchField() {
    this.searchFieldOpen = !this.searchFieldOpen;
    this.changeDetectorRef.detectChanges();
    if (this.searchFieldOpen) {
      this.searchField.nativeElement.focus();
    }
  }
}

export class searchResult {
  reportTab: ReportTabs;
  results: ReportHistory[];
}

export enum ReportTabs {
  Favorites = 0,
  Suggested = 1,
}
