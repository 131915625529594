<div class="well" [ngStyle]="{ height: height }">
  <div class="well__header">
    <ng-content select="[well-header]"></ng-content>
  </div>

  <div class="well__body custom-scrollbar">
    <ng-content></ng-content>
  </div>

  <div class="well__footer">
    <ng-content select="[well-footer]"></ng-content>
  </div>
</div>
