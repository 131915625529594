import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Subscription } from 'rxjs';
import { FilterTypes } from '../../../enums/filterTypes';
import { OperationTypes } from '../../../enums/operationTypes';
import { WtValidators } from '../../../reactiveValidators/wtValidators';
import { AlertService } from '../../../services/alert.service';
import { EmployeeSettingsService } from '../../../services/employee-settings.service';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { EmployeeDetailsViewModel } from '../../../viewModels/employeeDetailsViewModel';

@Component({
  selector: 'add-external-user-modal',
  templateUrl: 'add-external-user-modal.component.html',
  styleUrls: ['add-external-user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddExternalUserModal implements OnInit, OnDestroy, AfterViewInit {
  private readonly subscriptions = new Subscription();
  form: UntypedFormGroup;
  readonly userAdded = new Subject<EmployeeDetailsViewModel>();

  constructor(
    private readonly elemRef: ElementRef,
    private readonly bsModalRef: BsModalRef<AddExternalUserModal>,
    private readonly employeeService: EmployeeSettingsService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly alertService: AlertService,
    private fb: UntypedFormBuilder,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    const restrictedChars = [';', '!', '<', '>'];
    this.form = this.fb.group({
      firstName: ['', [Validators.required, this.wtValidators.title(3, 80), this.wtValidators.restrictedChars(restrictedChars)]],
      surname: ['', [Validators.required, this.wtValidators.title(3, 80), this.wtValidators.restrictedChars(restrictedChars)]],
      username: [
        '',
        [Validators.required, this.wtValidators.title(7, 80), Validators.email, this.wtValidators.restrictedChars(restrictedChars)],
      ],
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.setModalContainerDisplayFlex();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onAdd() {
    const newExternalUser = new EmployeeDetailsViewModel();
    newExternalUser.firstName = this.form.controls.firstName.value.trim();
    newExternalUser.surname = this.form.controls.surname.value.trim();
    newExternalUser.username = this.form.controls.username.value.trim();
    newExternalUser.isActive = true;
    newExternalUser.isExternal = true;

    this.subscriptions.add(
      this.employeeService.addEmployee(newExternalUser).subscribe((res) => {
        if (res.errorList?.length) {
          void this.alertService.error(res.errorList[0]);
        } else {
          this.allowedFiltersService.refreshFilters(
            OperationTypes.Create,
            [{ id: res.returnModel.id, title: res.returnModel.firstName + ' ' + res.returnModel.surname, relatedObjectId: 0 }],
            FilterTypes.External_User
          );
          this.userAdded.next(res.returnModel);
          this.bsModalRef.hide();
        }
      })
    );
  }

  private setModalContainerDisplayFlex() {
    const modalDialog = this.elemRef.nativeElement.closest('[role=document]');
    (modalDialog as HTMLElement).classList.add('overflow-visible');
  }
}
