import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { FilterTypes } from '../enums/filterTypes';
import { PermissionOperationTypes } from '../enums/permissionOperationTypes.enum';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { IPermissionManager } from './IPermissionManager';
import { ControlPermissionManager } from './objectSpecifics/controlPermissionManager';
import { DefaultPermissionManager } from './objectSpecifics/defaultPermissionManager';

/**
 * Wrapper over object speific permission managers
 */
export class PermissionManager {
  private permManagers: IPermissionManager[] = [new ControlPermissionManager()];

  private permissionManager: IPermissionManager;
  private defaultPermissionManager: IPermissionManager = new DefaultPermissionManager();

  constructor(filterType: FilterTypes) {
    this.permissionManager = this.permManagers.find((a) => a.IsApplicable(filterType));
    if (!this.permissionManager) {
      this.permissionManager = this.defaultPermissionManager;
    }
  }

  /**
   *Checks if the manager ontains at least one manager which to be applied
   */
  public isApplicable = () => this.permissionManager != null;

  public applyPermissions = (
    obj: { id: number; filters: FilterViewModel[]; permissionFilters: FilterViewModel[] },
    moduleType: ModuleTypes,
    filterType: FilterTypes, //current object i.e Task list => Task | Write => Project
    employee: Employee,
    operationType?: PermissionOperationTypes
  ) => this.permissionManager.applyPermissions(obj, moduleType, filterType, employee, operationType);
}
