import { FilterTypes } from '../../enums/filterTypes';
import { FilterSelectorTypes } from '../../enums/filter/filterSelectorTypes';
import { ObjectTypes } from '../../enums/objectTypes';
import { FilterDateOptions } from '../../enums/filter/filterDateOptions';
import { FilterDateRangeOptions } from '../../enums/filter/filterDateRangeOptions';

export class FilterTypeSelectorViewModel {
  public filterType: FilterTypes;
  public filterSelectorType: FilterSelectorTypes;
  public displayForObjectType: ObjectTypes;
  public filterTypeText: string;
  public dateProperty: FilterDateOptions;
  public dateRangeOptions: FilterDateRangeOptions[];
  public viewOrder: number;
  public isModalReadOnly: boolean;
  public isSelected: boolean;
}
