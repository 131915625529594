<div class="toggle-switch-wrapper">
  <div
    class="toggle-switch-button"
    [class.toggle-switch-button-active]="active === left"
    (click)="onChangeActive($event, left)"
  >
    {{ left }}
  </div>
  <div
    class="toggle-switch-button"
    [class.toggle-switch-button-active]="active === right"
    (click)="onChangeActive($event, right)"
  >
    {{ right }}
  </div>
</div>
