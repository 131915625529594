import {
  ApplicationRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgDropdownComponent } from '../components/common/ngDropdown/ngDropdown.component';
import { DropdownEventsEmitter } from '../events/dropdown.events';
import { WindowEventsEmitter } from '../events/window.events';
import { DynamicComponentsService } from '../services/dynamicComponents.service';
import { isChildOf } from '../utilities/html.utilities';

@Directive({
  selector: '[ngDropdown]',
  host: {
    '(click)': 'onClick($event)',
  },
  exportAs: 'ngDropdown',
})
export class NgDropdownDirective implements OnDestroy {
  @Input() templateRef: TemplateRef<ElementRef<HTMLElement>>;
  @Input() templateOutletContext: object;
  @Input() closeUponSelection: boolean;
  @Input() adaptToElementWidth: boolean;
  @Input() useCustomHeight: number;
  @Input() initialPositionY: 'top' | 'centerTop' | 'centerBottom' | 'bottom' = 'bottom';
  @Input() canOpen: boolean = true;

  @Output() dropdownToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostListener('document:keydown.escape', ['$event']) onEscape() {
    this.visible = false;
  }
  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.updatePosition();
  }

  unVisible() {
    this.visible = false;
  }

  static ngDropdownComponentRef: ComponentRef<NgDropdownComponent>;

  private _clickOutEventSubscription: Subscription = null;
  private _scrollEventSubscription: Subscription = null;
  private _positionChangedSubscription: Subscription = null;
  private _visible: boolean = false;

  constructor(
    private readonly dynamicComponentsService: DynamicComponentsService,
    private readonly appRef: ApplicationRef,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly windowEvents: WindowEventsEmitter,
    private readonly dropdownEventsEmitter: DropdownEventsEmitter
  ) {
    if (!NgDropdownDirective.ngDropdownComponentRef) {
      this.appendToBody();
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromWindowClick();
    this.unsubscribeFromWindowScroll();
    this.unsubscribeFromDropdownPostitonChange();
    const instance = this.ngDropdownComponentInstance;

    if (instance && instance.visible && instance.directiveElementRef === this.elementRef) {
      this.visible = !this.visible;
    }
  }

  get ngDropdownComponentInstance(): NgDropdownComponent {
    return NgDropdownDirective.ngDropdownComponentRef.instance;
  }

  getNgDropdownElement(): HTMLElement {
    return this.getDomElement(NgDropdownDirective.ngDropdownComponentRef);
  }

  updateInstance() {
    if (!this.ngDropdownComponentInstance) {
      return;
    }

    const objToPass = {
      templateRef: this.templateRef,
      templateOutletContext: this.templateOutletContext,
      directiveElementRef: this.elementRef,
    };
    this.ngDropdownComponentInstance.updateDropdownContent(objToPass);
  }

  onClick() {
    if (!this.canOpen) {
      return;
    }
    const { directiveElementRef } = this.ngDropdownComponentInstance;

    if (this.ngDropdownComponentInstance.visible) {
      if (directiveElementRef === this.elementRef) {
        this.visible = false;
      } else {
        this.visible = false;
        this.updateInstance();
        this.updatePosition();
        this.visible = true;
      }
    } else {
      this.updateInstance();
      this.updatePosition();
      this.visible = true;
    }
  }

  onDocumentClick({ target }: Event) {
    if (this.ngDropdownComponentInstance.directiveElementRef !== this.elementRef) {
      return;
    }

    if ((target as HTMLElement).classList.contains('block-screen')) {
      return;
    }

    const targetIsNotPartOfTheDropdown =
      target !== this.getNgDropdownElement() && !isChildOf(this.getNgDropdownElement(), target as HTMLElement);
    const targetIsNotPartOfTheElementRef =
      target !== this.elementRef.nativeElement && !isChildOf(this.elementRef.nativeElement, target as HTMLElement);

    if (targetIsNotPartOfTheDropdown && targetIsNotPartOfTheElementRef) {
      this.visible = false;
    }

    if (!targetIsNotPartOfTheDropdown && this.closeUponSelection) {
      this.visible = false;
    }
  }

  public updatePosition() {
    if (this.ngDropdownComponentInstance.directiveElementRef !== this.elementRef) {
      return;
    }

    const dropdownContainerElemRef = this.ngDropdownComponentInstance.getCurrentDropdownElementRef();
    const { left, top, width, height } = this.elementRef.nativeElement.getBoundingClientRect();

    if (this.adaptToElementWidth) {
      dropdownContainerElemRef.style.width = `${width}px`;
    }else {
      dropdownContainerElemRef.style.width = `unset`;
    }

    const ngDropdownBoundingRect = dropdownContainerElemRef.getBoundingClientRect();

    if (this.initialPositionY === 'bottom') {
      const elementFitsBeneath: boolean = top + height + ngDropdownBoundingRect.height <= window.innerHeight;
      dropdownContainerElemRef.style.top = elementFitsBeneath
        ? `${top + height}px`
        : top - ngDropdownBoundingRect.height >= 0
        ? `${top - ngDropdownBoundingRect.height}px`
        : `${window.innerHeight - ngDropdownBoundingRect.height}px`;
        dropdownContainerElemRef.style.bottom = "";
      // !!! centerBottom and centerTop are used only for dependency item cards in runsheets !!!
    } else if (this.initialPositionY === 'centerBottom') {
      dropdownContainerElemRef.style.top = `${top - 5}px`;
    } else if (this.initialPositionY === 'centerTop') {
      dropdownContainerElemRef.style.top = `${top - 53}px`;
    } else {
      const elementFitsAbove: boolean = top - ngDropdownBoundingRect.height <= window.innerHeight;
      if (elementFitsAbove) {
        dropdownContainerElemRef.style.bottom = `${window.innerHeight - top}px`;
        dropdownContainerElemRef.style.top = "";
      } else {
        dropdownContainerElemRef.style.top = `${top + height}px`;
      }
    }

    const elementFitsOnTheRight: boolean = left + ngDropdownBoundingRect.width <= window.innerWidth;

    if (elementFitsOnTheRight) {
      dropdownContainerElemRef.style.left = `${left}px`;
    } else {
      dropdownContainerElemRef.style.left = `${left + width - ngDropdownBoundingRect.width}px`;
    }
  }

  private set visible(visible: boolean) {
    if (visible) {
      this.ngDropdownComponentInstance.show();
      this.subscribeForWindowClick();
      this.subscribeForWindowScroll();
      this.subscribeForDropdownPositionChange();
      this._visible = true;
    } else {
      this._visible = false;
      this.ngDropdownComponentInstance.hide();
      this.unsubscribeFromWindowClick();
      this.unsubscribeFromWindowScroll();
      this.unsubscribeFromDropdownPostitonChange();
    }

    this.dropdownToggled.emit(visible);
  }

  public get visible(): boolean {
    return this._visible;
  }

  private getDomElement<T>(componentRef: ComponentRef<T>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<T>).rootNodes[0] as HTMLElement;
  }

  private appendToBody() {
    NgDropdownDirective.ngDropdownComponentRef = this.dynamicComponentsService.createComponentElement(NgDropdownComponent, {});

    this.appRef.attachView(NgDropdownDirective.ngDropdownComponentRef.hostView);

    document.body.appendChild(this.getDomElement(NgDropdownDirective.ngDropdownComponentRef));
  }

  private removeFromBody() {
    this.getDomElement(NgDropdownDirective.ngDropdownComponentRef).remove();

    this.appRef.detachView(NgDropdownDirective.ngDropdownComponentRef.hostView);
  }

  private onWinowScroll(e: Event) {
    const targetIsNotPartOfTheDropdown =
      e.target !== this.getNgDropdownElement() && !isChildOf(this.getNgDropdownElement(), e.target as HTMLElement);
    if (targetIsNotPartOfTheDropdown) {
      this.visible = false;
    }
  }

  private subscribeForWindowClick() {
    this._clickOutEventSubscription = this.windowEvents.windowClickEventTriggered$.subscribe((e: Event) => {
      this.onDocumentClick(e);
    });
  }
  private unsubscribeFromWindowClick() {
    if (this._clickOutEventSubscription) {
      this._clickOutEventSubscription.unsubscribe();
    }
  }

  private subscribeForWindowScroll() {
    this._scrollEventSubscription = this.windowEvents.windowScrollEventTriggered$.subscribe((e: Event) => {
      this.onWinowScroll(e);
    });
  }

  private unsubscribeFromWindowScroll() {
    if (this._scrollEventSubscription) {
      this._scrollEventSubscription.unsubscribe();
    }
  }

  // External subscription in case we need to update the position of the dropdown
  private subscribeForDropdownPositionChange() {
    this._positionChangedSubscription = this.dropdownEventsEmitter.dropdownPositionChanged$.subscribe(() => {
      this.updatePosition();
    });
  }
  private unsubscribeFromDropdownPostitonChange() {
    if (this._positionChangedSubscription) {
      this._positionChangedSubscription.unsubscribe();
    }
  }
}
