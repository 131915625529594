<div class="layouts-dropdown-wrapper" [closeUponSelection]="false" ngDropdown [templateRef]="layoutsTemplate">
  <div class="layouts-text" [class.unsaved-view]="!isCurrentlySelectedLayoutSaved">
    <ng-container *ngIf="selectedLayout && isCurrentlySelectedLayoutSaved">{{ selectedLayout.title }}</ng-container>
    <ng-container *ngIf="!isCurrentlySelectedLayoutSaved">{{ T.dashboard.unsaved_view | translate }}</ng-container>
  </div>
  <div class="expand-more-icon d-flex"><i class="material-symbols-outlined expand-icon">expand_more</i></div>
</div>

<ng-template #layoutsTemplate>
  <div class="layouts-container">
    <app-filters-search-input (search)="onLayoutSearch($event)"> </app-filters-search-input>
    <app-filters-entries-information [entriesCount]="layoutsCopy.length"></app-filters-entries-information>
    <div class="layouts-entries custom-scrollbar">
      <app-dropdown-option
        *ngFor="let layout of layoutsCopy"
        [value]="layout"
        [mobile]="true"
        [allowDeselect]="false"
        [selected]="isLayoutSelected(layout)"
        (selectionChange)="onLayoutSelected(layout)"
      >
        <i class="material-symbols-outlined me-2" *ngIf="layout.public">public</i>
        {{layout.title}}
      </app-dropdown-option>
    </div>
    <div class="layouts-footer">
      <div class="manage-views-button-left"
        (click)="openManageSavedViewsModal()"
      >
        {{ T.dashboard.manage_saved_views | translate }}
      </div>
    </div>
  </div>
</ng-template>
