import { Component, ContentChild, ElementRef, OnDestroy } from '@angular/core';
import { TabHeadingDirective } from '../../../directives/tabHeading.directive';
import { TabContentDirective } from '../../../directives/tabContent.directive';
import { Subject } from 'rxjs';
import { Input } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnDestroy {
  @ContentChild(TabHeadingDirective, { static: true }) tabHeadingDirective: TabHeadingDirective;
  @ContentChild(TabContentDirective, { static: true }) tabContentDirective: TabContentDirective;

  @Input() active: boolean;
  @Input() isTabVisible: boolean = true;
  activated: Subject<void> = new Subject();

  constructor(public readonly elementRef: ElementRef<HTMLElement>) {}

  get id(): string {
    return this.elementRef.nativeElement.id;
  }

  activate(): void {
    this.activated.next();
  }

  ngOnDestroy() {
    this.tabHeadingDirective = null;
    this.tabContentDirective = null;
  }
}
