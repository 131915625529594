<div class="tabset-tabs custom-scrollbar" [class.top-border]="useTopBorder" [style.height]="isMobile ? '45px' : '34px'">
  <ng-container *ngIf="useCollapseButton">
    <div class="tabset-tab collapse-button" (click)="toggleCollapseButtonEvent($event)">
      <div class="collapse-details-icon">
        <i class="material-symbols-outlined menu-open-icon">menu_open</i>
      </div>
      <div class="collapse-details-text">
        <span *ngIf="!isCollapsed">Hide Details</span>
        <span *ngIf="isCollapsed">Show Details</span>
      </div>
    </div>
  </ng-container>
  <div
    class="tabset-tab"
    [class.tabset-tab-hidden]="!tab.isTabVisible"
    *ngFor="let tab of tabs"
    (click)="onTabClicked(tab)"
    [class.tabset-tab-active]="tab.active"
  >
    <ng-container *ngIf="tab.isTabVisible">
      <ng-template [ngTemplateOutlet]="tab.tabHeadingDirective?.templateRef"></ng-template>
      <div [style.margin]="isMobile ? '2px 0' : '0px'" class="tabset-tab-active-indicator"></div>
    </ng-container>
  </div>
</div>

<ng-content></ng-content>
