import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { Constants } from '../../../models/constants';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit {
  @Input() doubleWidth = false;
  @Input() fullScreen = false;

  private static readonly fullScreenClass = Constants.modalFullScreenCss;
  private classesList: string[] = [];

  constructor(private readonly elemRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    this.fullScreen = this.isFullScreen();
  }

  setModalFullScreen(state: boolean) {
    const modalContainer = this.elemRef.nativeElement.closest('.modal-dialog');
    if (modalContainer) {
      if (state) {
        this.classesList = this.getClassList();
        modalContainer.classList.forEach((c) => {
          if (c !== 'modal-dialog') {
            modalContainer.classList.remove(c);
          }
        });
        modalContainer.classList.add(ModalComponent.fullScreenClass);
      } else {
        modalContainer.classList.remove(ModalComponent.fullScreenClass);
        this.classesList.forEach((el) => {
          if (el !== ModalComponent.fullScreenClass) {
            modalContainer.classList.add(el);
          }
        });
      }
    }
  }

  // setModalContainerWidth() {
  //   const modalContainer = this.elemRef.nativeElement.closest('modal-container');
  //   (modalContainer as HTMLElement).firstElementChild.classList.add('modal-double-width');
  // }

  // resetModalClasses() {
  //   const modalContainer = this.elemRef.nativeElement.closest('modal-container');
  //   (modalContainer as HTMLElement)?.firstElementChild.classList.remove('modal-double-width');
  //   (modalContainer as HTMLElement)?.firstElementChild.classList.remove('modal-full-screen');
  // }

  private getClassList(): string[] {
    const list: string[] = [];

    const modalContainer = this.elemRef.nativeElement.closest('.modal-dialog');
    if (modalContainer) {
      modalContainer.classList.forEach((c) => {
        if (c !== 'modal-dialog') {
          list.push(c);
        }
      });
    }

    return list;
  }

  private isFullScreen(): boolean {
    return this.getClassList().some((el) => el === ModalComponent.fullScreenClass);
  }
}
