
import { IEnvironment } from 'src/app/modules/shared/models/interfaces/environment.interface';
import * as baseEnv from 'src/environments/enviroment.base';

export const environment: IEnvironment = {
  ...baseEnv.environment,
  ...{
    CONFIGURATION: 'production',
    API_URL: 'https://wetrack-preprod-api.wetrack.com',
    Issuer: 'https://auth-preprod.wetrack.com',
    DOMAIN: 'wetrack-preprod.wetrack.com',
    MOBILE_APP_ID: 'com.wetrack.preprod',
    CLUSTER_NAME:'WT-PREPROD'
  }
};
