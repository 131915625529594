import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Router, NavigationStart, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { T } from 'src/assets/i18n/translation-keys';

import { HeaderEventsEmitter } from '../../../events/header.events';
import { PaginatedCardsComponent } from '../paginated-cards/paginated-cards.component';

@Component({
  selector: 'app-split-pane',
  templateUrl: './split-pane.component.html',
  styleUrls: ['./split-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitPaneComponent implements OnDestroy, OnInit, OnChanges {
  @ViewChild(PaginatedCardsComponent) paginatedCardsComponent: PaginatedCardsComponent;

  @Input() items: object[];
  @Input() itemBindProperty: string = 'id';
  @Input() itemName: string;
  @Input() cardTemplate: TemplateRef<HTMLElement>;
  @Input() loading: boolean;
  @Input() leftSideTemplate: TemplateRef<HTMLElement>;
  @Input() rightSideTemplate: TemplateRef<HTMLElement>;
  @Input() pageChangeSubject: Subject<number> = new Subject();
  @Input() useCustomList: boolean = false;
  @Input() customListComponentTemplate: TemplateRef<HTMLElement>;
  @Input() showHelperAndSort: boolean = true;
  @Input() showSelectedItem: boolean = true;
  @Input() collapsed: boolean = false;
  @Output() expandCollapseHappened: EventEmitter<boolean> = new EventEmitter();

  isDataLoaded: boolean = false;

  editMode: boolean = false;
  subscriptions: Subscription[] = [];
  selectedItem: object;
  selectedItemId: string;
  public readonly T = T;

  constructor(
    public readonly changeDetectorRef: ChangeDetectorRef,
    private readonly headerEventEmitter: HeaderEventsEmitter,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {
    this.subscriptions.push(
      this.headerEventEmitter.editModeToggled$.subscribe((editMode) => {
        this.editMode = editMode;
        this.changeDetectorRef.markForCheck();
      })
    );

    this.subscriptions.push(
      this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => {
        this.editMode = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnInit(): void {
    this.getSelectedItem();
    this.subscriptions.push(
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
        this.getSelectedItem();
      })
    );

    this.isDataLoaded = true;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges && simpleChanges.items && this.selectedItemId) {
      this.selectedItem = this.items.find((i) => i[this.itemBindProperty] == +this.selectedItemId);
      if (this.selectedItem) {
        this.items = this.items.filter((i) => i !== this.selectedItem);
        this.changeDetectorRef.markForCheck();
      }
    }
  }

  onExpandCollapse(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.collapsed = !this.collapsed;
    this.changeDetectorRef.detectChanges();
    this.expandCollapseHappened.emit(this.collapsed);
  }

  getSelectedItem() {
    if (this.route.firstChild) {
      this.subscriptions.push(
        this.route.firstChild.params.subscribe((params) => {
          const id = params['id'];
          this.selectedItemId = id;
          if (id) {
            this.selectedItem = this.items.find((i) => i[this.itemBindProperty] == +id);
            if (this.selectedItem) {
              this.items = this.items.filter((i) => i !== this.selectedItem);
              this.changeDetectorRef.markForCheck();
            }
          }
        })
      );
    }
  }
}
