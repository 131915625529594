import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { RagHelper } from '../../../utilities/rag.utilities';
import { ObjectTypes } from '../../../enums/objectTypes';
import { ObjectSimpleViewModel } from '../../../models/linkedItemObjects/objectSimpleViewModel';
import { RiskRagHelper } from '../../../utilities/risk-rag.utilities copy';
import { Router } from '@angular/router';
import { ObjectSubTypes } from '../../../enums/objectSubTypes.enum';
import { IncidentsManager } from '../../../managers/incidents.manager';
import { UrlService } from '../../../services/url.service';

@Component({
  selector: 'app-simple-card',
  templateUrl: './simple-card.component.html',
  styleUrls: ['./simple-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleCardComponent implements OnInit {
  @Input() cardData: ObjectSimpleViewModel;
  @Input() useDifferentIcon: string;
  @Input() isChecked = false;
  @Input() showCheckbox = true;
  // set to false when you dont want to navigate to deatils page
  @Input() redirectable = true;
  @Input() clickable: boolean = true;
  @Input() isActive = true;
  @Input() subTitle: string;
  @Input() moreBtnTemplate: ElementRef;

  @Output() selected: EventEmitter<boolean> = new EventEmitter();

  public cardIconType: string;
  public readonly objectTypes = ObjectTypes;

  constructor(
    private router: Router,
    private readonly incidentsManager: IncidentsManager,
    private readonly urlService: UrlService
  ) {}

  ngOnInit(): void {
    this.convertObjTypeTpDetailIconType(this.cardData.objectType);
  }

  get ragStatusColor(): string {
    if (this.cardData.objectType == ObjectTypes.Risk) {
      return RiskRagHelper.getRAGColourHexFromValue(this.cardData?.status);
    } else if (this.cardData.objectType == ObjectTypes.IncidentItem) {
      return this.incidentsManager.getColorBySeverity(this.cardData?.status);
    } else {
      return RagHelper.getRAGColourHexFromValue(this.cardData?.status);
    }
  }

  onCheck(isSelected: boolean) {
    if (isSelected) {
      this.selected.emit(true);
    } else {
      this.selected.emit(false);
    }
  }

  convertObjTypeTpDetailIconType(objType: ObjectTypes) {
    if (!this.useDifferentIcon) {
      if (this.cardData.objectSubType) {
        this.cardIconType = ObjectSubTypes[this.cardData.objectSubType];
      } else {
        this.cardIconType = ObjectTypes[objType];
      }
    } else {
      this.cardIconType = this.useDifferentIcon;
    }
  }

  navigateToDetailsPage(): void {
    if (!this.redirectable) {
      if (this.showCheckbox) {
        this.onCheck(!this.isChecked);
      }
      return;
    }
    this.urlService.navigateToObjectDetailsPage(this.cardData.objectType, this.cardData.objectSubType, this.cardData.id);
  }
}
