export interface NgRangeDatesOutput {
  startDate: string | Date;
  endDate: string | Date;
}
export interface NgRangePickerProps {
  startDate: string | Date;
  endDate: string | Date;
  minDate: string | Date;
  maxDate: string | Date;
  localEndDate: string | Date;
  localStartDate: string | Date;
}
export enum NgRangePickerActions {
  StartDateChange,
  EndDateChange,
}

export enum NgMinMaxDateStrategies {
  EmitDate,
  DisableDate,
}

export type NgRangeSelectCallback = (action: NgRangePickerActions, currentState: NgRangePickerProps) => NgRangePickerProps;
