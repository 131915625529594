import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ButtonThemes } from 'src/app/modules/shared/enums/styles/buttonThemes.enum';
import { SortDropdownItem } from 'src/app/modules/shared/models/sortDropdownItem';

@Component({
  selector: 'app-button-sort-rectangle-new',
  templateUrl: './button-sort-rectangle-new.component.html',
  styleUrls: ['./button-sort-rectangle-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSortRectangleNewComponent implements OnChanges {
  @Input() text: string;
  selectedOption: SortDropdownItem;
  @Input() disabled = false;
  @Input() fitToContainer: boolean = false;
  @Input() buttonTheme = ButtonThemes.Primary;
  @Input() dropdownOptions: SortDropdownItem[] = [];

  @Output() sortDropdownItemsChanged = new EventEmitter<SortDropdownItem[]>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dropdownOptions) {
      this.selectedOption = this.dropdownOptions.find((di) => di.default);

      this.changeDetectorRef.markForCheck();
    }
  }

  onDropdownItemSelected(item: SortDropdownItem) {
    this.selectedOption = item;
    item.handler(...item.parameters);

    this.dropdownOptions = this.dropdownOptions.map((di) => {
      if (di.value === item.value) {
        di.default = true;
      } else {
        di.default = false;
      }

      return di;
    });

    this.sortDropdownItemsChanged.emit(this.dropdownOptions);
  }
}
