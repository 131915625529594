import { Account } from '../models/account';
import { AccountConfigurationViewModel } from './bases/AccountConfiguration.viewmodel';

export class BaseConfigurationViewModel {
  url: string;
  urlAlpha: string;
  title: string;
  accountId: number;
  logo: string;
  background: string;
  accountData: Account;
  appMode: string;
  allowedUploadTypes: string;
  maxFileSize: number;
  resourceFolderName: string;
  accountConfiguration: AccountConfigurationViewModel[];
}
