<div class="wrapper" *ngIf="modules">
  <div class="row">
    <div class="col-12">
      <div class="editor-section-wrapper">
        <div class="main-image-wrapper" *ngIf="showProfileImage">
          <app-employee-avatar [employee]="employee"></app-employee-avatar>
        </div>
        <div
          class="editor-wrapper"
          [class.input-field-disabled]="isLoading"
          (keydown.control.enter)="addComment(showButtons)"
        >
          <quill-editor
            [(ngModel)]="commentModel"
            customToolbarPosition="bottom"
            [formats]="allowedFormats"
            [modules]="modules"
            [placeholder]="placeholder"
            (onFocus)="onEditorFocused($event)"
            (onBlur)="onEditorBlur($event)"
            (onEditorChanged)="changedEditor($event)"
            (onEditorCreated)="setFocus($event)"
            (ngModelChange)="onNgModelChange($event)"
            class="d-block"
          >
            <div quill-editor-toolbar [ngStyle]="{ display: isFocused ? 'block' : 'none' }">
              <span [class.align-right]="isMobile" class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button *ngIf="!isMobile" class="ql-strike"></button>
              </span>
              <button class="ql-cncl custom-size normal-weight" *ngIf="showButtons && !showClear" (click)="cancel()">
                {{ T.common.cancel | translate }}
              </button>
              <button
                class="ql-cncl custom-size normal-weight"
                *ngIf="showButtons && showClear && !isLoading"
                (click)="clear()"
              >
                {{ T.common.clear | translate }}
              </button>
              <button
                class="ql-cmnt custom-size mr-4"
                [class.disabled]="disableButton"
                *ngIf="showButtons && !isLoading"
                (click)="addComment(true)"
              >
                {{ T.common.save | translate }}
              </button>
              <button
                *ngIf="!isMobile && !isLoading"
                type="button"
                class="ql-undo"
                (click)="undo()"
                value='<svg viewbox="0 0 18 18">
                                <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
                                <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
                              </svg>'
              >
                <svg viewBox="0 0 18 18">
                  <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
                  <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
                </svg>
              </button>
              <ng-container *ngIf="isLoading">
                <app-button-loader class="custom-size"></app-button-loader>
              </ng-container>
              <button
                *ngIf="!isMobile && !isLoading"
                type="button"
                class="ql-redo"
                (click)="redo()"
                value='<svg viewbox="0 0 18 18">
                                <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
                                <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
                              </svg>'
              >
                <svg viewBox="0 0 18 18">
                  <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
                  <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
                </svg>
              </button>
            </div>
          </quill-editor>
          <ng-container *ngIf="showShortcut && showButtons && commentModel?.length">
            <div *forScreen="'desktop'" class="ms-2 helper-text">
              <b class="me-1">Ctrl + Enter</b> to
              {{ placeholder | lowercase }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
