<div
  *ngIf="message"
  class="modal bd-example-modal-lg"
  tabindex="-1"
  data-backdrop="static"
  role="dialog"
  style="display: flex !important"
>
  <div class="modal-delete-container">
    <div class="modal-delete-header">
      <h6 class="modal-delete-title">{{message.headMessage}}</h6>
    </div>
    <div class="modal-delete-content mb-24" *ngIf="!message.useCaptcha">
      <div class="modal-delete-message" [innerHTML]="message.message"></div>
    </div>
    <div class="modal-delete-content mb-24" *ngIf="message.useCaptcha">
      <div class="modal-delete-message">
        <span [innerHTML]="message.message"></span> {{T.common.enter_following_confirm_code | translate}}
        <span class="confirmation-code">{{captchaNumber}}</span>
      </div>
      <form class="code-input" [formGroup]="form">
        <input
          [autoFocusDirective]="true"
          formControlName="i1"
          type="number"
          id="1"
          #input1
          class="single-digit"
          (keydown)="onKeyDown($event)"
        />
        <input formControlName="i2" type="number" id="2" #input2 class="single-digit" (keydown)="onKeyDown($event)" />
        <input formControlName="i3" type="number" id="3" #input3 class="single-digit" (keydown)="onKeyDown($event)" />
        <input formControlName="i4" type="number" id="4" #input4 class="single-digit" (keydown)="onKeyDown($event)" />
      </form>
    </div>
    <div class="modal-delete-footer">
      <div class="modal-delete-buttons">
        <app-button-rectangle
          class="cancel-button"
          [text]="cancelText"
          (clicked)="cancel()"
          buttonTheme="white"
          size="large"
        ></app-button-rectangle>
        <app-button-rectangle
          class="delete-button"
          *ngIf="message.useCaptcha"
          [disabled]="!isFormValid"
          [text]="confirmText"
          (clicked)="confirm()"
          buttonTheme="danger"
          size="large"
        ></app-button-rectangle>
        <app-button-rectangle
          class="delete-button"
          *ngIf="!message.useCaptcha"
          [text]="confirmText"
          (clicked)="confirm()"
          [buttonTheme]="theme"
          size="large"
        ></app-button-rectangle>
      </div>
    </div>
  </div>
</div>
