<div *ngIf="isAdmin">
  <div class="section-header">
    <span>{{ T.dashboard.info_widget_options | translate }}</span>
  </div>
  <app-dashboard-info-widget-configure
    *ngIf="dashboardInfoWidget"
    [dashboardInfoWidget]="dashboardInfoWidget"
    [modalActionHandlerClick]="modalActionHandlerClick"
    (onWidgetInfoUpdated)="onWidgetInfoUpdated($event)"
  >
  </app-dashboard-info-widget-configure>
</div>
