<div *ngIf="isAdmin && !loading">
  <div style="display: none" class="section-header">
    <span>{{ T.dashboard.countdown_timer | translate }}</span>
  </div>
  <div class="body-wrapper">
    <div class="field-wrapper">
      <span class="date-heading-text">{{ T.dashboard.countdown_title | translate }}</span>
      <div class="cool-input-wrapper">
        <input
          type="text"
          autocomplete="off"
          class="form-control custom-input"
          [(ngModel)]="countdownSettings.countdownTitle"
          name="countdownTitle"
          maxlength="80"
          minlength="4"
        />
        <span class="countdown">{{ countdownSettings.countdownTitle.length + '/80' }}</span>
      </div>
    </div>
    <div class="field-wrapper">
      <span class="date-heading-text">{{ T.dashboard.countdown_time | translate }}</span>
      <app-timepicker [date]="date.countdownDate | dateToISO" (timeChanged)="onCountdownDateChanged($event)">
      </app-timepicker>
    </div>
    <div class="field-wrapper">
      <span class="date-heading-text">{{ T.dashboard.countdown_date | translate }}</span>
      <app-datepicker
        placeholder="Countdown Date*"
        [required]="true"
        [date]="date.countdownDate | dateToISO"
        (dateChanged)="onCountdownDateChanged($event)"
      >
      </app-datepicker>
    </div>
  </div>
</div>
