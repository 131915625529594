import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { Account } from 'src/app/modules/shared/models/account';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { EmployeeSettingViewModel } from 'src/app/modules/shared/viewModels/employeeSettingViewModel';
import { Employee } from 'src/app/modules/shared/models/employee';
import { DashboardWeatherLocationViewModel } from 'src/app/modules/settings/viewModels/dashboardWeatherLocationViewModel';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { AddWidgetLocationModal } from 'src/app/modules/shared/components/modals/location-select/add-widget-location-modal.component';
import { TemperatureOptions } from 'src/app/modules/shared/enums/temperatureOptions';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { IDashboardManager } from '../../../managers/iDashboardManager';
import { EmployeeUtil } from '../../../utilities/employee.utilities';
import { environment } from 'src/environments/environment';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { EnumUtilities } from '../../../utilities/enum.utilities';

@Component({
  selector: 'app-dashboard-sidebar',
  templateUrl: 'dashboard-sidebar.component.html',
  styleUrls: ['dashboard-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardSidebarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  private account: Account;
  private employee: Employee;
  private bsModalRef: BsModalRef;
  private modalConfig = { backdrop: true, ignoreBackdropClick: true };

  @Input() allComponents: any[];
  @Input() module: ModuleTypes;
  @Input() dashboardManager: IDashboardManager;
  isAdmin = false;

  moduleTypes = ModuleTypes;

  dashboardWeatherLocations: DashboardWeatherLocationViewModel[] = [];
  dashboardWeatherLocationToAdd: DashboardWeatherLocationViewModel;
  temperatureOptions = EnumUtilities.items(TemperatureOptions);
  selectedTemperatureOption: { key: TemperatureOptions; value: string } = this.temperatureOptions[0];
  temperatureSetting: EmployeeSettingViewModel;
  showWeatherLocationUpdateButton = false;
  showWeatherOptions: boolean = true;
  public readonly T = T;
  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly accountSettingsService: AccountSettingsService,
    private readonly alertService: AlertService,
    private readonly localisationService: LocalisationService,
    private readonly bsModalService: BsModalService,
    private readonly dashboardEventEmitters: DashboardEventEmitters,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly translateService: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  ngOnInit(): void {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.temperatureOptions = TemperatureOptions.translateItems(this.temperatureOptions, this.translateService);

    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    if (environment.enableExtarnalAPIs == 'false') {
      this.showWeatherOptions = false;
    }

    this.getDashboardWeatherLocations();
    this.getTemperatureOption();
  }

  //#region temperature options

  getTemperatureOption() {
    this.subscriptions.push(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Temperature_Option).subscribe((setting) => {
        this.selectedTemperatureOption = this.temperatureOptions.find((o) => o.key === +setting.value);
        this.temperatureSetting = setting;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  onTemperatureOptionChange(option: { key: TemperatureOptions; value: string }) {
    this.selectedTemperatureOption = Object.assign({}, option);
    this.changeDetectorRef.markForCheck();
    this.updateTemperatureOption();
  }

  updateTemperatureOption() {
    this.temperatureSetting.value = this.selectedTemperatureOption.key.toString();
    this.employeeSettingsService
      .updateEmployeeSetting(EmployeeSettingTypes.Temperature_Option, this.temperatureSetting)
      .subscribe((res) => {
        void this.alertService.success(this.translateService.instant(T.dashboard.temperature_setting_updated));
        this.changeDetectorRef.detectChanges();
        this.dashboardEventEmitters.broadcastTemperatureSettingChanged(this.temperatureSetting);
      });
  }

  //#endregion

  //#region weather locations

  getDashboardWeatherLocations() {
    this.subscriptions.push(
      this.accountSettingsService.getIncidentDashboardWeatherSettings().subscribe((weather) => {
        this.dashboardWeatherLocations = weather == null ? [] : weather;
        this.dashboardWeatherLocations = Object.assign([], this.dashboardWeatherLocations);
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  addWeatherLocation() {
    const initialState = {};
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.bsModalService.show(AddWidgetLocationModal, modalParams);
    this.subscriptions.push(
      this.bsModalRef.content.onSubmit.subscribe((res) => {
        this.onMapLocationUpdated(res);
      })
    );
  }

  onMapLocationUpdated(location: LocationViewModel) {
    const dashboardLocation = new DashboardWeatherLocationViewModel();
    dashboardLocation.location = location;
    dashboardLocation.isDefault = false;
    this.dashboardWeatherLocationToAdd = dashboardLocation;
    this.dashboardWeatherLocations.push(dashboardLocation);
    this.dashboardWeatherLocations = Object.assign([], this.dashboardWeatherLocations);
    this.changeDetectorRef.detectChanges();
    this.updateWeatherLocations();
  }

  onRemoveWeatherLocation(i: number) {
    this.dashboardWeatherLocations.splice(i, 1);
    this.showWeatherLocationUpdateButton = true;
  }

  onMakeDefault(location: DashboardWeatherLocationViewModel) {
    const checked = !location.isDefault;
    if (checked == true) {
      this.dashboardWeatherLocations.forEach((d) => {
        d.isDefault = false;
      });
    }
    location.isDefault = checked;
    this.showWeatherLocationUpdateButton = true;
  }

  updateWeatherLocations() {
    if (this.dashboardWeatherLocations.length > 0 && this.dashboardWeatherLocations.find((d) => d.isDefault === true) === null) {
      this.dashboardWeatherLocations[0].isDefault = true;
    }

    this.accountSettingsService.updateIncidentDashboardWeatherSettings(this.dashboardWeatherLocations).subscribe(() => {
      void this.alertService.success(this.translateService.instant(T.dashboard.weather_locations_updated));
      this.getDashboardWeatherLocations();
      this.showWeatherLocationUpdateButton = false;
      this.dashboardEventEmitters.broadcastLocationSettingsChanged();
    });
  }

  //#endregion

  resetLayout(): void {
    this.dashboardEventEmitters.broadcastGridLayoutResetHappened();
  }
}
