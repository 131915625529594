import { RiskStatusTypes } from '../enums/riskStatusTypes';
import { RiskImpact } from '../enums/riskImpact';
import { RiskTypes } from '../enums/riskTypes';
import { RiskLikelihoods } from 'src/app/modules/shared/enums/risks/riskLikelihoods';
import { ModifiableEntityViewModel } from 'src/app/modules/incidents/viewModels/modifiableEntityViewModel';
import { RiskRAGStatuses } from '../../shared/enums/planning/riskRagStatuses';

export class RiskListViewModel extends ModifiableEntityViewModel {
  rag: number;
  type: RiskTypes;
  refCode: string;
  attachmentsCount: number;
  commentsCount: number;
  status: RiskStatusTypes;
  impact: RiskImpact;
  likelihood: RiskLikelihoods;
  riskStrategyId?: number;
  // startDate: string;
  dueDate: string;
  identifiedById: number;
  createdById: number;
  ownerId: number;
  latitude: number;
  longitude: number;
  locationDetails: string;
  completedActionsCount: number;
  actionsCount: number;
  headlineStatus: string;
  headlineStatusUpdated: string;
  masterRiskId: number;
  riskAssesmentId: number;
  isSelected: boolean;
  accountId: number;
  baselineRAG: RiskRAGStatuses;
  baselineLikelihood: RiskLikelihoods;
  baselineImpact: RiskImpact;
}
