import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FloatingSidebarEventEmitters {
  private sidebarToggled = new Subject<boolean>();

  sidebarToggled$ = this.sidebarToggled.asObservable();

  broadcastSidebarToggled(sidebarToggled: boolean) {
    this.sidebarToggled.next(sidebarToggled);
  }
}
