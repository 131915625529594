import { IPolygonLike } from '../interfaces/polygonLike.interface';
import { ShapePointViewModel } from './shapePointViewModel';

export class ZonePolygon implements IPolygonLike {
  id: number;
  shapePoints: ShapePointViewModel[] = [];

  constructor(_id: number, _shapePoints: ShapePointViewModel[]) {
    this.id = _id;
    this.shapePoints = _shapePoints;
  }

  getLocationPoints(): ShapePointViewModel[] {
    return this.shapePoints;
  }
}
