import { FilterTypes } from '../../enums/filterTypes';
import { ObjectTypes } from '../../enums/objectTypes';
import { FilterSelectorTypes } from '../../enums/filter/filterSelectorTypes';
import { FilterTypeVisibility } from '../../enums/filter/filterTypeVisibility';
import { FilterDateOptions } from '../../enums/filter/filterDateOptions';
import { FilterDateRangeOptions } from '../../enums/filter/filterDateRangeOptions';
import { FilterActionTypes } from '../../enums/filter/filterActionTypes.enum';
import { FilterItem } from './FilterItem.model';

export class FilterViewModel {
  public id: number;
  public filterType: FilterTypes;
  public filterDropdownTitle: string;
  public filterValue: any;
  public filterText: string;
  public displayForGlobalObjectType: ObjectTypes;
  public isPrimary: boolean;
  public isActive: boolean;
  public filterSelectorType: FilterSelectorTypes;
  public colour: string;
  public icon: string;
  public exclude: boolean;
  public isPrimitive: boolean;
  public selected?: boolean;
  public visibility: FilterTypeVisibility;
  public isModalReadOnly: boolean;
  public viewOrder: number;
  public relatedObjectId?: number;
  public dateProperty: FilterDateOptions;
  public dateRangeOption: FilterDateRangeOptions;
  public isExternal: boolean;
  public accountId: number;
  public adittionalItems:FilterItem[];

  public filterAction: FilterActionTypes = FilterActionTypes.None;
}
