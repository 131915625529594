<app-modal>
  <!-- Header -->
  <app-modal-header
    [title]="T.common.edit_item | translate: { item: T.common.location.one | translate }"
    (closed)="confirmClose()"
  ></app-modal-header>

  <!-- Body -->
  <app-modal-body>
    <app-loading *ngIf="loading; else content" [displayLogo]="true">

    </app-loading>

    <ng-template #content>
      <div class="row">
        <div class="col-12">
          <app-filter-dropdown-new
            [label]="T.common.assign_item | translate: { item: localisedZones }"
            [filterType]="filterTypes.Zone"
            [filterByModuleObjectType]="false"
            [appliedFilters]="filters"
            [disableMargin]="true"
            [typeahead]="true"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <app-filter-dropdown-new
            [label]="T.common.assign_item | translate: { item: localisedAreas }"
            [filterType]="filterTypes.Area"
            [filterByModuleObjectType]="false"
            [usePredefinedFilters]="true"
            [predefinedFilters]="applicableAreaFilters"
            [disabled]="applicableAreaFilters.length === 0"
            [appliedFilters]="filters"
            [disableMargin]="true"
            [typeahead]="true"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
      </div>

      <!-- LOCATION -->
      <div class="row mt-4">
        <div class="col-12">
          <app-loading *ngIf="zonesAndAreasLoading; else map"></app-loading>
          <ng-template #map>
            <app-incident-map
              [markerType]="markerType"
              [markerColor]="markerColor"
              [defaultZoom]="20"
              [selectedZones]="selectedZones"
              [selectedAreas]="selectedAreas"
              [showIncidentZonesUponLoad]="true"
              [lat]="latitude"
              [lng]="longitude"
              [editMode]="true"
              [zones]="zones"
              [areas]="areas"
              (onLocationUpdated)="onLocationUpdated($event)"
            >
            </app-incident-map>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </app-modal-body>

  <!-- Footer -->
  <app-modal-footer>
    <app-button [type]="'block'" [text]="T.common.cancel | translate" (clicked)="confirmClose()"></app-button>
    <app-button
      [type]="'block'"
      [text]="T.common.confirm | translate"
      [buttonTheme]="'primary'"
      [disabled]="false"
      (clicked)="handleSubmit()"
    ></app-button>
  </app-modal-footer>
</app-modal>
