import { Injectable } from '@angular/core';
import { ReportItemViewModel } from '../viewModels/reportItemViewModel';
import { ReportStatus } from '../enums/reportStatus.enum';
import { Employee } from '../../shared/models/employee';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { FilterTypes } from '../../shared/enums/filterTypes';
import { TimeZoneService } from '../../shared/services/timeZone.service';
import { ReportSendIntervalTypes } from '../../shared/enums/reports/reportSendIntervalTypes';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ReportUtilityService {
  private employee: Employee | undefined;

  constructor(private readonly authenticationService: AuthenticationService, private readonly timeZoneService: TimeZoneService) {
    this.employee = this.authenticationService.getCurrentEmployee();
  }

  filterSingleReportOut(reports: ReportItemViewModel[], reportId: number): ReportItemViewModel[] {
    return reports.filter((report) => report.id !== reportId);
  }

  filterFavouriteReportItems(reports: ReportItemViewModel[]): ReportItemViewModel[] {
    return reports.filter((report) => report.reportStatus === ReportStatus.Favorite).sort(ReportUtilityService.sortReports);
  }

  filterSuggestedReportItems(reports: ReportItemViewModel[]): ReportItemViewModel[] {
    return reports.filter((report) => report.reportStatus === ReportStatus.Suggested).sort(ReportUtilityService.sortReports);
  }

  filterSharedWithMeReportItems(reports: ReportItemViewModel[]): ReportItemViewModel[] {
    return reports
      .filter(
        (report) => report.isShared && this.isReportSharedWithMe(report)
      )
      .sort(ReportUtilityService.sortReports);
  }

  filterSharedByMeReportItems(reports: ReportItemViewModel[]): ReportItemViewModel[] {
    return reports
      .filter(
        (report) => report.isShared && this.isReportCreatedByMe(report)
      )
      .sort(ReportUtilityService.sortReports);
  }

  filterAllSharedReportItems(reports: ReportItemViewModel[]): ReportItemViewModel[] {
    return reports
      .filter((report) => report.isShared)
      .filter((report) => !this.isReportExpired(report))
      .sort(ReportUtilityService.sortReports);
  }

  filterPastSharedReportItems(reports: ReportItemViewModel[]): ReportItemViewModel[] {
    return reports
      .filter(
        (report) =>
          report.isShared &&
          this.isReportExpired(report) &&
          (this.isReportCreatedByMe(report) || this.isReportSharedWithMe(report))
      )
      .sort(ReportUtilityService.sortReports);
  }

  isReportExpired(report: ReportItemViewModel): boolean {
    const now = this.timeZoneService.getCurrentMomentInLocalisedTimezone();
    let sendEndDate = this.getLastReportDate(report.sendEndDate);

    if (report.sendIntervalType === ReportSendIntervalTypes.Custom_Dates && report.reportItemShareDates?.length) {
      report.reportItemShareDates = report.reportItemShareDates.sort((a, b) => (a.id > b.id ? 1 : -1));

      sendEndDate = this.timeZoneService.getCurrentMomentInLocalisedTimezone(
        report.reportItemShareDates[report.reportItemShareDates.length - 1].sentOn
      );
    }

    return sendEndDate.isBefore(now);
  }

  private isReportCreatedByMe(report: ReportItemViewModel): boolean {
    return report.createdById === this.employee.id;
  }

  private isReportSharedWithMe(report: ReportItemViewModel): boolean {
    return (
      report.filters.filter((filter) => filter.filterType === FilterTypes.Employee && filter.filterValue === this.employee.id)
        .length > 0
    );
  }

  getLastReportDate(sendEndDate: string): Moment {
    return this.timeZoneService.getCurrentMomentInLocalisedTimezone(sendEndDate).add(1, 'day').hour(0).minute(0);
  }

  private static sortReports(a: ReportItemViewModel, b: ReportItemViewModel): number {
    return new Date(b.created).getTime() - new Date(a.created).getTime();
  }
}
