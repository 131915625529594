<div class="fixed-filters-indicators">
  <div *ngIf="showFixedEvent" class="fixed-filters-indicators-icon">
    <app-icon [name]="'event'" [size]="'regular'"></app-icon>
  </div>

  <div *ngIf="showFixedZone" class="fixed-filters-indicators-icon">
    <app-icon [name]="'location_searching'" [size]="'regular'"></app-icon>
  </div>

  <div *ngIf="showFixedChannel" class="fixed-filters-indicators-icon">
    <app-icon [name]="'record_voice_over'" [size]="'regular'"></app-icon>
  </div>
</div>
