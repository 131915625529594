import { RiskActionType } from '../enums/riskActionItemType.enum';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { RiskActionStatuses } from '../enums/riskActionStatusType.enum';

export class RiskActionListViewModel {
  title: string;
  id: number;
  filters: FilterViewModel[];
  permissionFilters: FilterViewModel[];
  type: RiskActionType;
  status: RiskActionStatuses;
  // startDate: string;
  dueDate: string;
  isPinned: boolean;
  description: string;
  refCode: string;
  archived: boolean;
  isSelected: boolean;
}
