<div class="add-item-wrapper" [style.height]="containerHeight + '%'" [style.cursor]="isClickable ? 'pointer' : 'default'">
  <i *ngIf="materialIcon" class="material-symbols-outlined" (click)="addItemClick()">{{ materialIcon }}</i>
  <ng-container *ngIf="!hideIcon">
    <i *ngIf="!icon && !materialIcon; else customIcon" class="material-symbols-outlined" (click)="addItemClick()">add_circle</i>
  </ng-container>
  <ng-template #customIcon>
    <div class="custom-icon" [innerHTML]="icon | safeHtml" (click)="addItemClick()"></div>
  </ng-template>
  <span>{{ message }}</span>
  <a *ngIf="showAddItemBtn" (click)="addItemClick()">{{ T.common.add_item | translate: { item: itemTitle } }}</a>
</div>
