<div class="access-denied-wrapper">
  <div class="access-denied-header">
    {{ header }}
  </div>
  <div class="access-denied-subtext">
    {{ subtext }}
  </div>
  <div class="access-denied-subtext">
    <a [routerLink]="['/v2/mytrack/planning']">Go to MyTrack</a>
  </div>
</div>
