import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, takeUntil } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HttpStatusCodes } from '../enums/httpStatuses';
import { HttpCancelService } from '../services/http-cancel.service';
import { T } from 'src/assets/i18n/translation-keys';
import { GeoLocationService } from '../services/geo-location.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
    private readonly httpCancelService: HttpCancelService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(1),
      takeUntil(this.httpCancelService.onCancelPendingRequests()),
      catchError((error: HttpErrorResponse) => {
        //TODO: Extract the error handling logic and make sure that it is applied FOR INNER WeTrack requests only.

        //PATCH: The following code is a patch for the current issue with the What3Words API.
        if(error.url.toLowerCase().indexOf(GeoLocationService.W3W_API_URL) > -1) {
          return throwError(() => error);
        }

        if (error.status === HttpStatusCodes.Unauthorized) {
          void this.router.navigate(['/v2/unauthorized']);
          return throwError(() => error);
        } else if (error.status === HttpStatusCodes.Forbidden) {
          if (error.url.toLowerCase().indexOf('googleapi') < 0) {
            void this.router.navigate(['/v2/access-denied']);
            return throwError(() => error);
          }
        } else if (error.status === HttpStatusCodes.NotFound) {
          if (error.url.toLowerCase().indexOf('googleapi') < 0) {
            void this.router.navigate(['/v2/not-found']);
          }
        } else if (error.status === HttpStatusCodes.PaymentRequired) {
          void this.router.navigate(['/v2/no-access']);
          this.httpCancelService.cancelPendingRequests();
          return throwError(() => error);
        } else {
          const alertService = this.injector.get(AlertService);
          const translateService = this.injector.get(TranslateService);

          const errorMessage = translateService.instant(T.common.reload_error_message);
          void alertService.error('', errorMessage, undefined, () => location.reload());
          return throwError(() => error);
        }
      })
    );
  }
}
