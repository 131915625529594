import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { SlackAddIntegrationResponse } from '../models/slack/slackAddIntegrationResponse';

@Injectable({
  providedIn: 'root',
})
export class SlackService {
  constructor(
    private http: HttpClient,
    private configuration: Configuration
  ) {
  }

  public GetSlackAuthLink = (): Observable<SlackAddIntegrationResponse> => {
    return this.http.get<SlackAddIntegrationResponse>(this.configuration.buildEndpoint(`Slack/Add`));
  };
}
