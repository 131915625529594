import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { DropdownItem } from '../../../models/dropdownItem';
@Component({
  selector: 'app-dropdown-text-item',
  templateUrl: './dropdown-text-item.component.html',
  styleUrls: ['./dropdown-text-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownTextItemComponent {
  @Input() dropdownItem: DropdownItem;
  @Input() bindProperty: string;
  @Input() selected: boolean;
  @Input() readonly: boolean = true;
  @Input() deletable: boolean = false;

  @Output() deleted: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();
  @Output() dropdownItemSelected: EventEmitter<DropdownItem> = new EventEmitter<DropdownItem>();

  onClick() {
    this.dropdownItemSelected.emit(this.dropdownItem);
  }

  onDelete() {
    this.deleted.next(this.dropdownItem);
  }

  get isSelected(): boolean {
    return this.selected || this.dropdownItem.selected;
  }
}
