import { ObjectTypes } from '../enums/objectTypes';

export class GlobalObjectItemCount {
  public objectType: ObjectTypes;
  public itemCount: number;

  constructor(_objectType: ObjectTypes, _itemCount: number) {
    this.objectType = _objectType;
    this.itemCount = _itemCount;
  }
}
