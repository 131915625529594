import { Component, OnDestroy, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChecklistTemplateViewModel } from 'src/app/modules/shared/models/checklists/checklistTemplateViewModel';
import { ChecklistTemplateService } from 'src/app/modules/shared/services/checklist-template.service';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ReportRefs } from 'src/app/modules/shared/enums/reports/reportRefs';
import { Subscription } from 'rxjs';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { ChecklistItemTemplateService } from 'src/app/modules/shared/services/checklist-item-template.service';
import { ChecklistItemTemplateViewModel } from 'src/app/modules/shared/models/checklists/checklistItemTemplateViewModel';
import { ChecklistResponseTypes } from 'src/app/modules/shared/enums/checklists/checklistEnums';
import { TranslateService } from '@ngx-translate/core';
import { GroupedChecklistItemsViewModel } from 'src/app/modules/shared/models/checklists/groupedChecklistItemsViewModel';


@Component({
  selector: 'app-checklist-items-add-modal',
  templateUrl: 'checklist-items-add.component.html',
  styleUrls: ['checklist-items-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChecklistItemsAddModal implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  objectType: ObjectTypes;

  useSimpleAddModal: boolean = true;
  showFilters: boolean = true;
  checklistTemplates: ChecklistTemplateViewModel[] = [];
  showEmptyChecklists: boolean = true;
  filterSettings: FilterViewModel[] = [];
  onSubmit = new EventEmitter<number[]>();
  selectedChecklistTemplates: ChecklistTemplateViewModel[] = [];
  previouslySelectedChecklistTemplates: GroupedChecklistItemsViewModel[] = [];
  size = 35;
  searchValue: string;
  originalChecklistTemplates: ChecklistTemplateViewModel[] = [];
  checklistItemTemplates: ChecklistItemTemplateViewModel[] = [];
  selectedTemplateItems: ChecklistItemTemplateViewModel[] = [];
  filterTypes = [FilterTypes.Department, FilterTypes.Incident_Category, FilterTypes.Indicator_Category];
  loading = true;
  customMessage: string;
  public reponseTypeOptions: { id: number; value: string }[] = [];
  public openedChecklist: ChecklistTemplateViewModel;
  public readonly T = T;

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly checklistTemplateService: ChecklistTemplateService,
    private readonly checklistItemTemplateService: ChecklistItemTemplateService,
  ) {

  }

  ngOnInit() {
    this.subscriptions.add(this.checklistTemplateService.getList(this.showEmptyChecklists).subscribe(res => {
      this.initChecklistItems();
      this.initChecklistResponseTypes();
      this.checklistTemplates = res.filter(r => r.parentObjectType == this.objectType);
      this.originalChecklistTemplates = JSON.parse(JSON.stringify(res.filter(r => r.parentObjectType == this.objectType))) as ChecklistTemplateViewModel[];
      this.loading = false;

      if(this.filterSettings && this.filterSettings.length) {
        this.onFilterSettingsUpdated(this.filterSettings);
      };

      this.changeDetectorRef.detectChanges();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initChecklistItems(): void {
    this.checklistItemTemplateService.getList().subscribe((res) => {
      this.checklistItemTemplates = res;
      this.changeDetectorRef.markForCheck();
    })
  }

  groupOpenedChecklistItems(template: ChecklistTemplateViewModel) {
    if (template) {
      if (this.openedChecklist && this.openedChecklist.id == template.id) {
        this.openedChecklist = undefined;
        this.selectedTemplateItems = [];
        this.changeDetectorRef.markForCheck();
        return;
      }

      this.openedChecklist = template;
      this.selectedTemplateItems = this.checklistItemTemplates.filter((t) => t.checklistTemplateId === this.openedChecklist.id);

      if (!this.selectedTemplateItems) this.selectedTemplateItems = [];

      this.selectedTemplateItems.sort((a, b) => a.viewOrder - b.viewOrder);
    } else {
      this.openedChecklist = undefined;
      this.selectedTemplateItems = [];
    }

    this.changeDetectorRef.markForCheck();
  }

  onFilterSettingsUpdated(fs: FilterViewModel[]) {
    if(fs && fs.length) {

      if(this.objectType === ObjectTypes.IncidentItem) {
        const departmentFs = fs.filter(f => f.filterType === FilterTypes.Department);

        if(departmentFs && departmentFs.length) {
          const departmentIDs:number[] = [];

          departmentFs.forEach(s => departmentIDs.push(+s.filterValue));

          this.checklistTemplates = this.checklistTemplates.filter(ch => {
            const deps = ch.itemDepartments.filter(d => departmentIDs.indexOf(d.id) >= 0);
            return deps.length > 0;
          });
        }

        const incidentCategoryFS = fs.filter(f => f.filterType === FilterTypes.Incident_Category);
        if(incidentCategoryFS && incidentCategoryFS.length) {
          const incidentCategoryIDs:number[] = [];

          incidentCategoryFS.forEach(s => incidentCategoryIDs.push(+s.filterValue));
          this.checklistTemplates = this.checklistTemplates.filter(function(ch) {
            const categorys = ch.incidentCategories.filter(d => incidentCategoryIDs.indexOf(d.id) >= 0);
            return categorys.length > 0;
          });
        }
      }

      if (this.objectType === ObjectTypes.Indicator) {
        const indicatorCategoryFS = fs.filter(f => f.filterType === FilterTypes.Indicator_Category);
        if(indicatorCategoryFS && indicatorCategoryFS.length) {
          const indicatorCategoryIds:number[] = [];

          indicatorCategoryFS.forEach(s => indicatorCategoryIds.push(+s.filterValue));
          this.checklistTemplates = this.checklistTemplates.filter(function(ch) {
            const categorys = ch.indicatorCategories.filter(d => indicatorCategoryIds.indexOf(d.id) >= 0);
            return categorys.length > 0;
          });
        }
      }

      this.checklistTemplates = Object.assign([], this.checklistTemplates);
      this.changeDetectorRef.markForCheck();
    } else {
      this.checklistTemplates = JSON.parse(JSON.stringify(this.originalChecklistTemplates)) as ChecklistTemplateViewModel[];
    }
  }



  onSave() {
    const checklistTemplateIDs: number[] = [];
    this.selectedChecklistTemplates.forEach(t => {
      if (!this.isSelectedTemplate(t)) {
        checklistTemplateIDs.push(t.id);
      }
    })

    this.onSubmit.next(checklistTemplateIDs);
    this.bsModalRef.hide();
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.onSave();
    }
  }

  get reportRefs(): ReportRefs {
    return ReportRefs.Incident_Module;
  }

  updateSelected(templates: ChecklistTemplateViewModel[]) {
    this.selectedChecklistTemplates = JSON.parse(JSON.stringify(templates)) as ChecklistTemplateViewModel[];
  }

  onSelectChecklist(checklist: ChecklistTemplateViewModel) {
    this.groupOpenedChecklistItems(checklist);
  }

  getCheckResponseType(check: ChecklistItemTemplateViewModel) {
    return this.reponseTypeOptions.find(x => x.id == check.responseType).value;
  }

  isSelectedTemplate(template: ChecklistTemplateViewModel) {
    const matching = this.previouslySelectedChecklistTemplates.find((t) => t.checklistId === template.id);
    return matching !== undefined;
  }

  initChecklistResponseTypes() {
    ChecklistResponseTypes.translatedItems(this.translateService).forEach((item) => {
      if (item.key === ChecklistResponseTypes.Options_Set_1) {
        this.reponseTypeOptions.push({ id: item.key, value: this.translateService.instant(T.control.fail_ensure_pass) });
      } else if (item.key === ChecklistResponseTypes.Options_Set_2) {
        this.reponseTypeOptions.push({ id: item.key, value: this.translateService.instant(T.control.poor_fair_good) });
      } else {
        this.reponseTypeOptions.push({ id: item.key, value: item.value });
      }
    });
  }
}
