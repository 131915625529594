import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-filters-exclude-selected',
  templateUrl: './filters-exclude-selected.component.html',
  styleUrls: ['./filters-exclude-selected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersExcludeSelectedComponent {
  @Input() label: string = this.translateService.instant(T.common.filters_exclude.default_label);
  @Input() checkState: boolean;
  @Input() disabled: boolean;
  @Input() message: string = this.translateService.instant(T.common.filters_exclude.default_message);

  @Output() excluded: EventEmitter<boolean> = new EventEmitter();

  filtersExcluded: boolean = false;

  constructor(private readonly translateService: TranslateService) {}

  onClick(): void {
    this.excluded.emit((this.checkState = !this.checkState));
  }
}
