import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { LinkedItemObjectsViewModel } from '../models/linkedItemObjects/linkedItemObjectsViewModel';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { ObjectTypes } from '../enums/objectTypes';
import { ObjectSimpleViewModel } from '../models/linkedItemObjects/objectSimpleViewModel';
import { ObjectLinkTypes } from '../enums/objectLinkTypes.enum';
import { ObjectLinkDependencyTypes } from '../enums/objectLinkDependencyType.enum';
import { MultipleLinkedItemsObjectViewModel } from '../models/linkedItemObjects/multipleLinkedItemsObjectViewModel';

@Injectable({
  providedIn: 'root',
})
export class LinkedItemsService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`LinkedItemObject/`);
  }

  create(linkedItem: LinkedItemObjectsViewModel, linkType: ObjectLinkTypes) {
    return this.http.post<LinkedItemObjectsViewModel>(`${this.actionUrl}Add/${linkType}`, linkedItem);
  }

  linkMultipleItems(linkedItem: MultipleLinkedItemsObjectViewModel, linkType: ObjectLinkTypes) {
    return this.http.post<LinkedItemObjectsViewModel>(`${this.actionUrl}AddMultiple/${linkType}`, linkedItem);
  }

  getDetails(objectId: number, objectType: number, linkType: ObjectLinkTypes): Observable<LinkedItemObjectsViewModel> {
    return this.http.get<LinkedItemObjectsViewModel>(`${this.actionUrl}Details/${objectId}/${objectType}/${linkType}`);
  }

  getObjectSimpleList(objectType: ObjectTypes, filters: FilterViewModel[]) {
    return this.http.post<ObjectSimpleViewModel[]>(`${this.actionUrl}ObjectSimpleList/${objectType}`, filters);
  }

  getLinkedItems(objectId: number, objectType: ObjectTypes, linkedObjectTypes: ObjectTypes[], linkType: ObjectLinkTypes) {
    return this.http.post<{ objectType: number; linkedItems: ObjectSimpleViewModel[] }[]>(
      `${this.actionUrl}GetLinkedItems/${objectId}/${objectType}/${linkType}`,
      linkedObjectTypes
    );
  }

  removeRelation(objectId: number, objectType: ObjectTypes, removedObjectId: number, removedObjectType: ObjectTypes) {
    return this.http.post<void>(
      `${this.actionUrl}RemoveRelation/${objectId}/${objectType}/${removedObjectId}/${removedObjectType}`,
      null
    );
  }

  removeTaskDependency(taskId: number, removedTaskId: number, dependencyType: ObjectLinkDependencyTypes) {
    return this.http.post<void>(`${this.actionUrl}RemoveTaskDependency/${taskId}/${removedTaskId}/${dependencyType}`, null);
  }
}
