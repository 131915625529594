import { Component, ChangeDetectionStrategy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-information-modal',
  templateUrl: 'information-modal.component.html',
  styleUrls: ['information-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationModalComponent {
  title: string = '';
  message: string = '';
  constructor(public bsModalRef: BsModalRef) {}
}
