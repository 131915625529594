import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToISO',
})
export class DateToISOPipe implements PipeTransform {
  transform(date: Date): string {
    return date.toISOString();
  }
}
