import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { DashboardWeatherLocationViewModel } from 'src/app/modules/settings/viewModels/dashboardWeatherLocationViewModel';
import { TemperatureOptions } from 'src/app/modules/shared/enums/temperatureOptions';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { Account } from 'src/app/modules/shared/models/account';
import { Employee } from 'src/app/modules/shared/models/employee';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { EnumUtilities } from 'src/app/modules/shared/utilities/enum.utilities';
import { EmployeeSettingViewModel } from 'src/app/modules/shared/viewModels/employeeSettingViewModel';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { environment } from 'src/environments/environment';
import { AddWidgetLocationModal } from '../../../modals/location-select/add-widget-location-modal.component';

@Component({
  selector: 'app-weather-widget-dashboard-settings',
  templateUrl: './weather-widget-dashboard-settings.component.html',
  styleUrls: ['./weather-widget-dashboard-settings.component.scss'],
})
export class WeatherWidgetDashboardSettingsComponent implements OnInit {
  @Input() module: ModuleTypes;
  @Input() modalActionHandlerClick: Observable<void>;

  private subscriptions: Subscription[] = [];
  private account: Account;
  private employee: Employee;
  private bsModalRef: BsModalRef;
  private modalConfig = {
    backdrop: true,
    ignoreBackdropClick: true,
  };

  public isAdmin = false;
  public moduleTypes = ModuleTypes;
  public showWeatherOptions = true;
  public temperatureOptions = EnumUtilities.items(TemperatureOptions);
  public selectedTemperatureOption: {
    key: TemperatureOptions;
    value: string;
  } = this.temperatureOptions[0];
  public temperatureSetting: EmployeeSettingViewModel;
  public dashboardWeatherLocations: DashboardWeatherLocationViewModel[] = [];
  public dashboardWeatherLocationToAdd: DashboardWeatherLocationViewModel;
  public showWeatherLocationUpdateButton = false;
  public readonly T = T;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly accountSettingsService: AccountSettingsService,
    private readonly dashboardEventEmitters: DashboardEventEmitters,
    private readonly alertService: AlertService,
    private readonly bsModalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.temperatureOptions = TemperatureOptions.translateItems(this.temperatureOptions, this.translateService);

    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    if (environment.enableExtarnalAPIs == 'false') {
      this.showWeatherOptions = false;
    }

    this.getDashboardWeatherLocations();
    this.getTemperatureOption();
  }

  getDashboardWeatherLocations() {
    this.subscriptions.push(
      this.accountSettingsService.getIncidentDashboardWeatherSettings().subscribe((weather) => {
        this.dashboardWeatherLocations = weather == null ? [] : weather;
        this.dashboardWeatherLocations = Object.assign([], this.dashboardWeatherLocations);
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  onTemperatureOptionChange(option: { key: TemperatureOptions; value: string }) {
    this.selectedTemperatureOption = Object.assign({}, option);
    this.changeDetectorRef.markForCheck();
    this.updateTemperatureOption();
  }

  onRemoveWeatherLocation(i: number) {
    this.dashboardWeatherLocations.splice(i, 1);
    this.showWeatherLocationUpdateButton = true;
  }

  onMakeDefault(location: DashboardWeatherLocationViewModel) {
    const checked = !location.isDefault;
    if (checked == true) {
      this.dashboardWeatherLocations.forEach((d) => {
        d.isDefault = false;
      });
    }
    location.isDefault = checked;
    this.showWeatherLocationUpdateButton = true;
  }

  addWeatherLocation() {
    const initialState = {};
    const modalParams = Object.assign({}, this.modalConfig, { initialState });
    this.bsModalRef = this.bsModalService.show(AddWidgetLocationModal, modalParams);
    this.subscriptions.push(
      this.bsModalRef.content.onSubmit.subscribe((res) => {
        this.onMapLocationUpdated(res);
      })
    );
  }

  updateWeatherLocations() {
    if (this.dashboardWeatherLocations.length > 0 && this.dashboardWeatherLocations.find((d) => d.isDefault === true) === null) {
      this.dashboardWeatherLocations[0].isDefault = true;
    }

    this.accountSettingsService.updateIncidentDashboardWeatherSettings(this.dashboardWeatherLocations).subscribe(() => {
      void this.alertService.success(this.translateService.instant(T.dashboard.weather_locations_updated));
      this.getDashboardWeatherLocations();
      this.showWeatherLocationUpdateButton = false;
      this.dashboardEventEmitters.broadcastLocationSettingsChanged();
    });
  }

  private updateTemperatureOption() {
    this.temperatureSetting.value = this.selectedTemperatureOption.key.toString();
    this.employeeSettingsService
      .updateEmployeeSetting(EmployeeSettingTypes.Temperature_Option, this.temperatureSetting)
      .subscribe((res) => {
        void this.alertService.success(this.translateService.instant(T.dashboard.temperature_setting_updated));
        this.changeDetectorRef.detectChanges();
        this.dashboardEventEmitters.broadcastTemperatureSettingChanged(this.temperatureSetting);
      });
  }

  private onMapLocationUpdated(location: LocationViewModel) {
    const dashboardLocation = new DashboardWeatherLocationViewModel();
    dashboardLocation.location = location;
    dashboardLocation.isDefault = false;

    this.dashboardWeatherLocationToAdd = dashboardLocation;
    this.dashboardWeatherLocations.push(dashboardLocation);
    this.dashboardWeatherLocations = Object.assign([], this.dashboardWeatherLocations);
    this.changeDetectorRef.detectChanges();

    this.updateWeatherLocations();
  }

  private getTemperatureOption() {
    this.subscriptions.push(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Temperature_Option).subscribe((setting) => {
        this.selectedTemperatureOption = this.temperatureOptions.find((o) => o.key === +setting.value);
        this.temperatureSetting = setting;
        this.changeDetectorRef.markForCheck();
      })
    );
  }
}
