import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DocumentViewModel } from '../viewModels/documents/documentViewModel';

@Injectable()
export class AttachmentsEventsEmitter {
  private readonly attachmentDeleted = new Subject<DocumentViewModel>();
  private readonly attachmentDownloaded = new Subject<DocumentViewModel>();

  attachmentDeleted$ = this.attachmentDeleted.asObservable();
  attachmentDownloaded$ = this.attachmentDownloaded.asObservable();

  broadcastAttachmentDeleted(document: DocumentViewModel) {
    this.attachmentDeleted.next(document);
  }

  broadcastAttachmentDownloaded(document: DocumentViewModel) {
    this.attachmentDownloaded.next(document);
  }
}
