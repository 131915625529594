import { Capacitor } from '@capacitor/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { Browser } from '@capacitor/browser';

let authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.Issuer,

  //Stage Deploy: 'https://auth-stage.wetrack.com'
  //IS Deployed 'https://auth.wetrack.com'
  //localhost IS: 'https://localhost:44378',
  // Disable Https
  requireHttps: true, //true,

  // URL of the SPA to redirect the user to after login
  redirectUri: `${window.location.origin}/signin-oidc`,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: environment.ClientId,

  //Live: 'com.wetrack.frontend.spa.client.live'
  //Development 'com.wetrack.frontend.spa.client'

  silentRefreshTimeout: 0.3,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  silentRefreshShowIFrame: true,
  postLogoutRedirectUri: `${window.location.origin}/signout-oidc`,
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: [
    'openid',
    'email',
    'accountid',
    'employeeid',

    'com.wetrack.backend.web.api:read',
    'com.wetrack.backend.web.api:write',
  ].join(' '),
};

if (Capacitor.getPlatform() !== 'web') {
  authConfig = {
    // Url of the Identity Provider
    issuer: environment.Issuer,

    //Stage Deploy: 'https://auth-stage.wetrack.com'
    //IS Deployed 'https://auth.wetrack.com'
    //localhost IS: 'https://localhost:44378',
    // Disable Https
    requireHttps: true,

    // URL of the SPA to redirect the user to after login
    //  redirectUri: `${window.location.origin}/signin-oidc`,
    //  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    //  postLogoutRedirectUri: `${window.location.origin}/signout-oidc`,

    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: environment.ClientId,

    //Live: 'com.wetrack.frontend.spa.client.live'
    //Development 'com.wetrack.frontend.spa.client'

    //## new stuffs for capacitor

    //responseType: environment.resonseType,
    //responseType: 'code',

    //responseType:'implicit',
    redirectUri: `${environment.MOBILE_APP_ID}:/signin-oidc`,
    postLogoutRedirectUri: `${environment.MOBILE_APP_ID}:/signout-oidc`,
    silentRefreshRedirectUri: `${environment.MOBILE_APP_ID}:/silent-refresh.html`,
    // useSilentRefresh: true,
    // timeoutFactor: 0.75,
    // sessionChecksEnabled: true,
    // showDebugInformation: true,
    // clearHashAfterLogin: false,
    // nonceStateSeparator : 'semicolon',

    //## new stuffs

    silentRefreshTimeout: 0.3,

    silentRefreshShowIFrame: true,

    openUri: async (uri: string) => {
      await Browser.open({ url: uri });
    },
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: [
      'openid',
      'email',
      'employeeid',
      'accountid',
      'com.wetrack.backend.web.api:read',
      'com.wetrack.backend.web.api:write',
    ].join(' '),
  };
}

authConfig.logoutUrl = `${authConfig.issuer}/Account/Logout/?client_id=${authConfig.clientId}&returnTo=${encodeURIComponent(
  authConfig.redirectUri
)}`;

authConfig.revocationEndpoint = `${authConfig.issuer}/connect/revocation`

export { authConfig };
