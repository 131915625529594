import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(date: string): string {
    if (moment(date).isValid()) {
      const seconds = Math.floor((+new Date() - +new Date(date)) / 1000);

        if (seconds < 29) {
          return this.translateService.instant(T.common.time_ago.just_now);
        }

      const intervals = {
        year: 31536000,
        month_ago: 2592000,
        days_ago: 86400,
        hour_ago: 3600,
        minute_ago: 60,
        seconds_ago: 1
      };

      let counter: number;

      for (const i in intervals) {
        counter = Math.round(seconds / intervals[i]);

        if (counter > 0) {
          if(i.toString() === 'year') return this.translateService.instant(T.common.time_ago.more_than_1_year_ago);

          return counter === 1 ?
            `${this.translateService.instant(T.common.time_ago[i].one)}`
            : `${this.translateService.instant(T.common.time_ago[i].many, {count: counter })}`
        }
      }
    }

    return null;
  }
}
