import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IMapViewUnitItem, MapViewUnit } from 'src/app/modules/shared/enums/maps/mapViewUnit';

@Component({
  selector: 'app-map-views-group-button',
  templateUrl: './map-views-group-button.component.html',
  styleUrls: ['./map-views-group-button.component.scss'],
})
export class MapViewsGroupButtonComponent implements OnInit, OnDestroy {
  @Input() map: google.maps.Map = null;
  @Input() drawAsMapOverlay = false;
  @Output() mapViewUnitSelected = new EventEmitter<MapViewUnit>();

  private mapTypeIdChangedListener: google.maps.MapsEventListener;

  public scaleUnits: IMapViewUnitItem[];
  public currentlySelected: MapViewUnit;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.initScaleUnits();
    this.initEvents();
    this.currentlySelected = this.scaleUnits.find((u) => u.key === MapViewUnit.FOCUS).key;
  }

  ngOnDestroy() {
    if(this.mapTypeIdChangedListener) {
      this.mapTypeIdChangedListener.remove();
    }
  }

  public onScaleUnitSelected(scaleUnit: IMapViewUnitItem): void {
    this.currentlySelected = scaleUnit.key;

    if (this.map) {
      switch (this.currentlySelected) {
        case MapViewUnit.MAP:
          this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
          break;
        case MapViewUnit.SATELLITE:
          this.map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
          break;
        case MapViewUnit.TERRAIN:
          this.map.setMapTypeId(google.maps.MapTypeId.TERRAIN);
          break;
        case MapViewUnit.FOCUS:
          this.map.setMapTypeId('focus');
          break;
        default:
          break;
      }
    }

    this.changeDetector.detectChanges();
    this.mapViewUnitSelected.emit(scaleUnit.key);
  }

  public setActiveScaleUnit(scaleUnit: MapViewUnit) {
    this.currentlySelected = scaleUnit;
    this.changeDetector.detectChanges();
  }

  get scaleUnitWidth(): string {
    return `${100 / this.scaleUnits.length}%`;
  }

  private initScaleUnits(): void {
    this.scaleUnits = MapViewUnit.items();
  }

  private initEvents(): void {
    this.mapTypeIdChangedListener = this.map.addListener('maptypeid_changed', () => {
      if(this.currentlySelected !== this.map.getMapTypeId()) {
        this.currentlySelected = MapViewUnit.items().find(i => i.key === this.map.getMapTypeId()).key;
        this.changeDetector.detectChanges();
      }
    });
  }
}
