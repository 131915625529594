import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() isActive = false;
  @Input() isFocused = false;
  @Input() disabled = false;
  @Input() label: string;
  @Input() value: string;
  @Input() counter: number;
  // String to be used as a maximum width for the select (default the select will be as wide as the label)
  @Input() customSelectSpacer: string;

  @Output() clicked = new EventEmitter<MouseEvent>();

  @HostBinding('class.active') get activeState(): boolean {
    return this.isActive;
  }
  @HostBinding('class.focused') get focusedState(): boolean {
    return this.isFocused;
  }

  public onClick(event: MouseEvent): void {
    this.clicked.emit(event);
    this.toggleSelect();
  }

  toggleSelect() {
    this.isFocused = !this.isFocused;
    this.isFocused = !this.isFocused;
  }

  public get widthSpacer(): string {
    return this.customSelectSpacer ? this.customSelectSpacer : this.label;
  }
}
