import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { AccordionItemDetailsDirective } from '../../../directives/accordion/accordion-item-details.directive';
import { Constants } from '../../../models/constants';

@Component({
  selector: 'app-accordion-details',
  templateUrl: './accordion-details.component.html',
  styleUrls: ['./accordion-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('contentExpansion', [
      state('expanded', style({ height: '*', opacity: 1, visibility: 'visible' })),
      state('collapsed', style({ height: '0px', opacity: 0, visibility: 'hidden' })),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(.37,1.04,.68,.98)')),
    ]),
  ],
})
export class AccordionDetailsComponent implements AfterViewInit {
  @ContentChildren(AccordionItemDetailsDirective) items: QueryList<AccordionItemDetailsDirective>;
  @Output() onItemIndexClicked = new EventEmitter<number>();
  @Input() collapsing: boolean = true;
  @Input() sideAccordion: boolean = false;
  expanded = new Set<number>();

  constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.items.forEach((x) => {
      x.expanded ? this.expanded.add(this.findQueryListIndex(x)) : null;
    });

    this.changeDetectorRef.markForCheck();
  }

  findQueryListIndex(item: AccordionItemDetailsDirective) {
    return this.items.toArray().findIndex((x) => x.title === item.title);
  }

  getToggleState = (index: number) => {
    return this.toggleState.bind(this, index);
  };

  getItemContent() {
    const expandedIndex = [...this.expanded][0];
    return this.items.filter((el, i) => i === expandedIndex)[0];
  }

  toggleState = (index: number) => {
    if (this.expanded.has(index)) {
      if (this.sideAccordion && this.collapsing) {
        return;
      }

      this.expanded.delete(index);
    } else {
      if (this.collapsing) {
        this.expanded.clear();
      }
      this.expanded.add(index);
    }

    this.onItemIndexClicked.emit(index);
  };

  get isMobile() {
    return window.innerWidth <= Constants.sm;
  }
}
