<app-modal>
  <app-modal-header [title]="headerText" [showDelete]="showDelete" (closed)="onClose()" (deleted)="onDeleteClicked()">
  </app-modal-header>

  <app-modal-body>
    <div class="row col-row-space">
      <div class="col-12">
        <app-text-field
          [(ngModel)]="title"
          [placeholder]="T.defaultLocalizations.title.one | translate"
          [maxLength]="maxLength"
          [autofocus]="true"
        ></app-text-field>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <div class="action-buttons">
      <app-button-rectangle
        *ngIf="showDelete"
        size="large"
        [text]="T.common.delete | translate"
        buttonTheme="danger"
        (clicked)="onDeleteClicked()"
      ></app-button-rectangle>
      <app-button-rectangle
        class="button"
        [buttonTheme]="'white'"
        [size]="'large'"
        [text]="T.common.cancel | translate"
        (clicked)="onClose()"
      ></app-button-rectangle>
      <app-button-rectangle
        class="button"
        [buttonTheme]="'primary'"
        [size]="'large'"
        [text]="T.common.save | translate"
        [disabled]="buttonDisabled"
        (clicked)="onSaveAndExit()"
      ></app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>
