import { ReplaceUnderscoresPipe } from "../pipes/replace-underscores.pipe";

export enum AccountVenueType {
  Stadium = 1,
  Arena = 2,
  Conference_Centre = 3,
  Convention_Centre = 4,
  Performing_Arts_Centre = 5,
  University = 6,
  Casino = 7,
  Hotel = 8,
  Corporate = 9,
}

export type VenueTypeOption = {
  id: AccountVenueType,
  value: string,
}

export function convertAccountVenueTypesToAnArray(): VenueTypeOption[] {
  const venueTypesOptions: VenueTypeOption[] = [];
  const venueTypeValues = Object.keys(AccountVenueType).filter((v) => isNaN(Number(v)));
    const replaceUnderscoresPipe = new ReplaceUnderscoresPipe()

    venueTypeValues.forEach((venueType) => {
      const optionObj: VenueTypeOption = { id: AccountVenueType[venueType], value: replaceUnderscoresPipe.transform(venueType) };
      venueTypesOptions.push(optionObj);
    })

  return venueTypesOptions;
}
