export enum CardTypes {
  IncidentDetails = 'incidentDetails',
  Settings = 'settings',
  IncidentMap = 'incidentMap',
  IncidentDashboard = 'incidentDashboard',
  RunsheetDetails = 'runsheetDetails',
  RunsheetMap = 'runsheetMap',
  RunsheetMapDetails = 'runsheetMapDetails',

  //Rebuild
  Project = 'Project',
  Task_Group = 'Task_Group',
  Task = 'Task',
  Sub_Task = 'Sub_Task',
  Department = 'Department',
  Department_Category = 'Department Category',

  Risk = 'Risk',
  Issue = 'Issue',
  Opportunity = 'Opportunity',
  Risk_Action = 'Risk_Action',

  History_Feed = 'History_Feed',

  // Sustainability
  Indicator = 'Indicator',
  Indicator_Template = 'Indicator_Template',
  Milestone = 'Milestone',
}
