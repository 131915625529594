import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ConfirmationMessage, ConfirmationService } from '../../../services/confirmation.service';
import { generateRandomNumber } from '../../../utilities/number.utilities';
import { ConfirmModalThemes } from '../../../types/confirmModalTheme';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirmation-modal.html',
  styleUrls: ['confirmation-modal.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;

  form: UntypedFormGroup;

  message: ConfirmationMessage;
  confirmMessage: string;
  captchaNumber: string;
  userCaptchaNumber: string = '';
  cancelText: string = 'Cancel';
  confirmText: string = 'Confirm';
  theme: ConfirmModalThemes = 'primary';
  public readonly T = T;

  constructor(
    private readonly confirmationService: ConfirmationService,
    private fb: UntypedFormBuilder,
    private renderer: Renderer2,
    private readonly translateService: TranslateService
  ) {}

  @HostListener('document:keydown', ['$event']) escapeAndEnterHandler(event: KeyboardEvent) {
    if (this.message) {
      if (event.key === 'Enter') {
        this.confirm();
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        this.cancel();
      }
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      i1: ['', [Validators.required]],
      i2: ['', [Validators.required]],
      i3: ['', [Validators.required]],
      i4: ['', [Validators.required]],
    });

    this.confirmationService.getMessage().subscribe((message) => {
      this.message = message;
      this.theme = message?.theme ?? 'primary';
      this.cancelText = message?.cancelText ?? this.translateService.instant(T.common.cancel);
      this.confirmText = message?.confirmText ?? this.translateService.instant(T.common.confirm);

      if (this.message !== null && this.message.useCaptcha) {
        this.captchaNumber = generateRandomNumber(4).toString();
      }
    });
  }
  onKeyDown(e: KeyboardEvent) {
    const currentTarged = e.target as HTMLInputElement;
    const currentId = currentTarged.id;
    const previousElemId = `input${+currentId - 1}`;
    const previousElemRef = this[previousElemId] as ElementRef;
    const nextElem = this.renderer.nextSibling(currentTarged);
    const isDelete = e.key === 'Backspace' || e.key === 'Delete';
    const currentFormControlName = this.form.get(`i${currentId}`);

    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      this.confirm();
      return false;
    }

    // This will allow only 1 digit
    // Will move to previos input when user delete
    // Will move to next input when use enter digit
    if (currentTarged.value.length === 0) {
      if (isDelete) {
        if (previousElemRef) {
          previousElemRef.nativeElement?.focus();
          return;
        }
      } else {
        if (nextElem !== null) {
          currentFormControlName.setValue(`${e.key}`);
          nextElem?.focus();
          return false;
        }
      }
    } else if (currentTarged.value.length > 0) {
      if (!isDelete) {
        currentFormControlName.setValue(`${e.key}`);
        if (nextElem === null) {
          nextElem?.focus();
        }
        return false;
      }
    }
  }

  confirm() {
    if (!this.isFormValid) {
      return;
    }
    this.form.reset();
    this.message.confirm();
  }

  cancel() {
    this.form.reset();
    this.message.cancel();
  }

  get isFormValid() {
    if (this.captchaNumber && this.message.useCaptcha) {
      return (
        this.form.controls.i1.value == this.captchaNumber[0] &&
        this.form.controls.i2.value == this.captchaNumber[1] &&
        this.form.controls.i3.value == this.captchaNumber[2] &&
        this.form.controls.i4.value == this.captchaNumber[3]
      );
    } else {
      return true;
    }
  }
}
