import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Constants } from 'src/app/modules/shared/models/constants';

@Component({
  selector: 'app-w3w-grid-button',
  templateUrl: './w3w-grid-button.component.html',
  styleUrls: ['./w3w-grid-button.component.scss'],
})
export class W3wGridButtonComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', { static: true }) controlWrapper: ElementRef<HTMLElement>;

  @Input() title: string = 'Click to toggle "What3Word grid service"';
  @Input() map: google.maps.Map;
  @Output() clicked = new EventEmitter<boolean>();
  @Output() gridToggled = new EventEmitter<boolean>();

  private isMobile = false;

  //The minimum zoom level which needs to be applied in order the control to become available.
  private readonly MIN_ZOOM = 18;

  //A reference to Google maps listener for changing the zoom of the map.
  private zoomChangedListener: google.maps.MapsEventListener;

  //A reference of Google maps listenr for finishing of map dragging.
  private dragEndListener: google.maps.MapsEventListener;

  /**
   * Indicator whether the control button is clicked or not!
   * The control button can ONLY be clicked once the 'controlButtonAvailable' is true
   */
  private w3wGridButtonClicked = false;

  /**
   * Indicator whether the control button is enabled (can be clicked) or disabled (grayed out)
   * This behaviour is controlled by the zoom level of the map and becomes available when
   * the zoom level of the map is more than the MIN_ZOOM parameter. Once this condition is satisfied
   * the control becomes available.
   *
   * It is completely managed by the callback of the map zoom_change event listener.
   *
   * NOTE!
   * !This is not the same as the button being clicked or not!
   */
  public controlButtonAvailable = false;

  /**
   * Indicator whether the grid is visible on the map or not
   * The state of this parameter depends on whether the MIN_ZOOM level is satisfied
   * and the control button is clicked.
   *
   */
  public w3wGridVisible = false;

  //The bounds of the current grid data layer which represents 3x3 square grid.
  private gridDataLayerLatLngBounds: google.maps.LatLngBounds = null;

  /**
   * The 3x3 grid data layer which is the base of the W3W idea.
   *
   * As the world is too big in order to load the 3x3 coordinates
   * of the whole planet we load only a chunc of it based on the
   * current view port.
   *
   * With other worlds when the map is zoomed more than MIN_ZOOM value
   * the grid layer covers the view port area.
   */
  private gridDataLayer: google.maps.Data = null;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < Constants.xs;
    this.controlButtonAvailable = this.map.getZoom() > this.MIN_ZOOM;
    this.initMapEvents();

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    if(this.zoomChangedListener) {
      this.zoomChangedListener.remove();
    }

    if(this.dragEndListener) {
      this.dragEndListener.remove();
    }
  }

  onClick(_$event: MouseEvent) {
    if(this.controlButtonAvailable) {
      this.w3wGridButtonClicked = !this.w3wGridButtonClicked;
      if (this.w3wGridButtonClicked) {
        if (this.shouldLoadNewW3WGrid()) {
          this.loadW3WGrid();
        }

        this.w3wGridVisible = this.map.getZoom() > this.MIN_ZOOM;
        this.toggleW3WGrid();
      } else {
        this.hideW3WDGrid();
      }

      this.clicked.emit(this.w3wGridButtonClicked);
    }
  }

  public get isbuttonToggled(): boolean {
    return this.w3wGridButtonClicked;
  }

  private shouldLoadNewW3WGrid(): boolean {
    let flag = true;

    const northEast = this.map.getBounds().getNorthEast();
    const southWest = this.map.getBounds().getSouthWest();
    if (
      this.gridDataLayerLatLngBounds &&
      this.gridDataLayerLatLngBounds.contains(northEast) &&
      this.gridDataLayerLatLngBounds.contains(southWest)
    ) {
      flag = false;
    }

    return flag;
  }

  /**
   * Loads the 3x3 grid layer which is a part of the W3W service.
   * This method should be called ONLY after the service restriction
   * for availability has been met and also it is recommended to always
   * check if the current map viewport is a subset of "gridDataLayerLatLngBounds"
   * in order to minimize the request for new data from the w3w service endpoint.
   */
  private loadW3WGrid() {
    if (this.gridDataLayer) {
      this.gridDataLayer.setMap(null);
    }

    this.gridDataLayer = new google.maps.Data();

    const latLng = this.map.getCenter();
    const northLatLng = google.maps.geometry.spherical.computeOffset(latLng, 500, 0);
    const eastLatLng = google.maps.geometry.spherical.computeOffset(latLng, 500, 90);
    const southLatLng = google.maps.geometry.spherical.computeOffset(latLng, 500, 180);
    const westLatLng = google.maps.geometry.spherical.computeOffset(latLng, 500, 270);

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(northLatLng);
    bounds.extend(eastLatLng);
    bounds.extend(southLatLng);
    bounds.extend(westLatLng);

    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    const url = `https://api.what3words.com/v3/grid-section?key=H5RBLMXW&bounding-box=${sw.lat()},${sw.lng()},${ne.lat()},${ne.lng()}&format=geojson`;
    this.gridDataLayer.loadGeoJson(url);
    this.gridDataLayer.setStyle({
      strokeColor: '#999999',
      strokeWeight: 0.5,
    });

    this.gridDataLayerLatLngBounds = bounds;
  }

  private showW3WGrid(): void {
    if (this.gridDataLayer) {
      if (this.shouldLoadNewW3WGrid()) {
        this.loadW3WGrid();
      }

      this.gridDataLayer.setMap(this.map);
    }
  }

  private hideW3WDGrid(): void {
    if (this.gridDataLayer) {
      this.gridDataLayer.setMap(null);
      this.w3wGridVisible = false;
    }
  }

  private toggleW3WGrid(): void {
    if (this.w3wGridVisible) {
      this.showW3WGrid();
    } else {
      this.hideW3WDGrid();
    }
  }

  private initMapEvents() {
    this.zoomChangedListener = this.map.addListener('zoom_changed', () => {
      this.controlButtonAvailable = this.map.getZoom() > this.MIN_ZOOM;

      if (this.w3wGridButtonClicked) {
        this.w3wGridVisible = this.map.getZoom() > this.MIN_ZOOM;
        this.toggleW3WGrid();
      } else {
        this.hideW3WDGrid();
      }

      this.changeDetectorRef.detectChanges();
      this.gridToggled.emit(this.w3wGridVisible);
    });
    this.dragEndListener = this.map.addListener('dragend', () => {
      if (this.w3wGridButtonClicked) {
        this.toggleW3WGrid();
      } else {
        this.hideW3WDGrid();
      }
    });
  }
}
