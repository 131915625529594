import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from 'src/app/app.constants';
import { StateService } from 'src/app/modules/planning/services/state.service';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { IApiService } from 'src/app/modules/shared/interfaces/iHttpService';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { LightFilterUpdateModel } from 'src/app/modules/shared/models/filter/LightFilterUpdateModel';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { JobDetailsItemViewModel } from '../../viewModels/jobs/jobDetailsItemViewModel';
import { JobItemViewModel } from '../../viewModels/jobs/jobItemViewModel';
import { JobKanbanUpdateViewModel } from '../../viewModels/jobs/jobKanbanUpdateViewModel';
import { JobListItemViewModel } from '../../viewModels/jobs/jobListItemViewModel';
import { ModifiableEntityViewModel } from '../../viewModels/modifiableEntityViewModel';
import { JobSummaryViewModel } from '../../models/jobSummaryViewModel';
import { JobDueWidgetViewModel } from '../../models/jobDueWidgetViewModel';
import { AssociatedJobToRiskActionViewModel } from '../../viewModels/jobs/AssociatedJobToRiskActionViewModel';
import { JobsUtilities } from '../../utilities/job-utilities.util';

@Injectable({
  providedIn: 'root',
})
export class JobsService extends StateService<JobListItemViewModel> implements IApiService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    super();

    this.actionUrl = this.configuration.buildEndpoint(`Job/`);
  }
  updateLight(item: LightFilterUpdateModel) {
    throw new Error('Method not implemented.');
  }
  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly?: boolean
  ): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  public list(filters: FilterViewModel[]): Observable<JobListItemViewModel[]> {
    return this.http.post<JobListItemViewModel[]>(`${this.actionUrl}GetList`, filters);
  }

  public listWithPercentage(filters: FilterViewModel[]): Observable<JobListItemViewModel[]> {
    return this.http.post<JobListItemViewModel[]>(`${this.actionUrl}GetList`, filters).pipe(
      map(jobs => {
        const jobsByType = jobs.reduce((acc, job) => {
          const category = job.jobTypeId;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(job);
          return acc;
        }, {} as Record<string, JobListItemViewModel[]>);

        const completionPercentagesByCategory = Object.keys(jobsByType).reduce((acc, type) => {
          const jobsInType = jobsByType[type];
          const totalJobsInCategory = jobsInType.length;

          const completedJobsInCategory = jobsInType.filter(job => job.status === 3).length;
          acc[type] = totalJobsInCategory > 0 ? Math.round((completedJobsInCategory / totalJobsInCategory) * 100) : 0;
          return acc;
        }, {} as Record<string, number>);

        return jobs.map(job => ({
          ...job,
          percentageComplete: completionPercentagesByCategory[job.jobTypeId],
          statusColor: JobsUtilities.GetJobStatusColor(job.status),
        }));
      })
    );
  }

  public details(jobId: number): Observable<JobDetailsItemViewModel> {
    return this.http.get<JobDetailsItemViewModel>(`${this.actionUrl}GetDetails/${jobId}`);
  }

  public update(job: JobItemViewModel): Observable<JobItemViewModel> {
    return this.http.post<JobItemViewModel>(`${this.actionUrl}Update`, job);
  }

  public updateKanban(job: JobKanbanUpdateViewModel): Observable<JobKanbanUpdateViewModel> {
    return this.http.post<JobKanbanUpdateViewModel>(`${this.actionUrl}UpdateKanban`, job);
  }

  public delete(jobId: number): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}Delete/${jobId}`);
  }

  archive(jobId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${jobId}/${archive}`, null).pipe(
      map((x) => {
        this.deleteFromList(jobId);
        return x;
      })
    );
  }

  bulkArchive(jobIds: number[]) {
    return this.http.post(`${this.actionUrl}BulkArchive`, jobIds);
  }

  public add(job: JobDetailsItemViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(this.actionUrl + 'Add', job, this.authenticationService.getHttpOptions());
  }

  public getSummary(): Observable<JobSummaryViewModel> {
    return this.http.get<JobSummaryViewModel>(`${this.actionUrl}getsummary`);
  }

  public getJobsDueWidget(filters: FilterViewModel[]): Observable<JobDueWidgetViewModel> {
    return this.http.post<JobDueWidgetViewModel>(`${this.actionUrl}GetJobsDueWidget`, filters);
  }

  public getAssociatedRiskActions(riskId: number): Observable<AssociatedJobToRiskActionViewModel[]>{
    return this.http.post<AssociatedJobToRiskActionViewModel[]>(`${this.actionUrl}GetListForRisk/${riskId}`, null);
  }

  updateLozenges(incident: JobListItemViewModel): Observable<any> {
    // TODO Add this to the BE
    return of('Must add logic in the BE');
    // return this.http.post(`${this.actionUrl}UpdateLozenges/${employeeId}`, incident);
  }
}
