<div class="quick-add-wrapper">
  <div class="quick-add-desktop-input-wrapper">
    <div class="quick-add-desktop-input">
      <div class="quick-add-desktop-input-icon">
        <div class="responsive-flex-wrapper">
          <i class="material-symbols-outlined">receipt</i>
        </div>
      </div>
      <input
        #quickInput
        type="text"
        class="quick-add-input-field"
        [formControl]="quickFieldFormControl"
        [style.padding]="quickInput.value ? '0 36px' : '0 0 0 36px'"
        [maxLength]="80"
        [placeholder]="placeholder"
        (keydown.enter)="onEnter()"
      />
      <div *ngIf="quickInput.value" class="quick-add-desktop-input-clear" (click)="onClearInput(quickInput)">
        <div class="responsive-flex-wrapper">
          <i class="material-symbols-outlined">close</i>
        </div>
      </div>
    </div>
    <div class="quick-add-desktop-suggestion">
      <span *ngIf="quickInput.value">Press Enter to add</span>
    </div>
    <!-- <div class="quick-add-desktop-action" *ngIf="quickInput.value" (click)="onAddDetails()">Add Details</div> -->
  </div>
</div>
