import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from '../../../enums/objectTypes';
import { ObjectEventEmitters } from '../../../events/object.events';
import { Account } from '../../../models/account';
import { Employee } from '../../../models/employee';
import { EmployeeCustomFilterViewModel } from '../../../models/filter/employeeCustomFilterViewModel';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AlertService } from '../../../services/alert.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CustomFiltersService } from '../../../services/custom-filters.service';

@Component({
  selector: 'app-filters-favorite',
  templateUrl: './filters-favorite.component.html',
  styleUrls: ['./filters-favorite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-favorite-mobile]': 'mobile',
  },
})
export class FiltersFavoriteComponent implements OnInit {
  @Input() mobile: boolean;
  @Input() favoriteFilters: EmployeeCustomFilterViewModel[];
  @Input() suggestedFilters: EmployeeCustomFilterViewModel[] = [];
  @Input() appliedFilters: FilterViewModel[];

  @Output() favoriteFiltersApplied: EventEmitter<FilterViewModel[]> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();

  loading: boolean = false;
  account: Account;
  employee: Employee;
  selectedIndex: number;
  editIndex: number;
  titleModel: string = null;
  public toggleOptions = [
    { label: 'Favourites', displayText: this.translateService.instant(T.common.favourites) },
    { label: 'Suggested', displayText: this.translateService.instant(T.common.suggested) },
  ];
  public selectedToggleOption = { label: 'Suggested', displayText: this.translateService.instant(T.common.suggested) };
  public selectedSuggestedFilter: EmployeeCustomFilterViewModel;
  public readonly T = T;

  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
    private readonly authenticationService: AuthenticationService,
    private readonly customFilterService: CustomFiltersService,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    if (this.favoriteFilters?.length) {
      this.selectedToggleOption = { label: 'Favourites', displayText: this.translateService.instant(T.common.favourites) };
    }
  }

  ngOnChanges() {
    this.favoriteFilters = this.favoriteFilters.slice();
    this.selectedIndex = undefined;
  }

  @HostListener('document:keydown.escape')
  onEscape(): void {
    this.ngOnChanges();
  }

  onSaveFavorite(): void {
    const title: string = this.titleModel;

    this.titleModel = null;

    this.loading = true;

    if (this.selectedIndex !== undefined) {
      this.customFilterService
        .updateEmployeeCustomFilter({
          accountId: this.account.id,
          employeeId: this.employee.id,
          filters: this.appliedFilters,
          globalObjectType: ObjectTypes.Favorite_Filters,
          title,
          id: this.favoriteFilters[this.selectedIndex].id,
        })
        .subscribe((favoriteFilter) => {
          this.objectEventEmitters.broadcastObjectUpdated(favoriteFilter.id, ObjectTypes.Favorite_Filters, favoriteFilter);
          void this.alertService.success('My Filters updated');
          this.loading = false;
          this.onCancel();
        });
    } else {
      this.customFilterService
        .addEmployeeCustomFilter({
          accountId: this.account.id,
          employeeId: this.employee.id,
          filters: this.appliedFilters,
          globalObjectType: ObjectTypes.Favorite_Filters,
          title,
          id: 0,
        })
        .subscribe((favoriteFilter) => {
          this.objectEventEmitters.broadcastObjectAdded(favoriteFilter.id, ObjectTypes.Favorite_Filters, favoriteFilter);
          void this.alertService.success('My Filters updated');
          this.loading = false;
          this.onCancel();
        });
    }
  }

  onEntrySelected(index: number): void {
    this.selectedIndex = index;
    this.titleModel = this.favoriteFilters[this.selectedIndex].title;
  }

  onSuggestedSelected(filter: EmployeeCustomFilterViewModel) {
    this.selectedSuggestedFilter = filter;
  }

  onClear(): void {
    this.selectedIndex = undefined;
    this.titleModel = null;
  }

  onEntryDelete(): void {
    this.loading = true;

    const filter = this.favoriteFilters[this.selectedIndex];

    this.customFilterService.deleteEmployeeCustomFilter(filter.id).subscribe(() => {
      this.objectEventEmitters.broadcastObjectDeleted(filter.id, ObjectTypes.Favorite_Filters, filter);
      this.loading = false;
      this.favoriteFilters = this.favoriteFilters.filter((x) => x.id !== filter.id);
      this.onClear();
      this.changeDetectorRef.markForCheck();
    });
  }

  onCancel(): void {
    this.closed.emit();
  }

  onApply(): void {
    let filter: EmployeeCustomFilterViewModel;

    if (this.selectedToggleOption.label == 'Favourites') {
      filter = this.favoriteFilters[this.selectedIndex];
    } else {
      filter = this.selectedSuggestedFilter;
    }

    this.favoriteFiltersApplied.emit(filter.filters);

    this.onCancel();
  }

  onToggleChange(object: { label: string; displayText: string }) {
    this.selectedToggleOption = object;
    this.changeDetectorRef.markForCheck();
  }
}
