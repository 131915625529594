import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { CollapseAnimation } from '../../../utilities/animations/collapse.animation';

@Component({
  selector: 'app-expand-collapse-button',
  templateUrl: 'expand-collapse-button.component.html',
  styleUrls: ['expand-collapse-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [CollapseAnimation.slideInOut],
})
export class ExpandCollapseButtonComponent {
  @Input() expandedTitle = this.translateService.instant(T.common.show_less);
  @Input() expandedIcon = 'expand_less';
  @Input() collapsedTitle = this.translateService.instant(T.common.show_more);
  @Input() collapsedIcon = 'expand_more';
  @Input() state: boolean = false;

  @Output() onExpandCollapse = new EventEmitter<boolean>();

  constructor(private readonly translateService: TranslateService) {}

  expandCollapseBtnClicked() {
    this.state = !this.state;
    this.onExpandCollapse.next(this.state);
  }

  get expandCollapseButtonText(): string {
    return this.state ? this.expandedTitle : this.collapsedTitle;
  }

  get expandCollapseButtonIcon(): string {
    return this.state ? this.expandedIcon : this.collapsedIcon;
  }
}
