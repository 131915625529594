<ng-container *ngIf="!isReadOnly">
  <app-upload-container
    [globalObjectId]="globalObjectId"
    (onFilesAdded)="addFiles($event)"
    (onLinkAdded)="addLink()"
  ></app-upload-container>
</ng-container>
<div class="cards-flex-wrapper" #wrapper (window:resize)="getWrapperWidth()">
  <ng-container *ngIf="!loading">
    <ng-container *ngFor="let document of documents">
      <div class="m-2" [ngClass]="cardClass">
        <app-document-card
          [document]="document"
          [documentCategories]="documentCategories"
          [isSmallScreen]="wrapperIsSmall"
          [isReadOnly]="isReadOnly"
          (documentChanged)="onDocumentChanged()"
          (uploadDeleted)="deleteUploadViewModel($event)"
        ></app-document-card>
      </div>
    </ng-container>
    <ng-container *ngFor="let uploadViewModel of uploadViewModels">
      <div class="m-2" [ngClass]="cardClass">
        <app-document-card
          [uploadViewModel]="uploadViewModel"
          [documentCategories]="documentCategories"
          [isReadOnly]="isReadOnly"
          [isSmallScreen]="wrapperIsSmall"
          (uploadDeleted)="deleteUploadViewModel($event)"
          (uploadChanged)="updateUploadViewModel($event)"
        >
        </app-document-card>
      </div>
    </ng-container>
  </ng-container>

  <app-loading *ngIf="loading" [displayLogo]="true">
    <p>{{ T.common.loading | translate }}...</p>
  </app-loading>
</div>
