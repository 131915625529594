import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tooltip } from '../../../models/tooltip';

@Component({
  selector: 'app-information-bubble',
  templateUrl: './information-bubble.component.html',
  styleUrls: ['./information-bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationBubbleComponent {
  @Input() color: string = 'var(--wt-primary)';
  @Input() size: string = '20px';
  @Input() tooltip: Tooltip;
  @Input() outlinedIcon = true;
}
