<div class="backdrop" (click)="close()" [class.wttimepicker-visible]="visible">
  <div class="wt-timepicker-container" (click)="$event.stopPropagation()" [class.wttimepicker-visible]="visible">
    <div class="wt-timepicker_desktop">
      <app-modal-header
        [title]="T.common.set_time | translate"
        [showDelete]="false"
        (closed)="close()"
      ></app-modal-header>
      <div class="wt_timepicker_desktop-body">
        <ngx-timepicker-field
          [controlOnly]="true"
          [ngModel]="timeString"
          [format]="24"
          (ngModelChange)="onTimeChange($event)"
        ></ngx-timepicker-field>
        <app-button-transparent
          size="small"
          textColor="dark-text"
          [text]="T.common.remove_time | translate"
          (clicked)="onTimeRemove()"
        ></app-button-transparent>
      </div>
      <app-modal-footer>
        <div class="desktop-footer-container">
          <div class="desktop-footer-container__right d-flex justify-content-between">
            <app-button-transparent
              [text]="T.common.cancel | translate"
              size="large"
              (clicked)="close()"
            ></app-button-transparent>
            <app-button-transparent
              [text]="T.common.apply | translate"
              size="large"
              (clicked)="apply()"
            ></app-button-transparent>
          </div>
        </div>
      </app-modal-footer>
    </div>
  </div>
</div>
