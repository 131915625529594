import { RAGStatuses } from '../../shared/enums/ragStatuses';
import { RAGBreakdown } from '../../shared/models/rag/ragBreakdown';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';

export class TaskGroupListItemViewModel extends ModifiableEntityViewModel {
  rag: RAGStatuses;
  ragBreakdown: RAGBreakdown[];
  taskCount: number;
  projectId: number;
  taskDueDate: string;
}
