<ng-container *ngIf="!loading">
  <div #left class="left">
    <ng-container *ngTemplateOutlet="leftSideTemplate"></ng-container>
  </div>
  <div #right class="right custom-scrollbar">
    <ng-container *ngTemplateOutlet="rightSideTemplate"></ng-container>
  </div>
</ng-container>
<app-loading *ngIf="loading" [displayLogo]="true">
  <p>{{ T.common.loading | translate }}...</p>
</app-loading>
