export enum PrivacyStatuses {
  Open = 0,
  Confidential = 1,
  Medical = 2,
  Police = 3,
  Confidential_Type_4 = 4,
  Confidential_Type_5 = 5,
  Confidential_Type_6 = 6,
  Confidential_Type_7 = 7,
  Confidential_Type_8 = 8,
  Confidential_Type_9 = 9,
  Confidential_Type_10 = 10,
}
