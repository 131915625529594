import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Constants } from 'src/app/modules/shared/models/constants';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { Geolocation, Position } from '@capacitor/geolocation';
import { GoogleGeolocationResponseModel } from 'src/app/modules/shared/viewModels/GoogleGeolocationResponseModel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-location-button',
  templateUrl: './my-location-button.component.html',
  styleUrls: ['./my-location-button.component.scss'],
})
export class MyLocationButtonComponent implements OnInit, OnDestroy {
  @Input() showLabel = false;
  @Output() locationAvailableEvent = new EventEmitter<google.maps.LatLng>();

  private subscriptions = new Subscription();
  public isMobile = false;

  constructor(
    private ref: ChangeDetectorRef,
    private geoLocationService: GeoLocationService
  ) {

  }

  ngOnInit(): void {
    this.isMobile = window.innerWidth < Constants.xs;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onClick() {
    const onResponseCallback = (lat: number, lng: number): void => {
      this.locationAvailableEvent.emit(new google.maps.LatLng(lat, lng));
    };

    if (this.isMobile) {
      Geolocation.getCurrentPosition().then(
        (coordinates: Position) => {
          const lat = coordinates.coords.latitude;
          const lng = coordinates.coords.longitude;
          onResponseCallback(lat, lng);
        }
      ).catch(_error => undefined);
    } else {
      this.subscriptions.add(
        this.geoLocationService.getPositionFromGoogleGeolocationAPI()
        .subscribe((response: GoogleGeolocationResponseModel) => {
          const lat = response.location.lat;
          const lng = response.location.lng;
          onResponseCallback(lat, lng);
        })
      );
    }
  }
}
