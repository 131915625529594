import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum AddModalButtonOptions {
  Save_View_Details = 'Save & View Details',
  Save_Close = 'Save & Close',
  Save_Add_Another = 'Save & Add Another',
}

export namespace AddModalButtonOptions {
  export function getTranslatedOptionText(option: AddModalButtonOptions, translateService: TranslateService): string {
    switch (option) {
      case AddModalButtonOptions.Save_View_Details:
        return translateService.instant(T.common.save_amp_view_details);
      case AddModalButtonOptions.Save_Close:
        return translateService.instant(T.common.save_amp_close);
      case AddModalButtonOptions.Save_Add_Another:
        return translateService.instant(T.common.save_amp_add_another);
    }
  }
  export function getOptions(translateService: TranslateService) {
    return Object.values(AddModalButtonOptions)
      .filter((e) => typeof e !== 'function')
      .map((e) => {
        return getTranslatedOptionText(e as AddModalButtonOptions, translateService);
      });
  }
}
