<ng-template #dropdownTemplate>
  <ng-content></ng-content>
</ng-template>

<div
  #wrapper
  *ngIf="!hideDropdown"
  class="wrapper"
  [class.expanded]="expanded"
  [app-dropdown]="{ template: dropdownTemplate }"
  (click)="toggleDropdown($event)"
>
  <div class="input-wrapper">
    <input type="text" disabled class="custom-input" />
    <div class="label" localise>{{ label }}</div>
    <div class="arrow-wrapper">
      <span class="arrow"></span>
    </div>
  </div>
</div>

<ng-container *ngIf="hideDropdown">
  <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
</ng-container>
