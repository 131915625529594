import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  TemplateRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TooltipEventsEmitter } from '../../../events/tooltip.events';
import { Position } from '../../../enums/position';
import { Tooltip } from '../../../models/tooltip';

@Component({
  selector: 'app-tooltip',
  styleUrls: ['./tooltip.component.scss'],
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLElement>;
  @ViewChild('titleEl') titleEl: ElementRef<HTMLElement>;
  @ViewChild('messageEl', { static: true }) messageEl: ElementRef<HTMLElement>;
  message: string;
  templateRef: TemplateRef<HTMLElement>;
  title: string;
  icon: string;
  position: Position = Position.Bottom;
  additionalPosition: Position;
  tooltipPosition = Position;
  cursorOffset: number;
  width: number;
  stylingVersion: number;

  constructor(
    private readonly tooltipEventsEmitter: TooltipEventsEmitter,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.tooltipEventsEmitter.tooltipChanged$.subscribe((tooltip: Tooltip) => {
        this.message = tooltip.message;
        this.title = tooltip.title;
        this.icon = tooltip.icon;
        this.templateRef = tooltip.templateRef;
        this.cursorOffset = tooltip.cursorOffset;
        this.width = tooltip.width;

        if (tooltip.position) {
          this.position = tooltip.position[0];
          if (tooltip.position[1]) {
            this.additionalPosition = tooltip.position[1];
          }
        }
        this.changeDetectorRef.detectChanges();

        if (this.titleEl) {
          while (this.titleEl.nativeElement.offsetHeight > 20 && this.width < 400) {
            this.width += 10;
            this.changeDetectorRef.detectChanges();
          }
        }

        if(tooltip.stylingVersion) {
          this.stylingVersion = tooltip.stylingVersion;
        } else {
          this.stylingVersion = 1;
        }
      })
    );

    this.subscriptions.push(
      this.tooltipEventsEmitter.tooltipDirectiveInitialized$.subscribe(() => {
        this.tooltipEventsEmitter.broadcastTooltipElementChanged(this.wrapper.nativeElement);
      })
    );
  }

  ngAfterViewInit() {
    this.tooltipEventsEmitter.broadcastTooltipElementChanged(this.wrapper.nativeElement);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  close(e: Event) {
    e.stopPropagation();

    this.wrapper.nativeElement.classList.remove('shown');
    this.tooltipEventsEmitter.broadcastTooltipVisibilityChanged(false);
  }
}
