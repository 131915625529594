import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';
import { NgRangeDatesOutput, NgRangeSelectCallback } from '../../../models/rangeDatepicker/rangeDatepickerModels.model';
import { TimeZoneService } from '../../../services/timeZone.service';
import { UserAgentService } from '../../../services/user-agent.service';

@Component({
  selector: 'app-date-slider',
  templateUrl: './date-slider.component.html',
  styleUrls: ['./date-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateSliderComponent implements OnInit, OnChanges {
  @Input() isCompleted: boolean = false;
  @Input() actualStart: string;
  @Input() actualEnd: string;
  @Input() baselineStart: string;
  @Input() baselineEnd: string;
  @Input() showTime: boolean = false;
  @Input() canEdit: boolean = false;
  @Input() baselineInfo: string;
  @Input() onDateSelectCallbackFn: NgRangeSelectCallback;

  @Output() startDateChange: EventEmitter<string> = new EventEmitter();
  @Output() dueDateChange: EventEmitter<string> = new EventEmitter();
  @Output() datesChanged: EventEmitter<NgRangeDatesOutput> = new EventEmitter();

  public isExpanded = false;
  public isDatesInputValid: boolean;
  public touchDevice: boolean;

  // parsed Dates props
  public actualStartDate: Date;
  public actualEndDate: Date;
  public baselineStartDate: Date;
  public baselineEndDate: Date;

  // Actual bar
  public actualBarPassedPercentage: number;
  public actualBarDateSpace: number;
  public actualBarRestPercentage: number;

  // Baseline bar
  public baselineBarPassedPercentage: number;
  public baselineBarDateSpace: number;
  public baselineBarRestPercentage: number;

  public today: Date;

  // Difference in days
  public actualStartDiffInDays: number;
  public actualEndDiffInDays: number;

  public baselineStartDiffInDays: number;
  public baselineEndDiffInDays: number;

  public readonly T = T;

  constructor(
    private readonly timeZoneService: TimeZoneService,
    private readonly userAgentService: UserAgentService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userAgentService.isTouch$.subscribe((isTouch) => {
      this.touchDevice = isTouch;
      this.canEdit = !this.touchDevice;
      this.changeDetectorRef.markForCheck();
    });

    this.isDatesInputValid = !!this.actualStart && !!this.baselineStart && !!this.actualEnd && !!this.baselineEnd;
    if (this.isDatesInputValid) {
      this.initDates();
      this.calculatePassedTime();
      this.calculateDifferenceInDays();
    }
  }
  get dateFormat(): string {
    return this.timeZoneService.dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
  }

  ngOnChanges() {
    this.initDates();
    this.isDatesInputValid = !!this.actualStart && !!this.baselineStart && !!this.actualEnd && !!this.baselineEnd;
    if (this.isDatesInputValid) {
      this.calculatePassedTime();
      this.calculateDifferenceInDays();
    }
  }

  validateInputDates() {
    // const isDatesInputValid = this.actualStart && this.baselineStart && this.actualEnd && this.baselineEnd;
  }

  initDates() {
    this.today = new Date();

    if (this.baselineStart) {
      this.baselineStartDate = this.timeZoneService.localiseDateISOStringToDate(this.baselineStart);
    }
    if (this.baselineEnd) {
      this.baselineEndDate = this.timeZoneService.localiseDateISOStringToDate(this.baselineEnd);
    }
    if (this.actualStart) {
      this.actualStartDate = this.timeZoneService.localiseDateISOStringToDate(this.actualStart);
    }
    if (this.actualEnd) {
      this.actualEndDate = this.timeZoneService.localiseDateISOStringToDate(this.actualEnd);
    }
  }

  calculatePassedTime() {
    const rightHand = Math.max(this.baselineEndDate.toTimelessDate().getTime(), this.actualEndDate.toTimelessDate().getTime());
    const leftHand = Math.min(this.baselineStartDate.toTimelessDate().getTime(), this.actualStartDate.toTimelessDate().getTime());
    const total = leftHand - rightHand;

    // This is for the Actual Dates
    const actualTotal = this.actualStartDate.toTimelessDate().getTime() - this.actualEndDate.toTimelessDate().getTime();

    const actualTimeFront = this.actualStartDate.toTimelessDate().getTime() - leftHand;
    this.actualBarPassedPercentage = Math.abs((actualTimeFront / total) * 100);

    const actualTimeBack = this.actualEndDate.toTimelessDate().getTime() - rightHand;
    this.actualBarRestPercentage = Math.abs((actualTimeBack / total) * 100);

    const actualToday = this.today.toTimelessDate().getTime() - this.actualStartDate.toTimelessDate().getTime();
    this.actualBarDateSpace = Math.abs((actualToday / actualTotal) * 100);

    // This is for the Baseline Dates
    const baselineTotal = this.baselineStartDate.toTimelessDate().getTime() - this.baselineEndDate.toTimelessDate().getTime();

    const baselineTimeFront = this.baselineStartDate.toTimelessDate().getTime() - leftHand;
    this.baselineBarPassedPercentage = Math.abs((baselineTimeFront / total) * 100);

    const baselineTimeBack = this.baselineEndDate.toTimelessDate().getTime() - rightHand;
    this.baselineBarRestPercentage = Math.abs((baselineTimeBack / total) * 100);

    const baselineToday = this.today.toTimelessDate().getTime() - this.baselineEndDate.toTimelessDate().getTime();
    this.baselineBarDateSpace = Math.abs((baselineToday / baselineTotal) * 100);
  }

  calculateDifferenceInDays() {
    const actualStartTimeDiff = this.actualStartDate.toTimelessDate().getTime() - this.today.toTimelessDate().getTime();
    this.actualStartDiffInDays = Math.round(actualStartTimeDiff / (1000 * 60 * 60 * 24));

    const actualEndTimeDiff = this.actualEndDate.toTimelessDate().getTime() - this.today.toTimelessDate().getTime();
    this.actualEndDiffInDays = Math.round(actualEndTimeDiff / (1000 * 60 * 60 * 24));

    const baselineStartTimeDiff = this.baselineStartDate.toTimelessDate().getTime() - this.today.toTimelessDate().getTime();
    this.baselineStartDiffInDays = Math.round(baselineStartTimeDiff / (1000 * 60 * 60 * 24));

    const baselineEndTimeDiff = this.baselineEndDate.toTimelessDate().getTime() - this.today.toTimelessDate().getTime();
    this.baselineEndDiffInDays = Math.round(baselineEndTimeDiff / (1000 * 60 * 60 * 24));
  }

  handleStartDateChange(date: string) {
    this.startDateChange.emit(date);
  }

  handleEndDateChange(date: string) {
    this.dueDateChange.emit(date);
  }

  onRangeDateChanged(datesAsObj: NgRangeDatesOutput) {
    this.datesChanged.emit(datesAsObj);
  }
}
