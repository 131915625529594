import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-tag-expandable-filter-selector',
  templateUrl: './tag-expandable-filter-selector.component.html',
  styleUrls: ['./tag-expandable-filter-selector.component.scss']
})
export class TagExpandableFilterSelectorComponent {
  @Input() tagGroupFilter: FilterViewModel;
  @Input() filterSelectorVM: FilterTypeSelectorViewModel;
  @Input() appliedFilters: FilterViewModel[] = [];
  @Input() availableTagFilters: FilterViewModel[] = [];
  @Input() expanded: boolean = false;

  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();
  @Output() toggleChange: EventEmitter<'open' | 'close'> = new EventEmitter<'open' | 'close'>();

  public searchFilteredAllowedFilters: FilterViewModel[] = [];
  public searchText: string = '';
  public readonly T = T;

  constructor(private allowedFiltersService: AllowedFiltersService) {
  }

  ngOnInit(): void {
    this.searchFiltersByTextSearch(this.searchText);
  }

  public onExpand(): void {
    const newState = !this.expanded;
    this.toggleChange.emit(newState ? 'open' : 'close');
  }

  onFilterSelected(filter: FilterViewModel): void {
    const copiedFilter = JSON.parse(JSON.stringify(filter)) as FilterViewModel;
    const isAlreadySelected = this.getItemIndex(copiedFilter) > -1;

    let localAppliedFilters = this.appliedFilters.slice();
    localAppliedFilters = localAppliedFilters.filter((a) => a.id !== filter.id);

    if (isAlreadySelected) {
      copiedFilter.filterAction = FilterActionTypes.Remove;
    } else {
      copiedFilter.filterAction = FilterActionTypes.Add;
      copiedFilter.displayForGlobalObjectType = this.tagGroupFilter.displayForGlobalObjectType;
      copiedFilter.exclude = this.excludeState;
    }
    localAppliedFilters.push(copiedFilter);

    this.filtersUpdated.emit(localAppliedFilters);
  }

  private getItemIndex(filter: FilterViewModel): number {
    return this.appliedFilters.findIndex((a) => a.filterType === filter.filterType && a.filterValue == filter.filterValue);
  }

  public onTextSearch(text: string): void {
    this.searchText = text;
    this.searchFiltersByTextSearch(text);
  }

  private searchFiltersByTextSearch(text: string): void {
    this.searchFilteredAllowedFilters = this.availableTagFilters.filter((a) => a.filterText.toLowerCase().includes(text.toLowerCase()));
  }

  public clearFilters(): void {
    this.appliedFilters.forEach((a) => a.filterAction = FilterActionTypes.Remove);
    this.filtersUpdated.emit(this.appliedFilters);
  }

  public get excludeState(): boolean {
    return this.appliedFilters?.[0]?.exclude;
  }

  onExclude(excluded: boolean): void {
    let localAppliedFilters = this.appliedFilters.slice();
    localAppliedFilters.forEach((a) => {
      a.exclude = excluded;
    });
    this.filtersUpdated.emit(localAppliedFilters);
  }
}
