import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IBsModalState } from '../models/interfaces/bs-modal-state.interface';

@Injectable()
export class BsModalEventsEmitter {
  private bsModalStateChanged = new Subject<IBsModalState>();
  private exportToPdfHappened = new Subject<void>();

  private addModalActionHandleClicked = new Subject<any>();
  private addModalCloseClicked = new Subject<any>();

  bsModalStateChanged$ = this.bsModalStateChanged.asObservable();
  exportToPdfHappened$ = this.exportToPdfHappened.asObservable();
  addModalCloseClicked$ = this.addModalCloseClicked.asObservable();

  addModalActionHandleClicked$ = this.addModalActionHandleClicked.asObservable();

  broadcastBsModalStateChanged(bsModalState: IBsModalState) {
    this.bsModalStateChanged.next(bsModalState);
  }

  broadcastExportToPdfHappened() {
    this.exportToPdfHappened.next();
  }

  broadcastBsModalActionHandleClicked(obj: any) {
    this.addModalActionHandleClicked.next(obj);
  }

  broadcastBsModalCloseClicked(obj: any) {
    this.addModalCloseClicked.next(obj);
  }
}
