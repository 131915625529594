import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { DepartmentVisibilities } from '../../enums/departmentVisibility.enum';
import { EmployeeRoleTypes } from '../../enums/employees/EmployeeRoleTypes';
import { FilterTypes } from '../../enums/filterTypes';
import { PermissionOperationTypes } from '../../enums/permissionOperationTypes.enum';
import { Employee } from '../../models/employee';
import { FilterViewModel } from '../../models/filter/filterViewModel';
import { EmployeeUtil } from '../../utilities/employee.utilities';
import { IPermissionManager } from '../IPermissionManager';
import { BasePermissionManager } from './basePermissionManager';
import { RiskPrivacyStatusTypes } from 'src/app/modules/risk/enums/riskPrivacyStatusTypes';

export class DefaultPermissionManager extends BasePermissionManager implements IPermissionManager {
  IsApplicable(filterType: FilterTypes): boolean {
    throw new Error('Method not implemented.');
  }

  applyPermissions(
    obj: { id: number; filters: FilterViewModel[]; permissionFilters: FilterViewModel[] },
    moduleType: ModuleTypes,
    filterType: FilterTypes,
    employee: Employee,
    operationType?: PermissionOperationTypes
  ): boolean {
    // if (obj.createdById == employee.id) return true;
    const primaryFilters = {
      [FilterTypes.Risk]: [FilterTypes.Risk],
      [FilterTypes.Project]: [FilterTypes.Project, FilterTypes.Task, FilterTypes.Task_Group],
      [FilterTypes.Incident]: [FilterTypes.Incident],
      [FilterTypes.Job]: [FilterTypes.Job],
    };

    const primaryFilterType = Object.keys(primaryFilters).find((r) => primaryFilters[r].some((r) => r == filterType));

    const availableProjects = obj.filters.filter((r) => r.filterType == FilterTypes.Project).map((r) => +r.filterValue);

    const haveProjectRightPermissions = EmployeeUtil.hasWritePermission(
      employee,
      ModuleTypes.Planning,
      FilterTypes.Project,
      availableProjects,
      obj
    );

    const haveObjectWritePermissions = EmployeeUtil.hasWritePermission(employee, 0 as ModuleTypes, filterType, [obj.id], obj);
    let isOwner = obj.filters.some((e) => e.filterType == FilterTypes.Owner && e.filterValue == employee.id);
    if (!isOwner && obj.permissionFilters) {
      isOwner = obj.permissionFilters.some((e) => e.filterType == FilterTypes.Owner && e.filterValue == employee.id);
    }

    const hasDepartmentPermissions = this.haveDepartmentPermissions(
      employee,
      +primaryFilterType,
      obj.filters,
      obj.permissionFilters
    );

    if (haveObjectWritePermissions || isOwner) {
      return true;
    }

    if (primaryFilters[FilterTypes.Project].some((f) => f == filterType)) {
      if (
        EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Project_Admin) ||
        (haveProjectRightPermissions && hasDepartmentPermissions)
      ) {
        if (filterType == FilterTypes.Project) {
          return EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Project_Admin);
        } else {
          return true;
        }
      } else if (filterType == FilterTypes.Project) {
        if (haveProjectRightPermissions && hasDepartmentPermissions) {
          return true;
        }
      } else if (filterType == FilterTypes.Task_Group && haveProjectRightPermissions) {
        return true;
      }
    }


    //Check Task Specific Logic
    if (filterType == FilterTypes.Task) {
      const isParentTask = obj.filters.some(
        (r) => r.filterType != FilterTypes.Parent_Task || (r.filterType == FilterTypes.Parent_Task && r.filterValue == 0)
      );
      const taskFilter = employee.permissions.filter((s) => s.type == FilterTypes.Task && s.value > 0);

      if (obj.filters.some((e) => e.filterType == FilterTypes.Parent_Task && taskFilter.some((r) => r.value == e.filterValue))) {
        return true;
      }

      if (haveProjectRightPermissions) {
        return true;
      }
    }

    if (filterType == FilterTypes.Risk) {
      const hasRiskWritePermissions = EmployeeUtil.hasWritePermission(
        employee,
        ModuleTypes.Planning,
        FilterTypes.Risk,
        [obj.id],
        obj
      );

      // if user is Risk_Manager he should be able to see Confidential risks
      if (EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Risk_Manager)) {
        return true;
      }
      // if the risk is not confidential (open) we need to check for regular permissions (hasDepartmentPermissions will do the work)
      if (
        obj.filters.some(
          (f) => f.filterType === FilterTypes.Risk_Privacy_Status && f.filterValue === RiskPrivacyStatusTypes.Public
        ) &&
        hasDepartmentPermissions
      ) {
        return true;
      }

      if (hasRiskWritePermissions) {
        return true;
      }
    }

    // as many items are linked to project this is sorting the issue when you are added as an owner to project to have Read Write access to the specific item
    if (haveProjectRightPermissions && filterType != FilterTypes.Risk) {
      if (isOwner) {
        return true;
      }

      // this is for edge case when you have selected department from the permission setting and under the project
      // you have multuiple tasks so you need to be able to edit only those whcih are on your department
      if (filterType == FilterTypes.Task) {
        const projectId = obj.filters.find((s) => s.filterType == FilterTypes.Project);

        if (projectId) {
          const projectRightP = employee.permissions.find(
            (s) => s.type == FilterTypes.Project && s.value == projectId.filterValue
          );

          if (projectRightP && projectRightP.departmentVisibility == DepartmentVisibilities.Only_User_Department) {
            const havedepPermission = obj.filters.some(
              (r) => r.filterType == FilterTypes.Department && r.filterValue == employee.departmentId
            );
            const haveProjectWithDepPermission = obj.permissionFilters.some(
              (r) => r.filterType == FilterTypes.Department && r.filterValue == employee.departmentId
            );

            return havedepPermission || haveProjectWithDepPermission;
          }
        }
      }
    }

    if (filterType == FilterTypes.Incident) {
      const availableIncidents = obj.filters.filter((r) => r.filterType == FilterTypes.Incident);
      const availableIncidentsIDs = availableIncidents.map((r) => +r.filterValue);
      const availableIncidentsLevel = obj.filters
        .filter((r) => r.filterType == FilterTypes.Privacy_Status)
        .map((r) => r.filterValue)[0];

      const haveIncidentsWritePermissions = EmployeeUtil.hasWritePermission(
        employee,
        ModuleTypes.Incidents,
        FilterTypes.Incident,
        availableIncidentsIDs,
        obj,
        availableIncidentsLevel
      );

      if (
        EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Control_Admin) ||
        (haveIncidentsWritePermissions && hasDepartmentPermissions)
      ) {
        return true;
      }
    }

    if (filterType == FilterTypes.Job) {
      const availableJobs = obj.filters.filter((r) => r.filterType == FilterTypes.Job);
      const availableJobIds = availableJobs.map((r) => +r.filterValue);
      const availableJobLevels = obj.filters
        .filter((r) => r.filterType == FilterTypes.Privacy_Status)
        .map((r) => r.filterValue)[0];

      const hasJobWritePermissions = EmployeeUtil.hasWritePermission(
        employee,
        ModuleTypes.Incidents,
        FilterTypes.Job,
        availableJobIds,
        obj,
        availableJobLevels
      );

      if (
        EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Control_Admin) ||
        (hasJobWritePermissions && hasDepartmentPermissions)
      ) {
        return true;
      }
    }

    return false;
  }
}
