import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum RAGStatuses {
  Grey = 0,
  Red = 1,
  Amber = 2,
  Green = 3,
  Blue = 4,
}

export namespace RAGStatuses {
  export function translatedItems(translateService: TranslateService): { key: RAGStatuses; value: string }[] {
    const a: { key: RAGStatuses; value: string }[] = [];
    for (const enumMember in RAGStatuses) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translateItem(+enumMember, translateService) });
      }
    }
    return a;
  }

  export function translateItem(key: RAGStatuses, translateService: TranslateService): string {
    switch (key) {
      case RAGStatuses.Amber:
        return translateService.instant(T.common.rag_statuses.amber);
      case RAGStatuses.Blue:
        return translateService.instant(T.common.rag_statuses.blue);
      case RAGStatuses.Green:
        return translateService.instant(T.common.rag_statuses.green);
      case RAGStatuses.Grey:
        return translateService.instant(T.common.rag_statuses.grey);
      case RAGStatuses.Red:
        return translateService.instant(T.common.rag_statuses.red);
      default:
        return 'unknown';
    }
  }
}
