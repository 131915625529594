<div class="attachments-board-view-container">
  <app-attachment-card
    *ngFor="let document of documents"
    class="attachment-card"
    (onAttachmentDownload)="attachmentDownload($event)"
    (onAttachmentDoelete)="attachmentDelete($event)"
    (onAttachmentLinkClicked)="attachmentLinkClicked($event)"
    [attachment]="document"
    [accountId]="accountId"
    [uploadViewModel]="uploadViewModel"
  ></app-attachment-card>
</div>
