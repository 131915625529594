import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ZonePolygon } from '../viewModels/zonePolygon';
import { AreaPolygon } from '../viewModels/areaPolygon';
import { ShapePointViewModel } from '../viewModels/shapePointViewModel';

@Injectable()
export class LocationManagerEventEmitter {
  //Zones
  private addNewZoneButtonClicked = new Subject<void>();
  private cancelAddNewZoneButtonClicked = new Subject<void>();
  private editZoneButtonClicked = new Subject<number>();
  private selectZoneButtonClicked = new Subject<number>();
  private cancelEditZoneButtonClicked = new Subject<ShapePointViewModel[]>();
  private zonePolygonDrawingCompleted = new Subject<ZonePolygon>();
  private zoneSaveCompleted = new Subject<number>();
  private zoneDeleteCompleted = new Subject<number[]>();
  private zoneTableRowHovered = new Subject<number>();
  private zonePolygonStyleReset = new Subject<void>();
  private zonePolygonClicked = new Subject<number>();

  //Areas
  private addNewAreaButtonClicked = new Subject<boolean>();
  private cancelAddNewAreaButtonClicked = new Subject<void>();
  private editAreaButtonClicked = new Subject<number>();
  private selectAreaButtonClicked = new Subject<number>();
  private cancelEditAreaButtonClicked = new Subject<ShapePointViewModel[]>();
  private areaPolygonDrawingCompleted = new Subject<AreaPolygon>();
  private areaSaveCompleted = new Subject<number>();
  private areaDeleteCompleted = new Subject<number[]>();
  private areaTableRowHovered = new Subject<number>();
  private areaPolygonStyleReset = new Subject<void>();
  private areaPolygonClicked = new Subject<number>();

  //Zones
  addNewZoneButtonClicked$ = this.addNewZoneButtonClicked.asObservable();
  cancelAddNewZoneButtonClicked$ = this.cancelAddNewZoneButtonClicked.asObservable();
  zonePolygonDrawingCompleted$ = this.zonePolygonDrawingCompleted.asObservable();
  editZoneButtonClicked$ = this.editZoneButtonClicked.asObservable();
  selectZoneButtonClicked$ = this.selectZoneButtonClicked.asObservable();
  cancelEditZoneButtonClicked$ = this.cancelEditZoneButtonClicked.asObservable();
  zoneSaveCompleted$ = this.zoneSaveCompleted.asObservable();
  zoneDeleteCompleted$ = this.zoneDeleteCompleted.asObservable();
  zoneTableRowHovered$ = this.zoneTableRowHovered.asObservable();
  zonePolygonStyleReset$ = this.zonePolygonStyleReset.asObservable();
  zonePolygonClicked$ = this.zonePolygonClicked.asObservable();

  //Areas
  addNewAreaButtonClicked$ = this.addNewAreaButtonClicked.asObservable();
  cancelAddNewAreaButtonClicked$ = this.cancelAddNewAreaButtonClicked.asObservable();
  editAreaButtonClicked$ = this.editAreaButtonClicked.asObservable();
  selectAreaButtonClicked$ = this.selectAreaButtonClicked.asObservable();
  areaPolygonDrawingCompleted$ = this.areaPolygonDrawingCompleted.asObservable();
  cancelEditAreaButtonClicked$ = this.cancelEditAreaButtonClicked.asObservable();
  areaSaveCompleted$ = this.areaSaveCompleted.asObservable();
  areaDeleteCompleted$ = this.areaDeleteCompleted.asObservable();
  areaTableRowHovered$ = this.areaTableRowHovered.asObservable();
  areaPolygonStyleReset$ = this.areaPolygonStyleReset.asObservable();
  areaPolygonClicked$ = this.areaPolygonClicked.asObservable();

  //Zones events
  broadcastAddNewZoneButtonClicked() {
    this.addNewZoneButtonClicked.next();
  }

  broadcastCancelAddNewZoneButtonClicked() {
    this.cancelAddNewZoneButtonClicked.next();
  }

  broadcastEditZoneButtonClicked(zoneId: number) {
    this.editZoneButtonClicked.next(zoneId);
  }

  broadcastSelectZoneButtonClicked(zoneId: number) {
    this.selectZoneButtonClicked.next(zoneId);
  }

  broadcastZonePolygonDrawingCompleted(zonePolygon: ZonePolygon) {
    this.zonePolygonDrawingCompleted.next(zonePolygon);
  }

  broadcastCancelEditZoneButtonCLicked(beforeEditZoneShapePoints: ShapePointViewModel[]) {
    this.cancelEditZoneButtonClicked.next(beforeEditZoneShapePoints);
  }

  broadcastZoneDeleteCompleted(zoneIds: number[]) {
    this.zoneDeleteCompleted.next(zoneIds);
  }

  broadcastZoneTableRowHovered(zoneId: number) {
    this.zoneTableRowHovered.next(zoneId);
  }

  broadcastResetHoveredZonePolygonStyle() {
    this.zonePolygonStyleReset.next();
  }

  broadcastZonePolygonClicked(zoneId: number) {
    this.zonePolygonClicked.next(zoneId);
  }

  //Areas events
  broadcastAddNewAreaButtonClicked(allowPolygonDrawing: boolean) {
    this.addNewAreaButtonClicked.next(allowPolygonDrawing);
  }

  broadcastCancelAddNewAreaButtonClicked() {
    this.cancelAddNewAreaButtonClicked.next();
  }

  broadcastEditAreaButtonClicked(areaId: number) {
    this.editAreaButtonClicked.next(areaId);
  }

  broadcastSelectAreaButtonClicked(areaId: number) {
    this.selectAreaButtonClicked.next(areaId);
  }

  broadcastAreaPolygonDrawingCompleted(areaPolygon: AreaPolygon) {
    this.areaPolygonDrawingCompleted.next(areaPolygon);
  }

  broadcastAreaSaveCompleted(areaId: number) {
    this.areaSaveCompleted.next(areaId);
  }

  broadcastZoneSaveCompleted(zoneId: number) {
    this.zoneSaveCompleted.next(zoneId);
  }

  broadcastCancelEditAreaButtonCLicked(boforeEditAreaShapePoints: ShapePointViewModel[]) {
    this.cancelEditAreaButtonClicked.next(boforeEditAreaShapePoints);
  }

  broadcastAreaDeleteCompleted(areaIds: number[]) {
    this.areaDeleteCompleted.next(areaIds);
  }

  broadcastAreaTableRowHovered(areaId: number) {
    this.areaTableRowHovered.next(areaId);
  }

  broadcastResetHoveredAreaPolygonStyle() {
    this.areaPolygonStyleReset.next();
  }

  broadcastAreaPolygonClicked(areaId: number) {
    this.areaPolygonClicked.next(areaId);
  }
}
