import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { FBRegistrationInputViewModel } from '../models/fbRegistrationInputViewModel';
import { PushNotificationDataModel } from '../models/push-notification-data.model';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration, private urlService: UrlService) {
    this.actionUrl = this.configuration.buildEndpoint(`Firebase/`);
  }

  public async initPush(employeeId: number): Promise<void> {
    if (Capacitor.getPlatform() !== 'web') {
      await this.registerPush(employeeId);
    }
  }

  private async registerPush(employeeId: number): Promise<void> {
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();

    await PushNotifications.addListener('registration', (token: Token) => {
      const body: FBRegistrationInputViewModel = {
        employeeId: employeeId,
        token: token.value,
      };
      this.http.post(`${this.actionUrl}Register/Token`, body).subscribe();
    });

    void PushNotifications.addListener('registrationError', (error: any) => {
      console.error('error while registering push', error);
    });

    await PushNotifications.addListener('pushNotificationReceived', () => {});

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {

      const data = notification.notification.data as PushNotificationDataModel;
      const {
        objecttype: objectType,
        objectsubtype: subTypeId,
        objectid: objectId
      } = data;

      this.urlService.navigateToObjectDetailsPage(+objectType, +subTypeId, +objectId, false);
    });
  }

  public async clearAllPushNotifications(): Promise<void> {
    if (Capacitor.getPlatform() !== 'web') {
      await PushNotifications.removeAllDeliveredNotifications();
    }
  }
}
