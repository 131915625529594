import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-percentage-of-completion',
  templateUrl: 'percentage-of-completion.html',
  styleUrls: ['percentage-of-completion.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PercentageOfCompletionComponent {
  @Input() totalItems: number = 0;
  @Input() completedItems: number = 0;

  get percentage(): number {
    let res = this.completedItems / this.totalItems;

    if (isNaN(res) || res === -Infinity) {
      res = 0;
    } else if (res === Infinity) {
      res = 1;
    } else {
      res = Math.round(res * 100);
    }

    return res;
  }
}
