<app-modal>
  <!-- Header -->
  <app-modal-header
    [icon]="'event'"
    [iconColor]="'var(--wt-primary)'"
    [title]="modalTitle"
    (closed)="onClose()"
  ></app-modal-header>

  <!-- Body -->
  <app-modal-body>
    <div class="alert-warning intro-text mb-3">
      <div class="m-r-5"><i class="material-symbols-outlined">info</i></div>
      <div class="m-l-5">
        <p
          [innerHTML]="
            T.common.select_zone_modal.please_select_zone_info_1 | translate: { localisedZone: localisedZone }
          "
        ></p>
        <p>
          {{
            T.common.select_zone_modal.please_select_zone_info_2
              | translate: { localisedZone: localisedZone | lowercase }
          }}
        </p>
        <p class="note">
          {{ T.common.select_zone_modal.please_select_zone_info_3 | translate: { localisedZone: localisedZone } }}
        </p>
      </div>
    </div>
    <app-list-search-input-fixed
      class="m-b-8"
      [background]="'var(----wt-neutral-extra-light)'"
      [placeholder]="T.common.search_item | translate: { item: localisedZones }"
      (inputChanged)="searchZones($event)"
    >
    </app-list-search-input-fixed>
    <div class="custom-scrollbar search-results-container">
      <div
        *ngFor="let zone of filteredZones; let i = index; trackBy: trackFilteredZones"
        class="template-list-wrapper"
        [ngClass]="{ 'is-selected': isSelected(zone) }"
        (click)="setSelected(zone)"
      >
        <span class="title">{{ zone.title }}</span>
        <span *ngIf="isSelected(zone)" class="material-symbols-outlined">done</span>
      </div>
    </div>
  </app-modal-body>
  <!-- Footer -->
  <div class="footer">
    <app-button-rectangle
      [buttonTheme]="'white'"
      [text]="T.common.no_item | translate: { item: localisedZone }"
      (clicked)="onNoFixedZoneConfirmed()"
    ></app-button-rectangle>
    <app-button-rectangle
      [buttonTheme]="'primary'"
      [text]="T.common.confirm | translate"
      [disabled]="!selectedZone"
      (clicked)="onConfirm()"
    ></app-button-rectangle>
  </div>
</app-modal>
