import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-title-with-counter',
  templateUrl: './accordion-title-with-counter.component.html',
  styleUrls: ['./accordion-title-with-counter.component.scss']
})
export class AccordionTitleWithCounterComponent {
  @Input() title: string = '';
  @Input() count: number = 0;
}
