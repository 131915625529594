import { Injectable } from '@angular/core';
import { FilterTypes } from '../../enums/filterTypes';
import { LightFilterUpdateModel } from '../../models/filter/LightFilterUpdateModel';
import { FilterUtilities } from '../../utilities/filter.utilities';
import { FilterViewModel } from '../../models/filter/filterViewModel';

@Injectable({
  providedIn: 'root',
})
export class ListOperationsService<T = any> {
  public addObjectToList(object: T, list: T[], bindProp: string = 'id', unshift: boolean = false): T[] {
    if (!list.find((o) => o[bindProp] === object[bindProp])) {
      if (unshift) {
        list.unshift(JSON.parse(JSON.stringify(object)) as T);
      } else {
        list.push(JSON.parse(JSON.stringify(object)) as T);
      }
    }

    list = list.slice();

    return list;
  }

  public updateObjectInListLight(list: T[], filterChanges: LightFilterUpdateModel) {
    const hStatusFilter = filterChanges.changes.find((f) => f.filterType === FilterTypes.Headline_Status);
    const changedObject: any = list.find((a: any) => a.id == filterChanges.globalObjectId);

    if (hStatusFilter) {
      changedObject.headlineStatus = hStatusFilter.filterValue as string;
    }

    const titleFilter = filterChanges.changes.find((f) => f.filterType === FilterTypes.Title);

    if (titleFilter) {
      changedObject.title = titleFilter.filterValue as string;
    }

    const descFilter = filterChanges.changes.find((f) => f.filterType === FilterTypes.Description);

    if (descFilter) {
      changedObject.description = descFilter.filterValue as string;
    }

    changedObject.filters = FilterUtilities.ApplyFilterActions(changedObject.filters as FilterViewModel[], filterChanges.changes);

    if(filterChanges.updated) {
      changedObject.updated = filterChanges.updated as string;
    }
    return this.updateObjectInList(changedObject, list);
  }

  public updateObjectInList(object: T, list: T[], bindProp: string = 'id'): T[] {
    if (object) {
      const matchingObject = list.find((o) => o[bindProp] === object[bindProp]);
      if (matchingObject) {
        list[list.indexOf(matchingObject)] = JSON.parse(JSON.stringify(object)) as T;
      } else {
        list.push(object);
      }
    }
    list = list.slice();
    return list;
  }

  public deleteObjectFromList(object: T, list: T[], bindProp: string = 'id'): T[] {
    const matchingObject = list.find((o) => o[bindProp] == object[bindProp]);

    if (matchingObject) {
      list = list.filter((o) => o !== matchingObject);
    }

    list = list.slice();

    return list;
  }

  public getObjectFromList(propValue: any, list: T[], bindProp: string = 'id'): T {
    return list.find((o) => o[bindProp] == propValue);
  }
}
