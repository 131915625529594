import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { DropdownDirective } from '../directives/dropdown.directive';
import { DropdownContentComponent } from '../components/common/dropdown-content/dropdown-content.component';
import { DropdownDimensions } from '../models/dropdownDimensions';

@Injectable()
export class DropdownEventsEmitter {
  private readonly dropdownPositionChanged: Subject<void> = new Subject();
  private readonly dropdownDimensionsChanged: Subject<DropdownDimensions> = new Subject();
  private readonly visibilityChanged: Subject<DropdownDirective> = new Subject();

  dropdownPositionChanged$ = this.dropdownPositionChanged.asObservable();
  dropdownDimensionsChanged$ = this.dropdownDimensionsChanged.asObservable();
  visibilityChanged$ = this.visibilityChanged.asObservable();

  dropdownComponentRef: DropdownContentComponent;

  broadcastDropdownPositionChanged() {
    this.dropdownPositionChanged.next();
  }

  broadcastDropdownDimensionsChanged(dropdownCoordinates: DropdownDimensions) {
    this.dropdownDimensionsChanged.next(dropdownCoordinates);
  }

  broadcastDropdownVisibilityChanged(dropdownDirective: DropdownDirective) {
    this.visibilityChanged.next(dropdownDirective);
  }
}
