import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ObjectLinkTypes } from '../../../enums/objectLinkTypes.enum';
import { ObjectLinkDependencyTypes } from '../../../enums/objectLinkDependencyType.enum';
import { LinkedItemObjectViewModel } from '../../../models/linkedItemObjects/linkedItemObjectViewModel';
import { ObjectSimpleViewModel } from '../../../models/linkedItemObjects/objectSimpleViewModel';
import { T } from 'src/assets/i18n/translation-keys';

export enum LinkedItemSortOpitions {
  A_Z,
  RAG,
  Selected,
}

@Component({
  selector: 'app-linked-items-list',
  templateUrl: './linked-items-list.component.html',
  styleUrls: ['./linked-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedItemsListComponent implements OnInit, OnChanges {
  @Input() linkedItems: ObjectSimpleViewModel[];
  @Input() selectedItems: LinkedItemObjectViewModel[];
  @Input() objectLinkType: ObjectLinkTypes;
  @Input() objectLinkDependencyType: ObjectLinkDependencyTypes;
  @Input() cardContainerHeight: number = 280;
  @Input() loading: boolean = true;
  @Input() showCheckbox: boolean = true;
  @Input() redirectableCards = true;
  @Input() showMoreOptions: boolean = false;

  @Output() selectedItemsChange: EventEmitter<LinkedItemObjectViewModel[]> = new EventEmitter();
  @Output() onLinkRemoved: EventEmitter<ObjectSimpleViewModel> = new EventEmitter();

  public filteredLinkedItems: ObjectSimpleViewModel[] = [];
  public readonly T = T;

  ngOnInit(): void {
    this.filteredLinkedItems = [...this.linkedItems];
  }

  ngOnChanges(): void {
    this.filteredLinkedItems = [...this.linkedItems];
  }

  isCardChecked(card: ObjectSimpleViewModel): boolean {
    if (this.selectedItems) {
      return this.selectedItems.some((c) => c.objectId == card.id);
    }
  }

  onCardSelected(isChecked: boolean, currentlySelectedItem: ObjectSimpleViewModel) {
    let modifiedArr: LinkedItemObjectViewModel[] = [...this.selectedItems];

    if (isChecked) {
      modifiedArr.push({
        objectId: currentlySelectedItem.id,
        objectType: currentlySelectedItem.objectType,
        objectLinkType: this.objectLinkType,
        objectLinkDependencyType: this.objectLinkDependencyType,
      });
    } else {
      modifiedArr = modifiedArr.filter((item) => item.objectId !== currentlySelectedItem.id);
    }

    this.selectedItemsChange.emit(modifiedArr);
  }

  onRemoveLink(item: ObjectSimpleViewModel) {
    this.onLinkRemoved.next(item);
  }
}
