import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { FixedEventFiltersService } from '../../../services/fixed-event-filters.service';
import { FixedIncidentChannelFiltersService } from '../../../services/fixed-incident-channel-filters.service';
import { FixedZoneFiltersService } from '../../../services/fixed-zone-filters.service';

@Component({
  selector: 'app-fixed-filters-indicators',
  templateUrl: './fixed-filters-indicators.component.html',
  styleUrls: ['./fixed-filters-indicators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FixedFiltersIndicatorsComponent implements OnInit, OnDestroy {
  private readonly subscriptions = new Subscription();
  showFixedEvent = false;
  showFixedZone = false;
  showFixedChannel = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fixedEventFiltersService: FixedEventFiltersService,
    private readonly fixedZoneFiltersService: FixedZoneFiltersService,
    private readonly fixedIncidentChannelFiltersService: FixedIncidentChannelFiltersService
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      combineLatest([
        this.fixedEventFiltersService.isFilterActive$,
        this.fixedZoneFiltersService.isFilterActive$,
        this.fixedIncidentChannelFiltersService.isFilterActive$,
        this.fixedEventFiltersService.isAllFilterApplied$,
        this.fixedZoneFiltersService.isAllFilterApplied$,
        this.fixedIncidentChannelFiltersService.isAllFilterApplied$,
      ]).subscribe(
        ([
          isFixedEventActive,
          isFixedZoneActive,
          isFixedChannelActive,
          isAllEventsActive,
          isAllZonesActive,
          isAllChannelsActive,
        ]) => {
          this.showFixedEvent = isFixedEventActive && !isAllEventsActive;
          this.showFixedZone = isFixedZoneActive && !isAllZonesActive;
          this.showFixedChannel = isFixedChannelActive && !isAllChannelsActive;

          this.changeDetectorRef.markForCheck();
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
