export enum Colors {
  Amber = '#FD9C38',
  Blue = '#0574DB',
  Blue_Dark = '#0086d6',
  Red = '#F41B1B',
  Green = '#329F5B',
  Grey = '#939499',
  Yellow = '#FDD835',
  Orange = '#FF6D3F',
  Gold = '#d9b877',
  Silver = '#c7c0b3',
  Bronze = '#bf9986',
  Black = '#353f47',

  // Momentus Colours from root.scss
  Momentus_Dark_Blue = '#14164f',
  Momentus_Yellow = '#fff35f',
  Momentus_White = '#ffffff',
  Momentus_Danger = '#dc3545',
  Momentus_Neutral = '#FCFBFA',
  Momentus_Primary = '#4146e0',
  Momentus_Grey = '#B0B1C8',

}
