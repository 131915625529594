import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { AuthGuardService } from './auth-guard.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './token-interceptor.service';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { SigninOidcService } from './signin-oidc.service';
import { SignoutOidcService } from './signout-oidc.service';
import { WtStorageService } from '../shared/services/wt-storage.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, OAuthModule.forRoot()],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: OAuthStorage, useClass: WtStorageService },
        AuthService,
        AuthGuardService,
        SigninOidcService,
        SignoutOidcService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
      ],
    };
  }
}
