<div class="list-header-wrapper border-bottom">
  <div class="list-header-top-wrapper">
    <ng-content> </ng-content>
    <ng-container *ngIf="showSearchBar">
      <app-list-search-input-fixed
        [class.mr-8]="showRAGBreakdown"
        [background]="'var(----wt-neutral-extra-light)'"
        [placeholder]="searchPlaceholder"
        [value]="searchText"
        (inputChanged)="onListSearch($event)"
        style="flex: 1"
      ></app-list-search-input-fixed>
    </ng-container>
  </div>
  <div class="breakdown-wrapper" *ngIf="showRAGBreakdown">
    <span class="mr-8">{{ ragBreakdownTitle }}</span>
    <app-rag-breakdown-bar
      [ragBreakdowns]="ragBreakdownData"
      [rags]="rags"
      (onRAGsFiltered)="onRagsFiltered($event)"
    ></app-rag-breakdown-bar>
  </div>
  <div class="quick-filters-wrapper" *ngIf="showQuickFilters">
    <app-quick-filters [showQuickFiltersForFilterSelectors]="quickFilterSelectorVMs"></app-quick-filters>
  </div>
</div>
