import { EmployeeRole } from '../employeeRole';
import { PermissionViewModel } from './permissionViewModel';
import { EmployeeSettingViewModel } from './employeeSettingViewModel';

export class EmployeeDetailsViewModel {
  id: number;
  firstName: string;
  surname: string;
  username: string;
  thumbnailURL: string;
  itemOwnerId: number;
  managerId: number;
  managerSalutation: string;
  departmentId: number;
  lastLogin: string;
  photoFilename: string;
  isActive: boolean;
  childEmployeeRole: EmployeeRole[];
  employeeRole: EmployeeRole[];
  permissions: PermissionViewModel[];
  childAccountPermissions: PermissionViewModel[] = [];
  salutation: string;
  isActiveAsString: string;
  sendNotification: boolean;
  isLocked: boolean;
  notificationSettings: EmployeeSettingViewModel[];
  phone: string;
  isExternal: boolean;
  peapFrequencySetting: EmployeeSettingViewModel;
  eventId: number;
  zoneId: number;
  accountId: number;
  hubObjectId: number;
  languageCode: string;
}
