import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../shared/services/authentication.service';
import { WtStorageService } from '../shared/services/wt-storage.service';
import { Capacitor } from '@capacitor/core';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private oauthService: OAuthService,
    private authenticationService: AuthenticationService,
    private readonly wtStorage: WtStorageService
  ) {}

  externalAPiList = ['api.what3words.com', `thingproxy.freeboard.io`, 'www.googleapis.com'];

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.externalAPiList.findIndex((r) => req.url.indexOf(r) > -1) > -1) {
      return next.handle(req);
    }
    if (this.oauthService.hasValidAccessToken()) {
      const accessToken = this.wtStorage.getItem('access_token');
      const account = this.authenticationService.getCurrentAccount();
      const header = { Authorization: `Bearer ${accessToken}` };

      if (Capacitor.getPlatform() !== 'web') {
        header['m'] = 'm';
      }

      if (account) {
        header['aId'] = `${account.id}`;
      }

      req = req.clone({ setHeaders: header });
    }

    return next.handle(req);
  }
}
