import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  ViewChild,
  Input,
  Output,
  ElementRef,
  OnInit,
  OnChanges,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-time-box-fields',
  templateUrl: './time-box-fields.component.html',
  styleUrls: ['./time-box-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeBoxFieldsComponent implements OnInit, OnChanges {
  @ViewChild('minutesInput') minutesInputRef: ElementRef<HTMLInputElement>;

  @Input() hours: number | undefined;
  @Input() minutes: number | undefined;
  @Input() disabled: boolean | undefined;
  @Input() displayError: boolean | undefined;

  @Output() onTimeChange: EventEmitter<{ hours: number; minutes: number }> = new EventEmitter();

  public hoursInputValue: string | undefined;
  public minutesInputValue: string | undefined;

  public readonly hoursInputID: string = 'hoursID';
  public readonly minutesInputID: string = 'minutesID';

  private readonly maxValidHours: string = '23';
  private readonly maxValidMinutes: string = '59';
  private readonly hoursInputValueTitle: string = 'hoursInputValue';
  private readonly minutesInputValueTitle: string = 'minutesInputValue';

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.updateInputValues();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled?.currentValue === false) {
      this.updateInputValues();
    }
  }

  // Validate and set hours
  public onHoursChange(event: InputEvent): void {
    this.processInputData(this.hoursInputValueTitle, event);
  }

  // Validate and set minutes
  public onMinutesChange(event: InputEvent): void {
    this.processInputData(this.minutesInputValueTitle, event);
  }

  // Update inputs value
  private updateInputValues(): void {
    // We're checking by 'undefined' as here the value can possibly be 0
    this.hoursInputValue = this.hours === undefined ? '' : this.hours.toString();
    this.minutesInputValue = this.minutes === undefined ? '' : this.minutes.toString();
  }

  // Process input data, validate and set time
  private processInputData(target: string, event: InputEvent): void {
    const input = (event.target as HTMLInputElement).value.trim();

    if (this.hasOwnProperty(target)) {
      // Hours
      if (target === this.hoursInputValueTitle) {
        if (Number(input) > Number(this.maxValidHours)) {
          this.changeReference(this.hoursInputValueTitle, this.maxValidHours);
        } else {
          this.hoursInputValue = input;
        }

        if (this.hoursInputValue.length === 2) {
          this.minutesInputRef.nativeElement.focus();
        }
        // Minutes
      } else if (target === this.minutesInputValueTitle) {
        if (Number(input) > Number(this.maxValidMinutes)) {
          this.changeReference(this.minutesInputValueTitle, this.maxValidMinutes);
        } else {
          this.minutesInputValue = input;
        }
      }

      this.emitTime();
    }
  }

  // Highlight/select value on focus
  public onFocus(e: FocusEvent): void {
    const target = e.target as HTMLInputElement;

    if (target.value) {
      target.select();
    }
  }

  // Add leading zeros on blur if needed
  public onBlur(e: FocusEvent): void {
    const targetId = (e.target as HTMLElement).id;

    if (this.minutesInputValue.length || this.hoursInputValue.length) {
      if (targetId === this.hoursInputID) {
        if (this.hoursInputValue.length === 0 && this.minutesInputValue.length) {
          this.addLeadingZero(this.hoursInputValueTitle);
        }

        if (this.hoursInputValue.length && this.hoursInputValue.length < 2) {
          this.addLeadingZero(this.hoursInputValueTitle);
        }

        if (this.hoursInputValue.length && this.minutesInputValue.length === 0) {
          this.addLeadingZero(this.minutesInputValueTitle);

          if (this.hoursInputValue.length === 2) {
            // Here we have one edge case, long story short, it works please don't remove it!
            // We need to wait for the main thread to be idle before executing the following code else we don't achieve the expected behavior.
            // More appropriate solution would be to use 'requestIdleCallback' function but it's still experimental, therefore not fully supported! Instead I'm using setTimeout with 0 as time.
            // Example of requestIdleCallback function:

            // window.requestIdleCallback(() => this.minutesInputRef.nativeElement.select());

            setTimeout(() => {
              // Select the value only if the input is focused
              if (document.activeElement.id && document.activeElement.id === this.minutesInputID) {
                this.minutesInputRef.nativeElement.select();
              }
            }, 0);
          }
        }
      } else if (targetId === this.minutesInputID) {
        if (this.minutesInputValue.length === 0 && this.hoursInputValue.length) {
          this.addLeadingZero(this.minutesInputValueTitle);
        }

        if (this.minutesInputValue.length && this.minutesInputValue.length < 2) {
          this.addLeadingZero(this.minutesInputValueTitle);
        }

        if (this.minutesInputValue.length && this.hoursInputValue.length === 0) {
          this.addLeadingZero(this.hoursInputValueTitle);
        }
      }

      this.emitTime();
    }
  }

  // Emit time if it's valid
  private emitTime(): void {
    if (this.minutesInputValue.length === 2 && (this.hoursInputValue.length === 2 || this.hoursInputValue === '0')) {
      this.onTimeChange.emit({ hours: Number(this.hoursInputValue), minutes: Number(this.minutesInputValue) });
    }
  }

  // Add leading zero/s
  private addLeadingZero(target: string): void {
    if (this.hasOwnProperty(target) && this[target].length < 2) {
      this[target] = this[target].length ? '0'.concat(this[target]) : '00';
    }
  }

  // Change reference
  private changeReference(target: string, value: any): void {
    if (this.hasOwnProperty(target)) {
      this[target] = null;
      this.changeDetectorRef.detectChanges();
      this[target] = value;
    }
  }
}
