<app-modal>
  <app-modal-header [title]="T.common.update_headline_status | translate" (closed)="onClose()"></app-modal-header>

  <app-modal-body>
    <div class="update-modal-body">
      <div class="update-comment-section">
        <div class="comment-container">
          <app-text-area
            [(ngModel)]="headlineStatus"
            [placeholder]="T.defaultLocalizations.headline_status.one | translate"
            [maxLength]="255"
            [minLength]="3"
            [required]="false"
            [autofocus]="true"
          >
          </app-text-area>
        </div>
      </div>
    </div>
  </app-modal-body>

  <app-modal-footer style="margin-top: 24px">
    <div class="update-modal-footer">
      <app-button-rectangle
        class="modal-close"
        [text]="T.common.cancel | translate"
        [size]="'large'"
        class="mr-8"
        [buttonTheme]="'white'"
        (clicked)="onClose()"
      ></app-button-rectangle>
      <app-button-rectangle
        class="modal-add"
        [text]="T.common.save | translate"
        [size]="'large'"
        (click)="onUpdateHeadlineStatus()"
        [disabled]="isHeadlineStatusChanged"
      >
      </app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>
