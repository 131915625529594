import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
} from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-split-pane-details',
  templateUrl: './split-pane-details.component.html',
  styleUrls: ['./split-pane-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitPaneDetailsComponent {
  @Input() loading: boolean;
  @Input() canEdit: boolean = true;
  @Input() leftSideTemplate: TemplateRef<HTMLElement>;
  @Input() rightSideTemplate: TemplateRef<HTMLElement>;
  public readonly T = T;
}
