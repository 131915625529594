import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from '../../../enums/objectTypes';
import { LocalisationService } from '../../../services/localisation.service';
import { ZoneViewModel } from '../../../viewModels/zoneViewModel';

@Component({
  selector: 'app-fixed-zone-dropdown-select',
  templateUrl: './fixed-zone-dropdown-select.component.html',
  styleUrls: ['./fixed-zone-dropdown-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class FixedZoneDropdownSelectModalComponent implements OnInit, OnDestroy {
  @Output() fixedZoneChanged = new EventEmitter<number>();
  zones: ZoneViewModel[];
  selectedZone: ZoneViewModel;
  private readonly subscriptions = new Subscription();
  modalTitle = 'Select Zone';
  localisedZone = 'Zone';
  localisedZones = 'Zones';
  filteredZones: ZoneViewModel[] = [];
  public readonly T = T;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.localisedZone = this.localisationService.localiseObjectType(ObjectTypes.Zone);
    this.localisedZones = this.localisationService.localiseObjectType(ObjectTypes.Zone, true);
    this.modalTitle = this.translateService.instant(T.common.select_item, { item: this.localisedZone });
    this.filteredZones = JSON.parse(JSON.stringify(this.zones)) as ZoneViewModel[];
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onNoFixedZoneConfirmed() {
    this.setFixedZone(0);
  }

  onClose() {
    this.bsModalRef.hide();
  }

  isSelected(zone: ZoneViewModel) {
    return this.selectedZone && this.selectedZone.id === zone.id;
  }

  setSelected(zone: ZoneViewModel) {
    this.selectedZone = zone;
    this.changeDetectorRef.markForCheck();
  }

  onConfirm() {
    this.setFixedZone(this.selectedZone.id);
  }

  private setFixedZone(id: number) {
    this.fixedZoneChanged.emit(id);
    this.bsModalRef.hide();
  }

  searchZones(searchTerm: string) {
    if (!searchTerm || !searchTerm.length) {
      this.filteredZones = JSON.parse(JSON.stringify(this.zones)) as ZoneViewModel[];
    } else {
      this.filteredZones = this.zones.filter((z) => z.title.toUpperCase().includes(searchTerm.trim().toUpperCase()));
    }
  }

  trackFilteredZones(index: number, item: ZoneViewModel) {
    return item.id;
  }
}
