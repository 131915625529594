import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectTypes } from '../../../enums/objectTypes';
import { LocalisationService } from '../../../services/localisation.service';
import { EnumUtilities } from '../../../utilities/enum.utilities';

@Component({
  selector: 'app-side-pane-header',
  templateUrl: 'side-pane-header.component.html',
  styleUrls: ['side-pane-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePaneHeaderComponent {
  /**
   * Url for back button. In case when we want to navigate to specific url instead of going back with window.history.back(). The default behavior when this property is not passed is to use window.history.back().
   */
  @Input() url: string;
  @Input() objectType: ObjectTypes;
  @Input() iconType: string;
  @Input() objectTitle: string;
  @Input() outlinedIcon: boolean = true;
  @Input() handleBackClickExternaly: boolean = false;

  @Output() backClick = new EventEmitter();

  public readonly T = T;

  constructor(private readonly router: Router, private readonly localisationService: LocalisationService) {}

  ngOnInit() {
    if (!this.objectTitle) {
      this.objectTitle = this.localisationService.localiseObjectType(this.objectType);
    }

    if (!this.iconType) {
      this.iconType = this.getIconType();
    }
  }

  goBack(): void {
    if(this.handleBackClickExternaly) {
      this.backClick.emit();
      return;
    }

    if (this.url) {
      void this.router.navigate([this.url]);
      return;
    }
    window.history.back();
  }

  getIconType(): string {
    const objectType = EnumUtilities.items(ObjectTypes).find((t) => t.key === this.objectType);
    if (objectType) {
      return objectType.value;
    }
  }
}
