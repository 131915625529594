import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { ModuleService } from '../../../services/module.service';
import { Subscription } from 'rxjs';
import { Employee } from '../../../models/employee';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectTypes } from '../../../enums/objectTypes';

@Component({
  selector: 'app-details-tags',
  templateUrl: './details-tags.component.html',
  styleUrls: ['./details-tags.component.scss'],
  host: {
    '[class.d-flex]': '!accordionLozenge',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsTagsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  employee: Employee;

  //Input
  @Input() accordionLozenge: boolean = false;
  @Input() filters: FilterViewModel[];
  @Input() canEdit: boolean;
  @Input() tagGroupObjectType: ObjectTypes;
  // pass here bootstrap column classes (e.g. col-12)
  @Input() layoutClasses: string;
  @Input() accordionTagTitleClass: string;
  @Input() accordionTagValueClass: string;

  /**  child account Id for a hub to load only tags for specific Child account */
  @Input() childAccountId: number = 0;
  /**
   * Wheather to use simple update or not. If true, the component will emit only the updated filters.
   */
  @Input() useLightUpdate: boolean = false;

  @Output() onFiltersUpdated = new EventEmitter<FilterViewModel[]>();

  //public
  public allTagGroupFilters: FilterViewModel[] = [];
  public allTagFilters: FilterViewModel[] = [];
  public filterTypes = FilterTypes;
  public classes: string = '';

  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly authService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly moduleService: ModuleService
  ) {}

  ngOnInit() {
    this.classes = this.layoutClasses;
    this.employee = this.authService.getCurrentEmployee();
    this.initFilters();
  }

  initFilters() {
    this.allTagGroupFilters = this.allowedFiltersService
      .getCachedAllowedFiltersByType(FilterTypes.Tag_Group)
      .filter((s) => s.displayForGlobalObjectType === this.tagGroupObjectType);
    this.allTagFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Tag);

    if (this.childAccountId > 0) {
      this.allTagFilters = this.allTagFilters.filter((s) => s.accountId === this.childAccountId);
      this.allTagGroupFilters = this.allTagGroupFilters.filter((s) => s.accountId === this.childAccountId);
    }

    this.allTagGroupFilters = this.allTagGroupFilters.filter((group) => {
      const matchingTags = this.allTagFilters.filter(
        (f) => f.relatedObjectId && f.relatedObjectId.toString() == group.filterValue.toString()
      );
      return matchingTags && matchingTags.length;
    });

    this.changeDetectorRef.detectChanges();
  }

  updateFilters(filters: FilterViewModel[], tagGroup: string) {
    if(this.useLightUpdate) {
      this.onFiltersUpdated.next(filters);
    } else {
      const filtersByTagGroup = this.getTagFiltersByTagGroup(tagGroup);
      let updatedFilters: FilterViewModel[] = this.filters;

      filters.forEach((f) => {
        const match = updatedFilters.find((uf) => uf.filterValue === f.filterValue);
        if (match === undefined) {
          updatedFilters.push(f);
        }
      });

      filtersByTagGroup.forEach((ftg) => {
        const match = filters.find((f) => f.filterValue === ftg.filterValue);
        if (match === undefined) {
          updatedFilters = updatedFilters.filter((f) => f.filterValue != ftg.filterValue);
        }
      });

      this.onFiltersUpdated.next(updatedFilters);
    }
  }

  getTagFiltersByTagGroup(tagGroup: string) {
    const allTagFiltersByTagGroup = this.allTagFilters
      .filter((f) => f.relatedObjectId.toString() === tagGroup.toString())
      .map((t) => t.filterValue.toString());
    return this.filters.filter((f) => allTagFiltersByTagGroup.indexOf(f.filterValue.toString()) > -1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
