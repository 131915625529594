import { Component, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { ButtonSizes } from '../../../../enums/styles/buttonSizes.enum';
import { ButtonThemes } from '../../../../enums/styles/buttonThemes.enum';
import { ButtonClasses } from '../../../../enums/styles/buttonClasses.enum';

@Component({
  selector: 'app-button-icon-square',
  templateUrl: './button-icon-square.component.html',
  styleUrls: ['./button-icon-square.component.scss'],
})
export class ButtonIconSquareComponent {
  @Input() icon;
  @Input() size = ButtonSizes.Small;
  @Input() disabled = false;
  @Input() buttonTheme = ButtonThemes.White;
  @Input() loading = false;
  @Input() badge = false;
  @Input() badgeCount = 0;

  @Output() clicked = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  getButtonClasses() {
    const classesArr = [];
    classesArr.push(ButtonClasses[this.size]);
    classesArr.push(ButtonClasses[this.buttonTheme]);
    classesArr.push(this.isiOSSafari() ? 'safari' : 'not-safari');
    return classesArr;
  }

  onClick(e: Event): void {
    if (!this.loading) {
      this.clicked.emit(e);
    }
  }

  set loadingIndicator(state: boolean) {
    this.loading = state;
    this.changeDetectorRef.detectChanges();
  }

  isiOSSafari(): boolean {
    const userAgent = window.navigator.userAgent;
    const webkit = /WebKit/i.test(userAgent);
    const iOS = /iPad/i.test(userAgent) || /iPhone/i.test(userAgent);
    const iOSSafari = iOS && webkit && !/CriOS/i.test(userAgent);

    return iOSSafari;
  }
}
