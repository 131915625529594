import { Component, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-confirm-create-depend',
  templateUrl: './create-dependency-modal.component.html',
  styleUrls: ['./create-dependency-modal.component.scss'],
})
export class ModalConfirmCreateDependComponent {
  itemName: string;
  dependOn: string;

  public onClose: Subject<boolean> = new Subject();

  constructor(public bsModalRef: BsModalRef) {}

  @HostListener('document:keydown', ['$event']) escapeAndEnterHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onAdd();
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'Escape') {
      this.onCancel();
    }
  }

  onAdd() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  onCancel() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
