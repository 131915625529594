import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-list-search-input-fixed',
  templateUrl: './list-search-input-fixed.component.html',
  styleUrls: ['./list-search-input-fixed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSearchInputFixedComponent implements OnInit, OnChanges {
  private readonly debounceTime = 600;

  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

  @Input() value: string;
  @Input() placeholder: string;
  @Input() background: string;
  @Input() autofocus: boolean = false;
  @Output() inputChanged: EventEmitter<string> = new EventEmitter();

  input: UntypedFormControl;
  form: UntypedFormGroup;
  subscriptions: Subscription[] = [];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.input = new UntypedFormControl(this.value);
    this.form = new UntypedFormGroup({
      input: this.input,
    });

    this.subscriptions.push(
      this.input.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe((value) => {
        this.inputChanged.emit(value);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue !== changes.value.previousValue) {
      this.input?.setValue(changes.value.currentValue);
    }

    if (this.autofocus) {
      setTimeout(() => {
        this.inputElement.nativeElement.focus();
      });
    }
  }

  onClearInput() {
    this.input.setValue('');
    this.inputElement.nativeElement.focus();

    this.changeDetectorRef.markForCheck();
  }
}
