import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Position } from '../../../enums/position';
import { SortDropdownItem } from '../../../models/sortDropdownItem';

export function invokeDefaultSortDropdownItemHandler(sortDropdownItems: SortDropdownItem[]): void {
  const sortDropdownItem = sortDropdownItems.find((sdi) => sdi.default);

  sortDropdownItem.handler(...sortDropdownItem.parameters);
}

@Component({
  selector: 'app-sort-button-popup',
  templateUrl: './sort-button-popup.component.html',
  styleUrls: ['./sort-button-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortButtonPopupComponent implements OnChanges {
  @Input() sortDropdownItems: SortDropdownItem[];
  @Input() position: Position = Position.Bottom;
  @Input() arrowPosition: Position[] = [Position.Top, Position.Right];
  @Input() title = 'Sort';

  @Output() sortDropdownItemsChanged: EventEmitter<SortDropdownItem[]> = new EventEmitter();

  currentlySelectedIndex: number;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges() {
    if (!this.sortDropdownItems) {
      return;
    }

    this.currentlySelectedIndex = this.sortDropdownItems.findIndex((sdi) => sdi.default);
    this.changeDetectorRef.markForCheck();
  }

  onDropdownItemSelected(index: number, { handler, parameters }: SortDropdownItem) {
    handler(...parameters);

    const updated = this.sortDropdownItems.map((di, i) => {
      if (i === index) {
        di.default = true;
      } else {
        di.default = false;
      }

      return di;
    });

    this.sortDropdownItemsChanged.emit(updated);
  }
}
