import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Configuration } from "src/app/app.constants";
import { SuggestedRiskViewModel } from "../models/SuggestedRiskViewModel";
import { GlobalLibrarySimpleReturnModel } from "../models/globalLibrarySimpleReturnModel";

@Injectable({
  providedIn: 'root',
})
export class SuggestedRisksService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`Library/GlobalRisk/`);
  }

  getList(filters: any): Observable<SuggestedRiskViewModel[]> {
    return this.http.post<SuggestedRiskViewModel[]>(`${this.actionUrl}list`, filters);
  }

  getRiskActionsList(filters: any): Observable<any> {
    return this.http.post(`${this.actionUrl}controls/list`, filters);
  }

  getCategoryFilter(filters: any): Observable<any> {
    return this.http.post(`${this.actionUrl}categories`, filters);
  }

  getImpactTypes(filters: any): Observable<GlobalLibrarySimpleReturnModel[]> {
    return this.http.post<GlobalLibrarySimpleReturnModel[]>(`${this.actionUrl}impactTypes`, filters);
  }
}
