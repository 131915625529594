import { Injectable, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class HeaderEventsEmitter {
  private readonly headerViewInitialized = new Subject<void>();
  private readonly menuToggled = new Subject<boolean>();
  private readonly editModeToggled = new Subject<boolean>();
  private readonly appIsLoading = new Subject<boolean>();
  private venueDashboardSidebarToggled = new Subject<boolean>();

  headerViewInitialized$ = this.headerViewInitialized.asObservable();
  menuToggled$ = this.menuToggled.asObservable();
  editModeToggled$ = this.editModeToggled.asObservable();
  appIsLoading$ = this.appIsLoading.asObservable();
  venueDashboardSidebarToggled$ = this.venueDashboardSidebarToggled.asObservable();

  headerViewHasBeenInitialized: boolean = false;
  sidebarElementRef: ElementRef<HTMLElement>;

  broadcastHeaderViewInitialized() {
    this.headerViewHasBeenInitialized = true;
    this.headerViewInitialized.next();
  }

  broadcastMenuToggled(state: boolean) {
    this.menuToggled.next(state);
  }

  broadcastEditModeToggled(state: boolean) {
    this.editModeToggled.next(state);
  }

  broadcastAppIsLoading(loading: boolean) {
    this.appIsLoading.next(loading);
  }

  broadcastVenueDashboardSidebarToggled(state: boolean) {
    this.venueDashboardSidebarToggled.next(state);
  }
}
