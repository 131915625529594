import { Injectable, Type } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalConfig } from '../../config/modal.config';
import { PopupEventsEmitter } from '../../events/popup.events';
import { ObjectTypes } from '../../enums/objectTypes';
import { UpdateTypes } from '../../enums/updateTypes';
import { AddUpdateModal } from '../../components/modals/add-update/add-update-modal.component';
import { ShareItemModal } from '../../components/modals/share-item/share-item-modal.component';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { ReportItemViewModel } from 'src/app/modules/reporting/viewModels/reportItemViewModel';
import { ShareReportSettingsModalComponent } from 'src/app/modules/reporting/components/modals/share-report-settings-modal/share-report-settings-modal.component';
import { ReportSendIntervalTypes } from '../../enums/reports/reportSendIntervalTypes';
import { AlertService } from '../alert.service';
import { takeUntil } from 'rxjs';
import { PdfExportReportModalComponent } from 'src/app/modules/reporting/components/modals/pdf-export-report-modal/pdf-export-report-modal.component';
import { GanttTaskDetailsModalComponent } from 'src/app/modules/gantt/components/shared/modals/task-details/gantt-task-details-modal/gantt-task-details-modal.component';
import { DashboardWidgetConfigModalComponent } from '../../components/common/dashboard-widget-config-modal/dashboard-widget-config-modal.component';
import { UpdateHeadlineStatusModalComponent } from '../../components/modals/headline-status/update-headline-status-modal.component';
import { ModifiableEntityViewModel } from '../../viewModels/bases/modifiableEntityViewModel';
import { TaskDetailsModalComponent } from '../../components/modals/task-details-modal/task-details-modal.component';
import { FilterViewModel } from '../../models/filter/filterViewModel';
import { HubModalDataInterface } from '../../components/modals/interfaces/hub-modal-data.interface';
import { IApiService } from '../../interfaces/iHttpService';

interface ModalDatas {
  objectId: number;
  objectType: ObjectTypes;
  commentType: UpdateTypes;
  headerText?: string;
  message?: string;
  isRagUpdate?: boolean;
  placeholder?: string;
  childhubAccountId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ModalUtilityService {
  constructor(
    private readonly bsModalConfig: BsModalConfig,
    private readonly bsModalService: BsModalService,
    private readonly alertService: AlertService,
    private readonly popupEventsEmitter: PopupEventsEmitter
  ) {}

  /**
   * Opens modal to view and edit task similar to the task details page.
   *
   * @param task - the task details which will be displayed inside the modal
   * @param readonly - if set to true, the data will be readonly even though the user has permission to edit it.
   * @param allowedFilters - optional parameter to pass the allowed filters for the task details modal (hub Releated)
   * @returns a reference to the modal.
   */
  public openTaskDetailsModal(
    task: ModifiableEntityViewModel,
    allowedFilters: FilterViewModel[] = [],
    readonly = false,
    objectType = ObjectTypes.Global
  ): BsModalRef<TaskDetailsModalComponent> {
    const modalConfig: ModalOptions = {
      class: 'task-details-modal',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: {
        task: task,
        allowedFilters: allowedFilters,
        readonly: readonly,
        objectType: objectType,
      },
    };
    const modalParams = Object.assign({}, modalConfig);
    const bsModalRef = this.bsModalService.show(TaskDetailsModalComponent, modalParams);

    return bsModalRef;
  }

  /**
   * Opens modal to view and edit task similar to the task details page.
   *
   * @param task - the task details which will be displayed inside the modal
   * @param readonly - if set to true, the data will be readonly even though the user has permission to edit it.
   * @param allowedFilters - optional parameter to pass the allowed filters for the task details modal (hub Releated)
   * @returns a reference to the modal.
   */
  public openObjectDetailsModal<Tmodal>(modalComponent: Type<Tmodal>, data: HubModalDataInterface): BsModalRef<Tmodal> {
    const modalConfig: ModalOptions<Tmodal> = {
      class: 'task-details-modal',
      ignoreBackdropClick: true,
      keyboard: false,
      initialState: data as any,
    };
    const modalParams = Object.assign({}, modalConfig);
    const bsModalRef = this.bsModalService.show(modalComponent, modalParams);

    return bsModalRef;
  }

  /**
   * @param isRagUpdate this prop will show the long message for rag update
   */
  openAddUpdateModal(
    id: number,
    objectType: ObjectTypes,
    updateType: UpdateTypes,
    headerText: string = undefined,
    message: string = undefined,
    isRagUpdate: boolean = undefined,
    placeholder: string = undefined,
    childhubAccountId = 0
  ): BsModalRef<AddUpdateModal> {
    this.popupEventsEmitter.broadcastPopupCancelClicked();

    const modalConfig: ModalOptions<AddUpdateModal> = {
      ...this.bsModalConfig,
      class: 'modal-small-add',
      initialState: {
        objectId: id,
        objectType: objectType,
        commentType: updateType,
        headerText: headerText,
        message: message,
        isRagUpdate: isRagUpdate,
        placeholder: placeholder,
        childhubAccountId: childhubAccountId,
      },
    };
    const bsModalRef = this.bsModalService.show(AddUpdateModal, modalConfig);

    return bsModalRef;
  }

  openShareModal(
    id: number,
    objectType: ObjectTypes,
    title: string,
    customItemTitle?: string,
    incidentSubType?: IncidentItemTypes
  ): BsModalRef<ShareItemModal> {
    this.popupEventsEmitter.broadcastPopupCancelClicked();

    const modalConfig: ModalOptions<ShareItemModal> = {
      ...this.bsModalConfig,
      initialState: {
        id: id,
        objectType: objectType,
        title: title,
        customItemTitle: customItemTitle,
        incidentSubType: incidentSubType,
      },
    };

    const bsModalRef = this.bsModalService.show(ShareItemModal, modalConfig);

    return bsModalRef;
  }

  openShareReportModal(
    reportItem: ReportItemViewModel,
    isScheduled: boolean = false
  ): BsModalRef<ShareReportSettingsModalComponent> {
    const reportItemCopy: ReportItemViewModel = JSON.parse(JSON.stringify(reportItem));

    const modalConfig: ModalOptions<ShareReportSettingsModalComponent> = {
      ...this.bsModalConfig,
      class: isScheduled ? 'modal-large-add' : 'modal-small-add',
      initialState: {
        reportItem: reportItemCopy,
        isScheduled,
      },
    };

    const bsModalRef = this.bsModalService.show(ShareReportSettingsModalComponent, modalConfig);

    bsModalRef.content.onReportShare.pipe(takeUntil(bsModalRef.onHidden)).subscribe((newReport: ReportItemViewModel) => {
      if (newReport.sendIntervalType === ReportSendIntervalTypes.Right_Now) {
        void this.alertService.success(`Report shared successfully.`);
      } else {
        void this.alertService.success(`Report sharing schedule saved.`);
      }
    });

    return bsModalRef;
  }

  openExportReportModal(
    reportItem: ReportItemViewModel,
    pdfExport: { isExportSection: boolean; reportSectionIndex: number }
  ): BsModalRef<PdfExportReportModalComponent> {
    const modalConfig: ModalOptions<PdfExportReportModalComponent> = {
      ...this.bsModalConfig,
      class: 'modal-small-add',
      initialState: {
        reportItem: Object.assign({}, reportItem),
        singleSectionExport: pdfExport.isExportSection,
        reportSectionIndex: pdfExport.reportSectionIndex,
      },
    };

    const bsModalRef = this.bsModalService.show(PdfExportReportModalComponent, modalConfig);

    return bsModalRef;
  }

  openGanttTaskDetailModal(taskId: number): BsModalRef<GanttTaskDetailsModalComponent> {
    const modalConfig: ModalOptions = {
      class: 'gantt-light-box-modal',
      keyboard: false, //IMPORTANT!!! DO NOT CHANGE!!!
      ignoreBackdropClick: true,
      initialState: {
        selectedTaskId: taskId,
      },
    };
    const modalParams = Object.assign({}, modalConfig);
    const bsModalRef = this.bsModalService.show(GanttTaskDetailsModalComponent, modalParams);

    return bsModalRef;
  }

  openDashboardWidgetConfigModal(
    dashboardWidgetName: string,
    moduleType = null
  ): BsModalRef<DashboardWidgetConfigModalComponent> {
    const modalConfig: ModalOptions = {
      class: '',
      ignoreBackdropClick: true,
      backdrop: true,
      initialState: {
        dashboardWidgetName: dashboardWidgetName,
        moduleType: moduleType,
      },
    };
    const modalParams = Object.assign({}, modalConfig);
    const bsModalRef = this.bsModalService.show(DashboardWidgetConfigModalComponent, modalParams);

    return bsModalRef;
  }

  openUpdateHeadlineStatus(
    project: ModifiableEntityViewModel,
    service: IApiService
  ): BsModalRef<UpdateHeadlineStatusModalComponent> {
    const modalConfig: ModalOptions = {
      class: '',
      ignoreBackdropClick: true,
      backdrop: true,
      initialState: {
        object: project,
        service: service,
        activeLightUpdate: false,
      },
    };
    const modalParams = Object.assign({}, modalConfig);
    const bsModalRef = this.bsModalService.show(UpdateHeadlineStatusModalComponent, modalParams);

    return bsModalRef;
  }
}
