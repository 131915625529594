import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WtTimepickerComponent } from '../components/common/wt-timepicker/wt-timepicker.component';
import { WindowEventsEmitter } from '../events/window.events';
import { DynamicComponentsService } from '../services/dynamicComponents.service';
import { isChildOf } from '../utilities/html.utilities';

@Directive({
  selector: '[wtTimepicker]',
  host: {
    '(click)': 'onClick($event)',
  },
})
export class WtTimepickerDirective implements OnInit, OnChanges, OnDestroy {
  @Input() date: string | Date;
  @Output() timeChanged: EventEmitter<string> = new EventEmitter();
  @Output() timeRemoved: EventEmitter<boolean> = new EventEmitter();

  static wtTimepickerComponentRef: ComponentRef<WtTimepickerComponent>;

  private _clickOutEventSubscription: Subscription = null;
  private _routerEventSubscription: Subscription = null;
  private _scrollEventSubscription: Subscription = null;
  private onHideSubscription = new Subscription();

  constructor(
    private readonly dynamicComponentsService: DynamicComponentsService,
    private readonly appRef: ApplicationRef,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly windowEvents: WindowEventsEmitter,
    private readonly renderer: Renderer2,
    private readonly router: Router
  ) {
    if (!WtTimepickerDirective.wtTimepickerComponentRef) {
      this.appendToBody();
      // this.initGlobalScrollHandler();
    }
  }

  onClick() {
    this.updatePosition();
    this.updateInstance();
    this.getWtTimepickerInstance().show();
    this.subscribeForWindowClick();
    this.subscribeForRouteChange();
  }

  ngOnInit() {
    this.onHideSubscription.add(
      this.getWtTimepickerInstance().onHide.subscribe((r) => {
        this.unsubscribeFromEvents();
      })
    );
  }

  private updatePosition() {
    if (!this.getWtTimepickerElement()) {
      return;
    }

    // const datepicker = this.getWtTimepickerElement().firstElementChild as HTMLElement;

    // datepicker.style.left = '50%';
    // datepicker.style.top = '50%';
    // datepicker.style.transform = 'translate(-50%, -50%)';
  }

  ngOnChanges() {
    if (this.getWtTimepickerInstance().visible && this.getWtTimepickerInstance().directiveElementRef == this.elementRef) {
      this.updateInstance();
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromEvents();
    this.onHideSubscription.unsubscribe();
  }

  subscribeForWindowClick() {
    this._clickOutEventSubscription = this.windowEvents.windowClickEventTriggered$.subscribe((e: Event) => {
      this.onDocumentClick(e);
    });
  }
  unsubscribeFromWindowClick() {
    if (this._clickOutEventSubscription) {
      this._clickOutEventSubscription.unsubscribe();
    }
  }

  // We need to close the datepicker when route changes
  subscribeForRouteChange() {
    this._routerEventSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => {
      this.getWtTimepickerInstance().close();
      this.unsubscribeFromEvents();
    });
  }
  unsubscribeFromRouterEvents() {
    if (this._routerEventSubscription) {
      this._routerEventSubscription.unsubscribe();
    }
  }

  private updateInstance() {
    if (!this.getWtTimepickerInstance()) {
      return;
    }

    this.getWtTimepickerInstance().date = this.date;
    // this.getWtTimepickerInstance().minDate = this.minDate;
    // this.getWtTimepickerInstance().maxDate = this.maxDate;
    // this.getWtTimepickerInstance().helperDate = this.helperDate;
    this.getWtTimepickerInstance().timeChanged = this.timeChanged;
    this.getWtTimepickerInstance().timeRemoved = this.timeRemoved;
    // this.getWtTimepickerInstance().isEndDate = this.isEndDate;
    // this.getWtTimepickerInstance().initMonth();
    // this.getWtTimepickerInstance().initYear();
    this.getWtTimepickerInstance().directiveElementRef = this.elementRef;

    this.datepickerChangeDetectorRef.detectChanges();
  }

  onDocumentClick({ target }: Event) {
    if (!this.getWtTimepickerInstance().visible) {
      return;
    }

    if (this.getWtTimepickerInstance().directiveElementRef !== this.elementRef) {
      return;
    }

    const targetIsNotPartOfTheDatepicker =
      target !== this.getWtTimepickerElement() && !isChildOf(this.getWtTimepickerElement(), target as HTMLElement);
    const targetIsNotPartOfTheElementRef =
      target !== this.elementRef.nativeElement && !isChildOf(this.elementRef.nativeElement, target as HTMLElement);

    if (targetIsNotPartOfTheDatepicker && targetIsNotPartOfTheElementRef) {
      this.getWtTimepickerInstance().close();
      this.unsubscribeFromEvents();
    }
  }

  get datepickerChangeDetectorRef(): ChangeDetectorRef {
    return WtTimepickerDirective.wtTimepickerComponentRef.changeDetectorRef;
  }

  private getWtTimepickerElement(): HTMLElement {
    return this.getDomElement(WtTimepickerDirective.wtTimepickerComponentRef);
  }

  private getWtTimepickerInstance(): WtTimepickerComponent {
    return WtTimepickerDirective.wtTimepickerComponentRef.instance;
  }

  private getDomElement<T>(componentRef: ComponentRef<T>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<T>).rootNodes[0] as HTMLElement;
  }

  private appendToBody() {
    WtTimepickerDirective.wtTimepickerComponentRef = this.dynamicComponentsService.createComponentElement(
      WtTimepickerComponent,
      {}
    );
    this.appRef.attachView(WtTimepickerDirective.wtTimepickerComponentRef.hostView);
    document.body.appendChild(this.getDomElement(WtTimepickerDirective.wtTimepickerComponentRef));
  }

  private unsubscribeFromEvents() {
    this.unsubscribeFromWindowClick();
    this.unsubscribeFromRouterEvents();
  }
}
