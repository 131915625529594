<div #map style="width: 100%; height: 400px"></div>

<div #topLeftControlsWrapper class="top-left-controls-wrapper" [ngClass]="{ 'hidden': !mapControlsReady }">
  <div #searchControlBtnWrapper *ngIf="mapTilesLoaded">
    <app-search-field
      #searchControl
      [map]="gmap"
      [zones]="zones"
      [areas]="areas"
      (searchStateChanged)="onSearchStateChanged($event)"
      (autocompleteResult)="onAutocompleteResult($event)"
      (what3WordsResult)="onWhat3WordsResult($event)"
    >
    </app-search-field>
  </div>
  <div #myLocationControlBtnWrapper *ngIf="mapTilesLoaded" class="margin-left-fix">
    <app-my-location-button (locationAvailableEvent)="onMyLocationAvailable($event)"></app-my-location-button>
  </div>
</div>

<div #topCenterControlWrapper [ngClass]="{ 'hidden': !mapControlsReady }">
  <app-cancel-drawing-polygon-button *ngIf="newDrawingZonePolygon && zonesAndAreasLoaded"
    (clickedEvent)="onCancelDrawingPolygonClicked()"
  >
  </app-cancel-drawing-polygon-button>
</div>

<div #zoneAreaControlBtnWrapper [ngClass]="{ 'zone-area-control-wrapper': !isMobile, 'hidden': !mapControlsReady }">
  <app-zone-area-toggle-button *ngIf="zonesAndAreasLoaded"
    [map]="gmap"
    [zonesPolygon]="zonesPolygon"
    [areasPolygon]="areasPolygon"
    [enableStateChange]="enableZonesAreasStateChange"
  ></app-zone-area-toggle-button>
</div>
