<div class="bs-datepicker-wrapper">
  <div class="cool-input cool-input-with-icon" [class.disable-margin]="disableMargin">
    <input
      #input
      type="time"
      class="form-control input-label-shown"
      value="{{ formattedValue }}"
      (change)="onChangeNative()"
      [min]="minTime"
      [max]="maxTime"
      [disabled]="disabled"
      [class.cool-custom-input-invalid]="!valid"
    />
    <label>{{ placeholder }}</label>
    <span class="cool-input-icon"><i class="material-symbols-outlined">schedule</i></span>
  </div>
</div>
