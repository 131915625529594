import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent {
  @Input() isChecked: boolean | undefined;
  @Input() disabled: boolean | undefined;
  @Input() needConfirmation: boolean | undefined;
  @Input() isConfirmed: boolean | undefined;
  @Input() clickEventNeedsToBeBubbling: boolean | undefined;

  @Output() checked: EventEmitter<boolean | void> = new EventEmitter();

  onChecked(e: Event) {
    e.preventDefault();

    if (!this.clickEventNeedsToBeBubbling) {
      e.stopPropagation();
    }

    if (this.disabled) {
      return;
    }

    if (this.needConfirmation && !this.isConfirmed) {
      this.checked.emit();
    } else {
      this.isChecked = !this.isChecked;
      this.checked.emit(this.isChecked);
    }
  }
}
