<div class="d-flex align-items-center" [class.disabled]="disabled">
  <div class="cool-input">
    <input
      id="{{ hoursInputID }}"
      type="number"
      placeholder="HH"
      (focus)="onFocus($event)"
      (input)="onHoursChange($event)"
      (blur)="onBlur($event)"
      [(ngModel)]="hoursInputValue"
      [class.cool-custom-input-invalid]="displayError"
    />
  </div>

  &nbsp;:&nbsp;

  <div class="cool-input">
    <input
      #minutesInput
      id="{{ minutesInputID }}"
      type="number"
      placeholder="MM"
      (focus)="onFocus($event)"
      (input)="onMinutesChange($event)"
      (blur)="onBlur($event)"
      [(ngModel)]="minutesInputValue"
      [class.cool-custom-input-invalid]="displayError"
    />
  </div>
</div>
