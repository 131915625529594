import { NavigationItem } from "./navigationItem";

export class HubSideNavModel {
    navigationGroups: NavigationGroup[];
    selectedItem: any;
    selectedGroupItem: any;
    constaintItems: any[];
    bottomItems: any[];
    userItem: any;

    constructor() {
        this.navigationGroups = [];
        this.constaintItems = [];
        this.bottomItems = [];
    }
}

export class NavigationGroup {
    title: string | null;
    items: NavigationItem[];
}
