import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { LightFilterUpdateModel } from 'src/app/modules/shared/models/filter/LightFilterUpdateModel';
import { IncidentCategoryViewModel } from 'src/app/modules/incidents/viewModels/incidentCategoryViewModel';

@Injectable({providedIn: 'root'})
export class IncidentCategoriesService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`IncidentCategory/`);
  }

  //categories

  list(): Observable<IncidentCategoryViewModel[]> {
    return this.http.get<IncidentCategoryViewModel[]>(this.actionUrl);
  }

  add(model: IncidentCategoryViewModel): Observable<IncidentCategoryViewModel> {
    return this.http.post<IncidentCategoryViewModel>(this.actionUrl, model);
  }

  details(categoryId: number): Observable<IncidentCategoryViewModel> {
    return this.http.get<IncidentCategoryViewModel>(`${this.actionUrl}${categoryId}`);
  }

  update(model: IncidentCategoryViewModel): Observable<void> {
    return this.http.put<void>(this.actionUrl + 'Update', model);
  }

  delete(categoryId: number): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}${categoryId}`);
  }

  updateLight(item: LightFilterUpdateModel) {
    return this.http.post<LightFilterUpdateModel>(`${this.actionUrl}UpdateLight`, item);
  }
}
