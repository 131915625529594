export enum EmployeeSettingTypes {
  Comms_Receive_Notifications = 401,
  Comms_Receive_Export = 402,
  Comms_Receive_Invoices = 403,
  Sales_Target = 501,
  Sales_Target_Total = 502,
  Allow_Budget_Fields = 600,
  Can_Only_See_Assigned_Tasks = 601,

  Dashboard_View_Venues = 700,
  Dashboard_View_Departments = 701,
  Dashboard_View_Department_Categories = 702,
  Notification_Summary_Changes = 1004,

  Notification_Sustainability_Summary = 1006,
  Notification_Planning_Changes = 1010,
  Notification_Tasks_In_Venue = 1012,

  Notification_Incident_Changes = 1020,

  Notification_PEAP_Changes = 1035,


  View_Mode = 1040,
  Incident_Card_Subtitle_Type = 1041,
  Job_Card_Subtitle_Type = 1042,
  Log_Card_Subtitle_Type = 1043,
  Employee_Time_Zone = 1050,
  Date_Format = 1051,

  Incidents_Dashboard_Widget_Layout = 2000,
  Temperature_Option = 2001,
  Venues_Dashboard_Widget_Layout = 2002,

  PEAPS_Dashboard_Widget_Layout = 2004,
  Venue_Dashboard_Task_RAG_Widget = 2100,

  Default_Incident_Channel = 2110,
  Card_Filters = 2120,

  ICS_URL_Tasks = 2130,
  ICS_URL_Risks = 2131,
  ICS_URL_Actions = 2132,

  Notifications_Teams = 2140,
  Notifications_WhatsApp = 2141,
  Notifications_StandardMessage = 2142,
  Notifications_Incoming_Public_Incident_Reports = 2143,

  Language = 2150,
  HideSuggestedRisks = 2151,

  ShowSuggestedIndicators = 2152
}
