import { JobPriorities } from 'src/app/modules/shared/enums/jobs/jobpriorities';
import { JobStatuses } from 'src/app/modules/shared/enums/jobs/jobStatuses';
import { PrivacyStatuses } from 'src/app/modules/shared/enums/privacyStatuses';
import { SubscriberViewModel } from 'src/app/modules/shared/models/subscriberViewModel';
import { EmployeeBasicViewModel } from 'src/app/modules/shared/viewModels/employeeBasicViewModel';
import { ModifiableEntityViewModel } from '../modifiableEntityViewModel';

export class JobItemViewModel extends ModifiableEntityViewModel {
  jobTypeId: number;
  archived: boolean;
  created: string;
  latitude: number;
  longitude: number;
  locationDetails: string;
  description: string;
  headlineStatus: string;
  headlineStatusUpdated: string;
  createdBy: EmployeeBasicViewModel;
  updated: string;
  start: string;
  due: string;
  commentsCount: number;
  attachmentsCount: number;
  status: JobStatuses;
  privacyStatus: PrivacyStatuses;
  accountId: number;
  priority: JobPriorities;
  subscribers?: SubscriberViewModel[];
  eventId: number;
  startTime: string;
  endTime: string;
  allChecksCount: number;
  completedChecksCount: number;
  unsureChecksCount: number;
  failedChecksCount: number;
}
