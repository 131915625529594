import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

export type CheckboxStates = 'checked' | 'unchecked' | 'indeterminate';

@Component({
  selector: 'app-checkbox-with-label',
  templateUrl: './checkbox-with-label.component.html',
  styleUrls: ['./checkbox-with-label.component.scss'],
})
export class CheckboxWithLabelComponent implements OnInit, OnChanges {
  @Input() intialState: CheckboxStates;
  @Input() label: string;
  @Input() indeterminate: boolean;
  @Input() disabled: boolean;

  @Output() clicked = new EventEmitter<CheckboxStates>();
  state: CheckboxStates;
  isIndeterminate: boolean;
  public customCheckboxId = uuidv4() as string;

  ngOnInit(): void {
    this.state = this.intialState;
    if (this.intialState === 'indeterminate') {
      this.isIndeterminate = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.intialState && changes.intialState.currentValue) {
      this.state = changes.intialState.currentValue as CheckboxStates;
    }
  }

  getState() {
    if (this.state === 'checked') {
      return true;
    } else if (this.state === 'unchecked') {
      return false;
    } else if (this.state === 'indeterminate') {
      return false;
    }
  }

  onClick() {
    if (this.state === 'unchecked') {
      if (this.indeterminate) {
        this.state = 'indeterminate';
        this.isIndeterminate = true;
      } else {
        this.state = 'checked';
      }
    } else if (this.state === 'indeterminate') {
      this.isIndeterminate = false;
      this.state = 'checked';
    } else if (this.state === 'checked') {
      if (this.indeterminate) {
        this.state = 'unchecked';
        this.isIndeterminate = false;
      } else {
        this.state = 'unchecked';
      }
    }
    this.clicked.emit(this.state);
  }

  public static toBooleanState(state: string, treatIndeterminateStateAsChecked: boolean = false): boolean {
    if (state === 'unchecked') return false;
    if (state === 'indeterminate') {
      return treatIndeterminateStateAsChecked;
    }

    return true;
  }
}
