import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { WindowEventsEmitter } from '../../../events/window.events';
import { IDropdownState } from '../../../models/interfaces/dropdown-state.interface';
import { isChildOf } from '../../../utilities/html.utilities';

@Component({
  selector: 'app-select-dropdown',
  styleUrls: ['./select-dropdown.component.scss'],
  templateUrl: './select-dropdown.component.html',
})
export class SelectDropdownComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() hideDropdown: boolean = false;

  @Output() dropdownStateChanged = new EventEmitter<IDropdownState>();

  @ViewChild('wrapper') wrapperElementRef: ElementRef<HTMLDivElement>;

  expanded: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(private readonly windowEventsEmitter: WindowEventsEmitter, private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscriptions.push(
      this.windowEventsEmitter.windowClickEventTriggered$.subscribe((e: Event) => {
        if (
          this.expanded &&
          e.target !== this.wrapperElementRef.nativeElement &&
          !isChildOf(this.wrapperElementRef.nativeElement, e.target as HTMLElement)
        ) {
          this.toggleDropdown(e);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  toggleDropdown(e: Event) {
    e.stopPropagation();

    this.expanded = !this.expanded;
    this.changeDetectorRef.detectChanges();
    this.emitDropdownState();
    this.windowEventsEmitter.broadcastWindowClickEventTriggered(e);
  }

  private emitDropdownState(): void {
    this.dropdownStateChanged.emit({
      isToggled: !this.expanded,
      wrapper: this.wrapperElementRef.nativeElement,
    });
  }
}
