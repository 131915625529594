<div #icon class="icon">
  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'Risk'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 32 32">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H32V32H0z" [attr.fill]="backgroundFill" />
            <path
              [attr.fill]="innerFill"
              fill-rule="nonzero"
              d="M16 2.33c.656.016 1.246.404 1.52 1l11.86 21.8c.41.712.41 1.588 0 2.3-.295.545-.86.888-1.48.9H4.14c-.62-.012-1.185-.355-1.48-.9-.41-.712-.41-1.588 0-2.3L14.48 3.31c.274-.596.864-.984 1.52-1m0-1c-1.018.012-1.944.59-2.4 1.5L1.74 24.65c-1.12 2.06.22 4.68 2.4 4.68h23.72c2.18 0 3.52-2.62 2.4-4.68L18.4 2.83c-.456-.91-1.382-1.488-2.4-1.5v-.02z"
            />
            <path
              [attr.fill]="innerFill"
              fill-rule="nonzero"
              d="M17.33 21.33L14.67 21.33 14.67 24 17.33 24 17.33 21.33zM17.33 10.67L14.67 10.67 14.67 18.67 17.33 18.67z"
            />
          </g>
        </svg>
      </ng-container>

      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H48V48H0z" />
            <path
              [attr.fill]="backgroundFill"
              fill-rule="nonzero"
              d="M45.4 36.98L27.6 4.26c-.668-1.38-2.067-2.256-3.6-2.256s-2.932.876-3.6 2.256L2.6 36.98c-.817 1.378-.87 3.079-.139 4.504C3.192 42.91 4.604 43.86 6.2 44h35.6c1.596-.14 3.008-1.09 3.739-2.516.73-1.425.678-3.126-.139-4.504zM26 36h-4v-4h4v4zm0-8h-4V16h4v12z"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Issue'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 32 32">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H32V32H0z" [attr.fill]="backgroundFill" />
            <path
              [attr.fill]="innerFill"
              fill-rule="nonzero"
              d="M16 2.33c.534.005 1.057.157 1.51.44L26.87 8c.913.571 1.465 1.574 1.46 2.65v10.68c.022 1.079-.52 2.09-1.43 2.67l-9.42 5.29c-.916.578-2.084.578-3 0L5.13 24c-.913-.571-1.465-1.574-1.46-2.65V10.67C3.648 9.591 4.19 8.58 5.1 8l9.42-5.29c.45-.258.961-.389 1.48-.38m0-1c-.705.002-1.396.196-2 .56L4.61 7.16c-1.212.754-1.947 2.082-1.94 3.51v10.66c-.007 1.428.728 2.756 1.94 3.51L14 30.11c1.229.747 2.771.747 4 0l9.39-5.27c1.212-.754 1.947-2.082 1.94-3.51V10.67c.007-1.428-.728-2.756-1.94-3.51L18 1.89c-.604-.364-1.295-.558-2-.56z"
            />
            <path
              [attr.fill]="innerFill"
              fill-rule="nonzero"
              d="M17.33 20L14.67 20 14.67 22.67 17.33 22.67zM17.33 9.33L14.67 9.33 14.67 17.33 17.33 17.33 17.33 9.33z"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H48V48H0z" />
            <path
              [attr.fill]="backgroundFill"
              fill-rule="nonzero"
              d="M41.08 10.74l-14-7.9c-1.843-1.12-4.157-1.12-6 0l-14 8C5.221 11.903 4.053 13.86 4 16v16c-.015 2.144 1.093 4.14 2.92 5.26l14 7.9c1.843 1.12 4.157 1.12 6 0l14-7.9c1.889-1.081 3.062-3.084 3.08-5.26V16c.015-2.144-1.093-4.14-2.92-5.26zM26 34h-4v-4h4v4zm0-8h-4V14h4v12z"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Opportunity'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 32 32">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H32V32H0z" [attr.fill]="backgroundFill" />
            <path
              [attr.fill]="innerFill"
              fill-rule="nonzero"
              d="M16 2.33c.706 0 1.382.28 1.88.78l11 11c1.038 1.039 1.038 2.721 0 3.76l-11 11c-1.039 1.038-2.721 1.038-3.76 0l-11-11c-1.038-1.039-1.038-2.721 0-3.76l11-11c.498-.5 1.174-.78 1.88-.78m0-1c-.971-.001-1.903.384-2.59 1.07l-11 11c-1.421 1.434-1.421 3.746 0 5.18l11 11c1.432 1.426 3.748 1.426 5.18 0l11-11c1.421-1.434 1.421-3.746 0-5.18l-11-11c-.687-.686-1.619-1.071-2.59-1.07z"
            />
            <path
              [attr.fill]="innerFill"
              fill-rule="nonzero"
              d="M6.96 23.15L15.45 14.67 12 14.67 12 12 17.33 12 20 12 20 14.67 20 20 17.33 20 17.33 16.55 8.85 25.04"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H48V48H0z" />
            <path
              [attr.fill]="backgroundFill"
              fill-rule="nonzero"
              d="M44.4 20.12L27.88 3.6c-2.146-2.136-5.614-2.136-7.76 0L3.6 20.12c-2.136 2.146-2.136 5.614 0 7.76l6.84 6.84L23.18 22H18v-4h12v12h-4v-5.18L13.28 37.56l6.84 6.84c2.146 2.136 5.614 2.136 7.76 0L44.4 27.88c2.136-2.146 2.136-5.614 0-7.76z"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Project'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 32 32">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H32V32H0z" />
            <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="#353F47" rx="4" />
            <path
              fill="#353F47"
              fill-rule="nonzero"
              d="M23.111 9.778H16L14.222 8H8.89c-.982 0-1.778.796-1.778 1.778v12.444c0 .982.796 1.778 1.778 1.778H23.11c.982 0 1.778-.796 1.778-1.778V11.556c0-.982-.796-1.778-1.778-1.778zm0 12.444H8.89V11.556H23.11v10.666z"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H48V48H0z" />
            <rect width="40" height="40" x="4" y="4" [attr.fill]="backgroundFill" rx="6" />
            <path
              fill="#FFF"
              fill-rule="nonzero"
              d="M34.667 14.667H24L21.333 12h-8c-1.472 0-2.666 1.194-2.666 2.667v18.666c0 1.473 1.194 2.667 2.666 2.667h21.334c1.472 0 2.666-1.194 2.666-2.667v-16c0-1.472-1.194-2.666-2.666-2.666zm0 18.666H13.333v-16h21.334v16z"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Task'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 32 32">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H32V32H0z" />
            <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="#353F47" rx="4" />
            <g fill="#353F47" fill-rule="nonzero">
              <path
                d="M5.298 10.667L.667 6 2.533 4.124 5.298 6.898 12.133 0 14 1.876zM.222 13.333H14.443999999999999V15.111H.222z"
                transform="translate(8.667 8)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H48V48H0z" />
            <rect width="40" height="40" x="4" y="4" [attr.fill]="backgroundFill" rx="6" />
            <g fill="#FFF" fill-rule="nonzero">
              <path
                d="M7.947 16L1 9 3.8 6.187 7.947 10.347 18.2 0 21 2.813zM.333 20H21.665999999999997V22.667H.333z"
                transform="translate(13 12)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Sub_Task'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 32 32">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H32V32H0z" />
            <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="#353F47" rx="4" />
            <g fill="#353F47" fill-rule="nonzero">
              <path
                d="M4.338 9.333L.568 5.531 2.089 3.999 4.338 6.259 9.911.642 11.432 2.174zM4.338 18.025L.568 14.222 2.089 12.69 4.338 14.95 9.911 9.333 11.432 10.865z"
                transform="translate(10 6.667)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H48V48H0z" />
            <rect width="40" height="40" x="4" y="4" [attr.fill]="backgroundFill" rx="6" />
            <g fill="#FFF" fill-rule="nonzero">
              <path
                d="M6.507 14L.852 8.296 3.133 5.999 6.507 9.388 14.867.963 17.148 3.261zM6.507 27.037L.852 21.333 3.133 19.036 6.507 22.425 14.867 14 17.148 16.298z"
                transform="translate(15 10)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Risk_Action'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 32 32">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H32V32H0z" />
            <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="#353F47" rx="4" />
            <path d="M6 6L26 6 26 26 6 26z" />
            <path
              fill="#353F47"
              fill-rule="nonzero"
              d="M22.667 9.333v10h-10v-10h10m0-1.666h-10c-.917 0-1.667.75-1.667 1.666v10c0 .917.75 1.667 1.667 1.667h10c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666zm-6.275 10L13.5 14.75l1.167-1.175 1.725 1.733L20.667 11l1.166 1.175-5.441 5.492zM9.333 11H7.667v11.667c0 .916.75 1.666 1.666 1.666H21v-1.666H9.333V11z"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0L48 0 48 48 0 48z" />
            <path
              [attr.fill]="backgroundFill"
              fill-rule="nonzero"
              d="M40 8v24H16V8h24m0-4H16c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4zM24.94 28L18 21l2.8-2.82 4.14 4.16L35.2 12l2.8 2.82L24.94 28zM8 12H4v28c0 2.2 1.8 4 4 4h28v-4H8V12z"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Risk_Action_Item'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 32 32">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H32V32H0z" />
            <rect width="25.667" height="25.667" x="3.167" y="3.167" stroke="#353F47" rx="4" />
            <path d="M6 6L26 6 26 26 6 26z" />
            <path
              fill="#353F47"
              fill-rule="nonzero"
              d="M22.667 9.333v10h-10v-10h10m0-1.666h-10c-.917 0-1.667.75-1.667 1.666v10c0 .917.75 1.667 1.667 1.667h10c.916 0 1.666-.75 1.666-1.667v-10c0-.916-.75-1.666-1.666-1.666zm-6.275 10L13.5 14.75l1.167-1.175 1.725 1.733L20.667 11l1.166 1.175-5.441 5.492zM9.333 11H7.667v11.667c0 .916.75 1.666 1.666 1.666H21v-1.666H9.333V11z"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0L48 0 48 48 0 48z" />
            <path
              [attr.fill]="backgroundFill"
              fill-rule="nonzero"
              d="M40 8v24H16V8h24m0-4H16c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4V8c0-2.2-1.8-4-4-4zM24.94 28L18 21l2.8-2.82 4.14 4.16L35.2 12l2.8 2.82L24.94 28zM8 12H4v28c0 2.2 1.8 4 4 4h28v-4H8V12z"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'IncidentItem'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24">
          <path
            fill="#353F47"
            d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24">
          <path
            [attr.fill]="backgroundFill"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"
          />
        </svg>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Incident'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24">
          <path
            fill="#353F47"
            d="M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 24 24">
          <path
            [attr.fill]="backgroundFill"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4h-2v-2h2v2z"
          />
        </svg>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Incident_Bubble'">
      <ng-container *ngIf="ragStatus">
        <app-bubble [large]="false" [color]="backgroundFill" [value]="ragStatus"> </app-bubble>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Report'">
      <ng-container *ngIf="outlined"> </ng-container>
      <ng-container *ngIf="!outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#353f47"
          [attr.width]="size"
          [attr.height]="size"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill="#353f47"
            d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"
          />
        </svg>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Task_Group'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0L48 0 48 48 0 48z" />
            <path fill="#353F47" fill-rule="nonzero" d="M20.94 34L14 27 16.8 24.18 20.94 28.34 31.2 18 34 20.82z" />
            <path
              fill="#353F47"
              fill-rule="nonzero"
              d="M40 10H24l-4-4H8c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 28H8V14h32v24z"
            />
          </g>
        </svg>
      </ng-container>
      <!-- They are the same for now, replace that if we going to have not outlined -->
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0L48 0 48 48 0 48z" />
            <path
              [attr.fill]="backgroundFill"
              fill-rule="nonzero"
              d="M20.94 34L14 27 16.8 24.18 20.94 28.34 31.2 18 34 20.82z"
            />
            <path
              [attr.fill]="backgroundFill"
              fill-rule="nonzero"
              d="M40 10H24l-4-4H8c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V14c0-2.21-1.79-4-4-4zm0 28H8V14h32v24z"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Department'">
      <ng-container *ngIf="outlined"> </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H48V48H0z" />
            <rect width="40" height="40" x="3.167" y="3.167" [attr.fill]="backgroundFill" rx="6" />
            <g>
              <path d="M0 0L32 0 32 32 0 32z" transform="translate(8 8)" />
              <path
                fill="#FFF"
                fill-rule="nonzero"
                d="M21.333 14.667c2.214 0 3.987-1.787 3.987-4 0-2.214-1.773-4-3.987-4-2.213 0-4 1.786-4 4 0 2.213 1.787 4 4 4zm-10.666 0c2.213 0 3.986-1.787 3.986-4 0-2.214-1.773-4-3.986-4-2.214 0-4 1.786-4 4 0 2.213 1.786 4 4 4zm0 2.666c-3.107 0-9.334 1.56-9.334 4.667v3.333H20V22c0-3.107-6.227-4.667-9.333-4.667zm10.666 0c-.386 0-.826.027-1.293.067 1.547 1.12 2.627 2.627 2.627 4.6v3.333h8V22c0-3.107-6.227-4.667-9.334-4.667z"
                transform="translate(8 8)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Department_Category'">
      <ng-container *ngIf="outlined"> </ng-container>
      <ng-container *ngIf="!outlined">
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="size" [attr.height]="size" viewBox="0 0 48 48">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0H48V48H0z" />
            <rect width="40" height="40" x="3.167" y="3.167" [attr.fill]="backgroundFill" rx="6" />
            <g>
              <path d="M0 0L32 0 32 32 0 32z" transform="translate(8 8)" />
              <path
                fill="#FFF"
                fill-rule="nonzero"
                d="M20 14.667v-8l-4-4-4 4v2.666H4V28h24V14.667h-8zM9.333 25.333H6.667v-2.666h2.666v2.666zm0-5.333H6.667v-2.667h2.666V20zm0-5.333H6.667V12h2.666v2.667zm8 10.666h-2.666v-2.666h2.666v2.666zm0-5.333h-2.666v-2.667h2.666V20zm0-5.333h-2.666V12h2.666v2.667zm0-5.334h-2.666V6.667h2.666v2.666zm8 16h-2.666v-2.666h2.666v2.666zm0-5.333h-2.666v-2.667h2.666V20z"
                transform="translate(8 8)"
              />
            </g>
          </g>
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'Readiness'">
      <ng-container *ngIf="outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#353F47"
          [attr.width]="size"
          [attr.height]="size"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm7 10c0 4.52-2.98 8.69-7 9.93-4.02-1.24-7-5.41-7-9.93V6.3l7-3.11 7 3.11V11zm-11.59.59L6 13l4 4 8-8-1.41-1.42L10 14.17z"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          [attr.fill]="innerFill"
          [attr.width]="size"
          [attr.height]="size"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            [attr.fill]="innerFill"
            d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
          />
        </svg>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Lesson'">
      <ng-container *ngIf="outlined">
        <svg
          style="transform: rotate(180deg)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#353F47"
          [attr.width]="size"
          [attr.height]="size"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M3.55 19.09l1.41 1.41 1.79-1.8-1.41-1.41zM11 20h2v3h-2zM1 11h3v2H1zm12-6.95v3.96l1 .58c1.24.72 2 2.04 2 3.46 0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.42.77-2.74 2-3.46l1-.58V4.05h2m2-2H9v4.81C7.21 7.9 6 9.83 6 12.05c0 3.31 2.69 6 6 6s6-2.69 6-6c0-2.22-1.21-4.15-3-5.19V2.05zM20 11h3v2h-3zm-2.76 7.71l1.79 1.8 1.41-1.41-1.8-1.79z"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg
          style="transform: rotate(180deg)"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          [attr.fill]="innerFill"
          [attr.width]="size"
          [attr.height]="size"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M3.55 18.54l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8zM11 22.45h2V19.5h-2v2.95zM4 10.5H1v2h3v-2zm11-4.19V1.5H9v4.81C7.21 7.35 6 9.28 6 11.5c0 3.31 2.69 6 6 6s6-2.69 6-6c0-2.22-1.21-4.15-3-5.19zm5 4.19v2h3v-2h-3zm-2.76 7.66l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4z"
          />
        </svg>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Capability'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" [attr.width]="size" [attr.height]="size">
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0L20 0 20 20 0 20z" />
            <path
              [attr.fill]="'#939699'"
              d="M12 .5c1.11 0 2 .89 2 2v2h4c1.11 0 2 .89 2 2v11c0 1.11-.89 2-2 2H2c-1.11 0-2-.89-2-2l.01-11c0-1.11.88-2 1.99-2h4v-2c0-1.11.89-2 2-2zM14.325 8l-5.492 5.691-2.158-2.228L5.5 12.681l3.333 3.454L15.5 9.226 14.325 8zM12 2.5H8v2h4v-2z"
              transform="translate(-260 -314) translate(260 314) translate(2 1)"
            />
          </g>
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          [attr.fill]="innerFill"
          [attr.width]="size"
          [attr.height]="size"
        >
          <g fill="none" fill-rule="evenodd">
            <path d="M0 0L20 0 20 20 0 20z" />
            <path
              [attr.fill]="innerFill"
              d="M12 .5c1.11 0 2 .89 2 2v2h4c1.11 0 2 .89 2 2v11c0 1.11-.89 2-2 2H2c-1.11 0-2-.89-2-2l.01-11c0-1.11.88-2 1.99-2h4v-2c0-1.11.89-2 2-2zM14.325 8l-5.492 5.691-2.158-2.228L5.5 12.681l3.333 3.454L15.5 9.226 14.325 8zM12 2.5H8v2h4v-2z"
              transform="translate(-260 -314) translate(260 314) translate(2 1)"
            />
          </g>
        </svg>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Activity'">
      <ng-container *ngIf="outlined">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" [attr.width]="size" [attr.height]="size">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M17 10H7v2h10v-2zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-5-5H7v2h7v-2z"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          [attr.fill]="innerFill"
          [attr.width]="size"
          [attr.height]="size"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M17 10H7v2h10v-2zm2-7h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zm-5-5H7v2h7v-2z"
          />
        </svg>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Evaluation'">
      <ng-container *ngIf="outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          [attr.fill]="innerFill"
          [attr.width]="size"
          [attr.height]="size"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm7 10c0 4.52-2.98 8.69-7 9.93-4.02-1.24-7-5.41-7-9.93V6.3l7-3.11 7 3.11V11zm-11.59.59L6 13l4 4 8-8-1.41-1.42L10 14.17z"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          [attr.fill]="innerFill"
          [attr.width]="size"
          [attr.height]="size"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            [attr.fill]="innerFill"
            d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
          />
        </svg>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Evaluation_Action'">
      <ng-container *ngIf="outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          [attr.width]="size"
          [attr.height]="size"
          [attr.fill]="backgroundFill"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
          />
        </svg>
      </ng-container>
      <ng-container *ngIf="!outlined">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          [attr.width]="size"
          [attr.height]="size"
          [attr.fill]="backgroundFill"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
          />
        </svg>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'PEAP'">
      <i class="material-symbols-outlined" [style.font-size]="size">fact_check</i>
    </ng-container>
    <ng-container *ngSwitchCase="'Indicator'">
      <ng-container [ngSwitch]="trendStatus">
        <ng-container *ngSwitchCase="trendStatuses.Trending_Up">
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-1072.000000, -172.000000)">
                <g id="icon_indicator_amber_up" transform="translate(1072.000000, 172.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="backgroundFill"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g
                    id="trending_down_black_24dp"
                    transform="translate(24.000000, 24.000000) scale(1, -1) translate(-24.000000, -24.000000) translate(8.000000, 8.000000)"
                  >
                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                    <polygon
                      id="Path"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                      points="21.3333333 24 24.3866667 20.9466667 17.88 14.44 12.5466667 19.7733333 2.66666667 9.88 4.54666667 8 12.5466667 16 17.88 10.6666667 26.28 19.0533333 29.3333333 16 29.3333333 24"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-container *ngSwitchCase="trendStatuses.Trending_Down">
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-992.000000, -173.000000)">
                <g id="icon_indicator_amber_down" transform="translate(992.000000, 173.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="backgroundFill"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g id="trending_down_black_24dp" transform="translate(8.000000, 8.000000)">
                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                    <polygon
                      id="Path"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                      points="21.3333333 24 24.3866667 20.9466667 17.88 14.44 12.5466667 19.7733333 2.66666667 9.88 4.54666667 8 12.5466667 16 17.88 10.6666667 26.28 19.0533333 29.3333333 16 29.3333333 24"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-container *ngSwitchCase="trendStatuses.Pass">
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-1152.000000, -242.000000)">
                <g id="icon_indicator_green_pass" transform="translate(1152.000000, 242.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="backgroundFill"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g id="task_alt_black_24dp" transform="translate(8.000000, 8.000000)">
                    <rect id="Rectangle" x="0" y="0" width="32" height="32"></rect>
                    <path
                      d="M29.3333333,6.90666667 L14.12,22.1333333 L8.46666667,16.48 L10.3466667,14.6 L14.12,18.3733333 L27.4533333,5.04 L29.3333333,6.90666667 Z M26.3866667,13.6266667 C26.56,14.3866667 26.6666667,15.1866667 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.1066667 10.1066667,5.33333333 16,5.33333333 C18.1066667,5.33333333 20.0533333,5.94666667 21.7066667,7 L23.6266667,5.08 C21.4666667,3.56 18.84,2.66666667 16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,14.4133333 29.04,12.8933333 28.5333333,11.48 L26.3866667,13.6266667 Z"
                      id="Shape"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-container *ngSwitchCase="trendStatuses.Fail">
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-1152.000000, -102.000000)">
                <g id="icon_indicator_red_fail" transform="translate(1152.000000, 102.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="backgroundFill"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g id="close_black_24dp" transform="translate(8.000000, 8.000000)">
                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                    <polygon
                      id="Path"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                      points="25.3333333 8.54666667 23.4533333 6.66666667 16 14.12 8.54666667 6.66666667 6.66666667 8.54666667 14.12 16 6.66666667 23.4533333 8.54666667 25.3333333 16 17.88 23.4533333 25.3333333 25.3333333 23.4533333 17.88 16"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <svg
            [attr.width]="size"
            [attr.height]="size"
            viewBox="0 0 48 48"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g id="Components" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="ui_custom-icons-RAG-statuses" transform="translate(-992.000000, -313.000000)">
                <g id="icon_indicator_blue" transform="translate(992.000000, 313.000000)">
                  <rect id="Rectangle" x="0" y="0" width="48" height="48"></rect>
                  <rect
                    id="Rectangle-Copy-87"
                    [attr.fill]="backgroundFill"
                    x="4"
                    y="4"
                    width="40"
                    height="40"
                    rx="6"
                  ></rect>
                  <g id="track_changes_black_24dp" transform="translate(8.000000, 8.000000)">
                    <polygon id="Path" points="0 0 32 0 32 32 0 32"></polygon>
                    <path
                      d="M25.4266667,6.57333333 L23.5466667,8.45333333 C25.4666667,10.3866667 26.6666667,13.0533333 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.56 9.4,6.08 14.6666667,5.42666667 L14.6666667,8.12 C10.88,8.76 8,12.04 8,16 C8,20.4133333 11.5866667,24 16,24 C20.4133333,24 24,20.4133333 24,16 C24,13.7866667 23.1066667,11.7866667 21.6533333,10.3466667 L19.7733333,12.2266667 C20.7333333,13.2 21.3333333,14.5333333 21.3333333,16 C21.3333333,18.9466667 18.9466667,21.3333333 16,21.3333333 C13.0533333,21.3333333 10.6666667,18.9466667 10.6666667,16 C10.6666667,13.52 12.3733333,11.4533333 14.6666667,10.8533333 L14.6666667,13.7066667 C13.8666667,14.1733333 13.3333333,15.0133333 13.3333333,16 C13.3333333,17.4666667 14.5333333,18.6666667 16,18.6666667 C17.4666667,18.6666667 18.6666667,17.4666667 18.6666667,16 C18.6666667,15.0133333 18.1333333,14.16 17.3333333,13.7066667 L17.3333333,2.66666667 L16,2.66666667 C8.64,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.64,29.3333333 16,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,12.32 27.84,8.98666667 25.4266667,6.57333333 Z"
                      id="Path"
                      fill="#FFFFFF"
                      fill-rule="nonzero"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
