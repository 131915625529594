import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountSettingTypes } from '../../enums/accountSettingTypes';
import { ObjectEventEmitters } from '../../events/object.events';
import { AccountSettingsService } from '../../services/account-settings.service';

@Component({
  selector: 'app-global-alerts-bar',
  templateUrl: './global-alerts-bar.component.html',
  styleUrls: ['./global-alerts-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalAlertsBarComponent implements OnInit {
  private readonly subscriptions: Subscription = new Subscription();
  loading: boolean = true;
  private readonly settingFalseState: string = '0';
  public areNotificationsDisabled: boolean | undefined;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly accountSettingsService: AccountSettingsService,
    private readonly objectEventEmitter: ObjectEventEmitters
  ) {}

  ngOnInit(): void {
    this.getData();
    this.subscribeToSettingsUpdate();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private getData() {
    this.loading = true;

    this.subscriptions.add(
      this.accountSettingsService.getAccountSettingByType(AccountSettingTypes.Use_Notifications).subscribe((useNotifications) => {
        this.areNotificationsDisabled = useNotifications.value === this.settingFalseState;

        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private subscribeToSettingsUpdate() {
    this.subscriptions.add(
      this.objectEventEmitter.accountSettingUpdated$.subscribe(({ settingType }) => {
        if (settingType === AccountSettingTypes.Use_Notifications) {
          this.getData();
        }
      })
    );
  }
}
