import { QueryList } from '@angular/core';
import { FilterLozengeComponent } from '../components/filters-rebuild/filters-lozenge/filters-lozenge.component';
import { FilterActionTypes } from '../enums/filter/filterActionTypes.enum';
import { FilterDateOptions } from '../enums/filter/filterDateOptions';
import { FilterDateRangeOptions } from '../enums/filter/filterDateRangeOptions';
import { FilterSelectorTypes } from '../enums/filter/filterSelectorTypes';
import { FilterTypes } from '../enums/filterTypes';
import { ObjectTypes } from '../enums/objectTypes';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { AllowedFiltersService } from '../services/allowed-filters.service';
import { LocalisationService } from '../services/localisation.service';
import { FilterTypeSelectorViewModel } from '../viewModels/filters/filterTypeSelectorViewModel';
import { EmployeeUtil } from './employee.utilities';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export class FilterUtilities {
  /**
   * This dictionary is when you need to check if your filterType based on ObjectType is singleSelect
   */
  private static readonly filterSingleSelectDictionary = new Map<ObjectTypes, Set<FilterTypes>>([
    [
      ObjectTypes.Risk,
      new Set<FilterTypes>([FilterTypes.Owner, FilterTypes.Risk_Likelihood, FilterTypes.Risk_Impact, FilterTypes.Risk_Strategy]),
    ],
    [ObjectTypes.Task, new Set<FilterTypes>([FilterTypes.Project])],
    [ObjectTypes.Risk_Action_Item, new Set<FilterTypes>([FilterTypes.Risk_Action_Status, FilterTypes.Risk_Action_Type])],
    [ObjectTypes.Project, new Set<FilterTypes>([FilterTypes.Department])],
    [ObjectTypes.Runsheet, new Set<FilterTypes>([FilterTypes.Event])],
    [ObjectTypes.IncidentItem, new Set<FilterTypes>([FilterTypes.Event])],
    [ObjectTypes.Job, new Set<FilterTypes>([FilterTypes.Event])],
  ]);

  private static readonly headerFiltersSingleSelectDictionary = new Map<ObjectTypes, Set<FilterTypes>>([
    [ObjectTypes.Runsheet_Item, new Set<FilterTypes>([FilterTypes.Runsheet_Item_Dependencies])],
  ]);

  private static readonly includeZeroValuedValues = [
    FilterTypes.RAG,
    FilterTypes.Incident_Channel,
    FilterTypes.Incident_Status,
    FilterTypes.Privacy_Status,
    FilterTypes.Job_Status,
    FilterTypes.Indicator_RAG,
    FilterTypes.Risk_Privacy_Status,
  ];

  public static IsFilterTypeWithLocalisedValues(filterType: FilterTypes) {
    const filterTypesWithLocalisedValues = [
      FilterTypes.Risk_RAG,
      FilterTypes.Risk_Impact,
      FilterTypes.Risk_Likelihood,
      FilterTypes.Milestone_Type,
      FilterTypes.Privacy_Status,
      FilterTypes.Runsheet_Item_Priority,
      FilterTypes.Indicator_Priority,
      FilterTypes.Indicator_RAG,
      FilterTypes.RAG,
      FilterTypes.Task_Priority
    ];
    return filterTypesWithLocalisedValues.indexOf(filterType) >= 0;
  }

  public static IsFilterTypeWithTranslatedText(filterType: FilterTypes) {
    const filterTypesWithTranslatedText = [
      FilterTypes.Indicator_Category,
    ];
    return filterTypesWithTranslatedText.indexOf(filterType) >= 0;
  }

  public static MergeFilterArrays(...arrays: Array<FilterViewModel[]>): FilterViewModel[] {
    let mergedArray: FilterViewModel[] = [];

    arrays.forEach((array) => {
      mergedArray = [...mergedArray, ...array];
    });

    const mergedUnique = mergedArray.reduce((acc: FilterViewModel[], filter: FilterViewModel) => {
      if (acc.find((f) => f.filterType === filter.filterType && f.filterValue === filter.filterValue)) {
        return acc;
      } else {
        return [...acc, filter];
      }
    }, []);

    return mergedUnique;
  }

  public static RemoveFiltersFromArray(arr: FilterViewModel[], filtered: FilterViewModel[]): FilterViewModel[] {
    let modifiedArr = arr.slice();
    filtered.forEach((f) => {
      modifiedArr = this.RemoveFilterFromArray(modifiedArr, f);
    });

    return modifiedArr;
  }

  public static RemoveFilterFromArray(arr: FilterViewModel[], filter: FilterViewModel): FilterViewModel[] {
    let currentArr = JSON.parse(JSON.stringify(arr)) as FilterViewModel[];
    if (filter.filterType === FilterTypes.Date) {
      currentArr = currentArr.filter(
        (f) =>
          !(
            f.displayForGlobalObjectType === filter.displayForGlobalObjectType &&
            f.dateProperty === filter.dateProperty &&
            JSON.stringify(f.filterValue) === JSON.stringify(filter.filterValue)
          )
      );
    } else {
      currentArr = currentArr.filter((f) => !(f.filterType === filter.filterType && f.filterValue === filter.filterValue));
    }
    return currentArr;
  }

  public static addFilterToArray(arr: FilterViewModel[], filter: FilterViewModel): FilterViewModel[] {
    return this.replaceFilterInArray(arr, filter, filter);
  }

  /**
   * Replaces a filter inside an array with another filter of the same type. It will add the filter
   * to the array if it wasn't found.
   *
   * @param inputArr - the array containing filters
   * @param sourceFilter - the source filter which this method will replace with the @targetFilter
   * @param targetFilter - the target filter which will replace the @sourceFilter inside the @inputArr
   * @return the updated array
   */
  public static replaceFilterInArray(
    inputArr: FilterViewModel[],
    sourceFilter: FilterViewModel,
    targetFilter: FilterViewModel
  ): FilterViewModel[] {
    if (sourceFilter.filterType !== targetFilter.filterType) {
      return inputArr.slice();
    }

    const arr = JSON.parse(JSON.stringify(inputArr)) as FilterViewModel[];

    let existingFilterIndex = arr.findIndex((f) => {
      const flag1 = f.filterValue + '' === sourceFilter.filterValue + '';
      const flag2 = f.filterType === sourceFilter.filterType;

      return flag1 && flag2;
    });

    if (existingFilterIndex === -1) {
      existingFilterIndex = arr.length;
    }

    arr.splice(existingFilterIndex, 1, targetFilter);

    return arr;
  }

  public static GenerateFilter(
    type: FilterTypes,
    value: unknown,
    text = '',
    dateOption: FilterDateOptions = undefined,
    active = true,
    displayForGlobalObjectType: ObjectTypes = 0
  ): FilterViewModel {
    const filter = new FilterViewModel();
    filter.filterType = type;
    filter.filterValue = value;
    filter.filterText = text;
    filter.dateProperty = dateOption;
    filter.isActive = active;
    filter.displayForGlobalObjectType = displayForGlobalObjectType;
    return filter;
  }

  public static generateFilterForAccount(
    accountId: number,
    type: FilterTypes,
    value: unknown,
    text = '',
    dateOption: FilterDateOptions = undefined,
    active = true,
    displayForGlobalObjectType: ObjectTypes = 0
  ): FilterViewModel {
    const filter = new FilterViewModel();
    filter.accountId = accountId;
    filter.filterType = type;
    filter.filterValue = value;
    filter.filterText = text;
    filter.dateProperty = dateOption;
    filter.isActive = active;
    filter.displayForGlobalObjectType = displayForGlobalObjectType;
    return filter;
  }

  public static GenerateDefaultFilterForIncidentsList(): FilterViewModel {
    return {
      adittionalItems: null,
      colour: "",
      dateProperty: 0,
      dateRangeOption: null,
      displayForGlobalObjectType: 100,
      filterAction: 0,
      filterDropdownTitle: "Status",
      filterSelectorType: 1,
      filterText: "Open",
      filterType: 40,
      filterValue: 1,
      icon: "",
      id: <unknown>"Incident_Status:1",
      isActive: true,
      isExternal: false,
      isModalReadOnly: false,
      isPrimary: false,
      relatedObjectId: 0,
      viewOrder: 0,
      visibility: 0
    } as FilterViewModel;
  }

  /**
   *  Set Default value for a SINGLE filter based on filter Type
   */
  public static SetFilterValue(allFilters:FilterViewModel[],filterType:FilterTypes,filterValue:any,objectType:ObjectTypes):FilterViewModel[]{
    const filter = allFilters.find((filter) => filter.filterType === filterType);
    if (filter) {
      filter.filterValue = filterValue;
    } else {
      const newFilter = FilterUtilities.GenerateFilter(filterType, filterValue,'',undefined,true,objectType);
      allFilters.push(newFilter);
    }

    return allFilters;
  }

  public static GenerateDateFilter(
    dateProperty: FilterDateOptions,
    filterSelectorType: FilterSelectorTypes,
    dateRangeOption: FilterDateRangeOptions
  ) {
    const filter: FilterViewModel = new FilterViewModel();
    filter.filterType = FilterTypes.Date;
    filter.dateProperty = dateProperty;
    filter.filterSelectorType = filterSelectorType;
    filter.dateRangeOption = dateRangeOption;
    filter.filterValue = {
      dateProperty: dateProperty,
      dateRange: dateRangeOption,
    };
    filter.filterText = '';
    filter.isActive = true;
    filter.displayForGlobalObjectType = 0;
    return filter;
  }

  /// <summary>
  /// Returns a list of filters that are not supported by the current object type and include new filters
  /// </summary>
  public static ApplyFilterActions(
    filterViewModels: FilterViewModel[],
    filtersWithActions: FilterViewModel[]
  ): FilterViewModel[] {
    let removedFilters: FilterViewModel[] = [];
    let addedFilters: FilterViewModel[] = [];
    let updatedFilters: FilterViewModel[] = [];

    if (filtersWithActions) {
      removedFilters = filtersWithActions.filter((f) => f.filterAction === FilterActionTypes.Remove);
      addedFilters = filtersWithActions.filter((f) => f.filterAction === FilterActionTypes.Add);
      updatedFilters = filtersWithActions.filter((f) => f.filterAction === FilterActionTypes.Update);
    }

    filterViewModels = this.RemoveFiltersFromArray(filterViewModels, removedFilters);

    if (addedFilters.length > 0) filterViewModels.push(...addedFilters);

    if (updatedFilters.length > 0) {

      filterViewModels = filterViewModels.filter(
        (d) =>  !(d.filterType === updatedFilters[0].filterType && d.dateProperty === updatedFilters[0].dateProperty)
      );
      filterViewModels.push(...updatedFilters);
    }

    filterViewModels.forEach((f) => (f.filterAction = FilterActionTypes.None));
    filterViewModels = FilterUtilities.DistinctFilterValues(filterViewModels);
    return filterViewModels;
  }

  public static IsHeaderFilterSingleSelect(objectType: ObjectTypes, filterType: FilterTypes): boolean {
    return this.headerFiltersSingleSelectDictionary.get(objectType)?.has(filterType) ?? false;
  }

  public static IsSingleSelectFilter(objectType: ObjectTypes, filterType: FilterTypes): boolean {
    return this.filterSingleSelectDictionary.get(objectType)?.has(filterType) ?? false;
  }

  public static IncludeZeroValues(filterType: FilterTypes) {
    return this.includeZeroValuedValues.indexOf(filterType) >= 0;
  }

  public static ArrayContainsFiltersForObjectTypes(filters: FilterViewModel[], objectTypes: ObjectTypes[]): boolean {
    return filters.some((f) => objectTypes.some((oType) => oType === f.displayForGlobalObjectType));
  }

  public static FilterFiltersByPermissions(
    employee: Employee,
    filters: FilterViewModel[],
    filterType: FilterTypes
  ): FilterViewModel[] {
    const copiedFilters = JSON.parse(JSON.stringify(filters)) as FilterViewModel[];

    if (EmployeeUtil.IsAdmin(employee) || employee.permissions.some((p) => p.type === filterType && p.write && p.value === 0)) {
      return copiedFilters;
    }

    const a = copiedFilters.filter(
      (f) =>
        f.filterType === filterType &&
        employee.permissions.some((p) => p.type === filterType && p.value === f.filterValue && p.write)
    );
    return a;
  }

  static GroupTagFiltersByTagGroup(
    allFilters: FilterViewModel[],
    appliedFilters: FilterViewModel[]
  ): Map<string, FilterViewModel[]> {
    const tagGroups = allFilters.filter((s) => s.filterType === FilterTypes.Tag_Group);

    return appliedFilters
      .filter((a) => a.filterType === FilterTypes.Tag)
      .reduce((acc, curr) => {
        const tagGroup = tagGroups.find((s) => s.filterValue.toString() === curr.relatedObjectId.toString());
        const oType = ObjectTypes[curr.displayForGlobalObjectType].toString();
        const tagGroupValue = tagGroup ? tagGroup.filterValue.toString() : curr.filterValue?.toString();
        const key = `${oType}${tagGroupValue}`;

        const addToMap = acc.has(key) ? [...acc.get(key), curr] : [curr];
        acc.set(key, addToMap);

        return acc;
      }, new Map<string, FilterViewModel[]>());
  }

  static GroupHiddenFilters(desktopFilterLozenges: QueryList<FilterLozengeComponent>): Map<string, FilterViewModel[]> {
    if (!desktopFilterLozenges || !desktopFilterLozenges.length) {
      return new Map<string, FilterViewModel[]>();
    }

    const top = (element: HTMLElement) => element.getBoundingClientRect().top;
    const firstLozengeElementTop: number = top(desktopFilterLozenges.first.elementRef.nativeElement);

    return desktopFilterLozenges.toArray().reduce((acc, curr) => {
      if (top(curr.elementRef.nativeElement) === firstLozengeElementTop) {
        return acc;
      }

      const currentAppliedFilters = curr.appliedFiltersByType[0];
      const oType = ObjectTypes[currentAppliedFilters.displayForGlobalObjectType].toString();
      const fType = FilterTypes[currentAppliedFilters.filterType].toString();
      const dateProp = FilterDateOptions[currentAppliedFilters?.dateProperty] ?? '';
      const key = `${oType}${fType}${dateProp}`;

      if (!acc.has(key)) {
        acc.set(key, []);
      }

      acc.set(key, [...acc.get(key), ...curr.appliedFiltersByType]);

      return acc;
    }, new Map<string, FilterViewModel[]>());
  }

  static GroupHiddenTagFilters(desktopTagFilterLozenges: QueryList<FilterLozengeComponent>): Map<string, FilterViewModel[]> {
    if (!desktopTagFilterLozenges || !desktopTagFilterLozenges.length) {
      return new Map<string, FilterViewModel[]>();
    }

    const top = (element: HTMLElement) => element.getBoundingClientRect().top;
    const firstLozengeElementTop: number = top(desktopTagFilterLozenges.first.elementRef.nativeElement);

    return desktopTagFilterLozenges.toArray().reduce((acc, curr) => {
      if (top(curr.elementRef.nativeElement) === firstLozengeElementTop) {
        return acc;
      }

      const currentAppliedFilters = curr.appliedFiltersByType[0];
      const oType = ObjectTypes[currentAppliedFilters.displayForGlobalObjectType].toString();
      const tagGroupValue = currentAppliedFilters.filterValue.toString();
      const key = `${oType}${tagGroupValue}`;

      if (!acc.has(key)) {
        acc.set(key, []);
      }

      acc.set(key, [...acc.get(key), ...curr.appliedFiltersByType]);

      return acc;
    }, new Map<string, FilterViewModel[]>());
  }

  /** Group filters by Object Type and Filter type as all filter types can be invoked for different obejct in same page */
  static GroupFiltersByObject(appliedFilters: FilterViewModel[]): Map<string, FilterViewModel[]> {
    return appliedFilters
      .filter((a) => a.filterType !== FilterTypes.Tag_Group)
      .reduce((acc, curr) => {
        const oType = ObjectTypes[curr.displayForGlobalObjectType].toString();
        const fType = FilterTypes[curr.filterType].toString();
        const dateProp = curr.filterType === FilterTypes.Date ? FilterDateOptions[curr.dateProperty].toString() : '';
        const key = `${oType}${fType}${dateProp}`;

        const addToMap = acc.has(key) ? [...acc.get(key), curr] : [curr];
        acc.set(key, addToMap);

        return acc;
      }, new Map<string, FilterViewModel[]>());
  }

  static groupFiltersByFilterType(filters: FilterViewModel[]): Map<FilterTypes, FilterViewModel[]> {
    return filters.reduce((acc, curr) => {
      const fType = curr.filterType;

      const addToMap = acc.has(fType) ? [...acc.get(fType), curr] : [curr];
      acc.set(fType, addToMap);

      return acc;
    }, new Map<FilterTypes, FilterViewModel[]>());
  }

  static filterByObjectType(
    filters: FilterViewModel[],
    filterSelectorType: FilterTypeSelectorViewModel,
    allowedFiltersService: AllowedFiltersService
  ): FilterViewModel[] {
    if (!filterSelectorType) {
      return filters;
    }

    if (filterSelectorType.filterType === FilterTypes.RAG) {
      return filters.filter((a) => a.filterType === filterSelectorType.filterType);
    } else {
      return filters.filter(
        (a) =>
          a.filterType === filterSelectorType.filterType &&
          (a.displayForGlobalObjectType === ObjectTypes.Global ||
            a.displayForGlobalObjectType === filterSelectorType.displayForObjectType)
      );
    }
  }

  static getAppliedFilters(
    filters: FilterViewModel[],
    filterSelectorType: FilterTypeSelectorViewModel,
    selectedTagGroup: FilterViewModel
  ): FilterViewModel[] {
    if (filterSelectorType.filterType === FilterTypes.Tag) {
      return filters.filter(
        (a) =>
          a.filterType === filterSelectorType.filterType &&
          a.displayForGlobalObjectType === filterSelectorType.displayForObjectType &&
          a.relatedObjectId.toString() === selectedTagGroup.filterValue.toString()
      );
    } else {
      return filters.filter(
        (a) =>
          a.filterType === filterSelectorType.filterType &&
          a.displayForGlobalObjectType === filterSelectorType.displayForObjectType
      );
    }
  }

  static getFilterTypeSelectorViewModelByFilterType(
    filterTypeSelectorViewModels: FilterTypeSelectorViewModel[],
    filterType: FilterTypes,
    dateProperty?: FilterDateOptions,
    displayForGlobalObjectType?: ObjectTypes
  ): FilterTypeSelectorViewModel {
    if (dateProperty && displayForGlobalObjectType) {
      return filterTypeSelectorViewModels.find(
        (filterTypeSelectorViewModel) =>
          filterTypeSelectorViewModel.filterType === filterType &&
          filterTypeSelectorViewModel.dateProperty === dateProperty &&
          filterTypeSelectorViewModel.displayForObjectType === displayForGlobalObjectType
      );
    } else if (filterType === FilterTypes.Tag) {
      const tagFilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
      tagFilterTypeSelectorViewModel.filterType = FilterTypes.Tag;
      tagFilterTypeSelectorViewModel.filterTypeText = 'Tag';
      tagFilterTypeSelectorViewModel.filterSelectorType = FilterSelectorTypes.Dropdown;
      tagFilterTypeSelectorViewModel.displayForObjectType = displayForGlobalObjectType;

      return tagFilterTypeSelectorViewModel;
    } else {
      return filterTypeSelectorViewModels.find(
        (filterTypeSelectorViewModel) => filterTypeSelectorViewModel.filterType === filterType
      );
    }
  }

  static setFilterText(filters: FilterViewModel[], localisationService: LocalisationService, translateService: TranslateService): FilterViewModel[] {
    const filtersByProject = filters.filter((f) => f.filterType === FilterTypes.Project);

    return filters.map((f) => {
      // Replace filterText with enum localisation
      const shouldBeLocalisedValue = FilterUtilities.IsFilterTypeWithLocalisedValues(f.filterType);
      const shouldBeTranslatedText = FilterUtilities.IsFilterTypeWithTranslatedText(f.filterType);

      if (shouldBeLocalisedValue) {
        f.filterText = localisationService.localiseFilterValueByFilterType(+f.filterValue, f.filterType);
      }

      if(shouldBeTranslatedText) {
        const translationKey = `defaultLocalizations.${f.filterText.toLowerCase().replace(/ /g,"_")}.one`;
        const translatedText = translateService.instant(translationKey);

        if(translatedText && translatedText.length > 0 && translatedText.indexOf(translationKey) === -1)
          f.filterText = translatedText;
      }

      // For Task Group filters, replace filterText with taskGroup Name (Project Name)
      if (f.filterType === FilterTypes.Task_Group) {
        const project = filtersByProject.find((p) => p.filterValue.toString() === f.relatedObjectId.toString());
        f.filterText = project !== undefined ? `${f.filterText} (${project.filterText})` : f.filterText;
      }

      return f;
    });
  }

  static DistinctFilterValues(filters: FilterViewModel[]): FilterViewModel[] {
    return filters.filter((f, i, a) => a.findIndex((t) => t.filterType === f.filterType && t.filterValue == f.filterValue && t.dateProperty == f.dateProperty) === i);
  }

  /* refresh original filters by applying all changed filters */
  static updateFilters(originalFilters: FilterViewModel[], changedFilters: FilterViewModel[]): FilterViewModel[] {
    const removedFilters: FilterViewModel[] = changedFilters.filter((a) => a.filterAction === FilterActionTypes.Remove);
    const addedFilters: FilterViewModel[] = changedFilters.filter((a) => a.filterAction === FilterActionTypes.Add);
    const updatedFilters: FilterViewModel[] = changedFilters.filter((a) => a.filterAction === FilterActionTypes.Update);

    let modifiedFilters = originalFilters.slice();

    modifiedFilters = modifiedFilters.filter(
      (a) =>
        !removedFilters.some(
          (b) =>
            b.filterType === a.filterType &&
            b.filterValue == a.filterValue &&
            b.displayForGlobalObjectType === a.displayForGlobalObjectType
        )
    );

    modifiedFilters = modifiedFilters.concat(addedFilters);

    modifiedFilters = modifiedFilters.filter(
      (a) =>
        !updatedFilters.some(
          (b) =>
            b.filterType === a.filterType &&
            (a.displayForGlobalObjectType === 0 || b.displayForGlobalObjectType === a.displayForGlobalObjectType)
        )
    );

    modifiedFilters = modifiedFilters.concat(updatedFilters);

    return modifiedFilters;
  }

  /**
   * Returns function that can be used to filter
   */
  public static getAppliedFilterCallback(appliedFilter: FilterViewModel) {
    switch (appliedFilter.filterType) {
      case FilterTypes.Date:
        const dateProperty = appliedFilter.filterValue.dateProperty as FilterDateOptions;
        const dateRange = appliedFilter.filterValue.dateRange as  FilterDateRangeOptions;
        switch (dateProperty) {
          case FilterDateOptions.Due_Date:
            switch (dateRange) {
              case FilterDateRangeOptions.Past:
                return (object: {filters: FilterViewModel[]}) => {
                  const foundFilterValue = this.getFilterValue(object.filters, FilterTypes.Date, FilterDateOptions.Due_Date);
                  const now = moment();
                  const isBeforeNow = moment(foundFilterValue).isBefore(now);
                  return isBeforeNow;
                }
              case FilterDateRangeOptions.Yesterday:
                return (object: {filters: FilterViewModel[]}) => {
                  const foundFilterValue = this.getFilterValue(object.filters, FilterTypes.Date, FilterDateOptions.Due_Date);
                  const startOfYesterday = moment().subtract(1, 'days').startOf('day');
                  const endOfYesterday = moment().subtract(1, 'days').endOf('day');
                  const isYesterday = moment(foundFilterValue).isAfter(startOfYesterday) && moment(foundFilterValue).isBefore(endOfYesterday);
                  return isYesterday;
                }
              case FilterDateRangeOptions.Today:
                return (object: {filters: FilterViewModel[]}) => {
                  const foundFilterValue = this.getFilterValue(object.filters, FilterTypes.Date, FilterDateOptions.Due_Date);
                  const startOfToday = moment().startOf('day');
                  const endOfToday = moment().endOf('day');
                  const isToday = moment(foundFilterValue).isAfter(startOfToday) && moment(foundFilterValue).isBefore(endOfToday)
                  return isToday;
                }
              case FilterDateRangeOptions.Onward:
                return (object: {filters: FilterViewModel[]}) => {
                  const foundFilterValue = this.getFilterValue(object.filters, FilterTypes.Date, FilterDateOptions.Due_Date);
                  const tomorrow = moment().add(1, 'days').startOf('day');
                  const isAfterTomorrow = moment(foundFilterValue).isAfter(tomorrow);
                  return isAfterTomorrow;
                }
            }
        }
      case FilterTypes.Archived:
        const archived = appliedFilter.filterValue as boolean;
        return (object: {filters: FilterViewModel[]}) => {
          const foundFilterValue = this.getFilterValue(object.filters, FilterTypes.Archived);
          const parsedArchivedFilterValue = foundFilterValue === 'True' || <unknown>foundFilterValue === true; // Archived filter value is string ('True' or 'False'), so we need to parse it to boolean
          return parsedArchivedFilterValue == archived;
        }
      default:
        // In case of missing case statement, return true to not filter anything
        return (object: {filters: FilterViewModel[]}) => {
          return true
        }
    }
  }


  /**
   * Extracts the filters form the filters array which belong to the owner type
   * and their values match the given filterValues ones.
   *
   * @param filters
   * Source array of filters. This array is readonly and nothing gets modified!
   *
   * @param filterType
   * The filter type which we want to look for values.
   *
   * @param filterValues
   * Array of values which need to be search inside the source array
   * after being filtered by the given filterType
   *
   * @returns
   * An array which is a subset of the source array, containing references to the filtered elements
   * in the source array. All the elements of the returned array are of type filterType.
   */
  public static filterByValues(filters: FilterViewModel[], filterType: FilterTypes, filterValues: string[] | number[]): FilterViewModel[] {
    return filters
      .filter(f => f.filterType === filterType)
      .filter(f => filterValues.findIndex(id => id as string === f.filterValue as string))
  }




  /**
   * Extracts filter value from filters array based on filter type (and date property if filter type is date)
   */
  private static getFilterValue(filters: FilterViewModel[], filterType: FilterTypes, dateProp?: FilterDateOptions): string | number {
    if(filterType !== FilterTypes.Date) {
      return filters.find((f) => f.filterType == filterType)?.filterValue;
    }
    return filters.find((f) => f.filterType == filterType && f.dateProperty == dateProp).filterValue;
  }

  public static getFilterValueAsBoolean(filterViewModel: FilterViewModel): boolean {
    return filterViewModel && (filterViewModel.filterValue == 1 || filterViewModel.filterValue === true || (filterViewModel.filterValue + "").toLowerCase() === 'true');
  }
}
