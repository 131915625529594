import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DashboardLayoutViewModel } from 'src/app/modules/shared/viewModels/dashboard/dashboardLayoutViewModel';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-dashboard-layout-options',
  templateUrl: './dashboard-layout-options.component.html',
  styleUrls: ['./dashboard-layout-options.component.scss'],
})
export class DashboardLayoutOptionsComponent implements OnInit, OnChanges {
  @Output() onManageSavedViews: EventEmitter<null> = new EventEmitter();
  @Output() onChangeLayout: EventEmitter<DashboardLayoutViewModel> = new EventEmitter();

  @Input() isCurrentlySelectedLayoutSaved: boolean = true;
  @Input() layouts: DashboardLayoutViewModel[] = [];
  public layoutsCopy: DashboardLayoutViewModel[] = [];
  public selectedLayout: DashboardLayoutViewModel;
  public newLayoutSelected: DashboardLayoutViewModel;
  public readonly T = T;

  constructor(protected readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setCurrentlySelectedDefaultLayout();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layouts) {
      this.layouts = changes.layouts.currentValue;
      this.layoutsCopy = JSON.parse(JSON.stringify(this.layouts));
      this.setCurrentlySelectedDefaultLayout();
    }

    if (changes.isCurrentlySelectedLayoutSaved) {
      this.isCurrentlySelectedLayoutSaved = changes.isCurrentlySelectedLayoutSaved.currentValue;
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Search layout by given input
   * @param input string
   */
  public onLayoutSearch(input: string): void {
    if (input) {
      this.layouts = this.layoutsCopy.filter((c) => c.title.toLowerCase().indexOf(input.toLowerCase()) !== -1);
    } else {
      this.layouts = this.layoutsCopy;
    }
  }

  /**
   * Check if layout is currently selected
   * @param layout DashboardLayoutViewModel
   */
  public isLayoutSelected(layout: DashboardLayoutViewModel): boolean {
    const currentLayout = this.newLayoutSelected ?? this.selectedLayout;
    if (layout && this.isCurrentlySelectedLayoutSaved) {
      return currentLayout.id === layout.id;
    }

    return false;
  }

  /**
   * Set new selected layout
   * @param layout DashboardLayoutViewModel
   */
  public onLayoutSelected(layout: DashboardLayoutViewModel): void {
    this.newLayoutSelected = layout;
    this.onChangeLayout.next(layout);
    this.cancel();
  }

  public manageSavedViews(): void {
    this.onManageSavedViews.next(null);
  }

  /**
   * Change the default layout for the current user
   */
  public setCurrentlySelectedDefaultLayout() {
    const employeeSelectedDefaultLayout = this.layouts.find((x) => x.default);
    this.selectedLayout = employeeSelectedDefaultLayout
      ? employeeSelectedDefaultLayout
      : this.layouts.find((x) => x.accountDefault);
  }

  public openManageSavedViewsModal() {
    this.cancel();
    this.onManageSavedViews.next(null);
  }

  public cancel() {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }
}
