import { Directive, ElementRef, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';
import { isChildOf } from 'src/app/modules/shared/utilities/html.utilities';
import { TooltipEventsEmitter } from '../../events/tooltip.events';
import { WindowEventsEmitter } from '../../events/window.events';
import { Tooltip } from '../../models/tooltip';
import { Position } from '../../enums/position';

@Directive({
  selector: '[app-tooltip]',
  host: {
    '(click)': 'onClick($event, true)',
    '(mouseover)': 'onMouseOver($event)',
    '(mousemove)': 'onMouseMove($event)',
    '(mouseout)': 'onMouseOut($event)',
  },
  exportAs: 'app-tooltip',
})
export class TooltipDirective implements OnInit, OnDestroy {
  private readonly scrollEventDebounceTime: number = 160;
  private readonly initialMaxWidth: string = '404px';
  private readonly sizeOfCursor = 16;
  private readonly minCursorOffset = 2;
  private readonly minDistanceFromWindowEdge = 10;
  private positionChanged: boolean = false;
  @Input('app-tooltip') tooltip: Tooltip;
  @Input() showTooltip: boolean = true;

  tooltipIsVisible: boolean = false;
  mouseIsOver: boolean = false;
  tooltipElement: HTMLDivElement;
  interval: number;
  initialBoundingRectangle: ClientRect;
  subscriptions: Subscription[] = [];

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly tooltipEventsEmitter: TooltipEventsEmitter,
    private readonly windowEventsEmitter: WindowEventsEmitter,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.tooltipEventsEmitter.tooltipElementChanged$.subscribe((tooltipElement) => {
      this.tooltipElement = tooltipElement as HTMLDivElement;
    });

    this.tooltipEventsEmitter.broadcastTooltipDirectiveInitialized();

    const visibilityHandler = (e: Event) => {
      const target: HTMLElement = e.target as HTMLElement;
      const targetIsNotChildOfTooltip: boolean = !isChildOf(this.tooltipElement, target);
      const { nativeElement } = this.elementRef;
      const targetChildrenAreDifferentThanThis: boolean = !Array.from(target.children).find((c) => c === nativeElement);
      if (target !== nativeElement && targetIsNotChildOfTooltip && targetChildrenAreDifferentThanThis) {
        this.onClick(e, e.type !== 'mousemove');
      }
    };

    this.subscriptions.push(
      this.windowEventsEmitter.windowClickEventTriggered$.pipe(filter(() => this.tooltipIsVisible)).subscribe((e: Event) => {
        visibilityHandler(e);
      })
    );

    this.subscriptions.push(
      this.windowEventsEmitter.windowMousemoveEventTriggered$.pipe(filter(() => this.tooltipIsVisible)).subscribe((e: Event) => {
        visibilityHandler(e);
      })
    );

    this.subscriptions.push(
      this.windowEventsEmitter.windowScrollEventTriggered$
        .pipe(
          debounceTime(this.scrollEventDebounceTime),
          filter(() => this.tooltipIsVisible)
        )
        .subscribe((e) => {
          this.onClick(e, false);
        })
    );

    this.subscriptions.push(
      this.tooltipEventsEmitter.tooltipVisibilityChanged$.subscribe((visible: boolean) => {
        this.tooltipIsVisible = visible;
        if (!this.tooltipIsVisible) {
          this.tooltipElement.classList.remove('shown');
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    if (this.tooltipElement) {
      this.tooltipElement.classList.remove('shown');
    }
    clearInterval(this.interval);
  }

  onClick(e: Event, isClick: boolean) {
    const { title, icon, position, message, templateRef, width, stylingVersion} = this.tooltip;
    this.tooltipEventsEmitter.broadcastTooltipChanged({
      width,
      title,
      icon,
      position: position ? position : [Position.Bottom],
      message,
      templateRef: templateRef,
      cursorOffset: 0,
      stylingVersion
    });

    this.tooltipElement.style.maxWidth = this.initialMaxWidth;
    this.changeDetectorRef.detectChanges();

    this.tooltipEventsEmitter.broadcastTooltipChanged({
      width,
      title,
      icon,
      position: position ? position : [Position.Bottom],
      message,
      templateRef,
      cursorOffset: this.tooltip.cursorOffset,
      stylingVersion
    });

    this.tooltipElement.style.left = '0';
    this.tooltipElement.style.top = '0';

    this.initialBoundingRectangle = this.tooltipElement.getBoundingClientRect();

    this.positionTooltip();
    this.toggleTooltip();
  }

  onMouseOver(e: Event) {
    this.onClick(e, false);
    this.positionTooltip();
  }

  onMouseOut() {
    this.tooltipIsVisible = false;
    this.mouseIsOver = false;
    this.tooltipElement.classList.remove('shown');
    clearInterval(this.interval);
  }

  onMouseMove(event: Event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  private toggleTooltip(): void {
    if(this.showTooltip){
      this.tooltipIsVisible = !this.tooltipIsVisible;
    }

    if (this.mouseIsOver) {
      clearInterval(this.interval);
    }

    if (this.tooltipIsVisible) {
      this.tooltipElement.classList.add('shown');
    } else {
      this.tooltipElement.classList.remove('shown');
    }

    this.positionTooltip();
  }
  private positionTooltip(): void {
    this.tooltipElement.style.maxWidth = null;
    const tooltipPosition = this.tooltip.position ? this.tooltip.position : [Position.Bottom];
    const element: HTMLElement = this.elementRef.nativeElement;
    const boundingRect: ClientRect = element.getBoundingClientRect();
    const tooltipBoundingRect: ClientRect = this.tooltipElement.getBoundingClientRect();
    const arrowSize: number = 6;
    let left: number;
    let top: number;
    const position: string = tooltipPosition[0];
    const direction: string = tooltipPosition[1];
    this.tooltipElement.style.maxWidth = `calc(${this.initialBoundingRectangle.width}px)`;
    const windowWidth = window.innerWidth - this.minDistanceFromWindowEdge;
    switch (position) {
      case Position.Top:
        left = boundingRect.left + boundingRect.width / 2 - this.initialBoundingRectangle.width / 2;
        top = boundingRect.top - this.initialBoundingRectangle.height - arrowSize;
        break;
      case Position.Left:
        left = boundingRect.left - tooltipBoundingRect.width - arrowSize;
        top = boundingRect.top + boundingRect.height / 2 - this.initialBoundingRectangle.height / 2;
        break;
      case Position.Right:
        left = boundingRect.right + arrowSize;
        top = boundingRect.top + boundingRect.height / 2 - this.initialBoundingRectangle.height / 2;
        break;
      default:
        left = boundingRect.left + boundingRect.width / 2 - this.initialBoundingRectangle.width / 2;
        top = boundingRect.top + boundingRect.height + arrowSize;
        break;
    }

    if (direction !== undefined) {
      if (direction == Position.Left) {
        left = boundingRect.left + boundingRect.width - tooltipBoundingRect.width;
        if (left < 0) {
          this.tooltip.position[1] = Position.Right;
          return this.positionTooltip();
        }
      } else if (direction == Position.Right) {
        left = boundingRect.left;
        if (left + tooltipBoundingRect.width > windowWidth) {
          this.tooltip.position[1] = Position.Left;
          return this.positionTooltip();
        }
      } else if (direction == Position.Top) {
        top = boundingRect.top + boundingRect.height - tooltipBoundingRect.height;
        if (top < 0) {
          this.tooltip.position[1] = Position.Bottom;
          return this.positionTooltip();
        }
      } else if (direction == Position.Bottom) {
        top = boundingRect.top;
        if (top + this.tooltipElement.scrollHeight + arrowSize > window.innerHeight) {
          this.tooltip.position[1] = Position.Top;
          return this.positionTooltip();
        }
      }
    }

    if (!this.positionChanged && this.tooltip && this.tooltip.position) {
      if (top < 0 && position == Position.Top) {
        this.tooltip.position[0] = Position.Bottom;
        this.positionChanged = true;
      } else if (
        top > 0 &&
        top + this.tooltipElement.scrollHeight + arrowSize > window.innerHeight &&
        position == Position.Bottom
      ) {
        this.tooltip.position[0] = Position.Top;
        this.positionChanged = true;
      } else if (left > 0 && left + this.initialBoundingRectangle.width > windowWidth && position == Position.Right) {
        this.tooltip.position[0] = Position.Left;
        this.positionChanged = true;
      } else if (left < 0 && position == Position.Left) {
        this.tooltip.position[0] = Position.Right;
        this.positionChanged = true;
      }
      if (this.positionChanged) {
        return this.positionTooltip();
      }
    }
    if (left > 0 && left + this.initialBoundingRectangle.width <= windowWidth) {
      this.tooltipElement.style.left = `${left}px`;
      this.tooltipElement.style.top = `${top}px`;
      if (position == Position.Top || position == Position.Bottom) {
        this.tooltip.cursorOffset = (this.initialBoundingRectangle.width - this.sizeOfCursor) / 2;
      } else {
        this.tooltip.cursorOffset = (this.initialBoundingRectangle.height - this.sizeOfCursor) / 2;
      }
      if (tooltipPosition[1]) {
        if (this.tooltip.position[1] == Position.Left)
          this.tooltip.cursorOffset = Math.abs(boundingRect.width - this.initialBoundingRectangle.width - this.minCursorOffset);
        else if (this.tooltip.position[1] == Position.Top)
          this.tooltip.cursorOffset = Math.abs(boundingRect.height - this.initialBoundingRectangle.height - this.minCursorOffset);
        else this.tooltip.cursorOffset = this.minCursorOffset;
      }
    } else if (left > 0 && left + this.initialBoundingRectangle.width > windowWidth) {
      const delta = left + this.initialBoundingRectangle.width - windowWidth;
      this.tooltip.cursorOffset = (this.initialBoundingRectangle.width - this.sizeOfCursor) / 2 + delta;
      this.tooltipElement.style.left = `${left - delta}px`;
      this.tooltipElement.style.top = `${top}px`;
    } else {
      this.tooltip.cursorOffset = (this.initialBoundingRectangle.width - 2 * Math.abs(left)) / 2 - this.sizeOfCursor;
      this.tooltipElement.style.left = `0px`;
      this.tooltipElement.style.top = `${top}px`;
    }
    if ((position == Position.Right || position == Position.Left) && tooltipPosition[1] == undefined) {
      if (top > 0 && top + this.initialBoundingRectangle.height > window.innerHeight) {
        const delta = top + this.initialBoundingRectangle.height - window.innerHeight;
        this.tooltip.cursorOffset += delta;
        this.tooltipElement.style.top = `${top - delta}px`;
      } else if (top < 0) {
        this.tooltipElement.style.top = `$0px`;
      }
    }
  }
}
