import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModifiableEntityViewModel } from 'src/app/modules/incidents/viewModels/modifiableEntityViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { LightFilterUpdateModel } from '../../../models/filter/LightFilterUpdateModel';
import { AlertService } from '../../../services/alert.service';
import { IncidentsService } from '../../../services/incidents.service';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { IApiService } from '../../../interfaces/iHttpService';

@Component({
  selector: 'app-update-description-modal',
  templateUrl: 'update-description-status-modal.component.html',
  styleUrls: ['update-description-status-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateDescriptionModalComponent implements OnInit, OnDestroy, AfterViewInit {
  activeLightUpdate = true;
  subscriptions: Subscription[] = [];
  description: string;
  object: ModifiableEntityViewModel;
  service: IApiService;
  descriptionFilterType = FilterTypes.Description;
  handleChangeInternally: boolean = true;

  public onUpdate = new EventEmitter<ModifiableEntityViewModel>();

  public readonly T = T;
  constructor(
    public bsModalRef: BsModalRef,
    private readonly elemRef: ElementRef,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    const matchingFilter = this.object.filters.find((f) => f.filterType === this.descriptionFilterType);
    this.description = matchingFilter ? matchingFilter.filterValue as string : this.object['description'] as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.setModalContainerDisplayFlex();
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onUpdateDescription() {
    let filter = this.object.filters.find((f) => f.filterType === this.descriptionFilterType);

    if (!filter) {
      filter = FilterUtilities.GenerateFilter(this.descriptionFilterType, this.description, this.description);
      this.object.filters.push(filter);
    } else {
      filter.filterValue = this.description;
      filter.filterText = this.description;
    }

    filter.filterAction = FilterActionTypes.Update;
    this.object['description'] = this.description;

    if(!this.handleChangeInternally) {
      this.onUpdate.next(this.object);
      this.bsModalRef.hide();
    } else {
      if (this.activeLightUpdate) {
        const requestModel: LightFilterUpdateModel = {
          globalObjectId: this.object.id,
          objectType: ObjectTypes.IncidentItem,
          accountId: 0,
          changes: [filter],
          updated: ''
        };

        const currentService = this.service as IncidentsService;
        currentService.updateLight(requestModel).subscribe((res) => {
          const updateFilter = res.changes.find(
            (d) => d.filterType === FilterTypes.Date && d.dateProperty === FilterDateOptions.Updated
          );
          this.object.updated = updateFilter.filterValue as string;
          this.object = Object.assign({}, this.object);

          void this.alertService.success(this.translateService.instant(T.common.headline_status_updated));
          this.bsModalRef.hide();
        });
      } else {
        if(this.handleChangeInternally)
        {
          this.subscriptions.push(
            this.service.update(this.object).subscribe(() => {
              void this.alertService.success(this.translateService.instant(T.common.updated));
              this.bsModalRef.hide();
            })
          );
        }
      }
    }
  }

  get isChanged() {
    let result = false;
    const matchingFilter = this.object.filters.find((f) => f.filterType === this.descriptionFilterType);
    result = (!matchingFilter && !this.description.length) || matchingFilter?.filterValue === this.description;

    return result;
  }

  setModalContainerDisplayFlex() {
    const modalDialog = this.elemRef.nativeElement.closest('[role=document]') as HTMLElement;
    modalDialog.classList.add('overflow-visible');
  }
}
