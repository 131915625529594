import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { ModuleService } from '../../../services/module.service';
import { LocalisationService } from '../../../services/localisation.service';
import { ObjectTypes } from '../../../enums/objectTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { FiltersInlineEditContainerComponent } from '../filters-lozenge-inline-edit/filters-inline-edit-container.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filters-add',
  templateUrl: './filters-add.component.html',
  styleUrls: ['./filters-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-add-mobile]': 'mobile',
  },
})
export class FiltersAddComponent implements OnInit {
  private readonly objectTypesWithoutTags: ObjectTypes[] = [
    ObjectTypes.Runsheet,
  ];

  @Input() mobile: boolean;
  @Input() filterTypeSelectorViewModels: FilterTypeSelectorViewModel[];
  @Input() filters: FilterViewModel[];
  @Input() objectTypes: ObjectTypes[];
  @Output() closed: EventEmitter<void> = new EventEmitter();
  @Output() filtersChanged = new EventEmitter<FilterViewModel[]>();
  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();

  employee: Employee;
  public tagGroupFilters: FilterViewModel[] = [];
  public searchValue: string;
  appliedFilters: FilterViewModel[] = [];
  filteredFilters = new Map<string, FilterViewModel[]>();
  filteredTagsFilters = new Map<string, FilterViewModel[]>();
  selectedTagGroup: FilterViewModel;
  filterTypeSelectorViewModel: FilterTypeSelectorViewModel;
  private filterEditContainerBsModalRef: BsModalRef<FiltersInlineEditContainerComponent>;


  public unitedFiltersAndFilterTypesViewModels: (FilterViewModel | FilterTypeSelectorViewModel)[];
  public filteredUnitedFiltersAndFilterTypesViewModels: (FilterViewModel | FilterTypeSelectorViewModel)[];
  public isAllFiltersShowed = false;
  public readonly T = T;

  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
    private readonly authService: AuthenticationService,
    private readonly moduleService: ModuleService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly bsModalService: BsModalService
  ) {}

  ngOnInit() {
    this.employee = this.authService.getCurrentEmployee();
    this.initTagGroupFilters();

    // Remove Tag filter type as only Tag Groups displayed here
    this.filterTypeSelectorViewModels = this.filterTypeSelectorViewModels.filter(f => f.filterType !== FilterTypes.Tag);

    const coppiedFilterTypesViewModels = JSON.parse(JSON.stringify(this.filterTypeSelectorViewModels));
    const coppiedTagGroupsFilters = JSON.parse(JSON.stringify(this.tagGroupFilters));

    this.unitedFiltersAndFilterTypesViewModels = [...coppiedFilterTypesViewModels, ...coppiedTagGroupsFilters];
    this.filteredUnitedFiltersAndFilterTypesViewModels = this.unitedFiltersAndFilterTypesViewModels.slice(0, 10);
  }

  onCancel(): void {
    this.closed.emit();
  }

  onAddFilter(filterTypeSelectorViewModel: FilterTypeSelectorViewModel): void {
    this.addFiltersType(filterTypeSelectorViewModel);
  }

  initTagGroupFilters() {
    let objTypes = this.moduleService.currentObjectTypes;

    if (!objTypes.length && this.objectTypes) {
      objTypes = this.objectTypes;
    }

    if (this.filters) {
      this.tagGroupFilters = this.filters.filter(
        (s) =>
          s.filterType === FilterTypes.Tag_Group &&
          objTypes &&
          objTypes.indexOf(s.displayForGlobalObjectType) > -1 &&
          !this.objectTypesWithoutTags.includes(+s.displayForGlobalObjectType)
      );

      const newFilters = [];

      this.tagGroupFilters.forEach((f) => {
        if (f.displayForGlobalObjectType == 0 && objTypes) {
          objTypes.forEach((oType) => {
            if (this.objectTypesWithoutTags.indexOf(oType) === -1) {
              const newFilter: FilterViewModel = { ...f };
              newFilter.displayForGlobalObjectType = oType;
              newFilter.filterText = this.localisationService.localiseObjectType(oType) + ` ${f.filterText}`;
              newFilters.push(newFilter);
            }
          });
        }
      });

      this.tagGroupFilters = this.tagGroupFilters.filter((f) => f.displayForGlobalObjectType);
      this.tagGroupFilters = [...this.tagGroupFilters, ...newFilters];
      this.changeDetectorRef.detectChanges();
    }
  }

  uniteFilterSelectorAndTaskGroupFilters() {}

  onTagGroupFilterSelected(tagGroupFilter: FilterViewModel) {
    this.selectTagGroup(tagGroupFilter);
  }

  onSearch(value: string): void {
    this.searchValue = value?.toLowerCase().trim();

    if (value) {
      const filteredFilterTypesViewModels = this.filterTypeSelectorViewModels.filter(
        (f) => f.filterTypeText && f.filterTypeText.toLowerCase().indexOf(this.searchValue) > -1
      );
      const filteredTagGroupFilters = this.tagGroupFilters.filter(
        (f) => f.filterText && f.filterText.toLowerCase().indexOf(this.searchValue) > -1
      );
      this.filteredUnitedFiltersAndFilterTypesViewModels = [...filteredFilterTypesViewModels, ...filteredTagGroupFilters];
    } else {
      this.filteredUnitedFiltersAndFilterTypesViewModels = this.unitedFiltersAndFilterTypesViewModels;
    }

    this.isAllFiltersShowed = true;
    this.changeDetectorRef.markForCheck();
  }

  onShowAllFilters() {
    this.filteredUnitedFiltersAndFilterTypesViewModels = this.unitedFiltersAndFilterTypesViewModels;
    this.isAllFiltersShowed = true;
  }

  addFiltersType(filterTypeSelectorViewModel: FilterTypeSelectorViewModel): void {
    if (filterTypeSelectorViewModel.filterSelectorType === FilterSelectorTypes.Single_Checkbox) {
      const filter = this.filters.find(
        (f) =>
          f.filterSelectorType === filterTypeSelectorViewModel.filterSelectorType &&
          f.filterType === filterTypeSelectorViewModel.filterType
      );

      filter.displayForGlobalObjectType = filterTypeSelectorViewModel.displayForObjectType;

      this.appliedFilters.push(filter);
      this.appliedFilters = this.appliedFilters.slice();
      this.filteredFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
      this.filteredTagsFilters = FilterUtilities.GroupTagFiltersByTagGroup(this.filters, this.appliedFilters);

      this.filtersUpdated.emit(this.appliedFilters);

      return;
    }
    this.filterTypeSelectorViewModel = filterTypeSelectorViewModel;

    this.openEditContainerModal();

    this.changeDetectorRef.detectChanges();
  }

  selectTagGroup(tagGroup: FilterViewModel) {
    this.selectedTagGroup = tagGroup;
    this.openEditContainerModal(
      FilterUtilities.getFilterTypeSelectorViewModelByFilterType(
        this.filterTypeSelectorViewModels,
        FilterTypes.Tag,
        null,
        tagGroup.displayForGlobalObjectType
      ),
      this.selectedTagGroup
    );
    this.changeDetectorRef.detectChanges();
  }

  openEditContainerModal(filterTypeSelector?: FilterTypeSelectorViewModel, filterTagGroup?: FilterViewModel) {
    const account = this.authService.getCurrentAccount();
    const filterSelectorVM = filterTypeSelector ? filterTypeSelector : this.filterTypeSelectorViewModel;
    let filters = FilterUtilities.filterByObjectType(this.filters, filterSelectorVM, this.allowedFiltersService);

    filters = JSON.parse(JSON.stringify(filters)) as FilterViewModel[];

    if (filterSelectorVM.filterType === FilterTypes.Tag) {
      filters = filters.filter((r) => r.relatedObjectId.toString() === filterTagGroup.filterValue.toString());

      filters.forEach((t) => {
        t.filterDropdownTitle = filterTagGroup.filterText;
        t.displayForGlobalObjectType = filterTagGroup.displayForGlobalObjectType;
      });
    }

    if(account.isHubAccount){
      filters = filters.filter((f)=>f.accountId && f.accountId === account.id);
    }

    const initialState = {
      mobile: this.mobile,
      filters: filters,
      appliedFilters: FilterUtilities.getAppliedFilters(this.appliedFilters, filterSelectorVM, this.selectedTagGroup),
      localAppliedFilters: JSON.parse(
        JSON.stringify(FilterUtilities.getAppliedFilters(this.appliedFilters, filterSelectorVM, this.selectedTagGroup))
      ) as FilterViewModel[],
      canClickOnStar: false,
      filterTypeSelectorViewModel: filterSelectorVM,
      singleSelect: FilterUtilities.IsHeaderFilterSingleSelect(
        filterSelectorVM.displayForObjectType,
        filterSelectorVM.filterType
      ),
    };
    this.filterEditContainerBsModalRef = this.bsModalService.show(FiltersInlineEditContainerComponent,  {
      initialState,
      class: 'max-width-unset center-transform',
    });

    const editContainerSubscription = new Subscription();
    editContainerSubscription.add(
      this.filterEditContainerBsModalRef.content.filtersUpdated.subscribe((res) => {
        this.onFiltersChanged(res);

        editContainerSubscription.unsubscribe();
      })
    );
  }

  onFiltersChanged(filters: FilterViewModel[]) {
    this.filtersChanged.emit(filters);
  }

}
