import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ZonePolygon } from 'src/app/modules/shared/viewModels/zonePolygon';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { GMapUtilities } from 'src/app/modules/shared/utilities/gMap.utilities';
import { ShapePointViewModel } from 'src/app/modules/shared/viewModels/shapePointViewModel';
import { BaseMap } from 'src/app/modules/shared/interfaces/map/baseMapInterface';
import { ZoneViewModel } from 'src/app/modules/settings/viewModels/zoneViewModel';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { What3WordInfo } from 'src/app/modules/incidents/models/what3WordInfo';
import { PolygonType } from 'src/app/modules/shared/enums/maps/polygonType';
import { SearchFieldComponent } from '../controls/search-field/search-field.component';
import { IncidentsSettingsService } from 'src/app/modules/shared/services/indicents-settings.service';
import { AreaPolygon } from 'src/app/modules/shared/viewModels/areaPolygon';
import { IPolygonLike } from 'src/app/modules/shared/interfaces/polygonLike.interface';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { MapsLoaderService } from 'src/app/modules/shared/services/mapsLoader.service';

@Component({
  selector: 'app-zone-map',
  templateUrl: './zone-map.component.html',
  styleUrls: ['./zone-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoneMapComponent extends BaseMap implements OnInit, OnDestroy {
  @ViewChild('map', { static: true }) mapElement: ElementRef<HTMLElement>;
  @ViewChild('topLeftControlsWrapper') topLeftControlsWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('topCenterControlWrapper') topCenterControlsWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('searchControl') searchControlComponent: SearchFieldComponent;
  @ViewChild('myLocationControlBtnWrapper') myLocationControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('zoneAreaControlBtnWrapper') zoneAreaControlBtnWrapper: ElementRef<HTMLDivElement>;

  // Input properties
  @Input() lat: number;
  @Input() lng: number;
  @Input() defaultZoom: number;

  /** This property is used for editing mode. When it is not null the map displays the polygon in editing state and therefore a new polygon can not be added */
  @Input() polygonInput: IPolygonLike = null;

  /** Indicator if the newly created polygon is a zone or area. */
  @Input() polygonDrawingMode: PolygonType = PolygonType.ZONE;

  /** Indicator weather the zones and areas to be rendered on the map when the map tiles get loaded. */
  @Input() displayZonesUponLoad = true;

  // Output
  @Output() zonePolygonUpdated: EventEmitter<IPolygonLike> = new EventEmitter<IPolygonLike>();

  private static readonly MIN_POLYGON_VERTEX: number = 3;

  /** A callback function to be executed upon the creation of the zones polygons. */
  private onZonesPolygonCreated: () => void = null;

  private drawingManager: google.maps.drawing.DrawingManager;

  public zonesAndAreasLoaded = false;
  public mapTilesLoaded = false;

  public zoom: number;
  public searchControl: UntypedFormControl;
  public newDrawingZonePolygon: google.maps.Polygon = null;
  public focusZoneId: number;

  public mapControlsReady = false;

  disableMVCArraySetAtEventSubmitter: boolean = false;

  //Zones and areas view models represented by the response of the backend endpoint.
  zones: ZoneViewModel[] = [];
  areas: AreaViewModel[] = [];

  constructor(
    protected geoLocationService: GeoLocationService,
    protected readonly localisationService: LocalisationService,
    private changeDetector: ChangeDetectorRef,
    protected readonly mapsLoaderService: MapsLoaderService,
    protected readonly incidentsSettingsService: IncidentsSettingsService,
  ) {
    super(mapsLoaderService, geoLocationService, localisationService, incidentsSettingsService);
  }

  ngOnInit() {
    this.zoom = this.defaultZoom || 1;
    if(this.polygonInput) {
      this.polygonDrawingMode = (this.polygonInput instanceof ZonePolygon)
        ? PolygonType.ZONE
        : PolygonType.AREA;
    }

    this.initMap();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get gmap(): google.maps.Map {
    return this.map;
  }

  get isMobile(): boolean {
    return super.isMobile;
  }

  get enableZonesAreasStateChange(): boolean {
    return this.polygonInput === null;
  }

  protected initMap() {
    const mapOptions = {
      mapTypeControl: false
    };

    this.subscriptions.push(
      this.googleMapsAPIs.subscribe(() => {
        super.initMap(this.mapElement, mapOptions);
        this.initControlsOverlay();
        this.registerMapEvents();
      })
    );
  }

  private registerMapEvents(): void {
    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      this.processZonesAndAreas();

      this.initDrawingManager();
      this.setupDrawingManager();

      this.initOverlayTiles();

      this.mapTilesLoaded = true;
      this.changeDetector.detectChanges();
    });
  }

  // Override - Used in IncidentDetailsComponent to reset the location
  setComponentLocation(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
    this.zoom = 10;
  }

  public onCancelDrawingPolygonClicked() {
      this.drawingManager.setOptions({
        drawingControl: true,
      });

      if (this.newDrawingZonePolygon) {
        this.newDrawingZonePolygon.setMap(null);
        this.newDrawingZonePolygon = null;
      }

      this.zonePolygonUpdated.emit(this.getPolygonViewModel(null));
  }

  public panToZone(zoneId: number) {
    const zonePolygon = this.zonePolygonMap.get(zoneId);
    if (zonePolygon) {
      const latLngBounds = GMapUtilities.getPolygonLatLngBounds(zonePolygon);
      this.focusZoneId = zoneId;
      this.map.fitBounds(latLngBounds);
    } else {
      this.onZonesPolygonCreated = () => {
        if(this.zonePolygonMap.get(zoneId)) {
          this.focusZoneId = zoneId;
          this.map.fitBounds(GMapUtilities.getPolygonLatLngBounds(
            this.zonePolygonMap.get(zoneId)
          ));
        }
      };
    }
  }

  public onMyLocationAvailable($latLng: google.maps.LatLng): void {
    this.myLocationLatLng = $latLng;
    this.map.panTo(this.myLocationLatLng);
    this.map.setZoom(BaseMap.MY_LOCATION_DEFAULT_ZOOM);
  }

  public onSearchStateChanged($expanded: boolean): void {
    if ($expanded) {
      this.drawingManager.setMap(null);
      this.drawingManager.setDrawingMode(null);
    } else {
      this.drawingManager.setMap(this.map);
    }

    if (this.myLocationControlBtnWrapper) {
      this.myLocationControlBtnWrapper.nativeElement.style.display = $expanded ? 'none' : 'block';
    }
  }

  public onAutocompleteResult($place: google.maps.places.PlaceResult): void {
    if ($place) {
      this.lat = $place.geometry.location.lat();
      this.lng = $place.geometry.location.lng();
      this.zoom = BaseMap.LOCATION_SEARCH_DEFAULT_ZOOM;

      this.map.panTo(new google.maps.LatLng(this.lat, this.lng));
      this.map.setZoom(this.zoom);
    }
  }

  public onWhat3WordsResult(what3WordInfo: What3WordInfo): void {
    this.lat = what3WordInfo.lat;
    this.lng = what3WordInfo.lng;
    this.zoom = BaseMap.W3W_SERCH_DEFAULT_ZOOM;
    this.map.setCenter({ lat: this.lat, lng: this.lng });
    this.map.setZoom(this.zoom);
    this.map.setMapTypeId('satellite');
  }

  private initControlsOverlay() {
    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.zoneAreaControlBtnWrapper.nativeElement);

    if (this.topLeftControlsWrapper) {
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.topLeftControlsWrapper.nativeElement);
    }

    if(this.topCenterControlsWrapper) {
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.topCenterControlsWrapper.nativeElement);
    }

    window.setTimeout(() => {
      this.mapControlsReady = true;
      this.changeDetector.detectChanges();
    }, 1000);
  }

  private initOverlayTiles() {
    function CoordMapType(tileSize) {
      this.tileSize = tileSize;
    }

    CoordMapType.prototype.maxZoom = 19;
    CoordMapType.prototype.name = '';
    CoordMapType.prototype.alt = '';

    CoordMapType.prototype.getTile = function (_coord, _zoom, ownerDocument: any) {
      const div = ownerDocument.createElement('div');
      div.innerHTML = '';
      div.style.width = this.tileSize.width + 'px';
      div.style.height = this.tileSize.height + 'px';
      div.style.opacity = '0.3';
      div.style.backgroundColor = '#000';
      return div;
    };

    this.map.overlayMapTypes.insertAt(0, new CoordMapType(new google.maps.Size(256, 256)));
  }

  private initDrawingManager() {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        strokeColor: this.getPolygonDrawingColor(),
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillColor: this.getPolygonDrawingColor(),
        fillOpacity: 0.2,
        clickable: true,
        draggable: true,
        editable: true,
      },
    });
    this.drawingManager.setMap(this.map);
    this.drawingManager.setDrawingMode(null);

    this.drawingManager.addListener('polygoncomplete', (polygon: google.maps.Polygon) => {
      this.newDrawingZonePolygon = polygon;
      this.drawingManager.setDrawingMode(null);
      this.drawingManager.setOptions({
        drawingControl: false,
      });

      this.map.fitBounds(GMapUtilities.getPolygonLatLngBounds(polygon));
      this.registerPolygonEvents(this.newDrawingZonePolygon);
      this.handlePolygonBoundariesChange(polygon);

      this.mapControlsReady = true;
      this.changeDetector.detectChanges();
    });
    google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', (e) => {
      if(this.drawingManager.getDrawingMode() === "polygon") {
        this.mapControlsReady = false;
        this.changeDetector.detectChanges();
      }
   });

    //Hack to modify the drawing control buttons style
    window.setTimeout(() => {
      const elements: NodeListOf<Element> = document.querySelectorAll<Element>('.gmnoprint');
      const elementsAsArray: NodeListOf<Element> = Array.prototype.slice.call(elements);
      for (let i = 0; i < elementsAsArray.length; i++) {
        const nextElement = elementsAsArray[i] as HTMLInputElement;
        if (nextElement.style.left === '0px' && nextElement.style.top === '0px') {
          const handButtonWrapper = nextElement.childNodes[0] as HTMLElement;
          const drawButtonWrapper = nextElement.childNodes[1] as HTMLElement;

          (handButtonWrapper.firstChild as HTMLElement).style.padding = '6px';
          (drawButtonWrapper.firstChild as HTMLElement).style.padding = '6px';
        }
      }
    }, 1000);
  }

  private setupDrawingManager() {
    if (this.polygonInput && this.polygonInput.shapePoints.length > 0) {
      this.drawingManager.setOptions({
        drawingControl: false,
      });
    }
  }

  private registerPolygonEvents(polygon: google.maps.Polygon): void {
    polygon.addListener('click', (event: google.maps.PolyMouseEvent) => {
      const clickSport = event.vertex;
      if (typeof clickSport === 'number') {
        const clickedVertex: number = clickSport;
        const path = polygon.getPath();
        if (path.getLength() > ZoneMapComponent.MIN_POLYGON_VERTEX) {
          path.removeAt(clickedVertex);

          const polyLatLngBounds = GMapUtilities.getPolygonLatLngBounds(polygon);
          this.map.fitBounds(polyLatLngBounds);

          //this.zonePolygonUpdated.emit(this.getIncidentZonePolygon(polygon));
        }
      }
    });

    google.maps.event.addListener(polygon, 'dragstart', (_event: google.maps.MapMouseEvent) => {
      this.disableMVCArraySetAtEventSubmitter = true;
    });
    google.maps.event.addListener(polygon, 'dragend', (_event: google.maps.MapMouseEvent) => {
      this.disableMVCArraySetAtEventSubmitter = false;
      this.handlePolygonBoundariesChange(polygon);
    });

    polygon.getPaths().forEach((path, _index) => {
      google.maps.event.addListener(path, 'insert_at', () => {
        this.handlePolygonBoundariesChange(polygon);
      });

      google.maps.event.addListener(path, 'remove_at', () => {
        this.handlePolygonBoundariesChange(polygon);
      });

      google.maps.event.addListener(path, 'set_at', () => {
        if (!this.disableMVCArraySetAtEventSubmitter) {
          this.handlePolygonBoundariesChange(polygon);
        }
      });
    });
  }

  private handlePolygonBoundariesChange(polygon: google.maps.Polygon): void {
    const validShape = this.validateDrawingPolygonBoundaries();
    polygon.setOptions({
      strokeColor: validShape ? this.getPolygonDrawingColor() : 'red'
    });

    this.zonePolygonUpdated.emit(this.getPolygonViewModel(polygon));
  }

  /**
   * When a new polygon is drawn on the map or the shape of an exisitng one is modified
   * this function checks if the boundaries are compliant with the following rules:
   *
   * 1. In case the map editing mode is set to ZONE:
   * The function will return true if the boundaries of the polygon contain ALL the vertexes
   * of the polygons of the areas belonging to the zone.
   *
   * 2. In case the map editing mode is set to AREA:
   * The function will return true if ALL the vertexes of the area are inside the boundaries
   * of the polygon of the zone to which it belongs.
   *
   * @returns true if the vertexes of the zone/area which is being drawn on the map are not
   * outside of the parent polygon. Otherwise false is returned.
   */
  private validateDrawingPolygonBoundaries(): boolean {
    let result = true;

    if(this.polygonInput) {
      if(this.polygonDrawingMode === PolygonType.ZONE) {
        const outerVertexFound = this.areas
          .filter(a => a.zoneId === this.polygonInput.id)
          .some(a => {
            return this.areaPolygonMap.get(a.id).getPath().getArray()
              .some(latLng => {
                return !google.maps.geometry.poly.containsLocation(latLng, this.newDrawingZonePolygon);
              });
          });

        result = !outerVertexFound;
      }
    } else {
      if(this.polygonDrawingMode === PolygonType.AREA) {
        const outerVertexFound = this.newDrawingZonePolygon.getPath().getArray()
          .some(latLng => {
            return !google.maps.geometry.poly.containsLocation(latLng, this.zonePolygonMap.get(this.focusZoneId));
          });

        result = !outerVertexFound;
      }
    }

    return result;
  }

  private displayInputPolygon() {
    if (this.polygonInput) {
      const polygon =
        this.polygonDrawingMode === PolygonType.ZONE
          ? this.zonePolygonMap.get(this.polygonInput.id)
          : this.areaPolygonMap.get(this.polygonInput.id);

      if (polygon) {
        polygon.setOptions({
          editable: true,
          draggable: true,
          clickable: true,
        });

        this.newDrawingZonePolygon = polygon;
        this.registerPolygonEvents(this.newDrawingZonePolygon);

        const polyLatLngBounds = GMapUtilities.getPolygonLatLngBounds(this.newDrawingZonePolygon);
        this.map.panToBounds(polyLatLngBounds);
        this.map.fitBounds(polyLatLngBounds);
      }
    }
  }

  private getPolygonViewModel(polygon: google.maps.Polygon): IPolygonLike {
    const latLngPath: ShapePointViewModel[] = [];
    if (polygon !== null) {
      const vertexesArray = polygon.getPath().getArray();
      vertexesArray.forEach((latLng) => {
        latLngPath.push(new ShapePointViewModel(latLng.lat(), latLng.lng()));
      });
    }

    if (this.polygonDrawingMode === PolygonType.ZONE) {
      const zonePolygon = new ZonePolygon(this.polygonInput?.id || 0, latLngPath);
      return zonePolygon;
    } else {
      const areaPolygon = new AreaPolygon(0, latLngPath);
      return areaPolygon;
    }
  }

  private pantToMyLocation(): void {
    if (this.myLocationLatLng) {
      this.map.panTo(this.myLocationLatLng);
      this.map.setZoom(BaseMap.MY_LOCATION_DEFAULT_ZOOM);
    }
  }

  private getPolygonDrawingColor(): string {
    return this.polygonDrawingMode === PolygonType.ZONE
      ? GMapUtilities.DEFAULT_ZONE_POLYGON_COLOR
      : GMapUtilities.DEFAULT_AREA_POLYGON_COLOR;
  }

  private processZonesAndAreas() {
    super.getZonesAndAreas((zones, areas) => {
      this.zones = zones;
      this.areas = areas;
      super.buildPolygonsFromZonesAndAreas(this.zones, this.areas, true);

      if(this.polygonInput && this.polygonInput.shapePoints.length) {
        this.displayInputPolygon();
      } else {
        if(this.onZonesPolygonCreated) {
          this.onZonesPolygonCreated();
          this.onZonesPolygonCreated = null;
        } else {
          this.map.fitBounds(GMapUtilities.getPolygonsLatLngBounds(
            Array.from(this.zonePolygonMap.values()))
          );
        }
      }

      this.zonesAndAreasLoaded = true;
      this.changeDetector.detectChanges();
    });
  }
}
