<ng-template #template>
  <div class="dropdown-options-container">
    <div class="dropdown-option" (click)="addItem()">{{ T.common.new_item | translate }}</div>
    <div class="dropdown-option" (click)="addItems()">{{ addFromTemplateText }}</div>
  </div>
</ng-template>

<div class="checklist-component-wrapper">
  <div class="checklist-header" [style.padding-bottom]="!hideAddButtonFromHeader ? '8px' : '0px'">
    <div class="header-top-right" [ngClass]="{'stretched-header': !showHeaderButtons && !showQuickAdd}">
      <ng-container *ngIf="mobile && !hideAddButtonFromHeader">
        @if (!isMyTrack) {
          <app-button-icon-square
            size="small"
            icon="add"
            class="mr-8"
            buttonTheme="white"
            ngDropdown
            [templateRef]="template"
            [closeUponSelection]="true"
            class="mr-8">
          </app-button-icon-square>
        }
        @else {
          <app-button-icon-square
            size="small"
            icon="add"
            class="mr-8"
            buttonTheme="white"
            (clicked)="addItem()"
            class="mr-8">
          </app-button-icon-square>
        }
      </ng-container>
      <ng-container *ngIf="!mobile">
        <div class="header-title">{{ localisedItems }}</div>
        <ng-container *ngIf="!hideAddButtonFromHeader">
          @if (!isMyTrack) {
            <div class="add-checklist-button" ngDropdown [templateRef]="template" [closeUponSelection]="true">
              <i class="material-symbols-outlined check-icon">add</i>
              <div class="ms-1">{{ T.common.add_item | translate: { item: localisedItem } }}</div>
            </div>
          }
          @else {
            <div class="add-checklist-button" (click)="addItem()">
              <i class="material-symbols-outlined check-icon">add</i>
              <div class="ms-1">{{ T.common.add_item | translate: { item: localisedItem } }}</div>
            </div>
          }
        </ng-container>
      </ng-container>
    </div>
    <div class="checklist-header-buttons" *ngIf="showHeaderButtons">
      <ng-container *ngIf="!hideAddButtonFromHeader">
        <app-toggle-button
          [objects]="toggleOptions"
          [currentlySelectedObject]="selectedToggleOption"
          [bindProperty]="'displayText'"
          (clicked)="onShowHidePastActivities($event)"
        ></app-toggle-button>
      </ng-container>
    </div>
    <div class="checklist-quick-add mt-8" *ngIf="showQuickAdd">
      <app-quick-add-input [placeholder]="quickAddPlaceholder" (titleChange)="onQuickAddChange($event)">
      </app-quick-add-input>
      <app-toggle-button
        [objects]="toggleOptions"
        [currentlySelectedObject]="selectedToggleOption"
        [bindProperty]="'displayText'"
        (clicked)="onShowHidePastActivities($event)"
      ></app-toggle-button>
    </div>
    <div class="header-v2" *ngIf="useHeaderV2">
      <div class="left">
        <div class="header-title">{{ localisedItems }}</div>
      </div>
      <div class="right" *ngIf="!hideAddButtonFromHeader && !isEmptyState()">
        <div class="add-checklist-button" ngDropdown [templateRef]="template" [closeUponSelection]="true">
          <i class="material-symbols-outlined check-icon">add</i>
          <div class="pt-1 ms-1">{{ T.common.add_item | translate: { item: localisedItem } }}</div>
        </div>
        <app-button-transparent
          icon="unfold_more"
          [text]="isExpandedAll ? (T.common.collapse_all | translate) : (T.common.expand_all | translate)"
          (clicked)="toggleExpand()"
        ></app-button-transparent>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <div class="checklist-wrapper custom-scrollbar" resized (resizable)="onResized()">
      <div *ngIf="showChecklistSummary && !isEmptyState()" class="summary-container">
        <div class="first-row">
          <span class="percentage-completed">{{
            T.my_track.count_percentage_complete | translate: { count: percentageComplete }
          }}</span>
          <span class="count-completed">{{
            T.common.count_of_total_check_completed
              | translate: { count: completedChecklists, total: filteredItems.length }
          }}</span>
        </div>
        <div class="second-row">
          <div class="progress-bar">
            <div class="current-progress" [style.width]="percentageComplete + '%'"></div>
          </div>
        </div>
        <div class="third-row">
          <div class="left-side">
            <div class="tab incomplete-tab" *ngIf="incompleteCount">{{ T.common.count_incomplete | translate: { count: incompleteCount } }}</div>
            <div class="tab failed-or-poor-tab" *ngIf="failedOrPoorCount">
              {{ T.common.count_failed_slash_poor | translate: { count: failedOrPoorCount } }}
            </div>
            <div class="tab unsure-or-fair-tab" *ngIf="unsureOrFairCount">
              {{ T.common.count_unsure_slash_fair | translate: { count: unsureOrFairCount } }}
            </div>
          </div>
          <div class="right-side">
            <app-button-transparent
              icon="unfold_more"
              [text]="isExpandedAll ? (T.common.collapse_all | translate) : (T.common.expand_all | translate)"
              (clicked)="toggleExpand()"
            ></app-button-transparent>
          </div>
        </div>
      </div>
      <ng-template #cardTemplate let-item="item">
        <app-checklist-item-card
          [loading]="loadingChecklistItems"
          [showOwnerLozenge]="showOwner"
          style="width: 100%"
          [item]="item"
          [userHasWriteAccess]="canEdit"
          (viewItem)="onView($event)"
          (itemCompleted)="updateItem($event)"
        >
        </app-checklist-item-card>
      </ng-template>

      <app-paginated-cards
        *ngIf="!useGroupedChecklist && filteredItems.length"
        [paginationId]="paginationId"
        [trackByProperty]="'updated'"
        [items]="filteredItems"
        [cardTemplate]="cardTemplate"
        [draggable]="canEdit"
        (onDrag)="onItemsViewOrderChanged($event)"
      >
      </app-paginated-cards>

      <app-paginated-cards
        *ngIf="useGroupedChecklist && filteredItems.length"
        [paginationId]="paginationId"
        [trackByProperty]="'groupTitle'"
        [items]="groupedChecklistItems"
        [cardTemplate]="cardGroupTemplate"
        [draggable]="false"
        (onDrag)="onItemsViewOrderChanged($event)"
      >
      </app-paginated-cards>
      <ng-template #cardGroupTemplate let-item="item">
        <app-checklist-group-card
          [loading]="loadingChecklistItems"
          [canEdit]="canEdit"
          [groupTitle]="item.groupTitle"
          [expanded]="isExpanded(item.groupTitle)"
          [checklistItems]="item.checklistItems"
          (expand)="expandItem($event)"
          [draggable]="showQuickAdd"
          (viewItem)="onView($event)"
          (itemCompleted)="updateItem($event)"
        >
        </app-checklist-group-card>
      </ng-template>

      <ng-container *ngIf="isEmptyState()">
        <section class="d-flex align-items-center justify-content-center empty-state">
          <app-empty-state [hideIcon]="true" [isClickable]="false" [message]="noResultsTitle"></app-empty-state>
          @if (!hideAddButtonFromHeader && !isMyTrack) {
            <div class="add-checklist-button" ngDropdown [templateRef]="template" [closeUponSelection]="true">
              <i class="material-symbols-outlined check-icon">add</i>
              <div class="ms-1">{{ T.common.add_item | translate: { item: localisedItem } | uppercase }}</div>
            </div>
          }
        </section>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <app-loading>{{ T.common.loading | translate }}...</app-loading>
  </ng-container>
</div>
