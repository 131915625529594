<div class="cards-wrapper" [class.cards-wrapper-padding]="applyPadding">
  <table
    class="qa-side-panel-items"
    #dataTable="mfDataTable"
    [mfData]="items"
    [mfRowsOnPage]="itemsPerPage"
    [mfActivePage]="currentPage"
  >
    <tbody [class.with-footer]="items.length > itemsPerPage" (scroll)="onScroll($event)" *ngIf="!draggable">
      <ng-container *ngTemplateOutlet="rowsTemplate"></ng-container>
    </tbody>
    <tbody
      [class.with-footer]="items.length > itemsPerPage"
      (scroll)="onScroll($event)"
      dragula="templateItems"
      [(dragulaModel)]="items"
      *ngIf="draggable"
    >
      <ng-container *ngTemplateOutlet="rowsTemplate"></ng-container>
    </tbody>
    <tfoot *ngIf="items.length > itemsPerPage && showPagination">
      <tr>
        <div class="d-flex">
          <app-pagination
            [style.visibility]="totalPages > 1 ? 'visible' : 'hidden'"
            [paginationId]="paginationId"
            [shownPagesCount]="3"
          ></app-pagination>
        </div>
      </tr>
    </tfoot>
  </table>
</div>

<ng-template #rowsTemplate>
  <tr #row *ngFor="let item of dataTable.data; let i = index; trackBy: trackByFn">
    <td class="d-flex data-table-row" [id]="paginationId + '_' + item.id" [ngClass]="paginationId + '_i_' + i">
      <ng-template [ngTemplateOutlet]="cardTemplate" [ngTemplateOutletContext]="{ item: item, index: i }"></ng-template>
    </td>
  </tr>
</ng-template>
