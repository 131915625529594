import { UntypedFormControl, Validators } from '@angular/forms';

/**
 * @deprecated
 */
export class ValidationUtilities {
  /**
   * @deprecated
   *  * This is deprecated, look in WtValidators
   */
  public static validateAgainstWhiteSpace(value: string): boolean {
    return new UntypedFormControl(value, [Validators.required, Validators.pattern(/\S+/g)]).valid;
  }

  /**
   * @deprecated
   *  * This is deprecated, look in WtValidators
   */
  public static validateAgainstMinLength(value: string, minLength: number = 0): boolean {
    return new UntypedFormControl(value, [Validators.required, Validators.pattern(/\S+/g), Validators.minLength(minLength)]).valid;
  }

  /**
   * @deprecated
   * This is deprecated, look in WtValidators
   */
  public static isEmailValid(email: string): boolean {
    return new UntypedFormControl(email, [Validators.required, Validators.email]).valid;
  }
}
