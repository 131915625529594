import {
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { IncidentsEventsEmitter } from 'src/app/modules/shared/events/incidents.events';
import { DashboardWidgetComponent } from 'src/app/modules/shared/models/dashboardWidgetComponent';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import {
  DashboardWidgetViewModel,
  DashboardWidgetLayoutViewModel,
} from 'src/app/modules/settings/viewModels/dashboardWidgetViewModel';
import { IDashboardManager } from 'src/app/modules/shared/managers/iDashboardManager';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-dashboard-widget-options',
  templateUrl: './dashboard-widget-options.html',
  styleUrls: ['./dashboard-widget-options.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetOptionsComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLDivElement>;
  @Input() allComponents: any[];
  @Input() dashboardManager: IDashboardManager;
  @Input() module: ModuleTypes;
  animationOngoing: boolean = false;
  subscriptions: Subscription[] = [];
  currentEmployee: Employee;
  currentAccount: Account;

  public components: any[] = [];
  public visibleComponents: DashboardWidgetViewModel[] = [];
  public readonly T = T;
  constructor(
    private readonly cachingService: CachingService,
    private readonly incidentEventsEmitter: IncidentsEventsEmitter,
    private readonly dashboardEventEmitters: DashboardEventEmitters,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly localisationService: LocalisationService
  ) {}

  ngOnInit() {
    this.currentAccount = this.cachingService.GetAccountInfo();
    this.currentEmployee = this.cachingService.GetEmployee(this.currentAccount.id);
    this.getStoredWidgets();

    this.subscriptions.push(
      this.dashboardEventEmitters.gridLayoutResetHappened$.subscribe((res) => {
        this.allComponents.forEach((component) => {
          const widget = this.dashboardManager.allDashboardWidgetComponents.find((x) => x.component == component);
          const comp = this.visibleComponents.find((c) => c.containerTagName == widget.tagName);

          if (!comp) {
            this.GenerateDefaultWidget(widget);
          }
        });
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private GenerateDefaultWidget(widget: DashboardWidgetComponent) {
    const newWidget: DashboardWidgetViewModel = new DashboardWidgetViewModel();
    newWidget.containerTagName = widget.tagName;
    newWidget.layout = new DashboardWidgetLayoutViewModel();
    newWidget.layout.cols = widget.defaultLayout.cols;
    newWidget.layout.rows = widget.defaultLayout.rows;
    newWidget.layout.x = widget.defaultLayout.x;
    newWidget.layout.y = widget.defaultLayout.y;
    newWidget.layout.dragEnabled = widget.defaultLayout.dragEnabled;
    this.visibleComponents.push(newWidget);
  }

  private getStoredWidgets() {
    this.subscriptions.push(
      this.employeeSettingsService
        .getEmployeeDashboardWidgetLayout(this.dashboardManager.employeeSettingType)
        .subscribe((res) => {
          this.visibleComponents = Object.assign([], res);
          if (!res || !res.length) {
            this.initDbLoadSubscription();
          }
          this.changeDetectorRef.markForCheck();
        })
    );
  }

  initDbLoadSubscription() {
    this.dashboardEventEmitters.dashboardWidgetComponentsLoaded$
      .subscribe((res) => {
        this.getStoredWidgets();
      })
      .unsubscribe();
  }

  getComponentName(i: number) {
    const component = this.allComponents[i];
    const widget = this.dashboardManager.allDashboardWidgetComponents.find((x) => x.component == component);
    if (widget) {
      return this.localisationService.localise(widget.name);
    }
    return '';
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onCloseSidebar() {
    this.incidentEventsEmitter.broadcastDashboardWidgetOptionsToggled(false);
  }

  isComponentVisible(component: any) {
    const widget = this.dashboardManager.allDashboardWidgetComponents.find((x) => x.component == component);
    if (widget) {
      if (this.visibleComponents.find((c) => c.containerTagName == widget.tagName)) return true;
      return false;
    }
    return false;
  }

  onComponentChecked(state: boolean, component: any) {
    const widget = this.dashboardManager.allDashboardWidgetComponents.find((x) => x.component == component);
    if (!state) {
      const component = this.visibleComponents.find((c) => c.containerTagName == widget.tagName);
      if (component) {
        this.visibleComponents.splice(this.visibleComponents.indexOf(component), 1);
        this.updateWidgets();
        this.dashboardEventEmitters.broadcastDashboardWidgetComponentRemoved(widget);
      }
    } else {
      this.GenerateDefaultWidget(widget);
      this.updateWidgets(widget);
    }

    this.changeDetectorRef.markForCheck();
  }

  updateWidgets(addedWidget: DashboardWidgetComponent = null) {
    this.visibleComponents = this.visibleComponents.sort((a, b) => {
      return a.layout.y - b.layout.y || a.layout.x - b.layout.x;
    });

    this.employeeSettingsService
      .updateEmployeeDashboardWidgets(this.visibleComponents, this.dashboardManager.employeeSettingType)
      .subscribe(() => {
        if (addedWidget) {
          this.dashboardEventEmitters.broadcastDashboardWidgetComponentAdded(addedWidget);
        }
      });
  }
}
