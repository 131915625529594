import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constants';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DocumentCategoryViewModel } from '../viewModels/documents/documentCategoryViewModel';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentCategoryService {
  private actionUrl: string = '';

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`DocumentCategories/`);
  }

  getDocumentCategories(): Observable<DocumentCategoryViewModel[]> {
    return this.http.get<DocumentCategoryViewModel[]>(
      this.actionUrl + 'AllByAccountId',
      this.authenticationService.getHttpOptions()
    );
  }

  getDocumentCategoriesWithDocuments(): Observable<DocumentCategoryViewModel[]> {
    return this.http.get<DocumentCategoryViewModel[]>(
      this.actionUrl + 'AllByAccountIdWithDocuments',
      this.authenticationService.getHttpOptions()
    );
  }

  getSharedResources(): Observable<DocumentCategoryViewModel[]> {
    return this.http.get<DocumentCategoryViewModel[]>(
      this.actionUrl + 'SharedResources',
      this.authenticationService.getHttpOptions()
    );
  }

  addDocumentCategory(documentCategoryViewModel: DocumentCategoryViewModel): Observable<DocumentCategoryViewModel[]> {
    return this.http.post<DocumentCategoryViewModel[]>(
      this.actionUrl + 'Add',
      documentCategoryViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  deleteDocumentCategory(documentCategoryViewModel: DocumentCategoryViewModel): Observable<any> {
    return this.http.delete(
      `${this.actionUrl}Delete/${documentCategoryViewModel.id}`,
      this.authenticationService.getHttpOptions()
    );
  }

  updateDocumentCategory(documentCategoryViewModel: DocumentCategoryViewModel): Observable<any> {
    return this.http.put(this.actionUrl + 'Update', documentCategoryViewModel, this.authenticationService.getHttpOptions());
  }
}
