import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-add-placeholder',
  templateUrl: './add-placeholder.component.html',
  styleUrls: ['./add-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPlaceholderComponent {
  @Input() plural: string;
  @Input() singular: string;

  @Output() add: EventEmitter<void> = new EventEmitter();
  public readonly T = T;

  onAdd() {
    this.add.next();
  }
}
