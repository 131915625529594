<app-modal>
  <ng-container *ngIf="!loading">
    <app-modal-header
      [title]="T.common.share_this_localisedItem | translate: { localisedItem: localisedObjectType }"
      (closed)="bsModalRef.hide()"
    ></app-modal-header>
    <app-modal-body>
      <div class="body-wrapper" #body>
        <div class="row">
          <div class="col-12">
            <label class="label">{{
              T.common.add_user_below_to_share_details_of_this_item | translate: { item: localisedObjectType }
            }}</label>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-rich-text-editor
              (onChange)="onEditorChange($event)"
              [showButtons]="false"
              [useMentions]="false"
              [objectType]="objectType"
            >
            </app-rich-text-editor>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <app-filter-dropdown-new
              [filterByModuleObjectType]="false"
              [filterType]="ownerFilterType"
              [required]="true"
              [appliedFilters]="appliedOwnersFilters"
              [disableMargin]="true"
              [typeahead]="true"
              [label]="localisedEmployee"
              (onFilterUpdate)="onOwnerFilterUpdated($event)"
            >
            </app-filter-dropdown-new>
          </div>
        </div>
        <div class="row mt-8">
          <div class="col-12">
            <app-filter-dropdown-new
              [filterByModuleObjectType]="false"
              [filterType]="filterTypes.User_Group"
              [required]="true"
              [appliedFilters]="appliedOwnersFilters"
              [disableMargin]="true"
              [typeahead]="true"
              [label]="localisedEmployeeGroup"
              (onFilterUpdate)="onOwnerFilterUpdated($event)"
            >
            </app-filter-dropdown-new>
          </div>
        </div>
      </div>
    </app-modal-body>
    <app-modal-footer>
      <app-button-rectangle
        [text]="T.common.cancel | translate"
        [size]="'large'"
        class="mr-8"
        [buttonTheme]="'white'"
        (clicked)="onClose()"
      ></app-button-rectangle>
      <app-button-rectangle
        [disabled]="selectedEmployees?.length === 0 && selectedGroupIds?.length === 0"
        [text]="T.common.share | translate"
        [size]="'large'"
        (clicked)="onSubmit()"
      ></app-button-rectangle>
    </app-modal-footer>
  </ng-container>
  <ng-container>
    <app-loading *ngIf="loading">
      <p>{{ T.common.loading | translate }}...</p>
    </app-loading>
  </ng-container>
</app-modal>
