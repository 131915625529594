export class EqualityUtilities {
  public static objectsEqual = (o1, o2) => {
    if (!isNaN(o1) && !isNaN(o2)) {
      return o1 === o2;
    } else {
      return Object.keys(o1).length === Object.keys(o2).length && Object.keys(o1).every((p) => o1[p] === o2[p]);
    }
  };

  public static arraysEqual = (a1: unknown[], a2: unknown[]) =>
    a1.length === a2.length && a1.every((o, idx) => EqualityUtilities.objectsEqual(o, a2[idx]));
}
