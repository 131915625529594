import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { TaskGroupListItemViewModel } from '../viewModels/taskGroupListItemViewModel';
import { StateService } from './state.service';
import { ValidatedViewModel } from '../../shared/viewModels/validatedViewModel';
import { map } from 'rxjs/operators';
import { IApiService } from '../../shared/interfaces/iHttpService';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';

@Injectable({
  providedIn: 'root',
})
export class TaskGroupService extends StateService<TaskGroupListItemViewModel> implements IApiService {
  private actionUrl: string;
  constructor(private http: HttpClient, private configuration: Configuration) {
    super();
    this.actionUrl = this.configuration.buildEndpoint(`TaskGroup/`);
  }

  add(taskGroup: TaskGroupListItemViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}Add`, taskGroup).pipe(
      map((x) => {
        this.addToList(x.returnModel as TaskGroupListItemViewModel);
        return x;
      })
    );
  }

  list(filters: FilterViewModel[]): Observable<TaskGroupListItemViewModel[]> {
    return this.http.post<TaskGroupListItemViewModel[]>(this.actionUrl + 'List', filters).pipe(
      map((x) => {
        {
          if (!filters.length) this.updateList(x);
          return x;
        }
      })
    );
  }

  update(taskGroup: TaskGroupListItemViewModel): Observable<TaskGroupListItemViewModel> {
    return this.http.post<TaskGroupListItemViewModel>(`${this.actionUrl}Update`, taskGroup).pipe(
      map((x) => {
        this.updateInList(x as any);
        return x;
      })
    );
  }

  details(taskGroupId: number): Observable<TaskGroupListItemViewModel> {
    return this.http.get<TaskGroupListItemViewModel>(`${this.actionUrl}Details/${taskGroupId}`).pipe(
      map((x) => {
        this.addToList(x);
        return x;
      })
    );
  }

  delete(taskGroupId: number) {
    return this.http.post(`${this.actionUrl}Delete/${taskGroupId}`, {}).pipe(
      map((x) => {
        this.deleteFromList(x as TaskGroupListItemViewModel);
        return x;
      })
    );
  }

  archive(taskGroupId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${taskGroupId}/${archive}`, null).pipe(
      map((x) => {
        this.deleteFromList(x as TaskGroupListItemViewModel);
        return x;
      })
    );
  }

  updateLozenges(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  pinnedList(): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }
}
