import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Tooltip } from '../models/tooltip';

@Injectable()
export class TooltipEventsEmitter {
  private tooltipChanged = new Subject<Tooltip>();
  private tooltipVisibilityChanged = new Subject<boolean>();
  private tooltipElementChanged = new Subject<HTMLElement>();
  private tooltipDirectiveInitialized = new Subject<void>();

  tooltipChanged$ = this.tooltipChanged.asObservable();
  tooltipVisibilityChanged$ = this.tooltipVisibilityChanged.asObservable();
  tooltipElementChanged$ = this.tooltipElementChanged.asObservable();
  tooltipDirectiveInitialized$ = this.tooltipDirectiveInitialized.asObservable();

  broadcastTooltipChanged(tooltip: Tooltip) {
    this.tooltipChanged.next(tooltip);
  }

  broadcastTooltipVisibilityChanged(visible: boolean) {
    this.tooltipVisibilityChanged.next(visible);
  }

  broadcastTooltipElementChanged(tooltipElement: HTMLElement) {
    this.tooltipElementChanged.next(tooltipElement);
  }

  broadcastTooltipDirectiveInitialized() {
    this.tooltipDirectiveInitialized.next();
  }
}
