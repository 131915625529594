<app-modal>
  <app-modal-header [title]="widgetConfigTranslated" (closed)="onCancel()"> </app-modal-header>
  <app-modal-body>
    <div class="body-wrapper">
      <app-countdown-dashboard-settings
        *ngIf="dashboardWidgetName === 'DashboardCountdownComponent'"
        [modalActionHandlerClick]="modalActionHandleClicked$"
      ></app-countdown-dashboard-settings>
      <app-info-widget-dashboard-settings
        *ngIf="dashboardWidgetName === 'PlanningDashboardGeneralInfoComponent'"
        [modalActionHandlerClick]="modalActionHandleClicked$"
        [module]="moduleType"
      ></app-info-widget-dashboard-settings>
      <app-info-widget-dashboard-settings
        *ngIf="dashboardWidgetName === 'IncidentDashboardGeneralInfoComponent'"
        [modalActionHandlerClick]="modalActionHandleClicked$"
        [module]="moduleType"
      ></app-info-widget-dashboard-settings>
      <app-weather-widget-dashboard-settings
        *ngIf="dashboardWidgetName === 'DashboardWeatherOWComponent'"
        [modalActionHandlerClick]="modalActionHandleClicked$"
      >
      </app-weather-widget-dashboard-settings>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <div class="footer-wrapper">
      <app-button-rectangle
        [buttonTheme]="'white'"
        [size]="'medium'"
        [text]="T.common.cancel | translate"
        (clicked)="onCancel()"
      ></app-button-rectangle>
      <app-button-rectangle
        [buttonTheme]="'primary'"
        [size]="'medium'"
        [text]="T.common.confirm | translate"
        (clicked)="onConfirm()"
      ></app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>
