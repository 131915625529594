import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, iif, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Configuration } from 'src/app/app.constants';
import { ObjectTypes } from '../enums/objectTypes';
import { EqualityUtilities } from '../utilities/equality.utilities';
import { FilterTypeSelectorViewModel } from '../viewModels/filters/filterTypeSelectorViewModel';

@Injectable({
  providedIn: 'root',
})
export class SortingFiltersService {
  private url: string;
  private sortingModalVMs: { objectTypes: ObjectTypes[]; vms: FilterTypeSelectorViewModel[] }[] = [];
  /**
   * This Subject is used to trigger refresh of the sorting modal filter types (FilterTypeSelectorViewModels)
   */
  private readonly _refresherSubject = new BehaviorSubject<{ objectTypes: ObjectTypes[]; vms: FilterTypeSelectorViewModel[] }[]>([]);
  readonly refresher$ = this._refresherSubject.asObservable();

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.url = this.configuration.buildEndpoint(`Filters/`);
  }

  /**
   * Get Sorting an custom column modal filter types
   */
  public getSortingModalFilterTypes(objectTypes: ObjectTypes[]) {
    return this.refresher$.pipe(
      map(() => this.sortingModalVMs.find((m) => EqualityUtilities.arraysEqual(m.objectTypes, objectTypes))),
      switchMap((matchingData) =>
        iif(
          () => !!matchingData,
          of(matchingData?.vms),
          this.getSortingFilterSelectors(objectTypes))
      )
    )
  }

  private getSortingFilterSelectors(objectTypes: ObjectTypes[]) {
    return this.http.post<FilterTypeSelectorViewModel[]>(`${this.url}sortingmodal`, objectTypes).pipe(
      map((result) => {
        this.sortingModalVMs = this.sortingModalVMs.filter((m) => !EqualityUtilities.arraysEqual(m.objectTypes, objectTypes));
        this.sortingModalVMs.push({ objectTypes: objectTypes, vms: result });
        return result;
      })
    );
  }

  /**
   * Clear preselected Filter Types in the sorting modal
   * @param objectTypes the obejct type for which the sorting modal is opened
   */
  public clearSortingModalVMByObjectType(objectTypes: ObjectTypes[]) {
    const matchingData = this.sortingModalVMs.find((m) => EqualityUtilities.arraysEqual(m.objectTypes, objectTypes));
    if (matchingData) {
      this.sortingModalVMs = this.sortingModalVMs.filter((m) => !EqualityUtilities.arraysEqual(m.objectTypes, objectTypes));
      this._refresherSubject.next([]);
    }
  }
}
