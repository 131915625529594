import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { RiskActionDetailsViewModel } from '../../risk/models/riskActionDetailsViewModel';
import { RiskActionListViewModel } from '../../risk/models/riskActionListViewModel';
import { Observable } from 'rxjs';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { StateService } from './state.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ActionRiskService extends StateService<RiskActionListViewModel> {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    super();
    this.actionUrl = this.configuration.buildEndpoint(`RiskActionItem/`);
  }

  create(action: RiskActionDetailsViewModel) {
    return this.http.post(`${this.actionUrl}Add`, action);
  }

  getList(
    filters: FilterViewModel[],
    showPinnedOnly: boolean = false,
    customSortFilter: number = 0
  ): Observable<RiskActionListViewModel[]> {
    return this.http.post<RiskActionListViewModel[]>(`${this.actionUrl}List/${showPinnedOnly}/${customSortFilter}`, filters);
  }

  getDetails(actionId: number): Observable<RiskActionDetailsViewModel> {
    return this.http.get<RiskActionDetailsViewModel>(`${this.actionUrl}Details/${actionId}`);
  }

  update(action: RiskActionDetailsViewModel) {
    return this.http.put(`${this.actionUrl}Update`, action);
  }

  delete(actionId: number) {
    return this.http.delete(`${this.actionUrl}Delete/${actionId}`);
  }

  archive(actionId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${actionId}/${archive}`, null).pipe(
      map((res) => {
        this.deleteFromList(actionId);
        return res;
      })
    );
  }

  addToRisk(riskId: number, actionIds: number[]) {
    return this.http.post(`${this.actionUrl}AddToRisk/${riskId}`, actionIds);
  }

  bulkArchive(actionIds: number[]) {
    return this.http.post(`${this.actionUrl}BulkArchive`, actionIds);
  }
}
