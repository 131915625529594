<ng-container *ngIf="allTagGroupFilters.length > 0">
  <ng-container *ngIf="!accordionLozenge">
    <app-details-lozenge
      [useLightUpdate]="useLightUpdate"
      [ngClass]="classes"
      *ngFor="let f of allTagGroupFilters"
      [heading]="f.filterText"
      [relatedObjectId]="f.filterValue"
      [filters]="getTagFiltersByTagGroup(f.filterValue)"
      [filterType]="filterTypes.Tag"
      [canEdit]="canEdit"
      (onFiltersUpdated)="updateFilters($event, f.filterValue)"
    >
    </app-details-lozenge>
  </ng-container>
  <ng-container *ngIf="accordionLozenge">
    <div class="tag-group-row row" *ngFor="let f of allTagGroupFilters">
      <div class="tag-group-title" [ngClass]="accordionTagTitleClass">{{ f.filterText }}</div>
      <div class="tag-group-value" [ngClass]="accordionTagValueClass">
        <app-details-lozenge
          [useLightUpdate]="useLightUpdate"
          [ngClass]="classes"
          [heading]="f.filterText"
          [relatedObjectId]="f.filterValue"
          [accordionLozenge]="accordionLozenge"
          [filters]="getTagFiltersByTagGroup(f.filterValue)"
          [filterType]="filterTypes.Tag"
          [canEdit]="canEdit"
          (onFiltersUpdated)="updateFilters($event, f.filterValue)"
        >
        </app-details-lozenge>
      </div>
    </div>
  </ng-container>
</ng-container>
