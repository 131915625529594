import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentCategoryViewModel } from 'src/app/modules/shared/viewModels/documents/documentCategoryViewModel';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PrivacyStatuses } from '../../../enums/privacyStatuses';
import { UploadTypes } from '../../../enums/uploadTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { IDropdownState } from '../../../models/interfaces/dropdown-state.interface';
import { DropdownEventsEmitter } from '../../../events/dropdown.events';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-document-details-modal',
  templateUrl: 'document-details-modal.component.html',
  styleUrls: ['document-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentDetailsModalComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  @ViewChild('body', { static: true }) body: ElementRef<HTMLElement>;
  title = this.translateService.instant(T.common.add_item, { item: this.translateService.instant(T.common.attachment.one) });
  uploadDocumentViewModel: UploadViewModel;
  documentCategories: DocumentCategoryViewModel[] = [];
  selectedDocumentCategory: DocumentCategoryViewModel;
  selectedDocumentCategories: DocumentCategoryViewModel[] = [];
  onSubmit: EventEmitter<UploadViewModel> = new EventEmitter<UploadViewModel>();
  cancelled: EventEmitter<UploadViewModel> = new EventEmitter<UploadViewModel>();
  loaded: boolean = false;
  privacyStatuses: { key: PrivacyStatuses; value: string }[] = EnumUtilities.items(PrivacyStatuses);
  privacyStatus: { key: PrivacyStatuses; value: string } = this.privacyStatuses.find((s) => s.key == PrivacyStatuses.Open);
  uploadTypes = UploadTypes;
  documentDetailsForm: UntypedFormGroup = new UntypedFormGroup({
    link: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern("^(?:http(s)?://)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+$"),
    ]),
    title: new UntypedFormControl('', []),
    description: new UntypedFormControl('', []),
  });
  filterType = FilterTypes.Document_Category;
  filterSettings: FilterViewModel[] = [];
  public readonly T = T;

  constructor(
    public bsModalRef: BsModalRef,
    public changeDetectorRef: ChangeDetectorRef,
    public dropdownEventsEmitter: DropdownEventsEmitter,
    private translateService: TranslateService,
    private readonly alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.documentCategories = this.documentCategories.filter((c) => c.id != 0);
    this.documentCategories = Object.assign([], this.documentCategories);

    this.selectedDocumentCategories = this.documentCategories.filter(
      (c) => this.uploadDocumentViewModel.documentCategoryIDs.indexOf(c.id) !== -1
    );
    this.initDropdownValues();

    this.uploadDocumentViewModel.title = this.uploadDocumentViewModel.title ? this.uploadDocumentViewModel.title : '';
    this.uploadDocumentViewModel.link = this.uploadDocumentViewModel.link ? this.uploadDocumentViewModel.link : '';
    if (this.uploadDocumentViewModel.uploadType !== UploadTypes.Hyperlink) {
      this.documentDetailsForm.removeControl('link');
    }

    if (!this.uploadDocumentViewModel.privacyStatus) {
      this.uploadDocumentViewModel.privacyStatus = this.privacyStatus.key;
    } else {
      this.privacyStatus = this.privacyStatuses.find((s) => s.key == this.uploadDocumentViewModel.privacyStatus);
    }

    if (this.uploadDocumentViewModel.title) {
      this.documentDetailsForm.controls['title'].setValue(this.uploadDocumentViewModel.title);
    }

    if (this.uploadDocumentViewModel.link) {
      this.documentDetailsForm.controls['link'].setValue(this.uploadDocumentViewModel.link);
    }

    if (this.uploadDocumentViewModel.description) {
      this.documentDetailsForm.controls['description'].setValue(this.uploadDocumentViewModel.description);
    }

    this.loaded = true;
    this.changeDetectorRef.detectChanges();

    this.subscriptions.push(
      this.documentDetailsForm.valueChanges.subscribe((res) => {
        if (res.link) {
          this.uploadDocumentViewModel.link = res.link;
        }

        if (res.title) {
          this.uploadDocumentViewModel.title = res.title;
        }

        if (res.description) {
          this.uploadDocumentViewModel.description = res.description;
        }
      })
    );
  }

  private initDropdownValues() {
    this.filterSettings = this.extractFilterSettings(this.selectedDocumentCategories);
    this.changeDetectorRef.markForCheck();
  }

  private extractFilterSettings(documentCategories: DocumentCategoryViewModel[]) {
    const filterSettings: FilterViewModel[] = [];

    documentCategories.forEach((d) => {
      filterSettings.push(FilterUtilities.GenerateFilter(FilterTypes.Document_Category, d.id.toString(), d.title));
    });

    return filterSettings;
  }

  onDropdownStateChanged(dropdownState: IDropdownState, filterType: FilterTypes) {
    this.changeDetectorRef.markForCheck();
  }

  onCategorySelect(updatedFilterSettings: FilterViewModel[]) {
    this.filterSettings = Object.assign([], updatedFilterSettings);
    this.changeDetectorRef.detectChanges();
  }

  onPrivacyStatusSelected(privacyStatus: { key: PrivacyStatuses; value: string }) {
    this.privacyStatus = privacyStatus;
    this.uploadDocumentViewModel.privacyStatus = privacyStatus.key;
  }

  onCancel() {
    this.cancelled.next(this.uploadDocumentViewModel);
    this.bsModalRef.hide();
  }

  onConfirm() {
    if (this.documentDetailsForm.valid) {

      if(this.uploadDocumentViewModel.title.toLowerCase().indexOf('javascript:') !== -1) {
        void this.alertService.error("Invalid title", "Title should not contain javascript code.");
        return;
      }

      if(this.uploadDocumentViewModel.fileName.toLowerCase().indexOf('javascript:') !== -1) {
        void this.alertService.error("Invalid file name", "File name should not contain javascript code.");
      }

      if(this.uploadDocumentViewModel.link.toLowerCase().indexOf('javascript:') !== -1) {
        void this.alertService.error("Invalid link", "URL should not contain javascript code.");
      }

      this.uploadDocumentViewModel.title = this.uploadDocumentViewModel.title.trim();
      this.uploadDocumentViewModel.documentCategoryIDs = [];

      if (this.filterSettings && this.filterSettings.length) {
        const documentCategoryFilterSettings = this.filterSettings.filter((s) => s.filterType === this.filterType);

        if (documentCategoryFilterSettings) {
          documentCategoryFilterSettings.forEach((c) =>
            this.uploadDocumentViewModel.documentCategoryIDs.push(Number(c.filterValue))
          );
        }
      }
      if (
        this.uploadDocumentViewModel.uploadType === UploadTypes.Hyperlink &&
        !this.uploadDocumentViewModel.link.startsWith('https')
      ) {
        this.uploadDocumentViewModel.link = 'https://' + this.uploadDocumentViewModel.link;
      }
      this.onSubmit.next(this.uploadDocumentViewModel);
      this.bsModalRef.hide();
    }
  }
}
