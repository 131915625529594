import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { EmployeeViewModel } from '../viewModels/employeeViewModel';
import { Comment } from '../models/comment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private actionUrl: string = '';
  private _employees = new BehaviorSubject<EmployeeViewModel[]>([]);

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Comments/`);
  }

  addComment(comment: Comment): Observable<any[]> {
    return this.http.post<any[]>(this.actionUrl + 'Add', comment, this.authenticationService.getHttpOptions());
  }

  resetEmployees() {
    this._employees.next([]);
  }

  getAllEmployeesByAccountId(): Observable<EmployeeViewModel[]> {
    if (this._employees.getValue() !== undefined && this._employees.getValue().length > 0) {
      return this._employees.asObservable();
    } else {
      return this.http
        .get<EmployeeViewModel[]>(this.actionUrl + 'AllEmployeesByAccountId', this.authenticationService.getHttpOptions())
        .pipe(
          tap((res) => {
            this._employees.next(res);
          })
        );
    }
  }
}
