import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Configuration } from 'src/app/app.constants';
import { DocumentTypes } from 'src/app/modules/shared/enums/documentTypes';
import { UploadTypes } from 'src/app/modules/shared/enums/uploadTypes';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { DocumentViewModel } from 'src/app/modules/shared/viewModels/documents/documentViewModel';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { AttachmentsUtilities } from '../../../utilities/attachments.utilities';

@Component({
  selector: 'app-attachment-card',
  templateUrl: 'attachment-card.component.html',
  styleUrls: ['attachment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentCardComponent implements OnInit {
  @Output() onAttachmentLinkClicked = new EventEmitter<DocumentViewModel>();
  @Output() onaAttachmentDelete = new EventEmitter<DocumentViewModel>();
  @Output() onAttachmentDownload = new EventEmitter<DocumentViewModel>();
  @Input() attachment: DocumentViewModel;
  @Input() uploadViewModel: UploadViewModel;
  @Input() accountId: number;
  public attachmentCreatedDate: string = '';
  public attachmentType: DocumentTypes;
  public attachmentTypes = DocumentTypes;

  constructor(private readonly urlService: UrlService,
    private readonly configuration: Configuration) {}

  ngOnInit() {
    this.attachmentCreatedDate = moment(this.attachment.created).format('DD MMM, YYYY HH:mm');
    this.attachmentType = AttachmentsUtilities.getDocumentType(this.attachment).type;
  }

  public getDocumentIcon(attachment: DocumentViewModel): string {
    if (this.attachmentType === DocumentTypes.Image) {
      return `<img style="width: 100%; height: 66px; border-radius: 2px 2px 0px 0px;" src="${this.documentUrl(
        attachment
      )}" class="image">`;
    } else {
      return AttachmentsUtilities.getDocumentIcon(
        AttachmentsUtilities.getDocumentType(attachment).type,
        AttachmentsUtilities.getDocumentType(attachment).ext
      );
    }
  }

  public documentUrl(document: DocumentViewModel): string {
    if (document) {
      let resourseUrl = document.filename;
      if (resourseUrl) {
        resourseUrl = resourseUrl.toLowerCase().startsWith(this.configuration.ResourceFolderName.toLowerCase())
          ? resourseUrl
          : `/${this.configuration.ResourceFolderName}/${this.accountId}/${resourseUrl}`;
      }
      return document.uploadType === UploadTypes.File ? this.urlService.buildResourceUrl(resourseUrl) : document.filename;
    }
  }

  public onAttachmentClick() {
    if (this.attachmentType === DocumentTypes.Link) {
      this.onAttachmentLinkClicked.next(this.attachment);
    }
  }

  public downloadAttachment(event: Event): void {
    event.stopPropagation();
    this.onAttachmentDownload.emit(this.attachment);
  }

  public deleteAttachment(event: Event): void {
    event.stopPropagation();
    this.onaAttachmentDelete.emit(this.attachment);
  }
}
