import { What3WordInfo } from './what3WordInfo';

export class PulsingIncidentMarker  {
  private static offsetX = -16;
  private static offsetY = 16;

  private map: google.maps.Map;
  private marker: google.maps.Marker;
  private what3WordInfo: What3WordInfo;
  private displayWhat3WordPolygon: boolean;
  private pixelOffset: google.maps.Size;
  private severityColor: string;
  private overlapEnabled: boolean;
  private pulsingContentWrapper: HTMLDivElement;
  private onPositionChanged: Function;

  constructor(position: google.maps.LatLng, color: string, map: google.maps.Map) {
    //super();
    this.map = map;
    this.severityColor = color;
    this.overlapEnabled = false;
    this.pixelOffset = new google.maps.Size(PulsingIncidentMarker.offsetX, PulsingIncidentMarker.offsetY);
    this.initMarker(position);

    this.setMap(map);
  }

  onAdd(): void {
    const contentWrapper = document.createElement('DIV') as HTMLDivElement;
    contentWrapper.style.position = 'absolute';
    contentWrapper.style.whiteSpace = 'nowrap';

    const pulsingContent = document.createElement('DIV') as HTMLDivElement;
    pulsingContent.setAttribute('class', 'pulsing-marker');

    //const innerElem = document.createElement('DIV') as HTMLDivElement;
    //innerElem.setAttribute('class', 'pulsing-marker-inner');

    //pulsingContent.appendChild(innerElem);
    contentWrapper.appendChild(pulsingContent);

    this.pulsingContentWrapper = contentWrapper;

    const panes = null;//this.getPanes();
    panes.mapPane.appendChild(contentWrapper);

    const UID = {
      _current: 0,
      getNew: function () {
        this._current++;
        return this._current;
      },
    };

    (HTMLDivElement.prototype as any).pseudoStyle = function (element: any, prop: any, value: any) {
      const _sheetId = 'pseudoStyles';
      const _head = document.head || document.getElementsByTagName('head')[0];
      const _sheet = document.getElementById(_sheetId) || document.createElement('style');
      _sheet.id = _sheetId;
      const className = 'pseudoStyle' + UID.getNew();

      this.className += ' ' + className;

      _sheet.innerHTML += ' .' + className + ':' + element + '{' + prop + ':' + value + '}';
      _head.appendChild(_sheet);
      return this;
    };

    (pulsingContent as any).pseudoStyle(':before', 'border', `1px solid ${this.severityColor}`);
    (pulsingContent as any).pseudoStyle(':after', 'border', `1px solid ${this.severityColor}`);

    if (this.overlapEnabled) {
      this.setupZIndex();
    }
  }

  onRemove(): void {
    this.pulsingContentWrapper.parentNode.removeChild(this.pulsingContentWrapper);
  }

  draw(): void {
    if (this.pulsingContentWrapper) {
      const projection = null; //this.getProjection();
      const position = projection.fromLatLngToDivPixel(this.getPosition());

      const p = position;
      const h = this.pulsingContentWrapper.clientHeight;

      this.pulsingContentWrapper.style.left = p.x + this.pixelOffset.width + 'px';
      this.pulsingContentWrapper.style.top = p.y + this.pixelOffset.height - h + 'px';

      if (this.pulsingContentWrapper.style.zIndex) {
      }
    } else {
      window.setTimeout(() => {
        this.draw();
      }, 10);
    }
  }

  attachEvent(eventName: string, callback: Function): void {
    google.maps.event.addListener(this.marker, eventName, () => {
      callback();
    });
  }

  onPositionChangedByAPI(onPositionChanged: Function): void {
    this.onPositionChanged = onPositionChanged;
  }

  setMap(map: google.maps.Map): void {
    //super.setMap(map);
    this.marker.setMap(map);
    if (this.what3WordInfo) {
      if (map && this.displayWhat3WordPolygon) {
        this.what3WordInfo.polygon.setMap(map);
      }
      if (!map) {
        this.what3WordInfo.polygon.setMap(null);
      }
    }
  }

  getPosition(): google.maps.LatLng {
    return this.marker.getPosition();
  }

  setPosition(position: google.maps.LatLng): void {
    this.marker.setPosition(position);
    if (this.onPositionChanged !== null) {
      this.onPositionChanged();
    }

    if (this.overlapEnabled) {
      this.setupZIndex();
    }

    // if (this.getMap() !== null) {
    //   this.draw();
    // }
  }

  setDraggable(draggable: boolean) {
    this.marker.setDraggable(draggable);
  }

  getWhat3WordInfo(): What3WordInfo {
    return this.what3WordInfo;
  }

  setWhat3WordInfo(info: What3WordInfo) {
    this.what3WordInfo = info;
  }

  toggleWhat3Polygon(state: boolean) {
    this.displayWhat3WordPolygon = state;
    if (this.what3WordInfo) {
      if (state) {
        this.what3WordInfo.polygon.setMap(this.map);
      } else {
        this.what3WordInfo.polygon.setMap(null);
      }
    }
  }

  private setupZIndex(): void {
    const magicalNumber = 1000;
    const anotherMagicalNumber = 90;
    const z = magicalNumber * (anotherMagicalNumber - this.getPosition().lat());
    this.pulsingContentWrapper.style.zIndex = z + '';
  }

  private initMarker(position: google.maps.LatLng): void {
    const markerOptions = {
      position: position,
      draggable: false,
      clickable: true,
      optimized: true,
      animation: google.maps.Animation.DROP,
      icon: {
        anchor: null,
        url: `data:image/svg+xml;utf-8, \<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="${this.getRGBColor()}" d="M12 2C8.13 2 5 5.13 5 9c0 4.17 4.42 9.92 6.24 12.11.4.48 1.13.48 1.53 0C14.58 18.92 19 13.17 19 9c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>`,
        scaledSize: new google.maps.Size(32, 32),
      },
    };

    this.marker = new google.maps.Marker(markerOptions);
    this.marker.setMap(this.map);
  }

  private getRGBColor(): string {
    // "03F" -> "0033FF"
    if (this.severityColor == null) {
      this.severityColor = '#5fa32e';
    }

    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const hex = this.severityColor.replace(shorthandRegex, function (_m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };

    return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  }
}
