import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Configuration } from 'src/app/app.constants';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { HubSideNavModel } from 'src/app/modules/shared/models/hubSideNavigation';
import { NavigationItem } from 'src/app/modules/shared/models/navigationItem';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { T } from 'src/assets/i18n/translation-keys';
import { WtStorageService } from 'src/app/modules/shared/services/wt-storage.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { UtilitiesService } from 'src/app/modules/shared/services/utilities.service';
import { Subscription } from 'rxjs';
import { AbstractSideNavbarComponent } from '../../../../abstract/abstract-side-navbar/abstract-side-navbar.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hub-side-navbar',
  templateUrl: './hub-side-navbar.component.html',
  styleUrl: './hub-side-navbar.component.scss'
})
export class HubSideNavbarComponent extends AbstractSideNavbarComponent implements OnInit, AfterViewInit {
  private subscriptions: Subscription = new Subscription();

  public T = T;
  public navigationModel: HubSideNavModel = new HubSideNavModel();
  public isSidebarCollapsed: boolean;

  constructor(
    private readonly translateService: TranslateService,
    private readonly localisationService: LocalisationService,
    protected readonly configuration: Configuration,
    protected readonly urlService: UrlService,
    protected readonly router: Router,
    protected readonly http: HttpClient,
    protected readonly wtStorage: WtStorageService,
    protected readonly authenticationService: AuthenticationService,
    protected readonly cachingService: CachingService,
    protected readonly authService: AuthService,
    protected readonly utilitiesService: UtilitiesService,
  ) {
    super(
      configuration,
      http,
      urlService,
      wtStorage,
      authService,
      authenticationService,
      cachingService,
      utilitiesService,
      router
    );
   }

  ngOnInit(): void {
    super.ngOnInit();

    if(!this.currentAccount?.isHubAccount) {
      throw new Error("Non hub account is not supported in this version");
    }

    this.brandingUrl = '/assets/images/wetrack_logo_colour_rgb.png';

    this.initSideNavModel();
    this.initSubscriptions();
  }

  ngAfterViewInit(): void {
    const logoContainer = document.querySelector<HTMLDivElement>(".shiny");
    logoContainer.addEventListener("mousemove", (e) => {
        const { x, y } = logoContainer.getBoundingClientRect();
        logoContainer.style.setProperty("--x", `${e.clientX - x}`);
        logoContainer.style.setProperty("--y", `${e.clientY - y}`);
      });
  }

  protected initSideNavModel(): void {
    const localisedIncident = this.localisationService.localiseObjectType(ObjectTypes.IncidentItem, true);
    const localisedRisk = this.localisationService.localiseObjectType(ObjectTypes.Risk, true);

    this.navigationModel.navigationGroups = [
      {
          title: null,
          items: [
              new NavigationItem('Home', '/v2/hub/home', 'home', false, 'home'),
              new NavigationItem(this.translateService.instant(T.common.report.many), '/v2/reports', 'list_alt', false, 'reports'),
              new NavigationItem(this.translateService.instant(T.hub.common.hub_management), '/v2/hub/account', 'settings', false, 'settings'),
              new NavigationItem(this.translateService.instant(T.defaultLocalizations.event.many), '/v2/hub/events', 'event', false, 'event')
          ]
      },
      {
        title: this.translateService.instant(T.common.planning),
        items: [
          new NavigationItem(this.translateService.instant(T.common.planning), '/v2/hub/planning', 'folder_open', false, 'planning'),
          new NavigationItem(this.translateService.instant(T.hub.common.work_table), '/v2/hub/table', 'grid_view', false, 'wwork_table'),
          new NavigationItem(this.translateService.instant(T.hub.common.gantt_chart), '/v2/hub/gantt', 'access_time', false, 'gantt_chart'),
        ]
      },
      {
        title: this.translateService.instant(T.common.control),
        items: [
          new NavigationItem(this.translateService.instant(T.common.list_type, { type: localisedIncident }), '/v2/hub/control', 'error', false, 'control')
        ]
      },
      {
        title: this.translateService.instant(T.common.risk),
        items: [
          new NavigationItem(this.translateService.instant(T.common.list_type, { type: localisedRisk }), '/v2/hub/risk', 'warning', false, 'risk')
        ]
      }
    ];

    if(this.currentAccount.useSustainability) {
      this.navigationModel.navigationGroups.push({
          title: this.translateService.instant(T.common.sustainability),
          items: [
            new NavigationItem(this.translateService.instant(T.common.dashboard), '/v2/hub/sustainability/dashboard', 'dashboard', false, 'sus_dashboard'),
            new NavigationItem(this.translateService.instant(T.sustainability.emissions_overview), '/v2/hub/sustainability/emissions-overview', 'dashboard', false, 'sus_emissions_overview'),
            new NavigationItem(this.translateService.instant(T.common.data_view), '/v2/hub/sustainability/data', 'grid_view', false, 'sus_data')
          ]
        }
      )
    }
  }

  private initSubscriptions() {
    this.subscriptions.add(
      this.utilitiesService.sidebarToggled$.subscribe((isToggled) => {
        this.isSidebarCollapsed = !isToggled
      })
    );
  }
}
