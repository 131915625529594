import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextAreaComponent),
  multi: true,
};

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class TextAreaComponent implements ControlValueAccessor {
  @ViewChild('textarea') textarea: ElementRef<HTMLTextAreaElement>;
  // the internal data model
  private _value: string = '';
  // placeholders for the callbacks which are later provided by the Control Value Accessor
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (value: string) => void = () => {};

  @Input() placeholder: string = '';
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() rows: number = 3;
  @Input() required: boolean;
  @Input() autofocus: boolean;
  @Input() formGroup: UntypedFormGroup;
  @Input() formControlName: string;
  @Input() helperText: string;
  @Input() disabled: false;
  @Input() errorMessage: string;
  @Input() preventResize: boolean;

  @Output() blur: EventEmitter<Event> = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges();
  }

  get textareaIsPristine(): boolean {
    if (!this.textarea) {
      return false;
    }

    return this.textarea.nativeElement.classList.contains('ng-pristine');
  }

  get textareaIsValid(): boolean {
    if (!this.textarea) {
      return false;
    }

    let valid: boolean = this.textarea.nativeElement.checkValidity();

    if (this.formGroup && this.formControlName) {
      valid = valid && this.formGroup.controls[this.formControlName].valid;
    }

    return valid;
  }

  get valueLength(): number {
    return this.value ? this.value.length : 0;
  }

  get error(): string {
    return this.errorMessage || `${this.placeholder} is required`;
  }

  // get accessor
  get value(): string {
    return this._value;
  }

  // set accessor including call the onchange callback
  set value(v: string) {
    if (v !== this._value) {
      this._value = v;
      this.onChangeCallback(v);
    }
  }

  // set touched on blur
  onBlur(e: Event) {
    this.onTouchedCallback();
    this.blur.emit(e);
  }

  // from ControlValueAccessor interface
  writeValue(value: string) {
    if (value !== this._value) {
      this._value = value;
      this.changeDetectorRef.markForCheck();
    }
  }

  // from ControlValueAccessor interface
  registerOnChange(fn: (value: string) => void) {
    this.onChangeCallback = fn;
  }

  // from ControlValueAccessor interface
  registerOnTouched(fn: () => void) {
    this.onTouchedCallback = fn;
  }

  get showHelperText() {
    return this.helperText && (this.textareaIsPristine || (!this.textareaIsPristine && this.textareaIsValid));
  }
}
