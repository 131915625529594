<div class="side-menu custom-scrollbar" [class.collapsed]="isMobileScreen ? false : isSidebarCollapsed">
  <header>
    <div class="logo shine shiny">
      <img [src]="logoUrl" alt="logo" />
    </div>
    <div class="user">
      <p>{{ currentAccount.title }}</p>
    </div>
  </header>
  <main>
    <nav class="main-menu">
      @for (navGroup of navigationModel.navigationGroups; track navGroup.title) {
        <section>
          <div *ngIf="navGroup.title" class="menu-header">{{ navGroup.title }}</div>
          <ul>
            @for (navItem of navGroup.items; track navItem.name) {
              <li class="menu-item">
                <a
                  [id]="navItem.idAttr"
                  [routerLink]="[navItem.url]"
                  routerLinkActive="active-item"
                  [target]="navItem.target"
                >
                  <span class="menu-icon">
                    <i class="material-symbols-outlined">{{ navItem.icon }}</i>
                  </span>
                  <span class="menu-title">{{ navItem.name }}</span>
                </a>
              </li>
            }
          </ul>
        </section>
      }
    </nav>
    <nav class="extra-menu">
      <section>
        <ul>
          <li class="menu-item" [class.user-avatar]="!isSidebarCollapsed">
            <a data-intercom-target="Profile and Settings"
              (click)="$event.stopPropagation()"
              ngDropdown
              [templateRef]="accountOptionsTemplate"
              [closeUponSelection]="true"
            >
              <app-employee-avatar [employee]="currentEmployee" [size]="avatarSize"></app-employee-avatar>
              <span class="menu-title">Profile</span>
            </a>
          </li>
          <li class="menu-item">
            <a data-intercom-target="Support Options"
              (click)="$event.stopPropagation()"
              ngDropdown
              [templateRef]="supportTemplate"
              [closeUponSelection]="true"
              [app-tooltip]="{ message: 'Momentus Support', position: ['right'] }"
            >
              <span class="menu-icon">
                <i class="material-symbols-outlined">help_outline</i>
              </span>
              <span class="menu-title">{{ T.common.help | translate }}</span>
            </a>
          </li>
        </ul>
      </section>
    </nav>
  </main>
  <footer>
    <div class="footer-content">
      <div class="logo-container shine">
        <img class="wetrack-logo" [src]="brandingUrl" alt="" />
      </div>
    </div>
  </footer>
</div>>

<ng-template #accountOptionsTemplate>
  <div class="more-info-popup-container">
    <div class="more-info-popup-header">{{ currentEmployee.firstName }} {{ currentEmployee.surname }}</div>
    <div class="more-info-popup-body">
      <div
        class="more-info-popup-row"
        [routerLink]="'v2/settings/preferences'"
        (click)="collapseSidebarOnMenuItemSelect()"
      >
        <i class="material-symbols-outlined">account_circle</i>
        <span>{{ T.settings.my_preferences.my_preferences_text | translate }}</span>
      </div>
      <div class="more-info-popup-row" (click)="logout()">
        <i class="material-symbols-outlined">exit_to_app</i> <span>{{ T.settings.logout | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #supportTemplate>
  <div class="more-info-popup-container">
    <div class="more-info-popup-header">Momentus {{ T.support.support | translate }}</div>
    <div class="more-info-popup-body">
      <a href="https://supportcenter.ungerboeck.com/#wetrack" class="more-info-popup-row" target="_blank" rel="noopener">
        <i class="material-symbols-outlined">help_outline</i>
        <div>{{ T.support.help_articles | translate }}</div>
      </a>
      <a href="mailto:wetracksupport@gomomentus.com" class="more-info-popup-row">
        <i class="material-symbols-outlined">email</i>
        <div>{{ T.support.email_our_team | translate }}</div>
      </a>

    </div>
  </div>
</ng-template>
