import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EmployeeAvatarSizes } from '../../../enums/employeeAvatarSizes';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { Constants } from '../../../models/constants';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { CommentsService } from '../../../services/comments.service';
import { EqualityUtilities } from '../../../utilities/equality.utilities';
import { EmployeeViewModel } from '../../../viewModels/employeeViewModel';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-avatar-filter-selector',
  templateUrl: './avatar-filter-selector.component.html',
  styleUrls: ['./avatar-filter-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarFilterSelectorComponent implements OnInit, OnChanges {
  @Input() filterType: FilterTypes;
  @Input() filters: FilterViewModel[];
  @Input() canEdit: boolean;
  @Input() singleSelect: boolean = false;
  @Input() required: boolean = false;
  @Input() maxVisible: number = 2;
  @Input() size: 'large' | 'medium' | 'small' = 'large';
  @Input() showEmptyAddBtn: boolean = false;
  @Input() showAddButton: boolean = false;
  @Output() filtersUpdated: EventEmitter<FilterViewModel[]> = new EventEmitter<FilterViewModel[]>();

  public displayData: boolean = false;
  public ownerFiltersSize = 0;

  public currentEmployees: EmployeeViewModel[] = [];
  public filterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
  public allowedFilters: FilterViewModel[];
  public customFilters: FilterViewModel[] = [];
  /**
   * Current employees sliced to maxVisible
   */
  public limitedEmployeesByMaxVisible: EmployeeViewModel[];
  public limitedCustomFiltersByMaxVisible: FilterViewModel[];
  public overflowEmployeesCount: number;
  public readonly T = T;

  private availableEmployees: EmployeeViewModel[];

  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly commentsService: CommentsService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.ownerFiltersSize = this.filters.filter((f) => f.filterType === this.filterType).length;
    this.allowedFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);
    if (this.isEmployeeFilterType) {
      this.initAvailableEmployees();
    } else {
      this.getCustomFilters();
    }

    this.filterTypeSelectorViewModel.filterType = this.filterType;
    this.filterTypeSelectorViewModel.filterSelectorType = FilterSelectorTypes.Dropdown;
  }

  public get isEmployeeFilterType() {
    return (
      this.filterType === FilterTypes.Employee ||
      this.filterType === FilterTypes.Subscriber ||
      this.filterType === FilterTypes.Owner
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filters.isFirstChange() && this.availableEmployees && this.isEmployeeFilterType) {
      this.getCurrentEmployees();
    } else if (!changes.filters.isFirstChange()) {
      this.getCustomFilters();
    }
  }

  get isMobile(): boolean {
    return window.innerWidth <= Constants.xs;
  }

  public updateFilters(filters: FilterViewModel[]) {
    const existingFilters = this.filters.filter((f) => f.filterType === this.filterType);

    if (filters.length > 1) {
      filters = filters.filter((e) => e.filterValue.toString() !== '0');
    }

    if (!EqualityUtilities.arraysEqual(existingFilters, filters)) {
      const otherFilters = this.filters.filter((f) => f.filterType !== this.filterType);

      if (filters && filters.length) {
        otherFilters.push(...filters);
      }

      this.filtersUpdated.next(otherFilters);
    }

    this.changeDetectorRef.markForCheck();
  }

  private initAvailableEmployees(): void {
    this.commentsService.getAllEmployeesByAccountId().subscribe((employees) => {
      this.availableEmployees = employees;
      this.getCurrentEmployees();
    });
  }

  private getCurrentEmployees(): void {
    const employeeIds = this.filters.filter((f) => f.filterType === this.filterType).map((f) => +f.filterValue);
    this.currentEmployees = this.availableEmployees.filter((e) => employeeIds.some((id) => id === e.id));
    this.limitedEmployeesByMaxVisible = this.currentEmployees.slice(0, this.maxVisible);
    this.overflowEmployeesCount = this.currentEmployees.slice(this.maxVisible).length;
    this.displayData = true;
    this.changeDetectorRef.markForCheck();
  }

  private getCustomFilters(): void {
    const filterValues = this.filters.filter((f) => f.filterType === this.filterType).map((f) => +f.filterValue);

    if (filterValues && filterValues.length) {
      this.customFilters = this.allowedFiltersService
        .getCachedAllowedFiltersByType(this.filterType)
        .filter(filter => filterValues.includes(filter.filterValue));

      this.limitedCustomFiltersByMaxVisible = this.customFilters.slice(0, this.maxVisible);
      this.overflowEmployeesCount = this.customFilters.slice(this.maxVisible).length;
    } else {
      this.limitedCustomFiltersByMaxVisible = [];
      this.overflowEmployeesCount = 0;
      this.customFilters = [];
    }

    this.displayData = true;
    this.changeDetectorRef.markForCheck();
  }

  public getEnumSize(): EmployeeAvatarSizes {
    if (this.size === 'large') {
      return EmployeeAvatarSizes.Large;
    } else if (this.size === 'medium') {
      return EmployeeAvatarSizes.Medium;
    } else {
      return EmployeeAvatarSizes.Small;
    }
  }
}
