import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { PopupEventsEmitter } from 'src/app/modules/shared/events/popup.events';
import { IncidentsManager } from 'src/app/modules/shared/managers/incidents.manager';
import { Constants } from 'src/app/modules/shared/models/constants';
import { SeverityViewModel } from 'src/app/modules/shared/models/severityViewModel';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-severity-selector',
  templateUrl: 'severity-selector.component.html',
  styleUrls: ['./severity-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeveritySelectorComponent implements OnInit, OnChanges, AfterViewInit {
  readonly opacity: string = '1f';
  readonly defaultCheckpointStateIndicatorLeftShifting: string = '16px';
  readonly activeCheckpointStateIndicatorLeftShifting: string = '20px';

  @ViewChildren('checkpointStateIndicator') checkpointStateIndicators: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren('checkPoint') checkpoints: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren('separator') separatorElements: QueryList<ElementRef<HTMLElement>>;

  @Input() currentlySelectedSeverityCheckpoint: SeverityViewModel;
  @Input() showScaleGuideSelectSeverityText: boolean = true;
  @Output() checkpointSelected: EventEmitter<SeverityViewModel> = new EventEmitter();
  @Output() scaleGuideToggled: EventEmitter<boolean> = new EventEmitter();

  checkpointStateIndicatorsArray: HTMLElement[];
  checkpointsArray: HTMLElement[];
  separators: boolean[] = [];
  checkpointStateIndicatorLeftShiftingByIndex: { [key: number]: { shifting: string } } = {};
  lastlySelectedSeverity: SeverityViewModel;
  severities: SeverityViewModel[];
  viewInitialized: boolean = false;
  scaleGuideShown: boolean = false;
  isMobile: boolean = false;
  public readonly T = T;
  private readonly mobileWidth = Constants.xs;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly popupEventsEmitter: PopupEventsEmitter,
    private readonly incidentsManager: IncidentsManager,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.severities = this.incidentsManager.severities;
    this.checkIsMobile();
    // if the severityCheckpoints are N, the separators are N - 1
    for (let checkpoint = 0; checkpoint < this.severities.length - 1; checkpoint++) {
      this.separators.push(true);
    }

    this.severities.forEach((c, i) => {
      this.checkpointStateIndicatorLeftShiftingByIndex[i] = {
        shifting: this.defaultCheckpointStateIndicatorLeftShifting,
      };

      return c;
    });
  }

  ngOnChanges() {
    if (this.viewInitialized) {
      this.selectCurrentlySelectedSeverityCheckpoint();
    }
  }

  ngAfterViewInit() {
    this.checkpointStateIndicatorsArray = this.checkpointStateIndicators.toArray().map((i) => i.nativeElement as HTMLElement);

    this.checkpointsArray = this.checkpoints.toArray().map((i) => i.nativeElement as HTMLElement);

    this.viewInitialized = true;
    this.selectCurrentlySelectedSeverityCheckpoint();
    this.initGradients();
  }

  selectCurrentlySelectedSeverityCheckpoint() {
    if (!this.currentlySelectedSeverityCheckpoint) {
      this.currentlySelectedSeverityCheckpoint = this.incidentsManager.getSeverityByValue(1);
    }

    const { color, severity } = this.currentlySelectedSeverityCheckpoint;

    if (this.lastlySelectedSeverity) {
      if (this.lastlySelectedSeverity.color === color && this.lastlySelectedSeverity.severity === severity) {
        return;
      }
    }

    let correspondingSeverityCheckpoint: SeverityViewModel = null;
    let indexOfTheCorrespondingSeverityCheckpoint: number = 0;

    for (let i = 0; i < this.severities.length; i++) {
      const checkpoint = this.severities[i];

      if (
        checkpoint.color === this.currentlySelectedSeverityCheckpoint.color &&
        checkpoint.severity == this.currentlySelectedSeverityCheckpoint.severity
      ) {
        correspondingSeverityCheckpoint = checkpoint;
        indexOfTheCorrespondingSeverityCheckpoint = i;

        break;
      }
    }

    if (correspondingSeverityCheckpoint) {
      this.onCheckpointClick(indexOfTheCorrespondingSeverityCheckpoint, correspondingSeverityCheckpoint);
    }
  }

  getProperColor(color: string, includeOpacity: boolean = true): string {
    const hexRegex: RegExp = /[0-9a-f]{6}/i;

    if (hexRegex.test(color)) {
      return includeOpacity ? this.getProberRGBColor(`${color}${this.opacity}`) : color;
    }

    return color;
  }

  getProberRGBColor(hex: string): string {
    let r, g, b, a;
    hex = hex.replace('#', '');
    if (3 === hex.length) {
      r = hex.charAt(0);
      g = hex.charAt(1);
      b = hex.charAt(2);
    } else if (4 === hex.length) {
      r = hex.charAt(0);
      g = hex.charAt(1);
      b = hex.charAt(2);
      a = hex.charAt(3);
    } else if (6 === hex.length) {
      r = hex.substring(0, 2);
      g = hex.substring(2, 4);
      b = hex.substring(4, 6);
    } else if (8 === hex.length) {
      r = hex.substring(0, 2);
      g = hex.substring(2, 4);
      b = hex.substring(4, 6);
      a = hex.substring(6, 8);
    } else {
      return '';
    }
    if ('undefined' === typeof a) {
      a = 'ff';
    }
    if (1 === r.length) {
      r += r;
    }
    if (1 === g.length) {
      g += g;
    }
    if (1 === b.length) {
      b += b;
    }
    if (1 === a.length) {
      a += a;
    }
    r = parseInt(r, 16);
    g = parseInt(g, 16);
    b = parseInt(b, 16);
    a = parseInt(a, 16) / 255;

    return 'rgba(' + r + ',' + g + ',' + b + ',' + a.toFixed(2) + ')';
  }

  onShowHideScaleGuideClick(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.scaleGuideShown = !this.scaleGuideShown;

    this.changeDetectorRef.detectChanges();

    this.popupEventsEmitter.broadcastPopupDimensionsChanged();
    this.scaleGuideToggled.next(this.scaleGuideShown);
  }

  onCheckpointHover(index: number) {
    this.checkpointStateIndicatorsArray[index].classList.toggle('hover-show');
  }

  onCheckpointClick(index: number, selectedCheckpoint: SeverityViewModel) {
    if (this.checkpointStateIndicatorLeftShiftingByIndex[index].shifting === this.defaultCheckpointStateIndicatorLeftShifting) {
      if (!index) {
        this.checkpointStateIndicatorLeftShiftingByIndex[index].shifting = '16px';
      } else if (index === this.severities.length - 1) {
        this.checkpointStateIndicatorLeftShiftingByIndex[index].shifting = '24px';
      } else {
        this.checkpointStateIndicatorLeftShiftingByIndex[index].shifting = this.activeCheckpointStateIndicatorLeftShifting;
      }
    } else {
      this.checkpointStateIndicatorLeftShiftingByIndex[index].shifting = this.defaultCheckpointStateIndicatorLeftShifting;
    }

    this.checkpointStateIndicatorsArray.forEach((checkpointStateIndicator, i) => {
      if (i !== index) {
        this.checkpointStateIndicatorLeftShiftingByIndex[i].shifting = this.defaultCheckpointStateIndicatorLeftShifting;
      }
    });

    this.toggleCheckpoint(index);
    this.changeDetectorRef.detectChanges();
    this.checkpointSelected.emit((this.lastlySelectedSeverity = selectedCheckpoint));
  }

  private initGradients() {
    this.separatorElements
      .toArray()
      .map((e) => e.nativeElement)
      .forEach((e, i) => {
        this.renderer.setStyle(
          e,
          'background-image',
          `linear-gradient(to right, ${this.getProperColor(this.severities[i].color)},
        ${this.getProperColor(this.severities[i + 1].color)})`
        );
      });
  }

  checkIsMobile(): void {
    window.innerWidth <= this.mobileWidth ? (this.isMobile = true) : (this.isMobile = false);
  }

  // Toggles the currently selected checkpoint (pin) and hides the previously selected one
  private toggleCheckpoint(index: number): void {
    this.checkpointStateIndicatorsArray.forEach((checkpointIndicator) => checkpointIndicator.classList.remove('click-show'));
    this.checkpointStateIndicatorsArray[index].classList.toggle('click-show');

    this.checkpointsArray.forEach((checkpoint) => checkpoint.classList.remove('click-show'));
    this.checkpointsArray[index].classList.toggle('click-show');
  }
}
