import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { TextFieldComponent } from '../../common/text-field/text-field.component';

@Component({
  selector: 'app-filters-search-input',
  templateUrl: './filters-search-input.component.html',
  styleUrls: ['./filters-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersSearchInputComponent {
  @ViewChild(TextFieldComponent) textFieldComponent: TextFieldComponent;

  @Input() filterTitle: string;

  @Output() search = new EventEmitter<string>();

  searchValue = '';

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private readonly translateService: TranslateService) {}

  get searchPlaceholder(): string {
    return this.filterTitle
      ? this.translateService.instant(T.common.search_item, { item: this.filterTitle })
      : this.translateService.instant(T.common.search);
  }

  async inputIsFocused() {
    if (!this.textFieldComponent) {
      return;
    }
    const inputField = await this.textFieldComponent?.getInputElement();
    return inputField == document.activeElement;
  }

  onClick(): void {
    this.changeDetectorRef.detectChanges();
  }

  onSearch(): void {
    this.search.emit(this.searchValue);
  }

  onClear($event: MouseEvent): void {
    this.searchValue = null;
    this.search.emit(this.searchValue);
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    $event.preventDefault();
  }
}
