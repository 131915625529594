export enum RelevanceNames {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export enum RelevanceScores {
  Low = 33,
  Medium = 66,
  High = 100
}
