import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[resizable]',
})
export class ResizableDirective implements OnInit {
  private readonly _debounceTime = 200;

  // eslint-disable-next-line @angular-eslint/no-output-rename, @typescript-eslint/no-unsafe-assignment
  @Output('resizable') resizable: EventEmitter<void> = new EventEmitter();

  resizeSubject: Subject<void> = new Subject();
  subscriptions: Subscription[] = [];

  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  ngOnInit() {
    this.subscriptions.push(
      this.resizeSubject.pipe(debounceTime(this._debounceTime)).subscribe(() => {
        this.resizable.emit();
      })
    );
  }

  @HostListener('resized')
  onResized() {
    this.resizeSubject.next();
  }
}
