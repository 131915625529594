import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { map, Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { AuthService } from '../../auth/auth.service';
import { BaseConfigurationViewModel } from '../viewModels/baseConfigurationViewModel';
import { AuthenticationService } from './authentication.service';
import { CachingService } from './caching.service';

@Injectable()
export class ConfigurationService {
  private actionUrl: string;
  private currentUrl: URL;
  isBrowser: boolean;
  cachingService: any;

  constructor(
    private configuration: Configuration,
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private http: HttpClient,
    private caches: CachingService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const href: string = isPlatformBrowser(this.platformId) ? window.location.href : 'n/a';
    this.currentUrl = new URL(href);
    this.actionUrl = this.currentUrl.origin + '/v1/';

    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  public Validate(): boolean {
    const storedData = this.caches.GetAccountConfiguration();

    if (!storedData) {
      return false;
    }

    const storedUrl = new URL(storedData.url);
    const storedAlphaUrl = new URL(storedData.urlAlpha);
    const currentUrl = new URL(this.actionUrl);

    if (storedUrl.host != currentUrl.host && currentUrl.host != storedAlphaUrl.host) {
      return false;
    }

    return true;
  }

  public GetBaseConfiguration = (): Observable<BaseConfigurationViewModel> => {
    const bConfigRequest = new BaseConfigurationViewModel();
    const cachedAccount = this.caches.getCurrentAccount();

    // Mobile Or Development Flow
    if (Capacitor.getPlatform() !== 'web') {
      //|| this.configuration.configuration == 'development'

      const claims = this.authService.getIdentityClaims();

      if (!cachedAccount && !claims) {

        console.log('## Error: no Account or Claim found');
        //reload the app
        window.location.reload();
        //throw new Error('no Account or Claim found');
      }

      if (cachedAccount && cachedAccount.accountID) {
        bConfigRequest.url = cachedAccount.url;
      } else {
        if (claims) {
          if (claims.accountid && claims.accountid != '') {
            bConfigRequest.accountId = claims.accountid;
          }
        }
      }
    } else {
      bConfigRequest.url = this.currentUrl.host;
    }

    const body = JSON.stringify(bConfigRequest);
    const url = this.configuration.buildEndpoint(`configuration/base`);
    const options = this.authenticationService.getHttpOptions();

    return this.http.post<BaseConfigurationViewModel>(url, body, options).pipe(
      map((data) => {
        if (data && data.accountId > 0) {
          this.caches.AddAccountConfiguration(data);
        }

        return data;
      })
    );
  };
}
