<ng-container *ngIf="loading">
  <app-loading *ngIf="loading">{{ T.common.loading | translate }}...</app-loading>
</ng-container>
<ng-container *ngIf="!loading">
  <div #wrapper [class.employee-notifications-mobile]="mobile" resized (resizable)="onResize()">
    <div *ngIf="usePlanning" class="row content-wrapper notification-headings">
      <div [class.col-3]="!mobile"></div>
      <div [class.col-9]="!mobile">
        <div class="radio-filters-wrapper">
          <div class="radio-wrapper" *ngFor="let intervalType of summaryNotificationFrequencyTypes">
            <span>
              {{ getFrequencyTypeDescription(intervalType) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="usePlanning" class="row content-wrapper">
      <div [class.col-3]="!mobile">
        <span class="radio-filter-heading">{{
          T.settings.my_preferences.notifications.send_my_personal_summary_email | translate
        }}</span>
      </div>
      <div [class.col-9]="!mobile">
        <div class="radio-filters-wrapper">
          <div class="radio-wrapper" *ngFor="let intervalType of summaryNotificationFrequencyTypes">
            <div
              class="radio-button-wrapper"
              (click)="onIntervalCheckedChanged(intervalType, employeeSettingTypes.Notification_Summary_Changes)"
            >
              <input
                class="radio-button"
                [name]="intervalType.value"
                type="radio"
                [checked]="isSelectedSetting(intervalType, employeeSettingTypes.Notification_Summary_Changes)"
              />
              <div class="radio-circle"></div>
              <div
                class="radio-check"
                [class.visible]="isSelectedSetting(intervalType, employeeSettingTypes.Notification_Summary_Changes)"
              ></div>
            </div>
            <span class="notification-headings-inner">
              {{ getFrequencyTypeDescription(intervalType) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row content-wrapper notification-headings">
      <div [class.col-3]="!mobile"></div>
      <div [class.col-9]="!mobile">
        <div class="radio-filters-wrapper">
          <div class="radio-wrapper" *ngFor="let intervalType of notificationFrequencyTypes">
            <span>
              {{ getFrequencyTypeDescription(intervalType) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="usePlanning" class="row content-wrapper">
      <div [class.col-3]="!mobile">
        <span class="radio-filter-heading">{{
          T.settings.my_preferences.notifications.notify_me_about_planning_module_updates | translate
        }}</span>
      </div>
      <div [class.col-9]="!mobile">
        <div class="radio-filters-wrapper">
          <div class="radio-wrapper" *ngFor="let intervalType of notificationFrequencyTypes">
            <div
              class="radio-button-wrapper"
              (click)="onIntervalCheckedChanged(intervalType, employeeSettingTypes.Notification_Planning_Changes)"
            >
              <input
                class="radio-button"
                [name]="intervalType.value"
                type="radio"
                [checked]="isSelectedSetting(intervalType, employeeSettingTypes.Notification_Planning_Changes)"
              />
              <div class="radio-circle"></div>
              <div
                class="radio-check"
                [class.visible]="isSelectedSetting(intervalType, employeeSettingTypes.Notification_Planning_Changes)"
              ></div>
            </div>
            <span class="notification-headings-inner">
              {{ getFrequencyTypeDescription(intervalType) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="useControlModule" class="row content-wrapper">
      <div [class.col-3]="!mobile">
        <span class="radio-filter-heading">{{
          T.settings.my_preferences.notifications.notify_me_about_control_module_updates | translate
        }}</span>
      </div>
      <div [class.col-9]="!mobile">
        <div class="radio-filters-wrapper">
          <div class="radio-wrapper" *ngFor="let intervalType of notificationFrequencyTypes">
            <div
              class="radio-button-wrapper"
              (click)="onIntervalCheckedChanged(intervalType, employeeSettingTypes.Notification_Incident_Changes)"
            >
              <input
                class="radio-button"
                [name]="intervalType.value"
                type="radio"
                [checked]="isSelectedSetting(intervalType, employeeSettingTypes.Notification_Incident_Changes)"
              />
              <div class="radio-circle"></div>
              <div
                class="radio-check"
                [class.visible]="isSelectedSetting(intervalType, employeeSettingTypes.Notification_Incident_Changes)"
              ></div>
            </div>
            <span class="notification-headings-inner">
              {{ getFrequencyTypeDescription(intervalType) }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="useControlModule" class="row content-wrapper">
      <div [class.col-3]="!mobile">
        <span class="radio-filter-heading">{{
          T.settings.my_preferences.notifications.notify_me_via_sms | translate
        }}</span>
      </div>
      <div [class.col-9]="!mobile" *ngIf="smsControlSetting">
        <div class="radio-filters-wrapper">
          <div class="radio-wrapper" *ngFor="let intervalType of notificationBooleanTypes; let first = first; let last = last;" [class.order-1]="first" [class.order-3]="last">
              <div class="radio-button-wrapper" (click)="onBooleanSettingChanged(intervalType.key, employeeSettingTypes.Notifications_StandardMessage)">
                <input
                class="radio-button"
                [name]="intervalType.value"
                type="radio"
                [checked]="isSelectedBoolean(smsControlSetting.value, intervalType.key)"
              />
              <div class="radio-circle"></div>
              <div class="radio-check" [class.visible]="isSelectedBoolean(smsControlSetting.value, intervalType.key)"></div>
            </div>
            <span class="notification-headings-inner">
              {{ intervalType.value }}
            </span>
          </div>
          <ng-container *ngIf="!mobile">
            <div class="radio-wrapper radio-wrapper-sms-empty"></div>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="usePublicIncidentReports" class="row content-wrapper">
      <div [class.col-3]="!mobile">
        <span class="radio-filter-heading">{{
          T.settings.my_preferences.notifications.notify_me_public_incident_reports | translate
        }}</span>
      </div>
      <div [class.col-9]="!mobile" *ngIf="publicIncidentReportsNotificationsSetting">
        <div class="radio-filters-wrapper">
          <div class="radio-wrapper" *ngFor="let intervalType of notificationBooleanTypes; let first = first; let last = last;" [class.order-1]="first" [class.order-3]="last">
              <div class="radio-button-wrapper" (click)="onBooleanSettingChanged(intervalType.key, employeeSettingTypes.Notifications_Incoming_Public_Incident_Reports)">
                <input
                class="radio-button"
                [name]="intervalType.value"
                type="radio"
                [checked]="isSelectedBoolean(publicIncidentReportsNotificationsSetting.value, intervalType.key)"
              />
              <div class="radio-circle"></div>
              <div class="radio-check" [class.visible]="isSelectedBoolean(publicIncidentReportsNotificationsSetting.value, intervalType.key)"></div>
            </div>
            <span class="notification-headings-inner">
              {{ intervalType.value }}
            </span>
          </div>
          <ng-container *ngIf="!mobile">
            <div class="radio-wrapper radio-wrapper-sms-empty"></div>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container *ngIf="useSustainability">
      <div class="row content-wrapper notification-headings">
        <div [class.col-3]="!mobile"></div>
        <div [class.col-9]="!mobile">
          <div class="radio-filters-wrapper">
            <div class="radio-wrapper" *ngFor="let intervalType of sustainabilitySummaryNotificationFrequencyTypes">
              <span>
                {{ getFrequencyTypeDescription(intervalType) }}
              </span>
            </div>
            <div class="radio-wrapper"></div>
          </div>
        </div>
      </div>

      <div class="row content-wrapper">
        <div [class.col-3]="!mobile">
          <span class="radio-filter-heading">{{
            T.settings.my_preferences.notifications.send_my_personal_sustainability_summary_email | translate
          }}</span>
        </div>
        <div [class.col-9]="!mobile">
          <div class="radio-filters-wrapper">
            <div class="radio-wrapper" *ngFor="let intervalType of sustainabilitySummaryNotificationFrequencyTypes">
              <div
                class="radio-button-wrapper"
                (click)="onIntervalCheckedChanged(intervalType, employeeSettingTypes.Notification_Sustainability_Summary)"
              >
                <input
                  class="radio-button"
                  [name]="intervalType.value"
                  type="radio"
                  [checked]="isSelectedSetting(intervalType, employeeSettingTypes.Notification_Sustainability_Summary)"
                />
                <div class="radio-circle"></div>
                <div
                  class="radio-check"
                  [class.visible]="
                    isSelectedSetting(intervalType, employeeSettingTypes.Notification_Sustainability_Summary)
                  "
                ></div>
              </div>
              <span class="notification-headings-inner">
                {{ getFrequencyTypeDescription(intervalType) }}
              </span>
            </div>
            <div class="radio-wrapper"></div>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="venueManagerSetting && false">
      <div class="row content-wrapper notification-headings">
        <div [class.col-3]="!mobile"></div>
        <div [class.col-9]="!mobile">
          <div class="radio-filters-wrapper">
            <div class="radio-wrapper" *ngFor="let intervalType of notificationBooleanTypes">
              <span>
                {{ intervalType.value }}
              </span>
            </div>
            <div class="radio-wrapper"></div>
            <div class="radio-wrapper"></div>
          </div>
        </div>
      </div>

      <div class="row content-wrapper">
        <div [class.col-3]="!mobile">
          <span class="radio-filter-heading">
            For {{ localiseString('Projects', false) }} where I am {{ localiseString('Project', false) }} Manager, notify
            me of all changes to {{ localiseString('tasks', false) }} and sub- {{ localiseString('tasks', false) }}
          </span>
        </div>
        <div [class.col-9]="!mobile">
          <div class="radio-filters-wrapper">
            <div class="radio-wrapper" *ngFor="let intervalType of notificationBooleanTypes">
              <div class="radio-button-wrapper" (click)="onBooleanSettingChanged(intervalType.key, employeeSettingTypes.Notification_Tasks_In_Venue)">
                <input
                  class="radio-button"
                  [name]="intervalType.value"
                  type="radio"
                  [checked]="isSelectedBoolean(venueManagerSetting.value ,intervalType.key)"
                />
                <div class="radio-circle"></div>
                <div class="radio-check" [class.visible]="isSelectedBoolean(venueManagerSetting.value, intervalType.key)"></div>
              </div>
              <span class="notification-headings-inner">
                {{ intervalType.value }}
              </span>
            </div>
            <div class="radio-wrapper"></div>
            <div class="radio-wrapper"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
