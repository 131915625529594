import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccordionService {
  private readonly _toggleSubject = new Subject<{ id: string; state: boolean }>();
  newGroupAdded = new Subject<number | string>();
  private readonly _collapseAllSubject = new Subject<string>();
  toggleStageChanged = this._toggleSubject.asObservable();
  collapseAllGroups = this._collapseAllSubject.asObservable();

  changeToggleStage(id: string, state: boolean) {
    this._toggleSubject.next({ id: id, state: state });
  }

  collapseAllExcept(id: string) {
    this._collapseAllSubject.next(id);
  }
  addNewGroup(id: number | string) {
    this.newGroupAdded.next(id);
  }
}
