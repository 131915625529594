<button type="button" [ngClass]="buttonClasses" [disabled]="disabled" (click)="onClick($event)">
  <ng-container *ngIf="!useContent">
    <div *ngIf="icon" class="responsive-flex-wrapper">
      <i class="material-symbols-outlined" [ngClass]="iconClasses" [class.m-0]="!text">{{ icon }}</i>
    </div>
    <div *ngIf="text" class="button-text">{{ text }}</div>
  </ng-container>
  <ng-container *ngIf="useContent">
    <ng-content></ng-content>
  </ng-container>
</button>
