export class DashboardWidgetViewModel {
  public containerTagName: string;
  public layout: DashboardWidgetLayoutViewModel;
}

export class DashboardWidgetLayoutViewModel {
  public cols: number;
  public rows: number;
  public y: number;
  public x: number;
  public maxItemCols: number;
  public minItemCols: number;
  public maxItemRows: number;
  public minItemRows: number;
  public dragEnabled: boolean;
  public resizeEnabled: boolean;
}
