import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  HostListener,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { CommentsService } from '../../../services/comments.service';
import { UrlService } from '../../../services/url.service';
import { Employee } from '../../../models/employee';
import 'quill-mention';
import { QuillEditorComponent } from 'ngx-quill';
import { EmployeeViewModel } from '../../../viewModels/employeeViewModel';
import { MentionItem } from '../../../models/mentions/MentionItem';
import { Constants } from 'src/app/modules/shared/models/constants';
import { T } from 'src/assets/i18n/translation-keys';
@Component({
  selector: 'app-rich-text-editor-v2',
  templateUrl: './rich-text-editor-v2.component.html',
  styleUrls: ['./rich-text-editor-v2.component.scss'],
})
export class RichTextEditorV2Component implements OnInit, OnDestroy, OnChanges {
  @ViewChild(QuillEditorComponent, { static: false }) editor: QuillEditorComponent;

  @Input() placeholder = 'Write a response...';
  @Input() inputText = '';
  @Input() customMentions: MentionItem[];
  @Input() enableShortcutSave = true;
  @Input() useMentions = true;
  @Input() showProfileImage = true;
  @Input() disabled = false;
  @Input() showClear = true;
  @Input() showCancel = true;
  @Input() showUndoAndRedo = true;
  @Input() autoFocus = false;
  @Input() showCommentAlert = true;
  @Input() minLength = 3;
  @Input() enableCntrEnterSave = true;
  @Input() enableClickAwaySave = true;

  @Output() saved: EventEmitter<string> = new EventEmitter<string>();
  @Output() editorChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() textChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];
  private employeeModels: EmployeeViewModel[] = [];
  private employeeIdsToMention: number[] = [];
  private mentionedUserIds: number[] = [];
  private initialInputValue = '';

  public isMobile = false;
  public employee: Employee;
  public commentModel: string;
  public modules: any;
  public isFocused = false;
  public allowedFormats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    // 'image'
    // 'video'
    'mention',
    'list',
  ];
  public readonly T = T;

  constructor(
    private authenticationService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    private commentsService: CommentsService,
    private urlService: UrlService
  ) {}

  @HostListener('document:mousedown', ['$event'])
  clickout(event: MouseEvent) {
    const editorWrapper = this.editor?.editorElem?.parentElement;
    const clickedElement = event.target as HTMLElement;

    const clickedOutsideFlig = !editorWrapper?.contains(clickedElement);
    if (this.isFocused && clickedOutsideFlig) {
      if (this.commentModel !== this.initialInputValue) {
        this.save();
        return;
      }

      this.canceled.next();
    }
  }

  ngOnInit(): void {
    this.initialInputValue = this.inputText;
    this.commentModel = this.inputText;
    this.employee = this.authenticationService.getCurrentEmployee();

    if (this.useMentions) {
      this.getFeed();
    } else {
      this.loadConfiguration();
      this.changeDetectorRef.markForCheck();
    }

    this.checkIsMobile();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.inputText) {
      this.commentModel = this.inputText;
    }
  }


  get disableSaveButton() {
    if (this.commentModel) {
      return this.stripHtml(this.commentModel).trim().length < this.minLength;
    }

    return true;
  }

  public save() {
    this.saved.next(this.commentModel);
  }
  public saveOnCtrlEnter() {
    if (this.enableCntrEnterSave) {
      this.save();
    }
  }
  public saveOnClickAway() {
    if (this.enableClickAwaySave) {
      this.save();
    }
  }

  public onNgModelChange(text: string) {
    if (text && this.stripHtml(text).length > 0) {
      this.textChanged.next(text);
    } else {
      this.textChanged.next('');
    }
  }

  public getAvatarUrl(thumbnailUrl: string): string {
    return this.urlService.buildResourceUrl(thumbnailUrl);
  }

  public onEditorFocused(ev: any) {
    if (ev && ev.editor) {
      this.isFocused = true;

      ev.editor.container.classList.add('focus');
      ev.editor.container.nextElementSibling.classList.add('focus');
      const editor = this.editor.quillEditor.getModule('mention');

      const mentionContainer = editor.mentionContainer;
      mentionContainer.style.height = '300px';
      mentionContainer.style.overflowY = 'scroll';
    }
  }

  public onEditorBlur(ev: any) {
    if (ev && ev.editor) {
      this.isFocused = false;
      ev.editor.container.classList.remove('focus');
      ev.editor.container.nextElementSibling.classList.remove('focus');
    }
  }

  public changedEditor(ev: any) {
    if (!this.useMentions && ev && ev.text) {
      const value = ev.html;
      this.editorChanged.next(value);
    }
  }

  public cancel() {
    this.commentModel = undefined;
    this.changeDetectorRef.markForCheck();
    this.canceled.next();
  }

  public clear() {
    this.commentModel = undefined;
    this.changeDetectorRef.markForCheck();
  }

  public setFocus(editor: any) {
    if (this.autoFocus && editor) {
      editor.focus();
    }
  }

  public redo() {
    this.editor.quillEditor.history.redo();
  }

  public undo() {
    this.editor.quillEditor.history.undo();
  }

  private getFeed() {
    this.subscriptions.push(
      this.commentsService.getAllEmployeesByAccountId().subscribe((res) => {
        this.employeeModels = res;

        this.employeeModels.forEach((m) => {
          m.thumbnailURL = this.urlService.buildResourceUrl(m.thumbnailURL);
        });
        this.loadConfiguration();
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private loadConfiguration(): void {
    const modules = {
      mention: {
        onSelect: (item, insertItem) => {
          insertItem(item);
          this.mentionedUserIds.push(item.id);
        },
        mentionDenotationChars: ['@', '#'],
        source: (searchTerm: string, renderList, mentionChar) => {
          const values: {id: number, value: string, avatarHTML: string}[] = [];

          if (this.useMentions) {
            if (mentionChar === '@') {
              this.employeeModels.forEach((m) =>
                values.push({
                  id: m.id,
                  value: `${m.firstName} ${m.surname}`,
                  avatarHTML: m.thumbnailURL,
                })
              );
            } else if (mentionChar === '#' && this.customMentions) {
              this.customMentions.forEach((m) => {
                values.push({ id: m.id, value: `${m.salutation}`, avatarHTML: '' });
              });
            }

            if (searchTerm.length === 0) {
              renderList(values, searchTerm);
            } else {
              const matches = [];
              values.forEach((entry) => {
                if (entry.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1) {
                  matches.push(entry);
                }
              });
              renderList(matches, searchTerm);
            }
          }
        },
      },
    };

    this.modules = modules;
  }

  private checkIsMobile(): void {
    this.isMobile = window.innerWidth <= Constants.xs;
  }

  private stripHtml(text: string): string {
    const regex = /(<([^>]*)>)/gi;

    return text.replace(regex, '').trim();
  }
}
