<div class="filters-entries-information-left">
  <div class="match-result" *ngIf="matchedEntriesCount !== undefined">
    {{ matchedEntriesCountText }}
  </div>
  <ng-container *ngIf="(matchedEntriesCount === undefined && !selectedEntriesCount) || !entriesCount">{{
    entriesCountItemsText
  }}</ng-container>
  <div *ngIf="selectedEntriesCount && entriesCount">
    <ng-container *ngIf="matchedEntriesCount === undefined">
      {{ selectedEntriesCount }}/{{ entriesCount }} Selected
    </ng-container>
    <ng-container *ngIf="matchedEntriesCount !== undefined">
      &#40;{{ selectedEntriesCount }}/{{ entriesCount }} Selected&#41;
    </ng-container>
  </div>
</div>

<div
  *ngIf="isMultiSelect"
  class="filters-entries-information-right"
  (click)="areAllSelected ? onClearAll() : onSelectAll()"
>
  {{ areAllSelected ? clearAllLabel : selectAllLabel }}
</div>
