import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterTypes } from '../../../enums/filterTypes';

@Component({
  selector: 'app-filters-list-entry',
  templateUrl: './filters-list-entry.component.html',
  styleUrls: ['./filters-list-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersListEntryComponent {
  @Input() text: string;
  @Input() color: string;
  @Input() selected: boolean;
  @Input() mobile: boolean;
  @Input() useBorderBottom: boolean;
  @Input() hidden = false;
  @Input() required = false;
  @Input() focused: boolean;
  @Input() filterType: FilterTypes;
  @Input() filterValue: number | string;
  @Input() customIcon: string;

  @Output() clicked = new EventEmitter<void>();

  public filterTypes = FilterTypes;

  constructor(private readonly translateService: TranslateService) {}

  setText() {
    return this.text;
  }

  onClick(): void {
    this.clicked.emit();
  }
}
