import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { NgBlockScreenDirective } from '../../../directives/ngBlockScreen.directive';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { Constants } from 'src/app/modules/shared/models/constants';
import { Employee } from '../../../models/employee';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { FiltersAddComponent } from '../filters-add/filters-add.component';
import { FilterLozengeComponent } from '../filters-lozenge/filters-lozenge.component';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { LocalisationService } from '../../../services/localisation.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HistoryFiltersService } from '../../../services/history-filters.service';
import { HeaderFiltersTypeService } from '../../../services/header-filters-type.service';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filters-simple',
  templateUrl: './filters-simple.component.html',
  styleUrls: ['./filters-simple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersSimpleComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren('desktopFilterLozenge') desktopFilterLozenges: QueryList<FilterLozengeComponent>;
  @ViewChildren('hiddenDesktopFilterLozenge') hiddenDesktopFilterLozenges: QueryList<FilterLozengeComponent>;
  @ViewChildren('desktopTagFilterLozenge') desktopTagFilterLozenges: QueryList<FilterLozengeComponent>;
  @ViewChildren('mobileFilterLozenge') mobileFilterLozenges: QueryList<FilterLozengeComponent>;
  @ViewChild(NgBlockScreenDirective) blockScreenDirective: NgBlockScreenDirective;

  @Input() objectTypes: ObjectTypes[] = [];
  @Input() appliedFilters: FilterViewModel[] = [];
  @Input() hideAddFilterButton: boolean = false;
  @Input() showBackdrop: boolean = true;
  @Input() isReporting: boolean = false;
  @Input() fixedReportTemplateFilterObjectTypes: ObjectTypes[] = [];
  @Input() isHubFilter: boolean = false;
  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();

  employee: Employee;
  filters: FilterViewModel[] = [];
  filterTypeSelectorViewModels: FilterTypeSelectorViewModel[] = [];
  filterTypeSelectorViewModel: FilterTypeSelectorViewModel;
  filterTypesEnum = FilterTypes;
  private readonly subscriptions = new Subscription();
  selectedTagGroup: FilterViewModel;
  selectedLozengeTagGroup: FilterViewModel;
  tagFilterTypeSelectorViewModel: FilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();

  expanded: boolean;
  mobile: boolean;

  desktopFiltersAddContainerVisible: boolean = false;
  mobileFiltersAddContainerVisible: boolean = false;
  filteredFilters = new Map<string, FilterViewModel[]>();
  hiddenFilterTypes = new Map<string, FilterViewModel[]>();
  filteredTagsFilters = new Map<string, FilterViewModel[]>();
  hiddenTagsFilterTypes = new Map<string, FilterViewModel[]>();

  public isFiltersLoading: boolean = true;
  public readonly T = T;

  private addFilterSelectionModalRef: BsModalRef<FiltersAddComponent>;
  private isOnInitExecuted = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly headerFiltersTypeService: HeaderFiltersTypeService,
    private readonly historyFiltersService: HistoryFiltersService,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService,
    private readonly bsModalService: BsModalService
  ) {}

  ngOnInit() {
    this.mobile = window.innerWidth <= Constants.xs;

    this.employee = this.authenticationService.getCurrentEmployee();
    this.tagFilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
    this.tagFilterTypeSelectorViewModel.filterType = FilterTypes.Tag;
    this.tagFilterTypeSelectorViewModel.filterTypeText = 'Tag';
    this.tagFilterTypeSelectorViewModel.filterSelectorType = FilterSelectorTypes.Dropdown;

    this.employee = this.authenticationService.getCurrentEmployee();

    this.isFiltersLoading = true;
    const filters = this.allowedFiltersService.getCachedAllAllowedFilters();

    forkJoin([this.initFilterTypes(), this.historyFiltersService.getHistoryFilters()]).subscribe(([filtType, historyFilters]) => {
      this.setFilterlist(filters);
      this.SetFilterTypesAndFilters(historyFilters, filtType);
    });
    this.isOnInitExecuted = true;
  }

  setFilterlist(filters: FilterViewModel[]) {
    this.filters = filters.filter(
      (filter) => !filter.displayForGlobalObjectType || this.objectTypes.indexOf(filter.displayForGlobalObjectType) !== -1
    );
  }

  ngOnChanges() {
    if (this.isOnInitExecuted) {
      this.isFiltersLoading = true;

      forkJoin([this.initFilterTypes(), this.historyFiltersService.getHistoryFilters()]).subscribe(
        ([filtType, historyFilters]) => {
          this.SetFilterTypesAndFilters(historyFilters, filtType);
        }
      );
    }
  }

  ngAfterViewInit() {
    this.initQueryListSubscriptions();
  }

  private SetFilterTypesAndFilters(historyFilters: FilterViewModel[], filtType: FilterTypeSelectorViewModel[]) {
    this.filters = this.filters.concat(historyFilters);

    this.filters = FilterUtilities.setFilterText(this.filters, this.localisationService, this.translateService);
    this.filterTypeSelectorViewModels = filtType;

    if (this.objectTypes.includes(ObjectTypes.IncidentItem)) {
      const statusVM = new FilterTypeSelectorViewModel();
      statusVM.displayForObjectType = ObjectTypes.IncidentItem;
      statusVM.filterType = FilterTypes.Incident_Status;
      statusVM.filterSelectorType = FilterSelectorTypes.Dropdown;
      statusVM.filterTypeText = 'Status';
      this.filterTypeSelectorViewModels.push(statusVM);
    }

    this.isFiltersLoading = false;
    this.filteredFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
    this.filteredTagsFilters = FilterUtilities.GroupTagFiltersByTagGroup(this.filters, this.appliedFilters);
    this.changeDetectorRef.detectChanges();
  }

  public get isHiddenEmpty(): boolean {
    return this.hiddenFilterTypes.size === 0;
  }

  /**
   * Gets filter count depending on whether it is for reporting and excluding any filters that are fixed report
   * template filters, which are hidden and don't need to be counted
   */
  public get filterCount(): number {
    return !this.isReporting
      ? this.appliedFilters.length
      : this.appliedFilters?.filter((f) => !this.isFixedAndHiddenReportTemplateFilter(f)).length;
  }

  onExpandCollapse(): void {
    this.expanded = !this.expanded;
  }

  onResized() {
    this.changeDetectorRef.detectChanges();
  }

  onAdd(): void {
    if (this.mobile) {
      this.mobileFiltersAddContainerVisible = !this.mobileFiltersAddContainerVisible;
    } else {
      this.openAddFilterSelectionModal();
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Checks if a filter should be hidden from the lozenge and filter count (if it is a report template fixed filter)
   */
  isFixedAndHiddenReportTemplateFilter(f: FilterViewModel) {
    return this.isReporting && this.fixedReportTemplateFilterObjectTypes?.some((x) => x === f.displayForGlobalObjectType);
  }

  openAddFilterSelectionModal() {
    this.addFilterSelectionModalRef = null;

    const initialState = {
      mobile: this.mobile,
      filters: this.filters,
      filterTypeSelectorViewModels: this.filterTypeSelectorViewModels,
      objectTypes: this.objectTypes,
      appliedFilters: this.appliedFilters
    };
    this.addFilterSelectionModalRef = this.bsModalService.show(FiltersAddComponent,  {
      initialState,
      class: 'max-width-unset center-transform',
    });

    this.addFilterSelectionModalRef.content.objectTypes = this.objectTypes;

    this.invokeAddModalSubcriptions();
  }

  invokeAddModalSubcriptions(): void {
    const addModalSubscription = new Subscription();
    addModalSubscription.add(
      this.addFilterSelectionModalRef.content.filtersChanged.subscribe((res) => {
        this.onFiltersChanged(res);
        this.addFilterSelectionModalRef.hide();
        addModalSubscription.unsubscribe();
      })
    );
    addModalSubscription.add(
      this.addFilterSelectionModalRef.content.filtersUpdated.subscribe((res) => {
        this.filtersUpdated.next(res);
        this.addFilterSelectionModalRef.hide();
        addModalSubscription.unsubscribe();
      })
    )
    addModalSubscription.add(
      this.addFilterSelectionModalRef.content.closed.subscribe(() => {
        this.addFilterSelectionModalRef.hide();
        addModalSubscription.unsubscribe();
      })
    );
  }

  onFiltersChanged(filters: FilterViewModel[]): void {
    if (filters[0]?.filterType === FilterTypes.Tag) {
      this.appliedFilters = this.appliedFilters.filter(
        (applFilter) =>
          !filters.some(
            (currFilter) =>
              applFilter.filterType === currFilter.filterType &&
              applFilter.relatedObjectId === currFilter.relatedObjectId &&
              applFilter.displayForGlobalObjectType === currFilter.displayForGlobalObjectType
          )
      );
    } else {
      this.appliedFilters = this.appliedFilters.filter(
        (a) =>
          filters.findIndex(
            (r) =>
              r.filterType === a.filterType &&
              (!r.relatedObjectId || r.relatedObjectId === a.relatedObjectId) &&
              r.dateProperty === a.dateProperty &&
              r.displayForGlobalObjectType === a.displayForGlobalObjectType
          ) < 0
      );
    }

    if (!(filters.length === 1 && filters[0].filterValue === -1)) {
      this.appliedFilters = this.appliedFilters.concat(filters);
    } else {
      if (filters[0].filterType !== FilterTypes.Date && filters[0].filterType !== FilterTypes.Tag) {
        this.appliedFilters = this.appliedFilters.filter((f) => f.filterType !== filters[0].filterType);
      }
    }

    this.filtersUpdated.next(this.appliedFilters);

    this.filteredFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
    this.filteredTagsFilters = FilterUtilities.GroupTagFiltersByTagGroup(this.filters, this.appliedFilters);

    this.changeDetectorRef.detectChanges();
  }

  onBlockScreenClicked(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  @HostListener('document:keydown.escape')
  onEscape() {
    this.desktopFiltersAddContainerVisible = false;
    this.filterTypeSelectorViewModel = null;
    this.selectedTagGroup = null;
    this.selectedLozengeTagGroup = null;

    this.desktopFilterLozenges.forEach((filterLozengeComponent) => (filterLozengeComponent.editMode = false));
    this.hiddenDesktopFilterLozenges.forEach((filterLozengeComponent) => (filterLozengeComponent.editMode = false));
    this.mobileFilterLozenges.forEach((filterLozengeComponent) => (filterLozengeComponent.editMode = false));
  }

  onClear(): void {
    this.appliedFilters = this.appliedFilters.filter((f) => this.isFixedAndHiddenReportTemplateFilter(f));
    this.expanded = false;
    this.onFiltersChanged(this.appliedFilters);
  }

  onTagLozengeClicked(filterLozengeComponent: FilterLozengeComponent, e: Event, tagGroup: FilterViewModel) {
    this.selectedLozengeTagGroup = tagGroup;
    this.onLozengeClicked(filterLozengeComponent, e);
  }

  onLozengeClicked(filterLozengeComponent: FilterLozengeComponent, e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    const { editMode } = filterLozengeComponent;

    filterLozengeComponent.editMode = !filterLozengeComponent.editMode;

    if (editMode) {
      document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    } else {
      this.updateBlockScreen(filterLozengeComponent.filterLozengeElementRef);
    }
  }

  filterTypesTrackByFn(index: number): number {
    return index;
  }

  updateBlockScreen(elementRef: ElementRef<HTMLElement>): void {
    if (this.showBackdrop) {
      this.blockScreenDirective.ngBlockScreenInstance.show();
      this.blockScreenDirective.blockScreenElementRef = elementRef;
      this.blockScreenDirective.updateInstance();
      this.blockScreenDirective.addElementRefStyles();
    }
  }

  initFilterTypes(): Observable<FilterTypeSelectorViewModel[]> {
    return this.isHubFilter
      ? this.headerFiltersTypeService.getHubFilterHeaderFilterTypes(this.objectTypes, this.isReporting)
      : this.headerFiltersTypeService.getFilterHeaderFilterTypes(this.objectTypes, this.isReporting);
  }

  private initQueryListSubscriptions(): void {
    this.subscriptions.add(
      this.desktopFilterLozenges.changes.subscribe(() => {
        this.hiddenFilterTypes = FilterUtilities.GroupHiddenFilters(this.desktopFilterLozenges);
        this.hiddenTagsFilterTypes = FilterUtilities.GroupHiddenTagFilters(this.desktopTagFilterLozenges);

        if (
          this.desktopFilterLozenges.length === 0 ||
          (this.hiddenFilterTypes.size === 0 && this.hiddenTagsFilterTypes.size === 0)
        ) {
          this.expanded = false;
        }
      })
    );
  }
}
