import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { PEAPGroupViewModel } from '../../models/peaps/peapGroup';
import { ValidatedViewModel } from '../../viewModels/validatedViewModel';

@Injectable({
  providedIn: 'root'
})
export class PEAPGroupService {
  private baseUrl: string = '';

  constructor(private readonly httpClient: HttpClient, private readonly configuration: Configuration) {
    this.baseUrl = this.configuration.buildEndpoint('PEAPGroup/');
  }

  add(viewModel: PEAPGroupViewModel): Observable<ValidatedViewModel> {
    return this.httpClient.post<ValidatedViewModel>(`${this.baseUrl}Add`, viewModel);
  }

  update(viewModel: PEAPGroupViewModel): Observable<PEAPGroupViewModel> {
    return this.httpClient.put<PEAPGroupViewModel>(`${this.baseUrl}Update`, viewModel);
  }

  delete(id: number): Observable<PEAPGroupViewModel> {
    return this.httpClient.delete<PEAPGroupViewModel>(`${this.baseUrl}Delete/${id}`);
  }

  getList(): Observable<PEAPGroupViewModel[]> {
    return this.httpClient.get<PEAPGroupViewModel[]>(`${this.baseUrl}GetList`);
  }
}
