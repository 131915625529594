import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { RunsheetViewModel } from '../models/runsheet.model';
import { ValidatedViewModel } from '../../shared/viewModels/validatedViewModel';
import { Observable } from 'rxjs';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';

@Injectable({
  providedIn: 'root',
})
export class RunsheetsService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`Runsheet/`);
  }

  create(runsheet: RunsheetViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}Add`, runsheet);
  }

  getList(filters: FilterViewModel[]): Observable<RunsheetViewModel[]> {
    return this.http.post<RunsheetViewModel[]>(`${this.actionUrl}List`, filters);
  }

  getDetails(runsheetId: number): Observable<RunsheetViewModel> {
    return this.http.get<RunsheetViewModel>(`${this.actionUrl}Details/${runsheetId}`);
  }

  update(runsheet: RunsheetViewModel) {
    return this.http.put<RunsheetViewModel>(`${this.actionUrl}Update`, runsheet);
  }

  archive(runsheetId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${runsheetId}/${archive}`, null);
  }

  delete(runsheetId): Observable<RunsheetViewModel> {
    return this.http.delete<RunsheetViewModel>(`${this.actionUrl}Delete/${runsheetId}`, {});
  }

  duplicate(runsheet: RunsheetViewModel): Observable<RunsheetViewModel> {
    return this.http.post<RunsheetViewModel>(`${this.actionUrl}Duplicate`, runsheet);
  }
}
