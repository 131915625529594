<div (click)="navigateToDetailsPage()" class="history-card-container">
  <div class="card-inner">
    <div class="card-top">
      <div class="card-top-left">
        <i class="material-symbols-outlined" *ngIf="isLogSubType">receipt</i>
        <!-- TODO - Create SVG icon for Logs? -->

        <app-details-icon
          *ngIf="!isLogSubType"
          [size]="24"
          [backgroundFill]="getObjectRAGStatusColor()"
          [type]="getCardIconType()"
          [ragStatus]="ragStatus"
        ></app-details-icon>

        <span class="title" [innerHTML]="title"></span>
      </div>
    </div>
  </div>
</div>
