import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-well',
  templateUrl: './well.component.html',
  styleUrls: ['./well.component.scss'],
})
export class WellComponent {
  @Input() height: string = 'auto';
}
