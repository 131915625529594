import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  OnChanges,
  SimpleChange,
  OnDestroy,
} from '@angular/core';
import { PulsingIncidentMarker } from 'src/app/modules/incidents/models/PulsingIncidentMarker';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { ZoneViewModel } from 'src/app/modules/settings/viewModels/zoneViewModel';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { GMapUtilities } from 'src/app/modules/shared/utilities/gMap.utilities';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { What3WordInfo } from 'src/app/modules/incidents/models/what3WordInfo';
import { Constants } from 'src/app/modules/shared/models/constants';
import { ObjectMarker } from 'src/app/modules/shared/models/map/markers/objectMarker';
import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { SearchFieldComponent } from '../controls/search-field/search-field.component';
import { BaseMap } from 'src/app/modules/shared/interfaces/map/baseMapInterface';
import { W3wGridButtonComponent } from '../controls/w3w-grid-button/w3w-grid-button.component';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { MapsLoaderService } from 'src/app/modules/shared/services/mapsLoader.service';
import { IncidentsSettingsService } from 'src/app/modules/shared/services/indicents-settings.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let InfoBox: any;

// This component is meant to be used across the app anywhere there is a need to be displayed a SINGLE location marker (pin).
// Up to this point while writing the documentation we have the following cases to display a location pin:
// Incendent, Log, Job, Task, Risk, Issue, Opportunity, Runsheet item, Evaluation
//
// This component has many input fields based on which the location marker is visualized and positioned, however
// it is very important to note about the !editable! input field based on which the functionality and behaviour
// of the component is based.
//
// In case of editable = true the user is allowed to drag and drop the marker into a new location and use some of the
// controls to automatically geolocate his own position or lookup for W3W coordinates.

@Component({
  selector: 'app-location-map-v2',
  templateUrl: './location-map-v2.component.html',
  styleUrls: ['./location-map-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationMapv2Component extends BaseMap implements OnInit, OnDestroy, OnChanges {
  @ViewChild('map', { static: true }) mapElement: ElementRef<HTMLElement>;
  @ViewChild('topLeftControlsWrapper') topLeftControlsWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('zoneAreaControlBtnWrapper') zoneAreaControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('w3wGridControlBtnWrapper') w3wGridControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('searchControl') searchControl: SearchFieldComponent;
  @ViewChild('myLocationControlBtnWrapper') myLocationControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild('noLocationControlBtnWrapper') noLocationControlBtnWrapper: ElementRef<HTMLDivElement>;
  @ViewChild(W3wGridButtonComponent) w3wGridControl: W3wGridButtonComponent;

  // Input properties
  @Input() lat: number;
  @Input() lng: number;
  @Input() editMode: boolean;
  @Input() defaultZoom = LocationMapv2Component.DEFAULT_ZOOM;
  @Input() markerColor: string;
  @Input() markerType: MarkerType = MarkerType.INCIDENT;
  @Input() showNoLocationButton = true;
  @Input() showMyLocationButton = true;
  @Input() showSearchButton = true;
  @Input() showW3WGridButton = true;
  @Input() zones: ZoneViewModel[] = [];
  @Input() areas: AreaViewModel[] = [];
  @Input() showIncidentZonesUponLoad = false;

  @Output() locationUpdated = new EventEmitter<LocationViewModel>();
  @Output() mapInitialized: EventEmitter<void> = new EventEmitter<void>();

  // Popup info box that displays the what3word label bellow the incident marker.
  private what3WordInfoBox: any = null;

  // The location marker
  private locationMarker: ObjectMarker | PulsingIncidentMarker = null;

  private userDraggedMarker = false;

  private editControlsWrapper: HTMLDivElement;
  private zoom: number;

  public wrapperIsSmall = false;
  public mapLoaded = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    protected geoLocationService: GeoLocationService,
    protected readonly localisationService: LocalisationService,
    protected readonly mapsLoaderService: MapsLoaderService,
    protected readonly incidentsSettingsService: IncidentsSettingsService
  ) {
    super(mapsLoaderService, geoLocationService, localisationService, incidentsSettingsService);
  }

  ngOnInit() {
    this.wrapperIsSmall = window.innerWidth < Constants.xs;
    this.zoom = this.defaultZoom;

    this.initMap();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    if (changes && this.mapLoaded) {
      if (changes.editMode) {
        const displayType = this.editMode ? 'flex' : 'none';
        this.editControlsWrapper.style.display = displayType;
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  get gmap(): google.maps.Map {
    return this.map;
  }

  get location(): google.maps.LatLng {
    if (this.lat && this.lng) {
      if (this.locationMarker) {
        return this.locationMarker.getPosition();
      } else {
        return new google.maps.LatLng(this.lat, this.lng);
      }
    }

    return null;
  }

  public onMyLocationAvailable($latLng: google.maps.LatLng): void {
    this.setMyLocation($latLng);
  }

  protected initMap(): void {
    super.initMap(this.mapElement, null);
    google.maps.event.addListenerOnce(this.map, 'idle', () => {
      if (this.lat && this.lng) {
        this.setMapLocation(new google.maps.LatLng(this.lat, this.lng), true);
      }

      if (this.editMode) {
        this.addClickListenerOnce();
      }

      this.mapLoaded = true;
      this.changeDetectorRef.detectChanges();
      this.mapInitialized.emit();

      this.initControlsOverlay();
    });
  }

  private initControlsOverlay() {
    this.showW3WGridButton = this.showW3WGridButton && this.markerType === MarkerType.INCIDENT;
    if (this.showW3WGridButton) {
      this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(this.w3wGridControlBtnWrapper.nativeElement);
    }

    if (this.editMode) {
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.topLeftControlsWrapper.nativeElement);
    }

    this.map.controls[google.maps.ControlPosition.RIGHT_TOP].push(this.zoneAreaControlBtnWrapper.nativeElement);
  }

  public onW3WGridClicked() {
    if (this.locationMarker && this.markerType === MarkerType.INCIDENT) {
      (this.locationMarker as PulsingIncidentMarker).toggleWhat3Polygon(this.w3wGridControl.w3wGridVisible);
    }
  }

  public onW3WGridToggled($gridVisible: boolean) {
    if (this.locationMarker && this.markerType === MarkerType.INCIDENT) {
      (this.locationMarker as PulsingIncidentMarker).toggleWhat3Polygon($gridVisible);
    }
  }

  public onSearchStateChanged(expanded: boolean): void {
    if (this.noLocationControlBtnWrapper) {
      this.noLocationControlBtnWrapper.nativeElement.style.display = expanded ? 'none' : 'block';
    }
    if (this.myLocationControlBtnWrapper) {
      this.myLocationControlBtnWrapper.nativeElement.style.display = expanded ? 'none' : 'block';
    }
  }

  public onAutocompleteResult(place: google.maps.places.PlaceResult): void {
    if (place) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.zoom = LocationMapv2Component.LOCATION_SEARCH_DEFAULT_ZOOM;
      this.setMapLocation(new google.maps.LatLng(lat, lng));

      //Removing the what3address infoBox
      if (this.markerType === MarkerType.INCIDENT && this.what3WordInfoBox) {
        this.what3WordInfoBox.setVisible(false);
        this.what3WordInfoBox = null;
      }

      // Emit the new Location
      const updatedLocation = new LocationViewModel(lat, lng, place.formatted_address);
      this.locationUpdated.emit(updatedLocation);
    }
  }

  public onWhat3WordsResult(what3WordInfo: What3WordInfo): void {
    this.setMapLocation(new google.maps.LatLng(what3WordInfo.lat, what3WordInfo.lng), true);
    this.zoom = LocationMapv2Component.W3W_SERCH_DEFAULT_ZOOM;

    if (this.markerType === MarkerType.INCIDENT && this.what3WordInfoBox) {
      this.what3WordInfoBox.setVisible(false);
      this.what3WordInfoBox = null;
    }

    this.locationMarker.setMap(null);
    this.initMarker(what3WordInfo);
    this.map.setMapTypeId('satellite');
  }

  public setNoLocation() {
    this.lat = 0;
    this.lng = 0;

    if (this.locationMarker !== null) {
      this.locationMarker.setMap(null);
      this.locationMarker = null;
    }

    if (this.markerType === MarkerType.INCIDENT && this.what3WordInfoBox) {
      this.what3WordInfoBox.setVisible(false);
      this.what3WordInfoBox = null;
    }

    this.onLocationUpdate(null);
    this.addClickListenerOnce();
  }

  public onZonesPolygonsCreated($event: Map<number, google.maps.Polygon>) {
    Array.from($event.values()).forEach((polygon) => {
      google.maps.event.addListener(polygon, 'click', (event: google.maps.PolyMouseEvent) => {
        if (this.locationMarker === null) {
          this.setMapLocation(event.latLng, false);
        }
      });
    });
  }

  public onAreasPolygonsCreated($event: Map<number, google.maps.Polygon>) {
    Array.from($event.values()).forEach((polygon) => {
      google.maps.event.addListener(polygon, 'click', (event: google.maps.PolyMouseEvent) => {
        if (this.locationMarker === null) {
          this.setMapLocation(event.latLng, false);
        }
      });
    });
  }

  private addClickListenerOnce() {
    google.maps.event.addListener(this.map, 'click', (ev: google.maps.MapMouseEvent) => {
      if (this.locationMarker === null) {
        this.setMapLocation(ev.latLng, false);
      }
    });
  }

  private setMyLocation(latLng: google.maps.LatLng): void {
    this.zoom = LocationMapv2Component.MY_LOCATION_DEFAULT_ZOOM;
    this.setMapLocation(latLng);

    if (this.markerType === MarkerType.INCIDENT && this.what3WordInfoBox) {
      this.what3WordInfoBox.setVisible(false);
      this.what3WordInfoBox = null;
    }

    this.changeDetectorRef.markForCheck();
  }

  private setMapLocation(latLng: google.maps.LatLng = null, center = true) {
    if (latLng) {
      this.lat = latLng.lat();
      this.lng = latLng.lng();

      if (this.locationMarker) {
        //this.locationMarker.position = latLng;
      } else {
        this.initMarker(null);
      }

      if (center) {
        this.map.panTo(latLng);
        this.map.setZoom(this.zoom);
      }

      this.userDraggedMarker = true;
      this.onLocationUpdate(latLng);
    } else {
      this.setNoLocation();
    }
  }

  private onLocationUpdate(latLng: google.maps.LatLng): void {
    if (latLng) {
      const lat = latLng.lat(),
        lng = latLng.lng();

      this.geoLocationService.getAddressFromCoordinates(lat, lng).subscribe((addressResults) => {
        const address = addressResults[0] ? addressResults[0].formatted_address : '';
        const updatedLocation = new LocationViewModel(lat, lng, address);
        this.locationUpdated.emit(updatedLocation);
      });
    } else {
      const updatedLocation = new LocationViewModel(0, 0, '');
      this.locationUpdated.emit(updatedLocation);
    }
  }

  public panToZone(zoneId: number, updateLocationIfPristine = true) {
    const zone = this.zones.find((z) => z.id === zoneId);
    if (zone && zone.zoneShapePoints.length > 0) {
      const polygon = GMapUtilities.buildPolygonFromZoneModel(zone.zoneShapePoints, null);
      const polyLatLngBounds = GMapUtilities.getPolygonLatLngBounds(polygon);
      this.map.fitBounds(polyLatLngBounds);

      if (updateLocationIfPristine && this.userDraggedMarker === false && this.editMode === true) {
        this.setMapLocation(polyLatLngBounds.getCenter());
      }
    }
  }

  public panToArea(areaId: number, updateLocationIfPristine: boolean) {
    const area = this.areas.find((a) => a.id === areaId);
    if (area) {
      if (area.areaShapePoints.length > 0) {
        const polygon = GMapUtilities.buildPolygonFromAreaModel(area.areaShapePoints, null);
        const polyLatLngBounds = GMapUtilities.getPolygonLatLngBounds(polygon);
        this.map.fitBounds(polyLatLngBounds);

        if (updateLocationIfPristine && this.userDraggedMarker === false && this.editMode === true) {
          this.setMapLocation(polyLatLngBounds.getCenter());
        }
      } else {
        const zone = this.zones.find((z) => z.id === area.zoneId);
        this.panToZone(zone.id, false);
      }
    }
  }

  private initMarker(what3WordInfo: What3WordInfo = null) {
    const boxTextValue = `<div class='what3words-info-tooltip'>
          <span class="tripple-incline">///</span>
          <span class="address">{?}</span>
      <div>`;

    switch (this.markerType) {
      case MarkerType.INCIDENT:
        this.locationMarker = new PulsingIncidentMarker(new google.maps.LatLng(this.lat, this.lng), this.markerColor, this.map);
        this.locationMarker.onPositionChangedByAPI(() => {
          const what3WordInfo = (this.locationMarker as PulsingIncidentMarker).getWhat3WordInfo();
          if (what3WordInfo) {
            this.geoLocationService.getWhat3WordsFromCoordinates(this.lat, this.lng).subscribe((response: any) => {
              const newWhat3WordInfo = this.getWhat3WordInfoFromResponse(response);
              what3WordInfo.polygon.setPaths(newWhat3WordInfo.polygon.getPaths());
              what3WordInfo.address = newWhat3WordInfo.address;

              if (this.what3WordInfoBox) {
                this.what3WordInfoBox.setVisible(true);
                this.what3WordInfoBox.setContent(boxTextValue.replace('{?}', newWhat3WordInfo.address));
                this.what3WordInfoBox.setPosition(new google.maps.LatLng(this.lat, this.lng));
              }

              (this.locationMarker as PulsingIncidentMarker).toggleWhat3Polygon(this.w3wGridControl.w3wGridVisible);
            });
          }
        });

        if (this.what3WordInfoBox) {
          this.what3WordInfoBox.setVisible(false);
          this.what3WordInfoBox = null;
        }

        if (this.what3WordInfoBox === null) {
          const boxText = document.createElement('div');
          boxText.style.cssText = 'margin-top: 0px; padding: 0px;';
          boxText.innerHTML = boxTextValue.replace('{?}', 'Getting what3address...');

          const infoBoxOptions = this.getInfoboxOptions();
          infoBoxOptions.content = boxText;

          this.what3WordInfoBox = new InfoBox(infoBoxOptions);
          this.what3WordInfoBox.open(this.map, this.locationMarker);
        }

        if (what3WordInfo === null) {
          this.geoLocationService.getWhat3WordsFromCoordinates(this.lat, this.lng).subscribe((response: any) => {
            const what3Words = response.words;
            this.what3WordInfoBox.setContent(boxTextValue.replace('{?}', what3Words));

            let what3WordInfo = (this.locationMarker as PulsingIncidentMarker).getWhat3WordInfo();
            if (what3WordInfo) {
              what3WordInfo.address = what3Words;
            } else {
              what3WordInfo = this.getWhat3WordInfoFromResponse(response);
              (this.locationMarker as PulsingIncidentMarker).setWhat3WordInfo(what3WordInfo);
              (this.locationMarker as PulsingIncidentMarker).toggleWhat3Polygon(this.w3wGridControl.w3wGridVisible);
            }
          });
        } else {
          this.what3WordInfoBox.setContent(boxTextValue.replace('{?}', what3WordInfo.address));
          this.locationMarker.setWhat3WordInfo(what3WordInfo);
          //this.locationMarker.toggleWhat3Polygon(true);
        }

        break;
      case MarkerType.PROJECT:
      case MarkerType.TASK_GROUP:
      case MarkerType.TASK:
      case MarkerType.SUB_TASK:
      case MarkerType.RISK:
      case MarkerType.ISSUE:
      case MarkerType.OPPORTUNITY:
      case MarkerType.EVALUATION:
      case MarkerType.RUNSHEET_ITEM:
        this.locationMarker = new ObjectMarker(
          this.markerType,
          new google.maps.LatLng(this.lat, this.lng),
          this.markerColor,
          this.map
        );
        break;
    }

    //this.locationMarker.draggable = this.editMode;
    this.locationMarker.attachEvent('dragstart', () => {
      if (this.markerType === MarkerType.INCIDENT) {
        const what3WordInfo = (this.locationMarker as PulsingIncidentMarker).getWhat3WordInfo();
        if (what3WordInfo) {
          what3WordInfo.polygon.setMap(null);

          if (this.what3WordInfoBox) {
            this.what3WordInfoBox.setVisible(false);
          }
        }
      }
    });
    this.locationMarker.attachEvent('dragend', (mouseEvent: google.maps.MapMouseEvent) => {
      this.userDraggedMarker = true;

      this.lat = this.locationMarker.getPosition().lat();
      this.lng = this.locationMarker.getPosition().lng();
      this.zoom = 20;

      if (this.markerType === MarkerType.INCIDENT) {
        const what3WordInfo = (this.locationMarker as PulsingIncidentMarker).getWhat3WordInfo();
        if (what3WordInfo) {
          this.geoLocationService.getWhat3WordsFromCoordinates(this.lat, this.lng).subscribe((response: any) => {
            const newWhat3WordInfo = this.getWhat3WordInfoFromResponse(response);
            what3WordInfo.polygon.setPaths(newWhat3WordInfo.polygon.getPaths());
            what3WordInfo.address = newWhat3WordInfo.address;

            if (this.what3WordInfoBox) {
              this.what3WordInfoBox.setVisible(true);
              this.what3WordInfoBox.setContent(boxTextValue.replace('{?}', newWhat3WordInfo.address));
              this.what3WordInfoBox.setPosition(new google.maps.LatLng(this.lat, this.lng));
            }

            (this.locationMarker as PulsingIncidentMarker).toggleWhat3Polygon(this.w3wGridControl.w3wGridVisible);
          });
        }
      }

      this.onLocationUpdate(this.locationMarker.getPosition());
    });
    this.locationMarker.attachEvent('click', (mouseEvent: google.maps.MapMouseEvent) => {});
  }

  private getWhat3WordInfoFromResponse(response: any): What3WordInfo {
    const what3Words = response.words;

    const southEest = response.square.southwest;
    const northEast = response.square.northeast;
    const squareBounds = new google.maps.LatLngBounds();
    squareBounds.extend(new google.maps.LatLng(southEest.lat, southEest.lng));
    squareBounds.extend(new google.maps.LatLng(northEast.lat, northEast.lng));

    const what3SquarePolygon = new google.maps.Polygon({
      map: null,
      paths: GMapUtilities.convertLatLngBoundsToPolygonPath(squareBounds),
      fillColor: this.markerColor,
      fillOpacity: 0.3,
      strokeColor: this.markerColor,
      strokeOpacity: 1,
      strokeWeight: 1,
    });

    const what3WordInfo = {
      polygon: what3SquarePolygon,
      address: what3Words,
    } as What3WordInfo;

    return what3WordInfo;
  }

  private getInfoboxOptions(): any {
    const ibOptions = {
      disableAutoPan: false,
      maxWidth: 0,
      pixelOffset: new google.maps.Size(0, 10, 'px', 'px'),
      centerHorizontally: true,
      zIndex: null,
      boxStyle: {
        padding: '0px 0px 0px 0px',
        width: 'auto',
        height: 'auto',
      },
      closeBoxURL: '',
      infoBoxClearance: new google.maps.Size(1, 1),
      isHidden: false,
      pane: 'floatPane',
      enableEventPropagation: false,
    };

    return ibOptions;
  }

  // Override functions - Used in IncidentDetailsComponent to reset the location/zoom/add "No Location" overlay
  setComponentLocation(lat: number, lng: number) {
    this.setMapLocation(new google.maps.LatLng(lat, lng));
  }

  setComponentLocationAtZoom(lat: number, lng: number, zoom: number) {
    this.setComponentLocation(lat, lng);
    this.setZoom(zoom);
  }

  setZoom(zoom: number) {
    this.zoom = zoom;
  }
}

