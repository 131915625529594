import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { AttachmentsListFilterTypes } from 'src/app/modules/shared/components/documents/enums/attachmentsListFilterTypes';
import { AttachmentsListViewTypes } from 'src/app/modules/shared/components/documents/enums/attachmentsListViewTypes';
import { DocumentCategoryViewModel } from 'src/app/modules/shared/viewModels/documents/documentCategoryViewModel';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-attachments-list-header',
  templateUrl: 'attachments-list-header.component.html',
  styleUrls: ['attachments-list-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsListHeaderComponent implements OnInit {
  @Input() isNativePlatform = false;
  @Input() isDetailsPage: boolean = false;
  @Input() isInAccordion: boolean = false;
  @Input() showUploadButton: boolean = false;
  @Input() documentCategories: DocumentCategoryViewModel[] = [];
  /**
   * Whether to show the buttons for list and board view
   */
  @Input() showListBoardButtons: boolean = true;

  @Output() onAttachmentViewChanged= new EventEmitter<AttachmentsListViewTypes>();
  @Output() onAttachmentsTypeChanged = new EventEmitter<unknown>();
  @Output() onAddLink = new EventEmitter<null>();
  @Output() onTakePhoto = new EventEmitter<null>();
  @Output() onFileUpload = new EventEmitter<null>();
  @Output() onCategoryOptionChanged = new EventEmitter<DocumentCategoryViewModel>();
  public readonly T = T;
  public selectedListView: AttachmentsListViewTypes = AttachmentsListViewTypes.Board;
  public attachmentsListViewTypes = AttachmentsListViewTypes;
  public attachmentsListFilterTypes = AttachmentsListFilterTypes;
  public selectedDropdownOption: { id: number; value: AttachmentsListFilterTypes, translatedValue: string };
  public sortDropdownItems = [
    { id: 1, value: AttachmentsListFilterTypes.Show_All, translatedValue: this.translateService.instant(`common.${(AttachmentsListFilterTypes.Show_All).split(' ').join('_').toLowerCase()}`) },
    { id: 2, value: AttachmentsListFilterTypes.Media, translatedValue: this.translateService.instant(`common.${(AttachmentsListFilterTypes.Media).toLowerCase()}`) },
    { id: 3, value: AttachmentsListFilterTypes.Files, translatedValue: this.translateService.instant(`common.${(AttachmentsListFilterTypes.Files).toLowerCase()}`) },
  ];
  selectedCategory: DocumentCategoryViewModel;

  constructor(private readonly translateService: TranslateService) {
  }

  ngOnInit() {
    this.selectedDropdownOption = this.sortDropdownItems[0];
    this.selectedCategory = this.documentCategories[0];
  }

  public selectAttachmentsView(attachmentsListViewType: AttachmentsListViewTypes): void {
    this.selectedListView = attachmentsListViewType;
    this.onAttachmentViewChanged.emit(this.selectedListView);
  }

  public selectedDropdownOptionChanged(option: { id: number; value: AttachmentsListFilterTypes, translatedValue: string }): void {
    this.selectedDropdownOption = option;
    this.onAttachmentsTypeChanged.emit(option);
  }

  public openFileUpload() {
    this.onFileUpload.emit();
  }

  public takePhoto() {
    this.onTakePhoto.emit();
  }

  public addLink() {
    this.onAddLink.emit();
  }

  public onCategoryOptionChange(category: DocumentCategoryViewModel) {
    this.selectedCategory = category;
    this.onCategoryOptionChanged.next(category);
  }
}
