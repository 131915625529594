<div class="filters-live-event-entry" [class.filters-live-event-entry-selected]="selected">
  <div class="filters-live-event-entry-content">
    <div class="filters-live-event-entry-content-title">{{ event.title }}</div>
    <div class="filters-live-event-entry-content-information">
      <div class="live-lozenge-small">Live</div>
      <div class="filters-live-event-entry-content-information-date">{{ date }}</div>
    </div>
  </div>
  <div class="filters-live-event-entry-icon" *ngIf="selected">
    <i class="material-symbols-outlined">done</i>
  </div>
</div>
