import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Observer, of } from 'rxjs';
import { AuthGuardService } from '../../../auth/auth-guard.service';
import { AuthenticationService } from '../../services/authentication.service';
import { EmployeeSettingsService } from '../../services/employee-settings.service';

@Injectable({
  providedIn: 'root',
})
export class SustainabilityGuard {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly authGuardService: AuthGuardService,
    private readonly employeeSettingsService: EmployeeSettingsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authGuardService.canActivate(route, state)) {
      const account = this.authService.getCurrentAccount();
      const employee = this.authService.getCurrentEmployee();
      if (!account || !employee) {
        return new Observable<boolean>((observer: Observer<boolean>) => {
          this.employeeSettingsService.getLoggedInEmployeeForGuards().subscribe(() => {
            const result = this.canAccess();

            observer.next(result);
          });
        });
      } else {
        const result = this.canAccess();
        return of(result);
      }
    } else {
      return of(false);
    }
  }

  canAccess(): boolean {
    const account = this.authService.getCurrentAccount();

    if (!account.isActive) {
      void this.router.navigateByUrl('v2/unauthorized');
    }

    return account.useSustainability;
  }
}
