import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.radio-button-disabled]': 'disabled',
  },
})
export class RadioButtonComponent {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() name: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() text: string;
  @Input() useBiggerCheckmark: boolean;

  @Output() checkedChanged: EventEmitter<boolean> = new EventEmitter();

  @HostListener('click', ['$event'])
  onRadioButtonToggled(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.disabled) {
      return;
    }

    this.checkedChanged.emit(this.input.nativeElement.checked);
  }
}
