import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-list-header-loading',
  templateUrl: 'skeleton-list-header-loading.component.html',
  styleUrls: ['skeleton-list-header-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonListHeaderLoadingComponent {
  @Input() showRARs: boolean = true;
}
