<div class="selector-container">
  <div class="always-visible-container" (click)="onExpand()">
    <div class="left-side">
      <span class="selector-title">{{filterSelectorVM.filterTypeText}}</span>
      <ng-container *ngIf="dateFilterViewModel?.dateRangeOption">
        <span class="selector-count">1</span>
        <app-button-transparent *ngIf="expanded" [text]="'Clear filters'" (clicked)="onClear()"></app-button-transparent>
      </ng-container>
      <!-- <span class="selector-count">{{getDateFilterText()}}</span> -->
    </div>
    <div class="right-side">
      <i class="material-symbols-outlined expand-icon">expand_more</i>
    </div>
  </div>
  <div class="hidding-container" *ngIf="expanded">
    <div class="dates-select-container">
      <ng-container>
        <div class="filters-date-relative-range-container">
          <div class="date-options custom-scrollbar">
            <div class="date-option-container">
              <app-radio-button
              [text]="'None'"
              [checked]="isNoneFilterSelected()"
              (checkedChanged)="onClear()"
              ></app-radio-button>
            </div>
            <div class="date-option-container fixed-radio">
              <app-radio-button
              [checked]="isFixedRangeSelected()"
              [text]="'Fixed Dates'"
              (click)="openDatepicker($event)"
              ></app-radio-button>
              <span class="material-symbols-outlined open-modal-icon ms-2"
              #openDatepickerIcon
              ngRangeDatepicker
              [startDate]="fromDate"
              [endDate]="toDate"
              [timePicker]="showTimePicker"
              (datesChanged)="onDateRangeChanged($event)"
              >
                open_in_new
                </span>
            </div>
            <div class="range-time-field" *ngIf="isFixedRangeSelected()">
              <!-- This allows click over text field when pointer events is none -->
              <div class="text-field-overlay" (click)="openDatepicker($event)"></div>
              <app-text-field
              [placeholder]="''"
              [useNgDateMask]="true"
              [format]="dateFormat"
              [separator]="'/'"
              [ngModel]="getDateFilterText()"
              ></app-text-field>

            </div>
            <div class="date-option-container" *ngFor="let relativeOption of filterSelectorVM.dateRangeOptions">
              <app-radio-button
              [checked]="isSelected(relativeOption)"
              [text]="sanitizeText(relativeOption)"
              (checkedChanged)="onRelativeDateSelected(relativeOption)"
              ></app-radio-button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
