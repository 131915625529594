import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { NgBlockScreenComponent } from '../components/common/block-screen/block-screen.component';
import { DynamicComponentsService } from '../services/dynamicComponents.service';

@Directive({
  selector: '[ngBlockScreen]',
})
export class NgBlockScreenDirective implements OnInit, OnChanges {
  @Input() blockScreenElementRef: ElementRef<HTMLElement>;

  @Output() blockScreenClicked: EventEmitter<void> = new EventEmitter();

  static ngBlockScreenComponentRef: ComponentRef<NgBlockScreenComponent>;

  private zIndex: string;
  private position: string;

  constructor(private readonly dynamicComponentsService: DynamicComponentsService, private readonly appRef: ApplicationRef) {
    if (!NgBlockScreenDirective.ngBlockScreenComponentRef) {
      this.appendToBody();
    }
  }

  ngOnInit() {
    this.updateInstance();
  }

  ngOnChanges() {
    this.updateInstance();
  }

  get ngBlockScreenInstance(): NgBlockScreenComponent {
    return NgBlockScreenDirective.ngBlockScreenComponentRef.instance;
  }

  get ngBlockScreenChangeDetectorRef(): ChangeDetectorRef {
    return NgBlockScreenDirective.ngBlockScreenComponentRef.changeDetectorRef;
  }

  updateInstance() {
    if (!this.ngBlockScreenInstance) {
      return;
    }

    this.ngBlockScreenInstance.blockScreenClicked = this.blockScreenClicked;
    this.ngBlockScreenChangeDetectorRef.detectChanges();
  }

  addElementRefStyles(): void {
    const { nativeElement } = this.blockScreenElementRef;

    if (nativeElement.style) {
      this.zIndex = nativeElement.style.zIndex;
      this.position = nativeElement.style.position;
    }

    nativeElement.style.zIndex = `${this.ngBlockScreenInstance.zIndexMax - 1}`;

    const position: string = this.computedStyleByProperty(nativeElement, 'position');

    if ((!position || position === 'static') && position !== 'absolute') {
      this.blockScreenElementRef.nativeElement.style.position = 'relative';
    }
  }

  removeElementRefStyles() {
    if (!this.blockScreenElementRef) {
      return;
    }

    if (this.zIndex) {
      this.blockScreenElementRef.nativeElement.style.zIndex = this.zIndex;
    }

    this.blockScreenElementRef.nativeElement.style.position = this.position;
  }

  @HostListener('document:keydown.escape', ['$event']) onEscape(e: Event) {
    this.removeElementRefStyles();

    this.ngBlockScreenInstance.hide();
  }

  private getDomElement<T>(componentRef: ComponentRef<T>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<T>).rootNodes[0] as HTMLElement;
  }

  private appendToBody() {
    NgBlockScreenDirective.ngBlockScreenComponentRef = this.dynamicComponentsService.createComponentElement(
      NgBlockScreenComponent,
      {}
    );

    this.appRef.attachView(NgBlockScreenDirective.ngBlockScreenComponentRef.hostView);

    document.body.appendChild(this.getDomElement(NgBlockScreenDirective.ngBlockScreenComponentRef));
  }

  private removeFromBody() {
    this.getDomElement(NgBlockScreenDirective.ngBlockScreenComponentRef).remove();

    this.appRef.detachView(NgBlockScreenDirective.ngBlockScreenComponentRef.hostView);
  }

  private computedStyleByProperty(element: HTMLElement, property: string): string {
    return window.getComputedStyle(element).getPropertyValue(property);
  }
}
