import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CardTypes } from 'src/app/modules/shared/enums/cardTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { IndicatorUpdateCardViewModel } from '../../../../sustainability/viewModels/indicatorUpdateCardViewModel';
import { SustainabilityUtilities } from '../../../../sustainability/utilities/sustainability.utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-indicator-update',
  templateUrl: './card-indicator-update.component.html',
  styleUrls: ['./card-indicator-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardIndicatorUpdateComponent implements OnInit {
  @Input() indicatorUpdate: IndicatorUpdateCardViewModel;
  @Input() isUpdateDue: boolean = false;
  @Output() onEditUpdate: EventEmitter<number> = new EventEmitter();
  public cardType: CardTypes = CardTypes.Indicator;
  public readonly T = T;
  public valueString: string = '';
  public expectedValueString: string = '';
  protected modalRef: BsModalRef;

  constructor(private readonly translateService: TranslateService) {}

  ngOnInit(): void {
    this.valueString = SustainabilityUtilities.GetFormattedValue(this.indicatorUpdate.indicatorUpdateType,
      this.indicatorUpdate.value, this.indicatorUpdate.unitOfMeasurementAbbreviation,
      this.translateService.instant(T.control.pass), this.translateService.instant(T.control.fail));

    this.expectedValueString = SustainabilityUtilities.GetFormattedValue(this.indicatorUpdate.indicatorUpdateType,
        this.indicatorUpdate.expectedValue, this.indicatorUpdate.unitOfMeasurementAbbreviation,
        this.translateService.instant(T.control.pass), this.translateService.instant(T.control.fail));
  }

  public updateIndicatorUpdate() {
    this.onEditUpdate.emit(this.indicatorUpdate.id);
  }

}
