import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { T } from 'src/assets/i18n/translation-keys';
import { TimeZoneService } from '../../../services/timeZone.service';
import { isDateValid } from '../../../utilities/date.utilities';
import { BadgeColor, BadgeTextColor, BadgeVariant } from '../badge/badge.component';

@Component({
  selector: 'app-days-diff-badge',
  templateUrl: './days-diff-badge.component.html',
  styleUrls: ['./days-diff-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaysDiffBadgeComponent implements OnChanges {
  @Input() then: string;
  @Input() now: string = new Date().toISOString();
  @Input() variant: BadgeVariant = 'slim';

  diff: number;
  label: string;
  color: BadgeColor;
  textColor: BadgeTextColor;
  icon: 'event_note' | 'today';

  constructor(
    private readonly timeZoneService: TimeZoneService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const nowDate = this.timeZoneService.localiseDateISOStringToDate(changes.now?.currentValue ?? this.now);
    const thenDate = this.timeZoneService.localiseDateISOStringToDate(changes.then?.currentValue);

    if (!isDateValid(nowDate) || !isDateValid(thenDate)) {
      return;
    }

    this.diff = moment(nowDate.toISOString()).startOf('day').diff(moment(thenDate.toISOString()).startOf('day'), 'days');

    if (this.diff > 0) {
      this.label =
        this.diff === 1
          ? this.translateService.instant(T.calendar.yesterday)
          : this.translateService.instant(T.common.time_ago.days_ago.many, { count: this.diff });
      this.color = 'transparent';
      this.textColor = 'blue';
    } else if (this.diff === 0) {
      this.label = this.translateService.instant(T.calendar.today);
      this.color = 'black';
      this.textColor = 'white';
    } else if (this.diff === -1) {
      this.label = this.translateService.instant(T.calendar.tomorrow);
      this.color = 'gray';
      this.textColor = 'white';
    } else {
      this.label = this.translateService.instant(T.calendar.in_count_days.many, { count: Math.abs(this.diff) });
      this.color = 'transparent';
      this.textColor = 'gray';
    }

    this.icon = this.diff === 0 ? 'today' : 'event_note';

    this.changeDetectorRef.markForCheck();
  }
}
