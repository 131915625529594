import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { Employee } from '../../../models/employee';
import { RagHelper } from '../../../utilities/rag.utilities';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { Constants } from 'src/app/modules/shared/models/constants';
import { DetailIconTypes } from '../../../types/DetailsIcon.types';
import { FilterViewModelWithComment } from '../../../models/filter/filterViewModelWithComment.Model';
import { EnumUtilities } from '../../../utilities/enum.utilities';

@Component({
  selector: 'app-details-icon-filter',
  templateUrl: 'details-icon-filter.component.html',
  styleUrls: ['details-icon-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsIconFilterComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions = new Subscription();
  private employee: Employee;
  mobileFilterVisible: boolean = false;

  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;

  @Input() label: string;
  @Input() iconType: DetailIconTypes;
  @Input() filterType: FilterTypes;
  @Input() appliedFilters: FilterViewModel[];
  @Input() disabled = false;
  @Input() includeComment = true;
  @Input() commentRequiredForValues: any[] = [];
  @Input() commentPlaceholder: string = 'Add Comment';

  @Output() onFilterUpdated = new EventEmitter<{ filters: FilterViewModel[]; comment: string }>();

  availableFilters: FilterViewModel[] = [];
  currentFilter: FilterViewModel;
  comment: string;

  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setCurrentFilter();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.label) {
      this.label = EnumUtilities.items(FilterTypes).find((i) => i.key === this.filterType).value;
    }

    this.employee = this.authenticationService.getCurrentEmployee();
    this.availableFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);
    this.setCurrentFilter();
    this.changeDetectorRef.markForCheck();
  }

  setCurrentFilter() {
    this.currentFilter = this.appliedFilters.find((x) => x.filterType === this.filterType);

    if (this.currentFilter && !this.currentFilter.colour) {
      this.currentFilter.colour = RagHelper.getRAGColourHexFromValue(this.currentFilter.filterValue);
      this.changeDetectorRef.markForCheck();
    }
    if (this.currentFilter && !this.currentFilter.filterText) {
      const matching = this.availableFilters.find((f) => f.filterValue.toString() == this.currentFilter.filterValue.toString());
      if (matching) {
        this.currentFilter.filterText = matching.filterText;
      }
    }
  }

  getFilterTypes(): FilterTypes {
    return this.filterType;
  }

  get currentFilterColour(): string {
    let result = '';

    if (this.currentFilter) {
      if (this.currentFilter.colour) {
        result = this.currentFilter.colour;
      } else {
        const colour = RagHelper.getRAGColourHexFromValue(+this.currentFilter.filterValue);
        if (colour) {
          result = colour.toString();
        }
      }
    }

    return result;
  }

  get mobile(): boolean {
    return window.innerWidth <= Constants.xs;
  }
  onFiltersWithCommentAdded(ev: FilterViewModelWithComment) {
    const filters = this.appliedFilters.filter((s) => s.filterType !== this.filterType);
    filters.push(...ev.filters);
    this.appliedFilters = filters;
    this.comment = ev.comment;

    this.onFilterUpdated.next({ filters: this.appliedFilters, comment: this.comment });

    this.comment = undefined;

    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }

    if (this.mobileFilterVisible) {
      this.toggleMobileFilter();
    }
  }

  toggleMobileFilter() {
    this.mobileFilterVisible = !this.mobileFilterVisible;
  }

  onCancel() {}

  onClear(ev: any) {}

  changeComment(comment: string) {
    this.comment = comment;
  }
}
