import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Configuration } from 'src/app/app.constants';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { AccountSelectItemViewModel } from 'src/app/modules/shared/viewModels/accountSelectItemViewModel';

@Component({
  selector: 'user-accounts',
  templateUrl: './user-accounts.component.html',
  styleUrls: ['user-accounts.component.scss'],
})
export class UserAccountSelectComponent implements OnInit {
  public isLoading: boolean = true;
  public employee: Employee;
  public accounts: AccountSelectItemViewModel[] = [];
  public localUrl: string;

  constructor(
    private readonly configuration: Configuration,
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthService,
    private readonly cachingService: CachingService
  ) {}
  ngOnInit() {
    this.localUrl = this.configuration.BackEndAPI;
    this.employee = this.authenticationService.getCurrentEmployee();
    this.accounts = this.employee.accounts;
    this.isLoading = false;
  }

  onLoginWithAccount(account: AccountSelectItemViewModel) {
    if (!account.isActive) {
      return;
    }

    if (Capacitor.getPlatform() !== 'web' || this.configuration.configuration == 'development') {
      this.cachingService.setCurrentAccount(account);
      location.reload();
    } else {
      document.location.href = account.url;
    }
  }
}
