import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataBlock } from '../enums/weather/darkSkyApi/DataBlock';
import { Observable, tap } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { WeatherDataCachingService } from './weather-data/weather-data-caching.service';

@Injectable({
  providedIn: 'root',
})
export class WeatherDataService {
  private readonly openWeatherApiBaseURL = 'https://api.openweathermap.org/data/3.0/onecall';
  private readonly openWeatherApiKey = 'e02a21f55b05208f5caf3f2331c3a40d';

  private readonly openWeatherAssetsURL = 'https://openweathermap.org/img/wn/';

  private url: string;

  // Cache expire time in milliseconds
  public static readonly cacheExpireTime = 1000 * 60 * 60; // 1 hour

  constructor(
    private httpClient: HttpClient,
    private configuration: Configuration,
    private weatherDataCachingService: WeatherDataCachingService
  ) {
    this.url = this.configuration.buildEndpoint(``);
  }

  public getIconURL(icon: string, scaleSize: number): string {
    return `${this.openWeatherAssetsURL}${icon}@${scaleSize}x.png`;
  }

  /**
   * Get weather data from OpenWeather API for the given location.
   * If the data is available in cache and not expired, return the cached data.
   *
   * @param lat
   * @param lng
   * @param excludeBlocks
   * @returns
   */
  public getWeatherDataFromOpenWeatherApi(lat: number, lng: number, excludeBlocks: DataBlock[]): Observable<any> {
    const cachedData = this.weatherDataCachingService.getCachedWeatherData({ lat, lng });

    // Check if the data is available in cache
    if(cachedData && new Date().getTime() - cachedData.timestamp < WeatherDataService.cacheExpireTime) {
      return new Observable((observer) => {
        observer.next(cachedData.data);
        observer.complete();
      });
    }

    // If data is not in cache, make the API call
    const openWeatherApiUrl = this.buildOpenWeatherApiURL(lat, lng, excludeBlocks);
    return this.httpClient.post(`${this.url}Utilities/DarkSky`, { url: openWeatherApiUrl })
      .pipe(
        tap((data) => {
          this.weatherDataCachingService.saveCachedWeatherData({ lat, lng }, data);
        })
      );
  }

  private buildOpenWeatherApiURL(lat: number, lng: number, excludeBlocks: DataBlock[]): string {
    let urlStringBuilder: string = this.openWeatherApiBaseURL;
    urlStringBuilder += `?lat=${lat}&lon=${lng}&appid=${this.openWeatherApiKey}`;

    if (excludeBlocks && excludeBlocks.length > 0) {
      const excludedBlocks = excludeBlocks.join();
      urlStringBuilder += '&exclude=' + excludedBlocks;
    }

    urlStringBuilder += '&units=metric';

    return urlStringBuilder;
  }
}
