import { Component, ChangeDetectionStrategy, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-no-results',
  templateUrl: 'no-results.component.html',
  styleUrls: ['no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultsComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input() icon: string = 'error';
  @Input() title: string = 'No items match these filters';

  ngOnDestroy(): void {
    this.subscriptions.forEach((element) => {
      element.unsubscribe();
    });
  }
}
