import { DepartmentVisibilities } from '../../enums/departmentVisibility.enum';
import { FilterTypes } from '../../enums/filterTypes';
import { Employee } from '../../models/employee';
import { FilterViewModel } from '../../models/filter/filterViewModel';

export class BasePermissionManager {
  protected primaryFilters = {
    [FilterTypes.Risk]: [FilterTypes.Risk],
    [FilterTypes.Project]: [FilterTypes.Project, FilterTypes.Task, FilterTypes.Task_Group],
    [FilterTypes.Incident]: [FilterTypes.Incident],
    [FilterTypes.Job]: [FilterTypes.Job],
  };

  /**
   * Validates if the user have ownership rights
   */
  protected haveOwnerRights(obj, employee): boolean {
    let isOwner = obj.filters.some((e) => e.filterType == FilterTypes.Owner && e.filterValue == employee.id);
    if (!isOwner) {
      isOwner = obj.permissionFilters.some((e) => e.filterType == FilterTypes.Owner && e.filterValue == employee.id);
    }

    return isOwner;
  }

  /**
   * Check if the user have write access to the provided object by his own departments
   */
  protected haveDepartmentPermissions(
    employee: Employee,
    primaryFilterType: FilterTypes,
    objectFilters: FilterViewModel[],
    permissionFilters: FilterViewModel[]
  ): boolean {
    const applicablePermissions = employee.permissions.find(
      (e) => !e.confidential && e.type == +primaryFilterType && e.write && e.value == 0
    );

    if (applicablePermissions) {
      if (applicablePermissions.departmentVisibility == DepartmentVisibilities.All_Departments) {
        return true;
      }

      if (applicablePermissions.departmentVisibility == DepartmentVisibilities.Nothing) {
        return false;
      }

      return objectFilters.some((e) => e.filterType == FilterTypes.Department && e.filterValue == employee.departmentId);
    }

    if (permissionFilters != null && primaryFilterType == FilterTypes.Project) {
      return permissionFilters.some((e) => e.filterType == FilterTypes.Department && e.filterValue == employee.departmentId);
    }

    return false;
  }
}
