import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { ObjectTypes } from '../../enums/objectTypes';
import { EmployeeViewModel } from '../employeeViewModel';

export class ShareItemViewModel {
  id: number;
  objectType: ObjectTypes;
  employeeID: number;
  comment: string;
  recipients: EmployeeViewModel[];
  url: string;
  title: string;
  incidentSubType: IncidentItemTypes;
  userGroupRecipients: number[];
}
