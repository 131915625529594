<div class="modal-save-container">
  <div class="modal-save-header">
    <h6 class="modal-save-title">{{ titleToRender }}</h6>
  </div>
  <div class="modal-save-content">
    <div class="modal-save-message">{{ messageToRender }}</div>
  </div>
  <div class="modal-save-footer">
    <div class="modal-save-buttons">
      <app-button-rectangle
        *ngIf="!isCancel"
        class="cancel-button"
        text="Cancel"
        (clicked)="onCancel()"
        buttonTheme="white"
        size="large"
      ></app-button-rectangle>
      <app-button-rectangle
        *ngIf="isCancel"
        class="cancel-button"
        text="Stay"
        (clicked)="onCancel()"
        buttonTheme="white"
        size="large"
      ></app-button-rectangle>
      <app-button-rectangle
        *ngIf="!isCancel"
        class="save-button"
        text="Save"
        (clicked)="onDelete()"
        buttonTheme="primary"
        size="large"
      ></app-button-rectangle>
      <app-button-rectangle
        *ngIf="isCancel"
        class="save-button"
        text="Leave"
        (clicked)="onDelete()"
        buttonTheme="danger"
        size="large"
      ></app-button-rectangle>
    </div>
  </div>
</div>
