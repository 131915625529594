<div class="row" *ngIf="customMessage">
  <div class="col-12 mb-3 text-left">
    <span class="label" [innerHTML]="customMessage"> </span>
  </div>
</div>
<div class="row scroll-wrapper g-0" *ngIf="!loading">
  <div class="col-12">
    <div class="checklist-templates-wrapper" [ngStyle]="{ height: wrapperHeight + 'vh' }">
      <div class="search-wrapper">
        <input
          [(ngModel)]="searchValue"
          type="text"
          (input)="onSearch()"
          [placeholder]="
            T.common.search_item | translate: { item: T.defaultLocalizations.checklist_item.many | translate }
          "
        />
        <div class="responsive-flex-wrapper">
          <i class="material-symbols-outlined">search</i>
        </div>
      </div>
      <cdk-virtual-scroll-viewport class="list-container list-group" [itemSize]="size" minBufferPx="900" maxBufferPx="2000" orientation="vertical">
        <ng-container *ngIf="useSimpleAddModal">
          <div
            *cdkVirtualFor="let template of checklistTemplates; let i = index;templateCacheSize: 0"
            class="template-item"
            [ngClass]="{ 'selected-template': isSelectedTemplate(template) }"
            (click)="selectTemplate(template)"
          >
            <div class="checklist-template-item-wrapper description-wrap">
              <span [app-tooltip]="{ message: template.title, position: ['top'] }">
                {{ template.title }}
              </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!useSimpleAddModal">
          <div class="letter-sections-wrapper custom-scrollbar">
            <div class="checkpoint-count-wrapper">
              {{ T.common.count_selected.many | translate: { count: selectedChecklistTemplates?.length } }},
              {{ T.common.count_total | translate: { count: checklistTemplates?.length } }}
            </div>
            <div *ngFor="let symbol of checklistSymbols" class="letter-section">
              <div class="letter-heading">{{ symbol }}</div>
              <div *ngFor="let checklist of checklistBySymbol[symbol]" class="letter-section-item">
                <app-checkbox
                  [disabled]="checkIfChecklistIsAlreadyUsed(checklist)"
                  [isChecked]="isSelectedTemplate(checklist)"
                  (checked)="selectTemplate(checklist)"
                ></app-checkbox>
                <div
                  (click)="viewChecklistChecks(checklist)"
                  [class.opened-checklist]="openedChecklist && checklist.id === openedChecklist.id"
                  class="letter-section-item-text"
                >
                  {{ checklist.title | htmlString }}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
