import { Directive, ElementRef, Input, OnInit, OnDestroy, ChangeDetectorRef, HostListener, Renderer2 } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isChildOf } from 'src/app/modules/shared/utilities/html.utilities';
import { PopupEventsEmitter } from '../events/popup.events';
import { WindowEventsEmitter } from '../events/window.events';
import { Popup } from '../models/popup';
import { Position } from '../enums/position';

@Directive({
  selector: '[app-popup]',
  host: {
    '(click)': 'onClick($event, true)',
  },
  exportAs: 'app-popup',
})
export class PopupDirective implements OnInit, OnDestroy {
  private readonly arrowHeight: number = 6;
  private readonly arrowWidth: number = 16;
  private readonly arrowShifting: number = 4;

  @Input('app-popup') popup: Popup;

  popupElement: HTMLElement;
  subscriptions: Subscription[] = [];

  private mouseX = 0;
  private mouseY = 0;
  mouseroverListener: any;
  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly popupEventsEmitter: PopupEventsEmitter,
    private readonly windowEventsEmitter: WindowEventsEmitter,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly renderer: Renderer2
  ) {}

  ngOnInit() {
    this.popupElement = this.popupEventsEmitter.popupElement;


    this.subscriptions.push(
      this.popupEventsEmitter.popupComponentViewInitialized$.subscribe((element) => (this.popupElement = element))
    );

    this.subscriptions.push(
      this.popupEventsEmitter.popupCancelClicked$
        .pipe(filter(() => !!this.popupElement))
        .subscribe(() => this.onClick(null, false))
    );

    this.subscriptions.push(
      this.popupEventsEmitter.popupSaveChangesClicked$
        .pipe(filter(() => !!this.popupElement))
        .subscribe(() => this.onClick(null, false))
    );

    this.subscriptions.push(
      this.windowEventsEmitter.windowScrollEventTriggered$
        .pipe(
          filter(
            () =>
              !!this.popupElement &&
              this.popupElement.classList.contains('shown') &&
              this.elementRef.nativeElement.classList.contains('popup-element')
          )
        )
        .subscribe((e: Event) => {
          // const minX = this.popupElement.offsetLeft;
          // const maxX = minX + this.popupElement.offsetWidth;
          // const minY = this.popupElement.offsetTop;
          // const maxY = minY + this.popupElement.offsetHeight;

          // const mouseInsidePopupElementFlag = (this.mouseX >= minX && this.mouseX <= maxX && this.mouseY >= minY && this.mouseY <= maxY);
          // if(!mouseInsidePopupElementFlag) {
          //   this.onClick(null, false)
          // }

          const targetIsNotPartOfThePopup =
            e.target !== this.popupElement && !isChildOf(this.popupElement, e.target as HTMLElement);
          if (targetIsNotPartOfThePopup) {
            this.onClick(null, false);
          }
        })
    );

    this.subscriptions.push(
      this.popupEventsEmitter.popupPositionChanged$
        .pipe(
          filter(
            () =>
              !!this.popupElement &&
              this.popupElement.classList.contains('shown') &&
              this.elementRef.nativeElement.classList.contains('popup-element')
          )
        )
        .subscribe(() => this.onClick(null, false))
    );

    this.subscriptions.push(
      this.router.events
        .pipe(
          filter(
            (e) =>
              e instanceof NavigationStart &&
              !!this.popupElement &&
              this.popupElement.classList.contains('shown') &&
              this.elementRef.nativeElement.classList.contains('popup-element')
          )
        )
        .subscribe(() => this.onClick(null, false))
    );

    this.subscriptions.push(
      this.popupEventsEmitter.popupDimensionsChanged$
        .pipe(
          filter(
            () =>
              !!this.popupElement &&
              !!this.popup &&
              this.popupElement.classList.contains('shown') &&
              this.elementRef.nativeElement.classList.contains('popup-element')
          )
        )
        .subscribe(() => this.positionPopup(this.popup.position, this.popup.arrowPosition))
    );

    this.subscriptions.push(
      this.popupEventsEmitter.popupElementRefChanged$
        .pipe(filter((e) => e !== this.elementRef.nativeElement))
        .subscribe(() => this.elementRef.nativeElement.classList.remove('popup-element'))
    );

    this.subscriptions.push(
      this.windowEventsEmitter.windowClickEventTriggered$.pipe(filter(() => !!this.popupElement)).subscribe((e) => {
        const target: HTMLElement = e.target as HTMLElement;
        const targetIsNotChildOfTooltip: boolean = !isChildOf(this.popupElement, target);
        const { nativeElement } = this.elementRef;

        if (target !== nativeElement && targetIsNotChildOfTooltip) {
          this.popupEventsEmitter.broadcastPopupCancelClicked();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    if (
      !!this.popupElement &&
      this.popupElement.classList.contains('shown') &&
      this.elementRef.nativeElement.classList.contains('popup-element')
    ) {
      this.popupElement.classList.remove('shown');
      this.elementRef.nativeElement.classList.remove('popup-element');
    }
    //this.mouseroverListener();
  }

  @HostListener('mouseover', ['$event'])
  onMouseOver($event) {
    this.mouseX = $event.clientX;
    this.mouseY = $event.clientY;
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (
      !!this.popupElement &&
      this.popupElement.classList.contains('shown') &&
      this.elementRef.nativeElement.classList.contains('popup-element')
    ) {
      this.onClick(null, false);
    }
  }

  onClick(e: Event, isClick: boolean) {
    if (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

    if (!isClick) {
      this.popupElement.classList.remove('shown');
      this.elementRef.nativeElement.classList.remove('popup-element');

      return;
    }

    if (this.popupElement.classList.contains('shown')) {
      this.popupElement.classList.remove('shown');
      this.elementRef.nativeElement.classList.remove('popup-element');

      return;
    }

    if (!this.popup.position || !this.popup.arrowPosition) {
      return;
    }

    this.popupEventsEmitter.broadcastPopupChanged(this.popup);
    this.popupEventsEmitter.directiveElementRef = this.elementRef;

    this.changeDetectorRef.detectChanges();

    this.positionPopup(this.popup.position, this.popup.arrowPosition);

    this.popupElement.classList.toggle('shown');
    this.elementRef.nativeElement.classList.toggle('popup-element');
    this.popupEventsEmitter.broadcastPopupElementRefChanged(this.elementRef.nativeElement);
  }

  private positionPopup(position: Position, arrowPosition: Position[]) {
    if (!this.popup) {
      return;
    }

    this.popupEventsEmitter.broadcastPopupChanged(
      Object.assign({}, this.popup, {
        position,
        arrowPosition,
      })
    );

    const elementBoundingRectangle = this.elementRef.nativeElement.getBoundingClientRect();
    const bodyBoundingRectangle = document.body.getBoundingClientRect();

    this.popupElement.style.left = `0px`;
    this.popupElement.style.top = `0px`;

    const popupElementBoundingRectangle = this.popupElement.getBoundingClientRect();

    switch (position) {
      case Position.Top:
        if (elementBoundingRectangle.top - this.arrowHeight - popupElementBoundingRectangle.height < bodyBoundingRectangle.top) {
          return this.positionPopup(Position.Bottom, arrowPosition);
        }

        break;
      case Position.Bottom:
        if (
          elementBoundingRectangle.bottom + this.arrowHeight + popupElementBoundingRectangle.height >
          bodyBoundingRectangle.bottom
        ) {
          return this.positionPopup(Position.Top, arrowPosition);
        }

        break;
      case Position.Left:
        if (elementBoundingRectangle.left - this.arrowHeight - popupElementBoundingRectangle.width < bodyBoundingRectangle.left) {
          return this.positionPopup(Position.Right, arrowPosition);
        }

        break;
      case Position.Right:
        if (
          elementBoundingRectangle.right + this.arrowHeight + popupElementBoundingRectangle.width >
          bodyBoundingRectangle.right
        ) {
          return this.positionPopup(Position.Left, arrowPosition);
        }

        break;
    }

    const [axis, axisPosition] = arrowPosition;

    switch (axis) {
      case Position.Top:
        switch (axisPosition) {
          case Position.Left:
            if (
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 + popupElementBoundingRectangle.width >
              bodyBoundingRectangle.right
            ) {
              return this.positionPopup(position, [axis, Position.Right]);
            }

            break;
          case Position.Right:
            if (
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 - popupElementBoundingRectangle.width <
              bodyBoundingRectangle.left
            ) {
              return this.positionPopup(position, [axis, Position.Left]);
            }

            break;
          default:
            if (
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 + popupElementBoundingRectangle.width / 2 >
              bodyBoundingRectangle.right
            ) {
              return this.positionPopup(position, [axis, Position.Right]);
            }

            if (
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 - popupElementBoundingRectangle.width / 2 <
              bodyBoundingRectangle.left
            ) {
              return this.positionPopup(position, [axis, Position.Left]);
            }

            break;
        }

        break;
      case Position.Bottom:
        switch (axisPosition) {
          case Position.Left:
            if (
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 + popupElementBoundingRectangle.width >
              bodyBoundingRectangle.right
            ) {
              return this.positionPopup(position, [axis, Position.Right]);
            }

            break;
          case Position.Right:
            if (
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 - popupElementBoundingRectangle.width <
              bodyBoundingRectangle.left
            ) {
              return this.positionPopup(position, [axis, Position.Left]);
            }

            break;
          default:
            if (
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 + popupElementBoundingRectangle.width / 2 >
              bodyBoundingRectangle.right
            ) {
              return this.positionPopup(position, [axis, Position.Right]);
            }

            if (
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 - popupElementBoundingRectangle.width / 2 <
              bodyBoundingRectangle.left
            ) {
              return this.positionPopup(position, [axis, Position.Left]);
            }

            break;
        }

        break;
      case Position.Left:
        switch (axisPosition) {
          case Position.Top:
            if (
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 + popupElementBoundingRectangle.height >
              bodyBoundingRectangle.bottom
            ) {
              return this.positionPopup(position, [axis, Position.Bottom]);
            }

            break;
          case Position.Bottom:
            if (
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 - popupElementBoundingRectangle.height <
              bodyBoundingRectangle.top
            ) {
              return this.positionPopup(position, [axis, Position.Top]);
            }

            break;
          default:
            if (
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 + popupElementBoundingRectangle.height / 2 >
              bodyBoundingRectangle.bottom
            ) {
              return this.positionPopup(position, [axis, Position.Bottom]);
            }

            if (
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 - popupElementBoundingRectangle.height / 2 <
              bodyBoundingRectangle.top
            ) {
              return this.positionPopup(position, [axis, Position.Top]);
            }

            break;
        }

        break;
      case Position.Right:
        switch (axisPosition) {
          case Position.Top:
            if (
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 + popupElementBoundingRectangle.height >
              bodyBoundingRectangle.bottom
            ) {
              return this.positionPopup(position, [axis, Position.Bottom]);
            }

            break;
          case Position.Bottom:
            if (
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 - popupElementBoundingRectangle.height <
              bodyBoundingRectangle.top
            ) {
              return this.positionPopup(position, [axis, Position.Top]);
            }

            break;
          default:
            if (
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 + popupElementBoundingRectangle.height / 2 >
              bodyBoundingRectangle.bottom
            ) {
              return this.positionPopup(position, [axis, Position.Bottom]);
            }

            if (
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 - popupElementBoundingRectangle.height / 2 <
              bodyBoundingRectangle.top
            ) {
              return this.positionPopup(position, [axis, Position.Top]);
            }

            break;
        }

        break;
    }

    switch (position) {
      case Position.Top:
        this.popupElement.style.top = `${
          elementBoundingRectangle.top - popupElementBoundingRectangle.height - this.arrowHeight
        }px`;

        break;
      case Position.Bottom:
        this.popupElement.style.top = `${elementBoundingRectangle.top + elementBoundingRectangle.height + this.arrowHeight}px`;

        break;
      case Position.Left:
        this.popupElement.style.left = `${
          elementBoundingRectangle.left - popupElementBoundingRectangle.width - this.arrowHeight
        }px`;

        break;
      case Position.Right:
        this.popupElement.style.left = `${elementBoundingRectangle.left + elementBoundingRectangle.width + this.arrowHeight}px`;

        break;
    }

    switch (axis) {
      case Position.Top:
        switch (axisPosition) {
          case Position.Left:
            this.popupElement.style.left = `${
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 - this.arrowShifting - this.arrowWidth / 2
            }px`;

            break;
          case Position.Right:
            this.popupElement.style.left = `${
              elementBoundingRectangle.left +
              elementBoundingRectangle.width / 2 -
              popupElementBoundingRectangle.width +
              this.arrowShifting +
              this.arrowWidth / 2
            }px`;

            break;
          default:
            this.popupElement.style.left = `${
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 - popupElementBoundingRectangle.width / 2
            }px`;

            break;
        }

        break;
      case Position.Bottom:
        switch (axisPosition) {
          case Position.Left:
            this.popupElement.style.left = `${
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 - this.arrowShifting - this.arrowWidth / 2
            }px`;

            break;
          case Position.Right:
            this.popupElement.style.left = `${
              elementBoundingRectangle.left +
              elementBoundingRectangle.width / 2 -
              popupElementBoundingRectangle.width +
              this.arrowShifting +
              this.arrowWidth / 2
            }px`;

            break;
          default:
            this.popupElement.style.left = `${
              elementBoundingRectangle.left + elementBoundingRectangle.width / 2 - popupElementBoundingRectangle.width / 2
            }px`;

            break;
        }

        break;
      case Position.Left:
        switch (axisPosition) {
          case Position.Top:
            this.popupElement.style.top = `${
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 - this.arrowShifting - this.arrowWidth / 2
            }px`;

            break;
          case Position.Bottom:
            this.popupElement.style.top = `${
              elementBoundingRectangle.top +
              elementBoundingRectangle.height / 2 -
              popupElementBoundingRectangle.height +
              this.arrowShifting +
              this.arrowWidth / 2
            }px`;

            break;
          default:
            this.popupElement.style.top = `${
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 - popupElementBoundingRectangle.height / 2
            }px`;

            break;
        }

        break;
      case Position.Right:
        switch (axisPosition) {
          case Position.Top:
            this.popupElement.style.top = `${
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 - this.arrowShifting - this.arrowWidth / 2
            }px`;

            break;
          case Position.Bottom:
            this.popupElement.style.top = `${
              elementBoundingRectangle.top +
              elementBoundingRectangle.height / 2 -
              popupElementBoundingRectangle.height +
              this.arrowShifting +
              this.arrowWidth / 2
            }px`;

            break;
          default:
            this.popupElement.style.top = `${
              elementBoundingRectangle.top + elementBoundingRectangle.height / 2 - popupElementBoundingRectangle.height / 2
            }px`;

            break;
        }

        break;
    }
  }
}
