import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ChecklistItemViewModel } from 'src/app/modules/shared/models/checklists/checklistItemViewModel';
import { ChecklistTemplateViewModel } from 'src/app/modules/shared/models/checklists/checklistTemplateViewModel';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-checklist-template-list',
  templateUrl: 'template-list.component.html',
  styleUrls: ['template-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistTemplateListComponent implements OnInit, OnChanges {
  @Input() previouslySelectedChecklistTemplates: {
    checklistId: number;
    groupTitle: string;
    checklistItems: ChecklistItemViewModel[];
  }[];
  @Input() originalChecklistTemplates: ChecklistTemplateViewModel[] = [];
  @Input() wrapperHeight: number = 75;
  @Input() isSingleSelect: boolean = true;
  @Input() customMessage: string;
  @Input() useSimpleAddModal: boolean = true;
  @Output() updateSelectedTemplates = new EventEmitter<ChecklistTemplateViewModel[]>();
  @Output() editSelectedTemplate = new EventEmitter<ChecklistTemplateViewModel>();
  @Output() deleteSelectedTemplate = new EventEmitter<ChecklistTemplateViewModel>();
  @Output() onSelectChecklist = new EventEmitter<ChecklistTemplateViewModel>();
  public selectedChecklistTemplates: ChecklistTemplateViewModel[] = [];
  public checklistSymbols: Set<string> = new Set();
  public selectedChecklistSymbols: Set<string> = new Set();
  public selectedChecklistIds: number[] = [];
  public checklistBySymbol: { [key: string]: ChecklistTemplateViewModel[] } = {};
  public checklistSelected: { [key: number]: boolean } = {};
  public openedChecklist: ChecklistTemplateViewModel;
  size = 35;
  searchValue: string;
  checklistTemplates: ChecklistTemplateViewModel[] = [];
  loading = true;
  public readonly T = T;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.originalChecklistTemplates) {
      this.searchValue = undefined;
      this.checklistTemplates = JSON.parse(JSON.stringify(this.originalChecklistTemplates));
    }
  }

  ngOnInit(): void {
    this.checklistTemplates = JSON.parse(JSON.stringify(this.originalChecklistTemplates));
    this.initChecklistSelectionTable(this.checklistTemplates);
    this.initPreviouslySelectedChecklistTemplates(this.previouslySelectedChecklistTemplates);
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  private initChecklistSelectionTable(checklists: ChecklistTemplateViewModel[]): void {
    this.checklistSymbols = new Set();
    this.checklistBySymbol = {};

    checklists = checklists.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

    checklists.forEach((cap) => {
      const startingLetter = cap.title[0]?.toUpperCase();

      if (startingLetter != undefined) {
        if (!this.checklistSymbols.has(startingLetter)) {
          this.checklistSymbols.add(startingLetter);
        }

        if (this.checklistBySymbol[startingLetter] == undefined) {
          this.checklistBySymbol[startingLetter] = [cap];
        } else {
          this.checklistBySymbol[startingLetter].push(cap);
        }
      }
    });

    this.changeDetectorRef.markForCheck();
  }

  private initPreviouslySelectedChecklistTemplates(checklistGroup: any) {
    if (checklistGroup && checklistGroup.length) {
      checklistGroup.forEach((group) => {
        this.checklistTemplates.forEach((checklist) => {
          if (checklist.id === group.checklistId) {
            this.selectedChecklistTemplates.push(checklist);
          }
        });
      });
    }
  }

  public checkIfChecklistIsAlreadyUsed(checklist: ChecklistTemplateViewModel) {
    if (this.previouslySelectedChecklistTemplates) {
      return this.previouslySelectedChecklistTemplates.find((x) => x.checklistId === checklist.id);
    }
  }

  selectTemplate(template: ChecklistTemplateViewModel) {
    const matching = this.selectedChecklistTemplates.find((t) => t.id === template.id);
    if (matching) {
      if (this.isSingleSelect) {
        this.selectedChecklistTemplates = [];
      } else {
        this.selectedChecklistTemplates = this.selectedChecklistTemplates.filter((t) => t.id !== template.id);
      }
    } else {
      if (this.isSingleSelect) {
        this.selectedChecklistTemplates = [];
      }

      this.selectedChecklistTemplates.push(template);
    }

    this.updateSelectedTemplates.next(this.selectedChecklistTemplates);

    this.changeDetectorRef.markForCheck();
  }

  isSelectedTemplate(template: ChecklistTemplateViewModel) {
    const matching = this.selectedChecklistTemplates.find((t) => t.id === template.id);
    return matching !== undefined;
  }

  onSearch() {
    if (this.searchValue) {
      this.checklistTemplates = this.originalChecklistTemplates.filter((c) =>
        c.title.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    } else {
      this.checklistTemplates = this.originalChecklistTemplates.slice();
    }

    if (this.useSimpleAddModal) {
      this.selectedChecklistTemplates = [];
      this.initChecklistSelectionTable(this.checklistTemplates);
      this.initPreviouslySelectedChecklistTemplates(this.previouslySelectedChecklistTemplates);
    }

    this.changeDetectorRef.markForCheck();
  }

  public viewChecklistChecks(checklist: ChecklistTemplateViewModel) {
    if (this.openedChecklist && checklist.id === this.openedChecklist.id) {
      this.openedChecklist = undefined;
    } else {
      this.openedChecklist = checklist;
    }

    this.onSelectChecklist.next(checklist);
  }

  public onEditChecklist(template: ChecklistTemplateViewModel): void {
    this.editSelectedTemplate.emit(template);
  }

  public onDeleteChecklist(template: ChecklistTemplateViewModel): void {
    this.deleteSelectedTemplate.emit(template);
  }
}
