<app-button-dropdown-simple
  [text]="text"
  [dropdownOptions]="dropdownOptions"
  [currentlySelectedOption]="selectedOption"
  [bindProperty]="'value'"
  [fitToContainer]="fitToContainer"
  [buttonTheme]="buttonTheme"
  [icon]="'arrow_drop_down'"
  [closeUponSelection]="true"
  (optionSelected)="onDropdownItemSelected($event)"
></app-button-dropdown-simple>
