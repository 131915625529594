import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Constants } from 'src/app/modules/shared/models/constants';

@Component({
  selector: 'app-no-location-button',
  templateUrl: './no-location-button.component.html',
  styleUrls: ['./no-location-button.component.scss'],
})
export class NoLocationButtonComponent implements OnInit {
  public isMobile = false;

  @Output() onClickedEvent = new EventEmitter<void>();

  //Input
  onClicked: Function;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < Constants.xs;
  }

  onClick($event: MouseEvent) {
    if (this.onClicked && typeof this.onClicked === 'function') {
      this.onClicked();
    }

    this.onClickedEvent.emit();
  }
}
