import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { FilterDateRangeOptions } from '../../../enums/filter/filterDateRangeOptions';
import { FilterValueDateViewModel } from '../../../models/filter/filterValueDateViewModel';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { TimeZoneService } from '../../../services/timeZone.service';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { TextFieldComponent } from '../../common/text-field/text-field.component';

@Component({
  selector: 'app-filters-date',
  templateUrl: './filters-date.component.html',
  styleUrls: ['./filters-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-date-single-mobile]': 'mobile',
  },
})
export class FiltersDateComponent implements OnInit, OnChanges {
  @ViewChild('fromDateInput', { static: true }) fromTextFieldComponent: TextFieldComponent;
  @ViewChild('toDateInput', { static: true }) toTextFieldComponent: TextFieldComponent;

  @Input() filters: FilterViewModel[];
  @Input() mobile: boolean;
  @Input() dateRange: boolean = true;
  @Input() filterTypeSelectorViewModel: FilterTypeSelectorViewModel;

  @Input() keyboardCancel: boolean = true;
  @Output() cancelClicked = new EventEmitter();

  @Output() filterAdded: EventEmitter<FilterViewModel[]> = new EventEmitter();
  @Output() filterRemoved: EventEmitter<FilterViewModel[]> = new EventEmitter();

  dateFilterViewModel: FilterViewModel = new FilterViewModel();
  date: Date = new Date();
  dateModel: string;
  fromDate: Date;
  fromDateModel: string;
  toDate: Date;
  toDateModel: string;
  separator: string = '/';
  relative: boolean = true;
  focused: string;
  hasAnyChanges: boolean = false;
  showTimePicker: boolean = false;
  timePickerDisabled: boolean = true;
  public timeStartString: string;
  public timeDueString: string;

  constructor(private readonly timeZoneService: TimeZoneService, private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.filterTypeSelectorViewModel.dateProperty == FilterDateOptions.Start_Time) {
      this.showTimePicker = true;
    }

    if (
      this.filters.length > 0 &&
      this.filters.findIndex(
        (a) =>
          a.filterType == this.filterTypeSelectorViewModel.filterType &&
          a.dateProperty == this.filterTypeSelectorViewModel.dateProperty &&
          a.displayForGlobalObjectType == this.filterTypeSelectorViewModel.displayForObjectType
      ) > -1
    ) {
      this.dateFilterViewModel = this.filters.find(
        (a) =>
          a.filterType == this.filterTypeSelectorViewModel.filterType &&
          a.dateProperty == this.filterTypeSelectorViewModel.dateProperty &&
          a.displayForGlobalObjectType == this.filterTypeSelectorViewModel.displayForObjectType
      );
      this.relative = this.dateFilterViewModel.dateRangeOption != FilterDateRangeOptions.Fixed_Date_Range;
      if (this.dateFilterViewModel.dateRangeOption == FilterDateRangeOptions.Fixed_Date_Range) {
        this.fromDate = new Date(this.dateFilterViewModel.filterValue.fromDate);
        this.toDate = new Date(this.dateFilterViewModel.filterValue.toDate);
      }
      if (this.dateFilterViewModel.dateRangeOption == FilterDateRangeOptions.Fixed_Time_Range) {
        this.fromDate = new Date(this.dateFilterViewModel.filterValue.fromDate);
        this.toDate = new Date(this.dateFilterViewModel.filterValue.toDate);
        this.timeStartString = moment(this.fromDate).format('HH:mm');
        this.timeDueString = moment(this.toDate).format('HH:mm');
        this.timePickerDisabled = false;
        this.relative = false;
        this.hasAnyChanges = true;
      }
    } else if (!this.fromDate && !this.toDate) {
      this.InitDateModel();
    }
  }

  ngOnChanges() {
    if (!this.filters) {
      this.filters = [];
    }
    if (
      this.filters.length > 0 &&
      this.filters.findIndex(
        (a) =>
          a.filterType == this.filterTypeSelectorViewModel.filterType &&
          a.dateProperty == this.filterTypeSelectorViewModel.dateProperty &&
          a.displayForGlobalObjectType == this.filterTypeSelectorViewModel.displayForObjectType
      ) > -1
    ) {
      this.dateFilterViewModel = this.filters.find(
        (a) =>
          a.filterType == this.filterTypeSelectorViewModel.filterType &&
          a.dateProperty == this.filterTypeSelectorViewModel.dateProperty &&
          a.displayForGlobalObjectType == this.filterTypeSelectorViewModel.displayForObjectType
      );
      this.relative = this.dateFilterViewModel.dateRangeOption != FilterDateRangeOptions.Fixed_Date_Range;
      if (this.dateFilterViewModel.dateRangeOption == FilterDateRangeOptions.Fixed_Date_Range) {
        this.fromDate = new Date(this.dateFilterViewModel.filterValue.fromDate);
        this.toDate = new Date(this.dateFilterViewModel.filterValue.toDate);
      }
      if (this.dateFilterViewModel.dateRangeOption == FilterDateRangeOptions.Fixed_Time_Range) {
        this.fromDate = new Date(this.dateFilterViewModel.filterValue.fromDate);
        this.toDate = new Date(this.dateFilterViewModel.filterValue.toDate);
        this.timeStartString = moment(this.fromDate).format('HH:mm');
        this.timeDueString = moment(this.toDate).format('HH:mm');
        this.timePickerDisabled = false;
        this.relative = false;
        this.hasAnyChanges = true;
      }
    } else if (!this.fromDate && !this.toDate) {
      this.InitDateModel();
    }
  }

  get dateFormat(): string {
    return this.timeZoneService.dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
  }

  onFocus(side: string): void {
    this.focused = side;
  }

  onTextFieldClick(): void {
    this.changeDetectorRef.detectChanges();
  }

  onModelChange(): void {
    const formats = this.dateFormat.split(this.separator);
    const parts = this.dateModel.split(this.separator);

    // every date format has 3 parts e.g. dd/MM/yyyy
    if (parts.length < 3) {
      return;
    }

    const day = parts[formats.findIndex((f) => f === 'dd')];
    const month = parts[formats.findIndex((f) => f === 'MM')];
    const year = parts[formats.findIndex((f) => f === 'yyyy')];

    const date = new Date(`${month}/${day}/${year}`);

    this.onDateChanged(date.toISOString());
  }

  onRelativeDateSelected(dateRangeOption: FilterDateRangeOptions): void {
    if (this.dateFilterViewModel.dateRangeOption == dateRangeOption) {
      this.dateFilterViewModel.dateRangeOption = null;
    } else {
      this.dateFilterViewModel.dateRangeOption = dateRangeOption;
    }

    this.dateFilterViewModel.filterValue.dateRange = this.dateFilterViewModel.dateRangeOption;
    this.hasAnyChanges = true;
  }

  onDateChanged(dateAsIsoString: string): void {}

  onFromDateChanged(dateAsIsoString: string): void {
    this.dateFilterViewModel.dateRangeOption = FilterDateRangeOptions.Fixed_Date_Range;

    const filterValueDateVm = this.dateFilterViewModel.filterValue as FilterValueDateViewModel;
    filterValueDateVm.fromDate = dateAsIsoString;
    filterValueDateVm.dateRange = FilterDateRangeOptions.Fixed_Date_Range;
    this.dateFilterViewModel.filterValue = filterValueDateVm;

    this.fromDate = new Date(dateAsIsoString);
    this.hasAnyChanges = true;
    if (!this.toDate) {
      const dummyToDate = new Date(this.fromDate);
      dummyToDate.setMonth(this.fromDate.getMonth());
      this.onToDateChanged(dummyToDate.toISOString());
      this.onStartTimeChange('00:00');
      this.onDueTimeChange('00:00');
      this.timeStartString = '00:00';
      this.timeDueString = '00:00';
    }
    this.timePickerDisabled = false;
  }

  onToDateChanged(dateAsIsoString: string): void {
    this.dateFilterViewModel.dateRangeOption = FilterDateRangeOptions.Fixed_Date_Range;

    const filterValueDateVm = this.dateFilterViewModel.filterValue as FilterValueDateViewModel;
    filterValueDateVm.toDate = dateAsIsoString;
    filterValueDateVm.dateRange = FilterDateRangeOptions.Fixed_Date_Range;
    this.dateFilterViewModel.filterValue = filterValueDateVm;

    this.toDate = new Date(dateAsIsoString);
    this.hasAnyChanges = true;
    if (!this.fromDate) {
      const dummyFromDate = new Date(this.toDate);
      dummyFromDate.setMonth(this.toDate.getMonth());
      this.onFromDateChanged(dummyFromDate.toISOString());
      this.onStartTimeChange('00:00');
      this.onDueTimeChange('00:00');
      this.timeStartString = '00:00';
      this.timeDueString = '00:00';
    }
    this.timePickerDisabled = false;
  }

  onKeyboardCancel(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  onEmitCancel(): void {
    this.cancelClicked.emit();
  }

  onApply(): void {
    this.filterAdded.next([this.dateFilterViewModel]);

    this.keyboardCancel ? this.onKeyboardCancel() : this.onEmitCancel();
  }

  convertDateToFormat(date: Date): string {
    return this.timeZoneService.localiseDateISOString(date.toISOString(), false, false);
  }

  convertDateFilterViewModelToDate(): void {
    if (!this.dateFilterViewModel) {
      return;
    }

    const filterValueDateViewModel = this.dateFilterViewModel.filterValue as FilterValueDateViewModel;

    if (filterValueDateViewModel.dateRange !== FilterDateRangeOptions.Fixed_Date_Range) {
      return;
    }

    this.fromDate = new Date(filterValueDateViewModel.fromDate);
    this.toDate = new Date(filterValueDateViewModel.toDate);

    this.fromDateModel = this.convertDateToFormat(this.fromDate);
    this.toDateModel = this.convertDateToFormat(this.toDate);
  }

  sanitizeText(filterMember: FilterDateRangeOptions): string {
    return FilterDateRangeOptions[filterMember].toString().replace(/_/g, ' ');
  }

  isSelected(filterMember: FilterDateRangeOptions): boolean {
    return filterMember === this.dateFilterViewModel.dateRangeOption;
  }

  isRelativeChecked(isChecked: boolean): boolean {
    this.relative = isChecked;
    return this.relative;
  }

  private InitDateModel() {
    this.dateFilterViewModel.filterSelectorType = this.filterTypeSelectorViewModel.filterSelectorType;
    this.dateFilterViewModel.filterType = this.filterTypeSelectorViewModel.filterType;
    this.dateFilterViewModel.dateProperty = this.filterTypeSelectorViewModel.dateProperty;
    this.dateFilterViewModel.filterSelectorType == this.filterTypeSelectorViewModel.filterSelectorType;
    this.dateFilterViewModel.displayForGlobalObjectType = this.filterTypeSelectorViewModel.displayForObjectType;
    this.dateFilterViewModel.id = 0;

    const filterValueVM = new FilterValueDateViewModel();
    filterValueVM.dateProperty = this.dateFilterViewModel.dateProperty;
    filterValueVM.id = 0;
    filterValueVM.fromDate = '';
    filterValueVM.toDate = '';
    this.dateFilterViewModel.filterValue = filterValueVM;
  }

  onStartTimeChange(time: string) {
    const [h, m] = time.split(':');
    this.fromDate = moment(this.fromDate)
      .set({ hours: +h, minutes: +m, seconds: 0, milliseconds: 0 })
      .toDate();

    this.dateFilterViewModel.dateRangeOption = FilterDateRangeOptions.Fixed_Time_Range;

    const filterValueDateVm = this.dateFilterViewModel.filterValue as FilterValueDateViewModel;
    filterValueDateVm.fromDate = this.fromDate.toISOString();
    filterValueDateVm.dateRange = FilterDateRangeOptions.Fixed_Time_Range;
    this.dateFilterViewModel.filterValue = filterValueDateVm;
  }

  onDueTimeChange(time: string) {
    const [h, m] = time.split(':');
    this.toDate = moment(this.toDate)
      .set({ hours: +h, minutes: +m, seconds: 0, milliseconds: 0 })
      .toDate();

    this.dateFilterViewModel.dateRangeOption = FilterDateRangeOptions.Fixed_Time_Range;

    const filterValueDateVm = this.dateFilterViewModel.filterValue as FilterValueDateViewModel;
    filterValueDateVm.toDate = this.toDate.toISOString();
    filterValueDateVm.dateRange = FilterDateRangeOptions.Fixed_Time_Range;
    this.dateFilterViewModel.filterValue = filterValueDateVm;
  }
}
