<app-modal>
  <app-modal-header [title]="title" (closed)="onClose()"></app-modal-header>

  <app-modal-body>
    <form [formGroup]="form" (keydown)="onKeyDown($event)">
      <div class="row col-row-space">
        <div class="col-12">
          <app-text-field
            [formGroup]="form"
            formControlName="title"
            [placeholder]="(T.defaultLocalizations.title.one | translate) + '*'"
            [maxLength]="200"
            [required]="true"
          ></app-text-field>
        </div>

        <!-- <div class="col-12">
          <app-text-field [formGroup]="form" formControlName="description" [placeholder]="'description'" [maxLength]="200" [required]="false"></app-text-field>
        </div> -->
      </div>
    </form>
  </app-modal-body>
  <app-modal-footer>
    <div class="action-buttons">
      <app-button-rectangle
        class="button"
        [buttonTheme]="'white'"
        [size]="'large'"
        [text]="T.common.cancel | translate"
        (clicked)="onCancel()"
      ></app-button-rectangle>
      <app-button-rectangle
        class="button"
        [buttonTheme]="'primary'"
        [size]="'large'"
        [text]="T.common.confirm | translate"
        (clicked)="onConfirm()"
        [disabled]="!form.valid"
      >
      </app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>
