import { ObjectTypes } from '../../enums/objectTypes';
import { UploadTypes } from '../../enums/uploadTypes';
import { PrivacyStatuses } from '../../enums/privacyStatuses';

export class UploadViewModel {
  documentID: number;
  fileName: string;
  link: string;
  accountId: number;
  employeeId: number;
  globalObjectId: number;
  globalObjectType: ObjectTypes;
  documentCategoryIDs: number[];
  title: string;
  uploadType: UploadTypes;
  description: string;
  privacyStatus: PrivacyStatuses;
  originalTitle: string;
  shared: boolean;
}
