import { ShapePointViewModel } from '../viewModels/shapePointViewModel';
import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { ZonesAreasUtilies } from './zones.utilities';
import { IconUtilities } from './icon.utilities';
import { MapConstants } from '../../settings/constants/mapLayers';
import { LevelIndexLabelViewModel } from '../../settings/viewModels/levelIndexLabelViewModel';
import { ObjectTypes } from '../enums/objectTypes';
import { IncidentItemTypes } from '../../incidents/enums/incidentItemTypes';
import { RiskTypes } from '../../risk/enums/riskTypes';

export class GMapUtilities {
  public static readonly DEFAULT_RUNSHEET_ITEM_COLOR = '#4EC3E0';
  public static readonly DEFAULT_GRAY_BUTTON_COLOR = 'rgb(86, 86, 86)';
  public static readonly DEFAULT_ZONE_POLYGON_COLOR = '#4EC3E0';
  public static readonly DEFAULT_AREA_POLYGON_COLOR = '#F87C56';

  public static getMapFocusStyle(controlLabel: string): google.maps.StyledMapType {
    return new google.maps.StyledMapType(
      [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#bdbdbd',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#757575',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#dadada',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#616161',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#e5e5e5',
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#eeeeee',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#c9c9c9',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
      ],
      {
        name: controlLabel,
        maxZoom: 22,
      }
    );
  }

  public static getMapLayers(): LevelIndexLabelViewModel[] {
    const mapLayers: LevelIndexLabelViewModel[] = [];

    for (let i = 0; i < MapConstants.MAX_MAP_LAYERS; i++) {
      mapLayers.push({
        id: `${i}`,
        label: `${i}`,
      } as LevelIndexLabelViewModel);
    }

    return mapLayers;
  }

  public static getMarkerTypeFromObjectType(objectType: ObjectTypes, subType: RiskTypes | IncidentItemTypes = null): MarkerType {
    let markerType = MarkerType.PROJECT;

    switch (objectType) {
      case ObjectTypes.Project:
        markerType = MarkerType.PROJECT;
        break;
      case ObjectTypes.IncidentItem:
        switch(subType) {
          case IncidentItemTypes.Incident:
            markerType = MarkerType.INCIDENT;
            break;
          case IncidentItemTypes.Log:
            markerType = MarkerType.LOG;
            break;
          case IncidentItemTypes.Job:
            markerType = MarkerType.JOB;
            break;
          default:
            markerType = MarkerType.INCIDENT;
            break;
        }
        markerType = MarkerType.INCIDENT;
        break;
      case ObjectTypes.Job:
        markerType =  MarkerType.JOB;
        break;
      case ObjectTypes.Runsheet:
        markerType = MarkerType.RUNSHEET_ITEM;
        break;
      case ObjectTypes.Runsheet_Item:
        markerType = MarkerType.RUNSHEET_ITEM;
        break;
      case ObjectTypes.Task_Group:
        markerType = MarkerType.TASK_GROUP;
        break;
      case ObjectTypes.Risk:
        switch (subType) {
          case RiskTypes.Risk:
            markerType = MarkerType.RISK;
            break;
          case RiskTypes.Issue:
            markerType = MarkerType.ISSUE;
            break;
          case RiskTypes.Opportunity:
            markerType = MarkerType.OPPORTUNITY;
            break;
        }
        break;
      default:
        markerType = MarkerType.TASK;
        break;
    }

    return markerType;
  }

  /**
   * Returns the distance between two location in KM.
   *
   * @param shapePoint1
   * @param shapePoint2
   */
  public static distanceBetweenShapePoints(shapePoint1: ShapePointViewModel, shapePoint2: ShapePointViewModel): number {
    const R = 6371; // Radius of the earth in km
    const dLat = GMapUtilities.deg2rad(shapePoint1.getLat() - shapePoint2.getLat());
    const dLon = GMapUtilities.deg2rad(shapePoint1.getLng() - shapePoint2.getLng());
    const a =
      (Math.sin(dLat / 2) * Math.sin(dLat / 2)) +
      (Math.cos(GMapUtilities.deg2rad(shapePoint1.getLat())) *
      Math.cos(GMapUtilities.deg2rad(shapePoint2.getLat())) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2));

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km

    return d;
  }

  public static convertLatLngBoundsToPolygonPath(
    latLngBounds: google.maps.LatLngBounds
  ): google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>> {
    const paths = new google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>();
    const path = new google.maps.MVCArray<google.maps.LatLng>();
    const ne = latLngBounds.getNorthEast();
    const sw = latLngBounds.getSouthWest();
    path.push(ne);
    path.push(new google.maps.LatLng(sw.lat(), ne.lng()));
    path.push(sw);
    path.push(new google.maps.LatLng(ne.lat(), sw.lng()));
    paths.push(path);

    return paths;
  }

  public static createZoneAreaThreeStateButton(options: any): HTMLDivElement {
    const zonesControlUI = document.createElement('div');
    zonesControlUI.style.width = '26px';
    zonesControlUI.style.marginLeft = '-2px';
    zonesControlUI.className = 'map-control-btn';
    zonesControlUI.title = options.title;

    const zonesControlIcon = document.createElement('i');
    zonesControlIcon.className = options.initialClassName;
    zonesControlUI.appendChild(zonesControlIcon);
    zonesControlUI.addEventListener('click', options.onClick);

    return zonesControlUI;
  }

  public static buildMarkerSVG(markerType: MarkerType, rgbColor: string): string {
    return IconUtilities.buildMarkerSVG(markerType, rgbColor);
  }

  private static deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

  public static buildPolygonFromZoneModel(
    zoneShapePointsArray: ShapePointViewModel[],
    mapObj: google.maps.Map
  ): google.maps.Polygon {
    const path: google.maps.LatLng[] = [];
    zoneShapePointsArray.forEach((point) => {
      const latLng = new google.maps.LatLng(point.latitude, point.longitude);
      path.push(latLng);
    });

    const polygon = new google.maps.Polygon({
      paths: path,
      strokeColor: ZonesAreasUtilies.ZONE_POLYGON_COLOR,
      strokeOpacity: 0.9,
      strokeWeight: 2,
      fillColor: ZonesAreasUtilies.ZONE_POLYGON_COLOR,
      fillOpacity: 0.2,
      clickable: true,
      map: mapObj,
      zIndex: 1,
    });

    return polygon;
  }

  public static buildPolygonFromAreaModel(
    areaShapePointsArray: ShapePointViewModel[],
    mapObj: google.maps.Map
  ): google.maps.Polygon {
    const path: google.maps.LatLng[] = [];
    areaShapePointsArray.forEach((point) => {
      const latLng = new google.maps.LatLng(point.latitude, point.longitude);
      path.push(latLng);
    });

    const polygon = new google.maps.Polygon({
      paths: path,
      strokeColor: ZonesAreasUtilies.AREA_POLYGON_COLOR,
      strokeOpacity: 0.9,
      strokeWeight: 2,
      fillColor: ZonesAreasUtilies.AREA_POLYGON_COLOR,
      fillOpacity: 0.2,
      clickable: true,
      map: mapObj,
      zIndex: 2,
    });

    return polygon;
  }

  public static getPolygonLatLngBounds(polygon: google.maps.Polygon): google.maps.LatLngBounds {
    const polyLatLngBounds = new google.maps.LatLngBounds();
    const polygonPath = polygon.getPath();

    if (polygonPath) {
      const vertexesArray = polygonPath.getArray();
      vertexesArray.forEach((latLng) => {
        polyLatLngBounds.extend(latLng);
      });
    }

    return polyLatLngBounds;
  }

  public static getPolygonsLatLngBounds(polygons: google.maps.Polygon[]): google.maps.LatLngBounds {
    const polyLatLngBounds = new google.maps.LatLngBounds();

    polygons.forEach((polygon) => {
      const polygonPath = polygon.getPath();

      if (polygonPath) {
        const vertexesArray = polygonPath.getArray();
        vertexesArray.forEach((latLng) => {
          polyLatLngBounds.extend(latLng);
        });
      }
    });

    return polyLatLngBounds;
  }

  public static fromShapePointToGoogleMapsLatLng(shapePoints: ShapePointViewModel[]): google.maps.LatLng[] {
    return shapePoints.map((p) => new google.maps.LatLng(p.latitude, p.longitude));
  }
}
