import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ButtonClasses } from '../../../../enums/styles/buttonClasses.enum';
import { ButtonSizes } from '../../../../enums/styles/buttonSizes.enum';
import { ButtonThemes } from '../../../../enums/styles/buttonThemes.enum';
import { RotationPositions } from '../../../../enums/styles/rotationPositions.enum';
import { RotationSteps } from '../../../../enums/styles/rotationStepsObj.enum';
import { Directions } from '../../../../enums/styles/rotationDirections.enum';

@Component({
  selector: 'app-button-icon-circle',
  templateUrl: './button-icon-circle.component.html',
  styleUrls: ['./button-icon-circle.component.scss'],
  animations: [
    trigger('rotatedState', [
      state(
        'false',
        style({
          transform: 'rotate({{initialDeg}}deg)',
        }),
        { params: { initialDeg: 0 } }
      ),
      state(
        'true',
        style({
          transform: 'rotate({{finalDeg}}deg)',
        }),
        { params: { finalDeg: 0 } }
      ),
      transition('true => false', animate('500ms ease')),
      transition('false => true', animate('500ms ease')),
    ]),
  ],
})
export class ButtonIconCircleComponent {
  @Input() icon = 'expand_more';
  @Input() isOpen = false;
  @Input() size = ButtonSizes.Small;
  @Input() disabled = false;
  @Input() rotation = false;
  @Input() direction = 'left';
  @Input() initialPosition = RotationPositions[0];
  @Input() steps = 2;
  @Input() buttonTheme = ButtonThemes.Secondary;

  @Output() clicked = new EventEmitter();

  getButtonClasses() {
    const classesArr = [];
    classesArr.push(ButtonClasses[this.size]);
    classesArr.push(ButtonClasses[this.buttonTheme]);
    return classesArr;
  }

  get getCurrentState() {
    if (this.rotation) {
      return this.isOpen;
    }
    return false;
  }

  get finalDegrees() {
    const calculatedDegrees = Math.abs(RotationPositions[this.initialPosition] - RotationSteps[this.steps]);
    if (this.direction === Directions.Right) {
      return calculatedDegrees === 0 ? 360 : +calculatedDegrees;
    } else {
      return -calculatedDegrees;
    }
  }

  get initialDegrees() {
    return RotationPositions[this.initialPosition];
  }

  onClick(e: Event) {
    this.clicked.emit(e);
  }
}
