<div>
  <div class="section-header">
    <span>{{ localisedTask }} RAG Breakdown Options</span>
  </div>
  <div>
    <div class="row content-wrapper mb-2" *ngIf="showTaskRAGBreakdownOptions">
      <div class="col-12 mb-2">
        <span class="heading-text">Choose which breakdowns you want to see in dashboard (up to 3)</span>
      </div>
      <div class="col-12" *ngFor="let option of taskRAGBreakdownOptions">
        <div class="checkbox-wrapper">
          <app-checkbox
            [isChecked]="isTaskRAGOptionChecked(option)"
            (checked)="onTaskRAGOptionChecked($event, option)"
          ></app-checkbox>
          <span>
            {{ localiseString(option.value) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
