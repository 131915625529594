<ng-template #largeCardTemplate let-item="item">
  <!-- Card without Show more (...) -->
  <app-simple-card
    *ngIf="!showMoreOptions"
    [redirectable]="redirectableCards"
    [cardData]="item"
    [showCheckbox]="showCheckbox"
    [isChecked]="isCardChecked(item)"
    (selected)="onCardSelected($event, item)"
  >
  </app-simple-card>

  <!-- Card with Show more (...) -->
  <app-simple-card
    *ngIf="showMoreOptions"
    [redirectable]="redirectableCards"
    [cardData]="item"
    [showCheckbox]="showCheckbox"
    [isChecked]="isCardChecked(item)"
    (selected)="onCardSelected($event, item)"
    [moreBtnTemplate]="moreBtnTemplate"
  >
  </app-simple-card>
  <ng-template #moreBtnTemplate>
    <i
      class="material-symbols-outlined"
      (click)="$event.stopPropagation()"
      [closeUponSelection]="true"
      ngDropdown
      [templateRef]="removeLinkTemplate"
      >more_horiz</i
    >
  </ng-template>
  <ng-template #removeLinkTemplate>
    <div class="dropdown-options-container">
      <div class="dropdown-option" (click)="onRemoveLink(item)">{{ T.common.remove | translate }}</div>
    </div>
  </ng-template>
</ng-template>

<div class="linked-list-wrapper">
  <div class="linked-cards-container custom-scrollbar" [style.height]="cardContainerHeight + 'px'">
    <ng-container *ngIf="loading">
      <app-loading></app-loading>
    </ng-container>
    <ng-container *ngIf="linkedItems.length > 0 && !loading">
      <app-paginated-cards *ngIf="!loading" [items]="filteredLinkedItems" [cardTemplate]="largeCardTemplate">
      </app-paginated-cards>
    </ng-container>
    <ng-container *ngIf="linkedItems.length === 0 && !loading">
      <app-empty-state
        [materialIcon]="'link'"
        [showAddItemBtn]="false"
        [message]="T.common.no_item_to_display | translate: { item: T.common.linked_item.many | translate }"
      ></app-empty-state>
    </ng-container>
  </div>
</div>
