import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { TaskListItemViewModel } from '../viewModels/taskListItemViewModel';
import { TaskDetailsViewModel } from '../viewModels/projects/taskDetailsViewModel';
import { ValidatedViewModel } from '../../shared/viewModels/validatedViewModel';
import { StateService } from './state.service';
import { TaskDependenciesViewModel } from '../viewModels/taskDependenciesViewModel';
import { RAGUpdate } from '../models/ragUpdate';
import { map } from 'rxjs/operators';
import { FilterTypes } from '../../shared/enums/filterTypes';
import { FilterUtilities } from '../../shared/utilities/filter.utilities';
import { IApiService } from '../../shared/interfaces/iHttpService';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { ObjectDropResponse } from '../../shared/models/kanban/objectDropResponse';
import { ObjectDropRequest } from '../../shared/models/kanban/objectDropRequest';
import { LightFilterUpdateModel } from '../../shared/models/filter/LightFilterUpdateModel';

@Injectable({
  providedIn: 'root',
})
export class TaskService extends StateService<TaskListItemViewModel> implements IApiService {
  private actionUrl: string;
  constructor(private http: HttpClient, private configuration: Configuration) {
    super();
    this.actionUrl = this.configuration.buildEndpoint(`Task/`);
  }
  updateLight(item: LightFilterUpdateModel) {
    throw new Error('Method not implemented.');
  }

  updateLozenges(item: ModifiableEntityViewModel): Observable<any> {
    throw new Error('Method not implemented.');
  }

  list(filters: FilterViewModel[]): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly?: boolean
  ): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  add(task: TaskDetailsViewModel) {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}Add`, task).pipe(
      map((x) => {
        this.addToList(x.returnModel as TaskListItemViewModel);
        return x;
      })
    );
  }

  getList(
    filters: FilterViewModel[],
    showPinnedOnly: boolean = false,
    customSortFilter: number = 0,
    isMyTrack: boolean = false,
    isParentOnly: boolean = true
  ): Observable<TaskListItemViewModel[]> {
    if (isMyTrack) {
      if (showPinnedOnly) {
        return this.getListData(filters, showPinnedOnly, customSortFilter);
      } else {
        if (filters.findIndex((s) => s.filterType == FilterTypes.Parent_Only) < 0 && isParentOnly) {
          const parentFilter = FilterUtilities.GenerateFilter(FilterTypes.Parent_Only, 0, 'Parent Only');
          filters.push(parentFilter);
        }
        return this.getMyListData(filters, customSortFilter);
      }
    } else {
      return this.getListData(filters, showPinnedOnly, customSortFilter);
    }
  }

  public kanbanUpdateTaskGropup(taskId: number, taskgroupId: number): Observable<ObjectDropResponse> {
    const request: ObjectDropRequest = { objectId: taskId, destinationObjectId: taskgroupId };
    return this.http.post<ObjectDropResponse>(`${this.actionUrl}Kanban/Change/TaskGroup`, request);
  }

  private getListData(
    filters: FilterViewModel[],
    showPinnedOnly: boolean = false,
    customSortFilter: number = 0
  ): Observable<TaskListItemViewModel[]> {
    return this.http.post<TaskListItemViewModel[]>(`${this.actionUrl}List/${showPinnedOnly}/${customSortFilter}`, filters).pipe(
      map((x) => {
        if (!filters.length) this.updateList(x);
        return x;
      })
    );
  }

  private getMyListData(filters: FilterViewModel[], customSortFilter: number = 0): Observable<TaskListItemViewModel[]> {
    return this.http.post<TaskListItemViewModel[]>(`${this.actionUrl}MyList/${customSortFilter}`, filters).pipe(
      map((x) => {
        if (!filters.length) this.updateList(x);
        return x;
      })
    );
  }

  getTaskDependencies(taskId: number): Observable<TaskDependenciesViewModel> {
    return this.http.get<TaskDependenciesViewModel>(`${this.actionUrl}GetTaskDependencies/${taskId}`);
  }

  details(projectId: number): Observable<TaskDetailsViewModel> {
    return this.http.get<TaskDetailsViewModel>(`${this.actionUrl}Details/${projectId}`);
  }

  update(project: TaskDetailsViewModel) {
    return this.http.post(`${this.actionUrl}Update`, project).pipe(
      map((x) => {
        this.updateInList(x as TaskListItemViewModel);
        return x as TaskDetailsViewModel;
      })
    );
  }

  updateHubRAG(ragUpdateModel: RAGUpdate) {
    return this.http.post(`${this.actionUrl}hub/UpdateRAG`, ragUpdateModel);
  }

  updateRAG(ragUpdateModel: RAGUpdate) {
    return this.http.post(`${this.actionUrl}UpdateRAG`, ragUpdateModel);
  }

  updateListItem(taskListViewModel: TaskListItemViewModel) {
    return this.http.put(`${this.actionUrl}List`, taskListViewModel).pipe(
      map((x) => {
        this.updateInList(x as TaskListItemViewModel);
        return x;
      })
    );
  }

  delete(taskId: number) {
    return this.http.post(`${this.actionUrl}Delete/${taskId}`, null).pipe(
      map((x) => {
        this.deleteFromList(taskId);
        return x;
      })
    );
  }

  archive(taskId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${taskId}/${archive}`, null).pipe(
      map((x) => {
        this.deleteFromList(taskId);
        return x;
      })
    );
  }

  bulkArchive(taskIds: number[]) {
    return this.http.post(`${this.actionUrl}BulkArchive`, taskIds);
  }

  getAffectedTasksCount(projectId: number): Observable<number> {
    return this.http.get<number>(`${this.actionUrl}GetAffectedTasksCount/${projectId}`);
  }
}
