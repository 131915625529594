<ng-container *ngIf="canEdit">
  <!-- For now on mobile screens the datepicker will not able to edit -->
  <div
    class="date-details"
    wtTimepicker
    [date]="date"
    (timeChanged)="onTimeChange($event)"
    (timeRemoved)="onTimeRemove()"
  >
    <div class="label">
      <span>{{ label }}</span>
      <app-button-transparent
        style="position: absolute; margin-left: 50px"
        text="Edit"
        icon="edit"
        textColor="primary-text"
        class="edit-icon"
        iconFontSize="16"
      >
      </app-button-transparent>
    </div>
    <div style="font-size: 14px">
      <div *ngIf="date && !includeDate">{{ date | localisedTime }}</div>
      <div *ngIf="date && includeDate">
        <span style="font-weight: 700">{{ date | localisedTime }}</span>
        <span> {{ date | localisedDate: false }}</span>
      </div>
      <div *ngIf="!date">{{ placeholder }}</div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!canEdit">
  <!-- For now on mobile screens the datepicker will not able to edit -->
  <div class="date-details">
    <div class="label">
      <span>{{ label }}</span>
    </div>
    <div style="font-size: 14px">
      <div *ngIf="date && !includeDate">{{ date | localisedTime }}</div>
      <div *ngIf="date && includeDate">
        <span style="font-weight: 700">{{ date | localisedTime }}</span>
        <span> {{ date | localisedDate: false }}</span>
      </div>
      <div *ngIf="!date">{{ placeholder }}</div>
    </div>
  </div>
</ng-container>
