import { Directive, ElementRef, OnInit } from '@angular/core';
import { LocalisationService } from '../../services/localisation.service';

@Directive({
  selector: '[localise]',
})
export class LocalisationDirective implements OnInit {
  constructor(private elementRef: ElementRef, private readonly localisationService: LocalisationService) {}

  ngOnInit() {
    if (this.elementRef && this.elementRef.nativeElement) {
      let text = this.elementRef.nativeElement.innerText;
      if (text) {
        text = this.localisationService.localiseStringCapitalize(text);
        this.elementRef.nativeElement.innerText = text;
      }
    }
  }

}
