<div class="filters-desktop-footer">
  <div class="filters-desktop-footer-left" *ngIf="useClearAllButton">
    <app-button-transparent
      [text]="clearAllButtonLabel"
      [disabled]="clearDisabled"
      size="medium"
      [icon]="useClearAllButtonIcon ? 'highlight_off' : undefined"
      (clicked)="onClearAll()"
    >
    </app-button-transparent>
  </div>

  <div class="filters-desktop-footer-right" *ngIf="useCancelApplyButtons">
    <app-button-transparent [text]="T.common.cancel | translate" size="medium" (clicked)="onCancel()">
    </app-button-transparent>
    <app-button-rectangle
      [disabled]="applyDisabled"
      [size]="'medium'"
      [buttonTheme]="'primary'"
      [text]="T.common.apply | translate"
      (clicked)="onApply()"
      *ngIf="showApplyButton"
    ></app-button-rectangle>
  </div>
</div>
