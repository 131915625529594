import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, skip } from 'rxjs';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { IncidentsListStateService } from './incidents-list-state.service';

@Injectable({ providedIn: 'root' })
export class HeaderFiltersService {
  private readonly _currentFilters = new BehaviorSubject<FilterViewModel[]>([]);
  readonly currentFilters$ = this._currentFilters.asObservable();
  get currentFilters(): FilterViewModel[] {
    return this._currentFilters.value.slice();
  }

  readonly filtersChanged$ = this.currentFilters$.pipe(skip(1));
  private incidentFiltersSubject: BehaviorSubject<FilterViewModel[]> = new BehaviorSubject<FilterViewModel[]>([]);
  public incidentFilters$: Observable<FilterViewModel[]> = this.incidentFiltersSubject.asObservable();

  setCurrentFilters(filters: FilterViewModel[]): void {
    const newFilters = JSON.parse(JSON.stringify(filters)) as FilterViewModel[];
    this._currentFilters.next(newFilters);
  }

  saveIncidentFilters(filters: FilterViewModel[]): void {
    this.incidentFiltersSubject.next(filters);
  }

  getIncidentFilters(): Observable<FilterViewModel[]> {
    return this.incidentFilters$;
  }

  retrieveIncidentFilters(incidentsListStateService: IncidentsListStateService): void {
    this.getIncidentFilters().subscribe(filters => {
      if (filters.length !== 0 && !incidentsListStateService.initialStatusFilterHasBeenSet) {
        this.setCurrentFilters(filters);
      }
    });
  }
}
