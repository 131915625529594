<div [ngClass]="isLabelTopAligned ? 'vertical' : 'horizontal'">
  <div class="combo-label" [ngStyle]="{ width: labelWidth > 0 ? labelWidth + 'px' : '100%' }">{{ label }}</div>
  <div #comboWrapper class="wrapper"
    class="combo-wrapper"
    [ngClass]="{ focus: dropdownVisible || isFilterApplied }"
    [ngStyle]="{ width: width > 0 ? width + 'px' : '100%' }"
  >

    <div *ngIf="isInputText" class="input-wrapper" [formGroup]="form">
      <input #inputElement type="text" class="input" formControlName="input" [placeholder]="placeholder" />
    </div>
    <div *ngIf="!isInputText" class="input-wrapper">
      <ng-container *ngIf="isDropdownList" [formGroup]="form">
        <input
          #inputElement
          *ngIf="!selectedItemsCount; else selectedItemsTemplateView"
          type="text"
          class="input"
          formControlName="input"
          [placeholder]="placeholder"
          [readonly]="isReadonly || null"
          (click)="onInputClick()"
        />
      </ng-container>

      <ng-container *ngIf="isSingleDatePicker">
        <input
          *ngIf="!selectedStartDate; else selectedItemsTemplateView"
          ngdatepicker
          [date]="selectedStartDate"
          inputmode="none"
          type="text"
          autocomplete="off"
          class="input"
          [placeholder]="placeholder"
          (dateChanged)="onStartDateChanged($event)"
          readonly
        />
      </ng-container>
      <ng-container *ngIf="isDateRangePicker">
        <input
          *ngIf="!selectedStartDate && !selectedEndDate; else selectedItemsTemplateView"
          ngRangeDatepicker
          [startDate]="selectedStartDate"
          [endDate]="selectedEndDate"
          inputmode="none"
          type="text"
          autocomplete="off"
          class="input"
          [placeholder]="placeholder"
          (startDateChanged)="onStartDateChanged($event)"
          (endDateChanged)="onEndDateChanged($event)"
          (datesChanged)="onDatesChanged($event)"
          readonly
        />
      </ng-container>
    </div>

    <div class="icon-wrapper">
      <ng-container *ngIf="isDropdownList">
        <i *ngIf="!isFilterApplied; else clearFiltersIcon"
          class="material-symbols-outlined dropdown-icon"
          [ngClass]="{ rotated: dropdownVisible }"
          (click)="onDropdownClick()"
        >
          keyboard_arrow_down
        </i>
      </ng-container>

      <ng-container *ngIf="isInputText">
        <i *ngIf="!isFilterApplied; else clearFiltersIcon" class="material-symbols-outlined search-icon"> search </i>
      </ng-container>
      <i
        *ngIf="isSingleDatePicker"
        ngdatepicker
        [date]="selectedStartDate"
        (dateChanged)="onStartDateChanged($event)"
        class="material-symbols-outlined calendar-icon"
      >
        event
      </i>
      <ng-container *ngIf="isDateRangePicker">
        <i *ngIf="!isFilterApplied; else clearFiltersIcon"
          ngRangeDatepicker
          [startDate]="selectedStartDate"
          [endDate]="selectedEndDate"
          (startDateChanged)="onStartDateChanged($event)"
          (endDateChanged)="onEndDateChanged($event)"
          (datesChanged)="onDatesChanged($event)"
          class="material-symbols-outlined calendar-icon"
        >
          event
        </i>
      </ng-container>

    </div>

    <div
      *ngIf="dropdownVisible && isDropdownList"
      class="dropdown-container"
      [ngStyle]="{ 'max-width': dropDownMaxWidth > 0 ? dropDownMaxWidth + 'px' : '100%' }"
    >
      <div class="content-wrapper">
        <div class="content-list custom-scrollbar">
          <ng-container *ngFor="let item of dropdownListItems">
            <div
              *ngIf="isMatchSearchText(item.value)"
              class="list-item"
              [ngClass]="{ selected: item.selected }"
              (mouseover)="onItemHover(item)"
              (mouseout)="onItemUnhover()"
              (click)="onItemSelected(item)"
            >
              <div class="value-container">
                <ng-container *ngIf="dropdownItemTemplate; else noTemplateView">
                  <ng-template
                    [ngTemplateOutlet]="dropdownItemTemplate"
                    [ngTemplateOutletContext]="{ item: item }"
                  ></ng-template>
                </ng-container>
                <ng-template #noTemplateView>
                  {{ item.value }}
                </ng-template>
              </div>
              <div class="action-container">
                <i *ngIf="item.selected && item.id !== currentlyHoveredItem?.id" class="material-symbols-outlined selected"
                  >done</i
                >
                <i *ngIf="item.selected && item.id === currentlyHoveredItem?.id" class="material-symbols-outlined remove"
                  >highlight_off</i
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #selectedItemsTemplateView>
  <ng-container *ngIf="isDropdownList">
    <ng-container *ngIf="!multiselect; else multiselectTemplateView">
      <input value="{{ selectedItem.value }}" type="text" class="input" (click)="onInputClick()" readonly />
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isSingleDatePicker">
    <input
      value="{{ selectedStartDate | localisedDate: false }}"
      type="text"
      class="input"
      ngdatepicker
      [date]="selectedStartDate"
      (dateChanged)="onStartDateChanged($event)"
      readonly
    />
  </ng-container>
  <ng-container *ngIf="isDateRangePicker">
    <input
      value="{{ selectedStartDate | localisedDate: false }} - {{ selectedEndDate | localisedDate: false }}"
      type="text"
      class="input"
      ngRangeDatepicker
      [startDate]="selectedStartDate"
      [endDate]="selectedEndDate"
      (startDateChanged)="onStartDateChanged($event)"
      (endDateChanged)="onEndDateChanged($event)"
      (datesChanged)="onDatesChanged($event)"
      readonly
    />
  </ng-container>
</ng-template>

<ng-template #multiselectTemplateView>
  <div class="statistics-view-container" (click)="onDropdownClick()">
    <span class="txt">{{ selectedItemsCount }}</span>
    <span class="txt">x</span>
    <i class="material-symbols-outlined filter">filter_list_alt</i>
  </div>
</ng-template>

<ng-template #clearFiltersIcon>
  <i class="material-symbols-outlined remove-icon" (click)="onFilterClearClick()"> cancel </i>
</ng-template>
