export enum ReportRefs {
  None = 0,
  Custom_Objectives = 1001,
  Custom_Risks = 1002,
  Custom_Activity_Report = 1003,
  Custom_Venues = 1004,
  Custom_Departments = 1005,
  Custom_Department_Categories = 1006,
  Custom_Task_Systems = 1007,
  Custom_Employees = 1008,
  Custom_Incidents = 1009,
  Custom_Tasks_Risks_and_Issues = 1011,
  Custom_Action_Steps = 2001,
  Incident_Module = 3000,
  Custom_IMS_Incidents = 3001,
  Custom_IMS_Activity = 3002,
  Custom_IMS_Zones = 3003,
  Custom_IMS_Departments = 3004,
  Custom_IMS_Department_Categories = 3005,
  Custom_IMS_Logs = 3006,
  Custom_IMS_Incident_Categories = 3007,
  gantt_Module = 4000,
  Runsheets_Module = 4001,
  Custom_Logs = 2000,
}
