export enum DashboardWidgetsID {
  COUNTDOWN,
  MOMENTUM,
  DEPARTMENT_TASK_RAG,
  DEPARTMENT_CATEGORY_RAG,
  MILESTONES_SNAPSHOT,
  RISK_ISSUE,
  LEADERBOARD,
  GENERAL_INFO,
  WEATHER_FORECAST,
  MILESTONES_DUE_THIS_MONTH,
  MILESTONES_OVERDUE,
}
