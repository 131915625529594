export enum RiskLikelihoods {
  Highly_Unlikely = 1,
  Unlikely = 2,
  Possible = 3,
  Likely = 4,
  Highly_Likely = 5,
  Issue = 6,
}

export namespace RiskLikelihoods {
  export function numValues() {
    return Object.values(RiskLikelihoods).filter((v) => typeof v === 'number');
  }
}
