import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { MilestoneSnapshotViewModel } from '../viewModels/dashboardModels/milestoneSnapshotViewModel';
import { RiskAndIssuesSnapshotViewModel } from '../viewModels/dashboardModels/riskAndIssueSnapshotViewModel';
import { TaskRAGBreakdownByTypeViewModel } from '../viewModels/dashboardModels/taskRAGBreakdownByTypeViewModel';
import { LeaderboardViewModel } from '../viewModels/dashboardModels/leaderboardViewModel';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { MilestoneListItemViewModel } from '../models/dashboard/milestoneListIemviewModel';
import { Observable } from 'rxjs';
import { HeatMapViewModel } from '../models/dashboard/heatMapViewModel';
import { ProjectOverviewViewModel } from '../models/dashboard/projectOverviewViewModel';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationService } from '../../shared/services/localisation.service';

@Injectable({
  providedIn: 'root',
})
export class PlanningDashboardService {
  private actionUrl = '';

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private authenticationService: AuthenticationService,
    private readonly translateService: TranslateService,
    private readonly localisationService: LocalisationService
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`PlanningDashboard/`);
  }

  getMomentum(filterSettings: FilterViewModel[]) {
    return this.http.post<number>(`${this.actionUrl}momentum`, filterSettings);
  }

  getMilestoneSnapshot(filterSettings: FilterViewModel[]) {
    return this.http.post<MilestoneSnapshotViewModel[]>(`${this.actionUrl}milestonesnapshot`, filterSettings);
  }

  getRiskAndIssuesSnapshot(filterSettings: FilterViewModel[]) {
    return this.http.post<RiskAndIssuesSnapshotViewModel>(`${this.actionUrl}riskandissuessnapshot`, filterSettings);
  }

  getTaskRAGBreakdown(filterSettings: FilterViewModel[]) {
    return this.http.post<TaskRAGBreakdownByTypeViewModel[]>(`${this.actionUrl}taskragbreakdown`, filterSettings);
  }

  getLeaderboards(filterSettings: FilterViewModel[]) {
    return this.http.post<LeaderboardViewModel[]>(`${this.actionUrl}leaderboard`, filterSettings);
  }

  getOverdueMilestones(filterSettings: FilterViewModel[]): Observable<MilestoneListItemViewModel[]> {
    return this.http.post<MilestoneListItemViewModel[]>(`${this.actionUrl}overduemilestones`, filterSettings);
  }

  getDueThisMonthMilestones(filterSettings: FilterViewModel[]): Observable<MilestoneListItemViewModel[]> {
    return this.http.post<MilestoneListItemViewModel[]>(`${this.actionUrl}dueThisMonthMilestones`, filterSettings);
  }

  getRiskImpactTypeHeatmap(filterSettings: FilterViewModel[]): Observable<HeatMapViewModel> {
    return this.http.post<HeatMapViewModel>(`${this.actionUrl}RiskImpactTypeHeatmap`, filterSettings);
  }

  getRiskHeatmap(filterSettings: FilterViewModel[]): Observable<HeatMapViewModel> {
    return this.http.post<HeatMapViewModel>(`${this.actionUrl}RiskHeatmap`, filterSettings);
  }

  getProjectsTaskRAGBreakdown(filterSettings: FilterViewModel[]): Observable<ProjectOverviewViewModel[]> {
    return this.http.post<ProjectOverviewViewModel[]>(`${this.actionUrl}ProjectsTaskRAGBreakdown`, filterSettings);
  }
}
