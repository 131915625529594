import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CommentEventsEmitter {
  private readonly commentSaveChangesClicked = new Subject<void>();
  private readonly commentsSaved = new Subject<void>();
  private readonly commentAdded = new Subject<number>();
  private readonly commentCountUpdated = new Subject<number>();

  commentSaveChangesClicked$ = this.commentSaveChangesClicked.asObservable();
  commentsSaved$ = this.commentsSaved.asObservable();
  commentAdded$ = this.commentAdded.asObservable();
  commentCountUpdated$ = this.commentCountUpdated.asObservable();

  broadcastCommentSaveChangesClicked() {
    this.commentSaveChangesClicked.next();
  }

  broadcastCommentsSaved() {
    this.commentsSaved.next();
  }

  broadcastCommentAdded(incidentItemId: any) {
    this.commentAdded.next(incidentItemId);
  }

  broadcastCommentCountUpdated(incidentItemId: number) {
    this.commentCountUpdated.next(incidentItemId);
  }
}
