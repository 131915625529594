import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input() displayLogo: boolean = false;

  getBoundingRect(element: HTMLElement): ClientRect {
    if (!element) {
      return;
    }

    return element.getBoundingClientRect();
  }
}
