<div
  class="card-container"
  [ngClass]="{ 'active-card': isActive, 'inactive-card': !isActive, 'not-clickable': !clickable }"
>
  <div class="card-inner small" (click)="navigateToDetailsPage()">
    <app-details-icon
      *ngIf="cardData.objectType !== objectTypes.IncidentItem || useDifferentIcon"
      [size]="24"
      [backgroundFill]="ragStatusColor"
      [type]="cardIconType"
    ></app-details-icon>
    <app-bubble
      *ngIf="cardData.objectType === objectTypes.IncidentItem && !useDifferentIcon"
      [large]="false"
      [color]="ragStatusColor"
      [value]="cardData.status"
    ></app-bubble>
    <div class="card-right text-truncate">
      <div class="titles-container text-truncate">
        <span class="title">{{ cardData && cardData.title ? cardData.title : 'Title goes here' }}</span>
        <span class="card-sub-title" *ngIf="subTitle">{{ subTitle }}</span>
      </div>
      <app-checkbox *ngIf="showCheckbox" [isChecked]="isChecked" (checked)="onCheck($event)"></app-checkbox>
      <ng-template *ngIf="moreBtnTemplate" [ngTemplateOutlet]="moreBtnTemplate"></ng-template>
    </div>
  </div>
</div>
