<div class="content-wrapper">
  <div class="footer-template-wrapper">
    <app-incident-map
      #incidentMap
      [defaultZoom]="mapZoom"
      [lat]="location.latitude"
      [lng]="location.longitude"
      [showMyLocationButton]="true"
      [showNoLocationButton]="true"
      [showSearchButton]="true"
      [editMode]="editMode"
      [showIncidentZonesUponLoad]="true"
      (onLocationUpdated)="onIncidentLocationUpdated($event)"
      [markerColor]="pinColour"
      [selectedZones]="mapZones"
      [selectedAreas]="mapAreas"
    >
    </app-incident-map>

    <div class="row m-t-8">
      <ng-container *ngIf="!hasLocation() && config.showNoLocationMessage">
        <div class="col-12 m-b-5">
          <span class="color-red">Incident does not have a location. Click below to add a location.</span>
        </div>
      </ng-container>
      <ng-container *ngIf="config.showEditButtons">
        <div class="col-12" *ngIf="editMode">
          <button class="btn idle-btn" (click)="onCancelEditLocation()">Cancel</button>
          <button class="btn action-btn" (click)="onSaveLocation()">Save Location</button>
        </div>
        <div class="col-12" *ngIf="!editMode">
          <button class="btn idle-btn" (click)="onEditLocation()">Edit Location</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
