import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTypes',
  pure: false,
})
export class FilterFilterTypes implements PipeTransform {
  transform(items: any[], filter: Object): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => (filter as any).filterTypes.indexOf(item.filterType) > -1);
  }
}
