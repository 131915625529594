import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constants';
import { environment } from 'src/environments/environment';
import { isUri } from 'valid-url';
import { ObjectTypes } from '../enums/objectTypes';
import { Router } from '@angular/router';
import { Account } from '../models/account';
import { IncidentItemTypes } from '../../incidents/enums/incidentItemTypes';
import { RiskTypes } from '../../risk/enums/riskTypes';
@Injectable({
  providedIn: 'root',
})
export class UrlService {
  objectTypes = ObjectTypes;
  incidentItemTypes = IncidentItemTypes;

  constructor(private configuration: Configuration, private readonly router: Router) {}

  public buildResourceUrl(url: string): string {
    if (!url) {
      return environment.DEFAULT_AVATAR;
    }

    if (isUri(url)) {
      return url;
    }

    if (url.startsWith('/') && this.configuration.buildStaticFileUrl('/').endsWith('/')) {
      return this.configuration.buildStaticFileUrl(url.substring(1));
    }

    if (!url.startsWith('/') && !this.configuration.buildStaticFileUrl('/').endsWith('/')) {
      return this.configuration.buildStaticFileUrl('/' + url);
    }

    return this.configuration.buildStaticFileUrl(url);
  }

  /**
   * build Account Hub Logo URl to be shown in child account
   */
  public builLogoUrlForHub(url:string,hubAccountUrl:string):string{
    return `${hubAccountUrl}/${this.configuration.ResourceFolderName}/assets/clients/logo/${url}`;
  }

  public navigateToObjectDetailsPage(
    objectType: ObjectTypes,
    objectSubType: number,
    objectId: number,
    openInNewWindow: boolean = true,
    includeAttachmentsFragment = false
  ) {
    const routerLink = this.getLinkToDetailsPage(objectId, objectType, objectSubType);

    const isALogItem = objectType === this.objectTypes.IncidentItem && objectSubType === this.incidentItemTypes.Log;

    if (routerLink) {
      let url: string;

      if(!isALogItem) {
        if(includeAttachmentsFragment) {
          url = this.router.serializeUrl(this.router.createUrlTree([routerLink], {fragment: `attachments`}));
        } else {
          url = this.router.serializeUrl(this.router.createUrlTree([routerLink]));
        }
      } else {
        url = this.router.serializeUrl(this.router.createUrlTree([routerLink], {fragment: `${objectId}`}));
      }

      if (openInNewWindow) {
        window.open(url, '_blank');
      } else {
        void this.router.navigate([url]);
      }
    }
  }

  public getLinkToDetailsPage(objectId: number, objectType: ObjectTypes, objectSubType: number) {

    let routerLink = '';
    switch (objectType) {
      case ObjectTypes.Task:
        routerLink = `/v2/planning/list/tasks/${objectId}`;
        break;
      case ObjectTypes.IncidentItem:
        if(objectSubType === IncidentItemTypes.Incident) {
          routerLink = `/v2/control/list/${objectId}`
        }

        if(objectSubType === IncidentItemTypes.Log) {
          routerLink = `/v2/control/logs`
        }

        break;
      case ObjectTypes.Job:
        routerLink = `/v2/control/jobs/${objectId}`;
        break;
      case ObjectTypes.Project:
        routerLink = `/v2/planning/list/projects/${objectId}`;
        break;
      case ObjectTypes.Risk:
        if (objectSubType === RiskTypes.Risk) {
          routerLink = `/v2/risk/risks-issues/risk/${objectId}`;
        } else if (objectSubType === RiskTypes.Issue) {
          routerLink = `/v2/risk/risks-issues/issue/${objectId}`;
        } else if (objectSubType === RiskTypes.Opportunity) {
          routerLink = `/v2/risk/risks-issues/opportunity/${objectId}`;
        } else {
          routerLink = `/v2/risk/risks-issues/all/${objectId}`;
        }
        break;
      case ObjectTypes.Risk_Assesment:
        routerLink = `/v2/risk/risk-assessments/assessment/${objectId}`;
        break;
      case ObjectTypes.Runsheet:
        routerLink = `/v2/runsheets/list/${objectId}`;
        break;
      case ObjectTypes.Indicator:
        routerLink = `v2/sustainability/indicators/${objectId}`;
        break;
    }

    return routerLink;
  }

  public getAccountPrivacyPolicyURL(account: Account) {
    return account.useWhitelabeling && !!account.alternativePrivacyPolicyURL
      ? account.alternativePrivacyPolicyURL
      : 'https://www.wetrack.com/privacy-policy';
  }

  public getAccountTermsOfUseURL(account: Account) {
    return account.useWhitelabeling && !!account.alternativeTermsOfUseURL
      ? account.alternativeTermsOfUseURL
      : 'https://www.wetrack.com/terms-of-use';
  }
}
