@if (loading) {
  <app-loading>
    <p>{{ T.common.loading | translate }}...</p>
  </app-loading>
}

@else {
  <app-modal>
    <app-modal-header
      [iconType]="iconType"
      [richIconType]="richIconType"
      [title]="headerText"
      (closed)="close()"
    >
    </app-modal-header>
    <app-modal-body>
      <div class="content-wrapper">
        <main>
          <div class="top">
            <section class="left-section">
              <app-severity-picker
                [isReadOnly]="!canEdit"
                [useLargeBubble]="false"
                [rectangleButton]="true"
                [severityCheckpoint]="severityCheckpoint"
                (severityCheckpointSelected)="onSeverityCheckpointSelected($event)">
              </app-severity-picker>
              <div class="separator"></div>
              <app-details-dropdown
                [accountId]="incidentItem.accountId"
                [useLightUpdate]="true"
                [isStatus]="true"
                [displayLabel]="false"
                [showDropdownArrow]="true"
                [arrowPosition]="'right'"
                [label]="T.common.status.one | translate"
                [filterType]="filterTypes.Incident_Status"
                [currentDetailsItem]="incidentItem"
                [canEdit]="canEdit && !hasIncompleteStarredChecklists"
                [canEditMessage]="T.control.all_starred_checklists_must_be_completed_before_closing | translate"
                (onIncidentStatusFilterUpdated)="onStatusChanged($event)"
                [statusBars]="false"
                [appliedFilters]="incidentItem?.filters"
              >
              </app-details-dropdown>

              @if(incidentItem.startTime && incidentItem.status !== incidentStatusesEnum.Closed) {
                <div class="separator"></div>
                <div class="opened-days" [style.background-color]="openedDaysColor">
                    {{ getDayDifferenceText }}
                </div>
              }

            </section>
            <section class="right-section">
              <div class="ref-code">ID: {{incidentItem.id}}</div>
            </section>
          </div>

          <div class="bottom">
            <section class="title-wrapper">
              <app-simple-editable-field
                [input]="incidentItem.title"
                [canEdit]="canEdit"
                [fontSize]="20"
                [lineClamp]="2"
                (onUpdate)="onTitleUpdate($event)"
              ></app-simple-editable-field>
            </section>
            <section class="headline-status-wrapper">
              <app-headline-status
                [object]="incidentItem"
                (onChanged)="onHeadlineStatusUpdated($event)"
              ></app-headline-status>
            </section>
            <section class="desctipion-wrapper">
              <app-details-text-area
                [input]="incidentItem.description ? incidentItem.description : ''"
                [label]="T.defaultLocalizations.description.one | translate"
                [placeholder]="T.common.add_description | translate"
                [canEdit]="canEdit"
                (update)="updateDescription($event)"
              ></app-details-text-area>
            </section>
            <section class="additional-data">
              <div class="row details-row">
                <div class="col-2 details-label pe-0">{{ T.common.created | translate }}</div>
                <div class="col-10 details-value">
                  {{ incidentItem.created | date : 'dd/MM/yyyy HH:mm' }}
                </div>
              </div>
              <div class="row details-row">
                <div class="col-2 details-label pe-0">{{ T.defaultLocalizations.owner.many | translate }}</div>
                <div class="col-10 details-value">
                  {{ownerNames}}
                </div>
              </div>
            </section>
          </div>
        </main>
        <aside>
          <app-accordion-details [collapsing]="false"  (onItemIndexClicked)="onItemIndexClicked($event)">
            <app-accordion-item-details [title]="T.common.details | translate" [expanded]="false">
              <ng-template accordionContent>
                <div class="details-container">
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">
                      {{ T.common.category.one | translate }}
                    </div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                      [useLightUpdate]="true"
                      [accordionLozenge]="true"
                      [filters]="incidentItem?.filters"
                      [filterType]="filterTypes.Incident_Category"
                      [canEdit]="canEdit"
                      (onFiltersUpdated)="onFiltersUpdated($event)"
                    >
                    </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.owner.many | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                      [useLightUpdate]="true"
                      [accordionLozenge]="true"
                      [filters]="incidentItem?.filters"
                      [filterType]="filterTypes.Owner"
                      [canEdit]="canEdit"
                      (onFiltersUpdated)="onFiltersUpdated($event)"
                    >
                    </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.user_group.many | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                      [useLightUpdate]="true"
                      [accordionLozenge]="true"
                      [filters]="incidentItem?.filters"
                      [filterType]="filterTypes.User_Group"
                      [canEdit]="canEdit"
                      (onFiltersUpdated)="onFiltersUpdated($event)"
                    >
                    </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ objectTypes.Department | localiseObjectType : true }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                      [useLightUpdate]="true"
                      [accordionLozenge]="true"
                      [filters]="incidentItem?.filters"
                      [filterType]="filterTypes.Department"
                      [canEdit]="canEdit"
                      (onFiltersUpdated)="onFiltersUpdated($event)"
                    >
                    </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.calendar.starts | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-datepicker
                        [accordionLozenge]="true"
                        [range]="true"
                        [timePicker]="true"
                        [includeTime]="true"
                        [canEdit]="canEdit"
                        [date]="this.incidentItem.startTime"
                        [endDate]="this.incidentItem.endTime"
                        [maxDate]="this.incidentItem.endTime"
                        [label]="(T.defaultLocalizations.start_time.one | translate) + ':'"
                        (datesChanged)="onDatesChanged($event)">
                      </app-details-datepicker>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.lozenges_label.date_related.due | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-datepicker
                        [accordionLozenge]="true"
                        [range]="true"
                        [timePicker]="true"
                        [includeTime]="true"
                        [canEdit]="!canEdit"
                        [date]="this.incidentItem.endTime"
                        [startDate]="this.incidentItem.startTime"
                        [endDate]="this.incidentItem.endTime"
                        [minDate]="this.incidentItem.startTime"
                        [label]="(T.defaultLocalizations.end_time.one | translate) + ':'"
                        (datesChanged)="onDatesChanged($event)">
                      </app-details-datepicker>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.common.privacy.one | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        [filters]="incidentItem?.filters"
                        [filterType]="filterTypes.Privacy_Status"
                        [canEdit]="canEdit"
                        [singleSelect]="true"
                        [accordionLozenge]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)">
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{ T.defaultLocalizations.event.one | translate }}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                        [useLightUpdate]="true"
                        [accordionLozenge]="true"
                        [filters]="incidentItem?.filters"
                        [filterType]="filterTypes.Event"
                        [canEdit]="canEdit"
                        [singleSelect]="true"
                        (onFiltersUpdated)="onFiltersUpdated($event)"
                      >
                      </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{T.defaultLocalizations.reported_by.one | translate}}</div>
                    <div class="col-7 details-value">
                      <app-details-editable-field
                        [input]="incidentItem.reportedBy ? incidentItem.reportedBy : ''"
                        [header]="T.defaultLocalizations.reported_by.one | translate"
                        [type]="editableFieldTypes.Text"
                        [canEdit]="canEdit"
                        (onUpdate)="updateReportedBy($event)"
                        [maxLength]="40"
                        [placeholder]="T.defaultLocalizations.reported_by.one | translate"
                        [accordionLozenge]="true"
                      ></app-details-editable-field>
                    </div>
                  </div>
                  <div class="details-row row">
                    <div class="col-5 details-label pe-0">{{T.defaultLocalizations.incident_channel.one | translate}}</div>
                    <div class="col-7 details-value">
                      <app-details-lozenge
                      [useLightUpdate]="true"
                      [accordionLozenge]="true"
                      [filters]="incidentItem?.filters"
                      [filterType]="filterTypes.Incident_Channel"
                      [canEdit]="canEdit"
                      [singleSelect]="true"
                      (onFiltersUpdated)="onFiltersUpdated($event)"
                    >
                    </app-details-lozenge>
                    </div>
                  </div>
                  <div class="details-row row">
                    <app-details-tags
                      [accordionTagTitleClass]="'col-5'"
                      [accordionTagValueClass]="'col-7'"
                      [canEdit]="canEdit"
                      [accordionLozenge]="true"
                      [filters]="incidentItem.filters"
                      [tagGroupObjectType]="objectTypes.IncidentItem"
                      [useLightUpdate]="true"
                      (onFiltersUpdated)="onFiltersUpdated($event)"
                    ></app-details-tags>
                  </div>
                </div>
              </ng-template>
            </app-accordion-item-details>
            <app-accordion-item-details [title]="'History'" [expanded]="false">
              <ng-template accordionContent>
                <div *ngIf="historyExpanded" class="history-feed-wrapper">
                  <app-history-feed-new
                    [isModal]="true"
                    [canEdit]="canEdit"
                    [showInput]="true"
                    [objectId]="incidentItem.id"
                    [objectType]="objectType"
                    [showHeader]="false"
                    [singleItemHistory]="true"
                    [childHubAccountId]="incidentItem.accountId"
                  >
                  </app-history-feed-new>
                </div>
              </ng-template>
            </app-accordion-item-details>
          </app-accordion-details>
        </aside>
      </div>
    </app-modal-body>
    <app-modal-footer>
      <div class="footer-wrapper">
        <app-button-rectangle
          [text]="'View Full Details'"
          [size]="'medium'"
          [buttonTheme]="'primary'"
          (clicked)="navigateToDetaislPage()"
        ></app-button-rectangle>
      </div>
    </app-modal-footer>
  </app-modal>
}
