import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ComponentEventEmitter {
  private fullPageSpaceRequested = new Subject<boolean>();
  fullPageSpaceRequested$ = this.fullPageSpaceRequested.asObservable();

  private hideHeaderRequested = new Subject<boolean>();
  hideHeaderRequested$ = this.hideHeaderRequested.asObservable();

  broadcastFullPageSpaceRequested(state: boolean) {
    this.fullPageSpaceRequested.next(state);
  }

  broadcastHideHeaderRequested(state: boolean) {
    this.hideHeaderRequested.next(state);
  }
}
