export namespace EnumUtilities {
  export function names(T) {
    return Object.keys(T)
      .filter((type) => isNaN(<any>type) && type !== 'values')
      .map(function (e) {
        return e.replace('_', ' ');
      });
  }
  export function values(T) {
    const a: any = [];
    for (const enumMember in T) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push(T[enumMember]);
      }
    }
    return a;
  }
  export function items(T): { key: typeof T; value: string }[] {
    const a: { key: typeof T; value: string }[] = [];
    for (const enumMember in T) {
      if (!isNaN(parseInt(enumMember, 10))) {
        const memberAsUnknown = +enumMember as unknown;
        a.push({ key: memberAsUnknown as typeof T, value: T[enumMember].replace(/_/g, ' ') });
      }
    }
    return a;
  }
}
