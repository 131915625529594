import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, skip, Subscription, switchMap } from 'rxjs';
import { FilterTypes } from '../enums/filterTypes';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { FilterUtilities } from '../utilities/filter.utilities';
import { FixedEventFiltersService } from './fixed-event-filters.service';
import { FixedIncidentChannelFiltersService } from './fixed-incident-channel-filters.service';
import { FixedZoneFiltersService } from './fixed-zone-filters.service';

@Injectable({ providedIn: 'root' })
export class FixedHeaderFiltersService implements OnDestroy {
  private readonly subscriptions = new Subscription();

  private readonly _currentFilters = new BehaviorSubject<FilterViewModel[]>([]);
  readonly currentFilters$ = this._currentFilters.asObservable();
  get currentFilters(): FilterViewModel[] {
    return this._currentFilters.value.slice();
  }

  readonly filtersChanged$ = this.currentFilters$.pipe(skip(1));

  constructor(
    private readonly fixedEventFiltersService: FixedEventFiltersService,
    private readonly fixedZoneFiltersService: FixedZoneFiltersService,
    private readonly fixedIncidentChannelFiltersService: FixedIncidentChannelFiltersService
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  init(): Observable<undefined> {
    return forkJoin([
      this.fixedEventFiltersService.init(),
      this.fixedZoneFiltersService.init(),
      this.fixedIncidentChannelFiltersService.init(),
    ]).pipe(
      switchMap(() => {
        this.subscriptions.add(
          combineLatest([this.fixedEventFiltersService.fixedFilter$, this.fixedEventFiltersService.isFilterActive$]).subscribe(
            ([filter, isActive]) => {
              const newFilters = filter !== undefined && isActive ? [filter] : [];
              this.replaceAllByType(newFilters, this.fixedEventFiltersService.filterType);
            }
          )
        );

        this.subscriptions.add(
          combineLatest([this.fixedZoneFiltersService.fixedFilter$, this.fixedZoneFiltersService.isFilterActive$]).subscribe(
            ([filter, isActive]) => {
              const newFilters = filter !== undefined && isActive ? [filter] : [];
              this.replaceAllByType(newFilters, this.fixedZoneFiltersService.filterType);
            }
          )
        );

        this.subscriptions.add(
          combineLatest([
            this.fixedIncidentChannelFiltersService.fixedFilter$,
            this.fixedIncidentChannelFiltersService.isFilterActive$,
          ]).subscribe(([filter, isActive]) => {
            const newFilters = filter !== undefined && isActive ? [filter] : [];
            this.replaceAllByType(newFilters, this.fixedIncidentChannelFiltersService.filterType);
          })
        );

        return of(undefined);
      })
    );
  }

  private setCurrentFilters(filters: FilterViewModel[]): void {
    const oldFilters = this.currentFilters.sort((a) => a.filterType);
    const newFilterJSON = JSON.stringify(filters.filter((f) => f.filterValue.toString() !== '0').sort((a) => a.filterType));
    if (JSON.stringify(oldFilters) !== JSON.stringify(newFilterJSON)) {
      const newFilters = JSON.parse(newFilterJSON) as FilterViewModel[];
      this._currentFilters.next(newFilters);
    }
  }

  private replaceAllByType(newFilters: FilterViewModel[], filterType: FilterTypes): void {
    const filtersByType = this.currentFilters.filter((f) => f.filterType == filterType);
    const newFilteredFilters = newFilters.filter((f) => f.filterValue != '0');

    if (
      filtersByType.length != newFilteredFilters.length ||
      filtersByType.some((a) => !newFilteredFilters.some((r) => r.filterValue == a.filterValue))
    ) {
      const currentFilters = this.currentFilters.filter((f) => f.filterType !== filterType);
      this.setCurrentFilters(FilterUtilities.MergeFilterArrays(currentFilters, newFilters));
    }
  }
}
