export enum ImageExtensions {
  jpg = 1,
  jpeg = 2,
  gif = 3,
  bmp = 4,
  png = 5,
  svg = 6,
}

export namespace ImageExtensions {
  export function names() {
    return Object.keys(ImageExtensions)
      .filter((type) => isNaN(<any>type) && type !== 'values')
      .map(function (e) {
        return e.replace('_', ' ');
      });
  }
  export function values() {
    const a: any = [];
    for (const enumMember in ImageExtensions) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push(ImageExtensions[enumMember]);
      }
    }
    return a;
  }
  export function items(): { key: ImageExtensions; value: string }[] {
    const a: { key: ImageExtensions; value: string }[] = [];
    for (const enumMember in ImageExtensions) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: ImageExtensions[enumMember].replace(/_/g, ' ') });
      }
    }
    return a;
  }
}
