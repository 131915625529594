import { Component, OnInit, ViewChild } from '@angular/core';
import { DocumentManagerCoreComponent } from '../document-manager-core/document-manager-core.component';

@Component({
  selector: 'app-document-manager-general-settings-mobile',
  templateUrl: './document-manager-general-settings-mobile.component.html',
  styleUrls: ['./document-manager-general-settings-mobile.component.scss'],
})
export class DocumentManagerGeneralSettingsMobileComponent extends DocumentManagerCoreComponent implements OnInit {
  @ViewChild('uploadAction') uploadAction;

  async showUploadOptions() {
    const buttons = []
    if(this.isNativePlatform) {
      buttons.push({
        text: this.translateService.instant(this.T.common.take_a_photo),
        handler: () => {
          this.takePhoto();
        }
      })
    }

    buttons.push(
      {
        text: this.translateService.instant(this. T.common.choose_a_file),
        handler: () => {
          this.openFileUpload();
        }
      },
      {
        text: this.translateService.instant(this.T.common.add_link),
        handler: () => {
          this.addLink();
        }
      },
      {
        text: this.translateService.instant(this.T.common.cancel),
        role: 'cancel',
      }
    )

    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateService.instant(this.T.reports.button_actions),
      buttons: buttons,
    });
    void actionSheet.present();
  }
}
