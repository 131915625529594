import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { UserAgentService } from '../../../services/user-agent.service';

@Component({
  selector: 'app-copy-text-field',
  templateUrl: './copy-text-field.component.html',
  styleUrls: ['./copy-text-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyTextFieldComponent implements OnInit, OnDestroy {
  @Input() successMessage = 'URL Copied';
  @Input() textValue: string;

  subscriptions = new Subscription();
  isMobile: boolean;

  constructor(
    private readonly alertService: AlertService,
    private readonly userAgentService: UserAgentService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.userAgentService.isXSmall$.subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  copyMessage() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.textValue;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    void this.alertService.success(this.successMessage);
  }
}
