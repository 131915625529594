import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[hrefOut]',
  host: {
    '(click)': 'onClick()',
  },
})
export class HrefOutDirective {
  @Input('hrefOut') url: string;

  onClick(): void {
    window.location.href = this.url;
  }
}
