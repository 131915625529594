<app-filters-mobile-header
  *ngIf="mobile"
  [title]="filterTypeSelectorViewModel.filterType | localiseFilterType"
  [useClear]="false"
  [clearDisabled]="true"
  (cancel)="keyboardCancel ? onKeyboardCancel() : onEmitCancel()"
></app-filters-mobile-header>

<div class="filters-header" *ngIf="!mobile">
  <app-modal-header [title]="filterTypeSelectorViewModel.filterTypeText" (closed)="keyboardCancel ? onKeyboardCancel() : onEmitCancel()"></app-modal-header>
</div>

<div class="filters-date-radio">
  <div class="filters-date-radio-option">
    <app-radio-button
      [checked]="relative"
      (checkedChanged)="isRelativeChecked(true)"
      [text]="'Relative Dates'"
    ></app-radio-button>
  </div>
  <div class="filters-date-radio-option">
    <app-radio-button
      [checked]="!relative"
      (checkedChanged)="isRelativeChecked(false)"
      [text]="'Fixed Dates'"
    ></app-radio-button>
  </div>
</div>

<ng-template #relativeTemplate>
  <div class="filters-date-relative-range-container">
    <app-dropdown-option
    *ngFor="let relativeOption of filterTypeSelectorViewModel.dateRangeOptions"
    [value]="relativeOption"
    [selected]="isSelected(relativeOption)"
    (selectionChange)="onRelativeDateSelected(relativeOption)"
    >
      {{sanitizeText(relativeOption)}}
    </app-dropdown-option>
  </div>
</ng-template>

<ng-container *ngIf="!dateRange">
  <ng-container *ngIf="!relative">
    <app-text-field
      [placeholder]="'Date'"
      [useNgDateMask]="true"
      [format]="dateFormat"
      [separator]="'/'"
      [(ngModel)]="dateModel"
      (ngModelChange)="onModelChange()"
    ></app-text-field>
    <app-ngdatepicker [date]="date" [component]="true" (dateChanged)="onDateChanged($event)"></app-ngdatepicker>
  </ng-container>
  <ng-container *ngIf="relative">
    <ng-template [ngTemplateOutlet]="relativeTemplate"></ng-template>
  </ng-container>
</ng-container>
<ng-container *ngIf="dateRange">
  <ng-container *ngIf="!relative">
    <app-ng-range-datepicker
      [component]="true"
      [showHeader]="false"
      [showFooter]="false"
      [useRelativeHeight]="true"
      [startDate]="fromDate"
      [endDate]="toDate"
      (startDateChanged)="onFromDateChanged($event)"
      (endDateChanged)="onToDateChanged($event)"
    ></app-ng-range-datepicker>
    <ng-container *ngIf="showTimePicker">
      <div class="wt_timepicker_desktop-body">
        <div class="timepicker" [ngClass]="timePickerDisabled ? 'disabled' : ''">
          <div class="time-title">Start Time:</div>
          <ngx-timepicker-field
            [controlOnly]="true"
            [ngModel]="timeStartString"
            [format]="24"
            (ngModelChange)="onStartTimeChange($event)"
          ></ngx-timepicker-field>
        </div>
        <div class="timepicker" [ngClass]="timePickerDisabled ? 'disabled' : ''">
          <div class="time-title">Due Time:</div>
          <ngx-timepicker-field
            [controlOnly]="true"
            [ngModel]="timeDueString"
            [format]="24"
            (ngModelChange)="onDueTimeChange($event)"
          ></ngx-timepicker-field>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="relative">
    <ng-template [ngTemplateOutlet]="relativeTemplate"></ng-template>
  </ng-container>

  <app-filters-desktop-footer
    *ngIf="!mobile"
    [useCancelApplyButtons]="true"
    [useClearAllButton]="false"
    [applyDisabled]="!hasAnyChanges"
    (cancel)="keyboardCancel ? onKeyboardCancel() : onEmitCancel()"
    (apply)="onApply()"
  ></app-filters-desktop-footer>

  <app-filters-mobile-footer
    *ngIf="mobile"
    [applyDisabled]="!hasAnyChanges"
    (apply)="onApply()"
  ></app-filters-mobile-footer>
</ng-container>
