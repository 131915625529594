import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgBlockScreenDirective } from '../../../directives/ngBlockScreen.directive';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectEventEmitters } from '../../../events/object.events';
import { Constants } from 'src/app/modules/shared/models/constants';
import { Employee } from '../../../models/employee';
import { EmployeeCustomFilterViewModel } from '../../../models/filter/employeeCustomFilterViewModel';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { ModuleService } from '../../../services/module.service';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { FiltersAddComponent } from '../filters-add/filters-add.component';
import { FiltersFavoriteComponent } from '../filters-favorite/filters-favorite.component';
import { FiltersInlineEditContainerComponent } from '../filters-lozenge-inline-edit/filters-inline-edit-container.component';
import { FilterLozengeComponent } from '../filters-lozenge/filters-lozenge.component';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { LocalisationService } from '../../../services/localisation.service';
import { Account } from '../../../models/account';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { HeaderFiltersCachingService } from '../../../services/header-filters-caching.service';
import { CustomFiltersService } from '../../../services/custom-filters.service';
import { HeaderFiltersTypeService } from '../../../services/header-filters-type.service';
import { HeaderFiltersService } from '../../../services/header-filters.service';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() hidePartialBasicFilters: boolean = false;

  @ViewChildren('desktopFilterLozenge') desktopFilterLozenges: QueryList<FilterLozengeComponent>;
  @ViewChildren('desktopTagFilterLozenge') desktopTagFilterLozenges: QueryList<FilterLozengeComponent>;
  @ViewChildren('hiddenDesktopFilterLozenge') hiddenDesktopFilterLozenges: QueryList<FilterLozengeComponent>;
  @ViewChildren('mobileFilterLozenge') mobileFilterLozenges: QueryList<FilterLozengeComponent>;
  @ViewChild(NgBlockScreenDirective) blockScreenDirective: NgBlockScreenDirective;

  public isFiltersLoading: boolean = true;

  private filterEditContainerBsModalRef: BsModalRef;
  private addFilterSelectionModalRef: BsModalRef;
  private favouritesFiltersModalRef: BsModalRef;

  employee: Employee;
  account: Account;
  filters: FilterViewModel[] = [];
  appliedFilters: FilterViewModel[] = [];
  favoriteFilters: EmployeeCustomFilterViewModel[] = [];
  suggestedFilters: EmployeeCustomFilterViewModel[] = [];
  filterTypeSelectorViewModels: FilterTypeSelectorViewModel[] = [];
  filterTypeSelectorViewModel: FilterTypeSelectorViewModel;
  filterTypesEnum = FilterTypes;
  private readonly subscriptions = new Subscription();
  selectedTagGroup: FilterViewModel;
  selectedLozengeTagGroup: FilterViewModel;
  tagFilterTypeSelectorViewModel: FilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
  showOnlyActive: boolean = false;
  expanded: boolean;
  mobile: boolean;

  desktopFiltersAddContainerVisible: boolean = false;
  desktopFiltersAddFavoriteContainerVisible: boolean = false;
  mobileFiltersAddContainerVisible: boolean = false;
  mobileFiltersAddFavoriteContainerVisible: boolean = false;
  currentObjectTypes: ObjectTypes[] = [];
  filteredFilters = new Map<string, FilterViewModel[]>();
  hiddenFilterTypes = new Map<string, FilterViewModel[]>();
  filteredTagsFilters = new Map<string, FilterViewModel[]>();
  hiddenTagsFilterTypes = new Map<string, FilterViewModel[]>();
  public readonly T = T;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly headerFiltersService: HeaderFiltersService,
    private readonly headerFiltersTypeService: HeaderFiltersTypeService,
    private readonly customFiltersService: CustomFiltersService,
    private readonly moduleService: ModuleService,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private readonly localisationService: LocalisationService,
    private readonly bsModalService: BsModalService,
    private readonly headerFiltersCachingService: HeaderFiltersCachingService,
    private readonly translateService: TranslateService,
    private readonly router: Router
  ) {}

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    this.mobile = event.target.innerWidth <= Constants.xs;
  }

  ngOnInit() {
    this.mobile = window.innerWidth <= Constants.xs;
    this.employee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();

    this.tagFilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
    this.tagFilterTypeSelectorViewModel.filterType = FilterTypes.Tag;
    this.tagFilterTypeSelectorViewModel.filterTypeText = 'Tag';
    this.tagFilterTypeSelectorViewModel.filterSelectorType = FilterSelectorTypes.Dropdown;

    this.currentObjectTypes = this.moduleService.currentObjectTypes;

    if (this.currentObjectTypes.length) {
      this.showOnlyActive = this.moduleService.isIncidentsModule;

      this.isFiltersLoading = true;

      const filters = this.allowedFiltersService.getCachedAllAllowedFilters();

      this.subscriptions.add(
        forkJoin([this.initFilterTypes(), this.initFavoriteFilters(), this.initSuggestedFilters()]).subscribe(
          ([filtType, favFilt, suggFilters]) => {
            this.filters = filters.filter(
              (filter) =>
                !filter.displayForGlobalObjectType ||
                this.moduleService.currentObjectTypes.indexOf(filter.displayForGlobalObjectType) !== -1
            );
            this.filters = FilterUtilities.setFilterText(this.filters, this.localisationService, this.translateService);

            this.filterTypeSelectorViewModels = filtType;
            this.favoriteFilters = favFilt;
            this.suggestedFilters = suggFilters;

            this.isFiltersLoading = false;
            this.changeDetectorRef.markForCheck();
          }
        )
      );
    }

    this.subscriptions.add(
      this.moduleService.objectTypesChanged$
        .pipe(filter((objectTypes) => !this.currentObjectTypes.includes(objectTypes[0])))
        .subscribe(() => {
          let resetFilters = false;
          this.showOnlyActive = this.moduleService.isIncidentsModule;

          const hasRelatedFilters = FilterUtilities.ArrayContainsFiltersForObjectTypes(
            this.appliedFilters,
            this.moduleService.currentObjectTypes
          );
          const hasCommonObjTypes = this.currentObjectTypes.some((item) => this.moduleService.currentObjectTypes.includes(item));

          if (!hasCommonObjTypes && !hasRelatedFilters) {
            resetFilters = true;
          }

          this.isFiltersLoading = true;

          this.currentObjectTypes = this.moduleService.currentObjectTypes;

          if (resetFilters) {
            this.appliedFilters = [];
            this.onFiltersChanged(this.headerFiltersCachingService.currentFilters);
          }

          const filters = this.allowedFiltersService.getCachedAllAllowedFilters();

          this.subscriptions.add(
            forkJoin([this.initFilterTypes(), this.initFavoriteFilters()]).subscribe(([filtType, favFilt]) => {
              this.filters = filters.filter(
                (filter) =>
                  !filter.displayForGlobalObjectType ||
                  this.moduleService.currentObjectTypes.indexOf(filter.displayForGlobalObjectType) !== -1
              );
              this.filters = FilterUtilities.setFilterText(this.filters, this.localisationService, this.translateService);

              this.filterTypeSelectorViewModels = filtType;
              this.favoriteFilters = favFilt;

              this.isFiltersLoading = false;
              this.changeDetectorRef.markForCheck();
            })
          );
        })
    );

    this.initObjectEventEmitters();
    this.onFiltersChanged(this.headerFiltersCachingService.currentFilters);
    this.headerFiltersService.currentFilters$.subscribe((filters) => {
      this.appliedFilters = filters;
      this.filteredFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
      this.filteredTagsFilters = FilterUtilities.GroupTagFiltersByTagGroup(this.filters, this.appliedFilters);
      this.changeDetectorRef.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.initQueryListSubscriptions();
  }

  ngOnDestroy() {
    this.headerFiltersService.setCurrentFilters([]);
    this.subscriptions.unsubscribe();
  }

  public get isHiddenEmpty(): boolean {
    return this.hiddenFilterTypes.size === 0;
  }
  onExpandCollapse(): void {
    this.expanded = !this.expanded;
  }

  onResized() {
    this.changeDetectorRef.detectChanges();
  }

  onAdd(): void {
    if (this.mobile) {
      this.mobileFiltersAddContainerVisible = !this.mobileFiltersAddContainerVisible;
    } else {
      this.desktopFiltersAddContainerVisible = !this.desktopFiltersAddContainerVisible;
      this.changeDetectorRef.detectChanges();
      this.openAddFilterSelectionModal();
      // this.updateBlockScreen(this.filtersAddComponent.elementRef);
    }
  }

  openAddFilterSelectionModal() {
    const initialState = {
      mobile: this.mobile,
      filters: this.filters,
      filterTypeSelectorViewModels: this.filterTypeSelectorViewModels,
    };

    this.addFilterSelectionModalRef = this.bsModalService.show(FiltersAddComponent, {
      initialState,
      class: 'max-width-unset center-transform',
    });

    const addModalSubscription = new Subscription();
    addModalSubscription.add(
      (this.addFilterSelectionModalRef.content.filterAdded as EventEmitter<FilterTypeSelectorViewModel>).subscribe((res) => {
        this.onFiltersTypeAdd(res);
        //this.addFilterSelectionModalRef.hide();
        addModalSubscription.unsubscribe();
      })
    );
    addModalSubscription.add(
      (this.addFilterSelectionModalRef.content.tagGroupSelected as EventEmitter<FilterViewModel>).subscribe((res) => {
        this.onTagGroupSelected(res);
        //this.addFilterSelectionModalRef.hide();
        addModalSubscription.unsubscribe();
      })
    );
    addModalSubscription.add(
      (this.addFilterSelectionModalRef.content.closed as EventEmitter<void>).subscribe(() => {
        this.addFilterSelectionModalRef.hide();
        addModalSubscription.unsubscribe();
      })
    );
  }

  onCloseAdd(): void {
    if (this.mobile) {
      this.mobileFiltersAddContainerVisible = !this.mobileFiltersAddContainerVisible;
    } else {
      this.desktopFiltersAddContainerVisible = !this.desktopFiltersAddContainerVisible;
      this.addFilterSelectionModalRef.hide();
      document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    }
  }

  onAddFavorite(): void {
    if (this.mobile) {
      this.mobileFiltersAddFavoriteContainerVisible = !this.mobileFiltersAddFavoriteContainerVisible;
    } else {
      this.desktopFiltersAddFavoriteContainerVisible = !this.desktopFiltersAddFavoriteContainerVisible;
      this.changeDetectorRef.detectChanges();
      this.openFavoritesModal();
    }
  }

  openFavoritesModal(): void {
    const favouriteFiltersBasedOnAllowedObjectTypes = this.favoriteFilters.filter((favourite) =>
      favourite.filters.every((f) => this.currentObjectTypes.includes(+f.displayForGlobalObjectType))
    );
    const suggestedFiltersBasedOnAllowedObjectTypes = this.suggestedFilters.filter((favourite) =>
      favourite.filters.every((f) => this.currentObjectTypes.includes(+f.displayForGlobalObjectType))
    );

    const initialState = {
      mobile: this.mobile,
      appliedFilters: this.appliedFilters,
      favoriteFilters: favouriteFiltersBasedOnAllowedObjectTypes,
      suggestedFilters: suggestedFiltersBasedOnAllowedObjectTypes,
    };

    this.favouritesFiltersModalRef = this.bsModalService.show(FiltersFavoriteComponent, {
      initialState,
      class: 'max-width-unset center-transform',
    });
    const addModalSubscription = new Subscription();
    addModalSubscription.add(
      (this.favouritesFiltersModalRef.content.favoriteFiltersApplied as EventEmitter<FilterViewModel[]>).subscribe((res) => {
        this.onFavoriteFiltersApplied(res);
        this.favouritesFiltersModalRef.hide();
        addModalSubscription.unsubscribe();
      })
    );
    addModalSubscription.add(
      (this.favouritesFiltersModalRef.content.closed as EventEmitter<void>).subscribe(() => {
        this.favouritesFiltersModalRef.hide();
        addModalSubscription.unsubscribe();
      })
    );
  }

  onCloseAddFavorite(): void {
    if (this.mobile) {
      this.mobileFiltersAddFavoriteContainerVisible = !this.mobileFiltersAddFavoriteContainerVisible;
    } else {
      this.desktopFiltersAddFavoriteContainerVisible = !this.desktopFiltersAddFavoriteContainerVisible;

      document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    }
  }

  onFavoriteFiltersApplied(filters: FilterViewModel[]): void {
    filters = JSON.parse(JSON.stringify(filters));
    const allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
    const mappedFilters = filters.map((currentFilter) => {
      let findedInAllowed = allowedFilters.find(
        (allowedFilter) =>
          currentFilter.filterType == allowedFilter.filterType && currentFilter.filterValue == allowedFilter.filterValue
      );
      if (findedInAllowed) {
        findedInAllowed = JSON.parse(JSON.stringify(findedInAllowed)) as FilterViewModel;
        findedInAllowed.displayForGlobalObjectType = currentFilter.displayForGlobalObjectType;
        findedInAllowed.exclude = currentFilter.exclude;
        findedInAllowed.isPrimary = currentFilter.isPrimary;
        return findedInAllowed;
      }
      return currentFilter;
    });
    this.appliedFilters = FilterUtilities.MergeFilterArrays(this.appliedFilters, mappedFilters);
    this.filteredFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
    this.filteredTagsFilters = FilterUtilities.GroupTagFiltersByTagGroup(this.filters, this.appliedFilters);

    this.headerFiltersService.setCurrentFilters(this.appliedFilters);
    this.desktopFiltersAddFavoriteContainerVisible = false;
    this.changeDetectorRef.markForCheck();
  }

  onFiltersTypeAdd(filterTypeSelectorViewModel: FilterTypeSelectorViewModel): void {
    this.onCloseAdd();

    if (filterTypeSelectorViewModel.filterSelectorType === FilterSelectorTypes.Single_Checkbox) {
      const filter = this.filters.find(
        (f) =>
          f.filterSelectorType === filterTypeSelectorViewModel.filterSelectorType &&
          f.filterType === filterTypeSelectorViewModel.filterType
      );
      filter.displayForGlobalObjectType = filterTypeSelectorViewModel.displayForObjectType;

      this.appliedFilters.push(filter);
      this.appliedFilters = this.appliedFilters.slice();
      this.filteredFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
      this.filteredTagsFilters = FilterUtilities.GroupTagFiltersByTagGroup(this.filters, this.appliedFilters);
      this.headerFiltersService.setCurrentFilters(this.appliedFilters);

      return;
    }
    this.filterTypeSelectorViewModel = filterTypeSelectorViewModel;
    if (!this.mobile) {
      // this.updateBlockScreen(this.filtersInlineEditContainerComponent.elementRef);
    }
    this.openEditContainerModal();

    this.changeDetectorRef.detectChanges();
  }

  openEditContainerModal(filterTypeSelector?: FilterTypeSelectorViewModel, filterTagGroup?: FilterViewModel) {
    const filterSelectorVM = filterTypeSelector ? filterTypeSelector : this.filterTypeSelectorViewModel;
    let filters = FilterUtilities.filterByObjectType(this.filters, filterSelectorVM, this.allowedFiltersService);

    filters = JSON.parse(JSON.stringify(filters));

    if (filterSelectorVM.filterType == FilterTypes.Tag) {
      filters = filters.filter((r) => r.relatedObjectId.toString() == filterTagGroup.filterValue.toString());

      filters.forEach((t) => {
        t.filterDropdownTitle = filterTagGroup.filterText;
        t.displayForGlobalObjectType = filterTagGroup.displayForGlobalObjectType;
      });
    }

    const initialState = {
      mobile: this.mobile,
      filters: filters,
      appliedFilters: FilterUtilities.getAppliedFilters(this.appliedFilters, filterSelectorVM, this.selectedTagGroup),
      localAppliedFilters: JSON.parse(
        JSON.stringify(FilterUtilities.getAppliedFilters(this.appliedFilters, filterSelectorVM, this.selectedTagGroup))
      ),
      canClickOnStar: false,
      showOnlyActive: this.showOnlyActive,
      filterTypeSelectorViewModel: filterSelectorVM,
      singleSelect: FilterUtilities.IsHeaderFilterSingleSelect(
        filterSelectorVM.displayForObjectType,
        filterSelectorVM.filterType
      ),
    };
    const modalRef = this.bsModalService.show(FiltersInlineEditContainerComponent, {
      initialState,
      class: 'max-width-unset center-transform',
    });

    const editContainerSubscription = new Subscription();
    editContainerSubscription.add(
      modalRef.content.filtersUpdated.subscribe((res) => {
        this.onFiltersChanged(res);

        modalRef.hide();

        if (this.bsModalService.getModalsCount() > 0) this.bsModalService.hide();

        editContainerSubscription.unsubscribe();
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  onTagGroupSelected(tagGroup: FilterViewModel) {
    this.onCloseAdd();
    this.selectedTagGroup = tagGroup;
    this.openEditContainerModal(
      FilterUtilities.getFilterTypeSelectorViewModelByFilterType(
        this.filterTypeSelectorViewModels,
        FilterTypes.Tag,
        null,
        tagGroup.displayForGlobalObjectType
      ),
      this.selectedTagGroup
    );
    this.changeDetectorRef.detectChanges();
  }

  onFiltersChanged(filters: FilterViewModel[]): void {
    if (filters[0]?.filterType == FilterTypes.Tag) {
      this.appliedFilters = this.appliedFilters.filter(
        (applFilter) =>
          !filters.some(
            (currFilter) =>
              applFilter.filterType == currFilter.filterType &&
              applFilter.relatedObjectId == currFilter.relatedObjectId &&
              applFilter.displayForGlobalObjectType == currFilter.displayForGlobalObjectType
          )
      );
    } else {
      this.appliedFilters = this.appliedFilters.filter(
        (a) =>
          filters.findIndex(
            (r) =>
              r.filterType == a.filterType &&
              (!r.relatedObjectId || r.relatedObjectId == a.relatedObjectId) &&
              r.dateProperty == a.dateProperty &&
              r.displayForGlobalObjectType == a.displayForGlobalObjectType
          ) < 0
      );
    }

    if (!(filters.length == 1 && filters[0].filterValue == -1)) {
      this.appliedFilters = this.appliedFilters.concat(filters);
    } else {
      if (filters[0].filterType != FilterTypes.Date && filters[0].filterType != FilterTypes.Tag) {
        this.appliedFilters = this.appliedFilters.filter((f) => f.filterType !== filters[0].filterType);
      }
    }

    this.headerFiltersService.setCurrentFilters(this.appliedFilters);

    this.filteredFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
    this.filteredTagsFilters = FilterUtilities.GroupTagFiltersByTagGroup(this.filters, this.appliedFilters);

    this.changeDetectorRef.detectChanges();
  }

  onBlockScreenClicked(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  @HostListener('document:keydown.escape')
  onEscape() {
    this.desktopFiltersAddContainerVisible = false;
    this.desktopFiltersAddFavoriteContainerVisible = false;
    this.filterTypeSelectorViewModel = undefined;
    this.selectedTagGroup = undefined;
    this.selectedLozengeTagGroup = undefined;

    this.desktopFilterLozenges.forEach((filterLozengeComponent) => (filterLozengeComponent.editMode = false));
    this.hiddenDesktopFilterLozenges.forEach((filterLozengeComponent) => (filterLozengeComponent.editMode = false));
    this.mobileFilterLozenges.forEach((filterLozengeComponent) => (filterLozengeComponent.editMode = false));
  }

  onClear(): void {
    this.appliedFilters = [];
    this.expanded = false;
    this.onFiltersChanged([]);
  }

  onTagLozengeClicked(filterLozengeComponent: FilterLozengeComponent, e: Event, tagGroup: FilterViewModel) {
    this.selectedLozengeTagGroup = tagGroup;
    this.onLozengeClicked(filterLozengeComponent, e);
  }

  onLozengeClicked(filterLozengeComponent: FilterLozengeComponent, e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    const { editMode } = filterLozengeComponent;

    filterLozengeComponent.editMode = !filterLozengeComponent.editMode;

    if (editMode) {
      document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    } else {
      this.updateBlockScreen(filterLozengeComponent.filterLozengeElementRef);
    }
  }

  filterTypesTrackByFn(index: number): number {
    return index;
  }

  updateBlockScreen(elementRef: ElementRef<HTMLElement>): void {
    this.blockScreenDirective.ngBlockScreenInstance.show();
    this.blockScreenDirective.blockScreenElementRef = elementRef;
    this.blockScreenDirective.updateInstance();
    this.blockScreenDirective.addElementRefStyles();
  }

  initFilterTypes(): Observable<FilterTypeSelectorViewModel[]> {
    return this.headerFiltersTypeService.getFilterHeaderFilterTypes(this.moduleService.currentObjectTypes);
  }

  initFavoriteFilters(): Observable<EmployeeCustomFilterViewModel[]> {
    return this.customFiltersService.getEmployeeCustomFilters();
  }

  initSuggestedFilters(): Observable<EmployeeCustomFilterViewModel[]> {
    return this.customFiltersService.getSuggestedFilters(this.account.id, this.employee);
  }

  initObjectEventEmitters(): void {
    this.subscriptions.add(
      this.objectEventEmitters.headerFiltersExternalAdded$.subscribe((fl) => {
        this.appliedFilters = this.appliedFilters.filter(
          (s) =>
            fl.findIndex(
              (r) =>
                r.filterType == s.filterType &&
                r.displayForGlobalObjectType == r.displayForGlobalObjectType &&
                s.filterValue == r.filterValue
            ) < 0
        );

        fl.forEach((filter) => {
          let matching = this.filters.find((f) => f.filterType === filter.filterType && f.filterValue == filter.filterValue);
          if (filter.filterType == FilterTypes.Risk_RAG) {
            matching = this.allowedFiltersService
              .getCachedAllowedFiltersByType(FilterTypes.Risk_RAG)
              .find((f) => f.filterType === filter.filterType && f.filterValue == filter.filterValue);
          }
          if (filter.filterType == FilterTypes.Date) {
            matching = filter;
          }
          if (matching) {
            matching.displayForGlobalObjectType = filter.displayForGlobalObjectType;
            this.appliedFilters.push(matching);
          }
        });

        this.appliedFilters = this.appliedFilters.slice();

        this.changeDetectorRef.markForCheck();
        this.filteredFilters = FilterUtilities.GroupFiltersByObject(this.appliedFilters);
        this.headerFiltersService.setCurrentFilters(this.appliedFilters);
      })
    );

    this.subscriptions.add(
      this.objectEventEmitters.headerFiltersExternalCleared$.subscribe((res) => {
        this.onClear();
      })
    );

    this.subscriptions.add(
      this.objectEventEmitters.objectAdded$
        .pipe(filter(({ globalObjectType }) => globalObjectType === ObjectTypes.Favorite_Filters))
        .subscribe(({ model }) => {
          this.favoriteFilters.push(model);
          this.favoriteFilters = this.favoriteFilters.slice();
          this.changeDetectorRef.markForCheck();
        })
    );

    this.subscriptions.add(
      this.objectEventEmitters.objectUpdated$
        .pipe(filter(({ globalObjectType }) => globalObjectType === ObjectTypes.Favorite_Filters))
        .subscribe(({ globalObjectId, model }) => {
          const index = this.favoriteFilters.findIndex((f) => f.id === globalObjectId);

          this.favoriteFilters[index] = model;
          this.favoriteFilters = this.favoriteFilters.slice();
          this.changeDetectorRef.markForCheck();
        })
    );

    this.subscriptions.add(
      this.objectEventEmitters.objectDeleted$
        .pipe(filter(({ globalObjectType }) => globalObjectType === ObjectTypes.Favorite_Filters))
        .subscribe(({ globalObjectId }) => {
          this.favoriteFilters = this.favoriteFilters.filter((f) => f.id !== globalObjectId);
          this.changeDetectorRef.markForCheck();
        })
    );
  }

  getIsSingleSelectHeaderFilter(objectType: ObjectTypes, filterType: FilterTypes): boolean {
    return FilterUtilities.IsHeaderFilterSingleSelect(objectType, filterType);
  }

  public isAccountHubUrl(): boolean {
    return this.router.url.includes('hub');
  }

  private initQueryListSubscriptions(): void {
    this.subscriptions.add(
      this.desktopFilterLozenges.changes.subscribe(() => {
        this.hiddenFilterTypes = FilterUtilities.GroupHiddenFilters(this.desktopFilterLozenges);
        this.hiddenTagsFilterTypes = FilterUtilities.GroupHiddenTagFilters(this.desktopTagFilterLozenges);

        if (
          this.desktopFilterLozenges.length === 0 ||
          (this.hiddenFilterTypes.size === 0 && this.hiddenTagsFilterTypes.size === 0)
        ) {
          this.expanded = false;
        }
      })
    );
  }
}
