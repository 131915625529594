import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { IncidentDetailsItemViewModel } from '../../incidents/viewModels/incidentDetailsItemViewModel';
import { IncidentItemViewModel } from '../../incidents/viewModels/incidentItemViewModel';
import { IncidentListItemViewModel } from '../../incidents/viewModels/incidentListItemViewModel';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { ObjectTypes } from '../enums/objectTypes';
import { IApiService } from '../interfaces/iHttpService';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { LightFilterUpdateModel } from '../models/filter/LightFilterUpdateModel';
import { ValidatedViewModel } from '../viewModels/validatedViewModel';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class IncidentLogsService implements IApiService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Incident/`);
  }

  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly?: boolean
  ): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  updateLight(item: LightFilterUpdateModel) {
    return this.http.post<LightFilterUpdateModel>(`${this.actionUrl}UpdateLight`, item);
  }

  list(filters: FilterViewModel[]): Observable<IncidentListItemViewModel[]> {
    return this.http.post<IncidentListItemViewModel[]>(`${this.actionUrl}GetLogsList`, filters);
  }

  details(incidentId: number): Observable<IncidentDetailsItemViewModel> {
    return this.http.get<IncidentDetailsItemViewModel>(`${this.actionUrl}GetDetails/${incidentId}`);
  }

  update(incident: IncidentItemViewModel): Observable<IncidentItemViewModel> {
    return this.http.post<IncidentItemViewModel>(`${this.actionUrl}Update`, incident);
  }

  delete(incidentId: number): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}Delete/${incidentId}`);
  }

  archive(incidentId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive_New/${incidentId}/${archive}`, null);
  }

  add(incident: IncidentDetailsItemViewModel) {
    if(incident.eventId === 0) {
      incident.eventId = undefined;
    }
    return this.http.post<ValidatedViewModel>(this.actionUrl + 'Add', incident, this.authenticationService.getHttpOptions());
  }

  updateLozenges(incident: IncidentListItemViewModel): Observable<void> {
    throw new Error('Method not implemented.');
  }
}
