import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { Router } from '@angular/router';
import { Observable, Subscription, tap, throwError } from 'rxjs';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { FormArray, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AddModalKeyboardShortcuts } from 'src/app/modules/shared/enums/addModalKeyboardShortcuts.enum';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { RiskDetailsViewModel } from '../../../../risk/models/RiskDetailsViewModel';
import { Employee } from 'src/app/modules/shared/models/employee';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { AddModalButtonOptions } from 'src/app/modules/shared/enums/addModalButtonOptions.enum';
import { RisksService } from '../../../../risk/services/risks.service';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { IconTypes } from 'src/app/modules/shared/types/iconTypes';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { EmployeeRoleTypes } from 'src/app/modules/shared/enums/employees/EmployeeRoleTypes';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { RiskRagHelper } from 'src/app/modules/shared/utilities/risk-rag.utilities copy';
import { RiskRAGMatrixService } from 'src/app/modules/shared/services/risk-rag-matrix.service';
import { RiskLikelihoods } from 'src/app/modules/shared/enums/risks/riskLikelihoods';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { RiskTypes } from 'src/app/modules/risk/enums/riskTypes';
import { RiskStatusTypes } from 'src/app/modules/risk/enums/riskStatusTypes';
import { RiskStrategyTypes } from 'src/app/modules/risk/enums/riskStrategyTypes';
import { ThreadedBead } from '../../../../shared/models/threadedBead';
import { ButtonSizes } from '../../../../shared/enums/styles/buttonSizes.enum';
import { ButtonThemes } from '../../../../shared/enums/styles/buttonThemes.enum';
import { catchError } from 'rxjs/operators';
import { RiskImpact } from '../../../../risk/enums/riskImpact';
import { AccountSettingTypes } from 'src/app/modules/shared/enums/accountSettingTypes';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';

@Component({
  selector: 'app-risk-add-modal',
  templateUrl: './risk-add-modal.component.html',
  styleUrls: ['./risk-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RiskAddModalComponent implements OnInit, OnDestroy {
  shouldRedirect: boolean = true;

  public risk: RiskDetailsViewModel = new RiskDetailsViewModel();
  public filters: FilterViewModel[] = [];
  public actionGroupFilters: FilterViewModel[][] = [];
  public userGroupsFilters: FilterViewModel[][] = [];
  public filterTypes = FilterTypes;
  public isLoading = false;
  public isHiddenOptionVisible = false;
  private mobileMaxWidth = Constants.sm;
  public isMobile: boolean = window.innerWidth < this.mobileMaxWidth;
  public selectedRiskType: RiskTypes = RiskTypes.Risk;
  public form: FormGroup;
  public dateReseter = false;
  public addModalButtonOptions = AddModalButtonOptions;
  public buttonOptions = AddModalButtonOptions.getOptions(this.translateService);
  public keyboardShortcuts = AddModalKeyboardShortcuts.getKeyShortsAsObj(this.translateService);
  public editableFieldTypes = EditableFieldTypes;
  public likelihoodValue = RiskLikelihoods.Highly_Unlikely;
  public impactValue = RiskImpact.Negligible.valueOf();
  public statusValue = 0;
  public privacyValue = 0;
  public ownerValue = null;
  public selectedRisk: FilterViewModel

  public excludeDropdownValuesArr = [];
  public exludeRiskStrategyArr = [];
  public readonly riskTypes = RiskTypes;
  public triggerErrors = false;
  public riskStrategyValue = 0;
  public riskIdentifiedByValue = 0;
  public validationErrors: string[];
  ragDescription: string;
  ragColour: string;

  public initialSelectedProject: FilterViewModel;
  public initialSelectedType;
  public hasRiskManagerPermission = false;
  public exludeRiskStatuses: number[] = [];
  svg = IconUtilities.getSvgForIconType(IconTypes.Risk);

  private isTouched = false;
  private allowedFilters: FilterViewModel[];
  private subscriptions = new Subscription();
  public employee: Employee;
  public localisedDepartment: string = 'Department';
  objectTypes = ObjectTypes;
  public readonly T = T;
  localisedImpact = 'Impact';
  today: string;
  currentStep: number = 1;
  isAction: boolean = true;
  usedPreDefinedRisk: boolean = false;
  useRiskSearch = false;

  public beads: ThreadedBead[] = [
    { number: 1, description: 'Details', active: true, completed: false },
    { number: 2, description: 'Assign', active: false, completed: false },
    { number: 3, description: 'Actions', active: false, completed: false }
  ];

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    this.isMobile = window.innerWidth < this.mobileMaxWidth;
    this.changeDetectorRef.detectChanges();
  }

  constructor(
    private bsModalRef: BsModalRef,
    private router: Router,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private allowedFiltersService: AllowedFiltersService,
    private authenticationService: AuthenticationService,
    private riskService: RisksService,
    private readonly localisationService: LocalisationService,
    private readonly riskRAGMatrixService: RiskRAGMatrixService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators,
    private readonly accountSettingsService: AccountSettingsService
  ) {}

  ngOnInit(): void {
    this.today = new Date().toISOString();
    this.localisedImpact = this.localisationService.localiseFilterType(FilterTypes.Risk_Impact);
    this.isMobile = window.innerWidth < this.mobileMaxWidth;
    this.employee = this.authenticationService.getCurrentEmployee();

    this.hasRiskManagerPermission =
      EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Risk_Manager) || EmployeeUtil.IsAdmin(this.employee);
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);
    if (!this.hasRiskManagerPermission) {
      this.exludeRiskStatuses = [RiskStatusTypes.Open, RiskStatusTypes.Closed];
    }

    this.subscriptions.add(
      this.accountSettingsService.getAccountSettingByType(AccountSettingTypes.Enable_Risk_Search).subscribe((res) => {
        this.useRiskSearch = res.value === "1";
      })
    )

    this.initNewForm();

    this.subscriptions.add(
      this.form.valueChanges.subscribe((res) => {
        this.isTouched = true;
      })
    );

    this.initAllowedFilters();

    if (!this.initialSelectedType) {
      this.selectType(this.riskTypes.Risk);
    } else {
      this.selectType(this.initialSelectedType);
    }

    this.setRAGInformation();
  }

  initNewForm() {
    this.form = this.fb.group({
      riskTitle: [
        '',
        {
          validators: [
            Validators.required,
            Validators.maxLength(250),
            this.wtValidators.title(),
            this.wtValidators.restrictedChars([';'])
          ],
          updateOn: 'blur'
        }
      ],
      riskDescription: ['', Validators.maxLength(2000)],
      actionGroups: this.fb.array([this.createActionGroup()]),
      owner: [null],
    });
  }

  get actionGroups(): FormArray {
    return this.form.get('actionGroups') as FormArray;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectType(type: RiskTypes) {
    this.exludeRiskStrategyArr = [];

    switch (type) {
      case RiskTypes.Risk:
      {
        this.selectedRiskType = RiskTypes.Risk;
        this.likelihoodValue = RiskLikelihoods.Highly_Unlikely;
        this.setLikelihoodFilter(RiskLikelihoods.Highly_Unlikely);
        this.excludeDropdownValuesArr = [RiskLikelihoods.Issue];
        this.changeDetectorRef.detectChanges();
        this.riskStrategyValue = RiskStrategyTypes.Mitigate;
        this.svg = IconUtilities.getSvgForIconType(IconTypes.Risk, 24, true);
      }
        break;
      case RiskTypes.Issue: {
        this.selectedRiskType = RiskTypes.Issue;
        this.likelihoodValue = RiskLikelihoods.Issue;
        this.setLikelihoodFilter(RiskLikelihoods.Issue);
        this.excludeDropdownValuesArr = RiskLikelihoods.numValues().filter((v) => v !== RiskLikelihoods.Issue);
        this.changeDetectorRef.detectChanges();
        this.riskStrategyValue = RiskStrategyTypes.Contingency;
        this.svg = IconUtilities.getSvgForIconType(IconTypes.Issue);
        break;
      }
      case RiskTypes.Opportunity:
      {
        this.selectedRiskType = RiskTypes.Opportunity;
        this.likelihoodValue = RiskLikelihoods.Highly_Unlikely;
        this.setLikelihoodFilter(RiskLikelihoods.Highly_Unlikely);
        this.excludeDropdownValuesArr = [RiskLikelihoods.Issue];
        this.changeDetectorRef.detectChanges();
        this.riskStrategyValue = RiskStrategyTypes.Exploit;
        this.svg = IconUtilities.getSvgForIconType(IconTypes.Opportunity);
      }
        break;
      default:
        break;
    }
  }


  get getSelectedRiskName() {
    return this.localisationService.localise(RiskTypes[this.selectedRiskType]);
  }

  get getDueDate() {
    if (this.risk.dueDate) {
      return this.risk.dueDate;
    }
    return '';
  }

  setRAGInformation() {
    this.risk.rag = this.riskRAGMatrixService.getRAGFromImpactAndLikelihood(this.impactValue, this.likelihoodValue);
    this.ragDescription = this.localisationService.localiseFilterValueByFilterType(this.risk.rag, this.filterTypes.Risk_RAG) + " Risk";
    this.ragColour = RiskRagHelper.getRAGColourHexFromValue(this.risk.rag);
    this.changeDetectorRef.markForCheck();
  }

  setDueDate(dateAsIsoString: string) {
    this.risk.dueDate = dateAsIsoString;
    this.isTouched = true;
  }

  setActionDueDate(event: any, index: number) {
    const actionGroup = this.actionGroups.at(index) as FormGroup;
    if (actionGroup && actionGroup.get('dueDate')) {
      actionGroup.get('dueDate').setValue(event);
    }
    this.changeDetectorRef.detectChanges();
  }

  setAccountForCurrentOwner(isAction?: boolean, index?: number) {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (s: any) => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter !== null) {
        if (this.filters.indexOf(empFilter) < 0) {
          this.filters = this.filters.slice();
          this.filters.push(empFilter);
        }
        if (isAction) {
          const actionGroup = this.actionGroups.at(index) as FormGroup;
          if (actionGroup) {
            let ownersField = actionGroup.get('filters').value as FilterViewModel[];
            if (!Array.isArray(ownersField)) {
              ownersField = [];
            }
            if (!ownersField.some(f => f.filterValue === empFilter.filterValue)) {
              ownersField = [...ownersField, empFilter];
            }
            actionGroup.get('filters').setValue(ownersField);
            this.actionGroupFilters[index] = ownersField;
          }
        }
      }
    }
    this.ownerValue = this.filters.find((f) => f.filterType === FilterTypes.Owner).filterValue;

    this.changeDetectorRef.markForCheck();
  }

  setLikelihoodFilter(likelihood: RiskLikelihoods) {
    const filters = this.filters.filter((f) => f.filterType != FilterTypes.Risk_Likelihood);
    const newLikelihoodFilter = this.allowedFiltersService
      .getCachedAllowedFiltersByType(FilterTypes.Risk_Likelihood)
      .find((f) => f.filterValue == likelihood);
    this.filters.filter((x) => x.filterType == FilterTypes.Risk_Likelihood);
    this.filters = [...filters, newLikelihoodFilter];
  }

  setMyDepartment() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue.toString() === this.employee.departmentId.toString()
      );
      if (!this.filters.some((x) => x.id == empFilter.id)) {
        if (empFilter && this.filters.indexOf(empFilter) < 0) {
          this.filters = this.filters.slice();
          this.filters.push(empFilter);
        }
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  handleAction(action: string) {
    this.isLoading = true;

    this.subscriptions.add(
      this.handleSubmit().subscribe({
        next: (actionResult: ValidatedViewModel) => {
          const actionErrors = actionResult?.errorList || [];
          if (actionErrors.length > 0) {
            this.validationErrors = actionErrors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          }
          else {
            this.isLoading = false;
            void this.alertService.success(
              this.translateService.instant(T.common.item_added, { item: this.localiseRiskType(this.selectedRiskType) })
            );
            if (this.shouldRedirect) {
              const contextRoute = RiskTypes[this.selectedRiskType].toLowerCase();
              void this.router.navigate([`v2/risk/risks-issues/${contextRoute}/${this.selectedRisk?.id}`]);
            }
            this.changeDetectorRef.detectChanges();
            this.closeModal();
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.changeDetectorRef.markForCheck();
          return throwError(err);
        }
      })
    )
  }

  handleFilterChange(filters: FilterViewModel[]) {
    this.filters = filters;

    // Use this aproach because afterViewInit is triggered before emmiting the initial filters.
    // 4 are the initial filters, so thats the hacky way instead of AfterViewInit
    if (filters.length > 4) {
      this.isTouched = true;
    }

    const riskStatusValue = filters.find((f) => f.filterType === FilterTypes.Risk_Status)
    const riskImpactValue = filters.find((f) => f.filterType === FilterTypes.Risk_Impact);
    const riskProbabilityValue = filters.find((f) => f.filterType === FilterTypes.Risk_Likelihood);
    const riskPrivacyValue = filters.find((f) => f.filterType === FilterTypes.Risk_Privacy_Status);
    const riskOwnerValue = filters.find((f) => f.filterType === FilterTypes.Owner);
    const strategyValue = filters.find(f => f.filterType === FilterTypes.Risk_Strategy);
    const identifiedByValue = filters.find(f => f.filterType === FilterTypes.Identified_By);

    if (riskPrivacyValue?.filterText) {
      this.privacyValue = filters.find((f) => f.filterType === FilterTypes.Risk_Privacy_Status).filterValue;
    }
    if (riskProbabilityValue?.filterText) {
      this.likelihoodValue = filters.find((f) => f.filterType === FilterTypes.Risk_Likelihood).filterValue;
    }
    if (riskImpactValue?.filterText) {
      this.impactValue = filters.find((f) => f.filterType === FilterTypes.Risk_Impact).filterValue;
    }
    if (riskStatusValue?.filterText) {
      this.statusValue = filters.find((f) => f.filterType === FilterTypes.Risk_Status).filterValue;
    }
    if (riskOwnerValue?.filterText) {
      this.ownerValue = filters.find((f) => f.filterType === FilterTypes.Owner).filterValue;
    }
    if (strategyValue?.filterText) {
      this.riskStrategyValue = filters.find((f) => f.filterType === FilterTypes.Risk_Strategy).filterValue;
    }
    if (identifiedByValue?.filterText) {
      this.riskIdentifiedByValue = filters.find((f) => f.filterType === FilterTypes.Identified_By).filterValue;
    }

    this.changeDetectorRef.detectChanges();
    this.setRAGInformation();
  }


  handleActionFilterChange(event: FilterViewModel[] | any, index: number, field: string) {
    const actionGroup = this.actionGroups.at(index) as FormGroup;

    if (actionGroup && actionGroup.get(field)) {
      let relevantFilters: FilterViewModel[] = Array.isArray(event)
        ? event.filter(f => f.filterType === this.getFilterType(field))
        : [event];

      if (relevantFilters.length > 0) {
        if (field === 'filters') {
          actionGroup.get(field).setValue(relevantFilters);
        }
        else if (field === 'userGroup') {
          let filtersField = actionGroup.get('filters').value as FilterViewModel[] || [];
          const updatedFilters = [...filtersField, ...relevantFilters];
          const updatedUniqueFilters = Array.from(new Set(updatedFilters.map(filter => JSON.stringify(filter))))
            .map(filter => JSON.parse(filter));

          actionGroup.get('filters').setValue(updatedUniqueFilters);
          actionGroup.get('userGroup').setValue(relevantFilters);
        }
        else {
          actionGroup.get(field).setValue(relevantFilters[0].filterValue || relevantFilters[0].filterValue);
        }
      }
    }

    let ownersField = actionGroup.get('filters').value as FilterViewModel[] || [];
    let userGroupsField = actionGroup.get('userGroup').value as FilterViewModel[] || [];

    ownersField = ownersField.filter(filter =>
      filter.filterType !== event.filterType || filter.filterValue !== event.filterValue
    );
    userGroupsField = userGroupsField.filter(filter =>
      filter.filterType !== event.filterType || filter.filterValue !== event.filterValue
    );

    actionGroup.get('filters').setValue(ownersField.length > 0 ? ownersField : []);
    actionGroup.get('userGroup').setValue(userGroupsField.length > 0 ? userGroupsField : []);
    this.actionGroupFilters[index] = ownersField;
    this.userGroupsFilters[index] = userGroupsField;

    this.changeDetectorRef.detectChanges();
  }

  onObjectDeselected(object: FilterViewModel, index?: number): void {
    const actionGroup = this.actionGroups.at(index) as FormGroup;

    this.filters = this.filters.filter(filter =>
      filter.filterType !== object.filterType ||
      filter.filterValue !== object.filterValue
    );

    const statusFilter = this.filters.find(f => f.filterType === FilterTypes.Risk_Status);
    this.statusValue = statusFilter ? statusFilter.filterValue : 0;

    const strategyFilter = this.filters.find(f => f.filterType === FilterTypes.Risk_Strategy);
    this.riskStrategyValue = strategyFilter ? strategyFilter.filterValue : 0;

    const identifiedByFilter = this.filters.find(f => f.filterType === FilterTypes.Identified_By);
    this.riskIdentifiedByValue = identifiedByFilter ? identifiedByFilter.filterValue : 0;

    const ownerFilter = this.filters.find(f => f.filterType === FilterTypes.Owner);
    this.ownerValue = ownerFilter ? ownerFilter.filterValue : null;

    if (actionGroup?.value.type === object.filterValue) {
      actionGroup.get('type').setValue(null);
    }
    if (actionGroup?.value.status === object.filterValue) {
      actionGroup.get('status').setValue(null);
    }

    let ownersField = actionGroup?.get('filters').value as FilterViewModel[] || [];
    let userGroupsField = actionGroup?.get('userGroup').value as FilterViewModel[] || [];

    ownersField = ownersField.filter(filter =>
      filter.filterType !== object.filterType ||
      filter.filterValue !== object.filterValue
    );
    userGroupsField = userGroupsField.filter(filter =>
      filter.filterType !== object.filterType ||
      filter.filterValue !== object.filterValue
    );

    actionGroup?.get('filters').setValue(ownersField);
    actionGroup?.get('userGroup').setValue(userGroupsField);
    this.actionGroupFilters[index] = ownersField;
    this.userGroupsFilters[index] = userGroupsField;

    if (actionGroup && !ownersField.length) {
      actionGroup.get('filters').reset();
    }
    if (actionGroup && !userGroupsField.length) {
      actionGroup.get('userGroup').reset();
    }

    this.changeDetectorRef.detectChanges();
  }

  getFilterType(field: string): FilterTypes {
    switch (field) {
      case 'status':
        return FilterTypes.Risk_Action_Status;
      case 'type':
        return FilterTypes.Risk_Action_Type;
      case 'filters':
        return FilterTypes.Owner;
      case 'userGroup':
        return FilterTypes.User_Group;
      default:
        throw new Error(`Unknown field: ${field}`);
    }
  }


  handleSubmit(): Observable<any> {
    const actionGroupHasValues = this.actionGroups.controls.some((group: FormGroup) => {
      const title = <string>group.get('title')?.value?.trim();
      const description = <string>group.get('description')?.value?.trim();
      const type = <number>group.get('type')?.value;
      const status = <number>group.get('status')?.value;
      const dueDate = <Date>group.get('dueDate')?.value;
      const filters = <FilterViewModel[]>group.get('filters')?.value;
      const userGroup = <string>group.get('userGroup')?.value;

      return title || description || type || status || dueDate || (filters && filters.length > 0) || userGroup;
    });

    this.risk.filters = this.filters;
    this.risk.title = this.form.controls.riskTitle.value.trim();
    this.risk.description = this.form.controls.riskDescription.value?.trim();
    this.risk.type = this.selectedRiskType;
    this.risk.privacyStatus = this.getFilterValue(FilterTypes.Risk_Privacy_Status);
    this.risk.impact = this.getFilterValue(FilterTypes.Risk_Impact);
    this.risk.likelihood = this.getFilterValue(FilterTypes.Risk_Likelihood);
    this.risk.riskStrategyId = this.getFilterValue(FilterTypes.Risk_Strategy);
    this.risk.ownerId = this.getFilterValue(FilterTypes.Owner);
    this.risk.createdById = this.employee.id;
    this.risk.identifiedById = this.getFilterValue(FilterTypes.Identified_By);
    this.risk.status = this.getFilterValue(FilterTypes.Risk_Status);

    if (!actionGroupHasValues) {
      return this.riskService.add(this.risk).pipe(
        tap((riskResult: ValidatedViewModel) => {
          this.selectedRisk = riskResult.returnModel;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
    }
    else {
      const payload = this.preparePayload();

      return this.riskService.addWithActions(payload).pipe(
        tap((riskResult: ValidatedViewModel) => {
          this.selectedRisk = riskResult.returnModel;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
    }
  }


  preparePayload() {
    const actionGroupsArray = this.actionGroups.controls.map((group: FormGroup) => group.value);

    const nonEmptyActionGroups = actionGroupsArray.filter((group: any) => {
      const title = <string>group.title?.trim();
      const description = <string>group.description?.trim();
      const type = <number>group.type;
      const status = <number>group.status;
      const dueDate = <Date>group.dueDate;
      const filters = <FilterViewModel[]>group.filters;
      const userGroup = <string>group.userGroup;

      return title || description || type || status || dueDate || (filters && filters.length > 0) || userGroup;
    });

    return {
      risk: this.risk,
      actions: nonEmptyActionGroups
    };
  }

  getFilterValue(filterType: FilterTypes): number {
    const filter: FilterViewModel = this.risk?.filters?.find((f) => f.filterType === filterType);
    return filter ? filter.filterValue : 0;
  }
  closeModal() {
    this.bsModalRef.hide();
  }

  initAllowedFilters() {
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
    this.initInitalSelectedFields();
  }

  confirmClose() {
    if (this.isTouched) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.closeModal();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    } else {
      this.closeModal();
    }
  }

  updateRiskDescription($event) {
    this.form.controls.riskDescription.setValue($event);
  }

  updateActionDescription(event: any, index?: number) {
    const actionGroup = this.actionGroups.at(index) as FormGroup;
    actionGroup.get('description')?.setValue(event);
  }

  initInitalSelectedFields() {
    if (this.initialSelectedProject) {
      const filteredprj = this.allowedFilters.find(
        (f) =>
          f.filterType == FilterTypes.Project && f.filterValue.toString() == this.initialSelectedProject.filterValue.toString()
      );
      this.filters.push(filteredprj);
    }

    this.filters = this.filters.slice();
    this.changeDetectorRef.markForCheck();
  }

  localiseString(s: string) {
    return this.localisationService.localise(s);
  }

  private updateBeads() {
    this.beads = this.beads.map(bead => ({
      ...bead,
      active: bead.number === this.currentStep,
      completed: bead.number < this.currentStep
    }));
  }

  private onIncreaseStep() {
    if (!this.currentStepIsValid) {
      this.triggerErrors = true;
      this.changeDetectorRef.markForCheck();
      return;
    }
    else if (this.currentStep < 3) {
      this.currentStep++;
      this.updateBeads();
      this.changeDetectorRef.markForCheck();
    }
  }

  goToPreviousStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.updateBeads();
    }
  }

  get currentStepIsValid(): boolean {
    switch (this.currentStep) {
      case 1:
        return this.form.get('riskTitle')?.valid
          && this.statusValue !== 0
          && this.impactValue !== 0
          && this.likelihoodValue !== 0 as RiskLikelihoods
          && this.privacyValue !== null
          && this.ownerValue !== null
      case 2:
        return this.risk.dueDate !== undefined && this.risk.dueDate !== '';
      case 3:
        return this.actionGroups.controls.every((group: FormGroup) => {
          const title = <string>group.get('title')?.value?.trim();
          const status = <number>group.get('status')?.value;
          const type = <number>group.get('type')?.value;
          const description = <string>group.get('description')?.value?.trim();
          const dueDate = <Date>group.get('dueDate')?.value;
          const filters = group.get('filters')?.value as FilterViewModel[];
          const userGroup = group.get('userGroup')?.value as FilterViewModel[];

          const isTitleValid = title && title.length >= 3;
          const requiredFieldsFilled = isTitleValid && !!status && !!type;
          const hasOwnersOrUserGroups = (filters && filters.length > 0) || (userGroup && userGroup.length > 0);
          const isEmpty = !title && !status && !type && !description && !dueDate && !hasOwnersOrUserGroups;

          if (isEmpty) {
            return true;
          }
          if (requiredFieldsFilled) {
            return true;
          }

          return false;
        });
      default:
        return false;
    }
  }

  addMoreActions() {
    this.actionGroups.push(this.createActionGroup());
  }

  private createActionGroup(): FormGroup {
    return this.fb.group({
      title: [
        '',
        {
          validators: [
            Validators.minLength(3),
            Validators.maxLength(250),
            this.wtValidators.title(),
            this.wtValidators.restrictedChars([';'])
          ],
          updateOn: 'blur'
        }
      ],
      type: [''],
      status: [''],
      description: [''],
      dueDate: [''],
      filters: [[]],
      userGroup: [''],
    });
  }

  onConfirm(event: string) {
    if (this.currentStep === 3) {
      this.handleAction(event);
    }
    this.onIncreaseStep();

    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  get currentStepButton(): string {
    const continueTxt = this.translateService.instant(T.common.next) as string;
    const saveTxt = this.translateService.instant(T.common.save) as string;
    switch (this.currentStep) {
      case 1:
        return continueTxt;
      case 2:
        return continueTxt;
      case 3:
        return saveTxt;
      default:
        return this.translateService.instant(T.common.confirm) as string;
    }
  }

  public localiseRiskType(riskType: RiskTypes): string {
    switch (riskType) {
      case RiskTypes.Risk:
        return this.localisationService.localiseObjectType(ObjectTypes.Risk);
      case RiskTypes.Issue:
        return this.translateService.instant(T.defaultLocalizations.issue.one);
      case RiskTypes.Opportunity:
        return this.translateService.instant(T.defaultLocalizations.opportunity.one);
      default:
        return 'unknown';
    }
  }

  handlePrefillForm(eventData: { item: any, notEditable: boolean }) {
    this.form.patchValue({
      riskTitle: eventData.item.title,
      riskDescription: eventData.item.description,
    });

    this.impactValue = eventData.item.impact
    this.likelihoodValue = eventData.item.likelihood
    this.usedPreDefinedRisk = eventData.notEditable;

    this.setRAGInformation();
    this.changeDetectorRef.detectChanges();
  }

  protected readonly ButtonSizes = ButtonSizes;
  protected readonly ButtonThemes = ButtonThemes;
  protected readonly String = String;
  protected readonly RiskLikelihoods = RiskLikelihoods;
}
