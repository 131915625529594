import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SuggestedRisksService } from 'src/app/modules/risk/services/suggested-risks.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Account } from 'src/app/modules/shared/models/account';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { convertAccountVenueTypesToAnArray } from 'src/app/modules/shared/enums/accountVenueTypes';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { RisksService } from 'src/app/modules/risk/services/risks.service';
import { RiskDetailsViewModel } from 'src/app/modules/risk/models/RiskDetailsViewModel';
import { SuggestedRiskAction, SuggestedRiskViewModel } from 'src/app/modules/risk/models/SuggestedRiskViewModel';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { RiskPrivacyStatusTypes } from 'src/app/modules/risk/enums/riskPrivacyStatusTypes';
import { RiskTypes } from 'src/app/modules/risk/enums/riskTypes';
import { RiskStatusTypes } from 'src/app/modules/risk/enums/riskStatusTypes';
import { Employee } from 'src/app/modules/shared/models/employee';
import { RelevanceNames, RelevanceScores } from 'src/app/modules/shared/enums/relevanceEnums';
import { SuggestedRiskAppliedFilters, SuggestedRiskQuickFilterNames } from '../../../enums/suggestedRiskFilters';
import { DropdownOption } from '../../../enums/suggestedRiskFilterDropdown.type';
import { DataType, TableHeader } from 'src/app/modules/shared/components/common/responsive-table/responsive-table/responsive-table.component';
import { ThreadedBead } from 'src/app/modules/shared/models/threadedBead';
import { SuggestedRiskDetailsModalComponent } from '../suggested-risk-details-modal/suggested-risk-details-modal.component';
import { RiskActionListViewModel } from '../../../models/riskActionListViewModel';
import { RiskActionStatuses } from '../../../enums/riskActionStatusType.enum';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { Router } from '@angular/router';
import { OperationTypes } from 'src/app/modules/shared/enums/operationTypes';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';

@Component({
  selector: 'app-suggested-risks',
  templateUrl: './suggested-risks-modal.component.html',
  styleUrl: './suggested-risks-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestedRisksModalComponent implements OnInit, OnDestroy {
  @ViewChild('ratingFieldTemplate') ratingFieldTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('titleFieldTemplate') titleFieldTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('impactTypeFieldTemplate') impactTypeFieldTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('eventTypeFieldTemplate') eventTypeFieldTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('ownersFieldTemplate') ownersFieldTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('departmentFieldTemplate') departmentFieldTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('riskActionsTemplate') riskActionsTemplate: TemplateRef<ElementRef<HTMLElement>>;

  public objectTypes = ObjectTypes;
  public filterTypes = FilterTypes;
  public readonly T = T;
  public loading = true;
  public suggestedRisks: SuggestedRiskViewModel[] = [];
  public filteredSuggestedRisks: SuggestedRiskViewModel[] = [];

  public preselectedRisk: SuggestedRiskViewModel;
  protected openedCard: SuggestedRiskViewModel;
  public selectedRisks: SuggestedRiskViewModel[] = [];
  public bulkFilters: FilterViewModel[] = [];

  public searchText: string = '';
  protected account: Account;
  public employee: Employee;

  protected subscriptions = new Subscription();

  public venueType: string;
  public venueCountry: string;
  protected venueTypeOptions = convertAccountVenueTypesToAnArray();

  public currentStep: number = 0;
  protected footerBtnText: string = this.translateService.instant(T.common.next);
  protected footerBtnIcon = ['', 'add'];
  private appliedFilters: SuggestedRiskAppliedFilters = {
    impactRange: [],
    riskCategory: [],
    relevance: [],
    riskImpactType: [],
    likelihoodRange: [],
    keywords: [],
    limit: 8,
    venueType: [],
    eventType: []
  };

  public beads: ThreadedBead[] = [
    { active: true },
    { active: false },
  ];

  @HostListener('window:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'escape') {
      event.stopImmediatePropagation();
    }
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private readonly bsModalRef: BsModalRef,
    private readonly bsModalService: BsModalService,
    protected readonly translateService: TranslateService,
    protected readonly suggestedRisksService: SuggestedRisksService,
    private readonly riskService: RisksService,
    private readonly authenticationService: AuthenticationService,
    private readonly localisationService: LocalisationService,
    protected readonly geoLocationService: GeoLocationService,
    private readonly alertService: AlertService,
    private allowedFiltersService: AllowedFiltersService,
  ) { }

  ngOnInit(): void {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.venueType = this.venueTypeOptions.find(venueTypeOption => venueTypeOption.id === this.account.venueType)?.value;
    this.getVenuesCityAndCountry();

    this.loading = false;

    this.suggestedRisks.forEach(suggestedRisk => suggestedRisk.filters = this.generateFiltersForSuggestedRisks(suggestedRisk));

    if (this.preselectedRisk) {
      const risk = this.suggestedRisks.find(risk => risk.id === this.preselectedRisk.id);
      const risks = this.suggestedRisks.filter(risk => risk.id !== this.preselectedRisk.id);
      this.suggestedRisks = [risk, ...risks]
      this.openSelectedRisk(risk);
    }

    this.filteredSuggestedRisks = [...this.suggestedRisks];

    this.bulkFilters = [FilterUtilities.GenerateFilter(FilterTypes.Owner, this.employee.id)];

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get tableHeaders(): TableHeader[] {
    const headers: TableHeader[] = [
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.defaultLocalizations.rating.one),
        template: this.ratingFieldTemplate
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.defaultLocalizations.title.one),
        template: this.titleFieldTemplate
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.common.impact_type_text.one),
        template: this.impactTypeFieldTemplate
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.defaultLocalizations.event_type.one),
        template: this.eventTypeFieldTemplate
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.defaultLocalizations.owner.many),
        template: this.ownersFieldTemplate
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.defaultLocalizations.department.one),
        template: this.departmentFieldTemplate
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.defaultLocalizations.risk_action_item.many),
        template: this.riskActionsTemplate
      },

    ]

    return headers;
  }

  get selectedRisksFooterText(): string {
    return this.translateService.instant(T.common.number_risks_selected, { selected: this.selectedRisks.length });
  }

  public isSelected(id: string): boolean {
    return !!this.selectedRisks.find(card => card.id === id);
  }

  public closeModal(): void {
    this.bsModalRef.hide();
    this.bsModalService._hideBackdrop();
  }

  public onCreateRisks(): void {
    this.selectedRisks.forEach((risk, index) => this.createRisk(risk, index + 1 === this.selectedRisks.length));
  }

  public openSelectedRisk(item: SuggestedRiskViewModel): void {
    this.openedCard = Object.assign({}, item);
    const modalConfig: ModalOptions = { class: 'modal-suggested-item-add', ignoreBackdropClick: true };
    const initialState = {
      suggestedRisk: item,
      venueType: this.account.venueType
    };

    const modalParams = Object.assign({}, modalConfig, { initialState });

    const modalRef = this.bsModalService.show(SuggestedRiskDetailsModalComponent, modalParams);

    this.subscriptions.add(
      modalRef.content.suggestedRiskUpdated.subscribe((res: SuggestedRiskViewModel) => {
        const isRiskAdded = this.selectedRisks?.find(risk => risk.id === res.id);
        if (!isRiskAdded) {
          this.selectedRisks.push(res);
        }

        this.openedCard = null;
      })
    )

    this.subscriptions.add(
      modalRef.content.closed.subscribe((res) => {
        item.title = this.openedCard.title;
        item.description = this.openedCard.description;
        item.filters = this.openedCard.filters;
        item.impact = this.openedCard.impact;
        item.likelihood = this.openedCard.likelihood;
        item.riskActions = [];
        this.openedCard = null;
      })
    )
  }

  public selectRisk(item: SuggestedRiskViewModel): void {
    const card = this.selectedRisks?.find(card => card.id === item.id);

    if (!card) {
      this.selectedRisks.push(item);
    } else {
      this.selectedRisks = this.selectedRisks.filter(card => card.id !== item.id);
    }
  }

  public onListSearch(searchText: string): void {
    this.searchText = searchText;
  }

  protected filterItems(appliedFilters: SuggestedRiskAppliedFilters): void {
    this.loading = true;
    this.appliedFilters = appliedFilters;

    if (appliedFilters.relevance.length === 0 && appliedFilters.riskCategory.length === 0 && appliedFilters.impactRange.length === 0
      && (!this.searchText || this.searchText.length === 0) && appliedFilters.riskImpactType.length === 0) {
      this.loadSuggestedRisks();
      return;
    }

    if (appliedFilters.relevance.length > 0 && appliedFilters.riskCategory.length === 0 && appliedFilters.impactRange.length === 0) {
      const filtered = this.filterByRelevance(this.filteredSuggestedRisks, appliedFilters.relevance);

      this.loading = false;
      this.suggestedRisks = filtered;
      this.filteredSuggestedRisks = filtered;
      this.changeDetectorRef.detectChanges();
      return;
    }

    const riskCategories: number[] = appliedFilters.riskCategory?.map(({ id }) => (id)) as number[];
    const impactRange: number[] = appliedFilters.impactRange?.map(({ id }) => (id)) as number[];
    const impactTypes: number[] = appliedFilters.riskImpactType?.map(({ id }) => (id)) as number[];
    const keywords: string[] = [];
    const searchTerm: string = this.searchText? this.searchText : undefined;
    const payload = {
      [SuggestedRiskQuickFilterNames.Category]: riskCategories,
      [SuggestedRiskQuickFilterNames.Impact]: {},
      [SuggestedRiskQuickFilterNames.RiskImpactType]: impactTypes,
      keywords: keywords,
      searchTerm: searchTerm
    }

    if(appliedFilters.impactRange && impactRange.length > 0) {
      payload[SuggestedRiskQuickFilterNames.Impact] = {
        min: impactRange.length > 0 ? Math.min(...impactRange) : 0,
        minInclusive: true,
        max: impactRange.length > 0 ? Math.max(...impactRange) : 0,
        maxInclusive: true
      }
    }

    this.subscriptions.add(
      this.suggestedRisksService.getList(payload).subscribe(res => {
        res.forEach(risk => risk.filters = this.generateFiltersForSuggestedRisks(risk));
        this.suggestedRisks = res;
        this.filteredSuggestedRisks = res;

        if (appliedFilters.relevance.length > 0) {
          const filtered = this.filterByRelevance(this.filteredSuggestedRisks, appliedFilters.relevance);

          this.suggestedRisks = filtered;
          this.filteredSuggestedRisks = filtered;
        }

        this.loading = false;
        this.changeDetectorRef.detectChanges();
      }
      )
    )
  }

  protected resetFilters(): void {
    this.searchText = '';
    this.loadSuggestedRisks();
  }

  private filterByRelevance(arrToFilter: SuggestedRiskViewModel[] | any[], relevanceCriteria: DropdownOption[]) {
    const relevanceNames: RelevanceNames[] = relevanceCriteria?.map(({ value }) => (value)) as RelevanceNames[];
    let result: SuggestedRiskViewModel[] | any = [];

    relevanceNames.forEach(relevance => {
      switch (relevance) {
        case RelevanceNames.Low:
          const lowRelevance = arrToFilter.filter(obj => obj.relevanceScore <= RelevanceScores.Low);
          result = [...result, ...lowRelevance];
          return;
        case RelevanceNames.Medium:
          const mediumRelevance = arrToFilter.filter(obj => obj.relevanceScore > RelevanceScores.Low && obj.relevanceScore <= RelevanceScores.Medium);
          result = [...result, ...mediumRelevance];
          return;
        case RelevanceNames.High:
          const highRelevance = arrToFilter.filter(obj => obj.relevanceScore > RelevanceScores.Medium);
          result = [...result, ...highRelevance];
          return;
        default:
          return;
      }
    })

    return result;
  }

  protected createRisk(suggestedRisk: SuggestedRiskViewModel, isTheLastOne: boolean): void {
    const risk: RiskDetailsViewModel = new RiskDetailsViewModel();
    const filters: FilterViewModel[] = [...suggestedRisk.filters];
    const riskActions: RiskActionListViewModel[] = [];

    risk.description = suggestedRisk.description;
    risk.title = suggestedRisk.title;
    risk.type = RiskTypes.Risk;
    risk.createdById = this.employee.id;
    risk.likelihood = suggestedRisk.likelihood;
    risk.impact = suggestedRisk.impact;
    risk.privacyStatus = RiskPrivacyStatusTypes.Public;
    risk.status = RiskStatusTypes.Open;
    risk.ownerId = this.employee.id;

    const privacyStatusFilter = FilterUtilities.GenerateFilter(FilterTypes.Risk_Privacy_Status, risk.privacyStatus);
    filters.push(privacyStatusFilter);

    const statusFilter = FilterUtilities.GenerateFilter(FilterTypes.Risk_Status, risk.status);
    filters.push(statusFilter);

    risk.filters = filters;

    if (suggestedRisk.riskActions && suggestedRisk.riskActions.length > 0) {
      suggestedRisk.riskActions.forEach(action => riskActions.push(this.createRiskAction(action)))
    }

    this.subscriptions.add(
      this.riskService.addWithActions({ risk, actions: riskActions }).subscribe((res: ValidatedViewModel) => {
        const newRisk = res.returnModel as RiskDetailsViewModel;
        this.allowedFiltersService.refreshFilters(
          OperationTypes.Create,
          [{ id: newRisk.id, title: newRisk.title, relatedObjectId: 0 }],
          FilterTypes.Risk
        );
        void this.alertService.success(this.translateService.instant(T.common.item_added, { item: 'Risk' }));

        if (isTheLastOne) this.closeModal();
      })
    )
  }

  createRiskAction(riskAction: SuggestedRiskAction): RiskActionListViewModel {
    const newRiskAction: RiskActionListViewModel = new RiskActionListViewModel();

    newRiskAction.title = riskAction.title.trim();
    newRiskAction.status = RiskActionStatuses.NotStarted;

    return newRiskAction;
  }

  private loadSuggestedRisks(): void {
    const accountVenueType = this.account.venueType;
    const payload = { venueType: [accountVenueType] };

    this.loading = true;

    this.subscriptions.add(
      this.suggestedRisksService.getList(payload).subscribe(res => {
        res.forEach(risk => risk.filters = this.generateFiltersForSuggestedRisks(risk));
        this.suggestedRisks = res;
        this.filteredSuggestedRisks = res;
        this.loading = false;
        this.changeDetectorRef.detectChanges();
      })
    )
  }

  private getVenuesCityAndCountry(): void {
    if (this.account.location && this.account.location.length > 0) {
      this.subscriptions.add(
        this.geoLocationService.getAddressFromCoordinates(this.account.latitude, this.account.longitude).subscribe((addressResults) => {
          if (!addressResults) return;
          let country: string;
          let city: string;

          addressResults[0].address_components.forEach(component => {
            if (component.types[0] === "country") {
              country = component.long_name;
            }

            if (component.types[0] === "locality" || component.types[0] === "postal_town") {
              city = component.long_name;
            }
          })

          this.venueCountry = `${city}, ${country}`;

          this.changeDetectorRef.detectChanges();
        })
      )
    }
  }

  public increaseStep(): void {
    if (this.currentStep === 1) {
      this.onCreateRisks();
      return;
    }

    this.currentStep = 1;
    this.footerBtnText = this.translateService.instant(T.common.create_number_of_risks, { number: this.selectedRisks?.length });
    this.updateBeads(this.currentStep, true);

    this.changeDetectorRef.detectChanges();
  }

  public decreaseStep(): void {
    this.updateBeads(this.currentStep, false);
    this.currentStep = 0;
    this.footerBtnText = this.translateService.instant(T.common.next);

    this.changeDetectorRef.detectChanges();
  }

  private updateBeads(bead: number, isActive: boolean): void {
    this.beads[bead].active = isActive;
    this.beads = [...this.beads];
  }

  public updateTitle(newTitle: string, risk: SuggestedRiskViewModel): void {
    risk.title = newTitle;

    this.changeDetectorRef.detectChanges();
  }

  public updateFilters(filters: FilterViewModel[], risk: SuggestedRiskViewModel): void {
    risk.filters = filters;

    this.changeDetectorRef.detectChanges();
  }

  public updateFiltersInBulk(filters: FilterViewModel[], filterType: FilterTypes): void {
    this.bulkFilters = filters;
    this.selectedRisks.forEach(risk => {
      let newFilters = risk.filters.filter(f => f.filterType !== filterType);

      newFilters = [...newFilters, ...filters];

      risk.filters = newFilters;
    })
  }

  private generateFiltersForSuggestedRisks(risk: SuggestedRiskViewModel): FilterViewModel[] {
    const filters: FilterViewModel[] = [];

    const likelihoodFilter = FilterUtilities.GenerateFilter(FilterTypes.Risk_Likelihood, risk.likelihood);
    filters.push(likelihoodFilter);

    const impactFilter = FilterUtilities.GenerateFilter(FilterTypes.Risk_Impact, risk.impact);
    filters.push(impactFilter);

    const ownerFilter = FilterUtilities.GenerateFilter(FilterTypes.Owner, this.employee.id);
    filters.push(ownerFilter);

    return filters;
  }

  searchRisks() {
    this.filterItems(this.appliedFilters);
  }
}
