import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { Constants } from 'src/app/modules/shared/models/constants';
import { FilterLozengeComponent } from '../filters-lozenge/filters-lozenge.component';
import { NgBlockScreenDirective } from '../../../directives/ngBlockScreen.directive';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterDateOptions } from '../../../enums/filter/filterDateOptions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FiltersInlineEditContainerComponent } from '../filters-lozenge-inline-edit/filters-inline-edit-container.component';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { LocalisationService } from '../../../services/localisation.service';

@Component({
  selector: 'app-filters-lozenge-container',
  templateUrl: './filters-lozenge-container.component.html',
  styleUrls: ['./filters-lozenge-container.component.scss'],
})
export class FiltersLozengeContainerComponent implements OnInit, OnChanges {
  @ViewChild(NgBlockScreenDirective) blockScreenDirective: NgBlockScreenDirective;
  @ViewChild('filterLozenge') filterLozenge: ElementRef<HTMLElement>;

  @Input() appliedFiltersByType: FilterViewModel[] = [];
  @Input() allAllowedFilters: FilterViewModel[] = [];
  @Input() filterTypeSelectorViewModels: FilterTypeSelectorViewModel[] = [];
  @Input() isInModal = false;
  @Input() isFixedFilter = false;
  @Input() isSingleSelect: boolean = false;
  @Input() allowEdit: boolean = true;
  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();

  public filterType: FilterTypes;
  public displayForObjectType: ObjectTypes;
  public dateProperty: FilterDateOptions;
  public filterDropdownTitle: string;
  private bsModalRef: BsModalRef;

  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
    private readonly bsModalService: BsModalService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly localisationService: LocalisationService
  ) {}

  get mobile(): boolean {
    return window.innerWidth <= Constants.xs;
  }

  ngOnInit(): void {
    this.setInitialData();
  }

  private setInitialData() {
    this.filterType = this.appliedFiltersByType[0]?.filterType;
    this.displayForObjectType = this.appliedFiltersByType[0]?.displayForGlobalObjectType;
    this.filterDropdownTitle = this.appliedFiltersByType[0]?.filterDropdownTitle;
    this.dateProperty = this.appliedFiltersByType[0]?.dateProperty;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.appliedFiltersByType && !this.filterType) {
      this.setInitialData();
    }
  }

  onLozengeClicked(filterLozengeComponent: FilterLozengeComponent, e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    const { editMode } = filterLozengeComponent;
    filterLozengeComponent.editMode = !filterLozengeComponent.editMode;
    if (editMode) {
      document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    } else {
      // this.updateBlockScreen(filterLozengeComponent.filterLozengeElementRef);
    }
  }

  updateBlockScreen(elementRef: ElementRef<HTMLElement>): void {
    if (!this.isInModal) {
      this.blockScreenDirective.ngBlockScreenInstance.show();
      this.blockScreenDirective.blockScreenElementRef = elementRef;
      this.blockScreenDirective.updateInstance();
      this.blockScreenDirective.addElementRefStyles();
    }
  }

  /**TODO: Extract into Lozenge  */
  getFilterTypeSelectorViewModelByFilterType(): FilterTypeSelectorViewModel {
    if (this.filterType === FilterTypes.Date) {
      return this.filterTypeSelectorViewModels.find(
        (filterTypeSelectorViewModel) =>
          filterTypeSelectorViewModel.filterType === this.filterType &&
          filterTypeSelectorViewModel.displayForObjectType === this.displayForObjectType &&
          filterTypeSelectorViewModel.dateProperty === this.dateProperty
      );
    }

    if (this.filterType === FilterTypes.Tag) {
      const tagFilterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
      tagFilterTypeSelectorViewModel.filterType = FilterTypes.Tag;
      tagFilterTypeSelectorViewModel.filterTypeText = 'Tag';
      tagFilterTypeSelectorViewModel.filterSelectorType = FilterSelectorTypes.Dropdown;

      return tagFilterTypeSelectorViewModel;
    } else {
      return this.filterTypeSelectorViewModels.find(
        (filterTypeSelectorViewModel) =>
          filterTypeSelectorViewModel.filterType === this.filterType &&
          filterTypeSelectorViewModel.displayForObjectType === this.displayForObjectType
      );
    }
  }

  onFiltersChanged(filters: FilterViewModel[]): void {
    let appliedFilters = this.appliedFiltersByType.slice();

    appliedFilters = appliedFilters.filter(
      (s) => !filters.find((a) => a.filterType === s.filterType && (a.filterType === FilterTypes.Tag || a.displayForGlobalObjectType === s.displayForGlobalObjectType))
    );
    appliedFilters = appliedFilters.concat(filters);

    this.filtersUpdated.next(appliedFilters);
  }

  onBlockScreenClicked(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  filterByObjectType() {
    // This is quick fix in case we are passing the events and other bits (one day before the release)
    if (this.allAllowedFilters && this.allAllowedFilters.length > 0) {
      if (this.filterType === FilterTypes.RAG) {
        return this.allAllowedFilters.filter((a) => a.filterType == this.filterType);
      } else {
        return this.allAllowedFilters.filter(
          (a) =>
            a.filterType === this.filterType &&
            (a.displayForGlobalObjectType === ObjectTypes.Global || a.displayForGlobalObjectType === this.displayForObjectType)
        );
      }
    } else {
      const allowedFiltersByType = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);
      if (this.filterType === FilterTypes.RAG) {
        return allowedFiltersByType;
      } else {
        return allowedFiltersByType.filter(
          (a) => a.displayForGlobalObjectType === ObjectTypes.Global || a.displayForGlobalObjectType === this.displayForObjectType
        );
      }
    }
  }

  onLozengeRemoved(removedFilter: FilterViewModel) {
    const copiedFilterForRemove = JSON.parse(JSON.stringify(removedFilter)) as FilterViewModel;
    copiedFilterForRemove.filterValue = -1;
    this.onFiltersChanged([copiedFilterForRemove]);
  }

  openEditContainerModal() {
    if(!this.allowEdit) {
      return;
    }
    let filters = this.filterByObjectType();

    filters = filters.map((f) => {
      // Replace filterText with enum localisation
      const shouldBeLocalisedValue = FilterUtilities.IsFilterTypeWithLocalisedValues(f.filterType);
      if (shouldBeLocalisedValue) {
        f.filterText = this.localisationService.localiseFilterValueByFilterType(+f.filterValue, f.filterType);
      }

      return f;
    });
    if (this.filterType === FilterTypes.Tag) {
      filters = this.allowedFiltersService
        .getCachedAllowedFiltersByType(this.filterType)
        .filter((s) => this.appliedFiltersByType.some((e) => e.relatedObjectId === s.relatedObjectId));

      filters.forEach((f) => {
        f.displayForGlobalObjectType = this.displayForObjectType;
        f.filterDropdownTitle = this.filterDropdownTitle;
      });
    }

    const initialState = {
      mobile: this.mobile,
      filters: filters,
      appliedFilters: this.appliedFiltersByType,
      localAppliedFilters: JSON.parse(JSON.stringify(this.appliedFiltersByType)) as FilterViewModel[],
      canClickOnStar: false,
      singleSelect: this.isSingleSelect,
      isFixedFilter: this.isFixedFilter,
      filterTypeSelectorViewModel: this.getFilterTypeSelectorViewModelByFilterType(),
    };

    const modalRef = this.bsModalService.show(FiltersInlineEditContainerComponent, {
      initialState,
      class: 'max-width-unset center-transform',
    });

    modalRef.content.ngOnInit();

    modalRef.content.filtersUpdated.subscribe((res) => {
      this.onFiltersChanged(res);
      modalRef.hide();
      if (this.bsModalService.getModalsCount() > 0) this.bsModalService.hide();
    });
  }
}
