import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-skeleton-details-loading',
  templateUrl: 'skeleton-details-loading.component.html',
  styleUrls: ['skeleton-details-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonDetailsLoadingComponent {
}
