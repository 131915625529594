import { trigger, transition, query, stagger, animateChild, style, animate, state } from '@angular/animations';

export class BounceFadeInAnimation {
  public static ListBounce = trigger('list', [
    transition(':enter', [
      // child animation selector + stagger
      query('@items', stagger(300, animateChild())),
    ]),
  ]);

  public static ItemBounce = trigger('items', [
    // cubic-bezier for a tiny bouncing feel
    transition(':enter', [
      style({ transform: 'scale(0.5)', opacity: 0 }),
      animate('1s cubic-bezier(.8,-0.6,0.2,1.5)', style({ transform: 'scale(1)', opacity: 1 })),
    ]),
    transition(':leave', [
      style({ transform: 'scale(1)', opacity: 1, height: '*' }),
      animate('1s cubic-bezier(.8,-0.6,0.2,1.5)', style({ transform: 'scale(0.5)', opacity: 0, height: '0px', margin: '0px' })),
    ]),
  ]);

  public static FadeIn = trigger('fade', [state('void', style({ opacity: 0 })), transition('void <=> *', [animate(1000)])]);
}
