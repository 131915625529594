<app-filters-mobile-header
  *ngIf="mobile"
  [title]="T.common.my_filters | translate"
  (cancel)="onCancel()"
  (clear)="onClear()"
></app-filters-mobile-header>
<div class="filters-header">
  <app-modal-header *ngIf="!mobile" [title]="T.common.my_filters | translate" (closed)="onCancel()"></app-modal-header>
</div>
<ng-container *ngIf="!loading">
  <app-toggle-button
    [objects]="toggleOptions"
    [currentlySelectedObject]="selectedToggleOption"
    [bindProperty]="'displayText'"
    [fillWidth]="true"
    (clicked)="onToggleChange($event)"
  ></app-toggle-button>
  <div class="filters-favorite" *ngIf="selectedToggleOption.label === 'Favourites'">
    <!-- <div class="filters-favorite-title" *ngIf="!mobile">My Filters</div> -->
    <div class="my-filters-helper-header">{{ T.common.my_filters_favourite_helper | translate }}</div>

    <div class="filters-favorite-header">
      <app-text-field
        [placeholder]="T.common.enter_title_for_your_filters | translate"
        [disabled]="!appliedFilters.length && selectedIndex === undefined"
        [maxLength]="200"
        [(ngModel)]="titleModel"
        [autofocus]="true"
      ></app-text-field>

      <div class="filters-favorite-header-buttons">
        <app-button-rectangle
          [disabled]="selectedIndex === undefined"
          [size]="'medium'"
          [buttonTheme]="'danger'"
          [text]="T.common.delete | translate"
          (clicked)="onEntryDelete()"
        ></app-button-rectangle>
        <app-button-rectangle
          [disabled]="!titleModel"
          [size]="'medium'"
          [buttonTheme]="'primary'"
          [text]="T.common.save | translate"
          (clicked)="onSaveFavorite()"
        ></app-button-rectangle>
      </div>
    </div>

    <div class="filters-favorite-applied-count">
      {{ T.common.count_favourites | translate: { count: favoriteFilters.length } }}

      <div class="filters-favorite-applied-count-helper">
        <span *ngIf="appliedFilters.length && selectedIndex === undefined">{{
          T.common.select_favorite_to_update | translate
        }}</span>
        <app-button-transparent
          *ngIf="selectedIndex !== undefined"
          [text]="T.common.clear_selected | translate"
          size="small"
          (clicked)="onClear()"
        >
        </app-button-transparent>
      </div>
    </div>

    <div class="filters-favorite-body custom-scrollbar">
      <div
        class="filters-favorite-body-entry"
        *ngFor="let favoriteFilter of favoriteFilters; let i = index"
        (click)="onEntrySelected(i)"
      >
        <app-radio-button
          [checked]="i === selectedIndex"
          [name]="'favorite-body-entry'"
          (checkedChanged)="onEntrySelected(i)"
        ></app-radio-button>
        <div class="filters-favorite-body-entry-text">{{ favoriteFilter.title }}</div>
      </div>
    </div>
  </div>
  <div class="filters-suggested" *ngIf="selectedToggleOption.label === 'Suggested'">
    <div class="filters-favorite-applied-count">
      {{ T.common.count_suggested | translate: { count: suggestedFilters.length } }}

      <!-- <div class="filters-favorite-applied-count-helper">
            <span *ngIf="appliedFilters.length && selectedIndex === undefined">Select a favourite to update it</span>
            <span class="" *ngIf="selectedIndex !== undefined" (click)="onClear()">Clear Selected</span>
          </div> -->
    </div>

    <div class="filters-favorite-body custom-scrollbar">
      <div
        class="filters-favorite-body-entry"
        *ngFor="let suggestedFilter of suggestedFilters; let i = index"
        (click)="onSuggestedSelected(suggestedFilter)"
      >
        <app-radio-button
          [checked]="suggestedFilter.id === selectedSuggestedFilter?.id"
          [name]="'favorite-body-entry'"
          (checkedChanged)="onSuggestedSelected(suggestedFilter)"
        ></app-radio-button>
        <div class="filters-favorite-body-entry-text">{{ suggestedFilter.title }}</div>
      </div>
    </div>
  </div>
</ng-container>

<app-filters-desktop-footer
  *ngIf="!mobile"
  [useClearAllButton]="false"
  [useCancelApplyButtons]="true"
  [clearDisabled]="selectedIndex === undefined"
  [applyDisabled]="selectedIndex === undefined && !selectedSuggestedFilter"
  (clear)="selectedIndex = undefined"
  (cancel)="onCancel()"
  (apply)="onApply()"
  (clear)="onClear()"
></app-filters-desktop-footer>
<app-filters-mobile-footer
  *ngIf="mobile"
  [applyDisabled]="selectedIndex === undefined"
  (apply)="onApply()"
></app-filters-mobile-footer>

<ng-container *ngIf="loading">
  <app-loading>{{ T.common.loading | translate }}...</app-loading>
</ng-container>
