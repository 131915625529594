import { ObjectTypes } from '../../shared/enums/objectTypes';
import { LocalisationService } from '../../shared/services/localisation.service';

export enum TaskRAGBreakdownTypes {
  Department_Category = 1,
  Department = 7,
}

export namespace TaskRAGBreakdownTypes {
  export function items(localisationService): { key: TaskRAGBreakdownTypes; value: string }[] {
    const a: { key: TaskRAGBreakdownTypes; value: string }[] = [];

    for (const enumMember in TaskRAGBreakdownTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({
          key: +enumMember,
          value: translateEnumMember(+enumMember, localisationService),
        });
      }
    }

    return a;
  }

  function translateEnumMember(enumMember: TaskRAGBreakdownTypes, localisationService: LocalisationService): string {
    switch (enumMember) {
      case TaskRAGBreakdownTypes.Department:
        return localisationService.localiseObjectType(ObjectTypes.Department);
      case TaskRAGBreakdownTypes.Department_Category:
        return localisationService.localiseObjectType(ObjectTypes.Department_Category);
      default:
        return 'unknown';
    }
  }
}
