import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-ng-dropdown',
  templateUrl: './ngDropdown.component.html',
  styleUrls: ['./ngDropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgDropdownComponent {
  @ViewChild('dropdownContainer') dropdownContainer: ElementRef<HTMLElement>;

  templateRef: TemplateRef<ElementRef<HTMLElement>>;
  templateOutletContext: object;
  directiveElementRef: ElementRef<HTMLElement>;
  visible: boolean;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    public readonly dropDownComponentElementRef: ElementRef<HTMLElement>,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.visible) {
          this.hide();
        }
      }
    });
  }

  public show() {
    this.visible = true;
    this.changeDetectorRef.markForCheck();
  }

  public hide() {
    if (this.visible === true) {
      this.visible = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  public updateDropdownContent(obj: {
    templateRef: TemplateRef<ElementRef<HTMLElement>>;
    templateOutletContext: object;
    directiveElementRef: ElementRef<HTMLElement>;
  }) {
    this.templateRef = obj.templateRef;
    this.templateOutletContext = obj.templateOutletContext;
    this.directiveElementRef = obj.directiveElementRef;
    this.changeDetectorRef.detectChanges();
  }

  public getCurrentDropdownElementRef() {
    return this.dropdownContainer.nativeElement;
  }
}
