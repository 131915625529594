import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Account } from '../../../models/account';
import { Employee } from '../../../models/employee';
import { Capacitor } from '@capacitor/core';
import { Constants } from '../../../models/constants';
import { UrlService } from '../../../services/url.service';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { authConfig } from 'src/app/modules/auth/auth.config';
import { WtStorageService } from '../../../services/wt-storage.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { CachingService } from '../../../services/caching.service';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { EmployeeAvatarSizes } from '../../../enums/employeeAvatarSizes';
import { UtilitiesService } from '../../../services/utilities.service';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-abstract-side-navbar',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class AbstractSideNavbarComponent implements OnInit {
  @Input() currentAccount: Account;
  @Input() currentEmployee: Employee;

  public isAuthenticated = false;
  public isNativeDevice: boolean = false;
  public isMobileScreen = false;
  public brandingUrl = '';
  public logoUrl = '';
  public currentRoute = '';
  public avatarSize: EmployeeAvatarSizes = EmployeeAvatarSizes.Medium;

  protected actionUrl: string = '';

  constructor(
    protected readonly configuration: Configuration,
    protected readonly http: HttpClient,
    protected readonly urlService: UrlService,
    protected readonly wtStorage: WtStorageService,
    protected readonly authService: AuthService,
    protected readonly authenticationService: AuthenticationService,
    protected readonly cachingService: CachingService,
    protected readonly utilitiesService: UtilitiesService,
    protected readonly router: Router,
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Logout`);
  }

  protected abstract initSideNavModel(): void;

  ngOnInit(): void {
    this.isAuthenticated = !!this.currentEmployee;
    if(!this.isAuthenticated) {
      throw new Error("Current employee is required");
    }

    if(!this.currentAccount) {
      throw new Error("Current account is required");
    }

    this.isNativeDevice = Capacitor.getPlatform() !== 'web';
    if (window.innerWidth < Constants.xs) {
      this.isMobileScreen = true;
    }

    this.logoUrl = this.buildLogoUrl();

    this.currentRoute = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.currentRoute = event.url;
      }
    });
  }

  protected buildLogoUrl(): string {
    return this.urlService.buildResourceUrl(this.currentAccount.localLogoURL);
  }

  protected logout() {
    this.http.post<object>(
      this.actionUrl + '/Revocation', {
        ClientId: authConfig.clientId,
        Token: this.wtStorage.getItem('access_token')
      },
      this.authenticationService.getHttpOptions()
    ).subscribe(response => { });

    this.cachingService.RemoveEmployeeData(this.currentAccount.id);
    this.cachingService.RemoveAccountData();
    this.cachingService.removeFixedEventPromptCookie();
    this.cachingService.removeFixedZonePromptCookie();
    this.authService.logOut();
  }

  public collapseSidebarOnMenuItemSelect() {
    if (this.isMobileScreen) {
      this.collapseSidebar();
    }
  }

  public collapseSidebar() {
    this.utilitiesService.broadcastSidebarToggle(false);
  }

  public expandSidebar() {
    this.utilitiesService.broadcastSidebarToggle(true);
  }
}
