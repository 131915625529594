import { Employee } from '../models/employee';
import { EmployeeRoleTypes } from '../enums/employees/EmployeeRoleTypes';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { FilterTypes } from '../enums/filterTypes';
import { PrivacyStatuses } from '../enums/privacyStatuses';
import { EmployeeDetailsViewModel } from '../viewModels/employeeDetailsViewModel';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { PermissionViewModel } from '../viewModels/permissionViewModel';

export class EmployeeUtil {
  /**
   * Check if the user is in specific role
   * @param role provided role from the enum
   */
  public static hasRole(employee: Employee | EmployeeDetailsViewModel, role: EmployeeRoleTypes): boolean {
    if (employee.employeeRole !== undefined) return employee.employeeRole.findIndex((a) => a.roleId === role) !== -1;

    if (employee.employeeRole !== undefined) return employee.employeeRole.findIndex((a) => a.roleId === role) > -1;

    return true;
  }

  //used in filters service so we can filter department dropdown values per module (f.e. user can see all deps in incidents, but only department X and Y in Planning)
  //TODO: use in new filters service :)
  public static permissionsByModuleAndType(employee: Employee, module: ModuleTypes, type: FilterTypes, level: number = 1) {
    let ignoreLevel = false;

    if (level === 0 || level === 1) {
      ignoreLevel = true;
    }

    if (employee.permissions) {
      const permissions = employee.permissions.filter((p) =>
        p.module === module && (p.type === type || p.type === FilterTypes.Empty) && ignoreLevel ? true : p.level === level
      );
      if (permissions && permissions.length) {
        const permissionForAllDepartments = permissions.find((p) => p.value === 0 && p.read);

        if (permissionForAllDepartments) {
          return permissionForAllDepartments;
        } else {
          return permissions[0];
        }
      }
    }
  }

  //used in signalR to determine if the updates to entity(ies) should be added for the current employee ( checks if current employee has permissions to read the specific type)
  public static permissionByModuleTypeAndValue(employee: Employee, module: ModuleTypes, type: FilterTypes, value: number) {
    if (this.IsAdmin(employee)) {
      return true;
    }

    if (employee.permissions) {
      const permission = employee.permissions.find(
        (p) => p.module === module && (p.type === type || p.type === FilterTypes.Empty) && (p.value === value || p.value === 0)
      );
      return permission ? permission.read : false;
    }
  }

  /**
   * used in signalR to determine if the updates to entity(ies) should be added for the current employee in it's Employee Allowed Filters
   */
  public static permissionByFilterViewModel(employee: Employee, filterViewModel: FilterViewModel) {
    if (this.IsAdmin(employee)) {
      return true;
    }

    if (employee.permissions) {
      const permission = employee.permissions.find(
        (p) =>
          (p.type === filterViewModel.filterType || p.type === 0) && (p.value === filterViewModel.filterValue || p.value === 0)
      );
      return permission ? permission.read : false;
    }
  }

  public static getWritePermission(
    employee: Employee | EmployeeDetailsViewModel,
    module: ModuleTypes,
    type: FilterTypes,
    values: number[],
    level: number = 0
  ): PermissionViewModel {
    const permission = employee.permissions.find(
      (p) =>
        (p.type === type || p.type === FilterTypes.Empty) &&
        p.write &&
        (p.value === 0 || values.indexOf(p.value) !== -1) &&
        p.level === level &&
        +p.departmentVisibility > -1
    );

    return permission;
  }
  // used everywhere where user can edit an entity (details page, lozenges etc.). Checks if the employee has write permissions for module, filterType, filterValues (optional), level(optional, default 0 which means privacy status Open)
  public static hasWritePermission(
    employee: Employee | EmployeeDetailsViewModel,
    module: ModuleTypes,
    type: FilterTypes,
    values: number[],
    obj: { id: number; filters: FilterViewModel[] },
    level: number = 0
  ): boolean {
    let hasEditPermission = false;

    if (
      employee.employeeRole.findIndex((r) => this.AdminRoles().findIndex((e) => e === r.roleId) > -1) > -1 &&
      level <= PrivacyStatuses.Open
    ) {
      hasEditPermission = true;
    } else if (employee.permissions) {
      const permissions = employee.permissions.filter(
        (p) =>
          (p.type === type || p.type === FilterTypes.Empty) &&
          p.write &&
          (p.value === 0 || values.indexOf(p.value) !== -1) &&
          p.level === level &&
          +p.departmentVisibility > -1
      );

      const permission = employee.permissions.find(
        (p) =>
          (p.type === type || p.type === FilterTypes.Empty) &&
          p.write &&
          (p.value === 0 || values.indexOf(p.value) !== -1) &&
          p.level === level &&
          +p.departmentVisibility > -1
      );

      if (permission) {
        if (obj && permission.value === 0 && permission.departmentVisibility > 0) {
          const hasDepSpecificPermissions = obj.filters.some(
            (s) => s.filterType === FilterTypes.Department && s.filterValue === employee.departmentId
          );

          hasEditPermission = hasDepSpecificPermissions;
        } else {
          if (obj && type === FilterTypes.Project) {
            const cFilter = obj.filters.find((s) => s.filterType === FilterTypes.Project_Privacy_Status);

            const confidential = cFilter && Number(cFilter.filterValue) > 0;

            const haveCPermissions = permissions.find(
              (s) => s.type === type && s.confidential === confidential && (s.value === 0 || values.indexOf(s.value) !== -1)
            );
            if (!haveCPermissions) {
              return false;
            }
          }

          hasEditPermission = true;
        }
      }
    }

    return hasEditPermission;
  }

  // used in header (for add button options), checks if the employee has any write permissions for the specific module and filterType
  public static hasAnyWritePermission(
    employee: Employee | EmployeeDetailsViewModel,
    module: ModuleTypes,
    type: FilterTypes
  ): boolean {
    let hasEditPermission = false;

    if (this.IsAdmin(employee)) {
      hasEditPermission = true;
    } else if (employee.permissions) {
      const permission = employee.permissions.find(
        (p) =>
          p.module === module &&
          (p.type === type || p.type === FilterTypes.Empty) &&
          p.write &&
          p.value !== -1 &&
          +p.departmentVisibility > -1
      );

      if (permission) {
        hasEditPermission = true;
      }
    }

    return hasEditPermission;
  }

  public static hasOwnerPermission(employee: Employee | EmployeeDetailsViewModel, type: FilterTypes, values: number[]): boolean {
    return employee.permissions.some((r) => r.type === type && (r.value === 0 || values.some((a) => a === r.value)));
  }

  public static hasOwnerPermissions(employeeId: number, filters: FilterViewModel[]): boolean {
    return filters.some((r) => r.filterType === FilterTypes.Owner && Number(r.filterValue) === employeeId);
  }

  public static hasReadPermission(
    employee: Employee | EmployeeDetailsViewModel,
    type: FilterTypes,
    values: number[],
    confidential: boolean
  ): boolean {
    let hasreadPermission = false;

    if (this.IsAdmin(employee)) {
      hasreadPermission = true;
    } else if (employee.permissions) {
      const permission = employee.permissions.find(
        (p) =>
          (p.type === type || p.type === FilterTypes.Empty) &&
          p.read &&
          (p.value === 0 || values.some((r) => r === p.value)) &&
          +p.departmentVisibility > -1
      );

      if (permission) {
        hasreadPermission = true;
      }
    }

    return hasreadPermission;
  }

  public static IsAdmin(employee: Employee | EmployeeDetailsViewModel): boolean {
    return employee.employeeRole.findIndex((a) => this.AdminRoles().findIndex((r) => r === a.roleId) > -1) > -1;
  }

  /**
   * get the assigined primary role for the provided employee
   * the primary roles are main roles which can be selected from the left side pane of user edit
   */
  public static GetPrimaryRole(employee: Employee | EmployeeDetailsViewModel): EmployeeRoleTypes {
    return employee.employeeRole.find((a) => this.PrimaryRoles().findIndex((r) => r === a.roleId) > -1).roleId;
  }

  /**
   * Returns only primary Admin roles
   */
  public static AdminRoles(): EmployeeRoleTypes[] {
    return [EmployeeRoleTypes.Admin, EmployeeRoleTypes.User_Admin, EmployeeRoleTypes.Account_Admin];
  }

  public static PrimaryRoles(): EmployeeRoleTypes[] {
    return [...this.AdminRoles(), EmployeeRoleTypes.General_User];
  }

  public static hasAnyReadPermission(employee: Employee, module: ModuleTypes, type: FilterTypes) {
    let hasEditPermission = false;

    if (EmployeeUtil.IsAdmin(employee)) {
      hasEditPermission = true;
    } else if (employee.permissions) {
      const permission = employee.permissions.find(
        (p) =>
          p.module === module &&
          (p.type === type || p.type === FilterTypes.Empty) &&
          p.read &&
          !p.write &&
          p.value !== -1
      );

      if (permission) {
        hasEditPermission = true;
      }
    }

    return hasEditPermission;
  }
}
