<app-modal>
  <app-modal-header [title]="title" (closed)="onCancel()"></app-modal-header>

  <app-modal-body>
    <ng-container *ngIf="!loading">
      <div class="row">
        <div class="col-12">
          <label class="label">{{ description }}</label>
        </div>
        <div class="col-12">
          <app-text-area
            [(ngModel)]="closeText"
            [placeholder]="'Comments'"
            [maxLength]="300"
            [rows]="3"
          ></app-text-area>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-document-upload
            [globalObjectId]="0"
            [globalObjectType]="objectType"
            [uploadViewModels]="attachments"
            [handleUpdatesInternally]="false"
            (uploadViewModelsChanged)="updateUploadViewModels($event)"
          >
          </app-document-upload>
        </div>
      </div>
    </ng-container>
    <div class="row" *ngIf="loading">
      <app-loading>
        <p>Loading...</p>
      </app-loading>
    </div>
  </app-modal-body>

  <app-modal-footer>
    <app-button-rectangle [buttonTheme]="'white'" [size]="'large'" [text]="'Cancel'" (clicked)="onCancel()">
    </app-button-rectangle>
    <app-button-rectangle
      [buttonTheme]="'primary'"
      [size]="'large'"
      [text]="submitButtonText"
      (clicked)="onSubmit()"
      [disabled]="closeText.length === 0"
    ></app-button-rectangle>
  </app-modal-footer>
</app-modal>
