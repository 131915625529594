<div
  *ngIf="ragBreakdowns"
  [ngClass]="isWithinEventDetails ? 'thinner breakdown-wrapper' : 'breakdown-wrapper'"
  class="breakdown-wrapper"
  [style.width]="displayRelativeValueWidth && maxRAGsSum > 0 ? calculateContainerRelativeWidth() + '%' : '100%'"
>
  <ng-container *ngIf="isEmptyState; else notEmptyState">
    <ng-container *ngIf="displayZeroRAGs">
      <div
        *ngFor="let ragBreakdown of ragBreakdowns; let i = index; trackBy: trackByMethod"
        class="rag-breakdown empty"
        [style.width]="'20%'"
        [style.margin-right]="i < ragBreakdowns.length - 1 ? '2px' : '0px'"
        [class.clickable]="canClickRag"
        (click)="ragClicked(ragBreakdown)"
      >
      <ng-container *ngIf="displayCount">
        {{ ragBreakdown.count }}
      </ng-container>        <div *ngIf="i < ragBreakdowns.length - 1" class="separator"></div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #notEmptyState>
    <ng-container *ngFor="let ragBreakdown of ragBreakdowns; let i = index; trackBy: trackByMethod">
      <div
        *ngIf="displayZeroRAGs || ragBreakdown.count > 0"
        class="rag-breakdown"
        [class.skip-zero-values]="!displayZeroRAGs && ragBreakdown.count === 0"
        [style.background-color]="ragBreakdown.colour"
        [style.width]="calculateRelativeWidth(ragBreakdown.count) + '%'"
        [style.margin-right]="i < ragBreakdowns.length - 1 && displayZeroRAGs ? '2px' : '0px'"
        [style.opacity]="getRagOpacity(ragBreakdown)"
        (click)="ragClicked(ragBreakdown)"
        [class.clickable]="canClickRag"
      >
      <ng-container *ngIf="displayCount">
        {{ ragBreakdown.count }}
      </ng-container>        <div *ngIf="i < ragBreakdowns.length - 1" class="separator"></div>
      </div>
    </ng-container>
  </ng-template>
</div>
