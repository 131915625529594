import { Injectable, PLATFORM_ID, Inject, NgZone } from '@angular/core';
import { OAuthService, OAuthEvent, AuthConfig } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './auth.config';
import { isPlatformBrowser } from '@angular/common';
import { Observable, from, BehaviorSubject, map } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { Router } from '@angular/router';
import { CachingService, deleteCookie } from '../shared/services/caching.service';
import { WtStorageService } from '../shared/services/wt-storage.service';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
@Injectable()
export class AuthService {
  private loggedOutCookieName: string = 'l_o';
  public isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  private silentRefreshErrType = 'silent_refresh_error';
  private invalideNounce = 'invalid_nonce_in_state';
  private liveEventPromptCookieName = 'liveEventPrompt';
  private discoveryDocumentLoadedEventType = 'discovery_document_loaded';

  constructor(
    private configuraiton: Configuration,
    private oauthService: OAuthService,
    private router: Router,
    private wtStorage: WtStorageService,
    private zone: NgZone,
    private cachingService: CachingService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public init() {
    if (isPlatformBrowser(this.platformId)) {
      this.oauthService.configure(authConfig);
      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
      this.oauthService.setupAutomaticSilentRefresh();
      if (Capacitor.getPlatform() !== 'web') {
        void this.listenForOAuthRedirect();
      }

      this.oauthService.events.subscribe((e) => {
        if (e.type === this.silentRefreshErrType) {
          this.logOut();
        } else if (e.type == this.invalideNounce) {
          void this.router.navigate(['v2/mytrack/planning']);
        }
      });

      this.loadDiscoveryDocument();

      window.addEventListener('storage', ({ key, oldValue, newValue }) => {
        if (key === 'id_token' && !newValue) {
          this.logOut();
        }
      });
    }
  }

  public tryLogin(): Observable<boolean> {
    return from(this.oauthService.tryLogin());
  }

  public refresh(): Observable<OAuthEvent> {
    return from(this.oauthService.silentRefresh());
  }

  public isAuthenticated(): boolean {
    return this.oauthService.hasValidIdToken();
  }

  public login() {
    this.oauthService.initImplicitFlow();
  }

  public logOut() {
    deleteCookie(this.loggedOutCookieName);
    deleteCookie(this.liveEventPromptCookieName);
    this.wtStorage.clear();
    this.oauthService.logOut();
  }

  public get user() {
    return this.oauthService.getIdentityClaims();
  }

  public get config(): AuthConfig {
    return authConfig;
  }

  public getIdentityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  public getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public loadDiscoveryDocument(): Observable<object> {
    return from(this.oauthService.loadDiscoveryDocument());
  }

  public isLoggedout(): boolean {
    const lgcookie = this.getCookie(this.loggedOutCookieName);

    if (lgcookie) return true;

    return false;
  }

  public getLoggedOutCookie(): string {
    return this.getCookie('l_o');
  }

  // Given a cookie key `name`, returns the value of
  // the cookie or `null`, if the key is not found.
  private getCookie(name: string): string {
    const nameLenPlus = name.length + 1;
    return (
      document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((cookie) => {
          return cookie.substring(0, nameLenPlus) === `${name}=`;
        })
        .map((cookie) => {
          return decodeURIComponent(cookie.substring(nameLenPlus));
        })[0] || null
    );
  }

  private async listenForOAuthRedirect() {
    // example of the important stuff
    // const oauthParameters = {
    // responseType: 'code',
    // the IDP must redirect to the App URI (it should be whitelisted)
    // redirectUri: 'custom-app-uri://localhost',
    // open a new browser window when starting implicit flow
    // openUri: url => Browser.open({url})
    // };

    // this.oauthService.configure(oauthParameters);

    void App.addListener('appUrlOpen', async (data) => {
      const platform = (await Device.getInfo()).platform;

      // because reasons
      if (platform === 'ios') {
        await Browser.close();
      }

      await this.zone.run(async () => {
        await this.oauthService.tryLogin({ customHashFragment: data.url.substr(data.url.indexOf('#')) }).then((res: boolean) => {
          this.isAuthenticatedSubject$.next(true);
          if (res) {
            this.isAuthenticatedSubject$.next(true);
          } else {
            this.router.navigate(['/']);
          }

          return false;
        });

        const hasValidAccessToken = await this.oauthService.hasValidAccessToken();

        if (hasValidAccessToken) {
          await this.router.navigate(['/v2/mytrack/planning']);
        }
      });
    });
  }



  private handleTryLogin(res: boolean): boolean {
    if (res) {
      this.isAuthenticatedSubject$.next(true);
    } else {
      void this.router.navigate(['/'], {onSameUrlNavigation: 'reload'});
    }

    return false;
  }

  public handleLogin() {
    return this.tryLogin().pipe(map((e) => this.handleTryLogin(e)))
  }
}
