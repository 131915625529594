import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TimeZoneService } from '../../../services/timeZone.service';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativeTimepickerComponent implements AfterViewInit {
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  @Input() placeholder: string;
  @Input() date: string;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() disabled: boolean;
  @Input() disableMargin = false;

  @Output() timeChanged = new EventEmitter<string>();

  public isFieldDirty = false;

  constructor(private readonly timeZoneService: TimeZoneService, private readonly changeDetectionRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
  }

  onChangeNative() {
    if (!this.isFieldDirty) {
      this.isFieldDirty = true;
    }

    if (!this.valid) {
      return;
    }

    const [hours, minutes] = this.input.nativeElement.value.split(':');
    // 1 parse timezone and set hours and minutes
    // 2 then parse to ISO and emit
    let momentDate: any;

    if (this.date) {
      momentDate = this.timeZoneService.getCurrentMomentInLocalisedTimezone(this.date);
    } else {
      momentDate = this.timeZoneService.getCurrentMomentInLocalisedTimezone();
      this.date = momentDate.toDate();
    }

    const dateWithHoursAndMinutes = momentDate.set({ hour: +hours, minute: +minutes });

    this.timeChanged.emit(dateWithHoursAndMinutes.toISOString());
  }

  get formattedValue(): string {
    if (this.date) {
      return this.timeZoneService.localiseDateISOStringByCustomFormat(this.date, 'HH:mm');
    }
    return '';
  }

  get minHour(): string {
    if (!this.minDate) {
      return '';
    }

    return this.timeZoneService.localiseDateISOStringByCustomFormat(this.minDate.toISOString(), 'HH');
  }

  get minMinute(): string {
    if (!this.minDate) {
      return '';
    }

    return this.timeZoneService.localiseDateISOStringByCustomFormat(this.minDate.toISOString(), 'mm');
  }

  get minTime(): string {
    if (!this.minDate) {
      return '';
    }

    return `${this.minHour}:${this.minMinute}:00`;
  }

  get maxHour(): string {
    if (!this.maxDate) {
      return '';
    }

    return this.timeZoneService.localiseDateISOStringByCustomFormat(this.maxDate.toISOString(), 'HH');
  }

  get maxMinute(): string {
    if (!this.maxDate) {
      return '';
    }

    return this.timeZoneService.localiseDateISOStringByCustomFormat(this.maxDate.toISOString(), 'mm');
  }

  get maxTime(): string {
    if (!this.maxDate) {
      return '';
    }

    return `${this.maxHour}:${this.maxMinute}:00`;
  }

  get valid(): boolean {
    if (!this.input) {
      return false;
    }

    const [hours, minutes] = this.input.nativeElement.value.split(':');

    if (!hours || !minutes) {
      return !this.isFieldDirty;
    }

    const currentTimelessDate = new Date(this.date);

    currentTimelessDate.setHours(0);
    currentTimelessDate.setMinutes(0);
    currentTimelessDate.setSeconds(0);
    currentTimelessDate.setMilliseconds(0);

    const minTimelessDate = new Date(this.minDate);

    minTimelessDate.setHours(0);
    minTimelessDate.setMinutes(0);
    minTimelessDate.setSeconds(0);
    minTimelessDate.setMilliseconds(0);

    if (currentTimelessDate.getTime() <= minTimelessDate.getTime()) {
      if (this.input.nativeElement.validity.rangeUnderflow || this.input.nativeElement.validity.rangeOverflow) {
        return false;
      }
    }

    return true;
  }
}
