<app-modal>
  <app-modal-header [title]="'Add New External User'" (closed)="onClose()"></app-modal-header>

  <app-modal-body>
    <div class="row" [formGroup]="form">
      <div class="col-12 mb-2">
        <app-text-field
          formControlName="firstName"
          [useReactiveMessages]="true"
          [placeholder]="'First Name*'"
          [formGroup]="form"
          [required]="true"
        >
        </app-text-field>
      </div>
      <div class="col-12 mb-2">
        <app-text-field
          formControlName="surname"
          [useReactiveMessages]="true"
          [placeholder]="'Last Name*'"
          [formGroup]="form"
          [required]="true"
        >
        </app-text-field>
      </div>
      <div class="col-12 mb-2">
        <app-text-field
          formControlName="username"
          [useReactiveMessages]="true"
          [placeholder]="'Email Address*'"
          [formGroup]="form"
          [required]="true"
          [type]="'email'"
        >
        </app-text-field>
      </div>
    </div>
  </app-modal-body>

  <app-modal-footer>
    <app-button-rectangle
      class="modal-close"
      [text]="'Cancel'"
      [size]="'large'"
      class="mr-8"
      [buttonTheme]="'white'"
      (clicked)="onClose()"
    ></app-button-rectangle>
    <app-button-rectangle
      class="modal-add"
      [text]="'Add User'"
      [size]="'large'"
      (click)="onAdd()"
      [disabled]="!form.valid"
    ></app-button-rectangle>
  </app-modal-footer>
</app-modal>
