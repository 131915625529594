import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';

export class RunsheetViewModel extends ModifiableEntityViewModel {
  id: number;
  refCode: string;
  title: string;
  date: string;
  notes: string;
  filters: FilterViewModel[];
  permissionFilters: FilterViewModel[];
  archived: boolean;
  created: string;
  eventId: number;
  itemsCount: number;
}
