import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { IncidentMapComponent } from 'src/app/modules/shared/components/common/maps/incident-map/incident-map.component';
import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { GMapUtilities } from '../../../utilities/gMap.utilities';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LocationDetailsEditModalComponent } from 'src/app/modules/planning/components/modals/location-details-edit-modal/location-details-edit-modal.component';
import { LocationAndFiltersViewModel } from '../../../viewModels/locationAndFiltersViewModel';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { RiskTypes } from 'src/app/modules/risk/enums/riskTypes';

@Component({
  selector: 'app-location-details',
  templateUrl: 'location-details.component.html',
  styleUrls: ['location-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDetailsComponent implements OnInit, OnDestroy {
  /**
   * @deprecated
   * Use markerType instead and remove everywhere currently used
   */
  @Input() objectType: ObjectTypes;
  @Input() objectSubType: RiskTypes | IncidentItemTypes;
  @Input() objectId: number;
  @Input() filters: FilterViewModel[] = [];
  @Input() markerColor: string;
  @Input() markerType: MarkerType;
  @Input() lat: number;
  @Input() lng: number;
  @Input() locationDetails: string;
  @Input() showEditButton = false;
  @Input() editButtonEnabled = false;
  @Input() displayFiltersAsLozenges = true;

  @Output() locationUpdated = new EventEmitter<LocationAndFiltersViewModel>();


  private subscriptions: Subscription[] = [];
  public objectTypes = ObjectTypes;
  public filterTypes = FilterTypes;
  public bsModalRef: BsModalRef;
  public readonly T = T;

  @ViewChild('incidentMap', { static: false }) incidentMapComponent: IncidentMapComponent;

  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly modalService: BsModalService,
  ) {}

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.subscriptions = [];
    }
  }

  ngOnInit(): void {
    this.markerType = this.markerType || GMapUtilities.getMarkerTypeFromObjectType(this.objectType, this.objectSubType);
  }

  get locationAvailable() {
    return !!this.lat && !!this.lng && this.lat !== 0 && this.lng !== 0;
  }

  get zonesLabels(): string {
    const currentZones = this.filters
      .filter((f) => f.filterType === FilterTypes.Zone)
      .map((f) => f.filterValue);
    return this.allowedFiltersService
      .getCachedAllowedFiltersByType(FilterTypes.Zone)
      .filter((f) => currentZones.includes(f.filterValue))
      .map((f) => f.filterText)
      .join(', ');
  }

  get areasLabels(): string {
    const currentZones = this.filters
      .filter((f) => f.filterType === FilterTypes.Area)
      .map((f) => f.filterValue);
    return this.allowedFiltersService
      .getCachedAllowedFiltersByType(FilterTypes.Area)
      .filter((f) => currentZones.includes(f.filterValue))
      .map((f) => f.filterText)
      .join(', ');
  }


  public openLocationEditModal() {
    const modalConfig: ModalOptions<LocationDetailsEditModalComponent> = {
      backdrop: true,
      ignoreBackdropClick: true,
      class: 'modal-lg',
      animated: true,
      initialState: {
        filters: this.filters,
        locationDetails: this.locationDetails,
        latitude: this.lat,
        longitude: this.lng,
        markerColor: this.markerColor,
        markerType: MarkerType.INCIDENT,
      },
    };

    this.bsModalRef = this.modalService.show(LocationDetailsEditModalComponent, modalConfig);

    const context = this.bsModalRef.content as LocationDetailsEditModalComponent;
    context.onSave.subscribe((res: LocationAndFiltersViewModel) => {
      if(res) {
        this.lat = res.latitude;
        this.lng = res.longitude;
        this.locationDetails = res.locationDetails;
        this.filters = res.filters;

        const returnModel: LocationAndFiltersViewModel = {
          filters: res.filters,
          latitude: res.latitude,
          longitude: res.longitude,
          locationDetails: res.locationDetails,
        };

        this.locationUpdated.next(returnModel);
      }
    })
  }
}
