import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { WtValidators } from '../../../reactiveValidators/wtValidators';

@Component({
  selector: 'app-quick-add-input',
  templateUrl: './quick-add-input.component.html',
  styleUrls: ['./quick-add-input.component.scss'],
})
export class QuickAddInputComponent {
  @Input() placeholder: string;

  @Output() titleChange: EventEmitter<string> = new EventEmitter();

  public quickFieldFormControl: UntypedFormControl = new UntypedFormControl('', { validators: [this.wtValidators.title(3, 80)] });

  constructor(private readonly wtValidators: WtValidators) {}

  isFocused(element: HTMLElement): boolean {
    if (!element) {
      return;
    }

    return element === document.activeElement;
  }

  onClearInput(input: HTMLInputElement, focus: boolean = true) {
    // this.quickFieldFormControl.setValue('');
    this.quickFieldFormControl.reset('');

    if (focus) {
      input.focus();
    }
  }

  onEnter() {
    if (this.quickFieldFormControl.valid) {
      this.titleChange.emit(this.quickFieldFormControl.value);
      this.quickFieldFormControl.reset('');
    }
  }

  onAddDetails() {}
}
