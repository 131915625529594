import { DashboardInfoWidgetViewModel } from 'src/app/modules/settings/viewModels/dashboardInfoWidgetViewModel';
import { ModifiableEntityViewModel } from '../bases/modifiableEntityViewModel';
import { EventStatuses } from 'src/app/modules/events/enums/eventStatuses';

export class EventViewModel extends ModifiableEntityViewModel {
  public startDate: string;
  public endDate: string;
  public isVenue: boolean;
  public capacity: number;
  public duration: string;
  public eventStatus: EventStatuses;
  public dashboardWidgetInfo: DashboardInfoWidgetViewModel;
  public isSelected: boolean;
}
