import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { ReportTypesViewModel } from '../viewModels/reportTypesViewModel';
import { Observable, of } from 'rxjs';
import { ReportTypesRequest } from '../viewModels/reportTypesRequest';
import { ReportTypeNew } from '../../shared/enums/reports/reportTypeNew';
import { ReportItemViewModel } from '../viewModels/reportItemViewModel';
import { ReportItemSectionViewModel } from '../viewModels/reportItemSectionViewModel';
import { KeyValueItem } from '../../shared/models/keyValueItem';
import { CachingService } from '../../shared/services/caching.service';
import { ReportStatus } from '../enums/reportStatus.enum';
import { ReportPreviewSectionViewModel } from '../viewModels/reportPreviewSectionViewModel';
import { DownloadedFileViewModel } from '../../shared/models/downloadedFileViewModel';
import { OutputFormats } from '../../shared/enums/outputFormats';
import { LocalisationService } from '../../shared/services/localisation.service';
import { ReportingColumnResponse } from '../viewModels/reportingColumn.response';
import { map } from 'rxjs/operators';
import { ExportPdfViewModel } from '../viewModels/exportPdfViewModel';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private actionUrl: string;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private cacheService: CachingService,
    private localisationService: LocalisationService
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Reporting/`);
  }

  download(outPutFormat: OutputFormats, reportItemId: number, reportItemSectionId: number): Observable<DownloadedFileViewModel> {
    return this.http.get<DownloadedFileViewModel>(
      `${this.actionUrl}export/${outPutFormat}/${reportItemId}/${reportItemSectionId}`
    );
  }

  exportRunsheet(runsheetId: number, outputFormat: OutputFormats = OutputFormats.PDF): Observable<DownloadedFileViewModel> {
    return this.http.get<DownloadedFileViewModel>(`${this.actionUrl}ExportRunsheet/${runsheetId}?outputFormat=${outputFormat}`);
  }

  exportPdfDetails(exportPdfViewModel: ExportPdfViewModel): Observable<DownloadedFileViewModel> {
    return this.http.post<DownloadedFileViewModel>(`${this.actionUrl}PdfDetails`, exportPdfViewModel);
  }

  share(reportItem: ReportItemViewModel): Observable<ReportItemViewModel> {
    return this.http.post<ReportItemViewModel>(`${this.actionUrl}share`, reportItem);
  }

  getAllSectionsByReportId(reportItemId: number): Observable<ReportItemSectionViewModel[]> {
    return this.http.get<ReportItemSectionViewModel[]>(`${this.actionUrl}sections/${reportItemId}`);
  }

  getAllReports(): Observable<ReportItemViewModel[]> {
    return this.http.get<ReportItemViewModel[]>(`${this.actionUrl}items`);
  }

  getAllReportsByStatus(reportStatus: ReportStatus): Observable<ReportItemViewModel[]> {
    return this.http.get<ReportItemViewModel[]>(`${this.actionUrl}items/reportStatus/${reportStatus}`);
  }

  deleteReportItem(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.actionUrl}item/${id}`);
  }

  addReportItem(model: ReportItemViewModel): Observable<ReportItemViewModel> {
    return this.http.post<ReportItemViewModel>(`${this.actionUrl}item`, model);
  }

  addAsFavouriteReport(model: ReportItemViewModel): Observable<ReportItemViewModel> {
    return this.http.post<ReportItemViewModel>(`${this.actionUrl}addtofavourite`, model);
  }

  unsubscribeFromReportItem(id: number): Observable<number> {
    return this.http.post<number>(`${this.actionUrl}unsubscribe/${id}`, id);
  }

  addMultipleReportItems(reports: ReportItemViewModel[]) {
    return this.http.post<ReportItemViewModel>(`${this.actionUrl}multipleReportItems`, reports);
  }

  updateReportItem(model: ReportItemViewModel): Observable<ReportItemViewModel> {
    return this.http.put<ReportItemViewModel>(`${this.actionUrl}item`, model);
  }

  getReportItemById(id: number): Observable<ReportItemViewModel> {
    return this.http.get<ReportItemViewModel>(`${this.actionUrl}item/${id}`);
  }

  updateReportItemTitleAndDescription(model: ReportItemViewModel): Observable<ReportItemViewModel> {
    return this.http.post<ReportItemViewModel>(`${this.actionUrl}item/exportDetails`, model);
  }

  updateAndAddReportItemSection(model: ReportItemSectionViewModel): Observable<ReportItemSectionViewModel[]> {
    return this.http.post<ReportItemSectionViewModel[]>(`${this.actionUrl}item/section/addUpdate`, model);
  }

  /** Report Item Section  */

  deleteReportItemSection(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.actionUrl}item/section/${id}`);
  }

  addReportItemSection(model: ReportItemSectionViewModel): Observable<ReportItemSectionViewModel> {
    return this.http.post<ReportItemSectionViewModel>(`${this.actionUrl}item/section`, model);
  }

  updateReportItemSection(model: ReportItemSectionViewModel): Observable<ReportItemSectionViewModel> {
    return this.http.put<ReportItemSectionViewModel>(`${this.actionUrl}item/section`, model);
  }

  getReportItemSectionById(id: number): Observable<ReportItemSectionViewModel> {
    return this.http.get<ReportItemSectionViewModel>(`${this.actionUrl}item/section/${id}`);
  }

  getExtraRowTypes(employeeId: number, reportTypeID: number): Observable<KeyValueItem[]> {
    const data = this.cacheService.getReportExtraRow(reportTypeID);

    if (!data) {
      return this.http.get<KeyValueItem[]>(`${this.actionUrl}build/extrarow/types/${employeeId}/${reportTypeID}`).pipe(
        map((a) => {
          this.cacheService.addReportExtraRow(reportTypeID, a);
          return a;
        })
      );
    }

    return of(data);
  }

  getExtraRowSubTypes(employeeId: number, reportBuilderTypeId: number): Observable<KeyValueItem[]> {
    return this.http.get<KeyValueItem[]>(`${this.actionUrl}build/extrarow/subtype/${employeeId}/${reportBuilderTypeId}`);
  }

  getReportTypes(employeeId: number, objectTypes: ObjectTypes[]): Observable<ReportTypesViewModel> {
    const model = new ReportTypesRequest();
    model.employeeId = employeeId;
    model.objectTypes = objectTypes;

    return this.http.post<ReportTypesViewModel>(`${this.actionUrl}types`, model).pipe(
      map((a) => {
        this.cacheService.addReportTypes(objectTypes, a);
        return a;
      })
    );
  }

  getColumnsByReportType(
    reportType: ReportTypeNew,
    objectType: ObjectTypes,
    reportItemSectionID: number
  ): Observable<ReportingColumnResponse> {
    return this.http.get<ReportingColumnResponse>(
      `${this.actionUrl}type/columns/${reportType}/${objectType}/${reportItemSectionID}`
    );
  }

  getReportPreview(reportItemId: number, reportItemSectionId: number): Observable<ReportPreviewSectionViewModel> {
    return this.http.get<ReportPreviewSectionViewModel>(`${this.actionUrl}preview/${reportItemId}/${reportItemSectionId}`);
  }

  getReportTitleByReportType(item: ReportTypeNew): string {
    if (item) {
      return item === ReportTypeNew.Action_Steps
        ? 'Actions (Risks & Issues) '
        : this.localisationService.localise(ReportTypeNew[item].toString().replace(/_/g, ' '));
    }
    return '';
  }
}
