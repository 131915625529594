import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { TableHeader, DataType } from '../../common/responsive-table/responsive-table/responsive-table.component';
import { EmployeeCustomGanttSettingViewModel } from '../../../models/gantt/ganttChartLayoutViewConfig';
import { GanttEventsEmitter } from '../../../events/gantt.events';

@Component({
  selector: 'app-gantt-layouts-view-settings-modal',
  templateUrl: './gantt-layouts-view-settings-modal.component.html',
  styleUrls: ['./gantt-layouts-view-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GanttLayoutsViewSettingsModalComponent implements OnInit, OnDestroy {
  onLayoutDelete = new EventEmitter<EmployeeCustomGanttSettingViewModel>();

  @ViewChild('publicTemplate') publicTemplate: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<ElementRef<HTMLElement>>;

  private _deletableLayouts: EmployeeCustomGanttSettingViewModel[] = [];
  private subscriptions = new Subscription();

  public layouts: EmployeeCustomGanttSettingViewModel[] = [];
  public isLayoutChanged: boolean = false;
  public readonly T = T;
  public readonly moduleTypes = ModuleTypes;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    private readonly ganttEventsEmitter: GanttEventsEmitter,
  ) {}

  ngOnInit(): void {
    this._deletableLayouts = this.layouts.slice();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onClose() {
    return this.bsModalRef.hide();
  }

  public get deletableLayouts(): EmployeeCustomGanttSettingViewModel[] {
    return this._deletableLayouts;
  }

  public deleteLayout(layout: EmployeeCustomGanttSettingViewModel) {
    this._deletableLayouts = this._deletableLayouts.filter((x) => x.id !== layout.id);
    this.changeDetectorRef.detectChanges();

    this.onLayoutDelete.next(layout);
  }

  get tableHeaders(): TableHeader[] {
    const headers = [
      {
        dataType: DataType.String,
        title: this.translateService.instant(T.common.saved_view_title),
        property: 'title',
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.common.public),
        template: this.publicTemplate,
        style: {
          textAlign: 'center',
          width: '100px'
        }
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.common.action.many),
        template: this.actionsTemplate,
        style: {
          textAlign: 'center',
          width: '100px'
        }
      },
    ];

    return headers;
  }

  public dashboardLayoutUpdated(widgets) {
    this.isLayoutChanged = true;
  }
}
