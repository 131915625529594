export enum RiskImpact {
  Negligible = 1,
  Minor = 2,
  Moderate = 3,
  Major = 4,
  Extreme = 5,
}

export namespace RiskImpact {
  export function names() {
    return Object.keys(RiskImpact)
      .filter((type) => isNaN(<any>type) && type !== 'values')
      .map(function (e) {
        return e.replace('_', ' ');
      });
  }
  export function values() {
    const a: any = [];
    for (const enumMember in RiskImpact) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push(RiskImpact[enumMember]);
      }
    }
    return a;
  }
  export function items(): { key: RiskImpact; value: string }[] {
    const a: { key: RiskImpact; value: string }[] = [];
    for (const enumMember in RiskImpact) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: RiskImpact[enumMember].replace(/_/g, ' ') });
      }
    }
    return a;
  }
}
