/* eslint-disable @typescript-eslint/no-namespace */
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum ChecklistResponseTypes {
  Mark_as_Complete = 1,
  Text_Response = 2,
  Options_Set_1 = 3,
  Options_Set_2 = 4,
  Numeric_Response = 5,
}

export namespace ChecklistResponseTypes {
  export function translatedItems(translateService: TranslateService): { key: ChecklistResponseTypes; value: string }[] {
    const a: { key: ChecklistResponseTypes; value: string }[] = [];
    for (const enumMember in ChecklistResponseTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translateItem(translateService, +enumMember) });
      }
    }
    return a;
  }
}
function translateItem(translateService: TranslateService, key: ChecklistResponseTypes): string {
  switch (key) {
    case ChecklistResponseTypes.Mark_as_Complete:
      return translateService.instant(T.control.mark_as_complete);
    case ChecklistResponseTypes.Text_Response:
      return translateService.instant(T.control.text_response);
    case ChecklistResponseTypes.Options_Set_1:
      return translateService.instant(T.control.fail_ensure_pass);
    case ChecklistResponseTypes.Options_Set_2:
      return translateService.instant(T.control.poor_fair_good);
    case ChecklistResponseTypes.Numeric_Response:
      return translateService.instant(T.control.numeric_response);
    default:
      return 'n/a';
  }
}

export enum ChecklistOptionSet1 {
  Fail = 1,
  Unsure = 2,
  Pass = 3,
}

export namespace ChecklistOptionSet1 {
  export function names() {
    return Object.keys(ChecklistOptionSet1)
      .filter((type) => isNaN(<any>type) && type !== 'values')
      .map(function (e) {
        return e.replace('_', ' ');
      });
  }
  export function values() {
    const a: any = [];
    for (const enumMember in ChecklistOptionSet1) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push(ChecklistOptionSet1[enumMember]);
      }
    }
    return a;
  }
  export function items(): { key: ChecklistOptionSet1; value: string }[] {
    const a: { key: ChecklistOptionSet1; value: string }[] = [];
    for (const enumMember in ChecklistOptionSet1) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: ChecklistOptionSet1[enumMember].replace(/_/g, ' ') });
      }
    }
    return a;
  }
}

export enum ChecklistOptionSet2 {
  Poor = 1,
  Fair = 2,
  Good = 3,
}

export namespace ChecklistOptionSet2 {
  export function names() {
    return Object.keys(ChecklistOptionSet2)
      .filter((type) => isNaN(<any>type) && type !== 'values')
      .map(function (e) {
        return e.replace('_', ' ');
      });
  }
  export function values() {
    const a: any = [];
    for (const enumMember in ChecklistOptionSet2) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push(ChecklistOptionSet2[enumMember]);
      }
    }
    return a;
  }
  export function items(): { key: ChecklistOptionSet2; value: string }[] {
    const a: { key: ChecklistOptionSet2; value: string }[] = [];
    for (const enumMember in ChecklistOptionSet2) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: ChecklistOptionSet2[enumMember].replace(/_/g, ' ') });
      }
    }
    return a;
  }
}
