import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { ObjectEventEmitters } from 'src/app/modules/shared/events/object.events';
import { DetailsViewBaseClass } from 'src/app/modules/shared/interfaces/detailsViewBase';
import { HasWritePermissionPipe } from 'src/app/modules/shared/pipes/has-write-permission.pipe';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { PinningService } from 'src/app/modules/shared/services/pinning.service';
import { SubscriptionService } from 'src/app/modules/shared/services/subscription.service';
import { ModalUtilityService } from 'src/app/modules/shared/services/utilities/modals-utilities.service';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { UpdateTypes } from 'src/app/modules/shared/enums/updateTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { SortDropdownItem } from 'src/app/modules/shared/models/sortDropdownItem';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import * as moment from 'moment';
import { ActionRiskService } from 'src/app/modules/planning/services/action-risk.service';
import { RiskDetailsViewModel } from 'src/app/modules/risk/models/RiskDetailsViewModel';
import { RiskActionDetailsViewModel } from 'src/app/modules/risk/models/riskActionDetailsViewModel';
import { RiskActionStatuses } from '../../../enums/riskActionStatusType.enum';

@Component({
  selector: 'app-risk-action-details-modal',
  templateUrl: './risk-action-details-modal.component.html',
  styleUrls: ['./risk-action-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskActionDetailsModalComponent extends DetailsViewBaseClass implements OnInit, OnDestroy {
  public sortDropdownItems: SortDropdownItem[] = [];
  public filters: FilterViewModel[] = [];
  public editableFieldTypes = EditableFieldTypes;
  public filterTypes = FilterTypes;
  // public readonly riskTypes = RiskTypes;
  public currentDetailsItem: RiskActionDetailsViewModel = new RiskActionDetailsViewModel();
  public risk: RiskDetailsViewModel = new RiskDetailsViewModel();
  public localisedItem: string = 'Action';
  public localisedRisk: string = 'Risk';
  public localisedRiskAction: string = 'Risk Action';
  public cardFilters: FilterViewModel[] = [];
  public collapsed: boolean = false;
  public actionItemObjectType = ObjectTypes.Risk_Action_Item;
  public commentType = UpdateTypes.Risk_Action_Comment_Added;
  public activityItemsTypes: ObjectTypes[] = [ObjectTypes.Risk_Action_Item];
  public userHasWriteAccess: boolean = true;
  public canEditRisk: boolean = false;
  public readonly moduleTypes = ModuleTypes;
  public actionId: number;
  public readonly objectTypes = ObjectTypes;
  public historyExpanded = false;

  constructor(
    private readonly actionService: ActionRiskService,
    protected readonly route: ActivatedRoute,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly router: Router,
    protected readonly confirmationService: ConfirmationService,
    protected readonly alertService: AlertService,
    protected readonly authenticationService: AuthenticationService,
    protected readonly localisationService: LocalisationService,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly hasWritePermissionPipe: HasWritePermissionPipe,
    protected readonly pinningService: PinningService,
    protected readonly subscriptionService: SubscriptionService,
    protected readonly bsModalService: BsModalService,
    protected readonly utilityModalService: ModalUtilityService,
    protected readonly translateService: TranslateService,
    public readonly bsModalRef: BsModalRef
  ) {
    super(
      ObjectTypes.Risk_Action_Item,
      localisationService,
      changeDetectorRef,
      bsModalService,
      utilityModalService,
      authenticationService,
      subscriptionService,
      objectEventEmitters,
      alertService,
      confirmationService,
      router,
      route,
      hasWritePermissionPipe,
      pinningService,
      translateService
    );
  }

  ngOnInit(): void {
    this.localisedItem = this.localisationService.localiseObjectType(ObjectTypes.Risk_Action_Item);
    this.localisedRisk = this.localisationService.localiseObjectType(ObjectTypes.Risk);
    this.localisedRiskAction = this.localisationService.localiseObjectType(ObjectTypes.Risk_Action_Item);

    this.loading = true;
    this.getActionDetails(this.actionId);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getActionDetails(id) {
    this.subscriptions.add(
      this.actionService.getDetails(id).subscribe((res) => {
        this.currentDetailsItem = res;
        this.initIsArchived();
        const riskFilters = res.filters.filter(f => f.filterType === FilterTypes.Risk);
        riskFilters.forEach(filter => {
          if(!this.canEditRisk) {
            const riskId = +filter.filterValue;
            this.canEditRisk = this.hasWritePermissionPipe.transform(
              { id: riskId, filters: res.filters, permissionFilters: res.permissionFilters },
              ModuleTypes.Planning,
              FilterTypes.Risk
            );
          }
        })

        this.loading = false;
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  openArchiveModal(archive: boolean) {
    let message = this.confirmUnarchiveText;
    if (archive) {
      message = this.confirmArchiveText;
    }

    this.confirmationService.confirmThis(
      message,
      () => {
        this.archive(archive);
      },
      () => {
        // handle cancel
      }
    );
  }

  archive(archive: boolean) {
    this.actionService.archive(this.currentDetailsItem.id, archive).subscribe((res) => {
      void this.alertService.success(archive ? this.itemArchivedText : this.itemUnarchivedText);
      this.getActionDetails(this.currentDetailsItem.id);
    });
  }

  deleteAction() {
    this.confirmationService.confirmThis(
      this.confirmDeleteText,
      () => {
        this.actionService.delete(this.currentDetailsItem.id).subscribe((res) => {
          void this.alertService.success(this.itemDeletedText);
          this.closeModal();
        });
      },
      () => {
        //handle cancel
      }
    );
  }

  saveChanges() {
    this.subscriptions.add(
      this.actionService.update(this.currentDetailsItem).subscribe((res) => {
        void this.alertService.success(this.itemUpdatedText);
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  validateUrl(id: string): boolean {
    let isValidUrl = false;
    if (this.router.url.endsWith(id)) {
      isValidUrl = true;
    }
    return isValidUrl;
  }

  onDueDateChange(dateAsIsoString: string) {
    this.currentDetailsItem.dueDate = dateAsIsoString;
    this.changeDateFilterValue(dateAsIsoString, 'DueDate');
    this.saveChanges();
    this.changeDetectorRef.detectChanges();
  }

  changeDateFilterValue(dateAsIsoString: string, filterDropdownTitle: string) {
    // Using filterDropdownTitle because to this moment every date filter uses filterType 113
    // Change that in future when we have different filterTypes for every dateType
    const parsedDate = dateAsIsoString;
    const currentFilter = this.currentDetailsItem.filters.find((f) => f.filterDropdownTitle === filterDropdownTitle);
    if (currentFilter) {
      currentFilter.filterValue = parsedDate;
    } else {
      this.currentDetailsItem.filters.push(this.generateDateFilter(parsedDate, filterDropdownTitle));
    }
  }

  generateDateFilter(dateString: string, dropdownTitle: string) {
    const splitWithUnderscore = dropdownTitle.replace(/([a-z0-9])([A-Z])/g, '$1_$2');

    const dateFilter = {
      colour: null,
      dateProperty: 12,
      displayForGlobalObjectType: 0,
      exclude: false,
      filterDropdownTitle: dropdownTitle,
      filterSelectorType: 4,
      filterText: dateString,
      filterType: 113,
      filterValue: dateString,
      icon: null,
      id: `Date:${splitWithUnderscore}:${dateString}`,
      isActive: false,
      isModalReadOnly: false,
      isPrimary: false,
      relatedObjectid: 0,
      viewOrder: 2147483647,
      visibility: 0,
    };

    return dateFilter as any;
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  get isDueToday(): boolean {
    if(!this.currentDetailsItem.dueDate) return false;

    return moment(this.currentDetailsItem.dueDate).isSame(moment(), 'day');
  }
  public onItemIndexClicked(index: number) {
    if(index === 0) {
      this.historyExpanded = !this.historyExpanded;
    }
  }

  public get isComplete(): boolean {
    return this.currentDetailsItem.status === RiskActionStatuses.Complete;
  }
}
