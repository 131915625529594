<app-modal>
  <app-modal-header [title]="title" (closed)="onCancel()"> </app-modal-header>

  <app-modal-body>
    <form [formGroup]="documentDetailsForm">
      <div class="row">
        <div class="col-12 mb-32" *ngIf="uploadDocumentViewModel.uploadType === uploadTypes.Hyperlink">
          <app-text-field
            [formGroup]="documentDetailsForm"
            [formControlName]="'link'"
            [placeholder]="T.common.link.one | translate"
            [maxLength]="255"
            [autofocus]="uploadDocumentViewModel.uploadType === uploadTypes.Hyperlink"
          ></app-text-field>
        </div>
        <div class="col-12 mb-32">
          <app-text-field
            [formGroup]="documentDetailsForm"
            [formControlName]="'title'"
            [placeholder]="T.defaultLocalizations.title.one | translate"
            [maxLength]="80"
            [autofocus]="uploadDocumentViewModel.uploadType !== uploadTypes.Hyperlink"
          ></app-text-field>
        </div>
        <div class="col-12 mb-32">
          <app-text-field
            [formGroup]="documentDetailsForm"
            [formControlName]="'description'"
            [placeholder]="T.defaultLocalizations.description.one | translate"
            [maxLength]="200"
          ></app-text-field>
        </div>
        <div class="col-12">
          <app-filter-dropdown-new
            [filterType]="filterType"
            [appliedFilters]="filterSettings"
            [singleSelect]="true"
            [typeahead]="true"
            [label]="T.common.add_item | translate: { item: T.defaultLocalizations.document_category.one | translate }"
            (onFilterUpdate)="onCategorySelect($event)"
          >
          </app-filter-dropdown-new>
        </div>
      </div>
      <!-- enable when privacy functionallity sorted -->
      <!-- <div class="col-12 mb-2">
                <app-search-dropdown [items]="privacyStatuses" [bindProperty]="'value'" [label]="'Privacy Status'"
                    [currentlySelectedItem]="privacyStatus" (itemSelected)="onPrivacyStatusSelected($event, ind)">
                </app-search-dropdown>
            </div> -->
    </form>
  </app-modal-body>
  <app-modal-footer>
    <app-button [type]="'block'" [text]="T.common.cancel | translate" (clicked)="onCancel()"></app-button>
    <app-button
      [type]="'block'"
      [buttonTheme]="'primary'"
      [text]="T.common.save | translate"
      [disabled]="!documentDetailsForm.valid"
      (clicked)="onConfirm()"
    ></app-button>
  </app-modal-footer>
</app-modal>
