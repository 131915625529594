import { DropdownOption } from "./suggestedRiskFilterDropdown.type"

export enum SuggestedRiskQuickFilterNames {
  Category = 'riskCategory',
  Relevance = 'relevance',
  Impact = 'impactRange',
  RiskImpactType = 'riskImpactType',
}

export type SuggestedRiskAppliedFilters = {
  impactRange: DropdownOption[],
  likelihoodRange: DropdownOption[],
  riskCategory: DropdownOption[],
  relevance: DropdownOption[],
  riskImpactType: DropdownOption[],
  keywords: string[],
  limit: number,
  venueType: DropdownOption[],
  eventType: DropdownOption[],
}
