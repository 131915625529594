import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ZonesAreasButtonState } from 'src/app/modules/shared/enums/maps/zonesButtonState';
import { Constants } from 'src/app/modules/shared/models/constants';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';

@Component({
  selector: 'app-zone-area-toggle-button',
  templateUrl: './zone-area-toggle-button.component.html',
  styleUrls: ['./zone-area-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoneAreaToggleButtonComponent implements OnInit {
  @ViewChild('icon', { static: true }) buttonIconElement: ElementRef<HTMLElement>;

  @Input() map: google.maps.Map = null;
  @Input() enableStateChange = true;
  @Input() zonesPolygon: google.maps.Polygon[];
  @Input() areasPolygon: google.maps.Polygon[];

  @Output() onButtonStateChanged = new EventEmitter<ZonesAreasButtonState>();

  @Output() zonesPolygonsCreated = new EventEmitter<Map<number, google.maps.Polygon>>();
  @Output() areasPolygonsCreated = new EventEmitter<Map<number, google.maps.Polygon>>();
  @Output() polygonsCreated = new EventEmitter<{
    zonePolygons: Map<number, google.maps.Polygon>,
    areaPolygons: Map<number, google.maps.Polygon>
  }>();

  private zonesAreasButtonState: ZonesAreasButtonState = ZonesAreasButtonState.ZONES_AREAS_DISPLAYED;
  private displayZonesOnlyLabel = 'Click to display zones layer';
  private displayZonedAndAreasLabel = 'Click to display both zones and areas layer';
  private hideZonedAndAreasLabel = 'Click to hide zones and areas layer';

  private _title: string = this.displayZonesOnlyLabel;

  // Indicator if zones are toggled on/off through the control UI layer.
  private zonesVisible = true;
  private areasVisible = true;

  public isMobile = false;
  public localisedZone = 'Zone';
  public localisedArea = 'Area';

  constructor(
    private readonly localisationService: LocalisationService,
  ) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < Constants.xs;

    this.zonesAreasButtonState = this.getInitialButtonState();

    this.localisedZone = this.localisationService.localiseObjectType(ObjectTypes.Zone);
    this.localisedArea = this.localisationService.localiseObjectType(ObjectTypes.Area);
  }

  get isZonesDisplayed(): boolean {
    return (
      this.zonesAreasButtonState === ZonesAreasButtonState.ZONES_DISPLAYED ||
      this.zonesAreasButtonState === ZonesAreasButtonState.ZONES_AREAS_DISPLAYED
    );
  }

  get isAreasDisplayed(): boolean {
    return this.zonesAreasButtonState === ZonesAreasButtonState.ZONES_AREAS_DISPLAYED;
  }

  get buttonClassname(): string {
    const zonesOnlySelectedClass = 'zones';
    const zonesAndAreasSelectedClass = 'zones-areas';
    const inactiveClass = 'inactive';

    return this.zonesAreasButtonState === ZonesAreasButtonState.NONE_DISPLAYED
      ? inactiveClass
      : this.zonesAreasButtonState === ZonesAreasButtonState.ZONES_DISPLAYED
      ? zonesOnlySelectedClass
      : zonesAndAreasSelectedClass;
  }

  get title(): string {
    return this._title;
  }

  onClick(_$event: MouseEvent) {
    switch (this.zonesAreasButtonState) {
      case ZonesAreasButtonState.NONE_DISPLAYED: {
        this.buttonIconElement.nativeElement.title = this.displayZonesOnlyLabel;
        this.zonesAreasButtonState = ZonesAreasButtonState.ZONES_DISPLAYED;
        this.toggleZonesLayer();

        break;
      }
      case ZonesAreasButtonState.ZONES_DISPLAYED: {
        this.buttonIconElement.nativeElement.title = this.displayZonedAndAreasLabel;
        this.zonesAreasButtonState = ZonesAreasButtonState.ZONES_AREAS_DISPLAYED;
        this.toggleAreasLayer();

        break;
      }
      case ZonesAreasButtonState.ZONES_AREAS_DISPLAYED: {
        this.buttonIconElement.nativeElement.title = this.hideZonedAndAreasLabel;
        this.zonesAreasButtonState = ZonesAreasButtonState.NONE_DISPLAYED;
        this.toggleZonesLayer();
        this.toggleAreasLayer();

        break;
      }
    }

    this.buttonIconElement.nativeElement.className = this.buttonClassname;
    this.onButtonStateChanged.emit(this.zonesAreasButtonState);
  }

  private toggleZonesLayer(): void {
    this.zonesPolygon.forEach((zonePolygon) => {
      zonePolygon.setMap(this.zonesVisible ? null : this.map);
    });
    this.zonesVisible = !this.zonesVisible;
  }

  private toggleAreasLayer(): void {
    this.areasPolygon.forEach((areaPolygon) => {
      areaPolygon.setMap(this.areasVisible ? null : this.map);
    });
    this.areasVisible = !this.areasVisible;
  }

  private getInitialButtonState(): ZonesAreasButtonState {
    const zonesOnMap = this.zonesPolygon.findIndex(poly => !!poly.getMap()) > -1;
    const areasOnMap = this.areasPolygon.findIndex(area => !!area.getMap()) > - 1;

    if(zonesOnMap && areasOnMap) return ZonesAreasButtonState.ZONES_AREAS_DISPLAYED;
    else if(zonesOnMap && !areasOnMap) return ZonesAreasButtonState.ZONES_DISPLAYED;
    else return ZonesAreasButtonState.NONE_DISPLAYED;
  }
}
