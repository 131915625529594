import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterTypes } from '../../enums/filterTypes';
import { ObjectLinkTypes } from '../../enums/objectLinkTypes.enum';
import { ObjectSubTypes } from '../../enums/objectSubTypes.enum';
import { ObjectTypes } from '../../enums/objectTypes';
import { RAGStatuses } from '../../enums/ragStatuses';
import { IncidentsManager } from '../../managers/incidents.manager';
import { Account } from '../../models/account';
import { Employee } from '../../models/employee';
import { ObjectSimpleViewModel } from '../../models/linkedItemObjects/objectSimpleViewModel';
import { AlertService } from '../../services/alert.service';
import { AuthenticationService } from '../../services/authentication.service';
import { ConfirmationService } from '../../services/confirmation.service';
import { LinkedItemsService } from '../../services/linked-items.service';
import { LocalisationService } from '../../services/localisation.service';
import { UrlService } from '../../services/url.service';
import { EmployeeUtil } from '../../utilities/employee.utilities';
import { RagHelper } from '../../utilities/rag.utilities';
import { RiskRagHelper } from '../../utilities/risk-rag.utilities copy';
import { FilterTypeSelectorViewModel } from '../../viewModels/filters/filterTypeSelectorViewModel';
import { LinkedItemsAddModalComponent } from '../modals/linked-items-add-modal/linked-items-add-modal.component';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';

@Component({
  selector: 'app-linked-items-accordion-list',
  templateUrl: './linked-items-accordion-list.component.html',
  styleUrls: ['./linked-items-accordion-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedItemsAccordionListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() rag: RAGStatuses;
  @Input() id: number;
  @Input() objectType: ObjectTypes;
  @Input() objectSubType: ObjectSubTypes;
  @Input() canAddLinkedItems: boolean = false;

  @Output() count: EventEmitter<number> = new EventEmitter();
  @Output() linkedItemsCount = new EventEmitter<number>();

  public loading = true;
  public linkedTasks: ObjectSimpleViewModel[] = [];
  public linkedProjects: ObjectSimpleViewModel[] = [];
  public linkedRisks: ObjectSimpleViewModel[] = [];
  public linkedIndicators: ObjectSimpleViewModel[] = [];
  public linkedIncidents: ObjectSimpleViewModel[] = [];
  public linkedJobs: ObjectSimpleViewModel[] = [];
  public currentlySelectedItems: ObjectSimpleViewModel[] = [];
  public currentlySelectedVisibleFilters: FilterTypeSelectorViewModel[] = [];
  public useRiskAndIssues: boolean;
  private employee: Employee;
  public account: Account;
  private subscriptions = new Subscription();
  private linkedObjectTypes: ObjectTypes[] = [
    ObjectTypes.Task,
    ObjectTypes.Project,
    ObjectTypes.Risk,
    ObjectTypes.Job,
    ObjectTypes.IncidentItem,
    ObjectTypes.Indicator,
    ObjectTypes.Public_Incident_Report
  ];
  private localisedRisksIssuesOpportunities = `${this.localisationService.localiseObjectType(
    ObjectTypes.Risk,
    true
  )}, ${this.translateService.instant(T.defaultLocalizations.issue.many)} & ${this.translateService.instant(
    T.defaultLocalizations.opportunity.many
  )}`;

  private localisedLogs = `${this.translateService.instant(T.defaultLocalizations.log.many)}`
  public readonly T = T;

  public linkedItems: { objectType: number; linkedItems: ObjectSimpleViewModel[] }[];

  bsModalRef: BsModalRef;
  constructor(
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private linkedService: LinkedItemsService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly alertService: AlertService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
    private readonly urlService: UrlService,
    private readonly incidentsManager: IncidentsManager
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();
    this.useRiskAndIssues = EmployeeUtil.hasAnyReadPermission(this.employee, ModuleTypes.Planning, FilterTypes.Risk);
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes.id.currentValue !== changes.id.previousValue) {
        this.loadData();
      }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadData() {
    this.loading = true;
    this.subscriptions.add(
      this.linkedService
        .getLinkedItems(this.id, this.objectType, this.linkedObjectTypes, ObjectLinkTypes.Relation)
        .subscribe((itemsByObject) => {
          this.linkedItems = itemsByObject.filter((x) => x.linkedItems.length > 0);
          this.splitLogsAndIncidentItems();
          this.count.emit(this.linkedItems.length);
          const totalCount = this.linkedItems.reduce((count, type) => {
            return count + (type.linkedItems ? type.linkedItems.length : 0);
          }, 0);
          this.linkedItemsCount.emit(totalCount)
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        })
    );


  }

  splitLogsAndIncidentItems(): void {
    const allLinkedIncidents = this.linkedItems?.find(item => item.objectType === ObjectTypes.IncidentItem);
    const linkedLogs = {
      objectType: ObjectTypes.IncidentItem,
      objectSubType: IncidentItemTypes.Log,
      linkedItems: []
      }

      const linkedIncidents = {
        objectType: ObjectTypes.IncidentItem,
        objectSubType: IncidentItemTypes.Incident,
        linkedItems: []
      }

      // Remove the linked incident items from the linkedItems array
      this.linkedItems = this.linkedItems?.filter(item => item.objectType !== ObjectTypes.IncidentItem);

      // Split the incidents and the logs into different objects
      allLinkedIncidents?.linkedItems.forEach((item) => {
        const objectSubType: number = item.objectSubType;

        if (objectSubType === IncidentItemTypes.Incident) {
          linkedIncidents.linkedItems.push(item);
        } else if (objectSubType === IncidentItemTypes.Log) {
          linkedLogs.linkedItems.push(item);
        }
      })

      if (linkedIncidents.linkedItems.length > 0) {
        this.linkedItems = [...this.linkedItems, linkedIncidents];
      }

      if (linkedLogs.linkedItems.length > 0) {
        this.linkedItems = [...this.linkedItems, linkedLogs];
      }

  }

  /**
   * Gets linked items by object type
   */
  getItemsByObjectType(
    itemsByObejct: { objectType: number; linkedItems: ObjectSimpleViewModel[] }[],
    objectType: ObjectTypes
  ): ObjectSimpleViewModel[] {
    const items = itemsByObejct.find((e) => e.objectType === objectType);

    return items ? items.linkedItems : [];
  }

  /**
   * Opens modal to add linked items, when item is created, reloads the list to get the new item
   */
  addLinkedItem() {
    const modalConfig = { class: 'modal-large-add', ignoreBackdropClick: true };
    const currentLinkedObj: ObjectSimpleViewModel = {
      id: this.id,
      status: this.rag,
      title: this.title,
      objectType: this.objectType,
      objectSubType: this.objectSubType,
    };

    const initialState = {
      currentLinkedObj: currentLinkedObj,
    };
    const modalParams = Object.assign({}, modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(LinkedItemsAddModalComponent, modalParams);
    this.subscriptions.add(
      this.bsModalRef.content.itemCreated.subscribe((res: boolean) => {
        if (res === true) {
          this.loadData();
        }
      })
    );
  }

  /**
   * Opens confirmation modal to remove linked item
   */
  removeLink(item: ObjectSimpleViewModel) {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.common.confirm_remove_link_for_item),
      () => {
        this.linkedService.removeRelation(this.id, this.objectType, item.id, item.objectType).subscribe((res) => {
          void this.alertService.success(this.translateService.instant(T.common.item_removed));
          this.loadData();
        });
      },
      () => {
        // handle cancel
      }
    );
  }

  /**
   * Navigates to details page of the linked item
   */
  navigateToDetailsPage(item: ObjectSimpleViewModel): void {
    this.urlService.navigateToObjectDetailsPage(item.objectType, item.objectSubType, item.id);
  }

  /**
   * Based on ObjectSimpleViewModel object type (or subType), returns the icon type
   */
  convertObjTypeToDetailIconType(item: ObjectSimpleViewModel) {
    if (item.objectSubType) {
      return ObjectSubTypes[item.objectSubType];
    } else {
      return ObjectTypes[item.objectType];
    }
  }

  /**
   * Returns the color of the RAG status
   */
  getRagStatusColor(item: ObjectSimpleViewModel): string {
    const objectSubType: number = item.objectSubType;

    if (item.objectType === ObjectTypes.Risk) {
      return RiskRagHelper.getRAGColourHexFromValue(item?.status);
    } else if (item.objectType === ObjectTypes.IncidentItem && objectSubType === IncidentItemTypes.Incident) {
      return this.incidentsManager.getColorBySeverity(item?.status);
    } else {
      return RagHelper.getRAGColourHexFromValue(item?.status);
    }
  }

  /**
   * Returns the localised object type
   */
  getLocalisedObjectType(objectType: number, objectSubType: number): string {
    if (objectType === ObjectTypes.Risk) {
      return this.localisedRisksIssuesOpportunities;
    }

    if(objectType === ObjectTypes.IncidentItem && objectSubType === IncidentItemTypes.Log) {
      return this.localisedLogs;
    }

    if(objectType === ObjectTypes.IncidentItem && objectSubType === IncidentItemTypes.Incident) {
      this.localisationService.localiseObjectType(objectType, true)
    }

    return this.localisationService.localiseObjectType(objectType, true);
  }
}
