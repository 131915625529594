import { Injectable } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { FilterTypes } from '../../enums/filterTypes';
import { Employee } from '../../models/employee';
import { FilterViewModel } from '../../models/filter/filterViewModel';
import { ZoneViewModel } from '../../viewModels/zoneViewModel';
import { AuthenticationService } from '../authentication.service';
import { IncidentsSettingsService } from '../indicents-settings.service';
import { LocationService } from '../location.service';

//This has a major issue with a rice condition!!!

@Injectable({
  providedIn: 'root',
})
export class FilterToViewModelUtilityService {
  private subscriptions = new Subscription();
  private zones: ZoneViewModel[] = [];
  private areas: AreaViewModel[] = [];
  private employee: Employee;

  constructor(
    private readonly locationService: LocationService,
    private readonly authenticationService: AuthenticationService,
    private readonly incidentsSettingsService: IncidentsSettingsService
  ) {
    this.employee = this.authenticationService.getCurrentEmployee();

    this.subscriptions.add(
      forkJoin([this.locationService.getZones(), this.incidentsSettingsService.getAreas()]).subscribe(
        ([zones, areas]) => {
          this.zones = zones;
          this.areas = areas;
        }
      )
    );
  }

  getZoneViewModelsFromFilters(filters: FilterViewModel[], inputZones?: ZoneViewModel[]): ZoneViewModel[] {
    const result: ZoneViewModel[] = [];

    if (filters && filters.length) {
      const zoneFilters = filters.filter((f) => f.filterType === FilterTypes.Zone);

      if (zoneFilters && zoneFilters.length) {
        if (!this.zones.length && inputZones) {
          zoneFilters.forEach((filter) => {
            if (filter.filterValue) {
              const zone = inputZones.find((z) => z.id.toString() === filter.filterValue.toString());

              if (zone) {
                result.push(zone);
              }
            }
          });
        } else {
          zoneFilters.forEach((filter) => {
            if (filter.filterValue) {
              const zone = this.zones.find((z) => z.id.toString() === filter.filterValue.toString());

              if (zone) {
                result.push(zone);
              }
            }
          });
        }
      }
    }

    return result;
  }

  getAreaViewModelsFromFilters(filters: FilterViewModel[], inputAreas?: AreaViewModel[]): AreaViewModel[] {
    const result: AreaViewModel[] = [];

    if (filters && filters.length) {
      const areaFilters = filters.filter((f) => f.filterType === FilterTypes.Area);

      if (areaFilters && areaFilters.length) {
        if (!this.zones.length && inputAreas) {
          areaFilters.forEach((filter) => {
            const area = inputAreas.find((a) => a.id.toString() === filter.filterValue.toString());

            if (area) {
              result.push(area);
            }
          });
        } else {
          areaFilters.forEach((filter) => {
            const area = this.areas.find((a) => a.id.toString() === filter.filterValue.toString());

            if (area) {
              result.push(area);
            }
          });
        }
      }
    }

    return result;
  }
}
