import { Injectable } from "@angular/core";
import { WtStorageService } from "../../shared/services/wt-storage.service";
import { GanttColumnSize } from "../utilities/gantt-column-size";

@Injectable()
export class GanttCachingService {
  // The key for the grid columns
  public static readonly gridColumnsKey = 'DHX_GANTT_GRID';

  // The key for the expanded tree nodes for particular views
  public static readonly viewsExpandedTreeNodesKey = 'DHX_GANTT_VIEWS';

  constructor(
    private readonly wtStorage: WtStorageService
  ) {}

  public getCachedGanttColumnsSize(): GanttColumnSize[] {
    if(this.wtStorage.getItem(GanttCachingService.gridColumnsKey)) {
      return JSON.parse(this.wtStorage.getItem(GanttCachingService.gridColumnsKey)) as GanttColumnSize[];
    }

    return [];
  }

  public saveCachedGanttColumnsSize(cachedData: GanttColumnSize[]): void {
    this.wtStorage.setItem(GanttCachingService.gridColumnsKey, JSON.stringify(cachedData));
  }

  public updateCachedGanttColumnSize(columnID: string, width: number) {
    const cachedColsData = this.getCachedGanttColumnsSize();
    const cached = cachedColsData.find(e => e.columnId === columnID);
    if(cached) {
      cached.size = width;
    } else {
      cachedColsData.push({
        columnId: columnID,
        size: width
      });
    }

    this.saveCachedGanttColumnsSize(cachedColsData);
  }

  public getCachedColumnSize(columnId: string): number {
    const cachedColsData = this.getCachedGanttColumnsSize();
    return cachedColsData.find(c => c.columnId === columnId)?.size ?? 0;
  }



  /**
   * Get the expanded tree nodes for particular view
   * @param viewId
   * @returns
   */
  public getCachedGanttViewExpandedTreeNodes(viewId: number): string[] {
    const cachedData = this.getCachedGanttViewsExpandedTreeNodes();
    return cachedData.get(viewId) ?? [];
  }

  /**
   * Update the expanded tree nodes for particular view
   * @param viewId
   * @param expandedNodes
   */
  public saveCachedGanttViewExpandedTreeNodes(viewId: number, expandedNodes: string[]): void {
    const cachedData = this.getCachedGanttViewsExpandedTreeNodes();
    cachedData.set(viewId, expandedNodes ?? []);
    this.saveCachedGanttViewsExpandedTreeNodes(cachedData);
  }

  /**
   * Delete the expanded tree nodes for particular view
   * @param viewId
   */
  public deleteCachedGanttViewExpandedTreeNodes(viewId: number): void {
    const cachedData = this.getCachedGanttViewsExpandedTreeNodes();
    const deleted = cachedData.delete(viewId);
    if(deleted) {
      this.saveCachedGanttViewsExpandedTreeNodes(cachedData);
    }
  }

  /**
   * Get the expanded tree nodes for particular views
   * @returns
   */
  private getCachedGanttViewsExpandedTreeNodes(): Map<number, string[]> {
    if(this.wtStorage.getItem(GanttCachingService.viewsExpandedTreeNodesKey)) {
      try {
        const cachedData = JSON.parse(this.wtStorage.getItem(GanttCachingService.viewsExpandedTreeNodesKey)) as Map<number, string[]>;
        return new Map(cachedData);
      } catch(_error) { }

      return new Map();
    }

    return new Map();
  }

  /**
   * Save the expanded tree nodes for particular views
   * @param cachedData
   */
  private saveCachedGanttViewsExpandedTreeNodes(cachedData: Map<number, string[]>): void {
    try {
      this.wtStorage.setItem(GanttCachingService.viewsExpandedTreeNodesKey, JSON.stringify([...cachedData]));
    } catch(_error) { }
  }
}
