import { MarkerType } from 'src/app/modules/shared/enums/maps/marketType';
import { GMapUtilities } from '../../../utilities/gMap.utilities';

export class ObjectMarker  {
  protected static readonly markerClassName = 'object-marker-wrapper';
  protected static readonly DEFAULT_COLOR = '#212529';

  protected map: google.maps.Map;
  protected color: string;
  protected markerType: MarkerType;
  protected marker: google.maps.marker.AdvancedMarkerElement;

  constructor(markerType: MarkerType, position: google.maps.LatLng, color: string,  map: google.maps.Map) {
    this.map = map;
    this.markerType = markerType;
    this.color = color || ObjectMarker.DEFAULT_COLOR;
    this.initObjectMarker(position);
  }

  attachEvent(eventName: string, callback: Function): void {
    google.maps.event.addListener(this.marker, eventName, () => {
      callback();
    });
  }

  setMap(map: google.maps.Map): void {
    this.marker.map = map;
  }

  get rawMarker(): google.maps.marker.AdvancedMarkerElement {
    return null;
    //return this.marker;
  }

  getColor() {
    return this.color;
  }

  setColor(hexColor: string) {
    this.color = hexColor;
    const markerContent = <HTMLDivElement>this.marker.element;
    markerContent.querySelector('path#marker').setAttribute('style', `fill:${this.getRGBColor()}`);
  }

  set w3wPopupLabelVisible(state: boolean) {
    if(this.marker && this.markerType === MarkerType.INCIDENT) {
      const element = (<HTMLElement>this.marker.element.querySelector('.what3words-info-tooltip'));
      if(state) {
        element.classList.remove('hidden');
      } else {
        element.classList.add('hidden');
      }
    }
  }

  getPosition(): google.maps.LatLng {
    return new google.maps.LatLng(this.marker.position);
  }

  set position(position: google.maps.LatLng) {
    this.marker.position = position;
  }

  set draggable(draggable: boolean) {
    this.marker.gmpDraggable = draggable;
  }

  private initObjectMarker(position: google.maps.LatLng) {
    const svg = GMapUtilities.buildMarkerSVG(this.markerType, this.getRGBColor());

    const markerWrapper: HTMLDivElement = document.createElement('div');
    markerWrapper.classList.add('object-marker-wrapper');
    markerWrapper.innerHTML = `${svg}`;

    this.marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      position: position,
      gmpDraggable: false,
      content: markerWrapper
    });
  }

  private getRGBColor(): string {
    // "03F" -> "0033FF"
    if (!this.color) {
      this.color = ObjectMarker.DEFAULT_COLOR;
    }

    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const hex = this.color.replace(shorthandRegex, (_m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };

    return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  }
}
