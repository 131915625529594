import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { RiskDetailsViewModel } from '../models/RiskDetailsViewModel';
import { RiskListViewModel } from '../models/RiskListViewModel';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { StateService } from '../../planning/services/state.service';
import { map } from 'rxjs/operators';
import { IApiService } from '../../shared/interfaces/iHttpService';
import { ValidatedViewModel } from '../../shared/viewModels/validatedViewModel';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { LightFilterUpdateModel } from '../../shared/models/filter/LightFilterUpdateModel';
import { RiskActionDetailsViewModel } from '../models/riskActionDetailsViewModel';
import { EventDetailsOverviewViewModel } from 'src/app/modules/shared/viewModels/events/eventDetailsOverviewViewModel';

@Injectable({
  providedIn: 'root',
})
export class RisksService extends StateService<RiskListViewModel> implements IApiService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    super();
    this.actionUrl = this.configuration.buildEndpoint(`Risk/`);
  }
  updateLight(item: LightFilterUpdateModel) {
    throw new Error('Method not implemented.');
  }
  list(filters: FilterViewModel[]): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }
  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly?: boolean
  ): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  add(risk: RiskDetailsViewModel) {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}Add`, risk);
  }

  addWithActions(payload: { risk: RiskDetailsViewModel; actions: RiskActionDetailsViewModel[]}) {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}AddWithActions`, payload);
  }

  addFromMasterIds(assesmentId: number, masterRiskIds: number[]) {
    return this.http.post<RiskDetailsViewModel[]>(`${this.actionUrl}AddFromMasterIds/${assesmentId}`, masterRiskIds);
  }

  getListForAssesment(assesmentId: number) {
    return this.http.get<RiskDetailsViewModel[]>(`${this.actionUrl}GetListForAssesment/${assesmentId}`);
  }
  getList(
    employeeId: number,
    filters: FilterViewModel[],
    showPinnedOnly: boolean = false,
    customSortFilter: number = 0
  ): Observable<RiskListViewModel[]> {
    return this.http.post<RiskListViewModel[]>(
      `${this.actionUrl}List/${employeeId}/${showPinnedOnly}/${customSortFilter}`,
      filters
    );
  }

  details(riskId: number): Observable<RiskDetailsViewModel> {
    return this.http.get<RiskDetailsViewModel>(`${this.actionUrl}Details/${riskId}`);
  }

  update(risk: RiskDetailsViewModel) {
    return this.http.put<RiskDetailsViewModel>(`${this.actionUrl}Update`, risk);
  }

  updateLozenges(risk: RiskListViewModel) {
    return this.http.post(`${this.actionUrl}UpdateLozenges`, risk);
  }

  delete(riskId: number) {
    return this.http.delete(`${this.actionUrl}Delete/${riskId}`);
  }

  archive(riskId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${riskId}/${archive}`, null).pipe(
      map((x) => {
        this.deleteFromList(riskId);
        return x;
      })
    );
  }

  bulkArchive(riskIds: number[]) {
    return this.http.post(`${this.actionUrl}BulkArchive`, riskIds);
  }

  getEventDetailsOverview(eventId: number): Observable<EventDetailsOverviewViewModel> {
    return this.http.get<EventDetailsOverviewViewModel>(`${this.actionUrl}GetEventDetails/Overview/${eventId}`);
  }
}
