import { Pipe, PipeTransform } from '@angular/core';
import { FilterTypes } from '../enums/filterTypes';
import { FilterViewModel } from '../models/filter/filterViewModel';

@Pipe({
  name: 'filtersByType',
})
export class FiltersByTypePipe implements PipeTransform {
  transform(filters: FilterViewModel[], type: FilterTypes): FilterViewModel[] {
    return filters.filter((f) => f.filterType === type);
  }
}
