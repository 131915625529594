import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum FilterDateOptions {
  None = 0,
  Start_Date = 11,
  Due_Date = 12,
  Created = 13,
  Updated = 14,
  Closed = 15,
  Run_Sheet_Date = 16,
  Not_Updated = 17,
  Activity_Date = 18,
  Action_Due_Date = 19,
  Date = 20,
  Expected_Due_Date = 21,
  Last_Updated = 22,
  Time = 23,
  Start_Time = 24,
  Due_Time = 25,
  End_Date = 26,
}

export namespace FilterDateOptions {
  export function translatedItems(translateService: TranslateService): { key: FilterDateOptions; value: string }[] {
    const a: { key: FilterDateOptions; value: string }[] = [];
    for (const enumMember in FilterDateOptions) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translatedDateOption(+enumMember, translateService) });
      }
    }
    return a;
  }

  export function translatedDateOption(dateOption: FilterDateOptions, translateService: TranslateService) {
    switch (dateOption) {
      case FilterDateOptions.Start_Date:
        return translateService.instant(T.common.start_date.one);
      case FilterDateOptions.Due_Date:
        return translateService.instant(T.defaultLocalizations.due_date.one);
      case FilterDateOptions.Created:
        return translateService.instant(T.defaultLocalizations.created.one);
      case FilterDateOptions.Updated:
        return translateService.instant(T.defaultLocalizations.updated.one);
      case FilterDateOptions.Closed:
        return translateService.instant(T.common.closed.one);
      case FilterDateOptions.Run_Sheet_Date:
        return translateService.instant(T.runsheet.run_sheet_date);
      case FilterDateOptions.Not_Updated:
        return translateService.instant(T.common.not_updated.one);
      case FilterDateOptions.Activity_Date:
        return 'Activity Date';
      case FilterDateOptions.Action_Due_Date:
        return 'Due Date';
      case FilterDateOptions.Date:
        return translateService.instant(T.defaultLocalizations.date.one);
      case FilterDateOptions.Expected_Due_Date:
        return translateService.instant(T.common.expected_due_date);
      case FilterDateOptions.Last_Updated:
        return translateService.instant(T.common.last_updated);
      case FilterDateOptions.Time:
        return translateService.instant(T.common.time);
      case FilterDateOptions.Start_Time:
        return translateService.instant(T.defaultLocalizations.start_time.one);
      case FilterDateOptions.Due_Time:
        return translateService.instant(T.defaultLocalizations.due_time.one);
      case FilterDateOptions.End_Date:
        return translateService.instant(T.common.end_date.one);
      default:
        return 'n/a';
    }
  }

  // returns tha translated date option with the word 'Date' appended to it
  export function translatedDateOptionWithDateSuffix(dateOption: FilterDateOptions, translateService: TranslateService) {
    switch (dateOption) {
      case FilterDateOptions.Start_Date:
        return translateService.instant(T.common.start_date.one);
      case FilterDateOptions.Due_Date:
        return translateService.instant(T.defaultLocalizations.due_date.one);
      case FilterDateOptions.Created:
        return translateService.instant(T.calendar.created_date);
      case FilterDateOptions.Updated:
        return translateService.instant(T.calendar.updated_date);
      case FilterDateOptions.Closed:
        return translateService.instant(T.calendar.closed_date);
      case FilterDateOptions.Run_Sheet_Date:
        return translateService.instant(T.runsheet.run_sheet_date);
      case FilterDateOptions.Not_Updated:
        return translateService.instant(T.calendar.not_updated_date);
      case FilterDateOptions.Activity_Date:
        return 'Activity Date ';
      case FilterDateOptions.Action_Due_Date:
        return 'Due Date';
      case FilterDateOptions.Date:
        return translateService.instant(T.defaultLocalizations.date.one);
      case FilterDateOptions.Expected_Due_Date:
        return translateService.instant(T.common.expected_due_date);
      case FilterDateOptions.Last_Updated:
        return translateService.instant(T.calendar.last_updated_date);
      case FilterDateOptions.Time:
        return translateService.instant(T.common.time);
      case FilterDateOptions.Start_Time:
        return translateService.instant(T.defaultLocalizations.start_time.one);
      case FilterDateOptions.Due_Time:
        return translateService.instant(T.defaultLocalizations.due_time.one);
      case FilterDateOptions.End_Date:
        return translateService.instant(T.common.end_date.one);
      default:
        return 'n/a';
    }
  }
}
