import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime, fromEvent } from 'rxjs';
import { Constants } from '../models/constants';

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  private readonly debounceTime = 200;

  private readonly _isXSmall = new BehaviorSubject<boolean>(this.checkIsViewportMobile(window.innerWidth));
  readonly isXSmall$ = this._isXSmall.asObservable();
  get isXSmall() {
    return this._isXSmall.getValue();
  }

  private readonly _isTouch = new BehaviorSubject<boolean>(this.checkIsTouchDevice());
  readonly isTouch$ = this._isTouch.asObservable();
  get isTouch() {
    return this._isTouch.getValue();
  }

  constructor() {
    // TODO: Inject window as dependency
    // TODO: Track this by listening to a media query
    fromEvent(window, 'resize')
      .pipe(debounceTime(this.debounceTime))
      .subscribe((event: Event) => {
        this._isXSmall.next(this.checkIsViewportMobile((event.target as Window).innerWidth));
      });

    // TODO: Track touch capabilities over time by listening to media query changes
  }

  private checkIsViewportMobile(width: number): boolean {
    // TODO: Check this with a media query
    return width <= Constants.xs;
  }

  private checkIsTouchDevice(): boolean {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = (query) => window.matchMedia(query).matches;

    if ('ontouchstart' in window) {
      return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');

    return mq(query);
  }
}
