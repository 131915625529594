import { Component, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent, base64ToFile } from 'ngx-image-cropper';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/alert.service';
import { T } from "src/assets/i18n/translation-keys";
import { AttachmentsUtilities } from '../../../utilities/attachments.utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: 'image-cropper-modal.component.html',
  styleUrls: ['image-cropper-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCropperModalComponent implements OnInit {
  imageChangedEvent: Event;
  imageFile: File;
  aspectRatio: string;
  maintainAspectRatio = false;
  roundCropper = false;
  croppedImage: any = '';
  croppedImageFile: File;
  onCroppedImageChange = new EventEmitter<File>();
  loading = true;
  extension: string;
  public readonly T = T;

  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  constructor(public bsModalRef: BsModalRef, public changeDetectorRef: ChangeDetectorRef, private alertService: AlertService,private translateService:TranslateService) {}

  ngOnInit(): void {
    if (this.extension === 'jpg') {
      this.extension = 'jpeg';
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageFile = this.convertBlobToFile(base64ToFile(event.base64), 'test.' + this.extension);
  }
  imageLoaded() {
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }
  cropperReady() {
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }
  loadImageFailed() {
    this.loading = false;

    if(AttachmentsUtilities.VerifyImageExtension(this.extension)) {
      void this.alertService.error( this.translateService.instant(T.common.error_imageLoad));
    } else{
      void this.alertService.error( this.translateService.instant(T.common.error_imageWrongExt));
    }

    this.bsModalRef.hide();
  }

  rotateLeft() {
    this.imageCropper.transform.rotate = 270;
  }
  rotateRight() {
    this.imageCropper.transform.rotate = 90;
  }
  flipHorizontal() {
    this.imageCropper.transform.flipH = true;
  }
  flipVertical() {
    this.imageCropper.transform.flipV = true;
  }

  onClose() {
    this.bsModalRef.hide();
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onConfirm() {
    this.onCroppedImageChange.emit(this.croppedImageFile);
    this.bsModalRef.hide();
  }

  private convertBlobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, { type: `image/${this.extension}` });
  };
}
