import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { TimeZoneService } from '../../../services/timeZone.service';

enum TimeStates {
  Changing_Time,
  Removing_Time,
}

@Component({
  selector: 'app-wt-timepicker',
  templateUrl: './wt-timepicker.component.html',
  styleUrls: ['./wt-timepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WtTimepickerComponent {
  @Input() set date(date: string | Date) {
    if (date) {
      this._pristineDate = this.timeZoneService.getCurrentMomentInLocalisedTimezone(date);
      this.localisedLocalDate = this.timeZoneService.getCurrentMomentInLocalisedTimezone(date);
      this.initTime();
    } else {
      this.localisedLocalDate = undefined;
      this.timeString = null;
    }
  }

  @Output() timeChanged: EventEmitter<string> = new EventEmitter();
  @Output() timeRemoved: EventEmitter<boolean> = new EventEmitter();

  public visible: boolean;
  public onHide: Subject<boolean> = new Subject();
  public directiveElementRef: ElementRef<HTMLElement>;
  public localisedLocalDate: moment.Moment;
  public timeString: string;
  public currentTimeState: TimeStates;
  public readonly T = T;

  private _pristineDate: moment.Moment;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef, private readonly timeZoneService: TimeZoneService) {}

  show() {
    this.visible = true;
    this.changeDetectorRef.markForCheck();
  }

  close() {
    this.visible = false;
    this.changeDetectorRef.markForCheck();
  }

  apply() {
    if (this.currentTimeState == TimeStates.Changing_Time) {
      this.timeChanged.emit(this.localisedLocalDate.toISOString());
    } else {
      this.timeRemoved.emit();
    }
    this.close();
    this.onHide.next(true);
  }

  initTime() {
    const hours = this.localisedLocalDate.get('hours');
    const minutes = this.localisedLocalDate.get('minutes');
    this.timeString = `${hours}:${minutes}`;
  }

  onTimeChange(time: string) {
    const [hours, minutes] = time.split(':');
    if (!this.localisedLocalDate) {
      this.localisedLocalDate = this.timeZoneService.getCurrentMomentInLocalisedTimezone();
    }
    this.localisedLocalDate.set('hours', +hours);
    this.localisedLocalDate.set('minutes', +minutes);
    this.initTime();
    this.currentTimeState = TimeStates.Changing_Time;
  }

  get hasTimeChanged() {
    if (this.localisedLocalDate && this._pristineDate) {
      return this.localisedLocalDate.toISOString() != this._pristineDate.toISOString();
    } else {
      return false;
    }
  }

  onTimeRemove() {
    this.timeString = null;
    this.currentTimeState = TimeStates.Removing_Time;
    this.changeDetectorRef.detectChanges();
  }
}
