import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { HeaderEventsEmitter } from 'src/app/modules/shared/events/header.events';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { dateFormatOptions } from 'src/app/modules/shared/utilities/date.utilities';
import { EmployeeSettingViewModel } from 'src/app/modules/shared/viewModels/employeeSettingViewModel';
import { NotificationFrequencyTypes } from 'src/app/modules/shared/enums/notificationFrequencies';
import { Account } from 'src/app/modules/shared/models/account';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { Constants } from '../../../../../shared/models/constants';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-notifications',
  templateUrl: 'employee-notifications.component.html',
  styleUrls: ['employee-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeNotificationsComponent implements OnInit, OnDestroy {
  @Input() handleUpdateInternally: boolean = true;
  @Input() showAlerts: boolean = false;
  @Input() employeeId: number;

  @Output() notificationUpdated = new EventEmitter<EmployeeSettingViewModel[]>();

  private readonly tabletWidth: number = Constants.sm;
  private subscriptions = new Subscription();
  private account: Account;
  private receiveNotificationSetting: EmployeeSettingViewModel;
  private readonly notificationSettingTypes = [
    EmployeeSettingTypes.Notification_Planning_Changes,
    EmployeeSettingTypes.Notification_Incident_Changes,
    EmployeeSettingTypes.Notification_Summary_Changes,
    EmployeeSettingTypes.Notifications_StandardMessage,
  ];

  readonly dateFormatOptions = dateFormatOptions;

  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;
  @ViewChild('fileElement') fileElement: ElementRef<HTMLElement>;
  public employeeSettingTypes = EmployeeSettingTypes;
  public sidebarIsCollapsed: boolean;
  public loading: boolean = true;

  public notificationFrequencyTypes: { key: NotificationFrequencyTypes; value: string }[] = NotificationFrequencyTypes.translatedItems(
    this.translateService
  ).filter((f) => f.key !== 3);
  public summaryNotificationFrequencyTypes: { key: NotificationFrequencyTypes; value: string }[] =
    NotificationFrequencyTypes.translatedItems(this.translateService).filter((f) => f.key > 1);
  public readinessSummaryNotificationFrequencyTypes: { key: NotificationFrequencyTypes; value: string }[] =
    NotificationFrequencyTypes.translatedItems(this.translateService).filter((f) => f.key > 1 && f.key !== 3);
  public sustainabilitySummaryNotificationFrequencyTypes: { key: NotificationFrequencyTypes; value: string }[] =
    NotificationFrequencyTypes.translatedItems(this.translateService).filter((f) => f.key >= 3);
  public selectedNotificationFrequencyTypes: { key: NotificationFrequencyTypes; value: string; settingType: EmployeeSettingTypes }[] = [];

  public notificationSettings: EmployeeSettingViewModel[] = [];
  public venueManagerSetting: EmployeeSettingViewModel;
  public notificationBooleanTypes: { key: boolean; value: string }[] = [];
  public smsControlSetting: EmployeeSettingViewModel;
  public publicIncidentReportsNotificationsSetting: EmployeeSettingViewModel;
  public useSustainability: boolean = false;
  public useControlModule: boolean = false;
  public usePublicIncidentReports: boolean = false;
  public usePlanning: boolean = false;
  public readonly T = T;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly headerEventsEmitter: HeaderEventsEmitter,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly alertService: AlertService,
    private readonly localisationService: LocalisationService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.account = this.authenticationService.getCurrentAccount();
    this.usePlanning = this.account.usePlanning && !this.account.useIMSOnly;
    this.useControlModule = this.account.useIMS || this.account.useIMSOnly;
    this.usePublicIncidentReports = this.useControlModule && this.account.usePublicIncidentReporting;
    this.useSustainability = this.account.useSustainability;

    this.subscriptions.add(
      this.headerEventsEmitter.menuToggled$.pipe(filter(() => window.innerWidth >= this.tabletWidth)).subscribe(() => {
        this.sidebarIsCollapsed = this.headerEventsEmitter.sidebarElementRef.nativeElement.classList.contains('collapsed');
        this.changeDetectorRef.markForCheck();
      })
    );

    this.subscriptions.add(
      this.headerEventsEmitter.headerViewInitialized$
        .pipe(filter(() => !!this.headerEventsEmitter.sidebarElementRef && window.innerWidth >= this.tabletWidth))
        .subscribe(() => {
          this.sidebarIsCollapsed = this.headerEventsEmitter.sidebarElementRef.nativeElement.classList.contains('collapsed');
          this.changeDetectorRef.markForCheck();
        })
    );

    if (this.employeeId) {
      this.addNotificationBooleanTypes();
      this.getVenueManagerSetting();
      this.getSMSControlSettings();
      this.getPublicIncidentReportsNotificationsSetting();
      this.getEmployeeReceiveNotificationSetting();
      this.getEmployeeNotificationFrequencyType(this.employeeSettingTypes.Notification_Incident_Changes);
      this.getEmployeeNotificationFrequencyType(this.employeeSettingTypes.Notification_Planning_Changes);
      this.getEmployeeNotificationFrequencyType(this.employeeSettingTypes.Notification_Summary_Changes);
      this.getEmployeeNotificationFrequencyType(this.employeeSettingTypes.Notification_Sustainability_Summary);
    } else {
      this.receiveNotificationSetting = {
        id: 0,
        accountId: this.account.id,
        employeeId: this.employeeId,
        title: this.employeeSettingTypes.Comms_Receive_Notifications,
        value: '1',
      };
      this.addDefaultNotification(EmployeeSettingTypes.Notification_Planning_Changes, NotificationFrequencyTypes.Daily);
      this.addDefaultNotification(EmployeeSettingTypes.Notification_Incident_Changes, NotificationFrequencyTypes.Daily);
      this.addDefaultNotification(EmployeeSettingTypes.Notification_Summary_Changes, NotificationFrequencyTypes.Weekly);
      this.addDefaultNotification(EmployeeSettingTypes.Notification_Sustainability_Summary, NotificationFrequencyTypes.Weekly);
      this.notificationSettingTypes.forEach((s) => {
        this.updateEmployeeNotificationFrequencyType(s);
      });
      this.updateEmployeeReceiveNotificationSetting();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public isSelectedSetting(
    reportSendIntervalType: { key: NotificationFrequencyTypes; value: string },
    employeeSettingType: EmployeeSettingTypes
  ): boolean {
    const intervalTypeSetting = this.selectedNotificationFrequencyTypes.find((s) => s.settingType === employeeSettingType);
    return intervalTypeSetting ? intervalTypeSetting.key === reportSendIntervalType.key : false;
  }

  get mobile(): boolean {
    if (!this.wrapper) {
      return;
    }

    return this.wrapper.nativeElement.getBoundingClientRect().width <= Constants.xs;
  }

  public onResize(): void {
    this.changeDetectorRef.detectChanges();
  }

  private addDefaultNotification(employeeSetting: EmployeeSettingTypes, notificationFrequencyType: NotificationFrequencyTypes): void {
    const freqType =
      employeeSetting === EmployeeSettingTypes.Notification_Summary_Changes
        ? this.summaryNotificationFrequencyTypes.find((s) => s.key === notificationFrequencyType)
        : this.notificationFrequencyTypes.find((s) => s.key === notificationFrequencyType);

    const objectivesChangesNotification = {
      key: freqType.key,
      value: freqType.value,
      settingType: employeeSetting,
    };
    this.selectedNotificationFrequencyTypes.push(objectivesChangesNotification);
  }

  private getEmployeeReceiveNotificationSetting(): void {
    this.loading = true;
    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeReceiveNotificationSetting().subscribe((res) => {
        this.receiveNotificationSetting = res;
        this.loading = false;
        this.notificationSettings.push(res);
      })
    );
  }

  private updateEmployeeReceiveNotificationSetting(): void {
    if (this.handleUpdateInternally) {
      this.subscriptions.add(
        this.employeeSettingsService.updateEmployeeReceiveNotificationSetting(this.receiveNotificationSetting).subscribe(() => {
          if (this.showAlerts) {
            void this.alertService.success(this.translateService.instant(T.settings.my_preferences.notifications.notification_setttings_updated));
          }
          this.getEmployeeReceiveNotificationSetting();
        })
      );
    } else {
      const setting = this.notificationSettings.find((s) => s.title === this.receiveNotificationSetting.title);
      if (setting) {
        this.notificationSettings[this.notificationSettings.indexOf(setting)] = this.receiveNotificationSetting;
      } else {
        this.notificationSettings.push(this.receiveNotificationSetting);
      }
      this.notificationUpdated.next(this.notificationSettings);
    }
  }

  private updateEmployeeNotificationFrequencyType(employeeSettingType: EmployeeSettingTypes): void {
    const setting: EmployeeSettingViewModel = new EmployeeSettingViewModel();
    setting.title = employeeSettingType;
    setting.value = this.selectedNotificationFrequencyTypes.find((s) => s.settingType === employeeSettingType).key.toString();
    setting.employeeId = this.employeeId;
    setting.accountId = this.account.id;
    if (this.handleUpdateInternally) {
      if (employeeSettingType === EmployeeSettingTypes.Notification_Planning_Changes) {
        this.subscriptions.add(
          forkJoin(
            this.employeeSettingsService.updateEmployeeSetting(employeeSettingType, setting),
            this.employeeSettingsService.updateEmployeeSetting(EmployeeSettingTypes.Notification_Planning_Changes, setting)
          ).subscribe(() => {
            if (this.showAlerts) {
              void this.alertService.success(this.translateService.instant(T.settings.my_preferences.notifications.notification_setttings_updated));
            }
            this.getEmployeeNotificationFrequencyType(employeeSettingType);
          })
        );
      } else {
        this.subscriptions.add(
          this.employeeSettingsService.updateEmployeeSetting(employeeSettingType, setting).subscribe(() => {
            if (this.showAlerts) {
              void this.alertService.success(this.translateService.instant(T.settings.my_preferences.notifications.notification_setttings_updated));
            }
            this.getEmployeeNotificationFrequencyType(employeeSettingType);
          })
        );
      }
    } else {
      this.saveToSettingList(setting);
      this.notificationUpdated.next(this.notificationSettings);
    }
  }

  private saveToSettingList(setting: EmployeeSettingViewModel): void {
    const storedSetting = this.notificationSettings.find((s) => s.title && s.title.toString() === setting.title.toString());
    if (storedSetting) {
      this.notificationSettings[this.notificationSettings.indexOf(storedSetting)] = setting;
    } else {
      this.notificationSettings.push(setting);
    }
  }

  private getEmployeeNotificationFrequencyType(employeeSetting: EmployeeSettingTypes): void {
    this.loading = true;
    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeSetting(employeeSetting).subscribe((res) => {
        let selectedEmployeeSetting = this.selectedNotificationFrequencyTypes.find((s) => s.settingType === employeeSetting);
        if (!selectedEmployeeSetting) {
          const settingTypes =
            employeeSetting === EmployeeSettingTypes.Notification_Summary_Changes
              ? this.summaryNotificationFrequencyTypes
              : this.notificationFrequencyTypes;
          let settingType = settingTypes.find((s) => s.key === Number(res.value));
          if (!settingType) {
            settingType = settingTypes[0];
          }
          selectedEmployeeSetting = {
            key: Number(res.value),
            value: settingType.value,
            settingType: employeeSetting,
          };
          this.selectedNotificationFrequencyTypes.push(selectedEmployeeSetting);
        } else {
          selectedEmployeeSetting.key = Number(res.value);
        }
        this.notificationSettings.push(res);
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  public onIntervalCheckedChanged(ev: { key: NotificationFrequencyTypes; value: string }, settingType: EmployeeSettingTypes): void {
    if (settingType === EmployeeSettingTypes.Notification_Summary_Changes && ev.key === NotificationFrequencyTypes.Never) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.settings.my_preferences.notifications.are_you_sure_automatic_rag_updates),
        () => {
          this.selectedNotificationFrequencyTypes.find((s) => s.settingType === settingType).key = ev.key;
          this.updateEmployeeNotificationFrequencyType(settingType);
        },
        () => {}
      );
    } else {
      this.selectedNotificationFrequencyTypes.find((s) => s.settingType === settingType).key = ev.key;
      this.updateEmployeeNotificationFrequencyType(settingType);
    }
  }

  public localiseString(str: string, capitalize: boolean = true): string {
    if (capitalize) {
      return this.capitalizeFirstLetter(this.localisationService.localise(str));
    } else {
      return this.localisationService.localise(str);
    }
  }

  private capitalizeFirstLetter(str: string): string {
    if (!str.startsWith('fa')) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str.charAt(0).toUpperCase() + str.charAt(1).toUpperCase() + str.slice(2);
    }
  }

  public getFrequencyTypeDescription(frequencyType: { key: NotificationFrequencyTypes; value: string }): string {
    if (frequencyType.key === NotificationFrequencyTypes.Every_Time) return this.translateService.instant(T.common.immediately);
    return frequencyType.value.replace('Before', '').replace('One', '1');
  }

  private getSMSControlSettings(): void {
    this.loading = true;
    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Notifications_StandardMessage).subscribe((res) => {
        this.smsControlSetting = res;
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private getPublicIncidentReportsNotificationsSetting(): void {
    this.loading = true;
    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Notifications_Incoming_Public_Incident_Reports).subscribe((res) => {
        this.publicIncidentReportsNotificationsSetting = res;
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private addNotificationBooleanTypes(): void {
    this.notificationBooleanTypes.push({ key: true, value: 'Yes' });
    this.notificationBooleanTypes.push({ key: false, value: 'No' });
  }

  private getVenueManagerSetting(): void {
    this.loading = true;
    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Notification_Tasks_In_Venue).subscribe((res) => {
        this.venueManagerSetting = res;
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  public isSelectedBoolean(value: string, checked: boolean): boolean {
    return value === checked.toString();
  }

  public onBooleanSettingChanged(checked: boolean, settingsType: EmployeeSettingTypes): void {
    let setting: EmployeeSettingViewModel;
    if (settingsType === EmployeeSettingTypes.Notification_Tasks_In_Venue) {
      this.venueManagerSetting.value = checked.toString();
      setting = this.venueManagerSetting;
    } else if (settingsType === EmployeeSettingTypes.Notifications_StandardMessage) {
      this.smsControlSetting.value = checked.toString();
      setting = this.smsControlSetting;
    }
    else if (settingsType === EmployeeSettingTypes.Notifications_Incoming_Public_Incident_Reports) {
      this.publicIncidentReportsNotificationsSetting.value = checked.toString();
      setting = this.publicIncidentReportsNotificationsSetting;
    }
    this.employeeSettingsService
      .updateEmployeeSetting(settingsType, setting)
      .subscribe(() => {
        void this.alertService.success(this.translateService.instant(T.settings.my_preferences.notifications.notification_setttings_updated));
    });
  }
}
