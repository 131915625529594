import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-wt-button',
  templateUrl: './wt-button.component.html',
  styleUrls: ['./wt-button.component.scss']
})
export class WtButtonComponent {
  @Input() active = false;
  @Input() disabled = false;

  @Output() clicked = new EventEmitter<MouseEvent>();

  @HostBinding('class.active') get isActive(): boolean {
    return this.active;
  }

  public onClick(event: MouseEvent): void {
    this.clicked.emit(event);
  }
}
