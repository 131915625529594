import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type BadgeColor = 'transparent' | 'black' | 'gray' | 'red' | 'amber' | 'green' | 'blue' | 'custom';
export type BadgeTextColor = 'white' | 'gray' | 'blue';
export type BadgeVariant = 'fat' | 'slim';
export type BadgeStyle = 'filled' | 'outlined';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() label: string;
  @Input() color: BadgeColor = 'black';
  @Input() customColor: string;
  @Input() textColor: BadgeTextColor = 'white';
  @Input() variant: BadgeVariant = 'slim';

  /**
   * style property is used to determine if the badge is filled (background color) or outlined (only borders)
   */
  @Input() style: BadgeStyle = 'filled';

  get classnames() {
    const classnames = ['badge'];

    classnames.push(`badge-${this.variant}`);
    classnames.push(`badge-${this.color}`);
    classnames.push(`badge-text-${this.textColor}`);

    return classnames;
  }

  get styles(): { [key: string]: string } {
    const styles = {};

    if (this.customColor && this.color === 'custom' && this.style === 'filled') {
      styles['background-color'] = this.customColor;
    } else if (this.customColor && this.color === 'custom' && this.style === 'outlined') {
      styles['color'] = this.customColor;
      styles['border'] = 'solid';
      styles['border-width'] = '2px';
    }
    return styles;
  }
}
