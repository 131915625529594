<div class="radio-button">
  <input #input
    type="radio"
    [name]="name ? name : ''"
    [checked]="checked"
    [disabled]="disabled"
  />

  <div class="radio-circle"></div>
  <div [ngClass]="useBiggerCheckmark ? 'radio-check-big' : 'radio-check'"></div>
</div>
<div *ngIf="text" [ngStyle]="{'font-weight': useBiggerCheckmark ? '500' : 'normal'}" class="radio-button-text">{{ text }}</div>
