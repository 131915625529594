import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IncidentItemTypes } from "src/app/modules/incidents/enums/incidentItemTypes";
import { EmployeeSettingTypes } from "src/app/modules/settings/enums/employeeSettingTypes";
import { IncidentCardSubtitleType } from "src/app/modules/shared/enums/incidentCardSubtitleType";
import { JobCardSubtitleType } from "src/app/modules/shared/enums/jobCardSubtitleType";
import { LogCardSubtitleType } from "src/app/modules/shared/enums/logCardSubtitleType";
import { ObjectTypes } from "src/app/modules/shared/enums/objectTypes";
import { EmployeeSettingsService } from "src/app/modules/shared/services/employee-settings.service";
import { EmployeeSettingViewModel } from "src/app/modules/shared/viewModels/employeeSettingViewModel";
import { T } from "src/assets/i18n/translation-keys";

@Component({
  selector: 'app-filter-sorting-headers',
  templateUrl: 'filter-sorting-headers.component.html',
  styleUrls: ['filter-sorting-headers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSortingHeadersComponent implements OnInit {
  @Input() objectType: ObjectTypes;
  @Input() incidentItemType: IncidentItemTypes;

  @Output() subtitleTypeChanged = new EventEmitter<{type: EmployeeSettingTypes, setting: EmployeeSettingViewModel}>();
  objectTypes = ObjectTypes;
  incidentItemTypes = IncidentItemTypes;

  incidentCardSubtitleType = IncidentCardSubtitleType;
  selectedIncidentCardSubtitleType = IncidentCardSubtitleType.Details;
  cardSubtitleSetting: EmployeeSettingViewModel;

  subTitleSettingType = EmployeeSettingTypes.Incident_Card_Subtitle_Type;
  jobCardSubtitleType = JobCardSubtitleType;
  selectedJobCardSubtitleType = JobCardSubtitleType.Details;

  logCardSubtitleType = LogCardSubtitleType;
  selectedLogCardSubtitleType = LogCardSubtitleType.Details;

  public readonly T = T;

  constructor(private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly changeDetectorRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    if(this.objectType === ObjectTypes.Job) {
      this.subTitleSettingType = EmployeeSettingTypes.Job_Card_Subtitle_Type;
    } else if(this.incidentItemType === IncidentItemTypes.Log) {
      this.subTitleSettingType = EmployeeSettingTypes.Log_Card_Subtitle_Type;
    }

    this.employeeSettingsService.getEmployeeSetting(this.subTitleSettingType).subscribe(setting => {
      this.cardSubtitleSetting = setting;

      if (setting && setting.value) {
        if(this.objectType === ObjectTypes.IncidentItem) {
          if(this.incidentItemType === IncidentItemTypes.Incident) {
            this.selectedIncidentCardSubtitleType = +setting.value as IncidentCardSubtitleType
          } else if(this.incidentItemType === IncidentItemTypes.Log) {
            this.selectedLogCardSubtitleType = +setting.value as LogCardSubtitleType
          }
        } else if(this.objectType === ObjectTypes.Job) {
          this.selectedJobCardSubtitleType = +setting.value as JobCardSubtitleType
        }

        this.changeDetectorRef.markForCheck();
      }
    })
  }

  changeIncidentCardSubtitleType(type: IncidentCardSubtitleType) {
    this.selectedIncidentCardSubtitleType = type;
    this.updateSetting(type);
  }

  changeJobCardSubtitleType(type: JobCardSubtitleType) {
    this.selectedJobCardSubtitleType = type;
    this.updateSetting(type);
  }

  changeLogCardSubtitleType(type: LogCardSubtitleType) {
    this.selectedLogCardSubtitleType = type;
    this.updateSetting(type);
  }

  private updateSetting(type: number) {
    this.cardSubtitleSetting.value = type.toString();
    this.subtitleTypeChanged.next({ type: this.subTitleSettingType, setting: this.cardSubtitleSetting });
    this.changeDetectorRef.markForCheck();
  }
}
