import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filters-include-comment',
  templateUrl: './filters-include-comment.component.html',
  styleUrls: ['./filters-include-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersIncludeCommentComponent {
  @Input() includeComment: boolean;
  @Input() placeholder: string = 'Add comment';

  @Output() commentChanged: EventEmitter<string> = new EventEmitter();
  @Output() includeCommentChanged = new EventEmitter<boolean>();
  comment: string = '';

  onCommentChange(): void {
    this.commentChanged.emit(this.comment);
  }

  changeState() {
    this.includeComment = !this.includeComment;
    this.includeCommentChanged.next(this.includeComment);
  }
}
