import { Component, Input } from '@angular/core';
import { IconTypes } from 'src/app/modules/shared/types/iconTypes';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { IndicatorTargetBadgeTypes } from '../../../enums/indicatorTargetBadgeTypes';
import { SustainabilityUtilities } from '../../../utilities/sustainability.utilities';
import { IndicatorTargetIconViewModel } from '../../../viewModels/indicatorTargetIconViewModel';

@Component({
  selector: 'app-target-badge-icons',
  templateUrl: './target-badge-icons.component.html',
  styleUrls: ['./target-badge-icons.component.scss'],
})
export class TargetBadgeIconsComponent {
  @Input() targets: IndicatorTargetIconViewModel[];
  @Input() size: number = 24;
  @Input() disabled: boolean = false;
  @Input() showToolTipText: boolean = false;
  @Input() isTemplateTarget: boolean = false;

  getIndicatorTargetBadgeHexColour(target: IndicatorTargetIconViewModel) {
    if (this.disabled) {
      return 'var(--wt-grey-light)';
    }
    return `${SustainabilityUtilities.getIndicatorTargetBadgeHexColour(target.badgeType)}`;
  }

  getIndicatorTargetBadgeMaterialIcon(target: IndicatorTargetIconViewModel) {
    return SustainabilityUtilities.GetIndicatorTargetBadgeMaterialIcon(target.badgeType);
  }

  isTargetWithBadgeName(target: IndicatorTargetIconViewModel): string {
    return IndicatorTargetBadgeTypes[target.badgeType];
  }

  // getIndicatorTargetBadgeText(target: IndicatorTargetIconViewModel): string {
  //   return this.isTargetDue ? IndicatorTargetBadgeTypes[target.badgeType] + ' Target Due' : IndicatorTargetBadgeTypes[target.badgeType] + ' Target Achieved';
  // }

  getIndicatorTargetBadgeSVGIcon(target: IndicatorTargetIconViewModel): string {
    return IconUtilities.getSvgForIconType(
      IconTypes.Target_Badge,
      this.size,
      this.disabled,
      this.getIndicatorTargetBadgeHexColour(target)
    );
  }
}
