import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'replaceUnderscores' })
export class ReplaceUnderscoresPipe implements PipeTransform {
  transform(value: string): string {
    //only for already added in the context setings
    if (value == 'Exclude_Blue_RAGs') {
      value = 'Hide Complete';
    }

    if (value) {
      return value.replace('|', '-').replace(/_/g, ' ');
    } else {
      return value;
    }
  }
}
