import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isChildOf } from 'src/app/modules/shared/utilities/html.utilities';
import { WindowEventsEmitter } from '../../../events/window.events';
import { DropdownItem } from '../../../models/dropdownItem';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _debounceTime = 200;

  @Input() label: string;
  @Input() typeahead: Subject<string>;
  @Input() foundItems: DropdownItem[] = [];
  @Input() bindProperty: string;

  @Output() itemSelected: EventEmitter<DropdownItem> = new EventEmitter();

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLDivElement>;
  @ViewChild('input', { static: true }) input: ElementRef<HTMLInputElement>;
  @ViewChild('dropdown') dropdown: ElementRef<HTMLDivElement>;

  displayLabel: boolean = true;
  inputSubject: Subject<string> = new Subject();
  private subscriptions = new Subscription();

  constructor(private readonly windowEventsEmitter: WindowEventsEmitter, private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscriptions.add(
      this.windowEventsEmitter.windowClickEventTriggered$.subscribe((e) => {
        if (e.target !== this.wrapper.nativeElement && !isChildOf(this.wrapper.nativeElement, e.target as HTMLElement)) {
          this.input.nativeElement.classList.remove('focused');

          if (this.dropdown) {
            this.dropdown.nativeElement.classList.remove('opened');
          }

          if (!this.input.nativeElement.value) {
            this.displayLabel = true;
            this.changeDetectorRef.markForCheck();
          }
        }
      })
    );

    this.inputSubject.pipe(debounceTime(this._debounceTime)).subscribe((value) => {
      this.typeahead.next(value);
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges() {
    this.changeDetectorRef.detectChanges();
  }

  onInput() {
    this.inputSubject.next(this.input.nativeElement.value);
  }

  onClick() {
    this.input.nativeElement.classList.add('focused');
    this.input.nativeElement.focus();

    if (this.dropdown) {
      this.dropdown.nativeElement.classList.add('opened');
    }
  }

  onLabelClick(e: Event) {
    e.stopPropagation();

    this.displayLabel = false;
    this.changeDetectorRef.detectChanges();
    this.onClick();
  }

  onItemSelected(item: DropdownItem) {
    this.input.nativeElement.value = item[this.bindProperty] as string;
    this.input.nativeElement.classList.remove('focused');

    if (this.dropdown) {
      this.dropdown.nativeElement.classList.remove('opened');
    }

    this.itemSelected.next(item);
  }
}
