import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ChangeDetectorRef,
  ViewChild,
  ChangeDetectionStrategy,
  HostListener,
  ElementRef,
} from '@angular/core';
import { NgDatepickerComponent } from '../ngdatepicker/ngdatepicker.component';
import { TimeZoneService } from '../../../services/timeZone.service';
import {
  NgMinMaxDateStrategies,
  NgRangeDatesOutput,
  NgRangePickerActions,
  NgRangePickerProps,
  NgRangeSelectCallback,
} from '../../../models/rangeDatepicker/rangeDatepickerModels.model';
import * as moment from 'moment';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-ng-range-datepicker',
  templateUrl: './ng-range-datepicker.component.html',
  styleUrls: ['./ng-range-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgRangeDatepickerComponent implements OnInit, OnChanges, NgRangePickerProps {
  @ViewChild('startPicker') startPicker: NgDatepickerComponent;
  @ViewChild('endPicker') endPicker: NgDatepickerComponent;
  @ViewChild('mobileStartPicker') mobileStartPicker: NgDatepickerComponent;
  @ViewChild('mobileEndPicker') mobileEndPicker: NgDatepickerComponent;

  @Input() startDate: string | Date;
  @Input() endDate: string | Date;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() component: boolean;
  @Input() showHeader: boolean = true;
  @Input() showFooter: boolean = true;
  @Input() useRelativeHeight: boolean = false;
  @Input() timePicker: boolean = false;
  @Input() applyToAllOption: boolean = false;
  @Input() onDateSelectCallbackFn: NgRangeSelectCallback;
  @Output() startDateChanged: EventEmitter<string> = new EventEmitter();
  @Output() endDateChanged: EventEmitter<string> = new EventEmitter();

  @Output() datesChanged: EventEmitter<NgRangeDatesOutput> = new EventEmitter();
  @Output() onApplyDatesToAll: EventEmitter<NgRangeDatesOutput> = new EventEmitter();
  @Output() innerClosed: EventEmitter<boolean> = new EventEmitter();

  public localStartDate: string | Date;
  public localEndDate: string | Date;
  public visible: boolean;
  public fromDateModel: string;
  public endDateModel: string;
  public localStartDateModel: string;
  public localEndDateModel: string;
  public separator: string = '/';
  public focused: string;
  public isMobile: boolean = false;
  public isMobileStartDateSelected = true;
  public mobileTitle = '';
  public readonly minMaxDateStragies = NgMinMaxDateStrategies;
  private mobileMaxWidth = Constants.xs;
  public directiveElementRef: ElementRef<HTMLElement>;
  public timeStartString: string = '';
  public timeEndString: string = '';
  public readonly T = T;

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    if (event.target.innerWidth < this.mobileMaxWidth) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  mobileToDateInput;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private timeZoneService: TimeZoneService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.isMobile = window.innerWidth < this.mobileMaxWidth;
    this.initDateModels();
  }

  ngOnChanges() {
    this.initDateModels();
  }

  initDateModels() {
    this.fromDateModel = '';
    this.endDateModel = '';
    this.localStartDateModel = '';
    this.localEndDateModel = '';

    if (this.startDate) {
      if (this.timePicker) {
        this.fromDateModel = this.convertDateToFormatIncludesTime(new Date(this.startDate));
        this.timeStartString = this.setTimeString(this.startDate);
      } else {
        this.fromDateModel = this.convertDateToFormat(new Date(this.startDate));
      }
    }

    if (this.endDate) {
      if (this.timePicker) {
        this.endDateModel = this.convertDateToFormatIncludesTime(new Date(this.endDate));
        this.timeEndString = this.setTimeString(this.endDate);
      } else {
        this.endDateModel = this.convertDateToFormat(new Date(this.endDate));
      }
    }

    // Init localStartDate
    if (this.localStartDate) {
      if (this.timePicker) {
        this.localStartDateModel = this.convertDateToFormatIncludesTime(new Date(this.localStartDate));
        this.timeStartString = this.setTimeString(this.localStartDate);
      } else {
        this.localStartDateModel = this.convertDateToFormat(new Date(this.localStartDate));
      }
    } else if (this.startDate) {
      this.localStartDate = this.startDate;

      if (this.timePicker) {
        this.localStartDateModel = this.convertDateToFormatIncludesTime(new Date(this.startDate));
        this.timeStartString = this.setTimeString(this.startDate);
      } else {
        this.localStartDateModel = this.convertDateToFormat(new Date(this.startDate));
      }
    }

    // Init localEndDate
    if (this.localEndDate) {
      if (this.timePicker) {
        this.localEndDateModel = this.convertDateToFormatIncludesTime(new Date(this.localEndDate));
        this.timeEndString = this.setTimeString(this.localEndDate);
      } else {
        this.localEndDateModel = this.convertDateToFormat(new Date(this.localEndDate));
      }
    } else if (this.endDate) {
      this.localEndDate = this.endDate;

      if (this.timePicker) {
        this.localEndDateModel = this.convertDateToFormatIncludesTime(new Date(this.endDate));
        this.timeEndString = this.setTimeString(this.endDate);
      } else {
        this.localEndDateModel = this.convertDateToFormat(new Date(this.endDate));
      }
    }

    if (this.localStartDateModel || this.localEndDateModel) {
      this.mobileTitle = this.translateService.instant(T.common.edit_dates);
    } else {
      this.mobileTitle = this.translateService.instant(T.common.add_dates);
    }

    if (this.localStartDate && !this.localEndDate) {
      const monthToFocus = moment(this.localStartDate).get('month');
      const yearToFocus = moment(this.localStartDate).get('year');
      this.endPicker?.setExternalYearAndMonth({ year: yearToFocus, month: monthToFocus });
    }
  }

  onStartDateChange(dateAsIsoString: string) {
    this.startDateChanged.emit(dateAsIsoString);
  }

  onEndDateChange(dateAsIsoString: string) {
    this.endDateChanged.emit(dateAsIsoString);
  }
  onLocalEndDateChange(dateAsIsoString: string) {
    // If we are using the mobileFooter that means that we will deal with date localy and emit after the APPLY btn is clicked
    // Else means that we wont use the footer , so we need to emit the date
    if (this.showFooter) {
      if (!this.localEndDate || !this.localEndDateModel) {
        dateAsIsoString = moment(dateAsIsoString).add(1, 'minutes').toISOString();
      }
      this.localEndDate = dateAsIsoString;
      if (moment(dateAsIsoString).isBefore(this.localStartDate) || !this.localStartDate) {
        this.localStartDate = dateAsIsoString;
      }
      this.callTheCallback(NgRangePickerActions.EndDateChange);
      this.initDateModels();
    } else {
      if (!this.localEndDate || !this.localEndDateModel) {
        dateAsIsoString = moment(dateAsIsoString).add(1, 'minutes').toISOString();
      }
      this.localEndDate = dateAsIsoString;
      this.onEndDateChange(dateAsIsoString);
      this.onClose();
    }

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Handle start date change on desktop (no footer and no apply button),
   * create date models and emit changes.
   */
  onLocalStartDateChange(dateAsIsoString: string) {
    this.localStartDate = dateAsIsoString;

    let localEndDateChangedFlag = false;
    if (!this.localEndDate || moment(dateAsIsoString).isAfter(this.localEndDate)) {
      this.localEndDate = dateAsIsoString;
      localEndDateChangedFlag = true;
    }

    if (!this.timePicker) {
      this.isMobileStartDateSelected = false;
    }

    // If we are using the mobileFooter that means that we will deal with date localy and emit after the APPLY btn is clicked
    // Else means that we wont use the footer , so we need to emit the date
    if (this.showFooter) {
      this.callTheCallback(NgRangePickerActions.StartDateChange);
      this.initDateModels();
    } else {
      this.onStartDateChange(dateAsIsoString);
      if(localEndDateChangedFlag) {
        this.onEndDateChange(dateAsIsoString);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  callTheCallback(action: NgRangePickerActions) {
    if (this.onDateSelectCallbackFn) {
      const currentState: NgRangePickerProps = {
        startDate: this.startDate,
        endDate: this.endDate,
        minDate: this.minDate,
        maxDate: this.maxDate,
        localStartDate: this.localStartDate,
        localEndDate: this.localEndDate,
      };
      const newState = this.onDateSelectCallbackFn(action, currentState);
      this.startDate = newState.startDate;
      this.endDate = newState.endDate;
      this.minDate = newState.minDate;
      this.maxDate = newState.maxDate;
      this.localStartDate = newState.localStartDate;
      this.localEndDate = newState.localEndDate;

      this.changeDetectorRef.detectChanges();
    }
  }

  initYear() {
    if (!this.isMobile) {
      this.startPicker.initYear();
      this.endPicker.initYear();
    } else {
      if (this.isMobileStartDateSelected) {
        this.mobileStartPicker.initYear();
        this.mobileStartPicker.initMonth();
      } else {
        this.mobileEndPicker.initYear();
        this.mobileEndPicker.initMonth();
      }
    }
  }
  initMonth() {
    if (!this.isMobile) {
      this.startPicker.initMonth();
      this.endPicker.initMonth();
    }
  }

  show() {
    this.visible = true;
    this.changeDetectorRef.detectChanges();
  }

  hide() {
    this.endPicker?.clearExternalMonthAndYear();
    this.startPicker?.clearExternalMonthAndYear();
    this.visible = false;
    this.changeDetectorRef.detectChanges();
  }

  // begin
  get dateFormat(): string {
    return this.timeZoneService.dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
  }

  onFocus(side: string): void {
    this.focused = side;
  }

  convertDateToFormat(date: Date): string {
    return this.timeZoneService.localiseDateISOString(date.toISOString(), false, false);
  }

  convertDateToFormatIncludesTime(date: Date): string {
    return this.timeZoneService.localiseDateISOString(date.toISOString(), true, false);
  }

  onTextFieldClick(): void {
    this.changeDetectorRef.detectChanges();
  }

  get daysDifference() {
    return Math.abs(moment(this.localStartDate).diff(this.localEndDate, 'days')) + 1;
  }

  onApplyClick() {
    this.datesChanged.emit({ startDate: this.localStartDate, endDate: this.localEndDate });
    this.timeEndString = '';
    this.timeStartString = '';
    this.hide();
  }

  onApplyToAll() {
    this.onApplyDatesToAll.emit({ startDate: this.localStartDate, endDate: this.localEndDate });
    this.timeEndString = '';
    this.timeStartString = '';
    this.hide();
  }

  onClose() {
    this.hide();
    this.timeEndString = '';
    this.timeStartString = '';
    this.innerClosed.emit(true);
  }

  onStartTimeChange(time: string) {
    const [h, m] = time.split(':');
    const newStartDate = moment(this.localStartDate)
      .set({ hours: +h, minutes: +m, seconds: 0, milliseconds: 0 })
      .toDate();

    if (
      moment(newStartDate).isSameOrAfter(moment(this.localEndDateModel)) ||
      moment(newStartDate).isSameOrAfter(moment(this.localEndDate))
    ) {
      this.timeStartString = '';
      this.changeDetectorRef.detectChanges();
    } else {
      this.localStartDate = newStartDate;
    }

    this.initDateModels();
    this.changeDetectorRef.detectChanges();
  }

  onDueTimeChange(time: string) {
    const [h, m] = time.split(':');
    const newEndDate = moment(this.localEndDate)
      .set({ hours: +h, minutes: +m, seconds: 0, milliseconds: 0 })
      .toDate();

    if (
      moment(newEndDate).isSameOrBefore(moment(this.localStartDate)) ||
      moment(newEndDate).isSameOrBefore(moment(this.localStartDate))
    ) {
      this.timeEndString = '';
      this.changeDetectorRef.detectChanges();
    } else {
      this.localEndDate = newEndDate;
    }

    this.initDateModels();
    this.changeDetectorRef.detectChanges();
  }

  setTimeString(dateAsIsoString: string | Date) {
    return `${moment(dateAsIsoString).format('HH').toString()}:${moment(dateAsIsoString).format('mm').toString()}`;
  }
}
