import { ObjectTypes } from '../enums/objectTypes';
import { FilterTypes } from '../enums/filterTypes';
import { EmployeeCardShowingFilter } from './employeeCardShowingFilter';
import { FilterDateOptions } from '../enums/filter/filterDateOptions';

export class EmployeeCardFilterSetting {
  objectType: ObjectTypes;
  primarySortFilter: FilterTypes;
  primarySortFilterDateProperty?: FilterDateOptions;
  primarySortAscending: boolean;
  secondarySortFilter?: FilterTypes;
  secondarySortAscending?: boolean;
  secondarySortFilterDateProperty?: FilterDateOptions;
  showingFilters: EmployeeCardShowingFilter[];
  readOnlyFilters: EmployeeCardShowingFilter[];
  showEmptyLozenges: boolean;
}
