import { Injectable } from '@angular/core';
import { map, Observable, Observer, of, tap } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { DashboardWidgetViewModel } from 'src/app/modules/settings/viewModels/dashboardWidgetViewModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmployeeSettingViewModel } from '../viewModels/employeeSettingViewModel';
import { EmployeeDetailsViewModel } from '../viewModels/employeeDetailsViewModel';
import { AuthenticationService } from './authentication.service';
import { CachingService } from './caching.service';
import { LoginEventsEmitter } from '../events/login.events';
import { EmployeeSettingTypes } from '../../settings/enums/employeeSettingTypes';
import { ValidatedViewModel } from '../viewModels/validatedViewModel';
import { AccountSelectItemViewModel } from '../viewModels/accountSelectItemViewModel';
import { Employee } from '../models/employee';
import { ObjectTypes } from '../enums/objectTypes';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { AccountSettingTypes } from '../enums/accountSettingTypes';

@Injectable({
  providedIn: 'root'
})
export class EmployeeSettingsService {
  private actionUrl: string;
  private options: HttpHeaders;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private cachingService: CachingService,
    private authenticationService: AuthenticationService,
    private loginEventEmitters: LoginEventsEmitter
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`EmployeeSettings/`);
  }
  private settingTypes: { settingType: EmployeeSettingTypes; setting: EmployeeSettingViewModel }[] = [];

  public getLoggedInEmployeeForGuards() {
    const account = this.cachingService.GetAccountInfo();
    let employee = account ? this.cachingService.GetEmployee(account.id) : null;
    if (!account) {
      return new Observable<Employee>((observer: Observer<Employee>) => {
        this.loginEventEmitters.currentAccountChanged$.subscribe((account) => {
          employee = this.cachingService.GetEmployee(account.id);
          if (!employee) {
            this.loginEventEmitters.currentEmployeeChanged$.subscribe((res) => {
              observer.next(res);
            });
          } else {
            return observer.next(employee);
          }
        });
      });
    } else if (!employee) {
      return new Observable<Employee>((observer: Observer<Employee>) => {
        this.loginEventEmitters.currentEmployeeChanged$.subscribe((res) => {
          observer.next(res);
        });
      });
    } else {
      return of(employee);
    }
  }

  public getApplicableAccounts(): Observable<AccountSelectItemViewModel[]> {
    return this.http.get<AccountSelectItemViewModel[]>(this.actionUrl + `ApplicableAccounts`);
  }

  public getCurrentEmployee(): Observable<string> {
    return this.http.get(this.actionUrl + `CurrentEmployee`).pipe(
      map((res: any) => {
        return res.token;
      })
    );
  }

  public getEmployeeDashboardWidgetLayout(settingType: EmployeeSettingTypes): Observable<DashboardWidgetViewModel[]> {
    return this.http.get<DashboardWidgetViewModel[]>(`${this.actionUrl}GetEmployeeDashboardWidgetLayout/${settingType}`);
  }

  updateEmployeeDashboardWidgets(widgets: DashboardWidgetViewModel[], settingType: EmployeeSettingTypes): Observable<void> {
    return this.http.post<void>(`${this.actionUrl}UpdateEmployeeDashboardWidgetLayout/${settingType}`, widgets);
  }

  // Employee Receive Notification Setting
  public getEmployeeReceiveNotificationSetting(): Observable<EmployeeSettingViewModel> {
    return this.http.get<EmployeeSettingViewModel>(this.actionUrl + 'GetEmployeeReceiveNotificationsSetting');
  }

  updateEmployeeReceiveNotificationSetting(setting: EmployeeSettingViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateEmployeeReceiveNotificationsSetting', setting);
  }

  public getEmployeeSetting(settingType: number) {
    const matching = this.settingTypes.find((m) => settingType === m.settingType);
    return matching ? of(matching.setting) : this.getEmployeeSettingHttp(settingType);
  }

  getEmployeeSettingHttp(settingType: number): Observable<EmployeeSettingViewModel> {
    return this.http.get<EmployeeSettingViewModel>(this.actionUrl + 'GetEmployeeSetting/' + settingType.toString())
    .pipe(map((result) => {
      this.settingTypes = this.settingTypes.filter((m) => settingType !== m.settingType);
      this.settingTypes.push({ settingType: settingType, setting: result });
      return result;
    }));
  }

  updateEmployeeSetting(settingType: number, setting: EmployeeSettingViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateEmployeeSetting/' + settingType.toString(), setting).pipe(
      tap(() => {
        this.settingTypes = this.settingTypes.filter((m) => settingType !== m.settingType);
        this.settingTypes.push({ settingType: settingType, setting: setting });
      })
    );
  }

  updateEmployeeSettingInUserArea(employeeId: number, settingType: number, setting: EmployeeSettingViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateEmployeeSettingInUsersArea/' + employeeId + '/' + settingType, setting);
  }

  getEmployeeDetails(): Observable<EmployeeDetailsViewModel> {
    return this.http.get<EmployeeDetailsViewModel>(this.actionUrl + 'GetEmployeeDetails');
  }

  updateEmployeeDetails(employeeDetailsViewModel: EmployeeDetailsViewModel): Observable<string> {
    return this.http.post<string>(this.actionUrl + 'UpdateEmployeeDetails', employeeDetailsViewModel);
  }

  setEmployeeLiveEvent(eventId: number): Observable<string> {
    return this.http.post<string>(this.actionUrl + 'SetEmployeeLiveEvent/' + eventId, '');
  }

  getToken(accountId: number, broadcastChange: boolean = true): Observable<string> {
    const options = this.authenticationService.getHttpOptions();
    return this.http.post(`${this.actionUrl}Details`, null, options).pipe(
      map((res: string) => {
        this.cachingService.AddEmployeeData(res, accountId);

        if (broadcastChange) this.loginEventEmitters.broadcastCurrentEmployeeChanged(this.cachingService.GetEmployee(accountId));

        return res;
      })
    );
  }

  getEmployeesDetails(): Observable<EmployeeDetailsViewModel[]> {
    return this.http.get<EmployeeDetailsViewModel[]>(this.actionUrl + `GetEmployeesDetails`);
  }

  getExternalUsersDetails(): Observable<EmployeeDetailsViewModel[]> {
    return this.http.get<EmployeeDetailsViewModel[]>(this.actionUrl + `GetEmployeesDetails?getOnlyExternalUsers=true`);
  }

  updateEmployeeFullDetails(employeeDetailsViewModel: EmployeeDetailsViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateEmployeeFullDetails', employeeDetailsViewModel);
  }

  updateEmployeeZone(employeeDetailsViewModel: EmployeeDetailsViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'UpdateEmployeeZone', employeeDetailsViewModel);
  }

  updateEmployeePermissions(employeeDetailsViewModel: EmployeeDetailsViewModel): Observable<void> {
    return this.http.post<void>(this.actionUrl + 'Permissions', employeeDetailsViewModel);
  }

  deleteEmployees(employeeIdsToDelete: number[]): Observable<void> {
    return this.http.post<void>(`${this.actionUrl}DeleteEmployees`, employeeIdsToDelete);
  }

  addEmployee(employeeDetailsViewModel: EmployeeDetailsViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(this.actionUrl + 'AddEmployee', employeeDetailsViewModel);
  }

  completeOnboarding(): Observable<any> {
    return this.http.post<number>(this.actionUrl + 'CompleteOnboarding', null);
  }

  getPEAPSubscribersList(): Observable<EmployeeDetailsViewModel[]> {
    return this.http.get<EmployeeDetailsViewModel[]>(this.actionUrl + `GetPEAPSubscribersList`);
  }

  getConfidentialIDs(objectType: ObjectTypes): Observable<FilterViewModel[]> {
    return this.http.post<FilterViewModel[]>(`${this.actionUrl}PrivacyItems/${objectType}`, {});
  }

  getEmployeeValueBySettingType(accountSettingType: AccountSettingTypes): Observable<number> {
    return this.http.get<number>(this.actionUrl + `GetEmployeeValueBySettingType/${accountSettingType}`);
  }
}
