<ng-container *ngIf="isLoading">
  <div>currently loading</div>
</ng-container>
<ng-container *ngIf="!isLoading">
  <div class="select-account">
    <div class="select-account-section select-account-section-idle">
      Select an Account
      <div class="select-account-section-close">
        <div class="responsive-flex-wrapper">
          <i class="material-symbols-outlined">close</i>
        </div>
      </div>
    </div>
    <div class="select-account-content">
      <div class="select-account-content-card" *ngFor="let account of accounts" (click)="onLoginWithAccount(account)">
        <div class="select-account-content-card-image-account">
          <img
            [src]="localUrl + '/Resource/assets/clients/logo/' + account.logo"
            class="select-account-content-card-image-account-image"
          />
          <div class="select-account-content-card-image-account-account">{{ account.title }}</div>
        </div>

        <div class="select-account-content-card-last-logged-in">
          <div *ngIf="!account.isActive" class="select-account-content-card-last-logged-in-inactive-indicator">
            inactive
          </div>
          <div class="responsive-flex-wrapper" *ngIf="account.isActive">
            <i class="material-symbols-outlined">arrow_forward</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
