import { Injectable, OnDestroy } from '@angular/core';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Observable, of, skip, switchMap } from 'rxjs';
import { FixedZoneDropdownSelectModalComponent } from '../components/modals/fixed-zone-dropdown-select/fixed-zone-dropdown-select.component';
import { BsModalConfig } from '../config/modal.config';
import { AccountSettingTypes } from '../enums/accountSettingTypes';
import { FilterTypes } from '../enums/filterTypes';
import { FixedFilters } from '../enums/fixedFilters.enum';
import { ObjectTypes } from '../enums/objectTypes';
import { ObjectEventEmitters } from '../events/object.events';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { FixedFilterType } from '../types/FixedFilterType';
import { FixedObjectType } from '../types/FixedObjectType';
import { FilterUtilities } from '../utilities/filter.utilities';
import { ZoneViewModel } from '../viewModels/zoneViewModel';
import { AllowedFiltersService } from './allowed-filters.service';
import { AuthenticationService } from './authentication.service';
import { CachingService } from './caching.service';
import { EmployeeSettingsService } from './employee-settings.service';
import { FixedFiltersServiceAbstract } from './fixed-filters-service.abstract';
import { LocalisationService } from './localisation.service';
import { LocationService } from './location.service';
import { ModuleService } from './module.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { T } from 'src/assets/i18n/translation-keys';

@Injectable({ providedIn: 'root' })
export class FixedZoneFiltersService extends FixedFiltersServiceAbstract<ZoneViewModel> implements OnDestroy {
  protected override readonly objectTypes: ObjectTypes[] = [
    ObjectTypes.IncidentItem,
    ObjectTypes.Runsheet,
    ObjectTypes.Runsheet_Item,
    ObjectTypes.Job,
  ];
  override readonly filterType: FixedFilterType = FilterTypes.Zone;
  override readonly fixedObjectType: FixedObjectType = ObjectTypes.Zone;

  constructor(
    private readonly cachingService: CachingService,
    private readonly localisationService: LocalisationService,
    private readonly employeeSettingsService: EmployeeSettingsService,
    private readonly locationService: LocationService,
    private readonly bsModalService: BsModalService,
    private readonly bsModalConfig: BsModalConfig,
    protected readonly authenticationService: AuthenticationService,
    protected readonly moduleService: ModuleService,
    protected readonly allowedFiltersService: AllowedFiltersService,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly translateService: TranslateService
  ) {
    super(authenticationService, moduleService, allowedFiltersService, objectEventEmitters, translateService);
  }

  protected override checkServiceOnInit(): Observable<undefined> {
    this._isServiceEnabled.next(
      (this.account.useIMS || this.account.useRunSheets) && this.account.fixedFilters === FixedFilters.VenueZone
    );
    const hasCommonObjTypes = this.objectTypes.some((item) => this.moduleService.currentObjectTypes.includes(item));
    this._isFilterActive.next(this.isServiceEnabled && hasCommonObjTypes);

    return of(undefined);
  }

  protected override generateAllFilter(): FilterViewModel {
    return FilterUtilities.GenerateFilter(
      this.filterType,
      '0',
      this.translateService.instant(T.common.all_items, {
        items: this.localisationService.localiseObjectType(ObjectTypes.Zone, true),
      }),
      null,
      true,
      ObjectTypes.Global
    );
  }

  protected override generateEpmtyEntity(): ZoneViewModel {
    const emptyEntity = new ZoneViewModel(
      0,
      this.account.id,
      this.translateService.instant(T.common.all_items, {
        items: this.localisationService.localiseObjectType(ObjectTypes.Zone, true),
      }),
      null,
      null
    );

    return emptyEntity;
  }

  protected override initSubscriptions() {
    super.initSubscriptions();

    this.subscriptions.add(
      this.objectEventEmitters.accountSettingUpdated$.subscribe(({ settingType, setting }) => {
        if (settingType === AccountSettingTypes.Fixed_Filters) {
          this._isServiceEnabled.next(setting.value === FixedFilters.VenueZone.toString());
          this.populateData().subscribe();
        }
      })
    );

    this.subscriptions.add(
      this.fixedFilter$.pipe(skip(1)).subscribe((fixedFilter) => {
        const zoneId = fixedFilter ? parseInt(fixedFilter.filterValue as string) : 0;
        this.cachingService.selectedZoneID = zoneId > 0 ? zoneId.toString() : '';

        this.employeeSettingsService.getEmployeeDetails().subscribe((details) => {
          if (zoneId !== details.zoneId) {
            this.employeeSettingsService.updateEmployeeZone({ ...details, zoneId: zoneId }).subscribe();
          }
        });
      })
    );
  }

  protected override setFixedFilterOnInit(): Observable<undefined> {
    const allowedZones = this.allowedEntities;
    const allowedFilters = this.allowedFilters;

    if (allowedZones.length === 0 || !this.isServiceEnabled) {
      this.cachingService.removeFixedZonePromptCookie();
      return of(undefined);
    }

    return this.employeeSettingsService.getEmployeeDetails().pipe(
      switchMap(({ zoneId: employeeZoneId }) => {
        if (!employeeZoneId) {
          this.showFixedFilterModal();
        } else {
          const zoneInAllowed = allowedZones.find((zone) => zone.id === employeeZoneId);

          if (!employeeZoneId || !zoneInAllowed) {
            this.showFixedFilterModal();
          } else {
            const newFilter = allowedFilters.find((f) => f.filterValue as string === zoneInAllowed.id.toString());
            this._fixedFilter.next(newFilter);
          }
        }

        return of(undefined);
      })
    );
  }

  private showFixedFilterModal(): void {
    this._fixedFilter.next(this.allFilter);

    if (this.cachingService.fixedZonePromptCookie) {
      return;
    }

    const filteredAllowedZones = this.allowedEntities.filter((e) => e.id !== 0);
    const allowedFilters = this.allowedFilters;

    const modalConfig: ModalOptions<FixedZoneDropdownSelectModalComponent> = {
      ...this.bsModalConfig,
      class: 'modal-select-zone-event',
      initialState: {
        zones: filteredAllowedZones,
      },
    };

    if (!environment.disableZoneModal && filteredAllowedZones.length) {
      const bsModalRef = this.bsModalService.show(FixedZoneDropdownSelectModalComponent, modalConfig);
      bsModalRef.content.fixedZoneChanged.subscribe((id) => {
        this.cachingService.fixedZonePromptCookie = 'True';
        if (id === 0) {
          return;
        }

        const newFilter = allowedFilters.find((f) => f.filterValue as string === id.toString());
        this._fixedFilter.next(newFilter);
      });
    }
  }

  protected override populateData(): Observable<undefined> {
    if (!this.isServiceEnabled) {
      this._allowedFilters.next([]);
      this._allowedEntities.next([]);

      return of(undefined);
    }

    return this.locationService.getZones().pipe(
      switchMap((zones) => {
        zones.unshift(this.emptyEntity);
        zones = zones.sort((a, b) => a.title.toUpperCase().localeCompare(b.title.toUpperCase()));
        const newAllowedFilters = zones.map((zone) => {
          return FilterUtilities.GenerateFilter(this.filterType, zone.id.toString(), zone.title, 0, true, ObjectTypes.Global);
        });

        this._allowedFilters.next(newAllowedFilters);
        this._allowedEntities.next(zones);

        return of(undefined);
      })
    );
  }
}
