import { PrivacyStatuses } from '../../shared/enums/privacyStatuses';
import { EmployeeBasicViewModel } from '../../shared/viewModels/employeeBasicViewModel';
import { IncidentItemTypes } from '../enums/incidentItemTypes';
import { IncidentStatuses } from '../enums/incidentStatuses';
import { IncidentSubTypes } from '../enums/incidentSubTypes';
import { ModifiableEntityViewModel } from './modifiableEntityViewModel';

export class IncidentItemViewModel extends ModifiableEntityViewModel {
  refCode: string;
  severity: number;
  type: IncidentSubTypes;
  archived: boolean;
  created: string;
  latitude: number;
  longitude: number;
  locationDetails: string;
  description: string;
  headlineStatus: string;
  headlineStatusUpdated: string;
  createdBy: EmployeeBasicViewModel;
  startTime: string;
  endTime: string;
  incidentChannelId: number;
  reportedBy: string;
  eventId: number;
  commentsCount: number;
  status: IncidentStatuses;
  privacyStatus: PrivacyStatuses;
  incidentItemType: IncidentItemTypes;
  accountId: number;
  closed: string;
  isQuickAdd: boolean;
}
