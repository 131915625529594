import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-details-timepicker',
  templateUrl: './details-timepicker.component.html',
  styleUrls: ['./details-timepicker.component.scss'],
})
export class DetailsTimepickerComponent {
  @Input() date: string;
  @Input() includeDate: boolean = false;
  @Input() canEdit: boolean = true;
  @Input() label: string;
  @Input() placeholder: string = 'No Time';

  @Output() timeChanged: EventEmitter<string> = new EventEmitter();
  @Output() timeRemoved: EventEmitter<boolean> = new EventEmitter();

  onTimeChange(dateAsIsoString: string) {
    this.timeChanged.emit(dateAsIsoString);
  }
  onTimeRemove() {
    this.timeRemoved.emit();
  }
}
