import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectTypes } from '../../../enums/objectTypes';
import { RiskRagHelper } from '../../../utilities/risk-rag.utilities copy';
import { IncidentsManager } from '../../../managers/incidents.manager';
import { RagHelper } from '../../../utilities/rag.utilities';
import { ObjectSubTypes } from '../../../enums/objectSubTypes.enum';
import { IncidentItemTypes } from 'src/app/modules/incidents/enums/incidentItemTypes';
import { TaskSubTypes } from '../../../enums/taskSubTypes';
import { UrlService } from '../../../services/url.service';
import { IncidentsService } from '../../../services/incidents.service';
import { IncidentListItemViewModel } from 'src/app/modules/incidents/viewModels/incidentListItemViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IncidentsViewLogModalComponent } from 'src/app/modules/incidents/components/common/modals/view-log/view-log.component';
import { BsModalConfig } from '../../../config/modal.config';
import { BsModalEventsEmitter } from '../../../events/bsModal.events';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-card-history-feed',
  templateUrl: './card-history-feed.component.html',
  styleUrls: ['./card-history-feed.component.scss'],
})
export class CardHistoryFeedComponent {
  @Input() ragStatus: number;
  @Input() objectId: number;
  @Input() objectType: ObjectTypes;
  @Input() objectSubType: ObjectSubTypes;
  @Input() title: string;
  @Input() useDifferentIcon: string;

  private readonly incidentBubbleIconType = 'Incident_Bubble';

  log: IncidentListItemViewModel;
  bsModalRef: BsModalRef;
  constructor(
    private router: Router,
    private readonly incidentsManager: IncidentsManager,
    private readonly urlService: UrlService,
    private readonly incidentService: IncidentsService,
    private readonly bsModalService: BsModalService,
    private readonly bsModalConfig: BsModalConfig,
    private readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly alertService: AlertService
  ) {}

  getObjectRAGStatusColor(): string {
    let color = '';
    switch (this.objectType) {
      case ObjectTypes.Risk:
        color = RiskRagHelper.getRAGColourHexFromValue(this.ragStatus);
        break;
      case ObjectTypes.IncidentItem:
        color = this.incidentsManager.getColorBySeverity(this.ragStatus);
        break;
      default:
        color = RagHelper.getRAGColourHexFromValue(this.ragStatus);
        break;
    }

    return color;
  }

  getCardIconType() {
    let type = '';
    if (!this.useDifferentIcon) {
      if (this.objectSubType) {
        if (this.objectType == ObjectTypes.IncidentItem) {
          type = this.incidentBubbleIconType;
        } else if (this.objectType == ObjectTypes.Task) {
          type = TaskSubTypes[this.objectSubType];
        } else {
          type = ObjectSubTypes[this.objectSubType];
        }
      } else {
        type = ObjectTypes[this.objectType];
      }
    } else {
      type = this.useDifferentIcon;
    }

    return type;
  }

  get isLogSubType() {
    return this.objectType === ObjectTypes.IncidentItem && this.objectSubType.toString() === IncidentItemTypes.Log.toString();
  }

  onView(id: number) {
    const initialState = {
      logId: id,
    };

    this.bsModalRef = this.bsModalService.show(
      IncidentsViewLogModalComponent,
      Object.assign(this.bsModalConfig, { initialState })
    );
    this.bsModalEventsEmitter.broadcastBsModalStateChanged({ loaded: true });
    this.bsModalRef.content.onUpdatedItem.subscribe((updatedItemVM: IncidentListItemViewModel) => {
      this.changeDetectorRef.detectChanges();
    });
  }

  navigateToDetailsPage(): void {
    if (this.objectSubType.toString() === IncidentItemTypes.Log.toString() && this.objectType == ObjectTypes.IncidentItem) {
      this.onView(this.objectId);
    } else {
      this.urlService.navigateToObjectDetailsPage(this.objectType, this.objectSubType, this.objectId, false);
    }
  }
}
