import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'convertNum',
})
export class ConvertNumPipe implements PipeTransform {
  transform(number: number, convertTo: string): number {
    if (convertTo.toLowerCase() === 'positive') {
      return Math.abs(number);
    } else if (convertTo.toLowerCase() === 'negative') {
      return -Math.abs(number);
    }
  }
}
