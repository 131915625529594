import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeeAvatarSizes } from '../../../enums/employeeAvatarSizes';
import { EnumUtilities } from '../../../utilities/enum.utilities';

@Component({
  selector: 'app-initials-avatar',
  templateUrl: './initials-avatar.component.html',
  styleUrls: ['./initials-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialsAvatarComponent implements OnInit {
  @Input() title: string;
  @Input() size: EmployeeAvatarSizes = EmployeeAvatarSizes.Large;
  @Input() isHyperlink: boolean = false;
  @Input() darkBackground: boolean = false;
  @Input() showTooltipWithFullName: boolean = false;
  @Output() clicked = new EventEmitter<boolean>();

  initials = '';

  ngOnInit() {
    if(this.title) {
      const splitted = this.title.trim().split(' ');
      if(splitted && splitted.length > 1) {
        this.initials = `${splitted[0][0].toUpperCase()}${splitted[splitted.length - 1][0].toUpperCase()}`;
      } else {
        this.initials = `${this.title[0].toUpperCase()}${this.title[1].toUpperCase()}`;
      }
    }
  }

  get sizeCssClass() {
    return `avatar-${EnumUtilities.items(EmployeeAvatarSizes)
      .find((f) => f.key === this.size)
      .value.toLowerCase()}${this.isHyperlink ? ' avatar-hyperlink' : ''}`;
  }

  avatarClicked() {
    this.clicked.next(true);
  }
}
