import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Employee } from '../../../models/employee';
import { EmployeeAvatarSizes } from '../../../enums/employeeAvatarSizes';
import { EnumUtilities } from '../../../utilities/enum.utilities';
import { EmployeeViewModel } from '../../../viewModels/employeeViewModel';
import { EmployeeDetailsViewModel } from '../../../viewModels/employeeDetailsViewModel';

@Component({
  selector: 'app-employee-avatar',
  templateUrl: './employee-avatar.component.html',
  styleUrls: ['./employee-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeAvatarComponent {
  @Input() employee: Employee | EmployeeViewModel | EmployeeDetailsViewModel;
  @Input() size: EmployeeAvatarSizes = EmployeeAvatarSizes.Large;
  @Input() isHyperlink: boolean = false;
  @Input() darkBackground: boolean = false;
  @Input() showTooltipWithFullName: boolean = false;
  @Output() onClicked = new EventEmitter<boolean>();

  get defaultImageUrl() {
    return this.darkBackground ? environment.DEFAULT_AVATAR_INVERSE : environment.DEFAULT_AVATAR;
  }

  get showInitials() {
    return this.employee && this.avatarURL === this.defaultImageUrl && this.size !== EmployeeAvatarSizes.Small;
  }

  get sizeCssClass() {
    return `avatar-${EnumUtilities.items(EmployeeAvatarSizes)
      .find((f) => f.key === this.size)
      .value.toLowerCase()}${this.isHyperlink ? ' avatar-hyperlink' : ''}${
      this.avatarURL === this.defaultImageUrl ? ' default' : ''
    }`;
  }

  get avatarURL() {
    if (
      !this.employee ||
      this.employee?.thumbnailURL === environment.DEFAULT_AVATAR ||
      this.employee?.thumbnailURL?.length === 0
    ) {
      return this.defaultImageUrl;
    } else {
      return this.employee.thumbnailURL;
    }
  }

  get employeeInitials() {
    return `${this.employee?.firstName?.[0] ?? ''}${this.employee?.surname?.[0] ?? ''}`;
  }

  avatarClicked() {
    this.onClicked.next(true);
  }
}
