<app-modal>
  <app-modal-header [title]="headingText" (closed)="onClose()"></app-modal-header>

  <app-modal-body>
    <div class="row">
      <div class="col-12 d-flex">
        <app-time-box-fields [displayError]="false" [hours]="hours" [minutes]="minutes"
          (onTimeChange)="onStartTimeChange($event)"></app-time-box-fields>
      </div>
    </div>
  </app-modal-body>

  <app-modal-footer style="margin-top: 24px">
    <div class="update-modal-footer">
      <app-button-rectangle class="modal-close" [text]="T.common.cancel | translate" [size]="'large'" class="mr-8"
        [buttonTheme]="'white'" (clicked)="onClose()"></app-button-rectangle>
      <app-button-rectangle class="modal-add" [text]="T.common.save | translate" [size]="'large'" (click)="onSave()"
        [disabled]="!isChanged">
      </app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>
