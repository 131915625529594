import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration } from 'src/app/app.constants';
import { ObjectTypes } from '../enums/objectTypes';
import { EqualityUtilities } from '../utilities/equality.utilities';
import { FilterTypeSelectorViewModel } from '../viewModels/filters/filterTypeSelectorViewModel';

@Injectable({ providedIn: 'root' })
export class HeaderFiltersTypeService {
  private url: string;
  private headerFilterTypes: { objectTypes: ObjectTypes[]; isReporting: boolean; filterTypes: FilterTypeSelectorViewModel[] }[] =
    [];

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.url = this.configuration.buildEndpoint(`Filters/`);
  }

  /**
   * Get Filter Types which will be shown in the filters
   */
  public getFilterHeaderFilterTypes(objectTypes: ObjectTypes[], isReporting = false): Observable<FilterTypeSelectorViewModel[]> {
    let foundFilters: FilterTypeSelectorViewModel[] = [];

    if (!objectTypes) {
      objectTypes = [];
    }

    if (this.headerFilterTypes && this.headerFilterTypes.length) {
      const matching = this.headerFilterTypes.find(
        (f) => EqualityUtilities.arraysEqual(f.objectTypes, objectTypes) && f.isReporting === isReporting
      );

      if (matching && matching.filterTypes) {
        foundFilters = matching.filterTypes;
      }
    }

    if (foundFilters && foundFilters.length) {
      return of(foundFilters);
    } else {
      return this.http.post<FilterTypeSelectorViewModel[]>(`${this.url}filterheader/${isReporting.toString()}`, objectTypes).pipe(
        map((filterTypes) => {
          this.headerFilterTypes.push({
            objectTypes: objectTypes,
            isReporting,
            filterTypes: JSON.parse(JSON.stringify(filterTypes)) as FilterTypeSelectorViewModel[],
          });
          return filterTypes;
        })
      );
    }
  }

    /**
   * Get Filter Types which will be shown in the filters
   */
    public getHubFilterHeaderFilterTypes(objectTypes: ObjectTypes[], isReporting = false): Observable<FilterTypeSelectorViewModel[]> {
      let foundFilters: FilterTypeSelectorViewModel[] = [];

      if (!objectTypes) {
        objectTypes = [];
      }

      if (this.headerFilterTypes && this.headerFilterTypes.length) {
        const matching = this.headerFilterTypes.find(
          (f) => EqualityUtilities.arraysEqual(f.objectTypes, objectTypes) && f.isReporting === isReporting
        );

        if (matching && matching.filterTypes) {
          foundFilters = matching.filterTypes;
        }
      }

      if (foundFilters && foundFilters.length) {
        return of(foundFilters);
      } else {
        return this.http.post<FilterTypeSelectorViewModel[]>(`${this.url}hub/filterheader/${isReporting.toString()}`, objectTypes).pipe(
          map((filterTypes) => {
            this.headerFilterTypes.push({
              objectTypes: objectTypes,
              isReporting,
              filterTypes: JSON.parse(JSON.stringify(filterTypes)) as FilterTypeSelectorViewModel[],
            });
            return filterTypes;
          })
        );
      }
    }

}
