import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormHelperService } from '../../../services/form-helper.service';

@Component({
  selector: 'app-reactive-error',
  templateUrl: './reactive-error.component.html',
  styleUrls: ['./reactive-error.component.scss'],
})
export class ReactiveErrorComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  @Input() name: string;
  @Input() prefix: string;

  public errors: string[] = [];

  private subscriptions = new Subscription();

  constructor(private readonly formHelperService: FormHelperService) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.form?.controls?.[this.name].statusChanges.subscribe((v: 'VALID' | 'INVALID') => {
        const controlErrors: ValidationErrors = this.form.controls[this.name].errors;
        this.errors = this.formHelperService.getErrorStringMessages(controlErrors, this.prefix);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  stripHtml(html: string) {
    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }
}
