<ng-container *ngIf="!imageCroppingState">
  <div class="logo-upload" (click)="openFileUpload()">
    <input #fileElement type="file" class="inputfile" (change)="fileChangeEvent($event)" />
    <label
      appDragDrop
      [class.drag-enter]="dragAndDrop"
      (dropHandler)="onDropHandler($event)"
      (dragenter)="onDragEnterHandler($event)"
      (dragleave)="onDragLeaveHandler($event)"
    >
      <ng-container *ngIf="!dragAndDrop; else dropInfo">
        <div class="content-wrapper">
          <div class="upload-image-top">
            <div class="upload-image-icon">
              <ng-container *ngIf="!imageLink">
                <i class="material-symbols-outlined material-image-icon">image</i>
              </ng-container>
              <ng-container *ngIf="imageLink">
                <img
                  style="width: 50px"
                  class="profile-image"
                  [src]="imageLink"
                  [ngClass]="{ rounded: roundCropper }"
                />
              </ng-container>
            </div>
            <div class="upload-text-right">
              <div class="upload-image-title">{{ T.common.drag_and_drop_logo_here | translate }}...</div>
              <div class="upload-image-recommendation">({{ T.common.square_logos_recommended | translate }})</div>
            </div>
          </div>
          <div class="upload-button-text">{{ T.common.or_upload_from_files | translate }}...</div>
        </div>
      </ng-container>
      <ng-template #dropInfo>
        <span class="material-symbols-outlined bounce">download</span>
        <span class="drop-info-label">{{ T.common.drop_the_file_here | translate }}</span>
      </ng-template>
    </label>
  </div>
</ng-container>

<ng-container *ngIf="imageCroppingState">
  <p class="crop-tool-info">*{{ T.common.ajdust_the_cropping_tool | translate }}</p>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [imageFile]="imageFile"
    [maintainAspectRatio]="maintainAspectRatio"
    [aspectRatio]="aspectRatio"
    [roundCropper]="roundCropper"
    [format]="extension"
    (imageCropped)="onImageCropped($event)"
    (imageLoaded)="onImageLoaded()"
    (cropperReady)="onCropperReady()"
    (loadImageFailed)="onLoadImageFailed()"
  >
  </image-cropper>
</ng-container>
