import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { FilterTypes } from '../../../enums/filterTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { TaskPriorities } from '../../../enums/taskPriorities';

@Component({
  selector: 'app-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityComponent implements OnInit, OnChanges {
  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;
  @Input() filterType: FilterTypes;
  @Input() selectedPriority: FilterViewModel;
  @Input() showSelectPriorityLabel: boolean = false;
  @Input() canChange: boolean = true;
  @Input() widthPx: number = 384;
  @Input() topOffsetPx: number = 0;
  @Output() priorityChanged = new EventEmitter<FilterViewModel>();

  availablePriorityFilters: FilterViewModel[] = [];
  displayedPriorityFilters: FilterViewModel[] = [];

  public oldSelectedPriorityFilter: FilterViewModel;
  public readonly T = T;

  private allowedFilters: FilterViewModel[];
  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    protected readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.availablePriorityFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);
    this.displayedPriorityFilters = this.availablePriorityFilters.filter((priority) => priority.filterValue !== TaskPriorities.No_Priority);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes) {
      if(this.selectedPriority) this.oldSelectedPriorityFilter = JSON.parse(JSON.stringify(this.selectedPriority)) as FilterViewModel;
    }
  }

  get priorityEntries(): FilterViewModel[] {
    return this.selectedPriority ? [this.selectedPriority] : [];
  }

  public onEntrySelected(entry: FilterViewModel): void {
    this.selectedPriority = entry;
  }

  public onCancel(): void {
    this.ngDropdownDirective.unVisible();
    if(this.oldSelectedPriorityFilter) this.selectedPriority = JSON.parse(JSON.stringify(this.oldSelectedPriorityFilter)) as FilterViewModel;
    else this.selectedPriority = null;
  }

  public onApply(): void {
    this.changeDetectorRef.detectChanges();
    this.priorityChanged.emit(this.selectedPriority);
    this.ngDropdownDirective.unVisible();
  }

  onClearAll(): void {
    this.resetPriority();
    this.onApply();
  }

  resetPriority():void {
    this.selectedPriority = this.availablePriorityFilters.find((priority) => priority.filterValue === TaskPriorities.No_Priority);
  }

  private getSelectedPriorityFilter(): FilterViewModel {
    return this.availablePriorityFilters.find((x) => x.filterValue == this.selectedPriority.filterValue);
  }

  isSelected(entry: FilterViewModel): boolean {
    return this.selectedPriority && this.selectedPriority.filterValue.toString() === entry.filterValue.toString()
  }
}
