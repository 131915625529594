import { Injectable } from '@angular/core';
import { ObjectTypes } from '../../enums/objectTypes';
import { RAGStatus } from '../../models/rag/ragStatus';

@Injectable()
export class ListFilteringService<T = any> {
  public performListSearchFilter(unfilteredList: T[], stringSearchProperties: string[], searchText: string) {
    return this.performStringSearch(unfilteredList, stringSearchProperties, searchText);
  }

  public performListRAGFilter(unfilteredList: T[], rags: RAGStatus[], objectType?: ObjectTypes) {
    let filteredList = unfilteredList;
    switch (objectType) {
      case ObjectTypes.IncidentItem:
        filteredList = this.filterByRagLikeProperty(filteredList, rags, 'severity');
        break;
      case ObjectTypes.Indicator:
        filteredList = this.filterByRagLikeProperty(filteredList, rags, 'trendRAG');
        break;
      case ObjectTypes.Indicator_Template:
        filteredList = this.filterByRagLikeProperty(filteredList, rags, 'trendRag');
        break;
      case ObjectTypes.Job:
        filteredList = this.filterByRagLikeProperty(filteredList, rags, 'status');
        break;
      default:
        filteredList = this.filterByRagLikeProperty(filteredList, rags, 'rag');
        break;
    }

    return filteredList;
  }

  private performStringSearch(unfilteredList: T[], properties: string[], searchText: string): T[] {
    searchText = searchText.trim();
    let filteredList = null;

    if (!searchText || !searchText.length) {
      filteredList = [...unfilteredList];
    } else {
      const temp = unfilteredList.filter((item) => this.compareItemPropertiesAgainstSearchText(item, properties, searchText));
      filteredList = [...temp];
    }

    return filteredList;
  }

  private compareItemPropertiesAgainstSearchText(item: T, properties: string[], searchText: string, propertyIndex: number = 0) {
    if (propertyIndex > properties.length) {
      return false;
    } else {
      const val = (item[properties[propertyIndex]] as string) || '';
      const itemContainsSearchText = val.toString().toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) !== -1;
      if (itemContainsSearchText) {
        return true;
      } else {
        propertyIndex += 1;
        return this.compareItemPropertiesAgainstSearchText(item, properties, searchText, propertyIndex);
      }
    }
  }

  /**
   * Filters array by rag status property which could be named differently for different object types
   *
   * @param unfilteredList List to be filtered
   * @param ragStatuses RagStatuses to filter by
   * @param property Object property to filter by, for different object types this will be different
   * @returns Filtered array ot type T
   */
  private filterByRagLikeProperty(unfilteredList: T[], ragStatuses: RAGStatus[], property: string) {
    return ragStatuses.length === 0 ? unfilteredList : unfilteredList.filter((item) => ragStatuses.indexOf(item[property]) > -1);
  }
}
