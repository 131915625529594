export enum OperationTypes {
  Create = 1,
  Update = 2,
  Delete = 3,
  HistoryCreated = 4,
  DocumentAdded = 5,
  Archive = 6,
  RagBreakdownUpdate = 7,
  CreateList = 8,
  UpdateList = 9,
  DeleteList = 10,
  FilterLightUpdate = 11,
}
