import { FilterTypes } from '../enums/filterTypes';
import { FilterDateOptions } from '../enums/filter/filterDateOptions';
import { ObjectTypes } from '../enums/objectTypes';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { LocalisationService } from '../services/localisation.service';

/**
 * Get Proper Filter Type label for the lozenges
 */
export class FilterTypeLozengeLabelUtillities {
  private static filterTypelabels: { [ftype: number]: string } = {};

  public static ignoredLozenges(objectType: ObjectTypes): FilterTypes[] {
    const ignoredFilterTypes: FilterTypes[] = [];
    switch (objectType) {
      case ObjectTypes.Project:
      case ObjectTypes.Task:
      case ObjectTypes.Runsheet:
        ignoredFilterTypes.push(FilterTypes.RAG, FilterTypes.Title);
        break;
    }

    return ignoredFilterTypes;
  }

  public static getLabel(
    ftype: FilterTypes,
    dateproperty: FilterDateOptions,
    translateService: TranslateService,
    localisationService: LocalisationService
  ): string {
    let label = '';
    switch (ftype) {
      case FilterTypes.Date:
        switch (dateproperty) {
          case FilterDateOptions.Action_Due_Date:
            label = translateService.instant(T.lozenges_label.date_related.action_due);
            break;
          case FilterDateOptions.Activity_Date:
            label = translateService.instant(T.lozenges_label.date_related.activity);
            break;
          case FilterDateOptions.Closed:
            label = translateService.instant(T.lozenges_label.date_related.closed);
            break;
          case FilterDateOptions.Created:
            label = translateService.instant(T.lozenges_label.date_related.created);
            break;
          case FilterDateOptions.Date:
            label = translateService.instant(T.lozenges_label.date_related.date);
            break;
          case FilterDateOptions.Due_Date:
            label = translateService.instant(T.lozenges_label.date_related.due);
            break;
          case FilterDateOptions.Start_Date:
            label = translateService.instant(T.lozenges_label.date_related.start);
            break;
          case FilterDateOptions.Updated:
            label = translateService.instant(T.lozenges_label.date_related.updated);
            break;
          case FilterDateOptions.Expected_Due_Date:
            label = translateService.instant(T.lozenges_label.date_related.expected_due);
            break;
        }
        break;
      case FilterTypes.Risk_Impact_Type:
        label = translateService.instant(T.lozenges_label.risk_impact_type);
        break;
      case FilterTypes.Ref_Code:
        label = translateService.instant(T.lozenges_label.ref_code);
        break;
      case FilterTypes.Risk_Action_Status:
        label = translateService.instant(T.lozenges_label.risk_action_status);
        break;
      case FilterTypes.Risk_Action_Type:
        label = translateService.instant(T.lozenges_label.risk_action_type);
        break;
      case FilterTypes.Risk_Breakdown:
        label = translateService.instant(T.defaultLocalizations.risk.many);
        break;
      case FilterTypes.Issue_Breakdown:
        label = translateService.instant(T.defaultLocalizations.issue.many);
        break;
      case FilterTypes.Opportunity_Breakdown:
        label = translateService.instant(T.defaultLocalizations.opportunity.many);
        break;
      case FilterTypes.Runsheet_Item_Dependencies_Count:
        label = translateService.instant(T.lozenges_label.runsheet_dependencies);
        break;
      default:
        label = localisationService.localiseFilterType(ftype);
        break;
    }

    if(label === undefined)
      return '';

    label = label.replace('Risk_', '').replace('_', ' ');
    return label;
  }
}
