import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { RAGUpdate } from 'src/app/modules/planning/models/ragUpdate';
import { TaskService } from 'src/app/modules/planning/services/task-service';
import { TaskDetailsViewModel } from 'src/app/modules/planning/viewModels/projects/taskDetailsViewModel';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { RAGStatuses } from 'src/app/modules/shared/enums/ragStatuses';
import { GanttEventsEmitter } from 'src/app/modules/shared/events/gantt.events';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import {
  NgRangeDatesOutput,
  NgRangePickerActions,
  NgRangeSelectCallback,
} from 'src/app/modules/shared/models/rangeDatepicker/rangeDatepickerModels.model';
import { HasWritePermissionPipe } from 'src/app/modules/shared/pipes/has-write-permission.pipe';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import * as moment from 'moment';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { TaskSubTypes } from 'src/app/modules/shared/enums/taskSubTypes';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { ModalHeaderIconType } from 'src/app/modules/shared/components/common/modal-header/modal-header.component';
import { EnumUtilities } from 'src/app/modules/shared/utilities/enum.utilities';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { ObjectEventEmitters } from 'src/app/modules/shared/events/object.events';
import { FilterActionTypes } from 'src/app/modules/shared/enums/filter/filterActionTypes.enum';

@Component({
  selector: 'app-gantt-task-details-modal',
  templateUrl: './gantt-task-details-modal.component.html',
  styleUrls: ['./gantt-task-details-modal.component.scss'],
})
export class GanttTaskDetailsModalComponent implements OnInit, OnDestroy {
  @Output() taskUpdated = new EventEmitter<TaskDetailsViewModel>();

  private subscriptions = new Subscription();
  private _canEditTask = false;

  public readonly editableFieldTypes = EditableFieldTypes;
  public loading = false;
  public selectedTaskId: number;
  public task: TaskDetailsViewModel;
  public filterTypes = FilterTypes;
  public T = T;
  public modalHeaderIconType = ModalHeaderIconType;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly taskService: TaskService,
    private readonly alertService: AlertService,
    private readonly confirmationService: ConfirmationService,
    private readonly hasWritePermissionPipe: HasWritePermissionPipe,
    private readonly ganttEventsEmitter: GanttEventsEmitter,
    private readonly translateService: TranslateService,
    private readonly objectEventEmitters: ObjectEventEmitters
  ) {}

  ngOnInit(): void {
    if (!this.task) {
      this.getTaskDetails(this.selectedTaskId);
    } else {
      this.processTask();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get title() {
    return this.task.title;
  }

  get titleLabel() {
    return this.translateService.instant(T.common.item_title, { item: this.getLocalizedTask() });
  }

  get headerText() {
    return this.translateService.instant(T.common.item_details, { item: this.getLocalizedTask() });
  }

  get taskType(): TaskSubTypes {
    return +this.task.filters.find((f) => f.filterType === FilterTypes.Task_Type).filterValue;
  }

  get iconType(): string {
    let iconType = '';

    const objectType = EnumUtilities.items(ObjectTypes).find((t) => t.key === ObjectTypes.Task);
    if (objectType) {
      iconType = objectType.value;
    }

    return iconType;
  }

  get ragIconType(): string {
    let icon = '';
    const taskType = this.taskType;
    switch (taskType) {
      case TaskSubTypes.Task:
        icon = 'Task';
        break;
      case TaskSubTypes.Sub_Task:
        icon = 'Sub_Task';
        break;
      default:
        break;
    }

    return icon;
  }

  get isCompleted(): boolean {
    return this.task.rag === RAGStatuses.Blue;
  }

  get baselineStart(): string {
    return this.task.baselineStart;
  }

  get baselineEnd(): string {
    return this.task.baselineDue;
  }

  get taskStart(): string {
    return this.task.start;
  }

  get taskEnd(): string {
    return this.task.due;
  }

  get canEditTask(): boolean {
    return this._canEditTask;
  }

  get milestoneType(): string {
    const milestoneFilter = this.task.filters.find((f) => f.filterType == FilterTypes.Milestone_Type);
    return this.localisationService.localiseFilterValueByFilterType(milestoneFilter.filterValue, milestoneFilter.filterType);
  }

  navigateToDetaislPage() {
    let url = '';
    const taskType = this.taskType;
    switch (taskType) {
      case TaskSubTypes.Task:
        url = `/v2/planning/list/tasks/${this.task.id}`;
        break;
      case TaskSubTypes.Sub_Task:
        url = `/v2/planning/list/tasks/${this.task.id}`;
        break;
    }

    window.open(url, '_blank');
    this.close();
  }

  updateRagFilter(ev: { filters: FilterViewModel[]; comment: string }) {
    this.task.filters = ev.filters;
    const ragFilter = ev.filters.find((f) => f.filterType === FilterTypes.RAG);
    const comment = ev.comment;

    if (ragFilter) {
      this.task.rag = +ragFilter.filterValue;
    }

    this.updateTaskRag(comment);
  }

  onDatesSelectMilestoneCallback: NgRangeSelectCallback = (action, currentState) => {
    const coppiedState = { ...currentState };
    if (this.task.milestoneType) {
      if (action == NgRangePickerActions.StartDateChange) {
        coppiedState.localEndDate = coppiedState.localStartDate;
      } else if (action == NgRangePickerActions.EndDateChange) {
        coppiedState.localStartDate = coppiedState.localEndDate;
      }
    }
    return { ...coppiedState };
  };

  onDatesChange(datesAsObj: NgRangeDatesOutput) {
    if (moment(this.task.due).isBefore(moment()) && moment(datesAsObj.endDate).isSameOrAfter(moment())) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.rag_status_will_not_be_changed_automatically),
        () => {
          this.task.start = datesAsObj.startDate as string;
          this.task.due = datesAsObj.endDate as string;
          this.task.filters.find(
            (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Date
          ).filterValue = this.task.start;
          this.task.filters.find(
            (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Date
          ).filterValue = this.task.due;
          this.task.filters = this.task.filters.slice();
          this.saveChanges();
          this.changeDetectorRef.detectChanges();
        },
        () => {
          //handle cancel
        }
      );
    } else {
      this.task.start = datesAsObj.startDate as string;
      this.task.due = datesAsObj.endDate as string;
      this.task.filters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Date
      ).filterValue = this.task.start;
      this.task.filters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Date
      ).filterValue = this.task.due;
      this.task.filters = this.task.filters.slice();

      this.saveChanges();
      this.changeDetectorRef.detectChanges();
    }
  }

  onMilestoneFilterUpdate(filters: FilterViewModel[]) {
    this.task.filters = filters;

    const ragFilter = filters.find((f) => f.filterType === FilterTypes.RAG);
    const due = filters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Due_Date);
    const start = filters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Date);
    this.task.milestoneType = filters.find((f) => f.filterType == FilterTypes.Milestone_Type).filterValue;

    if (due) {
      this.task.start = due.filterValue;
      this.task.filters.find(
        (f) => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Start_Date
      ).filterValue = this.task.start;
    } else if (!start) {
      this.task.start = new Date().toUTCString();
    }

    if (ragFilter) {
      this.task.rag = ragFilter.filterValue;
    }

    this.saveChanges();
  }

  onTitleChanged($event: string) {
    this.task.title = $event;
    this.task.filters.find(f => f.filterType === FilterTypes.Title).filterValue = $event;
    this.saveChanges();
  }

  onHeadlineStatusUpdated($event: TaskDetailsViewModel) {
    this.task.headlineStatus = $event.headlineStatus;
    this.task.headlineStatusUpdated = new Date().toJSON();

    const filter = this.task.filters.find((f) => f.filterType === FilterTypes.Headline_Status);
    if (filter) {
      filter.filterValue = $event.headlineStatus;
      filter.filterAction = FilterActionTypes.Update;
    }

    this.saveChanges();
  }

  onFilterUpdated(filters: FilterViewModel[], _filterType: FilterTypes) {
    this.task.filters = filters;
    this.saveChanges();
  }

  close() {
    this.bsModalRef.hide();
  }

  public getLocalizedTask(): string {
    let localizeWord = '';

    const localizedTask = this.localisationService.localiseObjectType(ObjectTypes.Task);
    const localizedSubTask = this.localisationService.localiseSubTask();

    if (this.task) {
      const taskType = this.taskType;
      switch (taskType) {
        case TaskSubTypes.Task:
          localizeWord = localizedTask;
          break;
        case TaskSubTypes.Sub_Task:
          localizeWord = localizedSubTask;
          break;
        default:
          break;
      }
    }

    return localizeWord;
  }

  private getTaskDetails(id: number) {
    this.loading = true;
    this.subscriptions.add(
      this.taskService.details(id).subscribe((res: TaskDetailsViewModel) => {
        this.task = res;
        this.processTask();
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  private processTask() {
    this._canEditTask = this.hasWritePermissionPipe.transform(this.task, ModuleTypes.Planning, FilterTypes.Task);
  }

  private updateTaskRag(comment: string) {
    this.loading = true;

    const ragUpdate = new RAGUpdate();
    ragUpdate.taskId = this.task.id;
    ragUpdate.comment = comment;
    ragUpdate.ragStatus = this.task.rag;
    ragUpdate.projectId = +this.task.filters.find((a) => a.filterType === FilterTypes.Project).filterValue;

    this.subscriptions.add(
      this.taskService.updateRAG(ragUpdate).subscribe((r) => {
        this.taskUpdated.emit(this.task);
        void this.alertService.success(this.translateService.instant(T.common.item_rag_updated, { item: this.getLocalizedTask() }));
        this.loading = false;
      })
    );
  }

  private saveChanges() {
    this.loading = true;
    this.subscriptions.add(
      this.taskService.update(this.task).subscribe((res) => {
        this.taskUpdated.emit(res);
        void this.alertService.success(this.translateService.instant(T.common.item_updated, { item: this.getLocalizedTask() }));
        this.loading = false;
      })
    );
  }
}
