import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Observable, interval } from 'rxjs';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { CountdownSettingsViewModel } from 'src/app/modules/settings/viewModels/countdownSettingsViewModel';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { DashboardCardInterface } from '../../../../../interfaces/dashboard/dashboardCardInterface';
import { map } from 'rxjs/operators';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalUtilityService } from 'src/app/modules/shared/services/utilities/modals-utilities.service';

@Component({
  selector: 'app-dashboard-countdown',
  templateUrl: './dashboard-countdown.component.html',
  styleUrls: ['./dashboard-countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCountdownComponent extends DashboardCardInterface implements OnInit, OnDestroy {
  @ViewChild('countdownContainer') countdownContainer: ElementRef<HTMLElement>;

  private bsModalRef: BsModalRef;
  private account: Account;
  private $counter: Observable<number>;
  private subscriptions: Subscription[] = [];
  private durationToEvent: number;

  protected readonly widgetName: string = 'DashboardCountdownComponent';

  public employee: Employee;
  public loading: boolean;
  public countdownSetting: CountdownSettingsViewModel;
  public initSubscription: Subscription;
  public countdownDate: Date;
  public readonly T = T;
  public dateIsNotInPast = true;

  formattedDate: string;
  weeks: string;
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  hasCountdownSetting = false;
  resizeIntervalListener: any = null;

  constructor(
    private cachingService: CachingService,
    private accountSettingsService: AccountSettingsService,
    private timeZoneService: TimeZoneService,
    private dashboardEventEmitters: DashboardEventEmitters,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private readonly modalUtilityService: ModalUtilityService
  ) {
    super();
  }

  ngOnInit() {
    this.loading = true;
    this.account = this.cachingService.GetAccountInfo();
    this.employee = this.cachingService.GetEmployee(this.account.id);
    this.getDashboardCountdownSettings();
    this.subscriptions.push(
      this.dashboardEventEmitters.countdownSettingsChanged$.subscribe(() => {
        this.getDashboardCountdownSettings();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  onResized(ev: Event) {
    this.setSizes();
  }

  configureWidget() {
    this.bsModalRef = this.modalUtilityService.openDashboardWidgetConfigModal(this.widgetName);
  }

  get headerTitle(): string {
    return this.translateService.instant(T.common.countdown);
  }

  private initCountdownSubscription() {
    this.$counter = interval(1000).pipe(
      map((x) => {
        this.durationToEvent = Math.floor((this.countdownDate.getTime() - new Date().getTime()) / 1000);
        this.changeDetectorRef.detectChanges();
        return x;
      })
    );
    this.subscriptions.push(
      this.$counter.subscribe((x) => {
        this.extractTimeDifference(this.durationToEvent);
      })
    );
  }

  private getDashboardCountdownSettings() {
    this.subscriptions.push(
      this.accountSettingsService.getCountdownTimerSettings().subscribe((res) => {
        this.hasCountdownSetting = !!res;

        if (!res) {
          res = new CountdownSettingsViewModel();
        }

        this.countdownSetting = res;

        if (res.countdownDate) {
          this.countdownDate = new Date(this.timeZoneService.localiseDateISOStringToDate(res.countdownDate));
          if (this.countdownDate < new Date()) {
            this.dateIsNotInPast = false;
          } else {
            this.dateIsNotInPast = true;
            this.initCountdownSubscription();
          }
        }

        this.loading = false;
        this.setSizes();
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  private extractTimeDifference(t: number) {
    const weeks = Math.floor(t / 604800);
    t -= weeks * 604800;

    const days = Math.floor(t / 86400);
    t -= days * 86400;

    const hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;

    const minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;

    const seconds = t % 60;

    this.weeks = this.setFormattedString(weeks);
    this.days = this.setFormattedString(days);
    this.hours = this.setFormattedString(hours);
    this.minutes = this.setFormattedString(minutes);
    this.seconds = this.setFormattedString(seconds);

    this.changeDetectorRef.detectChanges();
  }

  private setFormattedString(timeAsNumber: number) {
    if (timeAsNumber < 10 && timeAsNumber > -10) {
      return '0' + timeAsNumber.toString();
    } else {
      return timeAsNumber.toString();
    }
  }

  protected setSizes() {}
}
