import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { ExternalObjectTypesViewModel, WebhookBaseViewModel, WebhookViewModel } from '../models/integrations/webhook.model';

@Injectable({
  providedIn: 'root'
})
export class WebhookService {


  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`webhook/`);
  }


  createWebhook(webhook: WebhookBaseViewModel): Observable<WebhookViewModel> {
    return this.http.post<WebhookViewModel>(this.actionUrl, webhook);
  }

  deleteWebhook(id: number): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}${id}`);
  }

  getWebhookById(id: number): Observable<WebhookViewModel> {
    return this.http.get<WebhookViewModel>(`${this.actionUrl}${id}`);
  }

  getAllWebhooksForAccount(): Observable<WebhookViewModel[]> {
    return this.http.get<WebhookViewModel[]>(`${this.actionUrl}account`);
  }


  getExternalObjectTypes(): Observable<ExternalObjectTypesViewModel[]> {
    return this.http.get<ExternalObjectTypesViewModel[]>(`${this.actionUrl}ObjectTypes`);
  }
}
