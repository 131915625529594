import { ModifiableEntityWithLocationViewModel } from '../../incidents/viewModels/modifiableEntityWithLocationViewModel';
import { ZoneViewModel } from '../viewModels/zoneViewModel';
import { GMapUtilities } from './gMap.utilities';

export class LocationUtilities {
  public static setLocationDetails(
    zones: ZoneViewModel[],
    itemWithLocation: ModifiableEntityWithLocationViewModel
  ): ModifiableEntityWithLocationViewModel {
    if (zones && zones.length) {
      const polygon = GMapUtilities.buildPolygonFromZoneModel(zones[0].zoneShapePoints, null);
      const polyLatLngBounds = GMapUtilities.getPolygonLatLngBounds(polygon);
      const polygonCenterLatLng = polyLatLngBounds.getCenter();

      itemWithLocation.latitude = polygonCenterLatLng.lat();
      itemWithLocation.longitude = polygonCenterLatLng.lng();
    }

    return itemWithLocation;
  }
}
