import { Pipe, PipeTransform } from '@angular/core';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { EmployeeRoleTypes } from '../enums/employees/EmployeeRoleTypes';
import { FilterTypes } from '../enums/filterTypes';
import { Employee } from '../models/employee';
import { AuthenticationService } from '../services/authentication.service';
import { EmployeeUtil } from '../utilities/employee.utilities';

@Pipe({
  name: 'canDelete',
})
export class CanDeletePipe implements PipeTransform {
  private employee: Employee;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.employee = authenticationService.getCurrentEmployee();
  }

  transform(obj: { id: number }, moduleType: ModuleTypes, filterType: FilterTypes): boolean {
    const planningFtypes = [FilterTypes.Project, FilterTypes.Task, FilterTypes.Task_Group];

    if (EmployeeUtil.IsAdmin(this.employee)) {
      return true;
    }

    if (planningFtypes.some((r) => r === filterType) && EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Project_Admin)) {
      return true;
    }

    const primaryFilters = {
      [FilterTypes.Risk]: [FilterTypes.Risk],
      [FilterTypes.Project]: [FilterTypes.Project, FilterTypes.Task, FilterTypes.Task_Group],
      [FilterTypes.Incident]: [FilterTypes.Incident],
      [FilterTypes.Job]: [FilterTypes.Job],
    };

    // Apply this when createdById is added in the models
    // const isCreator = obj.createdById == this.employee.id;
    const isCreator = false;

    if (primaryFilters[FilterTypes.Project].some((f) => f === filterType) && isCreator) {
      if (
        (filterType === FilterTypes.Task_Group || filterType === FilterTypes.Task) &&
        EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Project_Admin)
      ) {
        return true;
      }
    }

    if (filterType === FilterTypes.Risk && (EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Risk_Manager) || isCreator)) {
      return true;
    }

    return false;

    /**
     * Logic
     * # Project
     *    ## Only if He has PrimaryAdmin role or he is Creator of the Project NB!!! creators are Project_Admins
     *        If user is Admin || Project Admin || Creator
     * # Task Group
     *    Task groups can be added from anyone with Edit Permission so that mean the creator should be able to delete it
     *    ## If user is Admin || Project Admin || Creator
     * # Task
     *    Tasks can be added by everyone with Edit Permissions sa that also mean the creator should be able to delete it
     *    ## If user is Admin || Project Admin || Creator
     *
     *
     *
     * #Risk
     * Risk Dont have delete for now but if they have it should be
     *  Risks can be created by everyone with edit Permissions ( in draft status) so that mean Creator should be able to delete it
     *  Risk_Manager and Admins also will be able to delete is and here Risk_manager is for all risks so the logic should be:
     *  IF user is Admin || Risk_Manager || Creator
     *
     * #Actions they dont have permission matrix stil , but for now we will apply the same logic as Risks
     * IF user is Admin || Risk_Manager || Creator
     *
     *
     * Incidents
     * Incidents can be Archived only by Account Admin AND (User Admin and Admin) that are assigned
     */
  }
}
