<div class="status-stars"
  ngDropdown
  [templateRef]="filterTemplate"
  [closeUponSelection]="true"
  [canOpen]="canEdit"
>
  <rating [ngModel]="currentPriority" [max]="3" [readonly]="true" [customTemplate]="ratingTemplate" [titles]="filterTitles"></rating>
</div>

<ng-template #ratingTemplate let-i="index" let-v="value">
  <span
    class="material-symbols-outlined star"
    [ngStyle]="{ color: i < v ? 'var(--wt-black)' : 'var(--wt-grey-light)', cursor: 'pointer' }"
    >star</span
  >
</ng-template>

<ng-template #filterTemplate>
  <div class="filters-container">
    <div class="priority-container">
      <div class="priority-container-title">{{ T.common.select_priority | translate }}</div>
      <div class="priority-container-entries-container">
        <app-filters-list-entries
          [filterType]="filterType"
          [entries]="availablePriorityFilters"
          [selectedEntries]="[selectedPriorityFilter]"
          [mobile]="mobile"
          [required]="true"
          (entrySelected)="onEntrySelected($event)"
        >
        </app-filters-list-entries>
      </div>
    </div>
  </div>
</ng-template>
