import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { IconTypes } from '../../../types/iconTypes';
import { IconUtilities } from '../../../utilities/icon.utilities';

@Component({
  selector: 'app-empty-state',
  templateUrl: 'empty-state-placeholder.component.html',
  styleUrls: ['empty-state-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStatePlaceholderComponent {
  @Input() showAddItemBtn = false;
  @Input() itemTitle = this.translateService.instant(T.common.item.one);
  @Input() message = this.translateService.instant(T.common.no_item_to_display, {
    item: this.translateService.instant(T.common.item.one),
  });
  @Input() iconType: IconTypes;
  @Input() materialIcon: string;
  @Input() hideIcon: boolean = false;
  @Input() isClickable: boolean = true;
  @Input() containerHeight: number = 100;

  @Output() onItemAddClicked = new EventEmitter<void>();

  public readonly T = T;

  constructor(private readonly translateService: TranslateService) {}

  get icon() {
    const icon = IconUtilities.getSvgForIconType(this.iconType);
    return icon ? icon : '';
  }

  addItemClick() {
    if (this.isClickable) {
      this.onItemAddClicked.next();
    }
  }
}
