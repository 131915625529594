import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/modules/shared/models/constants';

@Component({
  selector: 'app-tabset',
  templateUrl: './tabset.component.html',
  styleUrls: ['./tabset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsetComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ContentChildren(TabComponent, { static: true } as unknown) tabComponents: QueryList<TabComponent>;

  @Input() tabIndex: number;
  @Input() tabId: string;
  @Input() useTopBorder = true;
  @Input() isCollapsed: boolean = false;
  @Input() useCollapseButton: boolean = false;

  @Output() tabChanged: EventEmitter<TabComponent> = new EventEmitter<TabComponent>();
  @Output() tabIndexChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() collapseChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscriptions: Subscription[] = [];

  public mobileWidth = Constants.xs;
  public isMobile = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.isMobile = window.innerWidth <= this.mobileWidth;

    this.tabComponents.toArray().forEach((tabComponent) => {
      this.subscriptions.push(tabComponent.activated.subscribe(() => this.changeTab(tabComponent)));
    });

    if (this.tabId) {
      const tab = this.tabs.find((t) => t.id.toString() === this.tabId.toString());
      if (tab) {
        tab.activate();
      } else {
        this.tabComponents.first.activate();
      }
    } else {
      this.tabComponents.first.activate();
    }

    const activeTab = this.tabs.find((t) => t.active);
    if (activeTab) {
      this.changeTab(activeTab);
    }
  }

  ngAfterViewInit() {
    if (this.tabId) {
      const tab = this.tabs.find((t) => t.id.toString() === this.tabId.toString());
      if (tab) {
        tab.activate();
        const activeTab = this.tabs.find((t) => t.active);
        if (activeTab) {
          this.changeTab(activeTab);
        }
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges() {
    if (this.tabIndex) {
      this.tabs.filter((_, i) => i !== this.tabIndex).forEach((t) => (t.active = false));
      this.tabs[this.tabIndex].active = true;
    } else if (this.tabId) {
      this.tabs.forEach((tab) => {
        tab.active = tab.id === this.tabId.toString();
      });
    } else {
      this.tabComponents.first.active = true;
      // this.tabComponents.find(x => x.isTabVisible === true).active = true;
    }

    const activeTab = this.tabs.find((t) => t.active);
    if (activeTab) {
      this.changeTab(activeTab);
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.tabComponents.forEach((s) => s.ngOnDestroy());
    this.tabComponents = null;

    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  get tabs(): TabComponent[] {
    return this.tabComponents.toArray();
  }

  get activeTab(): TabComponent {
    return this.tabs.find((t) => t.active);
  }

  onTabClicked(tab: TabComponent): void {
    const activeTab = this.tabs.find((t) => t.active);
    if (activeTab) {
      if (tab.id !== activeTab.id) {
        this.changeTab(tab);
      }
    } else {
      this.changeTab(tab);
    }
  }

  changeTab(tab: TabComponent): void {
    this.tabs.filter((t) => t !== tab).forEach((t) => (t.active = false));
    tab.active = true;
    this.tabChanged.next(tab);
    this.tabIndexChanged.next(this.tabs.findIndex((tab) => tab.active));
    this.changeDetectorRef.detectChanges();
  }

  toggleCollapseButtonEvent(e): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapseChanged.next(this.isCollapsed);
  }
}
