<div class="dates-container" [class.ngdatepicker-visible]="visible" [class.in-component]="component">
  <!-- Edit Dates Only Desktop -->
  <div class="desktop-pickers" *ngIf="!isMobile && !timePicker">
    <app-modal-header
      *ngIf="showHeader"
      [title]="mobileTitle"
      [showDelete]="false"
      (closed)="onClose()"
    ></app-modal-header>
    <div class="filters-date-date-range-container" *ngIf="!isMobile">
      <div class="filters-date-date-range-sub-container">
        <app-text-field #fromDateInput
          [placeholder]="T.lozenges_label.date_related.start | translate"
          [useNgDateMask]="true"
          [format]="dateFormat"
          [separator]="'/'"
          [(ngModel)]="localStartDateModel"
        ></app-text-field>
        <app-ngdatepicker #startPicker
          class="start-range"
          [class.filters-date-datepicker-active]="true"
          [date]="localStartDate"
          [helperDate]="localEndDate"
          [maxDate]="localEndDate"
          [component]="true"
          [isInRangePicker]="true"
          [minMaxDateStrategy]="showFooter ? minMaxDateStragies.EmitDate : minMaxDateStragies.DisableDate"
          (dateChanged)="onLocalStartDateChange($event)"
        ></app-ngdatepicker>
      </div>
      <div class="filters-date-date-range-sub-container">
        <app-text-field #toDateInput
          [placeholder]="T.lozenges_label.date_related.due | translate"
          [useNgDateMask]="true"
          [format]="dateFormat"
          [separator]="'/'"
          [(ngModel)]="localEndDateModel"
        ></app-text-field>
        <app-ngdatepicker #endPicker
          class="end-range"
          [class.filters-date-datepicker-active]="true"
          [date]="localEndDate"
          [minDate]="localStartDate"
          [helperDate]="localStartDate"
          [isEndDate]="true"
          [component]="true"
          [isInRangePicker]="true"
          [minMaxDateStrategy]="showFooter ? minMaxDateStragies.EmitDate : minMaxDateStragies.DisableDate"
          (dateChanged)="onLocalEndDateChange($event)"
        ></app-ngdatepicker>
      </div>
    </div>
    <app-modal-footer *ngIf="showFooter">
      <div class="desktop-footer-container">
        <div class="desktop-footer-container__left">
          <span>{{ T.common.count_days | keyPlural: daysDifference | translate: { count: daysDifference } }}</span>
        </div>
        <div class="desktop-footer-container__right">
          <app-button-transparent
          [text]="T.common.cancel | translate"
          size="medium"
          (clicked)="onClose()"
          ></app-button-transparent>
          <ng-container *ngIf="applyToAllOption">
            <app-button-rectangle
            [text]="T.common.apply_to_all | translate"
            size="medium"
            buttonTheme="white"
            (clicked)="onApplyToAll()"
            ></app-button-rectangle>
          </ng-container>
          <app-button-rectangle
            size="medium"
            buttonTheme="primary"
            [text]="T.common.apply | translate"
            (clicked)="onApplyClick()"
          ></app-button-rectangle>
        </div>
      </div>
      <!-- <app-button-rectangle size='large' text='Apply' (clicked)="onApplyClick()" fitToContainer="true"></app-button-rectangle> -->
    </app-modal-footer>
  </div>

  <!-- Edit Dates Mobile -->
  <div
    class="dates-mobile-container"
    *ngIf="isMobile && !timePicker"
    [class.adapt-to-parent-height]="useRelativeHeight"
  >
    <app-modal>
      <app-modal-header
        *ngIf="showHeader"
        [title]="mobileTitle"
        [showDelete]="false"
        (closed)="onClose()"
      ></app-modal-header>
      <app-modal-body>
        <div
          class="date-input"
          (click)="isMobileStartDateSelected = true"
          [ngStyle]="isMobileStartDateSelected === true && { 'border-bottom': '2px solid var(--wt-primary)' }"
          style="margin-bottom: 24px"
        >
          <app-text-field
            #mobileTofromDateInput
            [placeholder]="T.lozenges_label.date_related.start | translate"
            [useNgDateMask]="true"
            [format]="dateFormat"
            [disableMargin]="true"
            [separator]="'/'"
            [disabled]="false"
            [(ngModel)]="localStartDateModel"
          ></app-text-field>
        </div>
        <div
          class="date-input"
          (click)="isMobileStartDateSelected = false"
          [ngStyle]="isMobileStartDateSelected === false && { 'border-bottom': '2px solid var(--wt-green)' }"
          style="margin-top: 24px; margin-bottom: 24px"
        >
          <app-text-field
            #mobileToDateInput
            [placeholder]="T.lozenges_label.date_related.due | translate"
            [useNgDateMask]="true"
            [format]="dateFormat"
            [separator]="'/'"
            [disableMargin]="true"
            [disabled]="false"
            [(ngModel)]="localEndDateModel"
          ></app-text-field>
        </div>
        <div class="mobile-datepicker-container">
          <app-ngdatepicker
            *ngIf="isMobileStartDateSelected"
            class="start-range"
            style="border: 1px solid var(--wt-primary)"
            #mobileStartPicker
            [class.filters-date-datepicker-active]="true"
            [date]="localStartDate"
            [helperDate]="localEndDate"
            [maxDate]="localEndDate"
            [component]="true"
            [isInRangePicker]="true"
            [minMaxDateStrategy]="showFooter ? minMaxDateStragies.EmitDate : minMaxDateStragies.DisableDate"
            (dateChanged)="onLocalStartDateChange($event)"
          ></app-ngdatepicker>
          <app-ngdatepicker
            *ngIf="!isMobileStartDateSelected"
            class="end-range"
            style="border: 1px solid var(--wt-green)"
            #mobileEndPicker
            [class.filters-date-datepicker-active]="true"
            [date]="localEndDate"
            [minDate]="localStartDate"
            [helperDate]="localStartDate"
            [isEndDate]="true"
            [component]="true"
            [isInRangePicker]="true"
            [minMaxDateStrategy]="showFooter ? minMaxDateStragies.EmitDate : minMaxDateStragies.DisableDate"
            (dateChanged)="onLocalEndDateChange($event)"
          ></app-ngdatepicker>
        </div>
      </app-modal-body>
      <app-modal-footer *ngIf="showFooter">
        <app-button-rectangle
          size="large"
          [text]="T.common.apply | translate"
          (clicked)="onApplyClick()"
          fitToContainer="true"
        ></app-button-rectangle>
      </app-modal-footer>
    </app-modal>
  </div>

  <!-- Edit Time and Dates Desktop -->
  <div class="desktop-pickers" *ngIf="!isMobile && timePicker">
    <app-modal-header
      *ngIf="showHeader"
      [title]="mobileTitle"
      [showDelete]="false"
      (closed)="onClose()"
    ></app-modal-header>
    <div class="filters-date-date-range-container" *ngIf="!isMobile">
      <div class="filters-date-date-range-sub-container">
        <app-text-field
          #fromDateInput
          [placeholder]="T.lozenges_label.date_related.start | translate"
          [useNgDateMask]="true"
          [format]="dateFormat"
          [separator]="'/'"
          [(ngModel)]="localStartDateModel"
        ></app-text-field>
        <app-ngdatepicker
          class="start-range"
          #startPicker
          [class.filters-date-datepicker-active]="true"
          [date]="localStartDate"
          [helperDate]="localEndDate"
          [maxDate]="localEndDate"
          [component]="true"
          [isInRangePicker]="true"
          [minMaxDateStrategy]="showFooter ? minMaxDateStragies.EmitDate : minMaxDateStragies.DisableDate"
          (dateChanged)="onLocalStartDateChange($event)"
        ></app-ngdatepicker>
        <div class="timepicker" [ngClass]="!localStartDate ? 'disabled' : ''">
          <div class="time-title">{{ T.defaultLocalizations.start_time.one | translate }}:</div>
          <ngx-timepicker-field
            [controlOnly]="true"
            [ngModel]="timeStartString"
            [format]="24"
            (ngModelChange)="onStartTimeChange($event)"
          ></ngx-timepicker-field>
        </div>
      </div>
      <div class="filters-date-date-range-sub-container">
        <app-text-field
          #toDateInput
          [placeholder]="T.lozenges_label.date_related.end | translate"
          [useNgDateMask]="true"
          [format]="dateFormat"
          [separator]="'/'"
          [(ngModel)]="localEndDateModel"
        ></app-text-field>
        <app-ngdatepicker
          #endPicker
          class="end-range"
          [class.filters-date-datepicker-active]="true"
          [date]="localEndDate"
          [minDate]="localStartDate"
          [helperDate]="localStartDate"
          [isEndDate]="true"
          [component]="true"
          [isInRangePicker]="true"
          [minMaxDateStrategy]="showFooter ? minMaxDateStragies.EmitDate : minMaxDateStragies.DisableDate"
          (dateChanged)="onLocalEndDateChange($event)"
        ></app-ngdatepicker>
        <div class="timepicker" [ngClass]="!localEndDate ? 'disabled' : ''">
          <div class="time-title">{{ T.defaultLocalizations.end_time.one | translate }}:</div>
          <ngx-timepicker-field
            [controlOnly]="true"
            [ngModel]="timeEndString"
            [format]="24"
            (ngModelChange)="onDueTimeChange($event)"
          ></ngx-timepicker-field>
        </div>
      </div>
    </div>
    <app-modal-footer *ngIf="showFooter">
      <div class="desktop-footer-container">
        <div class="desktop-footer-container__left">
          <span>{{ T.common.count_days | keyPlural: daysDifference | translate: { count: daysDifference } }}</span>
        </div>
        <div class="desktop-footer-container__right">
          <app-button-transparent
            [text]="T.common.cancel | translate"
            size="medium"
            (clicked)="onClose()"
          ></app-button-transparent>
          <ng-container *ngIf="applyToAllOption">
            <app-button-rectangle
            [text]="'Apply to all'"
            size="medium"
            buttonTheme="white"
            (clicked)="onApplyToAll()"
            ></app-button-rectangle>
          </ng-container>
          <app-button-rectangle
            size="medium"
            buttonTheme="primary"
            [text]="T.common.apply | translate"
            (clicked)="onApplyClick()"
          ></app-button-rectangle>
        </div>
      </div>
      <!-- <app-button-rectangle size='large' text='Apply' (clicked)="onApplyClick()" fitToContainer="true"></app-button-rectangle> -->
    </app-modal-footer>
  </div>

  <!-- Edit Time and Dates Mobile -->
  <div class="dates-mobile-container" *ngIf="isMobile && timePicker" [class.adapt-to-parent-height]="useRelativeHeight">
    <app-modal>
      <app-modal-header
        *ngIf="showHeader"
        [title]="mobileTitle"
        [showDelete]="false"
        (closed)="onClose()"
      ></app-modal-header>
      <app-modal-body>
        <div
          class="date-input"
          (click)="isMobileStartDateSelected = true"
          [ngStyle]="isMobileStartDateSelected === true && { 'border-bottom': '2px solid var(--wt-primary)' }"
          style="margin-bottom: 24px"
        >
          <app-text-field
            #mobileTofromDateInput
            [placeholder]="T.lozenges_label.date_related.start | translate"
            [useNgDateMask]="true"
            [format]="dateFormat"
            [disableMargin]="true"
            [separator]="'/'"
            [disabled]="false"
            [(ngModel)]="localStartDateModel"
          ></app-text-field>
        </div>
        <div
          class="date-input"
          (click)="isMobileStartDateSelected = false"
          [ngStyle]="isMobileStartDateSelected === false && { 'border-bottom': '2px solid var(--wt-green)' }"
          style="margin-top: 24px; margin-bottom: 24px"
        >
          <app-text-field
            #mobileToDateInput
            [placeholder]="T.lozenges_label.date_related.due | translate"
            [useNgDateMask]="true"
            [format]="dateFormat"
            [separator]="'/'"
            [disableMargin]="true"
            [disabled]="false"
            [(ngModel)]="localEndDateModel"
          ></app-text-field>
        </div>
        <div class="mobile-datepicker-container">
          <app-ngdatepicker
            *ngIf="isMobileStartDateSelected"
            class="start-range"
            style="border: 1px solid var(--wt-primary)"
            #mobileStartPicker
            [class.filters-date-datepicker-active]="true"
            [date]="localStartDate"
            [helperDate]="localEndDate"
            [maxDate]="localEndDate"
            [component]="true"
            [isInRangePicker]="true"
            [minMaxDateStrategy]="showFooter ? minMaxDateStragies.EmitDate : minMaxDateStragies.DisableDate"
            (dateChanged)="onLocalStartDateChange($event)"
          ></app-ngdatepicker>
          <app-ngdatepicker
            *ngIf="!isMobileStartDateSelected"
            class="end-range"
            style="border: 1px solid var(--wt-green)"
            #mobileEndPicker
            [class.filters-date-datepicker-active]="true"
            [date]="localEndDate"
            [minDate]="localStartDate"
            [helperDate]="localStartDate"
            [isEndDate]="true"
            [component]="true"
            [isInRangePicker]="true"
            [minMaxDateStrategy]="showFooter ? minMaxDateStragies.EmitDate : minMaxDateStragies.DisableDate"
            (dateChanged)="onLocalEndDateChange($event)"
          ></app-ngdatepicker>
          <div *ngIf="!isMobileStartDateSelected" class="timepicker" [ngClass]="!localEndDate ? 'disabled' : ''">
            <div class="time-title">{{ T.defaultLocalizations.end_time.one | translate }}:</div>
            <ngx-timepicker-field
              [controlOnly]="true"
              [ngModel]="timeEndString"
              [format]="24"
              (ngModelChange)="onDueTimeChange($event)"
            ></ngx-timepicker-field>
          </div>
          <div *ngIf="isMobileStartDateSelected" class="timepicker" [ngClass]="!localStartDate ? 'disabled' : ''">
            <div class="time-title">{{ T.defaultLocalizations.start_time.one | translate }}:</div>
            <ngx-timepicker-field
              [controlOnly]="true"
              [ngModel]="timeStartString"
              [format]="24"
              (ngModelChange)="onStartTimeChange($event)"
            ></ngx-timepicker-field>
          </div>
        </div>
      </app-modal-body>
      <app-modal-footer *ngIf="showFooter">
        <app-button-rectangle
          size="large"
          [text]="T.common.apply | translate"
          (clicked)="onApplyClick()"
          fitToContainer="true"
        ></app-button-rectangle>
      </app-modal-footer>
    </app-modal>
  </div>
</div>
