import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  HostListener,
  SimpleChanges,
  TemplateRef,
  OnInit,
  OnChanges,
  AfterViewInit,
} from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { Constants } from 'src/app/modules/shared/models/constants';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { AuthenticationService } from '../../../services/authentication.service';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { Employee } from '../../../models/employee';
import { IconUtilities } from '../../../utilities/icon.utilities';
import { LocalisationService } from '../../../services/localisation.service';
import { Router } from '@angular/router';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { Account } from '../../../models/account';
import { UserAgentService } from '../../../services/user-agent.service';
import { EqualityUtilities } from '../../../utilities/equality.utilities';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { IndicatorUpdateExpectedDirections } from 'src/app/modules/sustainability/enums/indicatorUpdateExpectedDirections';

@Component({
  selector: 'app-details-lozenge',
  templateUrl: './details-lozenge.component.html',
  styleUrls: ['./details-lozenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsLozengeComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('detailsLozengeWrapper', { static: true }) detailsLozengeWrapper: ElementRef<HTMLElement>;

  //Input
  @Input() customTemplate: TemplateRef<HTMLElement>;
  @Input() showAccordionLozengeIcon: boolean = false;
  @Input() accordionLozenge: boolean = false;
  @Input() filters: FilterViewModel[];
  @Input() filterType: FilterTypes;
  @Input() canEdit: boolean;
  @Input() singleSelect: boolean = false;
  @Input() heading: string = '';
  @Input() relatedObjectId: number = 0;
  @Input() useFilterValueAsLink = false;
  @Input() handleEditExternally = false;
  @Input() isFilterRequired: boolean = false;
  @Input() useConfirmation: boolean = false;
  @Input() confirmationHeader: string = '';
  @Input() confirmationText: string = '';
  @Input() useClearAllButton: boolean = true;
  @Input() clearPaddingInLozenge: boolean = false;
  @Input() isSearchActive: boolean = true;
  @Input() showOverflowingLozenges = false;
  /**
   * Divider for accordion lozenge overflow. Used with [InnerHtml] so can be any valid html.
   */
  @Input() accordionOverflowDivider: string = ',<br />';
  // Account hub Specific Input for selecting filters for proper child or hub account
  @Input() accountId: number = 0;
  /**
   * Wheather to use simple update or not. If true, the component will emit only the updated filters.
   */
  @Input() useLightUpdate: boolean = false;
  @Input() dropdownListItems: FilterViewModel[] = [];
  @Input() showEmptyFilters: boolean = true;

  @Output() onFiltersUpdated = new EventEmitter<FilterViewModel[]>();
  @Output() onExternalEditClicked = new EventEmitter<FilterTypes>();

  //public
  filterTypes = FilterTypes;
  filterSelectorTypes = FilterSelectorTypes;
  editMode: boolean = false;
  filterTypeSelectorViewModel = new FilterTypeSelectorViewModel();
  filtersByFilterType: FilterViewModel[] = [];
  employee: Employee;
  allFilters: FilterViewModel[];
  isTouchDevice: boolean = false;
  localisedFilterType: string;
  showEditButton: boolean = false;
  iconByType: string;
  canvas: any;
  overflowingCount: number = 0;
  public primaryFilterTooltipMessage: string = '';
  account: Account;
  excludeInactiveFilterTypes = [FilterTypes.Event, FilterTypes.Incident_Channel];
  public readonly T = T;

  constructor(
    public readonly elementRef: ElementRef<HTMLElement>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly userAgentService: UserAgentService,
    private readonly authenticationService: AuthenticationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly localisationService: LocalisationService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.account = this.authenticationService.getCurrentAccount();

    if (this.filterType) {
      this.localisedFilterType = this.localisationService.localiseFilterType(this.filterType);

      this.iconByType = IconUtilities.getIconForFilterType(this.filterType);
    }

    this.userAgentService.isTouch$.subscribe((isTouch) => {
      this.isTouchDevice = isTouch;
      this.changeDetectorRef.markForCheck();
    });

    this.employee = this.authenticationService.getCurrentEmployee();
    this.filterTypeSelectorViewModel.filterType = this.filterType;
    this.filterTypeSelectorViewModel.filterSelectorType = FilterSelectorTypes.Dropdown;
    this.initFilters();

    this.primaryFilterTooltipMessage = this.translateService.instant(T.common.primary_filter_details_lozenge_tooltip_message, {
      item: this.lozengeTitle,
      items: this.pluralLozengeTitleIfThereIsNoHeading
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.filterType && changes.filterType.currentValue) {
      this.iconByType = IconUtilities.getIconForFilterType(this.filterType);

      this.filtersByFilterType = this.filters.filter((f) => f.filterType === this.filterType);

      this.setOverflowingCount();
      this.localisedFilterType = this.localisationService.localiseFilterType(this.filterType);
    }
    if (changes && changes.filters && changes.filters.currentValue && this.allFilters) {
      this.filtersByFilterType = this.filters.filter((f) => f.filterType === this.filterType);

      this.filters.forEach((f) => {
        if (!f.filterText) {
          const matching = this.allFilters.find(
            (filter) => filter.filterType === f.filterType && filter.filterValue.toString() === f.filterValue.toString()
          );

          if (matching) {
            f.filterText = matching.filterText;
            f.relatedObjectId = matching.relatedObjectId;
          }
        }
        if (this.filterType) {
          this.filtersByFilterType = this.filters.filter((f) => f.filterType === this.filterType);
          this.setOverflowingCount();
        }
      });

      this.filtersByFilterType = FilterUtilities.DistinctFilterValues(this.filtersByFilterType);
      if(!this.showEmptyFilters) {
        this.filtersByFilterType = this.filtersByFilterType.filter(f => f.filterText !== '')
      }
      this.filtersByFilterType.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary));
    }
  }

  ngAfterViewInit() {
    this.setOverflowingCount();
  }

  initFilters() {
    this.allFilters =
      this.dropdownListItems && this.dropdownListItems.length > 0
        ? this.dropdownListItems
        : this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);

    if (this.accountId > 0) {
      const accountFilters = this.allFilters.filter((d) => d.accountId && d.accountId === this.accountId);
      this.allFilters = accountFilters;
    }

    if (this.relatedObjectId > 0) {
      this.allFilters = this.allFilters.filter((f) => f.relatedObjectId.toString() === this.relatedObjectId.toString());
    }

    if (this.filterType && this.excludeInactiveFilterTypes.indexOf(this.filterType) >= 0) {
      this.allFilters = this.allFilters.filter((f) => f.isActive);
    }

    const shouldBeLocalised = FilterUtilities.IsFilterTypeWithLocalisedValues(this.filterType);

    if (shouldBeLocalised) {
      this.allFilters.forEach((f) => {
        f.filterText = this.localisationService.localiseFilterValueByFilterType(+f.filterValue, f.filterType);
      });
    }

    if (this.filterType === FilterTypes.Expected_Direction) {
      this.allFilters.forEach((filter) => {
        if (filter.filterValue == IndicatorUpdateExpectedDirections.Up) {
          filter.filterText =
            `${filter.filterText} - ` + this.translateService.instant(T.settings.imports_and_exports.import_guides.increasing);
        }
        if (filter.filterValue == IndicatorUpdateExpectedDirections.Down) {
          filter.filterText =
            `${filter.filterText} - ` + this.translateService.instant(T.settings.imports_and_exports.import_guides.decreasing);
        }
      });
    }

    this.filters.forEach((f) => {
      if (!f.filterText || shouldBeLocalised) {
        const matching = this.allFilters.find(
          (filter) => filter.filterType === f.filterType && filter.filterValue.toString() === f.filterValue.toString()
        );
        if (matching) {
          f.filterText = matching.filterText;
          f.relatedObjectId = matching.relatedObjectId;
        }
      }
      if (this.filterType) {
        this.filtersByFilterType = this.filters.filter((f) => f.filterType === this.filterType && f.filterValue != -1);

        if(!this.showEmptyFilters) {
          this.filtersByFilterType = this.filtersByFilterType.filter(f => f.filterText !== '')
        }

        this.setOverflowingCount();
      }
    });

    this.filters.sort((a, b) => {
      if (a.isPrimary === b.isPrimary) {
        return 0;
      }

      if (a.isPrimary) {
        return -1;
      }

      if (b.isPrimary) {
        return +1;
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  goToDetailsPage(event: MouseEvent, filter: FilterViewModel) {
    if (this.useFilterValueAsLink) {
      event.stopPropagation();

      let route = '';

      if (this.filterType === FilterTypes.Project) {
        route = `/v2/planning/list/projects/${filter.filterValue as string}`;
      }

      void this.router.navigateByUrl(route);
    }
  }

  get first(): FilterViewModel {
    return this.filtersByFilterType[0];
  }

  get selectorType(): FilterSelectorTypes {
    if (!this.first) {
      return;
    }

    return this.first.filterSelectorType;
  }

  get lozengeTitle() {
    return this.heading.length > 0 ? this.heading : this.localisedFilterType;
  }

  get pluralLozengeTitleIfThereIsNoHeading() {
    return this.heading.length > 0 ? this.heading : this.localisationService.localiseFilterType(this.filterType, true);
  }

  setOverflowingCount() {
    const filterText = this.filtersByFilterType.map((f) => f.filterText).join(', ');

    if (this.detailsLozengeWrapper && this.detailsLozengeWrapper.nativeElement) {
      const containerWidth = this.detailsLozengeWrapper.nativeElement.clientWidth;

      const textWidth = this.getTextWidth(filterText);

      if (containerWidth >= textWidth) {
        this.overflowingCount = 0;
      } else {
        let previousElementsWidths = 0;
        for (let i = this.filtersByFilterType.length - 1; i > 0; i--) {
          let filterText = this.filtersByFilterType[i].filterText;
          if (this.filtersByFilterType.length - 1 > i) {
            filterText += ', ';
          }
          const elementWidth = this.getTextWidth(filterText);

          previousElementsWidths += elementWidth;

          if (containerWidth >= textWidth - previousElementsWidths) {
            this.overflowingCount = this.filtersByFilterType.length - i;
            break;
          }
        }
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setOverflowingCount();
  }

  getTextWidth(text): number {
    if (!this.canvas) {
      this.canvas = document.createElement('canvas');
    }

    const canvas = this.canvas;
    const context = canvas.getContext('2d');
    context.font = '14px sans-serif';
    const metrics = context.measureText(text);
    return metrics.width as number;
  }

  toggleEditMode(isDropdownOpen: boolean) {
    if (this.canEdit) {
      this.editMode = isDropdownOpen;
      this.filters = this.filters.slice();
      this.changeDetectorRef.markForCheck();
    }
  }

  get isMobile(): boolean {
    return document.body.clientWidth <= Constants.xs;
  }

  toggleShowEditButton(isVisible: boolean) {
    this.showEditButton = isVisible;
  }

  @HostListener('document:keydown.escape')
  onEscape(): void {
    // this.editMode = false;
    // this.showEditButton = false;
    this.changeDetectorRef.markForCheck();
  }

  public updateFilters(filters: FilterViewModel[]): void {
    if (this.useConfirmation) {
      this.confirmationService.confirm({
        headMessage: this.confirmationHeader,
        message: this.confirmationText,
        confirm: () => {
          this.saveFilters(filters);
        },
        cancel: () => {}
      });
    } else {
      this.saveFilters(filters);
    }
  }

  private saveFilters(filters: FilterViewModel[]) {
    if (this.useLightUpdate) {
      this.saveFiltersLight(filters);
    } else {
      this.saveFiltersLegacy(filters);
    }
  }

  /**
   * @deprecated
   * Used to save filters in the old way. Which was emmiting all filters and not just the ones for the current filter type.
   */
  private saveFiltersLegacy(filters: FilterViewModel[]) {
    const existingFilters = this.filters.filter((f) => f.filterType === this.filterType);

    if (filters.length > 1) {
      filters = filters.filter((e) => e.filterValue.toString() != '0');
    }

    if (!EqualityUtilities.arraysEqual(existingFilters, filters)) {
      const otherFilters = this.filters.filter((f) => f.filterType !== this.filterType);

      if (filters && filters.length) {
        otherFilters.push(...filters);
      }

      this.onFiltersUpdated.next(otherFilters);

      this.filtersByFilterType = otherFilters.filter((f) => f.filterType === this.filterType);

      if(!this.showEmptyFilters) {
        this.filtersByFilterType = this.filtersByFilterType.filter(f => f.filterText !== '')
      }

      this.filtersByFilterType = FilterUtilities.DistinctFilterValues(this.filtersByFilterType);

      this.setOverflowingCount();
    }

    this.filters.sort((a, b) => {
      if (a.isPrimary === b.isPrimary) {
        return 0;
      }

      if (a.isPrimary) {
        return -1;
      }

      if (b.isPrimary) {
        return +1;
      }
    });

    // this.editMode = false;
    // this.showEditButton = false;
    this.changeDetectorRef.markForCheck();
  }

  private saveFiltersLight(filters: FilterViewModel[]) {
    const existingFilters = this.filters.filter((f) => f.filterType === this.filterType);

    // TODO: Investigate why this is needed and document it here
    if (filters.length > 1) {
      filters = filters.filter((e) => e.filterValue.toString() != '0');
    }

    if (!EqualityUtilities.arraysEqual(existingFilters, filters)) {
      this.onFiltersUpdated.next(filters);
      this.filtersByFilterType = filters;
      // TODO: Revise if this is still needed
      this.filtersByFilterType = FilterUtilities.DistinctFilterValues(this.filtersByFilterType);
      this.setOverflowingCount();
    }

    //TODO: Investigate why this is needed and document it here
    this.filters.sort((a, b) => {
      if (a.isPrimary === b.isPrimary) {
        return 0;
      }

      if (a.isPrimary) {
        return -1;
      }

      if (b.isPrimary) {
        return +1;
      }
    });
    this.changeDetectorRef.markForCheck();
  }

  onEditClicked() {
    this.onExternalEditClicked.next(this.filterType);
  }

  public IsEditButtonVisible(): boolean {
    return this.showEditButton || this.isTouchDevice;
    // return (this.showEditButton || this.isTouchDevice) && !this.editMode;
  }
}
