<div>
  <ng-container *ngIf="dashboardInfoWidgetForm">
    <form [formGroup]="dashboardInfoWidgetForm">
      <div class="row content-wrapper mb-2 mt-0">
        <div class="col-12">
          <app-text-field
            [formGroup]="dashboardInfoWidgetForm"
            formControlName="widgetTitle"
            [placeholder]="(T.defaultLocalizations.title.one | translate) + '*'"
            [maxLength]="25"
            [autofocus]="true"
            [required]="true"
            [useReactiveMessages]="true"
          ></app-text-field>
        </div>
      </div>

      <div class="row content-wrapper mb-2 mt-0">
        <div class="col-12">
          <app-text-field
            [formGroup]="dashboardInfoWidgetForm"
            formControlName="widgetField1Label"
            [placeholder]="'*'"
            [maxLength]="25"
            [autofocus]="true"
            [required]="true"
            [useReactiveMessages]="true"
          ></app-text-field>
        </div>
        <div class="col-12">
          <app-text-field
            [formGroup]="dashboardInfoWidgetForm"
            formControlName="widgetField1Text"
            [placeholder]="'*'"
            [maxLength]="255"
            [autofocus]="true"
            [required]="true"
            [useReactiveMessages]="true"
          ></app-text-field>
        </div>
      </div>

      <div class="row content-wrapper mb-2 mt-0">
        <div class="col-12">
          <app-text-field
            [formGroup]="dashboardInfoWidgetForm"
            formControlName="widgetField2Label"
            [placeholder]="'*'"
            [maxLength]="25"
            [autofocus]="true"
            [required]="true"
            [useReactiveMessages]="true"
          ></app-text-field>
        </div>
        <div class="col-12">
          <app-text-field
            [formGroup]="dashboardInfoWidgetForm"
            formControlName="widgetField2Text"
            [placeholder]="'*'"
            [maxLength]="255"
            [autofocus]="true"
            [required]="true"
            [useReactiveMessages]="true"
          ></app-text-field>
        </div>
      </div>

      <div class="row content-wrapper mb-2 mt-0">
        <div class="col-12">
          <app-text-field
            [formGroup]="dashboardInfoWidgetForm"
            formControlName="widgetField3Label"
            [placeholder]="'*'"
            [maxLength]="25"
            [autofocus]="true"
            [required]="true"
            [useReactiveMessages]="true"
          >
          </app-text-field>
        </div>
        <div class="col-12">
          <app-text-field
            [formGroup]="dashboardInfoWidgetForm"
            formControlName="widgetField3Text"
            [placeholder]="'*'"
            [maxLength]="255"
            [autofocus]="true"
            [required]="true"
            [useReactiveMessages]="true"
          >
          </app-text-field>
        </div>
      </div>
    </form>
  </ng-container>
</div>
