<app-modal>
  <app-modal-header [title]="T.common.add_location | translate" (closed)="onCancel()"> </app-modal-header>

  <app-modal-body>
    <div class="row">
      <div class="col-12 mb-32">
        <app-incident-map
          [lat]="defaultLocation.latitude"
          [lng]="defaultLocation.longitude"
          [editMode]="true"
          [showNoLocationButton]="false"
          [showMyLocationButton]="false"
          (onLocationUpdated)="onIncidentLocationUpdated($event)"
        ></app-incident-map>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <app-button
      [type]="ButtonType.Block"
      [text]="T.common.cancel | translate"
      (clicked)="onCancel()">
    </app-button>
    <app-button
      [type]="ButtonType.Block"
      [buttonTheme]="ButtonTheme.Primary"
      [text]="T.common.confirm | translate"
      (clicked)="onConfirm()"
    ></app-button>
  </app-modal-footer>
</app-modal>
