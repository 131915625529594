<div class="unauthorized-wrapper">
  <div class="unauthorized select-account">
    <img [src]="logoUrl | safeUrl" />
    <h3>Hang on a second...</h3>
    <p>
      You do not have access to this WeTrack account. Please click below to see the list of accounts you have access to.
    </p>
    <button type="button" class="btn btn-primary btn-block" (click)="goToAccountList()">Account List</button>
    <div class="select-account-section select-account-section-action" (click)="onLogout()">Logout</div>
  </div>
</div>
