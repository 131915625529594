import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CachingService } from './caching.service';
import { Employee } from '../models/employee';
import { Account } from '../models/account';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isBrowser: boolean;

  constructor(private cachingService: CachingService, private readonly authService: AuthService) {}

  logout() {
    this.authService.logOut();
  }

  public getCurrentAccount(): Account {
    return this.cachingService.GetAccountInfo();
  }

  public getCurrentAccountObservable(): Observable<Account> {
    return of(this.getCurrentAccount());
  }

  public getCurrentEmployeeObservable(): Observable<Employee> {
    return this.cachingService.employee$;
  }

  public getCurrentEmployee(): Employee {
    const currAccount = this.getCurrentAccount();

    if (currAccount) {
      return this.cachingService.GetEmployee(currAccount.id);
    } else return null;
  }

  public getHttpOptions(includeAuth: boolean = true) {
    let headers: HttpHeaders;

    if (includeAuth) {
      const currentEmployee: Employee = this.getCurrentEmployee();

      if (currentEmployee && currentEmployee.token) {
        headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + currentEmployee.token,
        });
      } else {
        headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        });
      }
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      });
    }

    return { headers };
  }

  public getRequestOptions(includeAuth: boolean = true) {
    const mHeaders = new HttpHeaders();

    mHeaders.append('Content-Type', 'application/json');
    mHeaders.append('Accept', 'application/json');

    const currAccount = this.getCurrentAccount();
    const currentEmployee: Employee = this.cachingService.GetEmployee(currAccount.id);

    if (includeAuth && currentEmployee && currentEmployee.token) {
      mHeaders.append('Authorization', 'Bearer ' + currentEmployee.token);
    }

    return { headers: mHeaders };
  }
}
