<div class="mt-3" *ngIf="objectType === objectTypes.IncidentItem">
  <ng-container *ngIf="incidentItemType === incidentItemTypes.Incident">
    <app-radio-button [checked]="this.selectedIncidentCardSubtitleType === incidentCardSubtitleType.Updated"
      [text]="T.control.subtitle.show_id_updated_actual | translate"
      (checkedChanged)="changeIncidentCardSubtitleType(incidentCardSubtitleType.Updated)">
    </app-radio-button>
    <app-radio-button [checked]="this.selectedIncidentCardSubtitleType === incidentCardSubtitleType.Details"
      [text]="T.control.subtitle.show_id_started_actual | translate"
      (checkedChanged)="changeIncidentCardSubtitleType(incidentCardSubtitleType.Details)">
    </app-radio-button>
    <app-radio-button [checked]="this.selectedIncidentCardSubtitleType === incidentCardSubtitleType.UpdatedRelative"
      [text]="T.control.subtitle.show_id_updated_relative | translate"
      (checkedChanged)="changeIncidentCardSubtitleType(incidentCardSubtitleType.UpdatedRelative)">
    </app-radio-button>
    <app-radio-button [checked]="this.selectedIncidentCardSubtitleType === incidentCardSubtitleType.CreatedRelative"
      [text]="T.control.subtitle.show_id_started_relative | translate"
      (checkedChanged)="changeIncidentCardSubtitleType(incidentCardSubtitleType.CreatedRelative)">
    </app-radio-button>
    <app-radio-button class="mb-2"
      [checked]="this.selectedIncidentCardSubtitleType === incidentCardSubtitleType.HeadlineStatus"
      [text]="T.control.subtitle.show_headline_status | translate"
      (checkedChanged)="changeIncidentCardSubtitleType(incidentCardSubtitleType.HeadlineStatus)">
    </app-radio-button>
  </ng-container>
  <ng-container *ngIf="incidentItemType === incidentItemTypes.Log">
    <app-radio-button [checked]="this.selectedLogCardSubtitleType === logCardSubtitleType.Updated"
      [text]="T.control.subtitle.show_id_updated_actual | translate"
      (checkedChanged)="changeLogCardSubtitleType(logCardSubtitleType.Updated)">
    </app-radio-button>
    <app-radio-button [checked]="this.selectedLogCardSubtitleType === logCardSubtitleType.Details"
      [text]="T.control.subtitle.show_id_started_actual | translate"
      (checkedChanged)="changeLogCardSubtitleType(logCardSubtitleType.Details)">
    </app-radio-button>
    <app-radio-button [checked]="this.selectedLogCardSubtitleType === logCardSubtitleType.UpdatedRelative"
      [text]="T.control.subtitle.show_id_updated_relative | translate"
      (checkedChanged)="changeLogCardSubtitleType(logCardSubtitleType.UpdatedRelative)">
    </app-radio-button>
    <app-radio-button [checked]="this.selectedLogCardSubtitleType === logCardSubtitleType.CreatedRelative"
      [text]="T.control.subtitle.show_id_started_relative | translate"
      (checkedChanged)="changeLogCardSubtitleType(logCardSubtitleType.CreatedRelative)">
    </app-radio-button>
  </ng-container>
</div>
<div class="mt-3" *ngIf="objectType === objectTypes.Job">
  <app-radio-button [checked]="this.selectedJobCardSubtitleType === jobCardSubtitleType.Details"
    [text]="T.control.subtitle.show_id_job_type | translate"
    (checkedChanged)="changeJobCardSubtitleType(jobCardSubtitleType.Details)">
  </app-radio-button>
  <app-radio-button [checked]="this.selectedJobCardSubtitleType === jobCardSubtitleType.HeadlineStatus"
    [text]="T.control.subtitle.show_headline_status | translate"
    (checkedChanged)="changeJobCardSubtitleType(jobCardSubtitleType.HeadlineStatus)">
  </app-radio-button>
  <app-radio-button [checked]="this.selectedJobCardSubtitleType === jobCardSubtitleType.Created"
    [text]="T.control.subtitle.show_id_created | translate"
    (checkedChanged)="changeJobCardSubtitleType(jobCardSubtitleType.Created)">
  </app-radio-button>
  <app-radio-button class="mb-2" [checked]="this.selectedJobCardSubtitleType === jobCardSubtitleType.Due"
    [text]="T.control.subtitle.show_id_due | translate" (checkedChanged)="changeJobCardSubtitleType(jobCardSubtitleType.Due)">
  </app-radio-button>
</div>
