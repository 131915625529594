<div
*ngIf="showTooltipWithFullName"
[app-tooltip]="{ message: title }">
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</div>

<div
*ngIf="!showTooltipWithFullName" >
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</div>

<ng-template #avatar>
  <div class="employee-avatar" [class]="sizeCssClass" (click)="avatarClicked()">
    <div class="initials">
      {{ initials }}
    </div>
  </div>
</ng-template>
