import { Component, Input } from '@angular/core';
import { IndicatorTrendRAGs } from 'src/app/modules/sustainability/enums/indicatorTrendRAGs';
import { IndicatorTrendStatuses } from 'src/app/modules/sustainability/enums/indicatorTrendStatuses';
import { IncidentSeverities } from '../../../enums/incidents/incidentSeverities';
import { RiskRAGStatuses } from '../../../enums/planning/riskRagStatuses';
import { RAGStatuses } from '../../../enums/ragStatuses';
import { DetailIconTypes } from '../../../types/DetailsIcon.types';

@Component({
  selector: 'app-details-icon',
  templateUrl: './details-icon.component.html',
  styleUrls: ['./details-icon.component.scss'],
})
export class DetailsIconComponent {
  public trendStatuses = IndicatorTrendStatuses;

  @Input() type: DetailIconTypes;
  @Input() backgroundFill: string = 'none';
  @Input() innerFill: string = '#353F47';
  @Input() outlined: boolean = false;
  @Input() size: number = 32;
  @Input() ragStatus: RAGStatuses | RiskRAGStatuses | IncidentSeverities | IndicatorTrendRAGs;
  @Input() trendStatus: IndicatorTrendStatuses;
}
