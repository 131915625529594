<div
*ngIf="showTooltipWithFullName"
[app-tooltip]="{ message: employee?.firstName + ' ' + employee?.surname }">
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</div>

<div
*ngIf="!showTooltipWithFullName" >
  <ng-container *ngTemplateOutlet="avatar"></ng-container>
</div>

<ng-template #avatar>
  <img *ngIf="!showInitials" class="employee-avatar" [class]="sizeCssClass" (click)="avatarClicked()" [src]="avatarURL" />

  <div *ngIf="showInitials" class="employee-avatar" [class]="sizeCssClass" (click)="avatarClicked()">
    <div class="initials">
      {{ employeeInitials }}
    </div>
  </div>
</ng-template>
