import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { getCookie, setCookie, deleteCookie } from '../shared/services/caching.service';
import { NotificationsService } from '../shared/services/notifications.service';

@Injectable()
export class AuthGuardService {
  constructor(
    private authService: AuthService,
    private router: Router,
    private readonly notificationsService: NotificationsService
  ) {
  }

  //TODO: Move to auth service and handle internaly
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      if (!getCookie('redirectURL')) {
        setCookie('redirectURL', state.url.toString());
      }
      this.authService.login();
      return false;
    } else {

      this.authService.isAuthenticatedSubject$.next(true);

      let url = getCookie('redirectURL');
      if (url) {
        if (url.endsWith('#openNotificationCenter')) {
          url = url.replace('#openNotificationCenter', '');
          this.notificationsService.forceShowNotifications = true;
        }

        void this.router.navigateByUrl(url);
        deleteCookie('redirectURL');
      }

      return true;
    }
  }

  /**
   * Big lepej.....
   * IMS Only issue
   */
  public GetRedirectCookie(): string {
    return getCookie('redirectURL');
  }
}
