import { Component, OnInit, Renderer2, ViewChild, ElementRef, HostListener } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
})
export class ModalConfirmDeleteComponent implements OnInit {
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;

  form: UntypedFormGroup;

  itemName: string;
  withCode: boolean;

  public onClose: Subject<boolean> = new Subject();

  constructor(private renderer: Renderer2, public bsModalRef: BsModalRef, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      i1: ['', [Validators.required, Validators.pattern('0')]],
      i2: ['', [Validators.required, Validators.pattern('0')]],
      i3: ['', [Validators.required, Validators.pattern('0')]],
      i4: ['', [Validators.required, Validators.pattern('0')]],
    });
  }

  @HostListener('document:keydown', ['$event']) escapeAndEnterHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onDelete();
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'Escape') {
      this.onCancel();
    }
  }

  onKeyDown(e: KeyboardEvent) {
    const currentTarged = e.target as HTMLInputElement;
    const currentId = currentTarged.id;
    const previousElemId = `input${+currentId - 1}`;
    const previousElemRef = this[previousElemId] as ElementRef;
    const nextElem = this.renderer.nextSibling(currentTarged);
    const isDelete = e.key === 'Backspace' || e.key === 'Delete';
    const currentFormControlName = this.form.get(`i${currentId}`);

    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      this.onDelete();
      return false;
    }

    // This will allow only 1 digit
    // Will move to previos input when user delete
    // Will move to next input when use enter digit
    if (currentTarged.value.length === 0) {
      if (isDelete) {
        if (previousElemRef) {
          previousElemRef.nativeElement.focus();
          return;
        }
      } else {
        if (nextElem !== null) {
          currentFormControlName.setValue(`${e.key}`);
          nextElem.focus();
          return false;
        }
      }
    } else if (currentTarged.value.length > 0) {
      if (!isDelete) {
        currentFormControlName.setValue(`${e.key}`);
        if (nextElem !== null) {
          nextElem.focus();
        }
        return false;
      }
    }
  }

  onDelete() {
    if (this.withCode) {
      if (this.form.valid) {
        this.onClose.next(true);
        this.bsModalRef.hide();
      }
    } else {
      this.onClose.next(true);
      this.bsModalRef.hide();
    }
  }

  onCancel() {
    this.onClose.next(false);
    this.bsModalRef.hide();
  }
}
