import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { skip, switchMap } from 'rxjs/operators';
import { Configuration } from 'src/app/app.constants';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { FilterTypes } from '../enums/filterTypes';
import { OperationTypes } from '../enums/operationTypes';
import { FilterSelectorTypes } from '../enums/filter/filterSelectorTypes';
import { FilterUtilities } from '../utilities/filter.utilities';
import { ObjectTypes } from '../enums/objectTypes';
import { MINUTE_IN_MS } from '../utilities/date.utilities';
import { EnumUtilities } from '../utilities/enum.utilities';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AllowedFiltersService {
  private url: string;

  private readonly _allowedFiltersByType = new BehaviorSubject(new Map<FilterTypes, FilterViewModel[]>());
  get allowedFiltersByType() {
    return this._allowedFiltersByType.value;
  }
  readonly allowedFiltersByType$ = this._allowedFiltersByType.asObservable();
  readonly allowedFiltersByTypeChanged$ = this._allowedFiltersByType.asObservable().pipe(skip(1));

  constructor(private http: HttpClient, private configuration: Configuration, private authenticationService:AuthenticationService) {
    this.url = this.configuration.buildEndpoint(`Filters/`);
  }

  public init(): Observable<undefined> {

    const account = this.authenticationService.getCurrentAccount();
    if(!account.isHubAccount){
      setInterval(() => this.loadAllowedFilters().subscribe(), MINUTE_IN_MS * 6);
    }

    return this.loadAllowedFilters().pipe(switchMap(() => of(null)));
  }

  public getCachedAllowedFiltersByType(filterType: FilterTypes): FilterViewModel[] {
    const result = this._allowedFiltersByType.value.get(filterType);

    return result ? JSON.parse(JSON.stringify(result)) as FilterViewModel[]: [];
  }

  public getCachedAllAllowedFilters(): FilterViewModel[] {
    const filters = Array.from(this._allowedFiltersByType.value.values()).flatMap((f) => f);

    return JSON.parse(JSON.stringify(filters)) as FilterViewModel[];
  }

  public getChildHubAllowedFilters():Observable<FilterViewModel[]> {

    let account = this.authenticationService.getCurrentAccount();
    return this.http.get<FilterViewModel[]>(this.url + `hub/allowed/child/${account.id}`).pipe(
      switchMap((filters) => {

        let allowedFilters = this.getCachedAllAllowedFilters();

        let missingFilters = filters.filter(f => !allowedFilters.some(af => af.accountId === f.accountId));

        allowedFilters.push(...missingFilters);
        this.setFilterDictionaries(allowedFilters);

        return of(null);
      })
    );
  }

  private loadAllowedFilters(): Observable<null> {
    return this.http.get<FilterViewModel[]>(this.url + `GetAllowedFilters`).pipe(
      switchMap((filters) => {
        this.setFilterDictionaries(filters);
        return of(null);
      })
    );
  }

  private setFilterDictionaries(filters: FilterViewModel[]): void {
    const allowedFiltersByType = new Map<FilterTypes, FilterViewModel[]>();

    EnumUtilities.items(FilterTypes).forEach((filter) => {
      allowedFiltersByType.set(
        filter.key,
        filters.filter((f) => f.filterType === filter.key)
      );
    });

    this._allowedFiltersByType.next(allowedFiltersByType);
  }

  /**
   * Refresh currently saved Allowed filter by specific operation
   */
  public refreshFilters(
    operation: OperationTypes,
    objects: { id: number; title: string; relatedObjectId: number }[],
    filterType: FilterTypes,
    setActive = true
  ): void {
    let filters = objects.map((obj) => {
      const filterSetting = FilterUtilities.GenerateFilter(
        filterType,
        obj.id,
        obj.title,
        undefined,
        setActive,
        ObjectTypes.Global
      );
      filterSetting.exclude = false;
      filterSetting.filterSelectorType = FilterSelectorTypes.Dropdown;

      if (obj.relatedObjectId) {
        filterSetting.relatedObjectId = obj.relatedObjectId;
      }

      return filterSetting;
    });

    if (filterType === FilterTypes.Employee) {
      filters = this.cloneEmployee(filters);
    }

    if (filters.length > 0) {
      if (operation === OperationTypes.Create) {
        this.addFilterSettings(filters);
      } else if (operation === OperationTypes.Update) {
        this.updateFilterSettings(filters);
      } else if (operation === OperationTypes.Delete) {
        this.deleteFilterSettings(filters);
      }
    }
  }

  public removeFiltersByType(filterType: FilterTypes): void {
    const allowedFiltersByType = this._allowedFiltersByType.value;
    allowedFiltersByType.delete(filterType);
    this._allowedFiltersByType.next(allowedFiltersByType);
  }

  public addFilterSettings(filters: FilterViewModel[]): void {
    const allowedFiltersByType = this._allowedFiltersByType.value;

    filters.forEach((filter) => {
      const updatedFiltersOfType = allowedFiltersByType.has(filter.filterType)
        ? FilterUtilities.addFilterToArray(allowedFiltersByType.get(filter.filterType), filter)
        : [filter];
      allowedFiltersByType.set(filter.filterType, updatedFiltersOfType);
    });

    this._allowedFiltersByType.next(allowedFiltersByType);
  }

  public updateFilterSettings(filters: FilterViewModel[], replaceAllByType = true): void {
    // TODO: Refactor this method to use the map of filters instead of the flat array
    let res = this.getCachedAllAllowedFilters();

    res.forEach((filterSetting) => {
      const modifiedFilterSetting = filters.find(
        (fs) => fs.filterType === filterSetting.filterType && fs.filterValue === filterSetting.filterValue
      );
      if (modifiedFilterSetting) {
        filterSetting.filterText = modifiedFilterSetting.filterText;
        filterSetting.isActive = modifiedFilterSetting.isActive;
      }
    });

    const usedFilterTypes: FilterTypes[] = filters
      .map((e) => e.filterType)
      .reduce((prev, curr) => {
        if (prev.findIndex((s) => s === curr) < 0) {
          prev.push(curr);
        }
        return prev;
      }, [] as FilterTypes[]);

    usedFilterTypes.forEach((ft) => {
      const filtersByType = filters.filter((s) => s.filterType === ft);

      // TODO: Figure out what this flag is about
      if (!replaceAllByType) {
        if (res.findIndex((e) => e.filterType === ft) > -1) {
          res = res.filter(
            (e) =>
              e.filterType !== ft ||
              filtersByType.find((f) => f.filterValue.toString() === e.filterValue.toString()) === undefined
          );
        }
      }

      res = res.concat(filtersByType);
    });

    this.setFilterDictionaries(res);
  }

  // Clear Allowed Filters for all types
  public clearFilterSettings(): void {
    this._allowedFiltersByType.next(new Map<FilterTypes, FilterViewModel[]>());
  }

  public deleteFilterSettings(filters: FilterViewModel[]): void {
    const allowedFiltersByType = this._allowedFiltersByType.value;

    filters.forEach((filter) => {
      if (allowedFiltersByType.has(filter.filterType)) {
        const updatedFiltersOfType = FilterUtilities.RemoveFilterFromArray(allowedFiltersByType.get(filter.filterType), filter);

        allowedFiltersByType.set(filter.filterType, updatedFiltersOfType);
      }
    });

    this._allowedFiltersByType.next(allowedFiltersByType);
  }

  /**
   * This method is used to populate filter text for filters.
   * The filter text is populated based on the filter value and filter type.
   * Only filters which don't have filter text are populated.
   *
   * !!! NOTE: This method mutates the filters array. !!!
   *
   * @param filters
   * @param filterTypes
   */
  public populateFiltersText(filters: FilterViewModel[], filterTypes: FilterTypes[]) {
    filters.forEach(filter => {
      const matchFlag = filterTypes.includes(filter.filterType) && filter.filterText?.trim().length === 0;
      if(matchFlag) {
        const filtersByType = this._allowedFiltersByType.value.get(filter.filterType);
        if(filtersByType) {
          filter.filterText = filtersByType.find(f => +f.filterValue === +filter.filterValue)?.filterText;
        }
      }
    });
  }

  /**
   * This method is used to populate relatedObjectId for filters.
   * The relatedObjectId is populated based on the filter type.
   * Only filters which don't have assigned related object id are populated.
   *
   * !!! NOTE: This method mutates the filters array. !!!
   *
   * @param filters
   * @param filterTypes
   */
  public populateFiltersRelatedObjectId(filters: FilterViewModel[], filterTypes: FilterTypes[]) {
    filters.forEach(filter => {
      const matchFlag = filterTypes.includes(filter.filterType) && filter.relatedObjectId === 0;
      if(matchFlag) {
        const filtersByType = this._allowedFiltersByType.value.get(filter.filterType);
        if(filtersByType) {
          filter.relatedObjectId = filtersByType.find(f => +f.filterValue === +filter.filterValue)?.relatedObjectId;
        }
      }
    });
  }

  private cloneEmployee(filterSettings: FilterViewModel[]): FilterViewModel[] {
    const createClone: (owner: FilterViewModel, filterType: FilterTypes) => FilterViewModel = (
      owner: FilterViewModel,
      filterType: FilterTypes
    ) => {
      const clone = JSON.parse(JSON.stringify(owner)) as FilterViewModel;
      clone.filterType = filterType;
      return clone;
    };

    const cloneTypes: FilterTypes[] = [
      FilterTypes.Created_By,
      FilterTypes.Subscriber,
      FilterTypes.Updated_By,
      FilterTypes.Venue_Manager,
      FilterTypes.Owner,
      FilterTypes.Identified_By,
      FilterTypes.Reported_By,
      FilterTypes.Approver
    ];

    const clones: FilterViewModel[] = [];

    filterSettings.forEach((o) => {
      cloneTypes.forEach((ct) => clones.push(createClone(o, ct)));
    });

    filterSettings.push(...clones);
    return filterSettings;
  }
}
