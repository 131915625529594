import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';
import { EmployeeCustomGanttSettingViewModel } from '../../../models/gantt/ganttChartLayoutViewConfig';
import { GanttEventsEmitter } from '../../../events/gantt.events';
import { Subscription } from 'rxjs';
import { GanttCachingService } from 'src/app/modules/gantt/services/ganttCaching.service';

@Component({
  selector: 'app-gantt-layout-options',
  templateUrl: './gantt-layout-options.component.html',
  styleUrls: ['./gantt-layout-options.component.scss']
})
export class GanttLayoutOptionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() isCurrentlySelectedLayoutSaved: boolean = true;
  @Input() layouts: EmployeeCustomGanttSettingViewModel[] = [];

  @Output() manageSavedViews: EventEmitter<null> = new EventEmitter<null>();

  private subscriptions: Subscription[] = [];

  public layoutsCopy: EmployeeCustomGanttSettingViewModel[] = [];
  public selectedLayout: EmployeeCustomGanttSettingViewModel;

  public readonly T = T;

  constructor(
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly ganttEventsEmitter: GanttEventsEmitter,
    private readonly ganttCachingService: GanttCachingService,
  ) {}

  ngOnInit(): void {
    this.layoutsCopy = JSON.parse(JSON.stringify(this.layouts)) as EmployeeCustomGanttSettingViewModel[];
    this.setCurrentlySelectedDefaultLayout();
    this.initEventEmitters();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(c => c.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.layouts && !changes.layouts.firstChange) {
      this.layouts = changes.layouts.currentValue as EmployeeCustomGanttSettingViewModel[];
      this.layoutsCopy = JSON.parse(JSON.stringify(this.layouts)) as EmployeeCustomGanttSettingViewModel[];
      this.setCurrentlySelectedDefaultLayout();
    }

    if (changes.isCurrentlySelectedLayoutSaved) {
      this.isCurrentlySelectedLayoutSaved = changes.isCurrentlySelectedLayoutSaved.currentValue as boolean;
    }

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Search layout by given input
   * @param input string
   */
  public onLayoutSearch(input: string): void {
    this.layoutsCopy = input
      ? this.layouts.filter(c => c.title.toLowerCase().indexOf(input.toLowerCase()) !== -1)
      : this.layouts
  }

  /**
   * Check if layout is currently selected
   *
   * @param layout - GanttChartLayoutViewConfig
   */
  public isLayoutSelected(layout: EmployeeCustomGanttSettingViewModel): boolean {
    if (layout && this.isCurrentlySelectedLayoutSaved) {
      return this.selectedLayout.id === layout.id;
    }

    return false;
  }

  /**
   * Set new selected layout
   * @param layout DashboardLayoutViewModel
   */
  public onLayoutSelected(layout: EmployeeCustomGanttSettingViewModel): void {
    if(this.selectedLayout) {
      this.selectedLayout.default = false;
    }

    this.selectedLayout = layout;
    this.selectedLayout.default = true;
    this.ganttEventsEmitter.broadcastGanttLayoutViewChanged(this.selectedLayout);
    this.cancel();
  }

  /**
   * Change the default layout for the current user
   */
  public setCurrentlySelectedDefaultLayout() {
    const employeeSelectedDefaultLayout = this.layouts.find((x) => x.default);
    this.selectedLayout = employeeSelectedDefaultLayout
      ? employeeSelectedDefaultLayout
      : this.layouts.find((x) => x.systemDefault);
  }

  public openManageSavedViewsModal() {
    this.cancel();
    this.manageSavedViews.next(null);
  }

  public cancel() {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  private initEventEmitters() {
    this.subscriptions.push(
      this.ganttEventsEmitter.ganttLayoutViewModified$.subscribe((res: boolean) => {
        this.isCurrentlySelectedLayoutSaved = res;
        this.changeDetectorRef.detectChanges();
      })
    );
  }
}
