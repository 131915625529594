import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SignalREventEmitters {
  private readonly disconnected = new Subject<void>();
  private readonly reconnected = new Subject<void>();

  disconnected$ = this.disconnected.asObservable();
  reconnected$ = this.reconnected.asObservable();

  onDisconnected() {
    this.disconnected.next();
  }

  onReconnected() {
    this.reconnected.next();
  }
}
