import {
  Component,
  AfterViewInit,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentCategoryViewModel } from '../../../../viewModels/documents/documentCategoryViewModel';
import { PrivacyStatuses } from 'src/app/modules/shared/enums/privacyStatuses';
import { Constants } from 'src/app/modules/shared/models/constants';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';
import { EnumUtilities } from 'src/app/modules/shared/utilities/enum.utilities';

@Component({
  selector: 'app-document-category-add-modal',
  templateUrl: './document-category-add-modal.component.html',
  styleUrls: ['./document-category-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [animate('200ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))]),
    ]),
  ],
})
export class DocumentCategoryAddModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly mobileWidth: number = Constants.xs;
  @ViewChild('header', { static: true }) header: ElementRef<HTMLElement>;
  @Output() documentCategoryResult: EventEmitter<DocumentCategoryViewModel> = new EventEmitter<DocumentCategoryViewModel>();
  documentCategory: DocumentCategoryViewModel = new DocumentCategoryViewModel();
  parentCategories: DocumentCategoryViewModel[] = [];
  form: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl(null, [Validators.required]),
    description: new UntypedFormControl(),
  });
  privacyStatuses: { key: PrivacyStatuses; value: string }[] = EnumUtilities.items(PrivacyStatuses);
  privacyStatus: { key: PrivacyStatuses; value: string };
  subscriptions: Subscription[] = [];
  title: string = this.translateService.instant(T.common.add_item, {
    item: this.translateService.instant(T.defaultLocalizations.document_category.one),
  });
  public readonly T = T;

  constructor(public readonly bsModalRef: BsModalRef, private translateService: TranslateService) {}

  ngOnInit() {
    this.documentCategory.title = this.documentCategory.title ? this.documentCategory.title : '';
    this.subscriptions.push(
      this.form.valueChanges.subscribe((val) => {
        this.documentCategory.title = val.title as string;
        this.documentCategory.description = val.description as string;
      })
    );
  }

  ngAfterViewInit() {
    this.form.controls['title'].setValue(this.documentCategory.title);
    if (window.innerWidth <= this.mobileWidth) {
      const modalDialog = this.header.nativeElement.parentElement.parentElement.parentElement;
      const modalContent = this.header.nativeElement.parentElement.parentElement;
      const incidentAddModal = this.header.nativeElement.parentElement;

      modalDialog.style.borderRadius = '0';
      modalDialog.style.display = 'flex';
      modalDialog.style.margin = '0';
      modalDialog.style.padding = '0';
      modalDialog.style.width = '100%';
      modalDialog.style.maxWidth = 'unset';
      modalDialog.style.minHeight = '100%';

      modalContent.style.borderRadius = '0';

      incidentAddModal.style.display = 'block';
      incidentAddModal.style.height = '100%';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  onCategorySelect(parentCategory: DocumentCategoryViewModel) {
    this.documentCategory.parentDocumentCategoryId = parentCategory.id;
  }

  onPrivacyStatusSelect(privacyStatus: { key: PrivacyStatuses; value: string }) {
    this.privacyStatus = privacyStatus;
    this.documentCategory.privacyStatus = privacyStatus.key;
  }

  onConfirm() {
    this.handleConfirm();
  }

  onKeyDown(event: unknown) {
    if (event["keyCode"] === 13) {
      this.handleConfirm();
    }
  }

  handleConfirm() {
    this.documentCategoryResult.next(this.documentCategory);
    return this.bsModalRef.hide();
  }

  onCancel() {
    return this.bsModalRef.hide();
  }

  onClose() {
    return this.bsModalRef.hide();
  }
}
