import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { T } from 'src/assets/i18n/translation-keys';
import { Account } from '../../../../../shared/models/account';
import { AuthenticationService } from '../../../../../shared/services/authentication.service';
import { ProfileImageComponent } from '../../components/profile-image/profile-image.component';

@Component({
  selector: 'app-image-file-chooser-modal',
  templateUrl: './image-file-chooser-modal.component.html',
  styleUrls: ['./image-file-chooser-modal.component.scss'],
})
export class ImageFileChooserModalComponent implements OnInit {
  @ViewChild(ProfileImageComponent, { static: true }) dragAndDropComponent: ProfileImageComponent;
  @Input() headerTitle = '';
  @Input() imageURL = '';
  @Input() maintainAspectRatio = false;
  @Input() aspectRatio = '';
  @Input() isAccountLogo = false;

  onImageUploaded = new EventEmitter<string>();

  public readonly T = T;
  public account: Account;
  public showCancelButton = false;
  public enableSaveButton = false;
  public loading = false;

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.account = this.authenticationService.getCurrentAccount();
  }

  public onSave(): void {
    this.loading = true;
    this.dragAndDropComponent.uploadCroppedImageFile().subscribe((res: string) => {
      this.loading = false;
      this.onImageUploaded.emit(res);
    });
  }

  public onCropImageLoaded() {
    this.showCancelButton = true;
    this.enableSaveButton = true;
  }

  public cancelImageCropping() {
    this.dragAndDropComponent.cancelImageCropping();
    this.showCancelButton = false;
    this.enableSaveButton = false;
  }
}
