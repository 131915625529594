import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SidebarEventsEmitter {
  private sidebarStateChanged = new Subject<boolean>();

  sidebarStateChanged$ = this.sidebarStateChanged.asObservable();

  broadcastSidebarStateChanged(sidebarState: boolean) {
    this.sidebarStateChanged.next(sidebarState);
  }
}
