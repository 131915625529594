import { T } from 'src/assets/i18n/translation-keys';
import { RunsheetItemPriorities } from '../runsheets/enums/runsheetEnums';
import { FilterTypes } from '../shared/enums/filterTypes';
import { MilestoneTypes } from '../shared/enums/milestoneTypes';
import { ObjectTypes } from '../shared/enums/objectTypes';
import { PrivacyStatuses } from '../shared/enums/privacyStatuses';
import { RAGStatuses } from '../shared/enums/ragStatuses';
import { IndicatorPriorities } from '../sustainability/enums/indicatorPriorities';
import { TaskPriorities } from '../shared/enums/taskPriorities';

export const ObjectTypesTranslations = {
  [ObjectTypes.Employee]: T.defaultLocalizations.employee,
  [ObjectTypes.Account]: T.defaultLocalizations.account,
  [ObjectTypes.Department]: T.defaultLocalizations.department,
  //[ObjectTypes.Message] = 6,
  [ObjectTypes.Department_Category]: T.defaultLocalizations.department_category,
  // [ObjectTypes.MediaCategory] = 31,
  [ObjectTypes.Task]: T.defaultLocalizations.task,
  // [ObjectTypes.TaskSystem] = 61,
  // [ObjectTypes.TaskHistory] = 62,
  [ObjectTypes.IncidentItem]: T.defaultLocalizations.incident_item,
  [ObjectTypes.Job]: T.defaultLocalizations.job,
  [ObjectTypes.Job_Category]: T.defaultLocalizations.job_category,
  [ObjectTypes.Job_Type]: T.defaultLocalizations.job_type,

  // [ObjectTypes.ProductCategory] = 200,
  // [ObjectTypes.Product] = 201,
  // [ObjectTypes.Context] = 202,
  // [ObjectTypes.GeographicArea] = 300,
  // [ObjectTypes.Trend_Rule] = 401,
  // [ObjectTypes.Venue] = 500,
  // [ObjectTypes.Venue_Category] = 501,
  // [ObjectTypes.Venue_Type] = 502,
  // [ObjectTypes.Venue_Tag] = 503,
  // [ObjectTypes.Help] = 901,
  // [ObjectTypes.Unit_Of_Measurement] = 1010,
  // [ObjectTypes.Log] = 10103,
  [ObjectTypes.Runsheet]: T.defaultLocalizations.runsheet,
  [ObjectTypes.Runsheet_Item]: T.defaultLocalizations.runsheet_item,
  [ObjectTypes.Tag]: T.defaultLocalizations.tag,
  [ObjectTypes.Zone]: T.defaultLocalizations.zone,
  [ObjectTypes.Area]: T.defaultLocalizations.area,
  [ObjectTypes.Incident_Category]: T.defaultLocalizations.incident_category,
  // [ObjectTypes.Checklist] = 804,
  // [ObjectTypes.Checklist_Item_Template] = 805,
  [ObjectTypes.Checklist_Item]: T.defaultLocalizations.checklist_item,

  [ObjectTypes.Event]: T.defaultLocalizations.event,
  [ObjectTypes.Event_Type]: T.defaultLocalizations.event_type,
  [ObjectTypes.Incident_Channel]: T.defaultLocalizations.incident_channel,

  [ObjectTypes.Report_History]: T.defaultLocalizations.report_history,
  [ObjectTypes.Tag_Group]: T.defaultLocalizations.tag_group,
  // [ObjectTypes.Tag_Category] = 815,

  [ObjectTypes.Project]: T.defaultLocalizations.project,
  [ObjectTypes.Task_Group]: T.defaultLocalizations.task_group,
  [ObjectTypes.Risk]: T.defaultLocalizations.risk,
  [ObjectTypes.Risk_Action_Item]: T.defaultLocalizations.risk_action_item,
  [ObjectTypes.Risk_Assesment]: T.defaultLocalizations.risk_assessment,
  [ObjectTypes.Risk_Impact_Type]: T.defaultLocalizations.risk_impact_type,

  [ObjectTypes.Filter]: T.defaultLocalizations.filter,
  [ObjectTypes.Favorite_Filters]: T.defaultLocalizations.favorite_filter,
  // [ObjectTypes.External_Filter] = 1002,
  // [ObjectTypes.Reporting] = 1003,
  [ObjectTypes.PEAP_Checkpoint_Type]: T.defaultLocalizations.peap_checkpoint_type,
  [ObjectTypes.PEAP]: T.defaultLocalizations.peap,
  [ObjectTypes.PEAP_Check]: T.defaultLocalizations.peap_check,
  [ObjectTypes.PEAP_Checkpoint]: T.defaultLocalizations.peap_checkpoint,
  // [ObjectTypes.PEAP_Group] = 1008,
  // [ObjectTypes.Document_Category] = 1009,

  // This is for system use only in order to use the same SignalR Flow like the one which is implemented now
  [ObjectTypes.Allowed_Filter]: T.defaultLocalizations.allowed_filter,

  [ObjectTypes.Indicator]: T.defaultLocalizations.indicator,
  [ObjectTypes.Indicator_Category]: T.defaultLocalizations.indicator_category,
  [ObjectTypes.Indicator_Target]: T.defaultLocalizations.indicator_target,
  [ObjectTypes.Indicator_Target_Type]: T.defaultLocalizations.indicator_target_type,
  [ObjectTypes.Indicator_Update]: T.defaultLocalizations.indicator_update,
  // [ObjectTypes.UN_Sustainable_Development_Goal] = 3005
};

export const FilterTypesTranslations = {
  // Empty = 0,
  // Account = 10000,

  [FilterTypes.Owner]: T.defaultLocalizations.owner,
  [FilterTypes.Department]: T.defaultLocalizations.department,
  [FilterTypes.RAG]: T.defaultLocalizations.rag,
  // DateRange = 15,
  [FilterTypes.Due_Date]: T.defaultLocalizations.due_date,
  // Venue = 3,
  // Task_System = 6,
  // [FilterTypes.Venue_Category] = 2,
  // [FilterTypes.Venue_Type] = 1,
  // [FilterTypes.Venue_Tag] = 35,
  // Organisation = 10,
  [FilterTypes.Department_Category]: T.defaultLocalizations.department_category,
  [FilterTypes.Milestone_Type]: T.defaultLocalizations.milestone_type,
  // Venue_Manager = 34,
  // Health_and_Safety = 46,
  // Client_Group = 45,
  [FilterTypes.Priority]: T.defaultLocalizations.priority,
  [FilterTypes.Keyword]: T.defaultLocalizations.keyword,
  // [FilterTypes.Venue_Department]: 402,
  [FilterTypes.Item_Type]: T.defaultLocalizations.item_type,
  // Task_Type = 9,
  // Update_Type = 535,
  // Status = 17,
  [FilterTypes.Archived]: T.defaultLocalizations.archived,
  [FilterTypes.Risk_Likelihood]: T.defaultLocalizations.risk_likelyhood,
  [FilterTypes.Risk_Impact]: T.defaultLocalizations.risk_impact,
  [FilterTypes.Risk_Impact_Type]: T.defaultLocalizations.risk_impact_type,
  [FilterTypes.Risk_Privacy_Status]: T.defaultLocalizations.risk_privacy_status,
  [FilterTypes.Risk_Strategy]: T.defaultLocalizations.risk_strategy,
  [FilterTypes.Risk_Status]: T.defaultLocalizations.risk_status,
  [FilterTypes.Risk_Type]: T.defaultLocalizations.risk_type,
  [FilterTypes.Incident_Severity]: T.defaultLocalizations.incident_severity,
  // ImsIncident_Severity = 39,
  [FilterTypes.Incident_Status]: T.defaultLocalizations.incident_status,
  [FilterTypes.Privacy_Status]: T.defaultLocalizations.privacy_status,
  [FilterTypes.Incident_Resolution_Time]: T.defaultLocalizations.incident_resolution_time,
  [FilterTypes.Incident_Channel]: T.defaultLocalizations.incident_channel,
  [FilterTypes.Headline_Status]: T.defaultLocalizations.headline_status,
  [FilterTypes.Incident_Category]: T.defaultLocalizations.incident_category,
  // Incident_Location = 67,
  // Task_Has_Attachments = 62,
  // Task_Has_Dependencies = 63,
  // Hide_Confidential = 65,
  // Incident_Has_Attachments = 66,
  // [FilterTypes.Show_Archived_Incidents] = 68,
  [FilterTypes.Incident_Type]: T.defaultLocalizations.incident_type,
  [FilterTypes.Employee]: T.defaultLocalizations.employee,

  [FilterTypes.Tag]: T.defaultLocalizations.tag,
  [FilterTypes.Tag_Group]: T.defaultLocalizations.tag_group,
  // Tag_Category = 43,
  [FilterTypes.Zone]: T.defaultLocalizations.zone,
  [FilterTypes.Created_By]: T.defaultLocalizations.created_by,
  [FilterTypes.Identified_By]: T.defaultLocalizations.identified_by,

  [FilterTypes.Area]: T.defaultLocalizations.area,
  // Extras
  [FilterTypes.Incident]: T.defaultLocalizations.incident,
  // DateRangeType = 150,
  // IDsCSV = 54,
  // Current_EmployeeID = 42,
  // GlobalObjectID = 50,
  // GlobalObjectType = 51,
  // Output_SortColumn = 19,
  // Output_SortAsc = 20,
  // URL = 100,
  [FilterTypes.Financial_Impact]: T.defaultLocalizations.financial_impact,
  [FilterTypes.Updated_By]: T.defaultLocalizations.updated_by,
  [FilterTypes.Title]: T.defaultLocalizations.title,
  [FilterTypes.Description]: T.defaultLocalizations.description,
  [FilterTypes.Document_Category]: T.defaultLocalizations.document_category,
  [FilterTypes.What_Changed]: T.defaultLocalizations.what_changed,
  // Report_Activity_Types = 108,
  // Show_Archived = 109,
  [FilterTypes.Ref_Code]: T.defaultLocalizations.ref_code,
  [FilterTypes.Reported_By]: T.defaultLocalizations.reported_by,
  // Has_Attachments = 112,
  [FilterTypes.Date]: T.defaultLocalizations.date,
  [FilterTypes.Subscriber]: T.defaultLocalizations.subscriber,
  [FilterTypes.Additional_Info]: T.defaultLocalizations.additional_info,
  [FilterTypes.Unit]: T.defaultLocalizations.unit_of_measurement,

  //Charts
  // Chart_Object_Type = 200,
  // Chart_Object = 201,
  // Time_Scale = 202,

  // Filters
  // [FilterTypes.Filter_Dates_By] = 820,
  // Date_Ranges = 821,

  // Dependent Tasks
  [FilterTypes.Dependent_Task]: T.defaultLocalizations.dependent_task,

  // Log_Types = 800,

  // [FilterTypes.Action_Step_Type] = 710,


  // Module = 1000,
  // Import_Upload_Object = 1001,
  // Import_Type = 1002,

  [FilterTypes.Start_Time]: T.defaultLocalizations.start_time,
  [FilterTypes.End_Time]: T.defaultLocalizations.end_time,

  [FilterTypes.Event]: T.defaultLocalizations.event,
  [FilterTypes.Event_Type]: T.defaultLocalizations.event_type,

  [FilterTypes.Task]: T.defaultLocalizations.task,
  // [FilterTypes.Parent_Task] = 2001,
  [FilterTypes.Task_Breakdown]: T.defaultLocalizations.task_breakdown,

  [FilterTypes.Project]: T.defaultLocalizations.project,
  [FilterTypes.Project_Status]: T.defaultLocalizations.project_status,
  [FilterTypes.Project_Privacy_Status]: T.defaultLocalizations.privacy_status,

  [FilterTypes.Task_Group]: T.defaultLocalizations.task_group,

  [FilterTypes.Risk]: T.defaultLocalizations.risk,
  [FilterTypes.Risk_Breakdown]: T.defaultLocalizations.risk_breakdown,
  [FilterTypes.Issue_Breakdown]: T.defaultLocalizations.issue_breakdown,
  [FilterTypes.Opportunity_Breakdown]: T.defaultLocalizations.opportunity_breakdown,
  [FilterTypes.Risk_Action_Breakdown]: T.defaultLocalizations.risk_action_breakdown,

  [FilterTypes.Risk_Action_Status]: T.defaultLocalizations.risk_action_status,
  [FilterTypes.Risk_Action_Type]: T.defaultLocalizations.risk_action_type,
  [FilterTypes.Risk_Action_Item]: T.defaultLocalizations.risk_action_item,
  [FilterTypes.Checkpoint_Type]: T.defaultLocalizations.checkpoint_type,
  [FilterTypes.Checkpoint]: T.defaultLocalizations.checkpoint,
  // [FilterTypes.Check] = 1062,
  [FilterTypes.PEAP]: T.defaultLocalizations.peap,
  [FilterTypes.PEAP_Group]: T.defaultLocalizations.peap_group,
  [FilterTypes.Resolution]: T.defaultLocalizations.resolution,
  [FilterTypes.PEAP_Group_Status]: T.defaultLocalizations.peap_group_status,
  [FilterTypes.PEAP_Check_Status]: T.defaultLocalizations.peap_check_status,
  [FilterTypes.External_User]: T.defaultLocalizations.external_user,
  [FilterTypes.Risk_RAG]: T.defaultLocalizations.risk_rag,
  // [FilterTypes.Parent_Only] = 1082,
  // Show_Empty = 1083,

  [FilterTypes.Created]: T.defaultLocalizations.created,
  [FilterTypes.Updated]: T.defaultLocalizations.updated,

  [FilterTypes.Indicator]: T.defaultLocalizations.indicator,
  [FilterTypes.Indicator_Category]: T.defaultLocalizations.indicator_category,
  [FilterTypes.Indicator_Target]: T.defaultLocalizations.indicator_target,
  [FilterTypes.Indicator_Target_Type]: T.defaultLocalizations.indicator_target_type,
  [FilterTypes.Indicator_Update]: T.defaultLocalizations.indicator_update,
  [FilterTypes.Indicator_RAG]: T.defaultLocalizations.indicator_rag,
  [FilterTypes.Indicator_Update_Type]: T.defaultLocalizations.indicator_update_type,
  [FilterTypes.Indicator_Tracking_Date_Type]: T.defaultLocalizations.indicator_tracking_date_type,
  [FilterTypes.Indicator_Priority]: T.defaultLocalizations.indicator_priority,
  [FilterTypes.UNSDG]: T.defaultLocalizations.un_sdg,
  // Indicator_Target_Badge_Type = 7010,

  [FilterTypes.Runsheet]: T.defaultLocalizations.runsheet,
  [FilterTypes.Runsheet_Item]: T.defaultLocalizations.runsheet_item,
  [FilterTypes.Runsheet_Item_Priority]: T.defaultLocalizations.runsheet_item_priority,
  [FilterTypes.Runsheet_Incomplete_Items]: T.defaultLocalizations.runsheet_incomplete_items,
  [FilterTypes.Dependent_Runsheet_Item]: T.defaultLocalizations.dependent_runsheet_item,
  [FilterTypes.Runsheet_Item_Dependencies_Count]: T.defaultLocalizations.runsheet_item_dependencies_count,
  [FilterTypes.Runsheet_Item_Dependencies]: T.defaultLocalizations.runsheet_item_dependencies,
  [FilterTypes.User_Group]: T.defaultLocalizations.user_group,
  [FilterTypes.Subscriber_Group]: T.defaultLocalizations.subscriber_group,
  [FilterTypes.Job]: T.defaultLocalizations.job,
  [FilterTypes.Job_Category]: T.defaultLocalizations.job_category,
  [FilterTypes.Job_Status]: T.defaultLocalizations.job_status,
  [FilterTypes.Job_Type]: T.defaultLocalizations.job_type,
};

export const FilterValueTranslations = {
  [FilterTypes.Privacy_Status]: {
    [PrivacyStatuses.Open]: T.defaultFilterValues.privacy_statuses.open,
    [PrivacyStatuses.Confidential]: T.defaultFilterValues.privacy_statuses.confidential,
    [PrivacyStatuses.Medical]: T.defaultFilterValues.privacy_statuses.medical,
    [PrivacyStatuses.Police]: T.defaultFilterValues.privacy_statuses.police,
  },

  [FilterTypes.Runsheet_Item_Priority]: {
    [RunsheetItemPriorities.Level_1]: T.defaultFilterValues.runsheet_item_priority.level_1,
    [RunsheetItemPriorities.Level_2]: T.defaultFilterValues.runsheet_item_priority.level_2,
    [RunsheetItemPriorities.Level_3]: T.defaultFilterValues.runsheet_item_priority.level_3,
  },
  [FilterTypes.Indicator_Priority]: {
    [IndicatorPriorities.One_Star]: T.defaultFilterValues.indicator_priority.one_star,
    [IndicatorPriorities.Two_Stars]: T.defaultFilterValues.indicator_priority.two_stars,
    [IndicatorPriorities.Three_Stars]: T.defaultFilterValues.indicator_priority.three_stars,
  },
  [FilterTypes.Milestone_Type]: {
    [MilestoneTypes.No_Milestone]: T.defaultFilterValues.milestone_type.no_milestone,
    [MilestoneTypes.Bronze]: T.defaultFilterValues.milestone_type.bronze,
    [MilestoneTypes.Silver]: T.defaultFilterValues.milestone_type.silver,
    [MilestoneTypes.Gold]: T.defaultFilterValues.milestone_type.gold,
  },
  [FilterTypes.RAG]: {
    [RAGStatuses.Grey]: T.defaultFilterValues.rag_status.not_started,
    [RAGStatuses.Red]: T.defaultFilterValues.rag_status.slipped,
    [RAGStatuses.Amber]: T.defaultFilterValues.rag_status.slipping,
    [RAGStatuses.Green]: T.defaultFilterValues.rag_status.on_track,
    [RAGStatuses.Blue]: T.defaultFilterValues.rag_status.complete,
  },
  [FilterTypes.Task_Priority]: {
    [TaskPriorities.One_Star]: T.defaultFilterValues.task_priority.one_star,
    [TaskPriorities.Two_Star]: T.defaultFilterValues.task_priority.two_stars,
    [TaskPriorities.Three_Star]: T.defaultFilterValues.task_priority.three_stars,
  },
};
