import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DocumentCategoryViewModel } from 'src/app/modules/shared/viewModels/documents/documentCategoryViewModel';
import { Configuration } from 'src/app/app.constants';
import { AlertService } from '../../../../services/alert.service';
import { CapacitorUtilities } from 'src/app/modules/shared/utilities/capacitor.utilities';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { v4 as uuidv4 } from 'uuid';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-container',
  templateUrl: 'upload-container.component.html',
  styleUrls: ['upload-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadContainerComponent implements OnInit, OnDestroy {
  @ViewChild('file') fileElement: ElementRef<HTMLElement>;
  private subscriptions: Subscription[] = [];

  @Input() globalObjectId: number;
  @Input() canAddLink: boolean = true;
  @Input() canAddFile: boolean = true;

  @Output() onFilesAdded = new EventEmitter<File[]>();
  @Output() onLinkAdded = new EventEmitter<string>();

  url: any;
  loading: boolean = false;
  public documentCategories: DocumentCategoryViewModel[] = [];
  wrapperIsSmall: boolean = false;
  wrapperIsLarge: boolean = false;
  public isNativePlatform: boolean = false;
  public readonly T = T;

  constructor(
    private readonly configuration: Configuration,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isNativePlatform = CapacitorUtilities.isNative;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onSelectFile(files: FileList) {
    this.loading = true;
    const validFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        if (files[i].size > this.configuration.MaxFileSize) {
          void this.alertService.error(this.translateService.instant(T.common.the_file_is_too_large_try_smaller));
        } else {
          const splitted = files[i].name.split('.');
          const extention = splitted[splitted.length - 1].toLowerCase();
          if (!this.configuration.AllowedUploadTypes.includes(extention)) {
            void this.alertService.error(this.translateService.instant(T.common.the_file_is_not_supported_try_different_type));
          } else {
            validFiles.push(files[i]);
          }
        }
      }
    }
    this.loading = false;
    this.onFilesAdded.next(validFiles);
  }

  addLink() {
    this.onLinkAdded.next('');
  }

  onDropHandler(ev: DragEvent) {
    this.onSelectFile(ev.dataTransfer ? ev.dataTransfer.files : null);
  }

  openFileUpload() {
    if (this.fileElement) {
      const newEvent = new MouseEvent('click');
      this.fileElement.nativeElement.dispatchEvent(newEvent);
    }
  }

  async takePhoto() {
    this.loading = true;
    const image = await Camera.getPhoto({
      quality: 100,
      allowEditing: false,
      source: CameraSource.Camera,
      resultType: CameraResultType.Base64,
      saveToGallery: true,
    });

    const blobData = CapacitorUtilities.b64toBlob(image.base64String, `image/${image.format}`);
    const imageName = `${uuidv4()}.png`;
    const imageFile = new File([blobData], imageName, { type: 'image/png' });

    this.loading = false;
    this.onFilesAdded.next([imageFile]);
  }
}
