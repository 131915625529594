import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-hyperlink',
  templateUrl: './hyperlink.component.html',
  styleUrls: ['./hyperlink.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HyperlinkComponent {
  @Input() text: string;
  @Input() disabled = false;
  @Output() onClicked: EventEmitter<boolean> = new EventEmitter();

  get cssClass() {
    return this.disabled ? 'disabled' : 'enabled';
  }

  onClick() {
    this.onClicked.next(true);
  }

}
