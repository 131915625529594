import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import { DashboardInfoWidgetViewModel } from 'src/app/modules/settings/viewModels/dashboardInfoWidgetViewModel';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-dashboard-info-widget-configure',
  templateUrl: 'dashboard-info-widget-configure.component.html',
  styleUrls: ['dashboard-info-widget-configure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetConfigureComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  @Input() editMode = true;
  @Input() dashboardInfoWidget: DashboardInfoWidgetViewModel;
  @Input() emitAutomatically = false;
  @Input() modalActionHandlerClick: Observable<void>;

  @Output() onWidgetInfoUpdated = new EventEmitter<DashboardInfoWidgetViewModel>();
  @Output() editEnabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  isAdmin = false;
  employee: Employee;
  account: Account;
  dashboardInfoWidgetForm: UntypedFormGroup;
  dashboardInfoWidgetEditMode = false;
  public readonly T = T;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }
  ngOnInit(): void {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();
    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    this.initDashboardInfoWidgetForm();
    this.subscribeToWidgetFormChanges();

    if (this.modalActionHandlerClick) {
      this.modalActionHandlerClick.subscribe(() => {
        this.updateDashboardInfoWidget();
      });
    }
  }

  initDashboardInfoWidgetForm() {
    this.dashboardInfoWidgetForm = new UntypedFormGroup({
      widgetTitle: new UntypedFormControl(this.dashboardInfoWidget.title, [Validators.required]),
      widgetField1Label: new UntypedFormControl(this.dashboardInfoWidget.field1Label, [Validators.required]),
      widgetField1Text: new UntypedFormControl(this.dashboardInfoWidget.field1Text, [Validators.required]),
      widgetField2Label: new UntypedFormControl(this.dashboardInfoWidget.field2Label, [Validators.required]),
      widgetField2Text: new UntypedFormControl(this.dashboardInfoWidget.field2Text, [Validators.required]),
      widgetField3Label: new UntypedFormControl(this.dashboardInfoWidget.field3Label, [Validators.required]),
      widgetField3Text: new UntypedFormControl(this.dashboardInfoWidget.field3Text, [Validators.required]),
    });
    this.changeDetectorRef.detectChanges();
  }

  subscribeToWidgetFormChanges() {
    this.subscriptions.push(
      this.dashboardInfoWidgetForm.valueChanges.subscribe((values) => {
        this.dashboardInfoWidget.title = values.widgetTitle;
        this.dashboardInfoWidget.field1Label = values.widgetField1Label;
        this.dashboardInfoWidget.field1Text = values.widgetField1Text;
        this.dashboardInfoWidget.field2Label = values.widgetField2Label;
        this.dashboardInfoWidget.field2Text = values.widgetField2Text;
        this.dashboardInfoWidget.field3Label = values.widgetField3Label;
        this.dashboardInfoWidget.field3Text = values.widgetField3Text;

        if (this.emitAutomatically) {
          this.onWidgetInfoUpdated.next(this.dashboardInfoWidget);
        }
      })
    );
  }

  updateDashboardInfoWidget() {
    this.editEnabledChange.emit();
    this.onWidgetInfoUpdated.next(this.dashboardInfoWidget);
  }
}
