import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export class CollapseAnimation {
  public static slideInOut = trigger('slideInOut', [
    state('true', style({ height: '0px', overflow: 'hidden' })),
    state('false', style({ height: '*', overflow: 'visible' })),
    // show
    transition('true => false', [
      animate(
        '500ms ease-in-out',
        keyframes([style({ height: '0px', overflow: 'hidden' }), style({ height: '*', overflow: 'hidden' })])
      ),
    ]),
    // hide
    transition('false => true', [
      animate('500ms ease-in-out', keyframes([style({ height: '*', overflow: 'hidden' }), style({ height: '0px' })])),
    ]),
  ]);
}
