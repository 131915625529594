import {
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  Component,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { AuthenticationService } from '../../../services/authentication.service';
import { Subscription } from 'rxjs';
import { Employee } from '../../../models/employee';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';

@Component({
  selector: 'app-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrls: ['./filter-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTagComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  employee: Employee;
  public tagFilters: FilterViewModel[] = [];

  @Input() tagGroup: FilterViewModel;
  @Input() mobile: boolean;
  @Input() filters: FilterViewModel[];
  @Input() appliedFilters: FilterViewModel[];
  @Input() filterTypeSelectorViewModel: FilterTypeSelectorViewModel;

  @Output() filtersAdded: EventEmitter<FilterViewModel[]> = new EventEmitter();
  @Output() filtersRemoved: EventEmitter<FilterViewModel[]> = new EventEmitter();
  @Output() FilterChanged: EventEmitter<FilterViewModel[]> = new EventEmitter();

  constructor(private readonly authService: AuthenticationService, private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.employee = this.authService.getCurrentEmployee();
    this.initTagFilters();
  }

  initTagFilters() {
    if (this.tagGroup) {
      this.tagFilters = this.filters.filter(
        (s) => s.filterType === FilterTypes.Tag && s.relatedObjectId.toString() === this.tagGroup.filterValue.toString()
      );
      this.tagFilters.forEach((t) => {
        t.filterDropdownTitle = this.tagGroup.filterText;
        t.displayForGlobalObjectType = this.tagGroup.displayForGlobalObjectType;
      });
    } else {
      this.tagFilters = this.filters.filter((s) => s.filterType === FilterTypes.Tag);
    }

    this.changeDetectorRef.detectChanges();
  }

  onFiltersAdded(filters: FilterViewModel[]): void {
    this.filtersAdded.next(filters);
  }

  onFiltersRemoved(filters: FilterViewModel[]): void {
    this.filtersRemoved.next(filters);
  }

  onFilterChanged(filters: FilterViewModel[]): void {
    this.FilterChanged.next(filters);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
