import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { Constants } from '../../../models/constants';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-rating-dropdown',
  templateUrl: './rating-dropdown.component.html',
  styleUrls: ['./rating-dropdown.component.scss']
})
export class RatingDropdownComponent implements OnInit, OnChanges {
  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;

  @Input() filters: FilterViewModel[] = [];
  @Input() filterType: FilterTypes;
  @Input() canEdit: boolean = true;
  @Input() filterTitles: string[] = [];

  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();

  public availablePriorityFilters: FilterViewModel[] = [];
  public selectedPriorityFilter: FilterViewModel;
  public modifiedFilters: FilterViewModel[] = [];
  public readonly T = T;


  constructor(
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly cdr: ChangeDetectorRef,
    readonly elementRef: ElementRef<HTMLElement>
  ) { }

  ngOnInit(): void {
    this.modifiedFilters = this.filters.slice();
    this.availablePriorityFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterType);
    this.setSelectedPriorityFilter();
  }

  ngOnChanges(): void {
    this.modifiedFilters = this.filters.slice();
    this.setSelectedPriorityFilter();
  }

  private setSelectedPriorityFilter() {
    this.selectedPriorityFilter = this.modifiedFilters.find((x) => x.filterType === this.filterType);
  }

  public onEntrySelected(entry: FilterViewModel) {
    this.modifiedFilters =  this.modifiedFilters.filter(f => f.filterType !== this.filterType);
    this.modifiedFilters.push(entry);
    this.setSelectedPriorityFilter();
    this.filtersUpdated.emit(this.modifiedFilters);
    this.cdr.detectChanges();
  }

  get mobile(): boolean {
    return window.innerWidth <= Constants.xs;
  }

  get currentPriority(): string {
    return this.modifiedFilters.find(f => f.filterType === this.filterType)?.filterValue as string;
  }

}
