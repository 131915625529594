import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum TemperatureOptions {
  Celsius = 1,
  Farenheit = 2,
}

export namespace TemperatureOptions {
  export function translateItems(
    items: { key: TemperatureOptions; value: string }[],
    translateService: TranslateService
  ): { key: TemperatureOptions; value: string }[] {
    if (items) {
      return items.map((item) => {
        switch (item.key) {
          case TemperatureOptions.Celsius:
            item.value = translateService.instant(T.weather.celsius);
            break;
          case TemperatureOptions.Farenheit:
            item.value = translateService.instant(T.weather.farenheit);
            break;
        }

        return item;
      });
    }
  }
}
