<div class="unauthorized-wrapper">
  <div class="unauthorized select-account">
    <img [src]="logoUrl | safeUrl" />
    <h3>Let's reconnect</h3>
    <p>
      Something has gone wrong and we need to reconnect you to WeTrack. Please click below to reload or view your list of accounts.
    </p>
    <button type="button" class="btn btn-primary btn-block" (click)="reload()">Reload WeTrack</button>
    <div class="select-account-section select-account-section-action" (click)="goToAccountList()">Account list</div>
  </div>
</div>
