<app-modal>
  <app-modal-header
    [title]="T.gantt.save_gant_view | translate"
    [showDelete]="false"
    (closed)="bsModalRef.hide()"
  ></app-modal-header>

  <app-modal-body>
    <div class="modal-text-info">{{ T.gantt.enter_layout_title_gantt | translate }}</div>
    <div class="title-text-field-container mb-3" [formGroup]="layoutForm">
      <app-text-field #titleField
        formControlName="title"
        [autofocus]="true"
        [minLength]="3"
        [maxLength]="250"
        [disabled]="!!selectedLayoutForUpdate"
        [required]="true"
      >
      </app-text-field>
    </div>
    <div class="modal-text-info mb-2">{{ T.dashboard.or_select_existing_saved_view | translate }}</div>
    <div class="saved-views-list pb-2 pt-2 border-bottom border-top custom-scrollbar">
      <div class="saved-layout d-flex mb-1" *ngFor="let layout of savedLayouts">
        <ng-container *ngIf="layout.id">
          <div class="layout-radio-button me-2 mb-1">
            <app-radio-button
              [checked]="selectedLayoutForUpdate && selectedLayoutForUpdate.id === layout.id"
              (checkedChanged)="onLayoutSelect(layout)"
            >
            </app-radio-button>
          </div>
          <div class="layout-title">
            {{ layout.title }}
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="isAdmin">
      <div class="set-as-public-layout-container d-flex mb-2 mt-2">
        <div class="set-as-public-checkmark me-2">
          <app-checkbox [isChecked]="saveAsPublic" (checked)="onMakeLayoutAsPublic($event)"> </app-checkbox>
        </div>
        <div class="set-as-public-info">{{ T.dashboard.save_as_public_view | translate }}</div>
      </div>
    </ng-container>
  </app-modal-body>

  <app-modal-footer>
    <app-button-rectangle
      [buttonTheme]="'white'"
      [size]="'large'"
      [text]="T.common.cancel | translate"
      (clicked)="bsModalRef.hide()"
    >
    </app-button-rectangle>
    <app-button-rectangle
      [buttonTheme]="'primary'"
      [size]="'large'"
      [disabled]="!layoutFormIsValid"
      [text]="T.common.save | translate"
      (clicked)="saveNewLayout()"
    >
    </app-button-rectangle>
  </app-modal-footer>
</app-modal>
