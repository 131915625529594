import { Component, OnInit, ChangeDetectorRef, HostListener, ElementRef, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { Router } from '@angular/router';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { Employee } from 'src/app/modules/shared/models/employee';
import { AddModalButtonOptions } from 'src/app/modules/shared/enums/addModalButtonOptions.enum';
import { TaskDetailsViewModel } from '../../../viewModels/projects/taskDetailsViewModel';
import { AddModalKeyboardShortcuts } from 'src/app/modules/shared/enums/addModalKeyboardShortcuts.enum';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { Subscription, Observable } from 'rxjs';
import { TaskService } from '../../../services/task-service';
import { ValidatedViewModel } from 'src/app/modules/shared/viewModels/validatedViewModel';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { IconTypes } from 'src/app/modules/shared/types/iconTypes';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { TaskUtilities } from 'src/app/modules/shared/utilities/task.utilities';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { FilterDateOptions } from 'src/app/modules/shared/enums/filter/filterDateOptions';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { RAGStatuses } from 'src/app/modules/shared/enums/ragStatuses';

@Component({
  selector: 'app-subtask-add-modal',
  templateUrl: './subtask-add-modal.component.html',
  styleUrls: ['./subtask-add-modal.component.scss'],
})
export class SubtaskAddModalComponent implements OnInit, OnDestroy {
  /**
   * Indicator if the start/due dates should be mandatory.
   * By default this property is false but there are cases where the dates should be mandatory.
   * Such a case is when the user is creating a task from the Gantt chart.
   */
  @Input() mandatoryDates = false;

  /**
   * When the user clicks the close button this event is fired.
   *
   * NOTE!!!
   * This event MUST NOT be confused with closing the modal
   * so in order for all the callbacks subscribed to
   * this event to work correctly it shouldn't be called INSIDE any function
   * which closes the modal.
   */
  @Output() cancel = new EventEmitter<void>();

  public readonly subTaskSvgIcon = IconUtilities.getSvgForIconType(IconTypes.Sub_Task);

  public task: TaskDetailsViewModel = new TaskDetailsViewModel();
  public filterTypes = FilterTypes;
  public filters: FilterViewModel[] = [];
  public isMilestoneActivated = false;
  public addModalButtonOptions = AddModalButtonOptions;
  public buttonOptions = AddModalButtonOptions.getOptions(this.translateService);
  public dateReseter = false;
  public keyboardShortcuts = AddModalKeyboardShortcuts.getKeyShortsAsObj(this.translateService);
  public form: UntypedFormGroup;
  public isLoading = false;
  public isHiddenOptionVisible = false;
  public editableFieldTypes = EditableFieldTypes;
  public completionArr: { visibleValue: string; value: number }[] = TaskUtilities.getTaskCompletionArray();
  public triggerErrors = false;
  public readonly objectTypes = ObjectTypes;
  public isTriggeredFromDetails: boolean;
  public isParentTask: boolean = false;
  public validationErrors: string[];
  public localisedSubTask = 'Sub Task';
  public localisedProject: string = 'Project';
  public localisedDepartment: string = 'Department';
  public employee: Employee;
  public readonly T = T;
  private allowedFilters: FilterViewModel[];
  private subscriptions = new Subscription();
  private mobileMaxWidth = Constants.sm;
  private isTouched = false;

  selectedProject: FilterViewModel;
  selectedTaskGroup: FilterViewModel;
  selectedTask: FilterViewModel;

  selectedButtonOption: number = 1;
  referrer: string = '';

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      event.preventDefault();
      if (event.ctrlKey || event.metaKey) {
        if (event.shiftKey) {
          this.handleAction(
            AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
          );
          return;
        }
        this.handleAction(
          AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
        );
        return;
      }
      this.handleAction(AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService));
      return;
    }
  }

  @HostListener('window:resize', ['$event']) onWindowResize(event) {
    if (event.target.innerWidth < this.mobileMaxWidth) {
      this.isHiddenOptionVisible = true;
    }
  }

  constructor(
    private bsModalRef: BsModalRef,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private taskService: TaskService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private elemRef: ElementRef,
    private allowedFiltersService: AllowedFiltersService,
    private bsModalService: BsModalService,
    private confirmationService: ConfirmationService,
    private readonly localisationService: LocalisationService,
    private alertService: AlertService,
    private readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.localisedSubTask = this.localisationService.localiseSubTask();
    this.localisedProject = this.localisationService.localiseObjectType(ObjectTypes.Project);
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);

    this.form = this.fb.group({
      subTaskTitle: [
        '',
        { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])], updateOn: 'blur' },
      ],
      refCode: ['', [Validators.maxLength(12), this.wtValidators.restrictedChars([';'])]],
      description: ['', Validators.maxLength(1000)],
    });

    this.subscriptions.add(
      this.form.valueChanges.subscribe((res) => {
        this.isTouched = true;
      })
    );

    this.initAllowedFilters();

    if (this.selectedProject) {
      this.filters.push(this.selectedProject);
      this.isTriggeredFromDetails = true;
    }
    if (this.selectedTaskGroup) {
      this.filters.push(this.selectedTaskGroup);
    }
    if (this.selectedTask) {
      this.filters.push(this.selectedTask);
      this.isParentTask = true;
    }

    if(this.filters.findIndex(d=>d.filterType === FilterTypes.RAG) ===-1){
      this.filters.push(FilterUtilities.GenerateFilter(FilterTypes.RAG, +RAGStatuses.Grey,RAGStatuses.Grey.toString(), FilterDateOptions.None,true,ObjectTypes.Task));
    }

    if (this.referrer) {
      if (this.referrer === 'gantt') {
        this.buttonOptions = this.buttonOptions.filter(
          (o) => o != AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
        );
        this.selectedButtonOption = 1;
      }
    }

    this.filters = this.filters.slice();
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  validateRequiredFilters(filtersForSearch: FilterTypes[]): boolean {
    let isValid = true;
    filtersForSearch.forEach((currentFilterType) => {
      if (!this.filters.find((f) => f.filterType === currentFilterType)) {
        isValid = false;
      }
    });

    return isValid;
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  setAccountForCurrentOwner() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (s: any) => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.employee.id.toString()
      );
      if (empFilter !== null && this.filters.indexOf(empFilter) < 0) {
        this.filters = this.filters.slice();
        this.filters.push(empFilter);
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  setMyDepartment() {
    if (this.allowedFilters) {
      const empFilter = this.allowedFilters.find(
        (f) => f.filterType === FilterTypes.Department && f.filterValue.toString() === this.employee.departmentId.toString()
      );
      if (!this.filters.some((x) => x.id == empFilter.id)) {
        if (empFilter && this.filters.indexOf(empFilter) < 0) {
          this.filters = this.filters.slice();
          this.filters.push(empFilter);
        }
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  updateDescription($event) {
    this.form.controls.description.setValue($event);
  }

  showHiddenOptions() {
    this.isHiddenOptionVisible = !this.isHiddenOptionVisible;
    this.changeDetectorRef.detectChanges();
  }

  handleAction(action: string) {
    const requiredFilters = [FilterTypes.Project, FilterTypes.Task, FilterTypes.RAG];
    if(this.mandatoryDates) {
      requiredFilters.push(FilterTypes.Date);
    }

    const isFiltersValid = this.validateRequiredFilters(requiredFilters);
    this.validationErrors = [];
    if (!this.form.valid || !isFiltersValid) {
      this.triggerErrors = true;
      return;
    }
    if (action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Close, this.translateService)) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res: any) => {
          const errors = res.errorList;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.isLoading = false;
            const currentTask = res.returnModel;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedSubTask }));
            this.bsModalEventsEmitter.broadcastBsModalActionHandleClicked(currentTask);
            this.closeModal();
          }
        })
      );
    } else if (
      action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_View_Details, this.translateService)
    ) {
      this.isLoading = true;
      this.subscriptions.add(
        this.handleSubmit().subscribe((res: ValidatedViewModel) => {
          const errors = res.errorList;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.isLoading = false;
            const currentTask = res.returnModel;
            void this.router.navigate([`v2/planning/list/tasks/${currentTask.id}`]);
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedSubTask }));
            this.closeModal();
          }
        })
      );
    } else if (
      action === AddModalButtonOptions.getTranslatedOptionText(AddModalButtonOptions.Save_Add_Another, this.translateService)
    ) {
      this.isLoading = true;

      this.subscriptions.add(
        this.handleSubmit().subscribe((res) => {
          const errors = res.errorList;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.isLoading = false;
            this.changeDetectorRef.markForCheck();
          } else {
            this.isLoading = false;
            void this.alertService.success(this.translateService.instant(T.common.item_added, { item: this.localisedSubTask }));
            this.form.controls.subTaskTitle.reset();
          }
        })
      );
    }
  }

  handleSubmit(): Observable<ValidatedViewModel> {
    if (this.isMilestoneActivated) {
      this.task.start = '';
      this.task.due = '';
    }
    this.task.filters = this.filters;
    this.task.description = this.form.controls.description.value.trim();
    this.task.refCode = this.form.controls.refCode.value.trim();
    this.task.title = this.form.controls.subTaskTitle.value.trim();

    // Populate primitive properties from Filter list
    this.task.taskGroupId = this.getFilterValue(FilterTypes.Task_Group);
    this.task.priority = this.getFilterValue(FilterTypes.Priority);
    this.task.milestoneType = this.getFilterValue(FilterTypes.Milestone_Type);
    this.task.rag = this.getFilterValue(FilterTypes.RAG);
    this.task.parentId = this.getFilterValue(FilterTypes.Task);
    return this.taskService.add(this.task);
  }

  // Get dates
  get getStartDate() {
    if (this.isMilestoneActivated) {
      if (this.task.due) {
        return this.task.due;
      }
    } else if (this.task.start) {
      return this.task.start;
    }
    return '';
  }

  get getDueDate() {
    if (this.task.due) {
      return this.task.due;
    }
    return '';
  }
  // Set dates
  setStartDate(dateAsIsoString: string) {
    this.task.start = dateAsIsoString;
    this.populateDateInFilter(FilterDateOptions.Start_Date, dateAsIsoString);
  }

  setDueDate(dateAsIsoString: string) {
    this.task.due = dateAsIsoString;
    this.populateDateInFilter(FilterDateOptions.Due_Date, dateAsIsoString);
    if (!this.task.start) {
      this.task.start = this.task.due;
      this.populateDateInFilter(FilterDateOptions.Start_Date, dateAsIsoString);
    }
  }

  populateDateInFilter(filterOption: FilterDateOptions, value: any) {
    const due = this.filters.find((f) => f.filterType === FilterTypes.Date && f.dateProperty === filterOption);
    if (!due) {
      this.filters.push(FilterUtilities.GenerateFilter(FilterTypes.Date, value, value, filterOption));
    } else {
      due.filterValue = value;
    }
  }

  // resetModal() {
  //   this.form.reset();
  //   this.filters = [];
  //   this.resetDateComponent();
  // }

  resetDateComponent() {
    this.dateReseter = true;
    this.changeDetectorRef.detectChanges();
    this.dateReseter = false;
    this.changeDetectorRef.detectChanges();
  }

  initAllowedFilters() {
    // Load them on ngOnInit (in the beggining) so when the client click on "Assign Me" the assign of the filter will be instant;
    this.allowedFilters = this.allowedFiltersService.getCachedAllAllowedFilters();
  }

  confirmClose() {
    if (this.isTouched) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.are_sure_you_want_to_leave_page),
        () => {
          this.bsModalEventsEmitter.broadcastBsModalCloseClicked({});
          this.closeModal();
          this.cancel.next();
        },
        () => {
          //handle cancel
        },
        this.translateService.instant(T.common.confirm),
        false,
        this.translateService.instant(T.common.stay),
        this.translateService.instant(T.common.leave),
        'danger'
      );
    } else {
      this.closeModal();
      this.cancel.next();
    }
  }

  handleComletion(event) {
    this.task.percentageComplete = event.value;
  }

  // ** FILTER METHODS **

  handleProjectChange(filters: FilterViewModel[]) {
    // When Project selected, clear Task Groups
    this.selectedProject = filters.find((f) => f.filterType == FilterTypes.Project);
    this.selectedTaskGroup = null;
    this.filters = filters.filter((f) => f.filterType !== FilterTypes.Task_Group);
    this.changeDetectorRef.detectChanges();

    this.isTouched = true;
  }

  handleTaskGroupChange(filters: FilterViewModel[]) {
    // When Task Group selected, clear Tasks
    this.selectedTaskGroup = filters.find((f) => f.filterType == FilterTypes.Task_Group);
    this.filters = filters.filter((f) => f.filterType !== FilterTypes.Task);
    this.changeDetectorRef.detectChanges();

    this.isTouched = true;
  }

  handleFilterChange(filters: FilterViewModel[]) {
    this.selectedProject = filters.find((f) => f.filterType === FilterTypes.Project);
    this.selectedTaskGroup = filters.find((f) => f.filterType === FilterTypes.Task_Group);
    this.isMilestoneActivated = !!filters.find((f) => f.filterType === FilterTypes.Milestone_Type);

    this.filters = filters;
    this.changeDetectorRef.detectChanges();

    if (filters.length > 1) {
      this.isTouched = true;
    }
  }

  getFilterValue(filterType: FilterTypes): number {
    const filter: FilterViewModel = this.task.filters.find((f) => f.filterType === filterType);
    return filter ? filter.filterValue : 0;
  }
}
