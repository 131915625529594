import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[routeTransform]',
})
export class RouteTransformDirective {
  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    event.preventDefault();

    if (event.target instanceof HTMLAnchorElement) {
      const target = event.target;
      const href: string = target.getAttribute('href');

      if (href) {
        if (href.startsWith('mailto')) {
          return;
        }

        if (href.startsWith('http')) {
          window.open(href, '_blank');

          return;
        }

        void this.router.navigate([href]);
      }
    }
  }
}
