import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum FilterDateRangeOptions {
  Previous_24_Hours = 20,
  Up_to_today = 21,
  Last_3_months = 22,
  Last_30_days = 23,
  Last_7_days = 24,
  Yesterday = 25,
  Today = 26,
  Next_7_days = 27,
  Next_30_days = 28,
  Next_3_months = 29,
  Fixed_Date_Range = 31,
  Tomorrow = 32,
  Next_2_Weeks = 33,
  Past = 34,
  Onward = 35,
  Next_6_Months = 36,
  Next_Year = 37,
  Fixed_Time_Range = 50,
}

export namespace FilterDateRangeOptions {
  export function translatedItems(translateService: TranslateService): { key: FilterDateRangeOptions; value: string }[] {
    const a: { key: FilterDateRangeOptions; value: string }[] = [];
    for (const enumMember in FilterDateRangeOptions) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translateItem(+enumMember, translateService) });
      }
    }
    return a;
  }
}

function translateItem(key: FilterDateRangeOptions, translateService: TranslateService) {
  switch (key) {
    case FilterDateRangeOptions.Up_to_today:
      return translateService.instant(T.calendar.up_to_today);
    case FilterDateRangeOptions.Last_3_months:
      return translateService.instant(T.calendar.last_3_months);
    case FilterDateRangeOptions.Last_30_days:
      return translateService.instant(T.calendar.last_30_days);
    case FilterDateRangeOptions.Last_7_days:
      return translateService.instant(T.calendar.last_7_days);
    case FilterDateRangeOptions.Yesterday:
      return translateService.instant(T.calendar.yesterday);
    case FilterDateRangeOptions.Today:
      return translateService.instant(T.calendar.today);
    case FilterDateRangeOptions.Next_7_days:
      return translateService.instant(T.calendar.next_7_days);
    case FilterDateRangeOptions.Next_30_days:
      return translateService.instant(T.calendar.next_30_days);
    case FilterDateRangeOptions.Next_3_months:
      return translateService.instant(T.calendar.next_3_months);
    case FilterDateRangeOptions.Fixed_Date_Range:
      return translateService.instant(T.calendar.fixed_date_range);
    case FilterDateRangeOptions.Tomorrow:
      return translateService.instant(T.calendar.tomorrow);
    case FilterDateRangeOptions.Next_2_Weeks:
      return translateService.instant(T.calendar.next_2_weeks);
    case FilterDateRangeOptions.Past:
      return translateService.instant(T.common.past.many);
    case FilterDateRangeOptions.Onward:
      return translateService.instant(T.calendar.onward.many);
    case FilterDateRangeOptions.Next_6_Months:
      return translateService.instant(T.calendar.next_6_months);
    case FilterDateRangeOptions.Next_Year:
      return translateService.instant(T.calendar.next_year);
    case FilterDateRangeOptions.Fixed_Time_Range:
      return translateService.instant(T.calendar.fixed_time_range);
    case FilterDateRangeOptions.Previous_24_Hours:
      return translateService.instant(T.calendar.previous_24_hours);
    default:
      return 'n/a';
  }
}
