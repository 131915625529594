<app-modal>
  <app-modal-header style="z-index: 50;" [title]="T.control.add_items_from_a_template | translate" [showDelete]="false"
    (closed)="bsModalRef.hide()">
  </app-modal-header>

  <app-modal-body class="custom-scrollbar">
    <ng-container *ngIf="!loading">
      <div class="top-wrapper" *ngIf="showFilters">
        <div class="filters-wrapper">
          <!-- <app-filters-main #filtersMain [filterTypesArray]="filterTypes" [reportRefs]="reportRefs"
            [includeFiltersApplicator]="true" [filterSettings]="filterSettings"
            (filterSettingsUpdated)="onFilterSettingsUpdated($event)">
          </app-filters-main>
   -->
          <div class="responsive-flex-wrapper">
          </div>
        </div>
      </div>
      <div class="row scroll-wrapper">
        <div class="col-6" [class.col-12]="useSimpleAddModal">
          <app-checklist-template-list [useSimpleAddModal]="useSimpleAddModal" [isSingleSelect]="false" [previouslySelectedChecklistTemplates]="previouslySelectedChecklistTemplates" [originalChecklistTemplates]="checklistTemplates" wrapperHeight="50"
            (updateSelectedTemplates)="updateSelected($event)" (onSelectChecklist)="onSelectChecklist($event)" [customMessage]="customMessage">
          </app-checklist-template-list>
        </div>
        <ng-container *ngIf="!useSimpleAddModal">
          <div class="col-6 checklis-check-container custom-scrollbar">
            <div class="click-checklist-text mb-2">Click on a checklist to see the checks it contains</div>
            <ng-container *ngIf="openedChecklist">
              <div class="opened-checklist-title">{{ openedChecklist.title }}</div>
              <div class="checklist-checks-count mb-2">{{ selectedTemplateItems.length }} Checks</div>
              <div class="checklist-checks-wrapper custom-scrollbar">
                <div *ngFor="let check of selectedTemplateItems let i = index"  class="check mb-2">
                  <div class="check-title">
                    {{ i + 1 }}.&nbsp;&nbsp;&nbsp;{{ check.title }}
                  </div>
                  <div class="check-type">{{ getCheckResponseType(check) }}</div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="loading">
      <app-loading>
        <p>{{ T.common.loading | translate }}...</p>
      </app-loading>
    </ng-container>
  </app-modal-body>
  <app-modal-footer>
    <app-button-rectangle [buttonTheme]="'white'" [size]="'large'" [text]="T.common.cancel | translate" (clicked)="onCancel()">
    </app-button-rectangle>
    <app-button-rectangle [text]="T.common.save | translate" [size]="'large'" (clicked)="onSave()">
    </app-button-rectangle>
  </app-modal-footer>
</app-modal>
