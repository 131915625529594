<div class="checks-container" [class.expanded]="expanded">
  <div class="card-inner big">
    <div class="card-top" (click)="onExpandClicked()">
      <div class="card-top-left">
        <div class="playlist-add-check-icon" [class.allChecksCompleted]="completedChecks === checklistItems.length">
          <i class="material-symbols-outlined check-icon">playlist_add_check</i>
        </div>
        <div class="title">
          {{ groupTitle }}
        </div>
      </div>
      <div class="card-top-right">
        <div class="checks-complete" [class.allChecksCompleted]="completedChecks === checklistItems.length">
          <ng-container *ngIf="!isMobile">
            {{
              T.common.count_slash_total_completed | translate: { count: completedChecks, total: checklistItems.length }
            }}
          </ng-container>
          <ng-container *ngIf="isMobile"> {{ completedChecks }}/{{ checklistItems.length }} </ng-container>
        </div>
        <div class="arrow-down-icon" [style.transform]="expanded ? 'rotate(180deg)' : ''">
          <i class="material-symbols-outlined arrow-icon">expand_more</i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="expanded" class="card-inner-expanded">
    <app-paginated-cards
      [paginationId]="paginationId"
      [items]="checklistItems"
      [trackByProperty]="'updated'"
      [cardTemplate]="cardTemplate"
      [draggable]="false"
      *ngIf="checklistItems.length"
    >
    </app-paginated-cards>
  </div>
</div>

<ng-template #cardTemplate let-item="item">
  <app-checklist-item-card
    [loading]="loading"
    style="width: 100%"
    [item]="item"
    [draggable]="draggable"
    [userHasWriteAccess]="canEdit"
    (viewItem)="onView($event)"
    (itemCompleted)="updateItem($event)"
  >
  </app-checklist-item-card>
</ng-template>
