import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterTypes } from '../../../enums/filterTypes';
import { ObjectEventEmitters } from '../../../events/object.events';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { SortingService } from '../../../services/sorting.service';

@Component({
  selector: 'app-filters-singleselect',
  templateUrl: './filters-singleselect.component.html',
  styleUrls: ['./filters-singleselect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.filters-multiselect-mobile]': 'mobile',
    '[style.height]': 'lozengeInlineEdit ? "unset" : ""',
  },
})
export class FiltersSingleselectComponent implements OnInit {
  @Input() mobile: boolean;
  @Input() lozengeInlineEdit: boolean;
  @Input() filterType: FilterTypes;
  @Input() filters: FilterViewModel[];
  @Input() appliedFilters: FilterViewModel[];
  @Input() showOnlyActive: boolean = false;
  @Input() required: boolean = false;
  @Input() isFilterRequired: boolean = false;

  @Output() filtersAdded: EventEmitter<FilterViewModel[]> = new EventEmitter();
  @Output() filtersRemoved: EventEmitter<FilterViewModel[]> = new EventEmitter();
  @Output() onFiltersUpdated: EventEmitter<FilterViewModel[]> = new EventEmitter();

  selected: FilterViewModel[] = [];
  newlySelected: FilterViewModel[] = [];
  filteredFilters: FilterViewModel[] = [];
  searchValue: string;
  public readonly T = T;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly objectEventEmitters: ObjectEventEmitters,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly sortingService: SortingService
  ) {}

  ngOnInit() {
    this.filters = this.sortingService.sortFilters(this.filters, this.filterType);
    //this.allowedFiltersService.sortFilters(this.filterType, this.filters);
  }

  ngOnChanges() {
    if (!this.filters) {
      this.filters = [];
    }

    this.filters = this.filters.filter((f) => f.filterType === this.filterType);

    if (this.showOnlyActive) {
      this.filters = this.filters.filter((f) => f.isActive);
    }

    this.filteredFilters = this.filters.slice();

    if (!this.appliedFilters) {
      this.appliedFilters = [];
    }

    this.appliedFilters = this.appliedFilters.filter((f) => f.filterType === this.filterType);
    this.selected = this.appliedFilters.slice();
    this.newlySelected = [];
    this.changeDetectorRef.markForCheck();
  }

  get selectedEntries(): FilterViewModel[] {
    return [...this.selected, ...this.newlySelected];
  }

  onEntrySelected(entry: FilterViewModel): void {
    entry.filterAction = FilterActionTypes.Update;
    if (!(this.isFilterRequired && entry.filterValue == this.appliedFilters[0].filterValue)) {
      if (
        !this.appliedFilters ||
        !this.appliedFilters.length ||
        this.appliedFilters.find((f) => f.filterValue == entry.filterValue) === undefined
      ) {
        this.appliedFilters = [entry];
        this.selected = [entry];
        this.onFiltersUpdated.next(this.appliedFilters);
      } else {
        this.appliedFilters = [];
        this.selected = [];
        this.onFiltersUpdated.next(this.appliedFilters);
      }
    }

    this.onCancel();

    this.changeDetectorRef.markForCheck();
  }

  onSearch(value: string): void {
    this.searchValue = value.toLowerCase().trim();

    if (value) {
      this.filteredFilters = this.filters.filter((f) => f.filterText.toLowerCase().indexOf(value) > -1);
    } else {
      this.filteredFilters = this.filters.slice();
    }

    this.changeDetectorRef.markForCheck();
  }

  onAppliedFilterDeleted(filter: FilterViewModel): void {
    this.selected = this.selected.filter((f) => f.id !== filter.id);
  }

  onNewlyAddedFilterDeleted(filter: FilterViewModel): void {
    this.newlySelected = this.newlySelected.filter((f) => f.id !== filter.id);
  }

  onExclude(excluded: boolean): void {
    this.selectedEntries.forEach((f) => (f.exclude = excluded));
  }

  @HostListener('document:keydown.escape')
  onEscape(): void {
    this.ngOnChanges();
  }

  onCancel(): void {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }

  onClearAll(): void {
    this.selected = [];
    this.newlySelected = [];
    this.changeDetectorRef.markForCheck();
    this.onApply();
  }

  onApply(): void {
    this.filtersAdded.emit(this.newlySelected);
    this.filtersRemoved.emit(this.appliedFilters.filter((af) => !this.selected.find((f) => f.id === af.id)));

    let applied = this.appliedFilters.filter((af) => this.selected.find((f) => f.id === af.id));
    applied = [...applied, ...this.newlySelected];

    this.onFiltersUpdated.next(applied);
    // close upon apply
    this.onCancel();
  }

  get isFilterChanged(): boolean {
    if (this.isFilterRequired && !this.newlySelected.length) {
      return true;
    }

    return this.selected.length === this.appliedFilters.length ? !this.newlySelected.length : false;
  }
}
