import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReportingComponent } from '../../../reporting/components/reporting/reporting.component';
import { ConfirmationService } from '../../services/confirmation.service';
import { Observable, Observer, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { T } from '../../../../../assets/i18n/translation-keys';

@Injectable({ providedIn: 'root' })
export class LeaveGuardService {
  canNavigateSubject = new Subject<boolean>();

  constructor(private readonly confirmationService: ConfirmationService, private translateService: TranslateService) {}

  canDeactivate(
    component: ReportingComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    let canNavigate: boolean = false;
    if (state.url != '/v2/reports') {
      canNavigate = false;
    } else {
      canNavigate = true;
    }

    if (!canNavigate) {
      this.confirmationService.confirmThis(
        this.translateService.instant(T.common.you_changes_may_be_lost_confirm_quit),
        () => {
          this.canNavigateSubject.next(true);
        },
        () => {
          this.canNavigateSubject.next(false);
        },
        undefined,
        false
      );

      return new Observable<boolean>((observer: Observer<boolean>) => {
        this.canNavigateSubject.subscribe((res) => {
          observer.next(res);
        });
      });
    } else {
      return true;
    }
  }
}
