import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ChecklistResponseTypes } from 'src/app/modules/shared/enums/checklists/checklistEnums';
import { T } from 'src/assets/i18n/translation-keys';
import { ChecklistItemViewModel } from '../../../../models/checklists/checklistItemViewModel';

@Component({
  selector: 'app-checklists-details-section',
  templateUrl: './checklists-details-section.component.html',
  styleUrls: ['./checklists-details-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistsDetailsSectionComponent {
  @Input() checklistItems: ChecklistItemViewModel[] = [];
  @Input() useLargeBubble: boolean;
  @Input() label: string = 'Checklist';

  @Output() clicked: EventEmitter<void> = new EventEmitter();

  icon = 'list';
  public readonly T = T;

  onClick() {
    this.clicked.next();
  }

  isCompleted(item: ChecklistItemViewModel): boolean {
    let result = false;

    if (item.responseType == ChecklistResponseTypes.Mark_as_Complete) {
      result = item.response == 'true';
    } else if (item.responseType == ChecklistResponseTypes.Text_Response) {
      result = item.response && item.response.length > 0;
    } else if (item.responseType == ChecklistResponseTypes.Numeric_Response) {
      result = item.response && Number(item.response) > 0;
    } else {
      result = item.response !== undefined && parseInt(item.response) > 0;
    }
    return result;
  }

  get completedCount(): number {
    return this.checklistItems.filter((i) => this.isCompleted(i)).length;
  }

  get totalCount(): number {
    return this.checklistItems.length;
  }
}
