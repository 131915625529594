import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { Employee } from '../../../models/employee';
import { Subscription } from 'rxjs';
import { ObjectTypes } from '../../../enums/objectTypes';
import { RAGStatuses } from '../../../enums/ragStatuses';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LinkedItemsAddModalComponent } from '../../modals/linked-items-add-modal/linked-items-add-modal.component';
import { ObjectSimpleViewModel } from '../../../models/linkedItemObjects/objectSimpleViewModel';
import { LinkedItemsService } from '../../../services/linked-items.service';
import { ObjectLinkTypes } from '../../../enums/objectLinkTypes.enum';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { ObjectSubTypes } from '../../../enums/objectSubTypes.enum';
import { LocalisationService } from '../../../services/localisation.service';
import { EmployeeUtil } from '../../../utilities/employee.utilities';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { FilterTypes } from '../../../enums/filterTypes';
import { Account } from 'src/app/modules/shared/models/account';
import { AlertService } from '../../../services/alert.service';
import { ConfirmationService } from '../../../services/confirmation.service';
import { Constants } from 'src/app/modules/shared/models/constants';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

interface LinkedItemsToggleOptions {
  value: string;
  objectType: ObjectTypes;
  icon: string;
}
@Component({
  selector: 'app-linked-items-details-list',
  templateUrl: './linked-items-details-list.component.html',
  styleUrls: ['./linked-items-details-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedItemsDetailsListComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() rag: RAGStatuses;
  @Input() id: number;
  @Input() objectType: ObjectTypes;
  @Input() objectSubType: ObjectSubTypes;
  @Input() canAddLinkedItems: boolean = false;

  @Output() count: EventEmitter<number> = new EventEmitter();

  public loading = true;
  public linkedTasks: ObjectSimpleViewModel[] = [];
  public linkedProjects: ObjectSimpleViewModel[] = [];
  public linkedRisks: ObjectSimpleViewModel[] = [];
  public linkedIndicators: ObjectSimpleViewModel[] = [];
  public linkedIncidents: ObjectSimpleViewModel[] = [];
  public linkedJobs: ObjectSimpleViewModel[] = [];
  public currentlySelectedItems: ObjectSimpleViewModel[] = [];
  public currentlySelectedVisibleFilters: FilterTypeSelectorViewModel[] = [];
  public selectedToggleTab: LinkedItemsToggleOptions;
  public toggleButtonOptions: LinkedItemsToggleOptions[] = [];
  public useRiskAndIssues: boolean;
  private employee: Employee;
  public account: Account;
  private subscriptions = new Subscription();
  private linkedObjectTypes: ObjectTypes[] = [
    ObjectTypes.Task,
    ObjectTypes.Project,
    ObjectTypes.Risk,
    ObjectTypes.Job,
    ObjectTypes.IncidentItem,
  ];

  private localisedTasks = this.localisationService.localiseObjectType(ObjectTypes.Task, true);
  private localisedSubTasks = this.localisationService.localiseSubTask(true);
  private localisedProjects = this.localisationService.localiseObjectType(ObjectTypes.Project, true);
  private localisedRisksIssuesOpportunities = `${this.localisationService.localiseObjectType(
    ObjectTypes.Risk,
    true
  )}, ${this.translateService.instant(T.defaultLocalizations.issue.many)} & ${this.translateService.instant(
    T.defaultLocalizations.opportunity.many
  )}`;
  private localisedIndicators = this.localisationService.localiseObjectType(ObjectTypes.Indicator, true);
  private localisedIncidents = this.localisationService.localiseObjectType(ObjectTypes.IncidentItem, true);
  private localisedJobs = this.localisationService.localiseObjectType(ObjectTypes.Job, true);
  public readonly T = T;

  // private allowedFilters: FilterViewModel[];

  bsModalRef: BsModalRef;
  private readonly mobileWidth = Constants.xs;
  constructor(
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private linkedService: LinkedItemsService,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly alertService: AlertService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.account = this.authenticationService.getCurrentAccount();

    if (this.objectType !== ObjectTypes.Job && this.objectType !== ObjectTypes.IncidentItem) {
      if (this.account.usePlanning) {
        this.toggleButtonOptions.push({
          value: `${this.localisedTasks} & ${this.localisedSubTasks}`,
          objectType: ObjectTypes.Task,
          icon: 'download_done',
        });
        this.toggleButtonOptions.push({ value: this.localisedProjects, objectType: ObjectTypes.Project, icon: 'folder_open' });
      }
      if (this.useRiskAndIssues || this.account.showRiskAndIssues) {
        this.toggleButtonOptions.push({
          value: this.localisedRisksIssuesOpportunities,
          objectType: ObjectTypes.Risk,
          icon: 'warning',
        });
      }
      if (this.account.useSustainability) {
        this.linkedObjectTypes.push(ObjectTypes.Indicator);
        this.toggleButtonOptions.push({
          value: this.localisedIndicators,
          objectType: ObjectTypes.Indicator,
          icon: 'format_list_bulleted',
        });
      }
    } else if (this.objectType === ObjectTypes.Job) {
      if (this.account.useIMS || this.account.useIMSOnly) {
        this.linkedObjectTypes.push(ObjectTypes.IncidentItem);
        this.toggleButtonOptions.push({
          value: this.localisedIncidents,
          objectType: ObjectTypes.IncidentItem,
          icon: 'assignment_turned_in',
        });
      }
    } else if (this.objectType === ObjectTypes.IncidentItem) {
      if (this.account.useIMS || this.account.useIMSOnly) {
        this.linkedObjectTypes.push(ObjectTypes.IncidentItem);
        this.linkedObjectTypes.push(ObjectTypes.Job);
        this.toggleButtonOptions.push({
          value: this.localisedIncidents,
          objectType: ObjectTypes.IncidentItem,
          icon: 'format_list_bulleted',
        });
        this.toggleButtonOptions.push({ value: this.localisedJobs, objectType: ObjectTypes.Job, icon: 'assignment_turned_in' });
      }
    }

    this.selectedToggleTab = this.toggleButtonOptions[0];
    this.useRiskAndIssues = EmployeeUtil.hasAnyReadPermission(this.employee, ModuleTypes.Planning, FilterTypes.Risk);
    this.loadData();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  loadData(isInitialLoad: boolean = false) {
    this.subscriptions.add(
      this.linkedService
        .getLinkedItems(this.id, this.objectType, this.linkedObjectTypes, ObjectLinkTypes.Relation)
        .subscribe((itemsByObejct) => {
          this.loading = true;
          this.linkedTasks = this.getItemsByObjectType(itemsByObejct, ObjectTypes.Task);
          this.linkedProjects = this.getItemsByObjectType(itemsByObejct, ObjectTypes.Project);
          this.linkedRisks = this.getItemsByObjectType(itemsByObejct, ObjectTypes.Risk);
          this.linkedIndicators = this.getItemsByObjectType(itemsByObejct, ObjectTypes.Indicator);
          this.linkedJobs = this.getItemsByObjectType(itemsByObejct, ObjectTypes.Job);
          this.linkedIncidents = this.getItemsByObjectType(itemsByObejct, ObjectTypes.IncidentItem);
          if (this.objectType !== ObjectTypes.Job && this.objectType !== ObjectTypes.IncidentItem) {
            let count: number = 0;
            if (this.account.usePlanning) {
              this.toggleButtonOptions.find(
                (x) => x.objectType === ObjectTypes.Task
              ).value = `${this.localisedTasks} & ${this.localisedSubTasks} (${this.linkedTasks.length})`;

              this.toggleButtonOptions.find(
                (x) => x.objectType === ObjectTypes.Project
              ).value = `${this.localisedProjects} (${this.linkedProjects.length})`;
              count += this.linkedTasks.length + this.linkedProjects.length;
            }

            if (this.useRiskAndIssues && this.account.showRiskAndIssues) {
              this.toggleButtonOptions.find(
                (x) => x.objectType === ObjectTypes.Risk
              ).value = `${this.localisedRisksIssuesOpportunities} (${this.linkedRisks.length})`;

              count += this.linkedRisks.length;
            }

            if (this.account.useSustainability) {
              this.toggleButtonOptions.find(
                (x) => x.objectType === ObjectTypes.Indicator
              ).value = `${this.localisedIndicators} (${this.linkedIndicators.length})`;

              count += this.linkedIndicators.length;
            }

            this.count.next(count);
          } else if (this.objectType === ObjectTypes.Job) {
            this.toggleButtonOptions.find(
              (x) => x.objectType === ObjectTypes.IncidentItem
            ).value = `${this.localisedIncidents} (${this.linkedIncidents.length})`;
            this.currentlySelectedItems = this.linkedIncidents;
            this.count.next(this.linkedIncidents.length);
          } else if (this.objectType === ObjectTypes.IncidentItem) {
            this.toggleButtonOptions.find(
              (x) => x.objectType === ObjectTypes.Job
            ).value = `${this.localisedJobs} (${this.linkedJobs.length})`;
            this.toggleButtonOptions.find(
              (x) => x.objectType === ObjectTypes.IncidentItem
            ).value = `${this.localisedIncidents} (${this.linkedIncidents.length})`;

            if (this.selectedToggleTab.objectType === ObjectTypes.IncidentItem) {
              this.currentlySelectedItems = [...this.linkedIncidents];
            } else if (this.selectedToggleTab.objectType === ObjectTypes.Job) {
              this.currentlySelectedItems = [...this.linkedJobs];
            }

            this.count.next(this.linkedIncidents.length + this.linkedJobs.length);
          }

          this.selectedToggleTab = this.toggleButtonOptions[0];
          this.toggleButtonOptions = this.toggleButtonOptions.slice();
          this.onToggleSwitch(this.selectedToggleTab)
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        })
    );
  }

  getItemsByObjectType(
    itemsByObejct: { objectType: number; linkedItems: ObjectSimpleViewModel[] }[],
    objectType: ObjectTypes
  ): ObjectSimpleViewModel[] {
    const items = itemsByObejct.find((e) => e.objectType === objectType);

    return items ? items.linkedItems : [];
  }

  onToggleSwitch(event: LinkedItemsToggleOptions) {
    if (event.objectType === ObjectTypes.Task) {
      this.toggleButtonOptions[this.toggleButtonOptions.findIndex((x) => x.objectType === ObjectTypes.Task)] = event;
      this.currentlySelectedItems = this.linkedTasks;
    } else if (event.objectType === ObjectTypes.Project) {
      this.toggleButtonOptions[this.toggleButtonOptions.findIndex((x) => x.objectType === ObjectTypes.Project)] = event;
      this.currentlySelectedItems = this.linkedProjects;
    } else if (event.objectType === ObjectTypes.Risk) {
      this.toggleButtonOptions[this.toggleButtonOptions.findIndex((x) => x.objectType === ObjectTypes.Risk)] = event;
      this.currentlySelectedItems = this.linkedRisks;
    } else if (event.objectType === ObjectTypes.Indicator) {
      this.toggleButtonOptions[this.toggleButtonOptions.findIndex((x) => x.objectType === ObjectTypes.Indicator)] = event;
      this.currentlySelectedItems = this.linkedIndicators;
    } else if (event.objectType === ObjectTypes.Job) {
      this.toggleButtonOptions[this.toggleButtonOptions.findIndex((x) => x.objectType === event.objectType)] = event;
      this.currentlySelectedItems = this.linkedJobs;
    } else if (event.objectType === ObjectTypes.IncidentItem) {
      this.toggleButtonOptions[this.toggleButtonOptions.findIndex((x) => x.objectType === event.objectType)] = event;
      this.currentlySelectedItems = this.linkedIncidents;
    }

    this.selectedToggleTab = event;
  }

  addLinkedItem() {
    const modalConfig = { class: 'modal-large-add', ignoreBackdropClick: true };
    const currentLinkedObj: ObjectSimpleViewModel = {
      id: this.id,
      status: this.rag,
      title: this.title,
      objectType: this.objectType,
      objectSubType: this.objectSubType,
    };

    const initialState = {
      currentLinkedObj: currentLinkedObj,
    };
    const modalParams = Object.assign({}, modalConfig, { initialState });
    this.bsModalRef = this.modalService.show(LinkedItemsAddModalComponent, modalParams);
    this.subscriptions.add(
      this.bsModalRef.content.itemCreated.subscribe((res: boolean) => {
        if (res === true) {
          this.loadData();
        }
      })
    );
  }

  removeLink(item: ObjectSimpleViewModel) {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.common.confirm_remove_link_for_item),
      () => {
        this.linkedService.removeRelation(this.id, this.objectType, item.id, item.objectType).subscribe((res) => {
          void this.alertService.success(this.translateService.instant(T.common.item_removed));
          this.loadData();
        });
      },
      () => {
        // handle cancel
      }
    );
  }
}
