import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IncidentStatusViewModel } from '../models/incidentStatusViewModel';
import { locationMapViewModel } from '../../incidents/viewModels/locationMapViewModel';
import { IncidentsLocationMapDetailsComponent } from '../../incidents/components/incidents-location-map-details/incidents-location-map-details.component';
import { CachingService } from '../services/caching.service';
import { IncidentsEventsEmitter } from '../events/incidents.events';
import { IncidentsService } from '../services/incidents.service';
import { AccountSettingsService } from '../services/account-settings.service';
import { SeverityViewModel } from '../models/severityViewModel';
import { IncidentStatuses } from '../../incidents/enums/incidentStatuses';
import { PrivacyStatuses } from '../enums/privacyStatuses';
import { Employee } from '../models/employee';
import { Account } from '../models/account';
import { ZoneViewModel } from '../viewModels/zoneViewModel';
import { AreaViewModel } from '../viewModels/areaViewModel';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { Colors } from '../enums/colors';
import { IncidentSeverities } from '../enums/incidents/incidentSeverities';
import { IncidentsSettingsService } from '../services/indicents-settings.service';
import { MentionItem } from '../models/mentions/MentionItem';
import { IncidentItemViewModel } from '../../incidents/viewModels/incidentItemViewModel';
import { IncidentListItemViewModel } from '../../incidents/viewModels/incidentListItemViewModel';
import { RAGBreakdown } from '../models/rag/ragBreakdown';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { EnumUtilities } from '../utilities/enum.utilities';

@Injectable()
export class IncidentsManager {
  public severities: SeverityViewModel[] = [
    {
      severity: IncidentSeverities.Severity_1,
      color: Colors.Green,
      description: this.translateService.instant(T.control.severities_descriptions.severity_1),
    },
    {
      severity: IncidentSeverities.Severity_2,
      color: Colors.Yellow,
      description: this.translateService.instant(T.control.severities_descriptions.severity_2),
    },
    {
      severity: IncidentSeverities.Severity_3,
      color: Colors.Amber,
      description: this.translateService.instant(T.control.severities_descriptions.severity_3),
    },
    {
      severity: IncidentSeverities.Severity_4,
      color: Colors.Orange,
      description: this.translateService.instant(T.control.severities_descriptions.severity_4),
    },
    {
      severity: IncidentSeverities.Severity_5,
      color: Colors.Red,
      description: this.translateService.instant(T.control.severities_descriptions.severity_5),
    },
  ];

  public severityColourArray: string[] = this.severities.map((s) => s.color);
  public readonly statuses: { key: IncidentStatuses; value: string }[] = EnumUtilities.items(IncidentStatuses);
  public readonly privacyStatuses: { key: PrivacyStatuses; value: string }[] = EnumUtilities.items(PrivacyStatuses);
  public readonly statusViewModels: IncidentStatusViewModel[] = [
    {
      status: IncidentStatuses.Draft,
      color: '#939899',
      icon: 'priority_high',
    },
    {
      status: IncidentStatuses.Open,
      color: '#3a424b',
      icon: 'priority_high',
    },
    {
      status: IncidentStatuses.Closed,
      color: Colors.Blue,
      icon: 'check',
    },
  ];

  public readonly account: Account;
  public readonly employee: Employee;

  public locationMapData: locationMapViewModel[];
  public incidentZones: ZoneViewModel[];
  public indidentAreas: AreaViewModel[];
  public incidentLocationMapDetailComponent: IncidentsLocationMapDetailsComponent;

  constructor(
    private readonly cachingService: CachingService,
    private readonly incidentsEventsEmitter: IncidentsEventsEmitter,
    public incidentService: IncidentsService,
    public incidentSettingsService: IncidentsSettingsService,
    private accountSettingsService: AccountSettingsService,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService
  ) {
    this.account = this.cachingService.GetAccountInfo();
    this.employee = this.cachingService.GetEmployee(this.account.id);
    this.initAccountSeverities();
  }

  initAccountSeverities() {
    if (this.employee) {
      this.accountSettingsService.getIncidentSeveritySettings().subscribe((severities) => {
        this.severities = severities;
        this.setSeveritiesToDefaultColours();
      });

      this.incidentsEventsEmitter.accountSeveritySettingsChanged$.subscribe((severities) => {
        this.severities = severities;
        this.setSeveritiesToDefaultColours();
      });
    }
  }

  public setSeveritiesToDefaultColours() {
    // Override account setting colours and set to default
    this.severities.find((s) => s.severity == 1).color = Colors.Green;
    this.severities.find((s) => s.severity == 2).color = Colors.Yellow;
    this.severities.find((s) => s.severity == 3).color = Colors.Amber;
    this.severities.find((s) => s.severity == 4).color = Colors.Orange;
    this.severities.find((s) => s.severity == 5).color = Colors.Red;
  }

  public GetIncidentMentionFeedList(): Observable<MentionItem[]> {
    return this.incidentService.GetIncidentsMentionFeedList();
  }

  public getColorBySeverity(severity: number): string {
    const matchingSeverity = this.severities.find((s) => s.severity === severity);
    if (matchingSeverity) return matchingSeverity.color;
  }

  public getDescriptionBySeverity(severity: number) {
    const matchingSeverity = this.severities.find((s) => s.severity === severity);
    if (matchingSeverity) return matchingSeverity.description;
  }

  public getSeverityByColor(color: string): number {
    return this.severities.find((s) => s.color === color)?.severity;
  }

  public getSeverityByValue(severity: number) {
    return this.severities.find((s) => s.severity === severity);
  }

  public getColourByStatus(status: IncidentStatuses) {
    const matchingStatus = this.statusViewModels.find((s) => s.status === status);
    if (matchingStatus) return matchingStatus.color;
  }

  public getIconByStatus(status: IncidentStatuses) {
    const matchingStatus = this.statusViewModels.find((s) => s.status === status);
    if (matchingStatus) return matchingStatus.icon;
  }

  public getStatus(status: IncidentStatuses) {
    return this.statusViewModels.find((s) => s.status === status);
  }

  public getPrivacyStatus(status: PrivacyStatuses): string {
    if (isNaN(status)) {
      return 'n/a';
    }

    if (this.privacyStatuses.find((e) => e.key === status) == null) {
      return 'n/a';
    }

    return this.privacyStatuses.find((e) => e.key === status).value;
  }

  public getZones(): Observable<ZoneViewModel[]> {
    return this.incidentService.GetZones();
  }

  public getAreas(accountId: number): Observable<AreaViewModel[]> {
    return this.incidentService.GetAreas(accountId);
  }

  // INCIDENT UPDATE METHODS

  //todo remove

  public filterIncidentsWithLocation(incidents: IncidentListItemViewModel[]): IncidentListItemViewModel[] {
    return incidents.filter((i) => this.hasLocation(i));
  }

  public hasLocation(incident: IncidentItemViewModel): boolean {
    return incident.latitude !== 0 && incident.longitude !== 0;
  }

  public getIncidentChannelId(): number {
    return this.employee.defaultIncidentChannel;
  }

  public mapSeveritiesToRagBreakDown(severities: IncidentSeverities[]): RAGBreakdown[] {
    const list = [
      {
        ragStatus: IncidentSeverities.Severity_1,
        colour: Colors.Green,
        count: severities.filter((r) => r === IncidentSeverities.Severity_1).length,
      },
      {
        ragStatus: IncidentSeverities.Severity_2,
        colour: Colors.Yellow,
        count: severities.filter((r) => r === IncidentSeverities.Severity_2).length,
      },
      {
        ragStatus: IncidentSeverities.Severity_3,
        colour: Colors.Amber,
        count: severities.filter((r) => r === IncidentSeverities.Severity_3).length,
      },
      {
        ragStatus: IncidentSeverities.Severity_4,
        colour: Colors.Orange,
        count: severities.filter((r) => r === IncidentSeverities.Severity_4).length,
      },
      {
        ragStatus: IncidentSeverities.Severity_5,
        colour: Colors.Red,
        count: severities.filter((r) => r === IncidentSeverities.Severity_5).length,
      },
    ];

    return list;
  }
}
