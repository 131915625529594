import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BubbleComponent {
  @Input() color: string;
  @Input() value: number;
  @Input() icon: string;
  @Input() large: boolean;
}
