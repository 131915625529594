import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum RiskStatusTypes {
  Draft = 1,
  Open = 2,
  Closed = 3,
}

export namespace RiskStatusTypes {
  export function items(): { key: RiskStatusTypes; value: string }[] {
    const a: { key: RiskStatusTypes; value: string }[] = [];

    for (const enumMember in RiskStatusTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({
          key: +enumMember,
          value: RiskStatusTypes[enumMember].replace(/_/g, ' '),
        });
      }
    }

    return a;
  }

  export function translatedItems(translateService: TranslateService): { key: RiskStatusTypes; value: string }[] {
    const a: { key: RiskStatusTypes; value: string }[] = [];

    for (const enumMember in RiskStatusTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({
          key: +enumMember,
          value: translateItem(+enumMember, translateService),
        });
      }
    }

    return a;
  }
}

function translateItem(key: RiskStatusTypes, translateService: TranslateService): string {
  switch (key) {
    case RiskStatusTypes.Draft:
      return translateService.instant(T.common.draft.one);
    case RiskStatusTypes.Open:
      return translateService.instant(T.common.open.one);
    case RiskStatusTypes.Closed:
      return translateService.instant(T.common.closed.one);
    default:
      return 'n/a';
  }
}
