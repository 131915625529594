export class EmployeeViewModel {
  public id: number;
  public firstName: string;
  public surname: string;
  public username: string;
  public avatarHtml: string;
  public thumbnailURL: string;
  public itemOwnerId: number;
  public primary: boolean;

  constructor(_id: number, _firstName: string, _surname: string, _itemOwnerId?: number) {
    this.id = _id;
    this.firstName = _firstName;
    this.surname = _surname;
    this.itemOwnerId = _itemOwnerId;
  }
}
