import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export enum ButtonTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
}

export enum IconTheme {
  Primary = 'primary',
  Secondary = 'secondary',
}

export enum ButtonType {
  Normal = 'normal',
  Block = 'block',
}

export enum DropdownTogglePosition {
  Left = 'left',
  Right = 'right',
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() buttonTheme: ButtonTheme = ButtonTheme.Secondary;
  @Input() type: ButtonType = ButtonType.Normal;
  @Input() text: string;
  @Input() icon: string;
  @Input() iconTheme: IconTheme = IconTheme.Secondary;
  @Input() disabled: boolean;
  @Input() dropdownTogglePosition: DropdownTogglePosition;
  @Input() useContent: boolean;

  @Output() clicked: EventEmitter<Event> = new EventEmitter();

  get buttonClasses(): string {
    switch (this.buttonTheme) {
      case ButtonTheme.Primary:
        return this.getButtonClassesWithPropertiesTakenIntoAccount(`btn filter-button action-btn`);
      case ButtonTheme.Secondary:
        return this.getButtonClassesWithPropertiesTakenIntoAccount(`btn btn-white filter-button`);
      case ButtonTheme.Danger:
        return this.getButtonClassesWithPropertiesTakenIntoAccount(`btn filter-button danger-btn`);
      default:
        return '';
    }
  }

  get iconClasses(): string {
    switch (this.iconTheme) {
      case IconTheme.Primary:
        return 'material-symbols-outlined-primary';
      case IconTheme.Secondary:
        return '';
      default:
        return '';
    }
  }

  onClick(e: Event) {
    this.clicked.emit(e);
  }

  private getButtonClassesWithPropertiesTakenIntoAccount(classes: string): string {
    const classesArray: string[] = classes.split(' ');

    if (this.type === ButtonType.Block) {
      classesArray.push('btn-block');
    }

    if (this.dropdownTogglePosition) {
      switch (this.dropdownTogglePosition) {
        case DropdownTogglePosition.Left:
          classesArray.push('dropdown-toggle-left');
          break;
        case DropdownTogglePosition.Right:
          classesArray.push('dropdown-toggle');
          break;
      }
    }

    return classesArray.join(' ');
  }
}
