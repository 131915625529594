import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgImagePreviewComponent } from '../components/common/ngImagePrewview/ngImagePreview.component';
import { DocumentTypes } from '../enums/documentTypes';
import { DynamicComponentsService } from '../services/dynamicComponents.service';
import { DocumentViewModel } from '../viewModels/documents/documentViewModel';

@Directive({
  selector: '[ngImagePreview]',
  host: {
    '(click)': 'onClick($event)',
  },
})
export class NgImagePreviewDirective implements OnInit, OnChanges, OnDestroy {
  @Input() image: string;
  @Input() object: DocumentViewModel;
  @Input() attachmentType: DocumentTypes;
  static ngImagePreviewComponentRef: ComponentRef<NgImagePreviewComponent>;

  constructor(
    private elementRef: ElementRef,
    private readonly dynamicComponentsService: DynamicComponentsService,
    private readonly appRef: ApplicationRef
  ) {
    if (!NgImagePreviewDirective.ngImagePreviewComponentRef) {
      this.appendToBody();
    }
  }

  ngOnInit() {
    this.updateInstance();
  }

  ngOnChanges() {
    this.updateInstance();
  }

  ngOnDestroy() {
    this.removeFromBody();
  }

  openDirectiveFromComponent(object, attachmentType, image) {
    this.image = image;
    this.object = object;
    this.attachmentType = attachmentType;
    this.updateInstance();
  }

  get ngImagePreviewInstance(): NgImagePreviewComponent {
    return NgImagePreviewDirective.ngImagePreviewComponentRef.instance;
  }

  get ngImagePreviewChangeDetectorRef(): ChangeDetectorRef {
    return NgImagePreviewDirective.ngImagePreviewComponentRef.changeDetectorRef;
  }

  updateInstance() {
    if (!this.ngImagePreviewInstance) {
      return;
    }

    this.ngImagePreviewInstance.image = this.image;
    this.ngImagePreviewInstance.updateDropdownContent(this.object, this.attachmentType);
    this.ngImagePreviewChangeDetectorRef.detectChanges();
  }

  onClick() {
    this.updateInstance();

    this.ngImagePreviewInstance.show();
  }

  @HostListener('document:keydown.escape', ['$event']) onEscape(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.ngImagePreviewInstance.hide();
  }

  private getDomElement<T>(componentRef: ComponentRef<T>): HTMLElement {
    if (!componentRef) return;
    return (componentRef.hostView as EmbeddedViewRef<T>).rootNodes[0] as HTMLElement;
  }

  private appendToBody() {
    NgImagePreviewDirective.ngImagePreviewComponentRef = this.dynamicComponentsService.createComponentElement(
      NgImagePreviewComponent,
      {}
    );

    this.appRef.attachView(NgImagePreviewDirective.ngImagePreviewComponentRef.hostView);

    document.body.appendChild(this.getDomElement(NgImagePreviewDirective.ngImagePreviewComponentRef));
  }

  private removeFromBody() {
    // let el = this.getDomElement(NgImagePreviewDirective.ngImagePreviewComponentRef);
    // if(el) {
    //   el.remove();
    // }
    // this.appRef.detachView(NgImagePreviewDirective.ngImagePreviewComponentRef.hostView);
    // NgImagePreviewDirective.ngImagePreviewComponentRef = undefined;
  }
}
