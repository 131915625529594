import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { FilterTypes } from '../../../enums/filterTypes';
import { UserAgentService } from '../../../services/user-agent.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-headline-status',
  templateUrl: './headline-status.component.html',
  styleUrls: ['./headline-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadlineStatusComponent implements OnInit {
  @ViewChild('textArea') textArea: ElementRef<HTMLElement>;
  @ViewChild('headlineInput') headlineInput: ElementRef<HTMLElement>;

  @Input() set input(val: string) {
    this.origintalInput = val;
    this.modifiedInput = val;
  }
  @Input() label: string;
  @Input() canEdit: boolean = true;
  @Input() minLength = 3;
  @Input() object: any;
  @Output() onChanged = new EventEmitter<any>();

  public placeholder: string = '';
  public isEditMode = false;
  public origintalInput = '';
  public modifiedInput = '';
  public showEditButton = false;
  public editState = false;
  public isTouchDevice = false;
  public readonly T = T;
  public lineHeight: number = 26;
  public isCollapsed: boolean = false;
  public isCollapsable: boolean = false;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly userAgentService: UserAgentService,
    protected readonly translateService: TranslateService,
    private readonly elementRef: ElementRef
  ) {}

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.isEditMode === true && event.key === 'Enter') {
      event.preventDefault();
      this.saveHeadlineStatus();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  clickout(event: MouseEvent) {
    if (this.isEditMode === true && !this.elementRef.nativeElement.contains(event.target)) {
      this.saveHeadlineStatus();
    }
  }

  ngOnInit(): void {
    this.userAgentService.isTouch$.subscribe((isTouch) => {
      this.isTouchDevice = isTouch;
    });

    this.placeholder = this.translateService.instant(T.common.click_here_to_update_headline_status);
    this.input = this.object.headlineStatus;
  }

  ngAfterViewInit(): void {
    this.setCollapseState();
  }

  public saveHeadlineStatus(): void {
    const headlineFilter = this.object.filters.find((f) => f.filterType == FilterTypes.Headline_Status);
    if (headlineFilter) {
      headlineFilter.value = JSON.parse(JSON.stringify(this.modifiedInput));
      headlineFilter.filterAction = FilterActionTypes.Update;
    }
    this.object.headlineStatus = JSON.parse(JSON.stringify(this.modifiedInput));
    this.onChanged.emit(this.object);

    this.isCollapsed = true;
    this.toggleEditMode();
    this.changeDetectorRef.markForCheck();
  }

  private setCollapseState(): void {
    setTimeout(() => {
      if (this.textArea) {
        const currentHeight = this.textArea.nativeElement.offsetHeight;
        if (currentHeight > this.lineHeight) {
          this.isCollapsed = true;
          this.isCollapsable = true;
        }
      }
      this.changeDetectorRef.markForCheck();
    }, 20);
  }

  /**
   * Toggles edit mode
   */
  public toggleEditMode(e?: Event): void {
    e?.stopPropagation();
    if (this.canEdit) {
      this.isEditMode = !this.isEditMode;
      this.setCollapseState();
      this.changeDetectorRef.markForCheck();
    }
  }

  /**
   * Cancels edit mode and resets the input to the original value
   */
  public cancelEdit(event?: Event): void {
    if (this.origintalInput !== this.modifiedInput) {
      this.modifiedInput = this.origintalInput;
    }
    event?.stopPropagation();
    this.toggleEditMode(event);
    this.changeDetectorRef.markForCheck();
  }
}
