export class DarkOverlayMapType implements google.maps.MapType {
  public tileSize: google.maps.Size;
  public static maxZoom = 21;
  public static alt = '';

  constructor(tileSize: google.maps.Size) {
    this.tileSize = tileSize;
  }
  alt: string;
  maxZoom: number;
  minZoom: number;
  name: string;
  projection: google.maps.Projection;
  radius: number;

  getTile(tileCoord: google.maps.Point, zoom: number, ownerDocument: Document): HTMLDivElement {
    const div = ownerDocument.createElement('div');
    div.innerHTML = '';
    div.style.width = `${this.tileSize.width}px`;
    div.style.height = `${this.tileSize.height}px`;
    div.style.border = '0px';
    div.style.opacity = '0.46';
    div.style.backgroundColor = '#000';

    return div;
  }

  releaseTile(): void {}
}
