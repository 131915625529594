
<app-checkbox [isChecked]="checkState" [disabled]="disabled" (checked)="onClick()"></app-checkbox>
<div class="exclude-label" [class.disabled]="disabled">{{label}}</div>
<app-information-bubble
  [color]="disabled ? 'var(--wt-grey-light)' : 'var(--wt-primary)'"
  [outlinedIcon]="false"
  [size]="'20px'"
  [tooltip]="{
    position: ['right'],
    message: message
  }"
></app-information-bubble>
