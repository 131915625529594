import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';
import { LocalisationService } from '../../../services/localisation.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterTypes } from '../../../enums/filterTypes';

@Component({
  selector: 'app-expandable-filter-selector',
  templateUrl: './expandable-filter-selector.component.html',
  styleUrls: ['./expandable-filter-selector.component.scss']
})
export class ExpandableFilterSelectorComponent implements OnInit {
  @Input() filterSelectorVM: FilterTypeSelectorViewModel;
  @Input() appliedFilters: FilterViewModel[] = [];
  @Input() expanded: boolean = false;

  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();
  @Output() toggleChange: EventEmitter<'open' | 'close'> = new EventEmitter<'open' | 'close'>();

  public allowedFilters: FilterViewModel[] = [];
  public searchFilteredAllowedFilters: FilterViewModel[] = [];
  public searchText: string = '';
  public readonly T = T;
  public readonly filterTypes = FilterTypes;

  constructor(private allowedFiltersService: AllowedFiltersService,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService) {
  }

  ngOnInit(): void {
    this.allowedFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(this.filterSelectorVM.filterType);
    this.allowedFilters = this.allowedFilters.map((a) => {a.displayForGlobalObjectType = this.filterSelectorVM.displayForObjectType; return a;});
    this.allowedFilters = FilterUtilities.setFilterText(this.allowedFilters, this.localisationService, this.translateService);
    this.searchFiltersByTextSearch(this.searchText);
  }

  public onExpand(): void {
    const newState = !this.expanded;
    this.toggleChange.emit(newState ? 'open' : 'close');
  }

  onFilterSelected(filter: FilterViewModel): void {
    const copiedFilter = JSON.parse(JSON.stringify(filter)) as FilterViewModel;
    const isAlreadySelected = this.getItemIndex(copiedFilter) > -1;

    let localAppliedFilters = this.appliedFilters.slice();
    localAppliedFilters = localAppliedFilters.filter((a) => a.id !== filter.id);

    if (isAlreadySelected) {
      copiedFilter.filterAction = FilterActionTypes.Remove;
    } else {
      copiedFilter.filterAction = FilterActionTypes.Add;
      copiedFilter.displayForGlobalObjectType = this.filterSelectorVM.displayForObjectType;
      copiedFilter.exclude = this.excludeState;
    }
    localAppliedFilters.push(copiedFilter);

    this.filtersUpdated.emit(localAppliedFilters);
  }

  public onPrimaryChanged(filter: FilterViewModel): void {
    const copiedFilter = JSON.parse(JSON.stringify(filter)) as FilterViewModel;
    const isAlreadySelected = this.getItemIndex(copiedFilter) > -1;

    let localAppliedFilters = this.appliedFilters.slice();
    localAppliedFilters = localAppliedFilters.filter((a) => a.id !== filter.id);

    if (isAlreadySelected) {
      copiedFilter.filterAction = FilterActionTypes.Update;
    } else {
      copiedFilter.filterAction = FilterActionTypes.Add;
      copiedFilter.displayForGlobalObjectType = this.filterSelectorVM.displayForObjectType;
      copiedFilter.exclude = this.excludeState;
    }

    localAppliedFilters.push(copiedFilter);
    this.filtersUpdated.emit(localAppliedFilters);
  }

  private getItemIndex(filter: FilterViewModel): number {
    return this.appliedFilters.findIndex((a) => a.filterType === filter.filterType && a.filterValue == filter.filterValue && a.displayForGlobalObjectType === this.filterSelectorVM.displayForObjectType);
  }

  public onTextSearch(text: string): void {
    this.searchText = text;
    this.searchFiltersByTextSearch(text);
  }

  private searchFiltersByTextSearch(text: string): void {
    this.searchFilteredAllowedFilters = this.allowedFilters.filter((a) => a.filterText?.toLowerCase().includes(text?.toLowerCase()));
  }

  public clearFilters(): void {

    this.appliedFilters.forEach((a) => {
      a.filterAction = FilterActionTypes.Remove;
    });

    this.filtersUpdated.emit(this.appliedFilters);
    this.appliedFilters =[];
  }

  public get excludeState(): boolean {
    return this.appliedFilters?.[0]?.exclude;
  }

  onExclude(excluded: boolean): void {
    let localAppliedFilters = this.appliedFilters.slice();
    localAppliedFilters.forEach((a) => {
      a.exclude = excluded;
    });
    this.filtersUpdated.emit(localAppliedFilters);
  }
}
