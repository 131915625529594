import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Injectable({
  providedIn: 'root',
})
export class FormHelperService {
  constructor(
    private readonly translateService: TranslateService
  ) {}

  public getErrorStringMessages(validationErrors: ValidationErrors, prefix?: string): string[] {
    const modifiedStringErrors: string[] = [];
    // populate errors from regular validators (not the custom ones)
    if (!validationErrors) {
      return [];
    }

    if (validationErrors['minlength']) {
      if(prefix){
        modifiedStringErrors.push(
          this.translateService.instant(T.common.item_minimum_length_is_count_symbols, { item: prefix, count: validationErrors['minlength'].requiredLength })
          );
      }else {
        modifiedStringErrors.push(
          this.translateService.instant(T.common.minimum_length_is_count_symbols, { count: validationErrors['minlength'].requiredLength })
        );
      }
    }

    if (validationErrors['maxlength']) {
      if(prefix){
        modifiedStringErrors.push(
          this.translateService.instant(T.common.item_maximum_length_is_count_symbols, { item: prefix, count: validationErrors['maxlength'].requiredLength })
          );
      }else {
        modifiedStringErrors.push(
          this.translateService.instant(T.common.maximum_length_is_count_symbols, { count: validationErrors['maxlength'].requiredLength })
        );
      }
    }

    if (validationErrors['required']) {
      if(prefix){
        modifiedStringErrors.push(
          this.translateService.instant(T.common.item_is_required, { item: prefix })
          );
      }else {
        modifiedStringErrors.push(
          this.translateService.instant(T.common.required.one)
        );
      }
    }

    if (validationErrors['email']) {
      modifiedStringErrors.push(
        this.translateService.instant(T.common.please_enter_valid_email)
      );
    }

    // Here are the custom ones (the ones that are added in the WtValidators)
    Object.keys(validationErrors).forEach((keyError: string) => {
      if (validationErrors[keyError]?.['errorMessages']) {
        (validationErrors[keyError]?.['errorMessages'] as string[]).forEach((e) => {
          modifiedStringErrors.push(`${prefix ? prefix + ' ' : ''}${e}`);
        });
      }
    });

    return modifiedStringErrors;
  }
}
