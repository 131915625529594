import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class WindowEventsEmitter {
  private windowClickEventTriggered = new Subject<Event>();
  private windowScrollEventTriggered = new Subject<Event>();
  private windowResizeEventTriggered = new Subject<Event>();
  private windowMousemoveEventTriggered = new Subject<Event>();
  private windowEventTriggered = new Subject<Event>();

  [key: string]: ((e: Event) => void) | Subject<Event> | Observable<Event>;

  windowEventTriggered$ = this.windowEventTriggered.asObservable();
  windowClickEventTriggered$: Observable<Event> = this.windowClickEventTriggered.asObservable();
  windowScrollEventTriggered$: Observable<Event> = this.windowScrollEventTriggered.asObservable();
  windowResizeEventTriggered$: Observable<Event> = this.windowResizeEventTriggered.asObservable();
  windowMousemoveEventTriggered$: Observable<Event> = this.windowMousemoveEventTriggered.asObservable();

  broadcastWindowClickEventTriggered(event: Event) {
    this.windowClickEventTriggered.next(event);
  }

  broadcastWindowScrollEventTriggered(event: Event) {
    this.windowScrollEventTriggered.next(event);
  }

  broadcastWindowMousemoveEventTriggered(event: Event) {
    this.windowMousemoveEventTriggered.next(event);
  }

  broadcastWindowResizeEventTriggered(event: Event) {
    this.windowResizeEventTriggered.next(event);
  }

  broadcastWindowEventTriggered(event: Event) {
    this.windowEventTriggered.next(event);
  }
}
