import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-simple-editable-field',
  templateUrl: './simple-editable-field.component.html',
  styleUrls: ['./simple-editable-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleEditableFieldComponent implements OnInit {
  @ViewChild('inputWrapper') inputWrapper: ElementRef<HTMLElement>;
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

  public editMode: boolean = false;
  public originalInput: string;
  public hasClickStartedInside = false;

  @Input() canEdit: boolean;
  @Input() placeholder: string;
  @Input() maxLength = 80;
  @Input() minLength = 3;
  @Input() headerFontSize: string;
  @Input() required = true;
  @Input() input: string;
  @Input() lozengePadding: boolean = false;
  @Input() lozengeHeight: boolean = false;
  @Input() customWidth: string;
  @Input() fontSize: number = 16;

  //This property is used in non editable state to define how many lines the text can break before the overflow ellipsis appears.
  @Input() lineClamp = 3;

  //This property is used in edit state to define how many lines the text can break.
  @Input() editStateRows = 1;
  @Output() onUpdate = new EventEmitter<string>();

  @HostListener('mousedown', ['$event'])
  mouseDownInside(event: MouseEvent) {
    event.stopPropagation();
    this.hasClickStartedInside = true;
    this.changeDetectorRef.markForCheck();
  }

  @HostListener('document:mouseup', ['$event'])
  mouseUpAnywhere(event: MouseEvent) {
    event.stopPropagation();
    if (this.hasClickStartedInside) {
      this.hasClickStartedInside = false;
    } else {
      this.clickOutSave();
    }

    this.changeDetectorRef.markForCheck();
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.input !== this.originalInput) {
      if (this.fieldIsValid) {
        this.editMode = false;
        this.onSave(event);
      } else {
        this.cancelEdit();
      }
    } else {
      this.cancelEdit();
    }

    this.changeDetectorRef.markForCheck();
  }

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.originalInput = JSON.parse(JSON.stringify(this.input));
    if(this.lineClamp < 1) {
      this.lineClamp = 1;
    }
    if(this.editStateRows < 1) {
      this.editStateRows = 1;
    }
  }

  ngOnChanges() {
    this.originalInput = JSON.parse(JSON.stringify(this.input));
  }

  public toggleEditMode(): void {
    if (this.canEdit) {
      this.editMode = !this.editMode;
      setTimeout(() => {
        this.inputElement.nativeElement.select();
      }, 0);
      this.changeDetectorRef.markForCheck();
    }
  }

  public onSave(event: KeyboardEvent): void {
    if (this.fieldIsValid) {
      event.stopPropagation();
      this.onUpdate.next(this.input);
      this.editMode = false;
      this.changeDetectorRef.markForCheck();
    }
  }

  public clickOutSave() {
    if (this.fieldIsValid) {
      if (this.input !== this.originalInput) {
        this.onUpdate.next(this.input);
        this.editMode = false;
        this.changeDetectorRef.markForCheck();
      } else {
        this.cancelEdit();
      }
    } else {
      this.cancelEdit();
    }
  }

  public cancelEdit(event?): void {
    if (this.input !== this.originalInput) {
      this.input = JSON.parse(JSON.stringify(this.originalInput)).trim();
    }

    event?.stopPropagation();
    this.editMode = false;
    this.changeDetectorRef.markForCheck();
  }

  get fieldIsValid(): boolean {
    let result = true;

    const forbiddenChars = [';', '<', '>', '!'];
    result = !!(!forbiddenChars.some((el) => this.input.trim().includes(el)) && this.input.trim().length);

    return result;
  }
}
