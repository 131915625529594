import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { LocalisationService } from '../services/localisation.service';
import { ObjectTypes } from './objectTypes';

export enum TaskGroupSortOptions {
  A_Z = 1,
  //Task_Start_Date = 2,
  Task_Due_Date = 3,
  RAG = 4,
  Task_Count = 5,
}

export namespace TaskGroupSortOptions {
  export function translatedItems(
    translateService: TranslateService,
    localisationService: LocalisationService
  ): { key: TaskGroupSortOptions; value: string }[] {
    const a: { key: TaskGroupSortOptions; value: string }[] = [];
    for (const enumMember in TaskGroupSortOptions) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translateItem(+enumMember, translateService, localisationService) });
      }
    }
    return a;
  }
}

function translateItem(
  key: TaskGroupSortOptions,
  translateService: TranslateService,
  localisationService: LocalisationService
): string {
  switch (key) {
    case TaskGroupSortOptions.A_Z:
      return translateService.instant(T.common.sort_edit.a_z_alphabetically);
    case TaskGroupSortOptions.RAG:
      return translateService.instant(T.common.sort_edit.rag_with_colors);
    case TaskGroupSortOptions.Task_Count:
      return translateService.instant(T.common.sort_edit.items_count_high_to_low, {
        items: localisationService.localiseObjectType(ObjectTypes.Task, true),
      });
    case TaskGroupSortOptions.Task_Due_Date:
      return translateService.instant(T.common.sort_edit.item_due_date_earliest_first, {
        item: localisationService.localiseObjectType(ObjectTypes.Task, true),
      });
    default:
      return 'unknown';
  }
}
