<div class="update-container" (click)="updateIndicatorUpdate()">
  <ng-container *ngIf="indicatorUpdate.value !== undefined">
    <div class="trend-icon">
      <app-card-icon
        [size]="'24'"
        [trendRAG]="indicatorUpdate.trendRAG"
        [trendStatus]="indicatorUpdate.trendStatus"
        [cardType]="cardType"
      >
      </app-card-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="indicatorUpdate.value === undefined">
    <i class="material-symbols-outlined error-icon" [style.color]="!isUpdateDue ? 'var(--wt-red)' : 'var(--wt-grey-dark)'"
      >error_outlined</i
    >
  </ng-container>
  <div class="center">
    <div
      class="indicator-title text-truncate"
      [innerHTML]="indicatorUpdate.title | safeHtml"
      [app-tooltip]="{ message: indicatorUpdate.title }"
    ></div>
    <div class="center-bottom">
      <ng-container *ngIf="!isUpdateDue">
        <div class="update-value text-truncate">
          <ng-container *ngIf="indicatorUpdate.dateUpdated">
            <span class="me-2"> {{ valueString }} </span>
          </ng-container>
          <ng-container *ngIf="!indicatorUpdate.dateUpdated && indicatorUpdate.expectedValue">
            <span class="me-2">
              <span class="me-1">Exp.</span> {{ expectedValueString}}
            </span>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="isUpdateDue && indicatorUpdate.expectedValue">
        <div class="update-value">
          <span class="me-1">Exp.</span> {{ expectedValueString }}
        </div>
      </ng-container>
      <div class="updated-info text-truncate" [app-tooltip]="{ message: indicatorUpdate.updatedBy }">
        <ng-container *ngIf="!isUpdateDue">
          <ng-container *ngIf="indicatorUpdate.dateUpdated">
            {{ indicatorUpdate.dateUpdated | lastUpdatedDate }} | {{ indicatorUpdate.updatedBy }}
          </ng-container>
          <ng-container *ngIf="!indicatorUpdate.dateUpdated">
            {{
               indicatorUpdate.dueDate | dateDiff: false:false:true
            }}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isUpdateDue"
          >{{
            indicatorUpdate.dueDate | dateDiff: false:false:true
          }}
        </ng-container>
      </div>
    </div>
  </div>
  <!-- <div class="icon-container">
    <i class="material-symbols-outlined expand-icon">expand_more</i>
  </div> -->
</div>
