<div class="icon d-none d-sm-block" (click)="updateHeadlineStatus($event)">
  <div class="responsive-flex-wrapper">
    <i
      style="font-size: 16px"
      class="material-symbols-outlined"
      [class.grey-dark]="!headlineStatus"
      [app-tooltip]="{
        position: ['top'],
        message: headlineStatusMessage
      }"
      >record_voice_over</i
    >
  </div>
</div>
