/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { Injectable } from '@angular/core';
import { BehaviorSubject, skip } from 'rxjs';
import { IncidentItemTypes } from '../../incidents/enums/incidentItemTypes';
import { IncidentsUtilities } from '../../incidents/utilities/incidents-utilities.util';
import { IncidentDetailsItemViewModel } from '../../incidents/viewModels/incidentDetailsItemViewModel';
import { FilterTypes } from '../enums/filterTypes';
import { ObjectTypes } from '../enums/objectTypes';
import { CommentEventsEmitter } from '../events/comments.events';
import { ObjectEventEmitters } from '../events/object.events';
import { IncidentsManager } from '../managers/incidents.manager';
import { HeaderFiltersService } from './header-filters.service';
import { IncidentsService } from './incidents.service';
import { ListStateService } from './list-state.service';
import { FixedHeaderFiltersService } from './fixed-header-filters.service';
import { SortingService } from './sorting.service';
import { ListFilteringService } from './utilities/list-filtering.service';
import { ListOperationsService } from './utilities/list-operations.service';
import { ModuleService } from './module.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { ObjectEventModel } from '../models/objectEventModel';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { FilterUtilities } from '../utilities/filter.utilities';
import { LightFilterUpdateModel } from '../models/filter/LightFilterUpdateModel';
import { FilterActionTypes } from '../enums/filter/filterActionTypes.enum';
import { IncidentListItemViewModel } from '../../incidents/viewModels/incidentListItemViewModel';
import { FilterDateOptions } from '../enums/filter/filterDateOptions';
import { TrackingViews } from '../enums/tracking-view.enum';
import { TrackingService } from './tracking.service';
import { RAGBreakdown } from '../models/rag/ragBreakdown';
import { IncidentItemCardViewModel } from '../../incidents/viewModels/incidentItemCardViewModel';
import { IncidentSeverities } from '../enums/incidents/incidentSeverities';

@Injectable()
export class IncidentsListStateService extends ListStateService<IncidentDetailsItemViewModel> {
  protected readonly _toggleStatus = new BehaviorSubject<{ value: string; displayText: string }>({ value: 'Open', displayText: this.translateService.instant(T.common.open.many) });
  readonly toggleStatus$ = this._toggleStatus.asObservable();
  get toggleStatus(): { value: string; displayText: string } {
    return this._toggleStatus.getValue();
  }
  set toggleStatus(status: { value: string; displayText: string }) {
    this._toggleStatus.next(status);
  }

  trackingView: TrackingViews = TrackingViews.Incidents_List;
  initialStatusFilterHasBeenSet = false;
  constructor(
    private readonly incidentsManager: IncidentsManager,
    protected readonly apiService: IncidentsService,
    protected readonly headerFiltersService: HeaderFiltersService,
    protected readonly fixedHeaderFiltersService: FixedHeaderFiltersService,
    protected readonly sortingService: SortingService,
    protected readonly listOperationsService: ListOperationsService<IncidentDetailsItemViewModel>,
    protected readonly listFilteringService: ListFilteringService<IncidentDetailsItemViewModel>,
    protected readonly commentsEventEmitter: CommentEventsEmitter,
    protected readonly objectEventEmitters: ObjectEventEmitters,
    protected readonly moduleService: ModuleService,
    private readonly translateService: TranslateService,
    protected readonly trackingService: TrackingService
  ) {
    super(
      headerFiltersService,
      fixedHeaderFiltersService,
      sortingService,
      listOperationsService,
      listFilteringService,
      commentsEventEmitter,
      objectEventEmitters,
      moduleService,
      trackingService
    );
    this.objectType = ObjectTypes.IncidentItem;
  }

  protected updateRAGBreakdown(items: IncidentDetailsItemViewModel[]): void {
    this._ragBreakdown.next(this.createIncidentsRAGBreakdown(items));
  }

  public createIncidentsRAGBreakdown(items: IncidentItemCardViewModel[] | IncidentDetailsItemViewModel[]): RAGBreakdown[] {
    return this.incidentsManager.mapSeveritiesToRagBreakDown(items.map((item) => item.severity as number) as IncidentSeverities[]);
  }

  protected override initSubscriptions(): void {
    if (this.isListInitiated) {
      return;
    }

    super.initSubscriptions();

    this.subscriptions.add(
      this.commentsEventEmitter.commentCountUpdated$.subscribe((itemId) => {
        const item = this.getItem(itemId);

        if (item) {
          item.updated = new Date().toISOString();
          this.updateItem(item);
        }
      })
    );

    this.subscriptions.add(
      this.toggleStatus$.pipe(skip(1)).subscribe(({ value }) => {
        const incidentStatus = IncidentsUtilities.getIncidentStatusFromString(value);
        let filters: FilterViewModel[] = this.inputFilters.filter(
          (f) => f.filterType !== FilterTypes.Incident_Status && f.filterType !== FilterTypes.Archived
        );

        if (incidentStatus === undefined && value !== 'All') {
          filters.push(FilterUtilities.GenerateFilter(FilterTypes.Archived, true));
        } else {
          filters = IncidentsUtilities.setIncidentsFilters(filters, incidentStatus);
        }

        this.refreshList(filters);
      })
    );
  }

  protected override refreshFilters(): void {
    if (!this.initialStatusFilterHasBeenSet) {
      const statusHeaderFilter = this.headerFilters.find(f => f.filterType === FilterTypes.Incident_Status);
      if (!statusHeaderFilter || (statusHeaderFilter && statusHeaderFilter.filterValue !== undefined && statusHeaderFilter.filterValue !== null)) {
        const statusFilterValue = IncidentsUtilities.getIncidentStatusFromString(this.toggleStatus.value);
        const statusFilter = FilterUtilities.GenerateDefaultFilterForIncidentsList();
        statusFilter.filterValue = statusFilterValue.toString();
        statusFilter.displayForGlobalObjectType = ObjectTypes.IncidentItem;
        this.headerFilters = this.headerFilters.filter(f => f.filterType !== FilterTypes.Incident_Status);
        this.headerFilters.push(statusFilter);
        this.headerFiltersService.setCurrentFilters(this.headerFilters);
        this.initialStatusFilterHasBeenSet = true;
      }
    }

    this.filters = FilterUtilities.MergeFilterArrays(this.headerFilters, this.fixedHeaderFilters, this.inputFilters);

    this.filters = this.filters.map((f) => {
      if (f.filterType === FilterTypes.Keyword) {
        f.displayForGlobalObjectType = this.objectType;
      }

      return f;
    });
  }

  protected initObjectAddedSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.objectAdded$.subscribe((res) => {
        if (res.globalObjectType === this.objectType && this.getItem(res.model.id) === undefined) {
          const addedIncident = res.model as IncidentDetailsItemViewModel;

          if (
            addedIncident?.incidentItemType === IncidentItemTypes.Incident &&
            (addedIncident.incidentChannelId === this.incidentsManager.getIncidentChannelId() ||
              addedIncident.incidentChannelId === 0 ||
              this.incidentsManager.getIncidentChannelId() === 0)
          ) {

            const status = IncidentsUtilities.getIncidentStatusFromString(this.toggleStatus.value);

            if (addedIncident.status === status || this.toggleStatus.value === 'All') {

              const categoryFilter = addedIncident.filters.filter(f => f.filterType === FilterTypes.Incident_Category);
              const currentCategoriesFilters = this.filters.filter(f => f.filterType === FilterTypes.Incident_Category);

              if (!currentCategoriesFilters || currentCategoriesFilters.length === 0) {
                this.addOrRemoveItemBasedOnArchivedFilter(addedIncident);
              } else {
                const hasCategoryFilter = categoryFilter.some(f => currentCategoriesFilters.some(c => c.filterValue == f.filterValue));

                if (hasCategoryFilter) {
                  this.addOrRemoveItemBasedOnArchivedFilter(addedIncident);
                }
              }

            }
          }
        }
      })
    );
  }

  public addOrRemoveItemBasedOnArchivedFilter(incident: IncidentDetailsItemViewModel): void {
    const archivedFilter = this.currentFilters.find(f => f.filterType === FilterTypes.Archived);
    if(archivedFilter && FilterUtilities.getFilterValueAsBoolean(archivedFilter)) {
      this.deleteItem(incident);
    } else {
      this.addItem(incident);
    }
  }

  protected initObjectUpdatedSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.objectUpdated$.subscribe((res) => {
        if (res.globalObjectType === this.objectType) {
          const deleteItem = this.shouldDeleteItem(res);
          const status = IncidentsUtilities.getIncidentStatusFromString(this.toggleStatus.value);

          if (deleteItem) {
            this.deleteItem(res.model as IncidentDetailsItemViewModel);
          } else if (
            status?.toString() === res.model.filters.find((f) => f.filterType === FilterTypes.Incident_Status)?.filterValue
          ) {
            if (this.items.find((i) => i.id === res.model.id) !== undefined) {
              const incdientItem = res.model as IncidentDetailsItemViewModel;
              const updatedFilter = incdientItem.filters.find(f => f.filterType === FilterTypes.Date && f.dateProperty === FilterDateOptions.Updated);

              if (updatedFilter) {
                incdientItem.updated = updatedFilter.filterValue as string;
              }

              this.updateItem(incdientItem);
            } else if (!deleteItem && !res.model.isCommentUpdate) {
              this.addItem(res.model as IncidentDetailsItemViewModel);
            }
          }
        }
      })
    );
  }

  protected initObjectDeletedSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.objectDeleted$.subscribe((res) => {
        if (res.globalObjectType !== this.objectType) {
          return;
        }

        let itemForDeletion = this.items.find(d=>d.id== res.globalObjectId);
        if (itemForDeletion) {
          this.deleteItem(itemForDeletion);
        }
      })
    );
  }


  protected override initObjectUpdatedLightSubscription(): void {
    this.subscriptions.add(
      this.objectEventEmitters.listLightUpdated$.subscribe((res) => {
        if (res.globalObjectType === this.objectType) {
          this.updateIncidentItemLight(this.items, res);
        }
      })
    );
  }

  /**
   * Add/Update/Delete updated incident in the list depeding on the filter changes
   * @param {IncidentDetailsItemViewModel[]} list  list of all incidents
   * @param {ObjectEventModel} res signalR object
   */
  public updateIncidentItemLight(list: IncidentDetailsItemViewModel[], res: ObjectEventModel) {
    const filterChanges = res.model as LightFilterUpdateModel;
    const hStatusFilter = filterChanges.changes.find((f) => f.filterType === FilterTypes.Headline_Status);
    const changedObject: IncidentDetailsItemViewModel = list.find(
      (a: IncidentDetailsItemViewModel) => a.id === filterChanges.globalObjectId
    );

    if (changedObject) {
      this.changesToObject(hStatusFilter, changedObject, filterChanges);

      if (this.shouldDeleteItem({ globalObjectId: changedObject.id, globalObjectType: this.objectType, model: changedObject })) {
        this.deleteItem(changedObject);
      } else {
        this.updateItem(changedObject);
      }
    } else if (this.hasMatchingFiltersWithHeaderFilters(filterChanges.changes)) {
      this.subscriptions.add(
        this.apiService.details(res.globalObjectId).subscribe((res) => {
          this.addItem(res as IncidentListItemViewModel);
        })
      );
    }

    list = list.slice();
    return list;
  }

  /**
   * Reflect filter changes to object fields and filters
   * @param {FilterViewModel} hStatusFilter  headline status filter
   * @param {IncidentListItemViewModel} changedObject  target object
   * @param {LightFilterUpdateModel} filterChanges  list of changed filters to be applied/removed from the target object
   */
  private changesToObject(
    hStatusFilter: FilterViewModel,
    changedObject: IncidentDetailsItemViewModel,
    filterChanges: LightFilterUpdateModel
  ) {
    if (hStatusFilter) {
      changedObject.headlineStatus = hStatusFilter.filterValue as string;
      changedObject.headlineStatusUpdated = filterChanges.updated;
    }

    const titleFilter = filterChanges.changes.find((f) => f.filterType === FilterTypes.Title);

    if (titleFilter) {
      changedObject.title = titleFilter.filterValue as string;
    }

    const descFilter = filterChanges.changes.find((f) => f.filterType === FilterTypes.Description);

    if (descFilter) {
      changedObject.description = descFilter.filterValue as string;
    }

    changedObject.filters = FilterUtilities.ApplyFilterActions(changedObject.filters, filterChanges.changes);
    if (filterChanges.updated) {
      changedObject.updated = filterChanges.updated as string;
    }
  }

  /**
   * Check if added changed filters are part of currently selected filters from header
   * @param {FilterViewModel[]} changes  changed filters
   * @returns returns true if there are common filters found. If so item will be added to the list.
   */
  private hasMatchingFiltersWithHeaderFilters(changes: FilterViewModel[]): boolean {
    let addItem = false;
    const filters = this.filters.filter(
      (f) => f.filterValue && f.filterValue.toString() !== '0' && f.filterType !== FilterTypes.Date
    );

    filters.forEach((filter) => {
      if (!filter.exclude && !addItem) {
        const matching = changes.find(
          (f) =>
            f.filterType === filter.filterType &&
            f.filterValue.toString() === filter.filterValue.toString() &&
            (f.filterAction === FilterActionTypes.Add || f.filterAction === FilterActionTypes.Update)
        );

        addItem = matching !== undefined;
      }
    });

    return addItem;
  }
}
