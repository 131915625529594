<ng-container *ngFor="let visFilterType of visibleLozenges; trackBy: trackBy">
  <app-card-lozenge
    [canEdit]="canEdit"
    [objectType]="objectType"
    [allAppliedFilters]="filters"
    [filters]="GetVisibleFiltersByFilterType(visFilterType)"
    [filterTypeSelector]="getFilterTypeSelectorViewModelByFilterType(visFilterType.filterType)"
    [comparatorDate]="comparatorDate"
    (taskRAGBreakdownClicked)="onTaskRAGBreakdownClicked($event)"
    (riskRAGBreakdownClicked)="onRiskRAGBreakdownClicked($event)"
    (lozengeUpdates)="onLozengeUpdated($event,visFilterType.filterType)"
    (lozengeClicked)="handleLozengeClick($event)"
    (lozengeHover)="handleLozengeHover($event)"
    *ngIf="showLozenge(visFilterType.filterType, visFilterType.dateProperty)"
  >
  </app-card-lozenge>
</ng-container>
