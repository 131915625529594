import { RAGStatuses } from 'src/app/modules/shared/enums/ragStatuses';
import { RAGBreakdown } from 'src/app/modules/shared/models/rag/ragBreakdown';
import { ProjectPrivacyStatuses } from 'src/app/modules/shared/enums/projectPrivacyStatuses';
import { ProjectStatuses } from 'src/app/modules/shared/enums/projectStatuses';
import { ModifiableEntityViewModel } from 'src/app/modules/incidents/viewModels/modifiableEntityViewModel';

export class ProjectDetailsViewModel extends ModifiableEntityViewModel {
  expectedDueDate: string;
  refCode: string;
  description: string;
  departmentId: number;
  privacyStatus: ProjectPrivacyStatuses;
  status: ProjectStatuses;
  rag: RAGStatuses;
  ragBreakdown: RAGBreakdown[];
  completion: string;
  start: string;
  due: string;
  baselineStart: string;
  baselineDue: string;
  latitude: number;
  longitude: number;
  locationDetails: string;
  defaultTaskGroupId: number;
  headlineStatus: string;
  headlineStatusUpdated: string;
}
