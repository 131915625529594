import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  OnDestroy,
  Output,
  EventEmitter,
  HostListener,
  AfterViewInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DropdownEventsEmitter } from '../events/dropdown.events';
import { isChildOf } from 'src/app/modules/shared/utilities/html.utilities';
import { Router, NavigationStart } from '@angular/router';
import { WindowEventsEmitter } from '../events/window.events';
import { DropdownDimensions } from '../models/dropdownDimensions';

@Directive({
  selector: '[app-dropdown]',
  host: {
    '(click)': 'onClick($event)',
  },
  exportAs: 'app-dropdown',
})
export class DropdownDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input('app-dropdown') dropdown: { template: TemplateRef<HTMLElement>; closeUponSelection: boolean };

  @Output() dropdownHidden: EventEmitter<boolean> = new EventEmitter();

  visible: boolean = false;
  handler: () => void = () => {
    if (this.visible) {
      this.repositionDropdown(this.elementRef.nativeElement);
    }
  };
  subscriptions = new Subscription();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly dropdownEventsEmitter: DropdownEventsEmitter,
    private readonly windowEventsEmitter: WindowEventsEmitter,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.windowEventsEmitter.windowClickEventTriggered$.pipe(filter(() => this.visible)).subscribe((e) => {
        const { target } = e;
        const { dropdownComponentRef } = this.dropdownEventsEmitter;
        const {
          wrapper: { nativeElement },
        } = dropdownComponentRef;

        const isWithinDropdown: boolean = target === nativeElement || isChildOf(nativeElement, target as HTMLElement);
        const isWithinElementRef: boolean =
          target === this.elementRef.nativeElement || isChildOf(this.elementRef.nativeElement, target as HTMLElement);

        if (!isWithinDropdown && !isWithinElementRef) {
          this.hide();
        }

        if (isWithinDropdown && this.dropdown.closeUponSelection) {
          this.hide();
        }
      })
    );

    this.subscriptions.add(
      this.dropdownEventsEmitter.visibilityChanged$
        .pipe(filter((directive) => directive !== this && this.visible))
        .subscribe(() => {
          this.hide();
        })
    );

    this.subscriptions.add(
      this.router.events.pipe(filter((e) => e instanceof NavigationStart && this.visible)).subscribe(() => {
        this.hide();
      })
    );

    this.subscriptions.add(
      this.dropdownEventsEmitter.dropdownPositionChanged$.pipe(filter(() => this.visible)).subscribe(() => {
        this.repositionDropdown(this.elementRef.nativeElement);
      })
    );
  }

  ngAfterViewInit() {
    this.attachDetachScrollEventListeners(true);
  }

  ngOnDestroy() {
    this.attachDetachScrollEventListeners(false);
    this.hide();
    this.subscriptions.unsubscribe();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (this.visible) {
      this.hide();
    }
  }

  onClick(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    const { nativeElement } = this.elementRef;

    this.visible = !this.visible;

    this.repositionDropdown(nativeElement);

    if (this.visible) {
      this.dropdownEventsEmitter.broadcastDropdownVisibilityChanged(this);
    }

    const { dropdownComponentRef } = this.dropdownEventsEmitter;

    dropdownComponentRef.dropdownItemsTemplate = this.dropdown.template;
    dropdownComponentRef.visible = this.visible;
    dropdownComponentRef.changeDetectorRef.detectChanges();
  }

  private repositionDropdown(element: HTMLElement) {
    const boundingRectangle = element.getBoundingClientRect();

    const left = boundingRectangle.left;
    const bottom = boundingRectangle.top + boundingRectangle.height;

    this.dropdownEventsEmitter.broadcastDropdownDimensionsChanged(new DropdownDimensions(left, bottom, boundingRectangle.width));
  }

  private hide() {
    this.visible = false;
    this.elementRef.nativeElement.style.marginBottom = null;

    this.dropdownEventsEmitter.dropdownComponentRef.visible = false;
    this.dropdownEventsEmitter.dropdownComponentRef.changeDetectorRef.detectChanges();
    this.dropdownHidden.emit(true);
  }

  private attachDetachScrollEventListeners(attach: boolean) {
    let element = this.elementRef.nativeElement;

    while (element) {
      if (attach) {
        element.addEventListener('scroll', this.handler);
      } else {
        element.removeEventListener('scroll', this.handler);
      }

      element = element.parentElement;
    }
  }
}
