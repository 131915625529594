<div class="linked-items-details-list-wrapper">
  <div class="header-buttons">
    <ng-container *forScreen="'desktop'">
      <app-button-rectangle
        *ngIf="canAddLinkedItems"
        [text]="T.common.linked_item.one | translate"
        icon="add"
        buttonTheme="white"
        (clicked)="addLinkedItem()"
        class="mr-8"
      ></app-button-rectangle>
    </ng-container>
    <ng-container *forScreen="'mobile'">
      <app-button-icon-square
        *ngIf="canAddLinkedItems"
        icon="add"
        buttonTheme="white"
        (clicked)="addLinkedItem()"
        class="mr-8"
      ></app-button-icon-square>
    </ng-container>
    <app-toggle-button
      [objects]="toggleButtonOptions"
      [bindProperty]="'value'"
      [currentlySelectedObject]="selectedToggleTab"
      (clicked)="onToggleSwitch($event)"
    ></app-toggle-button>
  </div>
  <div class="main-linked-list-wrapper">
    @if (!loading) {
      <app-linked-items-list
        [loading]="loading"
        [linkedItems]="currentlySelectedItems"
        [showMoreOptions]="true"
        [showCheckbox]="false"
        (onLinkRemoved)="removeLink($event)">
      </app-linked-items-list>
    }
    @else {
      <app-loading>{{ T.common.loading | translate }}...</app-loading>
    }
  </div>
</div>
