<div *ngFor="let item of items; let i = index" class="checkbox-filter" (click)="onItemSelected(item, $event)">
  <div class="row" [style.border-bottom]="i === items.length - 1 ? 'none' : '1px solid #ccc'">
    <div class="col-9 description-wrapper">
      <div
        *ngIf="item.color && !item.icon"
        [ngClass]="item.color + ' color-wrapper'"
        [style.background-color]="item.color"
        [class.is-rag]="isRAG(item.color)"
      ></div>
      <div *ngIf="item.icon && item.color" class="icon-wrapper">
        <div class="responsive-flex-wrapper" [style.background-color]="item.color">
          <i class="material-symbols-outlined">{{ item.icon }}</i>
        </div>
      </div>
      <div *ngIf="item.icon && !item.color" class="icon-wrapper">
        <div class="responsive-flex-wrapper">
          <img [src]="item.icon" />
        </div>
      </div>
      <span [style.width]="item.color ? 'calc(100% - 42px)' : ''">{{
        item[itemsBindProperty] | replaceUnderscores
      }}</span>
    </div>
    <div class="col-3">
      <div class="responsive-flex-wrapper">
        <label class="check-container">
          <input type="checkbox" [checked]="isSelected(item)" />
          <span class="custom-radio-label checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</div>
