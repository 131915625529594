import { RAGStatuses } from 'src/app/modules/shared/enums/ragStatuses';
import { RAGBreakdown } from 'src/app/modules/shared/models/rag/ragBreakdown';
import { ModifiableEntityViewModel } from 'src/app/modules/incidents/viewModels/modifiableEntityViewModel';

export class TaskDetailsViewModel extends ModifiableEntityViewModel {
  refCode: string;
  milestoneType: number;
  start: string;
  due: string;
  taskGroupId: number;
  description: string;
  priority: number;
  parentId: number;
  rag: RAGStatuses;
  percentageComplete: number;
  ragBreakdown: RAGBreakdown[];
  baselineStart: string;
  baselineDue: string;
  includeTime: boolean;
  latitude: number;
  longitude: number;
  locationDetails: string;
  accountId:number;
  headlineStatus: string;
  headlineStatusUpdated: string;
}
