import { Component, Input, OnDestroy, Output, EventEmitter, OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { ImageFileChooserModalComponent } from 'src/app/modules/settings/components/common/modals/image-file-chooser-modal/image-file-chooser-modal.component';
import { AlertService } from '../../../services/alert.service';

@Component({
  selector: 'app-editable-logo',
  templateUrl: './editable-logo.component.html',
  styleUrls: ['./editable-logo.component.scss'],
})
export class EditableLogoComponent implements OnInit, OnDestroy {
  @Input() accountId: number;
  @Input() imageUrl: string;
  @Input() aspectRatio = '';
  @Input() maintainAspectRatio = false;
  @Input() roundCropper = false;
  @Input() isAccountLogo = false;
  @Input() canEdit: boolean = false;
  @Output() save = new EventEmitter<string>();

  private bsModalRef: BsModalRef;
  private subscriptions: Subscription[] = [];

  public loading = false;

  constructor(
    private readonly bsModalService: BsModalService,
    private readonly urlService: UrlService,
    private alertService: AlertService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loading = false;
    this.subscriptions = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  openFileChooserModal() {
    if (this.canEdit) {
      const modalConfig: ModalOptions<ImageFileChooserModalComponent> = {
        backdrop: true,
        ignoreBackdropClick: true,
        animated: true,
        initialState: {
          headerTitle: 'Choose image to upload...',
          imageURL: this.imageLink,
          maintainAspectRatio: this.maintainAspectRatio,
          aspectRatio: this.aspectRatio,
          isAccountLogo: this.isAccountLogo,
        },
      };
      this.bsModalRef = this.bsModalService.show(ImageFileChooserModalComponent, modalConfig);
      const modalContent = this.bsModalRef.content as ImageFileChooserModalComponent;
      this.subscriptions.push(
        modalContent.onImageUploaded.subscribe((res: string) => {
          this.imageUrl = res;
          this.changeDetectorRef.detectChanges();
          void this.alertService.success('The image was succesfully uploaded');
          this.bsModalRef.hide();

          this.save.emit(this.imageUrl);
        })
      );
    }
  }

  get imageLink(): string {
    if (this.imageUrl) {
      if (this.imageUrl.startsWith('http')) {
        return this.imageUrl;
      } else if (this.isAccountLogo) {
        return this.imageUrl.startsWith('/Resource')
          ? this.urlService.buildResourceUrl(this.imageUrl)
          : this.urlService.buildResourceUrl(`/Resource/assets/clients/logo/${this.imageUrl}`);
      } else {
        return this.imageUrl.startsWith('/Resource')
          ? this.urlService.buildResourceUrl(this.imageUrl)
          : this.urlService.buildResourceUrl(`/Resource/library/data/${this.imageUrl}`);
      }
    }
    return '';
  }
}
