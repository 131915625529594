import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, TemplateRef } from '@angular/core';
import { Constants } from 'src/app/modules/shared/models/constants';

@Component({
  selector: 'app-details-list-view',
  templateUrl: './details-list-view.component.html',
  styleUrls: ['./details-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsListViewComponent {
  @Input() addButtonTitle: string = 'Add';
  @Input() addButtonDisabled: boolean = false;
  @Input() listViewTemplate: TemplateRef<HTMLElement>;
  @Input() listViewTemplateContext: object;
  @Input() onAdd: Function;

  constructor(private readonly elementRef: ElementRef<HTMLElement>, private readonly changeDetectorRef: ChangeDetectorRef) {}

  get mobile(): boolean {
    return this.elementRef.nativeElement.getBoundingClientRect().width <= Constants.xs;
  }

  onButtonClicked() {
    this.onAdd();
  }

  onResized(): void {
    this.changeDetectorRef.detectChanges();
  }
}
