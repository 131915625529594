<app-modal>
  <app-modal-header [title]="headerTitle" (closed)="bsModalRef.hide()"></app-modal-header>
  <app-modal-body>
    <div class="body-wrapper" #body>
      <app-profile-image
        [inlineCropImage]="true"
        [accountId]="account.id"
        [isAccountLogo]="isAccountLogo"
        [title]="T.settings.account_and_configuration.account_logo_and_background.logo | translate"
        [aspectRatio]="aspectRatio"
        [maintainAspectRatio]="maintainAspectRatio"
        [roundCropper]="false"
        [imageUrl]="imageURL"
        (cropImageLoaded)="onCropImageLoaded()"
      >
      </app-profile-image>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <app-button-rectangle
      *ngIf="showCancelButton"
      [text]="'Back'"
      [size]="'medium'"
      [buttonTheme]="'white'"
      (clicked)="cancelImageCropping()"
    ></app-button-rectangle>
    <app-button-rectangle
      *ngIf="!showCancelButton"
      [text]="'Close'"
      [size]="'medium'"
      [buttonTheme]="'white'"
      (clicked)="bsModalRef.hide()"
    ></app-button-rectangle>
    <app-button-rectangle
      [text]="'Save'"
      [size]="'medium'"
      [disabled]="!enableSaveButton"
      [loading]="loading"
      (clicked)="onSave()"
    ></app-button-rectangle>
  </app-modal-footer>
</app-modal>
