import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-history-loading',
  templateUrl: 'skeleton-history-loading.component.html',
  styleUrls: ['skeleton-history-loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonHistoryLoadingComponent {
  @Input() count: number = 20;
}
