import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { SecretViewModel } from '../viewModels/secret.model';

@Injectable({
  providedIn: 'root'
})
export class SecretService {

  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`WebHookSecret/`);
  }


  getSecretById(id: number): Observable<SecretViewModel> {
    return this.http.get<SecretViewModel>(`${this.actionUrl}${id}`);
  }

  getSecretsByAccountId(accountId: number): Observable<SecretViewModel[]> {
    return this.http.get<SecretViewModel[]>(`${this.actionUrl}account/${accountId}`);
  }

  createSecret(secret: SecretViewModel): Observable<SecretViewModel> {
    return this.http.post<SecretViewModel>(this.actionUrl, secret);
  }

  updateSecret(id: number, secret: SecretViewModel): Observable<SecretViewModel> {
    return this.http.put<SecretViewModel>(`${this.actionUrl}${id}`, secret);
  }

  deleteSecret(id: number): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}${id}`);
  }
}
