import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneService } from '../services/timeZone.service';

@Pipe({
  name: 'localisedTime',
})
export class LocalisedTimePipe implements PipeTransform {
  constructor(private readonly timeZoneService: TimeZoneService) {}

  transform(dateISOString: string, useUserAgentLocale: boolean = false): string {
    if (useUserAgentLocale) {
      return this.timeZoneService.localiseDateISOStringByCustomFormat(dateISOString, 'LT');
    }

    return this.timeZoneService.localiseTimeISOString(dateISOString);
  }
}
