import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { CountdownSettingsViewModel } from 'src/app/modules/settings/viewModels/countdownSettingsViewModel';
import { AccountSettingViewModel } from 'src/app/modules/shared/viewModels/accountSettingViewModel';
import { Observable, Subscription } from 'rxjs';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { AccountSettingTypes } from 'src/app/modules/shared/enums/accountSettingTypes';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { Account } from 'src/app/modules/shared/models/account';
import * as moment from 'moment';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-countdown-dashboard-settings',
  templateUrl: 'countdown-dashboard-settings.component.html',
  styleUrls: ['countdown-dashboard-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownDashboardSettingsComponent implements OnInit, OnDestroy {
  @Input() modalActionHandlerClick: Observable<void>;

  private subscriptions: Subscription[] = [];
  private readonly settingTrueState = '1';
  private readonly settingFalseState = '0';

  public loading = true;

  isAdmin = false;
  employee: Employee;
  account: Account;
  countdownSettings: CountdownSettingsViewModel;
  showCountdownTimerSetting: AccountSettingViewModel;
  showCountdownTimer = false;
  date = {
    countdownDate: new Date(),
    countdownHours: new Date(),
  };
  diffInHours: number = 0;
  public readonly T = T;

  constructor(
    private readonly accountSettingsService: AccountSettingsService,
    private readonly alertService: AlertService,
    private readonly timeZoneService: TimeZoneService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dashboardEventEmitters: DashboardEventEmitters,
    private readonly authenticationService: AuthenticationService,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }
  ngOnInit(): void {
    this.account = this.authenticationService.getCurrentAccount();
    this.employee = this.authenticationService.getCurrentEmployee();

    this.isAdmin = EmployeeUtil.IsAdmin(this.employee);

    this.getCountdownTimerSettings();

    if (this.modalActionHandlerClick) {
      this.modalActionHandlerClick.subscribe(() => {
        this.updateCountdownTimerSettings();
      });
    }
  }

  getShowCountdownTimerSetting() {
    this.subscriptions.push(
      this.accountSettingsService.getAccountSettingByType(AccountSettingTypes.Dashboard_Show_Countdown).subscribe((res) => {
        this.showCountdownTimerSetting = res;
        this.showCountdownTimer = res.value === this.settingTrueState;
      })
    );
  }

  updateShowCountdownTimerSetting() {
    this.subscriptions.push(
      this.accountSettingsService
        .updateAccountSetting(this.showCountdownTimerSetting)
        .subscribe(() => this.alertService.success(this.translateService.instant(T.dashboard.countdown_timer_updated)))
    );
  }

  onShowCountdownTimerChecked(state: boolean) {
    this.showCountdownTimer = state;
    state
      ? (this.showCountdownTimerSetting.value = this.settingTrueState)
      : (this.showCountdownTimerSetting.value = this.settingFalseState);
    this.updateShowCountdownTimerSetting();
  }

  getCountdownTimerSettings() {
    this.subscriptions.push(
      this.accountSettingsService.getCountdownTimerSettings().subscribe((res) => {
        if (!res) {
          res = new CountdownSettingsViewModel();
          res.countdownTitle = '';
          res.countdownDate = new Date().toUTCString();
        }

        this.countdownSettings = res;

        if (res.countdownDate) {
          this.setCountdownDate(res.countdownDate);
        }

        this.loading = false;
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  updateCountdownTimerSettings() {
    this.countdownSettings.countdownDate = this.getDateAsIsoString();
    this.subscriptions.push(
      this.accountSettingsService.updateCountdownTimerSettings(this.countdownSettings).subscribe(() => {
        void this.alertService.success(this.translateService.instant(T.dashboard.countdown_timer_updated));
        this.getCountdownTimerSettings();
        this.dashboardEventEmitters.broadcastCountdownSettingsChanged();
      })
    );
  }

  onCountdownDateChanged(dateAsIsoString: string) {
    this.date.countdownDate = new Date(dateAsIsoString);
    this.date = Object.assign({}, this.date);
    this.changeDetectorRef.markForCheck();
  }

  localiseDateString(dateAsString: string) {
    return this.timeZoneService.localiseDateISOString(dateAsString, true, true);
  }

  setCountdownDate(dateAsString: string) {
    const now = moment(dateAsString);
    const then = moment(new Date(this.timeZoneService.localiseDateISOStringByCustomFormat(dateAsString, 'YYYY-MM-DDTHH:mm:ss')));
    const diff = moment.duration(now.diff(then)).as('hours');
    this.diffInHours = diff && !isNaN(diff) ? diff : 0;
    this.date.countdownDate = new Date(this.timeZoneService.localiseDateISOStringToDate(dateAsString));
    this.date.countdownDate.setHours(this.date.countdownDate.getHours() - this.diffInHours);
  }

  private getDateAsIsoString() {
    const date = new Date(this.date.countdownDate);
    date.setHours(date.getHours() + this.diffInHours);
    date.setMinutes(date.getMinutes());
    return date.toJSON();
  }

  //#endregion
}
