import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _navigationStartTime: Date;
  public previousUrl: string = undefined;
  public currentUrl: string = undefined;

  public get navigationStartTime(): Date {
    return this._navigationStartTime;
  }

  constructor(private readonly router: Router) {}

  public init(): void{
    this.currentUrl = this.router.url;
    this.router.events
    .pipe(filter((event) => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    })

    // Capture the start time when a route transition begins
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(() => {
      this._navigationStartTime = new Date();
    });
  }

  public get hasPreviousUrl(): boolean {
    return this.previousUrl && this.previousUrl !== "/";
  }
}
