import { NavigationItem } from './navigationItem';

export class SideNavModel {
  selectedItem: NavigationItem;
  selectedGroupItem: NavigationItem;
  viewModeItems: NavigationItem[];
  constaintItems: NavigationItem[];
  bottomItems: NavigationItem[];
  userItem: NavigationItem;
  constructor() {
    this.viewModeItems = [];
    this.constaintItems = [];
    this.bottomItems = [];
  }
}
