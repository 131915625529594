import { ObjectTypes } from '../enums/objectTypes';

export class ItemSubscriptionViewModel {
  id: number;
  employeeId: number;
  accountId: number;
  globalObjectId: number;
  globalObjectType: ObjectTypes;
  createdById: number;
  created: string;
}
