import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Configuration } from 'src/app/app.constants';
import { DocumentViewModel } from '../../../viewModels/documents/documentViewModel';
import { DocumentTypes } from '../../../enums/documentTypes';
import { TranslateService } from '@ngx-translate/core';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { FilterTypes } from '../../../enums/filterTypes';
import * as moment from 'moment';
import { AttachmentsEventsEmitter } from '../../../events/attachments.events';

@Component({
  selector: 'app-ng-image-preview',
  templateUrl: './ngImagePreview.component.html',
  styleUrls: ['./ngImagePreview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgImagePreviewComponent implements OnInit {
  visible: boolean;
  image: string;
  object: DocumentViewModel;
  attachmentType: DocumentTypes;
  public createdDate: string;
  public user: FilterViewModel;
  private users: FilterViewModel[] = [];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly authenticationService: AuthenticationService,
    private readonly configuration: Configuration,
    protected readonly translateService: TranslateService,
    protected readonly allowedFiltersService: AllowedFiltersService,
    protected readonly attachmentsEventsEmitter: AttachmentsEventsEmitter
  ) {}

  ngOnInit() {
    this.users = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Employee);
  }

  public show(): void {
    if (this.attachmentType == DocumentTypes.Image) {
      this.visible = true;
      this.createdDate = moment(this.object.created).format('DD MMM YYYY HH:mm');
      this.user = this.users.find((x) => x.filterValue == this.object.createdById);
      this.changeDetectorRef.markForCheck();
    }
  }

  public hide(): void {
    this.visible = false;
    this.object = null;
    this.user = null;
    this.attachmentType = null;
    this.createdDate = null;
    this.changeDetectorRef.markForCheck();
  }

  get imageUrl() {
    const indexOfResoure = this.image.toLowerCase().indexOf(`/${this.configuration.ResourceFolderName.toLowerCase()}/`);
    const lastIndexOfDash = this.image.lastIndexOf('/');

    if (indexOfResoure !== -1 || lastIndexOfDash === -1) {
      return this.image;
    }

    if (lastIndexOfDash) {
      return (
        this.image.slice(0, lastIndexOfDash) +
        `/${this.configuration.ResourceFolderName}/${this.authenticationService.getCurrentAccount().id}` +
        this.image.slice(lastIndexOfDash)
      );
    }
  }

  public updateDropdownContent(object: DocumentViewModel | null, attachmentType: DocumentTypes): void {
    this.attachmentType = attachmentType;
    this.object = object;
    this.changeDetectorRef.detectChanges();
  }

  public downloadAttachment(): void {
    this.attachmentsEventsEmitter.broadcastAttachmentDownloaded(this.object);
    this.hide();
  }

  public deleteAttachment(): void {
    this.attachmentsEventsEmitter.broadcastAttachmentDeleted(this.object);
    this.hide();
  }
}
