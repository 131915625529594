import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapSidebarEventsEmitter {
  private onZonesPolygonsCreated = new Subject<Map<number, google.maps.Polygon>>();
  public onZonesPolygonsCreated$ = this.onZonesPolygonsCreated.asObservable();
  public broadcastOnZonesPolygonsCreated(event: Map<number, google.maps.Polygon>) {
    this.onZonesPolygonsCreated.next(event);
  }

  private onAreasPolygonsCreated = new Subject<Map<number, google.maps.Polygon>>();
  public onAreasPolygonsCreated$ = this.onAreasPolygonsCreated.asObservable();
  public broadcastOnAreasPolygonsCreated(event: Map<number, google.maps.Polygon>) {
    this.onAreasPolygonsCreated.next(event);
  }

  private onMyLocationAvailable = new Subject<google.maps.LatLng>();
  public onMyLocationAvailable$ = this.onMyLocationAvailable.asObservable();
  public broadcastOnMyLocationAvailable(event: google.maps.LatLng) {
    this.onMyLocationAvailable.next(event);
  }
}
