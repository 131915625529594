<app-modal>
  <app-modal-header [title]="object.title" (closed)="bsModalRef.hide()"></app-modal-header>
  <app-modal-body>
    <div class="body-wrapper" #body>
      <app-history-feed-new
        [canEdit]="userHasWriteAccess"
        [showInput]="true"
        [objectId]="object.id"
        [objectType]="objectType"
        [showHeader]="showHeader"
        [singleItemHistory]="true"
        [childHubAccountId]="childHubAccountId"
        style="width: 600px; height: 360px"
      >
      </app-history-feed-new>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <app-button-rectangle
      class="modal-close"
      [text]="T.common.close | translate"
      [size]="'large'"
      class="mr-8"
      [buttonTheme]="'white'"
      (clicked)="bsModalRef.hide()"
    ></app-button-rectangle>
    <app-button-rectangle
      *ngIf="routerLink.length"
      class="modal-details"
      [text]="T.common.details | translate"
      [size]="'large'"
      (clicked)="onDetailsButtonClicked()"
    ></app-button-rectangle>
  </app-modal-footer>
</app-modal>
