<div class="include-comment-wrapper" [ngClass]="{ 'border-bottom': !includeComment }">
  <div class="filters-include-comment-checkbox">
    <app-checkbox-with-label
      [label]="'Include Comment'"
      [intialState]="includeComment ? 'checked' : 'unchecked'"
      (clicked)="changeState()"
    ></app-checkbox-with-label>
  </div>
  <app-text-area
    *ngIf="includeComment"
    [rows]="4"
    [preventResize]="true"
    [maxLength]="200"
    [placeholder]="placeholder + '*'"
    [required]="true"
    [errorMessage]="'You must include a comment to continue'"
    [(ngModel)]="comment"
    [autofocus]="true"
    (ngModelChange)="onCommentChange()"
  ></app-text-area>
</div>
