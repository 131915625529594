<div class="summary-bar-wrapper">
  <div class="summary-bar-main">
    <div class="summary-bar-label">{{ label }}</div>
    <div class="summary-bar-message" [style.color]="messageColor">{{ message }}</div>
  </div>
  <div class="summary-bar-color" *ngIf="showRagBreakdown">
    <ng-container *ngFor="let ragBreakdown of ragBreakdowns; let i = index; trackBy: trackByMethod">
      <div
        class="rag-breakdown"
        *ngIf="calculateRelativeWidth(ragBreakdown.count) >= 1"
        [style.background-color]="ragBreakdown.colour"
        [style.width]="calculateRelativeWidth(ragBreakdown.count) + '%'"
      ></div>
    </ng-container>
  </div>
</div>
