import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

type Item = {
  [key: string]: string;
};

@Component({
  selector: 'app-checkbox-dropdown',
  styleUrls: ['./checkbox-dropdown.component.scss'],
  templateUrl: './checkbox-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxDropdownComponent {
  @Input() items: Item[] = [];
  @Input() selectedItems: Item[] = [];
  @Input() itemsBindProperty: string;
  @Input() itemsBindValue: string;
  @Input() selectedItemsBindValue: string;

  @Output() itemSelected: EventEmitter<Item> = new EventEmitter();

  ngOnChanges() {
    if (!this.selectedItems) {
      this.selectedItems = [];
    }
  }

  onItemSelected(item: Item, e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.itemSelected.emit(item);
  }

  isSelected(item: Item) {
    return !!this.selectedItems.find((i) => i[this.selectedItemsBindValue] == item[this.itemsBindValue]);
  }

  isRAG(color: string): boolean {
    return color.toLowerCase().indexOf('rag') !== -1;
  }
}
