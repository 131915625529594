<div
  class="ngdatepicker"
  [class.ngdatepicker-visible]="visible"
  [class.ngdatepicker-component]="component"
  [class.predefined-dates]="usePredefinedAvailableDates"
  [class.use-visibility]="!isInRangePicker"
>
  <div class="ngdatepicker-header">
    <div class="ngdatepicker-header-arrow" (click)="backwards($event)">
      <div class="responsive-flex-wrapper">
        <i class="material-symbols-outlined">arrow_back</i>
      </div>
    </div>
    <div class="ngdatepicker-header-middle">
      <ng-container [ngSwitch]="currentDatepickerView">
        <ng-container *ngSwitchCase="datepickerView.Days">
          <div class="ngdatepicker-header-middle-inner" (click)="toggleView($event, datepickerView.Months)">
            {{ T.calendar.months[monthString?.toLowerCase()] | translate }}
          </div>
          <div class="ngdatepicker-header-middle-inner" (click)="toggleView($event, datepickerView.Years)">
            {{ year }}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="datepickerView.Months">
          <div class="ngdatepicker-header-middle-inner" (click)="toggleView($event, datepickerView.Years)">
            {{ year }}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="datepickerView.Years">
          <div class="ngdatepicker-header-middle-inner">{{ startYear }} - {{ endYear }}</div>
        </ng-container>
      </ng-container>
    </div>
    <div class="ngdatepicker-header-arrow" (click)="forwards($event)">
      <div class="responsive-flex-wrapper">
        <i class="material-symbols-outlined">arrow_forward</i>
      </div>
    </div>
  </div>
  <div class="ngdatepicker-cells">
    <ng-container [ngSwitch]="currentDatepickerView">
      <ng-container *ngSwitchCase="datepickerView.Days">
        <div class="ngdatepicker-cells-days">
          <div class="ngdatepicker-cells-days-cell" *ngFor="let day of days">
            {{ T.calendar.days_of_week[day.toLowerCase()] | translate }}
          </div>
        </div>
        <div class="ngdatepicker-cells-dates">
          <div
            class="ngdatepicker-cells-dates-cell"
            *ngFor="let d of dates; trackBy: trackByFn"
            (click)="onDateChosen($event, d)"
            [class.ngdatepicker-cells-dates-cell-disabled]="d.disabled"
            [class.ngdatepicker-cells-dates-cell-active]="isActive(d.date)"
            [class.ngdatepicker-cells-dates-cell-today]="isToday(d.date)"
            [class.ngdatepicker-cells-dates-cell-helper]="isHelperDate(d.date)"
            [class.ngdatepicker-cells-dates-cell-focused]="isDayFocused(d.date)"
            [class.left-helper]="isLeftHelper(d.date)"
            [class.right-helper]="isRightHelper(d.date)"
            [class.ngdatepicker-cells-dates-cell-between]="isBetween(d.date)"
          >
            <div class="ngdatepicker-cells-dates-cell-inner">{{ getDate(d.date) }}</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="datepickerView.Months">
        <div class="ngdatepicker-cells-months">
          <div
            class="ngdatepicker-cells-months-cell"
            *ngFor="let month of months; let i = index"
            (click)="onMonthChosen($event, i)"
          >
            <div class="ngdatepicker-cells-months-cell-inner">
              {{ T.calendar.months[month.toLowerCase()] | translate }}
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="datepickerView.Years">
        <div class="ngdatepicker-cells-years">
          <div
            class="ngdatepicker-cells-years-cell"
            *ngFor="let year of surroundingYears"
            (click)="onYearChosen($event, year)"
          >
            <div class="ngdatepicker-cells-years-cell-inner">{{ year }}</div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="ngdatepicker-footer" *ngIf="usePredefinedAvailableDates">
    <div class="ngdatepicker-footer-arrow" (click)="navigateToPreviousPredefined($event)">
      <span class="arrow-text">{{ T.calendar.previous_date | translate }}</span>
    </div>
    <div class="ngdatepicker-footer-arrow" (click)="navigateToNextPredefined($event)">
      <span class="arrow-text">{{ T.calendar.next_date | translate }}</span>
    </div>
  </div>
</div>
