import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
@Component({
  selector: 'app-filters-entries-information',
  templateUrl: './filters-entries-information.component.html',
  styleUrls: ['./filters-entries-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersEntriesInformationComponent {
  @Input() entriesCount: number;
  @Input() isMultiSelect: boolean;
  @Input() selectedEntriesCount: number;
  @Input() matchedEntriesCount: number;
  @Input() areAllSelected: boolean;

  @Output() selectAll = new EventEmitter<boolean>();
  @Output() clearAll = new EventEmitter<void>();

  public readonly selectAllLabel: string = this.translateService.instant(T.common.select_all);
  public readonly clearAllLabel: string = this.translateService.instant(T.common.clear_all);
  public readonly T = T;

  constructor(private readonly translateService: TranslateService) {}

  public onSelectAll(): void {
    if (!this.areAllSelected) {
      this.selectAll.emit();
    }
  }

  public onClearAll(): void {
    if (this.areAllSelected) {
      this.clearAll.emit();
    }
  }

  get entriesCountItemsText(): string {
    const translateKey = this.entriesCount === 0 || this.entriesCount > 1 ? T.common.count_items.many : T.common.count_items.one;
    return this.translateService.instant(translateKey, { count: this.entriesCount });
  }
  get matchedEntriesCountText(): string {
    const translateKey =
      this.matchedEntriesCount === 0 || this.matchedEntriesCount > 1 ? T.common.count_matches.many : T.common.count_matches.one;
    return this.translateService.instant(translateKey, { count: this.matchedEntriesCount });
  }
  get selectedEntriesCountOfTotalText(): string {
    const translateKey =
      this.selectedEntriesCount === 0 || this.selectedEntriesCount > 1
        ? T.common.count_selected.many
        : T.common.count_selected.one;
    return this.translateService.instant(translateKey, { count: this.selectedEntriesCount });
  }
}
