import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Configuration } from 'src/app/app.constants';
import { Observable } from 'rxjs';
import { Employee } from '../models/employee';
import { ChecklistItemTemplateViewModel } from '../models/checklists/checklistItemTemplateViewModel';

@Injectable({
  providedIn: 'root',
})
export class ChecklistItemTemplateService {
  private actionUrl: string = '';
  private employee: Employee;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.actionUrl = this.configuration.buildEndpoint(`ChecklistItemTemplate/`);
  }

  add(chechlistTemplateViewModel: ChecklistItemTemplateViewModel): Observable<ChecklistItemTemplateViewModel> {
    return this.http.post<ChecklistItemTemplateViewModel>(
      this.actionUrl + `Add`,
      chechlistTemplateViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  update(chechlistTemplateViewModel: ChecklistItemTemplateViewModel): Observable<ChecklistItemTemplateViewModel> {
    return this.http.put<ChecklistItemTemplateViewModel>(
      this.actionUrl + `Update`,
      chechlistTemplateViewModel,
      this.authenticationService.getHttpOptions()
    );
  }

  updateItems(chechlistTemplateViewModels: ChecklistItemTemplateViewModel[]): Observable<ChecklistItemTemplateViewModel[]> {
    return this.http.put<ChecklistItemTemplateViewModel[]>(
      this.actionUrl + `UpdateList`,
      chechlistTemplateViewModels,
      this.authenticationService.getHttpOptions()
    );
  }

  delete(checklistTemplateId: number): Observable<ChecklistItemTemplateViewModel> {
    return this.http.delete<ChecklistItemTemplateViewModel>(
      this.actionUrl + `Delete/${checklistTemplateId}`,
      this.authenticationService.getHttpOptions()
    );
  }

  getList(): Observable<ChecklistItemTemplateViewModel[]> {
    return this.http.get<ChecklistItemTemplateViewModel[]>(
      this.actionUrl + `GetList`,
      this.authenticationService.getHttpOptions()
    );
  }
}
