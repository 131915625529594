import { W3WAutocompleteOptions } from './W3WAutocompleteOptions';

export class W3WAutocomplete {
  private static readonly WHAT3WORDS_AUTOSUGGEST_URL: string =
    'https://api.what3words.com/v3/autosuggest?key={_key}&input={_input}';

  private inputField: HTMLInputElement;
  private opts: W3WAutocompleteOptions;

  private resultClickedFunc: Function;

  constructor(inputField: HTMLInputElement, opts: W3WAutocompleteOptions) {
    this.inputField = inputField;
    this.opts = opts;

    this.initAutoComplete();
  }

  public onResultClicked(func: Function): void {
    this.resultClickedFunc = func;
  }

  private initAutoComplete(): void {
    let dropDownDisplayed: boolean = false;
    let previousInputValue = '';

    const resultDropDown = document.createElement('div');
    resultDropDown.className = 'w3w-autosuggest-dropdown';
    resultDropDown.style.display = 'none';

    this.inputField.parentElement.appendChild(resultDropDown);
    this.inputField.addEventListener('keyup', (event: KeyboardEvent) => {
      const currentInputValue = this.inputField.value.trim();
      if (currentInputValue !== previousInputValue) {
        previousInputValue = currentInputValue;

        const word = this.inputField.value.trim();
        const tokens = word.split('.');
        if (tokens.length === 3) {
          if (tokens.every((el) => el.length > 0)) {
            void this.getWhat3WordsAutoSuggest(word).then((response: any) => {
              const suggestions = response.suggestions;
              if (suggestions.length > 0) {
                resultDropDown.innerHTML = '';
              }

              suggestions.forEach((suggestion, index) => {
                const words: string = suggestion.words;
                const countryISO: string = suggestion.country;
                const nearestPlace: string = suggestion.nearestPlace;

                if (!dropDownDisplayed) {
                  resultDropDown.style.display = 'block';
                  dropDownDisplayed = true;
                }

                const resultWrapper = document.createElement('a');
                resultWrapper.className = 'result-container';
                resultWrapper.addEventListener('click', (event) => {
                  if (this.resultClickedFunc) {
                    resultDropDown.style.display = 'none';
                    dropDownDisplayed = false;
                    this.resultClickedFunc(suggestion);
                    event.stopPropagation();
                  }
                });

                const wordsWrapper = document.createElement('div');
                wordsWrapper.className = 'words-wrapper';

                const spanA = document.createElement('span');
                spanA.className = 'words-prefix';
                spanA.innerText = '///';

                const wordsA = document.createElement('span');
                wordsA.className = 'words';
                wordsA.innerText = words;

                wordsWrapper.appendChild(spanA);
                wordsWrapper.appendChild(wordsA);

                const metaWrapper = document.createElement('div');
                metaWrapper.className = 'additional-info-wrapper';

                const flagWrapper = document.createElement('div');
                flagWrapper.setAttribute('class', `flag flag-${countryISO.toLowerCase()}`);

                const nearestPlaceWrapper = document.createElement('div');
                nearestPlaceWrapper.className = 'nearest-place';
                nearestPlaceWrapper.innerText = nearestPlace;

                metaWrapper.appendChild(flagWrapper);
                metaWrapper.appendChild(nearestPlaceWrapper);

                resultWrapper.appendChild(wordsWrapper);
                resultWrapper.appendChild(metaWrapper);

                resultDropDown.appendChild(resultWrapper);
              });
            });
          }
        } else {
          resultDropDown.innerHTML = '';
        }
      }
    });

    document.addEventListener('click', (event) => {
      resultDropDown.style.display = 'none';
      dropDownDisplayed = false;
    });
  }

  private async getWhat3WordsAutoSuggest(input: string): Promise<any> {
    const url = W3WAutocomplete.WHAT3WORDS_AUTOSUGGEST_URL.replace('{_key}', this.opts.apiKey).replace('{_input}', input);

    const response = await fetch(url, {});
    if (response.status === 200) {
      const json = await response.json();
      return Promise.resolve(json);
    } else {
      return Promise.reject({
        status: response.status,
      });
    }
  }
}
