import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Constants } from 'src/app/modules/shared/models/constants';

@Component({
  selector: 'app-cancel-drawing-polygon-button',
  templateUrl: './cancel-drawing-polygon-button.component.html',
  styleUrls: ['./cancel-drawing-polygon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CancelDrawingPolygonButtonComponent implements OnInit {
  @Output() clickedEvent = new EventEmitter<void>();

  //Input
  public onClicked: Function;

  public isMobile = false;
  public hoverTitle = 'Click to remove the drawed zone';

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < Constants.xs;
  }

  onClick(_$event: MouseEvent) {
    if (this.onClicked && typeof this.onClicked === 'function') {
      this.onClicked();
    }

    this.clickedEvent.emit();
  }

}
