import { Injectable } from "@angular/core";
import { StateService } from "../../planning/services/state.service";
import { IApiService } from "../../shared/interfaces/iHttpService";
import { HttpClient } from "@angular/common/http";
import { Configuration } from "src/app/app.constants";
import { AuthenticationService } from "../../shared/services/authentication.service";
import { LightFilterUpdateModel } from "../../shared/models/filter/LightFilterUpdateModel";
import { FilterViewModel } from "../../shared/models/filter/filterViewModel";
import { Observable, map } from "rxjs";
import { ModifiableEntityViewModel } from "../../incidents/viewModels/modifiableEntityViewModel";
import { ObjectTypes } from "../../shared/enums/objectTypes";
import { RiskProfileListItemViewModel } from "../models/RiskProfileListItemViewModel";
import { RiskProfileViewModel } from "../models/RiskProfileViewModel";
import { ValidatedViewModel } from "../../shared/viewModels/validatedViewModel";
import { RiskProfileAnswerViewModel } from "../models/riskProfileAnswerViewModel";
import { RiskProfileQuestionViewModel } from '../models/riskProfileQuestionViewModel';
import { RiskProfilerAnswerScoreViewModel } from "../../shared/viewModels/riskProfiler/riskProfilerAnswerScoreViewModel";
import { RiskProfilerAcceptabilityViewModel } from "../../shared/viewModels/riskProfiler/riskProfilerAcceptabilityViewModel";

@Injectable({
  providedIn: 'root',
})
export class RiskProfileService extends StateService<RiskProfileListItemViewModel> implements IApiService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private readonly authenticationService: AuthenticationService,

    ) {
    super();
    this.actionUrl = this.configuration.buildEndpoint(`riskProfile/`);
  }

  updateLight(item: LightFilterUpdateModel) {
    throw new Error('Method not implemented.');
  }

  list(filters: FilterViewModel[]): Observable<RiskProfileViewModel[]> {
    return this.getList(filters);
  }

  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly?: boolean
  ): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  add(riskProfile: RiskProfileViewModel): Observable<ValidatedViewModel> {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}Add`, riskProfile);
  }

  addFromMasterIds(profileId: number, masterQuestionIds: number[]) {
    return this.http.post<RiskProfileQuestionViewModel[]>(`${this.actionUrl}AddQuestionsIds/${profileId}`, masterQuestionIds);
  }

  getList(
    filters: FilterViewModel[],
    showPinnedOnly: boolean = false,
    customSortFilter: number = 0
  ): Observable<RiskProfileViewModel[]> {
    return this.http.post<RiskProfileViewModel[]>(
      `${this.actionUrl}List/${showPinnedOnly}/${customSortFilter}`,
      filters
    );
  }


  details(profileId: number): Observable<RiskProfileViewModel> {
    return this.http.get<RiskProfileViewModel>(`${this.actionUrl}Details/${profileId}`);
  }

  update(riskProfile: RiskProfileViewModel) {
    return this.http.put<RiskProfileViewModel>(`${this.actionUrl}Update`, riskProfile);
  }

  approve(riskProfile: RiskProfileViewModel) {
    return this.http.put<RiskProfileViewModel>(`${this.actionUrl}Approve`, riskProfile);
  }

  updateLozenges(riskProfile: RiskProfileViewModel) {
    return this.http.post(`${this.actionUrl}UpdateLozenges`, riskProfile);
  }

  delete(profileId: number) {
    return this.http.delete(`${this.actionUrl}Delete/${profileId}`);
  }

  archive(profileId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${profileId}/${archive}`, null).pipe(
      map((x) => {
        this.deleteFromList(profileId);
        return x;
      })
    );
  }

  updateAnswer(answer: RiskProfileAnswerViewModel) {
    return this.http.put(`${this.actionUrl}UpdateAnswer`, answer);
  }

  deleteQuestion(questionId: number) {
    return this.http.delete(`${this.actionUrl}DeleteQuestion/${questionId}`);
  }

  duplicate(originalProfileId: number, filters: FilterViewModel[]) {
    return this.http.post<RiskProfileViewModel>(`${this.actionUrl}DuplicateProfile/${originalProfileId}`, filters);
  }

  calculateProfileAcceptabilityAndScore(
    questions: RiskProfileQuestionViewModel[],
    scores: RiskProfilerAnswerScoreViewModel[],
    acceptabilities: RiskProfilerAcceptabilityViewModel[]
  ): {
    profileScore: number,
    profileAcceptability: RiskProfilerAcceptabilityViewModel
  } {
    let score = 0;
    const selectedAnswers: Map<number, RiskProfileAnswerViewModel> = new Map<number, RiskProfileAnswerViewModel>();

    questions.forEach(q => q.answers.find(answer => {
      if (answer.isSelected) selectedAnswers.set(q.id, q.answers.find(a => a.isSelected));
    }))

    questions.forEach(q => {
      const answer = selectedAnswers.get(q.id);
      if (answer) {
        const scoreVm = scores.find((s) => s.id === answer.riskProfilerAnswerScoreId);

        if (scoreVm) {
          score += scoreVm.score;
        }
      }
    });

    const profileScore = questions.length ? score / questions.length : 0;
    const profileAcceptability = acceptabilities.find(a => profileScore >= a.minScore && profileScore <= a.maxScore);

    return { profileScore,  profileAcceptability};
  }
}
