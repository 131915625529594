import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

interface toggleButtonObject {
  rotateIconDeg?: string;
  icon?: string;
  [x: string]: any;
}

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() objects: toggleButtonObject[];
  @Input() bindProperty: string;
  @Input() labelProperty: string;
  @Input() fillWidth: boolean = false;
  @Input() currentlySelectedObject: object;

  @Output() clicked = new EventEmitter();
  ngOnInit(): void {
    if (!this.labelProperty) {
      this.labelProperty = this.bindProperty;
    }
  }

  onClick(selectedOption: object) {
    this.clicked.emit(selectedOption);
  }
}
