import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RAGBreakdown } from 'src/app/modules/shared/models/rag/ragBreakdown';
import { RAGStatus } from '../../../models/rag/ragStatus';

@Component({
  selector: 'app-summary-bar',
  templateUrl: './summary-bar.component.html',
  styleUrls: ['./summary-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SummaryBarComponent {
  @Input() label: string;
  @Input() message: string;
  @Input() showRagBreakdown: boolean;
  @Input() ragBreakdowns: RAGBreakdown[];
  @Input() messageColor: string;

  @Output() onRAGsFiltered: EventEmitter<RAGStatus[]> = new EventEmitter<RAGStatus[]>();

  public filteredRags: RAGStatus[] = [];

  trackByMethod(index: number, el: RAGBreakdown): RAGStatus {
    return el.ragStatus;
  }

  calculateRelativeWidth(value: number): number {
    return Math.round((value / this.ragBreakdowns.reduce((prev, curr) => prev + curr.count, 0)) * 100);
  }
}
