import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-access-denied',
  templateUrl: 'access-denied.component.html',
  styleUrls: ['access-denied.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessDeniedComponent {
  @Input() header: string = 'Item not found.';
  @Input() subtext: string =
    'You might not be allowed to access this item. Please contact an account administrator to discuss your permissions.';
}
