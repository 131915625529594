import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { URLUtilities } from '../utilities/url.utilities';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WtValidators {

  constructor(private readonly translateService: TranslateService) {}

  title(minLength: number = 3, maxLenght: number = 250): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const TITLE_REGEXP = /([^\s].{1,}[^\s])/;
      const val = control.value as string;
      const isValidMinLength: boolean = val !== undefined && val !== null && val.length >= minLength;
      const isValidMaxLength: boolean = val !== undefined && val !== null && val.length <= maxLenght;
      // const isValidLength: boolean = (control.value as string).length >= minLength && (control.value as string).length <= maxLenght;
      const isValidRegexp: boolean = TITLE_REGEXP.test(control.value);

      const messages: string[] = [];
      const trKeys: string[] = [];
      if (!isValidMinLength) {
        messages.push(
          this.translateService.instant(T.common.minimum_length_is_count_symbols, {count: minLength})
          );
      }
      if (!isValidMaxLength) {
        messages.push(
          this.translateService.instant(T.common.maximum_length_is_count_symbols, {count: minLength})
          );
      }
      if (!isValidRegexp && isValidMinLength) {
        messages.push(
          this.translateService.instant(T.common.should_now_start_end_empty_space, {count: minLength})
          );
      }

      return isValidRegexp && isValidMinLength && isValidMaxLength ? null : { titleValidator: { errorMessages: messages, messageTrKeys: trKeys} };
    };
  }

  restrictedChars(chars: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasRestrictedChars: boolean = chars.some((char) => (control?.value as string)?.includes(char));
      const messages: string[] = [];

      if (hasRestrictedChars) {
        const matchedChars = chars.filter((char) => (control.value as string).includes(char));

        const stringAsHtmlElement = chars.map((c) => {
          if (matchedChars.some((mc) => mc == c)) {
            return `<span class="highlight-error-msg">${c}</span>`;
          } else {
            return c;
          }
        });

        if (hasRestrictedChars) {
          messages.push(
            this.translateService.instant(T.common.cant_contain_symbols, {symbols: stringAsHtmlElement.join(' ')})
          )
        }
      }

      return hasRestrictedChars ? { restrictedChars: { errorMessages: messages } } : null;
    };
  }

  isNumeric(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const messages: string[] = [];
      const val = control.value as string;
      const isNotNumeric = isNaN(Number(val));

      if (isNotNumeric) {
        messages.push(
          this.translateService.instant(T.common.value_must_be_numeric)
          );
      }

      return !isNotNumeric ? null : { titleValidator: { errorMessages: messages } };
    };
  }

  // Check Empty space no mather where in the form
  emptySpace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const hasEmptySpace: boolean = (control?.value as string)?.includes(' ');
      const messages: string[] = [];
      if (hasEmptySpace) {
        messages.push(
          this.translateService.instant(T.common.cant_contain_empty_space)
          );
      }

      return hasEmptySpace ? { restrictedChars: { errorMessages: messages } } : null;
    };
  }

  validURL(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValidRegexp: boolean;

      if (control.value) {
        isValidRegexp = URLUtilities.isValidUrl(control.value);
        const messages: string[] = [];
        if (control.value?.length > 0 && !isValidRegexp) messages.push(this.translateService.instant(T.common.invalid_url));

        return isValidRegexp ? null : { titleValidator: { errorMessages: messages } };
      }

      return;
    };
  }
}
