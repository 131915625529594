import { Injectable } from '@angular/core';
import { Configuration } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { ValidatedViewModel } from '../../shared/viewModels/validatedViewModel';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { StateService } from '../../planning/services/state.service';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { IndicatorUpdateViewModel } from '../viewModels/indicatorUpdateViewModel';
import { IndicatorChartViewModel } from '../viewModels/indicatorChartViewModel';
import { IndicatorViewModel } from '../viewModels/indicatorViewModel';
import { AuthenticationService } from '../../shared/services/authentication.service';
import { IApiService } from '../../shared/interfaces/iHttpService';
import { ModifiableEntityViewModel } from '../../incidents/viewModels/modifiableEntityViewModel';
import { ObjectTypes } from '../../shared/enums/objectTypes';
import { LightFilterUpdateModel } from '../../shared/models/filter/LightFilterUpdateModel';
import { IndicatorEmissionsCalculationModalViewModel } from '../viewModels/indicatorEmissionsCalculationModalViewModel';
import { SuggestedIndicatorDetailsViewModel } from '../models/suggested-indicator.models';

@Injectable({
  providedIn: 'root',
})
export class IndicatorService extends StateService<IndicatorViewModel> implements IApiService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private configuration: Configuration
  ) {
    super();
    this.actionUrl = this.configuration.buildEndpoint(`Sustainability/Indicator/`);
  }

  add(indicator: IndicatorViewModel) {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}Add`, indicator).pipe(
      map((x) => {
        this.addToList(x.returnModel as IndicatorViewModel);
        return x;
      })
    );
  }

  addSuggestedIndicators(suggestedIndicators: SuggestedIndicatorDetailsViewModel[]) {
    return this.http.post<ValidatedViewModel[]>(`${this.actionUrl}AddSuggestedIndicators`, suggestedIndicators).pipe(
      mergeMap((res: ValidatedViewModel[]) => {
        return from(res).pipe(
          map((x: ValidatedViewModel) => {
            const indicator = x.returnModel as IndicatorViewModel;
            this.addToList(indicator);
            return indicator;
          })
        );
      }),
      toArray()
    );
  }

  list(filters: FilterViewModel[]): Observable<IndicatorViewModel[]> {
    return this.http.post<IndicatorViewModel[]>(this.actionUrl + 'List', filters).pipe(
      map((x) => {
        {
          if (!filters.length) this.updateList(x);
          return x;
        }
      })
    );
  }

  getAvailableIndicatorUpdates(indicator: IndicatorViewModel): Observable<IndicatorUpdateViewModel[]> {
    return this.http.post<IndicatorUpdateViewModel[]>(`${this.actionUrl}AvailableIndicatorUpdates`, indicator);
  }

  details(indicatorId: number): Observable<IndicatorViewModel> {
    return this.http.get<IndicatorViewModel>(`${this.actionUrl}Details/${indicatorId}`);
  }

  update(indicator: IndicatorViewModel): Observable<IndicatorViewModel> {
    return this.http.post<IndicatorViewModel>(`${this.actionUrl}Update`, indicator).pipe(
      map((x) => {
        this.updateInList(x as any);
        return x;
      })
    );
  }

  updateBaseline(indicator: IndicatorViewModel): Observable<IndicatorViewModel> {
    return this.http.post<IndicatorViewModel>(`${this.actionUrl}UpdateBaseline`, indicator).pipe(
      map((x) => {
        this.updateInList(x as any);
        return x;
      })
    );
  }

  updateUnit(indicator: IndicatorViewModel, isDefault: boolean) : Observable<IndicatorViewModel> {
    return this.http.post<IndicatorViewModel>(`${this.actionUrl}UpdateUnit/${isDefault}`, indicator).pipe(
      map((x) => {
        this.updateInList(x as any);
        return x;
      })
    );
  }

  trackCo2Emissions(indicator: IndicatorViewModel): Observable<IndicatorViewModel[]> {
    return this.http.post<IndicatorViewModel[]>(`${this.actionUrl}TrackCo2Emissions`, indicator);
  }


  getChartData(indicatorId: number): Observable<IndicatorChartViewModel> {
    return this.http.get<IndicatorChartViewModel>(`${this.actionUrl}ChartData/${indicatorId}`);
  }

  delete(indicatorId: number): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}Delete/${indicatorId}`);
  }

  archive(indicatorId: number, archive: boolean) {
    return this.http.post(`${this.actionUrl}Archive/${indicatorId}/${archive}`, null);
  }

  stopTracking(indicatorId: number): Observable<any> {
    return this.http.post<any>(`${this.actionUrl}StopTracking/${indicatorId}`, null);
  }

  getEmissionsCalculationModalInformation(indicatorId: number): Observable<IndicatorEmissionsCalculationModalViewModel> {
    return this.http.get<IndicatorEmissionsCalculationModalViewModel>(`${this.actionUrl}EmissionsCalculationModalInformation/${indicatorId}`);
  }



  // Extend racking period methods

  // Get new updates generated by extending the tracking period
  getExtendTrackingPeriodUpdates(indicator: IndicatorViewModel): Observable<IndicatorUpdateViewModel[]> {
    return this.http.post<IndicatorUpdateViewModel[]>(`${this.actionUrl}ExtendTrackingPeriodUpdates`, indicator);
  }

  // Extend the tracking period
  extendTrackingPeriod(indicator: IndicatorViewModel): Observable<IndicatorViewModel> {
    return this.http.post<IndicatorViewModel>(`${this.actionUrl}ExtendTrackingPeriod`, indicator).pipe(
      map((x) => {
        this.updateInList(x as any);
        return x;
      })
    );
  }

  // Change the tracking period
  changeTrackingPeriod(indicator: IndicatorViewModel): Observable<IndicatorViewModel> {
    return this.http.post<IndicatorViewModel>(`${this.actionUrl}ChangeTrackingPeriod`, indicator);
  }

  checkRefCodeUpdate(indicatorId: number, refCode: string) {
    return this.http.post<ValidatedViewModel>(`${this.actionUrl}CheckRefCodeUpdate/${indicatorId}/${refCode}`, null);
  }

  updateLight(item: LightFilterUpdateModel) {
    throw new Error('Method not implemented.');
  }

  pinnedList(
    objectType: ObjectTypes,
    filters: FilterViewModel[],
    showPinnedOnly?: boolean
  ): Observable<ModifiableEntityViewModel[]> {
    throw new Error('Method not implemented.');
  }

  updateLozenges(indicator: IndicatorViewModel): Observable<any> {
    return of('Must add logic in the BE');
  }
}
