<div class="dashboard-customise-layout-wrapper">
  <div class="modal-info mt-2">
    <span class="modal-info-text">
      {{ T.common.customize_your_dashboard_by_dragging | translate }}
    </span>
  </div>

  <div class="body-wrapper" cdkDropListGroup>
    <div class="dashboard-customise-layout-left">
      <div class="section-header mb-2">{{ T.common.dashboard_widgets | translate }}</div>
      <div class="helper-text mb-3">{{ T.dashboard.widgets_available_to_be_dragged | translate }}</div>

      <div
        cdkDropList
        [id]='defaultWidgetsContainerId'
        [cdkDropListData]="defaultWidgets"
        class="inactive-widgets-container"
        (cdkDropListDropped)="drop($event)">
        <div class="inactive-widget"
          *ngFor="let widget of defaultWidgets"
          cdkDrag [cdkDragData]="widget">
          <div style="margin-left: 4px;">
            {{ widget.widgetName }}
          </div>
          <i class="material-symbols-outlined handle">drag_indicator</i>
        </div>
      </div>
    </div>

    <div class="dashboard-customise-layout-right">
      <div class="widget-layout-setup">
        <div class="section-header">{{ T.dashboard.dashboard_layout | translate }}</div>
        <div class="columns-pattern">
          <app-radio-button
            [checked]="columns === 2"
            [text]="T.dashboard.two_column | translate"
            (checkedChanged)="changeColumns(2)"
          >
          </app-radio-button>
          <app-radio-button
            [checked]="columns === 3"
            [text]="T.dashboard.three_column | translate"
            (checkedChanged)="changeColumns(3)"
          >
          </app-radio-button>
        </div>
      </div>
      <ng-container *ngIf="layout.moduleType === moduleTypes.Incidents">
        <div class="summary-widget-wrapper">
          <div class="summary-widget widget">
            <div class="summary-bar-title"
              [templateRef]="summaryBarOptions"
              [closeUponSelection]="true"
              ngDropdown
            >
              {{ selectedSummaryBarWidget.widgetName }}
              <div class="expand-more-icon d-flex"><i class="material-symbols-outlined expand-icon">expand_more</i></div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="layout.moduleType === moduleTypes.Sustainability">
        <div class="summary-widget-wrapper">
          <div class="summary-widget widget">
            {{ T.dashboard.sustainability_summary | translate }}
          </div>
        </div>
      </ng-container>
      <div class="active-widgets-container" cdkScrollable>
        <div
          cdkDropList
          [id]="usedWidgetsContainerId"
          [cdkDropListData]="usedWidgets"
          class="active-wrapper"
          (cdkDropListDropped)="drop($event)">
          <div class="widget"
            *ngFor="let widget of usedWidgets"
            cdkDrag [cdkDragData]="widget">
            {{ widget.widgetName }}
            <i class="material-symbols-outlined delete-widget" (click)="onDeleteWidget(widget)">close</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #summaryBarOptions let-i="index" let-v="value">
  <div class="dropdown-options-container">
    <div class="dropdown-option" [class.summary-bar-selected]="summaryBar.id === selectedSummaryBarWidget.id" (click)="onSummaryBarChanged(summaryBar)"
    *ngFor="let summaryBar of summaryBarWidgets">{{ summaryBar.widgetName }}</div>
  </div>
</ng-template>
