import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ValidationUtilities } from 'src/app/modules/shared/utilities/validation.utilities';
import { ChecklistItemViewModel } from 'src/app/modules/shared/models/checklists/checklistItemViewModel';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { ChecklistResponseTypes } from 'src/app/modules/shared/enums/checklists/checklistEnums';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { EmployeeRoleTypes } from 'src/app/modules/shared/enums/employees/EmployeeRoleTypes';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';

@Component({
  selector: 'app-checklist-item-add-modal',
  templateUrl: 'checklist-item-add.component.html',
  styleUrls: ['checklist-item-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistItemAddModalComponent implements OnInit {
  checklistItem = new ChecklistItemViewModel();
  globalObjectId: number;
  globalObjectType: number;
  entity: string;
  viewOrder: number;
  title = 'Add Checklist Item';
  onSubmit = new EventEmitter<ChecklistItemViewModel>();
  onDelete = new EventEmitter<void>();
  filterSettings: FilterViewModel[] = [];
  filterTypes = FilterTypes;
  subHeading: string = 'This item must be completed before an incident can be closed.';
  // These props are in cases when we want to open the modal without showing one of them
  // for example in MyTrack dashboard we dont want to show Description and OwnerFilter
  public showTitle: boolean = true;
  public showDescription: boolean = true;
  public showOwnerFilter: boolean = true;
  public showStar: boolean = true;
  public showResponseTypeDD = true;
  public isFromTemplate: boolean = false;

  reponseTypeOptions: { id: number; value: string }[] = [];
  selectedResponseType: { id: number; value: string };
  isEditMode: boolean = false;
  isAdmin: boolean = false;
  employee: Employee;
  public readonly T = T;
  public translatedChecklistItem = 'Checklist Item';

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly confirmationService: ConfirmationService,
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService,
    private readonly localisationService: LocalisationService
  ) {}

  ngOnInit() {
    this.employee = this.authenticationService.getCurrentEmployee();
    this.translatedChecklistItem = this.translateService.instant(T.defaultLocalizations.checklist_item.one);
    this.title = this.translateService.instant(T.common.add_item, { item: this.translatedChecklistItem });
    this.entity = this.localisationService.localiseObjectType(this.globalObjectType);
    this.subHeading = this.translateService.instant(T.control.this_item_must_be_completed_before_item_can_be_closed, { item: this.entity });

    ChecklistResponseTypes.translatedItems(this.translateService).forEach((item) => {
      this.reponseTypeOptions.push({ id: item.key, value: item.value });
    });

    if (this.checklistItem.id && this.checklistItem.id > 0) {
      this.title = this.translateService.instant(T.common.edit_item, { item: this.translatedChecklistItem });
      this.isEditMode = true;
      this.isAdmin =
        EmployeeUtil.IsAdmin(this.employee) ||
        EmployeeUtil.hasRole(this.employee, EmployeeRoleTypes.Control_Admin)

      if (this.checklistItem.employeeId) {
        this.filterSettings.push(FilterUtilities.GenerateFilter(FilterTypes.Employee, this.checklistItem.employeeId.toString()));
      }
      this.selectedResponseType = this.reponseTypeOptions.find((r) => r.id === this.checklistItem.responseType);
      if (this.checklistItem.checklistTemplateId) {
        this.isFromTemplate = true;
      }
    } else {
      this.checklistItem.globalObjectId = this.globalObjectId;
      this.checklistItem.globalObjectType = this.globalObjectType;
      this.checklistItem.viewOrder = this.viewOrder;
      this.checklistItem.responseType = this.reponseTypeOptions[0].id;
      this.selectedResponseType = this.reponseTypeOptions[0];
    }
  }

  onSave() {
    if (this.isValid) {
      this.onSubmit.next(this.checklistItem);
      this.bsModalRef.hide();
    }
  }

  onDeleteItem() {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.common.confirm_delete_item, { item: this.translatedChecklistItem }),
      () => {
        this.onDelete.next();
        this.bsModalRef.hide();
      },
      () => {}
    );
  }

  get isValid() {
    return (
      ValidationUtilities.validateAgainstMinLength(this.checklistItem.title) &&
      this.selectedResponseType !== undefined &&
      this.selectedResponseType.id > 0
    );
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  changeStarred(state: boolean) {
    this.checklistItem.starred = state;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.onSave();
    }
  }

  onEmployeesUpdated(filterSettings: FilterViewModel[]) {
    this.filterSettings = filterSettings;
    const ownerSetting = this.filterSettings.find((s) => s.filterType === FilterTypes.Owner);
    this.checklistItem.employeeId = ownerSetting ? +ownerSetting.filterValue : undefined;
  }

  onResponseTypeSelected(responseType: { id: number; value: string }) {
    this.selectedResponseType = responseType;
    this.checklistItem.responseType = responseType.id;
    this.changeDetectorRef.detectChanges();
  }

  onResponseTypeCleared(е: any) {
    this.selectedResponseType = undefined;
    this.checklistItem.responseType = null;
    this.changeDetectorRef.detectChanges();
  }
}
