import { Injectable, OnDestroy } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { catchError, map, Observable, of, Subscription } from 'rxjs';
import { HeaderFilterTypes } from '../enums/headerFilterTypes';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { AllowedFiltersService } from './allowed-filters.service';
import { AuthenticationService } from './authentication.service';
import { HeaderFiltersService } from './header-filters.service';
import { ModuleService } from './module.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderFiltersCachingService implements OnDestroy {
  private subscriptions = new Subscription();
  private employee: Employee;
  private currentHeaderFilterType: HeaderFilterTypes;
  private keyBase = 'HeaderFilters';
  private key = this.keyBase;
  private filtersMap = new Map<HeaderFilterTypes, FilterViewModel[]>();
  get currentFilters(): FilterViewModel[] {
    return this.filtersMap.get(this.currentHeaderFilterType) ?? [];
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly moduleService: ModuleService,
    private readonly headerFiltersService: HeaderFiltersService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly storage: StorageMap
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  init(): Observable<undefined> {
    return new Observable<undefined>((subscriber) => {
      this.employee = this.authenticationService.getCurrentEmployee();

      this.currentHeaderFilterType = this.moduleService.currentHeaderFilterType;
      this.key = `${this.keyBase}_Account_${this.employee.accountId}_Employee_${this.employee.id}`;

      this.loadFilters().subscribe((storedMap) => {
        this.filtersMap = storedMap;
        this.headerFiltersService.setCurrentFilters(this.currentFilters);

        this.subscriptions.add(
          this.moduleService.headerFilterTypeChanged$.subscribe((headerFilterType) => {
            this.currentHeaderFilterType = headerFilterType;
          })
        );

        this.subscriptions.add(
          this.headerFiltersService.filtersChanged$.subscribe((filters) => {
            this.storeFilters(filters).subscribe();
          })
        );

        subscriber.next(undefined);
        subscriber.complete();
      });
    });
  }

  private loadFilters(): Observable<Map<HeaderFilterTypes, FilterViewModel[]>> {
    const emptyMap = new Map<HeaderFilterTypes, FilterViewModel[]>();

    // TODO: Validate against allowed filters.
    return this.storage.get(this.key).pipe(
      map((storedMap) => (storedMap ? (storedMap as Map<HeaderFilterTypes, FilterViewModel[]>) : emptyMap)),
      map((storedMap) => {
        if (storedMap.size === 0) {
          return storedMap;
        }

        const allowedFilterTypes = Array.from(this.allowedFiltersService.allowedFiltersByType.keys());
        const storedAllowedMap = new Map<HeaderFilterTypes, FilterViewModel[]>();

        storedMap.forEach((filters, headerFilterType) => {
          const allowedFilters = filters.reduce((acc, curr) => {
            if (allowedFilterTypes.includes(curr.filterType)) {
              acc.push(curr);
            }

            return acc;
          }, [] as FilterViewModel[]);

          if (allowedFilters.length > 0) {
            storedAllowedMap.set(headerFilterType, allowedFilters);
          }
        });

        return storedAllowedMap;
      }),
      catchError(() => of(emptyMap))
    );
  }

  private storeFilters(filters: FilterViewModel[], headerFilterType?: HeaderFilterTypes): Observable<undefined> {
    headerFilterType = headerFilterType ?? this.currentHeaderFilterType;

    if (headerFilterType !== HeaderFilterTypes.None && headerFilterType) {
      this.filtersMap.set(headerFilterType, JSON.parse(JSON.stringify(filters)));

      return this.storage.set(this.key, this.filtersMap);
    }

    return of(undefined);
  }
}
