<div class="details-list-view-wrapper" resized (resizable)="onResized()">
  <div class="details-list-view-body">
    <div class="details-list-view-left-wrapper">
      <app-button
        [text]="mobile ? '' : addButtonTitle"
        [icon]="'add'"
        [disabled]="addButtonDisabled"
        (clicked)="onButtonClicked()"
      ></app-button>
    </div>
    <ng-template [ngTemplateOutlet]="listViewTemplate" [ngTemplateOutletContext]="listViewTemplateContext">
    </ng-template>
  </div>
</div>
