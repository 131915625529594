export function generateRandomNumber(length: number) {
  let text = '';

  for (let i = 0; i < length; i++) {
    let randomNumber = 0;
    const typedArray = new Uint8Array(1);

    // Generating a non-zero number for the first digit
    if (i === 0) {
      while (randomNumber === 0) {
        const randomValueAsString = crypto.getRandomValues(typedArray)[0].toString();
        randomNumber = +randomValueAsString.slice(0, 1);
      }
    } else {
      // Generating any digit (including zero) for other positions
      const randomValueAsString = crypto.getRandomValues(typedArray)[0].toString();
      randomNumber = +randomValueAsString.slice(0, 1);
    }

    text += randomNumber.toString();
  }

  return Number(text);
}
