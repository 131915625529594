<div class="select-wrapper" (click)="onClick($event)">
  <div class="select-outline-container">
      <!-- This help the notch to be responsive to the label -->
      <div class="select-outline-start"></div>
      <div class="select-outline-notch">
        <div class="notch-spacer">{{label}}</div>
      </div>
      <div class="select-outline-end"></div>
  </div>
  <div class="label-text-wrapper">
    <div class="label-text">
      {{label}}
    </div>
  </div>
  <div class="value-wrapper"
    [app-tooltip]="{ message: value, stylingVersion: 2 }"
    [showTooltip]="!!value"
  >
    <div class="select-text">
      {{value}}
    </div>
    <div class="counter" *ngIf="counter > 1">
      {{counter}}
    </div>
  </div>
  <div class="invisible-width-adjust">{{widthSpacer}}</div>
  <i class="material-symbols-outlined dropdown-icon">expand_more</i>
</div>


