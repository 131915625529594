export class HtmlUtilities {
  public static positionDropdown(container: HTMLElement, contextMenu: HTMLElement, clickEvent: MouseEvent): void {
    if (container && contextMenu) {
      try {
        const containerRect = container.getBoundingClientRect();

        let x = clickEvent.clientX + document.body.scrollLeft + document.documentElement.scrollLeft - containerRect.left;
        let y = clickEvent.clientY + document.body.scrollTop + document.documentElement.scrollTop;

        const { width, height } = contextMenu.getBoundingClientRect();

        if(x + width > containerRect.width) {
          x -= width;
        }

        if (y + height > window.innerHeight) {
          y -= height;
        }

        contextMenu.style.visibility = 'visible';
        contextMenu.style.left = `${x}px`;
        contextMenu.style.top = `${y - containerRect.top}px`;
      } catch(err) {
        return;
      }
    }
  }
}


export const isChildOf = (parent: HTMLElement, child: HTMLElement): boolean => {
  let node: HTMLElement = child;

  while (node) {
    if (node === parent) {
      return true;
    }

    node = node.parentElement;
  }

  return false;
};
