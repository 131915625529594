<app-modal>
  <app-modal-header
    *ngIf="!loading"
    [title]="T.reports.pdf_export_report | translate"
    [showDelete]="false"
    (closed)="confirmClose()"
  ></app-modal-header>
  <app-modal-body *ngIf="!loading">
    <div class="save-report-body" [formGroup]="form">
      <div class="save-report-body-instruction">
        {{ T.reports.give_your_exported_report_title | translate }}
      </div>
      <div class="save-report-body-title">
        <app-text-field
          [autofocus]="true"
          [displayError]="triggerErrors"
          formControlName="reportTitle"
          [maxLength]="80"
          [minlength]="3"
          [placeholder]="T.defaultLocalizations.title.one | translate"
          [formGroup]="form"
        >
        </app-text-field>
      </div>
      <div class="save-report-body-description">
        <app-text-area
          [placeholder]="T.defaultLocalizations.description.one | translate"
          formControlName="reportDescription"
          [maxLength]="250"
          [minlength]="3"
          [formGroup]="form"
          [rows]="3"
        ></app-text-area>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer *ngIf="!loading" style="margin-top: 24px">
    <div class="save-report-footer">
      <app-button-rectangle
        [text]="T.common.cancel | translate"
        [buttonTheme]="'white'"
        [size]="'medium'"
        (clicked)="confirmClose()"
      ></app-button-rectangle>
      <app-button-rectangle
        [text]="T.common.save | translate"
        [size]="'medium'"
        (clicked)="onApply()"
      ></app-button-rectangle>
    </div>
  </app-modal-footer>
  <app-loading *ngIf="loading">
    <p>{{ T.common.loading | translate }}...</p>
  </app-loading>
</app-modal>
