import { Component, Input } from '@angular/core';
import { IconTypes } from 'src/app/modules/shared/types/iconTypes';
import { IconUtilities } from 'src/app/modules/shared/utilities/icon.utilities';
import { SustainabilityUtilities } from 'src/app/modules/sustainability/utilities/sustainability.utilities';
import { DashboardTargetViewModel } from 'src/app/modules/sustainability/viewModels/dashboard/dashboardTargetViewModel';
import { IndicatorTrendRAGBreakdownViewModel } from 'src/app/modules/sustainability/viewModels/indicatorTrendRAGBreakdownViewModel';


@Component({
  selector: 'app-card-sus-account-indicator-total',
  templateUrl: './card-sus-account-indicator-total.component.html',
  styleUrls: ['./card-sus-account-indicator-total.component.scss'],
})
export class CardSustainabilityAccountIndicatorTotalComponent  {
  @Input() cardTypeLeaderboard: any;
  @Input() cardIcon: string;
  @Input() cardIconSVG: string;
  @Input() cardTitle: string;
  @Input() cardSubTitle: string;
  @Input() cardLeftText: string;
  @Input() cardRightText: string;
  @Input() cardRightSubText: string;
  @Input() cardRightColor: string = `#939699`;
  @Input() ragBreakdown: IndicatorTrendRAGBreakdownViewModel[];
  @Input() targetBadgeBreakdown: any[];
  @Input() cardBackgroundColor: string = `var(--white)`;

  getBadgeIcon(item: DashboardTargetViewModel) {
    if (item.badge !== 0) {
      return IconUtilities.getSvgForIconType(
        IconTypes.Target_Badge,
        24,
        false,
        this.getIndicatorTargetBadgeHexColour(item.badge),
        false
      );
    } else {
      return `<svg width="34" height="34" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="4" width="40" height="40" rx="6"/>
                <path d="M33.427 14.5733L31.547 16.4533C33.467 18.3866 34.667 21.0533 34.667 24C34.667 29.8933 29.8937 34.6666 24.0003 34.6666C18.107 34.6666 13.3337 29.8933 13.3337 24C13.3337 18.56 17.4003 14.08 22.667 13.4266V16.12C18.8803 16.76 16.0003 20.04 16.0003 24C16.0003 28.4133 19.587 32 24.0003 32C28.4137 32 32.0003 28.4133 32.0003 24C32.0003 21.7866 31.107 19.7866 29.6537 18.3466L27.7737 20.2266C28.7337 21.2 29.3337 22.5333 29.3337 24C29.3337 26.9466 26.947 29.3333 24.0003 29.3333C21.0537 29.3333 18.667 26.9466 18.667 24C18.667 21.52 20.3737 19.4533 22.667 18.8533V21.7066C21.867 22.1733 21.3337 23.0133 21.3337 24C21.3337 25.4666 22.5337 26.6666 24.0003 26.6666C25.467 26.6666 26.667 25.4666 26.667 24C26.667 23.0133 26.1337 22.16 25.3337 21.7066V10.6666H24.0003C16.6403 10.6666 10.667 16.64 10.667 24C10.667 31.36 16.6403 37.3333 24.0003 37.3333C31.3603 37.3333 37.3337 31.36 37.3337 24C37.3337 20.32 35.8403 16.9866 33.427 14.5733Z" fill="#939699"/>
              </svg>`;
    }
  }

  getIndicatorTargetBadgeHexColour(badge: number) {
    return SustainabilityUtilities.getIndicatorTargetBadgeHexColour(badge);
  }

  getOrdinalNumber(i: number) {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return String(i) + 'st';
    }
    if (j === 2 && k !== 12) {
      return String(i) + 'nd';
    }
    if (j === 3 && k !== 13) {
      return String(i) + 'rd';
    }
    return String(i) + 'th';
  }
}
