import { Component, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Configuration } from 'src/app/app.constants';

@Component({
  selector: 'app-employee-filter-setting-add-modal',
  templateUrl: 'employee-filter-setting-add-modal.component.html',
  styleUrls: ['employee-filter-setting-add-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeFilterSettingAddModalComponent {
  title: string;

  constructor(public bsModalRef: BsModalRef, private readonly configuration: Configuration) {}
  onSave = new EventEmitter<string>();

  onKeyDown(event: any) {
    if (event.keyCode == 13) {
      this.onConfirm();
    }
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onConfirm() {
    this.onSave.next(this.title);
    this.onCancel();
  }
}
