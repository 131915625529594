import { ChangeDetectorRef, Component, OnDestroy, EventEmitter, OnInit, Output , ElementRef, ViewChild  } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { EditableFieldTypes } from 'src/app/modules/shared/enums/editableFieldTypes';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { OperationTypes } from 'src/app/modules/shared/enums/operationTypes';
import { Account } from 'src/app/modules/shared/models/account';
import { Employee } from 'src/app/modules/shared/models/employee';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { ThreadedBead } from 'src/app/modules/shared/models/threadedBead';
import { WtValidators } from 'src/app/modules/shared/reactiveValidators/wtValidators';
import { AlertService } from 'src/app/modules/shared/services/alert.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { CachingService } from 'src/app/modules/shared/services/caching.service';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { FilterUtilities } from 'src/app/modules/shared/utilities/filter.utilities';
import { IndicatorTemplateService } from '../../../services/sustainability/indicator-template.service';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { FilterItemTypes } from 'src/app/modules/shared/enums/filter/filterItemTypes.enum';
import { IndicatorUpdateViewModel } from 'src/app/modules/sustainability/viewModels/indicatorUpdateViewModel';
import { IndicatorTemplateViewModel } from 'src/app/modules/accountHub/viewModels/sustainability/indicator-templates/indicatorTemplateViewModel';
import { IndicatorTrackingDateTypes } from 'src/app/modules/sustainability/enums/indicatorTrackingDateTypes';
import { IndicatorUpdateTypes } from 'src/app/modules/sustainability/enums/indicatorUpdateTypes';
import { IndicatorPriorities } from 'src/app/modules/sustainability/enums/indicatorPriorities';

@Component({
  selector: 'app-indicator-template-add-modal',
  templateUrl: './indicator-template-add-modal.component.html',
  styleUrls: ['./indicator-template-add-modal.component.scss']
})
export class IndicatorTemplateAddModalComponent implements OnInit, OnDestroy {
  @ViewChild('indicator_template_priority_dropdown') indicatorTemplatePriorityDropdown: ElementRef;

  public currentSelectedPriorityFilter: FilterViewModel;
  public currentStepText: string[] = [
    this.translateService.instant(T.common.getting_started),
    this.translateService.instant(T.common.add_details),
    this.translateService.instant(T.common.assign_item, {
      item: this.translateService.instant(T.defaultLocalizations.indicator.one)
    }),
    this.translateService.instant(T.sustainability.indicator_tracking_details),
    this.translateService.instant(T.sustainability.indicator_tracking_details)
  ];

  public footerButtonText: string[] = [
    this.translateService.instant(T.common.get_started),
    this.translateService.instant(T.sustainability.go_to_assignments),
    this.translateService.instant(T.sustainability.go_to_tracking_update_types),
    this.translateService.instant(T.sustainability.create_indicator_template),
    this.translateService.instant(T.sustainability.enter_tracking_details)
  ];

  public beads: ThreadedBead[] = [
    { number: 0, active: true },
    { number: 1, active: false },
    { number: 2, active: false },
    { number: 3, active: false },
    { number: 4, active: false }
  ];

  public loading: boolean = false;
  public currentStep: number = 0;
  public indicatorTrackingPeriodFilter: FilterViewModel[] = [];
  public objectType: ObjectTypes;
  public currentAccount: Account;
  public currentEmployee: Employee;
  public indicatorUpdates: IndicatorUpdateViewModel[] = [];
  public indicatorTemplate: IndicatorTemplateViewModel = new IndicatorTemplateViewModel();
  public indicatorForm: UntypedFormGroup;
  public indicatorTemplateForm: UntypedFormGroup;
  public unitOfMeasurementFilters: FilterViewModel[];
  public unitOfMeasurementSelectedFilter: FilterViewModel;
  public expectedDirectionFilters: FilterViewModel[];
  public expectedDirectionSelectedFilter: FilterViewModel;
  public unitTypeSelectedFilter: FilterViewModel;
  public unitSelectedFilter: FilterViewModel;
  public convertionSelectedUnitFilter: FilterViewModel;

  public filterTypes = FilterTypes;
  public objectTypes = ObjectTypes;
  public indicatorTrackingDateTypes = IndicatorTrackingDateTypes;
  public indicatorUpdateTypes = IndicatorUpdateTypes;
  public editableFieldTypes = EditableFieldTypes;
  public triggerIndicatorErrors: boolean = false;
  public priorityTitles: string[] = [];
  public validationErrors: string[];
  public readonly T = T;
  public localisedDepartment = 'Department';
  public localisedDepartments = 'Departments';
  public unitTypesFilters: FilterViewModel[] = [];
  public allowedUnitFilters: FilterViewModel[] = [];
  public unitFilters: FilterViewModel[] = [];
  public isConversionDisabled = false;

  private bsModalRefIndicatorTarget: BsModalRef;
  private bsModalRefTemplateTarget: BsModalRef;
  private subscriptions: Subscription = new Subscription();
  @Output() submit: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private readonly indicatorTemplateService: IndicatorTemplateService,
    private readonly authenticationService: AuthenticationService,
    private readonly cachingService: CachingService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fb: UntypedFormBuilder,
    private readonly localisationService: LocalisationService,
    private readonly allowedFiltersService: AllowedFiltersService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly bsModalService: BsModalService,
    public readonly bsModalRef: BsModalRef,
    public readonly checklistBsModalRef: BsModalRef,
    private readonly translateService: TranslateService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.currentAccount = this.cachingService.GetAccountInfo();
    this.localisedDepartment = this.localisationService.localiseObjectType(ObjectTypes.Department);
    this.localisedDepartments = this.localisationService.localiseObjectType(ObjectTypes.Department, true);
    this.initIndicatorForm();
    this.initIndicatorTemplateForm();
    this.initPriorityFilterTitles();
    this.initIndicatorFilters();
    this.initIndicatorTemplateFilters();
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initIndicatorFilters() {
    this.indicatorTemplate.filters = [];
    this.indicatorTemplate.filters.push(
      FilterUtilities.GenerateFilter(FilterTypes.Indicator_Tracking_Date_Type, IndicatorTrackingDateTypes.Months, 'Months')
    );
    this.indicatorTemplate.trackingDateType = IndicatorTrackingDateTypes.Months;
    this.indicatorTemplate.trackingDateValue = 1;
    this.setExpectedDirectionFilters();
    this.setUnitAndUnitTypes();
  }

  private initIndicatorTemplateFilters() {
    this.indicatorTrackingPeriodFilter = [];
    this.indicatorTrackingPeriodFilter.push(
      FilterUtilities.GenerateFilter(FilterTypes.Indicator_Tracking_Date_Type, IndicatorTrackingDateTypes.Years, 'Years')
    );
  }

  public increaseStep(): void {
    this.currentStep++;
    this.updateBeads();

    if (this.currentStep === 4) {
      this.onSave();
    }
  }

  public decreaseStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.updateBeads();
    }
  }

  private updateBeads(): void {
    this.beads = this.beads.slice();
    this.beads.forEach((b) => (b.active = false));

    for (let i = 0; i <= this.currentStep; i++) {
      this.beads[i].active = true;
    }
  }

  private initPriorityFilterTitles(): void {
    const one_star = this.localisationService.localiseFilterValueByFilterType(
      IndicatorPriorities.One_Star,
      FilterTypes.Indicator_Priority
    );
    const two_stars = this.localisationService.localiseFilterValueByFilterType(
      IndicatorPriorities.Two_Stars,
      FilterTypes.Indicator_Priority
    );
    const three_stars = this.localisationService.localiseFilterValueByFilterType(
      IndicatorPriorities.Three_Stars,
      FilterTypes.Indicator_Priority
    );

    this.priorityTitles = [one_star, two_stars, three_stars];
  }

  private initIndicatorForm(): void {
    this.indicatorForm = this.fb.group({
      title: ['', { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])] }],
      description: [''],
      priority: [0, [Validators.required]],
      trackingDateValue: ['1', { validators: [Validators.required, this.wtValidators.isNumeric()] }]
    });
  }

  private initIndicatorTemplateForm(): void {
    this.indicatorTemplateForm = this.fb.group({
    });
  }

  private initSubscriptions(): void {

    this.subscriptions.add(
      this.indicatorForm.controls.trackingDateValue.valueChanges.subscribe((value) => this.updateTrackingDateValue(value))
    );
    this.subscriptions.add(this.indicatorForm.controls.title.valueChanges.subscribe((value) => this.updateIndicatorTitle(value)));
  }

  public updateTrackingDateValue(value: string): void {
    if (!isNaN(+value)) {
      this.indicatorTemplate.trackingDateValue = +value;
    }

  }


  public updateIndicatorTitle(value: string): void {
    this.indicatorTemplate.title = value;
  }


  get stepDisabled(): boolean {

    if (this.currentStep === 0) {
      return !(
        this.indicatorForm.controls.title.valid &&
        this.indicatorTemplate.indicatorCategoryId > 0 &&
        this.indicatorForm.controls.priority.valid
      );
    }

    if(this.currentStep === 2 && (this.indicatorTemplate.updateType === undefined || this.indicatorTemplate.updateType < 1)) {
      return true;
    }

    if (this.currentStep === 2 && this.indicatorTemplate.updateType === this.indicatorUpdateTypes.Numeric) {
      return this.unitTypeSelectedFilter?.filterValue === undefined;
    }

    if (this.currentStep === 3) {
      return !this.indicatorTemplate.updateType || (this.indicatorTemplate.updateType === this.indicatorUpdateTypes.Numeric && !this.indicatorTemplate.conversionUnitId);
    }

    return false;
  }


  public setExpectedDirectionFilters(): void {
    this.expectedDirectionFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Expected_Direction);
    this.expectedDirectionSelectedFilter = this.expectedDirectionFilters[0];
  }

  public setUnitAndUnitTypes(): void {
    this.allowedUnitFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Unit);
    this.unitTypesFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Unit_Type);
  }

  public updateTrackingDateType(filters: FilterViewModel[]): void {
    this.indicatorTemplate  .filters = filters;
    const indicatorTrackingDateTypeFilter: FilterViewModel = filters.find(
      (f) => f.filterType === FilterTypes.Indicator_Tracking_Date_Type
    );
    this.indicatorTemplate.trackingDateType =
      indicatorTrackingDateTypeFilter !== undefined ? +indicatorTrackingDateTypeFilter.filterValue : 0;

    this.changeDetectorRef.detectChanges();
  }

  public updateIndicatorUnit(filters: FilterViewModel[]) {
    const unitFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Unit);
    this.unitSelectedFilter = unitFilter;
    this.indicatorTemplate.defaultUnitId = unitFilter ? +unitFilter.filterValue : null;
    this.changeDetectorRef.detectChanges();
  }

  public updateConversionIndicatorUnit(filters: FilterViewModel[]) {
    const unitFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Unit);
    this.convertionSelectedUnitFilter = unitFilter;
    this.indicatorTemplate.conversionUnitId = unitFilter ? +unitFilter.filterValue : null;
    this.changeDetectorRef.detectChanges();
  }

  public updateIndicatorUnitType(filters: FilterViewModel[]) {

    if (filters.length > 0) {
      const indicatorUnitTypeFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Unit_Type);
      this.unitTypeSelectedFilter = indicatorUnitTypeFilter;

      this.unitFilters = this.allowedUnitFilters.filter((f) => f.relatedObjectId === indicatorUnitTypeFilter.filterValue);

      const defaultUnit = this.unitFilters.find(
        (f) => f.adittionalItems.findIndex((s) => +s.key === FilterItemTypes.sus_unit_default_value && s.value === 'True') > -1
      );

      const isDisabled = this.unitTypeSelectedFilter.adittionalItems.find(
        (d) => +d.key === FilterItemTypes.sus_conversionDropdownVisibilityType
      );
      this.isConversionDisabled = isDisabled === undefined ? false : isDisabled.value === '1';

      if (defaultUnit) {
        this.unitSelectedFilter = defaultUnit;
        this.convertionSelectedUnitFilter = defaultUnit;
      } else {
        this.unitSelectedFilter = this.unitFilters.length > 0 ? this.unitFilters[0] : undefined;
        this.convertionSelectedUnitFilter = this.unitFilters.length > 0 ? this.unitFilters[0] : undefined;
      }

      this.indicatorTemplate.filters = this.indicatorTemplate.filters.filter(
        (f) => f.filterType !== FilterTypes.Unit_Type && f.filterType !== FilterTypes.Unit
      );

      this.indicatorTemplate.unitTypeId = +indicatorUnitTypeFilter.filterValue;
      this.indicatorTemplate.defaultUnitId = +this.unitSelectedFilter.filterValue;
      this.indicatorTemplate.conversionUnitId = +this.convertionSelectedUnitFilter.filterValue;

    }
    else {
      this.unitTypeSelectedFilter = undefined;
      this.unitSelectedFilter = undefined;
      this.convertionSelectedUnitFilter = undefined;

      this.indicatorTemplate.unitTypeId = undefined;
      this.indicatorTemplate.defaultUnitId = undefined;
      this.indicatorTemplate.conversionUnitId = undefined;

      this.setUnitAndUnitTypes();
    }



    this.changeDetectorRef.detectChanges();
  }

  public updatedExpectedDirection(filters: FilterViewModel[]) {
    const expectedDirectionFilter: FilterViewModel = filters.find((f) => f.filterType == FilterTypes.Expected_Direction);
    this.expectedDirectionSelectedFilter = expectedDirectionFilter;

    this.changeDetectorRef.detectChanges();
  }

  public updateIndicatorUpdateType(filters: FilterViewModel[]) {
    this.indicatorTemplate.filters = filters;
    const indicatorUpdateTypeFilter: FilterViewModel = filters.find((f) => f.filterType == FilterTypes.Indicator_Update_Type);
    this.indicatorTemplate.updateType = indicatorUpdateTypeFilter != undefined ? indicatorUpdateTypeFilter.filterValue : 0;

    if (this.indicatorTemplate.updateType === IndicatorUpdateTypes.Pass_Or_Fail) {
      this.indicatorTemplate.filters = this.indicatorTemplate.filters.filter((f) => f.filterType !== FilterTypes.Unit_Type && f.filterType !== FilterTypes.Unit);
      this.indicatorTemplate.defaultUnitId = undefined;
      this.indicatorTemplate.conversionUnitId = undefined;
      this.indicatorTemplate.unitTypeId = undefined;
    }

    this.changeDetectorRef.detectChanges();
  }

  public cancel(): void {
    this.bsModalRef.hide();
  }

  public clearPriority(): void {
    this.indicatorForm.controls.priority.setValue(0);
  }

  public updateViewModel(filters: FilterViewModel[]): void {
    this.indicatorTemplate.filters = filters;

    this.changeDetectorRef.detectChanges();
  }

  public onDescriptionChange($event: string): void {
    this.indicatorForm.controls.description.setValue($event);
  }

  public updateIndicatorCategory(filters: FilterViewModel[]): void {
    this.indicatorTemplate.filters = filters;
    const indicatorCategoryFilter: FilterViewModel = filters.find((f) => f.filterType == FilterTypes.Indicator_Category);
    this.indicatorTemplate.indicatorCategoryId = indicatorCategoryFilter != undefined ? indicatorCategoryFilter.filterValue : 0;

    this.changeDetectorRef.detectChanges();
  }

    public updateIndicatorScope(filters: FilterViewModel[]): void {
    this.indicatorTemplate.filters = filters;
    const indicatorScopeFilter: FilterViewModel = filters.find((f) => f.filterType === FilterTypes.Sustainability_Scope);
    this.indicatorTemplate.sustainabilityScope = (indicatorScopeFilter !== undefined)
      ? +indicatorScopeFilter.filterValue
      : 0;
    this.changeDetectorRef.detectChanges();
  }

  public localiseString(s: string): string {
    return this.localisationService.localise(s);
  }

  public addCurrentUserDepartment() {
    const filters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Department);

    if (filters) {
      const departmentFilter = filters.find(
        (f) => f.filterType == FilterTypes.Department && f.filterValue.toString() == this.currentEmployee.departmentId.toString()
      );
      if (
        departmentFilter &&
        !this.indicatorTemplate.filters.some(
          (f) => f.filterType == FilterTypes.Department && f.filterValue == this.currentEmployee.departmentId
        )
      ) {
        this.indicatorTemplate.filters = this.indicatorTemplate.filters.slice();
        this.indicatorTemplate.filters.push(departmentFilter);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  public addCurrentUserAsOwner(): void {
    const filters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Owner);

    if (filters) {
      const empFilter = filters.find(
        (s: any) => s.filterType === FilterTypes.Owner && s.filterValue.toString() === this.currentEmployee.id.toString()
      );
      if (
        empFilter &&
        !this.indicatorTemplate.filters.some((f) => f.filterType == FilterTypes.Owner && f.filterValue == this.currentEmployee.id)
      ) {
        this.indicatorTemplate.filters = this.indicatorTemplate.filters.slice();
        this.indicatorTemplate.filters.push(empFilter);
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  onSave() {
    this.loading = true;
    if (this.indicatorTemplate.updateType === IndicatorUpdateTypes.Numeric) {
      this.indicatorTemplate.expectedDirection = this.expectedDirectionSelectedFilter.filterValue;

      this.indicatorTemplate.filters.push(
        FilterUtilities.GenerateFilter(
          FilterTypes.Expected_Direction,
          this.expectedDirectionSelectedFilter.filterValue,
          this.expectedDirectionSelectedFilter.filterText
        )
      );
    }

    const item = { ...this.indicatorTemplate, ...this.indicatorForm.value };
    item.active = true;
    if(!item.defaultUnitId)
        item.defaultUnitId = item.conversionUnitId;

      this.subscriptions.add(
        this.indicatorTemplateService.add(this.indicatorTemplate).subscribe((res) => {
          const errors = res.errorList;
          const newIndicator = res.returnModel as IndicatorTemplateViewModel;
          if (errors?.length > 0) {
            this.validationErrors = errors;
            this.loading = false;
            this.changeDetectorRef.detectChanges();
          } else {
            this.allowedFiltersService.refreshFilters(
              OperationTypes.Create,
              [{ id: newIndicator.id, title: newIndicator.title, relatedObjectId: 0 }],
              FilterTypes.Indicator_Template
            );
            this.loading = false;
            this.indicatorTemplate = res.returnModel;
            this.submit.next(true);
            void this.alertService.success(this.translateService.instant(T.sustainability.indicator_template_added));
            this.changeDetectorRef.detectChanges();
          }
        })
      );

  }


  navigateToIndicatorTemplateDetailsPage() {
    void this.router.navigate([`/v2/hub/sustainability/indicator-templates/${this.indicatorTemplate.id}`]);
    this.cancel();
  }

  addAnotherIndicator(): void {
    this.indicatorTemplate = new IndicatorTemplateViewModel();
    this.initIndicatorForm();
    this.initIndicatorTemplateForm();
    this.initIndicatorFilters();
    this.indicatorTrackingPeriodFilter = [];
    this.currentStep = 0;
    this.updateBeads();
  }

  formatFrequencyText(indicatorTrackingDateType: IndicatorTrackingDateTypes): string {
    switch (indicatorTrackingDateType) {
      case IndicatorTrackingDateTypes.Days:
        return this.translateService.instant(T.calendar.daily);
      case IndicatorTrackingDateTypes.Weeks:
        return this.translateService.instant(T.calendar.weekly);
      case IndicatorTrackingDateTypes.Months:
        return this.translateService.instant(T.calendar.monthly);
      case IndicatorTrackingDateTypes.Years:
        return this.translateService.instant(T.calendar.yearly);
    }
  }

  public onApply($event): void {
    this.currentSelectedPriorityFilter = $event;
    this.indicatorForm.patchValue({priority: this.currentSelectedPriorityFilter.filterValue})
  }
}
