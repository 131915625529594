export class LocationViewModel {
  public latitude: number;
  public longitude: number;
  public locationDetails: string;

  constructor(_latitude: number, _longitude: number, _locationDetails: string) {
    this.latitude = _latitude;
    this.longitude = _longitude;
    this.locationDetails = _locationDetails;
  }

  public locationMapLink(): string {
    return (
      "<a href='http://www.google.com/maps/place/" +
      this.latitude +
      ',' +
      this.longitude +
      " + ' target='_new'>http://www.google.com/maps/place/" +
      this.latitude +
      ',' +
      this.longitude +
      '</a>'
    );
  }

  get position(): {lat: number, lng: number} {
    return {
      lat: this.latitude,
      lng: this.longitude
    }
  }
}
