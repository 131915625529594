import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { HttpClient } from '@angular/common/http';
import { JobCategoryViewModel } from '../../viewModels/jobs/jobCategoryViewModel';
import { LightFilterUpdateModel } from 'src/app/modules/shared/models/filter/LightFilterUpdateModel';

@Injectable()
export class JobCategoriesService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration) {
    this.actionUrl = this.configuration.buildEndpoint(`JobCategory/`);
  }

  //categories

  list(): Observable<JobCategoryViewModel[]> {
    return this.http.get<JobCategoryViewModel[]>(this.actionUrl + 'List');
  }

  add(model: JobCategoryViewModel): Observable<JobCategoryViewModel> {
    return this.http.post<JobCategoryViewModel>(this.actionUrl + 'Add', model);
  }
  update(models: JobCategoryViewModel[]): Observable<void> {
    return this.http.put<void>(this.actionUrl + 'Update', models);
  }

  delete(models: JobCategoryViewModel[]): Observable<void> {
    return this.http.post<void>(
      this.actionUrl + 'Delete',
      models.map((m) => m.id)
    );
  }

  updateLight(item: LightFilterUpdateModel) {
    return this.http.post<LightFilterUpdateModel>(`${this.actionUrl}UpdateLight`, item);
  }
}
