<div #map class="map-wraper"></div>

<div
  #topLeftControlsWrapper
  *ngIf="mapLoaded && editMode"
  class="top-left-controls-wrapper"
  [ngClass]="{ reverse: wrapperIsSmall }"
>
  <div #searchControlBtnWrapper *ngIf="showSearchButton">
    <app-search-field
      #searchControl
      [map]="gmap"
      [zones]="zones"
      [areas]="areas"
      (searchStateChanged)="onSearchStateChanged($event)"
      (autocompleteResult)="onAutocompleteResult($event)"
      (what3WordsResult)="onWhat3WordsResult($event)"
    >
    </app-search-field>
  </div>
  <div *ngIf="showMyLocationButton" #myLocationControlBtnWrapper class="margin-left-fix">
    <app-my-location-button (onLocationAvailableEvent)="onMyLocationAvailable($event)"></app-my-location-button>
  </div>
  <div *ngIf="showNoLocationButton && lat && lng" #noLocationControlBtnWrapper class="margin-left-fix">
    <app-no-location-button (onClickedEvent)="setNoLocation()"></app-no-location-button>
  </div>
</div>

<div *ngIf="mapLoaded" #zoneAreaControlBtnWrapper [ngClass]="{ 'zone-area-control-wrapper': !wrapperIsSmall }">
  <app-zone-area-toggle-button
    [map]="gmap"
  ></app-zone-area-toggle-button>
</div>

<div *ngIf="mapLoaded && showW3WGridButton" #w3wGridControlBtnWrapper>
  <app-w3w-grid-button
    [map]="gmap"
    (onClicked)="onW3WGridClicked()"
    (gridToggled)="onW3WGridToggled($event)"
  ></app-w3w-grid-button>
</div>
