<app-modal>
  <app-modal-header
    [title]="suggestedRisk.title"
    [editableTitle]="true"
    [icon]="'warning'"
    [iconColor]="'var(--rag-amber)'"
    [fontSize]="16"
    [lineClamp]="2"
    (closed)="closeModal()"
    (titleUpdate)="onTitleUpdate($event)"
    (isSuggestedRiskDetailsModal)="true"
  >
  </app-modal-header>
  <app-modal-body>

      <div class="mb-2 suggested-item-description">
        <app-details-text-area
          [input]="suggestedRisk.description ? suggestedRisk.description : ''"
          [label]="T.defaultLocalizations.description.one | translate"
          [placeholder]="T.common.add_description | translate"
          [canEdit]="true"
          (update)="updateDescription($event)"
        ></app-details-text-area>
      </div>
      <div class="row mb-2">
        <div class="col">
          <div class="risk-label mb-1">{{ T.common.risk_rating | translate }}</div>
          <app-badge
              class="d-flex"
              [label]="ragDescription"
              [svgIcon]="svgIcon()"
              color="custom"
              [customColor]="markerColor"
              variant="fat"
          ></app-badge>
        </div>

        <div class="col d-flex flex-column">
          <span class="risk-label mb-1">{{ T.common.relevance | translate }}</span>
          <span class="risk-relevance-value">{{ riskRelevanceValue }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <app-details-dropdown
            [filterType]="filterTypes.Risk_Impact"
            [canEdit]="true"
            [statusBars]="true"
            [appliedFilters]="suggestedRisk.filters"
            (onFiltersUpdated)="handleFilterChange($event)">
          </app-details-dropdown>
        </div>

        <div class="col">
          <app-details-dropdown
            [filterType]="filterTypes.Risk_Likelihood"
            [canEdit]="true"
            [statusBars]="true"
            [appliedFilters]="suggestedRisk.filters"
            (onFiltersUpdated)="handleFilterChange($event)">
          </app-details-dropdown>
        </div>
      </div>
        <header class="suggested-risk-actions-header">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <span>{{ T.defaultLocalizations.risk_action_item.many | translate }}</span>
            <span *ngIf="filteredSuggestedRiskActions.length > 0" >
              {{ (T.common.selected_of | translate: { selected: selectedRiskActions?.length, all: filteredSuggestedRiskActions?.length})}}
            </span>
          </div>
          <div *ngIf="filteredSuggestedRiskActions.length > 0" class="suggested-risk-actions-info-text">
            {{ T.common.select_actions_to_include | translate }}
          </div>
        </header>
        @if(!loading){
          <app-list-search-input-fixed
            *ngIf="filteredSuggestedRiskActions.length > 0"
            class="me-2"
            [background]="'var(--wt-neutral-extra-light)'"
            [placeholder]="T.common.search_item | translate: {item: 'Risk Actions'}"
            [value]="searchText"
            (inputChanged)="onListSearch($event)"
          ></app-list-search-input-fixed>

          <section class="suggested-risk-actions-list custom-scrollbar">
            @if (filteredSuggestedRiskActions.length > 0) {
              @for (riskAction of filteredSuggestedRiskActions; track riskAction) {
                <div class="d-flex align-items-center suggested-risk-action-checkbox-wrapper" [ngClass]="{'selected': riskAction.isSelected}">
                  <app-checkbox-with-label
                    [app-tooltip]="{ message: riskAction.title }"
                    (clicked)="selectRiskAction(riskAction)"
                    [intialState]="'unchecked'"
                    [label]="riskAction.title"
                  ></app-checkbox-with-label>
                </div>
              }
            } @else {
              <div class="suggested-risk-actions-info-text" >{{ T.common.no_item_to_display | translate: {item: 'Risk Actions'} }}</div>
            }

          </section>
        } @else if(loading) {
          <app-loading></app-loading>
        }


  </app-modal-body>
  <app-modal-footer>
    <app-button-rectangle
      [text]="T.common.save_settings_and_add_risk | translate"
      size="large"
      [disabled]="false"
      (clicked)="onAddRisk()">
    </app-button-rectangle>
  </app-modal-footer>
</app-modal>
