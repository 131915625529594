import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum RAGDateOrderTypes {
  Before = 1,
  After = 2,
}

export namespace RAGDateOrderTypes {
  export function translatedItems(translateService: TranslateService): { key: RAGDateOrderTypes; value: string }[] {
    const a: { key: RAGDateOrderTypes; value: string }[] = [];
    for (const enumMember in RAGDateOrderTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translateItem(+enumMember, translateService) });
      }
    }
    return a;
  }
}

function translateItem(key: RAGDateOrderTypes, translateService: TranslateService) {
  switch (key) {
    case RAGDateOrderTypes.Before:
      return translateService.instant(T.common.before);
    case RAGDateOrderTypes.After:
      return translateService.instant(T.common.after);
    default:
      return 'unknown';
  }
}
