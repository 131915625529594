import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-widget-content',
  templateUrl: './dashboard-widget-content.component.html',
  styleUrls: ['./dashboard-widget-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetContentComponent {
}
