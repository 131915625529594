<app-modal [fullScreen]="true" *ngIf="modalType === 'add'">
  <div class="assessment-modal-header">
    <div class="modal-header-content">
      <div class="top-navigation-container">
        <div *ngIf="currentStep !== 0" class="top-back-icon" (click)="decreaseStep()">
          <span class="material-symbols-outlined chevron-icon">chevron_left</span>
        </div>
        <div *ngIf="currentStep !== 0" class="back-text header-text" (click)="decreaseStep()">{{T.common.back | translate}}</div>
        <div *ngIf="currentStep !== 0" class="dividing-line"></div>
        <div class="header-text">{{T.common.create_item | translate: { item: localisedRiskAssessment } }}</div>
      </div>
    </div>
    <div class="modal-header-close">
      <div class="material-symbols-outlined close" (click)="closeModal()">close</div>
    </div>
  </div>
  <app-threaded-beads [beads]="beads" [showBubbles]="false"></app-threaded-beads>
  <app-modal-body class="custom-scrollbar" style="flex: 1">
    <div #wrapper class="wrapper">
    <div class="modal-body-container">
      <div class="content-container">
        <ng-container>
          <ng-container *ngIf="currentStep === 0">
            <ng-container *ngTemplateOutlet="assessmentFocusTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="currentStep === 1">
            <ng-container *ngTemplateOutlet="assessmentContextTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="currentStep === 2">
            <ng-container *ngTemplateOutlet="assessmentAssignmentsTemplate"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    </div>
  </app-modal-body>
</app-modal>

<div class="small-modal-container" *ngIf="modalType === 'post-add'">
  <div class="close-btn-row">
    <i class="material-symbols-outlined close-btn" (click)="closeModal()">close</i>
  </div>
  <div class="modal-body-content">
    <i class="material-symbols-outlined check_circle-icon mb-24">check_circle</i>
    <span class="item-created-title mb-16">{{T.defaultLocalizations.item_created | translate: {item: localisedRiskAssessment} }}</span>
    <span class="item-created-description mb-24">{{T.common.you_can_add_risks_from_details_page | translate}}</span>
    <app-button-rectangle class="mb-16" [text]="T.common.go_to_risk_assessment | translate" [size]="'large'" (clicked)="navigateToRiskAssessment()"></app-button-rectangle>
    <app-button-transparent [text]="T.common.create_another_risk_assessment | translate" size="large" (clicked)="addAnotherAssessment()"></app-button-transparent>
  </div>
</div>

<ng-template #assessmentFocusTemplate>
  <div class="assessment-focus-container">
    <div class="assessment-focus-title">{{T.common.what_is_the_focus_of_risk_assessment | translate}}</div>
    <div class="focus-types-container">
      <div class="focus-type-box" [ngClass]="{'selected': selectedFocusFilterType === filterTypes.Event, 'disabled': !!preselectedEventId }" (click)="onSelectFocusType(filterTypes.Event)">
        <i class="material-symbols-outlined">event</i>
        <div class="focus-type-text">{{T.common.risk_assessment_for_item | translate: {item: localisedEvent} }}</div>
      </div>
      <div class="focus-type-box" [ngClass]="{'selected': selectedFocusFilterType === filterTypes.Zone, 'disabled': !!preselectedEventId }" (click)="onSelectFocusType(filterTypes.Zone)">
        <i class="material-symbols-outlined">location_searching</i>
        <div class="focus-type-text">{{T.common.risk_assessment_for_item | translate: {item: localisedZone} }}</div>
      </div>
      <div class="focus-type-box" [ngClass]="{'selected': selectedFocusFilterType === filterTypes.Department, 'disabled': !!preselectedEventId }" (click)="onSelectFocusType(filterTypes.Department)">
        <i class="material-symbols-outlined">business</i>
        <div class="focus-type-text">{{T.common.risk_assessment_for_item | translate: {item: localisedDepartment} }}</div>
      </div>
    </div>
    <div class="focus-type-dropdown">
      <app-filter-dropdown-new
        *ngIf="selectedFocusFilterType === filterTypes.Event"
        [label]="filterTypes.Event | localiseFilterType"
        [filterType]="filterTypes.Event"
        [filterByModuleObjectType]="false"
        [appliedFilters]="riskAssessment.filters"
        [typeahead]="true"
        [singleSelect]="true"
        [disableMargin]="true"
        [required]="true"
        [disabled]="!!preselectedEventId"
        (onFilterUpdate)="handleFocusFilterChange($event, filterTypes.Event)"
      >
      </app-filter-dropdown-new>
      <app-filter-dropdown-new
        *ngIf="selectedFocusFilterType === filterTypes.Zone"
        [label]="filterTypes.Zone | localiseFilterType"
        [filterType]="filterTypes.Zone"
        [filterByModuleObjectType]="false"
        [appliedFilters]="riskAssessment.filters"
        [typeahead]="true"
        [singleSelect]="true"
        [disableMargin]="true"
        [required]="true"
        (onFilterUpdate)="handleFocusFilterChange($event, filterTypes.Zone)"
      >
      </app-filter-dropdown-new>
      <app-filter-dropdown-new
        *ngIf="selectedFocusFilterType === filterTypes.Department"
        [label]="filterTypes.Department | localiseFilterType"
        [filterType]="filterTypes.Department"
        [filterByModuleObjectType]="false"
        [appliedFilters]="riskAssessment.filters"
        [typeahead]="true"
        [singleSelect]="true"
        [disableMargin]="true"
        [required]="true"
        (onFilterUpdate)="handleFocusFilterChange($event, filterTypes.Department)"
      >
      </app-filter-dropdown-new>
    </div>
    <ng-container [ngTemplateOutlet]="actionButtonsTemplate"></ng-container>
  </div>
</ng-template>
<ng-template #assessmentContextTemplate>
  <div class="risk-context-container">
    <div class="assessment-focus-title">{{T.common.context_of_the_risk_assessment | translate}}</div>
    <div class="input-field-wrapper mb-32">
      <app-text-field
        [autofocus]="true"
        [(ngModel)]="riskAssessment.title"
        [placeholder]="(T.defaultLocalizations.title.one | translate) + '*'"
        [required]="true"
      >
      </app-text-field>
    </div>
    <div class="description-field-wrapper mb-32">
      <app-details-editable-field [input]="riskAssessment.description ? riskAssessment.description : ''"
        [header]="T.defaultLocalizations.description.one | translate" [type]="editableFieldTypes.RichText"
        [canEdit]="true" (onUpdate)="onDescriptionChange($event)" [placeholder]="T.common.add_description | translate"
        name="description">
        [maxLength]="2000" (onInputChange)="onDescriptionChange($event)"
      </app-details-editable-field>
    </div>
    <div class="input-field-wrapper mb-24">
      <app-datepicker
        [placeholder]="(T.common.start_date.one | translate) + '*'"
        [required]="true"
        [date]="riskAssessment.startDate"
        (dateChanged)="onStartDateChanged($event)"
      >
      </app-datepicker>
      <div class="field-helper-text">{{T.common.risk_assessment_will_be_shared_on_this_date | translate}}</div>
    </div>
    <div class="input-field-wrapper mb-24">
      <app-datepicker
        [placeholder]="(T.common.due_date | translate) + '*'"
        [required]="true"
        [date]="riskAssessment.dueDate"
        (dateChanged)="onDueDateChanged($event)"
      >
      </app-datepicker>
      <div class="field-helper-text">{{T.common.risk_assessment_must_be_approved_by_date | translate}}</div>
    </div>
    <ng-container [ngTemplateOutlet]="actionButtonsTemplate"></ng-container>
  </div>
</ng-template>



<ng-template #assessmentAssignmentsTemplate>
  <div class="assignment-container">
    <div class="assessment-focus-title">{{T.common.assignments_and_confidentiality | translate}}</div>
    <div class="dropdown-wrapper mb-24">
      <app-filter-dropdown-new
        [label]="(filterTypes.Owner | localiseFilterType) + '*'"
        [filterType]="filterTypes.Owner"
        [filterByModuleObjectType]="false"
        [appliedFilters]="riskAssessment.filters"
        [typeahead]="true"
        [singleSelect]="true"
        [disableMargin]="true"
        [required]="true"
        (onFilterUpdate)="handleFilterChange($event)"
      >
      </app-filter-dropdown-new>
      <div class="field-helper-text">{{T.common.responsible_for_managing_risk_assessment | translate}}</div>
    </div>
    <div class="dropdown-wrapper mb-24">
      <app-filter-dropdown-new
        [label]="(filterTypes.Approver | localiseFilterType) + '*'"
        [filterType]="filterTypes.Approver"
        [filterByModuleObjectType]="false"
        [appliedFilters]="riskAssessment.filters"
        [typeahead]="true"
        [singleSelect]="true"
        [disableMargin]="true"
        [required]="true"
        (onFilterUpdate)="handleFilterChange($event)"
      >
      </app-filter-dropdown-new>
      <div class="field-helper-text">{{T.common.responsible_for_approving_the_risk_assessment | translate}}</div>
    </div>
    <div class="dropdown-wrapper">
      <app-filter-dropdown-new
        [label]="T.common.confidentiality | translate"
        [filterType]="filterTypes.Risk_Assesment_Privacy_Status"
        [filterByModuleObjectType]="false"
        [appliedFilters]="riskAssessment.filters"
        [typeahead]="true"
        [singleSelect]="true"
        [disableMargin]="true"
        [required]="true"
        (onFilterUpdate)="handleFilterChange($event)"
      >
      </app-filter-dropdown-new>
    </div>
    <ng-container [ngTemplateOutlet]="actionButtonsTemplate"></ng-container>
  </div>
</ng-template>

<ng-template #actionButtonsTemplate>
  <div class="action-buttons">
    <app-button-rectangle [buttonTheme]="'white'" [class]="'button'" [fitToContainer]="true" [size]="'large'"
    [text]="T.common.cancel | translate" (clicked)="closeModal()"></app-button-rectangle>
  <app-button-rectangle *ngIf="currentStep !== 2" [disabled]="!isValid" [class]="'button'" [fitToContainer]="true" [size]="'large'"
    [text]="T.common.continue | translate" (clicked)="increaseStep()"></app-button-rectangle>
  <app-button-rectangle *ngIf="currentStep === 2" [disabled]="!isValid" [loading]="isSaving"  [class]="'button'" [fitToContainer]="true" [size]="'large'"
    [text]="T.common.save_item | translate: {item: localisedRiskAssessment}" (clicked)="onSave()"></app-button-rectangle>
  </div>
</ng-template>
