import { Component, OnInit, HostListener } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-confirmation-save',
  templateUrl: './modal-confirmation-save.component.html',
  styleUrls: ['./modal-confirmation-save.component.scss'],
})
export class ModalConfirmationSaveComponent implements OnInit {
  isCancel = false;

  saveMessage = 'Are you sure you want to save changes to this item?';
  saveTitle = 'Save Changes';

  cancelTitle = 'Leave';
  cancelMessage = 'Are you sure you want to leave the page';

  titleToRender = this.saveTitle;
  messageToRender = this.saveMessage;

  public onClose: Subject<boolean> = new Subject();
  constructor(public bsModalRef: BsModalRef) {}

  @HostListener('document:keydown', ['$event']) escapeAndEnterHandler(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onDelete();
      event.preventDefault();
      event.stopPropagation();
    } else if (event.key === 'Escape') {
      this.onCancel();
    }
  }

  ngOnInit(): void {
    if (this.isCancel) {
      this.titleToRender = this.cancelTitle;
      this.messageToRender = this.cancelMessage;
    }
  }
  onDelete() {
    this.bsModalRef.hide();
    this.onClose.next(true);
  }

  onCancel() {
    this.bsModalRef.hide();
    this.onClose.next(false);
  }
}
