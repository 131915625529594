import { ObjectMarker } from 'src/app/modules/shared/models/map/markers/objectMarker';
import { MarkerType } from '../../../enums/maps/marketType';
import { What3WordInfo } from 'src/app/modules/incidents/models/what3WordInfo';

export class PulsingIncidentMarker extends ObjectMarker {
  private severity: number;
  private _what3WordInfo: What3WordInfo;
  private _displayWhat3WordPolygon: boolean;

  constructor(position: google.maps.LatLng, color: string, severity: number, map: google.maps.Map) {
    super(MarkerType.INCIDENT, position, color, map);
    this.severity = severity;
    this.initPulsingMarker();
  }

  private initPulsingMarker() {
    const markerWrapper = (<HTMLElement>this.marker.element.querySelector(`.${ObjectMarker.markerClassName}`));
    markerWrapper.classList.add('pulsing-marker');
    markerWrapper.classList.add(`severity_${this.severity}`);

    const severity = `<div class='severity'>${this.severity || ''}</div>`;
    const w3wInfo =
      `<div class='what3words-info-tooltip'>
          <span class="tripple-incline">///</span>
          <span class="address">...</span>
      <div>`;

    markerWrapper.insertAdjacentHTML('beforeend', `${severity}${w3wInfo}`);
  }

  get what3WordInfo(): What3WordInfo {
    return this._what3WordInfo;
  }

  set what3WordInfo(obj: What3WordInfo) {
    this._what3WordInfo = obj;
    this.w3wPopupLabel = obj.address;
  }

  set w3wPopupLabel(label: string) {
    (<HTMLElement>this.marker.element.querySelector('.address')).innerHTML = label;
  }

  setMap(map: google.maps.Map): void {
    this.marker.map = map;
    if(!map) {
      this.toggleWhat3Polygon(false);
    }
  }

  public toggleWhat3Polygon(state: boolean) {
    this._displayWhat3WordPolygon = state;
    if (this._what3WordInfo) {
      this._what3WordInfo.polygon.setMap(state ? this.map: null);
    }
  }

}
