export enum IconTypes {
  Risk,
  Issue,
  Opportunity,
  Task,
  Sub_Task,
  Project,
  Task_Group,
  Risk_Action,
  Capability,
  Activity,
  Lesson,
  Action,
  Evaluation,
  Target_Badge,
}
