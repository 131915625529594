import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { DropdownEventsEmitter } from 'src/app/modules/shared/events/dropdown.events';
import { IncidentsManager } from 'src/app/modules/shared/managers/incidents.manager';
import { Account } from 'src/app/modules/shared/models/account';
import { DetailsMapConfiguration } from 'src/app/modules/shared/models/detailsMapConfig';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { AreaViewModel } from 'src/app/modules/shared/viewModels/areaViewModel';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { ZoneViewModel } from 'src/app/modules/shared/viewModels/zoneViewModel';
import { IncidentMapComponent } from '../incident-map/incident-map.component';

@Component({
  selector: 'app-details-map',
  templateUrl: 'details-map.component.html',
  styleUrls: ['details-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsMapComponent implements OnInit, OnDestroy, OnChanges {
  private subscriptions: Subscription[] = [];
  private account: Account;

  @ViewChild('incidentMap', { static: false }) incidentMapComponent: IncidentMapComponent;

  @Input() config: DetailsMapConfiguration;
  @Input() zones: ZoneViewModel[] = [];
  @Input() areas: AreaViewModel[] = [];
  @Input() location: LocationViewModel;
  @Input() globalObjectId: number;
  @Input() pinColour: string;
  @Input() editMode: boolean = false;
  @Input() signleSelect: boolean = false;
  @Output() onZonesUpdate = new EventEmitter<ZoneViewModel[]>();
  @Output() onAreasUpdate = new EventEmitter<AreaViewModel[]>();
  @Output() onLocationUpdate = new EventEmitter<LocationViewModel>();

  mapAreas: AreaViewModel[] = [];
  mapZones: ZoneViewModel[] = [];
  zonesViewModels = [];
  areasViewModels = [];
  filterTypes = FilterTypes;
  activeEditAreaByFilterTypes: { [key: number]: boolean } = {};
  mapZoom: number;
  originalLocation: LocationViewModel;
  hasBeenInitted = false;
  sidebarIsCollapsed = false;
  sideIsCollapsed = false;
  lozengeFilterTypes: FilterTypes[] = [FilterTypes.Zone, FilterTypes.Area];

  constructor(
    private readonly localisationService: LocalisationService,
    private readonly incidentsManager: IncidentsManager,
    private readonly authenticationService: AuthenticationService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dropdownEventsEmitter: DropdownEventsEmitter
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.initActiveEditAreaByFilterTypes();
    this.account = this.authenticationService.getCurrentAccount();
    this.originalLocation = JSON.parse(JSON.stringify(this.location));
    this.setMapOverrides();
    this.hasBeenInitted = true;

    if (this.config.useZonesAndAreas) {
      this.subscriptions.push(
        forkJoin(this.incidentsManager.getZones(), this.incidentsManager.getAreas(this.account.id)).subscribe(
          ([zones, areas]) => {
            this.zonesViewModels = zones;
            this.areasViewModels = areas;
            this.changeDetectorRef.markForCheck();
            this.setMapAreasAndZones();
          }
        )
      );
    }
  }

  ngOnChanges(): void {
    if (!this.hasBeenInitted) {
      return;
    }

    this.location = JSON.parse(JSON.stringify(this.location));
    this.setMapOverrides();
    this.setMapAreasAndZones();
    this.changeDetectorRef.markForCheck();

    if (!this.editMode && this.incidentMapComponent) {
      this.incidentMapComponent.setComponentLocationAtZoom(this.originalLocation.latitude, this.originalLocation.longitude, 20);
    }
  }

  private initActiveEditAreaByFilterTypes() {
    this.activeEditAreaByFilterTypes[FilterTypes.Zone] = false;
    this.activeEditAreaByFilterTypes[FilterTypes.Area] = false;
  }

  localise(text) {
    return this.localisationService.localise(text);
  }

  setMapAreasAndZones() {
    if (this.config.useZonesAndAreas) {
      this.mapAreas = this.areas.map((a: AreaViewModel & { areaId: number }) =>
        this.areasViewModels.find((avm: AreaViewModel & { areaId: number }) => avm.areaId === a.id)
      );

      this.mapZones = this.zones.map((z) => this.zonesViewModels.find((zvm) => zvm.zoneId === z.id));
    }
  }

  onIncidentLocationUpdated(location: LocationViewModel) {
    this.onLocationUpdate.next(location);
  }

  hasLocation(): boolean {
    return !!this.location.latitude && !!this.location.longitude;
  }

  onEditLocation() {
    this.editMode = true;

    if (this.incidentMapComponent) {
      this.incidentMapComponent.locationMarker.draggable = true;
    }
  }

  onCancelEditLocation() {
    this.editMode = false;

    // Reset map
    if (this.incidentMapComponent) {
      this.incidentMapComponent.setComponentLocationAtZoom(this.originalLocation.latitude, this.originalLocation.longitude, 20);
    }
  }

  onSaveLocation() {
    this.editMode = false;
    this.onLocationUpdate.next(this.location);
  }

  setMapOverrides() {
    this.mapZoom = this.hasLocation() ? 20 : 2;

    if (this.incidentMapComponent) {
      this.incidentMapComponent.setZoom(this.mapZoom);
    }
  }
}
