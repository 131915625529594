/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable } from '@angular/core';
import { DocumentViewModel } from 'src/app/modules/shared/viewModels/documents/documentViewModel';
import { Observable, Observer, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { Configuration } from 'src/app/app.constants';
import { AlertService } from './alert.service';
import { ObjectTypes } from '../enums/objectTypes';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AuthenticationService } from './authentication.service';
import { generateRandomGUID } from '../utilities/guid.utilities';
import { WtStorageService } from './wt-storage.service';
import { CopiedAttachmentsWhenLinkObjectViewModel } from '../models/linkedItemObjects/multipleLinkedItemsObjectViewModel';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private actionUrl: string = '';

  constructor(
    private readonly http: HttpClient,
    private readonly configuration: Configuration,
    private readonly alertService: AlertService,
    private readonly imageCompress: NgxImageCompressService,
    private readonly authenticationService: AuthenticationService,
    private readonly wtStorage: WtStorageService
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`Documents/`);
  }

  addDocument(uploadViewModel: UploadViewModel): Observable<DocumentViewModel> {
    return this.http.post<DocumentViewModel>(this.actionUrl + 'AddDocument', uploadViewModel);
  }

  uploadFile(file: File, isAttachment: boolean = false, isAccountLogo: boolean = false): Observable<string> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<string>(`${this.actionUrl}UploadFile/${isAttachment.toString()}/${isAccountLogo.toString()}`, formData, {
      responseType: 'text' as 'json',
    });
  }

  uploadPart(part: number, originalFileName: string, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post<void>(`${this.actionUrl}UploadPart/${part}/${originalFileName}`, formData);
  }

  completeUpload(
    originalFileName: string,
    newFileName: string,
    isAttachment: boolean,
    isAccountLogo: boolean
  ): Observable<string> {
    return this.http.post<string>(
      `${this.actionUrl}CompleteUpload/${originalFileName}/${newFileName}/${isAttachment.toString()}/${isAccountLogo.toString()}`,
      {},
      this.authenticationService.getHttpOptions()
    );
  }

  addDocuments(uploadViewModels: UploadViewModel[]): Observable<DocumentViewModel[]> {
    return this.http.post<DocumentViewModel[]>(this.actionUrl + 'AddMutipleDocuments', uploadViewModels);
  }

  deleteDocument(documentId: number, globalObjectId: number, globalObjectType: number): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}Delete/${documentId}/${globalObjectId}/${globalObjectType}`);
  }

  updateDocument(uploadViewModel: UploadViewModel): Observable<DocumentViewModel> {
    return this.http.put<DocumentViewModel>(this.actionUrl + 'Update', uploadViewModel);
  }

  getByObjectId(objectId: number, objectType: ObjectTypes): Observable<DocumentViewModel[]> {
    return this.http.get<DocumentViewModel[]>(this.actionUrl + `ByObjectId/${objectId}/${objectType}`);
  }

  downloadDocument(id: number) {
    let filename = '';
    const url = this.actionUrl + `Download/${id}`;
    const requestHeaders = new Headers();
    requestHeaders.append('Authorization', 'Bearer ' + this.wtStorage.getItem('access_token'));

    fetch(url, {
      method: 'GET',
      headers: requestHeaders,
      mode: 'cors',
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.Message as string);
        }

        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(response.headers.get('Content-Disposition'));

        if (matches !== null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }

        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, filename);
      })
      .catch((err) => this.alertService.error(err.message as string));
  }

  private forceDownload(blob: string, filename: string) {
    const anchorElement = document.createElement('a');
    anchorElement.download = filename;
    anchorElement.href = blob;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    anchorElement.remove();
  }

  deleteUnassignedFile(fileName: string): Observable<void> {
    return this.http.delete<void>(`${this.actionUrl}DeleteUnassignedFile/${fileName}`);
  }

  splitUploadFile(file: File, compress: boolean = false, isAttachment = true, isAccountLogo = false): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      const reader = new FileReader();
      reader.onload = (ev: ProgressEvent<FileReader>) => {
        if (compress) {
          this.compressAndSaveImage(file.name, ev.target.result as string, isAttachment, isAccountLogo).subscribe((res) => {
            observer.next(res);
          });
        } else {
          this.readAndUploadChunks(ev.target.result as string, file.name, isAttachment, isAccountLogo, observer);
        }
      };
      reader.readAsDataURL(file);
    });
  }

  private readAndUploadChunks(toBeChunked: string, fileName: string, isAttachment: boolean, isAccountLogo: boolean, observer: Observer<string>) {
    const chunkedFileParts = this.readAllChunks(toBeChunked);

    this.uploadChunksAndFinalise(fileName, chunkedFileParts).subscribe(() => {
      const newFileName = this.randomizeFileName();
      this.completeUpload(fileName, newFileName, isAttachment, isAccountLogo).subscribe((fileName) => {
        observer.next(fileName);
      });
    });
  }

  compressAndSaveImage(imageName: string, image: string, isAttachment: boolean, isAccountLogo: boolean): Observable<string> {
    return new Observable<string>((observer: Observer<string>) => {
      void this.imageCompress.compressFile(image, null, 75, 75).then((res) => {
        this.readAndUploadChunks(res, imageName, isAttachment, isAccountLogo, observer);
      });
    });
  }

  readAllChunks(base64str: string): Array<string> {
    const chunkSize = 500000;
    const base64result = base64str.substr(base64str.indexOf(',') + 1);
    const chunkedFileParts: string[] = [];

    for (let i = 0; i < base64result.length; i = i + chunkSize) {
      const part = this.readChunk(base64result, i, i + chunkSize);
      chunkedFileParts.push(part);
    }

    return chunkedFileParts;
  }

  readChunk(file, start, end) {
    const blob = file.slice(start, end);
    // const key = CryptoJS.enc.Utf8.parse(environment.API_KEY) as string;
    // const iv = CryptoJS.enc.Utf8.parse(environment.API_IV) as string;

    // const encrypted = CryptoJS.AES.encrypt(blob, key, {
    //   keySize: 128,
    //   iv: iv,
    //   mode: CryptoJS.mode.CBC,
    //   padding: CryptoJS.pad.Pkcs7,
    // });

    return blob.toString() as string;
  }

  uploadChunksAndFinalise(
    imageName: string,
    chunkedFileParts: Array<string>
  ): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      const observableBatch: Observable<void>[] = [];
      let count = 0;
      chunkedFileParts.forEach((chunk) => {
        const bytes = this.getBytesFromChunk(chunk);
        const temp = new File([new Blob([bytes], { type: 'application/octet-stream' })], 'temp.tmp');
        observableBatch.push(this.uploadPart(count + 1, imageName, temp));
        count += 1;
      });

      forkJoin(observableBatch).subscribe(() => {
        observer.next(true);
      });
    });
  }

  private getBytesFromChunk(chunk: string) {
    const bytes = new Uint8Array(chunk.length);
    for (let t = 0; t < chunk.length; t++) {
      bytes[t] = chunk.charCodeAt(t);
    }
    return bytes;
  }

  private randomizeFileName() {
    return generateRandomGUID();
  }

  public copyAttachments(copiedAttachmentsPayload: CopiedAttachmentsWhenLinkObjectViewModel): Observable<null> {
    return this.http.post<null>(`${this.actionUrl}CopyAttachments`, copiedAttachmentsPayload);
  }
}
