import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SplitPaneComponent } from '../split-pane/split-pane.component';

@Component({
  selector: 'app-list-details-switch-pane',
  templateUrl: './list-details-switch-pane.component.html',
  styleUrls: ['./list-details-switch-pane.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListDetailsSwitchPaneComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly itemsPerPage: number = 20;

  @ViewChild(SplitPaneComponent) splitPaneComponent: SplitPaneComponent;

  @Input() items: object[] = [];
  @Input() itemBindProperty: string;
  @Input() itemsTotalCount: number;
  @Input() loading: boolean;
  @Input() displayLogo: boolean;
  @Input() cardTemplate: TemplateRef<HTMLElement>;
  @Input() leftSideTemplate: TemplateRef<HTMLElement>;
  @Input() sortButtonTemplate: TemplateRef<HTMLElement>;
  @Input() title: string;
  @Input() useBackToListButton: boolean = true;
  @Input() showHelperAndSort: boolean = true;

  @Output() smallCardsChanged: EventEmitter<boolean> = new EventEmitter();

  smallCards: boolean;
  subscriptions: Subscription[] = [];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit() {
    this.smallCards = !!this.route.firstChild;
    this.subscriptions.push(
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
        this.smallCards = !!this.route.firstChild;
        this.changeDetectorRef.detectChanges();

        if (this.smallCards) {
          this.reflectPosition();
        }

        this.smallCardsChanged.emit(this.smallCards);
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  ngAfterViewInit() {
    if (this.smallCards) {
      this.reflectPosition();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onExpandCollapse(collapsed: boolean) {
    if (!collapsed) {
      this.reflectPosition();
    }
  }

  private reflectPosition() {
    if (!this.itemBindProperty) {
      return;
    }

    if (!this.route.firstChild) {
      return;
    }

    if (!this.items.length) {
      return;
    }

    const regExp: RegExp = /\/(\d+?)$|\/(\d+?)\?\w+?\=\d+?$/g;
    const regExpExecArray: RegExpExecArray = regExp.exec(this.router.url);
    const [, group1, group2] = regExpExecArray;
    const id: string = group1 || group2;
    const index: number = this.items.findIndex((l) => l[this.itemBindProperty] === +id);

    const {
      paginatedCardsComponent: { itemsPerPage },
    } = this.splitPaneComponent;

    const page: number = Math.floor(index / itemsPerPage) + 1;

    this.splitPaneComponent.pageChangeSubject.next(page);
    this.splitPaneComponent.paginatedCardsComponent.rows
      .toArray()
      [index - (page ? (page - 1) * itemsPerPage : 0)].nativeElement.firstElementChild.scrollIntoView({
        behavior: 'smooth',
      });
  }

  goBack() {
    void this.router.navigate(['../'], { relativeTo: this.route.firstChild });
  }
}
