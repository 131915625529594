import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Tooltip } from '../../../models/tooltip';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  host: {
    '[class.filters-inline-edit-lozenge-disabled]': 'disabled',
  },
})
export class ChipComponent {
  @HostListener('click', ['$event'])
  onClick(e: Event): void {
    e.stopPropagation();
    e.stopImmediatePropagation();

    if(!this.disabled){
      this.lozengeClicked.emit();
    }
  }

  @Input() tooltip: Tooltip;
  @Input() disabled: boolean = false;
  @Input() isActionsGroup: boolean = false;

  @Output() lozengeClicked = new EventEmitter<void>();

}
