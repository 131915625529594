<app-loading *ngIf="isLoading"></app-loading>
<app-modal *ngIf="!isLoading">
  <app-modal-header [title]="title" (closed)="confirmClose()"></app-modal-header>
  <app-modal-body>
    <div class="modal-body-container row col-row-space custom-gutters" [formGroup]="form">
      <div class="col-md-6 col-12">
        <app-text-field
          class="modal-project"
          [displayError]="triggerErrors"
          [autofocus]="true"
          [required]="true"
          [maxLength]="250"
          formControlName="title"
          [disableMargin]="true"
          [placeholder]="(T.common.item_title | translate: { item: localisedItem }) + '*'"
          [formGroup]="form"
          [useReactiveMessages]="true"
        >
        </app-text-field>
      </div>
      <div class="col-md-6 col-12">
        <div class="selection">
          <app-datepicker
            *ngIf="!dateReseter"
            [date]="getDate"
            (dateChanged)="dateChangeHandler($event)"
            [required]="true"
            [showError]="triggerErrors"
            [placeholder]="T.defaultLocalizations.date.one | translate"
            [showPlaceholderFirst]="true"
            [disableMargin]="true"
          ></app-datepicker>
        </div>
      </div>
      <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12">
        <div class="selection">
          <app-filter-dropdown-new
            [filterType]="filterTypes.Owner"
            [filterByModuleObjectType]="false"
            [appliedFilters]="filters"
            [disableMargin]="true"
            [typeahead]="true"
            [label]="T.common.assign_owner.many | translate"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="bottom-element d-flex">
          <app-button-transparent
            size="small"
            icon="add"
            [text]="T.common.assign_me | translate"
            (clicked)="setAccountForCurrentOwner()"
          ></app-button-transparent>
        </div>
      </div>
      <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12">
        <app-filter-dropdown-new
          [filterType]="filterTypes.User_Group"
          [filterByModuleObjectType]="false"
          [appliedFilters]="filters"
          [disableMargin]="true"
          [typeahead]="true"
          [label]="T.common.assign_group_s | translate"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="container-with-bottom-element d-flex flex-column col-md-6 col-12">
        <div class="selection">
          <app-filter-dropdown-new
            [filterByModuleObjectType]="false"
            [filterType]="filterTypes.Department"
            [disableMargin]="true"
            [appliedFilters]="filters"
            [typeahead]="true"
            [label]="T.common.assign_item | translate: { item: localisedDepartment }"
            (onFilterUpdate)="handleFilterChange($event)"
          >
          </app-filter-dropdown-new>
        </div>
        <div class="bottom-element d-flex" *ngIf="employee?.departmentId > 0">
          <app-button-transparent
            size="small"
            icon="add"
            [text]="T.common.assign_my_localisedDepartment | translate: { localisedDepartment: localisedDepartment }"
            (clicked)="setMyDepartment()"
          ></app-button-transparent>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <app-filter-dropdown-new
          [label]="T.common.add_item | translate: { item: localisedZones }"
          [filterType]="filterTypes.Zone"
          [filterByModuleObjectType]="false"
          [appliedFilters]="filters"
          [typeahead]="true"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="col-md-6 col-12">
        <app-filter-dropdown-new
          [label]="T.common.add_item | translate: { item: localisedAreas }"
          [filterType]="filterTypes.Area"
          [filterByModuleObjectType]="false"
          [appliedFilters]="filters"
          [typeahead]="true"
          [usePredefinedFilters]="true"
          [predefinedFilters]="applicableAreaFilters"
          [relatedObjectId]="selectedZone?.filterValue"
          [disabled]="applicableAreaFilters.length === 0"
          (onFilterUpdate)="handleFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="event col-md-6 col-12">
        <app-filter-dropdown-new
          [filterType]="filterTypes.Event"
          [filterByModuleObjectType]="false"
          [singleSelect]="true"
          [appliedFilters]="filters"
          [typeahead]="true"
          [label]="localiseEvent"
          (onFilterUpdate)="handleEventFilterChange($event)"
        >
        </app-filter-dropdown-new>
      </div>
      <div class="description col-12">
        <app-details-editable-field
          [input]="form.controls.notes.value ? form.controls.notes.value : ''"
          [header]="T.common.notes | translate"
          [type]="editableFieldTypes.RichText"
          [canEdit]="true"
          (onUpdate)="updateNotes($event)"
          (onInputChange)="updateNotes($event)"
          [emptyStateMessage]="T.common.click_to_add_notes | translate"
          [placeholder]="T.common.add_notes | translate"
        ></app-details-editable-field>
      </div>
    </div>
  </app-modal-body>
  <app-modal-footer>
    <div class="buttons-row d-flex justify-content-between align-items-center">
      <app-button-transparent
        [style.visibility]="modalState === 2 ? 'hidden !important' : ''"
        class="d-none d-lg-block"
        [app-tooltip]="{
          title: T.common.keyboard_shortcuts | translate,
          position: ['top'],
          templateRef: tooltipTemplate
        }"
        [text]="T.common.keyboard_shortcuts | translate"
        icon="keyboard"
      ></app-button-transparent>
      <div class="d-flex">
        <app-button-rectangle
          class="button d-none d-md-block mr-8"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.close | translate"
          buttonTheme="white"
          (clicked)="confirmClose()"
        ></app-button-rectangle>
        <app-button-dropdown
          [disabled]="!form.valid"
          *ngIf="modalState === 0"
          class="button"
          [fitToContainer]="true"
          [selectedOption]="buttonOptions[0]"
          [options]="buttonOptions"
          (selected)="handleAction($event)"
        ></app-button-dropdown>
        <app-button-rectangle
          [disabled]="!form.valid"
          *ngIf="modalState === 2"
          class="button d-none d-md-block mr-8"
          [fitToContainer]="true"
          size="large"
          [text]="T.common.confirm | translate"
          buttonTheme="primary"
          (clicked)="duplicateRunsheet()"
        ></app-button-rectangle>
      </div>
    </div>
  </app-modal-footer>
</app-modal>

<ng-template #tooltipTemplate>
  <table class="styled-table">
    <tr *ngFor="let keybind of keyboardShortcuts">
      <td>
        <span class="key-shortcut">{{ keybind.key }}</span> -
        <span class="key-description">{{ keybind.description }}</span>
      </td>
    </tr>
  </table>
</ng-template>
