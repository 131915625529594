import { ChangeDetectionStrategy, Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccordionService } from 'src/app/modules/shared/services/accordion.service';
import { CollapseAnimation } from '../../../../utilities/animations/collapse.animation';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-settings-accordion-group',
  templateUrl: 'settings-accordion-group.component.html',
  styleUrls: ['settings-accordion-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [CollapseAnimation.slideInOut],
})
export class SettingsAccordionGroupComponent implements OnInit, OnDestroy {
  @Input() opened: boolean = false;
  @Input() useHeaderBorderBottom: boolean = true;
  @Input() title: string;
  @Input() icon: string;
  id: string;
  private subscriptions = new Subscription();

  constructor(
    private readonly accordionService: AccordionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.id = uuidv4();

    this.subscriptions.add(
      this.accordionService.collapseAllGroups.subscribe((exceptId) => {
        if (this.id !== exceptId) {
          this.opened = false;
        } else {
          this.opened = true;
        }
        this.changeDetectorRef.markForCheck();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onToggle() {
    this.accordionService.changeToggleStage(this.id, !this.opened);
  }
}
