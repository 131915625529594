import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Employee } from '../models/employee';
import { Account } from '../models/account';

@Injectable()
export class LoginEventsEmitter {
  private readonly currentEmployee = new Subject<Employee>();
  private readonly currentAccount = new Subject<Account>();

  currentEmployeeChanged$ = this.currentEmployee.asObservable();
  currentAccountChanged$ = this.currentAccount.asObservable();

  broadcastCurrentEmployeeChanged(employee: Employee) {
    this.currentEmployee.next(employee);
  }

  broadcastCurrentAccountChanged(account: Account) {
    this.currentAccount.next(account);
  }
}
