<div class="video-preview">
  <button class="close-btn" (click)="changeState()">
    <i class="material-symbols-outlined"> close </i>
  </button>
  <video class="video" controls>
    <source [src]="imageUrl" type="video/mp4" />
    Video cannot be viewed without download
  </video>
  <div>
    <span class="video-title">{{ title }}</span>
    <span class="video-description">{{ description }}</span>
  </div>
</div>
