import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterViewModel } from '../../shared/models/filter/filterViewModel';
import { RunsheetViewModel } from '../models/runsheet.model';

@Injectable({
  providedIn: 'root',
})
export class SharedRunsheetService {
  private runsheet = new BehaviorSubject<RunsheetViewModel>(null);
  private filters = new BehaviorSubject<FilterViewModel[]>(null);

  public runsheet$: Observable<RunsheetViewModel> = this.runsheet.asObservable().pipe(filter((res) => res != null));

  loadRunsheet(runsheet: RunsheetViewModel) {
    this.runsheet.next(runsheet);
  }

  getSelectedRunsheetAsObservable(): Observable<RunsheetViewModel> {
    return this.runsheet$;
  }

  getSelectedRunsheet(): RunsheetViewModel {
    return this.runsheet.value;
  }

  loadFilters(filters: FilterViewModel[]) {
    this.filters.next(filters);
  }

  getFilters(): Observable<FilterViewModel[]> {
    return this.filters.asObservable();
  }
}
