import { Pipe, PipeTransform } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';

@Pipe({
  name: 'displayTaskForAgenda',
  pure: false,
})
export class DisplayTaskForAgendaPipe implements PipeTransform {
  transform(item: CalendarEvent): string {
    let title = item.title;

    if (item.meta.event.calendarDailyStatus == 1) {
      title = '(Start) ' + title;
    } else if (item.meta.event.calendarDailyStatus == 3) {
      title = '(Due) ' + title;
    }

    if (item.meta.event.projectTitle != null) {
      title + ' (' + item.meta.event.projectTitle + ')';
    }

    title = title + ' ' + this.getShortDate(item.meta.event.start) + ' - ' + this.getShortDate(item.meta.event.end);

    return title;
  }

  getShortDate(date: Date): string {
    const dt = new Date(date);
    return dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear();
  }
}
