import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { isDateValid } from 'ngx-bootstrap/chronos';
import { Constants } from '../../../models/constants';
import { TimeZoneService } from '../../../services/timeZone.service';
import { UserAgentService } from '../../../services/user-agent.service';
import { NgMinMaxDateStrategies } from '../../../models/rangeDatepicker/rangeDatepickerModels.model';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativeDatepickerComponent implements OnInit, OnChanges {
  private readonly browsersSupportedFormat: string = 'yyyy-MM-dd';
  private readonly separator: string = '/';
  private readonly minYear: number = 1895;

  @ViewChild('desktopInput') desktopInput: ElementRef<HTMLElement>;
  @ViewChild('mobileInput') mobileInput: ElementRef<HTMLElement>;

  @Input() required: boolean;
  @Input() placeholder: string;
  @Input() showPlaceholderFirst: boolean;
  @Input() date: string;
  // Use this when you want to display second date on the same picker
  // Dont use it on Range picker (for range picker you have end-date)
  @Input() helperDate: string;
  @Input() minDate: Date | string;
  @Input() disableMargin: boolean = false;
  @Input() maxDate: Date;
  @Input() disabled = false;
  @Input() errorMsg: string = 'Invalid Date';
  // Use This Inputs only when you want to use the Double Range picker
  @Input() range = false;
  @Input() endDate: string;
  @Input() isEndDateField: boolean;
  @Input() showFooter: boolean;
  @Input() showHeader: boolean;
  @Input() showTime: boolean = false;
  @Input() showError: boolean = false;
  // In case the date is already formatted in the correct time zone on the BE
  @Input() isTimeZoneFormattedInAccount: boolean = false;
  // In case where the calendar needs to use predefined active dates , set this to true
  @Input() usePredefinedAvailableDates: boolean = false;
  // Predefined dates that will be shown in the calendar as clickable, !!!EVERY OTHER DATE will be DISABLED
  @Input() predefinedAvailableDates: string[];
  @Input() minMaxDateStrategy: NgMinMaxDateStrategies = NgMinMaxDateStrategies.DisableDate;
  @Input() useFullBorder: boolean = false;

  @Input() alignIconLeftStyle: string;
  @Output() dateChanged: EventEmitter<string> = new EventEmitter();
  @Output() endDateChanged: EventEmitter<string> = new EventEmitter();

  touchDevice: boolean;
  value: string | Date;
  helperValue: string | Date;
  endDateValue: string | Date;
  format: string;
  minDateValue: Date;
  maxDateValue: Date;
  showPlaceholder: boolean;
  public isFieldDirty = false;
  inputValue: string;
  constructor(
    private readonly timeZoneService: TimeZoneService,
    private readonly userAgentService: UserAgentService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.userAgentService.isTouch$.subscribe((isTouch) => {
      this.touchDevice = isTouch;
      this.changeDetectorRef.markForCheck();
    });

    if (this.showPlaceholderFirst) {
      this.showPlaceholder = true;
    }

    if (this.date) {
      this.setDates();
    }

    this.format = this.timeZoneService.dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
  }

  ngOnChanges() {
    this.setDates();
  }

  // onChange(e: Event) {
  //   const input = e.target as HTMLInputElement;
  //   const formats = this.format.split(this.separator);
  //   const parts = input.value.split(this.separator);

  //   // every date format has 3 parts e.g. dd/MM/yyyy
  //   if (parts.length < 3) {
  //     return;
  //   }

  //   const day = parts[formats.findIndex(f => f === 'dd')];
  //   const month = parts[formats.findIndex(f => f === 'MM')];
  //   const year = parts[formats.findIndex(f => f === 'yyyy')];

  //   let date = new Date(`${month}/${day}/${year}`);

  //   if (isDateValid(this.value) && year.length === 4 && date.getFullYear() >= this.minYear && date.getMonth() + 1 === +month) {
  //     date.setHours(this.value.getHours());
  //     date.setMinutes(this.value.getMinutes());
  //     date.setSeconds(this.value.getSeconds());
  //     date.setMilliseconds(this.value.getMilliseconds());

  //     if (this.minDate) {
  //       if (date.getTime() < this.minDate.getTime()) {
  //         return;
  //       }
  //     }

  //     if (this.maxDate) {
  //       if (date.getTime() > this.maxDate.getTime()) {
  //         return;
  //       }
  //     }

  //     this.onDateChanged(date);

  //     input.dispatchEvent(new Event('click'));
  //   }
  // }
  onEndDateChanged(dateAsIsoString: string) {
    this.endDateChanged.emit(dateAsIsoString);
    this.isFieldDirty = true;
  }

  onDateChanged(dateIsoString: string) {
    this.showPlaceholder = false;
    this.dateChanged.emit(dateIsoString);
    this.isFieldDirty = true;
  }

  onChangeNative(e: Event) {
    // const date: Date = (<HTMLInputElement>e.target).valueAsDate;
    // if (date) {
    //   if(this.isEndDateField){
    //     this.endDateChanged.emit(date);
    //   } else if (!this.isEndDateField) {
    //     this.dateChanged.emit(date);
    //   }
    // }
  }

  get displayError(): boolean {
    if ((this.required && this.isFieldDirty) || (this.required && this.showError)) {
      if (this.touchDevice) {
        return this.mobileInput ? !(this.mobileInput.nativeElement as HTMLInputElement).value : false;
      } else {
        return this.desktopInput ? !(this.desktopInput.nativeElement as HTMLInputElement).value || !this.inputDateValid : false;
      }
    }

    return false;
  }

  get inputDateValid(): boolean {
    if (!this.desktopInput) {
      return;
    }

    const input = this.desktopInput.nativeElement as HTMLInputElement;
    const formats = this.format.split(this.separator);
    const parts = input.value.split(this.separator);

    const day = parts[formats.findIndex((f) => f === 'dd')];
    const month = parts[formats.findIndex((f) => f === 'MM')];
    const year = parts[formats.findIndex((f) => f === 'yyyy')];

    if (!day || !month || !year) {
      return false;
    }

    const date = new Date(`${month}/${day}/${year}`);

    if (
      isDateValid(new Date(this.value)) &&
      year.length === 4 &&
      date.getFullYear() >= this.minYear &&
      date.getMonth() + 1 === +month
    ) {
      return true;
    }

    return false;
  }

  getDate() {
    const result = '';
    if (!this.showPlaceholder) {
      if (this.isEndDateField && this.range && this.endDate) {
        return this.timeZoneService.localiseDateISOString(this.endDate, this.showTime, false);
      } else if (this.date) {

        if(this.isTimeZoneFormattedInAccount) return this.timeZoneService.formatDateISOStringWithoutLocalisation(this.date, this.showTime, false);

        return this.timeZoneService.localiseDateISOString(this.date, this.showTime, false);
      }
    }

    return result;
  }

  setInputValue() {
    this.inputValue = this.getDate();

    this.changeDetectorRef.markForCheck();
  }

  setDates() {
    if (this.date) {
      this.showPlaceholder = false;
    }
    if (this.date) {
      this.value = this.date;
    }
    if (this.endDate) {
      this.endDateValue = this.endDate;
    }
    if (this.helperDate) {
      this.helperValue = this.helperDate;
    }
    if (this.minDate) {
      this.minDateValue = new Date(this.timeZoneService.localiseDateISOStringByCustomFormat(this.minDate, 'MM/DD/YYYY HH:mm'));
    }
    if (this.maxDate) {
      this.maxDate = new Date(this.timeZoneService.localiseDateISOStringByCustomFormat(this.maxDate, 'MM/DD/YYYY HH:mm'));
    }

    this.setInputValue();
  }

  get isMobile(): boolean {
    return window.innerWidth <= Constants.sm;
  }
}
