import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { PopupEventsEmitter } from '../../../events/popup.events';
import { Popup } from '../../../models/popup';
import { Position } from '../../../enums/position';
import { Constants } from 'src/app/modules/shared/models/constants';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly desktopMaxWidth: string = '410px';
  private readonly mobileMaxWidth: string = '240px';
  private readonly mobileWidth: number = Constants.xs;

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLElement>;

  popup: Popup;
  position = Position;
  subscriptions: Subscription[] = [];

  constructor(private readonly popupEventsEmitter: PopupEventsEmitter, private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscriptions.push(
      this.popupEventsEmitter.popupChanged$.subscribe((popup) => {
        this.popup = popup;
        this.popup.icon = popup.icon ?? 'info';
        this.popup.title = popup.title ?? 'Information';

        if (!this.popup.maxWidth) {
          this.popup.maxWidth = this.desktopMaxWidth;
        }

        if (window.innerWidth <= this.mobileWidth) {
          this.popup.maxWidth = this.mobileMaxWidth;
        }

        this.changeDetectorRef.detectChanges();
      })
    );
  }

  ngAfterViewInit() {
    this.popupEventsEmitter.broadcastPopupComponentViewInitialized(this.wrapper.nativeElement);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe);
  }

  onCancel() {
    this.popupEventsEmitter.broadcastPopupCancelClicked();
  }

  onSaveChanges() {
    if (this.popup.saveChangesDisabled) {
      return;
    }

    this.popupEventsEmitter.broadcastPopupSaveChangesClicked();
  }

  onClick(e: Event) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    if (this.popup.closeUponSelection) {
      this.popupEventsEmitter.broadcastPopupCancelClicked();
    }
  }

  get axisPosition() {
    if (this.popup) {
      return this.popup.arrowPosition.slice().pop();
    }
  }
}
