<ng-template #textareaTemplate>
  <textarea
    #textarea
    [(ngModel)]="value"
    [class.cool-custom-textarea-invalid]="!textareaIsPristine && !textareaIsValid"
    [class.textarea-label-shown]="valueLength"
    [required]="required"
    [minlength]="minLength"
    [maxlength]="maxLength"
    [rows]="rows"
    (blur)="onBlur($event)"
    inputValidation
    [condition]="!textareaIsPristine && !textareaIsValid"
    [error]="error"
    [autoFocusDirective]="autofocus"
    [disabled]="disabled"
    [style.resize]="preventResize ? 'none' : ''"
  >
  </textarea>
</ng-template>

<div class="cool-textarea" [class.cool-textarea-margin-bottom]="textareaIsPristine || textareaIsValid">
  <ng-template [ngTemplateOutlet]="textareaTemplate"></ng-template>
  <label>{{ placeholder }}</label>
  <span *ngIf="maxLength" class="cool-textarea-countdown">{{ valueLength + '/' + maxLength }}</span>
  <div class="helper-text" *ngIf="showHelperText">{{ helperText }}</div>
</div>
