import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-ng-block-screen',
  templateUrl: './block-screen.component.html',
  styleUrls: ['./block-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgBlockScreenComponent {
  visible: boolean;

  @Output() blockScreenClicked: EventEmitter<void> = new EventEmitter();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  get max32Bit(): number {
    const bits = (count: number) => Array.from(new Array(count), () => '1').join('');

    return parseInt(bits(32), 2);
  }

  get zIndexMax(): number {
    return (this.max32Bit - 1) / 2;
  }

  get zIndex(): number {
    // block screen uses max index - 2
    // dropdown uses max index - 1
    // tooltip and popup use max index
    return this.zIndexMax - 2;
  }

  show(): void {
    this.visible = true;
    this.changeDetectorRef.markForCheck();
  }

  hide(): void {
    this.visible = false;
    this.changeDetectorRef.markForCheck();
  }
}
