import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { JobsUtilities } from 'src/app/modules/incidents/utilities/job-utilities.util';
import { JobItemViewModel } from 'src/app/modules/incidents/viewModels/jobs/jobItemViewModel';
import { NgDropdownDirective } from '../../../directives/ngDropdown.directive';
import { FilterTypes } from '../../../enums/filterTypes';
import { JobStatuses } from '../../../enums/jobs/jobStatuses';
import { Constants } from 'src/app/modules/shared/models/constants';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { AllowedFiltersService } from '../../../services/allowed-filters.service';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-job-status-dropdown',
  templateUrl: 'job-status-dropdown.component.html',
  styleUrls: ['job-status-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobStatusDropdownComponent implements OnInit, OnChanges {
  @ViewChild(NgDropdownDirective, { static: false }) ngDropdownDirective: NgDropdownDirective;

  @Input() job: JobItemViewModel;
  @Input() jobFilters: FilterViewModel[];
  @Input() availablePriorityFilters: FilterViewModel[] = [];
  @Input() availableStatusFilters: FilterViewModel[] = [];
  @Input() showRating: boolean = false;
  @Input() showDropdown: boolean = true;
  @Input() canEdit: boolean = true;

  public jobStatuses = JobStatuses;
  public filterTypes = FilterTypes;
  public filters: FilterViewModel[] = [];
  public selectedStatusFilter: FilterViewModel;
  public selectedPriorityFilter: FilterViewModel;
  public readonly T = T;

  @Output() onFiltersUpdated = new EventEmitter<FilterViewModel[]>();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly allowedFiltersService: AllowedFiltersService
  ) {}
  ngOnInit(): void {
    this.filters = this.job.filters.slice();
    this.setStatusColours();
    this.setSelectedStatusFilter();
    this.setSelectedPriorityFilter();
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  public getStatusBoxColor(): string {
    return JobsUtilities.GetJobStatusColor(this.job.status);
  }

  public onApply() {
    this.onFiltersUpdated.emit(this.filters);
    this.changeDetectorRef.detectChanges();
    this.onCancel();
  }

  public onEntrySelected(entry: FilterViewModel) {
    const findFilterIndex: number = this.filters.findIndex((f) => f.filterType === entry.filterType);
    this.filters[findFilterIndex] = entry;
    entry.filterType == FilterTypes.Job_Status ? this.setSelectedStatusFilter() : this.setSelectedPriorityFilter();
  }

  public onCancel() {
    if (this.ngDropdownDirective) {
      this.ngDropdownDirective.onEscape();
    }

    this.changeDetectorRef.markForCheck();
  }

  public onClear(e) {
    this.filters = this.jobFilters.slice();
  }

  private setStatusColours() {
    this.availableStatusFilters.forEach((element) => {
      element.colour = JobsUtilities.GetJobStatusColor(element.filterValue);
    });
  }

  private setSelectedStatusFilter() {
    this.selectedStatusFilter = this.filters.find((x) => x.filterType == FilterTypes.Job_Status);
  }

  private setSelectedPriorityFilter() {
    this.selectedPriorityFilter = this.filters.find((x) => x.filterType == FilterTypes.Job_Priority);
  }

  get mobile(): boolean {
    return window.innerWidth <= Constants.xs;
  }
}
