import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constants';
import { ConfigurationService } from '../services/configuration.service';
import { LoginEventsEmitter } from '../events/login.events';
import { Account } from '../models/account';
import { map, Observable } from 'rxjs';

@Injectable()
export class AuthenticationManager {
  private currentAccount: Account;
  public serverWebForms: string;
  constructor(
    private readonly baseConfigurationService: ConfigurationService,
    private readonly configuration: Configuration,
    private readonly loginEventEmitter: LoginEventsEmitter
  ) {}

  public getConfiguration(): Observable<Account> {
    return this.baseConfigurationService.GetBaseConfiguration().pipe(
      map((a) => {
        this.currentAccount = a.accountData;
        this.loginEventEmitter.broadcastCurrentAccountChanged(this.currentAccount);

        this.configuration.mode = a.appMode;
        this.configuration.SetMaxFileSize(a.maxFileSize);
        this.configuration.SetAllowedUploadTypes(a.allowedUploadTypes);
        this.configuration.SetResourceFolderName(a.resourceFolderName);

        return a.accountData;
      })
    );
  }
}
