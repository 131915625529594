import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SeverityViewModel } from 'src/app/modules/shared/models/severityViewModel';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-severity-picker',
  templateUrl: './severity-picker.component.html',
  styleUrls: ['./severity-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeverityPickerComponent {
  @Input() severityCheckpoint: SeverityViewModel;
  @Input() useLargeBubble: boolean;
  @Input() isReadOnly: boolean = false;
  @Input() rectangleButton: boolean = false;
  @Output() severityCheckpointSelected: EventEmitter<SeverityViewModel> = new EventEmitter();
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef<HTMLElement>;
  lastlySelectedSeverityCheckpoint: SeverityViewModel;
  subscriptions: Subscription[] = [];
  public readonly T = T;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  onClick(e: MouseEvent) {
    e.stopPropagation();
    this.severityCheckpoint = Object.assign({}, this.severityCheckpoint);
  }

  onSave() {
    this.severityCheckpointSelected.emit((this.severityCheckpoint = this.lastlySelectedSeverityCheckpoint));
    this.onCancel();
    this.changeDetectorRef.markForCheck();
  }

  onCancel() {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
    window.dispatchEvent(new KeyboardEvent('keydown', { key: 'escape' }));
  }
}
