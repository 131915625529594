import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EmissionsByIndicatorViewModel } from '../../../viewModels/dashboard/emissionsOverview/emissionsByIndicatorViewModel';
import { CardTypes } from 'src/app/modules/shared/enums/cardTypes';

@Component({
  selector: 'app-card-indicator-emissions',
  templateUrl: './card-indicator-emissions.component.html',
  styleUrl: './card-indicator-emissions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardIndicatorEmissionsComponent {
  @Input() public emissionsData: EmissionsByIndicatorViewModel;
  public cardType: CardTypes = CardTypes.Indicator;
}
