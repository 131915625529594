import { Pipe, PipeTransform } from '@angular/core';
import { WtTranslationService } from '../../wt-translation/wt-translation.service';

@Pipe({
  name: 'keyPlural',
})
export class KeyPluralPipe implements PipeTransform {
  constructor(private wtTranslateService: WtTranslationService) {}

  transform(key: { one: string; many: string }, count: number): string {
    return this.wtTranslateService.pluralizeKey(key, count);
  }
}
