import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Configuration } from 'src/app/app.constants';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { Employee } from '../../../models/employee';
import { AuthenticationService } from '../../../services/authentication.service';
import { CachingService } from '../../../services/caching.service';
import { AccountSelectItemViewModel } from '../../../viewModels/accountSelectItemViewModel';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAccountComponent implements OnInit {
  @ViewChild('wrapper') wrapper: ElementRef<HTMLElement>;

  public employee: Employee;
  localUrl: string;

  constructor(
    readonly bsModalRef: BsModalRef,
    private readonly configuration: Configuration,
    private readonly authenticationService: AuthenticationService,
    private readonly authService: AuthService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly cachingService: CachingService
  ) {}

  ngOnInit() {
    this.localUrl = this.configuration.BackEndAPI;
    this.employee = this.authenticationService.getCurrentEmployee();
  }

  get accounts(): AccountSelectItemViewModel[] {
    if (!this.employee || !this.employee.accounts) {
      return [];
    }

    return this.employee.accounts;
  }

  onLoginWithAccount(account: AccountSelectItemViewModel) {
    if (!account.isActive) {
      return;
    }

    if (Capacitor.getPlatform() !== 'web' || this.configuration.configuration == 'development') {
      this.cachingService.setCurrentAccount(account);
      location.reload();
    } else {
      document.location.href = account.url;
    }
  }

  loggedInDays(account: AccountSelectItemViewModel): string {
    const prefix = 'Last logged in';
    const neverLoggedIn = 'Never Logged in';

    if (account.lastLoggedIn > 1) {
      return `${prefix} ${account.lastLoggedIn} days ago`;
    } else if (account.lastLoggedIn > -1 && account.lastLoggedIn <= 1) {
      if (!account.lastLoggedIn) {
        return `${prefix} today`;
      } else {
        return `${prefix} yesterday`;
      }
    } else if (account.lastLoggedIn < 0) {
      return neverLoggedIn;
    }
  }

  onLogout() {
    this.authService.logOut();
  }
}
