<div class="not-found-outer">
  <div class="not-found-inner">
    <div class="left-part">
      <div class="content">
        <img
          src="https://api.wetrack.com/Resource/assets/manifest/WeTrackLogo_Light.png"
          alt="WeTrack"
          style="width: 160px"
        />
        <br />
        <h2>
          <p><img src="https://api.wetrack.com/Resource/assets/images/offtrack.gif" class="hmmStyle" /></p>
          <a href="{{ link }}" class="btn"
            ><h2><u>Let us take you home</u></h2></a
          >
        </h2>
        <br />
        <h1><span class="tada">404</span><span class="errorTitle">ERROR</span></h1>
      </div>
      <div class="right-part"></div>
    </div>
  </div>
</div>
