import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Constants } from 'src/app/modules/shared/models/constants';

@Component({
  selector: 'app-traffic-layer-button',
  templateUrl: './traffic-layer-button.component.html',
  styleUrls: ['./traffic-layer-button.component.scss']
})
export class TrafficLayerButtonComponent implements OnInit {
  @Input() showLabel = false;
  @Input() map: google.maps.Map = null;

  public isMobile = false;

  private trafficLayer: google.maps.TrafficLayer = null;

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isMobile = window.innerWidth < Constants.xs;

    this.trafficLayer = new google.maps.TrafficLayer();
    this.trafficLayer.setMap(null);
  }

  get trafficLayerActive(): boolean {
    return this.trafficLayer.getMap() != null;
  }

  onClick() {
    if (this.trafficLayer.getMap() === null) {
      this.trafficLayer.setMap(this.map);
    } else {
      this.trafficLayer.setMap(null);
    }
  }

}
