import { Injectable } from '@angular/core';
import { AlertController, AlertInput, AlertOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { T } from 'src/assets/i18n/translation-keys';
import { Constants } from '../models/constants';
import { ConfirmModalThemes } from '../types/confirmModalTheme';
import { generateRandomNumber } from '../utilities/number.utilities';

export class ConfirmationMessage {
  headMessage: string;
  message: string;
  useCaptcha: boolean;
  confirm: () => void;
  cancel: () => void;
  theme: ConfirmModalThemes;
  cancelText: string;
  confirmText: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  private subject = new Subject<ConfirmationMessage>();
  private readonly T = T;
  constructor(private readonly translateService: TranslateService, private alertController: AlertController) {}

  confirm(c): void {
    this.confirmThis(c.message, c.confirm, c.cancel, c.headMessage, c.useCaptcha, c.cancelText, c.confirmText, c.theme);
  }

  confirmThis(
    message: string,
    confirm: () => void,
    cancel: () => void,
    headMessage?: string,
    useCaptcha?: boolean,
    cancelText?: string,
    confirmText?: string,
    theme?: ConfirmModalThemes
  ) {
    if (headMessage === undefined) {
      headMessage = this.translateService.instant(T.common.confirm);
    }

    if (useCaptcha === undefined) {
      useCaptcha = false;
    }

    if (window.innerWidth <= Constants.sm) {
      this.showMobileAlert(headMessage, message, useCaptcha, confirm, cancel, cancelText, confirmText);
    } else {
      this.showDesktopAlert(headMessage, message, useCaptcha, confirm, cancel, cancelText, confirmText, theme);
    }
  }
  private showDesktopAlert(
    headMessage: string,
    message: string,
    useCaptcha: boolean,
    confirm: () => void,
    cancel: () => void,
    cancelText: string,
    confirmText: string,
    theme: ConfirmModalThemes
  ) {
    this.subject.next({
      headMessage,
      message,
      useCaptcha,
      confirm: () => {
        // sending an empty value will close it
        this.subject.next(null);
        // execute the callback
        confirm();
      },
      cancel: () => {
        // sending an empty value will close it
        this.subject.next(null);
        // execute the callback
        cancel();
      },
      cancelText,
      confirmText,
      theme,
    });
  }

  getMessage(): Observable<ConfirmationMessage> {
    return this.subject.asObservable();
  }

  /**
   * Shows an alert on mobile devices with captcha (Confirmation code). The alert is shown via Ionic's AlertController.
   * In case when confirmation code is needed, the message is modified to include the confirmation code. And an input field is added to the alert.
   * If the confirmation code is not correct, a new simpler alert is shown to the user on top of the current one.
   */
  private showMobileAlert(
    headMessage: string,
    message: string,
    useCaptcha: boolean,
    confirm: () => void,
    cancel: () => void,
    cancelText: string = this.translateService.instant(T.common.cancel),
    confirmText: string = this.translateService.instant(T.common.confirm)
  ) {
    const modifiedMessage = useCaptcha
      ? `${message} ${this.translateService.instant(T.common.enter_following_confirm_code)}`
      : message;
    const captchaNumber = generateRandomNumber(4).toString();
    const options: AlertOptions = {
      header: headMessage,
      message: modifiedMessage,
      buttons: [
        {
          text: cancelText,
          role: 'cancel',
          handler: () => {
            cancel();
          },
        },
        {
          text: confirmText,
          role: 'confirm',
          handler: async (data) => {
            if (useCaptcha && data['captcha'] != captchaNumber) {
              const invalidCaptchaAlert = await this.alertController.create({
                header: this.translateService.instant(T.common.invalid_code),
                message: this.translateService.instant(T.common.confirm_code_is_not_correct),
                buttons: [this.translateService.instant(T.common.ok)],
              });
             void invalidCaptchaAlert.present();
              return false;
            }
            confirm();
          },
        },
      ],
    };

    if (useCaptcha) {
      const messageWithCaptcha = modifiedMessage.concat(`<span class="fw-bold"> ${captchaNumber}</span>`);
      const input: AlertInput = {
        name: 'captcha',
        placeholder: this.translateService.instant(T.common.confirmation_code),
        attributes: {
          maxlength: 4,
        },
      };
      options.inputs = [input];
      options.message = messageWithCaptcha;
    }

   void this.alertController.create(options).then((alert) => {
      void alert.present();
    });
  }
}
