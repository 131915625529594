import { capitalize } from 'src/app/modules/shared/utilities/string.utilities';

export enum MapViewUnit {
  FOCUS = 'focus',
  MAP = 'roadmap', //IMPORTANT!!!!
  SATELLITE = 'satellite',
  TERRAIN = 'terrain',
}

export interface IMapViewUnitItem {
  key: MapViewUnit;
  value: string;
}

export namespace MapViewUnit {
  export function items(): IMapViewUnitItem[] {
    const a: IMapViewUnitItem[] = [];
    for (const enumMember in MapViewUnit) {
      if (typeof MapViewUnit[enumMember] === 'string') {
        a.push({ key: MapViewUnit[enumMember] as MapViewUnit, value: capitalize(enumMember.replace(/_/g, ' ').toLowerCase()) });
      }
    }
    return a;
  }
}
