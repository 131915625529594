import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ScaleUnit } from '../../gantt/enums/scaleUnit';
import { DatePeriod } from '../models/dateRange';
import { InnerGanttType } from '../../gantt/enums/innerGanttType';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { GridColumn } from 'src/assets/Libs/jsLibs/dhxGantt/codebase/dhtmlxgantt';
import { GanttGeneralOptions } from '../../gantt/providers/generalOptions';
import { GroupBy } from '../../gantt/enums/groupBy';
import { EmployeeCustomGanttSettingViewModel } from '../models/gantt/ganttChartLayoutViewConfig';

@Injectable()
export class GanttEventsEmitter {
  private ganttLayoutViewChanged = new Subject<EmployeeCustomGanttSettingViewModel>();
  private ganttLayoutViewDeleted = new Subject<EmployeeCustomGanttSettingViewModel>();
  private ganttLayoutViewCreated = new Subject<EmployeeCustomGanttSettingViewModel>();
  private ganttLayoutViewUpdated = new Subject<EmployeeCustomGanttSettingViewModel>();
  private ganttLayoutViewApplied = new Subject<EmployeeCustomGanttSettingViewModel>();
  private ganttLayoutViewModified = new Subject<boolean>();

  private ganttSidebarToggled = new Subject<boolean>();
  private ganttFullscreenToggled = new Subject<boolean>();
  private ganttAddColumnToggled = new Subject<boolean>();
  private ganttGridInlineEditModeToggled = new Subject<boolean>();

  private ganttTasksExpandedFromChart = new Subject<boolean>();
  private ganttTasksExpandedFromSidebar = new Subject<boolean>();
  private ganttScaleUnitChanged = new Subject<ScaleUnit>();

  private ganttColumnsInitialized = new Subject<GridColumn[]>();
  private ganttColumnToggled = new Subject<GridColumn>();

  private ganttTaskDependencyContextChanged = new Subject<string>();
  private ganttTaskDependencyOnlyContextChanged = new Subject<string>();

  private ganttGeneralOptionToggled = new Subject<{id: GanttGeneralOptions, state: boolean}>();

  private projectsToggled = new Subject<boolean>();
  private taskGroupsToggled = new Subject<boolean>();
  private multiPageExportToggled = new Subject<boolean>();

  private exportToPdfHappened = new Subject<void>();
  private exportToPngHappened = new Subject<void>();

  private timelineRestrictionHappened = new Subject<DatePeriod>();
  private timelineDatesPeriodChanged = new Subject<DatePeriod>();

  private tasksTreeLevelVisibilityChanged = new Subject<InnerGanttType[]>();


  private ganttSelectedTaskRagStatusChanged = new Subject<number>();
  private ganttSelectedTaskDatesChanged = new Subject<any>();
  private ganttSelectedTaskFiltersChanged = new Subject<FilterViewModel[]>();

  private ganttReloadClicked = new Subject<void>();

  private ganttGroupByClicked = new Subject<string>();

  private preserveDatesSortingTypesChanged = new Subject<{ taskType: InnerGanttType; state: boolean }>();

  private preserveTaskOriginalDatesUponTimelineRestrictionChanged = new Subject<boolean>();

  ganttLayoutViewChanged$ = this.ganttLayoutViewChanged.asObservable();
  ganttLayoutViewDeleted$ = this.ganttLayoutViewDeleted.asObservable();
  ganttLayoutViewCreated$ = this.ganttLayoutViewCreated.asObservable();
  ganttLayoutViewUpdated$ = this.ganttLayoutViewUpdated.asObservable();
  ganttLayoutViewApplied$ = this.ganttLayoutViewApplied.asObservable();
  ganttLayoutViewModified$ = this.ganttLayoutViewModified.asObservable();

  ganttSidebarToggled$ = this.ganttSidebarToggled.asObservable();
  ganttFullscreenToggled$ = this.ganttFullscreenToggled.asObservable();
  ganttAddColumnToggled$ = this.ganttAddColumnToggled.asObservable();
  ganttGridInlineEditModeToggled$ = this.ganttGridInlineEditModeToggled.asObservable();

  ganttTasksExpandedFromChart$ = this.ganttTasksExpandedFromChart.asObservable();
  ganttTasksExpandedFromSidebar$ = this.ganttTasksExpandedFromSidebar.asObservable();
  ganttScaleUnitChanged$ = this.ganttScaleUnitChanged.asObservable();
  ganttColumnsInitialized$ = this.ganttColumnsInitialized.asObservable();
  ganttColumnToggled$ = this.ganttColumnToggled.asObservable();

  ganttTaskDependencyContextChanged$ = this.ganttTaskDependencyContextChanged.asObservable();
  ganttTaskDependencyOnlyContextChanged$ = this.ganttTaskDependencyOnlyContextChanged.asObservable();

  ganttGeneralOptionToggled$ = this.ganttGeneralOptionToggled.asObservable();
  projectsToggled$ = this.projectsToggled.asObservable();
  taskGroupsToggled$ = this.taskGroupsToggled.asObservable();
  multiPageExportToggled$ = this.multiPageExportToggled.asObservable();

  exportToPdfHappened$ = this.exportToPdfHappened.asObservable();
  exportToPngHappened$ = this.exportToPngHappened.asObservable();

  timelineRestrictionHappened$ = this.timelineRestrictionHappened.asObservable();
  timelineDatesPeriodChanged$ = this.timelineDatesPeriodChanged.asObservable();

  tasksTreeLevelVisibilityChanged$ = this.tasksTreeLevelVisibilityChanged.asObservable();

  ganttSelectedTaskRagStatusChanged$ = this.ganttSelectedTaskRagStatusChanged.asObservable();
  ganttSelectedTaskDatesChanged$ = this.ganttSelectedTaskDatesChanged.asObservable();
  ganttSelectedTaskFiltersChanged$ = this.ganttSelectedTaskFiltersChanged.asObservable();

  ganttReloadClicked$ = this.ganttReloadClicked.asObservable();

  ganttGroupByClicked$ = this.ganttGroupByClicked.asObservable();

  preserveDatesSortingTypesChanged$ = this.preserveDatesSortingTypesChanged.asObservable();

  preserveTaskOriginalDatesUponTimelineRestrictionChanged$ =
    this.preserveTaskOriginalDatesUponTimelineRestrictionChanged.asObservable();

  ganttRef: { getState: () => { fullscreen: boolean } };


  broadcastGanttLayoutViewModified(modified: boolean) {
    this.ganttLayoutViewModified.next(modified);
  }

  broadcastGanttLayoutViewApplied(layout: EmployeeCustomGanttSettingViewModel) {
    this.ganttLayoutViewApplied.next(layout);
  }

  broadcastGanttLayoutViewCreated(layout: EmployeeCustomGanttSettingViewModel) {
    this.ganttLayoutViewCreated.next(layout);
  }

  broadcastGanttLayoutViewUpdated(layout: EmployeeCustomGanttSettingViewModel) {
    this.ganttLayoutViewUpdated.next(layout);
  }

  broadcastGanttLayoutViewChanged(layout: EmployeeCustomGanttSettingViewModel) {
    this.ganttLayoutViewChanged.next(layout);
  }

  broadcastGanttLayoutViewDeleted(layout: EmployeeCustomGanttSettingViewModel) {
    this.ganttLayoutViewDeleted.next(layout);
  }

  broadcastGanttTaskDependencyContextChanged(context: string) {
    this.ganttTaskDependencyContextChanged.next(context);
  }

  broadcastGanttTaskDependencyOnlyContextChanged(context: string) {
    this.ganttTaskDependencyOnlyContextChanged.next(context);
  }

  broadcastGanttSidebarToggled(ganttSidebarToggled: boolean) {
    this.ganttSidebarToggled.next(ganttSidebarToggled);
  }

  broadcastGanttFullscreenToggled(ganttFullscreenToggled: boolean) {
    this.ganttFullscreenToggled.next(ganttFullscreenToggled);
  }

  broadcastFanttAddColumnToggled(ganttAddColumnToggled: boolean) {
    this.ganttAddColumnToggled.next(ganttAddColumnToggled);
  }

  broadcastGanttGridInlineModeToggled(ganttGridInlineModeToggled: boolean) {
    this.ganttGridInlineEditModeToggled.next(ganttGridInlineModeToggled);
  }

  broadcastGanttTasksExpandedFromChart(expanded: boolean) {
    this.ganttTasksExpandedFromChart.next(expanded);
  }

  broadcastGanttTasksExpandedFromSidebar(expanded: boolean) {
    this.ganttTasksExpandedFromSidebar.next(expanded);
  }

  broadcastScaleUnitChanged(scaleUnit: ScaleUnit) {
    this.ganttScaleUnitChanged.next(scaleUnit);
  }

  broadcastColumnsInitialized(columns: GridColumn[]) {
    this.ganttColumnsInitialized.next(columns);
  }


  broadcastGanttColumnToggled(column: GridColumn) {
    this.ganttColumnToggled.next(column);
  }


  broadcastGanttGeneralOptionToggled($event: {id: GanttGeneralOptions, state: boolean}) {
    this.ganttGeneralOptionToggled.next($event);
  }


  broadcastMultiPageExportToggled(multiPageExportToggled: boolean) {
    this.multiPageExportToggled.next(multiPageExportToggled);
  }

  broadcastExportToPdfHappened() {
    this.exportToPdfHappened.next();
  }

  broadcastExportToPngHappened() {
    this.exportToPngHappened.next();
  }

  broadcastTimelineRestrictionHappened(datePeriod: DatePeriod) {
    this.timelineRestrictionHappened.next(datePeriod);
  }

  broadcastTimelineDatesRangeChanged(datePeriod: DatePeriod) {
    this.timelineDatesPeriodChanged.next(datePeriod);
  }

  broadcastTaskTreeLevelVisibilityChange(innerGanttTypes: InnerGanttType[]) {
    this.tasksTreeLevelVisibilityChanged.next(innerGanttTypes);
  }

  broadcastGanttSelectedTaskRagStatusChanged(rag: number) {
    this.ganttSelectedTaskRagStatusChanged.next(rag);
  }

  broadcastGanttSelectedTaskDatesChanged(dates: any) {
    this.ganttSelectedTaskDatesChanged.next(dates);
  }

  broadcastGanttSelectedTaskFiltersChanged(filters: FilterViewModel[]) {
    this.ganttSelectedTaskFiltersChanged.next(filters);
  }

  broadcastGanttReloadClicked() {
    this.ganttReloadClicked.next();
  }

  broadcastGanttGroupByClicked(val: GroupBy) {
    this.ganttGroupByClicked.next(val);
  }

  broadcastGanttPreserveDatesSortingChanged(val: { taskType: InnerGanttType; state: boolean }) {
    this.preserveDatesSortingTypesChanged.next(val);
  }

  broadcastPreserveTaskOriginalDatesUponRestrictionChanged(state: boolean) {
    this.preserveTaskOriginalDatesUponTimelineRestrictionChanged.next(state);
  }
}
