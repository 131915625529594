<div class="breakdown-wrapper">
  <ng-container *ngIf="isEmptyState">
    <div
      class="rag-breakdown empty"
      *ngFor="let ragBreakdown of ragBreakdowns; let i = index; trackBy: trackByMethod"
      [style.width]="'20%'"
      [style.margin-right]="i < ragBreakdowns.length - 1 ? '1px' : '0px'"
    >
      <div *ngIf="i < ragBreakdowns.length - 1" class="separator"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isEmptyState">
    <div
      class="rag-breakdown"
      *ngFor="let ragBreakdown of ragBreakdowns; let i = index; trackBy: trackByMethod"
      [style.background-color]="ragBreakdown.colour"
      [style.width]="relativeWidths[i] + '%'"
      [style.margin-right]="i < ragBreakdowns.length - 1 && ragBreakdown.count > 0 ? '1px' : '0px'"
    >
      <div *ngIf="i < ragBreakdowns.length - 1" class="separator"></div>
    </div>
  </ng-container>
</div>
