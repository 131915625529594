import { capitalize } from 'src/app/modules/shared/utilities/string.utilities';

export enum DataBlock {
  CURRENTLY = 'currently',
  MINUTELY = 'minutely',
  HOURLY = 'hourly',
  DAILY = 'daily',
  ALERTS = 'alerts',
  FLAGS = 'flags',
}

export interface DataBlockItem {
  key: DataBlock;
  value: string;
}

export namespace DataBlock {
  export function items(): DataBlockItem[] {
    const a: DataBlockItem[] = [];
    for (const enumMember in DataBlock) {
      if (typeof DataBlock[enumMember] === 'string') {
        a.push({ key: DataBlock[enumMember] as DataBlock, value: capitalize(enumMember.replace(/_/g, ' ').toLowerCase()) });
      }
    }
    return a;
  }
}
