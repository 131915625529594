export enum TrackingViews {
  MyTrack = 'My Track',
  Incidents_List = 'Incidents List',
  Incident_Details = 'Incident Details',
  DepartmentCategories_List = 'Department Categories List',
  Departments_List = 'Departments List',
  IndicatorCategories_List = 'Indicator Categories List',
  Jobs_List = 'Jobs List',
  Logs_List = 'Logs List',
  Projects_List = 'Projects List',
  TaskGroups_List = 'Task Groups List',
  Tasks_List = 'Tasks List',
}
