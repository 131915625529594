import { Injectable } from '@angular/core';
import * as momentTZ from 'moment-timezone';
import { AuthenticationService } from './authentication.service';
import { Account } from '../models/account';
import * as moment from 'moment';
import { getTimezoneOffset } from '../utilities/date.utilities';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { WtTranslationService } from '../../wt-translation/wt-translation.service';
@Injectable()
export class TimeZoneService {
  private account: Account;
  private readonly defaultTimeZoneFormat: string = 'GMT Standard Time'; // 'GMT Standard Time';
  private readonly defaultDateFormat: string = 'DD/MM/YYYY';

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly translateService: TranslateService,
    private readonly wtTranslationService: WtTranslationService
  ) {
    this.account = this.authenticationService.getCurrentAccount();
  }

  /**
   * Convert incoming UTC formated date string into localised one
   * @param dateString UTC specified date string
   * @param includeTime include Time as returned string
   */
  public localiseDateISOString(dateISOString: string, includeTime: boolean, setTimeFirst: boolean, timeFormat = 'HH:mm'): string {
    let dateTimeFormat = this.dateFormat;
    if (includeTime) {
      dateTimeFormat = setTimeFirst ? `${timeFormat} ${dateTimeFormat}` : `${dateTimeFormat} ${timeFormat}`;
    }

    return momentTZ.tz(dateISOString, this.timeZoneFormat).format(dateTimeFormat);
  }

  public formatDateISOStringWithoutLocalisation(dateISOString: string, includeTime: boolean, setTimeFirst: boolean, timeFormat = 'HH:mm'): string {
    let dateTimeFormat = this.dateFormat;
    if (includeTime) {
      dateTimeFormat = setTimeFirst ? `${timeFormat} ${dateTimeFormat}` : `${dateTimeFormat} ${timeFormat}`;
    }

    return moment.utc(dateISOString).format(dateTimeFormat);
  }

  /**
   * Convert incoming UTC formated date string into localised js date object
   * @param dateISOString UTC specified date string
   */
  public localiseDateISOStringToDate(dateISOString: string): Date {
    return momentTZ.tz(dateISOString, this.timeZoneFormat).toDate();
  }

  /**
   * Localise date time by Account specific settings and reformat it by provided format
   * @param dateISOString
   * @param dateTimeFormat
   */
  public localiseDateISOStringByCustomFormat(dateISOString: string | Date, dateTimeFormat: string): string {
    if (dateTimeFormat && dateTimeFormat.length > 0) {
      const dayOfWeek = momentTZ
        .tz(dateISOString, this.timeZoneFormat)
        .locale(this.wtTranslationService.locale)
        .format(dateTimeFormat);
      const translatedDayOfWeek = this.translateDayOfWeek(dayOfWeek);
      return translatedDayOfWeek || dayOfWeek;
    } else {
      return momentTZ.tz(dateISOString, this.timeZoneFormat).locale(this.wtTranslationService.locale).toISOString();
    }
  }

  /**
   *
   * @param dateISOString iso date format
   */
  public localiseTimeISOString(dateISOString: string) {
    const timeFormat = 'HH:mm';
    return momentTZ.tz(dateISOString, this.timeZoneFormat).format(timeFormat);
  }

  /**
   * Get the proper timezone based on Account and User preferences
   */
  private get timeZoneFormat(): string {
    let timeZone = this.defaultTimeZoneFormat;

    if (this.currentAccount.accountSpecificTimeZone) {
      timeZone = this.currentAccount.accountSpecificTimeZone;
    }

    return this.sanitazeTimeZone(timeZone);
  }

  /**
   *  Get the proper date format based on Account and User preferences
   */
  public get dateFormat(): string {
    let dateFormat = this.defaultDateFormat;
    if (this.currentAccount.accountSpecificDateFormat) {
      dateFormat = this.currentAccount.accountSpecificDateFormat;
    }

    return this.sanitazeDateFormat(dateFormat);
  }

  /**
   * Sanitaze the time zone format as it is slightly different from the one used already in WF
   * @param timeZone Time zone format
   */
  public sanitazeTimeZone(timeZone: string): string {
    let sanitizedTimeZone: string = timeZone;

    if (timeZone.toLowerCase() === 'gmt standard time') {
      sanitizedTimeZone = 'Etc/GMT';
    }

    return sanitizedTimeZone;
  }

  private sanitazeDateFormat(dateFormat: string): string {
    return dateFormat.toUpperCase();
  }

  getDateAndDiffFromString(date: string): number {
    const now = moment(date);
    const then = moment(new Date(this.localiseDateISOStringByCustomFormat(date, 'YYYY-MM-DDTHH:mm:ss')));
    const diff = moment.duration(now.diff(then)).as('hours');
    return diff && !isNaN(diff) ? diff : 0;
  }

  getUTCOffset() {
    const now = moment.utc();
    const offsetInMins = momentTZ.tz.zone(this.timeZoneFormat).utcOffset(now.unix());
    return offsetInMins ? offsetInMins / 60 : 0;
  }

  convertToUTCDateString(dateAsStr: string, setDefaultTime: boolean = false) {
    const date = new Date(dateAsStr);
    const utcDiff = this.getUTCOffset();
    const hours = setDefaultTime ? 12 : date.getHours();
    const minutes = setDefaultTime ? 0 : date.getMinutes();
    date.setUTCHours(hours + utcDiff);
    date.setUTCMinutes(minutes);
    return date.toISOString();
  }

  get currentAccount() {
    this.account = this.authenticationService.getCurrentAccount();
    return this.account;
  }

  public unlocaliseDate(localisedDate: Date): Date {
    return new Date(localisedDate.getTime() + (this.getUTCOffset() * 3600 * 1000) - getTimezoneOffset(localisedDate));
  }

  /**
   * Use this to create new moment date according to the timezone
   */
  public getCurrentMomentInLocalisedTimezone(dateInput?: moment.MomentInput): moment.Moment {
    return momentTZ(dateInput).tz(this.timeZoneFormat);
  }

  public translateDayOfWeek(dayOfWeek: string): string {
    switch (dayOfWeek) {
      case 'Monday':
        return this.translateService.instant(T.calendar.days_of_week_full.monday);
      case 'Tuesday':
        return this.translateService.instant(T.calendar.days_of_week_full.tuesday);
      case 'Wednesday':
        return this.translateService.instant(T.calendar.days_of_week_full.wednesday);
      case 'Thursday':
        return this.translateService.instant(T.calendar.days_of_week_full.thursday);
      case 'Friday':
        return this.translateService.instant(T.calendar.days_of_week_full.friday);
      case 'Saturday':
        return this.translateService.instant(T.calendar.days_of_week_full.saturday);
      case 'Sunday':
        return this.translateService.instant(T.calendar.days_of_week_full.sunday);
      default:
        return null;
    }
  }
}
