import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum AddModalKeyboardShortcuts {
  Enter = 'Save and Close',
  CtrlEnter = 'Save and View Details',
  CtrlShiftEnter = 'Save and Add Another',
}

enum ShortcutKeys {
  Enter = 'Enter',
  CtrlEnter = 'Ctrl (CMD) + Enter',
  CtrlShiftEnter = 'Ctrl (CMD) + Shift + Enter',
}

function getTranslatedAddModalShortcuts(key: string, translateService: TranslateService) {
  switch (key) {
    case AddModalKeyboardShortcuts.Enter:
      return translateService.instant(T.common.save_and_close);
    case AddModalKeyboardShortcuts.CtrlEnter:
      return translateService.instant(T.common.save_and_view_details);
    case AddModalKeyboardShortcuts.CtrlShiftEnter:
      return translateService.instant(T.common.save_and_add_another);
  }
}

export namespace AddModalKeyboardShortcuts {
  // This will form new array with objects that contains the mapped value from the above enums
  export function getKeyShortsAsObj(translateService: TranslateService): { key: string; description: string }[] {
    const arr = [];
    Object.keys(AddModalKeyboardShortcuts)
      .filter((e) => typeof AddModalKeyboardShortcuts[e] !== 'function')
      .forEach((k) => {
        arr.push({
          key: ShortcutKeys[k],
          description: getTranslatedAddModalShortcuts(AddModalKeyboardShortcuts[k], translateService),
        });
      });
    return arr;
  }
}
