import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';
import { ModalBaseComponent } from 'src/app/modules/shared/components/modals/base/modalBaseComponent';
import { EmployeeRoleTypes } from 'src/app/modules/shared/enums/employees/EmployeeRoleTypes';
import { ImageExtensions } from 'src/app/modules/shared/enums/imageExtensions';
import { BsModalEventsEmitter } from 'src/app/modules/shared/events/bsModal.events';
import { Account } from 'src/app/modules/shared/models/account';
import { Employee } from 'src/app/modules/shared/models/employee';
import { ThreadedBead } from 'src/app/modules/shared/models/threadedBead';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { ModuleService } from 'src/app/modules/shared/services/module.service';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { EmployeeUtil } from 'src/app/modules/shared/utilities/employee.utilities';
import { Constants } from '../../../../../shared/models/constants';

@Component({
  selector: 'onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingModalComponent extends ModalBaseComponent implements OnInit, OnDestroy {
  @ViewChild('wrapper') protected readonly wrapper: ElementRef<HTMLElement>;

  currentAccount: Account;
  currentEmployee: Employee;
  logoUrl: string;
  helpCenterImage: string;
  liveSupportImage: string;
  moduleSelectorImage: string;
  currentStep: number = 1;
  loading: boolean = false;
  moduleTypes: ModuleTypes[] = [
    ModuleTypes.Planning,
    ModuleTypes.Risk,
    ModuleTypes.Incidents,
    ModuleTypes.Runsheets,
    ModuleTypes.Sustainability,
  ];
  beads: ThreadedBead[] = [
    { icon: 'notification_important', description: 'Notifications', active: true },
    { icon: 'help', description: 'Help & Support', active: false },
    { icon: 'check_circle', description: 'Get Started', active: false },
  ];
  termsOfUseChecked: boolean = false;

  constructor(
    public readonly bsModalRef: BsModalRef,
    protected readonly bsModalEventsEmitter: BsModalEventsEmitter,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private readonly urlService: UrlService,
    private readonly moduleService: ModuleService,
    private router: Router,
    private readonly accountSettingsService: AccountSettingsService,
    private readonly employeeSettingsService: EmployeeSettingsService
  ) {
    super(true, bsModalRef, bsModalEventsEmitter);
  }

  protected rendered() {}

  ngOnInit() {
    this.currentAccount = this.authenticationService.getCurrentAccount();
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.logoUrl = this.urlService.buildResourceUrl(this.currentAccount.localLogoURL);
    this.helpCenterImage = this.urlService.buildResourceUrl('/assets/images/onboarding/v3/wetrack-access-help-centre.png');
    this.liveSupportImage = this.urlService.buildResourceUrl('/assets/images/onboarding/v3/wetrack-access-chat.png');
    this.moduleSelectorImage = this.urlService.buildResourceUrl('/assets/images/onboarding/v3/wetrack-module-selector.png');
    this.setDefaultCurrentStep();

    this.changeDetectorRef.detectChanges();
    this.updateBeads();
  }

  get mobile(): boolean {
    if (!this.wrapper) {
      return;
    }

    return this.wrapper.nativeElement.getBoundingClientRect().width <= Constants.xs;
  }

  setDefaultCurrentStep() {
    if(this.currentAccount.isHubAccount) {
      this.currentStep = 3;
      return;
    }

    if (!this.showNotificationsSection()) {
      this.currentStep = 2;
    } else {
      this.currentStep = 1;
    }
  }

  showNotificationsSection(): boolean {
    return (
      this.canSeeModule(ModuleTypes.Planning) ||
      this.canSeeModule(ModuleTypes.Risk) ||
      this.canSeeModule(ModuleTypes.Incidents) ||
      this.canSeeModule(ModuleTypes.Runsheets)
    );
  }

  onBeadClicked(bead: ThreadedBead) {
    const beadPosition = this.beads.findIndex((b) => b === bead) + 1;

    if (beadPosition === this.currentStep) {
      return;
    }

    this.currentStep = beadPosition;
    this.updateBeads();
    this.changeDetectorRef.markForCheck();
  }

  private updateBeads() {
    this.beads = this.beads.slice();
    this.beads.forEach((b) => (b.active = false));

    for (let i = 0; i < this.currentStep && this.currentStep < 3; i++) {
      this.beads[i].active = true;
    }

    if (this.currentStep === 3) {
      this.beads.forEach((b) => (b.active = true));
    }

    this.changeDetectorRef.markForCheck();
  }

  onContinue() {
    this.currentStep++;
    this.updateBeads();
    this.changeDetectorRef.markForCheck();
  }

  onDownloadGuidelines() {
    this.loading = true;
    this.changeDetectorRef.markForCheck();

    const downloadUrlCSV = this.accountSettingsService.getPDFHelpUrl();
    this.accountSettingsService.downloadPDFHelpFile(downloadUrlCSV).subscribe(
      (file) => {
        saveAs(`data:${file.contentType};base64,${file.contents}`, `${file.name}${file.extension}`);
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      },
      () => {
        this.loading = false;
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  canSeeModule(module: ModuleTypes): boolean {
    const isAdmin = EmployeeUtil.IsAdmin(this.currentEmployee);
    switch (module) {
      case ModuleTypes.Planning:
        return (
          this.currentAccount.usePlanning &&
          (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Planning_User))
        );
      case ModuleTypes.Risk:
        return (
          this.currentAccount.useRisk &&
          (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Risk_Manager))
        );
      case ModuleTypes.Incidents:
        return (
          this.currentAccount.useIMS && (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Incidents_User))
        );
      case ModuleTypes.Runsheets:
        return (
          this.currentAccount.useRunSheets &&
          (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Runsheets_User))
        );
      case ModuleTypes.Sustainability:
        return (
          this.currentAccount.useSustainability &&
          (isAdmin || EmployeeUtil.hasRole(this.currentEmployee, EmployeeRoleTypes.Sustainability_User))
        );
      default:
        return false;
    }
  }

  getModuleCount(): number {
    let moduleCount: number = 0;
    if (this.canSeeModule(ModuleTypes.Planning)) {
      moduleCount++;
    }
    if (this.canSeeModule(ModuleTypes.Risk)) {
      moduleCount++;
    }
    if (this.canSeeModule(ModuleTypes.Incidents)) {
      moduleCount++;
    }
    if (this.canSeeModule(ModuleTypes.Runsheets)) {
      moduleCount++;
    }
    if (this.canSeeModule(ModuleTypes.Sustainability)) {
      moduleCount++;
    }
    return moduleCount;
  }

  getModuleDescription(module: ModuleTypes) {
    return this.moduleService.getModuleDescription(module);
  }

  getModuleName(module: ModuleTypes) {
    return this.moduleService.getModuleName(module);
  }

  getModuleIcon(module: ModuleTypes) {
    return `/assets/images/modules/${this.moduleService.getModuleIcon(module, ImageExtensions.svg)}`;
  }

  getModuleAccessText() {
    const moduleCount = this.getModuleCount();
    return `${this.currentAccount.title} has given you access to ${moduleCount} ${moduleCount === 1 ? 'module' : 'modules'}.
    You can switch between these modules using the dropdown in the main navigation menu:`;
  }

  onTermsOfUseChecked(event: string) {
    this.termsOfUseChecked = event === 'checked';
    this.changeDetectorRef.detectChanges();
  }

  goToURL(module: ModuleTypes) {
    this.loading = true;
    this.changeDetectorRef.markForCheck();
    const route: string = this.moduleService.getModuleDefaultRoute(module);

    this.employeeSettingsService.completeOnboarding().subscribe((res) => {
      this.bsModalRef.hide();
      this.currentEmployee.onboarded = true;

      this.employeeSettingsService.getToken(this.currentEmployee.accountId).subscribe((res) => {
        this.loading = false;
        void this.router.navigate([route]);
      });
    });
  }

  getAccountTermsOfUse() {
    return this.urlService.getAccountTermsOfUseURL(this.currentAccount);
  }
}
