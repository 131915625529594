import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentEventsEmitter } from '../events/comments.events';
import { AlertService } from '../services/alert.service';
import { CommentsService } from '../services/comments.service';
import { Comment } from '../models/comment';
import { TranslateService } from "@ngx-translate/core";
import { T } from 'src/assets/i18n/translation-keys';

@Injectable()
export class CommentsManager {
  public readonly T = T;

  constructor(
    private readonly alertService: AlertService,
    private readonly commentsEventsEmitter: CommentEventsEmitter,
    public commentsService: CommentsService,
    private readonly translateService: TranslateService,
  ) {}

  public addComment(comment: Comment, showAlert: boolean): Observable<any[]> {
    return this.commentsService.addComment(comment).pipe(
      map((result) => {
        if (showAlert) {
          void this.alertService.success(this.translateService.instant(T.common.comment_added_success));
        }
        this.commentsEventsEmitter.broadcastCommentsSaved();
        return result;
      })
    );
  }
}
