<ng-container *ngIf="shouldShowFixedLozenges">
  <div class="fixed-filter-lozenges">
    <ng-container *ngIf="isFixedEventActive">
      <app-filters-lozenge-container
        [appliedFiltersByType]="appliedFixedEvent ? [appliedFixedEvent] : []"
        [allAllowedFilters]="allowedEventFilters"
        [isSingleSelect]="true"
        [isFixedFilter]="true"
        [filterTypeSelectorViewModels]="[eventFilterTypeSelector]"
        (filtersUpdated)="onFiltersChanged($event, filterTypes.Event)"
      >
      </app-filters-lozenge-container>
    </ng-container>
    <ng-container *ngIf="isFixedZoneActive">
      <app-filters-lozenge-container
        [appliedFiltersByType]="appliedFixedZone ? [appliedFixedZone] : []"
        [allAllowedFilters]="allowedZoneFilters"
        [isSingleSelect]="true"
        [isFixedFilter]="true"
        [filterTypeSelectorViewModels]="[zoneFilterTypeSelector]"
        (filtersUpdated)="onFiltersChanged($event, filterTypes.Zone)"
      >
      </app-filters-lozenge-container>
    </ng-container>
    <ng-container *ngIf="isFixedIncidentChannelActive">
      <app-filters-lozenge-container
        [appliedFiltersByType]="appliedIncidentChannel ? [appliedIncidentChannel] : []"
        [allAllowedFilters]="allowedIncidentChannelFilters"
        [isSingleSelect]="true"
        [isFixedFilter]="true"
        [filterTypeSelectorViewModels]="[incidentFilterTypeSelector]"
        (filtersUpdated)="onFiltersChanged($event, filterTypes.Incident_Channel)"
      >
      </app-filters-lozenge-container>
    </ng-container>
  </div>
</ng-container>
