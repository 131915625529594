<app-modal class="filters-add-container" [class.desktop-height]="!mobile">
  <app-filters-mobile-header
    *ngIf="mobile"
    [title]="T.common.add_filter | translate"
    (cancel)="onCancel()"
  ></app-filters-mobile-header>
  <div class="filters-header">
    <app-modal-header
      *ngIf="!mobile"
      [title]="T.common.select_filter | translate"
      (closed)="onCancel()"
    ></app-modal-header>
  </div>
  <app-filters-search-input (search)="onSearch($event)"></app-filters-search-input>

  <ng-template #entries>
    <div *ngFor="let entry of filteredUnitedFiltersAndFilterTypesViewModels">
      <!-- if entry has filterTypeText that means that entry is of type FilterTypeSelectorViewModel -->
      <div class="filters-add-entry" *ngIf="$any(entry).filterTypeText" (click)="onAddFilter(entry)">
        {{ $any(entry).filterTypeText }}
      </div>
      <!-- else if entry has filterText that means that entry is of type FilterViewModel -->
      <div class="filters-add-entry" *ngIf="$any(entry).filterText" (click)="onTagGroupFilterSelected(entry)">
        {{ $any(entry).filterText }}
      </div>
    </div>
  </ng-template>

  <div class="filters-add-mobile-container" *ngIf="mobile">
    <ng-template [ngTemplateOutlet]="entries"></ng-template>
  </div>
  <div class="filters-add-list custom-scrollbar" *ngIf="!mobile">
    <ng-container>
      <ng-template [ngTemplateOutlet]="entries"></ng-template>
    </ng-container>
  </div>
  <div class="show-all-filters-btn" *ngIf="!isAllFiltersShowed">
    <app-button-bar-rounded
      fitToContainer="true"
      [text]="T.common.show_all_filters | translate"
      (clicked)="onShowAllFilters()"
    ></app-button-bar-rounded>
  </div>
</app-modal>
