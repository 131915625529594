import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Configuration } from 'src/app/app.constants';
import {
  TableHeader,
  DataType,
} from 'src/app/modules/shared/components/common/responsive-table/responsive-table/responsive-table.component';
import { NgImagePreviewDirective } from 'src/app/modules/shared/directives/ngImagePreview.directive';
import { DocumentTypes } from 'src/app/modules/shared/enums/documentTypes';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { UploadTypes } from 'src/app/modules/shared/enums/uploadTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { LocalisationService } from 'src/app/modules/shared/services/localisation.service';
import { TimeZoneService } from 'src/app/modules/shared/services/timeZone.service';
import { UrlService } from 'src/app/modules/shared/services/url.service';
import { DocumentCategoryViewModel } from 'src/app/modules/shared/viewModels/documents/documentCategoryViewModel';
import { DocumentViewModel } from 'src/app/modules/shared/viewModels/documents/documentViewModel';
import { UploadViewModel } from 'src/app/modules/shared/viewModels/documents/uploadViewModel';
import { AttachmentsUtilities } from '../../../utilities/attachments.utilities';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-attachments-list-view',
  templateUrl: 'attachments-list-view.component.html',
  styleUrls: ['attachments-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsListViewComponent implements OnInit {
  @ViewChild('attachmentFileName') attachmentFileName: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild('attachmentActions') attachmentActions: TemplateRef<ElementRef<HTMLElement>>;
  @ViewChild(NgImagePreviewDirective) ngImagePreview: NgImagePreviewDirective;

  @Input() canDelete: boolean;
  @Input() isModal: boolean;
  @Input() accountId: number;
  @Input() objectId: number;
  @Input() objectType: ObjectTypes;
  @Input() documents: DocumentViewModel[];
  @Input() uploadViewModel: UploadViewModel;
  @Input() documentCategories: DocumentCategoryViewModel[];

  @Output() attachmentClicked = new EventEmitter<object>();
  @Output() attachmentDeleted = new EventEmitter<object>();
  @Output() attachmentDownloaded = new EventEmitter<object>();
  @Output() editAttachment = new EventEmitter<object>();
  @Output() onAttachmentLinkClicked = new EventEmitter<DocumentViewModel>();

  public attachmentTypes = DocumentTypes;
  private users: FilterViewModel[] = [];
  private latestClickedAttachmetnType: DocumentTypes;
  private T = T;

  constructor(
    protected readonly translateService: TranslateService,
    private readonly configuration: Configuration,
    private readonly urlService: UrlService,
    protected readonly allowedFiltersService: AllowedFiltersService,
    protected readonly timeZoneService: TimeZoneService,
    private readonly localisationService: LocalisationService
  ) {}

  ngOnInit() {
    this.users = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.Employee);
  }

  public onAttachmentClicked(attachment: DocumentViewModel): void {
    this.latestClickedAttachmetnType = this.getAttachmentDocumentType(attachment);
    if (this.latestClickedAttachmetnType == DocumentTypes.Image) {
      const image = this.documentUrl(attachment);
      this.ngImagePreview.openDirectiveFromComponent(attachment, this.latestClickedAttachmetnType, image);
    } else if (this.latestClickedAttachmetnType == DocumentTypes.Link) {
      this.onAttachmentLinkClicked.next(attachment);
    } else {
      this.ngImagePreview.openDirectiveFromComponent(null, null, null);
      this.attachmentClicked.next(attachment);
    }
  }

  public getDocumentIcon(attachment: DocumentViewModel): string {
    return AttachmentsUtilities.getDocumentIcon(
      AttachmentsUtilities.getDocumentType(attachment).type,
      AttachmentsUtilities.getDocumentType(attachment).ext,
      '20px'
    );
  }

  public documentUrl(document: DocumentViewModel): string {
    if (document) {
      let resourseUrl = document.filename;
      if (resourseUrl) {
        resourseUrl = resourseUrl.toLowerCase().startsWith(this.configuration.ResourceFolderName.toLowerCase())
          ? resourseUrl
          : `/${this.configuration.ResourceFolderName}/${this.accountId}/${resourseUrl}`;
      }
      return document.uploadType === UploadTypes.File ? this.urlService.buildResourceUrl(resourseUrl) : document.filename;
    }
  }

  public getAttachmentDocumentType(attachment: DocumentViewModel): DocumentTypes {
    return AttachmentsUtilities.getDocumentType(attachment).type;
  }

  public attachmentsTableHeaders(): TableHeader[] {
    const tableHeaders: TableHeader[] = [];
    tableHeaders.push(
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.common.filename),
        template: this.attachmentFileName,
      },
      {
        dataType: DataType.String,
        title: this.translateService.instant(T.common.date_uploaded),
        property: 'created',
        propertyFunction: (object) => {
          return this.timeZoneService.localiseDateISOString(object['created'], true, true);
        },
      },
      {
        dataType: DataType.String,
        title: this.translateService.instant(T.common.uploaded_by),
        property: 'createdById',
        propertyFunction: (object) => {
          const creator = this.users.find((x) => +x.filterValue=== object['createdById']);
          return creator ? creator.filterText : '';
        },
      },
      {
        dataType: DataType.Template,
        title: this.translateService.instant(T.common.action.many),
        template: this.attachmentActions,
      }
    );

    return tableHeaders;
  }

  localiseObjectType(objectType: ObjectTypes) {
    return this.localisationService.localiseObjectType(objectType);
  }

  hasDetailsPageLink(document: DocumentViewModel) {
    const detailsLink = this.urlService.getLinkToDetailsPage(
      document.globalObjectId,
      document.globalObjectType,
      document.globalObjectSubType
    );
    return detailsLink.length > 0;
  }

  detailsPageUrl(document: DocumentViewModel) {
    if (document) {
      this.urlService.navigateToObjectDetailsPage(
        document.globalObjectType,
        document.globalObjectSubType,
        document.globalObjectId
      );
    }
  }

  downloadAttachment(event: Event, attachment: DocumentViewModel) {
    if (this.getAttachmentDocumentType(attachment) !== DocumentTypes.Link) {
      event.stopPropagation();
      this.attachmentDownloaded.emit(attachment);
    }
  }

  deleteAttachment(event: Event, attachment: DocumentViewModel) {
    event.stopPropagation();
    this.attachmentDeleted.emit(attachment);
  }
  onEditClicked(event: Event, attachment: DocumentViewModel) {
    event.stopPropagation();
    this.editAttachment.emit(attachment);
  }
}
