import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChildren,
  QueryList,
  ElementRef,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { debounceTime, Subject, Subscription } from 'rxjs';

import { WindowEventsEmitter } from '../../../events/window.events';
import { DragulaService } from 'ng2-dragula';
import { PaginationManager, PaginationModel } from '../../../managers/paginationManager';
import { ModifiableEntityViewModel } from 'src/app/modules/incidents/viewModels/modifiableEntityViewModel';
import { DataTable } from '@pascalhonegger/ng-datatable';

@Component({
  selector: 'app-paginated-cards',
  templateUrl: './paginated-cards.component.html',
  styleUrls: ['./paginated-cards.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatedCardsComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('dataTable', { static: true }) dataTable: DataTable;
  @ViewChildren('row') rows: QueryList<ElementRef<HTMLElement>>;

  @Input() paginationId: string;
  @Input() items: ModifiableEntityViewModel[];
  @Input() cardTemplate: TemplateRef<HTMLElement>;
  @Input() draggable = false;
  @Input() pageChangeSubject: Subject<number> = new Subject();
  @Input() itemsPerPage = 20;
  @Input() currentPage = 1;
  @Input() scrollToIndex: number;
  @Input() showPagination: boolean = true;
  @Input() applyPadding: boolean = true;
  @Input() trackScrollPosition = false;
  /**
   * Property used to optimize rendering via comparing the objects to check which should be rerendered.
   * If the property is not provided NgFor will fall back to the default behavior of comparing the objects by reference.
   */
  @Input() trackByProperty: string;

  @Output() itemsPerPageChange = new EventEmitter<number>();
  @Output() currentPageChange = new EventEmitter<number>();
  @Output() firstCardInViewChange = new EventEmitter<number>();
  @Output() onDrag = new EventEmitter<object[]>();

  readonly scrollEventDebounceTime: number = 300;

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly windowEventsEmitter: WindowEventsEmitter,
    public readonly changeDetectorRef: ChangeDetectorRef,
    private readonly dragulaService: DragulaService,
    private readonly paginationManager: PaginationManager
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.paginationId) {
      return;
    }

    const model = this.paginationManager.getPaginationModel(this.paginationId);

    if (!model) {
      return;
    }

    if (changes.items?.currentValue && changes.items?.currentValue?.length !== changes.items?.previousValue?.length) {
      model.itemsCount = changes.items.currentValue.length;
    }

    if (changes.itemsPerPage?.currentValue && changes.itemsPerPage?.currentValue !== changes.itemsPerPage?.previousValue) {
      model.itemsPerPage = changes.itemsPerPage.currentValue;
    }

    if (changes.currentPage?.currentValue && changes.currentPage?.currentValue !== changes.currentPage?.previousValue) {
      model.currentPage = changes.currentPage.currentValue;
    }

    this.paginationManager.updateModel(model);
  }

  ngOnInit() {
    if (!this.paginationId) {
      this.paginationId = this.paginationManager.generateID();
    }

    this.paginationManager.addModel(this.paginationId, this.itemsPerPage, this.items.length, this.currentPage);

    this.subscriptions.add(
      this.paginationManager.paginationModels$.subscribe((res) => {
        const currentModel = res.find((m) => m.id === this.paginationId);
        if (currentModel) {
          if (currentModel.itemsPerPage !== this.itemsPerPage) {
            this.itemsPerPage = currentModel.itemsPerPage;
            this.itemsPerPageChange.emit(currentModel.itemsPerPage);
          }

          if (currentModel.currentPage !== this.currentPage) {
            this.currentPage = currentModel.currentPage;
            this.dataTable.setPage(currentModel.currentPage, this.itemsPerPage);
            this.currentPageChange.emit(currentModel.currentPage);
          }

          this.changeDetectorRef.markForCheck();
        }
      })
    );

    this.initPageChangeHandlers();

    if (this.draggable) {
      this.initDrag();
    }

    this.subscriptions.add(
      this.dataTable.onPageChange.subscribe((event) => {
        if (isNaN(event.activePage)) {
          this.dataTable.setPage(this.currentPage, this.itemsPerPage);
        }
      })
    );
  }

  ngAfterViewInit(): void {
    if (this.trackScrollPosition) {
      this.subscriptions.add(
        this.windowEventsEmitter.windowScrollEventTriggered$.pipe(debounceTime(this.scrollEventDebounceTime)).subscribe(() => {
          this.rows.some((row, i) => {
            if (this.isInViewport(row.nativeElement)) {
              this.firstCardInViewChange.emit(i);
              return true;
            }

            return false;
          });
        })
      );
    }

    this.subscriptions.add(
      this.paginationManager.scrolltoId$.subscribe((res) => {
        if (this.paginationId === res.paginationId) {
          const paginationModel = this.paginationManager.getPaginationModel(res.paginationId);
          const indexForItemId = this.items.findIndex((item) => item.id === res.itemId);
          if (!indexForItemId) {
            return;
          }
          this.openPaginationPage(paginationModel, indexForItemId);
          this.changeDetectorRef.markForCheck();

          const element = document.getElementById(`${this.paginationId}_${res.itemId}`);
          if (element) {
            element.scrollIntoView({
              behavior: 'auto',
              block: 'nearest'
            });
          }
        }
      })
    );

    this.subscriptions.add(
      this.paginationManager.scrolltoIndex$.pipe(debounceTime(0)).subscribe((res) => {
        if (this.paginationId === res.paginationId) {
          const paginationModel = this.paginationManager.getPaginationModel(res.paginationId);
          this.openPaginationPage(paginationModel, res.index);
          this.changeDetectorRef.markForCheck();
          const pageIndexToScroll = res.index % paginationModel.itemsPerPage;
          const childElement = document.getElementsByClassName(`${this.paginationId}_i_${pageIndexToScroll}`)[0];
          if (childElement) {
            childElement.scrollIntoView();
          }
        }
      })
    );

    if (this.scrollToIndex) {
      this.paginationManager.broadcastScrollToIndex(this.paginationId, this.scrollToIndex);
    }
  }

  private isInViewport(el: HTMLElement) {
    const bounding = el.getBoundingClientRect();

    return (
      bounding.width > 0 &&
      bounding.height > 0 &&
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= window.innerWidth &&
      bounding.bottom <= window.innerHeight
    );
  }

  /**
   * If the index is not on the current opened page, this method will open the page that contains the index
   */
  private openPaginationPage(paginationModel: PaginationModel, index: number) {
    const maxIndexOnOpenedPage = paginationModel.currentPage * paginationModel.itemsPerPage;
    const minIndexOnOpenedPage = maxIndexOnOpenedPage - paginationModel.itemsPerPage;
    const isItemOnOpenedPage = index >= minIndexOnOpenedPage && index <= maxIndexOnOpenedPage;
    if (isItemOnOpenedPage) {
      return;
    }

    let pageToOpen = Math.floor(index / paginationModel.itemsPerPage) + 1;
    pageToOpen = pageToOpen === 0 ? 1 : pageToOpen;

    paginationModel.currentPage = pageToOpen;
    this.paginationManager.updateModel(paginationModel);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.paginationManager.deleteModel(this.paginationId);
  }

  onScroll(e: Event) {
    this.windowEventsEmitter.broadcastWindowScrollEventTriggered(e);
  }

  private initPageChangeHandlers() {
    this.subscriptions.add(
      this.pageChangeSubject.subscribe((page) => {
        const model = this.paginationManager.getPaginationModel(this.paginationId);
        if (model) {
          model.currentPage = page;
          this.paginationManager.updateModel(model);
        }
      })
    );
  }

  private initDrag() {
    this.subscriptions.add(
      this.dragulaService.drop('templateItems').subscribe(({ name, el, target, source, sibling }) => {
        this.onDrag.next(this.items);
      })
    );
  }

  get totalPages(): number {
    return this.items.length / this.itemsPerPage;
  }

  /**
   * Angular trackBy function which is used to track the items in the ngFor loop, so that the DOM is not re-rendered when the items change.
   * If the trackByProperty is set, the trackBy function will use that property to track the items.
   * If the trackByProperty is not set, the trackBy function will use the item itself to track the items (default behavior).
   *
   * Must be arrow function to preserve the context of this (this.trackByProperty otherwise is not accessible).
   */
  public trackByFn = (index: number, item: unknown): unknown => {
    if (this.trackByProperty) {
      return item[this.trackByProperty];
    }
    return item;
  };
}
