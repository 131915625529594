import { Injectable } from '@angular/core';
import Smartlook from 'smartlook-client';
import {
  Smartlook as SmartlookMobile,
} from '@awesome-cordova-plugins/smartlook/ngx/index';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { TrackingViews } from '../enums/tracking-view.enum';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private isEnabled = false;
  private startingTime: Date;
  private hasBeenInitialized = false;

  constructor(
    private smartlookMobile: SmartlookMobile,
    private navigationService: NavigationService
    ) {
    // If we are NOT on premise, and we are in production, then enable tracking
    // This is to prevent tracking on staging and development environments
    // Also, we don't want to track on premise environments
    if(!environment.isOnPremise && environment.CONFIGURATION === 'production') {
      this.isEnabled = true;
    }
  }

  public init(): void {
    if (!this.isEnabled) {
      return;
    }

    if (Capacitor.getPlatform() === 'web') {
      Smartlook.init('f4f54e69584b0dcba66d795e69d01f105d92ad4c', { region: 'eu' });
    } else {
      this.smartlookMobile.start();
    }
  }

  public trackLoadedNavigationEvent(url: string): void {
    if (!this.isEnabled) {
      return;
    }

    if (Capacitor.getPlatform() === 'web') {
      Smartlook.navigation('loaded/' + url);
    } else {
      this.smartlookMobile.trackNavigationEnter({eventName: 'loaded/' + url});
    }
  }

  public setStartingTime(): void {
    this.startingTime = new Date();
  };

  public trackInitializedView(viewType: TrackingViews){
    if(!(Capacitor.getPlatform() === 'web' && this.isEnabled)){
      return;
    }

    if(!this.hasBeenInitialized){
      const timeToInitializeInSeconds = (new Date().getTime() - this.startingTime.getTime()) / 1000;
      Smartlook.track('App_Initialized', {viewType: viewType, timeToInitialize: timeToInitializeInSeconds.toFixed(2)});
      this.hasBeenInitialized = true;
    } else {
      const durationInSeconds = (new Date().getTime() - this.navigationService.navigationStartTime.getTime()) / 1000;
      Smartlook.track('View_Loaded', {
        navigationChange: `${this.navigationService.previousUrl} --> ${viewType}`,
        duration: durationInSeconds.toFixed(2),
      })
    }
  }
}
