import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountSettingsService } from 'src/app/modules/shared/services/account-settings.service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { LocationViewModel } from 'src/app/modules/shared/viewModels/locationViewModel';
import { GeoLocationService } from 'src/app/modules/shared/services/geo-location.service';
import { Account } from 'src/app/modules/shared/models/account';

import { WeatherDataService } from 'src/app/modules/shared/services/weather-data.service';
import { DashboardEventEmitters } from 'src/app/modules/shared/events/dashboard.events';
import { TemperatureOptions } from 'src/app/modules/shared/enums/temperatureOptions';
import { WeatherWidgetTemperatureTypes } from 'src/app/modules/shared/enums/weatherTemperatureTypes';
import { EmployeeSettingsService } from 'src/app/modules/shared/services/employee-settings.service';
import { Employee } from 'src/app/modules/shared/models/employee';
import { EmployeeSettingTypes } from 'src/app/modules/settings/enums/employeeSettingTypes';
import { DataBlock } from 'src/app/modules/shared/enums/weather/darkSkyApi/DataBlock';

@Component({
  selector: 'app-dashboard-weather-new',
  templateUrl: './dashboard-weather-new.component.html',
  styleUrls: ['./dashboard-weather-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWeatherComponentNew implements OnInit, OnDestroy {
  @ViewChild('dropdownWeatherlocations') dropdownWeatherlocations: ElementRef<HTMLElement>;
  @ViewChild('forecastContainer') forecastContainer: ElementRef<HTMLElement>;
  subscriptions = new Subscription();
  weatherLocations: LocationViewModel[] = [];
  weatherLocationSelected: LocationViewModel = null;
  account: Account;
  loading: boolean = false;
  loadingMessage: string = 'Loading...';
  employee: Employee;
  weatherAPIResponse: any = null;
  dailyData: any[] = [];
  temperatureOption = TemperatureOptions.Farenheit;

  constructor(
    private accountSettingsService: AccountSettingsService,
    private authService: AuthenticationService,
    private changeDetector: ChangeDetectorRef,
    private geoLocationService: GeoLocationService,
    private weatherDataService: WeatherDataService,
    private dashboardEventEmitters: DashboardEventEmitters,
    private readonly employeeSettingsService: EmployeeSettingsService
  ) {}

  onResized(ev: Event) {
    this.getProperClass();
  }

  ngOnInit() {
    this.account = this.authService.getCurrentAccount();
    this.employee = this.authService.getCurrentEmployee();
    this.getWeatherLocations();
    this.getTemperatureOption();
    this.subscriptions.add(
      this.dashboardEventEmitters.locationSettingsChanged$.subscribe(() => {
        this.getWeatherLocations();
      })
    );

    this.subscriptions.add(
      this.dashboardEventEmitters.temperatureSettingChanged$.subscribe((res) => {
        this.temperatureOption = +res.value;
        this.changeDetector.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getTemperatureOption() {
    this.subscriptions.add(
      this.employeeSettingsService.getEmployeeSetting(EmployeeSettingTypes.Temperature_Option).subscribe((res) => {
        this.temperatureOption = +res.value;
        this.changeDetector.markForCheck();
      })
    );
  }

  getWeatherLocations() {
    this.loading = true;
    this.subscriptions.add(
      this.accountSettingsService.getIncidentDashboardWeatherSettings().subscribe((weather) => {
        if (weather == null || weather.length == 0) {
          this.loadingMessage = 'Finding your location… this may take a while';
          this.changeDetector.markForCheck();

          this.geoLocationService.getPositionFromGoogleGeolocationAPI().subscribe((response: any) => {
            if (this.weatherLocations.length == 0) {
              const myWeatherLocation = new LocationViewModel(response.location.lat, response.location.lng, 'Your Location');
              this.weatherLocations.push(myWeatherLocation);
              this.weatherLocationSelected = myWeatherLocation;
              this.getOpenWeatherData();
            }
          });

          //This one is slow in some situations so currently it is commented.
          // this.geoLocationService.getPosition().subscribe(
          //   (pos: Position) => {
          //     if(this.weatherLocations.length == 0) {
          //       var myWeatherLocation = new LocationViewModel(pos.coords.latitude, pos.coords.longitude, 'Your Location');
          //       this.weatherLocations.push(myWeatherLocation);
          //       this.weatherLocationSelected = myWeatherLocation;
          //       this.getDarkSkyWidgets();
          //       this.getDarkSkyWeatherData();
          //     }
          //   })
        } else {
          this.weatherLocations = weather.map((w) => w.location);
          this.weatherLocationSelected = weather.filter((w) => w.isDefault == true).map((l) => l.location)[0];
          if (!this.weatherLocationSelected) {
            this.weatherLocationSelected = this.weatherLocations[0];
          }
          this.getOpenWeatherData();
        }
      })
    );

    // Listen for stored locations being added
    this.subscriptions
      .add
      // this.incidentEventsEmitter.dashboardWeatherLocationsUpdated$
      //   .subscribe(weather => {
      //     if (weather != null) {
      //       this.weatherLocations = weather.map(w => w.location);
      //     }
      //   })
      ();
  }

  getOpenWeatherData() {
    if (this.weatherLocationSelected && this.weatherLocationSelected.latitude && this.weatherLocationSelected.longitude) {
      const lat = this.weatherLocationSelected.latitude;
      const lng = this.weatherLocationSelected.longitude;
      this.subscriptions.add(
        this.weatherDataService.getWeatherDataFromOpenWeatherApi(lat, lng, [DataBlock.MINUTELY]).subscribe((response: any) => {
          if (response) {
            this.weatherAPIResponse = response;
            this.dailyData = response.daily.slice(0, response.daily.length - 1);
            this.dailyData.forEach((dayData) => {
              dayData.dt *= 1000;
            });
          }

          this.loading = false;
          this.changeDetector.detectChanges();
        })
      );
    } else {
      this.loading = false;
    }
  }

  getWeatherIcon(item: any) {
    return `http://openweathermap.org/img/wn/${item.weather[0].icon}@4x.png`;
  }

  getFormattedTemperature(type: WeatherWidgetTemperatureTypes, item: string): string {
    let temp = 0;
    if (type === WeatherWidgetTemperatureTypes.Current) {
      temp = parseInt(this.weatherAPIResponse.currently.temperature, 10);
    } else if (type === WeatherWidgetTemperatureTypes.Min) {
      temp = parseInt(this.weatherAPIResponse.daily.data[0].temperatureLow, 10);
    } else if (type === WeatherWidgetTemperatureTypes.Max) {
      temp = parseInt(this.weatherAPIResponse.daily.data[0].temperatureHigh, 10);
    } else if (item != null) {
      temp = parseInt(item, 10);
    }
    let defaultPrefix = 'F';
    if (this.temperatureOption === TemperatureOptions.Celsius) {
      temp = parseInt(this.farenheitToCelsius(temp).toString(), 10);
      defaultPrefix = 'C';
    }
    return temp.toString() + '°' + defaultPrefix;
  }

  farenheitToCelsius(fahrenheit: number): number {
    return ((fahrenheit - 32) * 5) / 9;
  }

  getDailyMinTemp(item): number {
    return Math.round(item.temp.min);
  }

  getDailyMaxTemp(item): number {
    return Math.round(item.temp.max);
  }

  getWeekDayName(dateMillis: number): string {
    const date = new Date(dateMillis);
    const dayOfWeek = date.getDay();

    let weekDayName = '';
    switch (dayOfWeek) {
      case 0:
        weekDayName = 'Sun';
        break;
      case 1:
        weekDayName = 'Mon';
        break;
      case 2:
        weekDayName = 'Tue';
        break;
      case 3:
        weekDayName = 'Wed';
        break;
      case 4:
        weekDayName = 'Thu';
        break;
      case 5:
        weekDayName = 'Fri';
        break;
      case 6:
        weekDayName = 'Sat';
        break;
      default:
        break;
    }

    return weekDayName;
  }

  private getProperClass(): void {
    if (!this.forecastContainer) {
      return;
    }

    const { width } = this.forecastContainer.nativeElement.getBoundingClientRect();
    this.adjustWidget(width, -1);
  }

  private adjustWidget(widgetWidth: number, widgetHeight: number) {
    if (!this.forecastContainer) {
      return;
    }

    if (widgetWidth > 730) {
      Array.from(this.forecastContainer.nativeElement.classList).forEach((el) => {
        if (el.startsWith('r')) {
          this.forecastContainer.nativeElement.classList.remove(el);
        }
      });
    } else if (widgetWidth > 700 && widgetWidth <= 730) {
      Array.from(this.forecastContainer.nativeElement.classList).forEach((el) => {
        if (el.startsWith('r')) {
          this.forecastContainer.nativeElement.classList.remove(el);
        }
      });
      this.forecastContainer.nativeElement.classList.add('r730');
    } else if (widgetWidth > 620 && widgetWidth <= 700) {
      Array.from(this.forecastContainer.nativeElement.classList).forEach((el) => {
        if (el.startsWith('r')) {
          this.forecastContainer.nativeElement.classList.remove(el);
        }
      });
      this.forecastContainer.nativeElement.classList.add('r700');
    } else if (widgetWidth > 560 && widgetWidth <= 620) {
      Array.from(this.forecastContainer.nativeElement.classList).forEach((el) => {
        if (el.startsWith('r')) {
          this.forecastContainer.nativeElement.classList.remove(el);
        }
      });
      this.forecastContainer.nativeElement.classList.add('r620');
    } else if (widgetWidth > 480 && widgetWidth <= 560) {
      Array.from(this.forecastContainer.nativeElement.classList).forEach((el) => {
        if (el.startsWith('r')) {
          this.forecastContainer.nativeElement.classList.remove(el);
        }
      });
      this.forecastContainer.nativeElement.classList.add('r560');
    } else if (widgetWidth > 0 && widgetWidth <= 480) {
      Array.from(this.forecastContainer.nativeElement.classList).forEach((el) => {
        if (el.startsWith('r')) {
          this.forecastContainer.nativeElement.classList.remove(el);
        }
      });
      this.forecastContainer.nativeElement.classList.add('r480');
    }
  }

  private nowDateRoundedToNearestHour(): Date {
    const date = new Date();
    const h = date.getHours() + date.getMinutes() / 60 + date.getSeconds() / 3600 + date.getMilliseconds() / 3600000;
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    date.setHours(Math.round(h));

    return date;
  }

  onWeatherLocationSelected(location: LocationViewModel) {
    if (this.weatherLocationSelected !== location) {
      this.weatherLocationSelected = location;
      this.dropdownWeatherlocations.nativeElement.innerText = location.locationDetails;
    }
    this.dropdownWeatherlocations.nativeElement.dispatchEvent(new Event('click'));
  }
}
