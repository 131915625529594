import { ObjectTypes } from '../enums/objectTypes';
import { UpdateTypes } from '../enums/updateTypes';

export class UpdateTypeUtilities {
  /* Returns Comment Type based on object Type  */
  public static GetCommentTypeByObjectType(objectType: ObjectTypes): UpdateTypes {
    switch (objectType) {
      case ObjectTypes.IncidentItem:
        return UpdateTypes.Incident_Comment_Added;
      case (ObjectTypes.Task, ObjectTypes.Task_Group, ObjectTypes.Project):
        return UpdateTypes.Task_Comment_Added;
      case ObjectTypes.Risk:
        return UpdateTypes.Risk_Comment_Added;
      case ObjectTypes.PEAP_Check:
        return UpdateTypes.Check_Comment_Added;
      case ObjectTypes.Venue:
        return UpdateTypes.Venue_Comment_Added;
      case ObjectTypes.Runsheet:
        return UpdateTypes.Runsheet_Comment_Added;
      case ObjectTypes.Indicator:
        return UpdateTypes.Indicator_Comment_Added;
      case ObjectTypes.Risk_Action_Item:
        return UpdateTypes.Risk_Action_Comment_Added;
      case ObjectTypes.Indicator_Update:
        return UpdateTypes.Indicator_Update_Comment_Added;
      default:
        return UpdateTypes.None;
    }
  }
}
