<div #wrapper class="wrapper">
  <div class="responsive-flex-wrapper" (click)="onClick()">
    <app-bubble [large]="useLargeBubble" [color]="'#424647'" [icon]="icon"> </app-bubble>
  </div>
  <div class="picker-information-wrapper">
    <div class="label">{{ label }}</div>
    <div class="description" *ngIf="totalCount > 0">
      {{ T.common.count_of_total_complete | translate: { count: completedCount, total: totalCount } }}
    </div>
    <div class="description" *ngIf="!totalCount">{{ T.common.no_elements | translate }}</div>
  </div>
</div>
