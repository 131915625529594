<app-filters-mobile-header
  *ngIf="mobile"
  [title]="filterType | localiseFilterType"
  [useClear]="true"
  [clearDisabled]="!localAppliedFilters.length"
  (cancel)="onCancel()"
  (clear)="onClearAll()"
></app-filters-mobile-header>

<div class="filters-header" *ngIf="!mobile">
  <app-modal-header
    [title]="
      ' ' +
      ((singleSelect ? T.common.item_filter : T.common.item_filters)
        | translate: { item: filterType | localiseFilterType })
    "
    (closed)="onCancel()"
  ></app-modal-header>
</div>

<app-filters-include-comment
  [placeholder]="placeholder"
  *ngIf="includeComment"
  [includeComment]="commentIsRequired"
  (commentChanged)="onCommentChanged($event)"
  (includeCommentChanged)="changeCurrentIncludeCommentState($event)"
></app-filters-include-comment>

<app-filters-list-entries
  [filterType]="filterType"
  [entries]="filters"
  [selectedEntries]="localAppliedFilters"
  [mobile]="mobile"
  [orderBy]="orderBy"
  (entrySelected)="onEntrySelected($event)"
></app-filters-list-entries>

<app-filters-desktop-footer
  *ngIf="!mobile && showFooter"
  [useCancelApplyButtons]="true"
  [clearDisabled]="!localAppliedFilters.length"
  [applyDisabled]="!isInputValid()"
  (clear)="onClearAll()"
  (cancel)="onCancel()"
  (apply)="onApply()"
></app-filters-desktop-footer>

<app-filters-mobile-footer
  *ngIf="mobile && showFooter"
  [applyDisabled]="!isInputValid()"
  (apply)="onApply()"
></app-filters-mobile-footer>
