<app-modal *ngIf="!loading">
  <app-modal-header
    style="z-index: 50"
    [title]="T.common.item_details | translate: { item: localisedItem }"
    [showDelete]="false"
    (closed)="onClose()"
  >
  </app-modal-header>
  <app-modal-body>
    <div class="header-info">
      <div class="left-side">
        <i class="material-symbols-outlined">receipt</i>
        <span class="fw-bold">{{createdByUserName}}</span>
        <span style="color: var(--wt-grey-dark);">&nbsp;at&nbsp;</span>
        <span class="fw-bold">{{currentDetailsItem.created | localisedDate: true : true}}</span>
      </div>
      <div class="right-side">
        {{currentDetailsItem.created | lastUpdatedDate}}
      </div>
    </div>
    <ng-container *ngIf="!isMobile">
      <app-details-header
      [item]="currentDetailsItem"
      [canEdit]="userHasWriteAccess"
      [localisedItem]="localisedItem"
      [showBackNavigation]="false"
      [showRefCode]="false"
      [showMoreButton]="false"
      [showId]="false"
      (titleUpdated)="onTitleUpdate($event)"
      (descriptionUpdated)="updateDescription($event)"
      [useSimpleTitleEdit]="true"
      [showDescription]="true"
      >
      </app-details-header>
    </ng-container>
    <ng-container  *ngIf="isMobile">
      <div class="mt-2">
        <app-simple-editable-field
        [input]="currentDetailsItem.title"
        [canEdit]="userHasWriteAccess"
        (onUpdate)="onTitleUpdate($event)"
        ></app-simple-editable-field>
      </div>
      <div class="mt-2">
        <app-details-text-area
          [input]="currentDetailsItem.description ? currentDetailsItem.description : ''"
          [label]="T.defaultLocalizations.description.one | translate"
          [placeholder]="T.common.add_description | translate"
          [canEdit]="userHasWriteAccess"
          (update)="updateDescription($event)"
        ></app-details-text-area>
      </div>
    </ng-container>
  <div class="details-container">
    <div class="details-row row">
      <div class="col-5 details-label pe-0">
        {{ T.common.category.one | translate }}
      </div>
      <div class="col-7 details-value">
        <app-details-lozenge
          [filters]="currentDetailsItem.filters"
          [filterType]="filterTypes.Incident_Category"
          [canEdit]="userHasWriteAccess"
          class="w-100"
          [accordionLozenge]="true"
          [useLightUpdate]="true"
          (onFiltersUpdated)="onFiltersUpdated($event)"
        >
        </app-details-lozenge>
      </div>
    </div>
    <div class="details-row row">
      <div class="col-5 details-label pe-0">
        {{ filterTypes.Department | localiseFilterType: true }}
      </div>
      <div class="col-7 details-value">
        <app-details-lozenge
          class="w-100"
          [filters]="currentDetailsItem.filters"
          [filterType]="filterTypes.Department"
          [canEdit]="userHasWriteAccess"
          [useLightUpdate]="true"
          (onFiltersUpdated)="onFiltersUpdated($event)"
          [accordionLozenge]="true"
        >
        </app-details-lozenge>
      </div>
    </div>
    <div class="details-row row">
      <div class="col-5 details-label pe-0">{{ filterTypes.Owner | localiseFilterType: true }}</div>
      <div class="col-7 details-value">
        <app-details-lozenge
        class="w-100"
        [filters]="currentDetailsItem.filters"
        [filterType]="filterTypes.Owner"
        [canEdit]="userHasWriteAccess"
        [accordionLozenge]="true"
        [useLightUpdate]="true"
        (onFiltersUpdated)="onFiltersUpdated($event)"
        >
      </app-details-lozenge>
      </div>
    </div>
    <div class="details-row row">
      <div class="col-5 details-label pe-0">
        {{ filterTypes.Privacy_Status | localiseFilterType }}
      </div>
      <div class="col-7 details-value">
        <app-details-lozenge
          class="w-100"
          [filters]="currentDetailsItem.filters"
          [filterType]="filterTypes.Privacy_Status"
          [canEdit]="userHasWriteAccess"
          [singleSelect]="true"
          [isFilterRequired]="true"
          [accordionLozenge]="true"
          [useLightUpdate]="true"
          (onFiltersUpdated)="onFiltersUpdated($event)"
        >
        </app-details-lozenge>
      </div>
    </div>
  </div>
  <div class="details-accordion">
    <app-accordion-details [collapsing]="false" (onItemIndexClicked)="onItemIndexClicked($event)">
      <app-accordion-item-details [title]="T.hub.simple_modals_accordeon | translate" [expanded]="false">
        <ng-template accordionContent>
          <div class="additional-details">
            <div class="details-row row">
              <div class="col-5 details-label pe-0">
                {{ objectTypes.User_Group | localiseObjectType: true }}
              </div>
              <div class="col-7 details-value">
                <app-details-lozenge
                  class="w-100"
                  [accordionLozenge]="true"
                  [filters]="currentDetailsItem.filters"
                  [filterType]="filterTypes.User_Group"
                  [canEdit]="userHasWriteAccess"
                  [useLightUpdate]="true"
                  (onFiltersUpdated)="onFiltersUpdated($event)"
                >
                </app-details-lozenge>
              </div>
            </div>
            <div class="details-row row">
              <div class="col-5 details-label pe-0">
                {{ objectTypes.Event | localiseObjectType }}
              </div>
              <div class="col-7 details-value">
                <app-details-lozenge
                  class="w-100"
                  [accordionLozenge]="true"
                  [filters]="currentDetailsItem.filters"
                  [filterType]="filterTypes.Event"
                  [canEdit]="userHasWriteAccess"
                  [singleSelect]="true"
                  [useLightUpdate]="true"
                  (onFiltersUpdated)="onFiltersUpdated($event)"
                >
                </app-details-lozenge>
              </div>
            </div>
            <div class="details-row row">
              <app-details-tags
                [accordionTagTitleClass]="'col-5'"
                [accordionTagValueClass]="'col-7'"
                [canEdit]="userHasWriteAccess"
                [accordionLozenge]="true"
                [filters]="currentDetailsItem.filters"
                [tagGroupObjectType]="objectType"
                [useLightUpdate]="true"
                (onFiltersUpdated)="onFiltersUpdated($event)"
              ></app-details-tags>
            </div>
            <div class="details-row row">
              <div class="col-5 details-label pe-0">{{ T.calendar.starts | translate }}</div>
              <div class="col-7 details-value">
                <app-details-datepicker
                  [range]="true"
                  [timePicker]="true"
                  [includeTime]="true"
                  [canEdit]="userHasWriteAccess"
                  [date]="currentDetailsItem.startTime"
                  [endDate]="currentDetailsItem.endTime"
                  [maxDate]="currentDetailsItem.endTime"
                  [accordionLozenge]="true"
                  [label]="T.calendar.start | translate"
                  (dateChanged)="onStartDateChanged($event)"
                  (datesChanged)="onDatesChanged($event)"
                >
                </app-details-datepicker>
              </div>
            </div>
            <div class="details-row row">
              <div class="col-5 details-label pe-0">{{ T.lozenges_label.date_related.due | translate }}</div>
              <div class="col-7 details-value">
                <app-details-datepicker
                  [range]="true"
                  [timePicker]="true"
                  [includeTime]="true"
                  [canEdit]="userHasWriteAccess"
                  [date]="this.currentDetailsItem.endTime"
                  [startDate]="this.currentDetailsItem.startTime"
                  [endDate]="this.currentDetailsItem.endTime"
                  [minDate]="this.currentDetailsItem.startTime"
                  [label]="T.defaultLocalizations.end_time.one | translate"
                  [accordionLozenge]="true"
                  (dateChanged)="onEndDateChanged($event)"
                  (datesChanged)="onDatesChanged($event)"
                >
                </app-details-datepicker>
              </div>
            </div>
            <div class="details-row row">
              <div class="col-5 details-label pe-0">{{ T.common.id | translate }}</div>
              <div class="col-7 details-value">
                <span style="padding-left: 4px">
                  {{ currentDetailsItem.id }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </app-accordion-item-details>
      <app-accordion-item-details>
        <ng-template accordionTitle>
          <app-accordion-title-with-counter
            [title]="T.common.location.one | translate"
            [count]="locationFieldsCount">
          </app-accordion-title-with-counter>
        </ng-template>
        <ng-template accordionContent>
          <div *ngIf="locationContainerDirty" class="location-container mb-3">
            <app-location-details
              [objectId]="currentDetailsItem.id"
              [filters]="currentDetailsItem.filters"
              [lat]="currentDetailsItem.latitude"
              [lng]="currentDetailsItem.longitude"
              [markerType]="markerType"
              [markerColor]="markerColor"
              [displayFiltersAsLozenges]="false">
            </app-location-details>
            <div class="mt-3 mb-3" *ngIf="isAdmin">
              <app-button-rectangle
                [text]="T.common.edit_location | translate"
                icon="edit"
                buttonTheme="white"
                [disabled]="false"
                (clicked)="openLocationEditModal()"
              ></app-button-rectangle>
            </div>
          </div>
        </ng-template>
      </app-accordion-item-details>
      <app-accordion-item-details>
        <ng-template accordionTitle>
          <app-accordion-title-with-counter
            [title]="T.common.linked_item.many | translate"
            [count]="linkedItemsCount">
          </app-accordion-title-with-counter>
        </ng-template>
        <ng-template accordionContent>
          <div>
            <app-linked-items-accordion-list
              (linkedItemsCount)="linkedItemsCount = $event"
              [canAddLinkedItems]="userHasWriteAccess"
              [id]="currentDetailsItem.id"
              [title]="currentDetailsItem.title"
              [objectType]="objectTypes.IncidentItem"
            ></app-linked-items-accordion-list>
          </div>
        </ng-template>
      </app-accordion-item-details>
      <app-accordion-item-details [title]="T.common.history | translate" [expanded]="false">
        <ng-template accordionContent>
          <div *ngIf="historyExpanded" class="history-feed-wrapper">
            <app-history-feed-new
            *ngIf="historyExpanded"
            [canEdit]="userHasWriteAccess"
            [showInput]="true"
            [objectId]="currentDetailsItem.id"
            [objectType]="objectType"
            [showHeader]="false"
            [singleItemHistory]="true"
            (historyEntriesCount)="onHistoryEntriesUpdate($event)"
          >
          </app-history-feed-new>
          </div>
        </ng-template>
      </app-accordion-item-details>
      <app-accordion-item-details [expanded]="selectedAccordionItemId === 'attachments'">
        <ng-template accordionTitle>
          <app-accordion-title-with-counter
            [title]="T.common.attachment.many | translate"
            [count]="attachmentsCount">
          </app-accordion-title-with-counter>
        </ng-template>
        <ng-template accordionContent>
          <div class="details-container">
            <app-document-manager-general-settings
              (documentsChanged)="onDocumentsChanged($event)"
              [objectId]="currentDetailsItem?.id"
              [objectType]="objectType"
              [object]="currentDetailsItem"
              [filterType]="filterTypes.Job"
              [isInAccordion]="true"
              [isDetailsPage]="true"
              [showListBoardButtons]="false">
            </app-document-manager-general-settings>
          </div>
        </ng-template>
      </app-accordion-item-details>
    </app-accordion-details>
  </div>
  </app-modal-body>
  <app-modal-footer>
    <div class="footer-buttons">
      <app-button-icon-square
      [icon]="'more_horiz'"
      ngDropdown
      [templateRef]="moreButtonTemplate"
      ></app-button-icon-square>
      <app-button-rectangle [size]="'large'"  [text]="T.common.close | translate" (clicked)="onClose()"></app-button-rectangle>
    </div>
  </app-modal-footer>
</app-modal>

<ng-template #moreButtonTemplate>
  <div class="dropdown-options-container">
    <div *ngIf="!currentDetailsItem.archived" (click)="onAddUpdate()" class="dropdown-option">{{ addCommentText }}</div>
    <div *ngIf="!currentDetailsItem.archived && userHasWriteAccess" (click)="onMakeIncident()" class="dropdown-option">
      {{ T.control.escalate_log_to_incident | translate }}
    </div>
    <div *ngIf="!currentDetailsItem.archived" (click)="onArchiveIncident(true)" class="dropdown-option">
      {{ archiveText }}
    </div>
    <div *ngIf="currentDetailsItem.archived" (click)="onArchiveIncident(false)" class="dropdown-option">
      {{ unarchiveText }}
    </div>
  </div>
</ng-template>
