export enum ReportTypeNew {
  Tasks = 1,
  Projects = 2,
  Action_Steps = 3,
  Department_Category_Summary = 4,
  Department_Summary = 5,
  Employees = 6,
  History = 7,
  Logs = 8,
  Risks_Issues_Opportunities = 9,
  Task_Group_Summary = 10,
  Incident_Category_Summary = 12,
  Incidents = 13,
  Zone_Summary = 14,
  Impact_Type_Summary = 15,
  Incident_Logs = 16,

  Run_Sheet_Items = 23,
  Indicators = 24,
  Achievements = 25,
  Jobs = 26,
  Job_Checklists = 27,
  Indicator_Updates = 28,
  Indicator_Templates = 30,
  Public_Incident_Reports = 31
}
