import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[autoFocusDirective]',
})
export class AutofocusDirective implements AfterViewInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('autoFocusDirective') autoFocusDirective: boolean;

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit() {
    if (!this.autoFocusDirective) {
      return;
    }

    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 500);
  }
}
