import { ShapePointViewModel } from './shapePointViewModel';

export class ZoneViewModel {
  id: number;
  accountId: number;
  title: string;
  itemZoneId: number;
  primary: boolean;
  zoneShapePoints?: ShapePointViewModel[];

  constructor(_zoneId: number, _accountId: number, _title: string, _itemZoneId?: number, _primary?: boolean) {
    this.id = _zoneId;
    this.accountId = _accountId;
    this.title = _title;
    this.itemZoneId = _itemZoneId;
    this.primary = _primary;
  }
}
