import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AuthGuardService } from '../../../auth/auth-guard.service';
import { Observable, of, Observer } from 'rxjs';
import { EmployeeSettingsService } from '../../services/employee-settings.service';
import { EmployeeRoleTypes } from '../../enums/employees/EmployeeRoleTypes';
import { EmployeeUtil } from '../../utilities/employee.utilities';

@Injectable()
export class IMSGuard{
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly authGuardService: AuthGuardService,
    private readonly employeeSettingsService: EmployeeSettingsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authGuardService.canActivate(route, state)) {
      const account = this.authService.getCurrentAccount();
      const employee = this.authService.getCurrentEmployee();
      if (!account || !employee) {
        return new Observable<boolean>((observer: Observer<boolean>) => {
          this.employeeSettingsService.getLoggedInEmployeeForGuards().subscribe((res) => {
            const result = this.checkPermissions(state.url);

            observer.next(result);
          });
        });
      } else {
        const result = this.checkPermissions(state.url);
        return of(result);
      }
    } else {
      return of(false);
    }
  }

  checkPermissions(currentUrl:string): boolean {
    let result = false;
    const account = this.authService.getCurrentAccount();
    const employee = this.authService.getCurrentEmployee();
    const hasIncidentUserRole =
      EmployeeUtil.hasRole(employee, EmployeeRoleTypes.Incidents_User) || EmployeeUtil.IsAdmin(employee);

    if (!account.isActive) {
      void this.router.navigateByUrl('v2/unauthorized');
    }

    let useIMS = (account.useIMS || account.useIMSOnly);


    if(useIMS && account.usePublicIncidentReporting && currentUrl.includes('control/incident-reports')){
      result = true;
    }
    else if((!useIMS || !account.usePublicIncidentReporting) && currentUrl.includes('control/incident-reports')){
      void this.router.navigateByUrl('v2/control/list');
    }


    if ( useIMS && hasIncidentUserRole) {
      result = true;
    }



    if (!result) {
      void this.router.navigateByUrl('v2/mytrack/planning');
    }

    return result;
  }
}
