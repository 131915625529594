<!--Details pages-->
<ng-container *ngIf="ragDropdownType === 'Badge'">
  <ng-container *ngIf="!disabled">
    <div class="icon-filter-container" ngDropdown [templateRef]="filterTemplate">
      <div
      class="status-box-details-page"
      [style.background-color]="currentFilterColour"
    >
      <div [ngClass]="['status-text-details-page', hideArrowIcon ? '' : 'separator']">
        {{ragDescription}}
      </div>
      <div class="drodpdown-arrow" [hidden]="hideArrowIcon">
        <i class="material-symbols-outlined">keyboard_arrow_down</i>
      </div>
    </div>
    </div>
  </ng-container>
  <ng-container *ngIf="disabled">
    <app-badge [label]="ragDescription" [customColor]="currentFilterColour" color="custom" variant="fat"></app-badge>
  </ng-container>
</ng-container>

<!--Cards-->
<ng-container *ngIf="ragDropdownType === 'Icon'">
  <ng-container *ngIf="!disabled">
    <ng-container *ngIf="showEditDropdownIcon">
      <div class="icon-filter-container" ngDropdown [templateRef]="filterTemplate">
        <div class="icon-wrapper">
          <app-details-icon [type]="iconType" [size]="iconSize" [backgroundFill]="currentFilterColour"></app-details-icon>
          <i class="material-symbols-outlined">arrow_drop_down</i>
        </div>
        <ng-container *ngIf="showInfo" [ngTemplateOutlet]="infoTemplate"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="!showEditDropdownIcon">
      <div class="icon-filter-container" ngDropdown [templateRef]="filterTemplate">
        <div class="rag-dropdown-icon">
          <app-card-icon
            [ragStatus]="+selectedEntry.filterValue"
            [cardType]="taskCardType"
            ngDropdown
            [templateRef]="filterTemplate"
          ></app-card-icon>
        </div>
        <ng-container *ngIf="showInfo" [ngTemplateOutlet]="infoTemplate"></ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="disabled">
    <app-card-icon
      [ragStatus]="+selectedEntry.filterValue"
      [cardType]="taskCardType">
    </app-card-icon>
  </ng-container>
</ng-container>


<ng-template #filterTemplate>
  <div class="filter-wrapper" [class.filters-mobile]="mobile" (click)="$event.stopPropagation()">
    <app-filters-mobile-header
      *ngIf="mobile"
      [title]="filterType | localiseFilterType"
      [useClear]="true"
      [clearDisabled]="!appliedFilters.length"
      (cancel)="onCancel()"
      (clear)="onClear()"
    >
    </app-filters-mobile-header>

    <div class="include-comment-wrapper" [ngClass]="{ 'border-bottom': !includeComment }">
      <div class="filters-include-comment-checkbox">
        <app-checkbox-with-label
          [label]="T.common.include_comment | translate"
          [intialState]="includeComment ? 'checked' : 'unchecked'"
          [disabled]="isRagRequiringCommentSelected"
          (clicked)="changeState()"
        ></app-checkbox-with-label>
      </div>
      <app-text-area
        *ngIf="includeComment"
        [rows]="4"
        [preventResize]="true"
        [maxLength]="200"
        [placeholder]="commentPlaceholder + '*'"
        [required]="true"
        [errorMessage]="T.common.you_must_include_comment_to_continue | translate"
        [(ngModel)]="comment"
        [autofocus]="true"
      ></app-text-area>
    </div>

    <div class="warning-message-wrapper" *ngIf="warningMessage.length > 0">
      <div><i class="material-symbols-outlined red" style="vertical-align: middle">warning</i></div>
      <div class="warning-message">{{ warningMessage }}</div>
    </div>

    <app-filters-list-entries
      [filterType]="filterType"
      [entries]="availableFilters"
      [selectedEntries]="[selectedEntry]"
      [mobile]="mobile"
      (entrySelected)="onEntrySelected($event)"
    ></app-filters-list-entries>

    <app-filters-desktop-footer *ngIf="!mobile && displayFooter"
      [useCancelApplyButtons]="true"
      [clearDisabled]="!appliedFilters.length"
      [applyDisabled]="!isInputValid"
      (clear)="onClear()"
      (cancel)="onCancel()"
      (apply)="onApply()"
    ></app-filters-desktop-footer>

    <app-filters-mobile-footer *ngIf="mobile && displayFooter"
      [applyDisabled]="!isInputValid"
      (apply)="onApply()"
    >
    </app-filters-mobile-footer>
  </div>
</ng-template>

<ng-template #infoTemplate>
  <div class="filter-container">
    <div class="heading">{{ infoLabel }}:</div>
    <div class="description">{{ currentFilter?.filterText }}</div>
  </div>
</ng-template>

<!-- <div *ngIf="mobile && mobileFilterVisible" class="dropdown-mobile">
    <div class="dropdown-mobile-content">
        <ng-template [ngTemplateOutlet]="filterTemplate"></ng-template>
    </div>
</div> -->
