import { ModuleTypes } from '../../settings/enums/moduleTypes';

export class NavigationItem {
  name: string;
  url: string;
  isRemoteUrl: boolean;
  icon: string;
  isDisabled: boolean;
  children: NavigationItem[];
  target: string;
  showOnMobile: boolean;
  iconClasses: string[];
  idAttr: string;
  moduleType: ModuleTypes;
  isGrouped: boolean;
  /**
   * this is module related
   */
  defaultUrl: string;
  hasSharedElements = false;

  constructor(
    name: string,
    url: string,
    icon: string,
    isDisabled: boolean,
    idAttr: string,
    target = '_self',
    hasSharedElements = false
  ) {
    this.name = name;
    this.url = url;
    this.icon = icon;
    this.isDisabled = isDisabled;
    this.children = [];
    this.isRemoteUrl = false;
    this.target = target;
    this.showOnMobile = true;
    this.idAttr = idAttr;
    this.isGrouped = true;
    this.hasSharedElements = hasSharedElements;
  }
}
