import { Component, ChangeDetectionStrategy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Configuration } from '../../../../../app.constants';

@Component({
  selector: 'app-video-modal',
  templateUrl: 'video-modal.component.html',
  styleUrls: ['video-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoModalComponent implements OnInit {
  fileName: string;
  title: string;
  id: number;
  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;

  constructor(public bsModalRef: BsModalRef, private readonly configuration: Configuration) {}

  ngOnInit(): void {
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.play();
    }
  }

  toggleVideo(el: ElementRef) {
    el.nativeElement.play();
  }

  get streamUrl() {
    return this.configuration.buildEndpoint(`Documents/GetVideo/${this.id}`);
  }
}
