import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-filters-desktop-footer',
  templateUrl: './filters-desktop-footer.component.html',
  styleUrls: ['./filters-desktop-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersDesktopFooterComponent {
  @Input() useClearAllButtonIcon: boolean = true;
  @Input() useClearAllButton: boolean = true;
  @Input() clearAllButtonLabel: string = this.translateService.instant(T.common.clear_all);
  @Input() useCancelApplyButtons: boolean;
  @Input() showApplyButton: boolean = true;
  @Input() clearDisabled: boolean;
  @Input() applyDisabled: boolean;

  @Output() clear = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() apply= new EventEmitter<void>();

  public readonly T = T;

  constructor(private readonly translateService: TranslateService) {}

  onClearAll(): void {
    this.clear.emit();
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onApply(): void {
    this.apply.emit();
  }
}
