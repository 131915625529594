<div class="dropdown-item" [class.selected]="isSelected" (click)="onClick()" [class.padding-right]="!readonly">
  <span [innerHTML]="dropdownItem[bindProperty]"></span>
  <ng-container *ngIf="!readonly">
    <ng-container *ngIf="!deletable">
      <div *ngIf="!isSelected" class="responsive-flex-wrapper add-wrapper">
        <i class="material-symbols-outlined">add</i>
      </div>
      <div *ngIf="isSelected" class="responsive-flex-wrapper clear-wrapper">
        <i class="material-symbols-outlined">clear</i>
      </div>
    </ng-container>
    <ng-container *ngIf="deletable">
      <div class="delete-wrapper" (click)="onDelete()">
        <div class="responsive-flex-wrapper">
          <i class="material-symbols-outlined">delete_forever</i>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
