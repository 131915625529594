import { ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'src/app/modules/shared/services/confirmation.service';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-title-only-modal.componentl',
  templateUrl: './title-only-modal.component.html',
  styleUrls: ['./title-only-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleOnlyModalComponent {
  title: string;
  description: string;
  shareChildAccount: boolean = false;
  showDelete: boolean = false;
  headerText: string = 'Add Item';
  maxLength: number = 200;
  onSave = new EventEmitter<string>();
  onDelete = new EventEmitter<void>();
  onSharedObject = new EventEmitter<boolean>();
  public readonly T = T;

  constructor(
    private readonly bsModalRef: BsModalRef,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService
  ) {}

  onSaveAndExit(): void {
    this.onSave.next(this.title);
    this.bsModalRef.hide();
  }

  onClose(): void {
    this.bsModalRef.hide();
  }

  get buttonDisabled(): boolean {
    return !this.title == undefined || (this.title && this.title.length < 3);
  }

  onDeleteClicked(): void {
    this.confirmationService.confirmThis(
      this.translateService.instant(T.common.confirm_delete_element),
      () => {
        this.onDelete.next();
        this.bsModalRef.hide();
      },
      () => {}
    );
  }

  onShareAccountState(object: any) {
    this.shareChildAccount = !this.shareChildAccount;
    this.onSharedObject.next(this.shareChildAccount);
  }

  isDepartmentParent(): string {
    return this.shareChildAccount ? 'checked' : 'unchecked';
  }
}
