import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { ToastrConfig, ToastrSettings } from '../utilities/toastr-config.utilities';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  protected isMobilePlatform = Capacitor.isNativePlatform();

  constructor(private toastrService: ToastrService, private readonly mobileToastController: ToastController) {}

  async success(title: string, message?: string, config?: ToastrConfig, callback?: any) {
    if (this.isMobilePlatform) {
      await this.handleMobileToast({ message, header: title });
    } else {
      const toastrConfig = config != null ? config : ToastrSettings.defaultToastrConfig;
      const currentToast = this.toastrService.success(message, title, toastrConfig);
      this.subscribeToTap(callback, currentToast);
    }
  }

  async error(title: string, message?: string, config?: ToastrConfig, callback?: any) {
    if (this.isMobilePlatform) {
      await this.handleMobileToast({ message, header: title });
    } else {
      const toastrConfig = config != null ? config : ToastrSettings.defaultToastrConfig;
      const currentToast = this.toastrService.error(message, title, toastrConfig);
      this.subscribeToTap(callback, currentToast);
    }
  }

  async info(title: string, message?: string, config?: ToastrConfig, callback?: any) {
    if (this.isMobilePlatform) {
      await this.handleMobileToast({ message, header: title });
    } else {
      const toastrConfig = config != null ? config : ToastrSettings.defaultToastrConfig;
      const currentToast = this.toastrService.info(message, title, toastrConfig);
      this.subscribeToTap(callback, currentToast);
    }
  }

  private async handleMobileToast(toastOpts: ToastOptions) {
    const toast = await this.mobileToastController.create({
      ...ToastrSettings.defaultMobileToastrConfig,
      ...toastOpts,
    });
    await toast.present();
  }

  private subscribeToTap(callback: any, currentToast) {
    if (callback != undefined) {
      currentToast.onTap.subscribe((res) => {
        callback();
      });
    }
  }
}
