<ng-container *ngIf="loading; else notLoading">
  <app-loading [displayLogo]="false">
  </app-loading>
</ng-container>

<ng-template #notLoading>
  <main class="mt-2 w-100 chat-container custom-scrollbar">
    <div>
      @for (date of chatMessageDates; track date) {
        <div class="datestamp"> {{ date }}</div>
        @for(chatMessage of chatMessagesObj[date]; track chatMessage) {
          <section [ngClass]="{'mt-4 d-flex mt-4 w-100': true, 'reversed': !chatMessage.isPatron}">
            <aside [ngClass]="{'avatar': true, 'patron': chatMessage.isPatron }">
              @if(chatMessage.isPatron) {
                <span class="material-symbols-outlined">
                  account_circle
                  </span>
              } @else {
                <app-editable-logo
                  [accountId]="currentAccount.id"
                  [title]="T.settings.account_and_configuration.account_logo_and_background.logo | translate"
                  [imageUrl]="currentAccount.logoFilename"
                  [isAccountLogo]="true"
                  [canEdit]="false"
                  ></app-editable-logo>
              }
            </aside>
            <article class="chat-message-container">
              <div class="mb-1 chat-message-sender d-flex" [ngClass]="[!chatMessage.isPatron ? 'reversed' : '']">
                {{ chatMessage.isPatron ? chatMessage.from : currentAccount.title }}
              </div>
              @if(isAnImage(chatMessage.mediaType)) {
                <img class="image-message" src="{{ chatMessage.message }}" alt="Received image" />
              } @else {
                <div
                [innerHTML]="chatMessage.message | safeHtml"
                [ngClass]="{'chat-message': true, 'sent-message': !chatMessage.isPatron }">
              </div>
              }
              <div [ngClass]="['chat-message-time', !chatMessage.isPatron ? 'reversed' : '']">
                {{ chatMessage.created | date:'H:mm' }}
              </div>
            </article>
          </section>
        }
      }
    </div>
    <section class="mt-5 response-field" #responseField>
      <div class="d-flex align-items-center">
        <app-text-field
          class="w-100"
          [(ngModel)]="message"
          [placeholder]="T.common.type_reponse | translate"
          [removeBorders]="true"
          [maxLength]="1600">
        </app-text-field>
        <i
          [closeUponSelection]="true"
          ngDropdown
          [templateRef]="savedTextsTemplate"
         class="material-symbols-outlined add-saved-response-icon">app_registration</i>
      </div>


      <div class="d-flex justify-content-end">
        <button (click)="sendResponse()" class="send-btn">
          <i class="material-symbols-outlined">send</i>
        </button>
      </div>
    </section>
  </main>
</ng-template>


<ng-template #savedTextsTemplate>
  <div class="dropdown-options-container custom-scrollbar">
    <h5 class="dropdown-title">{{ T.common.shortcuts | translate }}</h5>
    <div
      *ngFor="let textMsg of savedTextMessages"
      class="dropdown-option"
      (click)="addSavedResponse(textMsg)"
    >
      {{ textMsg.title }}
    </div>
  </div>
</ng-template>
