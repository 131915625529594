import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';

export enum ReportSendIntervalTypes {
  Empty = 0,
  // None = 1,
  Right_Now = 2,
  Hourly = 3,
  //Every_12_Hours = 4,
  Daily = 5,
  Weekly = 6,
  Monthly = 7,
  Custom_Dates = 19,
}

export namespace ReportSendIntervalTypes {
  export function translatedItems(translateService: TranslateService): { key: ReportSendIntervalTypes; value: string }[] {
    const a: { key: ReportSendIntervalTypes; value: string }[] = [];
    for (const enumMember in ReportSendIntervalTypes) {
      if (!isNaN(parseInt(enumMember, 10))) {
        a.push({ key: +enumMember, value: translatedItem(+enumMember, translateService) });
      }
    }
    return a;
  }
}

function translatedItem(key: ReportSendIntervalTypes, translateService: TranslateService): string {
  switch (key) {
    case ReportSendIntervalTypes.Empty:
      return translateService.instant(T.common.empty);
    case ReportSendIntervalTypes.Right_Now:
      return translateService.instant(T.calendar.right_now);
    case ReportSendIntervalTypes.Hourly:
      return translateService.instant(T.calendar.hourly);
    case ReportSendIntervalTypes.Daily:
      return translateService.instant(T.calendar.daily);
    case ReportSendIntervalTypes.Weekly:
      return translateService.instant(T.calendar.weekly);
    case ReportSendIntervalTypes.Monthly:
      return translateService.instant(T.calendar.monthly);
    case ReportSendIntervalTypes.Custom_Dates:
      return translateService.instant(T.calendar.custom_dates);
    default:
      return 'n/a';
  }
}
