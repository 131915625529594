export class Constants {
  static modalFullScreenCss = 'modal-full-screen';
  static modalConfig = {
    ignoreBackdropClick: true,
  };

  static modalConfigIgnoreBackDrop = {
    ignoreBackdropClick: false,
  };

  static readonly xs: number = 576;
  static readonly sm: number = 768;
  static readonly md: number = 991;
  static readonly lg: number = 1200;
  static readonly xl: number = 1366;

  static readonly customCarouselResolutionMedium: number = 1100;
  static readonly customCarouselResolutionSmall: number = 860;
}
