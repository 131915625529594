import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from 'src/app/app.constants';
import { AuthenticationService } from './authentication.service';
import { IncidentChannelViewModel } from '../../settings/viewModels/incidentChannelsViewModel';
import { Observable } from 'rxjs';
import { FilterViewModel } from '../models/filter/filterViewModel';

@Injectable({
  providedIn: 'root'
})
export class IncidentChannelsService {
  private baseUrl: string;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private authenticationService: AuthenticationService
  ) {
    this.baseUrl = this.configuration.buildEndpoint('IncidentChannels/');
  }

  getIncidentChannels() {
    return this.http.get<IncidentChannelViewModel[]>(`${this.baseUrl}GetIncidentChannels`);
  }

  getActiveChannels() {
    return this.http.get<IncidentChannelViewModel[]>(`${this.baseUrl}GetActiveChannels`);
  }

  getActiveChannelFilters(): Observable<FilterViewModel[]> {
    return this.http.get<FilterViewModel[]>(`${this.baseUrl}GetActiveChannelFilterss`);
  }

  updateIncidentChannels(channels: IncidentChannelViewModel[]): Observable<IncidentChannelViewModel[]> {
    return this.http.post<IncidentChannelViewModel[]>(this.baseUrl + 'UpdateIncidentChannels', channels);
  }

  setIncidentChannel(channelId: number): Observable<IncidentChannelViewModel> {
    const channelVM = new IncidentChannelViewModel();
    channelVM.id = channelId;

    return this.http.post<IncidentChannelViewModel>(this.baseUrl + 'Channel/Change', channelVM);
  }
}
