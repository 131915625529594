import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgProgressModule } from 'ngx-progressbar';
import { StorageModule } from '@ngx-pwa/local-storage';
import { DataTableModule } from "@pascalhonegger/ng-datatable";
import { GaugeModule } from 'angular-gauge';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './modules/shared/shared.module';
import { AppRoutes } from './app.routes';
import { ServerErrorInterceptor } from './modules/shared/interceptors/serverErrorInterceptor';
import { AuthModule } from './modules/auth/auth.module';
import { unAuthorisedComponent } from './unauthorised/unauthorised.component';
import { CustomToastrComponent } from './modules/shared/components/common/custom-toastr/custom-toastr.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { SignOidcComponent } from './modules/auth/signOidc-component/sign-oidc.component';
import { IonicModule } from '@ionic/angular';
import { WtStorageService } from './modules/shared/services/wt-storage.service';
import { QuicklinkModule } from 'ngx-quicklink';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { AzureDebugInterceptor } from './modules/shared/interceptors/azure-debug.interceptor';
import { UserAccountSelectComponent } from './modules/account-selection/components/user-accounts/user-accounts.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { NoAccessComponent } from './no-access/no-access.component';
import { MapsLoaderService } from './modules/shared/services/mapsLoader.service';

defineLocale('engb', enGbLocale);

export function onAppInit(storage: WtStorageService): () => Promise<any> {
  return storage.loadAllDataInMemoryFromStorage;
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/translations/', '.json');
}

export function googleMapsLoader(mapsLoaderService: MapsLoaderService) {
  return () => {
      return new Promise<void>((resolve) => {
        mapsLoaderService.loadGoogleMapsAPIs().subscribe(() => {
          resolve();
        });
      });
  };
}

const APP_MODULES = [CoreModule.forRoot(), SharedModule.forRoot()];

@NgModule({
  imports: [
    AppRoutes,
    BrowserModule,
    HammerModule,
    HttpClientModule,
    QuicklinkModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    ToastrModule.forRoot({
      toastComponent: CustomToastrComponent,
      autoDismiss: true,
      maxOpened: 1,
    }),
    DataTableModule,
    GaugeModule.forRoot(),
    TabsModule.forRoot(),
    NgProgressModule,
    // WETRACK MODULES
    AuthModule.forRoot(),
    ...APP_MODULES,
    StorageModule.forRoot({ IDBNoWrap: true }),
    NgxEchartsModule.forRoot({ echarts }),
    IonicModule.forRoot({
      scrollAssist: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    IntercomModule.forRoot({ appId: 'eaxg9ake' }),
  ],
  declarations: [AppComponent, unAuthorisedComponent, UserAccountSelectComponent, SignOidcComponent, AccessDeniedComponent, NoAccessComponent],
  providers: [
    FileOpener,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      deps: [WtStorageService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AzureDebugInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: googleMapsLoader,
      multi: true,
      deps: [MapsLoaderService]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
