import { Pipe, PipeTransform } from '@angular/core';
import { FilterViewModel } from '../models/filter/filterViewModel';

@Pipe({
  name: 'filtersText',
})
export class FiltersTextPipe implements PipeTransform {
  transform(filters: FilterViewModel | FilterViewModel[], glue = ', '): string {
    // TODO: Get the proper filterText if it's empty (because it comes empty from the backend)
    // TODO: Add support for localised filter text
    if (Array.isArray(filters)) {
      return filters.map((f) => f.filterText).join(glue);
    }

    return filters.filterText;
  }
}
