export class StringUtilities {
  public static ownerNameInitials(name: string): string {
    const tokens = name.split(' ');
    const firstInitial = tokens[0].substring(0, 1).toUpperCase();
    const secondInitial = tokens.length > 1
      ? tokens[1].substring(0, 1).toUpperCase()
      : '';

    return `${firstInitial + secondInitial}`;
  }

  public static StringToBoolean(s: string) {
    const truePattern: RegExp = /^(true|yes|1)$/i;
    const falsePattern: RegExp = /^(false|no|0)$/i;

    if (truePattern.test(s)) {
        return true;
    } else if (falsePattern.test(s)) {
        return false;
    } else {
      return false;
    }
  }
}

export const capitalize = (text: string) => {
  const chars = text.split('');

  chars[0] = chars[0].toUpperCase();

  return chars.join('');
};
