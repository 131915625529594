<ng-container *ngIf="allTagGroupFilters.length > 0">
  <span *ngIf="showTitle">{{ T.defaultLocalizations.tag.many | translate }}:</span>
  <div class="row modal-body-container row col-row-space custom-gutters">
    <div class="tag-group-container" [ngClass]="classes" *ngFor="let f of allTagGroupFilters">
      <div class="selection">
        <app-filter-dropdown-new
          [useFullBorder]="useFullBorder"
          [removeBorders]="removeBorders"
          [useSearch]="useSearch"
          [removeIcon]="removeIcon"
          [disableMargin]="disableMargin"
          [filterByModuleObjectType]="false"
          [filterType]="filterTypes.Tag"
          [appliedFilters]="getTagFiltersByTagGroup(f.filterValue)"
          [typeahead]="true"
          [label]="f.filterText"
          [relatedObjectId]="f.filterValue"
          (onFilterUpdate)="updateFilters($event, f.filterValue)"
        >
        </app-filter-dropdown-new>
      </div>
    </div>
  </div>
</ng-container>
