import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateService<T> {
  protected readonly _list = new BehaviorSubject<T[]>([]);
  readonly _list$ = this._list.asObservable();

  updateList(items: T[]) {
    this._list.next(items);
  }

  getFromList(idKey: any, idBind: string = 'id'): T {
    let item: T;
    const state = this._list.getValue();

    if (state) {
      item = state.find((i) => i[idBind] == idKey);
    }

    return item;
  }

  updateInList(updated: T, idBind: string = 'id') {
    let item: T;
    const state = this._list.getValue();

    if (state) {
      item = state.find((i) => i[idBind] == updated[idBind]);

      if (item) {
        state[state.indexOf(item)] = updated;
        this._list.next(state);
      } else {
        state.push(updated);
        this._list.next(state);
      }
    }
  }

  addToList(item: T, idBind: string = 'id') {
    let state = this._list.getValue();

    if (!state) {
      state = [];
    }

    const matching = state.find((i) => i[idBind] == item[idBind]);

    if (matching) {
      state[state.indexOf(matching)] = item;
    } else {
      state.push(item);
    }

    this._list.next(state);
  }

  deleteFromList(idKey: any, idBind: string = 'id') {
    const state = this._list.getValue();
    if (state) {
      const item = state.find((i) => i[idBind] == idKey);
      if (item) {
        state.splice(state.indexOf(item), 1);
        this._list.next(state);
      }
    }
  }
}
