import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { Constants } from 'src/app/modules/shared/models/constants';
import { PaginationManager } from 'src/app/modules/shared/managers/paginationManager';
import { Subscription } from 'rxjs';
import { WindowEventsEmitter } from 'src/app/modules/shared/events/window.events';
import { T } from 'src/assets/i18n/translation-keys';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit, OnChanges {
  private readonly subscriptions = new Subscription();

  total: number;
  limit: number;
  itemsPerPage = 20;
  currentPage = 1;

  @Input() paginationId: string;
  @Input() showSort = true;
  @Input() shownPagesCount = 4;

  isMobile = false;
  pages: number[] = [];

  sortDropdownItems: { label: string; value: number }[] = [
    { label: '5 ' + this.translateService.instant(T.common.item.many), value: 5 },
    { label: '10 ' + this.translateService.instant(T.common.item.many), value: 10 },
    { label: '20 ' + this.translateService.instant(T.common.item.many), value: 20 },
    { label: '50 ' + this.translateService.instant(T.common.item.many), value: 50 },
    { label: '100 ' + this.translateService.instant(T.common.item.many), value: 100 },
    { label: '500 ' + this.translateService.instant(T.common.item.many), value: 500 },
  ];
  selectedDropdownOption = this.sortDropdownItems[0];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly paginationManager: PaginationManager,
    private readonly windowEventEmitters: WindowEventsEmitter,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit() {
    this.initPagination();
    this.initCurrentSelectedItemsPerPage();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onResized();
    this.initPages();

    this.subscriptions.add(
      this.windowEventEmitters.windowResizeEventTriggered$.subscribe((res) => {
        this.onResized();
      })
    );

    if (changes && changes.paginationId) {
      this.initPagination();
    }
  }

  initPagination() {
    this.subscriptions.add(
      this.paginationManager.paginationModels$.subscribe((res) => {
        const currentModel = res.find((m) => m.id === this.paginationId);
        if (currentModel) {
          if (currentModel.itemsPerPage < 500 && this.sortDropdownItems.length === 6) {
            this.sortDropdownItems.pop();
            this.sortDropdownItems = JSON.parse(JSON.stringify(this.sortDropdownItems));
          }

          this.currentPage = currentModel.currentPage;
          this.itemsPerPage = currentModel.itemsPerPage;
          this.limit = currentModel.itemsPerPage;
          this.total = currentModel.itemsCount;
          this.initPages();
          this.initCurrentSelectedItemsPerPage();
          this.changeDetectorRef.markForCheck();
        }
      })
    );
  }

  initCurrentSelectedItemsPerPage() {
    this.selectedDropdownOption =
      this.sortDropdownItems.find((option) => option.value === this.itemsPerPage) ?? this.sortDropdownItems[0];
  }

  onResized() {
    const width = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

    if (width <= Constants.xs) {
      this.shownPagesCount = 3;
      this.isMobile = true;
    } else {
      this.isMobile = false;
      // this.shownPagesCount = this._defaultShownPagesCount;
    }
  }

  changeItemsPerPage(count: number) {
    const model = this.paginationManager.getPaginationModel(this.paginationId);
    if (model) {
      model.itemsPerPage = count;
      model.currentPage = 1;
      this.paginationManager.updateModel(model);
    }
  }

  get totalPagesCount(): number {
    return this.total < this.limit ? 1 : Math.ceil(this.total / this.limit);
  }

  navigate(page: number, event: MouseEvent) {
    event.stopImmediatePropagation();
    if (page < 1 || page > this.totalPagesCount) {
      return;
    }

    const model = this.paginationManager.getPaginationModel(this.paginationId);

    if (model) {
      model.currentPage = page;
      this.paginationManager.updateModel(model);
    }

    this.initPages();
    this.changeDetectorRef.markForCheck();
  }

  private initPages() {
    if (!this.total || !this.limit) {
      return;
    }

    if (this.totalPagesCount <= this.shownPagesCount) {
      this.pages = [];
      for (let index = 1; index <= this.totalPagesCount; index++) {
        this.pages.push(index);
      }
    } else {
      this.pages = [];

      let delta: number = 0;

      if (this.currentPage <= Math.floor(this.shownPagesCount / 2)) {
        delta = this.currentPage - 1;
      } else if (this.currentPage > this.totalPagesCount - Math.floor(this.shownPagesCount / 2)) {
        if (this.isMobile) {
          delta = 2 * Math.floor(this.shownPagesCount / 2) + this.currentPage - this.totalPagesCount;
        } else {
          delta = 2 * Math.floor(this.shownPagesCount / 2) + this.currentPage - this.totalPagesCount - 1;
        }
      } else {
        delta = Math.floor(this.shownPagesCount / 2);
      }

      for (
        let page = this.currentPage - delta, pagesCount = 0;
        page <= this.totalPagesCount && pagesCount < this.shownPagesCount;
        page++, pagesCount++
      ) {
        this.pages.push(page);
      }
    }
  }

  onDropdownChange(dropdownItem: { label: string; value: number }) {
    this.selectedDropdownOption = dropdownItem;
    this.changeItemsPerPage(this.selectedDropdownOption.value);
  }

  public paginationExtraMarginBreakpoint(): boolean {
    const firstPageBreakpoint: number = 9;
    const secondPageBreakpoint: number = this.totalPagesCount - 1;

    return this.currentPage > firstPageBreakpoint && this.currentPage < secondPageBreakpoint;
  }
}
