<div class="date-slider-general-wrapper">
  <div class="dummy-wrapper">
    <div class="date-slider-container">
      <div class="actual-dates-container">
        <div class="dates d-flex justify-content-between">
          <div class="start-container">
            <div
              class="date-wrapper"
              *ngIf="canEdit"
              [class.editable-date]="canEdit"
              ngRangeDatepicker
              [onDateSelectCallbackFn]="onDateSelectCallbackFn"
              [startDate]="actualStartDate"
              [endDate]="actualEndDate"
              (datesChanged)="onRangeDateChanged($event)"
            >
              <div class="date-label">
                <span class="label-text">{{ T.lozenges_label.date_related.start | translate }}:</span>
                <div class="edit-btn">
                  <span class="edit-btn__text" style="margin-right: 4px">{{ T.common.edit | translate }}</span>
                  <span class="material-symbols-outlined">edit</span>
                </div>
              </div>
              <span class="time" *ngIf="showTime"
                >{{ actualStart ? (actualStart | localisedTime) : (T.common.no_date | translate) }},
              </span>
              <span class="date">{{
                actualStart ? (actualStart | localisedDate: false:false) : (T.common.no_date | translate)
              }}</span>
              <ng-container *ngIf="actualStartDiffInDays > 0">
                <span class="time" *ngIf="999 >= actualStartDiffInDays">
                  ({{ actualStartDiffInDays | convertNum: 'positive' }}d)</span
                >
                <span class="time" *ngIf="999 < actualStartDiffInDays"> (999+d)</span>
              </ng-container>
            </div>
            <div class="date-wrapper" *ngIf="!canEdit">
              <div class="date-label">
                <span class="label-text">{{ T.lozenges_label.date_related.start | translate }}:</span>
                <!-- <span class="material-symbols-outlined edit-icon">edit</span> -->
              </div>
              <span class="time" *ngIf="showTime"
                >{{ actualStart ? (actualStart | localisedTime) : (T.common.no_date | translate) }},
              </span>
              <span class="date">{{
                actualStart ? (actualStart | localisedDate: false:false) : (T.common.no_date | translate)
              }}</span>
              <ng-container *ngIf="actualStartDiffInDays > 0">
                <span class="time" *ngIf="999 >= actualStartDiffInDays">
                  ({{ actualStartDiffInDays | convertNum: 'positive' }}d)</span
                >
                <span class="time" *ngIf="999 < actualStartDiffInDays"> (999+d)</span>
              </ng-container>
            </div>
          </div>
          <div class="end-container">
            <div
              class="date-wrapper"
              *ngIf="canEdit"
              [class.editable-date]="canEdit"
              ngRangeDatepicker
              [onDateSelectCallbackFn]="onDateSelectCallbackFn"
              [startDate]="actualStartDate"
              [endDate]="actualEndDate"
              (datesChanged)="onRangeDateChanged($event)"
            >
              <div class="date-label">
                <div class="edit-btn">
                  <span class="material-symbols-outlined">edit</span>
                  <span class="edit-btn__text" style="margin-left: 4px">{{ T.common.edit | translate }}</span>
                </div>
                <span class="label-text">{{ T.lozenges_label.date_related.due | translate }}:</span>
              </div>
              <div class="right-container-date-and-time">
                <span class="time" *ngIf="showTime"
                  >{{ actualEnd ? (actualEnd | localisedTime) : (T.common.no_date | translate) }},
                </span>
                <span class="date">{{
                  actualEnd ? (actualEnd | localisedDate: false:false) : (T.common.no_date | translate)
                }}</span>
                <ng-container
                  *ngIf="actualStartDiffInDays <= actualEndDiffInDays && actualStartDiffInDays <= 0 && !isCompleted"
                >
                  <span
                    class="time"
                    [class.passed-date]="actualEndDiffInDays < 0"
                    *ngIf="(actualEndDiffInDays | convertNum: 'positive') <= 999"
                  >
                    ({{ actualEndDiffInDays | convertNum: 'positive' }}d)</span
                  >
                  <span
                    class="time"
                    [class.passed-date]="actualEndDiffInDays < 0"
                    *ngIf="(actualEndDiffInDays | convertNum: 'positive') > 999"
                  >
                    (999+d)</span
                  >
                </ng-container>
              </div>
            </div>
            <div class="date-wrapper" *ngIf="!canEdit">
              <div class="date-label">
                <!-- <span class="material-symbols-outlined edit-icon">edit</span> -->
                <span class="label-text">{{ T.lozenges_label.date_related.due | translate }}:</span>
              </div>
              <span class="time" *ngIf="showTime"
                >{{ actualEnd ? (actualEnd | localisedTime) : (T.common.no_date | translate) }},
              </span>
              <span class="date">{{
                actualEnd ? (actualEnd | localisedDate: false:false) : (T.common.no_date | translate)
              }}</span>
              <ng-container
                *ngIf="actualStartDiffInDays <= actualEndDiffInDays && actualStartDiffInDays <= 0 && !isCompleted"
              >
                <span
                  class="time"
                  [class.passed-date]="actualEndDiffInDays < 0"
                  *ngIf="(actualEndDiffInDays | convertNum: 'positive') <= 999"
                >
                  ({{ actualEndDiffInDays | convertNum: 'positive' }}d)</span
                >
                <span
                  class="time"
                  [class.passed-date]="actualEndDiffInDays < 0"
                  *ngIf="(actualEndDiffInDays | convertNum: 'positive') > 999"
                >
                  (999+d)</span
                >
              </ng-container>
            </div>
          </div>
        </div>
        <div class="date-progress">
          <div class="passed-time" [style.width.%]="actualBarPassedPercentage"></div>
          <div class="active-time">
            <div
              class="pointer"
              *ngIf="actualBarDateSpace < 100 && actualBarDateSpace > 0"
              [style.left.%]="actualBarDateSpace"
            ></div>
          </div>
          <div class="passed-time" [style.width.%]="actualBarRestPercentage"></div>
        </div>
      </div>
    </div>
    <!-- Second component starts here -->
    <div class="date-slider-container" *ngIf="isExpanded" style="margin-top: 20px">
      <div class="actual-dates-container">
        <div class="dates d-flex justify-content-between">
          <div class="start-container">
            <div class="date-wrapper">
              <div class="date-label">
                <span class="label-text">{{ T.common.baseline_start | translate }}:</span>
              </div>
              <span class="time" *ngIf="showTime"
                >{{ baselineStart ? (baselineStart | localisedTime) : (T.common.no_date | translate) }},
              </span>
              <span class="date">{{
                baselineStart ? (baselineStart | localisedDate: false:false) : (T.common.no_date | translate)
              }}</span>
              <ng-container *ngIf="baselineStartDiffInDays > 0 && !isCompleted">
                <span class="time" *ngIf="999 >= baselineStartDiffInDays">
                  ({{ baselineStartDiffInDays | convertNum: 'positive' }}d)</span
                >
                <span class="time" *ngIf="999 < baselineStartDiffInDays"> (999+d)</span>
              </ng-container>
            </div>
          </div>
          <div class="end-container">
            <div class="date-wrapper">
              <div class="date-label">
                <span class="label-text">{{ T.common.baseline_due | translate }}:</span>
              </div>
              <div class="right-container-date-and-time">
                <span class="time" *ngIf="showTime"
                  >{{ baselineEnd ? (baselineEnd | localisedTime) : (T.common.no_date | translate) }},
                </span>
                <span class="date">{{
                  baselineEnd ? (baselineEnd | localisedDate: false:false) : (T.common.no_date | translate)
                }}</span>
                <ng-container
                  *ngIf="
                    baselineStartDiffInDays <= baselineEndDiffInDays && baselineStartDiffInDays <= 0 && !isCompleted
                  "
                >
                  <span
                    class="time"
                    [class.passed-date]="baselineEndDiffInDays < 0"
                    *ngIf="(baselineEndDiffInDays | convertNum: 'positive') <= 999"
                  >
                    ({{ baselineEndDiffInDays | convertNum: 'positive' }}d)</span
                  >
                  <span
                    class="time"
                    [class.passed-date]="baselineEndDiffInDays < 0"
                    *ngIf="(baselineEndDiffInDays | convertNum: 'positive') > 999"
                  >
                    (999+d)</span
                  >
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="date-progress baseline-bar">
          <div class="passed-time" [style.width.%]="baselineBarPassedPercentage"></div>
          <div class="active-time"></div>
          <div class="passed-time" [style.width.%]="baselineBarRestPercentage"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-button-container">
    <div class="show-more-btn">
      <app-button-icon-circle
        [rotation]="true"
        [size]="'large'"
        [isOpen]="isExpanded"
        (clicked)="this.isExpanded = !this.isExpanded"
      ></app-button-icon-circle>
    </div>
    <div class="info-popup" *ngIf="isExpanded && baselineInfo">
      <i class="material-symbols-outlined" [app-tooltip]="{ title: T.common.baseline_info | translate, message: baselineInfo }"
        >error_outline</i
      >
    </div>
  </div>
</div>
