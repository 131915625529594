<div
  [ngClass]="{'card-lozenge': true, 'cursor-default': !canEdit &&
    (lozengeType === lozengeTypes.Risk_Breakdown || lozengeType === lozengeTypes.Issue_Breakdown || lozengeType === lozengeTypes.Opportunity_Breakdown)}"
  #cardLozenge
  [class.empty]="emptyState"
  [class.milestone]="isMilestoneFilterType || isDateAndHasMilestone"
  [class.milestone-gold]="milestoneType === '3'"
  [class.milestone-silver]="milestoneType === '2'"
  [class.milestone-bronze]="milestoneType === '1'"
  (click)="$event.stopPropagation()"
  *ngIf="filterType && lozengeType !== lozengeTypes.Range_Time"
  [class.runsheet-date]="objectType === objectTypes.Runsheet && filterType === filterTypes.Date"
>
  <ng-container [ngSwitch]="lozengeType">
    <ng-container *ngSwitchCase="lozengeTypes.Regular">
      <ng-container *ngIf="canEdit && !isNotEditable">
        <div
          class="popup-info-wrapper"
          ngBlockScreen
          (blockScreenClicked)="onBlockScreenClicked()"
          ngDropdown
          [templateRef]="inlineEditContainer"
          (click)="onLozengeClicked($event)"
        >
          <ng-container [ngTemplateOutlet]="regularLozengeTemplate"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!canEdit || isNotEditable">
        <div
          *ngIf="!handleLozengeClickExternally"
          class="popup-info-wrapper"
          [app-popup]="{
            templateRef: labelLozengeContainer,
            maxWidth: '400px',
            title: filterLabel,
            position: 'bottom',
            arrowPosition: ['top', 'left'],
            useHeader: true,
            icon: null
          }"
        >
          <ng-container [ngTemplateOutlet]="regularLozengeTemplate"></ng-container>
        </div>
        <div class="popup-info-wrapper" *ngIf="handleLozengeClickExternally" (click)="onLozengeClickedExternal($event)">
          <ng-container [ngTemplateOutlet]="regularLozengeTemplate"></ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="lozengeTypes.Date">
      <ng-container *ngIf="!canEdit || isNotEditable">
        <div
          class="popup-info-wrapper"
          [app-popup]="{
            templateRef: labelLozengeContainer,
            maxWidth: '400px',
            title: filterLabel,
            position: 'bottom',
            arrowPosition: ['top', 'left'],
            useHeader: true,
            icon: null
          }"
        >
          <div class="card-lozenge-label" [class.empty]="emptyState" *ngIf="objectType !== objectTypes.Runsheet">
            {{ disableLabel ? '' : filterLabel.concat(': ') }}
          </div>
          <div class="card-lozenge-value" [ngStyle]="filterTextStyles" [class.empty]="emptyState">{{ filterText }}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="canEdit && !isNotEditable">
        <div
          class="popup-info-wrapper"
          *ngIf="!isRangeDate"
          ngdatepicker
          [date]="singleDate"
          (dateChanged)="onRegularDateChange($event)"
          (click)="closePopup()"
        >
          <div class="card-lozenge-label" [class.empty]="emptyState" *ngIf="objectType !== objectTypes.Runsheet">
            {{ disableLabel ? '' : filterLabel.concat(': ') }}
          </div>
          <div class="card-lozenge-value" [ngStyle]="filterTextStyles" [class.empty]="emptyState">{{ filterText }}</div>
        </div>
        <div
          class="popup-info-wrapper"
          *ngIf="isRangeDate"
          ngRangeDatepicker
          [startDate]="startDate"
          [endDate]="endDate"
          [onDateSelectCallbackFn]="onDatesSelectMilestoneCallback"
          (datesChanged)="onRangeDateChange($event)"
        >
          <div class="card-lozenge-label" [class.empty]="emptyState">
            {{ disableLabel ? '' : filterLabel.concat(': ') }}
          </div>
          <div class="card-lozenge-value" [ngStyle]="filterTextStyles" [class.empty]="emptyState">{{ filterText }}</div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="lozengeTypes.Time">
      <ng-container *ngIf="!canEdit || isNotEditable">
        <div
          class="popup-info-wrapper"
          [app-popup]="{
            templateRef: labelLozengeContainer,
            maxWidth: '400px',
            title: filterLabel,
            position: 'bottom',
            arrowPosition: ['top', 'left'],
            useHeader: true,
            icon: null
          }"
        >
          <div class="card-lozenge-label" [class.empty]="emptyState">
            {{ disableLabel ? '' : filterLabel.concat(': ') }}
          </div>
          <div class="card-lozenge-value" [ngStyle]="filterTextStyles" [class.empty]="emptyState">{{ filterText }}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="canEdit && !isNotEditable">
        <div
          class="popup-info-wrapper"
          wtTimepicker
          [date]="singleDate"
          (click)="closePopup()"
          (timeChanged)="onRegularDateChange($event)"
          (timeRemoved)="onDateRemove()"
        >
          <div class="custom-icons material-symbols-outlined">schedule</div>
          <div class="card-lozenge-value" [ngStyle]="filterTextStyles" [class.empty]="emptyState">{{ filterText }}</div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="lozengeTypes.Task_Breakdown">
      <div
        class="card-rag-breakdown-container"
        [app-popup]="{
          position: 'left',
          arrowPosition: ['right', 'top'],
          paddingless: false,
          closeUponSelection: true,
          templateRef: ragLozengePopupTemplate,
          title: objectTypes.Task | localiseObjectType: true
        }"
      >
        <svg *ngIf="objectType === objectTypes.Task" class="card-lozenge-rag-icon" [class.empty]="emptyState">
          <use xlink:href="../../../../../../assets/images/planning/icon-sub-task.svg#subtask"></use>
        </svg>
        <svg *ngIf="objectType !== objectTypes.Task" class="card-lozenge-rag-icon" [class.empty]="emptyState">
          <use xlink:href="../../../../../../assets/images/planning/icon-task.svg#check"></use>
        </svg>
        <div class="card-lozenge-rag-sum" [class.empty]="emptyState">{{ ragBreakdownValue }}</div>
        <div class="card-lozenge-rag-breakdown-bar">
          <app-simple-rag-breakdown-bar [ragBreakdowns]="ragBreakdownBars"></app-simple-rag-breakdown-bar>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="lozengeTypes.Risk_Breakdown">
      <div
        [ngClass]="['card-rag-breakdown-container', !canEdit ? 'pe-none' : '']"
        [app-popup]="{
          position: 'left',
          arrowPosition: ['right', 'top'],
          paddingless: false,
          closeUponSelection: true,
          templateRef: ragRiskLozengePopupTemplate,
          title: ragBreakdownRioName
        }"
      >
        <ng-container [ngTemplateOutlet]="regularLozengeTemplate"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="lozengeTypes.Issue_Breakdown">
      <div
        [ngClass]="['card-rag-breakdown-container', !canEdit ? 'pe-none' : '']"
        [app-popup]="{
          position: 'left',
          arrowPosition: ['right', 'top'],
          paddingless: false,
          closeUponSelection: true,
          templateRef: ragRiskLozengePopupTemplate,
          title: ragBreakdownRioName
        }"
      >
        <ng-container [ngTemplateOutlet]="regularLozengeTemplate"></ng-container>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="lozengeTypes.Opportunity_Breakdown">
      <div
        [ngClass]="['card-rag-breakdown-container', !canEdit ? 'pe-none' : '']"
        [app-popup]="{
          position: 'left',
          arrowPosition: ['right', 'top'],
          paddingless: false,
          closeUponSelection: true,
          templateRef: ragRiskLozengePopupTemplate,
          title: ragBreakdownRioName
        }"
      >
        <ng-container [ngTemplateOutlet]="regularLozengeTemplate"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<div #rangeItemLozenge *ngIf="lozengeType === lozengeTypes.Range_Time">
  <app-range-time-lozenge
    [startDateISO]="getStartTimeISO()"
    [endDateISO]="geTEndTimeISO()"
    [comparatorDateISO]="getComparatorDate()"
    [showLock]="getRangeTimeLock()"
    (mouseenter)="onLozengeHoveredExternal()"
    (click)="onLozengeClickedExternal($event)"
  ></app-range-time-lozenge>
</div>

<ng-template #inlineEditContainer>
  <app-filters-inline-edit-container
    *ngIf="canEdit"
    [mobile]="mobile"
    [lozengeInlineEdit]="true"
    [showTitle]="false"
    [singleSelect]="isSingleSelect"
    [filters]="allowedEmployeeFilters"
    [appliedFilters]="filters"
    [isUsedAlone]="true"
    [filterTypeSelectorViewModel]="filterTypeSelector"
    (filtersUpdated)="updateFilters($event)"
  >
  </app-filters-inline-edit-container>
</ng-template>

<ng-template #labelLozengeContainer>
  <div class="lozenge-popup-container">
    <div class="card-lozenge inner-lozenge" *ngFor="let filter of filters">
      <span class="material-symbols-outlined" style="color: var(--wt-primary)">check</span>
      <div>{{ getSingleFilterText(filter) }}</div>
    </div>
  </div>
</ng-template>

<ng-template #ragLozengePopupTemplate>
  <div class="rag-popup-container">
    <div class="rag-popup-row" *ngFor="let r of ragBreakdownBars">
      <div [class]="getRagBreakdownSquareClass(r)"></div>
      <div>{{ getRagBreakdownCountSummary(r) }}</div>
      <div class="rag-popup-view">
        <app-hyperlink [text]="'View'" (onClicked)="taskRagLinkClicked(r)" *ngIf="objectType !== objectTypes.Task">
        </app-hyperlink>
      </div>
    </div>
    <div class="rag-popup-footer" *ngIf="objectType !== objectTypes.Task">
      <app-hyperlink [text]="ragBreakdownText" (onClicked)="taskRagLinkClicked(null)"></app-hyperlink>
    </div>
  </div>
</ng-template>

<ng-template #ragRiskLozengePopupTemplate>
  <div class="rag-popup-container">
    <div class="rag-popup-row" *ngFor="let r of ragBreakdownBars">
      <div class="rag-square" [ngStyle]="{ 'background-color': r.colour }"></div>
      <div>{{ getRiskRagBreakdownCountSummary(r) }}</div>
      <div class="rag-popup-view">
        <app-hyperlink [text]="'View'" (onClicked)="riskRagLinkClicked(r)" *ngIf="objectType !== objectTypes.Risk">
        </app-hyperlink>
      </div>
    </div>
    <div class="rag-popup-footer" *ngIf="objectType !== objectTypes.Risk">
      <app-hyperlink [text]="setRiskRagBreakdownText()" (onClicked)="riskRagLinkClicked(null)"></app-hyperlink>
    </div>
  </div>
</ng-template>



<ng-template #regularLozengeTemplate>
  <ng-container *ngIf="isLabelLozenge">
    <div class="card-lozenge-label" [class.empty]="emptyState">{{ disableLabel ? '' : filterLabel.concat(': ') }}</div>
    <div class="card-lozenge-value" [ngStyle]="filterTextStyles" [class.empty]="emptyState">{{ filterText }}</div>
  </ng-container>
  <ng-container *ngIf="isIconLozenge">
    <i
      *ngIf="!isSvgIcon"
      class="material-symbols-outlined"
      [class.empty]="emptyState || (+filterText < 1 && isRunsheetItemDependenciesCountFilterType)"
      [class.milestone]="isMilestoneFilterType"
      [class.milestone-gold]="milestoneType === '3'"
      [class.milestone-silver]="milestoneType === '2'"
      [class.milestone-bronze]="milestoneType === '1'"
      [class.runsheet-item-dependency]="isRunsheetItemDependenciesCountFilterType"
    >
      {{ iconByType }}
    </i>
    <div *ngIf="isSvgIcon" class="custom-icons" [innerHTML]="iconByType | safeHtml"></div>
    <div *ngIf="hasLabelWithIcon" style="flex-grow: 0" class="card-lozenge-label">{{ filterLabel }}</div>
    <div
      class="card-lozenge-value"
      *ngIf="!isRunsheetItemDependenciesCountFilterType"
      [ngStyle]="filterTextStyles"
      [class.empty]="emptyState"
    >
      {{ filterText }}
    </div>
    <div
      class="card-lozenge-value"
      [class.empty]="+filterText < 1"
      *ngIf="isRunsheetItemDependenciesCountFilterType"
      [ngStyle]="filterTextStyles"
    >
      {{ filterText }} Dependencies
    </div>
    <div class="card-lozenge-overflowing-count" *ngIf="overflowingCount">+{{ overflowingCount }}</div>
  </ng-container>
</ng-template>
