import { ElementRef } from '@angular/core';
import { Constants } from '../../models/constants';
import { GeoLocationService } from '../../services/geo-location.service';
import { GMapUtilities } from '../../utilities/gMap.utilities';
import { GoogleGeolocationResponseModel } from '../../viewModels/GoogleGeolocationResponseModel';
import { ZoneViewModel } from 'src/app/modules/settings/viewModels/zoneViewModel';
import { AreaViewModel } from 'src/app/modules/settings/viewModels/areaViewModel';
import { LocalisationService } from '../../services/localisation.service';
import { ObjectTypes } from '../../enums/objectTypes';
import { MapsLoaderService } from '../../services/mapsLoader.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { IncidentsSettingsService } from '../../services/indicents-settings.service';


export abstract class BaseMap {
  private pressButtonTimer: NodeJS.Timeout = null;
  private tapAndHoldDetectionInitialized = false;
  private tipObj: HTMLDivElement = null;
  private tipOffset: { x: number; y: number } = {
    x: 20,
    y: 20,
  };

  // Used for getting the map projection and converting between geo point and xy point. ( Google Maps v3 api SUCKS!!!)
  private dummyOverlay: google.maps.OverlayView = null;

  protected static readonly DEFAULT_ZOOM = 15;
  protected static readonly MY_LOCATION_DEFAULT_ZOOM = 16;
  protected static readonly LOCATION_SEARCH_DEFAULT_ZOOM = 18;
  protected static readonly W3W_SERCH_DEFAULT_ZOOM = 19;

  protected map: google.maps.Map;
  protected defaultZoom = 15;

  // One to one mapping of the zones and areas ShapePointViewModel cached as google.maps.Polygon
  // so some operations can be applied on the map.
  protected zonePolygonMap: Map<number, google.maps.Polygon> = new Map();
  protected areaPolygonMap: Map<number, google.maps.Polygon> = new Map();
  protected myLocationLatLng: google.maps.LatLng;

  protected subscriptions: Subscription[] = [];

  public localisedZone = 'Zone';
  public localisedArea = 'Area';

  constructor(
    protected readonly mapsLoaderService: MapsLoaderService,
    protected readonly geoLocationService: GeoLocationService,
    protected readonly localisationService: LocalisationService,
    protected readonly incidentsSettingsService: IncidentsSettingsService) {
      this.localisedZone = this.localisationService.localiseObjectType(ObjectTypes.Zone);
      this.localisedArea = this.localisationService.localiseObjectType(ObjectTypes.Area);
    }

  get gmap(): google.maps.Map {
    return this.map;
  }

  get isMobile(): boolean {
    return window.innerWidth < Constants.xs;
  }

  get zonesPolygon(): google.maps.Polygon[] {
    return Array.from(this.zonePolygonMap.values());
  }

  get areasPolygon(): google.maps.Polygon[] {
    return Array.from(this.areaPolygonMap.values());
  }

  get polygonsAvailable(): boolean {
    return this.zonePolygonMap.size > 0;
  }

  protected get googleMapsAPIs(): Observable<any> {
    return this.mapsLoaderService.loadGoogleMapsAPIs();
  }

  protected initMapObservable(mapContainer: ElementRef<HTMLElement>, mapOptions: google.maps.MapOptions): Observable<void> {
    return new Observable<void>((observer) => {
        this.googleMapsAPIs.subscribe(() => {
          this.initMap(mapContainer, mapOptions);
          observer.next();
        })
      }
    );
  }

  protected initMapCallback(mapContainer: ElementRef<HTMLElement>, mapOptions: google.maps.MapOptions, callback: () => void): void {
    this.googleMapsAPIs.subscribe(() => {
      this.initMap(mapContainer, mapOptions);
      callback();
    });
  }

  protected initMap(mapContainer: ElementRef<HTMLElement>, mapOptions: google.maps.MapOptions): void {
    let mapParams: google.maps.MapOptions = {
      mapId: this.mapsLoaderService.mapConfiguration.map_id,
      zoom: 1,
      maxZoom: 22,
      center: new google.maps.LatLng(0, 0),
      controlSize: this.isMobile ? 32 : 26,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'satellite', 'hybrid', 'terrain', 'focus'],
        position: google.maps.ControlPosition.BOTTOM_CENTER,
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: 'cooperative',
      clickableIcons: false,
    };

    if(mapOptions) {
      mapParams = {
        ...mapParams,
        ...mapOptions
      };
    }

    this.map = new google.maps.Map(mapContainer.nativeElement, mapParams);
    this.map.mapTypes.set('focus', GMapUtilities.getMapFocusStyle('Focus'));
    this.map.setMapTypeId('focus');
  }

  protected initMyLocation(callback: Function) {
    if (this.isMobile) {
      this.geoLocationService.getPosition().subscribe((position: any) => {
        const lat = position.coords.latitude as number;
        const lng = position.coords.longitude as number;
        this.myLocationLatLng = new google.maps.LatLng(lat, lng);
        callback(lat, lng);
      });
    } else {
      this.geoLocationService.getPositionFromGoogleGeolocationAPI().subscribe((response: GoogleGeolocationResponseModel) => {
        const lat = response.location.lat;
        const lng = response.location.lng;
        this.myLocationLatLng = new google.maps.LatLng(lat, lng);
        callback(lat, lng);
      });
    }
  }

  protected get isZonesPolygonCreated(): boolean {
    return this.zonePolygonMap.size > 0;
  }

  protected get isAreasPolygonCreated(): boolean {
    return this.areaPolygonMap.size > 0;
  }

  /**
   * As google maps javascript api doesn't naturally supports tap and hold event
   * this method takas care to implement it using sets of available events.
   *
   * @param durationMillis - The time in milliseconds which needs to pass in order
   * the event to be recognised as tap and hold event.
   *
   * @param callback callback function to execute when the event is recognised.
   */
  protected registerTapAndHoldEven(callback: Function, durationMillis = 800): void {
    if (!this.tapAndHoldDetectionInitialized) {
      if (durationMillis < 800) {
        durationMillis = 800;
      }

      this.map.addListener('mousedown', (event: google.maps.MapMouseEvent) => {
        this.pressButtonTimer = setTimeout(() => {
          if (callback && typeof callback === 'function') {
            callback(event);
          }
        }, durationMillis);
      });

      this.map.addListener('mouseup', () => {
        clearTimeout(this.pressButtonTimer);
      });

      this.map.addListener('dragstart', () => {
        clearTimeout(this.pressButtonTimer);
      });

      this.map.addListener('dragend', () => {
        clearTimeout(this.pressButtonTimer);
      });

      this.map.addListener('zoom_changed', () => {
        clearTimeout(this.pressButtonTimer);
      });
    }
  }

  /**
   * As google maps javascript api doesn't naturally supports tap and hold event
   * this method takas care to implement it using sets of available events.
   *
   * @param polygon the overlay to which the tam and hold regognition event to be
   * applied to.
   *
   * @param durationMillis - The time in milliseconds which needs to pass in order
   * the event to be recognised as tap and hold event.
   *
   * @param callback callback function to execute when the event is recognised.
   */
  protected registerTapAndHoldEvenOnPolygon(polygon: google.maps.Polygon, callback: Function, durationMillis = 800): void {
    if (!this.tapAndHoldDetectionInitialized) {
      if (durationMillis < 800) {
        durationMillis = 800;
      }

      polygon.addListener('mousedown', (event: google.maps.MapMouseEvent) => {
        this.pressButtonTimer = setTimeout(() => {
          if (callback && typeof callback === 'function') {
            callback(event);
          }
        }, 800);
      });

      polygon.addListener('mouseup', () => {
        clearTimeout(this.pressButtonTimer);
      });

      polygon.addListener('dragstart', () => {
        clearTimeout(this.pressButtonTimer);
      });

      polygon.addListener('dragend', () => {
        clearTimeout(this.pressButtonTimer);
      });

      polygon.addListener('zoom_changed', () => {
        clearTimeout(this.pressButtonTimer);
      });
    }
  }

  protected removeZonesPolygon(): void {
    this.zonePolygonMap.forEach(poly => {
      poly.setMap(null);
    });
    this.zonePolygonMap.clear();
  }

  protected removeAreasPolygon(): void {
    this.areaPolygonMap.forEach(poly => {
      poly.setMap(null);
    });
    this.areaPolygonMap.clear();
  }

  protected buildPolygonsFromZonesAndAreas(zones: ZoneViewModel[], areas: AreaViewModel[], showOnMap: boolean): void {
    this.buildPolygonsFromZones(zones, showOnMap);
    this.buildPolygonsFromAreas(areas, showOnMap);
  }

  protected buildPolygonsFromZones(zones: ZoneViewModel[], showOnMap: boolean): void {
    if (zones?.length && this.zonePolygonMap.size === 0) {
      zones.forEach((zone) => {
        const zoneShapePointsArr = zone.zoneShapePoints;
        if (zoneShapePointsArr.length) {
          if(!this.zonePolygonMap.get(zone.id)) {
            const polygon = GMapUtilities.buildPolygonFromZoneModel(zoneShapePointsArr, showOnMap ? this.map : null);
            this.zonePolygonMap.set(zone.id, polygon);

            this.attachPolygonTooltipEvents(polygon, `${this.localisedZone}: ${zone.title}`, zone.levelIndex);
          }
        }
      });
    }
  }

  protected buildPolygonsFromAreas(areas: AreaViewModel[], showOnMap: boolean): void {
    if(areas?.length && this.areaPolygonMap.size === 0) {
      areas.forEach((area) => {
        const areaShapePointsArr = area.areaShapePoints;
        if (areaShapePointsArr.length) {
          if(!this.areaPolygonMap.get(area.id)) {
            const polygon = GMapUtilities.buildPolygonFromAreaModel(areaShapePointsArr, showOnMap ? this.map : null);
            this.areaPolygonMap.set(area.id, polygon);

            this.attachPolygonTooltipEvents(polygon, `${this.localisedZone}: ${area.title}`, area.levelIndex);
          }
        }
      });
    }
  }

  protected fitZonesBounds(): void {
    const latLngBounds = new google.maps.LatLngBounds();
    if (this.zonePolygonMap) {
      this.zonePolygonMap.forEach((zone) => {
        const zoneLatLngBounds = GMapUtilities.getPolygonLatLngBounds(zone);
        latLngBounds.union(zoneLatLngBounds);
      });
    }

    this.map.fitBounds(latLngBounds);
  }

  private attachPolygonTooltipEvents(polygon: google.maps.Polygon, tooltipText: string, indexLevel: number = 0): void {
    polygon.addListener('mouseover', (event: google.maps.PolyMouseEvent) => {
      this.injectTooltip(event, tooltipText, indexLevel);
    });

    polygon.addListener('mousemove', (event: google.maps.PolyMouseEvent) => {
      this.moveTooltip(event);
    });

    polygon.addListener('mouseout', () => {
      this.deleteTooltip();
    });
  }

  private injectTooltip(event: google.maps.PolyMouseEvent, text: string, indexLevel: number = 0): void {
    if (this.tipObj === null && event) {
      this.tipObj = document.createElement('div');
      this.tipObj.setAttribute('class', 'poly-tooltip');
      this.tipObj.innerHTML = `
        <div>${text}</div>
        <div>Level: ${indexLevel}</div>
      `;

      this.map.getDiv().appendChild(this.tipObj);

      if (this.dummyOverlay === null) {
        this.initDummyOverlay();
      }

      this.setTooltipAtLocation(event.latLng);
    }
  }

  private initDummyOverlay(): void {
    if (this.map !== null) {
      if (!this.dummyOverlay) {
        this.dummyOverlay = new google.maps.OverlayView();
        this.dummyOverlay.draw = () => void {};
        this.dummyOverlay.setMap(this.map);
      }
    }
  }

  private moveTooltip(event: google.maps.PolyMouseEvent): void {
    if (this.tipObj && event) {
      this.setTooltipAtLocation(event.latLng);
    }
  }

  private deleteTooltip(): void {
    if (this.tipObj !== null) {
      this.map.getDiv().removeChild(this.tipObj);
      this.tipObj = null;
    }
  }

  private setTooltipAtLocation(eventLatLng: google.maps.LatLng): void {
    if (this.dummyOverlay !== null && this.tipObj !== null && eventLatLng) {
      if (this.dummyOverlay.getProjection()) {
        const point = this.dummyOverlay.getProjection().fromLatLngToContainerPixel(eventLatLng);
        this.tipObj.style.top = point.y + window.scrollY + this.tipOffset.y + 'px';
        this.tipObj.style.left = point.x + window.scrollX + this.tipOffset.x + 'px';
      }
    }
  }

  protected getZonesAndAreas(callback: (zones: ZoneViewModel[], areas: AreaViewModel[]) => void) {
    this.subscriptions.push(
      forkJoin([this.incidentsSettingsService.getIncidentZonesViewModels(), this.incidentsSettingsService.getAreas()]).subscribe(
        ([zones, areas]) => {
          callback(zones, areas);
        }
      )
    );
  }
}
