import { ObjectTypes } from '../enums/objectTypes';

export class ItemSubscriberUserGroupViewModel {
  id: number;
  userGroupId: number;
  accountId: number;
  globalObjectId: number;
  globalObjectType: ObjectTypes;
  createdById: number;
  created: string;
}
