import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { EmployeeViewModel } from '../../../viewModels/employeeViewModel';
import { Subscription } from 'rxjs';
import { CommentsService } from '../../../services/comments.service';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { LocalisationService } from '../../../services/localisation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filters-list-entries',
  templateUrl: './filters-list-entries.component.html',
  styleUrls: ['./filters-list-entries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersListEntriesComponent implements OnInit, OnDestroy {
  @Input() entries: FilterViewModel[];
  @Input() selectedEntries: FilterViewModel[] = [];
  @Input() mobile: boolean;
  @Input() required: boolean = false;
  @Input() filterType: FilterTypes;
  @Input() useCheckbox: boolean = false;
  @Input() showPrimaryIcon: boolean = false;
  @Input() orderBy = 'filterText';
  @Output() entrySelected = new EventEmitter<FilterViewModel> ();
  @Output() primaryChanged = new EventEmitter<FilterViewModel>();
  public filterTypes = FilterTypes;
  /**
   * Used to show avatar icons for filters with FilterType.Owner
   */
  public availableEmployees: EmployeeViewModel[] = [];

  private subscription = new Subscription();

  constructor(private readonly commentsService: CommentsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly localisationService: LocalisationService,
    private readonly translateService: TranslateService
    ) {}

  ngOnInit(): void {
    this.initAvailableEmployees();
    this.entries = FilterUtilities.setFilterText(this.entries, this.localisationService, this.translateService);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initAvailableEmployees() {
    if(this.filterType === FilterTypes.Owner) {
      this.subscription.add(
        this.commentsService.getAllEmployeesByAccountId().subscribe((res) => {
          this.availableEmployees = res;
          this.cdr.detectChanges();
        })
      );
    }
  }

  isSelected(entry: FilterViewModel): boolean {
    return !!this.selectedEntries.find(
      (e) =>
        e !== undefined &&
        e.filterType === entry.filterType &&
        e.filterValue !== undefined &&
        e.filterValue.toString() == entry.filterValue.toString()
    );
  }

  isPrimary(entry: FilterViewModel): boolean {
    return this.selectedEntries.find(
      (e) =>
        e !== undefined &&
        e.filterType === entry.filterType &&
        e.filterValue !== undefined &&
        e.filterValue.toString() == entry.filterValue.toString()
    )?.isPrimary;
  }

  onSelect(entry: FilterViewModel): void {
    this.entrySelected.emit(entry);
  }

  changePrimary(entry: FilterViewModel, e: MouseEvent): void {
    e.stopPropagation();
    e.stopImmediatePropagation();
    const newPrimaryState = !this.isPrimary(entry);
    const copiedFilter = JSON.parse(JSON.stringify(entry)) as FilterViewModel;
    copiedFilter.isPrimary = newPrimaryState;
    this.primaryChanged.emit(copiedFilter);
  }

  public getEmployee(employeeId: number): EmployeeViewModel {
    return this.availableEmployees.find((employee) => employee.id == employeeId);
  }
}
