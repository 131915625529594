import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DashboardLayoutWidgetViewModel } from '../../../viewModels/dashboard/dashboardLayoutWidgetViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DashboardLayoutViewModel } from '../../../viewModels/dashboard/dashboardLayoutViewModel';
import { ModuleTypes } from 'src/app/modules/settings/enums/moduleTypes';

@Component({
  selector: 'app-dashboard-customise-layout',
  templateUrl: './dashboard-customise-layout.component.html',
  styleUrls: ['./dashboard-customise-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCustomiseLayoutComponent implements OnInit {
  @Output() summaryBarUpdated: EventEmitter<DashboardLayoutWidgetViewModel> = new EventEmitter<DashboardLayoutWidgetViewModel>();
  @Output() dashboardLayoutUpdated: EventEmitter<DashboardLayoutWidgetViewModel[]> = new EventEmitter<DashboardLayoutWidgetViewModel[]>();
  @Output() dashboardLayoutColumnsUpdated: EventEmitter<number> = new EventEmitter<number>();

  @Input() summaryBarWidgets: DashboardLayoutWidgetViewModel[];
  @Input() layout: DashboardLayoutViewModel;
  @Input() widgets: DashboardLayoutWidgetViewModel[] = [];
  @Input() columns: number;
  @Input() defaultWidgets: DashboardLayoutWidgetViewModel[] = [];
  public usedWidgets: DashboardLayoutWidgetViewModel[] = [];
  public selectedSummaryBarWidget: DashboardLayoutWidgetViewModel;
  public hoveredWidgetId = 0;
  public widgetIsBeingDragged = false;
  public readonly moduleTypes = ModuleTypes;
  public readonly T = T;
  protected usedWidgetsContainerId = "usedWidgetsContainer";
  protected defaultWidgetsContainerId = "defaultWidgetsContainer";

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.filterUsedWidgets(this.widgets);

    if (this.layout.summaryBarId) {
      this.selectedSummaryBarWidget = this.summaryBarWidgets.find(x => x.id === this.layout.summaryBarId);
    }
  }

  filterUsedWidgets(widgets: DashboardLayoutWidgetViewModel[]) {
    this.usedWidgets = widgets.filter(x => x.isActive);
  }

  sortWidgets() {
    this.widgets.sort((a, b)  => a.viewOrder - b.viewOrder)
  }

  drop(event: CdkDragDrop<DashboardLayoutWidgetViewModel[]>) {
    const { item, currentIndex, previousIndex, container, previousContainer } = event;

    if (container !== previousContainer) {
      // Deletes the widget that was moved from usedWidgets to default
      if(container.id === this.defaultWidgetsContainerId) {
        this.onDeleteWidget(item.data as DashboardLayoutWidgetViewModel);
        return;
      };

      // Dragged from defaultWidgets to usedWidgets container
      (item.data as DashboardLayoutWidgetViewModel).isActive = true; // Set isActive to true
      (item.data as DashboardLayoutWidgetViewModel).viewOrder = currentIndex; // Set viewOrder based on the current index

      // Clone the item that was dropped.
      const clone = {...previousContainer.data[previousIndex]};
      // Add the clone to the new array.
      container.data.splice(currentIndex, 0, clone);

      this.usedWidgets = [...container.data];
    } else {
      // Dragged and reorder within the containers
      moveItemInArray(container.data, previousIndex, currentIndex);

      if(container.id === this.usedWidgetsContainerId) {
        this.usedWidgets = [...container.data];
      }

      // Forbids reordering in the defaultWidgets container
      if(container.id === this.defaultWidgetsContainerId) return;
    }

    this.updateWidgetsOrder();
  }

  public updateWidgetsOrder() {
    // Update the viewOrder property based on the current index
    this.usedWidgets.forEach((widget, index) => {
      widget.viewOrder = index;
    });

    const widgets = [...this.usedWidgets];
    this.dashboardLayoutUpdated.next(widgets);
    this.changeDetectorRef.markForCheck();
  }

  public changeColumns(columns: number) {
    this.columns = columns;
    this.dashboardLayoutColumnsUpdated.emit(columns);
  }

  public onSummaryBarChanged(summaryBarWidget: DashboardLayoutWidgetViewModel) {
    this.selectedSummaryBarWidget = summaryBarWidget;
    this.summaryBarUpdated.emit(summaryBarWidget);
    this.changeDetectorRef.markForCheck();
  }

  public onDeleteWidget(widget: DashboardLayoutWidgetViewModel) {
    // Find the index of the widget in usedWidgets
    const widgetIndex = this.usedWidgets.findIndex(w => w.viewOrder === widget.viewOrder);

    if (widgetIndex !== -1) {
      // Remove the widget from usedWidgets
      const removedWidget = this.usedWidgets.splice(widgetIndex, 1)[0];

      // Set isActive to false
      removedWidget.isActive = false;

      // Set viewOrder to null
      removedWidget.viewOrder = null;

      this.updateWidgetsOrder();
      this.changeDetectorRef.markForCheck();
    }
  }
}
