import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { Configuration } from 'src/app/app.constants';

@Component({
  selector: 'app-image-preview',
  templateUrl: 'image-preview.component.html',
  styleUrls: ['image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent {
  @Input() url: string;
  @Input() title: string = '';
  @Input() description: string = '';
  @Output() onStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private authenticationService: AuthenticationService, private configuration: Configuration) {}

  changeState() {
    this.onStateChange.next(false);
  }

  get imageUrl() {
    const indexOfResoure = this.url.toLowerCase().indexOf(`/${this.configuration.ResourceFolderName.toLowerCase()}/`);
    const lastIndexOfDash = this.url.lastIndexOf('/');

    if (indexOfResoure !== -1 || lastIndexOfDash === -1) {
      return this.url;
    }

    if (lastIndexOfDash) {
      return (
        this.url.slice(0, lastIndexOfDash) +
        `/${this.configuration.ResourceFolderName}/${this.authenticationService.getCurrentAccount().id}` +
        this.url.slice(lastIndexOfDash)
      );
    }
  }
}
