<div
  class="responsive-flex-wrapper checkbox-container-wrapper"
  [class.checkbox-disabled]="disabled"
  [attr.qa-checked]="isChecked"
  (click)="onChecked($event)"
>
  <label class="check-container">
    <input type="checkbox" [checked]="isChecked" [disabled]="disabled" />
    <span class="custom-radio-label checkmark"></span>
  </label>
</div>
