import { Pipe, PipeTransform } from '@angular/core';
import { ModuleTypes } from '../../settings/enums/moduleTypes';
import { FilterTypes } from '../enums/filterTypes';
import { PermissionOperationTypes } from '../enums/permissionOperationTypes.enum';
import { Employee } from '../models/employee';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { PermissionManager } from '../permissions/permissionManager';
import { AuthenticationService } from '../services/authentication.service';
import { EmployeeUtil } from '../utilities/employee.utilities';

@Pipe({
  name: 'hasWritePermission',
})
export class HasWritePermissionPipe implements PipeTransform {
  private employee: Employee;

  constructor(private readonly authenticationService: AuthenticationService) {
    this.employee = authenticationService.getCurrentEmployee();
  }

  transform(
    obj: { id: number; filters: FilterViewModel[]; permissionFilters: FilterViewModel[] },
    moduleType: ModuleTypes,
    filterType: FilterTypes, //current object i.e Task list => Task | Write => Project
    operationType?: PermissionOperationTypes
  ): boolean {
    // Throw early if user is Admin
    if (EmployeeUtil.IsAdmin(this.employee)) {
      return true;
    }

    const pManager = new PermissionManager(filterType);
    const result = pManager.applyPermissions(obj, moduleType, filterType, this.employee, operationType);
    return result;
  }
}
