import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ObjectTypes } from 'src/app/modules/shared/enums/objectTypes';
import { UpdateTypes } from '../../../enums/updateTypes';
import { T } from "src/assets/i18n/translation-keys";

@Component({
  selector: 'app-item-history-modal',
  templateUrl: 'item-history-modal.component.html',
  styleUrls: ['item-history-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemHistoryModalComponent {
  //Inputs
  object: any;
  objectType: ObjectTypes;
  showHeader = false;
  routerLink = '';
  userHasWriteAccess: boolean;
  commentType: UpdateTypes;
  public readonly T = T;

  /**
   * If  the current history is for an object from child account this is the child accountEmployeeID
   */
  childHubAccountId: number;

  constructor(
    public readonly bsModalRef: BsModalRef,
    private readonly modalService: BsModalService,
    private readonly router: Router
  ) {}



  onDetailsButtonClicked() {
    void this.router.navigate([this.routerLink]);
    this.bsModalRef.hide();
    this.modalService.hide(1);
  }
}
