import {
  ApplicationRef,
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { DynamicComponentsService } from '../services/dynamicComponents.service';
import { isChildOf } from '../utilities/html.utilities';
import { NgRangeDatepickerComponent } from '../components/common/ng-range-datepicker/ng-range-datepicker.component';
import { Subscription } from 'rxjs';
import { WindowEventsEmitter } from '../events/window.events';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgRangeDatesOutput, NgRangeSelectCallback } from '../models/rangeDatepicker/rangeDatepickerModels.model';

@Directive({
  selector: '[ngRangeDatepicker]',
  host: {
    '(click)': 'onClick($event)',
  },
  exportAs: 'ngRangeDatepicker',
})
export class RangeDatepickerDirective implements OnChanges, OnDestroy {
  @Input() startDate: string | Date;
  @Input() endDate: string | Date;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() showFooter: boolean = true;
  @Input() showHeader: boolean = true;
  @Input() timePicker: boolean = false;
  @Input() applyToAllOption: boolean = false;

  // Use this one when you want the insert behaviour when localstate of the picker is changed
  @Input() onDateSelectCallbackFn: NgRangeSelectCallback;

  @Output() startDateChanged: EventEmitter<string> = new EventEmitter();
  @Output() endDateChanged: EventEmitter<string> = new EventEmitter();
  @Output() datesChanged: EventEmitter<NgRangeDatesOutput> = new EventEmitter();
  @Output() calendarInnerClosed = new EventEmitter<void>();
  @Output() onApplyDatesToAll: EventEmitter<NgRangeDatesOutput> = new EventEmitter();

  private readonly marginTop: number = 4;

  static ngRangeDatepickerComponentRef: ComponentRef<NgRangeDatepickerComponent>;

  private _clickOutEventSubscription: Subscription = null;
  private _routerEventSubscription: Subscription = null;
  private _innerCloseSubscription: Subscription = null;
  private listeners = [];

  constructor(
    private readonly dynamicComponentsService: DynamicComponentsService,
    private readonly appRef: ApplicationRef,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly windowEvents: WindowEventsEmitter,
    private readonly renderer: Renderer2,
    private readonly router: Router
  ) {
    if (!RangeDatepickerDirective.ngRangeDatepickerComponentRef) {
      this.appendToBody();
      this.initGlobalScrollHandler();
    }
  }

  ngOnChanges() {
    if (this.getNgDatepickerInstance().visible && this.getNgDatepickerInstance().directiveElementRef == this.elementRef) {
      this.updateInstance();
    }
  }

  ngOnDestroy() {
    this.destroyGlobalScrollHandler();
    this.unsubscribeFromEvents();
  }

  subscribeForWindowClick() {
    this._clickOutEventSubscription = this.windowEvents.windowClickEventTriggered$.subscribe((e: Event) => {
      this.onDocumentClick(e);
    });
  }

  unsubscribeFromWindowClick() {
    if (this._clickOutEventSubscription && !this._clickOutEventSubscription.closed) {
      this._clickOutEventSubscription.unsubscribe();
      if (this.getNgDatepickerInstance().visible) {
        this.getNgDatepickerInstance().hide();
      }
    }
  }

  // We need to close the datepicker when route changes
  subscribeForRouteChange() {
    this._routerEventSubscription = this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => {
      this.getNgDatepickerInstance().hide();
      this.unsubscribeFromEvents();
    });
  }
  unsubscribeFromRouterEvents() {
    if (this._routerEventSubscription && !this._routerEventSubscription.closed) {
      this._routerEventSubscription.unsubscribe();
      if (this.getNgDatepickerInstance().visible) {
        this.getNgDatepickerInstance().hide();
      }
    }
  }

  subscribeForInnerClose() {
    this._innerCloseSubscription = this.getNgDatepickerInstance().innerClosed.subscribe((res) => {
      this.calendarInnerClosed.emit();
      this.unsubscribeFromEvents();
    });
  }
  unsubscribeFromInnerClose() {
    if (this._innerCloseSubscription && !this._innerCloseSubscription.closed) {
      this._innerCloseSubscription.unsubscribe();
    }
  }

  private getDomElement<T>(componentRef: ComponentRef<T>): HTMLElement {
    return (componentRef.hostView as EmbeddedViewRef<T>).rootNodes[0] as HTMLElement;
  }

  private appendToBody() {
    RangeDatepickerDirective.ngRangeDatepickerComponentRef = this.dynamicComponentsService.createComponentElement(
      NgRangeDatepickerComponent,
      {}
    );

    this.appRef.attachView(RangeDatepickerDirective.ngRangeDatepickerComponentRef.hostView);

    document.body.appendChild(this.getDomElement(RangeDatepickerDirective.ngRangeDatepickerComponentRef));
  }

  private initGlobalScrollHandler() {
    const listener = this.renderer.listen(document.body, 'scroll', () => {
      this.updatePosition();
    });
    this.listeners.push(listener);
  }

  private updatePosition() {
    if (!this.getNgDatepickerElement()) {
      return;
    }

    const datepicker = this.getNgDatepickerElement().firstElementChild as HTMLElement;

    datepicker.style.left = '50%';
    datepicker.style.top = '50%';
    datepicker.style.transform = 'translate(-50%, -50%)';
  }

  getNgDatepickerElement(): HTMLElement {
    return this.getDomElement(RangeDatepickerDirective.ngRangeDatepickerComponentRef);
  }

  get rangeDatepickerChangeDetectorRef(): ChangeDetectorRef {
    return RangeDatepickerDirective.ngRangeDatepickerComponentRef.changeDetectorRef;
  }

  private updateInstance() {
    if (!this.getNgDatepickerInstance()) {
      return;
    }

    this.getNgDatepickerInstance().startDate = this.startDate;
    this.getNgDatepickerInstance().endDate = this.endDate;
    this.getNgDatepickerInstance().localStartDate = this.startDate;
    this.getNgDatepickerInstance().localEndDate = this.endDate;
    this.getNgDatepickerInstance().minDate = this.minDate;
    this.getNgDatepickerInstance().maxDate = this.maxDate;
    this.getNgDatepickerInstance().startDateChanged = this.startDateChanged;
    this.getNgDatepickerInstance().endDateChanged = this.endDateChanged;
    this.getNgDatepickerInstance().datesChanged = this.datesChanged;
    this.getNgDatepickerInstance().onApplyDatesToAll = this.onApplyDatesToAll;
    this.getNgDatepickerInstance().showFooter = this.showFooter;
    this.getNgDatepickerInstance().showHeader = this.showHeader;
    this.getNgDatepickerInstance().timePicker = this.timePicker;
    this.getNgDatepickerInstance().applyToAllOption = this.applyToAllOption;
    this.getNgDatepickerInstance().onDateSelectCallbackFn = this.onDateSelectCallbackFn;
    this.getNgDatepickerInstance().initMonth();
    this.getNgDatepickerInstance().initYear();
    this.getNgDatepickerInstance().initDateModels();
    this.getNgDatepickerInstance().directiveElementRef = this.elementRef;
    this.getNgDatepickerInstance().changeDetectorRef.detectChanges();
  }

  /**
   * Triggers the directive the as the host event,
   * however this method exist in order to be called from outside
   * angular inner mechanism for initializing directives and listening
   * to the trigger event.
   *
   * Unlike the host event this method doesn't subscribe to the windonClick
   * event.
   */
  trigger() {
    this.updatePosition();
    this.updateInstance();
    this.getNgDatepickerInstance().show();
    this.subscribeForRouteChange();
    this.subscribeForInnerClose();
  }

  onClick() {
    this.updatePosition();
    this.updateInstance();
    this.getNgDatepickerInstance().show();
    this.subscribeForWindowClick();
    this.subscribeForRouteChange();
    this.subscribeForInnerClose();
  }

  onDocumentClick({ target }: Event) {
    if (!this.getNgDatepickerInstance().visible) {
      return;
    }

    if (this.getNgDatepickerInstance().directiveElementRef !== this.elementRef) {
      return;
    }

    const targetIsNotPartOfTheDatepicker =
      target !== this.getNgDatepickerElement() && !isChildOf(this.getNgDatepickerElement(), target as HTMLElement);
    const targetIsNotPartOfTheElementRef =
      target !== this.elementRef.nativeElement && !isChildOf(this.elementRef.nativeElement, target as HTMLElement);

    if (targetIsNotPartOfTheDatepicker && targetIsNotPartOfTheElementRef) {
      this.getNgDatepickerInstance().hide();
      this.unsubscribeFromEvents();
    }
  }

  getNgDatepickerInstance(): NgRangeDatepickerComponent {
    return RangeDatepickerDirective.ngRangeDatepickerComponentRef.instance;
  }

  private removeFromBody() {
    this.getDomElement(RangeDatepickerDirective.ngRangeDatepickerComponentRef).remove();

    this.appRef.detachView(RangeDatepickerDirective.ngRangeDatepickerComponentRef.hostView);
  }

  private destroyGlobalScrollHandler() {
    this.listeners.forEach((listener) => listener());
  }

  private unsubscribeFromEvents() {
    this.unsubscribeFromWindowClick();
    this.unsubscribeFromRouterEvents();
    this.unsubscribeFromInnerClose();
  }
}
