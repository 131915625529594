import { Colors } from '../../shared/enums/colors';
import { JobStatuses } from '../../shared/enums/jobs/jobStatuses';
import { RAGBreakdown } from '../../shared/models/rag/ragBreakdown';
import { JobSummary } from '../enums/jobSummary';
import { JobDueWidgetViewModel } from '../models/jobDueWidgetViewModel';
import { JobListItemViewModel } from '../viewModels/jobs/jobListItemViewModel';

export class JobsUtilities {
  static GetJobStatusColor(status: JobStatuses) {
    switch (status) {
      case JobStatuses.New:
        return Colors.Grey;
      case JobStatuses.In_Progress:
        return Colors.Green;
      case JobStatuses.Slipped:
        return Colors.Red;
      case JobStatuses.Complete:
        return Colors.Blue;
    }
  }

  static GetJobsStatusColorSummaryRag(jobs: JobListItemViewModel[]): RAGBreakdown[] {
    let statusRAG: RAGBreakdown[] = [];

    statusRAG = [
      {
        ragStatus: JobStatuses.New,
        colour: this.GetJobStatusColor(JobStatuses.New),
        count: jobs.filter((r) => r.status === JobStatuses.New).length,
      },
      {
        ragStatus: JobStatuses.In_Progress,
        colour: this.GetJobStatusColor(JobStatuses.In_Progress),
        count: jobs.filter((r) => r.status === JobStatuses.In_Progress).length,
      },
      {
        ragStatus: JobStatuses.Slipped,
        colour: this.GetJobStatusColor(JobStatuses.Slipped),
        count: jobs.filter((r) => r.status === JobStatuses.Slipped).length,
      },
      {
        ragStatus: JobStatuses.Complete,
        colour: this.GetJobStatusColor(JobStatuses.Complete),
        count: jobs.filter((r) => r.status === JobStatuses.Complete).length,
      },
    ];

    return statusRAG;
  }

  static GetJobSummaryColor(status: JobSummary): string {
    switch (status) {
      case JobSummary.Not_started:
        return Colors.Grey;
      case JobSummary.Slipped:
        return Colors.Red;
      case JobSummary.In_Progress:
        return Colors.Green;
      case JobSummary.Complete:
        return Colors.Blue;
      case JobSummary.Failed_Checks:
        return Colors.Red;
    }
  }

  static GetJobsDashboardSummaryRag(jobsSummaryData: JobDueWidgetViewModel): RAGBreakdown[] {
    let jobsSummaryDataRag: RAGBreakdown[] = [];
    jobsSummaryDataRag = [
      {
        ragStatus: JobSummary.Not_started,
        colour: this.GetJobSummaryColor(JobSummary.Not_started),
        count: jobsSummaryData.notStarted
      },
      {
        ragStatus: JobSummary.Slipped,
        colour: this.GetJobSummaryColor(JobSummary.Slipped),
        count: jobsSummaryData.slipped
      },
      {
        ragStatus: JobSummary.In_Progress,
        colour: this.GetJobSummaryColor(JobSummary.In_Progress),
        count: jobsSummaryData.inProgress
      },
      {
        ragStatus: JobSummary.Complete,
        colour: this.GetJobSummaryColor(JobSummary.Complete),
        count: jobsSummaryData.completed
      },
      {
        ragStatus: JobSummary.Failed_Checks,
        colour: this.GetJobSummaryColor(JobSummary.Failed_Checks),
        count: jobsSummaryData.failedChecks
      }
    ]

    return jobsSummaryDataRag;
  }

  static getJobsDuePercentageColor(percentage: number) {
    switch (true) {
      case percentage <= 33:
        return 'var(--rag-red)';
      case percentage >= 34 && percentage <= 66:
        return 'var(--rag-amber)';
      case percentage >= 67 && percentage <= 99:
        return 'var(--rag-green)';
      case percentage <= 100:
        return 'var(--rag-blue)';
    }
  }
}
