<div
  #fieldWrapper
  class="details-editable-field-wrapper"
  [style.padding-left]="accordionLozenge ? '4px' : '0px'"
  (mouseenter)="toggleShowEditButton()"
  (mouseleave)="toggleShowEditButton()"
>
  <ng-container *ngIf="!accordionLozenge">
    <div class="heading" *ngIf="type !== fieldTypes.Header && type !== fieldTypes.Card">
      <ng-container *ngIf="header"> {{ header }}: </ng-container>
      <app-button-transparent
        *ngIf="(showEditButton || (isTouchDevice && canEdit)) && !useBorderedInput && !editMode && !preventEditButtonToBeShown"
        (clicked)="toggleEditMode($event)"
        [text]="T.common.edit | translate"
        icon="edit"
        iconFontSize="16"
        size="small"
        textColor="primary-text"
        class="ms-2"
      >
      </app-button-transparent>
    </div>
  </ng-container>
  <div class="editable-field-view-mode-container" *ngIf="!editMode">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="fieldTypes.RichText">
        <app-text-field *ngIf="useBorderedInput && input.length === 0"
          (click)="toggleEditMode($event)"
          [useFullBorder]="true"
          [removeBorders]="true"
          [disableMargin]="true"
          [placeholder]="(T.defaultLocalizations.description.one | translate)">
        </app-text-field>
        <div (click)="toggleEditMode($event)" *ngIf="!useBorderedInput && (!input || input.length === 0)" class="bordered-text empty-state">
          {{ emptyStateMessage }}
        </div>
        <div
          (click)="toggleEditMode($event)"
          *ngIf="input && input.length > 0"
          [ngClass]="useBorderedInput ? 'bordered-text rounded' : 'bordered-text'"
          [innerHTML]="input | safeHtml"
        ></div>
      </ng-container>
      <ng-container *ngSwitchCase="fieldTypes.Header">
        <div class="header" [style.font-size]="headerFontSize ? headerFontSize + ' !important' : '20px'">
          <div class="text-truncate" [innerHTML]="input | safeHtml"></div>
          <app-button-transparent
            *ngIf="(showEditButton || isTouchDevice) && !editMode && !preventEditButtonToBeShown"
            (clicked)="toggleEditMode($event)"
            [text]="T.common.edit | translate"
            size="small"
            iconFontSize="16"
            icon="edit"
            textColor="primary-text"
            class="ms-2"
          >
          </app-button-transparent>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="fieldTypes.Card">
        <div class="inline-edit-card-header">
          <div class="text-truncate card-input" #cardText [innerHTML]="input | safeHtml"></div>
          <app-button-transparent
            *ngIf="(showEditButton || isTouchDevice) && !editMode && !preventEditButtonToBeShown"
            (clicked)="toggleEditMode($event)"
            [text]="T.common.edit | translate"
            size="small"
            iconFontSize="16"
            icon="edit"
            textColor="primary-text"
            class="ms-2 d-none d-sm-block"
          >
          </app-button-transparent>
        </div>
      </ng-container>
      <div *ngSwitchDefault class="input-txt" [innerHTML]="input | safeHtml"></div>
    </ng-container>
  </div>
  <div (click)="$event.stopPropagation()" *ngIf="editMode">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="fieldTypes.RichText">
        <app-rich-text-editor-v2
          [placeholder]="placeholder"
          [inputText]="input"
          [showUndoAndRedo]="false"
          [showProfileImage]="false"
          [minLength]="minLength"
          [autoFocus]="true"
          (textChanged)="changeInput($event)"
          (canceled)="cancelEdit($event)"
          (saved)="onRichEditorSave($event)"
        ></app-rich-text-editor-v2>
      </ng-container>
      <div *ngSwitchCase="fieldTypes.Text">
        <div class="edit-wrapper">
          <app-text-field
            [(ngModel)]="input"
            [placeholder]="placeholder"
            [disableMargin]="true"
            class="text-field-wrapper"
            [maxLength]="maxLength"
          ></app-text-field>
          <div class="edit-buttons-wrapper">
            <app-button-rectangle
              class="cancel"
              [text]="T.common.cancel | translate"
              buttonTheme="white"
              (clicked)="cancelEdit($event)"
            ></app-button-rectangle>
            <app-button-rectangle
              class="save"
              [text]="T.common.save | translate"
              buttonTheme="primary"
              [disabled]="!fieldIsValid"
              (clicked)="onSave($event)"
            ></app-button-rectangle>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="fieldTypes.TextArea">
        <div class="edit-wrapper">
          <app-text-area [(ngModel)]="input" [placeholder]="placeholder" class="text-field-wrapper"></app-text-area>
          <div class="edit-buttons-wrapper">
            <app-button-rectangle
              class="me-1 ms-1 cancel"
              [text]="T.common.cancel | translate"
              buttonTheme="white"
              (clicked)="cancelEdit($event)"
            ></app-button-rectangle>
            <app-button-rectangle
              class="save"
              [text]="T.common.save | translate"
              buttonTheme="primary"
              [disabled]="!fieldIsValid"
              (clicked)="onSave($event)"
            ></app-button-rectangle>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="fieldTypes.Header">
        <div class="edit-wrapper">
          <app-text-field
            [(ngModel)]="input"
            [placeholder]="placeholder"
            class="text-field-wrapper"
            [disableMargin]="true"
            [maxLength]="maxLength"
          ></app-text-field>
          <div class="edit-buttons-wrapper">
            <ng-container *ngIf="!isMobile">
              <app-button-rectangle
                class="me-1 ms-1 cancel"
                [text]="T.common.cancel | translate"
                buttonTheme="white"
                (clicked)="cancelEdit($event)"
              ></app-button-rectangle>
              <app-button-rectangle
                class="save"
                [text]="T.common.save | translate"
                buttonTheme="primary"
                [disabled]="!fieldIsValid"
                (clicked)="onSave($event)"
              ></app-button-rectangle>
            </ng-container>
            <ng-container *ngIf="isMobile">
              <a class="cancel-btn ms-auto" (click)="cancelEdit($event)">
                <i class="material-symbols-outlined">close</i>
              </a>
              <a class="apply-btn me-1" (click)="onSave($event)">
                <i class="material-symbols-outlined">done</i>
              </a>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="!fieldIsValid && fieldIsPhoneNumber">
          <app-validation-error [error]="T.settings.my_preferences.profile.invalid_phone_number | translate"></app-validation-error>
        </ng-container>
      </div>
      <div *ngSwitchCase="fieldTypes.Card">
        <div class="edit-wrapper">
          <app-text-field
            [(ngModel)]="input"
            [placeholder]="placeholder"
            class="text-field-wrapper"
            [disableMargin]="true"
            [maxLength]="maxLength"
          ></app-text-field>
          <div class="edit-buttons-wrapper">
            <app-button-rectangle
              class="me-1 ms-1 cancel"
              [text]="T.common.cancel | translate"
              buttonTheme="white"
              (clicked)="cancelEdit($event)"
            ></app-button-rectangle>
            <app-button-rectangle
              class="save"
              [text]="T.common.save | translate"
              buttonTheme="primary"
              [disabled]="!fieldIsValid"
              (clicked)="onSave($event)"
            ></app-button-rectangle>
          </div>
        </div>
      </div>
      <div *ngSwitchDefault>{{ input }}</div>
    </ng-container>
  </div>
</div>
