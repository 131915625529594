import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FilterTypes } from 'src/app/modules/shared/enums/filterTypes';
import { FilterViewModel } from 'src/app/modules/shared/models/filter/filterViewModel';
import { AllowedFiltersService } from 'src/app/modules/shared/services/allowed-filters.service';
import { UrlService } from 'src/app/modules/shared/services/url.service';

@Component({
  selector: 'app-unsdg-icon',
  templateUrl: './unsdg-icon.component.html',
  styleUrls: ['./unsdg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UNSDGIconComponent implements OnInit {
  @Input() UNSDGFilter: FilterViewModel;
  @Input() size: string = '24';
  public UNSDGIconURL: string;
  public UNSDGFilters: FilterViewModel[] = [];
  public UNSDGTitle: string = '';

  constructor(protected readonly urlService: UrlService, protected allowedFiltersService: AllowedFiltersService) {}

  ngOnInit(): void {
    this.getUNSDGUrl();
    this.UNSDGFilters = this.allowedFiltersService.getCachedAllowedFiltersByType(FilterTypes.UNSDG);
    this.UNSDGTitle = this.UNSDGFilters.find((x) => x.filterValue == this.UNSDGFilter.filterValue)?.filterText;
  }

  public getUNSDGUrl() {
    this.UNSDGIconURL = this.urlService.buildResourceUrl(
      `/assets/images/unsdg/UNSDG_${this.UNSDGFilter.filterValue.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}.png`
    );
  }
}
