import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { MockedSuggestedFiltersService } from '../mocks/suggested-filters.mock';
import { Employee } from '../models/employee';
import { EmployeeCustomFilterViewModel } from '../models/filter/employeeCustomFilterViewModel';

@Injectable({
  providedIn: 'root',
})
export class CustomFiltersService {
  private url: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private readonly mockedSuggestedFiltersService: MockedSuggestedFiltersService
  ) {
    this.url = this.configuration.buildEndpoint(`Filters/`);
  }

  public getEmployeeCustomFilters(): Observable<EmployeeCustomFilterViewModel[]> {
    return this.http.get<EmployeeCustomFilterViewModel[]>(`${this.url}GetEmployeeCustomFilters`);
  }

  public getSuggestedFilters(accountId: number, employee: Employee): Observable<EmployeeCustomFilterViewModel[]> {
    return this.mockedSuggestedFiltersService.getSuggestedFilters(accountId, employee);
  }

  public addEmployeeCustomFilter(addModel: EmployeeCustomFilterViewModel): Observable<EmployeeCustomFilterViewModel> {
    return this.http.post<EmployeeCustomFilterViewModel>(`${this.url}AddEmployeeCustomFilter`, addModel);
  }

  public updateEmployeeCustomFilter(updateModel: EmployeeCustomFilterViewModel): Observable<EmployeeCustomFilterViewModel> {
    return this.http.post<EmployeeCustomFilterViewModel>(`${this.url}UpdateEmployeeCustomFilter`, updateModel);
  }

  public deleteEmployeeCustomFilter(customFilterId: number): Observable<EmployeeCustomFilterViewModel> {
    return this.http.post<EmployeeCustomFilterViewModel>(`${this.url}DeleteEmployeeCustomFilter/${customFilterId}`, null);
  }
}
