export enum RiskStrategyTypes {
  Insurance = 1,
  Avoid = 2,
  Mitigate = 3,
  Share = 4,
  Accept = 5,
  Exploit = 6,
  Contingency = 7,
  Custom = 8,
}
