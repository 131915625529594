export enum ModuleTypes {
  Venues = 1,
  Incidents = 2,
  Runsheets = 3,
  Settings = 5,
  Planning = 6,
  My_Track = 7,
  Reporting = 8,
  Sustainability = 9,
  AccountSelect = 10,
  AccountHub = 11,
  General = 12,
  Risk = 13,
  Events = 14,
}
