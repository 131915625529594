import { UpdateTypes } from '../../enums/updateTypes';
import { FilterViewModel } from '../../models/filter/filterViewModel';

export class ModifiableEntityViewModel {
  id: number;
  title: string;
  filters: FilterViewModel[];
  permissionFilters: FilterViewModel[];
  orderValue: number;
  createdById: number;
  updated:string;
  isPinned: boolean;
  archived: boolean;
  isShared: boolean;
  generalCommentUpdate: UpdateTypes = undefined;
  accountId: number;
}
