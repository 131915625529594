<div class="wraper-safe-random-54781273825-view-encapsulation-none">
  <header class="section-header" id="top" *ngIf="showHeader && !isLoading">
    <h3>{{ title }}</h3>
    <div class="subtitle">{{ description }}</div>
  </header>
  <ng-container *ngIf="isLoading && !singleItemHistory">
    <app-loading [displayLogo]="false">
      <p>{{ T.common.item_is_loading | translate: { item: T.common.history | translate } }}...</p>
    </app-loading>
  </ng-container>
  <ng-container *ngIf="!hideToggle && isMobile">
    <div class="comments-toggle-wrapper-mobile">
      <app-toggle-button
        [fillWidth]="true"
        [objects]="toggleOptions"
        [currentlySelectedObject]="selectedStatus"
        [bindProperty]="'value'"
        (clicked)="toggleStateChange($event)"
      ></app-toggle-button>
    </div>
  </ng-container>
  <ng-container *ngIf="!hideToggle && !isMobile">
    <div class="comments-toggle-wrapper" *ngIf="showToggle">
      <div class="history-title">
        {{ title }}
      </div>
      <div class="history-toggle-wrapper">
        <app-toggle-button
          [fillWidth]="true"
          [objects]="toggleOptions"
          [currentlySelectedObject]="selectedStatus"
          [bindProperty]="'visualText'"
          (clicked)="toggleStateChange($event)"
        ></app-toggle-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isControlModule && showHeader && !singleItemHistory && !isLoading">
    <app-incident-quick-add
      *ngIf="moduleType | canCreate: filterType"
      (logAdded)="loadData()"
      (incidentAdded)="loadData()"
    ></app-incident-quick-add>
  </ng-container>

  <ng-container *ngIf="showInput && canEdit">
    <div class="input-wrapper">
      <app-rich-text-editor
        #richTextEditorComponent
        [commentType]="selectedCommentType"
        [objectId]="objectId"
        [objectType]="objectType"
        [autoFocus]="false"
        [childHubAccountId]="childHubAccountId"
      >
      </app-rich-text-editor>
    </div>
  </ng-container>

  <ng-container *ngIf="headlineStatusCheckbox">
    <div class="headline-status-checkbox-wrapper mb-2">
      <app-checkbox-with-label
        (clicked)="toggleMarkAsHeadlineStatus()"
        [intialState]="'unchecked'"
        [label]="'Mark as Headline Status'">
      </app-checkbox-with-label>
    </div>
  </ng-container>

  <app-skeleton-history-loading
    *ngIf="isLoading && singleItemHistory"
    [count]="skeletonCount"
  ></app-skeleton-history-loading>
  <ng-container *ngIf="!isLoading">
    <ng-template #template let-dates="dates" let-dailyData="entries">
      <ng-container *ngFor="let date of dates">
        <!--date header-->
        <section class="feed-date" [ngClass]="{ margin: !singleItemHistory }">
          <div class="activity-feed-date-container">
            <div class="label-outer">
              <div class="label-inner" [class.grey-bg]="backgroundTheme === 'GREY'">
                {{ GetHeaderDate(date) }}
              </div>
            </div>
            <div class="activity-feed-line" [class.grey-bg]="backgroundTheme === 'GREY'"></div>
          </div>
        </section>

        <!--container wrapper-->
        <section class="feed-item" [ngStyle]="{ paddingTop: singleItemHistory ? '0' : '1.5rem' }">
          <ng-container *ngIf="!singleItemHistory">
            <ng-container *ngFor="let historyObjectId of getHistoryObjectKeysForDate(dailyData[date])">
              <app-card-history-feed
                [objectId]="getObjectId(historyObjectId, dailyData[date])"
                [ragStatus]="getObjectRAGStatus(historyObjectId, dailyData[date])"
                [title]="getTitle(historyObjectId, dailyData[date])"
                [objectType]="getObjectType(historyObjectId, dailyData[date])"
                [objectSubType]="getObjectSubType(historyObjectId, dailyData[date])"
              >
              </app-card-history-feed>

              <div class="activity-feed-history-outer-container mb-3" [class.pl-3]="!singleItemHistory">
                <div class="activity-feed-history-container">
                  <article class="activity-feed-content-wrapper">
                    <div
                      class="item-content-wrapper"
                      *ngFor="let historyEntry of getHistoryEntries(historyObjectId, dailyData[date])"
                      [innerHTML]="historyEntry.phrase"
                    ></div>
                  </article>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="singleItemHistory">
            <div class="activity-feed-history-outer-container" [class.pl-3]="!singleItemHistory">
              <div class="activity-feed-history-container">
                <article *ngIf="!showOnlyComments" class="activity-feed-content-wrapper">
                  <div
                    class="item-content-wrapper"
                    *ngFor="let historyEntry of dailyData[date]"
                    [innerHTML]="historyEntry.phrase | safeHtml"
                    routeTransform
                    ngImagePreview
                    (click)="handleClick($event)"
                  ></div>
                </article>
                <article *ngIf="showOnlyComments" class="activity-feed-content-wrapper">
                  <div
                    class="item-content-wrapper"
                    *ngFor="let historyEntry of comments[date]"
                    [innerHTML]="historyEntry.phrase | safeHtml"
                    routeTransform
                    ngImagePreview
                    (click)="handleClick($event)"
                  ></div>
                </article>
              </div>
            </div>
          </ng-container>
        </section>
      </ng-container>
    </ng-template>

    <div
      *ngIf="!showOnlyComments"
      [ngClass]="!hideToggle ? 'container-margin-top' : ''"
      #activityFeedContainer
      id="activity-feed-container"
    >
      <ng-container *ngIf="showIncidentHistoryTabs && showHeader; else nonTabsFeed">
        <ng-template
          *ngIf="currentlySelectedTab === allIncidentsHistoryId"
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ dates: historyItemDates, entries: historyMultipleEntries }"
        ></ng-template>
      </ng-container>

      <ng-template
        #nonTabsFeed
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{
          dates: historyItemDates,
          entries: singleItemHistory ? historyEntries : historyMultipleEntries
        }"
      ></ng-template>
    </div>

    <div *ngIf="showOnlyComments" #activityFeedContainer id="activity-feed-container">
      <ng-container *ngIf="showIncidentHistoryTabs && showHeader; else nonTabsFeed">
        <ng-template
          *ngIf="currentlySelectedTab === allIncidentsHistoryId"
          [ngTemplateOutlet]="template"
          [ngTemplateOutletContext]="{ dates: commentsDates, entries: comments }"
        ></ng-template>
      </ng-container>

      <ng-template
        #nonTabsFeed
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ dates: commentsDates, entries: comments }"
      ></ng-template>

      <ng-container *ngIf="commentsDates.length === 0">
        <app-empty-state
          [hideIcon]="true"
          [showAddItemBtn]="false"
          [itemTitle]="'test'"
          [message]="T.common.no_item_to_display | translate: { item: T.common.comment.many | translate }"
        ></app-empty-state>
      </ng-container>
    </div>
  </ng-container>
</div>
