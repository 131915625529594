import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Configuration } from 'src/app/app.constants';
import { ObjectTypes } from '../enums/objectTypes';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class PinningService {
  private actionUrl: string;

  constructor(private http: HttpClient, private configuration: Configuration, private alertService: AlertService) {
    this.actionUrl = this.configuration.buildEndpoint(`Pin/`);
  }

  pinItem(objectType: ObjectTypes, objectId: number) {
    return this.http.post(`${this.actionUrl}${objectType}/${objectId}`, {}).pipe(
      map((res: any) => {
        void this.alertService.success('Item pinned to MyTrack');
        return res;
      })
    );
  }

  unpinItem(objectType: ObjectTypes, objectId: number) {
    return this.http.delete(`${this.actionUrl}${objectType}/${objectId}`).pipe(
      map((res: any) => {
        void this.alertService.success('Item removed from MyTrack');
        return res;
      })
    );
  }
}
