import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { T } from 'src/assets/i18n/translation-keys';

@Component({
  selector: 'app-empty-map',
  templateUrl: './empty-map.component.html',
  styleUrls: ['./empty-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyMapComponent {
  @Input() emptyStateText = '';
  @Input() containerHeightPx = 400;
  @Input() emptyStateTextFontSize = 14;

  public readonly T = T;

  constructor() {

  }

}
