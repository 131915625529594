import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ToastrService, Toast, ToastPackage } from 'ngx-toastr';
@Component({
  selector: '[app-custom-toastr]',
  styleUrls: ['./custom-toastr.component.scss'],
  templateUrl: './custom-toastr.component.html',
  preserveWhitespaces: false,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
})
export class CustomToastrComponent extends Toast {
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  get iconImage() {
    let image: string = '';

    switch (this.toastPackage.toastType) {
      case 'toast-success': {
        image = 'icon-alert-success';
        break;
      }
      case 'toast-error': {
        image = 'icon-alert-error';
        break;
      }
      default: {
        image = 'icon-alert-success';
        break;
      }
    }
    return `assets/images/${image}.png`;
  }
}
