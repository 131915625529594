import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filters-inline-edit-lozenge',
  templateUrl: './filters-inline-edit-lozenge.component.html',
  styleUrls: ['./filters-inline-edit-lozenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.background-color]': 'applied ? "var(--wt-blue-extra-pale)" : ""',
    '[class.filters-inline-edit-lozenge-disabled]': 'disabled',
  },
})
export class FiltersInlineEditLozengeComponent {
  @Input() text: string;
  @Input() applied: boolean;
  @Input() disabled: boolean;
  @Input() showPrimaryIcon: boolean;
  @Input() isPrimary: boolean;

  @Output() clear: EventEmitter<void> = new EventEmitter();
  @Output() makePrimary: EventEmitter<boolean> = new EventEmitter();

  public hovered: boolean;

  onClear(e: Event): void {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.clear.emit();
  }

  onStarClick(e: Event): void {
    e.stopPropagation();
    e.stopImmediatePropagation();

    this.makePrimary.emit();
  }
}
