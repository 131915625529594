import { Component, ChangeDetectionStrategy, Input, SimpleChanges, ChangeDetectorRef, OnInit, OnChanges } from '@angular/core';
import { RAGBreakdown } from '../../../models/rag/ragBreakdown';

@Component({
  selector: 'app-simple-rag-breakdown-bar',
  templateUrl: './simple-rag-breakdown-bar.component.html',
  styleUrls: ['./simple-rag-breakdown-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleRAGBreakdownBarComponent implements OnInit, OnChanges {
  //Input
  @Input() ragBreakdowns: RAGBreakdown[];

  //public
  relativeWidths: number[] = [];
  //private

  isEmptyState: boolean = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.ragBreakdowns) {
      this.isEmptyState = this.ragBreakdowns?.findIndex((obj) => obj.count > 0) < 0;
      this.ragBreakdowns.forEach((rag, index) => {
        this.relativeWidths.push(this.calculateRelativeWidth(rag.count));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ragBrekdowns && !changes.ragBreakdowns.isFirstChange) {
      this.relativeWidths = [];
      this.isEmptyState = this.ragBreakdowns?.findIndex((obj) => obj.count > 0) < 0;
      this.ragBreakdowns.forEach((rag, index) => {
        this.relativeWidths.push(this.calculateRelativeWidth(rag.count));
      });
      this.changeDetectorRef.markForCheck();
    }
  }

  calculateRelativeWidth(value: number): number {
    return (value / this.ragBreakdowns.reduce((prev, curr) => prev + curr.count, 0)) * 100;
  }

  trackByMethod(index: number, el: RAGBreakdown): number {
    return el.count;
  }
}
