import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from 'src/app/app.constants';
import { PrepopulatedTextMessageInterface } from '../../shared/interfaces/prepopulated-text-messages/prepopulatedTextMessageInterface';
import { UpdatePrepopulatedTextMessageType } from '../../shared/interfaces/prepopulated-text-messages/updatePrepopulatedTextMessageType';
import { CreatePrepopulatedTextMessageType } from '../../shared/interfaces/prepopulated-text-messages/createPrepopulatedTextMessageType';

@Injectable({
  providedIn: 'root',
})
export class PrepopulatedTextMessagesService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration
  ) {
    this.actionUrl = this.configuration.buildEndpoint(`PrepopulatedTextMessage`);
  }

  public getPrepopulatedTextMsgsList(): Observable<PrepopulatedTextMessageInterface[]> {
    return this.http.get<PrepopulatedTextMessageInterface[]>(`${this.actionUrl}`);
  }

  public addPrepopulatedTextMsg(payload: CreatePrepopulatedTextMessageType): Observable<PrepopulatedTextMessageInterface[]> {
    return this.http.post<PrepopulatedTextMessageInterface[]>(`${this.actionUrl}`, payload);
  }

  public updatePrepopulatedTextMsg(payload: UpdatePrepopulatedTextMessageType): Observable<PrepopulatedTextMessageInterface[]> {
    return this.http.put<PrepopulatedTextMessageInterface[]>(`${this.actionUrl}`, payload);
  }

  public deletePrepopulatedTextMsg(msgId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.actionUrl}/${msgId}`);
  }
}
