import { Pipe, PipeTransform } from '@angular/core';
import { FilterTypes } from '../enums/filterTypes';
import { LocalisationService } from '../services/localisation.service';

@Pipe({
  name: 'localiseFilterType',
})
export class LocaliseFilterTypePipe implements PipeTransform {
  constructor(private readonly localisationService: LocalisationService) {}

  transform(filterType: FilterTypes, plural: boolean = false): string {
    return this.localisationService.localiseFilterType(filterType, plural);
  }
}
