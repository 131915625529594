import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { FilterSelectorTypes } from '../../../enums/filter/filterSelectorTypes';
import { FilterViewModel } from '../../../models/filter/filterViewModel';
import { FilterTypeSelectorViewModel } from '../../../viewModels/filters/filterTypeSelectorViewModel';
import { FilterTypes } from '../../../enums/filterTypes';
import { FilterUtilities } from '../../../utilities/filter.utilities';
import { ObjectTypes } from '../../../enums/objectTypes';
import { FilterActionTypes } from '../../../enums/filter/filterActionTypes.enum';

@Component({
  selector: 'app-simple-filters-container',
  templateUrl: './simple-filters-container.component.html',
  styleUrls: ['./simple-filters-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.margin-top]': 'lozengeInlineEdit ? "8px" : ""',
    '[style.max-height]': 'lozengeInlineEdit ? "360px" : ""',
    '[style.display]': 'lozengeInlineEdit ? "flex" : ""',
  },
})
export class SimpleFiltersContainerComponent implements OnInit, OnChanges {
  @Input() mobile: boolean;
  /** TODO to check where and why is needed? now used only for exclude? */
  @Input() lozengeInlineEdit: boolean;
  /** used in many places for refactoring 2.0 */
  @Input() filterTypeSelectorViewModel: FilterTypeSelectorViewModel;
  @Input() allowedFilters: FilterViewModel[];
  @Input() appliedFilters: FilterViewModel[];
  /** it is heavely used it is for decideing if we need single select or multy select drop down for refactoring 2.0 */
  @Input() singleSelect: boolean = false;
  @Input() showOnlyActive: boolean = false;
  @Input() showTitle: boolean = true;
  @Input() isFilterRequired: boolean = false;
  /**
    * Wheather to use simple update or not. If true, the component will emit only the updated filters.
   */
  @Output() filtersUpdated = new EventEmitter<FilterViewModel[]>();
  /**
   * Emits only the updated filters when useLightUpdate is true.
   */
  @Output() lightUpdate = new EventEmitter<FilterViewModel[]>();

  localAppliedFilters: FilterViewModel[] = [];
  filterSelectorTypes = FilterSelectorTypes;
  filterTypes = FilterTypes;
  canEdit: boolean = true;
  hasBeenInitExecuded = false;
  required: boolean = false;

  constructor(readonly elementRef: ElementRef<HTMLElement>, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (!FilterUtilities.IncludeZeroValues(this.filterTypeSelectorViewModel.filterType)) {
      this.allowedFilters = this.allowedFilters.filter((d) => d.filterValue);
    }

    if (
      this.filterTypeSelectorViewModel.displayForObjectType === ObjectTypes.Risk &&
      this.filterTypeSelectorViewModel.filterType === FilterTypes.Owner
    ) {
      this.required = true;
    }

    this.localAppliedFilters = (<FilterViewModel[]>JSON.parse(JSON.stringify(this.appliedFilters))).filter((f) => +f.filterType === +this.filterTypeSelectorViewModel.filterType);
    // Removes actions from the filters to ensure fresh data
    this.localAppliedFilters = this.stripFilterActions(this.localAppliedFilters);

    this.hasBeenInitExecuded = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appliedFilters && this.hasBeenInitExecuded) {
      this.localAppliedFilters = (<FilterViewModel[]>JSON.parse(JSON.stringify(this.appliedFilters))).filter((f) => +f.filterType === +this.filterTypeSelectorViewModel.filterType);

      // Removes actions from the filters to ensure fresh data
      this.localAppliedFilters = this.stripFilterActions(this.localAppliedFilters);

      this.changeDetectorRef.detectChanges();
    }
  }

  updateFilters(filters: FilterViewModel[]) {
    const updatedFilters = this.setFilterActions(filters, this.localAppliedFilters);
    this.localAppliedFilters = filters;

    // Cхecks if there any changes in the filters before broadcasting
    if(updatedFilters.length > 0){
      this.BroadcastChanges(updatedFilters);
    }
  }

  updateSingleSelectFilter(filters: FilterViewModel[]) {
    const updatedFilter  = filters[0];
    updatedFilter.filterAction = FilterActionTypes.Update ;
    this.localAppliedFilters = [updatedFilter];

    this.BroadcastChanges([updatedFilter]);
  }

  getProperFilterType(): FilterTypes {
    return this.filterTypeSelectorViewModel.filterType;
  }

  private BroadcastChanges(filters: FilterViewModel[]) {
    this.filtersUpdated.next(filters);
  }

  /**
   * Sets the filter action for each filter.
   * In case there are new filters, the action for each of them is set to Add.
   * In case there are removed filters, the action for each of them is set to Remove.
   */
  private setFilterActions(newFilters: FilterViewModel[], previousFilters: FilterViewModel[]): FilterViewModel[] {
    const removedFilters = previousFilters.filter((f) => !newFilters.find((f2) => f2.id == f.id));
    removedFilters.forEach((a) => (a.filterAction = FilterActionTypes.Remove));

    const addedFilters = newFilters.filter((f) => !previousFilters.find((f2) => f2.id == f.id));
    addedFilters.forEach((a) => (a.filterAction = FilterActionTypes.Add));

    return [...removedFilters, ...addedFilters];
  }

  /**
   * Removes the filter action from each filter.
   */
  private stripFilterActions(filters: FilterViewModel[]) {
    filters.forEach((f) => (f.filterAction = null));
    return filters;
  }

}
