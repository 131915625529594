<button
  class="rectangle-icon-btn"
  (click)="onClick($event)"
  [disabled]="disabled"
  [class.fit-to-container]="fitToContainer"
  [ngClass]="getButtonClasses()"
  [ngStyle]="isWithinEventDetails ? {'padding-right': '0'} : {}"
  [style.border-color]="borderColor"
>
  <div class="rectangle-icon-btn-container">
    <ng-container *ngIf="!loading">
      <i class="material-symbols-outlined" *ngIf="icon && !isWithinEventDetails" [style.color]="textColor" [style.margin.px]="text ? '0 8 0 -3' : '0'">{{
        icon
      }}</i>
      <div class="button-text text-truncate" [style.color]="textColor">{{ text }}</div>
      <i class="material-symbols-outlined ms-2" *ngIf="icon && isWithinEventDetails" [style.color]="textColor" [style.margin.px]="text ? '0 8 0 -3' : '0'">{{
          icon
        }}</i>
      <span
        *ngIf="svg && svg.length"
        [style.margin-left.px]="!!text ? 8 : 'unset'"
        [class.disabled]="disabled"
        [innerHtml]="svg | safeHtml"
      >
      </span>
      <span *ngIf="showDropdownArrow" class="ms-2 me-1">|</span>
      <span *ngIf="showDropdownArrow">
        <i class="material-symbols-outlined dropdown-icon">
          expand_more
        </i>
      </span>
    </ng-container>
    <ng-container *ngIf="loading">
      <app-button-loader [ngClass]="getButtonClasses()"></app-button-loader>
    </ng-container>
  </div>
</button>
