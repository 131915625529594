<ng-container *ngIf="disabled">
  <div class="icon-filter-container">
    <div class="icon-wrapper">
      <app-details-icon [type]="iconType" [size]="40" [backgroundFill]="currentFilterColour"></app-details-icon>
      <i class="material-symbols-outlined">arrow_drop_down</i>
    </div>
    <div class="filter-container">
      <div class="heading">{{ label }}:</div>
      <div class="description">{{ currentFilter?.filterText }}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!disabled">
  <div class="icon-filter-container" ngDropdown [templateRef]="filterTemplate" (click)="toggleMobileFilter()">
    <div class="icon-wrapper">
      <app-details-icon [type]="iconType" [size]="40" [backgroundFill]="currentFilterColour"></app-details-icon>
      <i class="material-symbols-outlined">arrow_drop_down</i>
    </div>
    <div class="filter-container">
      <div class="heading">{{ label }}:</div>
      <div class="description">{{ currentFilter?.filterText }}</div>
    </div>
  </div>
</ng-container>

<ng-template #filterTemplate>
  <app-filters-color
    [filters]="availableFilters"
    [appliedFilters]="[currentFilter]"
    [singleSelect]="true"
    [includeComment]="includeComment"
    [commentRequiredForValues]="commentRequiredForValues"
    [singleSelectWithApply]="true"
    [mobile]="mobile"
    [filterType]="getFilterTypes()"
    (filtersWithCommentAdded)="onFiltersWithCommentAdded($event)"
  ></app-filters-color>
</ng-template>

<div *ngIf="mobile && mobileFilterVisible" class="dropdown-mobile">
  <app-filters-mobile-header *ngIf="mobile" [title]="'Search'" (cancel)="onCancel()" (clear)="onClear($event)">
  </app-filters-mobile-header>

  <div class="dropdown-mobile-content">
    <ng-template [ngTemplateOutlet]="filterTemplate"></ng-template>
  </div>
</div>
