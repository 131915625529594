<div class="card-wrapper">
  <div class="card-left">
    <div class="icon">
      <app-card-icon
        [size]="'32'"
        [trendRAG]="emissionsData.trendRAG"
        [trendStatus]="emissionsData.trendStatus"
        [cardType]="cardType"
      ></app-card-icon>
    </div>
    <div class="card-center-wrapper">
      <span class="indicator-title text-truncate">{{ emissionsData.title }}</span>
      <span class="sub-title text-truncate">
        <span class="category-label">
          Category:
        </span>
        <span class="category-title">{{ emissionsData.indicatorCategoryTitle }}</span>
    </span>
    </div>
  </div>
  <div class="card-right">
    <div class="card-right-title">{{ emissionsData.co2e }}t CO2e</div>
  </div>
</div>
