import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DashboardLayoutViewModel } from 'src/app/modules/shared/viewModels/dashboard/dashboardLayoutViewModel';
import { T } from 'src/assets/i18n/translation-keys';
import { Employee } from '../../../models/employee';
import { WtValidators } from '../../../reactiveValidators/wtValidators';
import { AuthenticationService } from '../../../services/authentication.service';
import { EmployeeUtil } from '../../../utilities/employee.utilities';

@Component({
  selector: 'app-save-dashboard-view',
  templateUrl: './save-dashboard-view.component.html',
  styleUrls: ['./save-dashboard-view.component.scss'],
})
export class SaveDashboardViewComponent implements OnInit {
  onLayoutSave: EventEmitter<DashboardLayoutViewModel> = new EventEmitter();
  onLayoutUpdate: EventEmitter<DashboardLayoutViewModel> = new EventEmitter();

  public savedLayouts: DashboardLayoutViewModel[] = [];
  public layout: DashboardLayoutViewModel;
  public readonly T = T;
  public layoutForm: UntypedFormGroup;
  public isAdmin: boolean = false;
  public currentEmployee: Employee;
  public saveAsPublic: boolean = false;
  public selectedLayoutForUpdate: DashboardLayoutViewModel;

  constructor(
    private fb: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private readonly authenticationService: AuthenticationService,
    private readonly wtValidators: WtValidators
  ) {}

  ngOnInit(): void {
    this.currentEmployee = this.authenticationService.getCurrentEmployee();
    this.isAdmin = EmployeeUtil.IsAdmin(this.currentEmployee);
    this.initNewForm();
  }

  get layoutFormIsValid(): boolean {
    return this.layoutForm.controls.title.valid;
  }

  /**
   * Make the currently selected layout as public
   */
  public onMakeLayoutAsPublic(isChecked: boolean): void {
    this.saveAsPublic = isChecked;
  }

  /**
   * Check if existing layout is selected and update the 'layoutForm' and 'selectedLayoutForUpdate' component properties
   */
  public onLayoutSelect(layout: DashboardLayoutViewModel): void {
    if (this.selectedLayoutForUpdate && this.selectedLayoutForUpdate.id === layout.id) {
      this.selectedLayoutForUpdate = undefined;
      this.layoutForm.controls.title.setValue('');
    } else {
      this.selectedLayoutForUpdate = layout;
      this.layoutForm.controls.title.setValue(layout.title);
    }
  }

  /**
   * Check if the current layout needs to update existing layout or save new  one
   */
  public saveNewLayout(): void {
    this.layout.title = this.layoutForm.controls.title.value.trim();
    this.layout.public = this.saveAsPublic;
    this.layout.dashboardWidgets.forEach((x) => {
      x.id = 0;
    });

    if (this.selectedLayoutForUpdate) {
      this.layout.id = this.selectedLayoutForUpdate.id;
      this.onLayoutUpdate.next(this.layout);
    } else {
      this.onLayoutSave.next(this.layout);
    }
    this.bsModalRef.hide();
  }

  /**
   * Init layout form
   */
  public initNewForm(): void {
    this.layoutForm = this.fb.group({
      title: ['', { validators: [Validators.required, this.wtValidators.title(), this.wtValidators.restrictedChars([';'])] }],
    });
  }
}
