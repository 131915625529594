import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-range-time-lozenge',
  templateUrl: './range-time-lozenge.component.html',
  styleUrls: ['./range-time-lozenge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeTimeLozengeComponent implements OnInit {
  @Input() startDateISO: string;
  @Input() endDateISO: string;
  @Input() comparatorDateISO: string;
  @Input() showLock: boolean;

  public startTimeOffset: string = '';
  public endTimeOffset: string = '';
  public errorTimeOffset: boolean = false;

  ngOnInit(): void {
    this.calculateDayOffsets();
  }

  ngOnChanges(): void {
    this.calculateDayOffsets();
  }

  calculateDayOffsets(): void {
    if (!this.comparatorDateISO) {
      return;
    }

    this.startTimeOffset = '';
    this.endTimeOffset = '';

    const startDateMomentTimeless = moment(this.startDateISO).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const endDateMomentTimeless = moment(this.endDateISO).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const comparatorDateMomentTimeless = moment(this.comparatorDateISO).set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    if (this.startDateISO) {
      const startDiffDays = startDateMomentTimeless.diff(comparatorDateMomentTimeless, 'days');
      if (startDiffDays) {
        this.startTimeOffset = `${startDiffDays > 0 ? '+' : ''}${startDiffDays ?? ''}`;
      }
    }

    if (this.endDateISO) {
      const endDiffDays = endDateMomentTimeless.diff(comparatorDateMomentTimeless, 'days');
      if (endDiffDays) {
        this.endTimeOffset = `${endDiffDays > 0 ? '+' : ''}${endDiffDays ?? ''}`;
      }
    }

    this.setErrorLozengeStyle();
  }

  setErrorLozengeStyle() {
    if (this.startTimeOffset && Math.abs(+this.startTimeOffset) != 1) {
      this.errorTimeOffset = true;
    }
    if (this.endTimeOffset && Math.abs(+this.endTimeOffset) != 1) {
      this.errorTimeOffset = true;
    }
  }
}
