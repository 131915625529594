import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PopupEventsEmitter } from '../../../events/popup.events';
import { IncidentsManager } from '../../../managers/incidents.manager';
import { IncidentStatuses } from 'src/app/modules/incidents/enums/incidentStatuses';
import { Position } from '../../../enums/position';

@Component({
  selector: 'app-status-picker',
  templateUrl: './status-picker.component.html',
  styleUrls: ['./status-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusPickerComponent implements OnInit, OnDestroy {
  @Input() status: IncidentStatuses = IncidentStatuses.Open;
  @Input() popupPosition: Position;
  @Input() popupArrowPosition: Position[];
  @Input() useLargeBubble: boolean;
  @Input() isReadonly: boolean = false;
  @Input() readonlyMessage: string;
  @Output() statusSelected: EventEmitter<IncidentStatuses> = new EventEmitter();

  @ViewChild('wrapper', { static: false }) wrapper: ElementRef<HTMLElement>;

  statuses: any;
  currentlySelectedStatus: IncidentStatuses = this.status;
  private subscriptions = new Subscription();

  constructor(
    private readonly popupEventsEmitter: PopupEventsEmitter,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly incidentsManager: IncidentsManager
  ) {}

  ngOnInit() {
    this.statuses = this.incidentsManager.statuses;
    this.subscriptions.add(
      this.popupEventsEmitter.popupCancelClicked$
        .pipe(
          filter(
            () =>
              this.popupEventsEmitter.directiveElementRef &&
              this.popupEventsEmitter.directiveElementRef.nativeElement === this.wrapper.nativeElement
          )
        )
        .subscribe(() => {
          this.currentlySelectedStatus = this.status;
        })
    );

    this.subscriptions.add(
      this.popupEventsEmitter.popupContentChanged$.subscribe((res: IncidentStatuses) => {
        this.currentlySelectedStatus = res;
        this.status = res;
        this.changeDetectorRef.markForCheck();
      })
    );

    this.subscriptions.add(
      this.popupEventsEmitter.popupSaveChangesClicked$
        .pipe(
          filter(
            () =>
              this.popupEventsEmitter.directiveElementRef &&
              this.popupEventsEmitter.directiveElementRef.nativeElement === this.wrapper.nativeElement
          )
        )
        .subscribe(() => {
          this.statusSelected.emit((this.status = this.currentlySelectedStatus));
          this.changeDetectorRef.markForCheck();
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onStatusSelected(taskStatus: IncidentStatuses) {
    this.currentlySelectedStatus = taskStatus;
  }

  onClick() {
    this.currentlySelectedStatus = this.status;
  }

  getColourByStatus(status: number) {
    return this.incidentsManager.getColourByStatus(status);
  }

  getIconByStatus(status: number) {
    return this.incidentsManager.getIconByStatus(status);
  }
}
