<app-filters-inline-edit-container
  [mobile]="mobile"
  [lozengeInlineEdit]="true"
  [filters]="tagFilters"
  [appliedFilters]="appliedFilters"
  [isUsedAlone]="true"
  [filterTypeSelectorViewModel]="filterTypeSelectorViewModel"
  (filtersAdded)="onFiltersAdded($event)"
  (filtersUpdated)="onFilterChanged($event)"
  (filtersRemoved)="onFiltersRemoved($event)"
>
</app-filters-inline-edit-container>
