<div
  #tabset
  class="tabset-tabs custom-scrollbar"
  [ngClass]="isSticky ? 'sticky' : ''"
  [style.height]="isMobile ? '45px' : '34px'"
>
  <div
    class="tabset-tab"
    [class.tabset-tab-hidden]="!tab.isTabVisible"
    *ngFor="let tab of tabs"
    (click)="onTabClicked(tab)"
    [class.tabset-tab-active]="tab.active"
  >
    <ng-container *ngIf="tab.isTabVisible">
      <ng-template [ngTemplateOutlet]="tab.tabHeadingDirective.templateRef"></ng-template>
      <div [style.margin]="isMobile ? '2px 0' : '0px'" class="tabset-tab-active-indicator"></div>
    </ng-container>
  </div>
</div>

<ng-container *ngFor="let tab of tabs">
  <ng-container *ngIf="tab.active">
    <ng-template [ngTemplateOutlet]="tab.tabContentDirective.templateRef"></ng-template>
  </ng-container>
</ng-container>
