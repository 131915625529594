<div class="badges-container">
  <span *ngFor="let t of targets" style="vertical-align: middle; display: flex">
    <ng-container *ngIf="t.badgeType !== 0">
      <ng-container *ngIf="isTargetWithBadgeName(t)">
        <span
          *ngIf="showToolTipText"
          [app-tooltip]="{ message: t.tooltipText, position: ['top'] }"
          [innerHTML]="getIndicatorTargetBadgeSVGIcon(t) | safeHtml"
        ></span>

        <span *ngIf="!showToolTipText" [innerHTML]="getIndicatorTargetBadgeSVGIcon(t) | safeHtml"></span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="t.badgeType === 0">
      <span *ngIf="showToolTipText" [app-tooltip]="{ message: t.tooltipText, position: ['top'] }">
        <i [style.font-size]="size + 'px'" [class.grey-icon]="disabled" class="material-symbols-outlined">track_changes</i>
      </span>

      <span *ngIf="!showToolTipText">
        <i [style.font-size]="size + 'px'" [class.grey-icon]="disabled" class="material-symbols-outlined">track_changes</i>
      </span>
    </ng-container>
  </span>
</div>
