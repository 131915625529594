import { ObjectTypes } from '../../enums/objectTypes';
import { FilterViewModel } from './filterViewModel';

export class LightFilterUpdateModel {
  globalObjectId: number;
  accountId: number = 0;
  objectType: ObjectTypes;
  changes: FilterViewModel[];
  updated: string;
}
