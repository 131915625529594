<app-filters-mobile-header
  *ngIf="mobile"
  [title]="filterType | localiseFilterType"
  [useClear]="true"
  [clearDisabled]="isClearDisabled"
  (cancel)="onCancel()"
  (clear)="onClearAll()"
>
</app-filters-mobile-header>

<div class="filters-events">
  <app-filters-search-input [filterTitle]="filterType | localiseFilterType" (search)="onSearch($event)">
  </app-filters-search-input>

  <ng-container *ngIf="filterType === filterTypes.Zone">
    <app-filters-entries-information
      [entriesCount]="filteredZones.length"
      [matchedEntriesCount]="searchValue ? filteredZones.length : undefined"
    ></app-filters-entries-information>
    <div class="filters-events-entries">
      <app-dropdown-option
      *ngFor="let zone of filteredZones"
      [value]="zone"
      [mobile]="mobile"
      [useBorderBottom]="mobile"
      [selected]="isZoneSelected(zone)"
      (selectionChange)="onEntryZoneSelected(zone)"
      >
        <div *ngIf="zone.color" class="filled-round-box mr-8" [style.background]="zone.color"></div>
        {{zone.title}}
      </app-dropdown-option>
    </div>
  </ng-container>

  <ng-container *ngIf="filterType === filterTypes.Event">
    <app-filters-entries-information
      [entriesCount]="filteredEvents.length"
      [matchedEntriesCount]="searchValue ? filteredEvents.length : undefined"
    ></app-filters-entries-information>
    <div class="filters-events-entries">
      <app-filters-live-event-entry
        *ngFor="let event of filteredEvents"
        [event]="event"
        [selected]="isEventSelected(event)"
        (eventSelected)="onEntryEventSelected(event)"
      ></app-filters-live-event-entry>
    </div>
  </ng-container>

  <ng-container *ngIf="filterType === filterTypes.Incident_Channel">
    <app-filters-entries-information
      [entriesCount]="channels.length"
      [matchedEntriesCount]="searchValue ? filteredChannels.length : undefined"
    ></app-filters-entries-information>
    <div class="filters-events-entries">
      <app-dropdown-option
      *ngFor="let channel of filteredChannels"
      [value]="channel"
      [useBorderBottom]="mobile"
      [selected]="isChannelSelected(channel)"
      (selectionChange)="onEntryChannelSelected(channel)"
      >
        {{channel.title}}
      </app-dropdown-option>
    </div>
  </ng-container>
</div>

<app-filters-desktop-footer
  *ngIf="!mobile"
  [useClearAllButton]="true"
  [useCancelApplyButtons]="true"
  [clearAllButtonLabel]="clearAllButtonLabel"
  [clearDisabled]="isClearDisabled"
  [applyDisabled]="isFilterUpdated"
  [useClearAllButtonIcon]="false"
  (clear)="onClearAll()"
  (cancel)="onCancel()"
  (apply)="onApply()"
>
</app-filters-desktop-footer>

<app-filters-mobile-footer *ngIf="mobile" [applyDisabled]="isFilterUpdated" (apply)="onApply()">
</app-filters-mobile-footer>
