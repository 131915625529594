<div class="section-header">
  <span>{{T.dashboard.dashboard_widgets | translate}}</span>
</div>

<div class="row content-wrapper mb-2">
  <ng-container *ngIf="allComponents.length > 0">
    <div class="col-12 mb-2">
      <span class="heading-text"> {{T.dashboard.choose_widget_to_display | translate}} </span>
    </div>
    <div class="col-12" *ngFor="let component of allComponents; let i = index;">
      <div class="checkbox-wrapper">
        <app-checkbox [isChecked]="isComponentVisible(component)" (checked)="onComponentChecked($event, component)">
        </app-checkbox>
        <span> {{ getComponentName(i) }} </span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="allComponents.length === 0">
    <div class="col-12">{{T.dashboard.no_available_widgets | translate}}</div>
  </ng-container>
</div>
