import { Injectable } from '@angular/core';
import { Account } from '../models/account';
import { AuthenticationService } from './authentication.service';
import { FilterTypes } from '../enums/filterTypes';
import { LoginEventsEmitter } from '../events/login.events';
import { ObjectTypes } from '../enums/objectTypes';
import { AccountLocalisationDictionaryViewModel } from '../models/accountLocalisationDictionary';
import { PrivacyStatuses } from '../enums/privacyStatuses';
import { WtTranslationService } from '../../wt-translation/wt-translation.service';
import { EnumBasedLocalisationUtility } from '../utilities/enum-based-localisation.utilities';
import { MilestoneTypes } from '../enums/milestoneTypes';
import { TranslateService } from '@ngx-translate/core';
import { T } from 'src/assets/i18n/translation-keys';
import { EnumUtilities } from '../utilities/enum.utilities';

@Injectable()
export class LocalisationService {
  private currentAccount: Account;
  private localisations: AccountLocalisationDictionaryViewModel[] = [];

  private readonly T = T;
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly loginEventEmitters: LoginEventsEmitter,
    private readonly wtTranslationService: WtTranslationService,
    private readonly translateService: TranslateService
  ) {
    this.currentAccount = this.authenticationService.getCurrentAccount();
    this.loginEventEmitters.currentAccountChanged$.subscribe(() => {
      this.currentAccount = this.authenticationService.getCurrentAccount();
      this.localisations = [];
    });
  }

  public localiseFilterValueByFilterType(value: number, filterType: FilterTypes) {
    if (!this.currentAccount) {
      this.currentAccount = this.authenticationService.getCurrentAccount();
    }

    if (this.currentAccount && this.currentAccount.enumBasedLocalisations) {
      const matchingLocalisation = this.currentAccount.enumBasedLocalisations.find(
        (l) =>
          (l.filterType === filterType && l.enumValue === value)
      );
      if (matchingLocalisation) {
        const defaultText = EnumBasedLocalisationUtility.getEnumBasedLocalisationDefaultText(filterType, value);
        // If localisedValue is the same as the default text, then return translation of the default text
        if (defaultText === matchingLocalisation.localisedValue) {
          return this.wtTranslationService.getDefaultFilterValueTranslation(filterType, value);
        }
        return matchingLocalisation.localisedValue;
      } else if (filterType === FilterTypes.Milestone_Type && (!value || value.toString() === '0')) {
        return this.wtTranslationService.getDefaultFilterValueTranslation(filterType, MilestoneTypes.No_Milestone);
      }
    }
  }

  public getLocalizedIncidentPrivacyStatuses(): { key: PrivacyStatuses; value: string }[] {
    const privacyStatuses = EnumUtilities.items(PrivacyStatuses);
    return privacyStatuses.map((s) => {
      const val = this.localiseFilterValueByFilterType(s.key as PrivacyStatuses, FilterTypes.Privacy_Status);

      return {
        key: s.key as PrivacyStatuses,
        value: val ? val : s.value,
      };
    });
  }

  public localise(value: string): string {
    if (!value) return '';

    const localisation = this.getLocalisationFromList(value);
    if (localisation) {
      return localisation.Value;
    }

    if (!this.currentAccount) {
      this.currentAccount = this.authenticationService.getCurrentAccount();
      this.localisations = [];
    }

    let languageDictionary: AccountLocalisationDictionaryViewModel[] = [];
    if (this.currentAccount && this.currentAccount.languageDictionaryString) {
      languageDictionary = JSON.parse(this.currentAccount.languageDictionaryString) as AccountLocalisationDictionaryViewModel[];
    }

    value = value.replace(/[_]/g, ' ');

    const orderedLanguageDictionary = languageDictionary.sort((a, b) => {
      return b.Key.split(' ').length - a.Key.split(' ').length;
    });

    const splitted = value.split(' ');
    let result: string = '';

    for (let i = 0; i < splitted.length; i++) {
      let currentLocalisation: AccountLocalisationDictionaryViewModel;
      let foundMatch = false;

      if (i < splitted.length - 1) {
        currentLocalisation = orderedLanguageDictionary.find(
          (l) => l.Key.toLowerCase() === splitted[i].toLowerCase() + ' ' + splitted[i + 1].toLowerCase()
        );

        if (currentLocalisation !== undefined) {
          result += `${currentLocalisation.Value} `;
          splitted[i + 1] = '';
          i++;
          foundMatch = true;
        }
      }

      if (!foundMatch) {
        currentLocalisation = orderedLanguageDictionary.find((l) => l.Key.toLowerCase() === splitted[i].toLowerCase());

        if (currentLocalisation) {
          result += `${currentLocalisation.Value} `;
        } else {
          result += `${splitted[i]} `;
        }
      }
    }

    return result.trimEnd();
  }

  public localiseFilterType(ft: FilterTypes, plural: boolean = false): string {

    if (ft === FilterTypes.Runsheet_Item_Dependencies_Count) {
      return 'Dependencies';
    }

    if (ft === FilterTypes.Incident_Channel) {
      return this.wtTranslationService.getDefaultFilterTypeTranslation(FilterTypes.Incident_Channel);
    }

    const s = EnumUtilities.items(FilterTypes).find((x) => x.key === ft);

    if (!s) {
      return null;
    }

    let isVenueDepartament = false;
    if (ft === FilterTypes.Venue_Department) {
      isVenueDepartament = true;
    }

    const valSpl = s.value.split(' ');
    const leadStr = 'Lead';

    let localisedStr = '';
    const defaultTranslation = this.wtTranslationService.getDefaultFilterTypeTranslation(ft, plural);
    if (valSpl.length > 1 && ft === FilterTypes.Venue_Department) {
      valSpl.forEach((a, index) => {
        const lStr = this.localiseStringCapitalize(a);
        if (isVenueDepartament && index === 1) localisedStr = localisedStr + ' ' + leadStr + ' ' + lStr;
        else {
          localisedStr = localisedStr + ' ' + lStr;
        }
      });

      localisedStr = localisedStr.trimLeft();
    } else {
      const localisation = this.getLocalisationFromList(s.value);

      if (localisation) {
        if (!this.isCustomizedLocalisation(localisation)) {
          return defaultTranslation;
        }
        return localisation.Value;
      }

      if (!this.currentAccount) {
        this.currentAccount = this.authenticationService.getCurrentAccount();
        this.localisations = [];
      }

      let languageDictionary: AccountLocalisationDictionaryViewModel[] = [];
      if (this.currentAccount && this.currentAccount.languageDictionaryString) {
        languageDictionary = JSON.parse(this.currentAccount.languageDictionaryString) as AccountLocalisationDictionaryViewModel[];
      }

      const value = languageDictionary.find((f) => f.FilterType == s.key);
      if (value) {
        this.localisations.push(JSON.parse(JSON.stringify(value)) as AccountLocalisationDictionaryViewModel);
        if (!this.isCustomizedLocalisation(value)) {
          return defaultTranslation;
        }
        return value.Value;
      } else if (defaultTranslation) {
        return defaultTranslation;
      } else {
        return s.value.replace('Ims', '');
      }
    }

    return localisedStr.replace('Ims', '');
  }

  public localiseObjectType(objectType: ObjectTypes, plural: boolean = false): string {
    let objectTypeText = ObjectTypes[objectType].replace(/_/g, " ");
    if (objectType !== ObjectTypes.Runsheet_Item) {
      objectTypeText = objectTypeText.replace('Item', '');
    }

    return objectType !== undefined ? this.localiseByObjectType(objectType, objectTypeText, plural) : '';
  }

  public localiseString(s: string) {
    if (typeof s !== 'number') {
      return this.localise(s.replace('_', ' ')).replace('Ims', '');
    }
    return s;
  }

  public localiseStringCapitalize(str: string, capitalize: boolean = true) {
    if (capitalize) {
      return this.capitalizeFirstLetter(this.localise(str));
    } else {
      return this.localise(str);
    }
  }

  public clearCachedLocalisations() {
    this.localisations = [];
  }

  public localiseSubTask(plural: boolean = false): string {
    this.localiseObjectType(ObjectTypes.Task);
    const taskLocalisation = this.localisations.find((f) => f.ObjectType === ObjectTypes.Task);

    // This means that the "task" is not customized
    if (!this.isCustomizedLocalisation(taskLocalisation)) {
      return this.translateService.instant(T.defaultLocalizations.sub_task[plural ? 'many' : 'one']);
    } else {
      return 'Sub-' + taskLocalisation.Value;
    }
  }

  //#region Helpers

  private capitalizeFirstLetter(str: string) {
    const splitted = str.split('/');
    if (splitted && splitted.length && splitted.length > 1) {
      for (let i = 0; i < splitted.length; i++) {
        if (!splitted[i].toLowerCase().startsWith('fa')) {
          splitted[i] = splitted[i].charAt(0).toUpperCase() + splitted[i].slice(1);
        } else {
          splitted[i] = splitted[i].charAt(0).toUpperCase() + splitted[i].charAt(1).toUpperCase() + splitted[i].slice(2);
        }
      }
      return splitted.join('/');
    }

    if (!str.startsWith('fa')) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return str.charAt(0).toUpperCase() + str.charAt(1).toUpperCase() + str.slice(2);
    }
  }

  private localiseByObjectType(objectType: ObjectTypes, objectTypeText: string, plural: boolean = false): string {
    const localisation = this.getLocalisationFromList(objectTypeText);
    const defaultText = this.wtTranslationService.getDefaultObjectTypeTranslation(objectType, plural);

    if (localisation) {
      if (!this.isCustomizedLocalisation(localisation)) {
        return defaultText;
      }
      return localisation.Value;
    }

    if (!this.currentAccount) {
      this.currentAccount = this.authenticationService.getCurrentAccount();
      this.localisations = [];
    }

    let languageDictionary: AccountLocalisationDictionaryViewModel[] = [];
    if (this.currentAccount && this.currentAccount.languageDictionaryString) {
      languageDictionary = JSON.parse(this.currentAccount.languageDictionaryString) as AccountLocalisationDictionaryViewModel[];
    }

    const value = languageDictionary.find((f) => f.ObjectType === objectType);

    if (value) {
      this.localisations.push(JSON.parse(JSON.stringify(value)) as AccountLocalisationDictionaryViewModel);
      if (!this.isCustomizedLocalisation(value)) {
        return defaultText;
      }
      return value.Value;
    } else if (defaultText) {
      return defaultText;
    } else {
      return objectTypeText;
    }
  }

  private getLocalisationFromList(value: string) {
    const localisation = this.localisations.find((l) => l.Key === value);
    return localisation;
  }

  /**
   * Checks if localisation is customized for the current account,
   * a customized localisation is a localisation that is not the default one. That means the key and value are not the same.
   */
  private isCustomizedLocalisation(localisation: AccountLocalisationDictionaryViewModel): boolean {
    return localisation.Key !== localisation.Value;
  }

  /**
   * Capitalize first letter of every word in string (words that are separated with spaces)
   */
  public capitalizeEachWord(str: string): string {
    return str
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }

  //#endregion
}
