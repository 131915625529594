import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter, forkJoin, tap } from 'rxjs';
import { ModuleService } from './module.service';
import { HeaderFiltersTypeService } from './header-filters-type.service';
import { FilterTypeSelectorViewModel } from '../viewModels/filters/filterTypeSelectorViewModel';
import { CustomFiltersService } from './custom-filters.service';
import { AuthenticationService } from './authentication.service';
import { Employee } from '../models/employee';
import { Account } from '../models/account';
import { EmployeeCustomFilterViewModel } from '../models/filter/employeeCustomFilterViewModel';
import { HeaderFiltersService } from './header-filters.service';
import { FilterViewModel } from '../models/filter/filterViewModel';
import { ObjectEventEmitters } from '../events/object.events';
import { ObjectTypes } from '../enums/objectTypes';

@Injectable({
  providedIn: 'root'
})
export class SideFiltersService {
  public employee: Employee;
  public account: Account;

  private readonly _isSideFiltersOpened = new BehaviorSubject<boolean>(false);
  public readonly isSideFiltersOpened$ = this._isSideFiltersOpened.asObservable();

  private _filterTypeSelectorVMsForCurrentObjectTypes = new BehaviorSubject<FilterTypeSelectorViewModel[]>([]);
  public filterTypeSelectorVMsForCurrentObjectTypes$ = this._filterTypeSelectorVMsForCurrentObjectTypes.asObservable();
  public get filterTypeSelectorVMsForCurrentObjectTypes(): FilterTypeSelectorViewModel[] {
    return this._filterTypeSelectorVMsForCurrentObjectTypes.getValue();
  }
  private _favouriteFilters = new BehaviorSubject<EmployeeCustomFilterViewModel[]>([]);
  public favouriteFilters$ = this._favouriteFilters.asObservable();
  public get favouriteFilters(): EmployeeCustomFilterViewModel[] {
    return this._favouriteFilters.getValue();
  }
  private _suggestedFilters = new BehaviorSubject<EmployeeCustomFilterViewModel[]>([]);
  public suggestedFilters$ = this._suggestedFilters.asObservable();

  // Filters observables
  private get initFilterTypesForCurrentObjects$(): Observable<FilterTypeSelectorViewModel[]> {
    return this.headerFiltersTypeService.getFilterHeaderFilterTypes(this.moduleService.currentObjectTypes);
  }
  private get initFavouriteFilters$(): Observable<EmployeeCustomFilterViewModel[]> {
    return this.customFiltersService.getEmployeeCustomFilters();
  }
  private get initSuggestedFilters$(): Observable<EmployeeCustomFilterViewModel[]> {
    return this.customFiltersService.getSuggestedFilters(this.account.id, this.employee);
  }

  // public favouriteMatchingFilter: EmployeeCustomFilterViewModel;
  private _favoriteMatchingFilter = new BehaviorSubject<EmployeeCustomFilterViewModel>(null);
  public favoriteMatchingFilter$ = this._favoriteMatchingFilter.asObservable();


  constructor(
    private readonly moduleService: ModuleService,
    private readonly headerFiltersTypeService: HeaderFiltersTypeService,
    private readonly customFiltersService: CustomFiltersService,
    private readonly authenticationService: AuthenticationService,
    private readonly headerFiltersService: HeaderFiltersService,
    private readonly objectEventEmitters: ObjectEventEmitters
    )
    {
      this.employee = this.authenticationService.getCurrentEmployee();
      this.account = this.authenticationService.getCurrentAccount();
      this.headerFiltersService.filtersChanged$.subscribe((filters) => {
        this.checkForMatchingFavouriteFilter(filters);
      })

      this.initSubscriptions();
  }

  public initFilterSelectors(): Observable<any> {
    return forkJoin([this.initFilterTypesForCurrentObjects$, this.initFavouriteFilters$, this.initSuggestedFilters$]).pipe(
      tap(([filterTypes, favouriteFilters, suggestedFilters]) => {
        this._filterTypeSelectorVMsForCurrentObjectTypes.next(filterTypes);
        this._favouriteFilters.next(favouriteFilters);
        this._suggestedFilters.next(suggestedFilters);

        this.checkForMatchingFavouriteFilter(this.headerFiltersService.currentFilters);
      }))
  }

  public resetFilterTypeSelectorVMsForCurrentObjectTypes(): void {
    this._filterTypeSelectorVMsForCurrentObjectTypes.next([]);
  }

  public toggleSideFilters(state: 'open' | 'close'): void {
    this._isSideFiltersOpened.next(state === 'open');
  }

  public applyFavoriteFilter(favoriteFilter: EmployeeCustomFilterViewModel): void {
    this.headerFiltersService.setCurrentFilters(favoriteFilter.filters);
  }


  private checkForMatchingFavouriteFilter(filters: FilterViewModel[]): void {
    if(filters.length === 0) {
      this._favoriteMatchingFilter.next(null);
      return;
    }
    const currentObjectTypes = this.moduleService.currentObjectTypes;
    const favoriteFiltersForObjectTypes = this.favouriteFilters.filter((favourite) =>
      favourite.filters.every((f) => currentObjectTypes.includes(+f.displayForGlobalObjectType))
    );
    const matchingFavFiltersByLengthOfFilters = favoriteFiltersForObjectTypes.filter((favFilter) => favFilter.filters.length === filters.length);

    const foundFavouriteMatchingFilter = matchingFavFiltersByLengthOfFilters.find((favFilter) => {
      return favFilter.filters.every((f) =>
        filters.some((appliedFilter) => f.id === appliedFilter.id)
      )
    });

    this._favoriteMatchingFilter.next(foundFavouriteMatchingFilter);
  }

  public initSubscriptions(): void {
    // Subscribe for ADD of favourite filter
    this.objectEventEmitters.objectAdded$
    .pipe(filter(({ globalObjectType }) => globalObjectType === ObjectTypes.Favorite_Filters))
    .subscribe(({ model }) => {
      // Remove if already exists
      const currentFavooriteFilters = this.favouriteFilters.filter((f) => f.id !== model.id);
      currentFavooriteFilters.push(model);
      this._favouriteFilters.next(currentFavooriteFilters);
      this.checkForMatchingFavouriteFilter(this.headerFiltersService.currentFilters);
    });

    // Subscribe for UPDATE of favourite filter
    this.objectEventEmitters.objectUpdated$
    .pipe(filter(({ globalObjectType }) => globalObjectType === ObjectTypes.Favorite_Filters))
    .subscribe(({ globalObjectId, model }) => {
      const copyOfFilters = this.favouriteFilters.slice();
      const index = copyOfFilters.findIndex((f) => f.id === globalObjectId);

      copyOfFilters[index] = model as EmployeeCustomFilterViewModel;
      this._favouriteFilters.next(copyOfFilters);
      this.checkForMatchingFavouriteFilter(this.headerFiltersService.currentFilters);
    })

    // Subscribe for DELETE of favourite filter
    this.objectEventEmitters.objectDeleted$
    .pipe(filter(({ globalObjectType }) => globalObjectType === ObjectTypes.Favorite_Filters))
    .subscribe(({ globalObjectId }) => {
      const copyOfFilters = this.favouriteFilters.filter((f) => f.id !== globalObjectId);
      this._favouriteFilters.next(copyOfFilters);
      this.checkForMatchingFavouriteFilter(this.headerFiltersService.currentFilters);
    })
  }
}
